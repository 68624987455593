import React from "react";
import styles from "../index.module.scss";
import { CardSection } from "../../../../ui/Card";
import Heading from "../../../../ui/Heading";
import Text from "../../../../ui/Text";
import { capitalize } from "../../../../../utils/stringTransformations";
import { CreateScribeSubscriptionFormState } from "..";
import { ExternalLink } from "../../../../ui/Icon";

type PropsType = {
  formState: CreateScribeSubscriptionFormState;
};

const BillingSummary = ({ formState }: PropsType) => {
  return (
    <>
      <CardSection title="Plan">
        <Heading size="META">Plan</Heading>
        <Text className={styles.TextMarginBottom}>
          {formState.selectPlan?.values?.selectedPlan?.name}
        </Text>
        <div className={styles.TextMarginBottom}>
          <Heading size="META">Description</Heading>
          {formState.selectPlan?.values?.selectedPlan?.description}
        </div>
        <div className={styles.CardRow}>
          <div>
            <Heading size="META">Rate</Heading>$
            {formState.selectPlan?.values?.selectedPlan?.rate?.toFixed(2)}/provider/
            {formState.selectPlan?.values?.selectedPlan?.payCycleUnit}
          </div>
          <div>
            <Heading size="META">Renews</Heading>
            {capitalize(formState.selectPlan?.values?.selectedPlan?.payCycleUnit as string)}ly
          </div>
        </div>
      </CardSection>
      <Text>
        Plans renew automatically until you cancel. To make changes to your subscription,&nbsp;
        <a
          type="button"
          href="https://mikatahealth.com/feedback/"
          target="_blank"
          className={styles.InlineLink}
          rel="noreferrer"
        >
          contact us <ExternalLink size={16} />
        </a>
      </Text>
    </>
  );
};

export default BillingSummary;
