import React, { useEffect, useState } from "react";
import { useField } from "informed";

import { OptionWithIcon } from "../../../types";
import { Filters } from "./Filters";
import { ALL } from "../../../constants";

type PropsType = {
  selectedFilters: string[];
  filterOptions: OptionWithIcon[];
  className?: string;
  filterOptionClassName?: string;
  filterBadgeClassName?: string;
  fieldName: string;
};

export const FiltersInput = ({
  selectedFilters,
  filterOptions,
  className,
  filterOptionClassName,
  filterBadgeClassName,
  fieldName
}: PropsType) => {
  const { fieldState, fieldApi } = useField({
    name: fieldName
  });
  const { value } = fieldState;

  const [filters, setFilters] = useState(selectedFilters || [ALL]);

  const { setValue } = fieldApi;

  const updateFormState = (newValue: Array<string>) => {
    setValue(newValue);
    setFilters(newValue);
  };

  const onClickHandler = (changeValue: string) => {
    if (changeValue === ALL) {
      updateFormState([changeValue]);
    } else if (filters.includes(changeValue)) {
      const newValue = filters.filter((val: string) => val !== changeValue);
      if (newValue.length === 0) {
        updateFormState([ALL]);
      } else {
        updateFormState(newValue);
      }
    } else {
      const removeAllFilter = filters.filter((val: string) => val !== ALL);
      const newValue = [...removeAllFilter, changeValue];
      updateFormState(newValue);
    }
  };

  useEffect(() => {
    setFilters(value as string[]);
  }, [value]);

  useEffect(() => {
    setFilters(selectedFilters);
  }, [selectedFilters]);

  return (
    <Filters
      onClick={onClickHandler}
      selectedFilters={filters}
      filterOptions={filterOptions}
      className={className}
      filterOptionClassName={filterOptionClassName}
      filterBadgeClassName={filterBadgeClassName}
    />
  );
};
