import React, { useState, useRef, useEffect } from "react";
import cx from "classnames";

import Status from "../../../../ui/Status";
import { Form } from "../../../../ui/Input";
import LocationSelectorForm from "./LocationSelectorForm";

import { StatusComponentConfigMap, Option } from "../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  onLocationsChange: (value: string) => void;
  selectedLocations: string;
  locationOptions: Array<Option>;
  placeholder?: string;
  id?: string;
};

type LocationFiltersFormData = {
  locations: Array<string>;
};

const LocationSelector = ({
  onLocationsChange,
  selectedLocations: selectedLocationsStr,
  locationOptions,
  id,
  placeholder
}: PropsType) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((isOpen) => !isOpen);
  };

  const selectedLocations = selectedLocationsStr
    ? selectedLocationsStr.split(",").filter((locationId) => {
        return locationOptions.find(
          (locationOption) => locationOption.value.toString() === locationId
        );
      })
    : [];

  const selectedLocationsLabel = locationOptions
    .filter((locationOption) => {
      return selectedLocations.includes(locationOption.value.toString());
    })
    .map((locationOption) => locationOption.label)
    .join();

  const initialFormState = {
    locations: selectedLocations
  };

  const configMapping: StatusComponentConfigMap = locationOptions.reduce((config, option) => {
    // eslint-disable-next-line no-param-reassign
    config[option.value.toString()] = "gray";
    return config;
  }, {} as StatusComponentConfigMap);

  const onSave = (formData: LocationFiltersFormData) => {
    const locationIdsStr = formData.locations.join();
    toggle();
    onLocationsChange(locationIdsStr);
  };

  const offDropdownClick = (event: MouseEvent | KeyboardEvent) => {
    const dropdownEl = elementRef.current;
    const isOutsideClick = dropdownEl && !dropdownEl.contains(event.target as Node);

    if (isOutsideClick || (event as KeyboardEvent).key === "Escape") {
      toggle();
      document.removeEventListener("mouseup", offDropdownClick, false);
      document.removeEventListener("keyup", offDropdownClick, false);
    }
  };

  useEffect(() => {
    if (open) {
      // add when mounted
      document.addEventListener("mouseup", offDropdownClick);
      document.addEventListener("keyup", offDropdownClick);
      return () => {
        document.removeEventListener("mouseup", offDropdownClick, false);
        document.removeEventListener("keyup", offDropdownClick, false);
      };
    }

    document.removeEventListener("mouseup", offDropdownClick, false);
    document.removeEventListener("keyup", offDropdownClick, false);

    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mouseup", offDropdownClick, false);
      document.removeEventListener("keyup", offDropdownClick, false);
    };
  }, [open]);

  return (
    <div ref={elementRef} className={cx(styles.DropdownWrapper)}>
      <button
        id={id ? `dropdown-${id}` : undefined}
        type="button"
        className={cx(styles.Dropdown)}
        onClick={() => {
          toggle();
        }}
      >
        <Status
          value={selectedLocationsStr}
          placeholder={placeholder}
          options={[
            {
              label: "All Locations",
              value: ""
            },
            ...locationOptions,
            {
              label: `(${selectedLocations.length}) ${selectedLocationsLabel}`,
              value: selectedLocationsStr
            }
          ]}
          configMap={configMapping}
          className={cx(styles.DropdownStatus, { [styles.DropdownStatusActive]: open })}
          defaultColor="gray"
        />
      </button>

      <div className={cx(styles.DropdownList, { [styles.DropdownListOpen]: open })}>
        <Form
          key={selectedLocations.join(",")}
          initialValues={initialFormState}
          onSubmit={(formState) => onSave(formState.values as LocationFiltersFormData)}
        >
          <LocationSelectorForm locationOptions={locationOptions} />
        </Form>
      </div>
    </div>
  );
};

export default LocationSelector;
