import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { legacy_createStore as createStore, applyMiddleware } from "redux";
import promise from "redux-promise";
import { thunk } from "redux-thunk";
import App from "./components/App";
import UserProvider from "./components/providers/UserProvider";
import OrganizationProvider from "./components/providers/OrganizationProvider";
import AnalyticsProvider from "./components/providers/AnalyticsProvider";
import LayoutProvider from "./components/providers/LayoutProvider";
import AnnouncementsProvider from "./components/providers/AnnouncementsProvider";
import ErrorBoundary from "./components/ErrorBoundary";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import rootReducer from "./reducers";

import "./index.css";
import "./css/_base.scss";

const createStoreWithMiddleware = applyMiddleware(promise, thunk)(createStore);
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Provider
    store={createStoreWithMiddleware(
      rootReducer,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-underscore-dangle
      (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
    )}
  >
    <Router>
      <ErrorBoundary>
        <UserProvider>
          <AnalyticsProvider>
            <OrganizationProvider>
              <LayoutProvider>
                <AnnouncementsProvider>
                  <App />
                </AnnouncementsProvider>
              </LayoutProvider>
            </OrganizationProvider>
          </AnalyticsProvider>
        </UserProvider>
      </ErrorBoundary>
    </Router>
  </Provider>
);

// https://create-react-app.dev/docs/making-a-progressive-web-app/#offline-first-considerations
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateServiceWorker = (newServiceWorker: any) => {
  const registrationWaiting = newServiceWorker.waiting;
  console.log("SW -> updateServiceWorker -> registrationWaiting:", registrationWaiting);
  if (registrationWaiting) {
    // eslint-disable-next-line no-console
    console.log("Updating service worker now...");
    registrationWaiting.postMessage({ type: "SKIP_WAITING" });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    registrationWaiting.addEventListener("statechange", (e: any) => {
      console.log("SW -> updateServiceWorker -> statechange event:", e);
      if (e.target.state === "activated") {
        window.location.reload();
      }
    });
  }
};

serviceWorkerRegistration.register({ onUpdate: updateServiceWorker });

sessionStorage.removeItem("loadCount");
