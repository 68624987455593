import { AutomationJourneyDetails, MessageScheduleTriggerUnit } from "../../../../types";
import { capitalize } from "../../../../utils/stringTransformations";
import { getTriggerUnitToMinutesFactor } from "../../tables/AppointmentJourneysTable/JourneyTimeline/helpers";

const MessageScheduleEvents = {
  START: "start",
  CANCELLED: "cancelled",
  COMPLETE: "complete",
  BOOKED: "booked"
};

export type SortedAutomationJourneyDetails = ({
  templates: AutomationJourneyDetails[] | null;
  featuresDisabled?: boolean;
  heading: string;
} | null)[];

const areFeaturesDisabled = (template: AutomationJourneyDetails) => {
  const featuresDisabled = template
    ? template.features?.reduce((isDisabled, detail) => {
        if (isDisabled) return true;

        if (!detail.enabled) return true;

        return false;
      }, false)
    : false;

  return featuresDisabled;
};

const sortEventTemplates = (templates: AutomationJourneyDetails[]) => {
  const sortedTemplates = templates.sort((a, b) =>
    a.triggerDetails && b.triggerDetails
      ? a.triggerDetails.unit.localeCompare(b.triggerDetails.unit) ||
        a.triggerDetails.value - b.triggerDetails.value
      : 0
  );

  return sortedTemplates;
};

const generateStartEventHeading = (template: AutomationJourneyDetails) => {
  const { triggerDetails } = template;

  if (triggerDetails.value < 0) {
    if (triggerDetails.value === -1) {
      return `1 ${capitalize(triggerDetails.unit.slice(0, -1))} Before`;
    }
    return `${Math.abs(triggerDetails.value)} ${capitalize(triggerDetails.unit)} Before`;
  }

  if (triggerDetails.value === 1) {
    return `1 ${capitalize(triggerDetails.unit.slice(0, -1))} After`;
  }
  return `${triggerDetails.value} ${capitalize(triggerDetails.unit)} After`;
};

const orderByStartEventTriggers = (a: AutomationJourneyDetails, b: AutomationJourneyDetails) => {
  const aTriggerValueFactor = getTriggerUnitToMinutesFactor(
    a.triggerDetails?.unit as MessageScheduleTriggerUnit
  );
  const aOffsetMinutes = (a.triggerDetails?.value as number) * aTriggerValueFactor;
  const bTriggerValueFactor = getTriggerUnitToMinutesFactor(
    b.triggerDetails?.unit as MessageScheduleTriggerUnit
  );
  const bOffsetMinutes = (b.triggerDetails?.value as number) * bTriggerValueFactor;

  if (aOffsetMinutes > bOffsetMinutes) return 1;
  if (aOffsetMinutes < bOffsetMinutes) return -1;
  return 0;
};

const sortAutomationJourney = (automationJourneyDetails?: AutomationJourneyDetails[]) => {
  if (!automationJourneyDetails) return null;

  const bookedEventTemplates = automationJourneyDetails.filter(
    (template) => template.triggerDetails?.event === MessageScheduleEvents.BOOKED
  );

  const startEventTemplates = automationJourneyDetails.filter(
    (template) => template.triggerDetails?.event === MessageScheduleEvents.START
  );

  const cancelledEventTemplates = automationJourneyDetails.filter(
    (template) => template.triggerDetails?.event === MessageScheduleEvents.CANCELLED
  );

  const bookedTemplates = sortEventTemplates(bookedEventTemplates);
  const cancelledTemplates = sortEventTemplates(cancelledEventTemplates);

  const bookedDetails =
    bookedTemplates.length !== 0
      ? {
          templates: bookedTemplates,
          featuresDisabled: areFeaturesDisabled(bookedTemplates[0]),
          heading: "Booked Messages"
        }
      : null;

  const cancelledDetails =
    cancelledTemplates.length !== 0
      ? {
          templates: cancelledTemplates,
          featuresDisabled: areFeaturesDisabled(cancelledTemplates[0]),
          heading: "Cancelled Messages"
        }
      : null;

  const startEventTemplatesSorted = startEventTemplates.sort(orderByStartEventTriggers);

  const groupedStartEventTemplates: {
    [index: string]: {
      templates: AutomationJourneyDetails[];
      featuresDisabled: boolean | undefined;
      heading: string;
    };
  } = {};

  startEventTemplatesSorted.map((template) => {
    const matchingTemplates = startEventTemplatesSorted.filter((matchTemplate) => {
      return (
        template.triggerDetails?.unit === matchTemplate.triggerDetails?.unit &&
        template.triggerDetails?.value === matchTemplate.triggerDetails?.value
      );
    });

    const index = `${template.triggerDetails?.value} ${template.triggerDetails?.unit}`;

    if (!groupedStartEventTemplates[index] && matchingTemplates) {
      groupedStartEventTemplates[index] = {
        templates: matchingTemplates as AutomationJourneyDetails[],
        featuresDisabled: areFeaturesDisabled(template),
        heading: generateStartEventHeading(template)
      };
    }
  });

  const responseDetails = {
    templates: automationJourneyDetails.filter((template) => template.triggerDetails === undefined),
    featuresDisabled: undefined,
    heading: "User Responses"
  };

  if (groupedStartEventTemplates) {
    return [
      bookedDetails,
      cancelledDetails,
      ...Object.values(groupedStartEventTemplates),
      responseDetails
    ];
  }
  return [bookedDetails, cancelledDetails, responseDetails];
};

export default sortAutomationJourney;
