import React, { useEffect } from "react";
import { connect } from "react-redux";

import { FloatModal } from "../../../ui/Modal";
import Heading from "../../../ui/Heading";
import { Form, SelectInput } from "../../../ui/Input";
import Button from "../../../ui/Button";
import Loader from "../../../ui/Loader";

import {
  closeModal as closeModalAction,
  cloneChatFlow as CloneChatFlowAction,
  fetchDataForMikataAdmin as fetchDataForMikataAdminAction
} from "../../../../actions";
import { ReduxStateType, AdminOrgData } from "../../../../types";
import { isRequired } from "../../../../utils/validators";

import styles from "./index.module.scss";

type PropsType = {
  cloneChatFlow: (
    chatId: string,
    onSuccess?: (cloneChatFlowId: string) => void,
    toOrganizationId?: number
  ) => void;
  closeModal: () => void;
  chatFlowId: string;
  organizations: AdminOrgData[];
  fetchDataForMikataAdmin: () => void;
};

type FormState = {
  toOrganizationId: string;
};

const organizationValidator = isRequired("Please select an organization to clone to");

const CloneChatFlowToOrganization = ({
  chatFlowId,
  closeModal,
  cloneChatFlow,
  organizations,
  fetchDataForMikataAdmin
}: PropsType): JSX.Element => {
  useEffect(() => {
    fetchDataForMikataAdmin();
  }, []);

  const save = async (formValues: FormState) => {
    cloneChatFlow(chatFlowId, undefined, Number(formValues.toOrganizationId));
    closeModal();
  };

  const initialFormState = {
    toOrganizationId: ""
  };

  const organizationOptions = organizations.map((organization) => {
    return { label: organization.fullName, value: organization.id };
  });

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L" className={styles.Heading}>
        Fork Chat
      </Heading>
      <Form
        onSubmit={(formState) => save(formState.values as FormState)}
        initialValues={initialFormState}
      >
        {organizations.length !== 0 ? (
          <>
            <SelectInput
              fieldName="toOrganizationId"
              label="Organization"
              options={organizationOptions}
              placeholder="Select organization"
              validate={organizationValidator}
            />
            <div className={styles.ButtonGroup}>
              <Button inline onClick={closeModal}>
                Cancel
              </Button>
              <Button type="submit">Clone</Button>
            </div>
          </>
        ) : (
          <Loader small center />
        )}
      </Form>
    </FloatModal>
  );
};

const mapStateToProps = ({ adminData }: ReduxStateType) => {
  return {
    organizations: adminData?.adminData?.data || []
  };
};

export default connect(mapStateToProps, {
  closeModal: closeModalAction,
  cloneChatFlow: CloneChatFlowAction,
  fetchDataForMikataAdmin: fetchDataForMikataAdminAction
})(CloneChatFlowToOrganization);
