import React from "react";

import { useFormApi, useFormState } from "informed";
import Button from "../../Button";
import Heading from "../../Heading";
import Text from "../../Text";
import { TextAreaInput } from "../../Input";
import { Close } from "../../Icon";
import RatingInput from "../RatingInput";
import { ResponsiveHide } from "../../Responsive";
import { FeedbackSubmitButton, UserConsentCheckbox } from "..";

import { SetFeedbackModeData } from "../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  setFeedbackMode: (data: SetFeedbackModeData) => void;
};

type FeedbackFormData = {
  feedbackData: string;
  consent: boolean;
  rating: number | null;
};

const ScribeFeedbackForm = ({ setFeedbackMode }: PropsType): JSX.Element => {
  const formState = useFormState();
  const formApi = useFormApi();
  const values = formState.values as FeedbackFormData;
  return (
    <>
      <div className={styles.ScribeFeedbackHeaderRow}>
        <Heading size="S" bold className={styles.ScribeFeedbackHeading}>
          What do you think about the quality of Mika AI's suggested notes?
        </Heading>

        <ResponsiveHide hideOnMobile>
          <div className={styles.FeedbackFormCloseButtonWrapper}>
            <Button
              id="closeFeedbackForm"
              className={styles.CloseButton}
              type="button"
              inline
              onClick={() => setFeedbackMode({ feedbackMode: null })}
            >
              <Close size={20} />
            </Button>
          </div>
        </ResponsiveHide>
      </div>
      <RatingInput fieldName="rating" />

      {values.rating !== null && values?.rating >= 1 && (
        <>
          <div className={styles.FeedbackUserComment}>
            <Text size="M" bold>
              Do you have any issues or comments?
            </Text>

            <Text size="S">We want to hear more, help us improve our performance.</Text>

            <TextAreaInput
              size="M"
              fieldName="feedbackData"
              placeholder="What do you think of this experience? How can we improve it?"
              customOnChange={(event) => {
                formApi.setValue("feedbackData", event.feedbackData);
              }}
            />
          </div>

          <UserConsentCheckbox />
          <FeedbackSubmitButton fullWidth disabled={!values?.rating} />
        </>
      )}
    </>
  );
};

export default ScribeFeedbackForm;
