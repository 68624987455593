import React from "react";
import { connect } from "react-redux";

import { FloatModal } from "../../../ui/Modal";
import Heading from "../../../ui/Heading";
import { Form, SelectInput, TextInput } from "../../../ui/Input";
import Button from "../../../ui/Button";

import {
  closeModal as closeModalAction,
  addService as addServiceAction,
  AddServiceData
} from "../../../../actions";
import { isRequired } from "../../../../utils/validators";
import { ServiceProviders } from "../../../../constants";

import styles from "./index.module.scss";

type PropsType = {
  organizationId: number;
  closeModal: () => void;
  addService: (data: AddServiceData) => void;
};

type FormState = {
  provider: string;
  priority: number;
};

const initialFormState = {
  provider: ServiceProviders.VONAGE.value,
  priority: 5
};

const providerOptions = Object.keys(ServiceProviders).map((providerKey) => {
  return {
    label: ServiceProviders[providerKey].name,
    value: ServiceProviders[providerKey].value
  };
});

const formValidator = (values: FormState) => {
  return {
    provider: isRequired("Please select a service provider")(values.provider),
    priority: undefined
  };
};

const AddService = ({ organizationId, closeModal, addService }: PropsType): JSX.Element => {
  const save = async (formValues: FormState) => {
    const data = {
      organizationId,
      provider: formValues.provider,
      priority: formValues.priority
    };
    addService(data);
    closeModal();
  };

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L" className={styles.Heading}>
        Add New Service
      </Heading>
      <Form
        onSubmit={(formState) => save(formState.values as FormState)}
        initialValues={initialFormState}
        validateFields={(values) => formValidator(values as FormState)}
      >
        <SelectInput
          fieldName="provider"
          label="provider"
          options={providerOptions}
          placeholder="Select provider"
        />
        <TextInput fieldName="priority" label="priority" placeholder="Enter priority" />

        <hr className={styles.Divider} />

        <div className={styles.ButtonGroup}>
          <Button inline onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit">Create</Button>
        </div>
      </Form>
    </FloatModal>
  );
};

export default connect(null, {
  closeModal: closeModalAction,
  addService: addServiceAction
})(AddService);
