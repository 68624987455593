import React from "react";

import { useFormApi, useFormState } from "informed";
import Button, { ButtonRow } from "../../../ui/Button";
import { CheckboxGroup, TextInput, TextAreaInput } from "../../../ui/Input";

import {
  MessageTemplateFormState,
  payloadContentValidator,
  displayNameValidator,
  subjectValidator
} from "../../../../utils/validators/editMessageTemplateFormValidator";
import SmsMessageInfo from "../../OrganizationDetails/views/MessageTemplatesTable/MessageTemplateDetails/SmsMessageInfo";

import styles from "./index.module.scss";

type PropsType = {
  impactedJourneys?: Array<{ id: number; displayName: string }>;
  impactedJourneyNames: string;
  formDisabled?: boolean;
  payloadContentWarning: (value: string) => string | undefined;
  closeModal: () => void;
};

const FormContent = ({
  impactedJourneys,
  impactedJourneyNames,
  formDisabled,
  payloadContentWarning,
  closeModal
}: PropsType) => {
  const formState = useFormState();
  const formApi = useFormApi();
  const values = formState.values as MessageTemplateFormState;
  return (
    <>
      <TextInput
        fieldName="displayName"
        label="name"
        placeholder="Enter name"
        validate={displayNameValidator}
        disabled={formDisabled}
      />
      <TextInput
        fieldName="subject"
        label="Subject"
        disabled={formDisabled}
        validate={subjectValidator}
        info={<span>Appears in the subject line of emails. Irrelevant for SMS messages.</span>}
      />
      <TextAreaInput
        id="content"
        fieldName="payloadContent"
        label="Content"
        size="M"
        info="Standard messages contain max.160 characters. Non-standard messages contain max.70 characters."
        labelDetails={<SmsMessageInfo messageContent={values.payloadContent} />}
        customOnChange={() => {
          // Clear form level error
          formApi.setError("payloadContent", undefined);
        }}
        validate={payloadContentValidator}
        disabled={formDisabled}
        warningMessage={payloadContentWarning(values.payloadContent)}
      />

      {!!impactedJourneys?.length && (
        <div>
          <div className={styles.WarningMessage}>
            Your changes may impact other journeys using this message template (Impacted journeys:{" "}
            {impactedJourneyNames}). If you do not want your changes to impact other journeys,
            select "Save as new template".
          </div>
          <CheckboxGroup
            fieldName="saveAsNew"
            options={[{ label: "Save as new template", value: "true" }]}
          />
        </div>
      )}
      <ButtonRow topBar>
        <Button inline onClick={closeModal}>
          Cancel
        </Button>
        <Button id="editMessageTemplate" type="submit" disabled={formDisabled}>
          Save
        </Button>
      </ButtonRow>
    </>
  );
};

export default FormContent;
