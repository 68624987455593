import React, { useMemo, useContext } from "react";
import { connect } from "react-redux";

import moment from "moment";
import Heading from "../../../ui/Heading";
import { FloatModal } from "../../../ui/Modal";
import Button, { ButtonRow } from "../../../ui/Button";
import Loader from "../../../ui/Loader";
import {
  Form,
  TextInput,
  SelectInput,
  FormGrid,
  FormMoreDetails,
  DateSelectInput,
  TimeSelectInput
} from "../../../ui/Input";
import { CardDivider } from "../../../ui/Card";

import { OrganizationContext } from "../../../providers/OrganizationProvider";
import { usePermissions } from "../../../../hooks/usePermissions";

import {
  updateUnintegratedAppointment as updateUnintegratedAppointmentAction,
  UpdateUnintegratedAppointmentsData,
  closeModal as closeModalAction
} from "../../../../actions";

import { generatePractitionerLabel } from "../../../../utils/generatePractitionerLabel";
import { generateLocationLabel } from "../../../../utils/generateLocationLabel";
import { generateReasonLabel } from "../../../../utils/generateReasonLabel";
import formatPhone from "../../../../utils/formatPhone";

import { isRequired, isPhone, isEmail } from "../../../../utils/validators";

import {
  Option,
  ReduxStateType,
  Practitioner,
  Location,
  Reason,
  AppointmentDetail,
  Permissions,
  OrganizationEmrFeatures
} from "../../../../types";

import styles from "./index.module.scss";
import { CircleCheck } from "../../../ui/Icon";

type PropsType = {
  appointment?: AppointmentDetail;
  practitioners: Practitioner[];
  practitionersLoading: boolean;
  locations: Location[];
  locationsLoading: boolean;
  reasons: Reason[];
  reasonsLoading: boolean;
  updateUnintegratedAppointment: (
    appointmentId: number,
    data: UpdateUnintegratedAppointmentsData,
    options?: { onSuccess?: () => void }
  ) => void;
  updateUnintegratedLoading: boolean;
  closeModal: () => void;
};

type FormState = {
  firstName: string;
  lastName: string;
  mobilePhone: string;
  email: string;
  phn: string;
  practitionerId: number | null;
  locationId: number | null;
  reasonId: number | null;
  startDate: string;
  startTime: string;
};

const firstNameValidator = isRequired("Please enter a first name");
const lastNameValidator = isRequired("Please enter a last name");
const mobilePhoneValidator = isPhone("Please enter a valid phone number");
const startTimeValidator = isRequired("Please enter a start time");
const startDateValidator = isRequired("Please enter a start date");
const providerValidator = isRequired("Please enter a provider");
const locationValidator = isRequired("Please enter a location");
const reasonValidator = isRequired("Please enter a reason");
const emailValidator = isEmail("Please enter a valid email");

const formValidator = (values: FormState) => {
  return {
    firstName: firstNameValidator(values.firstName),
    lastName: lastNameValidator(values.lastName),
    mobilePhone: mobilePhoneValidator(values.mobilePhone),
    email: emailValidator(values.email),
    phn: undefined,
    startTime: startTimeValidator(values.startTime),
    startDate: startDateValidator(values.startDate),
    practitionerId: providerValidator(values.practitionerId),
    locationId: locationValidator(values.locationId),
    reasonId: reasonValidator(values.reasonId)
  };
};

const ViewEditAppointment = ({
  appointment,
  practitioners,
  practitionersLoading,
  locations,
  locationsLoading,
  reasons,
  reasonsLoading,
  updateUnintegratedAppointment,
  updateUnintegratedLoading,
  closeModal
}: PropsType) => {
  const organization = useContext(OrganizationContext);
  const emrFeatures = organization?.emrFeatures || [];
  const organizationHasCapability =
    emrFeatures.includes(OrganizationEmrFeatures.UNINTEGRATED_APPOINTMENTS) &&
    emrFeatures.includes(OrganizationEmrFeatures.UNINTEGRATED_PATIENTS);
  const userHasPermission = usePermissions(
    [Permissions.CREATE_UNINTEGRATED_APPOINTMENT, Permissions.CREATE_UNINTEGRATED_PATIENT],
    false
  );
  const canEditAppointment =
    appointment && !appointment.emrAppointmentId && userHasPermission && organizationHasCapability;

  const formDisabled = !canEditAppointment;

  const providerOptions: Option[] = practitioners
    .filter((practitioner) => practitioner.practitionerActive)
    .map((practitioner) => ({
      label: generatePractitionerLabel(practitioner),
      value: practitioner.id
    }));
  const locationOptions: Option[] = locations
    .filter((location) => location.active)
    .map((location) => ({
      label: generateLocationLabel(location),
      value: location.id
    }));
  const reasonOptions: Option[] = reasons
    .filter((reason) => reason.active)
    .map((reason) => ({
      label: generateReasonLabel(reason),
      value: reason.id
    }));
  const formInitializing =
    !appointment || practitionersLoading || locationsLoading || reasonsLoading;

  const initialFormState: FormState | null = useMemo(() => {
    if (formInitializing) return null;

    const { practitionerId, locationId, reasonId } = appointment;

    return {
      firstName: appointment.patientFirstName || "",
      lastName: appointment.patientLastName || "",
      mobilePhone: appointment.mobilePhone ? appointment.mobilePhone.replaceAll("+", "") : "",
      email: appointment.email || "",
      phn: appointment.healthcareIdentifier || "",
      practitionerId,
      locationId,
      reasonId,
      startTime: moment(appointment.start).format("h:mm a").toString() || "",
      startDate: moment(appointment.start).format("dddd, MMMM D").toString() || ""
    };
  }, [appointment?.id, formInitializing]);

  const save = async (formValues: FormState) => {
    const formattedStartDate = moment(formValues.startDate).format("ddd MMM DD YYYY");

    const data = {
      patient: {
        firstName: formValues.firstName || "",
        lastName: formValues.lastName || "",
        mobilePhone: formValues.mobilePhone || "",
        email: formValues.email || "",
        phn: formValues.phn || ""
      },
      appointment: {
        start: moment(`${formattedStartDate} ${formValues.startTime}`).utc().toISOString(),
        practitionerId: formValues.practitionerId,
        locationId: formValues.locationId,
        reasonId: formValues.reasonId
      }
    };

    if (appointment?.id) {
      updateUnintegratedAppointment(appointment?.id, data, { onSuccess: () => closeModal() });
    }
  };

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L">Appointment Details</Heading>
      {formInitializing && <Loader screen center />}
      {!formInitializing && initialFormState && (
        <Form
          key={`appointmentDetails-${appointment?.id}`}
          onSubmit={(formState) => save(formState.values as FormState)}
          initialValues={initialFormState}
          validateFields={(values) => formValidator(values as FormState)}
        >
          <FormMoreDetails
            sectionLabel="Patient Information"
            editable={canEditAppointment}
            initOpen={canEditAppointment && !appointment.canSendSecureMessage}
            formSummaryFields={[
              { label: "first name", fieldName: "firstName" },
              { label: "last name", fieldName: "lastName" },
              {
                label: "Mobile number",
                fieldName: "mobilePhone",
                fieldFormatter: formatPhone,
                fieldIcon:
                  appointment && appointment.canSendSms ? (
                    <div className={styles.VerifiedMobileIcon}>
                      <CircleCheck size={16} />
                    </div>
                  ) : undefined
              },
              { label: "Email", fieldName: "email" },
              { label: "Healthcare number", fieldName: "phn" }
            ]}
            formSection={
              <>
                <FormGrid>
                  <TextInput
                    fieldName="firstName"
                    label="first name"
                    placeholder="Enter first name"
                    validate={firstNameValidator}
                    disabled={formDisabled}
                  />
                  <TextInput
                    fieldName="lastName"
                    label="last name"
                    placeholder="Enter last name"
                    validate={lastNameValidator}
                    disabled={formDisabled}
                  />
                </FormGrid>
                <TextInput
                  fieldName="mobilePhone"
                  label="Mobile number (Optional)"
                  validate={mobilePhoneValidator}
                  disabled={formDisabled}
                />
                <TextInput
                  fieldName="email"
                  label="Email (Optional)"
                  validate={emailValidator}
                  disabled={formDisabled}
                />
                <TextInput
                  fieldName="phn"
                  label="Healthcare number (Optional)"
                  disabled={formDisabled}
                />
              </>
            }
          />
          <FormMoreDetails
            editable={canEditAppointment}
            sectionLabel="Appointment"
            formSummaryFields={[
              { label: "Date", fieldName: "startDate" },
              { label: "Time", fieldName: "startTime" },
              { label: "Provider", fieldName: "practitionerId", options: providerOptions },
              { label: "Location", fieldName: "locationId", options: locationOptions },
              { label: "Reason", fieldName: "reasonId", options: reasonOptions }
            ]}
            formSection={
              <>
                <FormGrid>
                  <DateSelectInput
                    fieldName="startDate"
                    label="Date"
                    initialValue={moment(appointment.start).toDate()}
                    disabled={formDisabled}
                  />
                  <TimeSelectInput
                    fieldName="startTime"
                    label="Time"
                    placeholder="Select a start time"
                    disabled={formDisabled}
                  />
                </FormGrid>
                <SelectInput
                  fieldName="practitionerId"
                  label="Provider"
                  placeholder="Enter a provider"
                  validate={providerValidator}
                  options={providerOptions}
                  disabled={formDisabled}
                />
                <SelectInput
                  fieldName="locationId"
                  label="Location"
                  placeholder="Enter a location"
                  validate={locationValidator}
                  options={locationOptions}
                  disabled={formDisabled}
                />
                <SelectInput
                  fieldName="reasonId"
                  label="Reason"
                  placeholder="Enter an appointment reason"
                  validate={reasonValidator}
                  options={reasonOptions}
                  disabled={formDisabled}
                />
              </>
            }
          />
          {canEditAppointment && (
            <>
              <CardDivider />
              <br />
              <ButtonRow>
                <Button inline onClick={closeModal}>
                  Cancel
                </Button>
                {canEditAppointment && (
                  <Button type="submit" disabled={updateUnintegratedLoading}>
                    Save
                  </Button>
                )}
              </ButtonRow>
            </>
          )}
        </Form>
      )}
    </FloatModal>
  );
};

const mapStateToProps = ({ appointments, practitioners, locations, reasons }: ReduxStateType) => {
  return {
    appointment: appointments.details.data,
    updateUnintegratedLoading: appointments.updateUnintegratedLoading,
    practitioners: practitioners.data,
    practitionersLoading: practitioners.loading,
    locations: locations.data,
    locationsLoading: locations.locationsFetchLoading,
    reasons: reasons.data,
    reasonsLoading: !reasons.initialized
  };
};

export default connect(mapStateToProps, {
  updateUnintegratedAppointment: updateUnintegratedAppointmentAction,
  closeModal: closeModalAction
})(ViewEditAppointment);
