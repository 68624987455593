import { InputValue } from "../../types";

export const isLessThan = (maxValue: number, message?: string) => (value: InputValue) => {
  const errorMessage = message || "Value is too high";
  if (value) {
    const newValue = Number(value);
    return newValue > maxValue || Number.isNaN(newValue) ? errorMessage : undefined;
  }
  return errorMessage;
};
