import React, { useEffect, useContext, useCallback } from "react";

import { EncounterSocketContext } from "../../../../../providers/EncounterSocketProvider";
import {
  SetFeedbackModeData,
  EventData,
  EventTypes,
  SocketMessageType
} from "../../../../../../types";
import { FeedbackFormModes } from "../../../../../../constants";

const useFeedbackQueue = (
  noteId: number,
  isActive: boolean,
  feedbackQueue: number[],
  setFeedbackMode: (data: SetFeedbackModeData) => void,
  clearNoteFromQueue: (data: number) => void
) => {
  const { sendMessage } = useContext(EncounterSocketContext);

  const sendProviderActivityMessage = useCallback(
    (eventType: EventTypes, eventData: EventData = {}) => {
      sendMessage(SocketMessageType.providerActivity, {
        eventType,
        eventData: { ...eventData, activeNoteId: noteId }
      });
    },
    [noteId]
  );

  useEffect(() => {
    const noteExistsInQueue = feedbackQueue.indexOf(noteId) > -1;

    if (noteExistsInQueue && isActive) {
      setFeedbackMode({
        feedbackMode: FeedbackFormModes.SCRIBE_FEEDBACK
      });
      clearNoteFromQueue(noteId);
      sendProviderActivityMessage(EventTypes.PROMPTED_FOR_FEEDBACK, {
        activeNoteId: noteId
      });
    }
  }, [feedbackQueue, isActive]);
};

export default useFeedbackQueue;
