import React, { useContext } from "react";
import { connect } from "react-redux";

import { ReduxStateType, ChatFlowOutput, ReportTemplate } from "../../../../../../../types";
import styles from "./index.module.scss";

import Heading from "../../../../../../ui/Heading";
import Text from "../../../../../../ui/Text";
import Button from "../../../../../../ui/Button";
import { Info, Trash } from "../../../../../../ui/Icon";

import {
  fetchServices as fetchServicesAction,
  OpenModal,
  openModal as openModalAction
} from "../../../../../../../actions";

import { AdminModalTypes, ChatFlowOutputTypes } from "../../../../../../../constants";
import Tooltip from "../../../../../../ui/Tooltip";
import { OrganizationContext } from "../../../../../../providers/OrganizationProvider";

type PropsType = {
  outputs: Array<ChatFlowOutput>;
  reportTemplates: Array<ReportTemplate>;
  openModal: OpenModal;
  published: boolean;
  isOrganizationChat: boolean;
  hasDraft: boolean;
};

const Outputs = ({
  outputs,
  reportTemplates,
  openModal,
  published,
  isOrganizationChat,
  hasDraft
}: PropsType) => {
  const organization = useContext(OrganizationContext);
  // hasDraft will be true if the chat is a published version with a draft, or false if the chat is a draft or a published version without a draft.
  const allowOutputCreation = isOrganizationChat ? !published : !hasDraft;

  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <Heading size="M" component="span" className={styles.Heading}>
          Outputs
        </Heading>

        {organization !== null && (
          <div className={styles.HeaderRight}>
            {!allowOutputCreation && (
              <Tooltip
                icon={
                  <div className={styles.Icon}>
                    <Info size={15} />
                  </div>
                }
                position="bottomLeft"
              >
                Outputs can only be added in draft mode or if the chat is a published Mikata-level
                chat without a draft.
              </Tooltip>
            )}

            <Button
              inline
              disabled={!allowOutputCreation}
              onClick={() => {
                openModal(AdminModalTypes.ADD_CHAT_FLOW_OUTPUT);
              }}
            >
              Add Output +
            </Button>
          </div>
        )}
      </div>

      <div className={styles.CardList}>
        {outputs.map((output) => {
          const reportTemplate = reportTemplates.find((reportTemplate) => {
            return String(reportTemplate.id) === String(output.reportTemplateId);
          });
          return (
            <div key={output.id} className={styles.Card}>
              <div className={styles.CardHeader}>
                <Text>ID: {output.id}</Text>
                <div>
                  <Tooltip
                    icon={
                      <Button
                        inline
                        disabled={!allowOutputCreation}
                        onClick={() => {
                          openModal(AdminModalTypes.DELETE_CHAT_FLOW_OUTPUT, {
                            outputId: output.id
                          });
                        }}
                      >
                        <Trash />
                      </Button>
                    }
                    position="bottomLeft"
                  >
                    Outputs can only be deleted in draft mode or if the chat is a published
                    Mikata-level chat without a draft.
                  </Tooltip>
                </div>
              </div>

              <div className={styles.CardBody}>
                {reportTemplate && <Text>Report: {reportTemplate.name}</Text>}
                <Text>Type: {output.type}</Text>
                {output.config?.letterTypeId && (
                  <Text key={`letterTypeId-${output.config?.letterTypeId}`}>
                    letterTypeId: {output.config?.letterTypeId}
                  </Text>
                )}
                {output.config?.title && (
                  <Text key={`title-${output.config?.title}`}>Title: {output.config?.title}</Text>
                )}
                {output.config?.dataMappings &&
                  output?.type === ChatFlowOutputTypes.PUSH_DEMOGRAPHICS && (
                    <Text>Pushed Demographics:</Text>
                  )}
                {output.config?.dataMappings &&
                  output?.type === ChatFlowOutputTypes.PUSH_DEMOGRAPHICS &&
                  Object.keys(output.config?.dataMappings).map((emrFieldName) => {
                    return (
                      <li
                        className={styles.CardDemographicList}
                        key={`dataMappings-${emrFieldName}`}
                      >
                        {emrFieldName}
                      </li>
                    );
                  })}

                {output.config?.dataMappings && output?.type === ChatFlowOutputTypes.FILE_CSV && (
                  <Text>Mappings for:</Text>
                )}
                {output.config?.dataMappings &&
                  output?.type === ChatFlowOutputTypes.FILE_CSV &&
                  Object.keys(output.config?.dataMappings).map((key) => {
                    return (
                      <li className={styles.CardDemographicList} key={`fileCSV-${key}`}>
                        {output?.config?.dataMappings?.[key].inputName}
                      </li>
                    );
                  })}
              </div>

              <div className={styles.CardEdit}>
                <Tooltip
                  icon={
                    <Button
                      inline
                      className={styles.CardEditButton}
                      disabled={!allowOutputCreation}
                      onClick={() => {
                        openModal(AdminModalTypes.ADD_CHAT_FLOW_OUTPUT, { chatFlowOutput: output });
                      }}
                    >
                      Edit
                    </Button>
                  }
                  position="topRight"
                >
                  Outputs can only be edited in draft mode or if the chat is a published
                  Mikata-level chat without a draft.
                </Tooltip>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = ({ chats }: ReduxStateType) => {
  return {
    isOrganizationChat: !!chats.chatDetails.organizationId,
    hasDraft: !!chats.chatDetails.draftChatFlowId,
    outputs: chats.chatDetails.outputs,
    reportTemplates: chats.chatDetails.reportTemplates,
    published: !!chats.chatDetails.published
  };
};

export default connect(mapStateToProps, {
  fetchServices: fetchServicesAction,
  openModal: openModalAction
})(Outputs);
