import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useField } from "informed";
import { get, set, cloneDeep } from "lodash";

import BaseInput from "../BaseInput";
import Button from "../../Button";
import { Plus } from "../../Icon";

import Condition from "./Condition";
import {
  cleanConditionsRecursively,
  getOperatorType,
  buildDefaultValidator,
  JsonLogicInputError
} from "./helpers";

import { combine } from "../../../../utils/validators";

import {
  ContextVariable,
  UpdateCondition,
  UpdateConditionValue,
  ConditionJSON,
  PLACEHOLDER_OPERATOR,
  INITIAL_CONDITION_VALUE
} from "./types";

import { InputPropsType, InputValue } from "../../../../types";

export type JsonLogicContextVariable = ContextVariable;

type PropsType = InputPropsType & {
  contextVariables: ContextVariable[];
};

const JsonLogicInput = ({
  fieldName,
  label,
  contextVariables,
  showField = true,
  info,
  infoStatus,
  disabled = false,
  warningMessage = "",
  validate,
  ...props
}: PropsType) => {
  const [errorsInfo, setErrorsInfo] = useState<JsonLogicInputError[]>([]);
  const defaultValidator: (value: InputValue) => string | undefined = useCallback(
    buildDefaultValidator(contextVariables, setErrorsInfo),
    [contextVariables]
  );

  const { fieldState, fieldApi } = useField({
    ...props,
    name: fieldName,
    validate: validate ? combine([defaultValidator, validate]) : defaultValidator,
    validateOn: "submit"
  });

  const value = fieldState.value as ConditionJSON;
  const { error } = fieldState;
  const { setValue, setTouched, validate: validateInput } = fieldApi;

  useEffect(() => {
    if (error) {
      validateInput();
    }
  }, [value, error]);

  const onBlurHandler = (event: ChangeEvent<HTMLDivElement>) => {
    setTouched(true);
  };

  const updateCondition: UpdateCondition = async (
    conditionPath: string,
    updateType:
      | "initialize"
      | "clear"
      | "operator"
      | "variable"
      | "comparator"
      | "addCompositionOperator"
      | "updateCompositionOperator",
    updateValue?: UpdateConditionValue
  ) => {
    if (updateType === "initialize") {
      if (!value) {
        setValue(INITIAL_CONDITION_VALUE);
        return;
      }
      const fieldValueClone = cloneDeep(value);
      const existingCondition = get(fieldValueClone, conditionPath, fieldValueClone); // defaults to root condition
      const operatorType = Object.keys(existingCondition)[0] || PLACEHOLDER_OPERATOR.value;

      const existingValues = (existingCondition as ConditionJSON)[operatorType];

      const newCondition = {
        [operatorType]: [...existingValues, INITIAL_CONDITION_VALUE]
      };

      const finalVal = conditionPath
        ? set(
            {
              ...fieldValueClone
            },
            conditionPath,
            newCondition
          )
        : newCondition;
      setValue(finalVal);
    }

    if (updateType === "clear") {
      // clear condition
      const fieldValueClone = cloneDeep(value);
      const newValue: ConditionJSON | undefined = conditionPath
        ? set(
            {
              ...fieldValueClone
            },
            conditionPath,
            undefined
          )
        : undefined;

      const cleanedNewValue = newValue ? cleanConditionsRecursively(newValue) : undefined;

      setValue(cleanedNewValue);
    }

    if (updateType === "variable") {
      const fieldValueClone: ConditionJSON = cloneDeep(value);
      const existingCondition = get(fieldValueClone, conditionPath, fieldValueClone); // defaults to root condition

      const operatorType = Object.keys(existingCondition)[0] || "";
      const newConditionItems = [updateValue];

      const newValue = { ...(existingCondition as ConditionJSON) };
      delete newValue[operatorType];

      const newCondition: ConditionJSON = {
        ...newValue,
        [PLACEHOLDER_OPERATOR.value]: newConditionItems
      };

      const finalVal = conditionPath
        ? set(
            {
              ...fieldValueClone
            },
            conditionPath,
            newCondition
          )
        : newCondition;
      setValue(finalVal);
    }

    if (updateType === "operator") {
      const fieldValueClone = cloneDeep(value);
      const existingCondition = get(fieldValueClone, conditionPath, fieldValueClone); // defaults to root condition
      const operatorType = Object.keys(existingCondition)[0] || "";
      const existingConditionItems = operatorType
        ? (existingCondition as ConditionJSON)[operatorType]
        : [];

      const newCondition: ConditionJSON = {
        ...(existingCondition as ConditionJSON),
        [updateValue]: existingConditionItems?.[0] ? [existingConditionItems[0]] : [] // keep variable value, clear comparator value
      };
      delete newCondition[operatorType];

      const finalVal = conditionPath
        ? set(
            {
              ...fieldValueClone
            },
            conditionPath,
            newCondition
          )
        : newCondition;
      setValue(finalVal);
    }

    if (updateType === "comparator") {
      const fieldValueClone = cloneDeep(value);
      const existingCondition = get(fieldValueClone, conditionPath, fieldValueClone); // defaults to root condition
      const operatorType = Object.keys(existingCondition)[0] || "";
      const existingConditionItems = operatorType
        ? (existingCondition as ConditionJSON)[operatorType]
        : [];

      const newConditionItems = [...existingConditionItems];
      newConditionItems[1] = updateValue;
      const newCondition: ConditionJSON = {
        ...(existingCondition as ConditionJSON),
        [operatorType]: newConditionItems
      };

      const finalVal = conditionPath
        ? set(
            {
              ...fieldValueClone
            },
            conditionPath,
            newCondition
          )
        : newCondition;
      setValue(finalVal);
    }

    if (updateType === "updateCompositionOperator") {
      const fieldValueClone = cloneDeep(value);
      const existingCondition = get(fieldValueClone, conditionPath, fieldValueClone); // defaults to root condition
      const operatorType = getOperatorType(existingCondition as ConditionJSON);
      const existingConditionItems = operatorType
        ? (existingCondition as ConditionJSON)[operatorType]
        : [];

      const newCondition: ConditionJSON = {
        [updateValue]: existingConditionItems
      };

      const finalVal = conditionPath
        ? set(
            {
              ...fieldValueClone
            },
            conditionPath,
            newCondition
          )
        : newCondition;
      setValue(finalVal);
    }

    if (updateType === "addCompositionOperator") {
      const fieldValueClone = cloneDeep(value);
      const existingCondition = get(fieldValueClone, conditionPath, fieldValueClone); // defaults to root condition
      const operator = updateValue || "and";
      const newCondition = { [operator]: [existingCondition, INITIAL_CONDITION_VALUE] };

      const finalVal = conditionPath
        ? set(
            {
              ...fieldValueClone
            },
            conditionPath,
            newCondition
          )
        : newCondition;
      setValue(finalVal);
    }
  };

  const errorsToDisplay = errorsInfo.slice(0, 1);

  return (
    <div onBlur={onBlurHandler}>
      <BaseInput
        error={error as string}
        fieldName={fieldName}
        label={label}
        showField={showField}
        info={info}
        infoStatus={infoStatus}
        warningMessage={warningMessage}
      >
        {!value ? (
          <Button inline disabled={disabled} onClick={() => updateCondition("", "initialize")}>
            <Plus size={18} /> condition
          </Button>
        ) : (
          <Condition
            conditionPath=""
            condition={value as ConditionJSON}
            contextVariables={contextVariables}
            updateCondition={updateCondition}
            errorsInfo={errorsToDisplay}
            disabled={disabled}
          />
        )}
      </BaseInput>
    </div>
  );
};

export default JsonLogicInput;
