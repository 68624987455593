import React, { useState } from "react";
import { connect } from "react-redux";

import Heading from "../../../ui/Heading";
import Text from "../../../ui/Text";
import { FloatModal } from "../../../ui/Modal";
import Button from "../../../ui/Button";
import { CheckboxInputBase, BaseInput } from "../../../ui/Input";

import { closeModal as closeModalAction } from "../../../../actions";

import styles from "./index.module.scss";

type PropsType = {
  title?: string;
  confirmButtonText?: string;
  warningText?: string | null;
  content: React.ReactNode;
  confirmButtonVariant?: "danger";
  confirmationText?: string;
  mustCheckConfirm?: boolean;
  closeModal: () => void;
  onConfirmation: () => void;
};

const ConfirmationModal = ({
  title = "Are you sure?",
  confirmButtonText = "Yes, I am sure!",
  warningText = null,
  content,
  confirmButtonVariant,
  confirmationText,
  mustCheckConfirm,
  closeModal,
  onConfirmation
}: PropsType) => {
  const [confirmed, setConfirmed] = useState<boolean>(false);
  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L">{title}</Heading>
      {content}
      <Text size="M" className={styles.Warning}>
        {warningText}
      </Text>
      {mustCheckConfirm && (
        <div className={styles.ConfirmCheck}>
          <BaseInput fieldName="confirmed">
            <CheckboxInputBase
              fieldName="confirmed"
              label={confirmationText}
              onChange={() => {
                setConfirmed(!confirmed);
              }}
            />
          </BaseInput>
          <hr className={styles.Divider} />
        </div>
      )}
      <div className={styles.ButtonGroup}>
        <Button inline onClick={closeModal}>
          Cancel
        </Button>
        <Button
          id="confirmButton"
          onClick={() => {
            onConfirmation();
            closeModal();
          }}
          disabled={mustCheckConfirm && !confirmed}
          danger={confirmButtonVariant === "danger"}
        >
          {confirmButtonText}
        </Button>
      </div>
    </FloatModal>
  );
};

export default connect(null, {
  closeModal: closeModalAction
})(ConfirmationModal);
