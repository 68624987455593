import React from "react";

type PropsType = {
  size?: number;
};

const Paragraph = ({ size = 24 }: PropsType) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={`${size}px`}
      height={`${size}px`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5h18a1 1 0 110 2H3a1 1 0 010-2zm0 4h14a1 1 0 110 2H3a1 1 0 110-2zm18 4H3a1 1 0 100 2h18a1 1 0 100-2zM3 17h14a1 1 0 110 2H3a1 1 0 110-2z"
        fill="#494B50"
      />
    </svg>
  );
};

export default Paragraph;
