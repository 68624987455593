import React, { useEffect, useContext, useMemo, useState } from "react";
import { connect } from "react-redux";

import Heading from "../../../ui/Heading";
import { FullScreenModal } from "../../../ui/Modal";
import Button from "../../../ui/Button";
import Loader from "../../../ui/Loader";

import { Form } from "../../../ui/Input";
import FormContent from "./FormContent";

import { UserContext } from "../../../providers/UserProvider";
import {
  closeModal as closeModalAction,
  fetchReasons as fetchReasonsAction,
  fetchLocations as fetchLocationsAction,
  fetchPractitioners as fetchPractitionersAction,
  updateOrgSettings as updateOrgSettingsAction,
  fetchBookingConfiguration as fetchBookingConfigurationAction
} from "../../../../actions";

import { globalFormValidator, FormState } from "./formValidator";
import useChatContextVariables from "../../../../hooks/useChatContextVariables";

import { SettingNames } from "../../../../constants";
import {
  BookingReason,
  ReduxStateType,
  Reason,
  Setting,
  Location,
  Practitioner,
  ScheduleTag,
  ChatFlowsNodes
} from "../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  bookingReasons: BookingReason[] | null;
  schedules: ScheduleTag[] | null;
  bookingChatNodes: ChatFlowsNodes[];
  emrScheduleLocationMap?: { [scheduleId: string]: string };
  reasons: Array<Reason>;
  practitioners: Array<Practitioner>;
  locations: Array<Location>;
  loading: boolean;
  closeModal: () => void;
  fetchBookingConfiguration: () => void;
  fetchReasons: () => void;
  fetchPractitioners: () => void;
  fetchLocations: (organizationId: number) => void;
  updateOrgSettings: (organizationId: string, settings: Setting[]) => void;
};

const defaultInitialBookingReason: BookingReason = {
  excludedLocationIds: [],
  excludedPractitionerIds: [],
  cardText: "",
  note: "",
  duration: null,
  defaultEmrReasonId: "",
  isDefault: false,
  skipBooking: false,
  autoBookable: false,
  requiresPrimaryPractitioner: false,
  hideLocationOptions: true,
  hidePractitionerOptions: false,
  selectionConditions: null,
  includedScheduleTags: [],
  availabilityRangeEndWeeks: 4
};

const defaultInitialConfiguration = {
  bookingReasons: [{ ...defaultInitialBookingReason, isDefault: true, skipBooking: true }]
};

const EditBookingConfig = ({
  bookingReasons,
  schedules,
  bookingChatNodes,
  emrScheduleLocationMap,
  reasons,
  locations,
  practitioners,
  loading,
  closeModal,
  fetchBookingConfiguration,
  fetchReasons,
  fetchLocations,
  fetchPractitioners,
  updateOrgSettings
}: PropsType) => {
  const formInitializing = loading;
  const { organizationId } = useContext(UserContext);
  const [globalError, setGlobalError] = useState("");
  const initialFormState: FormState = useMemo(() => {
    const initFormState =
      !formInitializing && bookingReasons ? { bookingReasons } : { ...defaultInitialConfiguration };

    return {
      ...initFormState,
      bookingReasons: initFormState.bookingReasons.map((bookingReason: BookingReason) => ({
        ...bookingReason,
        excludedLocationIds: bookingReason.excludedLocationIds || [],
        excludedPractitionerIds: bookingReason.excludedPractitionerIds || [],
        includedScheduleTags: bookingReason.includedScheduleTags || [],
        availabilityRangeEndWeeks: bookingReason.availabilityRangeEndWeeks || 4
      }))
    };
  }, [formInitializing, bookingReasons]);

  const { contextVariables } = useChatContextVariables(bookingChatNodes);
  const [forceClose, setForceClose] = useState(false);

  useEffect(() => {
    if (organizationId) {
      fetchBookingConfiguration();
      fetchReasons();
      fetchLocations(organizationId);
      fetchPractitioners();
    }
  }, [organizationId]);

  const submitUpdate = (values: FormState) => {
    const parsedValues = {
      bookingReasons: values.bookingReasons.map((item) => {
        const returnItem = { ...item };
        returnItem.duration = Number(returnItem.duration);
        returnItem.availabilityRangeEndWeeks = Number(returnItem.availabilityRangeEndWeeks);
        if (returnItem.selectionConditions) {
          return {
            ...returnItem,
            selectionConditions:
              typeof returnItem.selectionConditions === "string"
                ? JSON.parse(returnItem.selectionConditions)
                : returnItem.selectionConditions
          };
        }
        return returnItem;
      })
    };

    if (organizationId) {
      const updateData: Setting[] = [
        {
          settingName: SettingNames.APPOINTMENT_BOOKING_CONFIG,
          settingValue: { ...parsedValues, emrScheduleLocationMap },
          organizationId
        }
      ];
      updateOrgSettings(organizationId.toString(), updateData);
    }
  };

  if (formInitializing) {
    return (
      <FullScreenModal isOpen onClose={closeModal} headerButton={<Button disabled>Save</Button>}>
        <Heading size="L" className={styles.Heading} component="h1">
          Booking Chat Configuration
        </Heading>
        <Loader screen />
      </FullScreenModal>
    );
  }

  return (
    <Form
      onSubmit={(formState) => {
        const values = formState.values as FormState;
        const error = globalFormValidator(values);
        if (error) {
          setGlobalError(error);
          setForceClose(false);
          return;
        }
        submitUpdate(values);
      }}
      initialValues={initialFormState}
    >
      <FormContent
        setForceClose={setForceClose}
        forceClose={forceClose}
        globalError={globalError}
        contextVariables={contextVariables}
        schedules={schedules}
        reasons={reasons}
        practitioners={practitioners}
        locations={locations}
        closeModal={closeModal}
      />
    </Form>
  );
};

const mapStateToProps = ({
  reasons,
  locations,
  practitioners,
  appointmentRequestsInfo
}: ReduxStateType) => {
  return {
    reasons: reasons.data,
    locations: locations.data,
    practitioners: practitioners.data,
    loading:
      !reasons.initialized ||
      locations.locationsFetchLoading ||
      practitioners.loading ||
      appointmentRequestsInfo.bookingConfigurationLoading,
    bookingReasons: appointmentRequestsInfo?.bookingConfiguration?.bookingReasons || null,
    schedules: appointmentRequestsInfo?.bookingConfiguration?.schedules || null,
    bookingChatNodes: appointmentRequestsInfo?.bookingConfiguration?.bookingChatNodes || [],
    emrScheduleLocationMap: appointmentRequestsInfo?.bookingConfiguration?.emrScheduleLocationMap
  };
};

export default connect(mapStateToProps, {
  closeModal: closeModalAction,
  fetchReasons: fetchReasonsAction,
  fetchLocations: fetchLocationsAction,
  fetchPractitioners: fetchPractitionersAction,
  updateOrgSettings: updateOrgSettingsAction,
  fetchBookingConfiguration: fetchBookingConfigurationAction
})(EditBookingConfig);
