import React from "react";

type PropsType = {
  size?: number;
  color?: string;
};

const Profile = ({ size = 24, color = "currentColor" }: PropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}px`}
      height={`${size}px`}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18ZM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12Zm11-5a1.667 1.667 0 1 0 0 3.333A1.667 1.667 0 0 0 12 7ZM8.333 8.667a3.667 3.667 0 1 1 7.333 0 3.667 3.667 0 0 1-7.333 0ZM8 17a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v.5a1 1 0 1 0 2 0V17a4 4 0 0 0-4-4h-4a4 4 0 0 0-4 4v.5a1 1 0 1 0 2 0V17Z"
        fill={color}
      />
    </svg>
  );
};

export default Profile;
