import React from "react";
import cx from "classnames";

import styles from "./index.module.scss";

type PropsType = {
  active?: boolean;
  children: React.ReactElement;
};

const StopClickPropagation = ({ active = true, children }: PropsType) => {
  if (!active) return children;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClickEvent = (e: any) => {
    e.stopPropagation();
  };

  return (
    <div className={cx(styles.StopClickPropagation)} onClick={handleClickEvent}>
      {children}
    </div>
  );
};

export default StopClickPropagation;
