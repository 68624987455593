import React from "react";
import { connect } from "react-redux";
import cx from "classnames";

import {
  updateAppointmentRequest as updateAppointmentRequestAction,
  UpdateAppointmentRequestData
} from "../../../../../actions";

import Dropdown from "../../../../ui/Dropdown";
import { StopClickPropagation } from "../../../../ui/TableGrid";
import Text from "../../../../ui/Text";
import { ResponsiveHide } from "../../../../ui/Responsive";
import {
  AppointmentRequestStatuses,
  BookingModes,
  appointmentRequestStatusConfigMap,
  apptRequestStatusOptions
} from "../../../../../constants";
import {
  AppointmentRequestsUpdatesLoading,
  TaskAppointmentRequestContext,
  ReduxStateType
} from "../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  appointmentRequestId?: number;
  context: TaskAppointmentRequestContext;
  updateAppointmentRequest: (
    id: number,
    update: UpdateAppointmentRequestData,
    onSuccess?: () => void
  ) => void;
  updatesLoading?: AppointmentRequestsUpdatesLoading;
  refetchTasks: (silent: boolean, fetchingForAssignedUser?: boolean) => void;
};

const listDisplay = (list: Array<string> | undefined, displayAmount: number = 2) => {
  if (!list) return "";

  const options = list.map((item) => item);

  let listString = "";

  let itemsToDisplay = options.splice(0, displayAmount);
  let remainingAmount = options.length;
  const firstLength = itemsToDisplay[0] ? itemsToDisplay[0].length : 0;
  const secondLength = itemsToDisplay[1] ? itemsToDisplay[1].length : 0;
  if (secondLength > 30) {
    remainingAmount += remainingAmount + 1;
    itemsToDisplay = itemsToDisplay.splice(0, 1);
  }
  if (firstLength > 30) {
    itemsToDisplay = [`${itemsToDisplay[0].substring(0, 29)}...`];
  }
  listString = itemsToDisplay.join(", ");

  if (remainingAmount) listString = listString.concat(` +${remainingAmount}`);

  return listString;
};

const combinedDetails = (context: TaskAppointmentRequestContext, addRemainingCount: boolean) => {
  let combinedString = "";

  if (context.locations && context.locations[0]) {
    combinedString += `${context.locations[0]}, `;
  }

  if (context.practitioners && context.practitioners[0]) {
    combinedString += `${context.practitioners[0].displayName}, `;
  }

  if (context.timeOfDay && context.timeOfDay[0]) {
    combinedString += `${context.timeOfDay[0]}`;
  }

  if (addRemainingCount) {
    const remainingCount = context.timeOfDay ? context.timeOfDay.length - 1 : null;
    if (remainingCount) combinedString += ` +${remainingCount}`;
  }

  return combinedString;
};

const AppointmentRequestContext = ({
  appointmentRequestId,
  context,
  updateAppointmentRequest,
  updatesLoading,
  refetchTasks
}: PropsType) => {
  const addRemainingCount =
    context.bookingMode === BookingModes.AVAILABLE_TIMES &&
    context.appointmentRequestStatus !== AppointmentRequestStatuses.BOOKED;
  return (
    <div
      className={cx(styles.TableCell, {
        [styles.TableCellRequest]: context.bookingMode === BookingModes.REQUEST
      })}
    >
      {context.bookingMode === BookingModes.AVAILABLE_TIMES ||
      context.bookingMode === BookingModes.AUTO_BOOK ? (
        <>
          <div className={styles.SubCell}>
            <Text className={styles.CellData} align="left" component="span" size="S">
              {context.reasons ? context.reasons.join(", ") : ""}
            </Text>
          </div>
          <ResponsiveHide hideOnTablet hideOnMobile>
            <div className={styles.SubCell}>
              <Text className={styles.CellData} align="left" component="span" size="S">
                {combinedDetails(context, addRemainingCount)}
              </Text>
            </div>
            <div className={cx(styles.SubCell, styles.SubCellCenter)}>
              <StopClickPropagation>
                <Dropdown
                  id="context-taskStage"
                  onChange={(selectedTaskStage) => {
                    if (appointmentRequestId) {
                      updateAppointmentRequest(
                        appointmentRequestId,
                        {
                          status: selectedTaskStage
                        },
                        () => {
                          refetchTasks(true);
                        }
                      );
                    }
                  }}
                  value={context.appointmentRequestStatus || "None"}
                  options={apptRequestStatusOptions}
                  configMap={appointmentRequestStatusConfigMap}
                  loading={
                    updatesLoading && appointmentRequestId
                      ? updatesLoading[appointmentRequestId]?.status
                      : undefined
                  }
                  size="S"
                />
              </StopClickPropagation>
            </div>
          </ResponsiveHide>
        </>
      ) : (
        <>
          <div className={styles.SubCell}>
            <Text className={styles.CellData} align="left" component="span" size="S">
              {context.reasons ? context.reasons.join(", ") : ""}
            </Text>
          </div>
          <ResponsiveHide hideOnTablet hideOnMobile>
            <div className={styles.SubCell}>
              <Text className={styles.CellData} align="left" component="span" size="S">
                {listDisplay(context.locations)}
              </Text>
            </div>
            <div className={styles.SubCell}>
              <Text className={styles.CellData} align="left" component="span" size="S">
                {listDisplay(context.timeOfDay)}
              </Text>
            </div>
            <div className={cx(styles.SubCell, styles.SubCellCenter)}>
              <StopClickPropagation>
                <Dropdown
                  id="context-taskStage"
                  onChange={(selectedTaskStage) => {
                    if (appointmentRequestId) {
                      updateAppointmentRequest(
                        appointmentRequestId,
                        {
                          status: selectedTaskStage
                        },
                        () => {
                          refetchTasks(true);
                        }
                      );
                    }
                  }}
                  value={context.appointmentRequestStatus || "None"}
                  options={apptRequestStatusOptions}
                  configMap={appointmentRequestStatusConfigMap}
                  loading={
                    updatesLoading && appointmentRequestId
                      ? updatesLoading[appointmentRequestId]?.status
                      : undefined
                  }
                  size="S"
                />
              </StopClickPropagation>
            </div>
          </ResponsiveHide>
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ appointmentRequestsInfo }: ReduxStateType) => {
  return {
    updatesLoading: appointmentRequestsInfo.updatesLoading
  };
};

export default connect(mapStateToProps, {
  updateAppointmentRequest: updateAppointmentRequestAction
})(AppointmentRequestContext);
