import React, { useEffect, useMemo } from "react";

import { connect } from "react-redux";
import { jwtDecode } from "jwt-decode";
import Heading from "../../../../ui/Heading";
import Text from "../../../../ui/Text";

import { useQueryString } from "../../../../../utils/queryStringHelpers";
import { CreateScribeSubscriptionPlan } from "../../../../common/Modals";
import Card, { CardSection } from "../../../../ui/Card";
import { BillingConfigSetting, ReduxStateType, ChargeOverProductPlan } from "../../../../../types";
import {
  fetchBillingConfiguration as fetchBillingConfigurationAction,
  fetchProductList as fetchProductListAction
} from "../../../../../actions";
import getToken from "../../../../../utils/getToken";
import { capitalize } from "../../../../../utils/stringTransformations";
import { ExternalLink } from "../../../../ui/Icon";
import Loader from "../../../../ui/Loader";

import styles from "./index.module.scss";

type PropsType = {
  billingConfig?: BillingConfigSetting;
  fetchBillingConfiguration: (organizationId: string) => void;
  billingLoading?: boolean;
  fetchProductList: (organizationId: string) => void;
  productsLoading?: boolean;
  products?: ChargeOverProductPlan[];
};

const Billing = ({
  billingConfig,
  fetchBillingConfiguration,
  billingLoading,
  fetchProductList,
  productsLoading,
  products
}: PropsType) => {
  const token = getToken();
  const decodedToken: { userId: string; organizationId: string } | null = token
    ? jwtDecode(token)
    : null;
  const currentUserId = decodedToken?.userId;
  const organizationId = decodedToken?.organizationId || "";

  useEffect(() => {
    fetchBillingConfiguration(organizationId.toString());
    fetchProductList(organizationId.toString());
  }, []);

  const scribeSubscriptions = useMemo(() => {
    return billingConfig && billingConfig?.subscriptions ? billingConfig.subscriptions : [];
  }, [billingConfig]);

  const userSubscription =
    scribeSubscriptions?.find((subscription) => subscription.userId === currentUserId) ||
    scribeSubscriptions?.find((subscription) => subscription.userId === null);

  const userPlan = products?.find((product) =>
    userSubscription?.lineItems?.some((lineItem) => lineItem.productKey === product.key)
  );

  const { parsed } = useQueryString();

  return (
    <>
      <Card>
        <CardSection title="Plan">
          <Heading size="META">Plan</Heading>
          {billingLoading || productsLoading ? (
            <Loader center small />
          ) : (
            <>
              <Text className={styles.TextMarginBottom}>
                {userPlan?.name || "No plan selected"}
              </Text>
              {userPlan && (
                <>
                  <div className={styles.TextMarginBottom}>
                    <Heading size="META">Description</Heading>
                    {userPlan?.description}
                  </div>
                  <div className={styles.CardRow}>
                    <div>
                      <Heading size="META">Rate</Heading>${userPlan?.rate?.toFixed(2)}/provider/
                      {userPlan?.payCycleUnit}
                    </div>
                    <div>
                      <Heading size="META">Renews</Heading>
                      {capitalize(userPlan?.payCycleUnit as string)}ly
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </CardSection>
        {userPlan ? (
          <>
            <Text>
              Plans renew automatically until you cancel. To make changes to your
              subscription,&nbsp;
              <a
                type="button"
                href="https://mikatahealth.com/feedback/"
                target="_blank"
                className={styles.InlineLink}
                rel="noreferrer"
              >
                contact us <ExternalLink size={16} />
              </a>
            </Text>
          </>
        ) : (
          <>
            <Text>
              Questions? Please&nbsp;
              <a
                type="button"
                href="https://mikatahealth.com/feedback/"
                target="_blank"
                className={styles.InlineLink}
                rel="noreferrer"
              >
                contact us <ExternalLink size={16} />
              </a>
            </Text>
          </>
        )}
      </Card>
      <CreateScribeSubscriptionPlan isModalOpen={parsed?.form === "scribeSignUp"} />
    </>
  );
};

const mapStateToProps = ({ billing }: ReduxStateType) => {
  return {
    billingConfig: billing.billing?.setting?.settingValue as BillingConfigSetting,
    billingLoading: billing.billingLoading,
    products: billing.products,
    productsLoading: billing.productsLoading
  };
};

export default connect(mapStateToProps, {
  fetchBillingConfiguration: fetchBillingConfigurationAction,
  fetchProductList: fetchProductListAction
})(Billing);
