import React from "react";
import { connect } from "react-redux";

import Button from "../Button";

import ContentRenderEngine from "./ContentRenderEngine";
import { createTextReport } from "./helpers";

import { EMRFeatures } from "../../../constants";

import { Report, ReduxStateType } from "../../../types";

import styles from "./index.module.scss";

type PropsType = {
  report: Report;
  isIntegrated?: boolean;
  emrFeatures?: string[];
  syncReport?: (reportId: number) => void;
  syncLoading?: boolean;
};

const ReportCard = ({ report, isIntegrated, emrFeatures, syncReport, syncLoading }: PropsType) => {
  const canPushTextReport =
    emrFeatures &&
    emrFeatures.some(
      (feature: string) =>
        feature === EMRFeatures.PUSH_RTF_REPORT || feature === EMRFeatures.PUSH_HTML_REPORT
    );
  const copyToClipboard = () => {
    const reportString = report ? createTextReport(report.content) : "";
    navigator.clipboard.writeText(reportString);
  };
  return (
    <div id={`reportCard-${report.id}`} key={report.id} className={styles.Report}>
      <ContentRenderEngine content={report.content} />
      <div className={styles.ReportFooter}>
        {isIntegrated && canPushTextReport && syncReport && (
          <Button secondary size="XS" onClick={() => syncReport(report.id)} disabled={syncLoading}>
            Sync
          </Button>
        )}
        <Button secondary size="XS" onClick={copyToClipboard}>
          Copy
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = ({ organizationData }: ReduxStateType) => {
  return {
    isIntegrated: organizationData.organizationData
      ? organizationData.organizationData.integrated
      : false,
    emrFeatures: organizationData.organizationData
      ? organizationData.organizationData.emrFeatures
      : []
  };
};

export default connect(mapStateToProps, null)(ReportCard);
