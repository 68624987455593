import React, { useEffect, useState, useContext, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import cx from "classnames";
import moment from "moment-timezone";
import debounce from "lodash/debounce";

import { UserContext } from "../../../providers/UserProvider";
import TableGrid, {
  SortByValue,
  SortByDirection,
  StopClickPropagation
} from "../../../ui/TableGrid";
import Loader from "../../../ui/Loader";
import Text from "../../../ui/Text";
import Heading from "../../../ui/Heading";
import Button from "../../../ui/Button";
import { Chat, EmptyContentImage, Filter } from "../../../ui/Icon";
import Dropdown from "../../../ui/Dropdown";
import DateRangeSelector, { DateRangePresetType } from "../../../ui/DateRangeSelector";
import Search from "../../../ui/Search";
import LocationSelector from "./LocationSelector";

import AppointmentDetailsManager from "./AppointmentDetailsManager";

import AddUnintegratedAppointmentButton from "./AddUnintegratedAppointmentButton";
import { getTotalCount, getReasonLabel } from "./helpers";
import { DetailsCell, ChatStatus, Start, ScribeStatus } from "./TableCells";

import useInterval from "../../../../hooks/useInterval";

import { updateQueryString, useQueryString } from "../../../../utils/queryStringHelpers";
import formatPhone from "../../../../utils/formatPhone";

import {
  fetchAppointments as fetchAppointmentsAction,
  FetchAppointmentFilters,
  AppointmentFetchData,
  updateAppointment as updateAppointmentAction,
  fetchLocations as fetchLocationsAction,
  fetchPractitioners as fetchPractitionersAction,
  UpdateAppointmentData,
  sendParkingLotReady as sendParkingLotReadyAction,
  SendParkingLotReadyData,
  fetchReasons as fetchReasonsAction,
  openModal as openModalAction,
  OpenModal,
  closeModal
} from "../../../../actions";

import {
  ALL,
  AppointmentCheckedInStatuses,
  AppointmentStates,
  ModalTypes,
  UserTypeConstants
} from "../../../../constants";

import {
  Location,
  ReduxStateType,
  Option,
  AppointmentUpdatesLoading,
  Practitioner,
  Reason,
  AppointmentCheckedInStatus
} from "../../../../types";

import styles from "./index.module.scss";
import { ResponsiveHide } from "../../../ui/Responsive";

type PropsType = {
  appointmentFetchData?: AppointmentFetchData;
  locations: Array<Location>;
  practitioners: Array<Practitioner>;
  loading: boolean;
  locationLoading: boolean;
  updatesLoading: AppointmentUpdatesLoading;
  showCheckinStatus: boolean;
  reasons: Array<Reason>;
  fetchAppointments: (filters: FetchAppointmentFilters, options?: { silent: boolean }) => void;
  fetchLocations: (organizationId: number) => void;
  fetchPractitioners: () => void;
  updateAppointment: (data: UpdateAppointmentData, id: number, onSuccess?: () => void) => void;
  sendParkingLotReady: (data: SendParkingLotReadyData) => void;
  fetchReasons: () => void;
  openModal: OpenModal;
};
const getSortByForFetch = (sortBy: SortByValue) => {
  if (!sortBy) return "start";

  const columnNameMap: { [key: string]: string } = {
    patientName: "patientLastName"
  };

  return columnNameMap[sortBy.columnName] ? columnNameMap[sortBy.columnName] : sortBy.columnName;
};

const DEFAULT_APPOINTMENTS_PER_PAGE = 25;
const POLL_INTERVAL_MS =
  parseInt(process.env.REACT_APP_APPOINTMENTS_POLLING_INTERVAL || "", 10) || 60 * 1000;
const DEBOUNCE_LIMIT_MILLISECONDS = 1500;

const AppointmentsTable = ({
  appointmentFetchData,
  locations,
  practitioners,
  loading,
  locationLoading,
  updatesLoading,
  showCheckinStatus,
  reasons,
  fetchAppointments,
  fetchLocations,
  fetchPractitioners,
  updateAppointment,
  sendParkingLotReady,
  fetchReasons,
  openModal
}: PropsType): JSX.Element => {
  const { parsed } = useQueryString();
  const [searchParams, setSearchParams] = useSearchParams();
  const { organizationId, providerId, userType } = useContext(UserContext);
  const isProvider = !!providerId;
  const activeStatusLocalStorage = localStorage.getItem("appointmentFilters_activeStatus");
  const includeFilteredOutStorage = localStorage.getItem("appointmentFilters_includeFilteredOut");
  const activeCheckinStatusLocalStorage = localStorage.getItem(
    "appointmentFilters_activeCheckinStatus"
  );
  const locationIdsLocalStorage = localStorage.getItem("appointmentFilters_locationIds");
  const practitionerIdsLocalStorage = localStorage.getItem("appointmentFilters_practitionerIds");
  const reasonIdsLocalStorage = localStorage.getItem("appointmentFilters_reasonIds");
  const rowsPerPageLocalStorage = localStorage.getItem("appointmentFilters_rowsPerPage");
  const searchValueLocalStorage = localStorage.getItem("appointmentFilters_searchValue");

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(
    parsed.startDay
      ? moment.tz(parsed.startDay, timeZone).toDate()
      : moment().startOf("day").toDate()
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(
    parsed.endDay ? moment.tz(parsed.endDay, timeZone).toDate() : moment().endOf("day").toDate()
  );
  const [selectedActiveStatusFilters, setSelectedActiveStatusFilters] = useState(
    activeStatusLocalStorage || ALL
  );
  const [includeFilteredOut, setIncludeFilteredOut] = useState(
    Boolean(includeFilteredOutStorage) || false
  );
  const [selectedActiveCheckinStatusFilters, setSelectedActiveCheckinStatusFilters] =
    useState<string>(activeCheckinStatusLocalStorage || ALL);

  const [selectedLocations, setSelectedLocations] = useState<string>(locationIdsLocalStorage || "");
  const [selectedPractitioners, setSelectedPractitioners] = useState<string>(
    practitionerIdsLocalStorage || ""
  );
  const selectedPractitionersData: Array<Practitioner> = [];
  if (selectedPractitioners) {
    selectedPractitioners.split(",").forEach((pracIdStr) => {
      const pracObject = practitioners.find((prac) => prac.id === parseInt(pracIdStr, 10));
      if (pracObject) selectedPractitionersData.push(pracObject);
    });
  }
  const [selectedReasons, setSelectedReasons] = useState<string>(reasonIdsLocalStorage || "");

  const [searchValue, setSearchValue] = useState<string>(searchValueLocalStorage || "");

  const [page, setPage] = useState<number>(parsed.page ? Number.parseInt(parsed.page, 10) : 1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    rowsPerPageLocalStorage
      ? Number.parseInt(rowsPerPageLocalStorage, 10)
      : DEFAULT_APPOINTMENTS_PER_PAGE
  );

  const [sortBy, setSortBy] = useState<SortByValue>(
    parsed.sortBy && parsed.sortByDirection
      ? {
          columnName: parsed.sortBy,
          direction: parsed.sortByDirection as SortByDirection
        }
      : null
  );
  const isDetailsModalOpen = Boolean(parsed.appointmentId);
  const appointments = appointmentFetchData?.appointments || [];

  const selectedAppointment =
    appointments && parsed.appointmentId
      ? appointments.find((appt) => appt.id.toString() === parsed.appointmentId)
      : undefined;

  const showProviderTaskStatus = userType === UserTypeConstants.PRACTITIONER;

  const headers = [
    { colName: "start", content: "Time", sortable: true },
    { colName: "locationName", content: "Location", sortable: true },
    { colName: "practitionerName", content: "Provider", sortable: true },
    { colName: "patientName", content: "Patient", sortable: true },
    { colName: "reason", content: "Reason", sortable: true },
    { colName: "status", content: "Appt. Status", sortable: true },
    { colName: "chatStatus", content: "Chats" },
    { colName: "details", content: "" }
  ];

  const mobileHeaders = [
    {
      colName: "start",
      childColNames: ["start", "patientName", "scribeStatus"],
      content: "Appointment",
      sortable: false
    },
    {
      colName: "practitionerName",
      content: "Provider",
      sortable: false
    }
  ];

  const tabletHeaders = [
    {
      colName: "start",
      childColNames: ["start", "scribeStatus"],
      content: "Time",
      sortable: false
    },
    { colName: "locationName", content: "Location", sortable: true },
    { colName: "practitionerName", content: "Provider", sortable: true },
    { colName: "patientName", content: "Patient", sortable: true },
    { colName: "chatStatus", content: "Chats" },
    { colName: "details", content: "" }
  ];

  if (showCheckinStatus) {
    headers.splice(6, 0, {
      colName: "checkedIn",
      content: "Check-in",
      sortable: true
    });

    if (!isProvider && moment(parsed.startDay).isSame(moment(), "day")) {
      headers.splice(7, 0, { colName: "parkingLotNotify", content: "" });
    }
  }

  if (showProviderTaskStatus) {
    // add after "chatStatus" column
    const indexOfChatColumn = headers.findIndex((header) => header.colName === "chatStatus");
    headers.splice(indexOfChatColumn + 1, 0, {
      colName: "scribeStatus",
      content: "Scribe",
      sortable: false
    });
  }

  const locationDropdownOptions: Array<Option> = locations
    .filter((location) => (includeFilteredOut ? true : location.active === true))
    .map((location) => ({
      label: location.displayName || location.fullName,
      value: location.id.toString()
    }))
    .sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
  const practitionerDropdownOptions: Array<Option> = practitioners
    ? practitioners
        .filter((prac) => (includeFilteredOut ? true : !!prac.practitionerActive))
        .map((prac) => {
          return { label: prac.displayName || prac.fullName, value: prac.id.toString() };
        })
    : [];
  const reasonDropdownOptions: Array<Option> = reasons
    ? reasons
        .filter((reason) => (includeFilteredOut ? true : !!reason.active))
        .map((reason) => {
          return { label: getReasonLabel(reason), value: reason.id.toString() };
        })
    : [];
  const statusFilterOptions = [
    {
      label: "See All",
      value: ALL
    },
    {
      label: AppointmentStates.COMPLETE.label,
      value: AppointmentStates.COMPLETE.name
    },
    {
      label: AppointmentStates.CONFIRMED.label,
      value: AppointmentStates.CONFIRMED.name
    },
    {
      label: AppointmentStates.NEED_CONFIRM.label,
      value: AppointmentStates.NEED_CONFIRM.name
    },
    {
      label: AppointmentStates.NONE.label,
      value: AppointmentStates.NONE.name
    },
    {
      label: AppointmentStates.PENDING.label,
      value: AppointmentStates.PENDING.name
    },
    {
      label: AppointmentStates.CONTACTED.label,
      value: AppointmentStates.CONTACTED.name
    },
    {
      label: AppointmentStates.RESCHEDULE.label,
      value: AppointmentStates.RESCHEDULE.name
    },
    {
      label: AppointmentStates.UNCLEAR.label,
      value: AppointmentStates.UNCLEAR.name
    },
    {
      label: AppointmentStates.ON_HOLD.label,
      value: AppointmentStates.ON_HOLD.name
    }
  ];

  const checkinFilterOptions = [
    {
      label: "See All",
      value: ALL
    },
    {
      label: "Checked In",
      value: AppointmentCheckedInStatus.CHECKED_IN
    },
    {
      label: "Staff Contacted",
      value: AppointmentCheckedInStatus.STAFF_CONTACTED
    },
    {
      label: "Not Yet",
      value: "not_yet"
    }
  ];

  const startDay = moment(selectedStartDate).toISOString(true);
  const endDay = moment(selectedEndDate).toISOString(true);

  const appointmentFilters: FetchAppointmentFilters | null =
    organizationId && selectedStartDate
      ? {
          organizationId,
          startDay,
          endDay,
          pageSize: rowsPerPage,
          locationIds: selectedLocations,
          practitionerIds: selectedPractitioners,
          reasonIds: selectedReasons,
          activeStatuses: selectedActiveStatusFilters,
          includeFilteredOut,
          activeCheckinStatuses: selectedActiveCheckinStatusFilters,
          searchValue,
          currentPage: page - 1,
          sortBy: getSortByForFetch(sortBy),
          sortByDirection: sortBy ? sortBy.direction : undefined
        }
      : null;

  // start interval for silent re-fetching of appointments
  if (!(process.env.REACT_APP_SUPPRESS_POLLING === "true")) {
    const now = moment.tz(new Date(), timeZone).toDate();

    useInterval(
      () => {
        if (appointmentFilters && !searchValue && selectedEndDate && selectedEndDate > now) {
          fetchAppointments(appointmentFilters, { silent: true });
        } else {
          return null;
        }
      },
      [
        selectedStartDate,
        selectedEndDate,
        selectedActiveStatusFilters,
        includeFilteredOut,
        selectedActiveCheckinStatusFilters,
        selectedLocations,
        selectedPractitioners,
        selectedReasons,
        searchValue,
        sortBy,
        page,
        rowsPerPage
      ],
      POLL_INTERVAL_MS
    );
  }

  // fetch locations & practitioners on first load
  useEffect(() => {
    if (organizationId) {
      fetchLocations(organizationId);
      fetchPractitioners();
      fetchReasons();
    }
  }, [organizationId]);

  // fetch appointments on filter changes (except patient search)
  useEffect(() => {
    if (appointmentFilters && appointmentFilters.endDay) {
      fetchAppointments(appointmentFilters);
    }
  }, [
    selectedStartDate,
    selectedEndDate,
    selectedActiveStatusFilters,
    includeFilteredOut,
    selectedActiveCheckinStatusFilters,
    selectedLocations,
    selectedPractitioners,
    selectedReasons,
    sortBy,
    page,
    rowsPerPage
  ]);

  // fetch appointments on patient search
  const debouncedFetch = useCallback(debounce(fetchAppointments, DEBOUNCE_LIMIT_MILLISECONDS), []);

  // update query string on filter changes
  useEffect(() => {
    if (appointmentFilters && appointmentFilters.endDay) {
      updateQueryString(
        {
          startDay: appointmentFilters.startDay,
          endDay: appointmentFilters.endDay,
          sortBy: appointmentFilters.sortBy,
          sortByDirection: appointmentFilters.sortByDirection,
          locationIds: appointmentFilters.locationIds,
          practitionerIds: appointmentFilters.practitionerIds,
          reasonIds: appointmentFilters.reasonIds,
          activeStatuses: appointmentFilters.activeStatuses,
          activeCheckinStatuses: appointmentFilters.activeCheckinStatuses,
          searchValue: appointmentFilters.searchValue,
          page: page.toString(),
          pageRows: rowsPerPage.toString()
        },
        setSearchParams
      );
    }
  }, [
    selectedStartDate,
    selectedEndDate,
    selectedActiveStatusFilters,
    selectedActiveCheckinStatusFilters,
    selectedLocations,
    selectedPractitioners,
    selectedReasons,
    searchValue,
    sortBy,
    page,
    rowsPerPage
  ]);

  // update localStorage on location/status/practitioner/reason filter changes
  useEffect(() => {
    if (appointmentFilters) {
      localStorage.setItem("appointmentFilters_activeStatus", selectedActiveStatusFilters);
      localStorage.setItem(
        "appointmentFilters_activeCheckinStatus",
        selectedActiveCheckinStatusFilters
      );
      localStorage.setItem("appointmentFilters_locationIds", selectedLocations);
      localStorage.setItem("appointmentFilters_practitionerIds", selectedPractitioners);
      localStorage.setItem("appointmentFilters_reasonIds", selectedReasons);
      localStorage.setItem("appointmentFilters_searchValue", searchValue);
      localStorage.setItem("appointmentFilters_rowsPerPage", rowsPerPage.toString());

      if (includeFilteredOut) {
        localStorage.setItem("appointmentFilters_includeFilteredOut", "true");
      } else {
        localStorage.removeItem("appointmentFilters_includeFilteredOut");
      }
    }
  }, [
    selectedActiveStatusFilters,
    includeFilteredOut,
    selectedActiveCheckinStatusFilters,
    selectedLocations,
    selectedPractitioners,
    selectedReasons,
    searchValue,
    rowsPerPage
  ]);

  const openDetailsModal = (appointmentId: string): void => {
    updateQueryString({ appointmentId }, setSearchParams);
  };

  const closeDetailsModal = (fetchApptsOnModalClose: boolean): void => {
    updateQueryString({ appointmentId: undefined }, setSearchParams);
    if (appointmentFilters && fetchApptsOnModalClose) {
      fetchAppointments(appointmentFilters, { silent: true });
    }
  };

  const onLocationsChange = (locationIds: string) => {
    setPage(1);
    setSelectedLocations(locationIds);
  };

  const onPractitionerChange = (practitionerIds: string) => {
    setPage(1);
    setSelectedPractitioners(practitionerIds);
  };

  const onReasonsChange = (reasonIds: string) => {
    setPage(1);
    setSelectedReasons(reasonIds);
  };

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    setPage(1);
    if (dates[1] === null) {
      setSelectedStartDate(dates[0]);
      setSelectedEndDate(dates[1]);
    } else {
      let start = moment(dates[0]);
      let end = moment(dates[1]);
      if (start.isSame(end)) {
        // Date likely selected by clicking the date twice in the calendar
        start = start.startOf("day");
        end = end.endOf("day");
      }
      setSelectedStartDate(start.toDate());
      setSelectedEndDate(end.toDate());
    }
  };

  const handleActiveStatusFilterChange = (filterValue: string) => {
    setPage(1);
    setSelectedActiveStatusFilters(filterValue);
  };

  const handleIncludeFilteredOutChange = (filterValue: boolean) => {
    setPage(1);
    setIncludeFilteredOut(filterValue);
  };

  const handleActiveCheckinStatusFilterChange = (filterValue: string) => {
    setPage(1);
    setSelectedActiveCheckinStatusFilters(filterValue);
  };

  const handleSortChange = (sortByValue: SortByValue) => {
    setPage(1);
    setSortBy(sortByValue);
  };

  const handleRowsPerPageChange = (rowsPerPageSelection: number) => {
    setPage(1);
    setRowsPerPage(rowsPerPageSelection);
  };

  const onSearchClear = () => {
    setSearchValue("");
  };

  const onSearchChange = (searchValue: string) => {
    setSearchValue(searchValue);
    setPage(1);

    debouncedFetch({
      ...(appointmentFilters as FetchAppointmentFilters),
      searchValue
    });
  };

  const handleStatusSelect = (appointmentId: number, status: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateAppointment({ status: status as any }, appointmentId);
  };

  const handleCheckedInSelect = (appointmentId: number, status: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateAppointment({ checkedIn: status as any }, appointmentId, () => {
      if (appointmentFilters) {
        fetchAppointments(appointmentFilters, { silent: true });
      }
    });
  };

  const selectedPractitionersArr = selectedPractitioners
    ? selectedPractitioners.split(",").filter((practitionerId) => {
        return practitionerDropdownOptions.find(
          (practitionerOption) => practitionerOption.value.toString() === practitionerId
        );
      })
    : [];

  const selectedReasonsArr = selectedReasons
    ? selectedReasons.split(",").filter((reasonId) => {
        return reasonDropdownOptions.find(
          (reasonOption) => reasonOption.value.toString() === reasonId
        );
      })
    : [];

  const selectedActiveStatusFiltersArr = selectedActiveStatusFilters
    ? selectedActiveStatusFilters.split(",").filter((status) => {
        return statusFilterOptions.find((filterOption) => filterOption.value.toString() === status);
      })
    : [];

  const selectedActiveCheckinStatusFiltersArr = selectedActiveCheckinStatusFilters
    ? selectedActiveCheckinStatusFilters.split(",").filter((status) => {
        return checkinFilterOptions.find(
          (filterOption) => filterOption.value.toString() === status
        );
      })
    : [];

  const activeFilterCount =
    selectedPractitionersArr.length +
    selectedReasonsArr.length +
    selectedActiveStatusFiltersArr.filter((status) => status !== ALL).length +
    selectedActiveCheckinStatusFiltersArr.filter((status) => status !== ALL).length +
    (includeFilteredOut ? 1 : 0);

  const moreFiltersHeader =
    activeFilterCount > 0
      ? `(${activeFilterCount}) Filter${activeFilterCount > 1 ? "s" : ""} Applied`
      : "Filters";

  const rowData = !appointmentFetchData?.appointments
    ? []
    : appointmentFetchData.appointments.map((appointment) => {
        const checkedIn = appointment.checkedIn || AppointmentCheckedInStatuses.NONE.name;

        const { patientFirstName, patientLastName } = appointment;
        return {
          ...appointment,
          __onRowClick: () => {
            openDetailsModal(appointment.id.toString());
          },
          start: (
            <div id="openAppointmentDetails" className={styles.TableCell}>
              <Start appointment={appointment} />
            </div>
          ),
          locationName: (
            <div className={styles.TableCell}>
              <Text align="left" component="span" size="S">
                {appointment.locationName}
              </Text>
            </div>
          ),
          practitionerName: (
            <div className={styles.TableCell}>
              <Text align="left" component="span" size="S">
                {appointment.practitionerDisplayName || appointment.practitionerName}
              </Text>
            </div>
          ),
          patientName: (
            <div className={styles.TableCell}>
              <Text bold align="left" component="span" size="S">
                {/* Some EMR do not split first an last names.
                  In these cases the fist and last names are the
                  same and include both first and last names. */}
                {patientFirstName === patientLastName
                  ? patientFirstName
                  : `${patientFirstName} ${patientLastName}`}
              </Text>
              <Text align="left" component="span" size="XS">
                {formatPhone(
                  appointment.verifiedPhoneNumber ||
                    appointment.mobilePhone ||
                    appointment.homePhone
                )}
              </Text>
              <Text align="left" component="span" size="XS">
                {appointment.email}
              </Text>
              <Text align="left" component="span" size="XS">
                {appointment.healthcareIdentifier}
              </Text>
            </div>
          ),
          reason: (
            <div className={styles.TableCell}>
              <Text align="left" component="span" size="S">
                {appointment.reasonName}
              </Text>
            </div>
          ),
          status: (
            <StopClickPropagation>
              <div className={styles.TableCell}>
                <Dropdown
                  className={styles.DropdownStatus}
                  onChange={(status) => handleStatusSelect(appointment.id, status)}
                  value={appointment.status}
                  options={[
                    {
                      label: AppointmentStates.RESCHEDULE.label,
                      value: AppointmentStates.RESCHEDULE.name,
                      exclude: true
                    },
                    {
                      label: AppointmentStates.CONFIRMED.label,
                      value: AppointmentStates.CONFIRMED.name,
                      exclude: true
                    },
                    {
                      label: AppointmentStates.UNCLEAR.label,
                      value: AppointmentStates.UNCLEAR.name,
                      exclude: true
                    },
                    {
                      label: AppointmentStates.NEED_CONFIRM.label,
                      value: AppointmentStates.NEED_CONFIRM.name,
                      exclude: true
                    },
                    {
                      label: AppointmentStates.CONTACTED.label,
                      value: AppointmentStates.CONTACTED.name
                    },
                    {
                      label: AppointmentStates.COMPLETE.label,
                      value: AppointmentStates.COMPLETE.name,
                      exclude: true
                    },
                    {
                      label: AppointmentStates.NEEDS_CALL.label,
                      value: AppointmentStates.NEEDS_CALL.name,
                      exclude: true
                    },
                    {
                      label: AppointmentStates.PENDING.label,
                      value: AppointmentStates.PENDING.name,
                      exclude: appointment.status !== AppointmentStates.ON_HOLD.name
                    },
                    {
                      label: AppointmentStates.NONE.label,
                      value: AppointmentStates.NONE.name,
                      exclude: true
                    },
                    {
                      label: AppointmentStates.ON_HOLD.label,
                      value: AppointmentStates.ON_HOLD.name
                    }
                  ]}
                  configMap={{
                    RESCHEDULE: "yellow",
                    CONFIRMED: "blue",
                    UNCLEAR: "gray",
                    NEED_CONFIRM: "red",
                    CONTACTED: "green",
                    COMPLETE: "green",
                    NEEDS_CALL: "red",
                    PENDING: "gray",
                    NONE: "gray",
                    ON_HOLD: "yellow"
                  }}
                  loading={updatesLoading[appointment.id]?.status}
                />
              </div>
            </StopClickPropagation>
          ),
          checkedIn: (
            <StopClickPropagation>
              <div className={styles.TableCell}>
                {checkedIn === "none" ? null : (
                  <Dropdown
                    className={styles.DropdownCheckedIn}
                    onChange={(checkedInStatus) =>
                      handleCheckedInSelect(appointment.id, checkedInStatus)
                    }
                    value={checkedIn as string}
                    options={[
                      {
                        value: AppointmentCheckedInStatuses.NONE.name,
                        label: AppointmentCheckedInStatuses.NONE.label,
                        exclude: true
                      },
                      {
                        value: AppointmentCheckedInStatuses.CHECKED_IN.name,
                        label: AppointmentCheckedInStatuses.CHECKED_IN.label,
                        exclude: true
                      },
                      {
                        value: AppointmentCheckedInStatuses.NOTICE_SENT.name,
                        label: AppointmentCheckedInStatuses.NOTICE_SENT.label,
                        exclude: true
                      },
                      {
                        value: AppointmentCheckedInStatuses.NO_CONTACT_INFO.name,
                        label: AppointmentCheckedInStatuses.NO_CONTACT_INFO.label,
                        exclude: true
                      },
                      {
                        value: AppointmentCheckedInStatuses.STAFF_CONTACTED.name,
                        label: AppointmentCheckedInStatuses.STAFF_CONTACTED.label
                      },
                      {
                        value: AppointmentCheckedInStatuses.UNCLEAR.name,
                        label: AppointmentCheckedInStatuses.UNCLEAR.label,
                        exclude: true
                      }
                    ]}
                    configMap={{
                      none: "gray",
                      checked_in: "blue",
                      notice_sent: "gray",
                      no_contact_info: "red",
                      staff_contacted: "green",
                      unclear: "red"
                    }}
                    loading={updatesLoading[appointment.id]?.checkedIn}
                  />
                )}
              </div>
            </StopClickPropagation>
          ),
          parkingLotNotify: (
            <StopClickPropagation>
              <div
                className={cx(styles.TableCell, styles.ChatIcon, styles.TableCellParkingLotNotify)}
              >
                {(appointment.canSendSms || appointment.canSendEmail) && (
                  <Button
                    inline
                    className={styles.ParkingLotNotifyButton}
                    onClick={() =>
                      sendParkingLotReady({
                        id: appointment.id,
                        patientId: appointment.patientId,
                        organizationId: appointment.organizationId,
                        verifiedPhoneNumber: appointment.verifiedPhoneNumber || ""
                      })
                    }
                    disabled={updatesLoading[appointment.id]?.parkingLotNotify}
                  >
                    <Chat size={20} />
                  </Button>
                )}
              </div>
            </StopClickPropagation>
          ),
          chatStatus: (
            <div className={cx(styles.TableCell, styles.ChatIcon, styles.TableCellChatStatus)}>
              <ChatStatus conversations={appointment.conversations} />
            </div>
          ),
          scribeStatus: (
            <div className={cx(styles.TableCell)}>
              <ScribeStatus appointment={appointment} providerId={providerId} />
            </div>
          ),
          details: <DetailsCell classNames={cx(styles.TableCell)} appointment={appointment} />
        };
      });

  const datePresets: Array<DateRangePresetType> = [
    {
      label: "Today",
      start: moment.tz(new Date(), timeZone).startOf("day").toDate(),
      end: moment.tz(new Date(), timeZone).endOf("day").toDate()
    },
    {
      label: "Today Upcoming",
      start: new Date(),
      end: moment.tz(new Date(), timeZone).endOf("day").toDate()
    },
    {
      label: "Today Previous",
      start: moment.tz(new Date(), timeZone).startOf("day").toDate(),
      end: new Date()
    },
    {
      label: "Tomorrow",
      start: moment.tz(new Date(), timeZone).add(1, "days").startOf("day").toDate(),
      end: moment.tz(new Date(), timeZone).add(1, "days").endOf("day").toDate()
    },
    {
      label: "Yesterday",
      start: moment.tz(new Date(), timeZone).subtract(1, "days").startOf("day").toDate(),
      end: moment.tz(new Date(), timeZone).subtract(1, "days").endOf("day").toDate()
    },
    {
      label: "All Upcoming",
      start: new Date(),
      end: moment.tz(new Date(), timeZone).add(10, "years").endOf("day").toDate()
    },
    {
      label: "Previous Year",
      start: moment.tz(new Date(), timeZone).subtract(1, "years").startOf("day").toDate(),
      end: new Date()
    }
  ];

  return (
    <div className={styles.Wrapper}>
      <div className={styles.FilterHeaderRow}>
        <div className={styles.Filters}>
          <DateRangeSelector
            startDate={selectedStartDate as Date}
            endDate={selectedEndDate as Date}
            onChange={handleDateChange}
            presets={datePresets}
          />
          <ResponsiveHide hideOnMobile>
            <LocationSelector
              selectedLocations={selectedLocations}
              onLocationsChange={onLocationsChange}
              locationOptions={locationDropdownOptions}
              placeholder="Locations"
            />
          </ResponsiveHide>
          <ResponsiveHide hideOnMobile hideOnTablet>
            <Search
              id="searchValue"
              placeholder="Search info"
              classNames={styles.Search}
              onChange={(searchValue) => {
                onSearchChange(searchValue);
              }}
              onClear={onSearchClear}
              onEnter={(searchValue: string) => {
                setSearchValue(searchValue);
                setPage(1);
                if (appointmentFilters) {
                  debouncedFetch.flush();
                }
              }}
              initialValue={searchValue}
            />
          </ResponsiveHide>
          <button
            id="filters-dropdown"
            type="button"
            className={cx(styles.MoreFilters, {
              [styles.MoreFiltersActive]: activeFilterCount > 0
            })}
            onClick={async () => {
              openModal(ModalTypes.FILTER_APPOINTMENTS, {
                selectedPractitioners: selectedPractitionersArr,
                practitionerOptions: practitionerDropdownOptions,
                onPractitionerChange,
                selectedReasons: selectedReasonsArr,
                reasonOptions: reasonDropdownOptions,
                onReasonsChange,
                selectedActiveStatusFilters: selectedActiveStatusFiltersArr,
                statusFilterOptions,
                onStatusFilterChange: handleActiveStatusFilterChange,
                selectedActiveCheckinStatusFilters: selectedActiveCheckinStatusFiltersArr,
                checkinFilterOptions,
                onCheckinFilterChange: handleActiveCheckinStatusFilterChange,
                includeFilteredOut,
                onIncludeFilteredOutChange: handleIncludeFilteredOutChange,
                isMikataAdmin: userType === UserTypeConstants.MIKATA_ADMIN,
                showCheckinStatus,
                closeModal
              });
            }}
          >
            <Filter size={18} />

            <ResponsiveHide hideOnMobile>
              <Text
                component="span"
                size="S"
                bold
                className={cx(styles.FiltersLabel, {
                  [styles.FiltersLabelActive]: activeFilterCount > 0
                })}
              >
                {moreFiltersHeader}
              </Text>
            </ResponsiveHide>
          </button>

          <Button
            id="clear-all"
            type="button"
            className={styles.InlineButton}
            onClick={() => {
              setSelectedStartDate(moment.tz(new Date(), timeZone).startOf("day").toDate());
              setSelectedEndDate(moment.tz(new Date(), timeZone).endOf("day").toDate());
              setSelectedLocations("");
              setSelectedActiveStatusFilters(ALL);
              setSelectedActiveCheckinStatusFilters(ALL);
              setSelectedPractitioners("");
              setSelectedReasons("");
              setSearchValue("");
              setIncludeFilteredOut(false);
            }}
            inline
          >
            <Text component="span" size="S" bold className={styles.ClearFiltersLabel}>
              Clear
            </Text>
          </Button>
        </div>

        <div className={styles.UnintegratedButtonGroup}>
          <AddUnintegratedAppointmentButton isDetailsModalOpen={isDetailsModalOpen} />
          <AddUnintegratedAppointmentButton
            instantSession
            isDetailsModalOpen={isDetailsModalOpen}
          />
        </div>
      </div>

      {loading || !appointmentFetchData?.appointments || locationLoading ? (
        <Loader screen />
      ) : (
        <TableGrid
          headers={headers}
          mobileHeaders={mobileHeaders}
          tabletHeaders={tabletHeaders}
          rows={rowData}
          onSortChange={handleSortChange}
          sortBy={sortBy}
          emptyContent={
            <div className={styles.NoAppointments}>
              <Heading>There are no appointments</Heading> <EmptyContentImage />
            </div>
          }
          showRowFocus
          page={page}
          onPageChange={setPage}
          totalPages={Math.ceil(getTotalCount(appointmentFetchData) / rowsPerPage)}
          maxPageRows={rowsPerPage}
          rowsPerPageOptions={[25, 50, 100]}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
      <AppointmentDetailsManager
        isModalOpen={isDetailsModalOpen}
        closeModal={closeDetailsModal}
        simpleAppointment={selectedAppointment}
      />
    </div>
  );
};

const mapStateToProps = ({
  appointments,
  locations,
  practitioners,
  organizationData,
  reasons
}: ReduxStateType) => {
  return {
    appointmentFetchData: appointments.data,
    loading: appointments.appointmentsLoading,
    locations: locations.data,
    practitioners: practitioners.data,
    locationLoading: locations.locationsFetchLoading,
    showCheckinStatus: organizationData.organizationData
      ? organizationData.organizationData.features.includes("parking_lot_checkin")
      : false,
    updatesLoading: appointments.updatesLoading,
    reasons: reasons.data
  };
};

export default connect(mapStateToProps, {
  fetchAppointments: fetchAppointmentsAction,
  fetchLocations: fetchLocationsAction,
  fetchPractitioners: fetchPractitionersAction,
  fetchReasons: fetchReasonsAction,
  updateAppointment: updateAppointmentAction,
  sendParkingLotReady: sendParkingLotReadyAction,
  openModal: openModalAction
})(AppointmentsTable);
