/* eslint-disable consistent-return */
import React, { useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { Form, ToggleInput } from "../../../../ui/Input";
import Text from "../../../../ui/Text";
import ToolTip from "../../../../ui/Tooltip";
import Button from "../../../../ui/Button";
import Heading from "../../../../ui/Heading";
import Loader from "../../../../ui/Loader";
import { Info, Settings } from "../../../../ui/Icon";

import { PermissionsGuard, usePermissions } from "../../../../../hooks/usePermissions";

import {
  openModal as openModalAction,
  OpenModal,
  fetchOrgSettings as fetchOrgSettingsAction,
  updateOrgSettings as updateOrgSettingsAction
} from "../../../../../actions";

import {
  FEATURES,
  EMRTypeConstants,
  IntegrationTypes,
  SettingNames
} from "../../../../../constants";

import {
  Feature,
  OrganizationData,
  ReduxStateType,
  Setting,
  Permissions
} from "../../../../../types";

import styles from "./index.module.scss";

type FeaturesSettingValue = {
  [key: string]: boolean;
};

type FormState = { [featureName: string]: boolean };

type PropsType = {
  organization?: OrganizationData;
  organizationSettings?: Array<Setting>;
  openModal: OpenModal;
  fetchOrganizationSettings: (organizationId: string) => void;
  updateOrganizationSettings: (organizationId: string, settings: Array<Setting>) => void;
};

const features = { ...FEATURES };

const Features = ({
  organization,

  organizationSettings,
  openModal,
  fetchOrganizationSettings,
  updateOrganizationSettings
}: PropsType) => {
  const { organizationId } = useParams<{
    organizationId: string;
  }>();

  const canUpdateFeatures = usePermissions([Permissions.UPDATE_SETTINGS]);
  const isVerified = organization?.verified;

  // Fetch data on load
  useEffect(() => {
    if (organizationId) {
      fetchOrganizationSettings(organizationId);
    }
  }, [organizationId]);

  const initialFormState = useMemo(() => {
    if (!organizationSettings) return null;

    const featuresSetting: Setting | undefined = organizationSettings.find((setting) => {
      const { settingName } = setting;
      const featuresSettingName = SettingNames.FEATURES;
      return settingName === featuresSettingName;
    });

    return Object.values(FEATURES).reduce<FeaturesSettingValue>(
      (featureState, feature: Feature) => {
        // eslint-disable-next-line no-param-reassign
        featureState[feature.value] = ((featuresSetting?.settingValue as string[]) || []).includes(
          feature.value
        );
        return featureState;
      },
      {}
    );
  }, [organizationId, organizationSettings]);

  const save = (formValues: FormState) => {
    const activeFeatures: string[] = Object.keys(formValues).filter(
      (featureName) => formValues[featureName]
    );
    if (organizationId) {
      updateOrganizationSettings(organizationId.toString(), [
        {
          organizationId: Number(organizationId),
          settingName: SettingNames.FEATURES,
          settingValue: activeFeatures
        }
      ]);
    }
  };

  if (!organization || !organizationSettings || !initialFormState) return <Loader screen />;

  return (
    <Form
      onSubmit={(formState) => save(formState.values as FormState)}
      initialValues={initialFormState}
    >
      <div className={styles.Container}>
        <div className={styles.Section}>
          <Heading size="L" component="span">
            Features
          </Heading>
          {!isVerified && (
            <Text className={styles.Unverified}>
              Features are not available for this unverified account.
            </Text>
          )}
          {Object.values(features).map((feature: Feature) => {
            if (!feature) return;
            const fieldName = feature.value;
            return (
              <div className={styles.Row} key={`features-${feature.value}`}>
                <div className={styles.ToggleRowItem}>
                  <ToggleInput
                    fieldName={fieldName}
                    labelChecked="Active"
                    labelUnchecked="Inactive"
                    disabled={!canUpdateFeatures}
                  />
                </div>

                {feature.name}

                <ToolTip
                  icon={
                    <div className={styles.Icon}>
                      <Info size={15} />
                    </div>
                  }
                >
                  {feature.description}
                </ToolTip>
                {feature.configurationModalName && (
                  <PermissionsGuard requiredPermissions={[Permissions.UPDATE_SETTINGS]}>
                    <Button
                      inline
                      className={styles.SettingIcon}
                      onClick={() => {
                        openModal(feature.configurationModalName as string);
                      }}
                    >
                      <Settings />
                    </Button>
                  </PermissionsGuard>
                )}
              </div>
            );
          })}
        </div>

        <div className={styles.Footer}>
          <Button type="submit" id="featuresSaveButton" disabled={!canUpdateFeatures}>
            Save
          </Button>
        </div>
      </div>
    </Form>
  );
};

const mapStateToProps = ({ organizationDetails }: ReduxStateType) => {
  return {
    organization: organizationDetails.data,
    organizationSettings: organizationDetails.settings
  };
};

export default connect(mapStateToProps, {
  openModal: openModalAction,
  fetchOrganizationSettings: fetchOrgSettingsAction,
  updateOrganizationSettings: updateOrgSettingsAction
})(Features);
