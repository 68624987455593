/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from "react";

import { LayoutContext, LayoutViewMode } from "../../../providers/LayoutProvider";

type PropsType = {
  children?: React.ReactNode;
  DefaultComponent: React.ComponentType<any>;
  MobileComponent?: React.ComponentType<any>;
  TabletComponent?: React.ComponentType<any>;
  [otherProp: string]: any;
};

const ResponsiveComponentSwitch = ({
  children,
  DefaultComponent,
  MobileComponent,
  TabletComponent,
  ...otherProps // other props to passthrough to rendered component
}: PropsType): React.ReactElement => {
  const { viewMode } = useContext(LayoutContext);

  if (viewMode === LayoutViewMode.MOBILE && MobileComponent) {
    return <MobileComponent {...otherProps}>{children}</MobileComponent>;
  }
  if (viewMode === LayoutViewMode.TABLET && TabletComponent) {
    return <TabletComponent {...otherProps}>{children}</TabletComponent>;
  }

  return <DefaultComponent {...otherProps}>{children}</DefaultComponent>;
};

export default ResponsiveComponentSwitch;
