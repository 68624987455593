export enum Permissions {
  CREATE_ADMIN_USER = "create_admin",
  UPDATE_ADMIN_USER = "update_admin",

  // Users
  CREATE_USER = "create_user",
  GET_TOKEN_FOR_SOCKET = "get_token_for_socket",
  SOCKET_CONNECTION = "socket_connection",
  UPDATE_USER_SELF = "update_user_self",
  UPDATE_USER_OTHER_LEVEL_10 = "update_user_other_level_10",
  UPDATE_USER_OTHER_LEVEL_20 = "update_user_other_level_20",
  UPDATE_USER_OTHER_LEVEL_30 = "update_user_other_level_30",
  CREATE_STAFF = "create_staff",
  USERS_PASSWORD_RESET = "users_password_reset",
  REQUEST_PASSWORD_RESET_SELF = "request_password_reset_self",
  REQUEST_PASSWORD_RESET_OTHER = "request_password_reset_other",
  SET_USER_ROLE = "set_user_role",
  FIND_MIKATA_USERS = "find_mikata_users",
  FIND_ROLES = "find_roles",

  // Uploads
  UPLOAD_SAMPLE = "upload_sample",
  UPLOAD_DAILY = "upload_daily",
  UPLOAD_DATASCI = "upload_data_sci",
  STAFF_UPLOADS = "staff_uploads",

  // Organization Info
  CREATE_ORGANIZATION = "create_organization",
  GET_ORGANIZATION = "get_organization",
  GET_BASIC_ORG_INFO = "get_basic_org_info",
  GET_ORG_STAFF = "get_org_staff",
  GET_ORG_DETAILS = "get_org_details",
  FIND_ORGANIZATIONS = "find_organizations",
  UPDATE_ORGANIZATION = "update_organization",
  CREATE_LOCATION = "create_location",
  FIND_LOCATIONS = "find_locations",
  UPDATE_LOCATION = "update_location",
  VIEW_SETTINGS_PAGE = "view_settings_page",
  UPDATE_SETTINGS = "update_settings",
  VIEW_ANALYTICS = "view_analytics",
  FIND_PRACTITIONERS = "find_practitioners",
  FIND_REASONS = "find_reasons",
  UPDATE_REASONS = "update_reasons",
  CREATE_CHECKLISTS = "create_checklists",
  FIND_CHECKLISTS = "find_checklists",
  CREATE_REASONS = "create_reasons",
  UPDATE_SERVICES = "update_services",
  UPDATE_SERVICES_TFN = "update_services_tfn",
  DELETE_SERVICES = "delete_services",

  // Billing
  VIEW_BILLING = "view_billing",
  VIEW_BILLING_ORGANIZATION = "view_billing_organization",
  VIEW_BILLING_SELF = "view_billing_self",
  UPDATE_BILLING = "update_billing",
  UPDATE_BILLING_SELF = "update_billing_self",

  // Dashboard
  GET_APPTS = "get_appts",
  UPDATE_APPT_STATUS = "update_appt_status",
  CREATE_REPORT_SNAPSHOT = "create_report_snapshot",
  PUSH_REPORT_SNAPSHOT = "push_report_snapshot",
  FIND_APPT_REQUESTS = "find_appt_requests",
  UPDATE_APPT_REQUESTS = "update_appt_requests",
  DOWNLOAD_APPT_REQUISITION = "download_appt_requisition",
  FIND_TASKS = "find_tasks",

  // Campaigns
  CREATE_CAMPAIGN = "create_campaign",
  FIND_CAMPAIGN = "find_campaign",
  GET_CAMPAIGN = "get_campaign",
  GET_CAMPAIGN_SUMMARY = "get_campaign_summary",
  SEND_CAMPAIGN = "send_campaign",
  UPDATE_CAMPAIGN = "update_campaign",

  // Chat Flows
  CREATE_CHAT_FLOW = "create_chat_flow",

  // OAuth
  OAUTH_GET_CLIENT_ID = "oauth_get_client_id",
  OAUTH_REQUEST_TOKEN = "oauth_request_token",

  // Automations
  FIND_AUTOMATIONS = "find_automations",
  CREATE_AUTOMATION = "create_automation",
  UPDATE_AUTOMATION = "update_automation",
  DESTROY_AUTOMATION = "destroy_automation",
  GET_AUTOMATION_JOURNEY = "get_automation_journey",
  VIEW_AUTOMATION_GENERAL = "view_automation_general",
  VIEW_AUTOMATION_TRIGGERS = "view_automation_triggers",
  VIEW_AUTOMATION_RESPONSE_MESSAGES = "view_automation_response_messages",

  // Automated Message Configurations (Touchpoints)
  CREATE_AUTOMATED_MESSAGE_CONFIGURATION = "create_automated_message_config",
  UPDATE_AUTOMATED_MESSAGE_CONFIGURATION = "update_automated_message_config",
  DESTROY_AUTOMATED_MESSAGE_CONFIGURATION = "destroy_automated_message_config",
  VIEW_AUTOMATED_MESSAGE_CONFIGURATIONS = "view_automated_message_configs",

  // Message Templates
  CREATE_MESSAGE_TEMPLATE = "create_message_template",
  UPDATE_MESSAGE_TEMPLATE = "update_message_template",
  SEND_TEST_MESSAGE_TEMPLATES = "send_test_message_template",

  // Documents
  FIND_DOCUMENTS = "find_documents",
  GET_DOCUMENT = "get_document",

  // Unintegrated records
  CREATE_UNINTEGRATED_APPOINTMENT = "create_unintegrated_appointment",
  CREATE_UNINTEGRATED_PATIENT = "create_unintegrated_patient",
  DELETE_UNINTEGRATED_APPOINTMENT = "delete_unintegrated_appointment",

  // Appointment Request Direct Messages
  SEND_APPOINTMENT_REQUEST_DIRECT_MESSAGE = "send_appointment_request_direct_message",

  // Appointment History
  GET_APPOINTMENTS_HISTORY = "get_appointments_history",

  // Appointment Request History
  GET_APPOINTMENTS_REQUEST_HISTORY = "get_appointments_request_history",

  // Scribe Note Templates
  FIND_NOTE_TEMPLATES = "find_note_templates",
  CLONE_NOTE_TEMPLATES = "clone_note_templates",
  UPDATE_NOTE_TEMPLATE = "update_note_template",
  UPDATE_NOTE_TEMPLATE_SELF = "update_note_template_self",
  DELETE_NOTE_TEMPLATE = "delete_note_template",
  DELETE_NOTE_TEMPLATE_SELF = "delete_note_template_self",
  SET_DEFAULT_NOTE_TEMPLATES = "SET_DEFAULT_NOTE_TEMPLATES",

  // Notes
  DELETE_NOTES_ORGANIZATION = "delete_notes_organization"
}
