import { UserStatuses } from "../constants";

export const resetPasswordButtonText = (status: string): string => {
  switch (status) {
    case UserStatuses.PENDING:
      return "Send invite";
    case UserStatuses.INVITE_EXPIRED:
    case UserStatuses.INVITE_FAILED:
    case UserStatuses.INVITE_SENT:
    case UserStatuses.DEACTIVATED:
      return "Resend invite";
    default:
      return "Reset password";
  }
};
