import { StatusComponentConfigMap } from "./types";

export const ReminderTypePreference: {
  [index: string]: { value: string; name: string; description: string };
} = Object.freeze({
  SMS_ONLY: {
    value: "SMS_only",
    name: "SMS Only",
    description: "Send only SMS reminders"
  },
  // EMAIL_ONLY: {
  //   value: "email_only",
  //   name: "Email Only",
  //   description: "Send only email reminders"
  // },
  PREFER_SMS: {
    value: "prefer_sms",
    name: "Prefer SMS",
    description: "Send SMS reminders when mobile available, email when available and no mobile"
  },
  PREFER_EMAIL: {
    value: "prefer_email",
    name: "Prefer Email",
    description: "Send email reminders when email available, SMS when mobile available and no email"
  },
  BOTH: {
    value: "both",
    name: "Both SMS and Email",
    description:
      "Send reminders to both mobile and email when both available.  Use SMS or email as required for those with only one"
  }
});

export const OrganizationStates: {
  [index: string]: { value: string; name: string };
} = Object.freeze({
  LIVE: {
    value: "live",
    name: "Active"
  },
  TEST: {
    value: "test",
    name: "Test Mode"
  },
  SETUP: {
    value: "setup",
    name: "Deactivated"
  }
});

export const ServiceProviders: {
  [index: string]: { value: string; name: string };
} = Object.freeze({
  TWILIO: {
    name: "Twilio",
    value: "twilio"
  },
  PLIVO: {
    name: "Plivo",
    value: "plivo"
  },
  VONAGE: {
    name: "Vonage",
    value: "vonage"
  }
});

export const OrganizationTypes: {
  [index: string]: string;
} = Object.freeze({
  PARENT: "parent",
  ORGANIZATION: "organization"
});

export enum UserRoleConstants {
  GENERAL = "General",
  GENERAL_UPLOAD = "General Upload",
  MANAGER = "Clinic Manager",
  PROVIDER = "Practitioner",
  PROVIDER_MANAGER = "Practitioner Manager",
  API_INTEGRATION = "API Integration",
  MIKATA_BILLING_ADMIN = "Mikata Billing Admin",
  MIKATA_ADMIN = "Mikata Admin",
  MIKATA_SUPER_ADMIN = "Mikata Super Admin"
}

export enum UserStatuses {
  INVITE_EXPIRED = "invite expired",
  INVITE_SENT = "invite sent",
  INVITE_FAILED = "invite failed",
  AWAITING_PASSWORD_RESET = "awaiting password reset",
  PASSWORD_RESET_FAILED = "password reset failed",
  PENDING = "pending",
  ACTIVE = "active",
  DEACTIVATED = "deactivated",
  LOCKED_OUT = "locked out"
}

export enum UserTypeConstants {
  MIKATA_ADMIN = "mikata admin",
  PATIENT = "patient",
  STAFF = "staff",
  PRACTITIONER = "practitioner"
}

export enum FeedbackFormModes {
  SELECTION = "selection",
  REPORT_ISSUE = "reportIssue",
  SCRIBE_TEMPLATE_REQUEST = "scribeTemplateRequest",
  SUGGESTION = "suggestion",
  SCRIBE_FEEDBACK = "scribeFeedback",
  SUBMITTED = "submitted"
}

export const userStatusConfigMap: StatusComponentConfigMap = {
  [UserStatuses.ACTIVE]: "green",
  [UserStatuses.INVITE_EXPIRED]: "yellow",
  [UserStatuses.INVITE_FAILED]: "red",
  [UserStatuses.INVITE_SENT]: "blue",
  [UserStatuses.AWAITING_PASSWORD_RESET]: "blue",
  [UserStatuses.DEACTIVATED]: "gray",
  [UserStatuses.PENDING]: "gray",
  [UserStatuses.LOCKED_OUT]: "yellow",
  [UserStatuses.PASSWORD_RESET_FAILED]: "red"
};

export const userStatusOptions = [
  { label: "Active", value: UserStatuses.ACTIVE },
  { label: "Awaiting Password Reset", value: UserStatuses.AWAITING_PASSWORD_RESET },
  { label: "Deactivated", value: UserStatuses.DEACTIVATED },
  { label: "Invite Expired", value: UserStatuses.INVITE_EXPIRED },
  { label: "Invite Failed", value: UserStatuses.INVITE_FAILED },
  { label: "Invite Sent", value: UserStatuses.INVITE_SENT },
  { label: "Locked Out", value: UserStatuses.LOCKED_OUT },
  { label: "Not Invited", value: UserStatuses.PENDING },
  { label: "Password Reset Failed", value: UserStatuses.PASSWORD_RESET_FAILED }
];

export enum PatientVerificationMethods {
  PERSONAL_HEALTH_NUMBER = "personalHealthNumber",
  DATE_OF_BIRTH = "dateOfBirth"
}

export enum InboxModes {
  ALL = "All",
  TODO = "todo",
  DONE = "done"
}

export const TaskTypes = Object.freeze({
  APPOINTMENT_REQUEST: "appointmentRequest",
  DIRECT_MESSAGE_CONVERSATION: "directMessageConversation"
});

export const TaskOpenStates = Object.freeze({
  PENDING: "pending",
  OPEN: "open",
  CLOSED: "closed"
});

export enum AppointmentRequestStatuses {
  IN_PROGRESS = "In progress",
  IN_PROGRESS_WAITING_ON_INPUT = "In progress: Waiting on input",
  READY = "Ready",
  BOOKED = "Booked",
  UNABLE_TO_BOOK = "Unable to book",
  UNABLE_TO_BOOK_INSUFFICIENT_INFO = "Unable to book: Insufficient info",
  UNABLE_TO_BOOK_NOT_A_REQUEST = "Unable to book: Not a request",
  AUTO_BOOKED = "Auto-booked",
  SYNCED = "Synced",
  FAILED_TO_SYNC = "Failed to sync"
}

export const apptRequestStatusOptions = [
  {
    value: AppointmentRequestStatuses.IN_PROGRESS,
    label: AppointmentRequestStatuses.IN_PROGRESS,
    exclude: true
  },
  {
    value: AppointmentRequestStatuses.IN_PROGRESS_WAITING_ON_INPUT,
    label: "Waiting on input"
  },
  {
    value: AppointmentRequestStatuses.READY,
    label: AppointmentRequestStatuses.READY
  },
  {
    value: AppointmentRequestStatuses.BOOKED,
    label: AppointmentRequestStatuses.BOOKED
  },
  {
    value: AppointmentRequestStatuses.UNABLE_TO_BOOK,
    label: "Unable to book"
  },
  {
    value: AppointmentRequestStatuses.UNABLE_TO_BOOK_INSUFFICIENT_INFO,
    label: "Insufficient info"
  },
  {
    value: AppointmentRequestStatuses.UNABLE_TO_BOOK_NOT_A_REQUEST,
    label: "Not a request"
  },
  {
    value: AppointmentRequestStatuses.AUTO_BOOKED,
    label: AppointmentRequestStatuses.AUTO_BOOKED,
    exclude: true
  },
  {
    value: AppointmentRequestStatuses.SYNCED,
    label: AppointmentRequestStatuses.SYNCED,
    exclude: true
  },
  {
    value: AppointmentRequestStatuses.FAILED_TO_SYNC,
    label: AppointmentRequestStatuses.FAILED_TO_SYNC,
    exclude: true
  }
];

export const appointmentRequestStatusConfigMap = Object.freeze({
  [AppointmentRequestStatuses.IN_PROGRESS]: "gray",
  [AppointmentRequestStatuses.IN_PROGRESS_WAITING_ON_INPUT]: "gray",
  [AppointmentRequestStatuses.READY]: "blue",
  [AppointmentRequestStatuses.BOOKED]: "green",
  [AppointmentRequestStatuses.UNABLE_TO_BOOK]: "red",
  [AppointmentRequestStatuses.UNABLE_TO_BOOK_INSUFFICIENT_INFO]: "red",
  [AppointmentRequestStatuses.UNABLE_TO_BOOK_NOT_A_REQUEST]: "red",
  [AppointmentRequestStatuses.AUTO_BOOKED]: "green",
  [AppointmentRequestStatuses.SYNCED]: "green",
  [AppointmentRequestStatuses.FAILED_TO_SYNC]: "red"
});

export enum BookingModes {
  REQUEST = "request",
  AVAILABLE_TIMES = "availableTimes",
  AUTO_BOOK = "autoBook"
}
