import React, { useContext, useEffect } from "react";
import cx from "classnames";
import moment from "moment";

import Card from "../../../../../../ui/Card";
import Text from "../../../../../../ui/Text";
import Heading from "../../../../../../ui/Heading";
import Tooltip from "../../../../../../ui/Tooltip";
import { Info } from "../../../../../../ui/Icon";

import { EncounterSocketContext } from "../../../../../../providers/EncounterSocketProvider";

import { SocketMessageType } from "../../../../../../../types/recordingSession";

import styles from "./index.module.scss";

export const Timers = () => {
  const { activityTimerValues, sendMessage } = useContext(EncounterSocketContext);
  const {
    pageOpenDurationMilliseconds,
    mikaActiveDurationMilliseconds,
    providerActiveDurationMilliseconds
  } = activityTimerValues;

  useEffect(() => {
    sendMessage(SocketMessageType.activityTimers);
  }, []);

  const displayMilliseconds = (ms: number) => {
    if (!ms) {
      return null;
    }
    const hours = Math.floor(ms / 3600000);
    return moment.utc(ms).format(hours ? "HH:mm:ss" : "m:ss");
  };

  const currentMoment = moment();

  return (
    <>
      <div style={{ padding: "24px" }}>
        <div className={cx(styles.TooltipRow)}>
          <Heading size="META">TIME ON APPOINTMENT</Heading>
          <Tooltip
            icon={<Info size={12} />}
            position="bottomRight"
            className={cx(styles.ToolTip)}
            size="M"
          >
            How long this appointment page has been open.
          </Tooltip>
        </div>
        <Heading size="L">{displayMilliseconds(pageOpenDurationMilliseconds) || "-"}</Heading>
        <div className={cx(styles.SmallTimersRow)}>
          <div>
            <div className={cx(styles.TooltipRow)}>
              <Heading size="META">ENGAGEMENT</Heading>
              <Tooltip
                icon={<Info size={12} />}
                position="bottomRight"
                className={cx(styles.ToolTip)}
                size="M"
              >
                How long the provider has interacted with this page via keyboard or mouse click.
              </Tooltip>
            </div>
            <Text size="M">{displayMilliseconds(providerActiveDurationMilliseconds) || "-"}</Text>
          </div>
          <div>
            <div className={cx(styles.TooltipRow)}>
              <Heading size="META">MIKA ACTIVE</Heading>
              <Tooltip
                icon={<Info size={12} />}
                position="bottomLeft"
                className={cx(styles.ToolTip)}
                size="M"
              >
                How long the Mika tool has been active.
              </Tooltip>
            </div>
            <Text size="M">{displayMilliseconds(mikaActiveDurationMilliseconds) || "-"}</Text>
          </div>
        </div>
      </div>
      <Text>
        {`Last updated on ${currentMoment.format("MMM DD")} at ${currentMoment.format("h:mm a")}`}
      </Text>
    </>
  );
};
