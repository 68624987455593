import React from "react";

type PropsType = {
  size?: number;
  rating: number;
};

const ScribeRatingIcon = ({ size = 60, rating }: PropsType) => {
  const ratingIcon = (ratingValue: number) => {
    switch (ratingValue) {
      case 1:
        return (
          <svg
            width={`${size}px`}
            height={`${size}px`}
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="26.55"
              cy="26.55"
              r="26.55"
              transform="matrix(-1 0 0 1 56.6999 3.6001)"
              fill="#E2FAF3"
            />
            <path
              d="M13.5 27H22.5V29.7C22.5 32.1853 20.4853 34.2 18 34.2C15.5147 34.2 13.5 32.1853 13.5 29.7V27Z"
              fill="white"
            />
            <path
              d="M36.3 27H45.3V29.7C45.3 32.1853 43.2853 34.2 40.8 34.2C38.3147 34.2 36.3 32.1853 36.3 29.7V27Z"
              fill="white"
            />
            <path
              d="M13.5 27H19.2V29.55C19.2 31.124 17.924 32.4 16.35 32.4C14.776 32.4 13.5 31.124 13.5 29.55V27Z"
              fill="#94979E"
            />
            <path
              d="M36.3 27H42V29.55C42 31.124 40.724 32.4 39.15 32.4C37.576 32.4 36.3 31.124 36.3 29.55V27Z"
              fill="#94979E"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.2 27.1498C13.2 26.8184 13.4686 26.5498 13.8 26.5498H24C24.3314 26.5498 24.6 26.8184 24.6 27.1498C24.6 27.4812 24.3314 27.7498 24 27.7498H13.8C13.4686 27.7498 13.2 27.4812 13.2 27.1498Z"
              fill="#94979E"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M35.7 27.1498C35.7 26.8184 35.9686 26.5498 36.3 26.5498H46.5C46.8314 26.5498 47.1 26.8184 47.1 27.1498C47.1 27.4812 46.8314 27.7498 46.5 27.7498H36.3C35.9686 27.7498 35.7 27.4812 35.7 27.1498Z"
              fill="#94979E"
            />
          </svg>
        );
      case 2:
        return (
          <svg
            width={`${size}px`}
            height={`${size}px`}
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="26.55"
              cy="26.55"
              r="26.55"
              transform="matrix(-1 0 0 1 57.1999 3.6001)"
              fill="#E2FAF3"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.7 27.1498C13.7 26.8184 13.9686 26.5498 14.3 26.5498H24.5C24.8314 26.5498 25.1 26.8184 25.1 27.1498C25.1 27.4812 24.8314 27.7498 24.5 27.7498H14.3C13.9686 27.7498 13.7 27.4812 13.7 27.1498Z"
              fill="#94979E"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M36.2 27.1498C36.2 26.8184 36.4686 26.5498 36.8 26.5498H47C47.3314 26.5498 47.6 26.8184 47.6 27.1498C47.6 27.4812 47.3314 27.7498 47 27.7498H36.8C36.4686 27.7498 36.2 27.4812 36.2 27.1498Z"
              fill="#94979E"
            />
          </svg>
        );
      case 3:
        return (
          <svg
            width={`${size}px`}
            height={`${size}px`}
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="26.55"
              cy="26.55"
              r="26.55"
              transform="matrix(-1 0 0 1 56.6999 3.6001)"
              fill="#E2FAF3"
            />
            <circle cx="18.9" cy="27.0001" r="5.4" fill="white" />
            <circle cx="41.4" cy="27.0001" r="5.4" fill="white" />
            <circle cx="18.9" cy="26.9999" r="3.6" fill="#94979E" />
            <circle cx="41.4" cy="26.9999" r="3.6" fill="#94979E" />
          </svg>
        );
      case 4:
        return (
          <svg
            width={`${size}px`}
            height={`${size}px`}
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="26.55"
              cy="26.55"
              r="26.55"
              transform="matrix(-1 0 0 1 57.1999 3.6001)"
              fill="#E2FAF3"
            />
            <circle cx="19.5499" cy="26.5498" r="6.75" fill="white" />
            <ellipse cx="19.9999" cy="25.7999" rx="4.8" ry="5.4" fill="#94979E" />
            <circle cx="20.8999" cy="23.7001" r="2.1" fill="white" />
            <circle cx="19.5499" cy="27.4501" r="1.35" fill="white" />
            <circle cx="41.7499" cy="26.5498" r="6.75" fill="white" />
            <ellipse cx="42.1999" cy="25.7999" rx="4.8" ry="5.4" fill="#94979E" />
            <circle cx="43.0999" cy="23.7001" r="2.1" fill="white" />
            <circle cx="41.7499" cy="27.4501" r="1.35" fill="white" />
          </svg>
        );
      case 5:
        return (
          <svg
            width={`${size}px`}
            height={`${size}px`}
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="26.55"
              cy="26.55"
              r="26.55"
              transform="matrix(-1 0 0 1 56.6999 3.6001)"
              fill="#E2FAF3"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.9256 20.3878C14.5479 18.7926 17.1645 18.8061 18.77 20.4179L19.8 21.452L20.83 20.4179C22.4355 18.8061 25.0522 18.7926 26.6744 20.3878C28.2966 21.9831 28.3102 24.5829 26.7047 26.1947L19.8 33.1265L12.8953 26.1947C11.2898 24.5829 11.3034 21.9831 12.9256 20.3878Z"
              fill="#94979E"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M34.5256 20.3878C36.1478 18.7926 38.7645 18.8061 40.37 20.4179L41.4 21.452L42.43 20.4179C44.0355 18.8061 46.6521 18.7926 48.2744 20.3878C49.8966 21.9831 49.9102 24.5829 48.3047 26.1947L41.4 33.1265L34.4953 26.1947C32.8898 24.5829 32.9034 21.9831 34.5256 20.3878Z"
              fill="#94979E"
            />
          </svg>
        );
      default:
        return null;
    }
  };

  return ratingIcon(rating);
};

export default ScribeRatingIcon;
