import React from "react";

import { useFormApi, useFormState } from "informed";
import Button from "../../../../../ui/Button";
import Card from "../../../../../ui/Card";
import Heading from "../../../../../ui/Heading";
import { FullScreenModal } from "../../../../../ui/Modal";
import Text from "../../../../../ui/Text";
import { Trash } from "../../../../../ui/Icon";

import {
  TextInput,
  ToggleInput,
  SelectInput,
  CreatableSelectInput,
  CheckboxGroup,
  JsonContentInput
} from "../../../../../ui/Input";

import { OpenModal } from "../../../../../../actions";

import { displayNameValidator, InstructionFormState } from "./formValidator";

import { AdminModalTypes } from "../../../../../../constants";

import { Reason, InstructionDetailed, Option } from "../../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  organizationName: string;
  instruction: InstructionDetailed;
  reasons: Array<Reason>;
  isModalOpen: boolean;
  formDisabled: boolean;
  initialState: InstructionFormState;
  groupNameOptions: Option[];
  reasonOptions: Option[];
  closeModal: () => void;
  openModal: OpenModal;
};

const typeOptions = [
  { label: "None", value: "" },
  { label: "Combo", value: "combo" },
  { label: "Primary", value: "primary" },
  { label: "Default", value: "default" }
];

const InstructionsDetailsForm = ({
  organizationName,
  instruction,
  isModalOpen,
  formDisabled,
  initialState,
  groupNameOptions,
  reasonOptions,
  closeModal,
  openModal
}: PropsType) => {
  const formState = useFormState();
  const formApi = useFormApi();
  const values = formState.values as InstructionFormState;
  const hasFormChanges =
    values.displayName !== initialState.displayName ||
    values.description !== initialState.description ||
    values.groupName !== initialState.groupName ||
    values.type !== initialState.type ||
    values.filler !== initialState.filler ||
    values.reasons !== initialState.reasons ||
    values.content !== initialState.content;

  return (
    <FullScreenModal
      title={instruction?.displayName}
      isOpen={isModalOpen}
      subtitle={`${organizationName} > Edit Instruction`}
      onClose={closeModal}
      headerButton={
        <Button
          type="submit"
          onClick={formApi.submitForm}
          disabled={formDisabled || !hasFormChanges}
        >
          Save
        </Button>
      }
      headerIconButtons={
        <Button
          className={styles.IconButton}
          inline
          onClick={() =>
            openModal(AdminModalTypes.DELETE_INSTRUCTION, {
              instruction,
              onSuccess: () => closeModal()
            })
          }
          disabled={formDisabled}
        >
          <Trash />
        </Button>
      }
    >
      <div className={styles.Wrapper}>
        <div className={styles.ContainerSide}>
          {instruction && (
            <div className={styles.InfoGroup}>
              <Heading size="META" component="h5">
                Name
              </Heading>
              <Text>{instruction.displayName}</Text>
            </div>
          )}
        </div>

        <div className={styles.ContainerMain}>
          <div className={styles.Card}>
            <div className={styles.HeadingGroup}>
              <Heading size="L" component="h1" className={styles.Heading}>
                Instruction Details
              </Heading>
              <ToggleInput
                fieldName="filler"
                // label="Filler"
                labelChecked="Filler"
                labelUnchecked="Non-Filler"
                disabled={formDisabled}
              />
            </div>
            <TextInput
              fieldName="displayName"
              label="Display Name"
              disabled={formDisabled}
              validate={displayNameValidator}
            />
            <TextInput fieldName="description" label="Description" disabled={formDisabled} />
            <SelectInput
              fieldName="type"
              label="Type"
              info={
                <span>
                  Type is used to decide which instruction to send a patient when multiple
                  instructions with exactly the same name are triggered. If there is an instruction
                  with the same name and is a &apos;combo&apos; type then that will always be
                  chosen, even if its not directly related to an appointment reason. In order of
                  priority:
                  <ul>
                    <li>Combo</li>
                    <li>Primary</li>
                    <li>Default</li>
                  </ul>
                </span>
              }
              placeholder="None"
              options={typeOptions}
              disabled={formDisabled}
            />
            <CreatableSelectInput
              fieldName="groupName"
              label="Group"
              info={
                <span>
                  Defines the group within which the system will look to find a &apos;combo&apos;
                  instruction. All the appointment associated instructions and the &apos;combo&apos;
                  instruction must be of the same group.
                </span>
              }
              placeholder="Select or Create..."
              options={groupNameOptions}
              disabled={formDisabled}
            />
            <CheckboxGroup
              fieldName="reasons"
              label="Select Instruction Reason(s)"
              showCheckAll
              options={reasonOptions}
              searchPlaceholder="Find a reason"
              disabled={formDisabled}
            />
          </div>
          <Card>
            <Heading size="M">Content</Heading>
            <JsonContentInput fieldName="content" disabled={formDisabled} />
          </Card>
        </div>
      </div>
    </FullScreenModal>
  );
};

export default InstructionsDetailsForm;
