import React, { Dispatch, SetStateAction, useMemo } from "react";

import { TextInput, SelectInput, FormGrid } from "../../../../../../../../ui/Input";
import { ChatFlowsNodes } from "../../../../../../../../../types";

import BaseChatNodeForm, {
  BaseFormValues,
  PatientVerificationFormValues,
  FormValues
} from "../../BaseChatNodeForm";

import { PatientVerificationMethods } from "../../../../../../../../../constants";

const patientVerificationMethodOptions = [
  { label: "Personal Health Number", value: PatientVerificationMethods.PERSONAL_HEALTH_NUMBER },
  { label: "Date of Birth", value: PatientVerificationMethods.DATE_OF_BIRTH }
];

type PropsType = {
  chatId: string | undefined;
  node: ChatFlowsNodes;
  viewOnly: boolean;
  setSelectedNodeId: Dispatch<SetStateAction<number | null>>;
};

export type PatientVerificationFormNode = ChatFlowsNodes & {
  payloadContent: string | null;
  payloadOptions: {
    verificationMethod: string;
    hasSufficientPatientData: boolean;
    insufficientPatientDataMessage?: string;
  };
};

const onSaveCustomTransformer = (formValues: FormValues) => {
  const values = formValues as BaseFormValues & PatientVerificationFormValues;

  return {
    payloadContent: "",
    payloadOptions: {
      personalHealthNumberRequired:
        values.verificationMethod === PatientVerificationMethods.PERSONAL_HEALTH_NUMBER,
      dateOfBirthRequired: values.verificationMethod === PatientVerificationMethods.DATE_OF_BIRTH,
      insufficientPatientDataMessage: values.insufficientPatientDataMessage || ""
    }
  };
};

const PatientVerificationCard = ({ chatId, node, viewOnly, setSelectedNodeId }: PropsType) => {
  const initialFormValues: PatientVerificationFormValues = useMemo(() => {
    return {
      verificationMethod: node?.payloadOptions?.dateOfBirthRequired
        ? PatientVerificationMethods.DATE_OF_BIRTH
        : PatientVerificationMethods.PERSONAL_HEALTH_NUMBER,
      insufficientPatientDataMessage: node?.payloadOptions?.insufficientPatientDataMessage || ""
    };
  }, [node.id]);

  return (
    <BaseChatNodeForm
      chatId={chatId}
      initialValues={initialFormValues}
      onSaveCustomTransformer={onSaveCustomTransformer}
      node={node}
      key={node.id}
      isInput
      viewOnly={viewOnly}
      setSelectedNodeId={setSelectedNodeId}
    >
      <FormGrid>
        <SelectInput
          fieldName="verificationMethod"
          label="verification method"
          disabled={viewOnly}
          options={patientVerificationMethodOptions}
        />
      </FormGrid>
      <TextInput
        fieldName="insufficientPatientDataMessage"
        label="Insufficient Patient Data Message"
        disabled={viewOnly}
      />
    </BaseChatNodeForm>
  );
};

export default PatientVerificationCard;
