import React from "react";

import { useFormApi } from "informed";
import Button from "../../../../ui/Button";
import { CheckboxGroup } from "../../../../ui/Input";
import Heading from "../../../../ui/Heading";

import { Option } from "../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  options: Array<Option>;
  optionsTitle: string;
};

const MultiSelectorForm = ({ options, optionsTitle }: PropsType) => {
  const formApi = useFormApi();
  return (
    <>
      <Heading size="META">{`${optionsTitle}`}</Heading>
      <CheckboxGroup fieldName="selectedOptions" options={options} searchSize="M" />
      <div className={styles.Divider} />
      <div className={styles.ButtonWrapper}>
        <Button
          id="clear-filters"
          type="button"
          inline
          onClick={() => {
            formApi.setValue("selectedOptions", []);
          }}
        >
          Clear all
        </Button>

        <Button type="submit">Apply</Button>
      </div>
    </>
  );
};

export default MultiSelectorForm;
