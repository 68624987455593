import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import { Form } from "../../../ui/Input";
import { FloatModal } from "../../../ui/Modal";
import FilterAppointmentsForm from "./FilterAppointmentsForm";

import { closeModal as closeModalAction } from "../../../../actions";

import { Option } from "../../../../types";

type PropsType = {
  selectedPractitioners: string[];
  practitionerOptions: Array<Option>;
  onPractitionerChange: (value: string) => void;
  selectedReasons: string[];
  reasonOptions: Array<Option>;
  onReasonsChange: (value: string) => void;
  selectedActiveStatusFilters: string[];
  statusFilterOptions: Array<Option>;
  onStatusFilterChange: (value: string) => void;
  selectedActiveCheckinStatusFilters: string[];
  checkinFilterOptions: Array<Option>;
  onCheckinFilterChange: (value: string) => void;
  includeFilteredOut: boolean;
  onIncludeFilteredOutChange: (value: boolean) => void;
  showCheckinStatus: boolean;
  isMikataAdmin: boolean;
  closeModal: () => void;
};

type MoreFiltersFormData = {
  practitioners: Array<string>;
  reasons: Array<string>;
  activeStatusFilters: Array<string>;
  activeCheckinStatusFilters: Array<string>;
  includeFilteredOut: boolean;
};

const FilterAppointments = ({
  selectedPractitioners,
  practitionerOptions,
  onPractitionerChange,
  selectedReasons,
  reasonOptions,
  onReasonsChange,
  selectedActiveStatusFilters,
  onStatusFilterChange,
  statusFilterOptions,
  selectedActiveCheckinStatusFilters,
  onCheckinFilterChange,
  checkinFilterOptions,
  includeFilteredOut: includeFilteredOutValue,
  onIncludeFilteredOutChange,
  showCheckinStatus,
  isMikataAdmin,
  closeModal
}: PropsType) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const initialFormState = {
    practitioners: selectedPractitioners,
    reasons: selectedReasons,
    activeStatusFilters: selectedActiveStatusFilters,
    activeCheckinStatusFilters: selectedActiveCheckinStatusFilters,
    includeFilteredOut: includeFilteredOutValue
  };

  const toggle = () => {
    setOpen((isOpen) => !isOpen);
  };

  const offDropdownClick = (event: MouseEvent) => {
    const dropdownEl = elementRef.current;
    const isOutsideClick = dropdownEl && !dropdownEl.contains(event.target as Node);

    if (isOutsideClick) {
      toggle();
      document.removeEventListener("mouseup", offDropdownClick, false);
    }
  };

  useEffect(() => {
    if (open) {
      // add when mounted
      document.addEventListener("mouseup", offDropdownClick);
      return () => {
        document.removeEventListener("mouseup", offDropdownClick);
      };
    }

    document.removeEventListener("mouseup", offDropdownClick);

    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mouseup", offDropdownClick);
    };
  }, [open]);

  const onSave = (formData: MoreFiltersFormData) => {
    const practitionerIdsStr = formData.practitioners ? formData.practitioners.join() : "";
    const reasonIdsStr = formData.reasons ? formData.reasons.join() : "";
    const activeStatusStr = formData.activeStatusFilters ? formData.activeStatusFilters.join() : "";

    onPractitionerChange(practitionerIdsStr);
    onReasonsChange(reasonIdsStr);
    onStatusFilterChange(activeStatusStr);

    if (showCheckinStatus) {
      const activeCheckinStatusStr = formData.activeCheckinStatusFilters.join();
      onCheckinFilterChange(activeCheckinStatusStr);
    }
    if (isMikataAdmin) {
      const includeFilteredOut = formData.includeFilteredOut;
      onIncludeFilteredOutChange(includeFilteredOut);
    }

    closeModal();
  };

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Form
        key={selectedPractitioners.join(",")}
        onSubmit={(formState) => onSave(formState.values as MoreFiltersFormData)}
        initialValues={initialFormState}
      >
        <FilterAppointmentsForm
          practitionerOptions={practitionerOptions}
          reasonOptions={reasonOptions}
          selectedActiveStatusFilters={selectedActiveStatusFilters}
          statusFilterOptions={statusFilterOptions}
          selectedActiveCheckinStatusFilters={selectedActiveCheckinStatusFilters}
          checkinFilterOptions={checkinFilterOptions}
          showCheckinStatus={showCheckinStatus}
          isMikataAdmin={isMikataAdmin}
        />
      </Form>
    </FloatModal>
  );
};

export default connect(null, {
  closeModal: closeModalAction
})(FilterAppointments);
