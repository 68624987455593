import React from "react";

type PropsType = {
  size?: number;
};

const Trash = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 5H3a1 1 0 000 2h1v13a3 3 0 003 3h10a3 3 0 003-3V7h1a1 1 0 100-2h-4V4a3 3 0 00-3-3h-4a3 3 0 00-3 3v1zm2 0h6V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v1zm7 2H6v13a1 1 0 001 1h10a1 1 0 001-1V7h-2zm-7 4v6a1 1 0 102 0v-6a1 1 0 10-2 0zm4 6v-6a1 1 0 112 0v6a1 1 0 11-2 0z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Trash;
