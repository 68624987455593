import React, { Dispatch, SetStateAction } from "react";
import cx from "classnames";
import isEqual from "lodash/isEqual";

import { useFormState } from "informed";
import {
  JsonLogicInput,
  RadioGroup,
  SelectInput,
  TextInput,
  TextAreaInput
} from "../../../../../../ui/Input";
import { ContextVariable } from "../../../../../../ui/Input/JsonLogicInput/types";
import Button from "../../../../../../ui/Button";
import Heading from "../../../../../../ui/Heading";
import { Trash } from "../../../../../../ui/Icon";
import Loader from "../../../../../../ui/Loader";

import DeprecatedContentSection from "./DeprecatedContentSection";
import CollapsibleReportTemplateCard from "./CollapsibleReportTemplateCard";

import { OpenModal } from "../../../../../../../actions";
import { isJson, isRequired } from "../../../../../../../utils/validators";
import { getCardTypeIcon } from "./helpers/getCardTypeIcon";
import { AdminModalTypes } from "../../../../../../../constants";
import { ReportTemplateCardFormValues } from "./ReportTemplateCardForm";
import {
  Option,
  ReportTemplateCard,
  ReportTemplateCardType,
  ReportTemplateCardTypeLabel,
  Tag
} from "../../../../../../../types";

import styles from "./reportTemplateCardForm.module.scss";

type PropsType = {
  card: ReportTemplateCard;
  viewOnly: boolean;
  contextVariables: ContextVariable[];
  reportTemplateCardAddLoading: {
    [id: string]: boolean;
  };
  reportTemplateCardUpdateLoading: {
    [id: string]: boolean;
  };
  initialValues: ReportTemplateCardFormValues;
  subReportTagOptions: Option[];
  contentMode: string;
  setContentMode: (contentMode: string) => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isSubReportSection: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  focusRef: React.MutableRefObject<any>;
  isSection: boolean;
  filteredSectionOptions: Option[];
  contentValidator: (value: string) => string | undefined;
  isSavedCard: boolean;
  openModal: OpenModal;
  setTemporaryCards: React.Dispatch<React.SetStateAction<ReportTemplateCard[]>>;
  availableSubReportTags: Array<Tag>;
};

const jsonValidator = isJson("Please enter a valid JSON string");
const sectionDisplayNameValidator = isRequired("Please enter a section name");

const getTypeOptions = (cardType: string) => {
  switch (cardType) {
    case ReportTemplateCardType.SECTION:
      return [{ label: "section", value: ReportTemplateCardType.SECTION }];
    case ReportTemplateCardType.SUB_REPORT_SECTION:
      return [{ label: "sub-report", value: ReportTemplateCardType.SUB_REPORT_SECTION }];
    case ReportTemplateCardType.HEADER:
      return [{ label: "header", value: ReportTemplateCardType.HEADER }];
    default:
      return [
        { label: "No space above", value: ReportTemplateCardType.TEXT },
        { label: "Add space above", value: ReportTemplateCardType.TEXT_NEWLINE },
        { label: "Continue on same line", value: ReportTemplateCardType.TEXT_INLINE }
      ];
  }
};

const ReportTemplateCardFormContent = ({
  card,
  contextVariables,
  viewOnly = false,
  initialValues,
  subReportTagOptions,
  contentMode,
  setContentMode,
  isOpen,
  setIsOpen,
  isSubReportSection,
  focusRef,
  isSection,
  filteredSectionOptions,
  contentValidator,
  isSavedCard,
  reportTemplateCardUpdateLoading,
  reportTemplateCardAddLoading,
  openModal,
  setTemporaryCards,
  availableSubReportTags
}: PropsType) => {
  const formState = useFormState();
  const values = formState.values as ReportTemplateCardFormValues;
  const stateValues = {
    parentId: values.parentId || "",
    order: values.order || "",
    displayName: values.displayName || "",
    condition: values.condition || null,
    thenContent: values.thenContent || "",
    elseContent: values.elseContent || "",
    type: values.type || "",
    content: values.content || "",
    categoryTagId: values.categoryTagId || null
  };
  const initialFieldValues = {
    parentId: initialValues.parentId,
    order: initialValues.order,
    displayName: initialValues.displayName,
    condition: initialValues.condition,
    thenContent: initialValues.thenContent,
    elseContent: initialValues.elseContent,
    type: initialValues.type,
    content: initialValues.content,
    categoryTagId: initialValues.categoryTagId
  };
  const hasUnsavedChanges = !isSavedCard || !isEqual(stateValues, initialFieldValues);
  const submitDisabled =
    reportTemplateCardUpdateLoading[card.id] ||
    reportTemplateCardAddLoading[card.id] ||
    (!hasUnsavedChanges && isSavedCard);

  return (
    <CollapsibleReportTemplateCard
      availableSubReportTags={availableSubReportTags}
      card={card}
      hasUnsavedChanges={hasUnsavedChanges}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      setFocusRef={(ref) => {
        focusRef.current = ref;
      }}
    >
      <div className={styles.Row}>
        <div className={styles.Header}>
          <div className={styles.HeaderIcon}>{getCardTypeIcon(card.type, { size: 24 })}</div>
          <Heading className={styles.SummaryText} size="M">
            {ReportTemplateCardTypeLabel[card.type]}
          </Heading>
        </div>
        <div className={styles.HeaderInputs}>
          <div className={styles.HeaderSectionSelect}>
            <SelectInput
              fieldName="parentId"
              disabled={
                viewOnly ||
                reportTemplateCardUpdateLoading[card.id] ||
                reportTemplateCardAddLoading[card.id]
              }
              options={[...filteredSectionOptions, { label: "No section", value: "" }]}
            />
          </div>
          <div className={styles.HeaderOrder}>
            <TextInput
              fieldName="order"
              disabled={
                viewOnly ||
                reportTemplateCardUpdateLoading[card.id] ||
                reportTemplateCardAddLoading[card.id]
              }
            />
          </div>
        </div>
      </div>
      {typeof card.id === "number" ? (
        <Heading size="META">Card Id: {card.id}</Heading>
      ) : (
        <Heading size="META">New Card</Heading>
      )}
      {isSection && (
        <TextInput
          fieldName="displayName"
          label="Display"
          validate={sectionDisplayNameValidator}
          disabled={
            viewOnly ||
            reportTemplateCardUpdateLoading[card.id] ||
            reportTemplateCardAddLoading[card.id]
          }
        />
      )}

      {isSubReportSection && (
        <div className={styles.SubReportTag}>
          <SelectInput
            fieldName="categoryTagId"
            label="tag name"
            options={subReportTagOptions}
            isClearable
          />
        </div>
      )}

      {!isSubReportSection && (
        <JsonLogicInput
          fieldName="condition"
          label="Rules"
          disabled={
            viewOnly ||
            reportTemplateCardUpdateLoading[card.id] ||
            reportTemplateCardAddLoading[card.id]
          }
          validate={jsonValidator}
          contextVariables={contextVariables}
        />
      )}

      {!isSection && !isSubReportSection && (
        <>
          <Heading size="META">Content</Heading>
          <div className={styles.Tabs}>
            <Button
              inline
              className={cx(styles.Tab, {
                [styles.TabActive]: contentMode === "thenContent"
              })}
              onClick={() => {
                setContentMode("thenContent");
              }}
            >
              <Heading size="META">Display</Heading>
            </Button>

            <Button
              inline
              className={cx(styles.Tab, {
                [styles.TabActive]: contentMode === "elseContent"
              })}
              onClick={() => {
                setContentMode("elseContent");
              }}
            >
              <Heading size="META">Display Else</Heading>
            </Button>
          </div>

          <div className={cx({ [styles.None]: contentMode !== "thenContent" })}>
            <TextAreaInput
              label="then content"
              fieldName="thenContent"
              validate={contentValidator}
              validateOnChange={false}
              disabled={
                viewOnly ||
                reportTemplateCardUpdateLoading[card.id] ||
                reportTemplateCardAddLoading[card.id]
              }
            />
          </div>

          <div className={cx({ [styles.None]: contentMode !== "elseContent" })}>
            <TextAreaInput
              fieldName="elseContent"
              validate={contentValidator}
              validateOnChange={false}
              disabled={
                viewOnly ||
                reportTemplateCardUpdateLoading[card.id] ||
                reportTemplateCardAddLoading[card.id]
              }
            />
          </div>
        </>
      )}
      <div
        className={cx({
          [styles.None]:
            card.type === ReportTemplateCardType.HEADER ||
            card.type === ReportTemplateCardType.SECTION ||
            card.type === ReportTemplateCardType.SUB_REPORT_SECTION
        })}
      >
        <RadioGroup
          fieldName="type"
          label="Spacing"
          options={getTypeOptions(card.type)}
          disabled={
            viewOnly ||
            reportTemplateCardUpdateLoading[card.id] ||
            reportTemplateCardAddLoading[card.id]
          }
        />
      </div>

      {card.content && <DeprecatedContentSection />}

      {!viewOnly && (
        <div className={styles.ButtonRow}>
          {reportTemplateCardUpdateLoading[card.id] || reportTemplateCardAddLoading[card.id] ? (
            <Loader small center />
          ) : (
            <>
              {(card.type !== ReportTemplateCardType.SECTION ||
                (card.type === ReportTemplateCardType.SECTION && card?.children?.length === 0)) && (
                <Button
                  inline
                  onClick={() => {
                    if (isSavedCard) {
                      openModal(AdminModalTypes.DELETE_REPORT_TEMPLATE_CARD, {
                        reportId: card.reportTemplateId,
                        cardId: card.id
                      });
                    } else {
                      setTemporaryCards((tempCards) => {
                        return tempCards.filter((tempCard) => tempCard.id !== card.id);
                      });
                    }
                  }}
                >
                  <Trash size={24} />
                </Button>
              )}
              <div className={styles.ButtonRowGroup}>
                {hasUnsavedChanges && (
                  <Heading className={styles.UnsavedChanges} size="META">
                    Unsaved Changes
                  </Heading>
                )}
                <Button id="saveReportTemplateCard" type="submit" disabled={submitDisabled}>
                  Save
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </CollapsibleReportTemplateCard>
  );
};

export default ReportTemplateCardFormContent;
