import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import moment from "moment";

import Button from "../../../ui/Button";
import Card from "../../../ui/Card";
import Heading from "../../../ui/Heading";
import Text from "../../../ui/Text";
import Loader from "../../../ui/Loader";

import { FloatModal } from "../../../ui/Modal";
import SecureMessageItemCard from "./SecureMessageItemCard";

import {
  closeModal as closeModalAction,
  fetchDirectMessageDetails as fetchDirectMessageDetailsAction
} from "../../../../actions";

import { ChatFlowTags, DirectMessageModes } from "../../../../constants";

import { AppointmentConversation, ReduxStateType, SecureMessageItemData } from "../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  conversation: AppointmentConversation;
  createdAt: string;
  conversationSiblings: Array<AppointmentConversation>;
  emailPayloadContent: string;
  senderDisplayName: string;
  smsPayloadContent: string;
  secureMessageItems: Array<SecureMessageItemData>;
  loading: boolean;
  fetchDirectMessageDetails: (conversationId: string) => void;
  closeModal: () => void;
};

const DirectMessageDetails = ({
  conversation,
  createdAt,
  conversationSiblings,
  emailPayloadContent,
  senderDisplayName,
  smsPayloadContent,
  secureMessageItems,
  loading,
  fetchDirectMessageDetails,
  closeModal
}: PropsType) => {
  const conversationId = conversation.id;
  const mode = conversation.tagnames?.includes(ChatFlowTags.SECURE_DIRECT_MESSAGE)
    ? DirectMessageModes.SECURE
    : DirectMessageModes.BASIC;
  useEffect(() => {
    if (conversationId && typeof conversationId === "number") {
      fetchDirectMessageDetails(conversationId.toString());
    }
  }, [conversationId]);

  const sortedItems = useMemo(
    () => secureMessageItems.sort((a) => (a.payloadContent !== null ? -1 : 1)), // Sort items to place text content first
    [secureMessageItems]
  );

  return (
    <FloatModal isOpen onClose={closeModal}>
      <div className={styles.DirectMessageDetailsHeader}>
        <Heading size="L">{conversation.chatFlowTitle}</Heading>
        <Text size="M">
          {`Sent on ${moment(createdAt).format("dddd, MMM Do - h:mma").toUpperCase()}`}
        </Text>
        <Text>{`by ${senderDisplayName}`}</Text>
      </div>

      <Heading size="META">MESSAGE CONTENT</Heading>
      {loading && <Loader small center />}
      {!loading && mode === DirectMessageModes.BASIC && (
        <Card className={styles.CardBorder}>
          <Text>{smsPayloadContent || emailPayloadContent}</Text>
        </Card>
      )}
      {!loading &&
        sortedItems &&
        sortedItems.length > 0 &&
        sortedItems.map((item: SecureMessageItemData) => (
          <SecureMessageItemCard key={`item_${item.id}`} cardData={{ ...item, conversationId }} />
        ))}
      {!loading && conversationSiblings && conversationSiblings.length > 0 && (
        <div>
          <Heading size="META">CHATS</Heading>
          <Card className={styles.CardBorder}>
            {conversationSiblings.map((convo) => {
              return <Text key={`convo_sib_${convo.id}`}>{convo.chatFlowTitle}</Text>;
            })}
          </Card>
        </div>
      )}
      <Button inline onClick={closeModal}>
        <Text size="M" className={styles.DirectMessageDetailsButton}>
          Close
        </Text>
      </Button>
    </FloatModal>
  );
};

const mapStateToProps = ({ messages }: ReduxStateType) => {
  return {
    conversationSiblings: messages.directMessageDetails.conversationSiblings,
    emailPayloadContent: messages.directMessageDetails.emailPayloadContent,
    senderDisplayName: messages.directMessageDetails.senderDisplayName,
    smsPayloadContent: messages.directMessageDetails.smsPayloadContent,
    secureMessageItems: messages.directMessageDetails.secureMessageItems,
    loading: messages.directMessageDetailsLoading
  };
};

export default connect(mapStateToProps, {
  fetchDirectMessageDetails: fetchDirectMessageDetailsAction,
  closeModal: closeModalAction
})(DirectMessageDetails);
