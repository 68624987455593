import React from "react";
import { useFormApi, useFormState } from "informed";

import Button from "../../../../../ui/Button";
import Heading from "../../../../../ui/Heading";
import { FullScreenModal } from "../../../../../ui/Modal";
import Text from "../../../../../ui/Text";
import { Trash, Send } from "../../../../../ui/Icon";
import { TextInput, TextAreaInput, SelectInput, TitleInput } from "../../../../../ui/Input";
import SmsMessageInfo from "./SmsMessageInfo";

import formatDate from "../../../../../../utils/formatDate";
import getTopicLabel from "../../../../../../utils/getTopicLabel";

import { MessageMediums, MessageTopics } from "../../../../../../constants";

import {
  MessageTemplateFormState,
  payloadContentValidator,
  displayNameValidator,
  subjectValidator,
  topicValidator
} from "../../../../../../utils/validators/editMessageTemplateFormValidator";

import { MessageTemplate, Option } from "../../../../../../types";

import {
  defaultEmailSubjects,
  getDefaultEmailSubject
} from "../../../../../../utils/getDefaultEmailSubject";

import styles from "./index.module.scss";

type PropsType = {
  organizationName: string;
  messageTemplate: MessageTemplate;
  isModalOpen: boolean;
  loading?: boolean;
  openSendTestModal: () => void;
  openDeleteModal: () => void;
  formDisabled?: boolean;
  payloadContentWarning: (value: string) => string | undefined;
  chatOptions: Option[];
  initialState: MessageTemplateFormState;
  closeModal: () => void;
};

const FormContent = ({
  organizationName,
  messageTemplate,
  isModalOpen,
  loading,
  initialState,
  openSendTestModal,
  openDeleteModal,
  formDisabled,
  payloadContentWarning,
  chatOptions,
  closeModal
}: PropsType) => {
  const formState = useFormState();
  const { touched } = formState;
  const values = formState.values as MessageTemplateFormState;
  const formApi = useFormApi();
  const hasFormChanges =
    values.displayName !== initialState.displayName ||
    values.payloadContent !== initialState.payloadContent ||
    values.chatFlowId !== initialState.chatFlowId ||
    values.medium !== initialState.medium ||
    values.subject !== initialState.subject ||
    touched.chatFlowId;

  const topicDisplay = getTopicLabel(messageTemplate.topic);
  const subjectPlaceholder = getDefaultEmailSubject(messageTemplate.topic);
  const emailPossible = Object.keys(defaultEmailSubjects).includes(messageTemplate.topic);

  return (
    <FullScreenModal
      title={messageTemplate?.displayName}
      isOpen={isModalOpen}
      subtitle={`${organizationName} > Edit Message`}
      onClose={closeModal}
      headerButton={
        <Button type="submit" onClick={formApi.submitForm} disabled={loading || !hasFormChanges}>
          Save
        </Button>
      }
      headerIconButtons={
        <>
          {messageTemplate &&
            (messageTemplate.medium === MessageMediums.SMS ||
              messageTemplate.medium === MessageMediums.ANY) && (
              <Button className={styles.IconButton} inline onClick={openSendTestModal}>
                <Send />
              </Button>
            )}
          <Button
            className={styles.IconButton}
            inline
            onClick={openDeleteModal}
            disabled={messageTemplate.isDefault}
          >
            <Trash />
          </Button>
        </>
      }
    >
      <div className={styles.HeadingContainer}>
        <Heading size="L" component="h1" className={styles.Heading}>
          Message Template Details
        </Heading>
      </div>
      <div className={styles.Wrapper}>
        <div className={styles.ContainerSide}>
          {messageTemplate && (
            <>
              <div className={styles.InfoGroup}>
                <Heading size="META" component="h5">
                  Created
                </Heading>
                <Text>{formatDate(messageTemplate.createdAt, "simpleDate")}</Text>
              </div>
              <div className={styles.InfoGroup}>
                <Heading size="META" component="h5">
                  Updated
                </Heading>
                <Text>{formatDate(messageTemplate.updatedAt)}</Text>
              </div>
            </>
          )}
        </div>

        <div className={styles.ContainerMain}>
          <div className={styles.Card}>
            <TitleInput
              fieldName="displayName"
              label="Display Name"
              disabled={formDisabled}
              validate={displayNameValidator}
              extendedWidth
            />
            <Heading size="S" component="h3" className={styles.TopicLabel}>
              Topic
            </Heading>
            <TextInput
              fieldName="topic"
              validate={topicValidator}
              placeholder={topicDisplay}
              disabled
            />
            <hr className={styles.Divider} />
            <Heading size="S" component="h3">
              Message content
            </Heading>
            {(emailPossible || messageTemplate.topic === MessageTopics.SCHEDULE_BASED_MESSAGE) && (
              <TextInput
                fieldName="subject"
                label="Subject"
                placeholder={subjectPlaceholder}
                disabled={formDisabled}
                validate={subjectValidator}
                info={
                  <span>Appears in the subject line of emails. Irrelevant for SMS messages.</span>
                }
              />
            )}
            <TextAreaInput
              id="content"
              fieldName="payloadContent"
              label="Content"
              size="M"
              info="Standard messages contain max.160 characters. Non-standard messages contain max.70 characters."
              labelDetails={<SmsMessageInfo messageContent={values.payloadContent} />}
              customOnChange={() => {
                // Clear form level error
                formApi.setError("payloadContent", undefined);
              }}
              validate={payloadContentValidator}
              disabled={formDisabled}
              warningMessage={payloadContentWarning(values.payloadContent)}
            />
            {!(messageTemplate.topic === MessageTopics.SCHEDULE_BASED_MESSAGE) && (
              <SelectInput
                fieldName="chatFlowId"
                label="Chat Template"
                options={chatOptions}
                placeholder="None"
              />
            )}
          </div>
        </div>
      </div>
    </FullScreenModal>
  );
};

export default FormContent;
