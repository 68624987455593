import React, { useState, createRef, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import cx from "classnames";

import Text from "../../../../ui/Text";
import { AdminOrganizationDetailsPageTabs, OrganizationTypes } from "../../../../../constants";
import { ChevronDown, ChevronUp } from "../../../../ui/Icon";

import styles from "./index.module.scss";

type PropsType = {
  organizationId: string;
  organizationType: string | null;
  verified?: boolean;
};

const { ACCOUNT, BILLING, FEATURES, INTEGRATION, PREFERENCES, SERVICES, USERS } =
  AdminOrganizationDetailsPageTabs;

const getAccountActive = (): boolean => {
  const url = window.location.href;
  const accountPathOptions = [
    "account-old",
    "account",
    "billing",
    "features",
    "integration",
    "preferences",
    "services",
    "users"
  ];
  // Returns true when the url contains a account path option
  return accountPathOptions.reduce<boolean>((acc, curr) => acc || url.includes(curr), false);
};

const AccountDropdown = ({
  organizationId,
  organizationType,
  verified
}: PropsType): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdown = createRef<HTMLDivElement>();
  const isChild = organizationType !== OrganizationTypes.PARENT;

  const handleClickOutside = (event: MouseEvent | null) => {
    if (
      dropdown.current &&
      event &&
      event.target instanceof HTMLElement &&
      !dropdown.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const handleNavClick = (e: React.MouseEvent, requiresVerifiedOrg: boolean) => {
    if (requiresVerifiedOrg && !verified) {
      e.preventDefault();
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleClickOutside);

    // returned function will be called on component unmount
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [dropdown]);

  const [isAccountActive, setIsAccountActive] = useState(getAccountActive());
  const location = useLocation();
  useEffect(() => {
    return () => {
      setIsAccountActive(getAccountActive());
    };
  }, [location]);

  return (
    <div className={styles.Container}>
      <button
        id="adminAccountDropDown"
        type="button"
        className={cx(styles.Account, { [styles.AccountActive]: isAccountActive })}
        onClick={handleButtonClick}
      >
        <span>Account</span>
        <span className={styles.Chevron}>
          {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
        </span>
      </button>

      {isOpen && (
        <nav className={styles.Dropdown} ref={dropdown}>
          <NavLink
            key={ACCOUNT.label}
            to={ACCOUNT.path.replace(":organizationId", organizationId)}
            className={({ isActive }) => (isActive ? styles.NavLinkActive : styles.NavLink)}
            onClick={(e) => handleNavClick(e, false)}
          >
            <Text className={styles.LinkText} size="S" component="span">
              {ACCOUNT.label}
            </Text>
          </NavLink>
          {isChild && (
            <>
              <NavLink
                key={SERVICES.label}
                to={SERVICES.path.replace(":organizationId", organizationId)}
                className={({ isActive }) =>
                  isActive
                    ? styles.NavLinkActive
                    : cx(styles.NavLink, {
                        [styles.NavLinkDisabled]: !verified
                      })
                }
                onClick={(e) => handleNavClick(e, true)}
              >
                <Text className={styles.LinkText} size="S" component="span">
                  {SERVICES.label}
                </Text>
              </NavLink>
              <NavLink
                key={INTEGRATION.label}
                to={INTEGRATION.path.replace(":organizationId", organizationId)}
                className={({ isActive }) =>
                  isActive
                    ? styles.NavLinkActive
                    : cx(styles.NavLink, {
                        [styles.NavLinkDisabled]: !verified
                      })
                }
                onClick={(e) => handleNavClick(e, true)}
              >
                <Text className={styles.LinkText} size="S" component="span">
                  {INTEGRATION.label}
                </Text>
              </NavLink>
              <NavLink
                key={FEATURES.label}
                to={FEATURES.path.replace(":organizationId", organizationId)}
                className={({ isActive }) => (isActive ? styles.NavLinkActive : styles.NavLink)}
                onClick={(e) => handleNavClick(e, false)}
              >
                <Text className={styles.LinkText} size="S" component="span">
                  {FEATURES.label}
                </Text>
              </NavLink>
              <NavLink
                key={PREFERENCES.label}
                to={PREFERENCES.path.replace(":organizationId", organizationId)}
                className={({ isActive }) =>
                  isActive
                    ? styles.NavLinkActive
                    : cx(styles.NavLink, {
                        [styles.NavLinkDisabled]: !verified
                      })
                }
                onClick={(e) => handleNavClick(e, true)}
              >
                <Text className={styles.LinkText} size="S" component="span">
                  {PREFERENCES.label}
                </Text>
              </NavLink>
              <NavLink
                id="adminUsersLink"
                key={USERS.label}
                to={USERS.path.replace(":organizationId", organizationId)}
                className={({ isActive }) => (isActive ? styles.NavLinkActive : styles.NavLink)}
                onClick={(e) => handleNavClick(e, false)}
              >
                <Text className={styles.LinkText} size="S" component="span">
                  {USERS.label}
                </Text>
              </NavLink>
            </>
          )}
          <NavLink
            key={BILLING.label}
            to={BILLING.path.replace(":organizationId", organizationId)}
            className={({ isActive }) => (isActive ? styles.NavLinkActive : styles.NavLink)}
            onClick={(e) => handleNavClick(e, false)}
          >
            <Text className={styles.LinkText} size="S" component="span">
              {BILLING.label}
            </Text>
          </NavLink>
        </nav>
      )}
    </div>
  );
};

export default AccountDropdown;
