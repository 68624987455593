import React from "react";

type PropsType = {
  size?: number;
};

const Skip = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 6.414C11 4.632 13.154 3.74 14.414 5L20 10.586a2 2 0 0 1 0 2.828L14.414 19c-1.26 1.26-3.414.368-3.414-1.414v-3.172L6.414 19C5.154 20.26 3 19.368 3 17.586V6.414C3 4.632 5.154 3.74 6.414 5L11 9.586V6.414ZM18.586 12 13 6.414v11.172L18.586 12Zm-8 0L5 6.414v11.172L10.586 12Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Skip;
