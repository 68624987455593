import React from "react";

import { useFormApi, useFormState } from "informed";
import Heading from "../../../ui/Heading";
import Text from "../../../ui/Text";
import { FullScreenModal } from "../../../ui/Modal";
import Button from "../../../ui/Button";
import { Collapse, Expand } from "../../../ui/Icon";

import { FormState } from "./formValidator";
import BookingReasonsConfig from "../../../common/Forms/BookingReasonConfig";
import { ContextVariable } from "../../../ui/Input/JsonLogicInput/types";
import { Reason, Location, Practitioner, ScheduleTag } from "../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  setForceClose: React.Dispatch<React.SetStateAction<boolean>>;
  forceClose: boolean;
  globalError: string;
  contextVariables: ContextVariable[];
  schedules: ScheduleTag[] | null;
  reasons: Array<Reason>;
  practitioners: Array<Practitioner>;
  locations: Array<Location>;
  closeModal: () => void;
};

const FormContent = ({
  setForceClose,
  forceClose,
  globalError,
  contextVariables,
  schedules,
  reasons,
  locations,
  practitioners,
  closeModal
}: PropsType) => {
  const formState = useFormState();
  const formApi = useFormApi();
  const values = formState.values as FormState;

  return (
    <FullScreenModal
      isOpen
      onClose={closeModal}
      headerButton={
        <Button type="submit" onClick={formApi.submitForm}>
          Save
        </Button>
      }
    >
      <div className={styles.Header}>
        <Heading size="L" component="h1">
          Booking Chat Configuration
        </Heading>
        <Button
          type="button"
          inline
          secondary
          onClick={() => {
            setForceClose(!forceClose);
          }}
        >
          {forceClose ? <Expand /> : <Collapse />}
        </Button>
      </div>
      <>
        <div>
          {globalError && (
            <Text className={styles.ErrorMessage} size="M">
              {globalError}
            </Text>
          )}
        </div>

        <BookingReasonsConfig
          schedules={schedules}
          reasons={reasons}
          practitioners={practitioners}
          locations={locations}
          values={values}
          forceClose={forceClose}
          setForceClose={setForceClose}
          contextVariables={contextVariables}
        />
      </>
    </FullScreenModal>
  );
};

export default FormContent;
