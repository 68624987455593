import moment from "moment";
import { useContext, useEffect } from "react";

import { EditorState } from "draft-js";

import { EncounterSocketContext } from "../../../../../providers/EncounterSocketProvider";

import {
  Insight,
  InsightNoteContentCategory,
  NewNotification,
  NotificationData,
  ScribeNotificationType
} from "../../../../../../types";
import { getMikaAnchorDataMap } from "../RichTextEditor/helpers";
import { MikaAnchorEntityData } from "../RichTextEditor/types";

const notificationTitleMap: { [key: string]: string } = {
  [ScribeNotificationType.LONG_RESPONSE_TIME]: `Hang tight... Mika is generating suggestions for [noteTitle], but it's taking a little longer than usual`,
  [ScribeNotificationType.VERY_LONG_RESPONSE_TIME]: `Mika is still generating suggestions for [noteTitle]. If you'd like, you can leave this session and come back later.`,
  [ScribeNotificationType.MULTIPLE_CONNECTIONS]:
    "We noticed multiple connections to [recordingStartedAt]."
};
const autoDismissMap: { [key: string]: boolean } = {
  [ScribeNotificationType.LONG_RESPONSE_TIME]: true,
  [ScribeNotificationType.VERY_LONG_RESPONSE_TIME]: true,
  [ScribeNotificationType.MULTIPLE_CONNECTIONS]: false
};

const subtitleMap: { [key: string]: string } = {
  [ScribeNotificationType.MULTIPLE_CONNECTIONS]:
    "Please ensure you have a single browser tab on a single device open to this appointment, then resume the session."
};

const getNotificationTitle = (
  notifType: string,
  noteTitle: string,
  recordingStartedAt: string | null | undefined
) => {
  const defaultTitle = `Hang tight... Mika is taking a little longer than usual`;
  return notificationTitleMap[notifType]
    ? notificationTitleMap[notifType]
        .replace("[noteTitle]", noteTitle)
        .replace(
          "[recordingStartedAt]",
          recordingStartedAt
            ? `the Mika session which started at ${moment(recordingStartedAt).format("h:mm A")}`
            : "this Mika session"
        )
    : defaultTitle;
};
const filterInsights = (
  insights: Insight[],
  anchorDataMap: { [anchorName: string]: MikaAnchorEntityData }
) => {
  return insights.filter((insight) => Object.keys(anchorDataMap).includes(insight.output.category));
};

const findMatchingNotifications = (
  notifications: NotificationData[],
  anchorDataMap: { [anchorName: string]: MikaAnchorEntityData }
) => {
  const notificationsForCurrentNote = notifications.filter(
    (notif: NotificationData) =>
      !notif.context.insightCategories ||
      (notif.context.insightCategories && notif.context.insightCategories.length === 0) ||
      notif.context.insightCategories?.some((insightCategory: string) =>
        Object.keys(anchorDataMap).includes(insightCategory)
      )
  );

  return notificationsForCurrentNote;
};

const hasMatchingInsight = (notification: NotificationData, insights: Insight[]): boolean => {
  const { insightCategories } = notification.context;
  return (
    insightCategories !== undefined &&
    insightCategories.some((insightCategory: string) => {
      const filteredInsightsCategories = insights.map((insight) => insight.output.category);

      return filteredInsightsCategories.includes(insightCategory as InsightNoteContentCategory);
    })
  );
};

const hasVeryLongResponseNotification = (notifications: NotificationData[]) => {
  return notifications.some(
    (notif: NotificationData) => notif.type === ScribeNotificationType.VERY_LONG_RESPONSE_TIME
  );
};

const useScribeNotifications = (
  addNotification: (notification: NewNotification) => void,
  noteId: number,
  noteTitle: string,
  editorState: EditorState | null,
  isActive: boolean
) => {
  const { insights, notifications, clearScribeNotifications, recording } =
    useContext(EncounterSocketContext);

  useEffect(() => {
    if (!isActive || notifications.length === 0) return;

    const anchorDataMap = getMikaAnchorDataMap(noteId, editorState);
    const matchingNotifications = findMatchingNotifications(notifications, anchorDataMap);
    const filteredInsights = filterInsights(insights, anchorDataMap);

    const notificationsToClear: NotificationData[] = [];
    const notificationsToDisplay: NotificationData[] = [];

    matchingNotifications.forEach((matchingNotification) => {
      const notificationHasMatchingInsight = hasMatchingInsight(
        matchingNotification,
        filteredInsights
      );

      switch (matchingNotification.type) {
        case ScribeNotificationType.LONG_RESPONSE_TIME: {
          if (
            notificationHasMatchingInsight ||
            hasVeryLongResponseNotification(matchingNotifications)
          ) {
            notificationsToClear.push(matchingNotification);
            break;
          }
          notificationsToClear.push(matchingNotification);
          notificationsToDisplay.push(matchingNotification);
          break;
        }
        case ScribeNotificationType.VERY_LONG_RESPONSE_TIME: {
          if (notificationHasMatchingInsight) {
            notificationsToClear.push(matchingNotification);
            break;
          }
          notificationsToClear.push(matchingNotification);
          notificationsToDisplay.push(matchingNotification);
          break;
        }
        case ScribeNotificationType.MULTIPLE_CONNECTIONS: {
          if (
            !notificationsToDisplay
              .map((notif) => notif.type)
              .includes(ScribeNotificationType.MULTIPLE_CONNECTIONS)
          ) {
            notificationsToDisplay.push(matchingNotification);
            notificationsToClear.push(matchingNotification);
            break;
          }
          notificationsToClear.push(matchingNotification);
          break;
        }
        default: {
          break;
        }
      }
    });

    clearScribeNotifications(notificationsToClear);

    notificationsToDisplay.forEach(async (notifToShow: NotificationData) => {
      addNotification({
        type: "warning",
        title: getNotificationTitle(notifToShow.type, noteTitle, recording?.startedAt),
        subtitle: subtitleMap[notifToShow.type],
        autoDismiss: notifToShow.type in autoDismissMap ? autoDismissMap[notifToShow.type] : true
      });
    });
  }, [notifications.length, insights.length, isActive]);
};

export default useScribeNotifications;
