import React, { useState } from "react";
import { connect } from "react-redux";

import {
  XYPlot,
  VerticalGridLines,
  HorizontalGridLines,
  XAxis,
  YAxis,
  VerticalBarSeries,
  ChartLabel
} from "react-vis";

import styles from "./index.module.scss";

import Heading from "../../../../../components/ui/Heading";
import Text from "../../../../../components/ui/Text";
import Button from "../../../../../components/ui/Button";

import { setRequestsReasonsCountFilter as setRequestsReasonsCountFilterAction } from "../../../../../actions/analytics";

import { ReduxStateType } from "../../../../../types";

type PropsType = {
  requestsReasonsCount?: { x: string; y: number }[];
  requestsCount?: number;
  requisitionsCount?: number;
  requestsCountLoading?: boolean;
  requisitionsCountLoading?: boolean;
  setRequestsReasonsCountFilter: (filter: string) => void;
};

type DataPoint = {
  x?: string;
  y?: number;
};

const RequestsCompleted = ({
  requestsReasonsCount,
  requestsCount,
  requisitionsCount,
  requestsCountLoading,
  requisitionsCountLoading,
  setRequestsReasonsCountFilter
}: PropsType) => {
  const [hoverPoint, setHoverPoint] = useState<DataPoint>({});

  let percentageWithRequisition = 0;
  if (requestsCount && requisitionsCount) {
    percentageWithRequisition = Math.round((requisitionsCount / requestsCount) * 100);
  }

  const yMax = requestsReasonsCount?.length && requestsReasonsCount.sort((a, b) => b.y - a.y)[0].y;
  const yAxisMax = yMax ? Math.round(Number(yMax)) * 1.1 : 10;

  return (
    <div className={styles.Card}>
      <div className={styles.Wrapper}>
        <Heading size="M" component="h2">
          Requests Completed
        </Heading>
        <div>
          <Text>Total: {requestsCountLoading ? "..." : requestsCount}</Text>
          {hoverPoint.x && !requestsCountLoading && (
            <Text>
              {hoverPoint.x}: {hoverPoint.y}
            </Text>
          )}
        </div>
        <div>
          <Button onClick={() => setRequestsReasonsCountFilter("all")} inline>
            <Text>All</Text>
          </Button>
          <Button onClick={() => setRequestsReasonsCountFilter("booked")} inline>
            <Text>Booked</Text>
          </Button>
          <Button onClick={() => setRequestsReasonsCountFilter("unableToBook")} inline>
            <Text>Unable to book</Text>
          </Button>
        </div>
      </div>
      <XYPlot height={400} width={1000} xType="ordinal" margin={100} yDomain={[0, yAxisMax]}>
        <XAxis
          tickLabelAngle={-25}
          tickFormat={(label) => {
            const maxLength = 20;
            return label.length > maxLength ? `${label.slice(0, maxLength - 1)}...` : label;
          }}
        />
        <YAxis
          style={{
            line: { stroke: "#ADDDE1" }
          }}
        />
        <ChartLabel
          text="Count of requests"
          className="alt-y-label"
          includeMargin
          xPercent={0.03}
          yPercent={-0.1}
          style={{
            transform: "rotate(-90)",
            textAnchor: "end"
          }}
        />
        <VerticalGridLines />
        <HorizontalGridLines />
        <VerticalBarSeries
          data={requestsReasonsCount}
          barWidth={0.5}
          animation="noWobble"
          onValueMouseOver={(datapoint) => {
            setHoverPoint(datapoint as DataPoint);
          }}
          onValueMouseOut={() => {
            // This isn't triggering, and the workarounds don't seem to work
            // https://github.com/uber/react-vis/issues/518
            setHoverPoint({});
          }}
        />
      </XYPlot>
      <div className={styles.Divider} />
      <Text size="M">
        Requests with requisition attached: {requisitionsCountLoading ? "..." : requisitionsCount} (
        {percentageWithRequisition}%)
      </Text>
    </div>
  );
};

const mapStateToProps = ({ analytics }: ReduxStateType) => {
  const {
    requestsReasonsCount,
    requestsCount,
    requisitionsCount,
    requestsCountLoading,
    requisitionsCountLoading
  } = analytics;
  return {
    requestsReasonsCount,
    requestsCount,
    requisitionsCount,
    requestsCountLoading,
    requisitionsCountLoading
  };
};

export default connect(mapStateToProps, {
  setRequestsReasonsCountFilter: setRequestsReasonsCountFilterAction
})(RequestsCompleted);
