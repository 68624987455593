import React, { useEffect, useContext, useCallback } from "react";
import { connect } from "react-redux";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

import Text from "../../../../../../../ui/Text";
import Heading from "../../../../../../../ui/Heading";
import { MultiButton } from "../../../../../../../ui/Button/MultiButton";
import { Clipboard, Upload, Message } from "../../../../../../../ui/Icon";

import { EncounterSocketContext } from "../../../../../../../providers/EncounterSocketProvider";

import formatDate from "../../../../../../../../utils/formatDate";
import getPatientFullName from "../../../../../../../../utils/getPatientFullName";

import {
  openModal as openModalAction,
  OpenModal,
  syncNote as syncNoteAction,
  addNotification as addNotificationAction
} from "../../../../../../../../actions";

import { EMRFeatures, ModalTypes, NoteTemplateActionTags } from "../../../../../../../../constants";

import { NoteEditorModes } from "../../types";
import {
  ReduxStateType,
  Note,
  NotesSyncLoading,
  DirectPatientMessagingModalProps,
  AppointmentDetail,
  SocketMessageType,
  EventData,
  EventTypes,
  NewNotification
} from "../../../../../../../../types";

import styles from "./index.module.scss";

export type ToolbarActionGroupProps = {
  note?: Note;
  patientFullName: string;
  syncLoading?: NotesSyncLoading;
  isIntegrated?: boolean;
  emrAppointmentId?: string;
  emrFeatures?: string[];
  mode: "edit" | "view";
  addNotification?: (notification: NewNotification) => void;
  appointmentData?: AppointmentDetail;
  syncNote?: (note: Note, currentContent: string) => void;
  openModal: OpenModal;
  editorState: EditorState;
};

const contentTypeHtml = "text/html";
const contentTypePlain = "text/plain";

const ToolbarActionGroup = ({
  note,
  patientFullName,
  syncLoading,
  isIntegrated,
  emrAppointmentId,
  emrFeatures,
  addNotification,
  mode,
  editorState,
  appointmentData,
  syncNote,
  openModal
}: ToolbarActionGroupProps) => {
  const { recording, sendMessage } = useContext(EncounterSocketContext);

  const sendProviderActivityMessage = useCallback(
    (eventType: string, eventData: EventData = {}) => {
      sendMessage(SocketMessageType.providerActivity, {
        eventType,
        eventData: { ...eventData, activeNoteId: note?.id }
      });
    },
    [note]
  );

  const isSyncing = syncLoading && note && syncLoading[note.id];
  const canPushTextReport =
    emrFeatures &&
    emrFeatures.some(
      (feature: string) =>
        feature === EMRFeatures.PUSH_RTF_REPORT || feature === EMRFeatures.PUSH_HTML_REPORT
    );
  const canSendToEmr =
    isIntegrated && emrAppointmentId && canPushTextReport && mode === NoteEditorModes.EDIT;
  const sendToEmrDisabled = isSyncing || recording?.status === "recording";

  const hasDpmTag = note?.tags?.includes(NoteTemplateActionTags.SEND_DPM);

  const dpmContentSuggestion = editorState.getCurrentContent().getPlainText().trim();
  const sendDirectMessageModalProps: DirectPatientMessagingModalProps | undefined = appointmentData
    ? {
        patientDisplayName: patientFullName,
        patientId: appointmentData?.patientId,
        appointmentId: appointmentData?.id,
        mobilePhone: appointmentData?.verifiedPhoneNumber,
        emailAddress: appointmentData?.email,
        canSendSecureMessage: appointmentData?.canSendSecureMessage || false,
        dpmContentSuggestion,
        sendProviderActivityMessage
      }
    : undefined;

  const copyToClipboard = async () => {
    const currentContent = convertToRaw(editorState.getCurrentContent());

    const getCurrentContentAsPlain = editorState.getCurrentContent().getPlainText();
    const blobPlain = new Blob([getCurrentContentAsPlain], { type: contentTypePlain });

    const currentContentAsHtml = draftToHtml(currentContent);
    const blobHtml = new Blob([currentContentAsHtml], { type: contentTypeHtml });

    sendProviderActivityMessage(EventTypes.COPY_NOTE_TO_CLIPBOARD_BUTTON, {
      activeNoteId: note?.id,
      copiedTextLength: getCurrentContentAsPlain.length,
      noteTemplateId: note?.noteTemplateId
    });

    await navigator.clipboard.write([
      new ClipboardItem({
        [blobHtml.type]: blobHtml,
        [blobPlain.type]: blobPlain
      })
    ]);
    if (addNotification) {
      addNotification({
        type: "success",
        title: "Copied",
        autoDismiss: true
      });
    }
  };

  const sync = () =>
    openModal(ModalTypes.CONFIRMATION, {
      onConfirmation: () => {
        if (note && syncNote) {
          sendMessage(SocketMessageType.providerActivity, {
            eventType: EventTypes.PUSH_NOTE_TO_EMR,
            eventData: { activeNoteId: note.id, noteTemplateId: note.noteTemplateId }
          });
          const currentContent = convertToRaw(editorState.getCurrentContent());
          syncNote(note, JSON.stringify(currentContent));
        }
      },
      content: (
        <>
          <Text>
            Your encounter note for {patientFullName} will be sent to your EMR. You will no longer
            be able to edit it here.
          </Text>
          <br />
          <Text>You can still edit your note in the EMR.</Text>
        </>
      )
    });

  let openDPM = () => {
    openModal(ModalTypes.SEND_DIRECT_MESSAGE, sendDirectMessageModalProps);
  };

  useEffect(() => {
    openDPM = () => {
      openModal(ModalTypes.SEND_DIRECT_MESSAGE, sendDirectMessageModalProps);
    };
  }, [sendDirectMessageModalProps]);

  const actionButtonOptions = [];

  if (canSendToEmr) {
    actionButtonOptions.push({
      label: "Send to EMR",
      onClick: sync,
      disabled: sendToEmrDisabled,
      icon: <Upload size={16} />
    });
  }
  actionButtonOptions.push({
    label: "Copy",
    onClick: copyToClipboard,
    icon: <Clipboard size={16} />
  });
  if (hasDpmTag) {
    actionButtonOptions.push({
      label: "Send to Patient",
      onClick: openDPM,
      disabled: sendToEmrDisabled,
      icon: <Message size={16} />
    });
  }

  return (
    <div className={styles.ToolbarActionGroup}>
      <MultiButton
        buttonOptions={actionButtonOptions}
        className={styles.ToolbarActionGroupButton}
        customMultiIcon={<Upload size={16} />}
      />
      {note?.lastSyncedAt && (
        <Heading size="META" align="right" className={styles.LastSyncedAt}>
          {`synced on ${formatDate(note.lastSyncedAt, "simpleDate")}`}
        </Heading>
      )}
    </div>
  );
};

const mapStateToProps = ({ appointments, notes, organizationData }: ReduxStateType) => {
  const appointmentData = appointments.details.data;
  const patientFirstName = appointmentData?.patientFirstName || "";
  const patientLastName = appointmentData?.patientLastName || "";

  const patientFullName = getPatientFullName(patientFirstName, patientLastName);

  const orgDataToUse = organizationData.organizationData || {};

  return {
    patientFullName,
    syncLoading: notes.syncLoading,
    appointmentData,
    emrAppointmentId: appointmentData?.emrAppointmentId,
    isIntegrated: orgDataToUse.integrated || false,
    emrFeatures: orgDataToUse.emrFeatures || []
  };
};

export default connect(mapStateToProps, {
  openModal: openModalAction,
  syncNote: syncNoteAction,
  addNotification: addNotificationAction
})(ToolbarActionGroup);
