import React from "react";
import { connect } from "react-redux";

import Button from "../../../ui/Button";
import Heading from "../../../ui/Heading";
import { FloatModal } from "../../../ui/Modal";
import Text from "../../../ui/Text";
import { Form, CheckboxGroup } from "../../../ui/Input";

import {
  deleteService as deleteServiceAction,
  DeleteServiceData,
  closeModal as closeModalAction
} from "../../../../actions";

import { isRequired } from "../../../../utils/validators";

import styles from "./index.module.scss";

import { ReduxStateType, Service } from "../../../../types";

type DeleteServiceFormData = {
  confirmed: Array<string>;
};

type PropsType = {
  loading?: boolean;
  closeModal: () => void;
  deleteService: (data: DeleteServiceData) => void;
  service: Service;
};

const initialFormState: DeleteServiceFormData = {
  confirmed: []
};

const confirmedValidator = isRequired("Confirmation required");

const DeleteService = ({ loading, closeModal, service, deleteService }: PropsType) => {
  const onDelete = () => {
    deleteService({
      serviceId: service.id,
      organizationId: service.organizationId
    });
    closeModal();
  };

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L">Delete Service Provider</Heading>
      <Text component="p">{`You are about to permanently delete the "${service.provider}" service for this organization. Is this what you want to do?`}</Text>
      <Form className={styles.Form} onSubmit={onDelete} initialValues={initialFormState}>
        <CheckboxGroup
          fieldName="confirmed"
          options={[{ label: "Yes, I want to delete", value: "true" }]}
          validate={confirmedValidator}
        />
        <div className={styles.ButtonGroup}>
          <Button inline onClick={closeModal} disabled={loading}>
            Cancel
          </Button>
          <Button type="submit" disabled={loading}>
            Delete
          </Button>
        </div>
      </Form>
    </FloatModal>
  );
};
const mapStateToProps = ({ services }: ReduxStateType) => {
  return {
    loading: services.servicesDeleteLoading
  };
};

export default connect(mapStateToProps, {
  deleteService: deleteServiceAction,
  closeModal: closeModalAction
})(DeleteService);
