import React from "react";

type PropsType = {
  size?: number;
};

const Upload = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.637 11c.299 0 .534.086.705.258.172.173.258.4.258.685v6.682c0 .809-.276 1.49-.829 2.044-.552.554-1.231.831-2.038.831H6.267c-.807 0-1.486-.277-2.038-.831-.553-.554-.829-1.235-.829-2.044v-6.682c0-.284.086-.512.258-.685.171-.172.406-.258.705-.258.284 0 .511.086.683.258.172.173.258.4.258.685v6.682c0 .285.09.513.268.685.18.172.411.258.695.258h11.466c.284 0 .515-.086.695-.258.179-.172.268-.4.268-.685v-6.682c0-.284.086-.512.258-.685.172-.172.4-.258.683-.258zM8.823 7.454a.929.929 0 01-.677.292.929.929 0 01-.676-.292.967.967 0 01-.27-.677c0-.255.09-.48.27-.676l3.854-3.83a.468.468 0 01.146-.102.468.468 0 00.147-.101A1.49 1.49 0 0112.045 2c.135 0 .248.023.338.068.045 0 .094.022.147.067.052.045.101.09.146.135l3.854 3.831c.18.196.27.421.27.676 0 .256-.09.481-.27.677a1.03 1.03 0 01-.316.225.908.908 0 01-.36.067.971.971 0 01-.384-.067.81.81 0 01-.293-.225L12.97 5.245v9.183c0 .3-.09.537-.27.71-.18.172-.414.259-.699.259-.286 0-.518-.087-.699-.26-.18-.172-.27-.409-.27-.71V5.246L8.823 7.454z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Upload;
