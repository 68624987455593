/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";

import Button from "../Button";
import Text from "../Text";
import Status from "../Status";

import styles from "./index.module.scss";

import { StatusComponentColorModes, StatusComponentConfigMap, Option } from "../../../types";

type PropsType = {
  onChange: (value: string) => void;
  options: Array<Option & { exclude?: boolean }>;
  value: string;
  loading?: boolean;
  configMap?: StatusComponentConfigMap;
  defaultColor?: StatusComponentColorModes;
  className?: string;
  wrappingClassName?: string;
  placeholder?: string;
  id?: string;
  inline?: boolean;
  inlineText?: string;
  size?: "M" | "S";
};

const Dropdown = ({
  onChange,
  value,
  options,
  configMap,
  defaultColor = "gray",
  wrappingClassName,
  className,
  id,
  placeholder,
  loading = false,
  inline,
  inlineText,
  size = "M"
}: PropsType) => {
  const elementRef = useRef<any>();
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((isOpen) => !isOpen);
  };

  const offDropdownClick = (event: any) => {
    const dropdownEl = elementRef.current;
    const isOutsideClick = dropdownEl && !dropdownEl.contains(event.target);

    if (isOutsideClick) {
      toggle();
      document.removeEventListener("mouseup", offDropdownClick, false);
    }
  };

  const handleOnClick = (option: Option) => {
    onChange(option.value.toString());
    toggle();
  };

  useEffect(() => {
    if (open) {
      // add when mounted
      document.addEventListener("mouseup", offDropdownClick);
      return () => {
        document.removeEventListener("mouseup", offDropdownClick);
      };
    }

    document.removeEventListener("mouseup", offDropdownClick);

    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mouseup", offDropdownClick);
    };
  }, [open]);

  const configMapping: StatusComponentConfigMap =
    configMap ||
    options.reduce((config, option) => {
      // eslint-disable-next-line no-param-reassign
      config[option.value.toString()] = defaultColor;
      return config;
    }, {} as StatusComponentConfigMap);

  const includedOptions = options.filter((option) => !option.exclude);

  return (
    <div ref={elementRef} className={cx(styles.DropdownWrapper, wrappingClassName)}>
      {inline ? (
        <div className={cx({ [styles.DropdownLoading]: loading })}>
          <Button
            type="button"
            inline
            onClick={() => {
              toggle();
            }}
          >
            {inlineText}
          </Button>
        </div>
      ) : (
        <button
          id={id ? `dropdown-${id}` : undefined}
          type="button"
          className={cx(
            styles.Dropdown,
            { [styles.DropdownLoading]: loading, [styles.DropdownSmall]: size === "S" },
            className
          )}
          disabled={loading}
          onClick={() => {
            toggle();
          }}
        >
          <Status
            value={value}
            placeholder={placeholder}
            options={options}
            configMap={configMapping}
            className={cx(styles.DropdownStatus, { [styles.DropdownStatusActive]: open })}
            defaultColor={defaultColor}
          />
        </button>
      )}
      <div className={cx(styles.DropdownList, { [styles.DropdownListOpen]: open })}>
        <div className={cx(styles.DropdownListScrollContainer)}>
          {includedOptions.map((option) => (
            <button
              id={id ? `dropdown-${id}-${option.label.replace(/\s/g, "")}` : undefined}
              type="button"
              className={styles.DropdownListItem}
              key={option.value}
              onClick={() => handleOnClick(option)}
              onKeyPress={(e) => {
                if (e.nativeEvent.keyCode === 13) {
                  handleOnClick(option);
                  toggle();
                }
              }}
            >
              <Text bold size="S" component="span">
                {option.label}
              </Text>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
