import React, { useState } from "react";
import uniqueBy from "lodash/uniqBy";
import CreatableSelect from "react-select/creatable";
import Select, { OnChangeValue } from "react-select";

import { PLACEHOLDER_OPERATOR, ContextVariableOption, ElementInputValue } from "../types";

import { styles } from "./styles";

export type Options = Array<ContextVariableOption>;

type PropsType = {
  elementType:
    | "operator"
    | "variable"
    | "comparator"
    | "compositionZero"
    | "compositionOne"
    | "compositionTwo";
  value: ElementInputValue;
  options: Options;
  onChange: (value: ElementInputValue) => void;
  placeholder?: string;
  isMulti?: boolean;
  isExtensible?: boolean;
  isClearable?: boolean;
  disabled?: boolean;
};

const getInitialNewOptions = (
  isMulti: boolean,
  value: ElementInputValue
): ContextVariableOption[] => {
  if (!value) return [];

  if (isMulti) {
    const initialValue = value && Array.isArray(value) ? value : [];
    return [
      ...initialValue.map((optionValue: string) => ({
        label: optionValue,
        value: optionValue
      }))
    ];
  }

  return value !== PLACEHOLDER_OPERATOR.value
    ? [{ label: String(value), value: String(value) }]
    : [];
};

const ElementInput = ({
  elementType,
  options,
  onChange,
  isClearable,
  isExtensible,
  isMulti = false,
  placeholder,
  value,
  disabled,
  ...props
}: PropsType): JSX.Element => {
  const [newOptions, setNewOptions] = useState<ContextVariableOption[]>(
    getInitialNewOptions(isMulti, value)
  );

  const onChangeHandler = (selection: OnChangeValue<ContextVariableOption, boolean>): void => {
    let inputValue: string | string[] = "";
    if (isMulti) {
      inputValue = (
        ((Array.isArray(selection) ? selection : [selection]) || []) as ContextVariableOption[]
      ).map((option) => (option && String(option.value)) || "");
    } else if (selection) {
      inputValue = String((selection as ContextVariableOption).value);
    }
    onChange(inputValue);
  };

  const onCreateOptionHandler = (inputValue: string): void => {
    let newValue: OnChangeValue<ContextVariableOption, boolean>;
    if (isMulti && Array.isArray(value)) {
      newValue = [
        ...(value || []).map((optionValue: string) => ({
          label: optionValue,
          value: optionValue
        })),
        { label: inputValue, value: inputValue }
      ];
    } else newValue = { label: inputValue, value: inputValue };

    setNewOptions([...newOptions, { label: inputValue, value: inputValue }]);
    onChangeHandler(newValue);
  };

  const allOptions = uniqueBy([...options, ...newOptions], "value");
  const selectedValue =
    isMulti && Array.isArray(value)
      ? allOptions.filter((option) => value && value.includes(String(option.value)))
      : allOptions.find((option) => option.value === value);

  return isExtensible ? (
    <CreatableSelect
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      styles={styles({ elementType })}
      isClearable={isClearable}
      isMulti={isMulti}
      options={options}
      placeholder={placeholder}
      aria-required="true"
      isDisabled={disabled}
      onChange={onChangeHandler}
      onCreateOption={onCreateOptionHandler}
      value={selectedValue || null}
    />
  ) : (
    <Select
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      styles={styles({ elementType })}
      isClearable={isClearable}
      isMulti={isMulti}
      options={options}
      placeholder={placeholder}
      aria-required="true"
      isDisabled={disabled}
      onChange={onChangeHandler}
      value={selectedValue || null}
    />
  );
};

export default ElementInput;
