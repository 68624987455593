import React, { useState, useMemo } from "react";
import cx from "classnames";

import { useFormApi, useFormState } from "informed";

import AdvancedFilterLayout from "../../../../../ui/AdvancedFilterLayout";
import { FiltersInput } from "../../../../../ui/Filters";
import { CheckboxGroup } from "../../../../../ui/Input";
import Button from "../../../../../ui/Button";

import { ALL } from "../../../../../../constants";
import { Option } from "../../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  bookingTypeOptions: Array<Option>;
  selectedBookingTypes: string[];
  providerOptions: Array<Option>;
  locationOptions: Array<Option>;
  reasonOptions: Array<Option>;
  isFilterOpen: boolean;
  onFilterClose: () => void;
};

type FormState = {
  requestBookingMode: string[];
  locations: string[];
  reasons: string[];
  practitionerIds: number[];
};

const AdvancedInboxFiltersForm = ({
  bookingTypeOptions,
  selectedBookingTypes,
  providerOptions,
  locationOptions,
  reasonOptions,
  isFilterOpen,
  onFilterClose
}: PropsType) => {
  const formApi = useFormApi();

  const formState = useFormState();

  const { requestBookingMode, locations, reasons, practitionerIds } = formState.values as FormState;

  const [activeFilterLabel, setActiveFilterLabel] = useState<string>("Booking type");

  const labelConfig = useMemo(() => {
    return {
      "Booking type": requestBookingMode?.filter((item) => item !== ALL)?.length,
      Location: locations?.length,
      Reason: reasons?.length,
      Provider: practitionerIds?.length
    };
  }, [requestBookingMode, locations, reasons, practitionerIds]);

  return (
    <AdvancedFilterLayout
      labelConfig={labelConfig}
      headerLabel="Filters"
      isFilterOpen={isFilterOpen}
      onFilterClose={onFilterClose}
      activeLabel={activeFilterLabel}
      onLabelClick={setActiveFilterLabel}
      footerContent={
        <>
          <div className={styles.Divider} />
          <div className={styles.ButtonWrapper}>
            <Button
              id="clear-filters"
              type="button"
              inline
              onClick={() => {
                formApi.setValues({
                  requestBookingMode: [ALL],
                  practitionerIds: [],
                  locations: [],
                  reasons: []
                });
              }}
            >
              Clear all
            </Button>

            <Button type="submit">Apply</Button>
          </div>
        </>
      }
    >
      <div
        className={cx(styles.FilterWrapper, {
          [styles.HideFormElement]: activeFilterLabel !== "Booking type"
        })}
      >
        <FiltersInput
          selectedFilters={selectedBookingTypes}
          filterOptionClassName={styles.Filter}
          filterBadgeClassName={styles.FilterBadge}
          filterOptions={bookingTypeOptions}
          fieldName="requestBookingMode"
        />
      </div>
      <div
        className={cx(styles.FilterWrapper, {
          [styles.HideFormElement]: activeFilterLabel !== "Location"
        })}
      >
        <CheckboxGroup
          fieldName="locations"
          narrowList
          options={locationOptions}
          searchPlaceholder="Find..."
          searchSize="M"
          showCheckAll
        />
      </div>
      <div
        className={cx(styles.FilterWrapper, {
          [styles.HideFormElement]:
            activeFilterLabel !== "Reason" || !reasonOptions || reasonOptions.length === 0
        })}
      >
        <CheckboxGroup
          fieldName="reasons"
          narrowList
          options={reasonOptions}
          searchPlaceholder="Find..."
          searchSize="M"
          showCheckAll
        />
      </div>
      <div
        className={cx(styles.FilterWrapper, {
          [styles.HideFormElement]: activeFilterLabel !== "Provider"
        })}
      >
        <CheckboxGroup
          fieldName="practitionerIds"
          narrowList
          options={providerOptions}
          searchPlaceholder="Find..."
          searchSize="M"
          isValueTypeNumber
          showCheckAll
        />
      </div>
    </AdvancedFilterLayout>
  );
};

export default AdvancedInboxFiltersForm;
