import computedContextVariables from "../../../../mikata_admin/OrganizationDetails/views/ChatsTable/ChatDetails/ChatBuilder/EdgesOutComponent/computedContextVariables";
import { defaultConditionOperators } from "../defaultConfigurations";
import { ComparatorFieldType } from "../types";

type JSONValue = null | boolean | number | string | JSONObject;
interface JSONObject {
  [key: string]: JSONValue;
}

export const convertLogicToReadableString = (
  jsonToConvert: JSONObject,
  isGroupedLogic = false
): string | null => {
  const json = JSON.parse(JSON.stringify(jsonToConvert));
  if (json === null || typeof json !== "object") {
    return String(json);
  }

  const operator = Object.keys(json)[0];
  const operands = json[operator];

  if (operator === "var") {
    return String(operands) as string;
  }

  const multiOperators = defaultConditionOperators
    .filter((operatorConfig) => operatorConfig.comparatorFieldType === ComparatorFieldType.ARRAY)
    .map((operatorConfig) => operatorConfig.jsonLogicKey);
  if (multiOperators.includes(operator)) {
    operands[1] = String(operands[1]);
  }

  if (!Array.isArray(operands)) {
    throw new Error(`Invalid JSON: expected an array for operator "${operator}"`);
  }

  const operandStrings = operands.map((operand) =>
    convertLogicToReadableString(operand, ["and", "or"].includes(operator))
  );

  let resultString = "";
  if (isGroupedLogic) {
    resultString += "(";
  }

  // Can leave label as inputName, but computedContextVariables have human readable labels
  let operandLabel = operandStrings[0];
  const computedContextVariable = computedContextVariables.find(
    (variable) => variable.inputName === operandLabel
  );
  if (computedContextVariable && computedContextVariable.label) {
    operandLabel = computedContextVariable.label;
  }

  switch (operator) {
    case "and":
      resultString += operandStrings.join(" AND ");
      break;
    case "or":
      resultString += operandStrings.join(" OR ");
      break;
    case "not":
      resultString += `NOT ${operandStrings[0]}`;
      break;
    case "==":
      resultString += `${operandLabel} = ${operandStrings[1]}`;
      break;
    case "!=":
      resultString += `${operandLabel} ≠ ${operandStrings[1]}`;
      break;
    case "<":
      resultString += `${operandLabel} < ${operandStrings[1]}`;
      break;
    case ">":
      resultString += `${operandLabel} > ${operandStrings[1]}`;
      break;
    case "<=":
      resultString += `${operandLabel} ≤ ${operandStrings[1]}`;
      break;
    case ">=":
      resultString += `${operandLabel} ≥ ${operandStrings[1]}`;
      break;
    case "hasResponse":
      resultString += `Has response for ${operandLabel}`;
      break;
    case "matches":
      resultString += `${operandLabel} matches ${operandStrings[1]}`;
      break;
    case "includesAll":
      resultString += `${operandLabel} includes all of ${operandStrings[1]}`;
      break;
    case "includesAny":
      resultString += `${operandLabel} includes any of ${operandStrings[1]}`;
      break;
    case "in":
      resultString += `${operandLabel} is any of ${operandStrings[1]}`;
      break;
    case "isTrue":
      resultString += `${operandLabel} is true`;
      break;
    case "isFalse":
      resultString += `${operandLabel} is false`;
      break;
    default:
      resultString += "";
  }

  if (isGroupedLogic) {
    resultString += ")";
  }
  return resultString;
};
