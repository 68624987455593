import React from "react";

import { useFormApi } from "informed";
import Button from "../../../ui/Button";
import { CheckboxGroup, ToggleInput } from "../../../ui/Input";
import Text from "../../../ui/Text";
import Heading from "../../../ui/Heading";
import { FiltersInput } from "../../../ui/Filters";
import { ALL } from "../../../../constants";
import { Option } from "../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  practitionerOptions: Array<Option>;
  reasonOptions: Array<Option>;
  selectedActiveStatusFilters: string[];
  statusFilterOptions: Array<Option>;
  selectedActiveCheckinStatusFilters: string[];
  checkinFilterOptions: Array<Option>;
  showCheckinStatus: boolean;
  isMikataAdmin: boolean;
};

const FilterAppointmentsForm = ({
  practitionerOptions,
  reasonOptions,
  selectedActiveStatusFilters,
  statusFilterOptions,
  selectedActiveCheckinStatusFilters,
  checkinFilterOptions,
  showCheckinStatus,
  isMikataAdmin
}: PropsType) => {
  const formApi = useFormApi();
  return (
    <div className={styles.FilterWrapper}>
      <div className={styles.FilterContainer}>
        <div className={styles.FilterAppointmentsHeader}>
          <Heading component="span" bold size="M">
            Filters
          </Heading>
        </div>

        <Text component="span" size="S" bold>
          Appt. Status
        </Text>

        <FiltersInput
          selectedFilters={selectedActiveStatusFilters}
          filterOptions={statusFilterOptions}
          fieldName="activeStatusFilters"
        />
        {isMikataAdmin && (
          <div className={styles.IncludeFilteredOut}>
            <ToggleInput
              fieldName="includeFilteredOut"
              labelUnchecked="Exclude cancelled, deleted, and filtered out appointments"
              labelChecked="Include cancelled, deleted, and filtered out appointments"
              labelsAfter
            />
          </div>
        )}

        {showCheckinStatus && (
          <>
            <Text component="span" size="S" bold>
              Check-in Status
            </Text>
            <FiltersInput
              selectedFilters={selectedActiveCheckinStatusFilters}
              filterOptions={checkinFilterOptions}
              fieldName="activeCheckinStatusFilters"
            />
          </>
        )}

        <div className={styles.OptionsHeader}>
          <Text component="span" size="S" bold>
            Provider
          </Text>
          <button
            id="clear-filters"
            type="button"
            className={styles.ClearFiltersButton}
            onClick={() => {
              formApi.setValue("practitioners", []);
            }}
          >
            <Text component="span" size="S" className={styles.ClearFiltersLabel}>
              Clear all
            </Text>
          </button>
        </div>

        {practitionerOptions && practitionerOptions.length > 0 && (
          <CheckboxGroup
            fieldName="practitioners"
            narrowList
            options={practitionerOptions}
            searchPlaceholder="Find a provider"
            showCheckAll
          />
        )}

        <div className={styles.OptionsHeader}>
          <Text component="span" size="S" bold>
            Reasons
          </Text>
          <button
            id="clear-filters"
            type="button"
            className={styles.ClearFiltersButton}
            onClick={() => {
              formApi.setValue("reasons", []);
            }}
          >
            <Text component="span" size="S" className={styles.ClearFiltersLabel}>
              Clear all
            </Text>
          </button>
        </div>

        {reasonOptions && reasonOptions.length > 0 && (
          <CheckboxGroup
            fieldName="reasons"
            narrowList
            options={reasonOptions}
            searchPlaceholder="Find a reason"
            showCheckAll
          />
        )}
      </div>
      <div className={styles.ButtonWrapper}>
        <button
          id="clear-filters"
          type="button"
          className={styles.ClearAllFiltersButton}
          onClick={() => {
            formApi.setValue("reasons", []);
            formApi.setValue("practitioners", []);
            formApi.setValue("activeStatusFilters", [ALL]);
            if (showCheckinStatus) {
              formApi.setValue("activeCheckinStatusFilters", [ALL]);
            }
            if (isMikataAdmin) {
              formApi.setValue("includeFilteredOut", false);
            }
          }}
        >
          <Text component="span" size="S" bold className={styles.ClearFiltersLabel}>
            Clear all
          </Text>
        </button>

        <Button className={styles.Button} type="submit">
          Apply
        </Button>
      </div>
    </div>
  );
};

export default FilterAppointmentsForm;
