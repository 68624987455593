import React, { useContext } from "react";
import cx from "classnames";
import { connect } from "react-redux";

import { useSearchParams } from "react-router-dom";
import { Menu } from "../../../../../ui/Icon";
import { StopClickPropagation } from "../../../../../ui/TableGrid";

import MenuList, { ListItem } from "./MenuList";

import {
  openModal as openModalAction,
  OpenModal,
  CloneNoteTemplateData,
  deleteNoteTemplate as DeleteNoteTemplateAction,
  cloneNoteTemplate as cloneNoteTemplateAction,
  NoteTemplateDeleteData
} from "../../../../../../actions";

import { usePermissions } from "../../../../../../hooks/usePermissions";

import { ReduxStateType, Permissions } from "../../../../../../types";
import { UserContext } from "../../../../../providers/UserProvider";
import { AdminModalTypes, ModalTypes, UserTypeConstants } from "../../../../../../constants";
import { updateQueryString } from "../../../../../../utils/queryStringHelpers";

import styles from "./index.module.scss";

type PropsType = {
  mikataLevel: boolean;
  noteTemplateId: number;
  noteTemplateUserId: number | null;
  cloneNoteTemplate: (data: CloneNoteTemplateData) => void;
  deleteNoteTemplate: (data: NoteTemplateDeleteData) => void;
  openModal: OpenModal;
};

const DetailsCell = ({
  mikataLevel,
  openModal,
  deleteNoteTemplate,
  cloneNoteTemplate,
  noteTemplateId,
  noteTemplateUserId
}: PropsType) => {
  const { userId, userType } = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const isMikataAdmin = userType === UserTypeConstants.MIKATA_ADMIN;
  const hasDeletePermission = usePermissions([Permissions.DELETE_NOTE_TEMPLATE], false);
  const isProviderSelfTemplate = userId === noteTemplateUserId;

  const cloneTemplate = () => {
    if (isMikataAdmin && noteTemplateId) {
      openModal(AdminModalTypes.ADD_NOTE_TEMPLATE, {
        noteTemplateId
      });
    } else if (userId && noteTemplateId) {
      cloneNoteTemplate({
        cloneFromTemplateId: Number(noteTemplateId),
        onSuccess: (clonedTemplateId) => {
          updateQueryString({ noteTemplateId: clonedTemplateId.toString() }, setSearchParams);
        },
        cloneToUserId: isMikataAdmin ? undefined : userId // for mikata admins userId should be null for clone API
      });
    }
  };
  const menuList: ListItem[] = [];
  if (!mikataLevel) {
    menuList.push({
      label: "Duplicate",
      onClick: cloneTemplate
    });
  }
  if ((isMikataAdmin && hasDeletePermission) || isProviderSelfTemplate) {
    menuList.push({
      label: "Delete",
      onClick: () => {
        openModal(ModalTypes.CONFIRMATION, {
          title: "Are you sure you want to delete this Note Template?",
          onConfirmation: () => {
            deleteNoteTemplate({
              noteTemplateId: noteTemplateId?.toString() || ""
            });
          }
        });
      }
    });
  }

  return (
    <div className={cx(styles.DetailsCell)}>
      <StopClickPropagation>
        <MenuList icon={<Menu size={24} />} list={menuList} />
      </StopClickPropagation>
    </div>
  );
};

const mapStateToProps = ({ appointments }: ReduxStateType) => {
  return {
    updatesLoading: appointments.updatesLoading
  };
};

export default connect(mapStateToProps, {
  openModal: openModalAction,
  cloneNoteTemplate: cloneNoteTemplateAction,
  deleteNoteTemplate: DeleteNoteTemplateAction
})(DetailsCell);
