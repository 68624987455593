import React from "react";

type PropsType = {
  size?: number;
};

const info = ({ size = 22 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      fill="none"
      viewBox="0 0 22 22"
      preserveAspectRatio="xMidYMid"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor" fillRule="evenodd">
        <path d="M11 0c6.075 0 11 4.925 11 11s-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0zm0 2a9 9 0 100 18 9 9 0 000-18z" />
        <path d="M12 17a1 1 0 01-2 0V9a1 1 0 012 0v8zM11.707 4.293a1 1 0 11-1.414 1.414 1 1 0 011.414-1.414z" />
      </g>
    </svg>
  );
};

export default info;
