import {
  APPOINTMENT_REQUESTS,
  FETCH_APPOINTMENT_REQUESTS_DETAIL,
  CLEAR_APPOINTMENT_REQUESTS_DETAIL,
  UPDATE_APPOINTMENT_REQUEST,
  BOOK_APPOINTMENT,
  FETCH_BOOKING_CONFIGURATION,
  GET_APPOINTMENT_REQUEST_HISTORY,
  CLEAR_APPOINTMENT_REQUEST_HISTORY
} from "../constants";
import {
  FetchAppointmentRequestsAction,
  FetchAppointmentRequestsDetailAction,
  ClearAppointmentRequestsDetailAction,
  UpdateAppointmentRequestsAction,
  BookAppointmentAction,
  FetchBookingConfigurationAction,
  GetAppointmentRequestHistoryAction,
  ClearAppointmentRequestHistoryAction
} from "../actions/appointmentRequests";

import {
  AppointmentRequest,
  AppointmentRequestDetail,
  ActionStatus,
  BookingReason,
  ScheduleTag,
  ChatFlowsNodes,
  HistoryEvent
} from "../types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AppointmentRequestsAction =
  | FetchAppointmentRequestsAction
  | FetchAppointmentRequestsDetailAction
  | UpdateAppointmentRequestsAction
  | ClearAppointmentRequestsDetailAction
  | BookAppointmentAction
  | FetchBookingConfigurationAction
  | GetAppointmentRequestHistoryAction
  | ClearAppointmentRequestHistoryAction;

export type AppointmentRequestsUpdatesLoading = {
  [id: string]: { status?: boolean; assignedStaffUserId?: boolean };
};

export type AppointmentRequestsReduxState = {
  appointmentRequests: AppointmentRequest[];
  fetchLoading: boolean;
  allRequestsCount: number;
  readyRequestsCount: number;
  archivedRequestsCount: number;
  updatesLoading: AppointmentRequestsUpdatesLoading;
  details: {
    data?: AppointmentRequestDetail;
    loading: boolean;
  };
  appointmentRequestHistory: HistoryEvent[];
  appointmentRequestHistoryLoading: boolean;
  bookedLoading: boolean;
  bookingConfigurationLoading: boolean;
  bookingConfiguration: {
    bookingReasons: BookingReason[];
    schedules: ScheduleTag[];
    bookingChatNodes: ChatFlowsNodes[];
    emrScheduleLocationMap?: { [scheduleId: string]: string };
  } | null;
};

const initialState: AppointmentRequestsReduxState = {
  appointmentRequests: [],
  fetchLoading: false,
  allRequestsCount: 0,
  readyRequestsCount: 0,
  archivedRequestsCount: 0,
  updatesLoading: {},
  details: {
    data: undefined,
    loading: false
  },
  appointmentRequestHistory: [],
  appointmentRequestHistoryLoading: false,
  bookedLoading: false,
  bookingConfigurationLoading: false,
  bookingConfiguration: null
};

export const appointmentRequestsReducer = (
  state = initialState,
  action: AppointmentRequestsAction
) => {
  switch (action.type) {
    case APPOINTMENT_REQUESTS: {
      const { status, payload } = action as FetchAppointmentRequestsAction;
      const isSuccess = status === ActionStatus.success;

      return {
        ...state,
        appointmentRequests:
          isSuccess && payload ? payload.data.appointmentRequests : state.appointmentRequests,
        fetchLoading: status === ActionStatus.loading,
        allRequestsCount: isSuccess && payload ? payload.data.allRequestsCount : 0,
        readyRequestsCount: isSuccess && payload ? payload.data.readyRequestsCount : 0,
        archivedRequestsCount: isSuccess && payload ? payload.data.archivedRequestsCount : 0
      };
    }
    case FETCH_APPOINTMENT_REQUESTS_DETAIL: {
      const { status, payload } = action as FetchAppointmentRequestsDetailAction;

      return {
        ...state,
        details: {
          ...state.details,
          data: payload || state.details.data,
          loading: status === "loading"
        }
      };
    }
    case CLEAR_APPOINTMENT_REQUESTS_DETAIL: {
      return {
        ...state,
        details: initialState.details
      };
    }
    case UPDATE_APPOINTMENT_REQUEST: {
      const { status, requestId, change, payload } = action as UpdateAppointmentRequestsAction;
      const updatedApptReq = payload?.data.updatedAppointmentRequest;
      const newUpdatesLoading: AppointmentRequestsUpdatesLoading = requestId
        ? {
            ...state.updatesLoading,
            [requestId]: {
              ...state.updatesLoading[requestId],
              ...Object.keys(change).reduce(
                (updatesLoading, key) => {
                  // eslint-disable-next-line no-param-reassign
                  updatesLoading[key] = status === "loading";
                  return updatesLoading;
                },
                {} as { [key: string]: boolean }
              )
            }
          }
        : state.updatesLoading;
      return {
        ...state,
        updatesLoading: newUpdatesLoading,
        appointmentRequests: state.appointmentRequests.map((appReq: AppointmentRequest) => {
          if (updatedApptReq && appReq.id === updatedApptReq.id) {
            return {
              ...appReq,
              ...updatedApptReq
            };
          }
          return appReq;
        })
      };
    }
    case BOOK_APPOINTMENT: {
      const { status } = action as BookAppointmentAction;

      return {
        ...state,
        details: {
          ...state.details
        },
        bookedLoading: status === ActionStatus.loading
      };
    }
    case FETCH_BOOKING_CONFIGURATION: {
      const { status, payload } = action as FetchBookingConfigurationAction;

      return {
        ...state,
        bookingConfigurationLoading: status === ActionStatus.loading,
        bookingConfiguration: payload || state.bookingConfiguration
      };
    }

    case GET_APPOINTMENT_REQUEST_HISTORY: {
      const { status, payload } = action as GetAppointmentRequestHistoryAction;

      return {
        ...state,
        appointmentRequestHistory: payload || state.appointmentRequestHistory,
        appointmentRequestHistoryLoading: status === ActionStatus.loading
      };
    }
    case CLEAR_APPOINTMENT_REQUEST_HISTORY: {
      return {
        ...state,
        appointmentRequestHistory: initialState.appointmentRequestHistory
      };
    }
    default:
      return state;
  }
};
