import { maxBy, set, get } from "lodash";

import { ChatFlowsNodes, MessageTemplateConnection } from "../../../../../../../../../types";

export type ProcessedNodes = {
  [nodeId: string]: {
    fixed: boolean;
    xLevel: number;
    yLevel: number;
    node: ChatFlowsNodes;
    processedOrder: number;
  };
};

export const getMaxProcessedXLevel = (processedNodes: ProcessedNodes) => {
  const maxXLevelNode = maxBy(Object.values(processedNodes), (nodeDetails) => {
    return nodeDetails.xLevel;
  });
  return maxXLevelNode?.xLevel || 0;
};

export const getMaxProcessedYLevel = (processedNodes: ProcessedNodes) => {
  const maxYLevelNode = maxBy(Object.values(processedNodes), (nodeDetails) => {
    return nodeDetails.yLevel;
  });
  return maxYLevelNode?.yLevel || 0;
};

const orderOutgoingConnections = (edges: Array<MessageTemplateConnection>) => {
  return edges.sort((a, b) => {
    if (a.input === null && a.jsonLogic === null) {
      return -1;
    }
    if (b.input === null && b.jsonLogic === null) {
      return 1;
    }

    return a.id - b.id;
  });
};

export const processNodes = (
  nodes: ChatFlowsNodes[],
  edges: Array<MessageTemplateConnection>
): {
  processedNodes: ProcessedNodes;
  edgesWithConnectedNodes: Array<MessageTemplateConnection>;
} => {
  const orphanNodes = nodes?.filter((node) => node.edgesIn == null && node.edgesOut == null) || [];
  const connectedNodes: Array<ChatFlowsNodes> | undefined =
    nodes?.filter((node) => node.edgesIn !== null || node.edgesOut !== null) || [];
  const edgesWithConnectedNodes: Array<MessageTemplateConnection> | undefined = edges;

  const orphanNodesToProcess = orphanNodes || [];
  const nodesToProcess = connectedNodes || [];

  const nodesById: { [nodeId: string]: ChatFlowsNodes } = {};
  [...orphanNodesToProcess, ...nodesToProcess].forEach((node) => {
    const nodeKey: string = node.id.toString();
    if (nodeKey) {
      nodesById[nodeKey] = node;
    }
  });

  const edgesByToMessageTemplateId: { [nodeId: string]: MessageTemplateConnection } = {};
  (edges || []).forEach((edge) => {
    const toEdgeKey: string = edge.toMessageTemplateId.toString();
    if (toEdgeKey) {
      edgesByToMessageTemplateId[toEdgeKey] = edge;
    }
  });

  const processedNodes: ProcessedNodes = {};
  const gridPositionsTaken: boolean[][] = [];

  const processNextNodeConnections = (
    fromNode: ChatFlowsNodes,
    baseXLevel: number,
    baseYLevel: number,
    invocationCount: number
  ): null => {
    if (invocationCount >= 100) return null;

    // get default connection
    const outgoingConnections = (edges || []).filter((edge) => {
      return edge.fromMessageTemplateId === fromNode.id;
    });

    const sortedConnections = orderOutgoingConnections(outgoingConnections);

    // get next Node
    if (sortedConnections.length > 0) {
      sortedConnections.forEach((edge, idx) => {
        const nextNodeId: string = edge.toMessageTemplateId.toString();

        const nextNode = nodesById[nextNodeId];

        if (nextNode && !processedNodes[nextNodeId]) {
          const nextNodeYLevel = baseYLevel + 1;
          const rowPositionsTaken = get(gridPositionsTaken, `[${nextNodeYLevel}]`, []);
          const lastXLevelTaken = rowPositionsTaken.length;
          const nextNodeXLevel = Math.max(baseXLevel, lastXLevelTaken);

          set(gridPositionsTaken, `[${nextNodeYLevel}][${nextNodeXLevel}]`, true);

          // push node to processedNodes
          processedNodes[nextNodeId] = {
            fixed: true,
            xLevel: nextNodeXLevel,
            yLevel: nextNodeYLevel,
            node: nextNode,
            processedOrder: Object.keys(processedNodes).length
          };
          // recursively call processNextConnection
          return processNextNodeConnections(
            nextNode,
            nextNodeXLevel,
            nextNodeYLevel,
            invocationCount + 1
          );
        }
      });
    }

    return null;
  };

  if (orphanNodesToProcess.length > 0) {
    orphanNodes?.forEach((orphanNode, idx) => {
      const numberOfProcessedNodes = Object.keys(processedNodes).length;
      processedNodes[orphanNode.id.toString()] = {
        fixed: true,
        xLevel: idx,
        yLevel: 0,
        node: orphanNode,
        processedOrder: numberOfProcessedNodes + 1
      };
      processNextNodeConnections(orphanNode, idx, 0, 1);
    });
  }

  const rootNode = nodesToProcess.find((node) => {
    return !edgesByToMessageTemplateId[node.id.toString()];
  });

  if (rootNode && !processedNodes[rootNode.id]) {
    const numberOfProcessedNodes = Object.keys(processedNodes).length;
    const maxYLevel = getMaxProcessedYLevel(processedNodes);
    const startingYLevel = orphanNodesToProcess.length > 0 ? maxYLevel + 2 : maxYLevel + 0;

    processedNodes[rootNode.id.toString()] = {
      fixed: true,
      xLevel: 0,
      yLevel: startingYLevel,
      node: rootNode,
      processedOrder: numberOfProcessedNodes + 1
    };

    processNextNodeConnections(rootNode, 0, startingYLevel, 1);
  }

  // process all other nodes without incoming connections that have not been processed
  const unprocessedUnconnectedNodes = nodesToProcess.filter((node) => {
    return node && node.edgesIn === null && !processedNodes[node.id.toString()];
  });

  if (unprocessedUnconnectedNodes.length > 0) {
    unprocessedUnconnectedNodes.forEach((unprocessedUnconnectedNode) => {
      if (processedNodes[unprocessedUnconnectedNode.id.toString()]) return null;

      const numberOfProcessedNodes = Object.keys(processedNodes).length;
      const maxYLevel = getMaxProcessedYLevel(processedNodes);
      processedNodes[unprocessedUnconnectedNode.id.toString()] = {
        fixed: true,
        xLevel: 0,
        yLevel: maxYLevel + 2,
        node: unprocessedUnconnectedNode,
        processedOrder: numberOfProcessedNodes + 1
      };

      processNextNodeConnections(unprocessedUnconnectedNode, 0, maxYLevel + 2, 1);
    });
  }

  // process all unconnected ring/looping groups as there are no nodes without an incoming connection
  let unconnectedLoopProcessCount = 0;

  while (
    unconnectedLoopProcessCount < 10 &&
    Object.keys(processedNodes).length < (nodes || []).length
  ) {
    // Find all unProcesses nodes
    // sort nodes with "chatFlow" types last to handle disconnected cluster flow well
    const unprocessedNodes = (nodes || [])
      .filter((node) => {
        return node && !processedNodes[node.id.toString()];
      })
      .sort((a, b) => {
        //
        if (a.cardType === "chatFlow") {
          return 1;
        }
        if (b.cardType === "chatFlow") {
          return -1;
        }

        return a.id - b.id;
      });

    // process first node in list
    const nextUnprocessedNode = unprocessedNodes?.[0];
    if (nextUnprocessedNode) {
      if (!processedNodes[nextUnprocessedNode.id.toString()]) {
        const numberOfProcessedNodes = Object.keys(processedNodes).length;
        const maxYLevel = getMaxProcessedYLevel(processedNodes);
        processedNodes[nextUnprocessedNode.id.toString()] = {
          fixed: true,
          xLevel: 0,
          yLevel: maxYLevel + 2,
          node: nextUnprocessedNode,
          processedOrder: numberOfProcessedNodes + 1
        };
        processNextNodeConnections(nextUnprocessedNode, 0, maxYLevel + 2, 1);
      }
    }

    unconnectedLoopProcessCount += 1;
  }

  return { processedNodes, edgesWithConnectedNodes };
};
