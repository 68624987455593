import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import Heading from "../../../../ui/Heading";
import Text from "../../../../ui/Text";
import Loader from "../../../../ui/Loader";
import Button from "../../../../ui/Button";
import { Trash } from "../../../../ui/Icon";

import { PermissionsGuard } from "../../../../../hooks/usePermissions";

import {
  fetchServices as fetchServicesAction,
  OpenModal,
  openModal as openModalAction
} from "../../../../../actions";

import { Service, ReduxStateType, Permissions } from "../../../../../types";
import { AdminModalTypes, ServiceProviders } from "../../../../../constants";

import styles from "./index.module.scss";

type PropsType = {
  services: Array<Service>;
  loading: boolean;
  fetchServices: (organizationId: number) => void;
  openModal: OpenModal;
};

const getProviderDisplayName = (provider: string): string => {
  const providerDefinition = ServiceProviders[provider.toUpperCase()];

  return providerDefinition ? providerDefinition.name : `Unknown provider: ${provider}`;
};

const ServicesPage = ({ services, loading, fetchServices, openModal }: PropsType): JSX.Element => {
  const { organizationId: orgIdString } = useParams<{
    organizationId: string;
  }>();

  const organizationId = Number(orgIdString);

  // Fetch services on first load
  useEffect(() => {
    fetchServices(organizationId);
  }, [organizationId]);

  const deleteService = (service: Service): void => {
    if (service) {
      openModal(AdminModalTypes.DELETE_SERVICE, {
        service,
        organizationId
      });
    }
  };

  if (loading) {
    return <Loader screen />;
  }

  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <Heading size="M" component="span" className={styles.Heading}>
          Services
        </Heading>
        {services.length > 1 && (
          <PermissionsGuard requiredPermissions={[Permissions.UPDATE_SERVICES]}>
            <Button
              inline
              onClick={() => {
                openModal(AdminModalTypes.MIGRATE_SERVICE, { organizationId, services });
              }}
            >
              Migrate Service →
            </Button>
          </PermissionsGuard>
        )}
        <PermissionsGuard requiredPermissions={[Permissions.UPDATE_SERVICES]}>
          <Button
            inline
            onClick={() => {
              openModal(AdminModalTypes.ADD_SERVICE, { organizationId });
            }}
          >
            Add Service +
          </Button>
        </PermissionsGuard>
      </div>

      <div className={styles.CardList}>
        {services.map((service) => {
          const serviceNumberText = service.config.serviceNumbers
            ? service.config.serviceNumbers.join(", ")
            : "";
          return (
            <div key={service.id} className={styles.Card}>
              <div className={styles.CardHeader}>
                <Text bold>ID: {service.id}</Text>
                <div>
                  <PermissionsGuard requiredPermissions={[Permissions.DELETE_SERVICES]}>
                    <Button
                      className={styles.IconButton}
                      inline
                      onClick={() => {
                        deleteService(service);
                      }}
                    >
                      <Trash />
                    </Button>
                  </PermissionsGuard>
                </div>
              </div>

              <div className={styles.CardBody}>
                <Text bold>Provider: {getProviderDisplayName(service.provider)}</Text>
                <Text>Medium: {service.medium}</Text>
                <Text>Priority: {service.priority}</Text>
                {service.config && (
                  <>
                    <Text>
                      Service ID: {service.config.serviceId ? `${service.config.serviceId}` : ""}
                    </Text>
                    <Text>Phone Numbers: {serviceNumberText}</Text>
                  </>
                )}
                <PermissionsGuard requiredPermissions={[Permissions.UPDATE_SERVICES_TFN]}>
                  <Button
                    inline
                    className={styles.CardEdit}
                    onClick={() => {
                      openModal(AdminModalTypes.EDIT_SERVICE, { organizationId, service });
                    }}
                  >
                    <Text className={styles.CardEditText}>Add Toll Free Number</Text>
                  </Button>
                </PermissionsGuard>
              </div>

              <div>
                <PermissionsGuard requiredPermissions={[Permissions.UPDATE_SERVICES]}>
                  <Button
                    inline
                    className={styles.CardEdit}
                    onClick={() => {
                      openModal(AdminModalTypes.PURCHASE_NUMBERS, { organizationId, service });
                    }}
                  >
                    <Text className={styles.CardEditText}>Purchase Numbers</Text>
                  </Button>
                </PermissionsGuard>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = ({ services }: ReduxStateType) => {
  return {
    services: services.services,
    loading: services.servicesFetchLoading
  };
};

export default connect(mapStateToProps, {
  fetchServices: fetchServicesAction,
  openModal: openModalAction
})(ServicesPage);
