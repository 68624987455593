import React, { Component, ErrorInfo } from "react";

import { saveAppMetrics, AppMetricType } from "../hooks/useAppAnalytics";
import { MAIN_URL } from "../constants";

import Heading from "../components/ui/Heading";
import Button from "../components/ui/Button";
import { GeneralErrorGraphic } from "../components/ui/Icon";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PropsType = any;
type StateType = { hasError: boolean; error?: Error };

class ErrorBoundary extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service like AppSignal
    // logErrorToMyService(error, errorInfo);
    saveAppMetrics([
      {
        type: AppMetricType.APPLICATION_ERROR,
        errorMessage: error.message || "Unknown Application Error",
        errorInfo: errorInfo ? JSON.stringify(errorInfo) : "",
        userAgent: window?.navigator?.userAgent || "",
        timestamp: new Date().toISOString()
      }
    ]);
  }

  handleLoginRedirect() {
    window.location.href = `${MAIN_URL}/login`;
    localStorage.removeItem("jwt");
    this.setState({ hasError: false });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div
          style={{
            position: "relative",
            boxSizing: "border-box",
            width: "100%",
            height: "100vh",
            backgroundColor: "#fafaf9",
            padding: "64px",
            display: "flex",
            flexFlow: "row",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div
            style={{
              padding: "64px",
              display: "flex",
              flexFlow: "column",
              alignItems: "flex-start",
              justifyContent: "center"
            }}
          >
            <Heading size="M">Oh dear! Something went wrong.</Heading>
            <Heading size="S">
              Our team is working to fix the issue. Apologies for the inconvenience
            </Heading>
            <Button onClick={this.handleLoginRedirect}>Back To Log In</Button>
          </div>
          <GeneralErrorGraphic />
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
