import React from "react";

type PropsType = {
  size?: number;
};

const Refresh = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 9.133c0 .118.021.236.065.348l.004.012c.142.35.486.597.888.597h5.739a.956.956 0 100-1.91H4.37L7.05 5.67a7.677 7.677 0 017.122-2.071 7.647 7.647 0 015.525 4.9.957.957 0 001.804-.636 9.559 9.559 0 00-6.907-6.126 9.578 9.578 0 00-8.876 2.562l-2.805 2.63v-3.52a.956.956 0 00-1.913 0v5.725zm22 5.734a.955.955 0 00-.065-.348l-.004-.012a.957.957 0 00-.887-.597h-5.74a.956.956 0 100 1.91h3.325L16.95 18.33a7.676 7.676 0 01-7.122 2.071 7.647 7.647 0 01-5.525-4.9.957.957 0 00-1.804.636 9.559 9.559 0 006.907 6.127 9.578 9.578 0 008.876-2.563l2.805-2.63v3.52a.956.956 0 001.913 0v-5.725z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Refresh;
