import React from "react";

type PropsType = {
  size?: number;
};

// hamburger menu

const MenuHamburger = ({ size = 24 }: PropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill="#1D2029"
        fillRule="evenodd"
        d="M3.5 7.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM9 5a1 1 0 0 0 0 2h12a1 1 0 1 0 0-2H9Zm-4 7a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm4-1a1 1 0 1 0 0 2h12a1 1 0 1 0 0-2H9Zm-4 7a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm4-1a1 1 0 1 0 0 2h12a1 1 0 1 0 0-2H9Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default MenuHamburger;
