import React, { ReactNode } from "react";
import cx from "classnames";

import styles from "./index.module.scss";

type PropsType = {
  children: ReactNode;
  topBar?: boolean;
};

export const ButtonRow = ({ children, topBar = false }: PropsType): React.ReactElement => {
  const numberOfButtons = React.Children.count(children);

  return (
    <div
      className={cx(styles.ButtonRow, {
        [styles.ButtonRowSingleChild]: numberOfButtons === 1,
        [styles.ButtonRowTopBar]: topBar
      })}
    >
      {children}
    </div>
  );
};
