import React from "react";
import cx from "classnames";

import Button from "../Button";
import Text from "../Text";
import Heading from "../Heading";
import { ResponsiveHide, ResponsiveMobileDrawer } from "../Responsive";

import styles from "./index.module.scss";

type PropsType = {
  labelConfig: Record<string, number>;
  activeLabel?: string;
  headerLabel?: string;
  onLabelClick: (labelName: string) => void;
  isFilterOpen: boolean;
  onFilterClose: () => void;
  footerContent?: React.ReactNode;
  children: React.ReactNode;
};

const AdvancedFilterLayout = ({
  labelConfig,
  headerLabel,
  activeLabel,
  footerContent,
  onLabelClick,
  isFilterOpen,
  onFilterClose,
  children
}: PropsType) => {
  return (
    <>
      <ResponsiveHide hideOnTablet hideOnDesktop>
        <ResponsiveMobileDrawer
          header="Filters"
          isOpen={isFilterOpen}
          closeMobileDrawer={() => {
            onFilterClose();
          }}
        >
          <div className={styles.FormContainer}>
            <div className={styles.FilterLabelContainer}>
              {Object.keys(labelConfig).map((item) => {
                return (
                  <Button
                    className={cx(styles.LabelButton, {
                      [styles.LabelButtonActive]: activeLabel === item
                    })}
                    onClick={() => onLabelClick(item)}
                    inline
                    key={item}
                  >
                    <Text
                      size="M"
                      bold={activeLabel === item}
                      className={cx({
                        [styles.LabelButtonTextActive]: activeLabel === item
                      })}
                    >
                      {labelConfig[item] > 0 ? `${item} (${labelConfig[item]})` : `${item}`}
                    </Text>
                  </Button>
                );
              })}
            </div>
            {children}
          </div>
          {footerContent && footerContent}
        </ResponsiveMobileDrawer>
      </ResponsiveHide>
      <ResponsiveHide hideOnMobile>
        <div className={styles.FormContainer}>
          <div className={styles.FilterLabelContainer}>
            {headerLabel && (
              <Heading size="S" bold className={styles.Heading} component="h5">
                {headerLabel}
              </Heading>
            )}
            {Object.keys(labelConfig).map((item) => {
              return (
                <Button
                  className={cx(styles.LabelButton, {
                    [styles.LabelButtonActive]: activeLabel === item
                  })}
                  onClick={() => onLabelClick(item)}
                  inline
                  key={item}
                >
                  <Text
                    size="M"
                    bold={activeLabel === item}
                    className={cx({
                      [styles.LabelButtonTextActive]: activeLabel === item
                    })}
                  >
                    {labelConfig[item] > 0 ? `${item} (${labelConfig[item]})` : `${item}`}
                  </Text>
                </Button>
              );
            })}
          </div>
          {children}
        </div>
        {footerContent && footerContent}
      </ResponsiveHide>
    </>
  );
};

export default AdvancedFilterLayout;
