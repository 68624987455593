import {
  ConditionOperatorConfig,
  CompositionOperatorConfig,
  ContextVariableType,
  ComparatorFieldType
} from "./types";

export const defaultConditionOperators: ConditionOperatorConfig[] = [
  {
    label: "has response",
    jsonLogicKey: "hasResponse",
    validContextTypes: [ContextVariableType.STRING, ContextVariableType.ARRAY],
    comparatorFieldType: null
  },
  {
    label: "is equal to",
    jsonLogicKey: "==",
    validContextTypes: [ContextVariableType.STRING],
    comparatorFieldType: ComparatorFieldType.STRING
  },
  {
    label: "is not equal to",
    jsonLogicKey: "!=",
    validContextTypes: [ContextVariableType.STRING],
    comparatorFieldType: ComparatorFieldType.STRING
  },
  {
    label: "is any of",
    jsonLogicKey: "in",
    validContextTypes: [ContextVariableType.STRING],
    comparatorFieldType: ComparatorFieldType.ARRAY
  },
  {
    label: "matches exactly",
    jsonLogicKey: "matches",
    validContextTypes: [ContextVariableType.ARRAY],
    comparatorFieldType: ComparatorFieldType.ARRAY
  },
  {
    label: "includes all of",
    jsonLogicKey: "includesAll",
    validContextTypes: [ContextVariableType.ARRAY],
    comparatorFieldType: ComparatorFieldType.ARRAY
  },
  {
    label: "includes any of",
    jsonLogicKey: "includesAny",
    validContextTypes: [ContextVariableType.ARRAY],
    comparatorFieldType: ComparatorFieldType.ARRAY
  },
  {
    label: "is true",
    jsonLogicKey: "isTrue",
    validContextTypes: [ContextVariableType.BOOLEAN],
    comparatorFieldType: null
  },
  {
    label: "is false",
    jsonLogicKey: "isFalse",
    validContextTypes: [ContextVariableType.BOOLEAN],
    comparatorFieldType: null
  }
];

export const defaultCompositionOperators: CompositionOperatorConfig[] = [
  {
    label: "and",
    jsonLogicKey: "and"
  },
  {
    label: "or",
    jsonLogicKey: "or"
  }
];
