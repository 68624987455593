import {
  FETCH_NOTES,
  CREATE_NOTE,
  UPDATE_NOTE,
  SYNC_NOTE,
  CLEAR_NOTES,
  DELETE_NOTE
} from "../constants";

import {
  FetchNotesAction,
  CreateNoteAction,
  UpdateNotesAction,
  SyncNoteAction,
  ClearNotesAction,
  DeleteNoteAction
} from "../actions";

import { Note } from "../types";

export type NotesAction =
  | FetchNotesAction
  | CreateNoteAction
  | UpdateNotesAction
  | SyncNoteAction
  | ClearNotesAction
  | DeleteNoteAction;

export type NoteUpdatesLoading = {
  [id: string]: { checkedIn?: boolean; status?: boolean; parkingLotNotify?: boolean };
};

export type NotesSyncLoading = {
  [id: string]: boolean;
};

export type NotesReduxState = {
  notes: Note[];
  notesLoading: boolean;
  createLoading: boolean;
  deleteLoading: boolean;
  updatesLoading: NoteUpdatesLoading;
  syncLoading: NotesSyncLoading;
};

const initialNotes: NotesReduxState = {
  notes: [],
  notesLoading: false,
  createLoading: false,
  deleteLoading: false,
  updatesLoading: {},
  syncLoading: {}
};

export const notesReducer = (state = initialNotes, action: NotesAction): NotesReduxState => {
  const { type } = action;
  switch (type) {
    case FETCH_NOTES: {
      const { status, payload } = action as FetchNotesAction;

      return {
        ...state,
        notes: payload?.notes || state.notes,
        notesLoading: status === "loading"
      };
    }
    case CREATE_NOTE: {
      const { status, payload } = action as CreateNoteAction;

      // Helps guard against a cross-appointment note from being displayed
      const currentAppointmentIdContext = state?.notes?.[0]?.appointmentId;
      const hasNotes = state?.notes?.length > 0;
      const shouldAddNewNote =
        !hasNotes || currentAppointmentIdContext === payload?.note?.appointmentId;

      return {
        ...state,
        notes: payload && shouldAddNewNote ? [...state.notes, payload.note] : state.notes,
        createLoading: status === "loading"
      };
    }
    case UPDATE_NOTE: {
      return {
        ...state
      };
    }
    case SYNC_NOTE: {
      const { status, payload } = action as SyncNoteAction;
      const noteId = `${payload?.note.id}`;

      const syncLoading = { ...state.syncLoading, [noteId]: status === "loading" };

      return {
        ...state,
        notes:
          status === "success"
            ? state.notes.map((note) => {
                if (note.id === payload.note.id) {
                  return payload.note;
                }
                return note;
              })
            : state.notes,
        syncLoading
      };
    }
    case CLEAR_NOTES: {
      return {
        ...initialNotes
      };
    }
    case DELETE_NOTE: {
      const { status, payload } = action as DeleteNoteAction;

      let updatedNotes = state.notes;

      if (payload && payload.deletedNote && payload.deletedNote?.id) {
        updatedNotes = state.notes.filter((note) => note.id !== payload.deletedNote?.id);
      }

      return {
        ...state,
        notes: updatedNotes,
        deleteLoading: status === "loading"
      };
    }
    default:
      return state;
  }
};
