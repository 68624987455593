import React, { Dispatch, useEffect, useContext, useState } from "react";
import { connect } from "react-redux";
import { jwtDecode } from "jwt-decode";
import styles from "../index.module.scss";
import { CardSection } from "../../../../ui/Card";
import Heading from "../../../../ui/Heading";
import Text from "../../../../ui/Text";
import { ExternalLink, Info } from "../../../../ui/Icon";
import { TextInput, SelectInput, Form } from "../../../../ui/Input";
import Tooltip from "../../../../ui/Tooltip";
import Image from "../../../../ui/Image";
import { InputValue, ReduxStateType, User } from "../../../../../types";
import { Provinces, States } from "../../../../../constants";
import {
  CreateScribeSubscriptionFormState,
  CreateScribeSubscriptionPlanFormData,
  CreateScribeSubscriptionPlanModalSteps
} from "..";
import { isRequired } from "../../../../../utils/validators";
import Button from "../../../../ui/Button";
import {
  fetchUser as fetchUserAction,
  createChargeOverSubscription as createChargeOverSubscriptionAction,
  CreateChargeOverSubscriptionData
} from "../../../../../actions";
import getToken from "../../../../../utils/getToken";
import {
  BillingContext,
  CreditCardTokenizationResponse
} from "../../../../providers/BillingProvider";

type PropsType = {
  formState: CreateScribeSubscriptionFormState;
  setFormState: Dispatch<CreateScribeSubscriptionPlanFormData>;
  user: User | null;
  createChargeOverSubscription: (
    organizationId: string,
    data: CreateChargeOverSubscriptionData,
    onError?: () => void
  ) => void;
  submitStep: (
    stepName: CreateScribeSubscriptionPlanModalSteps,
    formState: CreateScribeSubscriptionPlanFormData
  ) => void;
};

const cardNumberValidator = isRequired("Please enter a card number");
const expiryMonthValidator = isRequired("Please enter an expiry month");
const expiryYearValidator = isRequired("Please enter an expiry year");
const cvvValidator = isRequired("Please enter a CVV");
const cardNameValidator = isRequired("Please enter a name");

const addressValidator = isRequired("Please enter an address");
const cityValidator = isRequired("Please enter a city");
const provinceStateValidator = isRequired("Please select a province or state");
const countryValidator = isRequired("Please select a country");
const postalZipCodeValidator = isRequired("Please enter a postal or zip code");

const PaymentBillingInformation = ({
  formState,
  setFormState,
  user,
  createChargeOverSubscription,
  submitStep
}: PropsType) => {
  const token = getToken();
  const decodedToken: { userId: string; organizationId: string } | null = token
    ? jwtDecode(token)
    : null;

  const organizationId = decodedToken?.organizationId;

  const billingProvider = useContext(BillingContext);
  const [cardValidationError, setCardValidationError] = useState<string | undefined>(undefined);

  const onChange = (event: { [fieldName: string]: InputValue }) => {
    setCardValidationError(undefined);
    setFormState({
      type: "paymentBillingInformation",
      payload: {
        values: {
          cardNumber:
            event["paymentBillingInformation.values.cardNumber"] ||
            formState.paymentBillingInformation?.values?.cardNumber,
          expiryMonth:
            event["paymentBillingInformation.values.expiryMonth"] ||
            formState.paymentBillingInformation?.values?.expiryMonth,
          expiryYear:
            event["paymentBillingInformation.values.expiryYear"] ||
            formState.paymentBillingInformation?.values?.expiryYear,
          cvv:
            event["paymentBillingInformation.values.cvv"] ||
            formState.paymentBillingInformation?.values?.cvv,
          cardName:
            event["paymentBillingInformation.values.cardName"] ||
            formState.paymentBillingInformation?.values?.cardName,
          address:
            event["paymentBillingInformation.values.address"] ||
            formState.paymentBillingInformation?.values?.address,
          suite:
            event["paymentBillingInformation.values.suite"] ||
            formState.paymentBillingInformation?.values?.suite,
          city:
            event["paymentBillingInformation.values.city"] ||
            formState.paymentBillingInformation?.values?.city,
          provinceState:
            event["paymentBillingInformation.values.provinceState"] ||
            formState.paymentBillingInformation?.values?.provinceState,
          country:
            event["paymentBillingInformation.values.country"] ||
            formState.paymentBillingInformation?.values?.country,
          postalZipCode:
            event["paymentBillingInformation.values.postalZipCode"] ||
            formState.paymentBillingInformation?.values?.postalZipCode
        }
      }
    });
  };

  const validateCreditCardData = async (event: { [fieldName: string]: InputValue }) => {
    let errorMessage: string | undefined;

    const { cardNumber, expiryMonth, expiryYear, cardName, cvv } =
      event.paymentBillingInformation.values;

    const validateCardCallback = (
      code: string,
      message: string,
      response: CreditCardTokenizationResponse
    ) => {
      errorMessage = code === "200" ? "" : message;
    };

    if (organizationId) {
      billingProvider?.validateCard(
        {
          organizationId: Number.parseInt(organizationId, 10),
          number: cardNumber as string,
          expiryMonth: expiryMonth as string,
          expiryYear: expiryYear as string,
          name: cardName as string,
          cvv: cvv as string
        },
        validateCardCallback
      );
    }

    const validationResult = await new Promise<string>((resolve) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const loop: any = () =>
        errorMessage !== undefined ? resolve(errorMessage) : setTimeout(loop, 100);
      loop();
    });

    const result =
      validationResult === "" || validationResult === null ? undefined : validationResult;
    setCardValidationError(result);
    return result;
  };

  const onSubmit = async (event: { [fieldName: string]: InputValue }) => {
    let error = false;
    const onErrorCallback = () => {
      error = true;
    };

    const { address, suite, city, provinceState, country, postalZipCode } =
      event.paymentBillingInformation.values;

    setFormState({
      type: "paymentBillingInformation",
      payload: {
        loading: true,
        ...event.paymentBillingInformation
      }
    });

    const validationResult = await validateCreditCardData(event);

    if (validationResult !== undefined) return;

    await createChargeOverSubscription(
      organizationId as string,
      {
        productKey: formState.selectPlan?.values?.productKey as string,
        address,
        suite,
        city,
        provinceState,
        country,
        postalZipCode
      },
      onErrorCallback
    );

    setFormState({
      type: "paymentBillingInformation",
      payload: {
        loading: false,
        ...event.paymentBillingInformation
      }
    });

    if (error) return;

    submitStep("confirmAndPay", {
      type: "paymentBillingInformation",
      payload: {
        ...event.paymentBillingInformation
      }
    });
  };

  return (
    <Form
      onSubmit={(formState) => onSubmit(formState.values as { [fieldName: string]: InputValue })}
      initialValues={formState}
    >
      <CardSection title="Payment">
        <TextInput
          id="creditCardNumber"
          fieldName="paymentBillingInformation.values.cardNumber"
          label="Card Number"
          validate={cardNumberValidator}
          customOnChange={onChange}
        />
        <div className={styles.CardRow}>
          <div className={styles.InputMargin}>
            <TextInput
              id="creditCardExpMonth"
              fieldName="paymentBillingInformation.values.expiryMonth"
              label="Exp. Month"
              validate={expiryMonthValidator}
              customOnChange={onChange}
            />
          </div>
          <div className={styles.InputMargin}>
            <TextInput
              id="creditCardExpYear"
              fieldName="paymentBillingInformation.values.expiryYear"
              label="Exp. Year"
              validate={expiryYearValidator}
              customOnChange={onChange}
            />
          </div>
          <div className={styles.InputFullWidth}>
            <div className={styles.FlexRow}>
              <Heading size="META" className={styles.ToolTipHeading}>
                CVV
              </Heading>
              <Tooltip icon={<Info size={16} />} position="topRight" className={styles.ToolTip}>
                <Image src="img/CreditCard.png" className={styles.ToolTipImage} />
                CVV is a 3 digit verification number printed on the card background.
              </Tooltip>
            </div>

            <TextInput
              id="cvv"
              fieldName="paymentBillingInformation.values.cvv"
              validate={cvvValidator}
              customOnChange={onChange}
            />
          </div>
        </div>
        <TextInput
          id="creditCardName"
          fieldName="paymentBillingInformation.values.cardName"
          label="Name On Card"
          validate={cardNameValidator}
          customOnChange={onChange}
        />
        {cardValidationError && <Text className={styles.ErrorMessage}>{cardValidationError}</Text>}
      </CardSection>

      <CardSection title="Billing details">
        <Heading size="META">Name</Heading>
        <Text className={styles.TextMarginBottom}>
          {user?.firstName} {user?.lastName}
        </Text>

        <div className={styles.FlexRow}>
          <Heading size="META" className={styles.ToolTipHeading}>
            Email
          </Heading>
          <Tooltip icon={<Info size={16} />} position="topRight" className={styles.ToolTip}>
            <span>
              Your invoice will be sent to your account email by default.&nbsp;
              <a
                type="button"
                href="https://mikatahealth.com/feedback/"
                target="_blank"
                className={styles.InlineLink}
                rel="noreferrer"
              >
                Contact us <ExternalLink size={12} />
                &nbsp;
              </a>
              to change your billing email address.
            </span>
          </Tooltip>
        </div>
        <Text className={styles.TextMarginBottom}>{user?.email}</Text>

        <TextInput
          id="billingAddress"
          fieldName="paymentBillingInformation.values.address"
          label="Address"
          validate={addressValidator}
          customOnChange={onChange}
        />
        <div className={styles.CardRow}>
          <div className={styles.InputMargin}>
            <TextInput
              fieldName="paymentBillingInformation.values.suite"
              label="Suite"
              customOnChange={onChange}
            />
          </div>
          <TextInput
            id="city"
            fieldName="paymentBillingInformation.values.city"
            label="City"
            validate={cityValidator}
            customOnChange={onChange}
          />
        </div>
        <div className={styles.CardRow}>
          <div className={styles.InputMargin}>
            <SelectInput
              id="province"
              fieldName="paymentBillingInformation.values.provinceState"
              label="Province/State"
              options={
                formState.paymentBillingInformation?.values?.country === "Canada"
                  ? Provinces.map((province) => ({
                      label: province,
                      value: province
                    }))
                  : States.map((state) => ({ label: state, value: state }))
              }
              validate={provinceStateValidator}
              customOnChange={onChange}
            />
          </div>
          <SelectInput
            id="country"
            fieldName="paymentBillingInformation.values.country"
            label="Country"
            options={[
              { label: "Canada", value: "Canada" },
              {
                label: "United States",
                value: "United States"
              }
            ]}
            validate={countryValidator}
            customOnChange={onChange}
          />
        </div>
        <TextInput
          id="postalCode"
          fieldName="paymentBillingInformation.values.postalZipCode"
          label="Postal/Zip Code"
          validate={postalZipCodeValidator}
          customOnChange={onChange}
        />
      </CardSection>
      <div className={styles.Button}>
        <Button
          id="proceedToPayment"
          type="submit"
          disabled={formState.paymentBillingInformation?.loading}
        >
          Proceed to Payment
        </Button>
      </div>
    </Form>
  );
};

const mapStateToProps = ({ users }: ReduxStateType) => {
  return {
    user: users.user
  };
};

export default connect(mapStateToProps, {
  fetchUser: fetchUserAction,
  createChargeOverSubscription: createChargeOverSubscriptionAction
})(PaymentBillingInformation);
