import React, { useState } from "react";
import { connect } from "react-redux";

import Button from "../../../../ui/Button";
import Heading from "../../../../ui/Heading";
import Text from "../../../../ui/Text";
import Card from "../../../../ui/Card";
import { PaperClip } from "../../../../ui/Icon";

import {
  fetchDirectMessageDetails as fetchDirectMessageDetailsAction,
  deleteSecureDirectMessageItem as deleteSecureDirectMessageItemAction
} from "../../../../../actions";

import { SecureMessageItemData } from "../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  cardData: SecureMessageItemData & { conversationId?: number };
  fetchDirectMessageDetails: (conversationId: string) => void;
  deleteSecureDirectMessageItem: (secureMessageId: number, onSuccess?: () => void) => void;
};

const SecureMessageItemCard = ({
  cardData,
  fetchDirectMessageDetails,
  deleteSecureDirectMessageItem
}: PropsType) => {
  const [showDeleteConfirmation, setshowDeleteConfirmation] = useState(false);
  const {
    id: secureMessageId,
    conversationId,
    deleted,
    payloadContent,
    fileName,
    deleterFirstName,
    deleterLastName
  } = cardData;

  return (
    <Card className={styles.SecureMessageItemCard} id="secureMessageDetailCard">
      {deleted ? (
        <Text className={styles.SecureMessageItemCardDeletedContent}>
          {`Secure Message deleted by ${deleterFirstName} ${deleterLastName}`}
        </Text>
      ) : (
        <>
          {payloadContent && <Text>{payloadContent}</Text>}
          {fileName && (
            <div className={styles.SecureMessageItemCardAttachment}>
              <PaperClip />
              <div className={styles.Relative}>
                <Text className={styles.SecureMessageItemCardAttachmentText}>{fileName}</Text>
              </div>
            </div>
          )}
          {showDeleteConfirmation ? (
            <div className={styles.SecureMessageItemCardDeleteConfirmation}>
              <Heading size="S">Are you sure?</Heading>
              <Text size="S">
                Deleting this message will remove the message in the patient’s secure chat and they
                will no longer be able to see the message sent.
              </Text>
              <Text size="S" className={styles.PaddingY}>
                Are you sure you would like to delete the message?
              </Text>
              <div className={styles.ButtonGroup}>
                <Button inline onClick={() => setshowDeleteConfirmation(false)}>
                  <Text size="M" className={styles.SecureMessageItemCardCancelButton}>
                    Cancel
                  </Text>
                </Button>
                <Button
                  onClick={() =>
                    deleteSecureDirectMessageItem(
                      secureMessageId,
                      () => conversationId && fetchDirectMessageDetails(conversationId.toString())
                    )
                  }
                  id="deleteSecureMessageConfirm"
                >
                  Delete
                </Button>
              </div>
            </div>
          ) : (
            <Button inline onClick={() => setshowDeleteConfirmation(true)} id="deleteSecureMessage">
              <Text size="S" className={styles.SecureMessageItemCardDeleteMessageButton}>
                Delete this message
              </Text>
            </Button>
          )}
        </>
      )}
    </Card>
  );
};

export default connect(undefined, {
  fetchDirectMessageDetails: fetchDirectMessageDetailsAction,
  deleteSecureDirectMessageItem: deleteSecureDirectMessageItemAction
})(SecureMessageItemCard);
