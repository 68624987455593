import { useCallback } from "react";

import axios from "axios";

import getToken from "../utils/getToken";

import { ROOT_URL, UserTypeConstants } from "../constants";

export enum AppMetricType {
  APPLICATION_HEARTBEAT = "applicationHeartbeat",
  APPLICATION_ERROR = "applicationError",
  SESSION_USER_AGENT = "sessionUserAgent",
  SESSION_SAMPLE_RATE = "sessionSampleRate",
  SESSION_USER_LOGIN = "sessionUserLogin",
  SESSION_ADMIN_USER_LOGIN = "sessionAdminUserLogin",
  SESSION_USER_TIMEOUT = "sessionUserTimeout"
}

type BaseAppMetric = {
  type: AppMetricType;
};

type HeartbeatMetric = BaseAppMetric & {
  type: AppMetricType.APPLICATION_HEARTBEAT;
  heartbeatMS: number;
  locationPath: string;
  locationSearch: string;
};

type ErrorMetric = BaseAppMetric & {
  type: AppMetricType.APPLICATION_ERROR;
  errorMessage: string;
  errorInfo: string;
  userAgent: string;
};

type SessionUserAgentMetric = BaseAppMetric & {
  type: AppMetricType.SESSION_USER_AGENT;
  appointmentId: number;
  userAgent: string;
  deviceId: string;
};

type SessionSampleRateMetric = BaseAppMetric & {
  type: AppMetricType.SESSION_SAMPLE_RATE;
  appointmentId: number;
  sampleRate: number;
};

type SessionUserLoginMetric = BaseAppMetric & {
  type: AppMetricType.SESSION_USER_LOGIN;
  organizationId?: number;
  message: string;
};

type SessionAdminUserLoginMetric = BaseAppMetric & {
  type: AppMetricType.SESSION_ADMIN_USER_LOGIN;
  message: string;
};

type SessionUserTimeoutMetric = BaseAppMetric & {
  type: AppMetricType.SESSION_USER_TIMEOUT;
  organizationId?: number;
  message: string;
};

export type AppMetric =
  | HeartbeatMetric
  | ErrorMetric
  | SessionUserAgentMetric
  | SessionSampleRateMetric
  | SessionUserLoginMetric
  | SessionAdminUserLoginMetric
  | SessionUserTimeoutMetric;

export type AppMetricWithContext = AppMetric & {
  timestamp: string; // ISO date string
  organizationId?: number;
  userId?: number;
  userType?:
    | typeof UserTypeConstants.MIKATA_ADMIN
    | typeof UserTypeConstants.STAFF
    | typeof UserTypeConstants.PRACTITIONER;
};

export type QueueMetricOptions = {
  postImmediately?: boolean;
};

const MAX_METRICS_QUEUE_LENGTH = 100;

const getMetricsQueue = (): AppMetricWithContext[] => {
  const metricsQueueString = localStorage.getItem("metricsQueue");
  const metricsQueue: AppMetricWithContext[] = metricsQueueString
    ? JSON.parse(metricsQueueString)
    : [];
  return metricsQueue;
};

const setMetricsQueue = (metricQueue: AppMetric[]): void => {
  const metricsQueueString = JSON.stringify(metricQueue);

  localStorage.setItem("metricsQueue", metricsQueueString);
};

export const saveAppMetrics = async (appMetrics: AppMetricWithContext[]) => {
  const config = {
    headers: { Authorization: getToken(), "Content-Type": "application/json" }
  };
  const data = { metrics: appMetrics };

  try {
    await axios.post(`${ROOT_URL}/analytics/clinic-application`, data, config);
  } catch (error) {
    console.error(error);
  }
};

type PropsType = {
  organizationId?: number;
  userId?: number;
  userType?:
    | typeof UserTypeConstants.MIKATA_ADMIN
    | typeof UserTypeConstants.STAFF
    | typeof UserTypeConstants.PRACTITIONER;
};

const useAppAnalytics = ({ organizationId, userId, userType }: PropsType) => {
  const processMetricsQueue = (): void => {
    const metricsQueue = getMetricsQueue();
    const isOnline = !window.navigator || window.navigator?.onLine;
    const isLoggedIn = Boolean(localStorage.getItem("jwt"));

    if (isOnline && isLoggedIn && metricsQueue && metricsQueue.length > 0) {
      saveAppMetrics(metricsQueue);
      setMetricsQueue([]);
    }
  };

  const queueMetric = useCallback(
    (appMetric: AppMetric, options?: QueueMetricOptions) => {
      const { postImmediately = false } = options || {};
      const appMetricWithContext = {
        ...appMetric,
        organizationId,
        userId,
        userType,
        timestamp: new Date().toISOString()
      };

      // Push app metric to local storage queue
      const currentMetricsQueue = getMetricsQueue();

      // Protect against memory leaks
      if (currentMetricsQueue?.length >= MAX_METRICS_QUEUE_LENGTH) {
        // Discard oldest queued metric
        currentMetricsQueue.shift();
      }

      const updatedMetricQueue = [...currentMetricsQueue, appMetricWithContext];
      setMetricsQueue(updatedMetricQueue);

      if (postImmediately) {
        processMetricsQueue();
      }
    },
    [organizationId, userId, userType]
  );

  return { queueMetric, processMetricsQueue };
};

export default useAppAnalytics;
