import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";

import { fetchAnalytics as fetchAnalyticsAction } from "../../../../actions/analytics";

import Heading from "../../../ui/Heading";

import styles from "./index.module.scss";

import RequestsCompleted from "./analytics-sections/RequestsCompleted";
import RequestProcessingTime from "./analytics-sections/RequestProcessingTime";
import ChatAbandonment from "./analytics-sections/ChatAbandonment";
import PatientInteractions from "./analytics-sections/PatientInteractions";

import DateSelector from "../../../ui/DateSelector";
import Text from "../../../ui/Text";

import { ReduxStateType } from "../../../../types";

type PropsType = {
  fetchAnalytics: (
    functionName: string,
    startDate: string,
    endDate: string,
    filter: string,
    clockStartTime: string,
    clockEndTime: string
  ) => void;
  requestsReasonsCountFilter: string;
  processingStartTime: string;
};

const analyticsFunctions = [
  "analyticsBookingMessages",
  "analyticsMultiBookingMessages",
  "analyticsCancelMessages",
  "analyticsMultiCancelMessages",
  "analyticsPatientConfirmationMessages",
  "analyticsPatientRescheduleMessages",
  "analyticsRequestsReasonsCount",
  "analyticsRequestsCount",
  "analyticsRequisitionCount",
  "analyticsAverageProcessingTime",
  "analyticsProcessingTimeByReason",
  "analyticsQueueTime",
  "analyticsChatCompletionTime",
  "analyticsChatDropOff"
];

const AnalyticsPage = ({
  fetchAnalytics,
  requestsReasonsCountFilter,
  processingStartTime
}: PropsType) => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const [startDate, setStartDate] = useState(moment().tz(timeZone).subtract(1, "week").toDate());
  const [endDate, setEndDate] = useState(moment().tz(timeZone).toDate());
  const startDateString = moment(startDate).utc().format("YYYY-MM-DD");
  const endDateString = moment(endDate).utc().add(1, "days").format("YYYY-MM-DD");

  useEffect(() => {
    analyticsFunctions.forEach((functionName) => {
      let filter = "all";
      let clockStartTime = "";
      let clockEndTime = "";
      if (
        [
          "analyticsRequestsReasonsCount",
          "analyticsRequestsCount",
          "analyticsRequisitionCount"
        ].includes(functionName)
      )
        filter = requestsReasonsCountFilter;
      if (
        ["analyticsAverageProcessingTime", "analyticsProcessingTimeByReason"].includes(functionName)
      ) {
        clockStartTime = processingStartTime;
        clockEndTime = "processedAt";
      }
      if (functionName === "analyticsQueueTime") {
        clockStartTime = "submittedAt";
        clockEndTime = "assignedAt";
      }

      fetchAnalytics(
        functionName,
        startDateString,
        endDateString,
        filter,
        clockStartTime,
        clockEndTime
      );
    });
  }, [startDate, endDate]);

  useEffect(() => {
    const filter = requestsReasonsCountFilter;
    const clockStartTime = "";
    const clockEndTime = "";
    [
      "analyticsRequestsReasonsCount",
      "analyticsRequestsCount",
      "analyticsRequisitionCount"
    ].forEach((functionName) => {
      fetchAnalytics(
        functionName,
        startDateString,
        endDateString,
        filter,
        clockStartTime,
        clockEndTime
      );
    });
  }, [requestsReasonsCountFilter]);

  useEffect(() => {
    const filter = requestsReasonsCountFilter;
    const clockStartTime = processingStartTime;
    const clockEndTime = "processedAt";
    ["analyticsAverageProcessingTime", "analyticsProcessingTimeByReason"].forEach(
      (functionName) => {
        fetchAnalytics(
          functionName,
          startDateString,
          endDateString,
          filter,
          clockStartTime,
          clockEndTime
        );
      }
    );
  }, [processingStartTime]);

  return (
    <div>
      <div className={styles.Wrapper}>
        <Heading className={styles.Heading} size="XL">
          Analytics
        </Heading>
        <div>
          <div className={styles.Wrapper}>
            <Text>Start Date</Text>
            <DateSelector
              onChange={(value: Date | null) => {
                value && setStartDate(value);
              }}
              selectedDate={new Date(startDate)}
            />
          </div>
          <div className={styles.Wrapper}>
            <Text>End Date</Text>
            <DateSelector
              onChange={(value: Date | null) => {
                value && setEndDate(value);
              }}
              selectedDate={new Date(endDate)}
            />
          </div>
        </div>
      </div>
      <PatientInteractions />
      <RequestsCompleted />
      <RequestProcessingTime />
      <ChatAbandonment />
    </div>
  );
};

const mapStateToProps = ({ analytics }: ReduxStateType) => {
  const { requestsReasonsCountFilter, processingStartTime } = analytics;
  return { requestsReasonsCountFilter, processingStartTime };
};

export default connect(mapStateToProps, {
  fetchAnalytics: fetchAnalyticsAction
})(AnalyticsPage);
