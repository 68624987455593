import { ConditionJSON } from "../components/ui/Input/JsonLogicInput/types";

export enum InsightType {
  NOTE_CONTENT = "noteContent",
  CORRECTED_TRANSCRIPT = "correctedTranscript",
  ROLE_IDENTIFIER = "roleIdentifier",
  PATIENT_HANDOUT = "patientHandout"
}

export enum InsightNoteContentCategory {
  SUBJECTIVE = "subjective",
  OBJECTIVE = "objective",
  ASSESSMENT = "assessment",
  PLAN = "plan",
  SUMMARY = "summary",
  BILLING = "billing"
}
export enum InsightUserInteraction {
  ACCEPTED = "accepted",
  REJECTED = "rejected"
}

export enum InsightActions {
  GET_INITIAL_INSIGHTS = "get-initial-insights",
  UPDATE_INSIGHT = "update-insight",
  CREATE_INSIGHT = "create-insight"
}

export enum TranscriptActions {
  GET_TRANSCRIPTS = "get-transcripts"
}

export type InsightNoteContentData = {
  category: InsightNoteContentCategory;
  text: string;
  firstTranscriptId?: number;
  lastTranscriptId?: number;
  title?: string;
  transcriptionSessionId?: string;
  language?: string;
  shouldPromptFeedback?: boolean;
};

export type Insight = {
  id: number;
  type: InsightType;
  output: InsightNoteContentData;
  modelId?: number;
  passesThreshold?: boolean; // Passes scoring thresholds
  userInteraction?: null | "accepted" | "rejected"; // accepted or rejected by provider
  scores?: { confidence?: number; F1?: number; something?: string };
  createdAt: Date;
  appointmentId?: string;
  content?: string;
};

export type UserInteractionHistory = {
  [id: string]: {
    interaction: InsightUserInteraction;
  };
};

export type AudioTranscript = {
  id: number;
  transcriptContent: string;
  createdAt: Date;
  appointmentId?: string;
  recordingSessionId?: string;
  speakerId?: string;
  transcriptionSessionId?: string;
};

export type Model = {
  id: number;
  type: InsightType;
  name: string;
  version: string;
  description: string;
  active: boolean;
  configuration: {
    modelURL: string;
    scores: { path: string }[];
    threshold: ConditionJSON;
  };
  createdAt: Date;
};

export enum SocketMessageType {
  audioData = "audio-data",
  endSession = "endSession",
  pauseSession = "pauseSession",
  notReady = "notReady",
  ready = "ready",
  recordingState = "recording-state",
  providerDetails = "provider-details",
  insights = "insights",
  transcripts = "transcripts",
  chaosMonkey = "chaos-monkey",
  providerActivity = "provider-activity",
  activityTimers = "activity-timers",
  upgrade = "upgrade",
  providerConsentsForAll = "provider-consents-for-all",
  createInsight = "create-insight",
  notifications = "notifications"
}

export type RawAudioChunk = ArrayBuffer | undefined;

export type MessagePayload =
  | { test: string }
  | { appointmentId: string; providerId: number }
  | { appointmentId: string }
  | { appointmentId?: number; scribeConversationId?: number }
  | { appointmentId: string; rawAudioChunk: RawAudioChunk; sequenceNumber: number }
  | { action: string; insightId: number; data?: unknown } // TODO: update data type
  | { monkeyType: string }
  | {
      eventType: string;
      eventData?: { [key: string]: string | number | string[] | number[] | undefined };
    }
  | { insightType: InsightType }
  | { pausedBoolean: boolean };

export enum RecordingSessionStatus {
  "PENDING" = "pending",
  "RECORDING" = "recording",
  "COMPLETE" = "complete",
  "PAUSED" = "paused",
  "LOCAL_STOPPED" = "localStopped", // Front-end Provider only status
  "LOCAL_PAUSED" = "localPaused", // Front-end Provider only status
  "DELETED" = "deleted"
}

export enum PatientConsentStatus {
  "PENDING" = "pending",
  "ACCEPTED" = "accepted",
  "DENIED" = "denied"
}

export type RecordingState = {
  appointmentId: number;
  status: RecordingSessionStatus;
  startedAt: string | null;
  providerReady: boolean;
  patientConsentStatus: PatientConsentStatus;
  sessionCompleted: boolean;
  patientSignature: string;
};

export enum MicStreamErrorType {
  UNKNOWN = "unknown",
  NEED_MIC_PERMISSIONS = "needMicPermissions",
  MIC_CHANGED = "micChanged"
}

export enum ScribeNotificationType {
  LONG_RESPONSE_TIME = "longResponseTime",
  VERY_LONG_RESPONSE_TIME = "veryLongResponseTime",
  MULTIPLE_CONNECTIONS = "multipleConnections"
}

export type NotificationData = {
  context: {
    appointmentId?: number;
    insightCategories?: [];
  };
  type: ScribeNotificationType;
};

export type EventData = {
  activeNoteId?: number;
  copiedTextLength?: number;
  injectedInsightCategories?: string[];
  noteTemplateId?: number;
};

export enum EventTypes {
  SCRIBE_APPT_PAGE_OPEN = "scribeApptPageOpen",
  SCRIBE_MIKA_ACTIVE = "scribeMikaActive",
  SCRIBE_PROVIDER_ACTIVITY = "scribeProviderActivity",
  PUSH_NOTE_TO_EMR = "pushNoteToEMR",
  COPY_NOTE_TO_CLIPBOARD_MANUAL = "copyNoteToClipboardManual",
  COPY_NOTE_TO_CLIPBOARD_BUTTON = "copyNoteToClipboardButton",
  SEND_NOTE_TO_PATIENT = "sendNoteToPatient",
  NOTE_CONTENT_INJECTED = "noteContentInjected",
  ACTIVE_NOTE_CHANGED = "activeNoteChanged",
  PROMPTED_FOR_FEEDBACK = "promptedForFeedback",
  ACTIVE_TOOL_CHANGED = "activeToolChanged"
}
