import React, { useState, createRef, useEffect } from "react";
import { NavLink } from "react-router-dom";

import { Permissions } from "../../../../../../types";
import Text from "../../../../../ui/Text";
import { StaffSettingsPageTabs } from "../../../../../../constants";
import { ChevronDown, ChevronUp } from "../../../../../ui/Icon";

import styles from "./index.module.scss";
import { PermissionsGuard } from "../../../../../../hooks/usePermissions";

const { REASONS } = StaffSettingsPageTabs;

const StaffSettingsDataDropdown = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdown = createRef<HTMLDivElement>();

  const handleClickOutside = (event: MouseEvent | null) => {
    if (
      dropdown.current &&
      event &&
      event.target instanceof HTMLElement &&
      !dropdown.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleClickOutside);

    // returned function will be called on component unmount
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [dropdown]);

  return (
    <div className={styles.Container}>
      <button
        id="dataDropdown"
        type="button"
        className={styles.DataDropdown}
        onClick={handleButtonClick}
      >
        <span>Data</span>
        <span className={styles.Chevron}>
          {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
        </span>
      </button>

      {isOpen && (
        <nav className={styles.Dropdown} ref={dropdown}>
          <PermissionsGuard
            requiredPermissions={[Permissions.FIND_REASONS]}
            requiresVerifiedOrg={false}
          >
            <NavLink
              key={REASONS.label}
              to={REASONS.path}
              className={({ isActive }) => (isActive ? styles.NavLinkActive : styles.NavLink)}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <Text className={styles.LinkText} size="S" component="span">
                {REASONS.label}
              </Text>
            </NavLink>
          </PermissionsGuard>
        </nav>
      )}
    </div>
  );
};

export default StaffSettingsDataDropdown;
