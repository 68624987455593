import React, { useContext, useState, useEffect } from "react";

import { ChatFlowTags } from "../../../../../../../constants";

import {
  PatientConsentStatus,
  RecordingSessionStatus,
  SocketMessageType,
  AppointmentConversation
} from "../../../../../../../types";

import Button from "../../../../../../ui/Button";
import { FloatModal } from "../../../../../../ui/Modal";
import Heading from "../../../../../../ui/Heading";
import Text from "../../../../../../ui/Text";
import Drawer from "../../../../../../ui/Drawer";
import { CheckboxInputBase } from "../../../../../../ui/Input";
import { EncounterSocketContext } from "../../../../../../providers/EncounterSocketProvider";
import { CheckCircle } from "../../../../../../ui/Icon";

import AudioSettings from "../../AudioSettings";

import styles from "./RecordingDetailsModal.module.scss";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  patientFullName: string;
  conversations?: Array<AppointmentConversation>;
  appointmentId?: number;
};

const SettingsSelectDrawer = () => {
  return (
    <Drawer
      initialOpen={false}
      collapse
      size={16}
      headerContent={<Text>Microphone and Language</Text>}
      headerClickable
    >
      <Text size="S" component="div" className={styles.MicTextSpacing}>
        Please select the microphone and language Mika will use to stream audio.
      </Text>
      <br />
      <AudioSettings />
    </Drawer>
  );
};

const RecordingDetailsModal: React.FC<Props> = (props) => {
  const { isOpen, onClose, patientFullName, conversations, appointmentId } = props;

  const { recording, sendMessage, sentScribeInvite } = useContext(EncounterSocketContext);
  const [localProviderReady, setLocalProviderReady] = useState<boolean>(true);
  const [providerConsentsForPatient, setProviderConsentsForPatient] = useState<boolean>(true);

  useEffect(() => {
    // If the modal is open and the recording starts, close the modal. This
    // allows the user to see the RecordingSessionInterface component and
    // have access to the encounter notes field
    if (recording?.startedAt) {
      onClose();
    }
  }, [recording?.startedAt]);

  const handleSetReadyToRecord = (ready: boolean) => {
    sendMessage(ready ? SocketMessageType.ready : SocketMessageType.notReady);
  };

  const providerConsentsForAll = () => {
    const scribeConversation = conversations?.find((conversation) =>
      conversation.tagnames?.includes(ChatFlowTags.SCRIBE_VISIT)
    );
    sendMessage(SocketMessageType.providerConsentsForAll, {
      appointmentId,
      scribeConversationId: scribeConversation?.id
    });
  };

  const activateMikaButton = (
    providerReady: boolean,
    providerConsentsForPatient: boolean,
    patientConsentStatus: PatientConsentStatus
  ) => {
    const patientAcceptedViaChat = patientConsentStatus === PatientConsentStatus.ACCEPTED;

    return (
      <div className={styles.ActionButtonWrapper}>
        <Button
          onClick={() => {
            handleSetReadyToRecord(false);
            setLocalProviderReady(false);
            setProviderConsentsForPatient(false);
            onClose();
          }}
          inline
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (providerConsentsForPatient) {
              providerConsentsForAll();
            } else {
              handleSetReadyToRecord(true);
            }
          }}
          disabled={!providerReady}
          secondary={!patientAcceptedViaChat && !providerConsentsForPatient}
        >
          Activate Mika
        </Button>
      </div>
    );
  };

  if (!recording) return null;
  if (recording.status === RecordingSessionStatus.RECORDING) return null; // Can't open this modal while recording

  if (recording.sessionCompleted) {
    return (
      <FloatModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <Heading size="L">Streaming session completed!</Heading>
        <div className={styles.IconCenter}>
          <CheckCircle size={90} />
        </div>
      </FloatModal>
    );
  }

  const patientConsented =
    recording && recording.patientConsentStatus === PatientConsentStatus.ACCEPTED;

  const patientDidNotConsentBeforeAppt =
    !patientConsented &&
    ((recording &&
      sentScribeInvite &&
      recording.patientConsentStatus === PatientConsentStatus.PENDING) ||
      !sentScribeInvite);

  const patientDeniedConsent =
    recording && recording.patientConsentStatus === PatientConsentStatus.DENIED;

  return (
    <FloatModal
      isOpen={isOpen}
      onClose={() => {
        setLocalProviderReady(false);
        setProviderConsentsForPatient(false);
        onClose();
      }}
    >
      <div className={styles.ScrollContainer}>
        {patientDidNotConsentBeforeAppt && !patientDeniedConsent && (
          <>
            <Heading size="L">Activate Mika during this appointment</Heading>
            {localProviderReady && providerConsentsForPatient && (
              <Text size="M" className={styles.AllConsentText}>
                Patient has provided consent
              </Text>
            )}
            {!localProviderReady && (
              <Text size="M" className={styles.PatientNoConsentText}>
                Patient hasn&apos;t provided consent
              </Text>
            )}
            <br />
            {localProviderReady && providerConsentsForPatient && (
              <Text size="M">
                You have confirmed that your patient has given verbal consent to use Mika.
              </Text>
            )}
            {!localProviderReady && !providerConsentsForPatient && (
              <Text size="M">
                <span className={styles.BoldText}>Confirm with your patient verbally</span> if they
                consent to using Mika during their appointment.
              </Text>
            )}
            <div className={styles.HeadingSpacing}>
              <div style={{ marginBottom: "45px" }}>
                <CheckboxInputBase
                  onChange={() => {
                    setLocalProviderReady(!localProviderReady);
                    setProviderConsentsForPatient(!providerConsentsForPatient);
                  }}
                  initialValue={providerConsentsForPatient}
                  fieldName="providerConsentsForPatient"
                  label={<span>I confirm that we both consent to use Mika.</span>}
                  labelClickable
                />
              </div>
              {localProviderReady && <SettingsSelectDrawer />}
              <div className={styles.ButtonGroup}>
                {activateMikaButton(
                  localProviderReady,
                  providerConsentsForPatient,
                  recording.patientConsentStatus
                )}
              </div>
            </div>
          </>
        )}

        {patientDeniedConsent && (
          <>
            <Heading size="L">Activate Mika during this appointment</Heading>
            <Text className={styles.DeniedConsentText}>Patient declined consent</Text>
            <br />
            <Text size="M">
              Mika cannot make suggestions for your notes because your patient declined consent.
              Still, Mika can track your time when you activate it.
            </Text>
            <div className={styles.HeadingSpacing}>
              <div className={styles.ButtonGroup}>
                {activateMikaButton(
                  localProviderReady,
                  providerConsentsForPatient,
                  recording.patientConsentStatus
                )}
              </div>
            </div>
          </>
        )}

        {patientConsented && (
          <>
            <Heading size="L">Activate Mika during this appointment</Heading>
            <Text size="M" className={styles.AllConsentText}>
              Patient has provided consent
            </Text>
            <br />
            <Text size="M">
              <span className={styles.BoldText}>Please confirm with your patient</span> that they
              still consent to using Mika during their appointment.
            </Text>
            <div className={styles.ConsentStatusAccepted}>
              <Text size="S" component="div">
                Patient &quot;<span className={styles.BoldText}>{patientFullName}</span>&quot; has
                given consent to use Mika.
              </Text>
              <br />
              <Text size="S" component="div">
                Consent Signature: &quot;
                <span className={styles.BoldText}>{recording.patientSignature}</span>&quot;
              </Text>
            </div>
            <div className={styles.HeadingSpacing}>
              <div style={{ marginBottom: "45px" }}>
                <CheckboxInputBase
                  onChange={() => {
                    setLocalProviderReady(!localProviderReady);
                  }}
                  initialValue={localProviderReady}
                  fieldName="localProviderReady"
                  label={<span>I confirm that we both consent to use Mika.</span>}
                />
              </div>
              <SettingsSelectDrawer />
              <div className={styles.ButtonGroup}>
                {activateMikaButton(
                  localProviderReady,
                  providerConsentsForPatient,
                  recording.patientConsentStatus
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </FloatModal>
  );
};

export default RecordingDetailsModal;
