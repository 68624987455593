import { InputValue } from "../../types";

export const isRequired = (message?: string) => (value: InputValue) => {
  const errorMessage = message || "Field required";

  if (Array.isArray(value)) {
    return value.length === 0 ? errorMessage : undefined;
  }

  return (!value || (typeof value === "string" && !value.trim())) && value !== 0
    ? errorMessage
    : undefined;
};
