import React, { useMemo } from "react";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import { v4 as uuid } from "uuid";

import Heading from "../../../ui/Heading";
import { FloatModal } from "../../../ui/Modal";
import {
  closeModal as closeModalAction,
  updateLocation as updateLocationAction,
  UpdateLocationData
} from "../../../../actions";
import { Form, ToggleInputBase } from "../../../ui/Input";

import { Location, ChatNodeTypes, JsonContent } from "../../../../types";

import styles from "./index.module.scss";
import EditLocationForm from "./EditLocationForm";

type PropsType = {
  organizationId: number;
  location: Location;
  closeModal: () => void;
  updateLocation: (
    organizationId: number,
    locationId: number,
    data: UpdateLocationData,
    onSuccess?: () => void,
    onError?: () => void
  ) => void;
};

const defaultInformation: JsonContent = [
  {
    id: uuid(),
    type: ChatNodeTypes.content,
    properties: undefined,
    children: []
  }
];

export type FormState = {
  displayName: string;
  street: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  timezone: string;
  email: string;
  telecom: string;
  telecomExtension: string;
  syncLock: string;
  information: JsonContent;
};

const initialState = {
  displayName: "",
  street: "",
  city: "",
  state: "",
  country: "",
  postalCode: "",
  timezone: "",
  email: "",
  telecom: "",
  telecomExtension: "",
  syncLock: "false",
  information: defaultInformation
};

const EditLocation = ({ organizationId, location, closeModal, updateLocation }: PropsType) => {
  const {
    active,
    displayName,
    street,
    city,
    state,
    country,
    postalCode,
    timezone,
    email,
    telecom,
    telecomExtension,
    syncLock,
    information
  } = location;

  const initialFormState = useMemo(() => {
    const newFormState: FormState = cloneDeep(initialState);
    newFormState.displayName = displayName;
    newFormState.street = street || "";
    newFormState.city = city || "";
    newFormState.state = state || "";
    newFormState.country = country || "";
    newFormState.postalCode = postalCode || "";
    newFormState.timezone = timezone;
    newFormState.email = email || "";
    newFormState.telecom = telecom;
    newFormState.telecomExtension = telecomExtension;
    newFormState.syncLock = syncLock ? "true" : "false";
    newFormState.information = information?.length > 0 ? information : defaultInformation;
    return newFormState;
  }, [
    displayName,
    street,
    city,
    state,
    country,
    postalCode,
    timezone,
    email,
    telecom,
    telecomExtension,
    syncLock,
    information
  ]);

  const locationActiveHandler = (newActive: boolean) => {
    updateLocation(organizationId, location.id, { active: newActive }, undefined);
  };

  const save = async (formValues: FormState) => {
    updateLocation(
      organizationId,
      location.id,
      {
        displayName: formValues.displayName,
        street: formValues.street,
        city: formValues.city,
        state: formValues.state,
        country: formValues.country,
        postalCode: formValues.postalCode,
        timezone: formValues.timezone,
        email: formValues.email || "", // Non-required field, allows updating to empty string
        telecom: formValues.telecom,
        telecomExtension: formValues.telecomExtension || "", // Non-required field
        syncLock: formValues.syncLock === "true",
        information: formValues.information
      },
      () => closeModal()
    );
  };
  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L" className={styles.Heading}>
        Edit Location
      </Heading>

      <div className={styles.ActiveToggle}>
        <ToggleInputBase
          fieldName="active"
          label="active"
          labelChecked="Active"
          labelUnchecked="Inactive"
          initialValue={active}
          onChange={(active) => {
            locationActiveHandler(active);
          }}
        />
      </div>

      <Form
        onSubmit={(formState) => save(formState.values as FormState)}
        initialValues={initialFormState}
      >
        <EditLocationForm initialFormState={initialFormState} closeModal={closeModal} />
      </Form>
    </FloatModal>
  );
};

export default connect(null, {
  closeModal: closeModalAction,
  updateLocation: updateLocationAction
})(EditLocation);
