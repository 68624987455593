import axios from "axios";

import getToken from "../utils/getToken";

import { closeModal } from "./modals";
import { addNotification } from "./notifications";

import { fetchAutomation, fetchAutomatedMessages } from "./automatedMessages";

import {
  ROOT_URL,
  ADD_MESSAGE_CONFIGURATION,
  UPDATE_MESSAGE_CONFIGURATION,
  DELETE_MESSAGE_CONFIGURATION
} from "../constants";
import { Dispatch, ActionStatus, Automation, TouchpointSettingExclude } from "../types";

export type AddMessageConfigurationAction = {
  type: typeof ADD_MESSAGE_CONFIGURATION;
  status?: ActionStatus;
};

export enum AppointmentTouchpointType {
  BEFORE_APPOINTMENT_START = "beforeAppointmentStart",
  AFTER_APPOINTMENT_START = "afterAppointmentStart",
  BOOK_EVENT = "bookEvent",
  CANCEL_EVENT = "cancelled message",
  COMPLETION_EVENT = "completionEvent"
}

export type addMessageConfigurationData = {
  type: AppointmentTouchpointType;
  automationIds: string[];
  templateId: number;
  multiTemplateId: number | null;
  triggerValue: number;
  triggerUnit: string;
  chatFlowIds: number[];
  exclusionHours: number;
  note: string;
  settings: {
    exclude: TouchpointSettingExclude;
  } | null;
};

export type addMessageConfigurationOptions = {
  onSuccess?: () => void;
};

type AddMessageConfigurationResponse = {
  status: number;
  data: {
    success: boolean;
    conflictingTriggers?: Array<Automation>;
    configuration: {
      active: boolean;
      automationId: number;
      id: number;
      scheduleId: number;
      templateId: number;
      topic: string;
    };
  };
};

export const addMessageConfiguration =
  (
    messageConfigurationData: addMessageConfigurationData,
    options?: addMessageConfigurationOptions
  ) =>
  async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };
    const { onSuccess } = options || {};
    dispatch({
      type: ADD_MESSAGE_CONFIGURATION,
      status: ActionStatus.loading
    });

    return axios
      .post(`${ROOT_URL}/messages/configurations`, messageConfigurationData, config)
      .then((payload: AddMessageConfigurationResponse) => {
        if (!payload || !payload.data.success) {
          dispatch({
            type: ADD_MESSAGE_CONFIGURATION,
            status: ActionStatus.error
          });

          const errorMessage = "Please try again";

          return dispatch(
            addNotification({
              type: "error",
              title: "Failed to add the schedule-based message",
              subtitle: errorMessage,
              autoDismiss: true
            })
          );
        }
        // TODO: add onsuccess option which will refetch automation or automations dashboard
        // fetchAutomation(messageConfigurationData.automationId.toString())(dispatch);
        if (onSuccess) {
          onSuccess();
        }

        fetchAutomatedMessages()(dispatch);

        closeModal()(dispatch);

        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: "Schedule-based message created",
            autoDismiss: true
          })
        );
        return dispatch({
          type: ADD_MESSAGE_CONFIGURATION,
          status: ActionStatus.success
        });
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to update the schedule-based message",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );

        return dispatch({
          type: ADD_MESSAGE_CONFIGURATION,
          status: ActionStatus.error
        });
      });
  };

export type UpdateMessageConfigurationAction = {
  type: typeof UPDATE_MESSAGE_CONFIGURATION;
  status?: ActionStatus;
};

export type UpdateMessageConfigurationData = {
  id: number;
  templateId: number | null;
  multiTemplateId: number | null;
  chatFlowIds: number[];
  note: string;
  exclusionHours: number | undefined;
  settings: {
    exclude: TouchpointSettingExclude;
  } | null;
};

export type UpdateMessageConfigurationOptions = {
  onSuccess?: () => void;
};

type UpdateMessageConfigurationResponse = {
  status: number;
  data: {
    success: boolean;
  };
};

export const updateMessageConfiguration =
  (
    messageConfigurationData: UpdateMessageConfigurationData,
    options?: UpdateMessageConfigurationOptions
  ) =>
  async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };
    const { onSuccess } = options || {};
    dispatch({
      type: UPDATE_MESSAGE_CONFIGURATION,
      status: ActionStatus.loading
    });

    const handleFailure = () => {
      dispatch({
        type: UPDATE_MESSAGE_CONFIGURATION,
        status: ActionStatus.error
      });

      const errorMessage = "Please try again";

      return dispatch(
        addNotification({
          type: "error",
          title: "Failed to add the schedule-based message",
          subtitle: errorMessage,
          autoDismiss: true
        })
      );
    };

    return axios
      .patch(
        `${ROOT_URL}/messages/configurations/${messageConfigurationData.id}`,
        messageConfigurationData,
        config
      )
      .then((payload: UpdateMessageConfigurationResponse) => {
        if (!payload || !payload.data.success) {
          return handleFailure();
        }

        if (onSuccess) {
          onSuccess();
        }

        fetchAutomatedMessages()(dispatch);

        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: "Schedule-based message updated",
            autoDismiss: true
          })
        );
        return dispatch({
          type: UPDATE_MESSAGE_CONFIGURATION,
          status: ActionStatus.success
        });
      })
      .catch(() => {
        return handleFailure();
      });
  };

export type MessageConfigurationDeleteData = {
  configurationId: number;
  automationId: string;
};

export type DeleteMessageConfigurationAction = {
  type: typeof DELETE_MESSAGE_CONFIGURATION;
  status?: ActionStatus;
};

type DeleteMessageConfigurationResponse = {
  status: number;
  data: {
    error?: boolean;
  };
};

export const deleteMessageConfiguration =
  (data: MessageConfigurationDeleteData) => (dispatch: Dispatch) => {
    const { configurationId, automationId } = data;
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    const handleFailure = () => {
      dispatch(
        addNotification({
          type: "error",
          title: "Failed to delete schedule-based message",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );

      dispatch({
        type: DELETE_MESSAGE_CONFIGURATION,
        status: ActionStatus.error
      });
    };

    dispatch({
      type: DELETE_MESSAGE_CONFIGURATION,
      status: ActionStatus.loading
    });

    return axios
      .delete(`${ROOT_URL}/messages/configurations/${configurationId}`, config)
      .then((payload: DeleteMessageConfigurationResponse) => {
        if (!payload || payload.data.error) {
          handleFailure();
          return;
        }
        closeModal()(dispatch);

        fetchAutomation(automationId)(dispatch);
        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: "Schedule-based Message deleted",
            autoDismiss: true
          })
        );
        dispatch({
          type: DELETE_MESSAGE_CONFIGURATION,
          status: ActionStatus.success
        });
      })
      .catch(() => {
        return handleFailure();
      });
  };
