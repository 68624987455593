import { v4 as uuid } from "uuid";

import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from "../constants";

import { NewNotification, NotificationType } from "../types";

export type RemoveNotificationAction = {
  type: typeof REMOVE_NOTIFICATION;
  payload: {
    notificationId: string;
  };
};
export const removeNotification = (notificationId: string): RemoveNotificationAction => {
  return {
    type: REMOVE_NOTIFICATION,
    payload: {
      notificationId
    }
  };
};

export type AddNotificationAction = {
  type: typeof ADD_NOTIFICATION;
  payload: {
    id: string;
    type: NotificationType;
    title: string;
    subtitle?: string | undefined;
    autoDismiss?: boolean | undefined;
  };
};

export const addNotification = (notification: NewNotification): AddNotificationAction => {
  const notificationId = uuid();
  return {
    type: ADD_NOTIFICATION,
    payload: {
      ...notification,
      id: notificationId
    }
  };
};

export type InvalidAutomationAssociation = {
  id: number;
  displayName: string;
  reasons: Array<{ id: number; value: string; emrReasonId: string; checklistId: number }>;
};

export const invalidAutomationAssociationsNotification = (
  invalidAutomations: Array<InvalidAutomationAssociation>
): AddNotificationAction => {
  return addNotification({
    type: "error",
    title:
      "Failed to save change. All published automations with instruction chats need to have linked reasons with instructions",
    subtitle: invalidAutomations
      .map((invalidAutomation) => {
        if (invalidAutomation.reasons.length === 0) {
          return `- "${invalidAutomation.displayName}" requires reasons to be associated. `;
        }
        const reasonsToShow = 3;
        const reasonValues = invalidAutomation.reasons
          .slice(0, reasonsToShow)
          .map((reason) => reason.value)
          .join(", ");

        return `- "${invalidAutomation.displayName}" has ${
          invalidAutomation.reasons.length
        } reasons needing instructions. Including: (${reasonValues}${
          invalidAutomation.reasons.length > reasonsToShow ? "..." : ""
        })`;
      })
      .join("\n"),
    autoDismiss: false
  });
};
