import React from "react";
import cx from "classnames";

import Text from "../../../../ui/Text";
import Tooltip from "../../../../ui/Tooltip";
import { Message, Chats } from "../../../../ui/Icon";
import { StopClickPropagation } from "../../../../ui/TableGrid";

import { getTouchpointLabel, getTouchpointDescription, orderByAppointmentOffset } from "./helpers";

import {
  AutomatedMessageConfiguration,
  MessageSchedule,
  MessageScheduleEvent
} from "../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  published: boolean;
  touchpoints: AutomatedMessageConfiguration[];
  messageSchedules: MessageSchedule[];
  onTouchpointSelection?: (automatedMessageConfigurationId: number) => void;
  selectedTouchpointId?: string;
};

type TouchpointProps = {
  touchpoint: AutomatedMessageConfiguration & { messageSchedule: MessageSchedule | null };
  published: boolean;
  showTimelineLeft?: boolean;
  showTimelineRight?: boolean;
  onTouchpointSelection?: (automatedMessageConfigurationId: number) => void;
  isSelected?: boolean;
};

const Touchpoint = ({
  touchpoint,
  published,
  showTimelineLeft,
  showTimelineRight,
  onTouchpointSelection,
  isSelected = false
}: TouchpointProps) => {
  const touchpointId: number = touchpoint?.id;

  return (
    <div
      id="journeyTimeline"
      className={cx(styles.TouchpointContainer, {
        [styles.TouchpointContainerDraft]: !published,
        [styles.TouchpointContainerTimelineLeft]: showTimelineLeft,
        [styles.TouchpointContainerTimelineRight]: showTimelineRight
      })}
    >
      <Tooltip
        icon={
          <StopClickPropagation>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              className={cx(styles.Touchpoint, {
                [styles.TouchpointDraft]: !published,
                [styles.TouchpointEmpty]: !touchpoint,
                [styles.TouchpointSelected]: isSelected
              })}
              onClick={
                onTouchpointSelection && touchpointId
                  ? () => onTouchpointSelection(touchpointId)
                  : undefined
              }
            >
              {touchpoint && (
                <Text
                  size="XS"
                  className={cx(styles.TouchpointText, {
                    [styles.TouchpointTextDraft]: !published
                  })}
                >
                  {getTouchpointLabel(touchpoint)}
                </Text>
              )}
            </div>
          </StopClickPropagation>
        }
      >
        <Text size="S">{getTouchpointDescription(touchpoint?.messageSchedule)}</Text>
        <div className={styles.TooltipRow}>
          <Message />
          <Text size="XS" className={styles.TooltipRowText}>
            {touchpoint?.templateDisplayName}
          </Text>
        </div>
        {touchpoint?.multiTemplateDisplayName && (
          <div className={styles.TooltipRow}>
            <Message />
            <Text size="XS" className={styles.TooltipRowText}>
              {touchpoint.multiTemplateDisplayName}
            </Text>
          </div>
        )}
        {touchpoint?.chatFlows &&
          touchpoint.chatFlows.map((chatFlow) => {
            return (
              <div key={`${(chatFlow.id, touchpointId)}`} className={styles.TooltipRow}>
                <Chats size={20} />
                <Text size="XS" className={styles.TooltipRowText}>
                  {chatFlow.title}
                </Text>
              </div>
            );
          })}
      </Tooltip>
    </div>
  );
};

const JourneyTimeline = ({
  touchpoints,
  messageSchedules,
  published,
  onTouchpointSelection,
  selectedTouchpointId = ""
}: PropsType) => {
  const hasTouchpoints = touchpoints.length > 0;
  const messageSchedulesById: { [scheduleId: string]: MessageSchedule } = messageSchedules.reduce(
    (schedulesById, messageSchedule) => {
      // eslint-disable-next-line no-param-reassign
      schedulesById[messageSchedule.id.toString()] = messageSchedule;
      return schedulesById;
    },
    {} as {
      [scheduleId: string]: MessageSchedule;
    }
  );
  const touchpointWithSchedules = touchpoints.map((touchpoint) => {
    const messageSchedule = messageSchedulesById[touchpoint.scheduleId];
    return { ...touchpoint, messageSchedule: messageSchedule || null };
  });

  const touchpointsByMessageScheduleId: {
    [scheduleId: string]: AutomatedMessageConfiguration & {
      messageSchedule: MessageSchedule | null;
    };
  } = {};
  touchpointWithSchedules.forEach((touchpoint) => {
    touchpointsByMessageScheduleId[touchpoint.scheduleId.toString()] = touchpoint;
  });

  const eventBasedSchedules = messageSchedules
    .filter((messageSchedule) => {
      return (
        messageSchedule &&
        (messageSchedule.event === MessageScheduleEvent.BOOKED ||
          messageSchedule.event === MessageScheduleEvent.CANCELLED ||
          messageSchedule.event === MessageScheduleEvent.COMPLETE)
      );
    })
    .sort((a, b) => {
      if (a.event > b.event) return 1;
      if (a.event < b.event) return -1;
      return 0;
    });

  const appointmentBasedBeforeSchedulesWithTouchpoints = messageSchedules
    .filter((messageSchedule) => {
      return (
        messageSchedule &&
        messageSchedule.event === MessageScheduleEvent.START &&
        messageSchedule.triggerValue < 0
      );
    })
    .map((messageSchedule) => {
      const touchpoint = touchpointsByMessageScheduleId[messageSchedule.id.toString()];
      return { ...messageSchedule, touchpoint };
    })
    .sort(orderByAppointmentOffset);

  const firstBeforeTouchpointIndex = appointmentBasedBeforeSchedulesWithTouchpoints.findIndex(
    (messageSchedule) => Boolean(messageSchedule.touchpoint)
  );

  const appointmentBasedAfterSchedules = messageSchedules
    .filter((messageSchedule) => {
      return (
        messageSchedule &&
        messageSchedule.event === MessageScheduleEvent.START &&
        messageSchedule.triggerValue > 0
      );
    })
    .map((messageSchedule) => {
      const touchpoint = touchpointsByMessageScheduleId[messageSchedule.id.toString()];
      return { ...messageSchedule, touchpoint };
    })
    .sort(orderByAppointmentOffset);

  // TypeScript bug where findLastIndex is not defined on global Array interface
  // see ticket https://github.com/microsoft/TypeScript/issues/48829
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const lastAfterTouchpointIndex = (appointmentBasedAfterSchedules as any).findLastIndex(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (messageSchedule: any) => Boolean(messageSchedule.touchpoint)
  );

  if (!hasTouchpoints) {
    return (
      <Text className={styles.NoTouchpointsText} size="XS">
        No touchpoints
      </Text>
    );
  }

  return (
    <div className={styles.Wrapper}>
      {eventBasedSchedules.length > 0 && (
        <div className={styles.EventBased}>
          {eventBasedSchedules.map((eventBasedSchedule) => {
            const touchpoint = touchpointsByMessageScheduleId[eventBasedSchedule.id.toString()];
            const isSelected = touchpoint?.id.toString() === selectedTouchpointId;

            return (
              <Touchpoint
                key={`eventBasedTouchpoint-${eventBasedSchedule.id}${touchpoint?.id}`}
                touchpoint={touchpoint}
                published={published}
                onTouchpointSelection={onTouchpointSelection}
                isSelected={isSelected}
              />
            );
          })}
        </div>
      )}

      {appointmentBasedBeforeSchedulesWithTouchpoints.map((appointmentBasedSchedule, idx) => {
        const touchpoint = appointmentBasedSchedule?.touchpoint;
        const showTimelineLeft =
          firstBeforeTouchpointIndex !== -1 && idx > firstBeforeTouchpointIndex;
        const showTimelineRight =
          firstBeforeTouchpointIndex !== -1 && idx >= firstBeforeTouchpointIndex;
        const isSelected = touchpoint?.id.toString() === selectedTouchpointId;

        return (
          <Touchpoint
            key={`eventBasedTouchpoint-${appointmentBasedSchedule?.id}${touchpoint?.id}`}
            touchpoint={appointmentBasedSchedule.touchpoint}
            published={published}
            showTimelineLeft={showTimelineLeft}
            showTimelineRight={showTimelineRight}
            onTouchpointSelection={onTouchpointSelection}
            isSelected={isSelected}
          />
        );
      })}
      <div
        className={cx(styles.AppointmentStart, { [styles.AppointmentStartDraft]: !published })}
      />
      {appointmentBasedAfterSchedules.map((appointmentBasedSchedule, idx) => {
        const touchpoint = appointmentBasedSchedule?.touchpoint;
        const showTimelineLeft = lastAfterTouchpointIndex !== -1 && idx <= lastAfterTouchpointIndex;
        const showTimelineRight = lastAfterTouchpointIndex !== -1 && idx < lastAfterTouchpointIndex;
        const isSelected = touchpoint?.id.toString() === selectedTouchpointId;

        return (
          <Touchpoint
            key={`eventBasedTouchpoint-${appointmentBasedSchedule.id}${touchpoint?.id}`}
            touchpoint={touchpoint}
            published={published}
            showTimelineLeft={showTimelineLeft}
            showTimelineRight={showTimelineRight}
            onTouchpointSelection={onTouchpointSelection}
            isSelected={isSelected}
          />
        );
      })}
    </div>
  );
};

export default JourneyTimeline;
