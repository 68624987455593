import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";

import TableGrid from "../../../../ui/TableGrid";
import Loader from "../../../../ui/Loader";
import Text from "../../../../ui/Text";
import Heading from "../../../../ui/Heading";
import Search from "../../../../ui/Search";
import Status from "../../../../ui/Status";

import MessageTemplateDetails from "./MessageTemplateDetails";

import { updateQueryString, useQueryString } from "../../../../../utils/queryStringHelpers";
import formatDate from "../../../../../utils/formatDate";
import useSearchFilter from "../../../../../hooks/useSearchFilter";
import { fetchMessageTemplates } from "../../../../../actions";

import { StatusComponentConfigMap, MessageTemplate, ReduxStateType } from "../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  messageTemplates: { data: Array<MessageTemplate> };
  loading: boolean;
  fetchMessages: () => void;
};

const isDefaultConfigMap: StatusComponentConfigMap = {
  default: "green",
  custom: "yellow"
};

const isDefaultOptions = [
  { label: "Default", value: "default" },
  { label: "Custom", value: "custom" }
];

const MessageTemplatesTable = ({
  messageTemplates,
  loading,
  fetchMessages
}: PropsType): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { parsed } = useQueryString();

  const headers = [
    { colName: "id", content: "ID" },
    { colName: "displayName", content: "Display Name" },
    { colName: "isDefault", content: "" },
    { colName: "medium", content: "Type" },
    { colName: "createdAt", content: "Created" },
    { colName: "updatedAt", content: "Updated" }
  ];
  const { filteredRows, onSearchChange, onSearchClear } = useSearchFilter<MessageTemplate>(
    messageTemplates.data,
    (search: string, messageTemplate: MessageTemplate) => {
      return !!(
        (messageTemplate.displayName &&
          messageTemplate.displayName.toLowerCase().includes(search.toLowerCase())) ||
        (messageTemplate.id && messageTemplate.id.toString().includes(search.toLowerCase())) ||
        (messageTemplate.topic &&
          messageTemplate.topic.toLowerCase().includes(search.toLowerCase()))
      );
    }
  );

  const maxPageRows = 20;

  const isDetailsModalOpen = Boolean(parsed.messageTemplateId);

  const openModal = (messageTemplateId: number): void => {
    updateQueryString({ messageTemplateId: messageTemplateId.toString() }, setSearchParams);
  };

  const closeModal = (): void => {
    updateQueryString({ messageTemplateId: undefined }, setSearchParams);
  };

  // Fetch messages on first load
  useEffect(() => {
    fetchMessages();
  }, []);

  if (loading) {
    return <Loader screen />;
  }

  const rowsData = filteredRows.map((row) => {
    return {
      ...row,
      __onRowClick: (): void => {
        openModal(row.id);
      },
      isDefault: (
        <Status
          value={row.isDefault ? "default" : "custom"}
          options={isDefaultOptions}
          configMap={isDefaultConfigMap}
        />
      ),
      displayName: <Text bold>{row.displayName}</Text>,
      createdAt: formatDate(row.createdAt, "simpleDate"),
      updatedAt: formatDate(row.updatedAt, "simpleDate")
    };
  });

  return (
    <>
      <TableGrid
        headers={headers}
        rows={rowsData}
        showRowFocus
        headerContent={
          <div className={styles.TableHeader}>
            <Heading className={styles.TableHeaderTitle}>Messages</Heading>
            <Search classNames={styles.Search} onChange={onSearchChange} onClear={onSearchClear} />
          </div>
        }
        maxPageRows={maxPageRows}
      />
      <MessageTemplateDetails
        messageTemplateId={parsed.messageTemplateId}
        isModalOpen={isDetailsModalOpen}
        closeModal={closeModal}
      />
    </>
  );
};

const mapStateToProps = ({ messageTemplates }: ReduxStateType) => {
  return {
    messageTemplates,
    loading: messageTemplates.messageTemplateFetchLoading
  };
};

export default connect(mapStateToProps, {
  fetchMessages: fetchMessageTemplates
})(MessageTemplatesTable);
