import React from "react";

import InboxTable from "../../../common/tables/InboxTable";

const InboxPage = () => {
  return (
    <div>
      <InboxTable />
    </div>
  );
};

export default InboxPage;
