import React, { ChangeEvent, useState, useRef, useEffect } from "react";
import { useField } from "informed";
import cx from "classnames";

import BaseInput from "../BaseInput";
import Button from "../../Button";
import { EditPencil } from "../../Icon";
import styles from "./index.module.scss";

import { InputPropsType } from "../../../../types";

type PropsType = InputPropsType & {
  initialValue?: string | number;
  id?: string;
  extendedWidth?: boolean;
};

const TitleInput = ({
  id = "",
  fieldName,
  label,
  info,
  infoStatus,
  placeholder,
  disabled = false,
  validate,
  customOnChange,
  initialValue,
  extendedWidth,
  warningMessage = "",
  ...props
}: PropsType) => {
  const { fieldState, fieldApi } = useField({
    ...props,
    name: fieldName,
    validate,
    initialValue
  });
  const value = fieldState.value as string | readonly string[] | number | undefined;
  const error = fieldState.error as string | undefined;

  const { setValue, setTouched, validate: validateInput } = fieldApi;

  const [inputInFocus, setInputInFocus] = useState(false);

  const inputElement = useRef<HTMLInputElement | null>(null);

  const resizeInputField = () => {
    if (inputElement.current) {
      inputElement.current.style.width = `${String(value).length + 1}ch`;
    }
  };

  useEffect(() => {
    resizeInputField();
  }, []);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const inputValue = event.target.value || "";
    setValue(inputValue);
    setTouched(true);
    validateInput();
    resizeInputField();

    if (customOnChange) {
      customOnChange({ [fieldName]: inputValue });
    }
  };

  const getFocus = () => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  };
  return (
    <BaseInput
      fieldName={fieldName}
      error={error}
      info={info}
      infoStatus={infoStatus}
      warningMessage={warningMessage}
    >
      <div className={styles.item}>
        <input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          ref={(elementRef) => {
            inputElement.current = elementRef;
          }}
          id={id || fieldName}
          className={cx(styles.Input, extendedWidth ? styles.InputExtendedWidth : undefined, {
            [styles.InputError]: error
          })}
          type="text"
          placeholder={placeholder}
          aria-label={label}
          aria-required="true"
          disabled={disabled}
          onChange={onChangeHandler}
          onBlur={(e) => {
            setInputInFocus(false);
            setTouched(true);
          }}
          onFocus={() => {
            setInputInFocus(true);
          }}
          value={!value && value !== 0 ? "" : value}
        />

        {!disabled && inputInFocus === false && (
          <Button
            inline
            onClick={() => {
              getFocus();
            }}
          >
            <div className={styles.icon}>
              <EditPencil />
            </div>
          </Button>
        )}
      </div>
    </BaseInput>
  );
};

export default TitleInput;
