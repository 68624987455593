import React from "react";
import cx from "classnames";

import { useFormApi, useFormState } from "informed";
import Button from "../../../../ui/Button";
import Text from "../../../../ui/Text";
import { SelectInput, TextInput } from "../../../../ui/Input";

import styles from "./index.module.scss";
import { MessageTemplate, MessageTemplateMedium, Option } from "../../../../../types";
import Heading from "../../../../ui/Heading";
import { isEmail, isPhone } from "../../../../../utils/validators";

type AppointmentRequestDirectMessageFormData = {
  messageTemplateId: string;
  mobilePhone: string | null;
  emailAddress: string | null;
  assignedStaffUserId: string | null;
};

type PropsType = {
  messageTemplates?: Array<MessageTemplate>;
  closeModal?: () => void;
  staffOptions: { label: string; value: string }[];
  setPreviewMedium: (previewMedium: MessageTemplateMedium) => void;
  previewMedium: MessageTemplateMedium;
  updateError: () => void;
  formDisabled?: boolean;
  errorMessage: string;
  messageTemplateOptions: Option[];
  displaySelectedMessageTemplate: (
    messageTemplateId: string,
    previewMedium: MessageTemplateMedium
  ) => JSX.Element | string;
};

const phoneNumberValidator = isPhone("Please enter a valid phone number");
const emailValidator = isEmail("Please enter a valid email");

const FormContent = ({
  closeModal,
  messageTemplates,
  staffOptions = [],
  setPreviewMedium,
  previewMedium,
  updateError,
  formDisabled,
  errorMessage,
  messageTemplateOptions,
  displaySelectedMessageTemplate
}: PropsType) => {
  const formState = useFormState();
  const formApi = useFormApi();
  const values = formState.values as AppointmentRequestDirectMessageFormData;
  return (
    <div>
      <Heading>Appointment Request Direct Message</Heading>
      <Text className={styles.Text}>
        You can send an appointment request link via SMS, Email, or both.
      </Text>
      <SelectInput
        fieldName="messageTemplateId"
        label="Template"
        options={messageTemplateOptions}
        placeholder="None"
        disabled={formDisabled}
        customOnChange={(event) => {
          const { messageTemplateId } = event;
          if (!messageTemplateId || messageTemplateId === "none") {
            formApi.setValue("payloadContent", " ");
            return;
          }

          const selectedTemplate = messageTemplates?.find(
            (template) => template.id.toString() === messageTemplateId
          );
          const payloadContent = selectedTemplate?.payloadContent;
          if (payloadContent) {
            formApi.setValue("payloadContent", payloadContent);
          }
        }}
      />
      <TextInput
        fieldName="mobilePhone"
        label="Phone Number"
        placeholder="Enter a mobile phone"
        disabled={formDisabled}
        customOnChange={() => {
          updateError();
        }}
        validate={phoneNumberValidator}
      />
      <TextInput
        fieldName="emailAddress"
        label="Email Address"
        placeholder="Enter an email address"
        disabled={formDisabled}
        customOnChange={() => {
          updateError();
        }}
        validate={emailValidator}
      />

      <SelectInput
        fieldName="assignedStaffUserId"
        label="Assign Task to"
        options={[{ label: "None", value: "" }, ...staffOptions]}
        disabled={formDisabled}
      />

      <Heading size="META">Preview Message Template</Heading>

      <div className={styles.Tabs}>
        <Button
          id="touchpoint-mode-sms"
          inline
          className={cx(styles.Tab, {
            [styles.TabActive]: previewMedium === MessageTemplateMedium.sms
          })}
          onClick={() => setPreviewMedium(MessageTemplateMedium.sms)}
        >
          SMS
        </Button>
        <Button
          id="touchpoint-mode-email"
          inline
          className={cx(styles.Tab, {
            [styles.TabActive]: previewMedium === MessageTemplateMedium.email
          })}
          onClick={() => setPreviewMedium(MessageTemplateMedium.email)}
        >
          Email
        </Button>
      </div>
      <div className={styles.PreviewMessage}>
        {displaySelectedMessageTemplate(values.messageTemplateId, previewMedium)}
      </div>

      <div>
        <div className={styles.ErrorMessage}>{errorMessage}</div>
        <div className={styles.ButtonGroup}>
          <Button inline onClick={closeModal}>
            Cancel
          </Button>
          <Button
            id="sendAppointmentRequestDirectMessage"
            type="submit"
            disabled={formDisabled || !values.messageTemplateId}
          >
            Send Appointment Request Link
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FormContent;
