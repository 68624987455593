import axios from "axios";
import getToken from "../utils/getToken";

import { addNotification } from "./notifications";

import { ROOT_URL, FETCH_PRACTITIONERS } from "../constants";

import { Dispatch, ActionStatus, Practitioner } from "../types";

export type FetchPractitionersAction = {
  type: typeof FETCH_PRACTITIONERS;
  status: ActionStatus;
  payload?: { practitioners: Array<Practitioner> };
};

export type FetchPractitionersOptions = {
  silent?: boolean;
};

export const fetchPractitioners = (options?: FetchPractitionersOptions) => (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };
  const isSilentFetch = options?.silent;

  if (!isSilentFetch) {
    dispatch({
      type: FETCH_PRACTITIONERS,
      status: ActionStatus.loading
    });
  }

  return axios
    .get(`${ROOT_URL}/practitioners`, config)
    .then((response) => {
      // validate and clean localStorage "appointmentFilters_practitionerIds"
      const appointmentFiltersPractitionerIds = localStorage.getItem(
        "appointmentFilters_practitionerIds"
      );

      if (appointmentFiltersPractitionerIds) {
        const appointmentFiltersPractitionerIdsArray = appointmentFiltersPractitionerIds.split(",");
        const validPractitionerIds = response.data.practitioners
          .filter((practitioner: Practitioner) => {
            return (
              practitioner.practitionerActive &&
              appointmentFiltersPractitionerIdsArray.includes(practitioner.id.toString())
            );
          })
          .map((practitioner: Practitioner) => practitioner.id);

        localStorage.setItem("appointmentFilters_practitionerIds", validPractitionerIds.join(","));
      }

      return dispatch({
        type: FETCH_PRACTITIONERS,
        status: ActionStatus.success,
        payload: { practitioners: response.data.practitioners }
      });
    })
    .catch(() => {
      dispatch(
        addNotification({
          type: "error",
          title: "Failed to fetch practitioners",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: FETCH_PRACTITIONERS,
        status: ActionStatus.error
      });
    });
};
