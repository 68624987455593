import React, { useState } from "react";
import { connect } from "react-redux";

import { BackgroundImageNoPeople } from "../../ui/Icon";
import InviteCodeForm from "./InviteCodeForm";
import CreateSelfServeOrganizationForm from "./CreateSelfServeOrganizationForm";
import CheckEmailCard from "./CheckEmailCard";

import { ReduxStateType } from "../../../types";

import styles from "./index.module.scss";

type PropsType = {
  inviteCodeVerified?: boolean;
};

const SignUp = ({ inviteCodeVerified }: PropsType) => {
  const [newOrganizationId, setNewOrganizationId] = useState(0);
  const [inviteCode, setInviteCode] = useState("");
  const [email, setEmail] = useState("");
  return (
    <div className={styles.Page}>
      {!inviteCodeVerified && <InviteCodeForm setInviteCode={setInviteCode} />}
      {inviteCodeVerified && !newOrganizationId && (
        <CreateSelfServeOrganizationForm
          inviteCode={inviteCode}
          setEmail={setEmail}
          setNewOrganizationId={setNewOrganizationId}
        />
      )}
      {!!newOrganizationId && <CheckEmailCard email={email} />}

      <BackgroundImageNoPeople className={styles.Background} />
    </div>
  );
};

const mapStateToProps = ({ organizationDetails }: ReduxStateType) => {
  return {
    inviteCodeVerified: organizationDetails.inviteCodeVerified
  };
};

export default connect(mapStateToProps, null)(SignUp);
