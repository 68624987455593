import React from "react";
import { Document } from "../../../../../types";
import Button from "../../../../ui/Button";
import { DownloadCloud } from "../../../../ui/Icon";

type PropsType = {
  document: Document;
  downloadDocument: (documentId: number, filename: string) => Promise<void | null>;
  documentLoading: { [id: number]: boolean };
};
const DownloadCell = ({ document, downloadDocument, documentLoading }: PropsType) => {
  const { id, filename } = document;
  const downloading = documentLoading[id] ? documentLoading[id] : false;

  return (
    <Button
      id={`downloadDocument-${id}`}
      inline
      onClick={() => downloadDocument(id, filename)}
      disabled={downloading}
    >
      <DownloadCloud size={24} />
    </Button>
  );
};

export default DownloadCell;
