/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import cx from "classnames";

import { Circle, CircleCheck } from "../../../../../Icon";
import Text from "../../../../../Text";
import { ResponsiveHide } from "../../../../../Responsive";
import Heading from "../../../../../Heading";

import styles from "./index.module.scss";

type PropsType = {
  isSelected?: boolean;
  setSelectedTemplate: (name: string) => void;
};

const SoapNoteSelector = ({ isSelected, setSelectedTemplate }: PropsType) => {
  const placeHolderTexts = ["Subjective", "Objective", "Assessment", "Plan"];
  const getPlaceHolderComponent = (name: string) => {
    return (
      <React.Fragment key={name}>
        <Text size="XS" bold>
          {name}
        </Text>
        <hr />
        <ResponsiveHide hideOnMobile>
          <hr />
        </ResponsiveHide>
        <hr className={styles.WidthHalf} />
      </React.Fragment>
    );
  };
  return (
    <div
      className={cx(styles.TemplateCard, { [styles.SelectedTemplate]: isSelected })}
      onClick={() => setSelectedTemplate("notes")}
    >
      <div className={styles.TemplateHeader}>
        <span className={styles.IconColor}>
          {isSelected ? <CircleCheck size={24} /> : <Circle size={24} />}
        </span>
        <div>
          <Heading size="S" component="h5" bold className={styles.HeadingContent}>
            Soap Note
          </Heading>
          <Text>A general purpose SOAP note for a single issue patient encounter.</Text>
        </div>
      </div>
      <div className={styles.TemplatePreview}>
        {placeHolderTexts?.map((item) => getPlaceHolderComponent(item))}
      </div>
    </div>
  );
};

export default SoapNoteSelector;
