import React, { useState } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import moment from "moment";

import Button from "../../ui/Button";

import Drawer from "../../ui/Drawer";
import Heading from "../../ui/Heading";
import { QRCode } from "../../ui/Icon";
import ReportCard from "../../ui/ReportCard";
import Status from "../../ui/Status";
import Text from "../../ui/Text";

import {
  addNotification as addNotificationAction,
  openModal as openModalAction,
  OpenModal
} from "../../../actions";

import { ModalTypes, ConversationStatuses } from "../../../constants";

import {
  AppointmentConversation,
  AppointmentDetail,
  StatusComponentConfigMap,
  NewNotification
} from "../../../types";

import { pushReport } from "../../../lib";

import styles from "./index.module.scss";

type PropsType = {
  appointment: AppointmentDetail;
  conversations: AppointmentConversation[];
  openModal: OpenModal;
  addNotification: (notification: NewNotification) => void;
};

const conversationStatusConfigMap: StatusComponentConfigMap = {
  [ConversationStatuses.NOT_APPLICABLE]: "gray",
  [ConversationStatuses.NOT_STARTED]: "gray",
  [ConversationStatuses.IN_PROGRESS]: "blue",
  [ConversationStatuses.COMPLETE]: "green",
  [ConversationStatuses.NEEDS_HELP]: "red",
  [ConversationStatuses.FLAGGED]: "red",
  [ConversationStatuses.LOCKED_OUT]: "red",
  [ConversationStatuses.NO_CONTACT]: "red"
};

const ChatSummaries = ({ appointment, conversations, addNotification, openModal }: PropsType) => {
  const [syncLoading, setSyncLoading] = useState<{ [reportId: string]: boolean }>({});

  const syncReport = async (reportId: number) => {
    const handleError = (message?: string) => {
      addNotification({
        type: "error",
        title: message || `Failed to sync report`,
        subtitle: "Please try again",
        autoDismiss: true
      });
    };
    setSyncLoading((state) => ({ ...state, [reportId]: true }));
    try {
      const response = await pushReport(reportId);
      if (response.success) {
        // successfully sent report
        addNotification({
          type: "success",
          title: `Success`,
          subtitle: "Report synced",
          autoDismiss: true
        });
      } else if (response.success === false || response.error === true) {
        // report was not accepted
        handleError();
      } else if (response.error) {
        // there is a configuration error
        handleError(`Unable to send, no report configured.`);
      }
    } catch {
      handleError();
    }

    setSyncLoading((state) => ({ ...state, [reportId]: false }));
  };

  const chatFlowIds = conversations.map((conversation) => conversation.chatFlowId);

  return (
    <>
      <Heading
        size="M"
        component="h1"
        className={cx(styles.Heading, {
          [styles.BottomBorder]: conversations && conversations.length > 0
        })}
      >
        <div className={styles.HeadingChatSummaries}>
          Chat Summaries
          <Text size="S">Complete the task by sharing the QR code with your patient</Text>
        </div>
        <Button
          secondary
          size="S"
          id="chatDetails"
          onClick={() => {
            openModal(ModalTypes.VIEW_APPOINTMENT_CHAT_DETAILS, {
              appointmentId: Number(appointment.id),
              chatFlowIds
            });
          }}
        >
          <QRCode />
          <span className={styles.ViewDetailsText}>View Details</span>
        </Button>
      </Heading>
      {conversations && conversations.length > 0 && (
        <div className={styles.ConversationList}>
          {conversations.map((conversation, i) => {
            const isLastItem = i === conversations.length - 1;
            const conversationReport =
              appointment?.reports &&
              appointment?.reports.find((report) => report.conversationId === conversation.id);
            return (
              <div
                className={isLastItem ? "" : styles.BottomBorder}
                key={`conversation-${conversation.id}`}
              >
                <Drawer
                  initialOpen={false}
                  collapse
                  togglePosition="right"
                  toggleLine={false}
                  headerContent={
                    <div className={styles.ConversationListItem}>
                      <div>
                        <Text>{conversation.chatFlowTitle}</Text>
                        <Text size="S">
                          {`Sent on ${moment(conversation.createdAt).format("dddd MMM Do, h:mma")}`}
                        </Text>
                      </div>

                      <Status
                        className={styles.ConversationStatus}
                        value={conversation.status}
                        options={[
                          {
                            label: ConversationStatuses.NOT_APPLICABLE,
                            value: ConversationStatuses.NOT_APPLICABLE
                          },
                          {
                            label: ConversationStatuses.NOT_STARTED,
                            value: ConversationStatuses.NOT_STARTED
                          },
                          {
                            label: ConversationStatuses.NEEDS_HELP,
                            value: ConversationStatuses.NEEDS_HELP
                          },
                          {
                            label: ConversationStatuses.IN_PROGRESS,
                            value: ConversationStatuses.IN_PROGRESS
                          },
                          {
                            label: ConversationStatuses.COMPLETE,
                            value: ConversationStatuses.COMPLETE
                          },
                          {
                            label: ConversationStatuses.FLAGGED,
                            value: ConversationStatuses.FLAGGED
                          },
                          {
                            label: ConversationStatuses.NO_CONTACT,
                            value: ConversationStatuses.NO_CONTACT
                          }
                        ]}
                        configMap={conversationStatusConfigMap}
                        defaultColor="gray"
                      />
                    </div>
                  }
                >
                  {conversationReport && (
                    <ReportCard
                      report={conversationReport}
                      syncReport={syncReport}
                      syncLoading={syncLoading[conversationReport.id]}
                    />
                  )}
                </Drawer>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default connect(null, {
  addNotification: addNotificationAction,
  openModal: openModalAction
})(ChatSummaries);
