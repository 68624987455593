import axios from "axios";
import getToken from "../utils/getToken";

import { closeModal } from "./modals";
import {
  addNotification,
  invalidAutomationAssociationsNotification,
  InvalidAutomationAssociation
} from "./notifications";

import {
  ROOT_URL,
  UPDATE_INSTRUCTIONS,
  FETCH_INSTRUCTIONS,
  FETCH_INSTRUCTION_DETAILS,
  ADD_INSTRUCTION,
  DELETE_INSTRUCTION
} from "../constants";

import {
  Dispatch,
  ActionStatus,
  Instruction,
  InstructionWithReasons,
  InstructionDetailed,
  JsonContent,
  Option
} from "../types";

export type FetchInstructionsAction = {
  type: typeof FETCH_INSTRUCTIONS;
  status: ActionStatus;
  payload?: { instructions: Array<InstructionWithReasons>; contentTemplateOptions: Option[] };
};

export const fetchInstructions = () => (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    }
  };

  dispatch({
    type: FETCH_INSTRUCTIONS,
    status: ActionStatus.loading
  });

  return axios
    .get(`${ROOT_URL}/chats/checklists`, config)
    .then((response) => {
      return dispatch({
        type: FETCH_INSTRUCTIONS,
        status: ActionStatus.success,
        payload: {
          instructions: response.data.checklists,
          contentTemplateOptions: response.data.contentTemplateOptions
        }
      });
    })
    .catch(() => {
      dispatch(
        addNotification({
          type: "error",
          title: "Failed to fetch instructions",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: FETCH_INSTRUCTIONS,
        status: ActionStatus.error
      });
    });
};

export type FetchInstructionDetailsAction = {
  type: typeof FETCH_INSTRUCTION_DETAILS;
  status: ActionStatus;
  payload?: { checklist: InstructionDetailed };
};

export const fetchInstructionDetails = (instructionId: number) => (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json"
    }
  };

  dispatch({
    type: FETCH_INSTRUCTION_DETAILS,
    status: ActionStatus.loading
  });

  return axios
    .get(`${ROOT_URL}/chats/checklists/${instructionId}`, config)
    .then((response) => {
      return dispatch({
        type: FETCH_INSTRUCTION_DETAILS,
        status: ActionStatus.success,
        payload: response.data
      });
    })
    .catch(() => {
      dispatch(
        addNotification({
          type: "error",
          title: "Failed to fetch instructions",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: FETCH_INSTRUCTION_DETAILS,
        status: ActionStatus.error
      });
    });
};

export type UpdateInstructionsAction = {
  type: typeof UPDATE_INSTRUCTIONS;
  status: ActionStatus;
  payload?: {
    instructionDetail: InstructionDetailed | null;
  };
};

export type UpdateInstructionsData = {
  id: number;
  displayName: string;
  description: InstructionDetailed["description"];
  groupName: InstructionDetailed["groupName"];
  type: InstructionDetailed["type"] | null;
  filler: boolean;
  reasonIds: Array<number>;
  content: JsonContent;
};

type UpdateInstructionsResponse = {
  status: number;
  data: {
    success: boolean;
    checklist?: InstructionDetailed;
    invalidAssociations?: Array<InvalidAutomationAssociation>;
  };
};

export const updateInstructions =
  (data: UpdateInstructionsData, onSuccess?: () => void) => (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    const handleError = (payload?: {
      data: { invalidAssociations?: Array<InvalidAutomationAssociation> };
    }) => {
      dispatch({
        type: UPDATE_INSTRUCTIONS,
        status: ActionStatus.error
      });

      if (
        payload &&
        payload.data &&
        payload.data.invalidAssociations &&
        payload.data.invalidAssociations.length > 0
      ) {
        return dispatch(
          invalidAutomationAssociationsNotification(payload.data.invalidAssociations)
        );
      }

      dispatch(
        addNotification({
          type: "error",
          title: `Failed to update instructions: ${data.displayName}`,
          subtitle: "Please try again",
          autoDismiss: true
        })
      );

      return fetchInstructions()(dispatch).then(() => {
        dispatch({
          type: UPDATE_INSTRUCTIONS,
          status: ActionStatus.success
        });
      });
    };

    dispatch({
      type: UPDATE_INSTRUCTIONS,
      status: ActionStatus.loading
    });

    return axios
      .patch(`${ROOT_URL}/chats/checklists/${data.id}`, data, config)
      .then((payload: UpdateInstructionsResponse) => {
        if (!payload || !payload.data.success) {
          return handleError(payload);
        }
        dispatch(
          addNotification({
            type: "success",
            title: `Saved changes to instruction: ${data.displayName}`,
            autoDismiss: true
          })
        );

        if (onSuccess) {
          onSuccess();
        }

        fetchInstructions()(dispatch);

        return dispatch({
          type: UPDATE_INSTRUCTIONS,
          status: ActionStatus.success,
          payload: {
            instructionDetail: payload.data.checklist || null
          }
        });
      })
      .catch(() => {
        return handleError();
      });
  };

export type AddInstructionAction = {
  type: typeof ADD_INSTRUCTION;
  status?: ActionStatus;
  payload?: { data: Instruction };
};

export type AddInstructionData = {
  displayName: string;
  description: string;
  contentTemplate: string;
};

type AddInstructionResponse = {
  status: number;
  data: {
    success?: boolean;
  };
};

export const addInstruction = (data: AddInstructionData) => (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  const handleFailure = () => {
    dispatch(
      addNotification({
        type: "error",
        title: "Failed to create instruction",
        subtitle: "Please try again",
        autoDismiss: true
      })
    );

    dispatch({
      type: ADD_INSTRUCTION,
      status: ActionStatus.error
    });
  };

  dispatch({
    type: ADD_INSTRUCTION,
    status: ActionStatus.loading
  });

  return axios
    .post(`${ROOT_URL}/chats/checklists`, data, config)
    .then((payload: AddInstructionResponse) => {
      if (!payload || !payload.data.success) {
        handleFailure();
        return;
      }
      closeModal()(dispatch);
      dispatch(
        addNotification({
          type: "success",
          title: "Success",
          subtitle: "Instruction created",
          autoDismiss: true
        })
      );
      dispatch({
        type: ADD_INSTRUCTION,
        status: ActionStatus.success
      });

      fetchInstructions()(dispatch);
    })
    .catch(() => {
      return handleFailure();
    });
};

export type InstructionDeleteData = {
  id: number;
  onSuccess?: () => void;
};

export type DeleteInstructionAction = {
  type: typeof DELETE_INSTRUCTION;
  status?: ActionStatus;
};

type DeleteInstructionResponse = {
  status: number;
  data: {
    success?: boolean;
    invalidAssociations?: Array<InvalidAutomationAssociation>;
  };
};

export const deleteInstruction = (data: InstructionDeleteData) => (dispatch: Dispatch) => {
  const { id, onSuccess } = data;
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  const handleFailure = (payload?: {
    data: { invalidAssociations?: Array<InvalidAutomationAssociation> };
  }) => {
    dispatch({
      type: DELETE_INSTRUCTION,
      status: ActionStatus.error
    });

    if (
      payload &&
      payload.data &&
      payload.data.invalidAssociations &&
      payload.data.invalidAssociations.length > 0
    ) {
      return dispatch(invalidAutomationAssociationsNotification(payload.data.invalidAssociations));
    }

    return dispatch(
      addNotification({
        type: "error",
        title: "Failed to delete instruction",
        subtitle: "Please try again",
        autoDismiss: true
      })
    );
  };

  dispatch({
    type: DELETE_INSTRUCTION,
    status: ActionStatus.loading
  });

  return axios
    .delete(`${ROOT_URL}/chats/checklists/${id}`, config)
    .then((payload: DeleteInstructionResponse) => {
      if (!payload || !payload.data.success) {
        handleFailure(payload);
        return;
      }
      closeModal()(dispatch);

      if (onSuccess) {
        onSuccess();
      }

      dispatch(
        addNotification({
          type: "success",
          title: "Success",
          subtitle: "Instruction deleted",
          autoDismiss: true
        })
      );
      dispatch({
        type: DELETE_INSTRUCTION,
        status: ActionStatus.success
      });

      fetchInstructions()(dispatch);
    })
    .catch(() => {
      return handleFailure();
    });
};
