import React, { useEffect, useRef } from "react";
import cx from "classnames";

import { connect } from "react-redux";
import Button from "../../../../../../../ui/Button";
import Text from "../../../../../../../ui/Text";
import { Close } from "../../../../../../../ui/Icon";

import {
  openModal as openModalAction,
  OpenModal,
  closeModal as closeModalAction,
  deleteNote as deleteNoteAction
} from "../../../../../../../../actions";
import { Note, Permissions } from "../../../../../../../../types";
import { ModalTypes } from "../../../../../../../../constants";

import styles from "./index.module.scss";
import { PermissionsGuard } from "../../../../../../../../hooks/usePermissions";

type PropsType = {
  note: Note;
  isActive: boolean;
  customTabWrapperClass?: string;
  setActiveAppointmentNoteId: (apppointmentNoteId: number) => void;
  onTabSwitch?: () => void;
  openModal: OpenModal;
  closeModal: () => void;
  deleteNote: (noteId: number, onSuccess?: () => void) => void;
};

const NoteTab = ({
  note,
  isActive,
  customTabWrapperClass = "",
  setActiveAppointmentNoteId,
  onTabSwitch,
  openModal,
  closeModal,
  deleteNote
}: PropsType) => {
  const activeNoteTab = useRef<HTMLDivElement>(null);
  const switchTab = (note: Note) => {
    setActiveAppointmentNoteId(note.id);
  };

  useEffect(() => {
    // logic to scroll the current active note tab into view
    if (isActive && activeNoteTab?.current) {
      activeNoteTab.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest"
      });
    }
  }, [isActive]);

  return (
    <div
      className={cx(
        styles.TabWrapper,
        {
          [styles.TabWrapperActive]: isActive
        },
        customTabWrapperClass
      )}
      ref={activeNoteTab}
    >
      <button
        id={`tab-${note.id}`}
        type="button"
        className={cx(styles.Tab)}
        onClick={() => {
          switchTab(note);
          if (onTabSwitch) {
            onTabSwitch();
          }
        }}
      >
        <Text className={styles.TabTitle} size="S">
          {note.title}
        </Text>
      </button>
      <PermissionsGuard
        requiredPermissions={[Permissions.DELETE_NOTES_ORGANIZATION]}
        requiresVerifiedOrg={false}
      >
        <Button
          inline
          className={styles.DeleteNoteButtonIcon}
          onClick={() => {
            openModal(ModalTypes.CONFIRMATION, {
              title: "Are you sure you want to delete this note?",
              warningText: "You will not be able to restore the note once you delete it.",
              confirmButtonVariant: "danger",
              confirmButtonText: "Delete",
              onConfirmation: () => {
                deleteNote(note.id, () => {
                  closeModal();
                });
              }
            });
          }}
        >
          <Close size={16} />
        </Button>
      </PermissionsGuard>
    </div>
  );
};

export default connect(null, {
  deleteNote: deleteNoteAction,
  openModal: openModalAction,
  closeModal: closeModalAction
})(NoteTab);
