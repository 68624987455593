import React from "react";

import Button, { ButtonRow } from "../../../../Button";
import Text from "../../../../Text";
import Heading from "../../../../Heading";
import Image from "../../../../Image";

import { AnnouncementModalBaseProps } from "..";

type PropsType = AnnouncementModalBaseProps;

const NewScribeFeatures = ({ announcement, markAnnouncementComplete }: PropsType) => {
  return (
    <div key={`announcement-${announcement.name}`}>
      <Heading size="M" component="h3">
        Multi-templates is available now
      </Heading>
      <Image src="/img/announcements/multiTemplate.png" />
      <br />
      <Text size="M" bold>
        Multi-template makes your appointment more organized
      </Text>
      <br />
      <Text size="S">
        You can choose different templates offered by Mikata for your appointments. More organized
        and efficient!
      </Text>
      <br />
      <ButtonRow>
        <Button
          id={`completeAnnouncement-${announcement.name}`}
          onClick={() => markAnnouncementComplete && markAnnouncementComplete(announcement.name)}
        >
          Got it
        </Button>
      </ButtonRow>
    </div>
  );
};

export default NewScribeFeatures;
