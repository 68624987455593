import axios from "axios";
import { ROOT_URL } from "../constants";

const getJwt = () => {
  return localStorage.getItem("jwt");
};

export const fetchCampaignSummary = (id: number): Promise<{ data: string }> => {
  const config = {
    headers: { Authorization: `${getJwt()}`, "Content-Type": "application/json" }
  };

  return axios.get(`${ROOT_URL}/campaigns/${id}/summary`, config);
};
