import React, { useMemo } from "react";
import { useFormState } from "informed";

import {
  JsonInput,
  JsonContentInput,
  SelectInput,
  TextAreaInput,
  TextInput
} from "../../../../../../../../ui/Input";
import { DeprecatedLandingContent, LandingFormValues } from "../../BaseChatNodeForm";

import { isJson } from "../../../../../../../../../utils/validators";

import { ChatFlowsNodes, JsonContent } from "../../../../../../../../../types";
import { StartCardVariants } from "../../../../../../../../../constants";

export type LandingFormNode = ChatFlowsNodes & {
  payloadContent: DeprecatedLandingContent | null;
  payloadOptions: {
    contentJSON?: JsonContent;
  };
};

const jsonValidator = isJson("Please enter a valid JSON string");

type StartCardContentPropsType = {
  initialValues: LandingFormValues;
  viewOnly: boolean;
};

const StartCardContent = ({ initialValues, viewOnly }: StartCardContentPropsType) => {
  const { values } = useFormState();

  const variant = useMemo(() => {
    return values.variant || initialValues.variant;
  }, [values.variant]);

  return (
    <div>
      <SelectInput
        label="Card Type"
        fieldName="variant"
        options={[
          { label: "Card", value: "card" },
          { label: "Task", value: "task" },
          { label: "Priority Task", value: "priorityTask" }
        ]}
      />

      {variant === StartCardVariants.CARD && (
        <>
          <JsonInput
            label="Deprecated Content"
            fieldName="payloadContent"
            infoStatus="warning"
            info={
              <span>
                Please prefer to make use of the content builder below to add custom landing element
                content.
              </span>
            }
            validate={jsonValidator}
          />
          <JsonContentInput fieldName="contentJson" disabled={viewOnly} />
        </>
      )}
      {(variant === StartCardVariants.TASK || variant === StartCardVariants.PRIORITY_TASK) && (
        <>
          <TextInput label="Title" fieldName="taskContent.title" disabled={viewOnly} />
          <TextAreaInput
            label="Description"
            fieldName="taskContent.description"
            disabled={viewOnly}
          />
        </>
      )}
    </div>
  );
};

export default StartCardContent;
