import axios from "axios";

import getToken from "../utils/getToken";

import { addNotification } from "./notifications";

import { FETCH_PATIENTS, ROOT_URL } from "../constants";
import { ActionStatus, Patient, Dispatch } from "../types";

export type FetchPatients = {
  personalHealthNumber: string;
};

export type PatientFetchData = {
  patient?: Patient;
};

export type FetchPatientsAction = {
  type: typeof FETCH_PATIENTS;
  status?: ActionStatus;
  payload?: {
    searchParams: FetchPatients;
    patients: Patient[];
  };
};

export const fetchPatients = (body: FetchPatients) => async (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  dispatch({
    type: FETCH_PATIENTS,
    status: ActionStatus.loading,
    payload: {
      searchParams: body,
      patients: []
    }
  });

  // Try and load patients
  try {
    const response = await axios.post(`${ROOT_URL}/requests/bookings/patients`, body, config);

    return dispatch({
      type: FETCH_PATIENTS,
      status: ActionStatus.success,
      payload: { ...response.data, searchParams: body }
    });
  } catch (error) {
    dispatch(
      addNotification({
        type: "error",
        title: "Error fetching patients",
        subtitle: "Please try again",
        autoDismiss: true
      })
    );
    return dispatch({
      type: FETCH_PATIENTS,
      status: ActionStatus.error
    });
  }
};
