import React, { useContext, useCallback } from "react";

import Heading from "../../../../../../ui/Heading";
import { FloatModal } from "../../../../../../ui/Modal";
import Button from "../../../../../../ui/Button";

import Text from "../../../../../../ui/Text";

import AudioSettings from "../../AudioSettings";

import styles from "./index.module.scss";
import { EncounterSocketContext } from "../../../../../../providers/EncounterSocketProvider";

type PropsType = {
  isOpen: boolean;
  setAudioSettingsOpen: (isOpen: boolean) => void;
};

const AudioSettingsModal = ({ isOpen, setAudioSettingsOpen }: PropsType) => {
  const { pauseSession } = useContext(EncounterSocketContext);

  const close = useCallback(() => {
    pauseSession(false);
    setAudioSettingsOpen(false);
  }, [pauseSession]);

  return (
    <FloatModal isOpen={isOpen} onClose={close}>
      <Heading size="L" className={styles.AddNewUser}>
        Audio Settings
      </Heading>
      <br />

      <Text>
        Please select the microphone and language Mika will use to stream audio. The Mika session
        will resume when the changes are applied.
      </Text>
      <br />
      <AudioSettings />

      <hr className={styles.Divider} />
      <div className={styles.ButtonGroup}>
        <Button inline onClick={close}>
          Cancel
        </Button>
        <Button id="saveAudioSettingsButton" type="button" onClick={close}>
          Save
        </Button>
      </div>
    </FloatModal>
  );
};

export default AudioSettingsModal;
