import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { UserContext } from "./providers/UserProvider";
import { OrganizationContext } from "./providers/OrganizationProvider";

import Login from "./Unauthenticated/Login";
import SignUp from "./Unauthenticated/SignUp";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import PasswordReset from "./Unauthenticated/PasswordReset";
import BookingLandingPage from "./Unauthenticated/BookingLandingPage";
import TelusLaunchLandingPage from "./Unauthenticated/Launch/TelusLaunchLandingPage";
import OAuthCallback from "./mikata_admin/OAuthCallback";

import { UserTypeConstants } from "../constants";

export default function App() {
  const user = useContext(UserContext);
  const organization = useContext(OrganizationContext);
  const isLoggedIn = user && user.userId;
  const isAdmin = user.userType === UserTypeConstants.MIKATA_ADMIN;

  return (
    <Routes>
      <Route path="/login" Component={Login} />
      <Route path="/login/*" element={<Navigate to="/login" replace />} />
      <Route path="/scribe/signup" Component={SignUp} />
      <Route path="/scribe/*" Component={SignUp} />
      <Route path="/users/reset/:token" Component={PasswordReset} />
      <Route path="/book-appointment/*" Component={BookingLandingPage} />
      <Route path="/launch/telus/*" Component={TelusLaunchLandingPage} />
      {isLoggedIn && <Route path="/oauth2/callback" Component={OAuthCallback} />}

      {(isAdmin || organization?.displayName) && (
        <Route path="/*" Component={AuthenticatedRoutes} />
      )}

      {!isLoggedIn && <Route path="/*" element={<Navigate to="/login" replace />} />}
    </Routes>
  );
}
