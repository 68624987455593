/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditorState, SelectionState, Modifier } from "draft-js";

export const findWithRegex = (
  regex: RegExp,
  contentBlock: any,
  callback: { (start: any, end: any): void; (arg0: any, arg1: any): void }
) => {
  const text = contentBlock.getText();
  let matchArr;
  let start;
  let end;
  // eslint-disable-next-line no-cond-assign
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    end = start + matchArr[0].length;
    callback(start, end);
  }
};

export const replaceContentByString = (
  phraseToSearch: string,
  updatedPhrase: string,
  editorState: EditorState
) => {
  // regex below finds the whole word match without case sensitivity
  const regex = new RegExp(`\\b${phraseToSearch}\\b`, "gi");
  const selectionsToReplace: SelectionState[] = [];
  const blockMap = editorState.getCurrentContent().getBlockMap();

  blockMap.forEach((contentBlock) =>
    findWithRegex(regex, contentBlock, (start: any, end: any) => {
      const blockKey = contentBlock?.getKey();
      if (blockKey) {
        const blockSelection = SelectionState.createEmpty(blockKey).merge({
          anchorOffset: start,
          focusOffset: end
        });

        selectionsToReplace.push(blockSelection);
      }
    })
  );

  let contentState = editorState.getCurrentContent();
  // need to reverse selectionsToReplace to preserve the indexing order
  // if we start from initial to last then for upcoming matches in same block the indexes become different
  selectionsToReplace.reverse().forEach((selectionState) => {
    contentState = Modifier.replaceText(contentState, selectionState, updatedPhrase);
  });
  return EditorState.push(editorState, contentState, "insert-characters");
};
