import React, { useState, useRef, useEffect } from "react";
import cx from "classnames";
import { v4 as uuid } from "uuid";

import Button from "../../../../../../../ui/Button";
import { Plus } from "../../../../../../../ui/Icon";
import Heading from "../../../../../../../ui/Heading";

import { getValidCardTypes } from "../helpers/getValidCardTypes";
import { getCardTypeIcon } from "../helpers/getCardTypeIcon";
import {
  ReportTemplateCard,
  ReportTemplateCardType,
  ReportTemplateCardTypeLabel
} from "../../../../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  card?: ReportTemplateCard;
  onAddCard: (card: ReportTemplateCard) => void;
  direction: string;
  reportTemplateId?: number | null;
};

const order = (card: ReportTemplateCard, direction: string) => {
  switch (direction) {
    case "above":
      return card ? card.order - 1 : 0;

    case "below":
      return card ? card.order + 1 : 0;

    default:
      return 1;
  }
};

const parentId = (card: ReportTemplateCard, direction: string) => {
  if (direction === "within") return card ? (card.id as number) : null;

  return card ? card.parentId : null;
};

const AddReportTemplateCardButton = ({
  card,
  onAddCard,
  direction,
  reportTemplateId
}: PropsType) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const validCardTypes = getValidCardTypes(card?.depth || 0);
  if (!validCardTypes) return null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (e: any) => {
    if (ref && ref.current && ref.current.contains(e.target)) {
      // inside modal click
      return;
    }
    // outside modal click
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      // add when mounted
      document.addEventListener("mousedown", handleClick);
      return () => {
        document.removeEventListener("mousedown", handleClick);
      };
    }

    document.removeEventListener("mousedown", handleClick);

    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [open]);

  const handleAddCard = (cardType: ReportTemplateCardType) => {
    const newCard = {
      id: uuid(),
      reportTemplateId: reportTemplateId || card?.reportTemplateId || 0,
      order: order(card as ReportTemplateCard, direction),
      content: "",
      contentType: "string",
      condition: null,
      thenContent: "",
      elseContent: "",
      displayName: "",
      parentId: parentId(card as ReportTemplateCard, direction),
      children: [],
      depth: card ? card.depth : 0,
      categoryTagId: null
    };
    onAddCard({ ...newCard, type: cardType });
    toggleOpen();
  };

  return (
    <div className={styles.Wrapper}>
      <Button id="addReportElement" inline onClick={toggleOpen}>
        <Plus size={16} />
        {direction === "within" ? "Add Element To Section" : "Add Element"}
      </Button>
      {open && (
        <div className={cx(styles.Modal)} ref={ref}>
          <Heading size="S" bold>
            Add Element
          </Heading>
          <div className={styles.NodeList}>
            {validCardTypes.map((cardType) => {
              const cardTypeLabel = ReportTemplateCardTypeLabel[cardType];
              return (
                <Button
                  id={`${cardType}`}
                  key={cardType}
                  inline
                  secondary
                  className={styles.NodeButton}
                  onClick={() => handleAddCard(cardType)}
                >
                  {getCardTypeIcon(cardType, { size: 24 })}
                  {cardTypeLabel}
                </Button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddReportTemplateCardButton;
