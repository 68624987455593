import React from "react";
import { connect } from "react-redux";

import Heading from "../../../ui/Heading";
import { FloatModal } from "../../../ui/Modal";
import { Form } from "../../../ui/Input";
import FormContent from "./FormContent";

import {
  addMessageTemplate,
  addMessageTemplateOptions,
  closeModal as closeModalAction
} from "../../../../actions";

import getTopicLabel from "../../../../utils/getTopicLabel";

import { MessageTopics, EMRTypeConstants, IntegrationTypes } from "../../../../constants";

import { formValidator } from "./formValidator";

import { MessageTemplateAddData, MessageTemplateMedium, ReduxStateType } from "../../../../types";
import { isSmsFriendly } from "../../../../utils/validators";

type AddMessageTemplateFormData = MessageTemplateAddData;

type PropsType = {
  inTouchpointForm?: boolean;
  loading?: boolean;
  emrCategory: string;
  emrIntegrationType: string;
  closeModal: () => void;
  addMessage: (data: MessageTemplateAddData, options?: addMessageTemplateOptions) => void;
  onSuccess?: (templateId: number) => void;
};

const initialFormState = {
  displayName: "",
  payloadContent: "",
  topic: "",
  medium: MessageTemplateMedium.sms,
  subject: ""
};

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

const AddMessageTemplate = ({
  inTouchpointForm = false,
  loading,
  closeModal,
  addMessage,
  onSuccess,
  emrCategory,
  emrIntegrationType
}: PropsType) => {
  const payloadContentWarning = isSmsFriendly();

  const onSave = (formData: AddMessageTemplateFormData) => {
    if (!formData.topic && inTouchpointForm) {
      formData.topic = MessageTopics.SCHEDULE_BASED_MESSAGE;
    }
    addMessage(formData, { onSuccess });
  };

  const topicOptions = Object.values(MessageTopics)
    .map((topic) => {
      // Campaign messages have no location, practitioner, or reason context
      // thus cannot be part of a custom automation.
      if (topic === MessageTopics.CAMPAIGN_RESPONSE_UNCLEAR) return null;
      if (
        (topic === MessageTopics.POST_VISIT_LINK || topic === MessageTopics.USER_SURVEY_OPT_OUT) &&
        emrCategory !== EMRTypeConstants.INTELERAD.name &&
        emrIntegrationType !== IntegrationTypes.PUSH
      )
        return null;

      return {
        label: getTopicLabel(topic),
        value: topic
      };
    })
    .filter(notEmpty);

  const formDisabled = loading;

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L">Add new message</Heading>
      <Form
        onSubmit={(formState) => onSave(formState.values as AddMessageTemplateFormData)}
        initialValues={initialFormState}
        validateFields={(values) => formValidator(values as AddMessageTemplateFormData)}
      >
        <FormContent
          formDisabled={formDisabled}
          inTouchpointForm={inTouchpointForm}
          topicOptions={topicOptions}
          closeModal={closeModal}
          payloadContentWarning={payloadContentWarning}
        />
      </Form>
    </FloatModal>
  );
};
const mapStateToProps = ({ messageTemplates, organizationData }: ReduxStateType) => {
  return {
    emrCategory: organizationData.organizationData.emrType.category || "",
    emrIntegrationType: organizationData.organizationData.emrType.integrationType || "",
    loading: messageTemplates.messageTemplateAddLoading
  };
};

export default connect(mapStateToProps, {
  addMessage: addMessageTemplate,
  closeModal: closeModalAction
})(AddMessageTemplate);
