import React from "react";

import Heading from "../../../ui/Heading";
import AppointmentJourneysTable from "../../../common/tables/AppointmentJourneysTable";

import styles from "./index.module.scss";

const AppointmentJourneysPage = () => {
  return (
    <div>
      <Heading className={styles.Heading} size="XL">
        Journeys
      </Heading>
      <AppointmentJourneysTable />
    </div>
  );
};

export default AppointmentJourneysPage;
