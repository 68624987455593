import React, { useEffect, useContext, useMemo, useRef } from "react";
import cx from "classnames";

import Card from "../../../../../../ui/Card";
import Text from "../../../../../../ui/Text";
import Heading from "../../../../../../ui/Heading";
import { User, Provider, ExternalLink } from "../../../../../../ui/Icon";

import { EncounterSocketContext } from "../../../../../../providers/EncounterSocketProvider";

import { AudioTranscript, InsightType, RecordingSessionStatus } from "../../../../../../../types";

import styles from "./index.module.scss";

enum SpeakerRole {
  PROVIDER = "provider",
  PATIENT = "patient",
  UNKNOWN = "unknown",
  PENDING = "pending"
}

type TranscriptGroup = {
  groupId: string;
  speakerRole: SpeakerRole;
  transcripts: { id: number; text: string }[];
};

type TranscriptGroupsPropsType = {
  transcriptGroup: TranscriptGroup;
};

type RoleIdentityMapType = { [key: string]: string };

const speakerRoleToLabelMap = {
  [SpeakerRole.PROVIDER]: "",
  [SpeakerRole.PATIENT]: "",
  [SpeakerRole.UNKNOWN]: "",
  [SpeakerRole.PENDING]: "Unknown speaker"
};

const possibleDoctorSynonyms = ["dr", "doctor", "provider"];
const possiblePatientSynonyms = ["pt", "patient"];

const TranscriptGroup = ({ transcriptGroup }: TranscriptGroupsPropsType) => {
  const { speakerRole } = transcriptGroup;
  const speakerLabel: string = speakerRoleToLabelMap[speakerRole] || "";

  return (
    <div
      className={cx(styles.TranscriptGroup, {
        [styles.TranscriptGroupProvider]: speakerRole === SpeakerRole.PROVIDER,
        [styles.TranscriptGroupPatient]: speakerRole === SpeakerRole.PATIENT,
        [styles.TranscriptGroupUnknown]: speakerRole === SpeakerRole.UNKNOWN,
        [styles.TranscriptGroupPending]: speakerRole === SpeakerRole.PENDING
      })}
    >
      {speakerLabel && (
        <Text
          size="S"
          bold
          className={cx(styles.TranscriptSpeakerRole, {
            [styles.TranscriptSpeakerRoleProvider]: speakerRole === SpeakerRole.PROVIDER,
            [styles.TranscriptSpeakerRolePatient]: speakerRole === SpeakerRole.PATIENT,
            [styles.TranscriptSpeakerRoleUnknown]: speakerRole === SpeakerRole.UNKNOWN,
            [styles.TranscriptSpeakerRolePending]: speakerRole === SpeakerRole.PENDING
          })}
        >
          {speakerLabel}
        </Text>
      )}
      {speakerRole === SpeakerRole.PROVIDER && (
        <div className={cx(styles.SpeakerRoleIconProvider)}>
          <Provider size={16} />
        </div>
      )}
      {speakerRole === SpeakerRole.PATIENT && (
        <div className={cx(styles.SpeakerRoleIconPatient)}>
          <User size={16} />
        </div>
      )}
      {transcriptGroup.transcripts.map((transcript) => {
        return (
          <Text
            key={`transcriptItem-${transcript.id}`}
            size="M"
            className={cx(styles.TranscriptText, {
              [styles.TranscriptTextProvider]: speakerRole === SpeakerRole.PROVIDER,
              [styles.TranscriptTextPatient]: speakerRole === SpeakerRole.PATIENT,
              [styles.TranscriptTextUnknown]: speakerRole === SpeakerRole.UNKNOWN,
              [styles.TranscriptTextPending]: speakerRole === SpeakerRole.PENDING
            })}
          >
            {transcript.text}
          </Text>
        );
      })}
    </div>
  );
};

type CleanTranscriptsPropsType = {
  audioTranscripts: AudioTranscript[];
  roleIdentityMap: RoleIdentityMapType[];
};

// Split transcript into lines, and style them based on speaker
const TranscriptsGroupedBySpeaker = ({
  audioTranscripts,
  roleIdentityMap
}: CleanTranscriptsPropsType) => {
  if (!audioTranscripts || !audioTranscripts.length) return null;

  let cleanTranscriptArray = audioTranscripts
    .map((transcript, index) => {
      // If we have a roleIdentifier insight, use that to convert, e.g. Guest-1 to Patient
      const matchingRoleIdentityMap = roleIdentityMap.find(
        (roleIdentityItem) =>
          roleIdentityItem?.transcriptionSessionId === transcript.transcriptionSessionId
      );
      let text = `${transcript.speakerId}: ${transcript.transcriptContent}` || "";
      let speakerRole: SpeakerRole = SpeakerRole.UNKNOWN;
      if (matchingRoleIdentityMap) {
        Object.keys(matchingRoleIdentityMap).forEach((key) => {
          if (text.toLowerCase().startsWith(key.toLowerCase())) {
            text = text.replace(key, matchingRoleIdentityMap[key]);
          }
        });
      }
      // Try to deal with randomness from the model output
      if (possibleDoctorSynonyms.some((prefix) => text.toLowerCase().startsWith(prefix))) {
        speakerRole = SpeakerRole.PROVIDER;
      }
      if (possiblePatientSynonyms.some((prefix) => text.toLowerCase().startsWith(prefix))) {
        speakerRole = SpeakerRole.PATIENT;
      }
      text = text.replace(/^[a-z]+[\s-]{0,1}[\d]{0,2}:[\s]{0,1}/gim, "");

      return {
        text,
        speakerRole,
        index
      };
    })
    .filter((lineObject) => Boolean(lineObject?.text));

  if (!cleanTranscriptArray || cleanTranscriptArray?.length === 0) return null;

  // Problem with transcription creating duplicate transcripts, IHA-7024
  // The identical transcripts might not be right next to each other, so we need to look through the whole array for duplicates
  // This is not an ideal solution, the better solution would be to look at the privOffset and transcriptionSessionId
  const duplicateTranscriptions: { transcriptText?: number[] } = {};

  cleanTranscriptArray.forEach((transcriptItem) => {
    const matchingTranscript = cleanTranscriptArray.find(
      (t) => t.text === transcriptItem.text && t.index !== transcriptItem.index
    );

    const key = transcriptItem.text as keyof typeof duplicateTranscriptions;
    if (matchingTranscript) {
      if (!duplicateTranscriptions[key]) {
        duplicateTranscriptions[key] = [];
      }
      duplicateTranscriptions[key]?.push(transcriptItem.index);
      duplicateTranscriptions[key]?.push(matchingTranscript.index);
    }
  });

  cleanTranscriptArray.forEach((transcriptItem) => {
    const key = transcriptItem.text as keyof typeof duplicateTranscriptions;
    if (duplicateTranscriptions[key]) {
      // Remove duplicates. The first one is most likely to be when the speaker actually said it
      const highestIndex = Math.min(...duplicateTranscriptions[key]);
      cleanTranscriptArray = cleanTranscriptArray.filter(
        (t) =>
          !(
            duplicateTranscriptions[key] &&
            duplicateTranscriptions[key].includes(t.index) &&
            t.index !== highestIndex
          )
      );
    }
  });

  const groupedTranscripts = cleanTranscriptArray.reduce<TranscriptGroup[]>(
    (grouped, transcriptItem) => {
      const lastGroup = grouped?.[grouped.length - 1];
      const groupId = lastGroup ? lastGroup.transcripts.map((t) => t.id).join("-") : "";
      if (!lastGroup || (lastGroup && lastGroup.speakerRole !== transcriptItem.speakerRole)) {
        grouped.push({
          groupId,
          speakerRole: transcriptItem.speakerRole,
          transcripts: [{ id: transcriptItem.index, text: transcriptItem.text }]
        });
      } else if (lastGroup && lastGroup.speakerRole === transcriptItem.speakerRole) {
        grouped?.[grouped.length - 1].transcripts.push({
          id: transcriptItem.index,
          text: transcriptItem.text
        });
      }

      return grouped;
    },
    []
  );

  return (
    <>
      {groupedTranscripts.map((transcriptGroup) => (
        <TranscriptGroup
          key={`transcript-${transcriptGroup.groupId}`}
          transcriptGroup={transcriptGroup}
        />
      ))}
    </>
  );
};

export const AudioTranscripts = () => {
  const { recording, audioTranscripts, insights } = useContext(EncounterSocketContext);
  const scrollableContainerRef = useRef<null | HTMLDivElement>(null);

  // Insight that tells whether Guest-1 or Guest-2 is the patient or provider
  const roleIdentityMap: RoleIdentityMapType[] = useMemo(() => {
    const roleIdentityMap: RoleIdentityMapType[] = [];
    const roleIdentifierInsights = insights.filter(
      (insight) => insight.type === InsightType.ROLE_IDENTIFIER
    );
    roleIdentifierInsights.forEach((roleIdentifierInsight) => {
      let mapItem;
      try {
        mapItem = JSON.parse(roleIdentifierInsight.output.text);
        mapItem.transcriptionSessionId = roleIdentifierInsight.output.transcriptionSessionId;
      } catch (e) {
        console.error(e);
      }
      if (mapItem) {
        roleIdentityMap.push(mapItem);
      }
    });
    return roleIdentityMap;
  }, [insights]);

  // Scroll to the bottom of the card when a new transcript is added
  // But only if the user is already at the bottom of the card
  useEffect(() => {
    const scrollEl = scrollableContainerRef?.current;

    if (scrollEl) {
      const isNearBottom =
        scrollEl.scrollHeight - scrollEl.clientHeight - scrollEl.scrollTop <= 300;
      if (isNearBottom) {
        scrollEl.scrollTo({
          top: scrollEl.scrollHeight,
          behavior: "smooth"
        });
      }
    }
  }, [scrollableContainerRef.current, audioTranscripts]);

  if (!audioTranscripts) return null;

  return (
    <div className={styles.TranscriptsWrapper}>
      <div className={styles.ScrollableContainer} ref={scrollableContainerRef}>
        <Heading size="META">
          Transcript generated using speech-to-text software and may contain errors.
        </Heading>
        {audioTranscripts && audioTranscripts.length > 0 && (
          <TranscriptsGroupedBySpeaker
            key={audioTranscripts[0]?.id}
            audioTranscripts={audioTranscripts}
            roleIdentityMap={roleIdentityMap}
          />
        )}

        {!audioTranscripts ||
          (audioTranscripts.length === 0 && (
            <Text size="S">
              The transcripts should appear within 30 seconds of activation. If you can&apos;t see
              it,{" "}
              <a
                href="https://mikata-health.gitbook.io/mikata-product-guide-for-customers/mika-scribe/issues-and-ideas/audio-troubleshooting"
                target="_blank"
                rel="noreferrer"
                className={styles.TroubleshootingText}
              >
                see our troubleshooting guide <ExternalLink size={16} />
              </a>
              .
            </Text>
          ))}

        {recording?.status === RecordingSessionStatus.RECORDING && (
          <div className={styles.Processing}>
            <div className={styles.ProcessingDot1} />
            <div className={styles.ProcessingDot2} />
            <div className={styles.ProcessingDot3} />
          </div>
        )}
      </div>
    </div>
  );
};
