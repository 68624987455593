import React, { Dispatch, SetStateAction, useMemo } from "react";

import { TextAreaInput } from "../../../../../../../../ui/Input";
import Text from "../../../../../../../../ui/Text";
import { ChatFlowsNodes } from "../../../../../../../../../types";

import BaseChatNodeForm, {
  BaseFormValues,
  InstructionsFromReasonsFormValues,
  FormValues
} from "../../BaseChatNodeForm";

type PropsType = {
  chatId: string | undefined;
  node: ChatFlowsNodes;
  viewOnly: boolean;
  setSelectedNodeId: Dispatch<SetStateAction<number | null>>;
};

export type InstructionsFromReasonsFormNode = ChatFlowsNodes & {
  payloadContent: string | null;
};

const onSaveCustomTransformer = (formValues: FormValues) => {
  const values = formValues as BaseFormValues & InstructionsFromReasonsFormValues;
  return {
    payloadContent: values.payloadContent || ""
  };
};

const InstructionsFromReasonsCard = ({ chatId, node, viewOnly, setSelectedNodeId }: PropsType) => {
  const initialFormValues: InstructionsFromReasonsFormValues = useMemo(() => {
    return {
      payloadContent: node.payloadContent || ""
    };
  }, [node.id]);

  return (
    <BaseChatNodeForm
      chatId={chatId}
      initialValues={initialFormValues}
      onSaveCustomTransformer={onSaveCustomTransformer}
      node={node}
      key={node.id}
      viewOnly={viewOnly}
      setSelectedNodeId={setSelectedNodeId}
    >
      <TextAreaInput
        id="chatNodePayloadContent"
        size="S"
        fieldName="payloadContent"
        label="Mikata Message"
        disabled={viewOnly}
        info="This message will appear directly above the list of appointment reasons."
      />
      <Text component="p">
        The instructions from reasons element will display the instructions based on the reasons
        selected by the patient.
      </Text>
    </BaseChatNodeForm>
  );
};

export default InstructionsFromReasonsCard;
