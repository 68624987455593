/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ANALYTICS_BOOKING_MESSAGES,
  ANALYTICS_MULTI_BOOKING_MESSAGES,
  ANALYTICS_CANCEL_MESSAGES,
  ANALYTICS_MULTI_CANCEL_MESSAGES,
  ANALYTICS_PATIENT_CONFIRMATION_MESSAGES,
  ANALYTICS_PATIENT_RESCHEDULE_MESSAGES,
  ANALYTICS_REQUESTS_REASONS_COUNT,
  ANALYTICS_REQUESTS_REASONS_COUNT_FILTER,
  ANALYTICS_REQUESTS_COUNT,
  ANALYTICS_REQUISITIONS_COUNT,
  ANALYTICS_AVERAGE_PROCESSING_TIME,
  ANALYTICS_SET_PROCESSING_START_TIME,
  ANALYTICS_PROCESSING_TIME_BY_REASON,
  ANALYTICS_QUEUE_TIME,
  ANALYTICS_CHAT_COMPLETION_TIME,
  ANALYTICS_CHAT_DROPOFF
} from "../constants";

import { FetchAnalyticsAction, SetRequestsReasonsCountFilterAction } from "../actions/analytics";

export type AnalyticsAction = FetchAnalyticsAction | SetRequestsReasonsCountFilterAction;

// Can't get the types of the analytics redux state to work
// Something to do with fetchAnalytics being able to return different types
// type AnalyticsActionPayload =
//   | string
//   | number
//   | { x: string; y: number }[]
//   | { [key: string]: number };

export type AnalyticsReduxState = {
  bookedMessages: any;
  multiBookedMessages: any;
  cancelMessages: any;
  multiCancelMessages: any;
  patientConfirmationMessages: any;
  patientRescheduleMessages: any;
  requestsReasonsCount: any;
  requestsCount: any;
  requisitionsCount: any;
  averageProcessingTime: any;
  processingTimeByReason: any;
  queueTime: any;
  chatCompletionTime: any;
  chatDropoff: any;
  // filters/toggles
  requestsReasonsCountFilter: any;
  processingStartTime: any;
  // loading states
  bookedMessagesLoading: boolean;
  cancelMessagesLoading: boolean;
  patientConfirmationMessagesLoading: boolean;
  patientRescheduleMessagesLoading: boolean;
  requestsReasonsCountLoading: boolean;
  requestsCountLoading: boolean;
  requisitionsCountLoading: boolean;
  averageProcessingTimeLoading: boolean;
  processingTimeByReasonLoading: boolean;
  queueTimeLoading: boolean;
  chatCompletionTimeLoading: boolean;
  chatDropoffLoading: boolean;
};

const initialAnalytics: AnalyticsReduxState = {
  bookedMessages: 0,
  multiBookedMessages: 0,
  cancelMessages: 0,
  multiCancelMessages: 0,
  patientConfirmationMessages: 0,
  patientRescheduleMessages: 0,
  requestsReasonsCount: [],
  requestsReasonsCountFilter: "all",
  requestsCount: 0,
  requisitionsCount: 0,
  processingStartTime: "submittedAt",
  averageProcessingTime: 0,
  queueTime: 0,
  processingTimeByReason: [],
  chatCompletionTime: 0,
  chatDropoff: {
    firstName: 0,
    lastName: 0,
    birthday: 0,
    phoneNumber: 0,
    reasons: 0,
    submitted: 0,
    processed: 0
  },
  bookedMessagesLoading: true,
  cancelMessagesLoading: true,
  patientConfirmationMessagesLoading: true,
  patientRescheduleMessagesLoading: true,
  requestsReasonsCountLoading: true,
  requestsCountLoading: true,
  requisitionsCountLoading: true,
  averageProcessingTimeLoading: true,
  processingTimeByReasonLoading: true,
  queueTimeLoading: true,
  chatCompletionTimeLoading: true,
  chatDropoffLoading: true
};

export const analyticsReducer = (
  state = initialAnalytics,
  action: AnalyticsAction
): AnalyticsReduxState => {
  const { type, payload, status } = action;

  switch (type) {
    case ANALYTICS_BOOKING_MESSAGES: {
      return {
        ...state,
        bookedMessages: status === "success" && payload ? payload.data : state.bookedMessages,
        bookedMessagesLoading: status === "loading"
      };
    }
    case ANALYTICS_MULTI_BOOKING_MESSAGES: {
      return {
        ...state,
        multiBookedMessages:
          status === "success" && payload ? payload.data : state.multiBookedMessages,
        bookedMessagesLoading: status === "loading"
      };
    }
    case ANALYTICS_CANCEL_MESSAGES: {
      return {
        ...state,
        cancelMessages: status === "success" && payload ? payload.data : state.cancelMessages,
        cancelMessagesLoading: status === "loading"
      };
    }
    case ANALYTICS_MULTI_CANCEL_MESSAGES: {
      return {
        ...state,
        multiCancelMessages:
          status === "success" && payload ? payload.data : state.multiCancelMessages,
        cancelMessagesLoading: status === "loading"
      };
    }
    case ANALYTICS_PATIENT_CONFIRMATION_MESSAGES: {
      return {
        ...state,
        patientConfirmationMessages:
          status === "success" && payload ? payload.data : state.patientConfirmationMessages,
        patientConfirmationMessagesLoading: status === "loading"
      };
    }
    case ANALYTICS_PATIENT_RESCHEDULE_MESSAGES: {
      return {
        ...state,
        patientRescheduleMessages:
          status === "success" && payload ? payload.data : state.patientRescheduleMessages,
        patientRescheduleMessagesLoading: status === "loading"
      };
    }
    case ANALYTICS_REQUESTS_REASONS_COUNT: {
      return {
        ...state,
        requestsReasonsCount:
          status === "success" && payload ? payload.data : state.requestsReasonsCount,
        requestsReasonsCountLoading: status === "loading"
      };
    }
    case ANALYTICS_REQUESTS_REASONS_COUNT_FILTER: {
      return {
        ...state,
        requestsReasonsCountFilter: payload ? payload.data : false
      };
    }
    case ANALYTICS_SET_PROCESSING_START_TIME: {
      return {
        ...state,
        processingStartTime: payload ? payload.data : false
      };
    }
    case ANALYTICS_REQUESTS_COUNT: {
      return {
        ...state,
        requestsCount: status === "success" && payload ? payload.data : state.requestsCount,
        requestsCountLoading: status === "loading"
      };
    }
    case ANALYTICS_REQUISITIONS_COUNT: {
      return {
        ...state,
        requisitionsCount: status === "success" && payload ? payload.data : state.requisitionsCount,
        requisitionsCountLoading: status === "loading"
      };
    }
    case ANALYTICS_AVERAGE_PROCESSING_TIME: {
      return {
        ...state,
        averageProcessingTime:
          status === "success" && payload ? payload.data : state.averageProcessingTime,
        averageProcessingTimeLoading: status === "loading"
      };
    }
    case ANALYTICS_QUEUE_TIME: {
      return {
        ...state,
        queueTime: status === "success" && payload ? payload.data : state.queueTime,
        queueTimeLoading: status === "loading"
      };
    }
    case ANALYTICS_PROCESSING_TIME_BY_REASON: {
      return {
        ...state,
        processingTimeByReason:
          status === "success" && payload ? payload.data : state.processingTimeByReason,
        processingTimeByReasonLoading: status === "loading"
      };
    }
    case ANALYTICS_CHAT_COMPLETION_TIME: {
      return {
        ...state,
        chatCompletionTime:
          status === "success" && payload ? payload.data : state.chatCompletionTime,
        chatCompletionTimeLoading: status === "loading"
      };
    }
    case ANALYTICS_CHAT_DROPOFF: {
      return {
        ...state,
        chatDropoff: status === "success" && payload ? payload.data : state.chatDropoff,
        chatDropoffLoading: status === "loading"
      };
    }
    default:
      return state;
  }
};
