import { isNestedList, isInInfoModal } from "./nodeContext";

import { ChatNodeTypes } from "../../../../../types";

const typeChildMap = {
  [ChatNodeTypes.content]: [
    ChatNodeTypes.heading,
    ChatNodeTypes.text,
    ChatNodeTypes.list,
    ChatNodeTypes.important,
    ChatNodeTypes.duration,
    ChatNodeTypes.link,
    ChatNodeTypes.infoModal
  ],
  [ChatNodeTypes.text]: null,
  [ChatNodeTypes.heading]: null,
  [ChatNodeTypes.important]: null,
  [ChatNodeTypes.duration]: null,
  [ChatNodeTypes.link]: null,
  [ChatNodeTypes.infoModal]: [
    ChatNodeTypes.heading,
    ChatNodeTypes.text,
    ChatNodeTypes.list,
    ChatNodeTypes.link,
    ChatNodeTypes.duration
  ],
  [ChatNodeTypes.list]: [ChatNodeTypes.listItem],
  [ChatNodeTypes.listItem]: [
    ChatNodeTypes.heading,
    ChatNodeTypes.text,
    ChatNodeTypes.list,
    ChatNodeTypes.link,
    ChatNodeTypes.infoModal
  ]
};

export const getValidChildTypes = (typePath: Array<ChatNodeTypes>): Array<ChatNodeTypes> | null => {
  const parentNodeType = typePath[typePath.length - 1];
  const nestedList = isNestedList(typePath);
  const inInfoModal = isInInfoModal(typePath);

  const allValidChildren = typeChildMap[parentNodeType];
  const filteredValidChildren = allValidChildren
    ? allValidChildren.filter((childType) => {
        if (
          nestedList &&
          (childType === ChatNodeTypes.list || childType === ChatNodeTypes.infoModal)
        ) {
          return false;
        }

        if (inInfoModal && childType === ChatNodeTypes.infoModal) {
          return false;
        }

        return true;
      })
    : null;

  return filteredValidChildren;
};
