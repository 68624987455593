import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import cx from "classnames";

import Heading from "../../../../../../ui/Heading";
import Dropdown from "../../../../../../ui/Dropdown";
import Button from "../../../../../../ui/Button";
import Loader from "../../../../../../ui/Loader";
import ChatMap from "./ChatMap";
import { ChatViewMode } from "./ChatMap/types";
import Text from "../../../../../../ui/Text";
import Scrollable from "../../../../../../ui/Scrollable";
import ChatNodeCard from "./ChatNodeCard";

import {
  openModal as OpenModalAction,
  OpenModal,
  getChatFlowDetails as getChatFlowDetailsAction,
  clearChatFlowDetails as clearChatFlowDetailsAction
} from "../../../../../../../actions";

import styles from "./index.module.scss";

import {
  ReduxStateType,
  ChatFlowsNodes,
  ChatEdge,
  MessageTemplateConnection
} from "../../../../../../../types";

import { AdminModalTypes } from "../../../../../../../constants";
import { useQueryString } from "../../../../../../../utils/queryStringHelpers";
import { ChevronRight, ChevronLeft } from "../../../../../../ui/Icon";

type PropsType = {
  published: boolean;
  chatFlowTags: Array<string>;
  openModal: OpenModal;
  loading: boolean;
  chatFlowsNodes?: Array<ChatFlowsNodes>;
  chatFlowEdges?: Array<ChatEdge | MessageTemplateConnection>;
};

const ChatBuilder = ({
  chatFlowTags,
  published,
  openModal,
  loading,
  chatFlowsNodes,
  chatFlowEdges
}: PropsType) => {
  const [viewMode, setViewMode] = useState<ChatViewMode>(ChatViewMode.MAP_LARGE);
  const hasGraphSystemTag = chatFlowTags.includes("useGraphSystem");
  const { organizationId: orgIdString } = useParams<{
    organizationId: string;
  }>();
  const organizationId = Number(orgIdString);
  const [selectedNodeId, setSelectedNodeId] = useState<number | null>(null);
  const [showNodeForm, setShowNodeForm] = useState<boolean>(!!selectedNodeId);
  const filteredRows = chatFlowsNodes || [];
  const selectedNode = filteredRows.find((node) => node.id === selectedNodeId);
  const selectedCardType = selectedNode?.cardType ? selectedNode.cardType : null;
  const { parsed } = useQueryString();
  const viewOnly = published;
  const { chatId } = parsed;

  useEffect(() => {
    if (selectedNodeId && !showNodeForm) {
      setShowNodeForm(true);
    }
  }, [selectedNodeId]);

  return (
    <div className={styles.Wrapper}>
      <div className={styles.GraphWrapper}>
        <div className={styles.HeaderWrapper}>
          <Heading size="M" component="h2" className={cx(styles.Heading, styles.BottomBorder)}>
            Chat Elements
            <Dropdown
              id="chatMapDropdown"
              className={styles.DropdownStatus}
              onChange={(selected) => {
                setViewMode(selected as ChatViewMode);
              }}
              value={viewMode}
              options={[
                {
                  label: "Overview Map",
                  value: ChatViewMode.MAP_SMALL
                },
                {
                  label: "Details Map",
                  value: ChatViewMode.MAP_LARGE
                },
                {
                  label: "List",
                  value: ChatViewMode.LIST
                }
              ]}
              configMap={{
                [ChatViewMode.MAP_SMALL]: "purple",
                [ChatViewMode.MAP_LARGE]: "purple",
                [ChatViewMode.LIST]: "blue"
              }}
            />
          </Heading>
          {hasGraphSystemTag && !published && (
            <Button
              id="addChatElement"
              className={styles.AddNodeBtn}
              inline
              onClick={() => {
                openModal(AdminModalTypes.ADD_CHAT_NODE, { organizationId, chatFlowTags });
              }}
              disabled={loading}
            >
              Add Element +
            </Button>
          )}
        </div>
        {loading && (
          <div className={styles.LoadingOverlay}>
            <Loader small screen />
          </div>
        )}
        <div className={styles.ScrollableWrapper}>
          {[ChatViewMode.MAP_SMALL, ChatViewMode.MAP_LARGE].includes(viewMode) &&
            hasGraphSystemTag && (
              <ChatMap
                viewMode={viewMode}
                nodes={chatFlowsNodes}
                edges={chatFlowEdges as MessageTemplateConnection[]}
                onNodeSelect={setSelectedNodeId}
                selectedNodeId={selectedNodeId}
              />
            )}
          {viewMode === ChatViewMode.LIST && (
            <div className={styles.InfoGroup}>
              <Scrollable>
                {filteredRows &&
                  filteredRows.map((chatNode) => {
                    let chatNodeName;
                    if (chatNode.displayName) {
                      chatNodeName = `${chatNode.id} - ${chatNode.displayName}`;
                    } else if (!chatNode.displayName && chatNode.inputName) {
                      chatNodeName = `${chatNode.id} - ${chatNode.inputName}`;
                    } else {
                      chatNodeName = chatNode.id;
                    }

                    return (
                      <div className={styles.InfoGroup} key={chatNode.id}>
                        <Button
                          id={`id-${chatNode.id.toString()}`}
                          key={chatNode.id}
                          className={styles.InfoValueText}
                          inline
                          type="button"
                          onClick={async () => {
                            setSelectedNodeId(chatNode.id);
                          }}
                        >
                          <Text className={styles.InfoValueRow}>{chatNodeName}</Text>
                        </Button>
                      </div>
                    );
                  })}
              </Scrollable>
            </div>
          )}
        </div>
      </div>
      {selectedNode && (
        <div className={cx(styles.NodeWrapper, { [styles.NodeWrapperOpen]: showNodeForm })}>
          <Button
            inline
            className={styles.NodeToggleButton}
            onClick={() => setShowNodeForm((showNodeForm) => !showNodeForm)}
          >
            {showNodeForm ? <ChevronRight size={32} /> : <ChevronLeft size={32} />}
          </Button>
          <Scrollable>
            <ChatNodeCard
              cardType={selectedCardType}
              chatId={chatId}
              node={selectedNode}
              viewOnly={viewOnly}
              loading={loading}
              setSelectedNodeId={setSelectedNodeId}
            />
          </Scrollable>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ chats }: ReduxStateType) => {
  return {
    chatFlowTags: chats.chatDetails.tags,
    published: !!chats.chatDetails.published,
    loading: chats.chatDetails.loading,
    chatFlowsNodes: chats.chatDetails.nodes,
    chatFlowEdges: chats.chatDetails.edges
  };
};

export default connect(mapStateToProps, {
  openModal: OpenModalAction,
  getChatFlowDetails: getChatFlowDetailsAction,
  clearChatFlowDetails: clearChatFlowDetailsAction
})(ChatBuilder);
