import React, { useContext } from "react";
import cx from "classnames";
import { connect } from "react-redux";

import { Menu } from "../../../../../ui/Icon";
import { StopClickPropagation } from "../../../../../ui/TableGrid";

import MenuList, { ListItem } from "./MenuList";

import { OrganizationContext } from "../../../../../providers/OrganizationProvider";

import { usePermissions } from "../../../../../../hooks/usePermissions";

import getPatientFullName from "../../../../../../utils/getPatientFullName";

import {
  deleteUnintegratedAppointment as deleteUnintegratedAppointmentAction,
  openModal as openModalAction,
  OpenModal
} from "../../../../../../actions";

import { ModalTypes } from "../../../../../../constants";

import {
  Permissions,
  OrganizationEmrFeatures,
  Appointment,
  ReduxStateType
} from "../../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  appointment: Appointment;
  openModal: OpenModal;
  deleteUnintegratedAppointment: (appointmentId: number) => void;
  classNames?: string;
};

const DetailsCell = ({
  appointment,
  openModal,
  deleteUnintegratedAppointment,
  classNames
}: PropsType) => {
  const organization = useContext(OrganizationContext);
  const emrFeatures = organization?.emrFeatures || [];
  const organizationHasCapability =
    emrFeatures.includes(OrganizationEmrFeatures.UNINTEGRATED_APPOINTMENTS) &&
    emrFeatures.includes(OrganizationEmrFeatures.UNINTEGRATED_PATIENTS);
  const userHasPermission = usePermissions([Permissions.DELETE_UNINTEGRATED_APPOINTMENT], false);

  // TODO: userHasPermission && organizationHasCapability  checks above can be done once for the whole table. REFACTOR
  const appointmentIsUnintegrated = appointment && !appointment.emrAppointmentId;
  const canDeleteUnintegratedAppointment =
    userHasPermission && organizationHasCapability && appointmentIsUnintegrated;

  const showMenu = canDeleteUnintegratedAppointment;

  const menuList: ListItem[] = [];

  if (canDeleteUnintegratedAppointment) {
    menuList.push({
      label: "Delete",
      onClick: () =>
        openModal(ModalTypes.CONFIRMATION, {
          title: "Delete appointment",
          content: `You are about to permanently delete the "${getPatientFullName(
            appointment.patientFirstName,
            appointment.patientLastName
          )}” appointment. Is this what you want to do?`,
          confirmButtonText: "Delete",
          confirmButtonVariant: "danger",
          onConfirmation: () => {
            deleteUnintegratedAppointment(appointment.id);
          }
        })
    });
  }

  return (
    <div className={cx(classNames)}>
      <div className={cx(styles.DetailsCell)}>
        {showMenu && (
          <StopClickPropagation>
            <MenuList icon={<Menu size={24} />} list={menuList} />
          </StopClickPropagation>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ appointments }: ReduxStateType) => {
  return {
    updatesLoading: appointments.updatesLoading
  };
};

export default connect(mapStateToProps, {
  deleteUnintegratedAppointment: deleteUnintegratedAppointmentAction,
  openModal: openModalAction
})(DetailsCell);
