import React, { useState, createRef, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import cx from "classnames";

import Text from "../../../../../ui/Text";
import { StaffSettingsPageTabs } from "../../../../../../constants";
import { ChevronDown, ChevronUp } from "../../../../../ui/Icon";

import styles from "./index.module.scss";

const { SCRIBE_TEMPLATES_ALL, SCRIBE_TEMPLATES, SCRIBE_TEMPLATES_FAVOURITES } =
  StaffSettingsPageTabs;

const ScribeTemplatesSettingDropdown = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdown = createRef<HTMLDivElement>();
  const location = useLocation();

  const handleClickOutside = (event: MouseEvent | null) => {
    if (
      dropdown.current &&
      event &&
      event.target instanceof HTMLElement &&
      !dropdown.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleClickOutside);

    // returned function will be called on component unmount
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [dropdown]);

  return (
    <div className={styles.Container}>
      <button
        id="dataDropdown"
        type="button"
        className={cx(styles.DataDropdown, {
          [styles.DataDropdownActive]: location.pathname.startsWith(SCRIBE_TEMPLATES.path)
        })}
        onClick={handleButtonClick}
      >
        <span>Scribe templates</span>
        <span className={styles.Chevron}>
          {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
        </span>
      </button>

      {isOpen && (
        <nav className={styles.Dropdown} ref={dropdown}>
          <NavLink
            key={SCRIBE_TEMPLATES_ALL.label}
            to={SCRIBE_TEMPLATES_ALL.path}
            className={({ isActive }) => (isActive ? styles.NavLinkActive : styles.NavLink)}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <Text className={styles.LinkText} size="S" component="span">
              {SCRIBE_TEMPLATES_ALL.label}
            </Text>
          </NavLink>
          <NavLink
            key={SCRIBE_TEMPLATES_FAVOURITES.label}
            to={SCRIBE_TEMPLATES_FAVOURITES.path}
            className={({ isActive }) => (isActive ? styles.NavLinkActive : styles.NavLink)}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <Text className={styles.LinkText} size="S" component="span">
              {SCRIBE_TEMPLATES_FAVOURITES.label}
            </Text>
          </NavLink>
        </nav>
      )}
    </div>
  );
};

export default ScribeTemplatesSettingDropdown;
