import React, { Dispatch, SetStateAction } from "react";
import cx from "classnames";

import Text from "../../../../../../../ui/Text";
import Heading from "../../../../../../../ui/Heading";
import { ChevronDown, Rules } from "../../../../../../../ui/Icon";

import { getCardTypeIcon } from "../helpers/getCardTypeIcon";

import {
  ReportTemplateCard,
  ReportTemplateCardType,
  ReportTemplateCardTypeLabel,
  Tag
} from "../../../../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  availableSubReportTags: Array<Tag>;
  children: React.ReactNode;
  card: ReportTemplateCard;
  hasUnsavedChanges: boolean;
  isOpen?: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFocusRef: (ref: any) => void;
};

const CollapsibleReportTemplateCard = ({
  availableSubReportTags,
  card,
  hasUnsavedChanges,
  isOpen,
  children,
  setIsOpen,
  setFocusRef
}: PropsType): React.ReactElement => {
  let headerText = card.displayName || card.thenContent || card.elseContent || card.content || "";
  if (card.type === ReportTemplateCardType.SUB_REPORT_SECTION) {
    const subReportTag = card.categoryTagId
      ? availableSubReportTags.find((tag) => tag.id === card.categoryTagId)
      : null;
    const subReportTagName = subReportTag ? subReportTag.name : "";
    headerText = `${ReportTemplateCardTypeLabel.subReportSection} - ${subReportTagName}`;
  }
  const isSectionOrHeader =
    card.type === ReportTemplateCardType.SECTION ||
    card.type === ReportTemplateCardType.SUB_REPORT_SECTION ||
    card.type === ReportTemplateCardType.HEADER;
  const textSize = isSectionOrHeader ? "M" : "S";

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const hasCondition = card.condition !== null;
  const isSection = card.type === ReportTemplateCardType.SECTION;

  return (
    <div
      id={`card-${card.id}`}
      className={cx(styles.Card, {
        [styles.CardSecondary]: isSection && !isOpen
      })}
    >
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button
        id="reportTemplateCard"
        type="button"
        className={cx(styles.ToggleButton, { [styles.ToggleButtonOpen]: isOpen })}
        onClick={toggleOpen}
        ref={setFocusRef}
      >
        {isOpen && (
          <div className={styles.ToggleButtonChevron}>
            <ChevronDown size={16} />
          </div>
        )}
      </button>

      {!isOpen && (
        <div className={cx(styles.Summary)}>
          <div className={styles.SummaryInfo}>
            <div className={styles.SummaryInfoIcon}>{getCardTypeIcon(card.type, { size: 24 })}</div>
            <div className={styles.SummaryInfoGroup}>
              <Heading
                className={cx(styles.SummaryInfoText, {
                  [styles.SummaryInfoTextHeader]: isSectionOrHeader
                })}
                size={textSize}
              >
                {headerText || "-"}
              </Heading>
              {hasUnsavedChanges && (
                <Heading className={cx(styles.SummaryInfoText)} size="META">
                  Unsaved Changes
                </Heading>
              )}
            </div>
          </div>

          <div className={styles.SummaryConditionalIcon}>{hasCondition && <Rules size={16} />}</div>
          <Text className={cx(styles.SummaryOrder)}>Order: {card.order}</Text>
        </div>
      )}

      <div className={cx(styles.Content, { [styles.ContentOpen]: isOpen })}>{children}</div>
    </div>
  );
};

export default CollapsibleReportTemplateCard;
