import React from "react";
import { TextInput, SelectInput } from "../../../../../ui/Input";
import { Option, InputPropsType } from "../../../../../../types";
import { isRequired } from "../../../../../../utils/validators";

import { IntegrationCategories } from "../../../../../../constants";

export type Options = Array<Option>;

type PropsType = InputPropsType & {
  reportTemplateOptions: Options;
  letterTypeOptions: Options;
  integrationCategory?: string;
};

const reportTemplateValidator = isRequired("Please select report template");
const titleValidator = isRequired("Please enter title");

const PushNote = ({
  reportTemplateOptions,
  letterTypeOptions,
  integrationCategory
}: PropsType): JSX.Element => {
  return (
    <>
      <SelectInput
        fieldName="reportTemplateId"
        label="Report Template"
        options={reportTemplateOptions}
        placeholder="Choose Report Template"
        validate={reportTemplateValidator}
      />
      <TextInput fieldName="title" label="title" validate={titleValidator} />
      {integrationCategory === IntegrationCategories.ACCURO && (
        <SelectInput
          fieldName="letterTypeId"
          label="Letter Type"
          options={letterTypeOptions}
          placeholder="Choose Letter Type"
        />
      )}
    </>
  );
};

export default PushNote;
