import React, { useEffect } from "react";

import { LanguageOptions } from "../../../../../../constants";

import { SelectInput, Form } from "../../../../../ui/Input";

import useAudioSettings, {
  updateProviderMicPreference,
  updateInputLanguage
} from "../../../../../../hooks/useAudioSettings";

type Props = {
  availableMicsInterval?: boolean;
};

const AudioSettings = ({ availableMicsInterval = false }: Props) => {
  const { inputLanguage, initialMicPreferenceSelectValue, micSelectOptions, getAvailableMics } =
    useAudioSettings();
  const micSelectOnChange = (formValues: { [fieldName: string]: string }) => {
    if (!formValues.micSelect) return;
    updateProviderMicPreference(formValues.micSelect);
  };
  const inputLanguageOnChange = (formValues: { [fieldName: string]: string }) => {
    if (!formValues.inputLanguageSelect) return;
    if (formValues.inputLanguageSelect === inputLanguage) return;
    updateInputLanguage(formValues.inputLanguageSelect);
  };

  useEffect(() => {
    let getMicInterval: NodeJS.Timeout;
    if (availableMicsInterval) {
      getMicInterval = setInterval(getAvailableMics, 1000);
    }

    return () => {
      if (getMicInterval) clearInterval(getMicInterval);
    };
  }, [availableMicsInterval]);

  return (
    <>
      <Form initialValues={{ micSelect: initialMicPreferenceSelectValue }}>
        <SelectInput
          customOnChange={(e) => micSelectOnChange(e)}
          options={micSelectOptions}
          fieldName="micSelect"
          label="Microphone"
        />
      </Form>
      <Form initialValues={{ inputLanguageSelect: inputLanguage }}>
        <SelectInput
          customOnChange={(e) => inputLanguageOnChange(e)}
          options={LanguageOptions}
          fieldName="inputLanguageSelect"
          label="Spoken Language"
        />
      </Form>
    </>
  );
};

export default AudioSettings;
