import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import useAppAnalytics, {
  AppMetric,
  AppMetricType,
  QueueMetricOptions
} from "../../hooks/useAppAnalytics";
import useInterval from "../../hooks/useInterval";

import { UserContext } from "./UserProvider";

type PropsType = {
  children: React.ReactNode;
};

type AnalyticsContextType = {
  queueMetric: (appMetric: AppMetric, options?: QueueMetricOptions) => void;
} | null;

const initialAnalyticsContext: AnalyticsContextType = null;

export const AnalyticsContext = React.createContext<AnalyticsContextType>(initialAnalyticsContext);
const HEARTBEAT_INTERVAL_MS = 20000; // 20s
const PROCESS_METRICS_INTERVAL_MS = 60000; // 60s

const AnalyticsProvider = ({ children }: PropsType) => {
  const { organizationId, userId, userType } = useContext(UserContext);
  const location = useLocation();

  const { queueMetric, processMetricsQueue } = useAppAnalytics({
    organizationId,
    userId,
    userType
  });

  // Post heartbeat metrics
  useInterval(
    () => {
      queueMetric({
        type: AppMetricType.APPLICATION_HEARTBEAT,
        heartbeatMS: HEARTBEAT_INTERVAL_MS,
        locationPath: location.pathname,
        locationSearch: location.search
      });
    },
    [queueMetric],
    HEARTBEAT_INTERVAL_MS
  );

  // Periodically process metrics queue
  useInterval(processMetricsQueue, [processMetricsQueue], PROCESS_METRICS_INTERVAL_MS);

  // Process metrics queue on un-mount
  useEffect(() => {
    return () => processMetricsQueue();
  }, [processMetricsQueue]);

  const value = {
    queueMetric
  };

  return (
    <>
      <AnalyticsContext.Provider value={value as AnalyticsContextType}>
        {children}
      </AnalyticsContext.Provider>
    </>
  );
};

export default AnalyticsProvider;
