import { isRequired, isMultipleOf } from "../../../../../utils/validators";

import {
  AutomationStatus,
  AutomationActionRangeTime,
  AutomationActionRangeTimeType,
  AutomationActionRangeTimeTriggerUnit,
  AutomationActionRangeType,
  AutomationSettingAction
} from "../../../../../types";

export type JourneyGeneralFormState = {
  displayName: string;
  description: string;
  status: AutomationStatus;
  settings: {
    locationId?: string | null;
    hideAddress?: boolean;
    hidePhoneNumber?: boolean;
    hideDate?: boolean;
    hideTime?: boolean;
    showPatients?: boolean;
    actions?: {
      reschedule?: {
        active: boolean;
        rangeStart: AutomationActionRangeTime;
        rangeEnd: AutomationActionRangeTime;
      };
      confirm?: {
        active: boolean;
        rangeStart: AutomationActionRangeTime;
        rangeEnd: AutomationActionRangeTime;
      };
      checkin?: {
        active: boolean;
        rangeStart: AutomationActionRangeTime;
        rangeEnd: AutomationActionRangeTime;
        chatFlowId: number | null;
      };
    };
  } | null;
};

export type JourneyTriggersFormState = {
  locations: Array<string>;
  practitioners: Array<string>;
  reasons: Array<string>;
};

export type JourneyResponseMessagesFormState = {
  excludedTopics: {
    [topic: string]: boolean;
  };
  messageTemplates: {
    [topic: string]: {
      messageTemplateId?: number;
    };
  };
};

const getRangeCompareValue = (rangeTime: AutomationActionRangeTime) => {
  switch (rangeTime.type) {
    case AutomationActionRangeTimeType.AFTER_APPOINTMENT:
    case AutomationActionRangeTimeType.UPON_APPOINTMENT_START:
      return 0;
    case AutomationActionRangeTimeType.BEFORE_APPOINTMENT:
      switch (rangeTime.unit) {
        case AutomationActionRangeTimeTriggerUnit.DAYS:
          return 24 * 60 * (rangeTime.value as number);
        case AutomationActionRangeTimeTriggerUnit.HOURS:
          return 60 * (rangeTime.value as number);
        case AutomationActionRangeTimeTriggerUnit.MINUTES:
          return rangeTime.value as number;
        default:
          return undefined;
      }

    default:
      return undefined;
  }
};

const actionSettingValueValidator = (
  rangeTime?: AutomationActionRangeTime,
  rangeTimeCompare?: AutomationActionRangeTime
) => {
  if (!rangeTime) return undefined;

  if (rangeTimeCompare) {
    const rangeTimeValue1 = getRangeCompareValue(rangeTime);
    const rangeTimeValue2 = getRangeCompareValue(rangeTimeCompare);

    if (rangeTimeValue1 && rangeTimeValue2 && rangeTimeValue1 <= rangeTimeValue2) {
      return "Please use a start time before the end time.";
    }
  }

  switch (rangeTime.type) {
    case AutomationActionRangeTimeType.BEFORE_APPOINTMENT:
    case AutomationActionRangeTimeType.AFTER_APPOINTMENT:
      switch (rangeTime.unit) {
        case AutomationActionRangeTimeTriggerUnit.DAYS:
          if ((rangeTime.value as number) < 1 || (rangeTime.value as number) > 366) {
            return "Schedule value for Days must be between 1-366";
          }
          return undefined;
        case AutomationActionRangeTimeTriggerUnit.HOURS:
          if ((rangeTime.value as number) < 1 || (rangeTime.value as number) > 24) {
            return "Schedule value for Hours must be between 1-24";
          }
          return undefined;
        case AutomationActionRangeTimeTriggerUnit.MINUTES:
          if ((rangeTime.value as number) < 10 || (rangeTime.value as number) > 120) {
            return "Schedule value for Minutes must be between 10-120";
          }
          return isMultipleOf(5, "Value must be a multiple of 5")(rangeTime.value) || undefined;
        default:
          return undefined;
      }
    default:
      return undefined;
  }
};

const getActionValue = (values: JourneyGeneralFormState, rangeType: string, actionType: string) => {
  switch (actionType) {
    case AutomationSettingAction.CHECK_IN:
      switch (rangeType) {
        case AutomationActionRangeType.RANGE_START:
          return values.settings?.actions?.checkin?.active
            ? actionSettingValueValidator(
                values.settings?.actions?.checkin?.rangeStart,
                values.settings?.actions?.checkin?.rangeEnd
              )
            : undefined;
        case AutomationActionRangeType.RANGE_END:
          return values.settings?.actions?.checkin?.active
            ? actionSettingValueValidator(values.settings?.actions?.checkin?.rangeEnd)
            : undefined;
        default:
          return undefined;
      }
    case AutomationSettingAction.CONFIRM:
      switch (rangeType) {
        case AutomationActionRangeType.RANGE_START:
          return values.settings?.actions?.confirm?.active
            ? actionSettingValueValidator(
                values.settings?.actions?.confirm?.rangeStart,
                values.settings?.actions?.confirm?.rangeEnd
              )
            : undefined;
        case AutomationActionRangeType.RANGE_END:
          return values.settings?.actions?.confirm?.active
            ? actionSettingValueValidator(values.settings?.actions?.confirm?.rangeEnd)
            : undefined;
        default:
          return undefined;
      }
    case AutomationSettingAction.RESCHEDULE:
      switch (rangeType) {
        case AutomationActionRangeType.RANGE_START:
          return values.settings?.actions?.reschedule?.active
            ? actionSettingValueValidator(
                values.settings?.actions?.reschedule?.rangeStart,
                values.settings?.actions?.reschedule?.rangeEnd
              )
            : undefined;
        case AutomationActionRangeType.RANGE_END:
          return values.settings?.actions?.reschedule?.active
            ? actionSettingValueValidator(values.settings?.actions?.reschedule?.rangeEnd)
            : undefined;
        default:
          return undefined;
      }
    default:
      return undefined;
  }
};

export const displayNameValidator = isRequired("Please enter a name");
export const statusValidator = isRequired("Status field required");
export const unitValidator = isRequired("Unit required");

export const journeyGeneralFormValidator =
  (isDefaultAutomation: boolean) => (values: JourneyGeneralFormState) => {
    return {
      displayName: displayNameValidator(values.displayName),
      description: undefined,
      status: statusValidator(values.status),
      settings: {
        actions: {
          checkin: {
            active: undefined,
            rangeStart: {
              type: undefined,
              value: getActionValue(
                values,
                AutomationActionRangeType.RANGE_START,
                AutomationSettingAction.CHECK_IN
              ),
              unit:
                values.settings?.actions?.checkin?.active &&
                values.settings?.actions?.checkin?.rangeStart.type === "beforeAppointment"
                  ? unitValidator(values.settings?.actions?.checkin?.rangeStart.unit)
                  : undefined
            },
            rangeEnd: {
              type: undefined,
              value: getActionValue(
                values,
                AutomationActionRangeType.RANGE_END,
                AutomationSettingAction.CHECK_IN
              ),
              unit:
                values.settings?.actions?.checkin?.active &&
                values.settings?.actions?.checkin?.rangeStart.type !== "uponAppointmentStart"
                  ? unitValidator(values.settings?.actions?.checkin?.rangeEnd.unit)
                  : undefined
            },
            chatFlowId: values.settings?.actions?.checkin?.active
              ? isRequired("Chat template required")(
                  values.settings?.actions?.checkin?.chatFlowId?.toString()
                )
              : undefined
          },
          confirm: {
            active: undefined,
            rangeStart: {
              type: undefined,
              value: getActionValue(
                values,
                AutomationActionRangeType.RANGE_START,
                AutomationSettingAction.CONFIRM
              ),
              unit:
                values.settings?.actions?.confirm?.active &&
                values.settings?.actions?.confirm?.rangeStart.type === "beforeAppointment"
                  ? unitValidator(values.settings?.actions?.confirm?.rangeStart.unit)
                  : undefined
            },
            rangeEnd: {
              type: undefined,
              value: getActionValue(
                values,
                AutomationActionRangeType.RANGE_END,
                AutomationSettingAction.CONFIRM
              ),
              unit:
                values.settings?.actions?.confirm?.active &&
                values.settings?.actions?.confirm?.rangeEnd.type === "beforeAppointment"
                  ? unitValidator(values.settings?.actions?.confirm?.rangeEnd.unit)
                  : undefined
            }
          },
          reschedule: {
            active: undefined,
            rangeStart: {
              type: undefined,
              value: getActionValue(
                values,
                AutomationActionRangeType.RANGE_START,
                AutomationSettingAction.RESCHEDULE
              ),
              unit:
                values.settings?.actions?.reschedule?.active &&
                values.settings?.actions?.reschedule?.rangeStart.type === "beforeAppointment"
                  ? unitValidator(values.settings?.actions?.reschedule?.rangeStart.unit)
                  : undefined
            },
            rangeEnd: {
              type: undefined,
              value: getActionValue(
                values,
                AutomationActionRangeType.RANGE_END,
                AutomationSettingAction.RESCHEDULE
              ),
              unit:
                values.settings?.actions?.reschedule?.active &&
                values.settings?.actions?.reschedule?.rangeEnd.type === "beforeAppointment"
                  ? unitValidator(values.settings?.actions?.reschedule?.rangeEnd.unit)
                  : undefined
            }
          }
        }
      }
    };
  };

export const journeyTriggersformValidator =
  (isDefaultAutomation: boolean, status: string) => (values: JourneyTriggersFormState) => {
    const invalidTriggers =
      !isDefaultAutomation &&
      values.locations?.length === 0 &&
      values.practitioners?.length === 0 &&
      values.reasons?.length === 0;
    const triggerGroupError =
      status === "published" && !isDefaultAutomation && invalidTriggers
        ? "Select at least one location, practitioner, or reason"
        : undefined;

    return {
      locations: triggerGroupError,
      practitioners: triggerGroupError,
      reasons: triggerGroupError
    };
  };
