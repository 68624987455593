/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from "react";

import { useField } from "informed";
import Button from "../../Button";
import Text from "../../Text";
import { ScribeRatingIcon, ScribeRatingSelected } from "../../Icon";

import styles from "./index.module.scss";

type PropsType = {
  fieldName: string;
};

const RatingInput = ({ fieldName }: PropsType): JSX.Element => {
  const [hover, setHover] = useState<boolean>(false);
  const [hoverRating, setHoverRating] = useState<number | null>(null);

  const { fieldState, fieldApi } = useField({
    name: fieldName
  });

  const { value } = fieldState;
  const { setValue, validate } = fieldApi;

  const handleRatingSelection = (rating: number) => {
    setValue(rating);
    validate();
  };

  return (
    <>
      <div className={styles.FeedbackCardContent}>
        {[1, 2, 3, 4, 5].map((rating) => {
          const isHovered = hover && rating === hoverRating;
          const isSelected = rating === value;

          return (
            <Button
              id="closeFeedbackForm"
              key={`select-rating-${rating}`}
              className={styles.RatingButton}
              type="button"
              inline
              onClick={() => handleRatingSelection(rating)}
            >
              <div
                className={styles.IconWrapper}
                onMouseOver={() => {
                  setHover(true);
                  setHoverRating(rating);
                }}
                onMouseOut={() => {
                  setHover(false);
                }}
              >
                {(isSelected || isHovered) && (
                  <ScribeRatingSelected rating={isHovered ? hoverRating : Number(value)} />
                )}
                {!isSelected && !isHovered && <ScribeRatingIcon rating={rating} />}
              </div>
            </Button>
          );
        })}
      </div>
      <div className={styles.RatingLegend}>
        <Text size="M">Hate it</Text>
        <Text size="M">Love it</Text>
      </div>
    </>
  );
};

export default RatingInput;
