/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

import {
  contextualLogin,
  login,
  updateAdminJWT,
  updateUser as updateUserDeprecated
} from "../../lib";

import {
  clearCurrentOrgData as clearCurrentOrgDataAction,
  fetchUser as fetchUserAction,
  updateUserSettings as updateUserSettingsAction,
  UpdateUsersSettingsData,
  UpdateUserSettingsOptions
} from "../../actions";

import { AppMetricType, saveAppMetrics } from "../../hooks/useAppAnalytics";

import { UserTypeConstants } from "../../constants";
import {
  AnnouncementsSetting,
  FeatureConfiguration,
  Permissions,
  ReduxStateType,
  TelusCategoryMap,
  User,
  UserSettings
} from "../../types";
import canUserUseFeature from "../../utils/canUserUseFeature";

type PropsType = {
  clearCurrentOrgData: (isAdmin: boolean) => void;
  fetchUser: (userId: number) => void;
  updateUserSettings: (
    userId: number,
    updates: UpdateUsersSettingsData,
    updateOptions?: UpdateUserSettingsOptions
  ) => void;
  children: React.ReactNode;
  user: User | null;
};

type UserType =
  | typeof UserTypeConstants.MIKATA_ADMIN
  | typeof UserTypeConstants.STAFF
  | typeof UserTypeConstants.PRACTITIONER;

type ContextualLoginData = {
  telusEMRCategory?: string;
  telusInstanceId?: string;
  contextualLaunchToken?: string;
};

export type DecodedToken = {
  exp: number;
  iat: number;
  lastLoggedIn: string;
  organizationId: string;
  tokenType: string;
  userId: number;
  practitionerId?: number;
  userType: UserType;
  permissions?: Permissions[];
  roleId?: number;
};

type UserData = {
  userId?: number;
  providerId?: number;
  userType?: UserType;
  organizationId?: number;
  tokenExpiry?: number;
  permissions?: Permissions[];
  roleId?: number;
};

type UserContextType = UserData & {
  loginLoading: boolean;
  loginErrorMessage: string;
  permissions: string[];
  settings: UserSettings;
  attemptLogin: (
    credentials: { email: string; password: string },
    contextualLoginData?: ContextualLoginData,
    twoFAData?: { twoFAToken?: string; twoFARecoveryCode?: string }
  ) => Promise<void | {
    twoFAError?: string;
    loginJWT?: string;
    qrCode?: string;
    recoveryCodes?: string[];
    needTwoFA?: boolean;
  }>;
  handleContextualLogin: (params: {
    telusEMRCategory?: string;
    telusInstanceId?: string;
    contextualLaunchToken?: string;
  }) => void;
  logout: () => void;
  attemptPasswordUpdate: (
    userId: string,
    password: string
  ) => Promise<{ success: boolean; msg: string; warning?: string; suggestions?: string[] }>;
  updateAdminOrg: (organizationId?: string) => Promise<void>;
  updateSettings: (updates?: { announcements?: AnnouncementsSetting }, silent?: boolean) => void;
  canUseFeature: (featureName: string) => FeatureConfiguration | false | void;
};

const initialUserContext: UserContextType = {
  loginLoading: false,
  loginErrorMessage: "",
  permissions: [],
  settings: {},
  attemptLogin: () => {
    console.error("attemptLogin not initialized yet");
    return Promise.resolve();
  },
  handleContextualLogin: () => {
    console.error("handleContextualLogin not initialized yet");
    return Promise.resolve();
  },
  logout: () => console.error("logout not initialized yet"),
  attemptPasswordUpdate: () => {
    console.error("attemptPasswordUpdate not initialized yet");
    return Promise.resolve({ success: false, msg: "", warning: "", suggestions: [] });
  },
  updateAdminOrg: () => {
    console.error("updateAdminOrg not initialized yet");
    return Promise.resolve();
  },
  updateSettings: () => {
    console.error("updateSettings not initialized yet");
  },
  canUseFeature: () => {
    console.error("canUseFeature not initialized yet");
  }
};

const getErrorMessageByCode = (code?: number) => {
  switch (code) {
    case 401: {
      return "Uh-oh! The username/password combination you’ve entered is incorrect.";
    }
    case 402: {
      return "Your account is due for a password reset! You should have received an email from Mikata Health with a link to reset your password. If you did not receive an email please talk to your clinic manager or create a support ticket at https://mikatahealth.com/help. Thanks!";
    }
    case 403: {
      return "Whoa there! You've tried to log in too many times. This account has been deactivated for 24 hrs. If you need access right away, please talk to your clinic manager.";
    }
    case 404: {
      return "Looks like your account is not associated with an active organization. If you need access right away, please talk to your clinic manager.";
    }
    case 500: {
      return "Oh dear, looks like things aren’t working quite right. Please refresh the app and try again. If that doesn't work, please create a support ticket at https://mikatahealth.com/help.";
    }
    default: {
      return "Login attempt failed";
    }
  }
};

export const UserContext = React.createContext(initialUserContext);

const getUserData = (token: string | null): UserData => {
  const decoded: DecodedToken | null = token ? jwtDecode(token) : null;

  return {
    userId: decoded?.userId,
    providerId: decoded?.practitionerId,
    userType: decoded?.userType,
    organizationId: decoded?.organizationId
      ? Number.parseInt(decoded.organizationId, 10)
      : undefined,
    permissions: decoded?.permissions,
    tokenExpiry: decoded?.exp,
    roleId: decoded?.roleId
  };
};

const UserProvider = ({
  children,
  user,
  clearCurrentOrgData,
  fetchUser,
  updateUserSettings
}: PropsType) => {
  const jwt = localStorage.getItem("jwt");
  const navigate = useNavigate();

  const [userData, setUserData] = useState<UserData>(getUserData(jwt));
  const [loginLoading, setLoginLoading] = useState<boolean>(initialUserContext.loginLoading);
  const [loginErrorMessage, setLoginErrorMessage] = useState<string>(
    initialUserContext.loginErrorMessage
  );
  const isAdmin = userData && userData.userType === UserTypeConstants.MIKATA_ADMIN;

  const userSettings = user?.settings || {};

  useEffect(() => {
    if (jwt) {
      const userTokenData = getUserData(jwt);
      const epicTimeSeconds = Date.now() / 1000;
      if (
        userTokenData &&
        userTokenData.tokenExpiry &&
        userTokenData.tokenExpiry < epicTimeSeconds
      ) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        logout();
      } else {
        setUserData(userTokenData);
      }
    }
  }, [jwt]);

  // fetch user information/settings
  useEffect(() => {
    if (userData?.userId && fetchUser) {
      fetchUser(userData.userId);
    }
  }, [userData?.userId, fetchUser]);

  const attemptLogin = async (
    credentials: { email: string; password: string },
    contextualLoginData?: ContextualLoginData,
    twoFAData?: { twoFAToken?: string; twoFARecoveryCode?: string }
  ) => {
    setLoginLoading(true);
    setLoginErrorMessage("");

    const data = await login({ ...credentials, ...twoFAData, contextualLoginData });
    if (!data || data.error || data.twoFAError) {
      const errorMessage = getErrorMessageByCode(data ? data.error : undefined);
      setLoginErrorMessage(errorMessage);
      if (data?.twoFAError) {
        setLoginLoading(false);
        return { twoFAError: data.twoFAError };
      }
    } else if (data) {
      const { jwt: loginJWT, qrCode, recoveryCodes, needTwoFA } = data;
      if (loginJWT) {
        localStorage.setItem("jwt", loginJWT);
        const tokenData = getUserData(loginJWT);
        setUserData(tokenData);

        if (userData?.userId) fetchUser(userData.userId);

        if (tokenData?.userType === UserTypeConstants.MIKATA_ADMIN) {
          navigate("/mikata-admin-dashboard");
        } else if (tokenData?.userType === UserTypeConstants.PRACTITIONER) {
          const existingPracFilter = localStorage.getItem("appointmentFilters_practitionerIds");
          if (existingPracFilter === null) {
            localStorage.setItem("appointmentFilters_practitionerIds", `${tokenData.providerId}`);
          }
          navigate("/");
        } else {
          navigate("/");
        }
        setLoginLoading(false);
        if (tokenData?.userType === UserTypeConstants.MIKATA_ADMIN) {
          saveAppMetrics([
            {
              type: AppMetricType.SESSION_ADMIN_USER_LOGIN,
              message: "Admin User login",
              userId: tokenData.userId,
              timestamp: new Date().toISOString()
            }
          ]);
        } else {
          saveAppMetrics([
            {
              type: AppMetricType.SESSION_USER_LOGIN,
              message: "User login",
              userId: tokenData.userId,
              organizationId: tokenData.organizationId,
              timestamp: new Date().toISOString()
            }
          ]);
        }
        return { loginJWT };
      }
      if (qrCode && recoveryCodes) {
        setLoginLoading(false);
        return { qrCode, recoveryCodes, needTwoFA: true };
      }
      if (needTwoFA) {
        setLoginLoading(false);
        return { needTwoFA };
      }
    }
    setLoginLoading(false);
  };

  const handleContextualLogin = async (params: ContextualLoginData) => {
    setLoginLoading(true);
    setLoginErrorMessage("");
    const isMedAccess = params.telusEMRCategory === TelusCategoryMap.MEDACCESS;

    const data = await contextualLogin(params);
    if (!data || data.error) {
      console.log("error in contextualLogin flow!!");
      // will be changed
      // setLoginErrorMessage("Error in contextual login flow.");
      navigate(
        `/login?EmrType=${params.telusEMRCategory}&InstanceId=${params.telusInstanceId}&ContextualLaunchToken=${params.contextualLaunchToken}`
      );
    } else if (data) {
      const { jwt: loginJWT, redirectContext = {} } = data;
      if (loginJWT) {
        const { appointmentId } = redirectContext;
        localStorage.setItem("jwt", loginJWT);
        const tokenData = getUserData(loginJWT);
        setUserData(tokenData);

        if (userData?.userId) fetchUser(userData.userId);

        if (tokenData?.userType === UserTypeConstants.PRACTITIONER) {
          const existingPracFilter = localStorage.getItem("appointmentFilters_practitionerIds");
          if (existingPracFilter === null) {
            localStorage.setItem("appointmentFilters_practitionerIds", `${tokenData.providerId}`);
          }
        }

        if (appointmentId && isMedAccess) {
          // TODO: [IHA-7056] Remove check for isMedAccess when PSS no longer "double" launches.
          // The push to the appointment details page for PSS has been disabled in favour of the appointments page
          // to mitigate effects from duplicate scribe session initializations
          navigate(`/appointments?appointmentId=${appointmentId}&promptStart=y`);
        } else {
          navigate("/");
        }
      }
    }
    setLoginLoading(false);
  };

  const logout = () => {
    localStorage.removeItem("jwt");
    clearCurrentOrgData(isAdmin);
    setUserData({});

    // Force a reload on logout to fetch new app updates
    if (process.env.PUBLIC_URL) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    } else {
      navigate("/login");
    }
  };

  const attemptPasswordUpdate = async (userId: string, password: string) => {
    const response = await updateUserDeprecated(userId, { userUpdateData: { password } });
    return response;
  };

  const updateAdminOrg = async (organizationId?: string) => {
    localStorage.removeItem("appointmentFilters_practitionerIds");
    const tokenData = getUserData(localStorage.getItem("jwt"));
    if (
      userData &&
      userData.userId &&
      userData.userType === UserTypeConstants.MIKATA_ADMIN &&
      organizationId !== tokenData.organizationId
    ) {
      setLoginLoading(true);
      clearCurrentOrgData(isAdmin);
      const response = await updateAdminJWT({
        userId: userData.userId,
        organizationId: organizationId || null
      });

      if (response && response.jwt) {
        localStorage.setItem("jwt", response.jwt);
        const responseTokenData = getUserData(response.jwt);
        setUserData(responseTokenData);
      } else {
        if (response) {
          console.log("updateJWT response", response);
          console.error(response.error);
        }
        logout();
      }
      setLoginLoading(false);
    }
    return Promise.resolve();
  };

  const updateSettings = (
    settingUpdates?: { announcements?: AnnouncementsSetting },
    silent = false
  ): void => {
    const updateData: UpdateUsersSettingsData | null =
      userData?.userId && settingUpdates?.announcements
        ? ({ ...settingUpdates } as UpdateUsersSettingsData)
        : null;
    if (userData?.userId && updateData)
      updateUserSettings(userData.userId, updateData, silent ? { silent } : undefined);
  };

  const canUseFeature = (featureName: string): FeatureConfiguration | false => {
    if (!user) return false;
    return canUserUseFeature(user, featureName);
  };

  const value = {
    ...userData,
    permissions: userData.permissions || [],
    settings: userSettings || {},
    loginLoading,
    loginErrorMessage,
    attemptLogin,
    handleContextualLogin,
    logout,
    attemptPasswordUpdate,
    updateAdminOrg,
    updateSettings,
    canUseFeature
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const mapStateToProps = ({ users }: ReduxStateType) => {
  return {
    user: users.user
  };
};

export default connect(mapStateToProps, {
  clearCurrentOrgData: clearCurrentOrgDataAction,
  fetchUser: fetchUserAction,
  updateUserSettings: updateUserSettingsAction
})(UserProvider);
