import React, { useState, useMemo, useEffect } from "react";
import cx from "classnames";

import { useFormApi, useFormState } from "informed";
import { isEqual } from "lodash";
import Button from "../../Button";
import { CheckboxGroup } from "../../Input";
import Text from "../../Text";
import { FiltersInput } from "../../Filters";
import { getNoteTemplateIcon } from "../../../mikata_admin/OrganizationDetails/views/NoteTemplatesTable";
import AdvancedFilterLayout from "../../AdvancedFilterLayout";

import { Option } from "../../../../types";
import { ALL } from "../../../../constants";

import styles from "./index.module.scss";

type PropsType = {
  ownerOptions: Array<Option>;
  selectedOwners: string[];
  typesOptions: Array<Option>;
  selectedTypes: string[];
  stylingOptions: Array<Option>;
  specialtyOptions: Array<Option>;
  isFilterOpen: boolean;
  onFilterClose: () => void;
};

type FormState = {
  types: string[];
  owners: string[];
  styling: string[];
  specialty: string[];
};

const FilterTemplatesForm = ({
  ownerOptions,
  selectedOwners,
  typesOptions,
  selectedTypes,
  stylingOptions,
  specialtyOptions,
  isFilterOpen,
  onFilterClose
}: PropsType) => {
  const formApi = useFormApi();

  const formState = useFormState();

  const { types, owners, specialty, styling } = formState.values as FormState;

  const [activeFilterLabel, setActiveFilterLabel] = useState<string>("Type");

  const labelConfig = useMemo(() => {
    return {
      Type: types?.filter((item) => item !== ALL)?.length,
      Owner: owners?.filter((item) => item !== ALL)?.length,
      "Specialty/Topics": specialty?.length,
      Styling: styling?.length
    };
  }, [types, owners, specialty, styling]);

  const typesOptionWithIcon = useMemo(() => {
    return typesOptions?.map((item) => {
      return {
        ...item,
        icon: getNoteTemplateIcon(item.value as string, 24, types?.includes(item.value as string))
      };
    });
  }, [typesOptions, types, selectedTypes]);

  useEffect(() => {
    if (!isEqual(selectedTypes, types)) {
      formApi.setValue("types", selectedTypes);
    }
  }, [selectedTypes]);

  useEffect(() => {
    // restores to most recently applied filter state
    if (!isFilterOpen) {
      formApi.reset();
    }
  }, [isFilterOpen]);

  return (
    <AdvancedFilterLayout
      labelConfig={labelConfig}
      headerLabel="Filters"
      isFilterOpen={isFilterOpen}
      onFilterClose={onFilterClose}
      activeLabel={activeFilterLabel}
      onLabelClick={setActiveFilterLabel}
      footerContent={
        <div className={styles.ButtonWrapper}>
          <button
            id="clear-filters"
            type="button"
            className={styles.ClearAllFiltersButton}
            onClick={() => {
              formApi.setTheseValues({
                owners: [ALL],
                types: [ALL],
                styling: [],
                specialty: []
              });
            }}
          >
            <Text component="span" size="S" bold className={styles.ClearFiltersLabel}>
              Clear all
            </Text>
          </button>

          <Button className={styles.Button} type="submit">
            Apply
          </Button>
        </div>
      }
    >
      <div className={styles.FilterWrapper}>
        <div className={styles.FilterContainer}>
          <div
            className={cx(styles.FilterWrapper, {
              [styles.HideFormElement]: activeFilterLabel !== "Type"
            })}
          >
            <FiltersInput
              selectedFilters={selectedTypes}
              filterOptionClassName={styles.Filter}
              filterBadgeClassName={styles.FilterBadge}
              filterOptions={[
                {
                  label: "See All",
                  value: ALL
                },
                ...typesOptionWithIcon
              ]}
              fieldName="types"
            />
          </div>
          <div
            className={cx(styles.FilterWrapper, {
              [styles.HideFormElement]: activeFilterLabel !== "Owner"
            })}
          >
            <FiltersInput
              selectedFilters={selectedOwners}
              filterOptionClassName={styles.Filter}
              filterBadgeClassName={styles.FilterBadge}
              filterOptions={[
                {
                  label: "See All",
                  value: ALL
                },
                ...ownerOptions
              ]}
              fieldName="owners"
            />
          </div>
          <div
            className={cx(styles.FilterWrapper, {
              [styles.HideFormElement]:
                activeFilterLabel !== "Specialty/Topics" ||
                !specialtyOptions ||
                specialtyOptions.length === 0
            })}
          >
            <CheckboxGroup
              fieldName="specialty"
              narrowList
              options={specialtyOptions}
              searchPlaceholder="Find..."
              searchSize="M"
              showCheckAll
            />
          </div>
          <div
            className={cx(styles.FilterWrapper, {
              [styles.HideFormElement]:
                activeFilterLabel !== "Styling" || !stylingOptions || stylingOptions.length === 0
            })}
          >
            <CheckboxGroup
              fieldName="styling"
              narrowList
              options={stylingOptions}
              searchPlaceholder="Find..."
              searchSize="M"
              showCheckAll
            />
          </div>
        </div>
      </div>
    </AdvancedFilterLayout>
  );
};

export default FilterTemplatesForm;
