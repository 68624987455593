import { Content } from "../../../types";

const textTableCard = (content: string) => {
  // Return the contents of a table card as an ascii table.
  try {
    const tableContent = JSON.parse(content);
    // remove table Headers
    tableContent.shift();

    let tableText = "";
    const textRows = tableContent.map((tableRow: string[]) => {
      return `${tableRow[0]}: ${tableRow[1]}`;
    });
    tableText += textRows.join("\n");
    return tableText;
  } catch {
    return "";
  }
};

const getItemAsText = (item: Content) => {
  switch (item.type) {
    case "title":
      return `${item.content}\n\n`;
    case "header":
      if (item.content.toLowerCase() === "chat transcript") return "";
      return `\n${item.content}\n\n`;
    case "text":
      return `${item.content}\n\n`;
    case "table":
      return `${textTableCard(item.content)}\n`;
    case "imageSelect":
      return "Image Select is unavailable in text format.\n\n";
    default:
      return "";
  }
};

export const createTextReport = (items: Content[]) => {
  let reportText = "";

  for (let i = 0; i < items.length; i += 1) {
    reportText += getItemAsText(items[i]);
  }

  return reportText;
};
