import React, { useContext, useEffect } from "react";
import cx from "classnames";

import Button from "../../Button";
import { Close } from "../../Icon";

import StepsCarousel from "./StepsCarousel";

import { AnnouncementsContext } from "../../../providers/AnnouncementsProvider";

import { AnnouncementName } from "../../../../types";

import styles from "./index.module.scss";

type AnnouncementTooltipPosition =
  | "topLeft"
  | "topRight"
  | "bottomLeft"
  | "bottomRight"
  | "topLeftAbove";

type PropsType = {
  announcementName: AnnouncementName;
  announcementTooltipClassName?: string;
  announcementShowable?: boolean;
  contentClassName?: string;
  arrowPosition?: AnnouncementTooltipPosition;
  children?: React.ReactNode;
};

const AnnouncementTooltip = ({
  announcementName,
  announcementShowable = true,
  contentClassName,
  announcementTooltipClassName,
  arrowPosition = "topRight",
  children = null
}: PropsType): React.ReactElement => {
  const {
    tooltipAnnouncements,
    markAnnouncementComplete,
    registerAnnouncement,
    deregisterAnnouncement
  } = useContext(AnnouncementsContext);

  useEffect(() => {
    if (announcementShowable) {
      if (announcementName && registerAnnouncement) {
        registerAnnouncement(announcementName);
      }
    } else if (announcementName && deregisterAnnouncement) {
      deregisterAnnouncement(announcementName);
    }

    return () => {
      if (deregisterAnnouncement) return deregisterAnnouncement(announcementName);
    };
  }, [announcementShowable, announcementName, registerAnnouncement, deregisterAnnouncement]);

  const announcementTooltipToDisplay = tooltipAnnouncements.find(
    (announcement) => announcement.name === announcementName
  );

  if (!announcementTooltipToDisplay) return <>{children}</>;

  return (
    <div className={cx(styles.AnnouncementTooltip, announcementTooltipClassName)}>
      <div
        className={cx(styles.Content, contentClassName, {
          [styles.ContentTopRight]: arrowPosition === "topRight",
          [styles.ContentTopLeft]: arrowPosition === "topLeft",
          [styles.ContentBottomRight]: arrowPosition === "bottomRight",
          [styles.ContentBottomLeft]: arrowPosition === "bottomLeft",
          [styles.ContentTopLeftAbove]: arrowPosition === "topLeftAbove"
        })}
      >
        <div className={cx(styles.ContentContainer)}>
          <div
            className={cx(styles.Arrow, {
              [styles.ArrowTopRight]: arrowPosition === "topRight",
              [styles.ArrowTopLeft]: arrowPosition === "topLeft",
              [styles.ArrowBottomRight]: arrowPosition === "bottomRight",
              [styles.ArrowBottomLeft]: arrowPosition === "bottomLeft",
              [styles.ArrowTopLeftAbove]: arrowPosition === "topLeftAbove"
            })}
          />
          <StepsCarousel
            announcementName={announcementName}
            markAnnouncementComplete={markAnnouncementComplete}
          />
          <Button
            className={cx(styles.ContentClose)}
            inline
            secondary
            darkMode
            onClick={() => markAnnouncementComplete && markAnnouncementComplete(announcementName)}
          >
            <Close size={18} />
          </Button>
        </div>
      </div>

      {children}
    </div>
  );
};

export default AnnouncementTooltip;
