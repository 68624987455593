import React, { useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import cx from "classnames";

import ChatDetails from "./ChatDetails";
import TableGrid from "../../../../ui/TableGrid";
import Heading from "../../../../ui/Heading";
import Loader from "../../../../ui/Loader";
import Button from "../../../../ui/Button";
import Search from "../../../../ui/Search";
import Status from "../../../../ui/Status";
import { Plus } from "../../../../ui/Icon";
import { OrganizationContext } from "../../../../providers/OrganizationProvider";
import useSearchFilter from "../../../../../hooks/useSearchFilter";
import ChatTags from "./ChatTags";

import { PermissionsGuard } from "../../../../../hooks/usePermissions";

import {
  fetchChats as fetchChatsAction,
  getChatFlowDetails as getChatFlowDetailsAction,
  openModal as openModalAction,
  OpenModal
} from "../../../../../actions";
import { updateQueryString, useQueryString } from "../../../../../utils/queryStringHelpers";
import formatDate from "../../../../../utils/formatDate";

import { AdminModalTypes } from "../../../../../constants";
import { Chat, StatusComponentConfigMap, ReduxStateType, Permissions } from "../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  chats: Array<Chat>;
  chatsLoading: boolean;
  fetchChats: () => void;
  getChatFlowDetails?: (chatId: string) => void;
  mikataChats?: string;
  openModal: OpenModal;
};

const statusConfigMap: StatusComponentConfigMap = {
  published: "green",
  draft: "yellow"
};

const headers = [
  { colName: "id", content: "ID" },
  { colName: "title", content: "Title" },
  { colName: "version", content: "Version" },
  { colName: "status", content: "Status" },
  { colName: "owner", content: "Owner" },
  { colName: "tags", content: "Tags" },
  { colName: "createdAt", content: "Created At" }
];

const orderChatsBy = (a: Chat, b: Chat) => {
  // subFlows after root chats
  if (a.isSubFlow && !b.isSubFlow) return 1;
  if (!a.isSubFlow && b.isSubFlow) return -1;

  // draft versions after published
  if (a.title?.toLowerCase() === b.title?.toLowerCase()) {
    return a.published ? -1 : 1;
  }

  // alphabetical by title
  return a.title?.toLowerCase() >= b.title?.toLowerCase() ? 1 : -1;
};

const ChatsTable = ({
  chats,
  chatsLoading,
  fetchChats,
  mikataChats,
  getChatFlowDetails,
  openModal
}: PropsType) => {
  const { filteredRows, onSearchChange, onSearchClear } = useSearchFilter<Chat>(
    chats,
    (search: string, chat: Chat) => {
      return !!(
        (chat.title && chat.title.toLowerCase().includes(search.toLowerCase())) ||
        (chat.version && chat.version.toLowerCase().includes(search.toLowerCase()))
      );
    }
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const { parsed } = useQueryString();

  const isDetailsModalOpen = Boolean(parsed.chatId);
  const maxPageRows = 15;

  const openDetailsModal = (chat: Chat): void => {
    updateQueryString({ chatId: chat.id.toString() }, setSearchParams);
    if (getChatFlowDetails) {
      getChatFlowDetails(chat.id.toString());
    }
  };

  const organization = useContext(OrganizationContext);
  const organizationId = organization ? organization.id : null;

  const closeDetailsModal = (): void => {
    updateQueryString({ chatId: undefined }, setSearchParams);
    onSearchClear();
    fetchChats();
  };

  // Fetch reasons on first load
  useEffect(() => {
    fetchChats();
  }, []);

  const title = mikataChats ? "Mikata Chats" : "Chats";

  const chatRows = filteredRows.sort(orderChatsBy).map((chat) => {
    return {
      ...chat,
      __onRowClick: () => openDetailsModal(chat),
      title: (
        <div className={cx(styles.TableCell, styles.ChatIcon)}>
          <Button
            id={`chat-${chat.id}`}
            inline
            onClick={() => openDetailsModal(chat)}
            className={styles.DetailsButton}
          >
            {chat.title}
          </Button>
        </div>
      ),
      owner: chat.organizationId ? "Your clinic" : "Mikata",
      createdAt: formatDate(chat.createdAt, "simpleDate"),
      version: chat.version ? chat.version : "-",
      status: chat.published ? (
        <div className={cx(styles.TableCell)}>
          <Status
            value="published"
            options={[{ label: "Published", value: "published" }]}
            configMap={statusConfigMap}
            defaultColor="green"
          />
        </div>
      ) : (
        <div className={cx(styles.TableCell)}>
          <Status
            value="draft"
            options={[{ label: "Draft", value: "draft" }]}
            configMap={statusConfigMap}
          />
        </div>
      ),
      tags: (
        <div className={cx(styles.TableCell)}>
          <ChatTags tagNames={chat?.tagNames || []} isSubFlow={chat.isSubFlow} />
        </div>
      )
    };
  });

  if (chatsLoading) return <Loader screen />;

  // Re-render the form if the update error state changes. this aligns the form state with the latest org reasons data.
  return (
    <div>
      <TableGrid
        id="chatsTable"
        headers={headers}
        rows={chatRows}
        headerContent={
          <div className={styles.TableHeader}>
            <Heading className={styles.TableHeaderTitle}>{title}</Heading>
            <div className={styles.TableHeaderGroup}>
              <PermissionsGuard
                requiredPermissions={[Permissions.CREATE_CHAT_FLOW]}
                requiresVerifiedOrg={false}
              >
                <Button
                  id="addChatFlow"
                  onClick={() =>
                    openModal(AdminModalTypes.ADD_CHAT_FLOW, {
                      organizationId
                    })
                  }
                >
                  Add Chat Flow
                  <Plus />
                </Button>
              </PermissionsGuard>
              <Search
                classNames={styles.Search}
                onChange={onSearchChange}
                onClear={onSearchClear}
              />
            </div>
          </div>
        }
        maxPageRows={maxPageRows}
        showRowFocus
      />
      <ChatDetails isModalOpen={isDetailsModalOpen} closeModal={closeDetailsModal} />
    </div>
  );
};

const mapStateToProps = ({ chats }: ReduxStateType) => {
  return {
    chats: chats.chats,
    chatsLoading: chats.chatsLoading
  };
};

export default connect(mapStateToProps, {
  fetchChats: fetchChatsAction,
  getChatFlowDetails: getChatFlowDetailsAction,
  openModal: openModalAction
})(ChatsTable);
