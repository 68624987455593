import React, { useEffect, useState } from "react";
import cx from "classnames";

import Text from "../../../Text";
import Heading from "../../../Heading";
import { Info } from "../../../Icon";
import Tooltip from "../../../Tooltip";

import styles from "./index.module.scss";

type PropsType = {
  fieldName: string;
  label?: string | undefined;
  labelChecked?: string | undefined;
  labelUnchecked?: string | undefined;
  info?: React.ReactNode;
  infoStatus?: "info" | "warning" | "error";
  toggleLabelPosition?: "left" | "right";
  placeholder?: string | undefined;
  disabled?: boolean | undefined;
  labelsAfter?: boolean;
  wrapperClassName?: string;
  displayInverted?: boolean;
  initialValue?: boolean;
  onChange: (value: boolean) => void;
};

const ToggleInputBase = ({
  fieldName,
  label,
  labelChecked,
  labelUnchecked,
  info,
  infoStatus = "info",
  toggleLabelPosition = "left",
  disabled = false,
  labelsAfter = false,
  wrapperClassName,
  initialValue,
  onChange,
  displayInverted = false,
  ...props
}: PropsType): React.ReactElement => {
  const showLabels = labelChecked && labelUnchecked;

  const [isChecked, setIsChecked] = useState(initialValue);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const changeValue = Boolean(event.target.checked);
    setIsChecked(changeValue);
    onChange(changeValue);
  };
  useEffect(() => {
    if (initialValue !== isChecked) setIsChecked(initialValue);
  }, [initialValue]);

  return (
    <div className={cx(styles.Wrapper, wrapperClassName)}>
      <div className={cx(styles.ToggleWrapper)}>
        {label && (
          <div className={styles.Label}>
            <Heading size="META">{label}</Heading>
            {info && (
              <Tooltip
                icon={
                  <div
                    className={cx(styles.Icon, {
                      [styles.IconWarning]: infoStatus === "warning",
                      [styles.IconError]: infoStatus === "error"
                    })}
                  >
                    <Info size={15} />
                  </div>
                }
              >
                {info}
              </Tooltip>
            )}
          </div>
        )}
        <div className={cx(styles.Wrapper, { [styles.WrapperReverse]: labelsAfter })}>
          {showLabels && toggleLabelPosition === "left" && (
            <Text
              className={cx({
                [styles.TextChecked]: isChecked,
                [styles.TextDisabled]: disabled
              })}
              size="S"
            >
              {isChecked ? labelChecked : labelUnchecked}
            </Text>
          )}
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label
            id={`${fieldName}-label`}
            key={fieldName}
            className={cx(styles.ToggleLabel, {
              [styles.ToggleLabelChecked]: displayInverted ? !isChecked : isChecked,
              [styles.ToggleLabelDisabled]: disabled
            })}
          >
            <input
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              id={`${fieldName}-input`}
              className={styles.ToggleInput}
              type="checkbox"
              aria-label={label}
              name={fieldName}
              checked={isChecked}
              onChange={onChangeHandler}
              onSelect={onChangeHandler}
              disabled={disabled}
            />
          </label>
          {showLabels && toggleLabelPosition === "right" && (
            <Text
              className={cx({
                [styles.TextChecked]: isChecked,
                [styles.TextDisabled]: disabled
              })}
              size="S"
            >
              {isChecked ? labelChecked : labelUnchecked}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

export default ToggleInputBase;
