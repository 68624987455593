import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";

import Heading from "../../../ui/Heading";
import { FloatModal } from "../../../ui/Modal";
import Button from "../../../ui/Button";
import { Form, TextInput, RadioGroup } from "../../../ui/Input";

import { closeModal, createUser as createUserAction } from "../../../../actions";
import { isEmail, isRequired, combine } from "../../../../utils/validators";
import { ReduxStateType, UserRole } from "../../../../types";

import styles from "./index.module.scss";
import { UserRoleConstants, UserTypeConstants } from "../../../../constants";
import { UserContext } from "../../../providers/UserProvider";

type PropsType = {
  roles: Array<UserRole>;
  closeModalConnect: () => void;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  createAdminUser: (data: any) => void;
};

type FormState = {
  firstName: string;
  lastName: string;
  email: string | null;
  roleId: number | undefined;
};

const initialFormState: FormState = {
  firstName: "",
  lastName: "",
  email: "",
  roleId: undefined
};

const firstNameValidator = isRequired("Please enter a first name");
const lastNameValidator = isRequired("Please enter a last name");
const emailValidator = combine([
  isRequired("Please enter a valid email"),
  isEmail("Please enter a valid email")
]);

const formValidator = (values: FormState) => {
  return {
    firstName: firstNameValidator(values.firstName),
    lastName: lastNameValidator(values.lastName),
    email: emailValidator(values.email),
    roleId: undefined
  };
};

const AddAdminUser = ({ roles, closeModalConnect, createAdminUser }: PropsType) => {
  const { roleId: editorRoleId } = useContext(UserContext);

  const [roleOptions, setRoleOptions] = useState<{ label: string; value: number }[]>([]);

  const canSeeRole = (editorRole: UserRole | undefined, possibleRole: UserRole): boolean => {
    if (!editorRole || !editorRole.rank) return false;
    if (!possibleRole || !possibleRole.rank) return false;
    return editorRole.rank >= possibleRole.rank;
  };

  useEffect(() => {
    const options = [];
    const editorRole = roles.find((role) => role.id === editorRoleId);

    const adminRole = roles.find((role) => role.title === UserRoleConstants.MIKATA_ADMIN);
    if (adminRole && adminRole.id && canSeeRole(editorRole, adminRole)) {
      options.push({ label: UserRoleConstants.MIKATA_ADMIN, value: adminRole.id });
    }
    const superAdminRole = roles.find(
      (role) => role.title === UserRoleConstants.MIKATA_SUPER_ADMIN
    );
    if (superAdminRole && superAdminRole.id && canSeeRole(editorRole, superAdminRole)) {
      options.push({ label: UserRoleConstants.MIKATA_SUPER_ADMIN, value: superAdminRole.id });
    }
    const billingRole = roles.find((role) => role.title === UserRoleConstants.MIKATA_BILLING_ADMIN);
    if (billingRole && billingRole.id && canSeeRole(editorRole, billingRole)) {
      options.push({ label: UserRoleConstants.MIKATA_BILLING_ADMIN, value: billingRole.id });
    }

    setRoleOptions(options);
  }, [roles]);

  const save = async (formValues: FormState) => {
    const data = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      roleId: formValues.roleId,
      type: UserTypeConstants.MIKATA_ADMIN
    };

    createAdminUser(data);
  };

  return (
    <FloatModal isOpen onClose={closeModalConnect}>
      <Heading size="L" className={styles.AddNewUser}>
        Add New Mikata Admin User
      </Heading>
      <Form
        onSubmit={(formState) => save(formState.values as FormState)}
        initialValues={initialFormState}
        validateFields={(values) => formValidator(values as FormState)}
      >
        <TextInput
          fieldName="firstName"
          label="first name"
          placeholder="Enter first name"
          validate={firstNameValidator}
        />
        <TextInput
          fieldName="lastName"
          label="last name"
          placeholder="Enter last name"
          validate={lastNameValidator}
        />
        <TextInput
          fieldName="email"
          label="email"
          placeholder="Enter email"
          validate={emailValidator}
        />
        <RadioGroup fieldName="roleId" label="choose role" options={roleOptions} />

        <hr className={styles.Divider} />

        <div className={styles.ButtonGroup}>
          <Button inline onClick={closeModalConnect}>
            Cancel
          </Button>
          <Button id="createNewUserButton" type="submit">
            Create
          </Button>
        </div>
      </Form>
    </FloatModal>
  );
};

const mapStateToProps = ({ users }: ReduxStateType) => {
  return {
    roles: users.roles
  };
};

export default connect(mapStateToProps, {
  closeModalConnect: closeModal,
  createAdminUser: createUserAction
})(AddAdminUser);
