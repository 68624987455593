import { TASKS_ASSIGN_TO, TASKS_FETCH, TASKS_FETCH_COUNT, TASK_GET, TaskTypes } from "../constants";

import {
  FetchTasksAction,
  FetchTaskCountsAction,
  GetTaskAction,
  AssignTasksAction
} from "../actions";

import { Task, ActionStatus, TaskCounts } from "../types";

export type TasksAction =
  | FetchTasksAction
  | FetchTaskCountsAction
  | GetTaskAction
  | AssignTasksAction;

export type TasksReduxState = {
  data: Array<Task>;
  taskQueryCount: number;
  task?: Task;
  counts: TaskCounts;
  tasksLoading: boolean;
  taskDetailsLoading: boolean;
  assignTasksLoading: { [id: number]: boolean };
};

const initialModalsState: TasksReduxState = {
  data: [],
  taskQueryCount: 0,
  task: undefined,
  counts: {
    totalCount: NaN,
    [TaskTypes.APPOINTMENT_REQUEST]: NaN,
    [TaskTypes.DIRECT_MESSAGE_CONVERSATION]: NaN
  },
  tasksLoading: false,
  taskDetailsLoading: false,
  assignTasksLoading: {}
};

export const tasksReducer = (state = initialModalsState, action: TasksAction): TasksReduxState => {
  const { type } = action;

  switch (type) {
    case TASKS_FETCH: {
      const { payload, status } = action as FetchTasksAction;
      return {
        ...state,
        data: payload?.tasks || state.data,
        taskQueryCount: payload?.taskQueryCount || state.taskQueryCount,
        tasksLoading: status === ActionStatus.loading
      };
    }

    case TASKS_FETCH_COUNT: {
      const { payload } = action as FetchTaskCountsAction;
      return {
        ...state,
        counts: payload || state.counts
      };
    }

    case TASK_GET: {
      const { payload, status } = action as GetTaskAction;
      return {
        ...state,
        task: payload || state.task,
        taskDetailsLoading: status === ActionStatus.loading
      };
    }

    case TASKS_ASSIGN_TO: {
      const { payload, status } = action as AssignTasksAction;
      const assignTasksLoading: { [id: number]: boolean } = {};
      if (payload?.data.taskIds && payload.data.taskIds.length) {
        payload?.data.taskIds.map((taskId: number) => {
          assignTasksLoading[taskId] = status === ActionStatus.loading;
        });
      }
      return {
        ...state,
        assignTasksLoading
      };
    }

    default:
      return state;
  }
};
