import React from "react";
import cx from "classnames";

import { useFormApi } from "informed";
import Text from "../../../ui/Text";
import { TextInput } from "../../../ui/Input";
import Button from "../../../ui/Button";

import { isRequired } from "../../../../utils/validators";

import styles from "./index.module.scss";

type PropsType = {
  loginLoading: boolean;
  recoveryMode: boolean;
  setRecoveryMode: (recoveryMode: boolean) => void;
  twoFAError: string;
  setTwoFAError: (twoFAError: string) => void;
};

const twoFAValidator = isRequired("Please enter a code");

const SignInTwoFAForm = ({
  recoveryMode,
  setRecoveryMode,
  twoFAError,
  setTwoFAError,
  loginLoading
}: PropsType) => {
  const formApi = useFormApi();
  return (
    <>
      <div className={cx({ [styles.Hidden]: !recoveryMode })}>
        <Text className={styles.Text}>
          To recover your account and set up a new device, please enter a recovery code.
        </Text>
        <TextInput
          fieldName="twoFARecoveryCode"
          label="Recovery Code"
          validate={recoveryMode ? twoFAValidator : undefined}
          warningMessage={twoFAError}
          autoFocus
        />
      </div>
      <div className={cx({ [styles.Hidden]: recoveryMode })}>
        <Text className={styles.Text}>
          Using your authenticator app, please enter the 2FA passcode.
        </Text>
        <TextInput
          fieldName="twoFAToken"
          label="2FA Token"
          validate={recoveryMode ? undefined : twoFAValidator}
          warningMessage={twoFAError}
          autoFocus
        />
      </div>
      <div className={styles.ButtonGroup}>
        <Button type="submit" disabled={loginLoading}>
          {recoveryMode ? "Submit Recovery Code" : "Verify One-Time Passcode"}
        </Button>
        {!recoveryMode && (
          <Button
            inline
            onClick={() => {
              setRecoveryMode(true);
              setTwoFAError("");
              formApi.setValue("twoFAToken", "");
            }}
            disabled={loginLoading}
          >
            Recover Your Account
          </Button>
        )}
      </div>
    </>
  );
};

export default SignInTwoFAForm;
