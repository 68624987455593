import React from "react";
import { connect } from "react-redux";

import Button from "../../../ui/Button";
import Heading from "../../../ui/Heading";
import { FloatModal } from "../../../ui/Modal";
import Text from "../../../ui/Text";
import { Form, CheckboxGroup } from "../../../ui/Input";

import {
  updateChatFlowOutput as updateOutputAction,
  closeModal as closeModalAction
} from "../../../../actions";

import { isRequired } from "../../../../utils/validators";

import styles from "./index.module.scss";

import { ReduxStateType, UpdateChatFlowOutput } from "../../../../types";

type DeleteOutputFormData = {
  confirmed: Array<string>;
};

type PropsType = {
  loading?: boolean;
  outputId: number;
  chatFlowId: number | null;
  closeModal: () => void;
  updateOutput: (outputId: number, data: UpdateChatFlowOutput, chatFlowId: number) => void;
};

const initialFormState: DeleteOutputFormData = {
  confirmed: []
};

const confirmedValidator = isRequired("Confirmation required");

const DeleteOutput = ({ loading, closeModal, updateOutput, outputId, chatFlowId }: PropsType) => {
  const onDelete = () => {
    if (!chatFlowId) return;
    updateOutput(outputId, { deleted: true }, chatFlowId);
    closeModal();
  };

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L">Delete Output</Heading>
      <Text component="p">
        You are about to permanently delete this output. Is this what you want to do?
      </Text>
      <Form className={styles.Form} onSubmit={onDelete} initialValues={initialFormState}>
        <CheckboxGroup
          fieldName="confirmed"
          options={[{ label: "Yes, I want to delete", value: "true" }]}
          validate={confirmedValidator}
        />
        <div className={styles.ButtonGroup}>
          <Button inline onClick={closeModal} disabled={loading}>
            Cancel
          </Button>
          <Button type="submit" disabled={loading}>
            Delete
          </Button>
        </div>
      </Form>
    </FloatModal>
  );
};
const mapStateToProps = ({ chats }: ReduxStateType) => {
  return {
    chatFlowId: chats.chatDetails.chatFlowId
  };
};

export default connect(mapStateToProps, {
  updateOutput: updateOutputAction,
  closeModal: closeModalAction
})(DeleteOutput);
