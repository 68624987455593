import React, { useContext, useState } from "react";
import { connect } from "react-redux";

import { UserContext } from "../../../providers/UserProvider";
import { OrganizationContext } from "../../../providers/OrganizationProvider";

import Heading from "../../../ui/Heading";
import Text from "../../../ui/Text";
import { FloatModal } from "../../../ui/Modal";
import { Form } from "../../../ui/Input";
import UploadAppointmentsForm from "./UploadAppointmentsForm";

import {
  addAutomation as addAutomationAction,
  pollUploadRecord as pollUploadRecordAction,
  addNotification as addNotificationAction,
  closeModal as closeModalAction
} from "../../../../actions";
import { getSignedUrl, uploadFiles } from "../../../../lib";
import { ReduxStateType, NewNotification } from "../../../../types";

import styles from "./index.module.scss";

type UploadAppointmentsFormData = {
  uploadFile: string;
};

type PropsType = {
  uploadInProgress: boolean;
  closeModal: () => void;
  addNotification: (notification: NewNotification) => void;
  pollUploadRecord: (organizationId: number, uploadRecordId: number) => void;
};

const UploadAppointments = ({
  uploadInProgress,
  addNotification,
  closeModal,
  pollUploadRecord
}: PropsType) => {
  const { organizationId, userId } = useContext(UserContext);
  const organization = useContext(OrganizationContext);
  const [uploading, setUploading] = useState(false);
  const isSampleUpload = !organization?.setupComplete;

  const initialFormState: UploadAppointmentsFormData = {
    uploadFile: ""
  };

  const onUpload = async (formData: UploadAppointmentsFormData) => {
    setUploading(true);
    // Prepare the file for uploading, fetching from state, adding organizationId and userId:
    const { uploadFile } = formData;

    const dataForS3 = new FormData();
    dataForS3.append("file", uploadFile);
    dataForS3.append("organizationId", organizationId?.toString() || "");
    dataForS3.append("userId", userId?.toString() || "");

    try {
      // get the url to make an s3 drop and upload the file to the appropriate s3 bucket:
      const bucketName = isSampleUpload ? "org-sample-export" : "file-uploads";
      const { url, uploadRecordId } = await getSignedUrl({ bucketName });
      dataForS3.append("uploadRecordId", uploadRecordId);
      const uploadResponse = await uploadFiles(url, dataForS3);

      // This conditional handles successful drops into s3:
      if (organizationId && uploadResponse.status === 200) {
        if (isSampleUpload) {
          addNotification({
            type: "success",
            title: "The sample file was successfully uploaded!",
            subtitle: "",
            autoDismiss: true
          });
          closeModal();
        } else {
          pollUploadRecord(organizationId, uploadRecordId);
        }
      } else {
        addNotification({
          type: "error",
          title:
            "Uh-oh! We weren't able to upload your file. Please refresh the app and try again. If that doesn't work, please create a support ticket at https://mikatahealth.com/help.",
          subtitle: "Please try again",
          autoDismiss: true
        });
      }
      // If there was an error connecting to the API:
    } catch (e) {
      addNotification({
        type: "error",
        title:
          "Uh-oh! We weren't able to upload your file. Please refresh the app and try again. If that doesn't work, please create a support ticket at https://mikatahealth.com/help.",
        subtitle: "Please try again",
        autoDismiss: true
      });
    }
    setUploading(false);
  };

  const loading = uploading || uploadInProgress;

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="XL" className={styles.Heading}>
        {isSampleUpload ? "It's time to upload a sample!" : "It's time to upload!"}
      </Heading>
      <Text size="M" className={styles.Subtitle}>
        Please choose the latest version of the CSV file
      </Text>
      <Form initialValues={initialFormState}>
        <UploadAppointmentsForm loading={loading} onUpload={onUpload} closeModal={closeModal} />
      </Form>
    </FloatModal>
  );
};

const mapStateToProps = ({ uploadRecord }: ReduxStateType) => {
  return {
    uploadInProgress: uploadRecord.uploadInProgress
  };
};

export default connect(mapStateToProps, {
  addAutomation: addAutomationAction,
  pollUploadRecord: pollUploadRecordAction,
  addNotification: addNotificationAction,
  closeModal: closeModalAction
})(UploadAppointments);
