import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";

import Text from "../../../../ui/Text";

import { Form } from "../../../../ui/Input";
import { getSignedUrl, uploadFiles } from "../../../../../lib";
import FormContent from "./SecureDirectMessageFormContent";

import {
  addNotification as addNotificationAction,
  closeModal as closeModalAction,
  fetchMessageTemplates as fetchMessageTemplatesAction,
  getAvailableChats as getAvailableChatsAction,
  GetAvailableChatsData,
  sendDirectMessage as sendDirectMessageAction,
  SendDirectMessageData
} from "../../../../../actions";

import {
  MessageTopics,
  BucketNameSuffixes,
  DirectMessageModes,
  FEATURES
} from "../../../../../constants";

import {
  AppointmentConversation,
  Chat,
  ReduxStateType,
  MessageTemplate,
  NewNotification,
  EventData,
  EventTypes
} from "../../../../../types";

import filterDirectMessageConversations from "../../../../../utils/filterDirectMessageConversations";

export type SecureDirectMessageFormData = {
  chatFlowIds: Array<number>;
  messageTemplateId: string;
  payloadContent: string;
  uploadSecureFiles: Array<File> | string;
  selectedSuggestion: string;
};

type PropsType = {
  loading?: boolean;
  availableChats?: Array<Chat>;
  conversations?: Array<AppointmentConversation>;
  messageTemplates?: Array<MessageTemplate>;
  fetchMessageTemplates?: () => void;
  getAvailableChats: (data: GetAvailableChatsData) => void;
  sendDirectMessage?: (data: SendDirectMessageData) => void;
  closeModal?: () => void;
  addNotification?: (notification: NewNotification) => void;
  sendProviderActivityMessage?: (eventType: string, eventData?: EventData) => void;
  patientDisplayName: string;
  patientId: number;
  appointmentId: number;
  mobilePhone: string | null;
  emailAddress: string;
  canSendSecureMessage: boolean;
  dpmContentSuggestion: string | undefined;
  organizationFeatures: string[];
};

const initialFormState: SecureDirectMessageFormData = {
  chatFlowIds: [],
  messageTemplateId: "",
  payloadContent: "",
  uploadSecureFiles: "",
  selectedSuggestion: ""
};

const templateTopicFilter = (topic: string) => (template: MessageTemplate) => {
  return template.topic === topic;
};

const SecureDirectMessageForm = ({
  loading,
  closeModal,
  addNotification,
  availableChats,
  conversations,
  messageTemplates,
  fetchMessageTemplates,
  getAvailableChats,
  sendDirectMessage,
  sendProviderActivityMessage,
  patientDisplayName,
  patientId,
  appointmentId,
  mobilePhone,
  emailAddress,
  canSendSecureMessage,
  dpmContentSuggestion,
  organizationFeatures
}: PropsType) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [contentUploadList, setContentUploadList] = useState<Array<string>>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  const updateError = () => {
    setErrorMessage("");
  };

  // Fetch messages on first load
  useEffect(() => {
    if (fetchMessageTemplates) fetchMessageTemplates();
    if (getAvailableChats) getAvailableChats({ includeMikataChats: false });
  }, [patientDisplayName]);

  const onSend = async (formData: SecureDirectMessageFormData) => {
    const { chatFlowIds, uploadSecureFiles } = formData;
    const payloadContent = formData.payloadContent.trim();

    if (!payloadContent && !uploadSecureFiles.length) {
      setErrorMessage("You must write your message here and/or attach files");
    } else if (sendDirectMessage) {
      if (uploadSecureFiles && Array.isArray(uploadSecureFiles) && uploadSecureFiles.length) {
        setUploading(true);
        const uploadResponseList = await Promise.all(
          uploadSecureFiles.map(async (secureFile: File) => {
            try {
              const response = await getSignedUrl({
                bucketName: BucketNameSuffixes.STAFF_UPLOADS,
                fileExt: secureFile.type.split("/")[1],
                fileNamePrefix: `${appointmentId}-${patientId}`
              });

              const clinicFileName = secureFile.name;
              const uploadResponse = await uploadFiles(response.url, secureFile);

              return {
                success: uploadResponse.status === 200,
                s3FileName: response.filename,
                clinicFileName
              };
            } catch {
              return {
                success: false,
                clinicFileName: secureFile.name
              };
            }
          })
        );

        if (uploadResponseList.every((response) => response.success)) {
          sendDirectMessage({
            mode: DirectMessageModes.SECURE,
            patientId,
            appointmentId,
            chatFlowIds,
            payloadContent,
            attachments: uploadResponseList.map((response) => {
              return {
                s3FileName: response.s3FileName as string,
                clinicFileName: response.clinicFileName as string
              };
            }),
            mobilePhone,
            emailAddress
          });
        } else if (addNotification) {
          addNotification({
            type: "error",
            title: "Failed to send direct message",
            subtitle: "Please try again",
            autoDismiss: true
          });
        }

        setUploading(false);
      } else {
        sendDirectMessage({
          mode: DirectMessageModes.SECURE,
          patientId,
          appointmentId,
          chatFlowIds,
          payloadContent,
          mobilePhone,
          emailAddress
        });
      }
      if (sendProviderActivityMessage) {
        sendProviderActivityMessage(EventTypes.SEND_NOTE_TO_PATIENT);
      }
    }
  };

  const formDisabled = loading || uploading;

  const regularConversations = useMemo(
    () => (conversations && filterDirectMessageConversations(conversations, false)) || [],
    [conversations]
  );

  const chatOptions = regularConversations
    ? regularConversations.map((conversation) => {
        return {
          label: `${conversation.chatFlowTitle}`,
          value: conversation.chatFlowId
        };
      })
    : [];
  const chatOptionIds = chatOptions.map((option) => option.value);

  availableChats?.forEach((chat) => {
    if (!chatOptionIds.includes(chat.id)) {
      chatOptions.push({
        label: `${chat.title}${chat.version ? ` (${chat.version})` : ""}`,
        value: chat.id
      });
    }
  });

  const messageTemplateOptions = messageTemplates
    ? messageTemplates
        .filter(templateTopicFilter(MessageTopics.SECURE_DIRECT_MESSAGE))
        .map((messageTemplate) => ({
          label: messageTemplate.isDefault
            ? `Default: ${messageTemplate.displayName} (${messageTemplate.id})`
            : `${messageTemplate.displayName} (${messageTemplate.id})`,
          value: messageTemplate.id.toString()
        }))
    : [];

  messageTemplateOptions.unshift({
    label: "None",
    value: "none"
  });

  if (dpmContentSuggestion || dpmContentSuggestion === "") {
    initialFormState.payloadContent = dpmContentSuggestion;
  }

  const hasDirectMessagingFeature = organizationFeatures?.includes(
    FEATURES.DIRECT_PATIENT_MESSAGES.value
  );
  if (!hasDirectMessagingFeature) {
    return (
      <Text>
        Hi! If you are interested in learning more about our on-demand messaging feature, please
        email your clinic advisor at demo@mikatahealth.com.
      </Text>
    );
  }

  return (
    <Form initialValues={initialFormState} id="secureMessageForm" keepStateIfRelevant>
      <FormContent
        messageTemplateOptions={messageTemplateOptions}
        chatOptions={chatOptions}
        errorMessage={errorMessage}
        dpmContentSuggestion={dpmContentSuggestion}
        updateError={updateError}
        canSendSecureMessage={canSendSecureMessage}
        contentUploadList={contentUploadList}
        onSend={onSend}
        closeModal={closeModal}
        messageTemplates={messageTemplates}
        formDisabled={formDisabled}
        setContentUploadList={setContentUploadList}
        patientDisplayName={patientDisplayName}
      />
    </Form>
  );
};

const mapStateToProps = ({
  appointments,
  chats,
  messageTemplates,
  messages,
  organizationData
}: ReduxStateType) => {
  return {
    availableChats: chats.availableChats,
    conversations: appointments.details.data?.conversations,
    messageTemplates: messageTemplates.data,
    loading: messages.sendDirectMessageLoading,
    organizationFeatures: organizationData.organizationData
      ? organizationData.organizationData.features
      : []
  };
};

export default connect(mapStateToProps, {
  fetchMessageTemplates: fetchMessageTemplatesAction,
  getAvailableChats: getAvailableChatsAction,
  sendDirectMessage: sendDirectMessageAction,
  closeModal: closeModalAction,
  addNotification: addNotificationAction
})(SecureDirectMessageForm);
