import React, { useContext } from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

import Button from "../../../ui/Button";
import Tooltip from "../../../ui/Tooltip";
import { AnnouncementTooltip } from "../../../ui/Announcements";

import { OrganizationContext } from "../../../providers/OrganizationProvider";
import { UserContext } from "../../../providers/UserProvider";

import {
  createUnintegratedAppointment as createUnintegratedAppointmentAction,
  CreateUnintegratedAppointmentData,
  openModal as openModalAction,
  OpenModal
} from "../../../../actions";

import { usePermissions } from "../../../../hooks/usePermissions";
import { updateQueryString } from "../../../../utils/queryStringHelpers";

import { ModalTypes, UserTypeConstants } from "../../../../constants";

import {
  Permissions,
  OrganizationEmrFeatures,
  AnnouncementName,
  ReduxStateType,
  Reason,
  Location
} from "../../../../types";

import styles from "./addUnintergratedAppointmentButton.module.scss";

type PropsType = {
  locations: Location[];
  locationsLoading: boolean;
  reasons: Reason[];
  reasonsLoading: boolean;
  createUnintegratedLoading: boolean;
  openModal: OpenModal;
  createUnintegratedAppointment: (
    data: CreateUnintegratedAppointmentData,
    options?: { onSuccess?: (appointmentId?: string) => void }
  ) => void;
  isDetailsModalOpen?: boolean;
  instantSession?: boolean;
};

const AddUnintegratedAppointmentButton = ({
  locations,
  locationsLoading,
  reasons,
  reasonsLoading,
  createUnintegratedLoading,
  openModal,
  createUnintegratedAppointment,
  isDetailsModalOpen = false,
  instantSession = false
}: PropsType) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const organization = useContext(OrganizationContext);
  const user = useContext(UserContext);
  const emrFeatures = organization?.emrFeatures || [];
  const organizationHasCapability =
    emrFeatures.includes(OrganizationEmrFeatures.UNINTEGRATED_APPOINTMENTS) &&
    emrFeatures.includes(OrganizationEmrFeatures.UNINTEGRATED_PATIENTS);
  const userHasPermission = usePermissions(
    [Permissions.CREATE_UNINTEGRATED_APPOINTMENT, Permissions.CREATE_UNINTEGRATED_PATIENT],
    false
  );

  const canCreateUnintegratedAppointment = userHasPermission && organizationHasCapability;

  if (!canCreateUnintegratedAppointment) return null;

  if (instantSession && (!user?.providerId || user.userType !== UserTypeConstants.PRACTITIONER))
    return null;

  const instantStartDisabled = locationsLoading || reasonsLoading || createUnintegratedLoading;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const instantStart = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    const practitionerId = user?.providerId || null;

    // initial selected locationId (1st last selected location, 2nd first option)
    let locationId = null;
    const userPreferenceLocationId = localStorage.getItem(
      "userPreference_unintegratedCreate_locationId"
    );
    const preferredLocation = userPreferenceLocationId
      ? locations.find((location) => location.id?.toString() === userPreferenceLocationId)
      : null;
    if (preferredLocation) {
      locationId = preferredLocation.id;
    } else {
      const firstActiveLocation = locations.find((location) => location.active);
      locationId = firstActiveLocation?.id || null;
    }
    // initial selected reasonId (1st last selected reason, 2nd first option)
    let reasonId = null;
    const userPreferenceReasonId = localStorage.getItem(
      "userPreference_unintegratedCreate_ReasonId"
    );
    const preferredReason = userPreferenceReasonId
      ? reasons.find((reason) => reason.id?.toString() === userPreferenceReasonId)
      : null;
    if (preferredReason) {
      reasonId = preferredReason.id;
    } else {
      const firstActiveReason = reasons.find((reason) => reason.active);
      reasonId = firstActiveReason?.id || null;
    }

    return createUnintegratedAppointment(
      {
        patient: {
          firstName: "Quick Start",
          lastName: "Patient",
          mobilePhone: "",
          email: "",
          phn: ""
        },
        appointment: {
          start: moment().toISOString(),
          practitionerId,
          locationId,
          reasonId
        }
      },
      {
        onSuccess: (appointmentId?: string) => {
          if (appointmentId) {
            updateQueryString({ appointmentId, promptStart: "y" }, setSearchParams);
          }
        }
      }
    );
  };

  return (
    <>
      {instantSession ? (
        <Tooltip
          className={styles.WrapperQuickStart}
          contentClassName={styles.Above}
          icon={
            <Button
              className={styles.ButtonQuickStart}
              size="S"
              onClick={instantStart}
              disabled={instantStartDisabled}
            >
              Quick Start
            </Button>
          }
          position="topLeft"
          size="L"
        >
          Start a Mika scribe session quickly without needing to enter any appointment details
          (these can always be added later if desired).
        </Tooltip>
      ) : (
        <AnnouncementTooltip
          announcementName={AnnouncementName.CREATE_UNINTEGRATED_APPOINTMENT_TUTORIAL}
          announcementShowable={!isDetailsModalOpen}
        >
          <Tooltip
            className={styles.WrapperAdd}
            contentClassName={styles.Above}
            icon={
              <Button
                className={styles.ButtonAdd}
                size="S"
                secondary
                onClick={() => openModal(ModalTypes.ADD_UNINTEGRATED_APPOINTMENT)}
              >
                Add
              </Button>
            }
            position="topLeft"
            size="L"
          >
            Add a new appointment with details that can be used now or later.
          </Tooltip>
        </AnnouncementTooltip>
      )}
    </>
  );
};

const mapStateToProps = ({ appointments, locations, reasons }: ReduxStateType) => {
  return {
    createUnintegratedLoading: appointments.createUnintegratedLoading,
    locations: locations.data,
    locationsLoading: locations.locationsFetchLoading,
    reasons: reasons.data,
    reasonsLoading: !reasons.initialized
  };
};

export default connect(mapStateToProps, {
  createUnintegratedAppointment: createUnintegratedAppointmentAction,
  openModal: openModalAction
})(AddUnintegratedAppointmentButton);
