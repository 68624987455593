import React, { useState } from "react";
import cx from "classnames";

import Heading from "../../../../../../../ui/Heading";
import { ChevronDown, ChevronUp } from "../../../../../../../ui/Icon";
import {
  SelectInput,
  TextInput,
  CheckboxGroup,
  ToggleInput,
  FormGrid
} from "../../../../../../../ui/Input";

import { ChatFlowsNodes } from "../../../../../../../../types";
import {
  MessageTemplateAppointmentRequestTags,
  MessageTemplateActionTags,
  ChatCardTypes
} from "../../../../../../../../constants";

import styles from "./index.module.scss";

type PropsType = {
  node: ChatFlowsNodes;
  viewOnly: boolean;
};

const AdvancedSection = ({ node, viewOnly }: PropsType) => {
  const [isOpen, setIsOpen] = useState(false);
  const isSkippable =
    Boolean(node.inputName) &&
    node.cardType !== ChatCardTypes.BOOKING &&
    node.cardType !== ChatCardTypes.PATIENT_VERIFICATION &&
    node.cardType !== ChatCardTypes.SCRIBE_VISIT;

  const tagNameOptions = [
    { label: "None", value: "" },
    ...MessageTemplateAppointmentRequestTags.map((tag) => ({
      label: tag.label,
      value: tag.value
    }))
  ];

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={cx(styles.Advanced, { [styles.AdvancedOpen]: isOpen })}>
      <div className={cx(styles.Header, { [styles.HeaderOpen]: isOpen })}>
        <button type="button" className={cx(styles.ToggleIcon)} onClick={toggleOpen}>
          <Heading size="S" className={cx(styles.HeaderText)}>
            Advanced
          </Heading>
          <span>{isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}</span>
        </button>
      </div>

      <div className={cx(styles.AdvancedDrawer, { [styles.AdvancedDrawerOpen]: isOpen })}>
        {isSkippable && (
          <>
            <FormGrid>
              <ToggleInput
                wrapperClassName={styles.Skippable}
                fieldName="skippable"
                label="Skippable"
                labelChecked="Skippable Input"
                labelUnchecked="Required Input"
                labelsAfter
                disabled={viewOnly}
              />
              <TextInput fieldName="skipMessage" label="Skip Button Label" disabled={viewOnly} />
            </FormGrid>

            <SelectInput
              fieldName="tagName"
              options={tagNameOptions}
              label="Select a Tag"
              disabled={viewOnly}
            />
          </>
        )}
        <CheckboxGroup
          label="Actions"
          fieldName="actions"
          options={[...MessageTemplateActionTags]}
        />
      </div>
    </div>
  );
};

export default AdvancedSection;
