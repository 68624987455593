import React, { useState } from "react";
import { connect } from "react-redux";

import Heading from "../../../ui/Heading";
import Text from "../../../ui/Text";
import Status from "../../../ui/Status";
import { FloatModal } from "../../../ui/Modal";
import Button from "../../../ui/Button";
import { DownloadCloud } from "../../../ui/Icon";

import { closeModal as closeModalAction } from "../../../../actions";

import formatDate from "../../../../utils/formatDate";
import downloadFile from "../../../../utils/download-campaign-file";
import { fetchCampaignSummary } from "../../../../lib";
import { BucketNameSuffixes } from "../../../../constants";

import styles from "./index.module.scss";

import { Campaign, CampaignStatus } from "../../../../types";

type PropsType = {
  campaign: Campaign;
  closeModal: () => void;
};

const CampaignsDetail = ({ campaign, closeModal }: PropsType) => {
  const [campaignFileLoading, setCampaignFileLoading] = useState(false);
  const [summaryFileLoading, setSummaryFileLoading] = useState(false);

  const downloadCampaignFile = async (fileName: string) => {
    setCampaignFileLoading(true);
    await downloadFile(BucketNameSuffixes.CAMPAIGN_UPLOADS, fileName);
    setCampaignFileLoading(false);
  };

  const downloadCampaignSummary = async (campaign: Campaign) => {
    try {
      setSummaryFileLoading(true);
      const result = await fetchCampaignSummary(campaign.id);
      const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${result.data}`);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${campaign.name} Summary.csv`);
      document.body.appendChild(link);
      link.click();
      setSummaryFileLoading(false);
    } catch (e) {
      console.error(e);
      setSummaryFileLoading(false);
    }
  };

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L" className={styles.Heading}>
        {campaign.name}
      </Heading>
      <div className={styles.Groups}>
        <div className={styles.InfoGroup}>
          <Heading size="META" component="h5">
            Status
          </Heading>

          {campaign.status ? (
            <Status
              value={campaign.status}
              options={[
                {
                  label: "Active",
                  value: CampaignStatus.ACTIVE
                },
                {
                  label: "Complete",
                  value: CampaignStatus.COMPLETE
                },
                {
                  label: "Draft",
                  value: CampaignStatus.DRAFT
                },
                {
                  label: "Error",
                  value: CampaignStatus.ERROR
                },
                {
                  label: "File Error",
                  value: CampaignStatus.FILE_ERROR
                }
              ]}
              configMap={{
                [CampaignStatus.COMPLETE]: "green",
                [CampaignStatus.ACTIVE]: "blue",
                [CampaignStatus.DRAFT]: "blue",
                [CampaignStatus.ERROR]: "red",
                [CampaignStatus.FILE_ERROR]: "red",
                default: "gray"
              }}
            />
          ) : (
            "-"
          )}
        </div>
        <div className={styles.InfoGroup}>
          <Heading size="META" component="h5">
            Created
          </Heading>
          <Text>{formatDate(campaign.created_at)}</Text>
        </div>
        <div className={styles.InfoGroup}>
          <Heading size="META" component="h5">
            Start Date
          </Heading>
          <Text>{campaign.startDate ? formatDate(campaign.startDate, "dateOnly") : "-"}</Text>
        </div>
        <div className={styles.InfoGroup}>
          <Heading size="META" component="h5">
            Send Rate
          </Heading>
          <Text>{`${campaign.sendRate} messages/day`}</Text>
        </div>
        <div className={styles.InfoGroup}>
          <Heading size="META" component="h5">
            Recipient Count
          </Heading>
          <Text>{campaign.recipientCount}</Text>
        </div>
      </div>
      {campaign.fileName && (
        <div className={styles.DownloadGroup}>
          <Heading size="META" component="h5">
            Recipient List Download
          </Heading>

          <div className={styles.DownloadRow}>
            <Text> {campaign.fileName}</Text>
            <Button
              inline
              className={styles.DownloadIcon}
              onClick={() => downloadCampaignFile(campaign.fileName)}
              disabled={campaignFileLoading}
            >
              <DownloadCloud />
            </Button>
          </div>
        </div>
      )}
      <div className={styles.DownloadGroup}>
        <Heading size="META" component="h5">
          summary
        </Heading>
        <div className={styles.DownloadRow}>
          <Text> {`${campaign.name} Summary.csv`}</Text>
          <Button
            id="campaignSummaryDownloadButton"
            inline
            className={styles.DownloadIcon}
            onClick={() => downloadCampaignSummary(campaign)}
            disabled={summaryFileLoading}
          >
            <DownloadCloud />
          </Button>
        </div>
      </div>
    </FloatModal>
  );
};

export default connect(null, {
  closeModal: closeModalAction
})(CampaignsDetail);
