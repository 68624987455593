import React from "react";

import { useFormState } from "informed";
import Button, { ButtonRow } from "../../../../../ui/Button";

import Heading from "../../../../../ui/Heading";
import Card from "../../../../../ui/Card";

import { CheckboxGroup } from "../../../../../ui/Input";
import Drawer from "../../../../../ui/Drawer";

import { PermissionsGuard } from "../../../../../../hooks/usePermissions";

import { generateReasonLabel } from "../../../../../../utils/generateReasonLabel";
import { generatePractitionerLabel } from "../../../../../../utils/generatePractitionerLabel";

import { JourneyTriggersFormState } from "../JourneyFormValidator";
import { Location, Permissions, Practitioner, Reason } from "../../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  locations: Array<Location>;
  practitioners: Array<Practitioner>;
  formDisabled: boolean;
  isDefaultAutomation: boolean;
  reasons: Array<Reason>;
};

const TriggerFormContent = ({
  locations,
  practitioners,
  reasons,
  formDisabled,
  isDefaultAutomation
}: PropsType): JSX.Element | null => {
  const formState = useFormState();
  const values = formState.values as JourneyTriggersFormState;

  return (
    <div className={styles.ContainerMain}>
      <Card>
        <Heading size="M" component="h4">
          Triggers configuration
        </Heading>
        <Drawer
          headerContent={
            <Heading size="S" bold component="h5" className={styles.Heading}>
              {`Locations (${values.locations?.length})`}
            </Heading>
          }
          initialOpen
        >
          {locations && locations.length > 0 && (
            <CheckboxGroup
              fieldName="locations"
              label="Select Location(s)"
              showCheckAll={locations.length > 2}
              options={locations.map((location) => ({
                label: `${location.displayName}${!location.active ? " (inactive)" : ""}`,
                value: location.id.toString()
              }))}
              disabled={formDisabled || isDefaultAutomation}
              searchPlaceholder="Find a location"
              searchSize="L"
            />
          )}
        </Drawer>
        <div className={styles.Divider} />
        <Drawer
          headerContent={
            <Heading size="S" bold component="h5" className={styles.Heading}>
              {`Providers (${values.practitioners?.length})`}
            </Heading>
          }
          initialOpen
        >
          {practitioners && practitioners.length > 0 && (
            <CheckboxGroup
              fieldName="practitioners"
              label="Select Provider(s)"
              showCheckAll={practitioners.length > 2}
              options={practitioners.map((practitioner) => ({
                label: generatePractitionerLabel(practitioner),
                value: practitioner.id.toString()
              }))}
              disabled={formDisabled || isDefaultAutomation}
              searchPlaceholder="Find a provider"
              searchSize="L"
            />
          )}
        </Drawer>
        <div className={styles.Divider} />
        <Drawer
          headerContent={
            <Heading size="S" bold component="h5" className={styles.Heading}>
              {`Reasons (${values.reasons?.length})`}
            </Heading>
          }
          initialOpen
        >
          {reasons && reasons.length > 0 && (
            <CheckboxGroup
              fieldName="reasons"
              label="Select Reason(s)"
              showCheckAll={reasons.length > 2}
              options={reasons.map((reason) => ({
                label: generateReasonLabel(reason),
                value: reason.id.toString()
              }))}
              disabled={formDisabled || isDefaultAutomation}
              searchPlaceholder="Find a reason"
              searchSize="L"
            />
          )}
        </Drawer>
        <PermissionsGuard requiredPermissions={[Permissions.UPDATE_AUTOMATION]}>
          {!isDefaultAutomation && (
            <>
              <div className={styles.Divider} />
              <ButtonRow>
                <Button type="submit" id="saveJourneyTriggers" disabled={formDisabled}>
                  Save
                </Button>
              </ButtonRow>
            </>
          )}
        </PermissionsGuard>
      </Card>
    </div>
  );
};

export default TriggerFormContent;
