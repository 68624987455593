import React, { useState } from "react";
import cx from "classnames";

import { ChevronDown, ChevronRight, Trash, ChevronUp } from "../../../Icon";
import Button from "../../../Button";
import Heading from "../../../Heading";

import NodeTypeInput from "../NodeInput";
import AddNode from "../AddNode";

import styles from "./index.module.scss";

import { ChatNode, ChatNodeTypes, CustomOnChangeHandler } from "../../../../../types";

type PropsType = {
  data: ChatNode;
  parentId: string;
  typePath: Array<ChatNodeTypes>;
  forceOpen: boolean;
  disabled: boolean;
  onChange: CustomOnChangeHandler;
  onAddNode: (id: string, nodeType: ChatNodeTypes) => void;
  onRemoveNode: (id: string) => void;
  onMoveNode: (parentId: string, nodeId: string, direction: "up" | "down") => void;
};
const getTypeDisplayName = (type: ChatNodeTypes) => {
  if (type === ChatNodeTypes.listItem) {
    return "item";
  }

  return type;
};

const NodeGroup = ({
  data,
  parentId,
  typePath,
  forceOpen,
  disabled,
  onAddNode,
  onRemoveNode,
  onMoveNode,
  onChange
}: PropsType) => {
  const [open, setOpen] = useState(true);
  const isCardLevel = typePath.length === 1;
  const toggleOpen = () => {
    setOpen(!open);
  };

  const isOpen = (forceOpen && !open) || open;
  return (
    <div
      className={cx(styles.Node, {
        [styles.NodeIsCardLevel]: isCardLevel
      })}
    >
      {!isCardLevel && (
        <>
          <button
            className={cx(styles.ToggleOpen)}
            type="button"
            onClick={toggleOpen}
            disabled={forceOpen}
          >
            {isOpen ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
          </button>
          <div className={cx(styles.NodeButtons)}>
            <Button
              inline
              secondary
              className={cx(styles.NodeButtonsDown)}
              onClick={() => {
                onMoveNode(parentId, data.id, "down");
              }}
            >
              <ChevronDown size={16} />
            </Button>
            <Button
              inline
              secondary
              className={cx(styles.NodeButtonsUp)}
              onClick={() => {
                onMoveNode(parentId, data.id, "up");
              }}
            >
              <ChevronUp size={16} />
            </Button>
            <Button
              inline
              secondary
              className={cx(styles.NodeButtonsDelete)}
              onClick={() => {
                onRemoveNode(data.id);
              }}
            >
              <Trash size={16} />
            </Button>
          </div>
          <Heading
            size="META"
            component="h3"
            className={cx({ [styles.NodeTypeNoChildren]: data.type === ChatNodeTypes.listItem })}
          >
            {getTypeDisplayName(data.type)}
          </Heading>
        </>
      )}
      <div
        className={cx(styles.Accordion, {
          [styles.AccordionOpen]: isOpen
        })}
      >
        <NodeTypeInput data={data} typePath={typePath} disabled={disabled} onChange={onChange} />
      </div>
      {data.children && (
        <>
          <div
            className={cx(styles.Children, styles.Accordion, {
              [styles.AccordionOpen]: isOpen
            })}
          >
            {data.children.map((childNode: ChatNode) => {
              return (
                <NodeGroup
                  key={`nodeGroup-${childNode.id}`}
                  parentId={data.id}
                  typePath={[...typePath, childNode.type]}
                  data={childNode}
                  forceOpen={forceOpen}
                  disabled={disabled}
                  onChange={onChange}
                  onAddNode={onAddNode}
                  onRemoveNode={onRemoveNode}
                  onMoveNode={onMoveNode}
                />
              );
            })}
          </div>
          <div
            className={cx(styles.Accordion, {
              [styles.AccordionOpen]: isOpen
            })}
          >
            <AddNode parentId={data.id} typePath={typePath} onAddNode={onAddNode} />
          </div>
        </>
      )}
    </div>
  );
};

export default NodeGroup;
