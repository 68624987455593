import { ReportTemplateCardType } from "../../../../../../../../types";

export const getValidCardTypes = (cardDepth: number): Array<ReportTemplateCardType> | null => {
  if (cardDepth > 1) {
    return [
      ReportTemplateCardType.HEADER,
      ReportTemplateCardType.TEXT,
      ReportTemplateCardType.SUB_REPORT_SECTION
    ];
  }
  if (cardDepth <= 1) {
    return [
      ReportTemplateCardType.HEADER,
      ReportTemplateCardType.TEXT,
      ReportTemplateCardType.SECTION,
      ReportTemplateCardType.SUB_REPORT_SECTION
    ];
  }
  return null;
};
