import React from "react";
import { connect } from "react-redux";

import { FloatModal } from "../../../ui/Modal";
import Heading from "../../../ui/Heading";
import { Form, SelectInput, TextAreaInput, TextInput } from "../../../ui/Input";
import Button from "../../../ui/Button";

import {
  closeModal as closeModalAction,
  addLocation as addLocationAction,
  AddLocationData
} from "../../../../actions";
import { InputValue } from "../../../../types";
import { isEmail, isRequired } from "../../../../utils/validators";
import { timezones } from "../../../../constants";

import styles from "./index.module.scss";

type PropsType = {
  organizationId: number;
  closeModal: () => void;
  addLocation: (data: AddLocationData) => void;
};

type FormState = {
  fullName: string;
  displayName: string;
  street: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  timezone: string;
  email: string;
  telecom: string;
  telecomExtension: string;
  note: string;
};

const initialFormState = {
  fullName: "",
  displayName: "",
  street: "",
  city: "",
  state: "",
  country: "",
  postalCode: "",
  timezone: "",
  email: "",
  telecom: "",
  telecomExtension: "",
  note: ""
};

const timezoneOptions = timezones.map((timezone) => {
  return {
    label: timezone,
    value: timezone
  };
});

const nonRequiredEmailValidator = isEmail("Please enter a valid email");

const formValidator = (values: FormState) => {
  return {
    fullName: isRequired("Please enter a full name")(values.fullName),
    displayName: isRequired("Please enter a display name")(values.displayName),
    street: isRequired("Please enter a street address")(values.street),
    city: isRequired("Please enter a city")(values.city),
    state: isRequired("Please enter a state/province")(values.state),
    country: isRequired("Please enter a country")(values.country),
    postalCode: isRequired("Please enter a postal code")(values.postalCode),
    timezone: isRequired("Please select a timezone")(values.timezone),
    email: nonRequiredEmailValidator(values.email),
    telecom: isRequired("Please enter a phone number")(values.telecom),
    telecomExtension: undefined,
    note: undefined
  };
};

const AddLocation = ({ organizationId, closeModal, addLocation }: PropsType): JSX.Element => {
  const save = async (formValues: FormState) => {
    const data = {
      organizationId,
      fullName: formValues.fullName,
      displayName: formValues.displayName,
      street: formValues.street,
      city: formValues.city,
      state: formValues.state,
      country: formValues.country,
      postalCode: formValues.postalCode,
      timezone: formValues.timezone,
      email: formValues.email,
      telecom: formValues.telecom,
      telecomExtension: formValues.telecomExtension,
      note: formValues.note
    };
    addLocation(data);
    closeModal();
  };

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L" className={styles.Heading}>
        Add New Location
      </Heading>
      <Form
        onSubmit={(formState) => save(formState.values as FormState)}
        initialValues={initialFormState}
        validateFields={(values) => formValidator(values as FormState)}
      >
        <TextInput fieldName="fullName" label="full name" placeholder="Enter full name" />
        <TextInput
          fieldName="displayName"
          label="display name"
          placeholder="Enter display name (for text messages)"
        />
        <TextInput fieldName="street" label="street address" placeholder="Enter street address" />
        <TextInput fieldName="city" label="city" placeholder="Enter city" />
        <TextInput fieldName="state" label="state/province" placeholder="Enter state/province" />
        <TextInput fieldName="country" label="country" placeholder="Enter country" />
        <TextInput fieldName="postalCode" label="postal code" placeholder="Enter postal code" />
        <SelectInput
          fieldName="timezone"
          label="timezone"
          options={timezoneOptions}
          placeholder="Select timezone"
        />
        <TextInput fieldName="email" label="email" placeholder="Enter email" />
        <TextInput fieldName="telecom" label="phone number" placeholder="Enter phone number" />
        <TextInput
          fieldName="telecomExtension"
          label="phone extension"
          placeholder="Enter phone extension"
        />
        {
          // TODO: This needs to be replaced with JsonContentInput
          /* <TextAreaInput
          size="S"
          fieldName="note"
          label="Special Instructions"
          placeholder="Enter special instructions"
        /> */
        }

        <hr className={styles.Divider} />

        <div className={styles.ButtonGroup}>
          <Button inline onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit">Create</Button>
        </div>
      </Form>
    </FloatModal>
  );
};

export default connect(null, {
  closeModal: closeModalAction,
  addLocation: addLocationAction
})(AddLocation);
