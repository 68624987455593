import React from "react";
import { useField } from "informed";

import { CardSection } from "../../../../../ui/Card";

import OrganizationSubscriptions from "./OrganizationSubscriptions";
import ProviderSubscriptions from "./ProviderSubscriptions";
import UnassignedSubscriptions from "./UnassignedSubscriptions";

import { generatePractitionerLabel } from "../../../../../../utils/generatePractitionerLabel";
import { Practitioner, Subscription, CustomChangeEvent } from "../../../../../../types";

export const SUBSCRIPTIONS_NAMESPACE = "_subscriptionsNamespace";

export type SubscriptionFormState = {
  id: string;
  type: "arrears" | "advance" | "unknown";
  lineItems: Subscription["lineItems"];
  userId: string | "unassigned" | "assignedToOrganization";
};

export type SubscriptionsFormState = SubscriptionFormState[];

type SubscriptionsInputType = {
  fieldName?: string;
  practitioners?: Practitioner[];
  chargeOverSubscriptionId?: string;
};

const SubscriptionsInput = ({
  fieldName = "subscriptions",
  practitioners,
  chargeOverSubscriptionId = ""
}: SubscriptionsInputType) => {
  const providerOptions = practitioners?.map((prac) => {
    return {
      label: generatePractitionerLabel(prac),
      value: prac.userId.toString()
    };
  });
  const { fieldState, fieldApi } = useField({ name: fieldName });
  const value = fieldState.value as SubscriptionFormState[];
  const { setValue, setTouched } = fieldApi;

  const onInputChange = (e: CustomChangeEvent) => {
    setTouched(true);
    const inputName = Object.keys(e)[0];
    let changeValue = Object.values(e)[0];
    const subscriptionId: string = inputName.split(".")[1];
    const subscriptionProperty: string = inputName.split(".")[2];

    // Handle userId assignment values: transform _assignToClinic into undefined _unassign to "unassigned"
    if (subscriptionProperty === "userId") {
      if (changeValue === "_assignToClinic") {
        changeValue = "assignedToOrganization";
      }
      if (changeValue === "_unassign") {
        changeValue = "unassigned";
      }
    }

    const newSubscriptions = value.map((sub) => {
      if (sub.id.toString() === subscriptionId) {
        return { ...sub, [subscriptionProperty]: changeValue };
      }

      return sub;
    });

    return setValue(newSubscriptions);
  };

  const organizationSubscriptions: SubscriptionFormState[] = value.filter((sub) => {
    return sub.userId === "assignedToOrganization";
  });
  const providerSubscriptions: SubscriptionFormState[] = value.filter((sub) => {
    return sub.userId !== "assignedToOrganization" && sub.userId !== "unassigned";
  });
  const unassignedSubScriptions: SubscriptionFormState[] = value.filter((sub) => {
    return sub.userId === "unassigned";
  });

  // TODO: enable org level subscription after deprecation of old root config.chargeOverSubscriptionId
  return (
    <>
      <CardSection title="Organization">
        <OrganizationSubscriptions
          subscriptions={organizationSubscriptions}
          onChange={onInputChange}
        />
      </CardSection>
      <CardSection title="Provider">
        <ProviderSubscriptions
          subscriptions={providerSubscriptions}
          providerOptions={providerOptions}
          onChange={onInputChange}
        />
      </CardSection>
      <CardSection title="Unassigned">
        <UnassignedSubscriptions
          subscriptions={unassignedSubScriptions}
          providerOptions={providerOptions}
          onChange={onInputChange}
          chargeOverSubscriptionId={chargeOverSubscriptionId}
        />
      </CardSection>
    </>
  );
};

export default SubscriptionsInput;
