import React from "react";

type PropsType = {
  size?: number;
};

const Insights = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1876 3.51874C10.9058 1.97984 13.0941 1.97984 13.8123 3.51874L15.9343 8.06562L20.4812 10.1877C22.0201 10.9059 22.0201 13.0941 20.4812 13.8123L15.9343 15.9344L13.8123 20.4813C13.0941 22.0202 10.9058 22.0202 10.1876 20.4813L8.06556 15.9344L3.51868 13.8123C1.97978 13.0941 1.97978 10.9059 3.51868 10.1877L8.06556 8.06562L10.1876 3.51874ZM11.9999 4.36456L9.8779 8.91145C9.67924 9.33713 9.33707 9.6793 8.91138 9.87796L4.3645 12L8.91138 14.122C9.33707 14.3207 9.67924 14.6629 9.8779 15.0886L11.9999 19.6354L14.122 15.0886C14.3206 14.6629 14.6628 14.3207 15.0885 14.122L19.6354 12L15.0885 9.87796C14.6628 9.6793 14.3206 9.33713 14.122 8.91145L11.9999 4.36456Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 1C20.5523 1 21 1.44772 21 2V3H22C22.5523 3 23 3.44772 23 4C23 4.55228 22.5523 5 22 5H21V6C21 6.55228 20.5523 7 20 7C19.4477 7 19 6.55228 19 6V5H18C17.4477 5 17 4.55228 17 4C17 3.44772 17.4477 3 18 3H19V2C19 1.44772 19.4477 1 20 1Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Insights;
