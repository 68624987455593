import React from "react";

type PropsType = {
  size?: number;
};

const UploadCloud = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M12.09 11.001l.032.004.032.004a1.003 1.003 0 01.08.015l.03.008a.997.997 0 01.23.093l.023.013a.994.994 0 01.196.153l-.086-.077.004.003.082.074 4 4a1 1 0 01-.531 1.691l-.118.014h-.118a.996.996 0 01-.648-.291l-2.293-2.293v6.586a1 1 0 01-.883.993l-.117.007a1 1 0 01-1-1v-6.586l-2.292 2.293a.995.995 0 01-.531.277l-.118.014h-.118a1 1 0 01-.648-1.705l4-4 .082-.074a1.013 1.013 0 01.09-.064l.012-.007a.994.994 0 01.012-.008l.024-.013a.997.997 0 01.15-.069l.018-.006a.998.998 0 01.09-.026l.026-.005a1.003 1.003 0 01.203-.021L11.92 11l.052-.003h.067l.025.002zM1.02 6.841a9 9 0 0116.466 1.157h.521a6 6 0 012.868 11.268 1 1 0 01-.957-1.756 4 4 0 00-1.912-7.512h-1.26a1 1 0 01-.968-.75 7 7 0 10-12.022 6.388 1 1 0 01-1.499 1.324A9 9 0 011.02 6.841z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default UploadCloud;
