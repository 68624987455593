import React from "react";
import { connect } from "react-redux";

import { FloatModal } from "../../../ui/Modal";
import Heading from "../../../ui/Heading";
import { Form, SelectInput } from "../../../ui/Input";
import Button from "../../../ui/Button";

import {
  closeModal as closeModalAction,
  updateOrgSettings as updateOrgSettingsAction
} from "../../../../actions";
import { isRequired } from "../../../../utils/validators";
import { SettingNames } from "../../../../constants";
import { Service, Setting, ServiceMigrationSetting } from "../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  organizationId: number;
  services: Array<Service>;
  closeModal: () => void;
  updateOrgSettings: (organizationId: string, settings: Array<Setting>) => void;
};

type FormState = {
  oldServiceRecordId: number;
  newServiceRecordId: number;
};

const initialFormState = {
  oldServiceRecordId: -1,
  newServiceRecordId: -1
};

const formValidator = (values: FormState) => {
  return {
    oldServiceRecordId: isRequired("Source service is required")(values.oldServiceRecordId),
    newServiceRecordId: isRequired("Destination service is required")(values.newServiceRecordId)
  };
};

const MigrateService = ({
  organizationId,
  services,
  closeModal,
  updateOrgSettings
}: PropsType): JSX.Element => {
  const save = async (formValues: FormState) => {
    const settingValue: ServiceMigrationSetting = {
      oldServiceRecordId: formValues.oldServiceRecordId,
      newServiceRecordId: formValues.newServiceRecordId
    };
    const data: Setting = {
      organizationId,
      settingName: SettingNames.NUMBER_MIGRATION,
      settingValue
    };
    updateOrgSettings(organizationId.toString(), [data]);
    closeModal();
  };

  const serviceOptions = services.map((service) => {
    return {
      label: service.provider,
      value: service.id
    };
  });

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L" className={styles.Heading}>
        Add New Service
      </Heading>
      <Form
        onSubmit={(formState) => save(formState.values as FormState)}
        initialValues={initialFormState}
        validateFields={(values) => formValidator(values as FormState)}
      >
        <SelectInput
          fieldName="oldServiceRecordId"
          label="Source Service"
          options={serviceOptions}
          placeholder="Select source service"
        />
        <SelectInput
          fieldName="newServiceRecordId"
          label="Destination Service"
          options={serviceOptions}
          placeholder="Select destination service"
        />

        <hr className={styles.Divider} />

        <div className={styles.ButtonGroup}>
          <Button inline onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit">Start Migration</Button>
        </div>
      </Form>
    </FloatModal>
  );
};

export default connect(null, {
  closeModal: closeModalAction,
  updateOrgSettings: updateOrgSettingsAction
})(MigrateService);
