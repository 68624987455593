import React from "react";

type PropsType = {
  size?: number;
};

const StarChat = ({ size = 24 }: PropsType) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={`${size}px`} height={`${size}px`} fill="none">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2 5.5A2.5 2.5 0 0 1 4.5 3h15A2.5 2.5 0 0 1 22 5.5v11a2.5 2.5 0 0 1-2.5 2.5h-4.373a.5.5 0 0 0-.436.255l-.948 1.686c-.764 1.36-2.722 1.36-3.486 0l-.948-1.686A.5.5 0 0 0 8.873 19H4.5A2.5 2.5 0 0 1 2 16.5v-11ZM4.5 5a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h4.373a2.5 2.5 0 0 1 2.179 1.274L12 19.96l.948-1.686A2.5 2.5 0 0 1 15.128 17H19.5a.5.5 0 0 0 .5-.5v-11a.5.5 0 0 0-.5-.5h-15Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M12 6a1 1 0 0 1 .825.435l1.21 1.765 2.052.605a1 1 0 0 1 .51 1.569l-1.305 1.696.059 2.139a1 1 0 0 1-1.335.97L12 14.46l-2.016.717a1 1 0 0 1-1.335-.97l.06-2.138-1.306-1.696a1 1 0 0 1 .51-1.57L9.966 8.2l1.21-1.765A1 1 0 0 1 12 6Zm0 2.769-.586.855a1 1 0 0 1-.542.394l-.994.293.632.82a1 1 0 0 1 .207.638l-.028 1.036.976-.347a1 1 0 0 1 .67 0l.976.347-.028-1.036a1 1 0 0 1 .207-.637l.632-.821-.994-.293a1 1 0 0 1-.542-.394L12 8.769Z"
        clipRule="evenodd"
        fill="currentColor"
      />
    </svg>
  );
};

export default StarChat;
