import React, { useContext, useMemo } from "react";
import { connect } from "react-redux";

import Heading from "../../../ui/Heading";
import { FloatModal } from "../../../ui/Modal";
import Text from "../../../ui/Text";
import Button from "../../../ui/Button";
import { CheckboxInput, Form, TextInput } from "../../../ui/Input";

import {
  closeModal,
  updateUserSettings as updateUserSettingsAction,
  addNotification as addNotificationAction,
  UpdateUserSettingsOptions,
  UpdateUsersSettingsData
} from "../../../../actions";
import { combine, isRequired, hasMaxCharacters } from "../../../../utils/validators";
import { InputValue, NewNotification } from "../../../../types";
import { UserContext } from "../../../providers/UserProvider";

import styles from "./index.module.scss";

type PropsType = {
  selectedWord: string;
  onReplaceWord: (oldWord: string, updatedWord?: string) => void;
  closeModalConnect: () => void;
  updateUserSettings: (
    userId: number,
    updates: UpdateUsersSettingsData,
    options?: UpdateUserSettingsOptions,
    customSuccessMessage?: string
  ) => void;
  addNotification: (notification: NewNotification) => void;
  noteId?: number;
};

type FormState = {
  oldWord: string;
  correctedWord: string;
  shouldReplace: boolean;
};

const selectedWordValidator = isRequired("Please enter the word to correct");
const isMaxThreeWords = () => (value: InputValue) => {
  const numberOfWords = (value ? value.toString() : "").split(" ").length;

  return numberOfWords > 3 ? "Phrase cannot contain more than 3 words" : undefined;
};
const correctedWordValidator = combine([
  isRequired("Please enter the corrected word"),
  hasMaxCharacters(30, "Too long phrase"),
  isMaxThreeWords()
]);

const formValidator = (values: FormState) => {
  return {
    oldWord: selectedWordValidator(values.oldWord),
    correctedWord: correctedWordValidator(values.correctedWord)
  };
};

const AddToVocabulary = ({
  selectedWord,
  onReplaceWord,
  updateUserSettings,
  closeModalConnect,
  addNotification,
  noteId
}: PropsType) => {
  const initialFormState: FormState = useMemo(() => {
    return {
      oldWord: selectedWord,
      correctedWord: "",
      shouldReplace: false
    };
  }, [selectedWord]);
  const {
    userId,
    settings: { features }
  } = useContext(UserContext);

  const save = async (formValues: FormState) => {
    const { oldWord, correctedWord, shouldReplace } = formValues;
    const existingVocabulary = features?.scribe?.customVocabulary || [];
    if (existingVocabulary.indexOf(correctedWord) === -1 && userId && features?.scribe) {
      updateUserSettings(
        userId,
        {
          features: {
            scribe: {
              ...features.scribe,
              customVocabulary: [...existingVocabulary, correctedWord]
            }
          },
          contextData: { noteId }
        },
        undefined,
        "Added to your vocabulary"
      );
    } else {
      addNotification({
        type: "success",
        title: "Success",
        subtitle: "Added to your vocabulary",
        autoDismiss: true
      });
    }
    onReplaceWord(oldWord, shouldReplace ? correctedWord : undefined);

    closeModalConnect();
  };

  const closeWithoutReplace = () => {
    onReplaceWord(selectedWord);
    closeModalConnect();
  };

  return (
    <FloatModal isOpen onClose={closeWithoutReplace} containerClass={styles.ModalContainer}>
      <Heading size="L" className={styles.AddNewWord}>
        Enhance Mika AI
      </Heading>
      <Text className={styles.AddNewWord} size="M">
        Improve Mika AI by adding new words to your personal vocabulary.
      </Text>
      <Form
        onSubmit={(formState) => save(formState.values as FormState)}
        initialValues={initialFormState}
        validateFields={(values) => formValidator(values as FormState)}
      >
        <TextInput fieldName="oldWord" label="Selected Word" validate={selectedWordValidator} />
        <TextInput
          fieldName="correctedWord"
          label="Corrected Word"
          validate={correctedWordValidator}
        />

        <CheckboxInput
          fieldName="shouldReplace"
          label="Find and replace in current document."
          labelClickable
        />

        <hr className={styles.Divider} />

        <div className={styles.ButtonGroup}>
          <Button inline onClick={closeWithoutReplace}>
            Cancel
          </Button>
          <Button id="addToVocabularyBtn" type="submit">
            Save
          </Button>
        </div>
      </Form>
    </FloatModal>
  );
};

export default connect(null, {
  closeModalConnect: closeModal,
  updateUserSettings: updateUserSettingsAction,
  addNotification: addNotificationAction
})(AddToVocabulary);
