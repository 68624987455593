import React, { useEffect } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { v4 as uuid } from "uuid";

import Button from "../../../../../ui/Button";
import { FullScreenModal } from "../../../../../ui/Modal";
import Loader from "../../../../../ui/Loader";
import InstructionsDetailsForm from "./InstructionTableForm";
import { Form } from "../../../../../ui/Input";
import { generateReasonLabel } from "../../../../../../utils/generateReasonLabel";

import {
  fetchInstructionDetails,
  updateInstructions as updateInstructionsAction,
  UpdateInstructionsData,
  openModal as openModalAction,
  OpenModal
} from "../../../../../../actions";

import { InstructionFormState } from "./formValidator";

import {
  Reason,
  InstructionDetailed,
  ReduxStateType,
  Option,
  InstructionWithReasons,
  ChatNodeTypes,
  JsonContent
} from "../../../../../../types";

type PropsType = {
  organizationName: string;
  instruction: InstructionDetailed | null;
  instructions: Array<InstructionWithReasons>;
  instructionsInitialized: boolean;
  reasons: Array<Reason>;
  reasonsInitialized: boolean;
  isModalOpen: boolean;
  loading: boolean;
  closeModal: () => void;
  openModal: OpenModal;
  fetchInstruction: (id: number) => void;
  updateInstruction: (instruction: UpdateInstructionsData, onSuccess?: () => void) => void;
};

const InstructionsDetails = ({
  organizationName,
  instruction,
  reasons,
  reasonsInitialized,
  instructions,
  instructionsInitialized,
  isModalOpen,
  loading,
  closeModal,
  openModal,
  fetchInstruction,
  updateInstruction
}: PropsType) => {
  const parsed = queryString.parse(window.location.search);
  const { instructionId } = parsed;

  const defaultContent: JsonContent = [
    {
      id: uuid(),
      type: ChatNodeTypes.content,
      properties: undefined,
      children: []
    }
  ];

  const instructionWithReasonIds: InstructionWithReasons | undefined =
    instructionId && instructionsInitialized
      ? instructions.find((inst) => inst.id.toString() === instructionId)
      : undefined;

  const existingGroupNames: string[] = instructions.reduce((existing, inst) => {
    if (inst && inst.groupName && !existing.includes(inst.groupName)) {
      existing.push(inst.groupName);
    }
    return existing;
  }, [] as string[]);

  const groupNameOptions: Option[] = existingGroupNames.map((groupName: string) => ({
    label: groupName,
    value: groupName
  }));

  const initialState: InstructionFormState | null =
    instruction && reasonsInitialized && instructionWithReasonIds
      ? {
          id: instruction.id,
          displayName: instruction.displayName,
          description: instruction.description || "",
          groupName: instruction.groupName || "",
          type: instruction.type || "",
          filler: instruction.filler,
          reasons: instructionWithReasonIds.reasonIds.map((id) => id.toString()),
          content: instruction.content.length > 0 ? instruction.content : defaultContent
        }
      : null;

  useEffect(() => {
    if (instructionId && typeof instructionId === "string") {
      const id = Number.parseInt(instructionId, 10);
      fetchInstruction(id);
    }
  }, [instructionId]);

  const save = (formState: InstructionFormState) => {
    if (instruction) {
      updateInstruction({
        id: instruction.id,
        displayName: formState.displayName,
        description: formState.description || "",
        groupName: formState.groupName || "",
        filler: formState.filler,
        type: formState.type || null,
        reasonIds: formState.reasons.map((reasonId) => Number.parseInt(reasonId, 10)),
        content: formState.content
      });
    }
  };

  const reasonOptions: Array<Option> = reasons.map((reason) => ({
    label: generateReasonLabel(reason),
    value: reason.id.toString()
  }));

  const formDisabled = loading;

  if (!initialState || !instructionId || !instruction)
    return (
      <FullScreenModal
        subtitle={`${organizationName} > Edit Instruction`}
        isOpen={isModalOpen}
        onClose={closeModal}
        headerButton={
          <Button type="submit" disabled>
            Save
          </Button>
        }
      >
        <Loader screen />
      </FullScreenModal>
    );

  return (
    <Form
      onSubmit={(formState) => save(formState.values as InstructionFormState)}
      initialValues={initialState}
    >
      <InstructionsDetailsForm
        organizationName={organizationName}
        instruction={instruction}
        reasons={reasons}
        isModalOpen={isModalOpen}
        formDisabled={formDisabled}
        initialState={initialState}
        groupNameOptions={groupNameOptions}
        reasonOptions={reasonOptions}
        closeModal={closeModal}
        openModal={openModal}
      />
    </Form>
  );
};
const mapStateToProps = ({ instructions, organizationData, reasons }: ReduxStateType) => {
  return {
    organizationName: organizationData.organizationData
      ? organizationData.organizationData.displayName
      : "",
    instruction: instructions.instructionDetail.instruction,
    reasons: reasons.data,
    reasonsInitialized: reasons.initialized,
    instructions: instructions.data,
    instructionsInitialized: instructions.initialized,
    loading: instructions.updateLoading
  };
};

export default connect(mapStateToProps, {
  fetchInstruction: fetchInstructionDetails,
  updateInstruction: updateInstructionsAction,
  openModal: openModalAction
})(InstructionsDetails);
