import React, { ReactNode } from "react";

import styles from "./index.module.scss";
import Text from "../Text";
import { ResponsiveHide } from "../Responsive";

type PropsType = {
  children: ReactNode;
};

const PageLayout = ({ children }: PropsType): React.ReactElement => {
  return (
    <div className={styles.Wrapper}>
      <div className={styles.Container}>{children}</div>
      <footer className={styles.Footer}>
        <ResponsiveHide hideOnMobile>
          <Text size="S" component="span">
            {`Copyright © ${new Date().getFullYear()} - Mikata Health Inc.`}
          </Text>
          <Text size="S" component="span">
            <a href="https://mikatahealth.com/privacy_policy" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
          </Text>
        </ResponsiveHide>
      </footer>
    </div>
  );
};

export default PageLayout;
