/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";

import Text from "../../../../../../ui/Text";

import styles from "./index.module.scss";

export type ListItem = { label: string; onClick: () => void };

type PropsType = {
  position?: "bottomLeft" | "bottomRight";
  list: ListItem[];
  icon: React.ReactElement;
};

const MenuList = ({ list, icon, position = "bottomLeft" }: PropsType) => {
  const elementRef = useRef<any>();
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((isOpen) => !isOpen);
  };

  const offMenuListClick = (event: any) => {
    const menulistEl = elementRef.current;
    const isOutsideClick = menulistEl && !menulistEl.contains(event.target);

    if (isOutsideClick) {
      toggle();
      document.removeEventListener("mouseup", offMenuListClick, false);
    }
  };

  const handleOnClick = (item: ListItem) => {
    item.onClick();
    toggle();
  };

  useEffect(() => {
    if (open) {
      // add when mounted
      document.addEventListener("mouseup", offMenuListClick);
      return () => {
        document.removeEventListener("mouseup", offMenuListClick);
      };
    }

    document.removeEventListener("mouseup", offMenuListClick);

    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mouseup", offMenuListClick);
    };
  }, [open]);

  return (
    <div ref={elementRef} className={cx(styles.MenuListWrapper)}>
      <button
        type="button"
        className={cx(styles.MenuListIcon)}
        onClick={() => {
          toggle();
        }}
      >
        {icon}
      </button>
      {open && (
        <div
          className={cx(styles.MenuList, {
            [styles.MenuListOpen]: open,
            [styles.MenuListBottomLeft]: position === "bottomLeft",
            [styles.MenuListBottomRight]: position === "bottomRight"
          })}
        >
          {list.map((item) => (
            <button
              type="button"
              className={styles.MenuListItem}
              key={item.label}
              onClick={() => handleOnClick(item)}
              onKeyPress={(e) => {
                if (e.nativeEvent.keyCode === 13) {
                  handleOnClick(item);
                  toggle();
                }
              }}
            >
              <Text bold size="S" component="span">
                {item.label}
              </Text>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default MenuList;
