import { getOperatorType, isCompositionOperator } from "./index";
import {
  ConditionJSON,
  ConditionValue,
  PLACEHOLDER_OPERATOR,
  INITIAL_CONDITION_VALUE
} from "../types";

export const cleanConditionsRecursively = (
  data: ConditionJSON | ConditionValue
): ConditionJSON | ConditionValue => {
  // handle condition values
  if (Array.isArray(data)) {
    return data
      .filter((item) => !!item)
      .map((item) => {
        return cleanConditionsRecursively(item as ConditionJSON);
      });
  }

  // handle condition
  if (typeof data === "object") {
    const operatorType = getOperatorType(data as ConditionJSON);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newCondition: ConditionJSON | undefined = Object.keys(data).reduce<any>((obj, key) => {
      if (!obj) return obj;
      // eslint-disable-next-line no-param-reassign
      const newConditionValue = cleanConditionsRecursively(data[key]) as ConditionValue;

      if (isCompositionOperator(operatorType)) {
        const filteredConditionValue = newConditionValue.filter(
          (value) => value && getOperatorType(value as ConditionJSON) !== PLACEHOLDER_OPERATOR.value
        );

        if (filteredConditionValue.length === 0) {
          return undefined;
        }

        if (filteredConditionValue.length === 1) {
          // un-nest the child last remaining condition
          return filteredConditionValue[0] as ConditionJSON;
        }
        obj[key] = newConditionValue;
      } else {
        obj[key] = newConditionValue;
      }

      return obj;
    }, {} as ConditionJSON);

    return newCondition || INITIAL_CONDITION_VALUE;
  }

  return data;
};
