import React from "react";

type PropsType = {
  size?: number;
};

const Section = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.913 18.558A2 2 0 0 0 8.912 20.5H18.5a2 2 0 0 0 2-2V8.912a2 2 0 0 0-1.942-2V16.56a2 2 0 0 1-2 2H6.972l-.058-.001Z"
        fill="currentColor"
      />
      <rect x="4.5" y="4.5" width="13.025" height="13.025" rx="1.5" stroke="currentColor" />
    </svg>
  );
};

export default Section;
