import React from "react";

import Heading from "../../../Heading";
import Text from "../../../Text";
import Image from "../../../Image";

const ScribeFeedback = (): React.ReactElement => {
  return (
    <div>
      <Heading size="M" component="h3" darkMode>
        Need help?
      </Heading>
      <Text size="S" darkMode>
        Contact us any time with your questions, concerns, and ideas.
      </Text>
      <br />
      <Text size="S" darkMode>
        We’ll get back to you right away.
      </Text>
      <br />
      <Image src="/img/announcements/needHelp.png" />
    </div>
  );
};

export default ScribeFeedback;
