import { FETCH_LOCATIONS, UPDATE_LOCATION, ADD_LOCATION } from "../constants";

import {
  AddLocationAction,
  FetchLocationsAction,
  UpdateLocationAction
} from "../actions/locations";

import { Location, ActionStatus } from "../types";

export type LocationsAction = FetchLocationsAction | UpdateLocationAction | AddLocationAction;

export type LocationsReduxState = {
  data: Array<Location>;
  locationsFetchLoading: boolean;
  locationsUpdateLoading: boolean;
  locationsAddLoading: boolean;
};

const initialLocationsState: LocationsReduxState = {
  data: [],
  locationsFetchLoading: false,
  locationsUpdateLoading: false,
  locationsAddLoading: false
};

export const locationsReducer = (
  state = initialLocationsState,
  action: LocationsAction
): LocationsReduxState => {
  const { type } = action;

  switch (type) {
    case FETCH_LOCATIONS: {
      const { payload, status } = action as FetchLocationsAction;
      return {
        ...state,
        data: payload ? payload.data : [],
        locationsFetchLoading: status === ActionStatus.loading
      };
    }
    case UPDATE_LOCATION: {
      const { status } = action as UpdateLocationAction;
      return {
        ...state,
        locationsUpdateLoading: status === ActionStatus.loading
      };
    }
    case ADD_LOCATION: {
      const { status } = action as AddLocationAction;
      return {
        ...state,
        locationsAddLoading: status === ActionStatus.loading
      };
    }

    default:
      return state;
  }
};
