import React from "react";

import { MenuHamburger } from "../../../../../../../ui/Icon";

import styles from "./index.module.scss";

interface PropsType {
  openMobileDrawer: (mobileDrawerOpen: boolean) => void;
}

const MobileMenu = ({ openMobileDrawer }: PropsType) => {
  return (
    <div className={styles.MobileMenu} onClick={() => openMobileDrawer(true)}>
      <MenuHamburger />
    </div>
  );
};

export default MobileMenu;
