import { UserRoleConstants } from "../constants";

export const userRoleDisplayMap = (role: string): string => {
  switch (role) {
    case UserRoleConstants.GENERAL:
      return "Basic";
    case UserRoleConstants.GENERAL_UPLOAD:
      return "Basic + Upload";
    case UserRoleConstants.MANAGER:
      return "Admin";
    case UserRoleConstants.PROVIDER:
      return "Provider";
    case UserRoleConstants.PROVIDER_MANAGER:
      return "Provider Manager";
    default:
      return "";
  }
};
