import React from "react";

type PropsType = {
  size?: number;
};

const Inbox = ({ size = 24 }: PropsType) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={`${size}px`}
      height={`${size}px`}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16.7 3a3 3 0 012.744 1.784l3.47 7.81.008.019a.993.993 0 01.033.088l-.041-.107A1 1 0 0123 13v5a3 3 0 01-3 3H4a3 3 0 01-3-3v-5l.007-.117.001-.013L1 13a1 1 0 01.078-.387l.008-.019 3.47-7.81A3 3 0 017.3 3h9.4zM3 18a1 1 0 001 1h16a1 1 0 001-1v-4h-4.465l-1.703 2.555A1 1 0 0114 17h-4a1 1 0 01-.832-.445L7.465 14H3v4zM16.7 5H7.3a1 1 0 00-.916.595L3.538 12H8a1 1 0 01.757.346l.075.1L10.535 15h2.93l1.703-2.555A1 1 0 0116 12h4.461l-2.845-6.405A1 1 0 0016.7 5z"
        id="a"
      />
    </svg>
  );
};

export default Inbox;
