import axios from "axios";
import {
  APPOINTMENT_REQUESTS,
  UPDATE_APPOINTMENT_REQUEST,
  FETCH_APPOINTMENT_REQUESTS_DETAIL,
  CLEAR_APPOINTMENT_REQUESTS_DETAIL,
  BOOK_APPOINTMENT,
  FETCH_BOOKING_CONFIGURATION,
  ROOT_URL,
  GET_APPOINTMENT_REQUEST_HISTORY,
  CLEAR_APPOINTMENT_REQUEST_HISTORY
} from "../constants";
import {
  ActionStatus,
  AppointmentRequest,
  AppointmentRequestDetail,
  Dispatch,
  AvailableSlot,
  Patient,
  Reason,
  BookingReason,
  ScheduleTag,
  ChatFlowsNodes,
  HistoryEvent
} from "../types";

import getToken from "../utils/getToken";

import { addNotification } from "./notifications";
import { closeModal } from "./modals";

export type FetchAppointmentRequestsData = {
  activeRequestFilter: string;
  assignedStaffUserIds: string;
  requestSortBy: string;
  requestSortDirection: string;
  requestCurrentPage: string;
  requestPageSize: string;
};

export type FetchAppointmentRequestsOptions = {
  silent?: boolean;
};

export type FetchAppointmentRequestsAction = {
  type: typeof APPOINTMENT_REQUESTS;
  status: ActionStatus;
  payload?: {
    data: {
      appointmentRequests: AppointmentRequest[];
      allRequestsCount: number;
      readyRequestsCount: number;
      archivedRequestsCount: number;
    };
  };
};
export const fetchAppointmentRequests =
  async (params: FetchAppointmentRequestsData, options?: FetchAppointmentRequestsOptions) =>
  async (dispatch: Dispatch) => {
    const isSilentFetch = options?.silent;
    const config = {
      headers: { Authorization: `${getToken()}`, "Content-Type": "application/json" }
    };
    const handleError = () => {
      dispatch(
        addNotification({
          type: "error",
          title: "Error fetching appointment requests",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: APPOINTMENT_REQUESTS,
        status: ActionStatus.error
      });
    };

    try {
      if (!isSilentFetch) {
        dispatch({
          type: APPOINTMENT_REQUESTS,
          status: ActionStatus.loading
        });
      }

      const payload = await axios.get(
        `${ROOT_URL}/requests/appointments?activeRequestFilter=${params.activeRequestFilter}&requestSortBy=${params.requestSortBy}&requestSortDirection=${params.requestSortDirection}&requestCurrentPage=${params.requestCurrentPage}&requestPageSize=${params.requestPageSize}&assignedStaffUserIds=${params.assignedStaffUserIds}`,
        config
      );

      if (payload.status !== 200) return handleError();

      return dispatch({
        type: APPOINTMENT_REQUESTS,
        status: ActionStatus.success,
        payload
      });
    } catch (error) {
      return handleError();
    }
  };

export type FetchAppointmentRequestsDetailAction = {
  type: typeof FETCH_APPOINTMENT_REQUESTS_DETAIL;
  status?: ActionStatus;
  payload?: AppointmentRequestDetail;
};

export type FetchAppointmentRequestsDetailOptions = {
  silent?: boolean;
};

export const fetchAppointmentRequestsDetail =
  (appointmentRequestId: number, options?: FetchAppointmentRequestsDetailOptions) =>
  async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };
    const isSilentFetch = options?.silent;

    const handleError = () => {
      dispatch(
        addNotification({
          type: "error",
          title: "Error fetching appointment request details",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: FETCH_APPOINTMENT_REQUESTS_DETAIL,
        status: ActionStatus.error
      });
    };

    dispatch({
      type: FETCH_APPOINTMENT_REQUESTS_DETAIL,
      status: isSilentFetch ? ActionStatus.silentLoading : ActionStatus.loading
    });

    // Try and load appointment requests
    try {
      const response = await axios.get(
        `${ROOT_URL}/requests/appointments/${appointmentRequestId}`,
        config
      );
      if (response.status !== 200) return handleError();

      return dispatch({
        type: FETCH_APPOINTMENT_REQUESTS_DETAIL,
        status: ActionStatus.success,
        payload: response.data
      });
    } catch (error) {
      return handleError();
    }
  };

export type ClearAppointmentRequestsDetailAction = {
  type: typeof CLEAR_APPOINTMENT_REQUESTS_DETAIL;
};

export const clearAppointmentRequestsDetail = () => ({
  type: CLEAR_APPOINTMENT_REQUESTS_DETAIL
});

export type UpdateAppointmentRequestsAction = {
  type: typeof UPDATE_APPOINTMENT_REQUEST;
  status: ActionStatus;
  requestId: number;
  change: UpdateAppointmentRequestData;
  payload?: { data: { success: boolean; updatedAppointmentRequest: AppointmentRequest } };
  onSuccess?: () => void;
};

export type UpdateAppointmentRequestData = {
  assignedStaffUserId?: string | null;
  status?: string;
};

export const updateAppointmentRequest =
  async (requestId: number, data: UpdateAppointmentRequestData, onSuccess?: () => void) =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: { Authorization: getToken(), "Content-Type": "application/json" }
    };
    const handleError = () => {
      dispatch(
        addNotification({
          type: "error",
          title: "Error updating appointment request",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: UPDATE_APPOINTMENT_REQUEST,
        status: ActionStatus.error,
        requestId,
        change: data
      });
    };

    try {
      dispatch({
        type: UPDATE_APPOINTMENT_REQUEST,
        status: ActionStatus.loading,
        requestId,
        change: data
      });

      const payload = await axios.patch(
        `${ROOT_URL}/requests/appointments/${requestId}`,
        data,
        config
      );

      if (payload.status !== 200) return handleError();

      dispatch({
        type: UPDATE_APPOINTMENT_REQUEST,
        status: ActionStatus.success,
        payload,
        requestId,
        change: data
      });

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      return handleError();
    }
  };

export type BookAppointmentAction = {
  type: typeof BOOK_APPOINTMENT;
  status: ActionStatus;
  payload?: { success: boolean; emrAppointmentId: string };
};

export type BookAppointmentData = {
  appointmentRequestId: number;
  patient: Patient;
  reason: Reason;
  availableSlot: AvailableSlot;
};

export const bookAppointment =
  (data: BookAppointmentData, onSuccess?: () => void) => async (dispatch: Dispatch) => {
    const config = {
      headers: { Authorization: getToken(), "Content-Type": "application/json" }
    };
    const handleError = () => {
      dispatch(
        addNotification({
          type: "error",
          title: "Error updating appointment request",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: BOOK_APPOINTMENT,
        status: ActionStatus.error
      });
    };

    try {
      dispatch({
        type: BOOK_APPOINTMENT,
        status: ActionStatus.loading
      });

      const response = await axios.post(
        `${ROOT_URL}/requests/bookings/book-appointment`,
        data,
        config
      );

      if (response.status !== 200) return handleError();

      dispatch(
        addNotification({
          type: "success",
          title: "Success",
          subtitle: "Appointment created",
          autoDismiss: true
        })
      );
      dispatch({
        type: BOOK_APPOINTMENT,
        status: ActionStatus.success,
        payload: response.data
      });

      closeModal()(dispatch);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      return handleError();
    }
  };

export type FetchBookingConfigurationAction = {
  type: typeof FETCH_BOOKING_CONFIGURATION;
  status: ActionStatus;
  payload?: {
    bookingReasons: BookingReason[];
    schedules: ScheduleTag[];
    bookingChatNodes: ChatFlowsNodes[];
  };
};

export const fetchBookingConfiguration = () => async (dispatch: Dispatch) => {
  const config = {
    headers: { Authorization: getToken(), "Content-Type": "application/json" }
  };

  const handleError = () => {
    dispatch(
      addNotification({
        type: "error",
        title: "Error to fetch booking configuration",
        subtitle: "Please try again",
        autoDismiss: true
      })
    );
    return dispatch({
      type: FETCH_BOOKING_CONFIGURATION,
      status: ActionStatus.error
    });
  };

  try {
    dispatch({
      type: FETCH_BOOKING_CONFIGURATION,
      status: ActionStatus.loading
    });

    const response = await axios.get(`${ROOT_URL}/requests/bookings/configuration`, config);

    if (response.status !== 200) return handleError();

    return dispatch({
      type: FETCH_BOOKING_CONFIGURATION,
      status: ActionStatus.success,
      payload: response.data
    });
  } catch (error) {
    return handleError();
  }
};

export type GetAppointmentRequestHistoryAction = {
  type: typeof GET_APPOINTMENT_REQUEST_HISTORY;
  status: ActionStatus;
  payload?: HistoryEvent[];
};

export const getAppointmentRequestHistory = (requestId: number) => async (dispatch: Dispatch) => {
  const config = {
    headers: { Authorization: getToken(), "Content-Type": "application/json" }
  };

  dispatch({
    type: GET_APPOINTMENT_REQUEST_HISTORY,
    status: ActionStatus.loading
  });

  try {
    const payload = await axios.get(
      `${ROOT_URL}/requests/appointments/${requestId}/history`,
      config
    );
    return dispatch({
      type: GET_APPOINTMENT_REQUEST_HISTORY,
      status: ActionStatus.success,
      payload: payload?.data?.appointmentRequestHistory
    });
  } catch (error) {
    return dispatch({
      type: GET_APPOINTMENT_REQUEST_HISTORY,
      status: ActionStatus.error
    });
  }
};

export type ClearAppointmentRequestHistoryAction = {
  type: typeof CLEAR_APPOINTMENT_REQUEST_HISTORY;
};

export const clearAppointmentRequestHistory = () => ({
  type: CLEAR_APPOINTMENT_REQUEST_HISTORY
});
