import React from "react";
import cx from "classnames";

import Text from "../../Text";

import styles from "./index.module.scss";

type FilterOption = { label: string; value: string; count?: number };

type PropsType = {
  selectedFilter: string;
  onFilterChange?: (filter: string) => void;
  filterOptions: FilterOption[];
  className?: string;
  filterOptionClassName?: string;
  filterBadgeClassName?: string;
  showMissingCounts?: boolean;
};

const Filters = ({
  selectedFilter,
  onFilterChange,
  filterOptions,
  className,
  filterOptionClassName,
  filterBadgeClassName,
  showMissingCounts = true
}: PropsType) => {
  return (
    <div className={cx(styles.FilterList, className)}>
      {filterOptions.map((filterOption) => {
        return (
          <button
            id={`filter-${filterOption.value}`}
            key={filterOption.value}
            type="button"
            className={cx(styles.Filter, filterOptionClassName)}
            onClick={onFilterChange ? () => onFilterChange(filterOption.value) : undefined}
          >
            <Text
              align="left"
              component="span"
              size="S"
              bold
              className={cx(styles.FilterLabel, {
                [styles.FilterLabelActive]: selectedFilter === filterOption.value
              })}
            >
              {filterOption.label}
            </Text>
            {((showMissingCounts && !filterOption.count) || filterOption.count) && (
              <span className={styles.FilterBadgeWrapper}>
                <div
                  className={cx(styles.FilterBadge, filterBadgeClassName, {
                    [styles.FilterBadgeActive]: selectedFilter === filterOption.value
                  })}
                >
                  {filterOption.count || "-"}
                </div>
              </span>
            )}
          </button>
        );
      })}
    </div>
  );
};

export default Filters;
