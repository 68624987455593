import React from "react";

type PropsType = {
  size?: number;
  color?: string;
};

const Underline = ({ size = 24, color = "currentColor" }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M8 17V6a1 1 0 0 1 2 0v11a4 4 0 0 0 8 0V6a1 1 0 0 1 2 0v11a6 6 0 0 1-12 0Z"
        fill={color}
      />
      <path
        d="M14 23a6 6 0 0 1-6-6V6a1 1 0 0 1 2 0v11a4 4 0 0 0 8 0V6a1 1 0 0 1 2 0v11a6 6 0 0 1-6 6Z"
        fill={color}
      />
      <path d="M7 24h14a1 1 0 0 1 1 1 1 1 0 0 1-1 1H7a1 1 0 0 1-1-1 1 1 0 0 1 1-1Z" fill={color} />
    </svg>
  );
};

export default Underline;
