import React, { useMemo } from "react";
import { connect } from "react-redux";
import QRCode from "react-qr-code";

import Card from "../../ui/Card";
import Button from "../../ui/Button";
import Text from "../../ui/Text";
import Heading from "../../ui/Heading";
import Loader from "../../ui/Loader";
import { Form, TextAreaInput } from "../../ui/Input";
import { Clipboard } from "../../../components/ui/Icon";

import { PATIENT_APP_URL } from "../../../constants";
import { ReduxStateType, OrganizationData } from "../../../types";
import copyToClipboard from "../../../utils/copyToClipboard";

import styles from "./index.module.scss";

type PropsType = {
  organizationData?: OrganizationData;
};

const AppointmentRequestsConfig = ({ organizationData }: PropsType): React.ReactElement => {
  const { token, secondaryColour } = organizationData as OrganizationData;

  const chatUrlWithoutToken = `${PATIENT_APP_URL}`;
  const chatUrlWithToken = `${PATIENT_APP_URL}/${token}`;

  const snippet: string = useMemo(
    () => `
    <script>
      var isIE = (navigator.userAgent.indexOf("MSIE ") > 0 || navigator.userAgent.indexOf("Trident") > 0);

      if (!isIE) {

        (function(w,d,u,n){w.mikataPointer=n;
          w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)}; 
        var s=d.createElement('script');s.type='text/javascript';s.async=!0;s.defer=!0;s.src=u;
        d.getElementsByTagName('head')[0].appendChild(s)})
        (window,document,'https://scripts.mikatahealth.com/embed-chat.js','Mikata');
    
        Mikata('start', {
          bgColor: '${secondaryColour}',
          chatUrl: '${chatUrlWithoutToken}',
          orgToken: '${token}',
          textColor: '#FFF', 
          buttonText: 'Request Appointment'
        });
      }
    </script>`,
    [token, secondaryColour]
  );

  const generateCardHeader = (headerText: string, contentType: string): React.ReactElement => {
    return (
      <div className={styles.CardHeader}>
        <Heading className={styles.CardHeaderTitle} size="S" bold component="div">
          {headerText}
        </Heading>
        <Button
          inline
          onClick={() => copyToClipboard(contentType === "link" ? chatUrlWithToken : snippet)}
        >
          <Text className={styles.CopyButtonText} size="S">
            {`Copy ${contentType}`}
          </Text>
          <Clipboard size={20} />
        </Button>
      </div>
    );
  };

  return (
    <>
      {!organizationData && <Loader screen />}
      {organizationData && (
        <>
          <Card headerContent={generateCardHeader("Static Link", "link")}>
            <Text className={styles.ContentHeader} size="S">
              This link will bring you directly to your clinic’s booking request chat.
            </Text>
            <Text className={styles.ChatUrlText}>{chatUrlWithToken}</Text>
            <br />
            <Text>
              Below is a QR Code for the above link to your clinic’s booking request chat.
            </Text>
            <div className={styles.QRCodeIcon}>
              <QRCode value={chatUrlWithToken} />
            </div>
          </Card>
          <Card headerContent={generateCardHeader("Embedded Code Snippet", "snippet")}>
            <Text className={styles.ContentHeader} size="S">
              Copy and paste the snippet below and send it to your website manager to add to the
              page(s) HTML.
            </Text>
            <Form initialValues={{ code: snippet }}>
              <TextAreaInput size="L" fieldName="code" disabled />
            </Form>
          </Card>
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ organizationData }: ReduxStateType) => {
  return {
    organizationData: organizationData?.organizationData
  };
};

export default connect(mapStateToProps, undefined)(AppointmentRequestsConfig);
