import React, { useMemo } from "react";
import { connect } from "react-redux";

import Heading from "../../../../ui/Heading";
import Text from "../../../../ui/Text";
import Status from "../../../../ui/Status";
import Card from "../../../../ui/Card";

import formatDate from "../../../../../utils/formatDate";

import {
  AutomationDetails,
  StatusComponentConfigMap,
  Location,
  Practitioner,
  Reason,
  AutomationActionRangeTime,
  AutomationActionRangeTimeType,
  AutomationActionRangeTimeTriggerUnit,
  ReduxStateType,
  TouchpointSettingRangeTimeUnit,
  MessageScheduleEvent
} from "../../../../../types";

import styles from "./journeySummaryCard.module.scss";
import RestrictTouchpointSummary from "./RestrictTouchpointSummary";
import { MessageConfigurationTopics } from "../../../../../constants";
import { getTriggerUnitToMinutesFactor } from "../JourneyTimeline/helpers";
import Tooltip from "../../../../ui/Tooltip";
import { Info } from "../../../../ui/Icon";

type PropsType = {
  automation?: AutomationDetails;
  locations: Array<Location>;
};

const publishedConfigMap: StatusComponentConfigMap = {
  published: "green",
  draft: "gray"
};

const isDefaultOptions = [
  { label: "Published", value: "published" },
  { label: "Draft", value: "draft" }
];

const summarizeTriggers = (
  triggers:
    | AutomationDetails["locations"]
    | AutomationDetails["reasons"]
    | AutomationDetails["practitioners"],
  limit: number
) => {
  if (!triggers) return "";

  if (triggers.length === 0) return "None";

  const triggersToShow = triggers.slice(0, limit).map(
    (trigger: Location | Practitioner | Reason) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (trigger as any)?.displayName || (trigger as any)?.fullName || (trigger as any)?.value || ""
  );

  return `${triggersToShow.join(", ")}${triggers.length > triggersToShow.length ? " ..." : ""}`;
};

type LocationOverrideSummaryPropsType = {
  settings: AutomationDetails["settings"];
  locations: Array<Location>;
};

const LocationOverride = ({ settings, locations }: LocationOverrideSummaryPropsType) => {
  if (!settings) return null;

  const locationOverRide = settings.locationId
    ? locations.find((location) => location.id === settings.locationId)?.displayName
    : "";

  return <Text>{locationOverRide || "None"}</Text>;
};

type SettingSummaryPropsType = {
  settings: AutomationDetails["settings"];
};

const SettingsSummary = ({ settings }: SettingSummaryPropsType) => {
  if (!settings) return null;

  const visibleHubDetails: string[] = [];
  if (settings.showPatients) {
    visibleHubDetails.push("Patient First Name");
  }
  if (!settings.hideAddress) {
    visibleHubDetails.push("Address");
  }
  if (!settings.hideDate) {
    visibleHubDetails.push("Date");
  }
  if (!settings.hideTime) {
    visibleHubDetails.push("Time");
  }
  if (!settings.hidePhoneNumber) {
    visibleHubDetails.push("Phone");
  }

  return <Text>{visibleHubDetails.length > 0 ? visibleHubDetails.join(", ") : "None"}</Text>;
};

const unitAbbreviation = {
  [AutomationActionRangeTimeTriggerUnit.DAYS]: "d",
  [AutomationActionRangeTimeTriggerUnit.HOURS]: "h",
  [AutomationActionRangeTimeTriggerUnit.MINUTES]: "m"
};

const rangeTimeText = (rangeTime: AutomationActionRangeTime) => {
  switch (rangeTime.type) {
    case AutomationActionRangeTimeType.BOOKING:
      return "Upon booking";
    case AutomationActionRangeTimeType.UPON_APPOINTMENT_START:
      return "Upon start time";

    case AutomationActionRangeTimeType.BEFORE_APPOINTMENT:
      return `Before ${rangeTime.value}${
        unitAbbreviation[rangeTime.unit as AutomationActionRangeTimeTriggerUnit]
      }`;

    case AutomationActionRangeTimeType.AFTER_APPOINTMENT:
      return `After ${rangeTime.value}${
        unitAbbreviation[rangeTime.unit as AutomationActionRangeTimeTriggerUnit]
      }`;
    default:
      return "";
  }
};

const actionSettingsSummaryText = (
  rangeStart: AutomationActionRangeTime,
  rangeEnd: AutomationActionRangeTime
) => {
  return `${rangeTimeText(rangeStart)} to ${rangeTimeText(rangeEnd)}`;
};

const ActionSettingsSummary = ({ settings }: SettingSummaryPropsType) => {
  if (!settings) return null;
  if (!settings.actions) return <Text>Default</Text>;
  const confirmActive = settings.actions?.confirm?.active;
  const rescheduleActive = settings.actions?.reschedule?.active;
  const checkinActive = settings.actions?.checkin?.active;

  return (
    <>
      {confirmActive && (
        <div className={styles.ActionSummaryGridRow}>
          <Text>Confirm:</Text>
          <Text>
            {settings.actions?.confirm &&
              actionSettingsSummaryText(
                settings.actions.confirm.rangeStart,
                settings.actions.confirm.rangeEnd
              )}
          </Text>
        </div>
      )}
      {rescheduleActive && (
        <div className={styles.ActionSummaryGridRow}>
          <Text>Reschedule:</Text>
          <Text>
            {settings.actions?.reschedule &&
              actionSettingsSummaryText(
                settings.actions.reschedule.rangeStart,
                settings.actions.reschedule.rangeEnd
              )}
          </Text>
        </div>
      )}
      {checkinActive && (
        <div className={styles.ActionSummaryGridRow}>
          <Text>Check-in:</Text>
          <Text>
            {settings.actions?.checkin &&
              actionSettingsSummaryText(
                settings.actions.checkin.rangeStart,
                settings.actions.checkin.rangeEnd
              )}
          </Text>
        </div>
      )}
      {!confirmActive && !rescheduleActive && !checkinActive && <Text>None</Text>}
    </>
  );
};

const JourneySummaryCard = ({ automation, locations }: PropsType) => {
  if (!automation) return null;

  const settings = useMemo(() => {
    return automation.settings;
  }, [automation.settings]);

  const restrictedMessageConfigurations = useMemo(() => {
    return automation.messageConfigurations
      .filter(
        (messageConfiguration) =>
          !!messageConfiguration.settings?.exclusionHoursBeforeStart ||
          !!messageConfiguration.settings?.exclude
      )
      .sort((a, b) => {
        if (a.topic === MessageConfigurationTopics.BOOKED) return -1;

        const aTriggerValueFactor = getTriggerUnitToMinutesFactor(a.scheduleData.triggerUnit);
        const aOffsetMinutes = a.scheduleData.triggerValue * aTriggerValueFactor;
        const bTriggerValueFactor = getTriggerUnitToMinutesFactor(b.scheduleData.triggerUnit);
        const bOffsetMinutes = b.scheduleData.triggerValue * bTriggerValueFactor;

        if (aOffsetMinutes > bOffsetMinutes) return 1;
        if (aOffsetMinutes < bOffsetMinutes) return -1;
        return 0;
      });
  }, [automation.messageConfigurations]);

  return (
    <Card>
      <Heading>{automation.displayName}</Heading>
      <Text className={styles.Description}>{automation.description}</Text>
      <div className={styles.Divider} />
      {automation && (
        <>
          <div className={styles.Row}>
            <div className={styles.LabelInfoGroup}>
              <Heading size="META">Status</Heading>
              <Status
                value={automation.status}
                options={isDefaultOptions}
                configMap={publishedConfigMap}
              />
            </div>
          </div>
          <div className={styles.Row}>
            <div className={styles.LabelInfoGroup}>
              <Heading size="META">Created</Heading>
              <Text>{formatDate(automation.createdAt)}</Text>
            </div>
            <div className={styles.LabelInfoGroup}>
              <Heading size="META">Updated</Heading>
              <Text>{formatDate(automation.updatedAt)}</Text>
            </div>
          </div>
          <div className={styles.Row}>
            <div className={styles.LabelInfoGroup}>
              <div className={styles.HeadingWithTooltip}>
                <Heading size="META">Location Override</Heading>
                <Tooltip
                  icon={
                    <div className={styles.Icon}>
                      <Info size={15} />
                    </div>
                  }
                  position="topRight"
                >
                  Override information from EMR with other location information
                </Tooltip>
              </div>
              <LocationOverride settings={automation.settings} locations={locations} />
            </div>
          </div>
          <div className={styles.Row}>
            <div className={styles.LabelInfoGroup}>
              <Heading size="META">Visible Details</Heading>
              <SettingsSummary settings={automation.settings} />
            </div>
          </div>
          <div className={styles.Row}>
            <div className={styles.LabelInfoGroup}>
              <Heading size="META">
                Location Triggers ({automation?.locations?.length || 0})
              </Heading>
              <Text>{summarizeTriggers(automation?.locations, 9)}</Text>
            </div>
          </div>
          <div className={styles.Row}>
            <div className={styles.LabelInfoGroup}>
              <Heading size="META">
                Provider Triggers ({automation?.practitioners?.length || 0})
              </Heading>
              <Text>{summarizeTriggers(automation?.practitioners, 9)}</Text>
            </div>
          </div>
          <div className={styles.Row}>
            <div className={styles.LabelInfoGroup}>
              <Heading size="META">Reason Triggers ({automation?.reasons?.length || 0})</Heading>
              <Text>{summarizeTriggers(automation?.reasons, 9)}</Text>
            </div>
          </div>
          <div className={styles.Row}>
            <div className={styles.LabelInfoGroup}>
              <Heading size="META">Actions</Heading>
              <ActionSettingsSummary settings={settings} />
            </div>
          </div>
          {restrictedMessageConfigurations.length > 0 && (
            <>
              <div className={styles.Divider} />
              <div className={styles.Row}>
                <div className={styles.LabelInfoGroup}>
                  <Heading size="S">Restrictions</Heading>
                  {restrictedMessageConfigurations.map((messageConfiguration) => {
                    if (messageConfiguration.settings?.exclusionHoursBeforeStart) {
                      let heading = "Upon Booking";

                      if (
                        messageConfiguration.scheduleData.event === MessageScheduleEvent.CANCELLED
                      ) {
                        heading = "Upon Cancelation";
                      }

                      return (
                        <div key={`restrict-summary-${messageConfiguration.id}`}>
                          <RestrictTouchpointSummary
                            heading={heading}
                            exclusionHoursOption="Appointment is within"
                            exclusionHours={messageConfiguration.settings.exclusionHoursBeforeStart}
                            exclusionHoursUnit={TouchpointSettingRangeTimeUnit.HOURS}
                          />
                        </div>
                      );
                    }
                    if (messageConfiguration.settings?.exclude?.patientHasOtherAppt) {
                      return (
                        <div key={`restrict-summary-${messageConfiguration.id}`}>
                          <RestrictTouchpointSummary
                            heading={`${messageConfiguration.scheduleData.triggerValue} ${messageConfiguration.scheduleData.triggerUnit} After Appointment`}
                            excludesApptsWhen="Patient has another appointment"
                            settings={messageConfiguration.settings}
                            reasonIds={messageConfiguration.settings.exclude.patientHasOtherAppt.reasonIds.map(
                              (reasonId) => {
                                return reasonId.toString();
                              }
                            )}
                          />
                        </div>
                      );
                    }

                    return null;
                  })}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </Card>
  );
};

const mapStateToProps = ({ locations }: ReduxStateType) => {
  return {
    locations: locations.data
  };
};

export default connect(mapStateToProps)(JourneySummaryCard);
