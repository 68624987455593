import React, { useEffect } from "react";
import { connect } from "react-redux";

import { FloatModal } from "../../../ui/Modal";
import Heading from "../../../ui/Heading";
import { Form } from "../../../ui/Input";
import Loader from "../../../ui/Loader";
import FormContent from "./FormContent";

import {
  addMessageTemplate,
  addMessageTemplateOptions,
  closeModal as closeModalAction,
  fetchMessageTemplateDetail,
  updateMessageTemplate
} from "../../../../actions";

import {
  formValidator,
  MessageTemplateFormState
} from "../../../../utils/validators/editMessageTemplateFormValidator";

import { MessageTopics } from "../../../../constants";

import {
  MessageTemplateAddData,
  MessageTemplateUpdateData,
  MessageTemplate,
  ReduxStateType
} from "../../../../types";
import { isSmsFriendly } from "../../../../utils/validators";

type PropsType = {
  automationId: number;
  messageTemplateId: number;
  messageTemplate: MessageTemplate | null;
  addLoading?: boolean;
  editLoading?: boolean;
  closeModal: () => void;
  addMessage: (data: MessageTemplateAddData, options?: addMessageTemplateOptions) => void;
  fetchMessage: (id: number) => void;
  updateMessage: (id: number, data: MessageTemplateUpdateData) => void;
  onSuccess?: (templateId: number) => void;
};

const EditMessageTemplate = ({
  automationId,
  messageTemplateId,
  messageTemplate,
  addLoading,
  editLoading,
  closeModal,
  addMessage,
  fetchMessage,
  updateMessage,
  onSuccess
}: PropsType) => {
  const payloadContentWarning = isSmsFriendly();

  const impactedJourneys = messageTemplate?.journeys?.filter(
    (journey) => journey.id !== automationId
  );
  const impactedJourneyNames = [
    ...new Set(impactedJourneys?.map((journey) => journey.displayName))
  ].join(", ");

  useEffect(() => {
    if (messageTemplateId) {
      fetchMessage(messageTemplateId);
    }
  }, [messageTemplateId]);

  const initialState: MessageTemplateFormState | null = messageTemplate
    ? { ...messageTemplate, saveAsNew: [] }
    : messageTemplate;

  const save = (formState: MessageTemplateFormState) => {
    if (formState.saveAsNew?.length) {
      const createData: MessageTemplateAddData = {
        displayName: formState.displayName,
        topic: formState.topic || MessageTopics.SCHEDULE_BASED_MESSAGE,
        payloadContent: formState.payloadContent,
        medium: formState.medium,
        subject: formState.subject
      };
      addMessage(createData, { onSuccess });
    } else {
      const updateData = {
        id: messageTemplateId,
        displayName: formState.displayName,
        payloadContent: formState.payloadContent,
        chatFlowId: formState.chatFlowId || null,
        topic: formState.topic,
        medium: formState.medium,
        subject: formState.subject
      };
      updateMessage(messageTemplateId, updateData);
    }
  };

  const formDisabled = addLoading || editLoading;
  if (!initialState || !messageTemplate)
    return (
      <FloatModal isOpen onClose={closeModal}>
        <Loader screen />
      </FloatModal>
    );

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L">Edit message</Heading>
      <Form
        onSubmit={(formState) => save(formState.values as MessageTemplateFormState)}
        initialValues={initialState}
        validateFields={(values) => formValidator(values as MessageTemplateFormState)}
      >
        <FormContent
          impactedJourneys={impactedJourneys}
          impactedJourneyNames={impactedJourneyNames}
          formDisabled={formDisabled}
          payloadContentWarning={payloadContentWarning}
          closeModal={closeModal}
        />
      </Form>
    </FloatModal>
  );
};

const mapStateToProps = ({ messageTemplates }: ReduxStateType) => {
  return {
    addLoading: messageTemplates.messageTemplateAddLoading,
    editLoading: messageTemplates.messageTemplateUpdateLoading,
    messageTemplate: messageTemplates.messageTemplateDetail.messageTemplate
  };
};

export default connect(mapStateToProps, {
  addMessage: addMessageTemplate,
  closeModal: closeModalAction,
  fetchMessage: fetchMessageTemplateDetail,
  updateMessage: updateMessageTemplate
})(EditMessageTemplate);
