import {
  Plus,
  Letters,
  List,
  Paragraph,
  Exclamation,
  Clock,
  ExternalLink,
  Map
} from "../../../Icon";
import { ChatNodeTypes } from "../../../../../types";

const typeIconMap = {
  [ChatNodeTypes.content]: Plus,
  [ChatNodeTypes.text]: Paragraph,
  [ChatNodeTypes.heading]: Letters,
  [ChatNodeTypes.list]: List,
  [ChatNodeTypes.listItem]: Plus,
  [ChatNodeTypes.important]: Exclamation,
  [ChatNodeTypes.duration]: Clock,
  [ChatNodeTypes.link]: ExternalLink,
  [ChatNodeTypes.infoModal]: Map
};

export const getNodeIcon = (type: ChatNodeTypes, props: { size: number }): JSX.Element => {
  const nodeIcon = typeIconMap[type];

  return nodeIcon(props);
};
