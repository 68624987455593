import React from "react";
import cx from "classnames";
import Comparator from "./Comparator";
import Operator from "./Operator";
import CompositionOperator from "./CompositionOperator";
import Variable from "./Variable";
import { getOperatorType, isCompositionOperator, JsonLogicInputError } from "../helpers";

import { Close, Plus } from "../../../Icon";
import Button from "../../../Button";
import Text from "../../../Text";

import { defaultConditionOperators, defaultCompositionOperators } from "../defaultConfigurations";
import { ConditionJSON, ContextVariable, UpdateCondition, PLACEHOLDER_OPERATOR } from "../types";

import styles from "./index.module.scss";

type PropsType = {
  conditionPath: string;
  condition: ConditionJSON;
  contextVariables: ContextVariable[];
  updateCondition: UpdateCondition;
  errorsInfo: JsonLogicInputError[];
  disabled: boolean;
};

const Condition = ({
  conditionPath,
  condition,
  contextVariables,
  updateCondition,
  errorsInfo,
  disabled = false
}: PropsType) => {
  const conditionType = getOperatorType(condition);
  const conditionItems = condition?.[conditionType] || [];
  const operatorValue = conditionType;

  const isUnknownOperator =
    operatorValue &&
    operatorValue !== PLACEHOLDER_OPERATOR.value &&
    ![...defaultConditionOperators, ...defaultCompositionOperators]
      .map((operatorConfig) => operatorConfig.jsonLogicKey)
      .includes(operatorValue);
  const comparatorValue = conditionItems?.[1] || "";

  const nestedConditionDepth = conditionPath ? conditionPath.split("[").length - 1 : 0;

  const showGroupAsAddCondition =
    nestedConditionDepth === 0 && !isCompositionOperator(conditionType);
  const conditionHasError = errorsInfo.find((error) => error.path === conditionPath);

  if (isUnknownOperator) {
    return (
      <Text>
        Unknown condition operator: <br /> {JSON.stringify(condition)}
      </Text>
    );
  }

  switch (conditionType) {
    case "and":
    case "or": {
      return (
        <div
          className={cx(styles.CompositionConditionWrapper, {
            [styles.ConditionNestedZero]: nestedConditionDepth === 0,
            [styles.ConditionNestedOne]: nestedConditionDepth === 1,
            [styles.ConditionNestedTwo]: nestedConditionDepth === 2,
            [styles.ConditionError]: conditionHasError
          })}
        >
          {conditionItems.map((childCondition, idx) => {
            const childConditionPath = `${conditionPath}${
              conditionPath ? "." : ""
            }${operatorValue}[${idx}]`;

            const isLastChildCondition = conditionItems.length - 1 === idx;
            const childOperator = getOperatorType(childCondition as ConditionJSON);
            const showAddCondition =
              isLastChildCondition &&
              childOperator !== PLACEHOLDER_OPERATOR.value &&
              !showGroupAsAddCondition;
            return (
              <React.Fragment key={childConditionPath}>
                <Condition
                  conditionPath={childConditionPath}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  condition={childCondition as any}
                  contextVariables={contextVariables}
                  updateCondition={updateCondition}
                  errorsInfo={errorsInfo}
                  disabled={disabled}
                />
                {(!isLastChildCondition || showAddCondition) && (
                  <CompositionOperator
                    className={cx(styles.CompositionOperatorElement)}
                    nestedDepth={nestedConditionDepth + 1}
                    operatorValue={operatorValue}
                    conditionPath={conditionPath}
                    updateCondition={updateCondition}
                    showAddCondition={showAddCondition}
                    disabled={disabled}
                  />
                )}
              </React.Fragment>
            );
          })}
        </div>
      );
    }
    case PLACEHOLDER_OPERATOR.value:
    default: {
      const variableValueItem = conditionItems.find(
        (valueItem) =>
          !Array.isArray(valueItem) && typeof valueItem !== "string" && Boolean(valueItem?.var)
      );
      const variableValue = variableValueItem ? (variableValueItem as { var: string }).var : "";

      return (
        <div
          className={cx(styles.ConditionWrapper, {
            [styles.ConditionWrapperColumn]: showGroupAsAddCondition
          })}
        >
          <div
            className={cx(styles.Condition, {
              [styles.ConditionNestedOne]: nestedConditionDepth === 1,
              [styles.ConditionNestedTwo]: nestedConditionDepth === 2,
              [styles.ConditionError]: conditionHasError
            })}
          >
            <Variable
              className={styles.Element}
              contextVariables={contextVariables}
              variableValue={variableValue}
              conditionPath={conditionPath}
              updateCondition={updateCondition}
              disabled={disabled}
            />
            {variableValue && (
              <Operator
                className={styles.Element}
                contextVariables={contextVariables}
                variableValue={variableValue}
                operatorValue={operatorValue}
                conditionPath={conditionPath}
                updateCondition={updateCondition}
                disabled={disabled}
              />
            )}
            {operatorValue && (
              <Comparator
                className={styles.Element}
                contextVariables={contextVariables}
                variableValue={variableValue}
                operatorValue={operatorValue}
                comparatorValue={comparatorValue}
                conditionPath={conditionPath}
                updateCondition={updateCondition}
                disabled={disabled}
              />
            )}
            {condition && (
              <Button
                className={styles.Clear}
                inline
                disabled={disabled}
                onClick={() => updateCondition(conditionPath, "clear")}
              >
                <Close />
              </Button>
            )}
          </div>
          {nestedConditionDepth < 2 && operatorValue !== PLACEHOLDER_OPERATOR.value && (
            <>
              {showGroupAsAddCondition ? (
                <Button
                  className={styles.AddCompositionOperator}
                  inline
                  disabled={disabled}
                  onClick={() => {
                    const initialOperator = nestedConditionDepth < 1 ? "and" : "or";
                    updateCondition(conditionPath, "addCompositionOperator", initialOperator);
                  }}
                >
                  <Plus size={18} /> condition
                </Button>
              ) : (
                <Button
                  className={styles.AddCompositionOperator}
                  inline
                  disabled={disabled}
                  onClick={() => {
                    const initialOperator = nestedConditionDepth < 1 ? "and" : "or";
                    updateCondition(conditionPath, "addCompositionOperator", initialOperator);
                  }}
                >
                  <Plus size={18} /> group
                </Button>
              )}
            </>
          )}
        </div>
      );
    }
  }
};

export default Condition;
