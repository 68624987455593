import {
  FETCH_REPORT_TEMPLATES,
  GET_REPORT_TEMPLATE_DETAILS,
  UPDATE_REPORT_TEMPLATES,
  CLEAR_REPORT_TEMPLATE_DETAILS,
  UPDATE_REPORT_TEMPLATE_CARD,
  CREATE_REPORT_TEMPLATE_CARD,
  CREATE_REPORT_TEMPLATE,
  REMOVE_REPORT_TEMPLATE_CARD
} from "../constants";

import {
  GetReportTemplateDetailsAction,
  UpdateReportTemplateAction,
  ClearReportTemplateDetailsAction,
  UpdateReportTemplateCardAction,
  CreateReportTemplateCardAction,
  CreateReportTemplateAction,
  RemoveReportTemplateCardAction
} from "../actions";
import { ReportTemplate, ReportTemplateCard, ActionStatus, Tag } from "../types";

export type ReportTemplatesAction =
  | GetReportTemplateDetailsAction
  | UpdateReportTemplateAction
  | ClearReportTemplateDetailsAction
  | UpdateReportTemplateCardAction
  | CreateReportTemplateCardAction
  | CreateReportTemplateAction
  | RemoveReportTemplateCardAction;

export type ReportTemplateCardAddLoading = {
  [id: string]: boolean;
};

export type ReportTemplateCardUpdateLoading = {
  [id: string]: boolean;
};

export type ReportTemplatesReduxState = {
  reportTemplates: Array<ReportTemplate>;
  reportTemplateFetchLoading: boolean;
  reportTemplateUpdateLoading: boolean;
  reportTemplateAddLoading: boolean;
  reportTemplateDeleteLoading: boolean;
  reportTemplateCardRemoveLoading: boolean;
  reportTemplateCardAddLoading: ReportTemplateCardAddLoading;
  reportTemplateCardUpdateLoading: ReportTemplateCardUpdateLoading;
  reportTemplateCards: Array<ReportTemplateCard>;
  allSubReportTags: Array<Tag>;
  availableSubReportTags: Array<Tag>;
};

const initialReportTemplatesState: ReportTemplatesReduxState = {
  reportTemplates: [],
  reportTemplateFetchLoading: false,
  reportTemplateUpdateLoading: false,
  reportTemplateAddLoading: false,
  reportTemplateDeleteLoading: false,
  reportTemplateCardRemoveLoading: false,
  reportTemplateCardAddLoading: {},
  reportTemplateCardUpdateLoading: {},
  reportTemplateCards: [],
  allSubReportTags: [],
  availableSubReportTags: []
};

export const reportTemplatesReducer = (
  state = initialReportTemplatesState,
  action: ReportTemplatesAction
): ReportTemplatesReduxState => {
  switch (action.type) {
    case GET_REPORT_TEMPLATE_DETAILS: {
      const { status, payload } = action as GetReportTemplateDetailsAction;
      return {
        ...state,
        reportTemplates: payload ? payload.reportTemplates : state.reportTemplates,
        reportTemplateFetchLoading: status === ActionStatus.loading,
        reportTemplateCards: payload ? payload.reportTemplateCards : state.reportTemplateCards,
        allSubReportTags: payload ? payload.allSubReportTags : state.allSubReportTags,
        availableSubReportTags: payload
          ? payload.availableSubReportTags
          : state.availableSubReportTags
      };
    }

    case UPDATE_REPORT_TEMPLATES: {
      const { status } = action as UpdateReportTemplateAction;
      return {
        ...state,
        reportTemplateUpdateLoading: status === ActionStatus.loading
      };
    }

    case REMOVE_REPORT_TEMPLATE_CARD: {
      const { status } = action as RemoveReportTemplateCardAction;
      return {
        ...state,
        reportTemplateCardRemoveLoading: status === ActionStatus.loading
      };
    }

    case CLEAR_REPORT_TEMPLATE_DETAILS: {
      return {
        ...state,
        reportTemplates: initialReportTemplatesState.reportTemplates,
        reportTemplateCards: initialReportTemplatesState.reportTemplateCards,
        allSubReportTags: initialReportTemplatesState.allSubReportTags,
        availableSubReportTags: initialReportTemplatesState.availableSubReportTags
      };
    }
    case UPDATE_REPORT_TEMPLATE_CARD: {
      const { status, payload } = action as UpdateReportTemplateCardAction;

      const cardId = `${payload?.cardId}`;

      const reportTemplateCardUpdateLoading = {
        ...state.reportTemplateCardUpdateLoading,
        [cardId]: status === ActionStatus.loading
      };

      return {
        ...state,
        reportTemplateCardUpdateLoading
      };
    }
    case CREATE_REPORT_TEMPLATE_CARD: {
      const { status, payload } = action as CreateReportTemplateCardAction;

      const temporaryCardId = `${payload?.reportTemplateCardData.id}`;

      const reportTemplateCardAddLoading = {
        ...state.reportTemplateCardAddLoading,
        [temporaryCardId]: status === ActionStatus.loading
      };

      return {
        ...state,
        reportTemplateCardAddLoading
      };
    }

    case CREATE_REPORT_TEMPLATE: {
      const { status } = action as CreateReportTemplateAction;
      return {
        ...state,
        reportTemplateAddLoading: status === ActionStatus.loading
      };
    }

    default:
      return state;
  }
};
