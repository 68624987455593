import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";

import { Form } from "../../../../../ui/Input";
import Loader from "../../../../../ui/Loader";
import TriggerFormContent from "./TriggerFormContent";

import { usePermissions } from "../../../../../../hooks/usePermissions";

import {
  fetchLocations as fetchLocationsAction,
  fetchPractitioners as fetchPractitionersAction,
  fetchReasons as fetchReasonsAction,
  updateAutomation,
  UpdateAutomationData
} from "../../../../../../actions";

import { JourneyTriggersFormState, journeyTriggersformValidator } from "../JourneyFormValidator";
import {
  AutomationDetails,
  Location,
  Permissions,
  Practitioner,
  Reason,
  ReduxStateType
} from "../../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  automation?: AutomationDetails;
  organizationId?: number;
  fetchLocations: (organizationId: number) => void;
  fetchPractitioners: () => void;
  fetchReasons: () => void;
  locations: Array<Location>;
  practitioners: Array<Practitioner>;
  reasons: Array<Reason>;
  loading?: boolean;
  updateAutomationDetails: (automationId: string, automationData: UpdateAutomationData) => void;
};

const initialState = {
  locations: [],
  practitioners: [],
  reasons: []
};

const Triggers = ({
  automation,
  organizationId,
  fetchLocations,
  fetchPractitioners,
  fetchReasons,
  locations,
  practitioners,
  reasons,
  updateAutomationDetails,
  loading
}: PropsType): JSX.Element | null => {
  const isDefaultAutomation = Boolean(
    automation &&
      automation.status === "published" &&
      automation.locations?.length === 0 &&
      automation.reasons?.length === 0 &&
      automation.practitioners?.length === 0
  );
  const userCanEdit = usePermissions([Permissions.UPDATE_AUTOMATION]);

  const formDisabled = loading || !userCanEdit;

  useEffect(() => {
    if (organizationId) {
      fetchLocations(organizationId);
      fetchPractitioners();
      fetchReasons();
    }
  }, [organizationId]);

  const initialFormState = useMemo(() => {
    if (automation) {
      const newFormState: JourneyTriggersFormState = cloneDeep(initialState);

      const locationIds = automation.locations.map((location) => location.id.toString());
      newFormState.locations = locationIds;
      const practitionerIds = automation.practitioners.map((practitioner) =>
        practitioner.id.toString()
      );
      newFormState.practitioners = practitionerIds;
      const reasonIds = automation.reasons.map((reason) => reason.id.toString());
      newFormState.reasons = reasonIds;

      return newFormState;
    }

    return null;
  }, [automation]);

  const journeyFormValidator = useMemo(() => {
    return journeyTriggersformValidator(isDefaultAutomation, automation?.status || "");
  }, [automation, isDefaultAutomation]);

  const save = (formValues: JourneyTriggersFormState) => {
    if (automation) {
      updateAutomationDetails(automation.id, {
        locationIds: formValues.locations || [],
        practitionerIds: formValues.practitioners || [],
        reasonIds: formValues.reasons || []
      });
    }
    return null;
  };

  if (!automation || !initialFormState) return <Loader screen />;

  return (
    <div className={styles.Wrapper}>
      <Form
        onSubmit={(formState) => save(formState.values as JourneyTriggersFormState)}
        initialValues={initialFormState}
        validateFields={(values) => journeyFormValidator(values as JourneyTriggersFormState)}
      >
        <TriggerFormContent
          locations={locations}
          practitioners={practitioners}
          formDisabled={formDisabled}
          isDefaultAutomation={isDefaultAutomation}
          reasons={reasons}
        />
      </Form>
    </div>
  );
};

const mapStateToProps = ({
  organizationData,
  automatedMessages,
  locations,
  practitioners,
  reasons
}: ReduxStateType) => {
  return {
    organizationId: organizationData.organizationData
      ? organizationData.organizationData.id
      : undefined,
    locations: locations.data,
    practitioners: practitioners.data,
    reasons: reasons.data,
    automation: automatedMessages.automationDetails,
    loading: automatedMessages.automationDetailsLoading
  };
};

export default connect(mapStateToProps, {
  fetchLocations: fetchLocationsAction,
  fetchPractitioners: fetchPractitionersAction,
  fetchReasons: fetchReasonsAction,
  updateAutomationDetails: updateAutomation
})(Triggers);
