import React, { useState } from "react";
import cx from "classnames";

import Heading from "../../../../../../../ui/Heading";
import { ChevronDown, ChevronRight } from "../../../../../../../ui/Icon";
import { TextAreaInput } from "../../../../../../../ui/Input";

import styles from "./index.module.scss";

const DeprecatedContentSection = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={cx(styles.Advanced, { [styles.AdvancedOpen]: isOpen })}>
      <div className={cx(styles.Header, { [styles.HeaderOpen]: isOpen })}>
        <button type="button" className={cx(styles.ToggleIcon)} onClick={toggleOpen}>
          <span>{isOpen ? <ChevronDown size={16} /> : <ChevronRight size={16} />}</span>
          <Heading size="META" className={cx(styles.HeaderText)}>
            Deprecated Content
          </Heading>
        </button>
      </div>

      <div className={cx(styles.AdvancedDrawer, { [styles.AdvancedDrawerOpen]: isOpen })}>
        <TextAreaInput fieldName="content" disabled />
      </div>
    </div>
  );
};

export default DeprecatedContentSection;
