import React from "react";
import cx from "classnames";

import Heading from "../../Heading";
import styles from "./index.module.scss";

type PropsType = {
  children: React.ReactNode;
  title?: string;
  id?: string;
  classname?: string;
};

export const CardSection = ({ title, children, id, classname }: PropsType): React.ReactElement => {
  return (
    <div className={cx(styles.CardSection, classname)} id={id}>
      <div className={styles.Title}>
        <Heading size="S">{title}</Heading>
      </div>
      <div className={styles.Content}>{children}</div>
    </div>
  );
};
