import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import { connect } from "react-redux";

import Button from "../../../ui/Button";
import Heading from "../../../ui/Heading";
import Status from "../../../ui/Status";
import Text from "../../../ui/Text";
import { Collapse, Expand } from "../../../ui/Icon";
import { Form } from "../../../ui/Input";
import { FullScreenModal } from "../../../ui/Modal";
import Loader from "../../../ui/Loader";
import JourneyDrawer from "./JourneyDrawer";
import FormContent from "./FormContent";

import { OrganizationContext } from "../../../providers/OrganizationProvider";

import { generatePractitionerLabel } from "../../../../utils/generatePractitionerLabel";
import { generateReasonLabel } from "../../../../utils/generateReasonLabel";
import { generateLocationLabel } from "../../../../utils/generateLocationLabel";
import sortAutomationJourney, { SortedAutomationJourneyDetails } from "./sortAutomationJourney";

import {
  getAutomationJourney as getAutomationJourneyAction,
  clearAutomationJourney as clearAutomationJourneyAction,
  fetchLocations as fetchLocationsAction,
  fetchPractitioners as fetchPractitionersAction,
  fetchReasons as fetchReasonsAction
} from "../../../../actions";
import {
  AutomationJourneyDetails,
  StatusComponentConfigMap,
  ReduxStateType,
  Practitioner,
  Reason,
  Location
} from "../../../../types";

import styles from "./index.module.scss";

type AutomationJourneyGetData = {
  locationId: string | null;
  reasonId: string | null;
  practitionerId: string | null;
};

type PropsType = {
  isModalOpen: boolean;
  closeModal: () => void;
  loading?: boolean;
  getAutomationJourney: (data: AutomationJourneyGetData) => void;
  clearAutomationJourney: () => void;
  automationJourneyDetails?: AutomationJourneyDetails[];
  fetchLocations: (organizationId: number) => void;
  fetchPractitioners: () => void;
  fetchReasons: () => void;
  locations: Array<Location>;
  practitioners: Array<Practitioner>;
  reasons: Array<Reason>;
};

const initialFormState: AutomationJourneyGetData = {
  locationId: null,
  reasonId: null,
  practitionerId: null
};

const configMap: StatusComponentConfigMap = {
  location: "blue",
  provider: "purple",
  reason: "green",
  featureDisabled: "gray"
};

const SimulateJourneyModal = ({
  loading,
  isModalOpen,
  closeModal,
  getAutomationJourney,
  clearAutomationJourney,
  automationJourneyDetails,
  fetchLocations,
  fetchPractitioners,
  fetchReasons,
  locations,
  practitioners,
  reasons
}: PropsType) => {
  const organization = useContext(OrganizationContext);
  const [forceClose, setForceClose] = useState(false);
  const [sortedAutomationJourneyDetails, setSortedAutomationJourneyDetails] =
    useState<SortedAutomationJourneyDetails | null>(
      sortAutomationJourney(automationJourneyDetails)
    );

  const [selectedPractitioner, setSelectedPractitioner] = useState<Practitioner | null>(null);
  const [selectedReason, setSelectedReason] = useState<Reason | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);

  useEffect(() => {
    setSortedAutomationJourneyDetails(sortAutomationJourney(automationJourneyDetails));
  }, [automationJourneyDetails]);

  useEffect(() => {
    if (organization) {
      fetchLocations(organization.id);
      fetchPractitioners();
      fetchReasons();
    }
  }, [organization]);

  if (!organization) return <Loader small center />;

  const getJourney = (values: AutomationJourneyGetData) => {
    clearAutomationJourney();
    setForceClose(false);

    const practitionerCriteria = practitioners
      ? practitioners.find(
          (practitioner) => practitioner.id === parseInt(values.practitionerId as string, 10)
        )
      : null;
    if (practitionerCriteria) {
      setSelectedPractitioner(practitionerCriteria);
    } else {
      setSelectedPractitioner(null);
    }

    const reasonCriteria = reasons
      ? reasons.find((reason) => reason.id === parseInt(values.reasonId as string, 10))
      : null;
    if (reasonCriteria) {
      setSelectedReason(reasonCriteria);
    } else {
      setSelectedReason(null);
    }

    const locationCriteria = locations
      ? locations.find((location) => location.id === parseInt(values.locationId as string, 10))
      : null;
    if (locationCriteria) {
      setSelectedLocation(locationCriteria);
    } else {
      setSelectedLocation(null);
    }

    getAutomationJourney({
      locationId: values.locationId,
      reasonId: values.reasonId,
      practitionerId: values.practitionerId
    });
  };

  const locationOptions = locations
    ? locations.map((location) => {
        return {
          label: `${generateLocationLabel(location)} ${location.active ? "" : " (Inactive)"}`,
          value: location.id
        };
      })
    : [];

  const reasonOptions = reasons
    ? reasons.map((reason) => {
        return {
          label: `${generateReasonLabel(reason)}
      ${reason.active ? "" : " (Inactive)"}`,
          value: reason.id
        };
      })
    : [];

  const practitionerOptions = practitioners
    ? practitioners.map((practitioner) => {
        return {
          label: `${generatePractitionerLabel(practitioner)}
      ${practitioner.practitionerActive ? "" : " (Inactive)"}`,
          value: practitioner.id
        };
      })
    : [];

  return (
    <FullScreenModal
      isOpen={isModalOpen}
      onClose={() => {
        clearAutomationJourney();
        closeModal();
      }}
      title="Appointment Journey Preview"
      contentClassName={styles.ModalContent}
    >
      <div className={styles.JourneyContainer}>
        <div className={styles.JourneyCriteria}>
          <Heading size="L">Journey Criteria</Heading>
          <Form
            onSubmit={(formState) => getJourney(formState.values as AutomationJourneyGetData)}
            initialValues={initialFormState}
          >
            <FormContent
              loading={loading}
              locationOptions={locationOptions}
              practitionerOptions={practitionerOptions}
              reasonOptions={reasonOptions}
            />
          </Form>
        </div>
        <div
          className={cx(styles.JourneyDetailsWrapper, {
            [styles.JourneyDetailsWrapperLoading]: loading || !automationJourneyDetails
          })}
        >
          {!loading && !automationJourneyDetails && (
            <>
              Select a location, provider, and reason to preview the messages in the appointment
              journey.
            </>
          )}
          {loading && (
            <>
              Preparing appointment journey
              <Loader className={styles.Loader} small center />
            </>
          )}
          {automationJourneyDetails && sortedAutomationJourneyDetails && (
            <>
              <div className={styles.DetailsHeader}>
                <div className={styles.SearchCriteria}>
                  <Text className={styles.SearchCriteriaText}>Search for results:</Text>
                  {selectedLocation && (
                    <Status
                      className={styles.SearchCriteriaTrigger}
                      value="location"
                      options={[
                        {
                          label: (selectedLocation as Location).displayName,
                          value: "location"
                        }
                      ]}
                      configMap={configMap}
                    />
                  )}
                  {selectedPractitioner && (
                    <Status
                      className={styles.SearchCriteriaTrigger}
                      value="provider"
                      options={[
                        {
                          label: (selectedPractitioner as Practitioner).fullName,
                          value: "provider"
                        }
                      ]}
                      configMap={configMap}
                    />
                  )}
                  {selectedReason && (
                    <Status
                      className={styles.SearchCriteriaTrigger}
                      value="reason"
                      options={[
                        {
                          label: (selectedReason as Reason).value,
                          value: "reason"
                        }
                      ]}
                      configMap={configMap}
                    />
                  )}
                </div>
                <Button
                  type="button"
                  inline
                  secondary
                  onClick={() => {
                    setForceClose(!forceClose);
                  }}
                >
                  {forceClose ? <Expand /> : <Collapse />}
                </Button>
              </div>

              <div className={styles.DetailsContent}>
                {sortedAutomationJourneyDetails
                  .filter((detail) => detail !== null)
                  .map((detail) => {
                    if (detail) {
                      return (
                        <JourneyDrawer
                          key={detail.heading}
                          templates={detail.templates}
                          selectedLocationId={selectedLocation?.id}
                          selectedPractitionerId={selectedPractitioner?.id}
                          selectedReasonId={selectedReason?.id}
                          featuresDisabled={detail.featuresDisabled}
                          heading={detail.heading}
                          close={forceClose}
                        />
                      );
                    }
                    return null;
                  })}
              </div>
            </>
          )}
        </div>
      </div>
    </FullScreenModal>
  );
};

const mapStateToProps = ({
  automatedMessages,
  locations,
  practitioners,
  reasons
}: ReduxStateType) => {
  return {
    loading: automatedMessages.automationJourneyDetailsLoading,
    automationJourneyDetails: automatedMessages.automationJourneyDetails,
    locations: locations.data,
    practitioners: practitioners.data,
    reasons: reasons.data
  };
};

export default connect(mapStateToProps, {
  getAutomationJourney: getAutomationJourneyAction,
  clearAutomationJourney: clearAutomationJourneyAction,
  fetchLocations: fetchLocationsAction,
  fetchPractitioners: fetchPractitionersAction,
  fetchReasons: fetchReasonsAction
})(SimulateJourneyModal);
