import React, { useState } from "react";
import { connect } from "react-redux";
import { toInteger } from "lodash";

import { Form } from "../../../../../../ui/Input";
import AddTouchpointForm from "./AddTouchpointsForm";

import { usePermissions } from "../../../../../../../hooks/usePermissions";

import {
  addMessageConfiguration as addMessageConfigurationAction,
  addMessageConfigurationData,
  AppointmentTouchpointType,
  addMessageConfigurationOptions,
  openModal as openModalAction,
  OpenModal
} from "../../../../../../../actions";

import {
  combine,
  isRequired,
  isInteger,
  isMultipleOf,
  patientHasOtherAppointmentRangeTime as patientHasOtherAppointmentRangeTimeValidator
} from "../../../../../../../utils/validators";

import { MessageTopics, IntegrationTypes, TriggerUnitTypes } from "../../../../../../../constants";

import {
  ChatFlow,
  ReduxStateType,
  MessageTemplate,
  AutomationDashboard,
  MessageSchedule,
  MessageScheduleEvent,
  MessageTemplateMedium,
  Permissions,
  Reason,
  TouchpointExclusionCases,
  TouchpointSettingRangeTime,
  TouchpointSettingRangeTimeType,
  TouchpointSettingRangeTimeUnit,
  EMRCategory
} from "../../../../../../../types";

type AddTouchpointFormState = {
  type: AppointmentTouchpointType | "";
  automationIds: string[];
  templateId: string;
  multiTemplateId: string;
  triggerValue: string;
  triggerUnit: string;
  chatFlowIds: number[];
  exclusionHours: number;
  exclusionHoursOption: string;
  exclusionHoursUnit: string;
  exclusionHoursActive: boolean;
  note: string;
  excludesApptsWhen?: string;
  settings: {
    exclude?: {
      patientHasOtherAppt?: {
        active: boolean;
        rangeStart: TouchpointSettingRangeTime;
        rangeEnd: TouchpointSettingRangeTime;
      };
    };
  };
  reasonIds?: string[];
};

type PropsType = {
  automationId?: number;
  automations?: AutomationDashboard[];
  inModal?: boolean;
  availableChats: ChatFlow[];
  loading?: boolean;
  messageTemplates: Array<MessageTemplate>;
  integrated: boolean;
  integrationType: string;
  integrationCategory: string;
  messageSchedules?: MessageSchedule[];
  reasons: Array<Reason>;
  onSuccess?: () => void;
  addMessageConfiguration: (
    data: addMessageConfigurationData,
    options?: addMessageConfigurationOptions
  ) => void;
  openModal: OpenModal;
};

export type PreviewMediumTypes = MessageTemplateMedium.sms | MessageTemplateMedium.email;

const supportsExcludeAppointments = (type?: string): boolean => {
  return type === AppointmentTouchpointType.AFTER_APPOINTMENT_START;
};

const templateValidator = isInteger("Please select from message templates");
const typeValidator = isRequired("Please select from types");
const touchpointRangeTimeUnitValidator = isRequired("Please select time unit");

const formValidator = (values: AddTouchpointFormState) => {
  const patientHasOtherApptActive = !!values.settings?.exclude?.patientHasOtherAppt?.active;

  return {
    type: typeValidator(values.type),
    automationIds: undefined,
    templateId: templateValidator(values.templateId),
    multiTemplateId: undefined,
    triggerValue: undefined,
    triggerUnit: undefined,
    chatFlowIds: undefined,
    exclusionHours: undefined,
    exclusionHoursOption: undefined,
    exclusionHoursUnit: undefined,
    exclusionHoursActive: undefined,
    note: undefined,
    excludesApptsWhen: undefined,
    settings: supportsExcludeAppointments(values.type)
      ? {
          exclude: {
            patientHasOtherAppt: {
              active: undefined,
              rangeStart: {
                type: undefined,
                value: patientHasOtherApptActive
                  ? combine([
                      () =>
                        patientHasOtherAppointmentRangeTimeValidator(
                          values.settings.exclude?.patientHasOtherAppt?.rangeStart,
                          values.settings.exclude?.patientHasOtherAppt?.rangeEnd
                        ),
                      isRequired("Range start value required")
                    ])(values.settings.exclude?.patientHasOtherAppt?.rangeStart?.value)
                  : undefined,
                unit: patientHasOtherApptActive
                  ? touchpointRangeTimeUnitValidator(
                      values.settings.exclude?.patientHasOtherAppt?.rangeStart.unit
                    )
                  : undefined
              },
              rangeEnd: {
                type: undefined,
                value: patientHasOtherApptActive
                  ? combine([
                      () =>
                        patientHasOtherAppointmentRangeTimeValidator(
                          values.settings.exclude?.patientHasOtherAppt?.rangeStart,
                          values.settings.exclude?.patientHasOtherAppt?.rangeEnd
                        ),
                      isRequired("Range end value required")
                    ])(values.settings.exclude?.patientHasOtherAppt?.rangeEnd?.value)
                  : undefined,
                unit: patientHasOtherApptActive
                  ? touchpointRangeTimeUnitValidator(
                      values.settings.exclude?.patientHasOtherAppt?.rangeEnd.unit
                    )
                  : undefined
              },
              reasonIds: undefined
            }
          }
        }
      : undefined
  };
};

const globalFormValidator = (values: AddTouchpointFormState) => {
  switch (values.type) {
    case AppointmentTouchpointType.BEFORE_APPOINTMENT_START:
    case AppointmentTouchpointType.AFTER_APPOINTMENT_START: {
      const triggerValueInt = Number.parseInt(values.triggerValue, 10);
      switch (values.triggerUnit) {
        case TriggerUnitTypes.DAYS:
          if (triggerValueInt < 1 || triggerValueInt > 366) {
            return "Schedule value for Days must be between 1-366";
          }
          return "";
        case TriggerUnitTypes.HOURS:
          if (triggerValueInt < 1 || triggerValueInt > 24) {
            return "Schedule value for Hours must be between 1-24";
          }
          return "";
        case TriggerUnitTypes.MINUTES:
          if (triggerValueInt < 10 || triggerValueInt > 120) {
            return "Schedule value for Minutes must be between 10-120";
          }
          return (
            isMultipleOf(5, "Schedule value must be a multiple of 5")(values.triggerValue) || ""
          );
        default:
          return "";
      }
    }

    default:
      return "";
  }
};

const templateMediumFilter = (medium: string) => (template: MessageTemplate) => {
  return template.medium === medium;
};

const AddTouchpoint = ({
  loading,
  addMessageConfiguration,
  automationId,
  automations,
  inModal = false,
  availableChats,
  messageTemplates,
  messageSchedules,
  reasons,
  onSuccess,
  integrated,
  integrationType,
  integrationCategory,
  openModal
}: PropsType) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [showMultiTemplate, setShowMultiTemplate] = useState(false);
  const [previewMedium, setPreviewMedium] = useState<PreviewMediumTypes>(MessageTemplateMedium.sms);
  const [multiPreviewMedium, setMultiPreviewMedium] = useState<PreviewMediumTypes>(
    MessageTemplateMedium.sms
  );
  const initialFormState: AddTouchpointFormState = {
    type: "",
    automationIds: automationId ? [automationId.toString()] : [],
    templateId: "",
    multiTemplateId: "",
    triggerValue: "",
    triggerUnit: "",
    chatFlowIds: [],
    exclusionHours: 0,
    exclusionHoursOption: "Appointment is within",
    exclusionHoursUnit: TouchpointSettingRangeTimeUnit.HOURS,
    exclusionHoursActive: false,
    note: "",
    excludesApptsWhen: TouchpointExclusionCases.PATIENT_OTHER_APPT,
    settings: {
      exclude: {
        patientHasOtherAppt: {
          active: false,
          rangeStart: {
            type: TouchpointSettingRangeTimeType.BEFORE_TOUCHPOINT,
            value: 1,
            unit: TouchpointSettingRangeTimeUnit.DAYS
          },
          rangeEnd: {
            type: TouchpointSettingRangeTimeType.AFTER_TOUCHPOINT,
            value: 1,
            unit: TouchpointSettingRangeTimeUnit.DAYS
          }
        }
      }
    },
    reasonIds: []
  };

  const userCanCreate = usePermissions([Permissions.CREATE_AUTOMATED_MESSAGE_CONFIGURATION]);

  const formDisabled = loading || !userCanCreate;

  const addToMultiAutomationsMode = !automationId;

  const chatOptions = availableChats.map((chat) => {
    return { label: `${chat.title}${chat.version ? ` (${chat.version})` : ""}`, value: chat.id };
  });

  const exclusionHoursOptions = [
    { label: "Appointment is within", value: "Appointment is within" }
  ];

  const exclusionHoursUnitOptions = [
    { label: "Hours", value: TouchpointSettingRangeTimeUnit.HOURS }
  ];

  const excludeAppointmentsOptions = [
    { label: "Patient has another appointment", value: TouchpointExclusionCases.PATIENT_OTHER_APPT }
  ];

  const touchpointSettingRangeTimeTypeOptions = [
    { label: "Before touchpoint", value: TouchpointSettingRangeTimeType.BEFORE_TOUCHPOINT },
    { label: "After touchpoint", value: TouchpointSettingRangeTimeType.AFTER_TOUCHPOINT }
  ];

  const touchpointSettingRangeTimeUnitOptions = [
    { label: "Days", value: TouchpointSettingRangeTimeUnit.DAYS },
    { label: "Hours", value: TouchpointSettingRangeTimeUnit.HOURS },
    { label: "Minutes", value: TouchpointSettingRangeTimeUnit.MINUTES }
  ];

  const updateError = () => {
    setErrorMessage("");
  };

  const onSave = (formData: AddTouchpointFormState) => {
    // either a single automationId is passed or automation options are generated
    const automationIds: string[] = automationId
      ? [automationId.toString()]
      : formData.automationIds;
    const reasonIds = formData.reasonIds?.map((reasonId) => parseInt(reasonId)) || [];
    const settings = formData.settings?.exclude?.patientHasOtherAppt?.active
      ? {
          exclude: {
            ...formData.settings.exclude,
            patientHasOtherAppt: {
              active: formData.settings.exclude?.patientHasOtherAppt?.active,
              rangeStart: formData.settings.exclude?.patientHasOtherAppt?.rangeStart,
              rangeEnd: formData.settings.exclude?.patientHasOtherAppt?.rangeEnd,
              reasonIds
            }
          }
        }
      : null;

    addMessageConfiguration(
      {
        type: formData.type as AppointmentTouchpointType,
        automationIds,
        templateId: toInteger(formData.templateId),
        multiTemplateId: toInteger(formData.multiTemplateId) || null,
        triggerUnit: formData.triggerUnit || "",
        triggerValue: formData.triggerValue ? parseInt(formData.triggerValue) : 0,
        chatFlowIds: formData.chatFlowIds,
        exclusionHours: formData.exclusionHours,
        note: formData.note,
        settings
      },
      { onSuccess }
    );
  };

  const messageTemplateOptions = messageTemplates
    .filter((messageTemplate) => {
      return (
        templateMediumFilter(MessageTemplateMedium.sms)(messageTemplate) &&
        messageTemplate.topic === MessageTopics.SCHEDULE_BASED_MESSAGE
      );
    })
    .map((messageTemplate) => ({
      label: messageTemplate.isDefault
        ? `Default ${messageTemplate.displayName} (${messageTemplate.id})`
        : `${messageTemplate.displayName} (${messageTemplate.id})`,
      value: messageTemplate.id.toString()
    }));

  const messageConfigurationOptions = [
    { label: "Before appointment", value: AppointmentTouchpointType.BEFORE_APPOINTMENT_START },
    { label: "After appointment", value: AppointmentTouchpointType.AFTER_APPOINTMENT_START },
    { label: "Upon booking", value: AppointmentTouchpointType.BOOK_EVENT }
  ];

  const automationOptions = automations
    ? automations.map((automation) => ({
        label: automation.displayName,
        value: automation.id.toString()
      }))
    : [];

  if (
    (integrated === true && integrationType === IntegrationTypes.PUSH) ||
    (integrated === true &&
      integrationType === IntegrationTypes.PULL &&
      [EMRCategory.MEDACCESS, EMRCategory.PS_SUITE].includes(integrationCategory as EMRCategory))
  ) {
    messageConfigurationOptions.push({
      label: "Upon Cancellation",
      value: AppointmentTouchpointType.CANCEL_EVENT
    });
    messageConfigurationOptions.push({
      label: "After Completion",
      value: AppointmentTouchpointType.COMPLETION_EVENT
    });
  }

  const findJourneysWithMatchingTouchpoints = (
    automationIds: string[],
    values: AddTouchpointFormState
  ) => {
    return automationIds
      .filter((automationId) => {
        const matchingAutomation = automations?.find(
          (automation) => automation.id.toString() === automationId
        );

        const automatedMessageConfigurations = matchingAutomation?.automatedMessageConfigurations;

        const matchingSchedule = messageSchedules?.find((schedule) => {
          switch (values.type) {
            case AppointmentTouchpointType.BEFORE_APPOINTMENT_START:
              return (
                schedule.triggerUnit === values.triggerUnit &&
                schedule.triggerValue === parseInt(values.triggerValue, 10) * -1
              );
            case AppointmentTouchpointType.AFTER_APPOINTMENT_START:
              return (
                schedule.triggerUnit === values.triggerUnit &&
                schedule.triggerValue === parseInt(values.triggerValue, 10) * 1
              );
            case AppointmentTouchpointType.BOOK_EVENT:
              return schedule.event === MessageScheduleEvent.BOOKED;
            case AppointmentTouchpointType.CANCEL_EVENT:
              return schedule.event === MessageScheduleEvent.CANCELLED;
            case AppointmentTouchpointType.COMPLETION_EVENT:
              return schedule.event === MessageScheduleEvent.COMPLETE;
            default:
              return false;
          }
        });

        if (matchingSchedule && automatedMessageConfigurations) {
          const matchingTouchpoint = automatedMessageConfigurations.find(
            (automatedMessageConfiguration) => {
              return automatedMessageConfiguration.scheduleId === matchingSchedule.id;
            }
          );
          return !!matchingTouchpoint;
        }

        return false;
      })
      .map((automationId) => {
        const matchingAutomation = automations?.find(
          (automation) => automation.id.toString() === automationId
        );

        return { id: matchingAutomation?.id, displayName: matchingAutomation?.displayName };
      });
  };

  return (
    <Form
      onSubmit={(formState) => {
        const values = formState.values as AddTouchpointFormState;
        const error = globalFormValidator(values);
        if (error) {
          setErrorMessage(error);
          return;
        }
        onSave(values);
      }}
      initialValues={initialFormState}
      validateFields={(values) => formValidator(values as AddTouchpointFormState)}
    >
      <AddTouchpointForm
        automationId={automationId}
        inModal={inModal}
        messageTemplates={messageTemplates}
        reasons={reasons}
        messageTemplateOptions={messageTemplateOptions}
        addToMultiAutomationsMode={addToMultiAutomationsMode}
        messageConfigurationOptions={messageConfigurationOptions}
        formDisabled={formDisabled}
        previewMedium={previewMedium}
        updateError={updateError}
        findJourneysWithMatchingTouchpoints={findJourneysWithMatchingTouchpoints}
        automationOptions={automationOptions}
        setPreviewMedium={setPreviewMedium}
        showMultiTemplate={showMultiTemplate}
        setShowMultiTemplate={setShowMultiTemplate}
        chatOptions={chatOptions}
        multiPreviewMedium={multiPreviewMedium}
        setMultiPreviewMedium={setMultiPreviewMedium}
        exclusionHoursUnitOptions={exclusionHoursUnitOptions}
        exclusionHoursOptions={exclusionHoursOptions}
        touchpointSettingRangeTimeUnitOptions={touchpointSettingRangeTimeUnitOptions}
        touchpointSettingRangeTimeTypeOptions={touchpointSettingRangeTimeTypeOptions}
        errorMessage={errorMessage}
        excludeAppointmentsOptions={excludeAppointmentsOptions}
        openModal={openModal}
      />
    </Form>
  );
};

const mapStateToProps = ({
  organizationData,
  automatedMessages,
  messageTemplates,
  messageConfigurations
}: ReduxStateType) => {
  return {
    automations: automatedMessages.automations,
    availableChats: automatedMessages.availableChats,
    integrated: organizationData.organizationData.integrated,
    integrationType: organizationData.organizationData.emrType.integrationType,
    integrationCategory: organizationData.organizationData.emrType.category,
    messageTemplates: messageTemplates.data,
    loading: messageConfigurations.messageConfigurationAddLoading,
    messageSchedules: automatedMessages.messageSchedules,
    reasons: organizationData.organizationData ? organizationData.organizationData.reasons : []
  };
};

export default connect(mapStateToProps, {
  addMessageConfiguration: addMessageConfigurationAction,
  openModal: openModalAction
})(AddTouchpoint);
