import React from "react";
import { useField } from "informed";

import ToggleInputBase from "./ToggleInputBase";

import { CustomOnChangeHandler } from "../../../../types";

type PropsType = {
  fieldName: string;
  label?: string | undefined;
  labelChecked?: string | undefined;
  labelUnchecked?: string | undefined;
  info?: React.ReactNode;
  infoStatus?: "info" | "warning" | "error";
  toggleLabelPosition?: "left" | "right";
  placeholder?: string | undefined;
  disabled?: boolean | undefined;
  labelsAfter?: boolean;
  wrapperClassName?: string;
  displayInverted?: boolean;
  customOnChange?: CustomOnChangeHandler | undefined;
};

// NOTE: Currently, this component does not render error states. Consider adding custom error styles.
const ToggleInput = ({ fieldName, customOnChange, ...props }: PropsType): React.ReactElement => {
  const { fieldState, fieldApi } = useField({
    ...props,
    name: fieldName
  });
  const { value } = fieldState;
  const { setValue, setTouched } = fieldApi;

  const isChecked = !!value;
  const onChangeHandler = (updatedValue: boolean): void => {
    setValue(updatedValue);
    setTouched(true);
    if (customOnChange) {
      customOnChange({ [fieldName]: updatedValue });
    }
  };

  return (
    <ToggleInputBase
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      initialValue={isChecked}
      onChange={onChangeHandler}
      fieldName={fieldName}
    />
  );
};

export default ToggleInput;
