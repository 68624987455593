import React from "react";

type PropsType = {
  size?: number;
};
const AutoBook = ({ size = 24 }: PropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#a)">
        <path
          fill="#494B50"
          fillRule="evenodd"
          d="M7 4a1 1 0 0 0 2 0h6a1 1 0 1 0 2 0h2a1 1 0 0 1 1 1v2H4V5a1 1 0 0 1 1-1h2Zm2-2h6V1a1 1 0 1 1 2 0v1h2a3 3 0 0 1 3 3v5.755a7.995 7.995 0 0 0-2-1.173V9H4v10a1 1 0 0 0 1 1h4.582c.293.726.69 1.398 1.173 2H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h2V1a1 1 0 0 1 2 0v1Zm14.207 9.793a1 1 0 0 1 0 1.414l-5.75 5.75a1 1 0 0 1-1.414 0l-1.75-1.75a1 1 0 0 1 1.414-1.414l1.043 1.043 5.043-5.043a1 1 0 0 1 1.414 0Zm-7.2 1.332a4 4 0 0 1 2.54.186 1 1 0 1 0 .774-1.844 6 6 0 1 0 3.662 5.08 1 1 0 1 0-1.994.151 4 4 0 1 1-4.982-3.573Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AutoBook;
