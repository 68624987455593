import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import cx from "classnames";

import Heading from "../../../ui/Heading";
import TableGrid from "../../../ui/TableGrid";
import Loader from "../../../ui/Loader";
import Search from "../../../ui/Search";
import Button from "../../../ui/Button";
import { Plus, Duplicate } from "../../../ui/Icon";
import DownloadCell from "./DownloadCell";

import { PermissionsGuard } from "../../../../hooks/usePermissions";

import {
  fetchDataForOrganization,
  fetchDocuments as fetchDocumentsAction,
  addNotification as addNotificationAction,
  openModal as openModalAction,
  OpenModal
} from "../../../../actions";
import { getDocumentSignedUrl } from "../../../../lib";
import copyToClipboard from "../../../../utils/copyToClipboard";

import {
  OrganizationData,
  Document,
  ReduxStateType,
  NewNotification,
  Permissions
} from "../../../../types";
import useSearchFilter from "../../../../hooks/useSearchFilter";
import formatDate from "../../../../utils/formatDate";
import { UserContext } from "../../../providers/UserProvider";
import { UserTypeConstants, ModalTypes } from "../../../../constants";

import styles from "./index.module.scss";

type PropsType = {
  fetchDataForOrganization: (organizationId: number) => void;
  organizationData?: OrganizationData;
  documents: Array<Document>;
  loading: boolean;
  fetchDocuments: () => void;
  addNotification: (data: NewNotification) => void;
  openModal: OpenModal;
};

const DocumentsTable = ({
  fetchDataForOrganization,
  organizationData,
  documents,
  loading,
  fetchDocuments,
  addNotification,
  openModal
}: PropsType): JSX.Element => {
  const user = useContext(UserContext);
  const isAdmin = user.userType === UserTypeConstants.MIKATA_ADMIN;

  // Fetch documents on first load
  useEffect(() => {
    if (user.organizationId) {
      fetchDocuments();
    }
  }, [user.organizationId]);

  const [documentLoading, setDocumentLoading] = useState<{ [id: number]: boolean }>({});

  const downloadDocument = async (documentId: number, filename: string) => {
    setDocumentLoading((state) => {
      return { ...state, [documentId]: true };
    });
    const signedUrl = await getDocumentSignedUrl(documentId);
    setDocumentLoading((state) => {
      return { ...state, [documentId]: false };
    });
    if (!signedUrl) {
      return addNotification({
        type: "error",
        title: "Failed to get download url",
        subtitle: "Please try again",
        autoDismiss: true
      });
    }

    // https://stackoverflow.com/questions/49040247/download-binary-file-with-axios
    const link = document.createElement("a");
    link.href = signedUrl;
    link.target = "_blank";
    link.setAttribute("download", `${filename}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return null;
  };

  const { filteredRows, onSearchChange, onSearchClear } = useSearchFilter<Document>(
    documents,
    (search: string, document: Document) => {
      return !!(
        ((document.filename && document.filename.toLowerCase().includes(search.toLowerCase())) ||
          (document.createdAt && document.createdAt.includes(search))) &&
        !document.shortCode
      );
    }
  );

  const {
    filteredRows: publicFilteredRows,
    onSearchChange: onSearchChangePublic,
    onSearchClear: onSearchClearPublic
  } = useSearchFilter<Document>(documents, (search: string, document: Document) => {
    return !!(
      ((document.filename && document.filename.toLowerCase().includes(search.toLowerCase())) ||
        (document.createdAt && document.createdAt.includes(search))) &&
      document.shortCode
    );
  });

  const documentRows = filteredRows
    .map((document) => {
      return {
        ...document,
        created: formatDate(document.createdAt),
        download: (
          <div className={cx(styles.TableCell, styles.ChatIcon)}>
            <DownloadCell
              document={document}
              downloadDocument={downloadDocument}
              documentLoading={documentLoading}
            />
          </div>
        )
      };
    })
    .sort((a, b) => {
      return b.id - a.id;
    });
  const publicDocumentRows = publicFilteredRows
    .map((document) => {
      return {
        ...document,
        created: formatDate(document.createdAt),
        shortLink: (
          <div>
            <Button inline onClick={() => copyToClipboard(document.shortCode)}>
              <Duplicate size={20} />
            </Button>
          </div>
        ),
        download: (
          <div className={cx(styles.TableCell, styles.ChatIcon)}>
            <DownloadCell
              document={document}
              downloadDocument={downloadDocument}
              documentLoading={documentLoading}
            />
          </div>
        )
      };
    })
    .sort((a, b) => {
      return b.id - a.id;
    });

  if (loading) {
    return <Loader screen />;
  }

  const documentHeaders = isAdmin
    ? [
        { colName: "id", content: "ID" },
        { colName: "filename", content: "File Name" },
        { colName: "s3Path", content: "S3 Path" },
        { colName: "created", content: "Created" },
        { colName: "download", content: "Download" }
      ]
    : [
        { colName: "id", content: "ID" },
        { colName: "filename", content: "File Name" },
        { colName: "created", content: "Created" },
        { colName: "download", content: "Download" }
      ];
  const publicDocumentHeaders = isAdmin
    ? [
        { colName: "id", content: "ID" },
        { colName: "filename", content: "File Name" },
        { colName: "s3Path", content: "S3 Path" },
        { colName: "created", content: "Created" },
        { colName: "shortLink", content: "Link" },
        { colName: "download", content: "Download" }
      ]
    : [
        { colName: "id", content: "ID" },
        { colName: "filename", content: "File Name" },
        { colName: "created", content: "Created" },
        { colName: "shortLink", content: "Link" },
        { colName: "download", content: "Download" }
      ];

  if (!organizationData) {
    return <Loader />;
  }

  return (
    <div>
      <TableGrid
        headers={documentHeaders}
        rows={documentRows}
        headerContent={
          <div className={styles.TableHeader}>
            <Heading className={styles.TableHeaderTitle}>Documents</Heading>
            <div className={styles.TableHeaderGroup}>
              <Search
                classNames={styles.Search}
                onChange={onSearchChange}
                onClear={onSearchClear}
              />
            </div>
          </div>
        }
        maxPageRows={20}
      />
      <br />
      {isAdmin && (
        <TableGrid
          headers={publicDocumentHeaders}
          rows={publicDocumentRows}
          headerContent={
            <div className={styles.TableHeader}>
              <Heading className={styles.TableHeaderTitle}>Public Documents</Heading>
              <div className={styles.TableHeaderGroup}>
                <PermissionsGuard requiredPermissions={[Permissions.STAFF_UPLOADS]}>
                  <Button
                    onClick={() => openModal(ModalTypes.UPLOAD_DOCUMENT, { organizationData })}
                  >
                    Upload Document
                    <Plus />
                  </Button>
                </PermissionsGuard>
                <Search
                  classNames={styles.Search}
                  onChange={onSearchChangePublic}
                  onClear={onSearchClearPublic}
                />
              </div>
            </div>
          }
          maxPageRows={20}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ organizationData, documents }: ReduxStateType) => {
  return {
    organizationData: organizationData?.organizationData,
    documents: documents.documents,
    loading: documents.documentsLoading
  };
};

export default connect(mapStateToProps, {
  fetchDataForOrganization,
  fetchDocuments: fetchDocumentsAction,
  addNotification: addNotificationAction,
  openModal: openModalAction
})(DocumentsTable);
