import React from "react";
import cx from "classnames";
import styles from "./index.module.scss";

export type RichTextEditorTooltipProps = {
  icon?: React.ReactNode;
  children: React.ReactNode;
};

export const RichTextEditorTooltip = ({ icon, children }: RichTextEditorTooltipProps) => {
  return (
    <div className={cx(styles.ToolTip)}>
      {icon}
      <div className={cx(styles.Content)}>{children}</div>
    </div>
  );
};
