import React from "react";
import cx from "classnames";

import Heading from "../../../../../../ui/Heading";
import Text from "../../../../../../ui/Text";
import { SelectInput } from "../../../../../../ui/Input";
import Drawer from "../../../../../../ui/Drawer";

import { usePermissions } from "../../../../../../../hooks/usePermissions";

import { SubscriptionFormState, SUBSCRIPTIONS_NAMESPACE } from "..";

import { Permissions, Option, CustomOnChangeHandler } from "../../../../../../../types";

import styles from "./index.module.scss";

type UnassignedSubscriptionsType = {
  subscriptions: SubscriptionFormState[];
  providerOptions?: Option[];
  onChange: CustomOnChangeHandler;
  chargeOverSubscriptionId?: string;
};

const subscriptionType = [
  { label: "Arrears", value: "arrears" },
  { label: "Advance", value: "advance" }
];

const UnassignedSubscriptions = ({
  subscriptions: unassignedSubScriptions,
  providerOptions,
  onChange,
  chargeOverSubscriptionId = ""
}: UnassignedSubscriptionsType) => {
  const userCanViewBilling = usePermissions([Permissions.VIEW_BILLING], false);
  const userCanEditBilling = usePermissions([Permissions.UPDATE_BILLING], false);

  const assignmentOptions = [
    { label: "Organization", value: "_assignToClinic" },
    ...(providerOptions || [])
  ];

  return (
    <>
      {unassignedSubScriptions.length === 0 && <Text size="M">No unassigned subscriptions</Text>}
      {unassignedSubScriptions.map((sub) => (
        <React.Fragment key={sub.id}>
          <div
            className={cx(styles.ChargeOverSubscriptionCard, {
              [styles.ChargeOverSubscriptionCardNoLineItems]: sub?.lineItems?.length === 0,
              [styles.Hidden]: sub.id === chargeOverSubscriptionId
            })}
          >
            <div className={styles.ProviderSubscriptionInfo}>
              <div className={styles.SubscriptionIdGroup}>
                <Text size="S" className={styles.SubscriptionHeading}>
                  UNASSIGNED SUBSCRIPTION ID
                </Text>
                <Text size="M" className={styles.SubscriptionId}>
                  {sub.id}
                </Text>
              </div>

              <div>
                <SelectInput
                  fieldName={`${SUBSCRIPTIONS_NAMESPACE}.${sub.id}.userId`}
                  label="Assign to"
                  options={assignmentOptions}
                  disabled={userCanViewBilling && !userCanEditBilling}
                  customOnChange={onChange}
                />
              </div>
            </div>
            {sub?.lineItems?.length && sub?.lineItems?.length > 0 ? (
              <Drawer
                initialOpen
                headerContent={
                  <div>
                    <Heading size="S" className={styles.DrawerHeaderContent}>
                      Line Items
                    </Heading>
                  </div>
                }
              >
                <div className={styles.LineItemGroup}>
                  {sub?.lineItems?.map((lineItem) => (
                    <div key={lineItem.productName} className={styles.LineItemCard}>
                      <Heading size="META">{lineItem.productName}</Heading>
                      <Text>{lineItem.id}</Text>
                    </div>
                  ))}
                </div>
              </Drawer>
            ) : (
              <div className={styles.NoLineItems}>
                <Text>No line Items</Text>
              </div>
            )}
          </div>
        </React.Fragment>
      ))}
    </>
  );
};

export default UnassignedSubscriptions;
