import React from "react";
import ReactDOMServer from "react-dom/server";
import cx from "classnames";

import Text from "../../../../../../../../ui/Text";
import Heading from "../../../../../../../../ui/Heading";
import { CircleCheck, Circle, User, Skip, UploadCloud } from "../../../../../../../../ui/Icon";

import { getNodeSummaryContent } from "../helpers";

import { MessageTemplateActionTag } from "../../../../../../../../../types";

import { D3Node } from "../types";

import styles from "./index.module.scss";

const ActionIcons = ({ node }: PropsType) => {
  const showSkippable = node.payloadOptions?.skippable;
  const showCompleteChat = node.tagNames?.includes(MessageTemplateActionTag.COMPLETE_CHAT);
  const showAssociatePatient = node.tagNames?.includes(MessageTemplateActionTag.ASSOCIATE_PATIENT);
  const showPushDemographics = node.tagNames?.includes(MessageTemplateActionTag.PUSH_DEMOGRAPHICS);

  if (!showSkippable && !showCompleteChat && !showAssociatePatient && !showPushDemographics)
    return null;

  return (
    <div className={styles.HeaderGroup2Icons}>
      {showSkippable && (
        <div className={cx(styles.Icon)}>
          <Skip size={16} />
        </div>
      )}
      {showAssociatePatient && (
        <div className={cx(styles.Icon)}>
          <User size={16} />
        </div>
      )}
      {showPushDemographics && (
        <div className={cx(styles.Icon)}>
          <UploadCloud size={16} />
        </div>
      )}
      {showCompleteChat && (
        <div className={cx(styles.Icon, styles.IconGreen)}>
          <CircleCheck size={16} />
        </div>
      )}
    </div>
  );
};

type PropsType = {
  node: D3Node;
};
const LargeMapNode = ({ node }: PropsType) => {
  const { heading, title, details } = getNodeSummaryContent(node);

  return (
    <div className={styles.Card}>
      <div className={styles.Header}>
        <div className={styles.HeaderGroup1}>
          <Heading size="META" className={styles.Heading}>
            {heading}
          </Heading>
        </div>
        <div className={styles.HeaderGroup2}>
          <ActionIcons node={node} />
          <Heading size="META" className={styles.HeaderGroup2Id}>{`ID: ${node.id}`}</Heading>
        </div>
      </div>
      <div className={styles.Content}>
        <div className={styles.ContentInnerCard}>
          <Heading className={styles.ContentTitle} size="S" bold component="h4">
            {title}
          </Heading>
          <Text className={styles.ContentDetails}>{details}</Text>
        </div>
      </div>
    </div>
  );
};

export const buildLargeMapNodeHTML = (node: D3Node) => {
  return ReactDOMServer.renderToStaticMarkup(<LargeMapNode node={node} />);
};

export default LargeMapNode;
