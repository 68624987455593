import React, { useRef } from "react";
import Modal from "react-modal";
import cx from "classnames";

import Button from "../../Button";
import Heading from "../../Heading";
import { Close } from "../../Icon";
import { ResponsiveHide } from "../../Responsive";
import Text from "../../Text";

import { useFocusTrap } from "../useFocusTrap";

import styles from "./index.module.scss";

type PropsType = {
  onClose: () => void;
  children: React.ReactNode;
  isOpen: boolean;
  title?: string;
  subtitle?: string;
  headerButton?: React.ReactNode;
  headerIconButtons?: React.ReactNode;
  contentClassName?: string;
  fullView?: boolean;
  scrollableContent?: boolean;
};

const FullScreenModal = ({
  onClose,
  children,
  isOpen,
  title,
  subtitle,
  headerButton,
  headerIconButtons,
  contentClassName,
  fullView = false,
  scrollableContent = true
}: PropsType): React.ReactElement => {
  const modalRef = useRef<HTMLDivElement>();

  useFocusTrap({ modalRef, isOpen, onClose });

  Modal.setAppElement("#root");

  return (
    <Modal
      isOpen={isOpen}
      portalClassName={styles.Portal}
      className={cx(styles.Modal, { [styles.ModalScrollableContent]: scrollableContent })}
      overlayClassName={styles.Overlay}
      contentLabel={title}
      onRequestClose={onClose}
      shouldFocusAfterRender
      shouldCloseOnEsc
      shouldReturnFocusAfterClose
      contentRef={(ref): void => {
        modalRef.current = ref;
      }}
    >
      <div className={styles.Header}>
        <div className={styles.HeaderContainer}>
          <Button
            id="closeFullScreenModal"
            className={styles.CloseButton}
            type="button"
            inline
            onClick={onClose}
          >
            <Close />
          </Button>
          <ResponsiveHide hideOnMobile>
            <div className={styles.TitleGroup}>
              <Text className={styles.Title}>{title || ""}</Text>
              {subtitle && (
                <Text size="XS" className={styles.Subtitle}>
                  {subtitle}
                </Text>
              )}
            </div>
          </ResponsiveHide>
          <div className={styles.HeaderContent}>
            {headerIconButtons}
            {headerButton}
          </div>
        </div>
      </div>
      <main
        // fullScreenModalScrollContainer is used to get this element for scrolling purposes
        id="fullScreenModalScrollContainer"
        className={cx(styles.Main, contentClassName, {
          [styles.MainFullView]: fullView,
          [styles.MainScrollableContent]: scrollableContent
        })}
      >
        <div
          className={cx(styles.MainContent, {
            [styles.MainContentFullView]: fullView,
            [styles.MainContentScrollable]: scrollableContent
          })}
        >
          <ResponsiveHide hideOnTablet hideOnDesktop>
            <div className={styles.TitleGroupMobile}>
              <Heading size="META">{title || ""}</Heading>
              {subtitle && <Heading size="META">{subtitle}</Heading>}
            </div>
          </ResponsiveHide>
          {children}
        </div>
      </main>
    </Modal>
  );
};

export default FullScreenModal;
