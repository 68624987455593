import React, { useState, useMemo, useRef, useEffect } from "react";
import { connect } from "react-redux";
import cx from "classnames";

import { Form } from "../../../../../../ui/Input";
import { ContextVariable } from "../../../../../../ui/Input/JsonLogicInput/types";
import AddReportTemplateCardButton from "./AddReportTemplateCardButton";
import ReportTemplateCardFormContent from "./ReportTemplateCardFormContent";

import {
  createReportTemplateCard as createReportTemplateCardAction,
  CreateReportTemplateCardData,
  updateReportTemplateCard as updateReportTemplateCardAction,
  openModal as OpenModalAction,
  OpenModal
} from "../../../../../../../actions";
import { chatPlaceholders } from "../../../../../../../utils/validators";

import {
  ReportTemplateCard,
  ReduxStateType,
  ReportTemplateCardType,
  Tag
} from "../../../../../../../types";

import styles from "./reportTemplateCardForm.module.scss";

type PropsType = {
  card: ReportTemplateCard;
  viewOnly: boolean;
  contextVariables: ContextVariable[];
  createReportTemplateCard: (data: CreateReportTemplateCardData, reportTemplateId: number) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateReportTemplateCard: (templateId: number, cardId: number, data: any) => void;
  reportTemplateCardUpdateLoading: {
    [id: string]: boolean;
  };
  reportTemplateCardAddLoading: {
    [id: string]: boolean;
  };
  openModal: OpenModal;
  onAddCard: (card: ReportTemplateCard) => void;
  setTemporaryCards: React.Dispatch<React.SetStateAction<ReportTemplateCard[]>>;
  reportTemplateCards: Array<ReportTemplateCard>;
  latestCreatedCardId?: string | number;
  availableSubReportTags: Array<Tag>;
};

export type ReportTemplateCardFormValues = {
  displayName: string;
  type: ReportTemplateCardType;
  order: string | null;
  condition: JSON | null;
  thenContent: string;
  elseContent: string;
  content: string;
  parentId: string;
  categoryTagId: number | null;
};

const generateLabel = (reportTemplateCard: ReportTemplateCard) => {
  return reportTemplateCard.displayName
    ? reportTemplateCard.displayName
    : reportTemplateCard.id.toString();
};

const ReportTemplateCardForm = ({
  card,
  contextVariables,
  viewOnly = false,
  createReportTemplateCard,
  updateReportTemplateCard,
  reportTemplateCardUpdateLoading,
  reportTemplateCardAddLoading,
  openModal,
  onAddCard,
  setTemporaryCards,
  reportTemplateCards,
  latestCreatedCardId = "",
  availableSubReportTags
}: PropsType) => {
  const isSavedCard = typeof card.id === "number";
  const [contentMode, setContentMode] = useState("thenContent");
  const contentValidator = chatPlaceholders(
    contextVariables.map((contextVariable) => contextVariable.inputName)
  );

  const subReportTagOptions = availableSubReportTags.map((tag) => {
    return {
      label: tag.name,
      value: tag.id
    };
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const focusRef = useRef<any>(null);

  const isSection = card.type === ReportTemplateCardType.SECTION;
  const isSubReportSection = card.type === ReportTemplateCardType.SUB_REPORT_SECTION;
  const sectionOptions = useMemo(() => {
    if (isSection) {
      return reportTemplateCards
        .filter(
          (reportTemplateCard) =>
            reportTemplateCard.type === ReportTemplateCardType.SECTION &&
            reportTemplateCard.parentId === null
        )
        .map((reportTemplateCard) => {
          return {
            label: generateLabel(reportTemplateCard),
            value: (reportTemplateCard.id as number).toString()
          };
        });
    }
    return reportTemplateCards
      .filter((reportTemplateCard) => reportTemplateCard.type === ReportTemplateCardType.SECTION)
      .map((reportTemplateCard) => {
        return {
          label: generateLabel(reportTemplateCard),
          value: (reportTemplateCard.id as number).toString()
        };
      });
  }, [reportTemplateCards]);

  const filteredSectionOptions = useMemo(() => {
    return sectionOptions.filter((option) => option.value !== card.id?.toString());
  }, [sectionOptions]);

  useEffect(() => {
    const isLatestAddition = latestCreatedCardId && latestCreatedCardId.toString() === card.id;

    if (isLatestAddition && focusRef.current) {
      focusRef.current.focus();
    }
  }, [focusRef.current, latestCreatedCardId]);

  const [isOpen, setIsOpen] = useState(!isSavedCard);

  const save = async (formValues: ReportTemplateCardFormValues) => {
    if (typeof card.id === "number") {
      await updateReportTemplateCard(card.reportTemplateId, card.id, {
        displayName: formValues.displayName ? formValues.displayName : "",
        type: formValues.type ? formValues.type : "",
        order: formValues.order ? formValues.order : null,
        condition: formValues.condition ? formValues.condition : null,
        thenContent: formValues.thenContent ? formValues.thenContent : "",
        elseContent: formValues.elseContent ? formValues.elseContent : "",
        content: formValues.content ? formValues.content : "",
        parentId: formValues.parentId ? formValues.parentId : null,
        categoryTagId: formValues.categoryTagId ? formValues.categoryTagId : null
      });
    } else {
      const newCardData = {
        id: card.id,
        displayName: formValues.displayName ? formValues.displayName : "",
        type: formValues.type ? formValues.type : "",
        order: formValues.order ? formValues.order : "",
        condition: formValues.condition ? formValues.condition : null,
        thenContent: formValues.thenContent ? formValues.thenContent : "",
        elseContent: formValues.elseContent ? formValues.elseContent : "",
        content: formValues.content ? formValues.content : "",
        parentId: formValues.parentId ? parseInt(formValues.parentId, 10) : null,
        categoryTagId: formValues.categoryTagId ? formValues.categoryTagId : null
      };
      await createReportTemplateCard(newCardData, Number(card.reportTemplateId));
      setTemporaryCards([]);
    }
  };

  const initialValues: ReportTemplateCardFormValues = {
    ...card,
    order: card?.order?.toString() || "",
    parentId: card.parentId ? card.parentId.toString() : "",
    displayName: card.displayName || "",
    condition: card.condition || null,
    thenContent: card.thenContent || "",
    elseContent: card.elseContent || "",
    type: card.type || "",
    content: card.content || "",
    categoryTagId: card.categoryTagId || null
  };

  const showAddFirstSubSection =
    card.type === ReportTemplateCardType.SECTION &&
    card.children.length === 0 &&
    isSavedCard &&
    isOpen;

  return (
    <div
      className={cx(styles.Wrapper, {
        [styles.WrapperNested]: card.depth > 0,
        [styles.WrapperNoChildren]: showAddFirstSubSection
      })}
    >
      <div className={styles.WrapperFocus}>
        {!viewOnly && (
          <div className={styles.AddButtonTop}>
            <AddReportTemplateCardButton card={card} onAddCard={onAddCard} direction="above" />
          </div>
        )}
        <Form
          initialValues={initialValues}
          onSubmit={(formState) => save(formState.values as ReportTemplateCardFormValues)}
          key={card.id}
        >
          <ReportTemplateCardFormContent
            card={card}
            viewOnly={viewOnly}
            contextVariables={contextVariables}
            reportTemplateCardAddLoading={reportTemplateCardAddLoading}
            reportTemplateCardUpdateLoading={reportTemplateCardUpdateLoading}
            initialValues={initialValues}
            subReportTagOptions={subReportTagOptions}
            contentMode={contentMode}
            setContentMode={setContentMode}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isSubReportSection={isSubReportSection}
            focusRef={focusRef}
            isSection={isSection}
            filteredSectionOptions={filteredSectionOptions}
            contentValidator={contentValidator}
            isSavedCard={isSavedCard}
            openModal={openModal}
            setTemporaryCards={setTemporaryCards}
            availableSubReportTags={availableSubReportTags}
          />
        </Form>
        {!viewOnly && (
          <div
            className={cx(styles.AddButtonBottom, {
              [styles.AddButtonBottomNoChildren]: showAddFirstSubSection
            })}
          >
            {showAddFirstSubSection && (
              <div className={styles.AddButtonWithin}>
                <AddReportTemplateCardButton card={card} onAddCard={onAddCard} direction="within" />
              </div>
            )}

            <AddReportTemplateCardButton card={card} onAddCard={onAddCard} direction="below" />
          </div>
        )}
      </div>

      {card.children && card.children.length > 0 && isOpen && (
        <div className={cx(styles.Children)}>
          {card.children.map((child) => {
            return (
              <ReportTemplateCardForm
                key={child.id}
                card={child}
                viewOnly={viewOnly}
                reportTemplateCardUpdateLoading={reportTemplateCardUpdateLoading}
                reportTemplateCardAddLoading={reportTemplateCardAddLoading}
                updateReportTemplateCard={updateReportTemplateCard}
                openModal={openModal}
                onAddCard={onAddCard}
                createReportTemplateCard={createReportTemplateCard}
                setTemporaryCards={setTemporaryCards}
                reportTemplateCards={reportTemplateCards}
                contextVariables={contextVariables}
                latestCreatedCardId={latestCreatedCardId}
                availableSubReportTags={availableSubReportTags}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ reportTemplates }: ReduxStateType) => {
  return {
    availableSubReportTags: reportTemplates.availableSubReportTags,
    reportTemplateCards: reportTemplates.reportTemplateCards,
    reportTemplateCardUpdateLoading: reportTemplates.reportTemplateCardUpdateLoading,
    reportTemplateCardAddLoading: reportTemplates.reportTemplateCardAddLoading
  };
};

export default connect(mapStateToProps, {
  createReportTemplateCard: createReportTemplateCardAction,
  updateReportTemplateCard: updateReportTemplateCardAction,
  openModal: OpenModalAction
})(ReportTemplateCardForm);
