import React, { useMemo, useContext, useState } from "react";

import { connect } from "react-redux";

import { EncounterSocketContext } from "../../../../../providers/EncounterSocketProvider";
import { getLanguageDisplay } from "../../../../../../hooks/useAudioSettings";

import ChatSummaries from "../../../../ChatSummaries";
import DirectMessageSummaries from "../../../../DirectMessageSummaries";
import Button from "../../../../../ui/Button";
import Heading from "../../../../../ui/Heading";
import DoMoreWithMika from "../../../../DoMoreWithMika";
import { ChevronRight, AudioSettingsIcon } from "../../../../../ui/Icon";
import { AnnouncementTooltip } from "../../../../../ui/Announcements";
import AudioSettingsModal from "./AudioSettingsModal";

import Transcripts from "./Transcripts";
import { Timers } from "./Timers";
import { AudioTranscripts } from "./AudioTranscripts";

import { fetchAppointmentsDetail as fetchAppointmentsDetailAction } from "../../../../../../actions";
import filterDirectMessageConversations from "../../../../../../utils/filterDirectMessageConversations";

import {
  ReduxStateType,
  AppointmentDetail,
  ScribeToolMode,
  AnnouncementName,
  RecordingState,
  RecordingSessionStatus
} from "../../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  toolMode: ScribeToolMode | null;
  recording: RecordingState | null;
  clearToolMode: () => void;
  appointment?: AppointmentDetail;
  setActiveAppointmentNoteId: (noteId: number) => void;
};

const ToolModeTitleMap = {
  [ScribeToolMode.CHAT_TRANSCRIPTS]: "Chat Transcripts",
  [ScribeToolMode.CHAT_SUMMARIES]: "Chat Summaries",
  [ScribeToolMode.SENT_MESSAGES]: "Messages Sent",
  [ScribeToolMode.TIMERS]: "Timer",
  [ScribeToolMode.AUDIO_TRANSCRIPT]: "Audio Transcript",
  [ScribeToolMode.DO_MORE_WITH_MIKA]: "Do more with Mika AI"
};

const ScribeTools = ({
  toolMode,
  recording,
  setActiveAppointmentNoteId,
  clearToolMode,
  appointment
}: PropsType): JSX.Element => {
  const [audioSettingsOpen, setAudioSettingsOpen] = useState(false);
  const languageDisplay = getLanguageDisplay();
  const { pauseSession } = useContext(EncounterSocketContext);

  const directMessageConversations = useMemo(
    () => (appointment && filterDirectMessageConversations(appointment.conversations, true)) || [],
    [appointment]
  );
  const regularConversations = useMemo(
    () => (appointment && filterDirectMessageConversations(appointment.conversations, false)) || [],
    [appointment]
  );

  const openSettingsModal = () => {
    pauseSession(true);
    setAudioSettingsOpen(true);
  };

  return (
    <>
      {toolMode && (
        <div className={styles.Container}>
          <div className={styles.ToolHeader}>
            <div className={styles.ToolHeaderSpacer}>
              <Button className={styles.ToolHeaderClose} onClick={clearToolMode} inline>
                <ChevronRight size={24} />
              </Button>
              <Heading size="S" bold className={styles.ToolHeaderTitle}>
                {ToolModeTitleMap[toolMode]}
              </Heading>
            </div>

            {toolMode === ScribeToolMode.AUDIO_TRANSCRIPT && (
              <AnnouncementTooltip
                arrowPosition="topRight"
                announcementName={AnnouncementName.SCRIBE_MULTI_LANGUAGE}
                announcementShowable={recording?.status !== RecordingSessionStatus.COMPLETE}
              >
                <div className={styles.LanguageSettings}>
                  <Heading
                    size="META"
                    className={styles.LanguageDisplay}
                    key={`scribe-tools-language-display-${languageDisplay}`}
                  >
                    {languageDisplay}
                  </Heading>
                  <Button
                    className={styles.ToolHeaderClose}
                    inline
                    onClick={() => openSettingsModal()}
                  >
                    <AudioSettingsIcon size={24} />
                  </Button>
                </div>
              </AnnouncementTooltip>
            )}
          </div>
          <div className={styles.ToolContent} id="scribe-tool-content-section">
            {toolMode === ScribeToolMode.CHAT_TRANSCRIPTS && (
              <Transcripts appointment={appointment} />
            )}
            {toolMode === ScribeToolMode.CHAT_SUMMARIES && appointment?.reports && (
              <ChatSummaries appointment={appointment} conversations={regularConversations} />
            )}
            {toolMode === ScribeToolMode.SENT_MESSAGES && (
              <DirectMessageSummaries conversations={directMessageConversations} />
            )}
            {toolMode === ScribeToolMode.DO_MORE_WITH_MIKA && appointment && (
              <DoMoreWithMika
                setActiveAppointmentNoteId={setActiveAppointmentNoteId}
                patientId={appointment.patientId}
                appointmentId={appointment.id}
              />
            )}
            {toolMode === ScribeToolMode.TIMERS && (
              <div>
                <Timers />
              </div>
            )}
            {toolMode === ScribeToolMode.AUDIO_TRANSCRIPT && <AudioTranscripts />}
          </div>
        </div>
      )}
      <AudioSettingsModal isOpen={audioSettingsOpen} setAudioSettingsOpen={setAudioSettingsOpen} />
    </>
  );
};

const mapStateToProps = ({ appointments, notes }: ReduxStateType) => {
  return {
    appointment: appointments.details.data,
    syncLoading: notes.syncLoading
  };
};

export default connect(mapStateToProps, {
  fetchAppointmentsDetail: fetchAppointmentsDetailAction
})(ScribeTools);
