import React from "react";
import { connect } from "react-redux";

import Button from "../../../ui/Button";
import Heading from "../../../ui/Heading";
import { FloatModal } from "../../../ui/Modal";
import Text from "../../../ui/Text";
import { Form, CheckboxGroup } from "../../../ui/Input";

import {
  deleteInstruction as deleteInstructionAction,
  InstructionDeleteData,
  closeModal as closeModalAction
} from "../../../../actions";

import { isRequired } from "../../../../utils/validators";

import styles from "./index.module.scss";

import { Instruction, ReduxStateType } from "../../../../types";

type DeleteInstructionFormData = {
  confirmed: Array<string>;
};

type PropsType = {
  instruction: Instruction;
  loading?: boolean;
  onSuccess?: () => void;
  closeModal: () => void;
  deleteInstruction: (data: InstructionDeleteData) => void;
};

const initialFormState: DeleteInstructionFormData = {
  confirmed: []
};

const confirmedValidator = isRequired("Confirmation required");

const DeleteInstruction = ({
  loading,
  onSuccess,
  closeModal,
  deleteInstruction,
  instruction
}: PropsType) => {
  const onDelete = () => {
    deleteInstruction({
      id: instruction.id,
      onSuccess: () => {
        closeModal();
        if (onSuccess) {
          onSuccess();
        }
      }
    });
  };

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L">Delete Instruction</Heading>
      <Text component="p">{`You are about to permanently delete the instruction "${instruction.displayName}". Is this what you want to do?`}</Text>
      <Form className={styles.Form} onSubmit={onDelete} initialValues={initialFormState}>
        <CheckboxGroup
          fieldName="confirmed"
          options={[{ label: "Yes, I want to delete", value: "true" }]}
          validate={confirmedValidator}
        />
        <div className={styles.ButtonGroup}>
          <Button inline onClick={closeModal} disabled={loading}>
            Cancel
          </Button>
          <Button type="submit" disabled={loading}>
            Delete
          </Button>
        </div>
      </Form>
    </FloatModal>
  );
};
const mapStateToProps = ({ instructions }: ReduxStateType) => {
  return {
    loading: instructions.deleteLoading
  };
};

export default connect(mapStateToProps, {
  deleteInstruction: deleteInstructionAction,
  closeModal: closeModalAction
})(DeleteInstruction);
