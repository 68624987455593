import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import cx from "classnames";

import AppointmentBookingCard from "../../AppointmentRequestsTable/AppointmentRequestDetails/AppointmentBookingCard";
import BookingSummary from "../../AppointmentRequestsTable/AppointmentRequestDetails/BookingSummary";
import Button from "../../../../ui/Button";
import Card from "../../../../ui/Card";
import ChatSummary from "../../AppointmentRequestsTable/AppointmentRequestDetails/ChatSummary";
import Heading from "../../../../ui/Heading";
import { FileText } from "../../../../ui/Icon";
import Loader from "../../../../ui/Loader";
import ReportCard from "../../../../ui/ReportCard";
import Timeline from "../../../Timeline";
import TranscriptMessages from "../../../../ui/TranscriptMessages";

import {
  addNotification as addNotificationAction,
  fetchAppointmentRequestsDetail as fetchAppointmentRequestsDetailAction,
  getAppointmentRequestHistory as getAppointmentRequestHistoryAction
} from "../../../../../actions";

import { AppointmentRequestStatuses, AppointmentRequestModes } from "../../../../../constants";

import {
  AppointmentRequest,
  AppointmentRequestDetail,
  AppointmentRequestDetailTabModes,
  AppointmentBookingData,
  HistoryEvent,
  NewNotification,
  Permissions,
  ReduxStateType,
  Report,
  Task,
  TranscriptMessage
} from "../../../../../types";

import { PermissionsGuard } from "../../../../../hooks/usePermissions";

import { getRequisitionUrl } from "../../../../../lib";

import TaskDetailsLayout from "./TaskDetailsLayout";

import styles from "./AppointmentRequestTask.module.scss";

type PropsType = {
  appointmentRequestDetails?: AppointmentRequestDetail;
  appointmentRequestHistoryLoading: boolean;
  appointmentRequestHistory?: HistoryEvent[];
  bookingData: AppointmentBookingData | null;
  detailsOpen: boolean;
  task: Task;
  loading: boolean;
  preferQuickView: boolean;
  reports: Report[];
  transcript: TranscriptMessage[];
  addNotification: (data: NewNotification) => void;
  fetchAppointmentRequestsDetail?: (
    appointmentRequestId: number,
    options?: { silent: boolean }
  ) => void;
  getAppointmentRequestHistory?: (id: number) => void;
  closeDrawer: () => void;
};

const initialApptRequest = {
  id: 0,
  bookingData: null,
  created_at: "",
  hasRequisition: null,
  isMobile: false,
  organizationId: 0,
  patientBirthday: "",
  patientFirstName: "",
  patientLastName: "",
  patientNumber: "",
  patientHealthcareNumber: "",
  status: "",
  uploadIds: [],
  updated_at: ""
};

const AppointmentRequestTask = ({
  appointmentRequestDetails,
  appointmentRequestHistoryLoading,
  appointmentRequestHistory,
  bookingData,
  detailsOpen,
  reports,
  transcript,
  addNotification,
  fetchAppointmentRequestsDetail,
  getAppointmentRequestHistory,
  closeDrawer,
  task,
  loading,
  preferQuickView
}: PropsType) => {
  const { appointmentRequestId } = task;
  const [tabMode, setTabMode] = useState(
    preferQuickView
      ? AppointmentRequestDetailTabModes.SUMMARY
      : AppointmentRequestDetailTabModes.CHAT
  );

  let appointmentRequest: AppointmentRequest = { ...initialApptRequest };

  if (appointmentRequestDetails) {
    appointmentRequest = {
      id: appointmentRequestDetails.id,
      bookingData: appointmentRequestDetails.bookingData,
      created_at: appointmentRequestDetails.created_at,
      dateTimePreference: appointmentRequestDetails.dateTimePreference,
      hasRequisition: appointmentRequestDetails.hasRequisition,
      isMobile: appointmentRequestDetails.isMobile,
      locationPreference: appointmentRequestDetails.locationPreference,
      organizationId: appointmentRequestDetails.organizationId,
      patientBirthday: appointmentRequestDetails.patientBirthday,
      patientFirstName: appointmentRequestDetails.patientFirstName,
      patientLastName: appointmentRequestDetails.patientLastName,
      patientNumber: appointmentRequestDetails.patientNumber,
      patientHealthcareNumber: appointmentRequestDetails.patientHealthcareNumber,
      reasons: appointmentRequestDetails.reasons,
      status: appointmentRequestDetails.status,
      uploadIds: appointmentRequestDetails.uploadIds,
      updated_at: appointmentRequestDetails.updated_at
    };
  }

  let mode = "";
  if (!loading && bookingData && bookingData.selectedAppointmentSlots.length > 0) {
    if (
      appointmentRequest?.status === AppointmentRequestStatuses.BOOKED ||
      appointmentRequest?.status === AppointmentRequestStatuses.AUTO_BOOKED
    ) {
      mode = AppointmentRequestModes.VIEW_BOOKING;
    } else {
      mode = AppointmentRequestModes.APPOINTMENT_BOOKING;
    }
  } else if (!loading) {
    mode = AppointmentRequestModes.SIMPLE_REQUEST;
  }

  const initialReqLoadingState: { [id: string]: boolean[] } = {};
  if (appointmentRequest) {
    initialReqLoadingState[appointmentRequest.id] = new Array(
      appointmentRequest.uploadIds.length
    ).fill(false);
  }
  const [requisitionLoading, setRequisitionLoading] = useState<{ [id: string]: boolean[] }>(
    initialReqLoadingState
  );

  useEffect(() => {
    if (
      !loading &&
      mode &&
      (mode === AppointmentRequestModes.VIEW_BOOKING ||
        mode === AppointmentRequestModes.APPOINTMENT_BOOKING)
    ) {
      setTabMode(AppointmentRequestDetailTabModes.BOOKING);
    } else if (!loading) {
      setTabMode(AppointmentRequestDetailTabModes.SUMMARY);
    }
  }, [loading]);

  useEffect(() => {
    const silent =
      !!appointmentRequestDetails && appointmentRequestDetails.id === appointmentRequestId;
    if (detailsOpen && appointmentRequestId && fetchAppointmentRequestsDetail) {
      fetchAppointmentRequestsDetail(appointmentRequestId, { silent });
    }
    if (appointmentRequestId && getAppointmentRequestHistory) {
      getAppointmentRequestHistory(appointmentRequestId);
    }
  }, [appointmentRequestId, detailsOpen]);

  useEffect(() => {
    if (
      !preferQuickView &&
      (tabMode === AppointmentRequestDetailTabModes.SUMMARY ||
        tabMode === AppointmentRequestDetailTabModes.BOOKING)
    ) {
      setTabMode(AppointmentRequestDetailTabModes.CHAT);
    }
  }, [preferQuickView]);

  // TODO: copied from AppointmentRequestsTable ... move to somewhere that can be shared
  const downloadRequisition = async (requestId: number, uploadId: number, uploadIdx: number) => {
    setRequisitionLoading((state) => {
      let newState = state[requestId];
      if (!newState || !newState[uploadIdx]) newState = new Array(uploadIdx).fill(false);
      newState[uploadIdx] = true;
      return { ...state, [requestId]: newState };
    });
    const { success, url } = await getRequisitionUrl(requestId, uploadId);
    setRequisitionLoading((state) => {
      const newState = state[requestId];
      newState[uploadIdx] = false;
      return { ...state, [requestId]: newState };
    });
    if (!success || !url) {
      return addNotification({
        type: "error",
        title: "Failed to get download url",
        subtitle: "Please try again",
        autoDismiss: true
      });
    }

    // https://stackoverflow.com/questions/49040247/download-binary-file-with-axios
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.setAttribute("download", `request-attachment-${requestId}-${uploadId}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return null;
  };

  const AppointmentRequestTabs = () => {
    return (
      <div className={cx(styles.Tabs, { [styles.BottomBorder]: preferQuickView })}>
        {preferQuickView &&
          bookingData &&
          (mode === AppointmentRequestModes.VIEW_BOOKING ||
            mode === AppointmentRequestModes.APPOINTMENT_BOOKING) && (
            <Button
              id="apptRequestTaskBooking"
              inline
              className={cx(styles.Tab, {
                [styles.TabActive]: tabMode === AppointmentRequestDetailTabModes.BOOKING,
                [styles.TabQuickView]: preferQuickView
              })}
              onClick={() => setTabMode(AppointmentRequestDetailTabModes.BOOKING)}
            >
              Booking
            </Button>
          )}
        {preferQuickView && (
          <Button
            id="apptRequestTaskSummary"
            inline
            className={cx(styles.Tab, {
              [styles.TabActive]: tabMode === AppointmentRequestDetailTabModes.SUMMARY,
              [styles.TabQuickView]: preferQuickView
            })}
            onClick={() => setTabMode(AppointmentRequestDetailTabModes.SUMMARY)}
          >
            Summary
          </Button>
        )}
        <Button
          id="apptRequestTaskTranscripts"
          inline
          className={cx(styles.Tab, {
            [styles.TabActive]: tabMode === AppointmentRequestDetailTabModes.CHAT,
            [styles.TabQuickView]: preferQuickView
          })}
          onClick={() => setTabMode(AppointmentRequestDetailTabModes.CHAT)}
        >
          Transcripts
        </Button>
        <PermissionsGuard requiredPermissions={[Permissions.GET_APPOINTMENTS_REQUEST_HISTORY]}>
          <Button
            id="apptRequestTasktimeline"
            inline
            className={cx(styles.Tab, {
              [styles.TabActive]: tabMode === AppointmentRequestDetailTabModes.TIMELINE,
              [styles.TabQuickView]: preferQuickView
            })}
            onClick={() => setTabMode(AppointmentRequestDetailTabModes.TIMELINE)}
          >
            Timeline
          </Button>
        </PermissionsGuard>
      </div>
    );
  };

  return (
    <TaskDetailsLayout task={task}>
      {loading ? (
        <Loader screen />
      ) : (
        <div>
          {preferQuickView && <AppointmentRequestTabs />}
          <div
            className={cx(styles.TabContentWrapper, {
              [styles.TabContentWrapperOneCol]: preferQuickView
            })}
          >
            {preferQuickView && tabMode === AppointmentRequestDetailTabModes.BOOKING && (
              <div>
                {appointmentRequest &&
                  bookingData &&
                  mode === AppointmentRequestModes.APPOINTMENT_BOOKING && (
                    <AppointmentBookingCard
                      appointmentRequestId={appointmentRequest.id}
                      personalHealthNumber={appointmentRequest.patientHealthcareNumber}
                      bookingData={bookingData}
                      closeFullScreenModal={() => {
                        closeDrawer();
                      }}
                      preferQuickView={preferQuickView}
                    />
                  )}
                {appointmentRequest &&
                  bookingData &&
                  mode === AppointmentRequestModes.VIEW_BOOKING && (
                    <BookingSummary
                      appointmentRequest={appointmentRequest}
                      bookingData={bookingData}
                      preferQuickView={preferQuickView}
                      closeDrawer={closeDrawer}
                    />
                  )}
              </div>
            )}
            {(!preferQuickView ||
              (preferQuickView && tabMode === AppointmentRequestDetailTabModes.SUMMARY)) && (
              <div>
                {appointmentRequest && (
                  <ChatSummary
                    appointmentRequest={appointmentRequest}
                    mode={mode}
                    preferQuickView={preferQuickView}
                    downloadRequisition={downloadRequisition}
                    requisitionLoading={requisitionLoading}
                  />
                )}
                {reports.map((report) => {
                  return (
                    <Card key={report.id} className={preferQuickView ? styles.QuickView : ""}>
                      <Heading size="S" bold className={styles.IconHeading}>
                        {preferQuickView && <FileText />}
                        <span className={preferQuickView ? styles.IconHeadingText : ""}>
                          Report
                        </span>
                      </Heading>
                      <ReportCard key={report.id} report={report} />
                    </Card>
                  );
                })}
              </div>
            )}
            <div>
              {!preferQuickView && <AppointmentRequestTabs />}
              {tabMode === AppointmentRequestDetailTabModes.CHAT && (
                <div>
                  {!preferQuickView &&
                    appointmentRequest &&
                    bookingData &&
                    mode === AppointmentRequestModes.APPOINTMENT_BOOKING && (
                      <AppointmentBookingCard
                        appointmentRequestId={appointmentRequest.id}
                        personalHealthNumber={appointmentRequest.patientHealthcareNumber}
                        bookingData={bookingData}
                        closeFullScreenModal={() => {}} // TODO - change
                        preferQuickView={preferQuickView}
                      />
                    )}
                  {!preferQuickView &&
                    appointmentRequest &&
                    bookingData &&
                    mode === AppointmentRequestModes.VIEW_BOOKING && (
                      <BookingSummary
                        appointmentRequest={appointmentRequest}
                        bookingData={bookingData}
                        closeDrawer={closeDrawer}
                      />
                    )}
                  <Card
                    id="apptRequestTaskTranscripts"
                    className={preferQuickView ? styles.QuickView : ""}
                    collapsible={!preferQuickView}
                    headerContent={
                      !preferQuickView ? (
                        <Heading size="M" component="span" className={cx(styles.Heading)}>
                          Transcripts
                        </Heading>
                      ) : null
                    }
                  >
                    <TranscriptMessages transcript={transcript} loading={false} />
                  </Card>
                </div>
              )}
              {tabMode === AppointmentRequestDetailTabModes.TIMELINE && (
                <div>
                  <Timeline
                    historyLoading={appointmentRequestHistoryLoading}
                    history={appointmentRequestHistory}
                    preferQuickView={preferQuickView}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </TaskDetailsLayout>
  );
};

const mapStateToProps = ({ appointmentRequestsInfo }: ReduxStateType) => {
  return {
    loading: appointmentRequestsInfo.details.data ? appointmentRequestsInfo.details.loading : true,
    appointmentRequestDetails: appointmentRequestsInfo.details.data,
    reports: appointmentRequestsInfo.details.data
      ? appointmentRequestsInfo.details.data.reports
      : [],
    transcript: appointmentRequestsInfo.details.data
      ? appointmentRequestsInfo.details.data.transcript
      : [],
    bookingData: appointmentRequestsInfo.details.data
      ? appointmentRequestsInfo.details.data.bookingData
      : null,
    appointmentRequestHistoryLoading: appointmentRequestsInfo.appointmentRequestHistoryLoading,
    appointmentRequestHistory: appointmentRequestsInfo.appointmentRequestHistory
  };
};

export default connect(mapStateToProps, {
  addNotification: addNotificationAction,
  fetchAppointmentRequestsDetail: fetchAppointmentRequestsDetailAction,
  getAppointmentRequestHistory: getAppointmentRequestHistoryAction
})(AppointmentRequestTask);
