import { InputValue } from "../../types";

export const isNodeInputName = (message?: string) => (value: InputValue) => {
  const regexPattern = /^([A-Za-z]+\.?[A-Za-z]+)$/;
  const errorMessage = message || "Please enter a valid input name";

  if (!regexPattern.test(value)) {
    return errorMessage;
  }
  return (!value || (typeof value === "string" && !value.trim())) && value !== 0
    ? errorMessage
    : undefined;
};
