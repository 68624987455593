import React from "react";

type PropsType = {
  size?: number;
};
const UserUnknown = ({ size = 28 }: PropsType) => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      width={`${size * (28 / size)}px`}
      height={`${size * (28 / size)}px`}
    >
      <circle cx="14" cy="14" r="14" fill="none" />
      <g clipPath="url(#a)">
        <path fill="#fff" d="M7 7h16v16H7z" />
        <path
          fill="#1D2029"
          fillRule="evenodd"
          d="M9 14.333a1.333 1.333 0 1 1 2.667 0 1.333 1.333 0 0 1-2.667 0Zm1.333-2.666a2.667 2.667 0 1 0 0 5.333 2.667 2.667 0 0 0 0-5.333Zm4.667 0A.667.667 0 0 0 15 13h6.667a.667.667 0 0 0 0-1.333H15ZM14.333 15c0-.368.299-.667.667-.667h6.667a.667.667 0 0 1 0 1.334H15a.667.667 0 0 1-.667-.667ZM15 17a.667.667 0 1 0 0 1.333h3.333a.667.667 0 0 0 0-1.333H15Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M7 7h16v16H7z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserUnknown;
