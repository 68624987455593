import React from "react";

type PropsType = {
  size?: number;
};

const Lock = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group_33" data-name="Group 33">
        <path
          id="Combined_Shape-2"
          data-name="Combined Shape"
          d="M3,22a3,3,0,0,1-3-3V12A3,3,0,0,1,3,9H4V6A6,6,0,0,1,16,6V9h1a3,3,0,0,1,3,3v7a3,3,0,0,1-3,3ZM2,12v7a1,1,0,0,0,1,1H17a1,1,0,0,0,1-1V12a1,1,0,0,0-1-1H3A1,1,0,0,0,2,12ZM14,9V6A4,4,0,1,0,6,6V9Z"
          transform="translate(2 1)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default Lock;
