import React, { useRef } from "react";
import cx from "classnames";
import { ContentState, ContentBlock } from "draft-js";

import { MikaContentEntityData, MikaContentStatus } from "../../types";

import styles from "./index.module.scss";

export type MikaContentDecoratorProps = {
  decoratedText: string;
  children: React.ReactNode;
  offsetKey?: string;
  contentState: ContentState;
  entityKey: string;
  removeMikaContentByEntityKey: (contentEntityKey: string) => void;
};

export const mikaContentStrategy = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) => {
  const entityRanges = contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();

      if (entityKey === null) {
        return false;
      }

      const entityType: string = contentState.getEntity(entityKey)?.getType();
      const isMikaContent = Boolean(entityType) && entityType === "MIKA_CONTENT";

      return isMikaContent;
    },
    (start, end) => {
      callback(start, end);
    }
  );

  return entityRanges;
};

export const MikaContentDecorator = ({
  decoratedText,
  children,
  offsetKey,
  contentState,
  entityKey,
  removeMikaContentByEntityKey
}: MikaContentDecoratorProps) => {
  const mikaContentRef = useRef<HTMLDivElement>(null);
  const entity = contentState.getEntity(entityKey);
  const entityData: MikaContentEntityData = entity.getData();

  const mikaContentStatus = entityData?.status || "unknown";
  const mikaContentAnchorName = entityData?.insightAnchorName || "unknown";
  const isAcceptedContent = entityData?.status === MikaContentStatus.ACCEPTED;

  return (
    <span
      ref={mikaContentRef}
      key={`mikaContent-${mikaContentAnchorName}-${mikaContentStatus}-${entityKey}`}
      data-offset-key={offsetKey}
      className={cx(styles.Container, {
        [styles.ContainerAccepted]: isAcceptedContent
      })}
    >
      {children}
    </span>
  );
};
