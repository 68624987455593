import React, { Dispatch, SetStateAction, useMemo } from "react";
import { v4 as uuid } from "uuid";

import BaseChatNodeForm, {
  BaseFormValues,
  DeprecatedLandingContent,
  FormValues,
  LandingFormValues
} from "../../BaseChatNodeForm";

import { ChatFlowsNodes, ChatNodeTypes, JsonContent } from "../../../../../../../../../types";
import { StartCardVariants } from "../../../../../../../../../constants";
import StartCardContent from "./StartCardContent";

export type LandingFormNode = ChatFlowsNodes & {
  payloadContent: DeprecatedLandingContent | null;
  payloadOptions: {
    contentJSON?: JsonContent;
  };
};

type PropsType = {
  chatId: string | undefined;
  node: ChatFlowsNodes;
  viewOnly: boolean;
  setSelectedNodeId: Dispatch<SetStateAction<number | null>>;
};

const onSaveCustomTransformer = (formValues: FormValues) => {
  const values = formValues as BaseFormValues & LandingFormValues;
  const contentJsonIsEmpty =
    values.contentJson &&
    values.contentJson.length === 1 &&
    values.contentJson[0].children.length === 0;

  return {
    payloadContent: values.payloadContent || null,
    payloadOptions: {
      contentJson: !contentJsonIsEmpty ? values.contentJson : undefined,
      variant: values.variant,
      taskContent:
        values.variant === StartCardVariants.TASK ||
        values.variant === StartCardVariants.PRIORITY_TASK
          ? {
              title: values.taskContent?.title || "",
              description: values.taskContent?.description || ""
            }
          : undefined
    }
  };
};

const defaultContent: JsonContent = [
  {
    id: uuid(),
    type: ChatNodeTypes.content,
    properties: undefined,
    children: []
  }
];

const Landing = ({ chatId, node, viewOnly, setSelectedNodeId }: PropsType) => {
  const initialFormValues: LandingFormValues = useMemo(() => {
    const deprecatedLandingContent: DeprecatedLandingContent = node?.payloadContent
      ? JSON.parse(node.payloadContent)
      : "";
    const contentJson = node?.payloadOptions?.contentJson
      ? node.payloadOptions.contentJson
      : defaultContent;
    const variant = node?.payloadOptions?.variant
      ? node.payloadOptions.variant
      : StartCardVariants.CARD;

    return {
      payloadContent: deprecatedLandingContent,
      contentJson,
      variant,
      taskContent: {
        title: node?.payloadOptions?.taskContent?.title ?? "",
        description: node?.payloadOptions?.taskContent?.description ?? ""
      }
    };
  }, [node.id]);

  if (!node) return null;

  return (
    <BaseChatNodeForm
      chatId={chatId}
      key={node.id}
      initialValues={initialFormValues}
      onSaveCustomTransformer={onSaveCustomTransformer}
      node={node}
      viewOnly={viewOnly}
      setSelectedNodeId={setSelectedNodeId}
    >
      <StartCardContent initialValues={initialFormValues} viewOnly={viewOnly} />
    </BaseChatNodeForm>
  );
};

export default Landing;
