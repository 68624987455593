import React, { useMemo } from "react";

import { connect } from "react-redux";
import cx from "classnames";
import moment from "moment";

import Card from "../../ui/Card";
import Heading from "../../ui/Heading";
import Text from "../../ui/Text";
import Button from "../../ui/Button";
import Status from "../../ui/Status";

import { openModal as openModalAction, OpenModal } from "../../../actions";

import { AppointmentConversation, StatusComponentConfigMap } from "../../../types";

import { ChatFlowTags, ConversationStatuses, ModalTypes } from "../../../constants";

import styles from "./index.module.scss";

export type PropsType = {
  conversations: Array<AppointmentConversation>;
  openModal: OpenModal;
};

const conversationStatusConfigMap: StatusComponentConfigMap = {
  [ConversationStatuses.NOT_APPLICABLE]: "gray",
  [ConversationStatuses.NOT_STARTED]: "gray",
  [ConversationStatuses.IN_PROGRESS]: "blue",
  [ConversationStatuses.COMPLETE]: "green",
  [ConversationStatuses.NEEDS_HELP]: "red",
  [ConversationStatuses.FLAGGED]: "red",
  [ConversationStatuses.LOCKED_OUT]: "red",
  [ConversationStatuses.NO_CONTACT]: "red"
};

const DirectMessageSummaries = ({ conversations, openModal }: PropsType) => {
  return (
    <>
      {conversations.length === 0 ? (
        <Text>No sent messages</Text>
      ) : (
        <Card>
          <Heading
            size="M"
            id="secureMessagesList"
            component="h1"
            className={cx(styles.Heading, styles.BottomBorder)}
          >
            Messages
          </Heading>
          <div className={styles.ConversationList}>
            {conversations.map((conversation) => {
              return (
                <React.Fragment key={`conversation-${conversation.id}`}>
                  <div>
                    <Text>{conversation.chatFlowTitle}</Text>
                    <Text size="S">
                      {`Sent on ${moment(conversation.createdAt).format("dddd MMM Do, h:mma")}`}
                    </Text>
                    <Button
                      inline
                      onClick={() => {
                        openModal(ModalTypes.DIRECT_MESSAGE_DETAILS, {
                          conversation: conversation,
                          createdAt: conversation.createdAt
                        });
                      }}
                      id="viewSecureMessage"
                    >
                      <Text size="S" className={styles.SecureMessageItemLink}>
                        View message
                      </Text>
                    </Button>
                  </div>
                  {conversation.tagnames?.includes(ChatFlowTags.SECURE_DIRECT_MESSAGE) ? (
                    <Status
                      className={styles.ConversationStatus}
                      value={conversation.status}
                      options={[
                        {
                          label: ConversationStatuses.NOT_STARTED,
                          value: ConversationStatuses.NOT_STARTED
                        }, // Message is sent, unopened
                        {
                          label: ConversationStatuses.NEEDS_HELP,
                          value: ConversationStatuses.NEEDS_HELP
                        }, // Message expired and patient tried to log in
                        {
                          label: ConversationStatuses.IN_PROGRESS,
                          value: ConversationStatuses.IN_PROGRESS
                        }, // Patient successfully clicks link and is in the process of entering their PHN
                        {
                          label: ConversationStatuses.COMPLETE,
                          value: ConversationStatuses.COMPLETE
                        }, // Patient successfully clicks link and successfully enters PHN
                        {
                          label: ConversationStatuses.LOCKED_OUT,
                          value: ConversationStatuses.LOCKED_OUT
                        }, // Patient tried to enter their PHN too many times and now locked out
                        {
                          label: ConversationStatuses.NO_CONTACT,
                          value: ConversationStatuses.NO_CONTACT
                        } // We do not have a valid phone number or email for the patient
                      ]}
                      configMap={conversationStatusConfigMap}
                      defaultColor="gray"
                    />
                  ) : (
                    <div />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </Card>
      )}
    </>
  );
};

export default connect(null, {
  openModal: openModalAction
})(DirectMessageSummaries);
