import React from "react";

import Button from "../../../Button";
import { Plus } from "../../../Icon";
import ElementInput from "../ElementInput";

import { defaultCompositionOperators } from "../defaultConfigurations";

import { UpdateCondition, ElementInputValue } from "../types";

type PropsType = {
  className?: string;
  conditionPath: string;
  operatorValue: string;
  nestedDepth: number;
  updateCondition: UpdateCondition;
  showAddCondition: boolean;
  disabled: boolean;
};

const getElementType = (nestedDepth?: number) => {
  switch (nestedDepth) {
    case 2: {
      return "compositionTwo";
    }
    case 1: {
      return "compositionOne";
    }
    default: {
      return "compositionZero";
    }
  }
};

const CompositionOperator = ({
  className,
  conditionPath,
  operatorValue,
  nestedDepth,
  updateCondition,
  showAddCondition,
  disabled
}: PropsType) => {
  const operatorOptions = defaultCompositionOperators.map((conditionOperator) => {
    return { label: conditionOperator.label, value: conditionOperator.jsonLogicKey };
  });

  const onOperatorSelection = (selection: ElementInputValue) => {
    updateCondition(conditionPath, "updateCompositionOperator", selection);
  };

  return (
    <div className={className}>
      {showAddCondition ? (
        <Button
          inline
          disabled={disabled}
          onClick={() => {
            updateCondition(conditionPath, "initialize");
          }}
        >
          <Plus size={18} />
          condition
        </Button>
      ) : (
        <ElementInput
          elementType={getElementType(nestedDepth)}
          placeholder="choose operator"
          options={operatorOptions}
          value={operatorValue}
          onChange={onOperatorSelection}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default CompositionOperator;
