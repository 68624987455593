import React, { useEffect, useState } from "react";
import { useFormState } from "informed";
import intersection from "lodash/intersection";
import cx from "classnames";

import Heading from "../../../Heading";
import Text from "../../../Text";
import Button from "../../../Button";

import { Option } from "../../../../../types";

import styles from "./index.module.scss";
import { FormGrid } from "../..";

type PropsType = {
  sectionLabel?: string;
  editable?: boolean;
  initOpen?: boolean;
  formSummaryFields: {
    label: string;
    fieldName: string;
    options?: Option[];
    fieldFormatter?: (value: string | null) => string;
    fieldIcon?: React.ReactElement;
  }[];
  formSection: React.ReactElement;
};

export const FormMoreDetails = ({
  sectionLabel = "More Details",
  editable = false,
  initOpen = false,
  formSummaryFields = [],
  formSection
}: PropsType) => {
  const [editMode, setEditMode] = useState(editable && initOpen);
  const formState = useFormState();
  const errorFields = Object.keys(formState.errors);
  const summaryFieldNames = formSummaryFields.map((fieldData) => fieldData.fieldName);
  const sectionErrors = intersection(errorFields, summaryFieldNames);

  const hasErrors = sectionErrors.length > 0;

  useEffect(() => {
    if (!editMode && hasErrors) {
      setEditMode(true);
    }
  }, [hasErrors, editMode]);

  return (
    <>
      <div className={styles.MoreDetailsRow}>
        <Heading size="S" className={styles.MoreDetailsHeading}>
          {sectionLabel}
        </Heading>
        {!editMode && editable && (
          <Button inline onClick={() => setEditMode(true)}>
            Edit
          </Button>
        )}
      </div>
      {!editMode && (
        <div className={styles.FormSummaryWrapper}>
          <FormGrid>
            {formSummaryFields.map((fieldData) => {
              const rawFormValue = formState.values?.[fieldData.fieldName];
              let displayValue =
                fieldData.fieldFormatter && rawFormValue && typeof rawFormValue === "string"
                  ? fieldData.fieldFormatter(rawFormValue)
                  : rawFormValue || "-";

              if (rawFormValue && fieldData.options) {
                const selectedOption = fieldData.options.find(
                  (option) => option.value === rawFormValue
                );
                if (selectedOption) displayValue = selectedOption.label;
              }

              return (
                <div key={fieldData.fieldName} className={styles.FormSummaryItem}>
                  <div className={styles.FormSummaryItemHeader}>
                    <Heading size="META">{fieldData.label}</Heading>
                  </div>
                  <div className={styles.FormSummaryItemContent}>
                    <Text size="M">{displayValue as string}</Text>

                    {fieldData.fieldIcon && (
                      <div className={styles.FormSummaryItemFieldIcon}>{fieldData.fieldIcon}</div>
                    )}
                  </div>
                </div>
              );
            })}
          </FormGrid>
        </div>
      )}
      <div className={cx(styles.FormSectionWrapper, { [styles.FormSectionWrapperOpen]: editMode })}>
        {formSection}
      </div>
    </>
  );
};
