import React from "react";

type PropsType = {
  size?: number;
};
const ArrowRight = ({ size = 28 }: PropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}px`}
      height={`${size}px`}
      fill="currentColor"
    >
      <path
        fill="currentColor"
        d="M13.293 18.695a1 1 0 0 1 0-1.414l4.293-4.293H4a1 1 0 1 1 0-2h13.586l-4.293-4.293a1 1 0 0 1 1.414-1.414l6 6a1 1 0 0 1 .172.23l.01.02v.005l.007.014.006.012v.008l.008.018a1 1 0 0 1-.009.817l-.001.023v.009l-.005.01-.007.014v.008l-.008.015v.005l-.01.016-.011.017-.012.018-.012.017-.035.03-.011.015v.006l-.01.012-.009.01-.006.008-.015.017-.04.042-6 6a1 1 0 0 1-1.414 0l-.002-.002Z"
      />
    </svg>
  );
};

export default ArrowRight;
