import React from "react";
import { useField } from "informed";

import BaseInput from "../BaseInput";
import RadioGroupBase from "./RadioGroupBase";

import { InputPropsType, Option } from "../../../../types";

type PropsType = InputPropsType & {
  options: Array<Option>;
  initialValue?: string;
};

const RadioGroup = ({
  fieldName,
  label,
  info,
  infoStatus,
  options,
  initialValue,
  showField = true,
  disabled = false,
  warningMessage = "",
  validate,
  customOnChange,
  ...props
}: PropsType): React.ReactElement => {
  const { fieldState, fieldApi } = useField({
    ...props,
    name: fieldName,
    validate,
    initialValue
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const value = fieldState.value as any;
  const error = fieldState.error as string | undefined;
  const { setValue, setTouched, validate: validateInput } = fieldApi;

  const onChangeHandler = (updatedVal: string | number): void => {
    setValue(updatedVal);
    setTouched(true);
    validateInput();

    if (customOnChange) {
      customOnChange({ [fieldName]: updatedVal });
    }
  };

  return (
    <BaseInput
      fieldName={fieldName}
      error={error}
      label={label}
      showField={showField}
      info={info}
      infoStatus={infoStatus}
      warningMessage={warningMessage}
    >
      <RadioGroupBase
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        fieldName={fieldName}
        label={label}
        options={options}
        initialValue={value}
        disabled={disabled}
        onChange={onChangeHandler}
      />
    </BaseInput>
  );
};

export default RadioGroup;
