import isEqual from "lodash/isEqual";

import {
  FETCH_APPOINTMENTS,
  FETCH_APPOINTMENTS_DETAIL,
  FETCH_APPOINTMENT_CHAT_LINK,
  CLEAR_APPOINTMENTS_DETAIL,
  UPDATE_APPOINTMENTS,
  SEND_PARKING_LOT_READY,
  CREATE_UNINTEGRATED_APPOINTMENTS,
  UPDATE_UNINTEGRATED_APPOINTMENTS,
  DELETE_UNINTEGRATED_APPOINTMENTS,
  AppointmentCheckedInStatuses,
  GET_APPOINTMENT_HISTORY,
  CLEAR_APPOINTMENT_HISTORY
} from "../constants";

import {
  FetchAppointmentsAction,
  FetchAppointmentsDetailAction,
  ClearAppointmentsDetailAction,
  AppointmentFetchData,
  UpdateAppointmentsAction,
  SendParkingLotReadyAction,
  FetchAppointmentChatLinkAction,
  FetchAppointmentFilters,
  CreateUnintegratedAppointmentsAction,
  UpdateUnintegratedAppointmentsAction,
  DeleteUnintegratedAppointmentsAction,
  GetAppointmentHistoryAction,
  ClearAppointmentHistoryAction
} from "../actions/appointments";

import { ActionStatus, AppointmentDetail, HistoryEvent } from "../types";

export type AppointmentsAction =
  | FetchAppointmentsAction
  | FetchAppointmentsDetailAction
  | ClearAppointmentsDetailAction
  | UpdateAppointmentsAction
  | SendParkingLotReadyAction
  | FetchAppointmentChatLinkAction
  | CreateUnintegratedAppointmentsAction
  | UpdateUnintegratedAppointmentsAction
  | DeleteUnintegratedAppointmentsAction
  | GetAppointmentHistoryAction
  | ClearAppointmentHistoryAction;

export type AppointmentUpdatesLoading = {
  [id: string]: {
    checkedIn?: boolean;
    status?: boolean;
    parkingLotNotify?: boolean;
    practitionerTaskStatus?: boolean;
    unintegratedDelete?: boolean;
  };
};

export type AppointmentsReduxState = {
  data?: AppointmentFetchData;
  appointmentsLoading: boolean;
  latestRequestFilters: FetchAppointmentFilters | undefined;
  updatesLoading: AppointmentUpdatesLoading;
  details: {
    data?: AppointmentDetail;
    loading: boolean;
    conversationsLink?: string;
    conversationsLinkLoading: boolean;
  };
  appointmentHistory: HistoryEvent[];
  appointmentHistoryLoading: boolean;
  createUnintegratedLoading: boolean;
  updateUnintegratedLoading: boolean;
};

const initialAppointments: AppointmentsReduxState = {
  data: undefined,
  latestRequestFilters: undefined,
  appointmentsLoading: false,
  updatesLoading: {},
  details: {
    data: undefined,
    loading: false,
    conversationsLink: "",
    conversationsLinkLoading: false
  },
  appointmentHistory: [],
  appointmentHistoryLoading: false,
  createUnintegratedLoading: false,
  updateUnintegratedLoading: false
};

export const appointmentDetailsReducer = (
  state = initialAppointments,
  action: AppointmentsAction
): AppointmentsReduxState => {
  const { type } = action;
  switch (type) {
    case FETCH_APPOINTMENTS: {
      const { status, filters, payload } = action as FetchAppointmentsAction;

      const isLatestSearch =
        !state.latestRequestFilters || isEqual(filters, state.latestRequestFilters);

      let data;
      if (isLatestSearch && status === ActionStatus.success) {
        data = payload;
      } else if (status === ActionStatus.loading) {
        data = initialAppointments.data;
      } else {
        data = state.data;
      }

      return {
        ...state,
        data,
        latestRequestFilters:
          status === ActionStatus.loading || status === ActionStatus.silentLoading
            ? filters
            : state.latestRequestFilters,
        appointmentsLoading: status === ActionStatus.loading
      };
    }
    case FETCH_APPOINTMENTS_DETAIL: {
      const { status, payload } = action as FetchAppointmentsDetailAction;

      return {
        ...state,
        details: {
          ...state.details,
          data: status !== ActionStatus.silentLoading ? payload?.data : state.details.data,
          loading: status === ActionStatus.loading
        }
      };
    }
    case FETCH_APPOINTMENT_CHAT_LINK: {
      const { status, payload } = action as FetchAppointmentChatLinkAction;

      return {
        ...state,
        details: {
          ...state.details,
          conversationsLink:
            status === ActionStatus.success
              ? payload?.conversationsLink
              : state.details.conversationsLink,
          conversationsLinkLoading: status === ActionStatus.loading
        }
      };
    }
    case CLEAR_APPOINTMENTS_DETAIL: {
      return {
        ...state,
        details: initialAppointments.details
      };
    }
    case UPDATE_APPOINTMENTS: {
      const { status, payload } = action as UpdateAppointmentsAction;

      const newUpdatesLoading =
        payload && payload.appointmentId
          ? {
              ...state.updatesLoading,
              [payload.appointmentId]: {
                ...state.updatesLoading[payload.appointmentId],
                ...Object.keys(payload.update).reduce(
                  (updatesLoading, key) => {
                    // eslint-disable-next-line no-param-reassign
                    updatesLoading[key] = status === ActionStatus.loading;
                    return updatesLoading;
                  },
                  {} as { [key: string]: boolean }
                )
              }
            }
          : state.updatesLoading;
      const updatedData =
        payload && status === ActionStatus.success && state.data
          ? {
              ...state.data,
              appointments: state.data.appointments?.map((appointment) => {
                if (appointment.id === payload.appointmentId) {
                  return {
                    ...appointment,
                    ...payload.update
                  };
                }
                return appointment;
              })
            }
          : state.data;

      const updatedDetails =
        payload &&
        payload.update &&
        status === ActionStatus.success &&
        state.details?.data?.id &&
        state.details?.data?.id === payload.appointmentId
          ? { ...state.details, data: { ...state.details.data, ...payload.update } }
          : state.details;

      return {
        ...state,
        data: updatedData,
        details: updatedDetails,
        updatesLoading: newUpdatesLoading
      };
    }
    case SEND_PARKING_LOT_READY: {
      const { status, payload } = action as SendParkingLotReadyAction;

      const newUpdatesLoading =
        payload && payload.appointmentId
          ? {
              ...state.updatesLoading,
              [payload.appointmentId]: {
                ...state.updatesLoading[payload.appointmentId],
                parkingLotNotify: status === ActionStatus.loading
              }
            }
          : state.updatesLoading;

      const newData =
        payload &&
        payload.appointmentId &&
        status === ActionStatus.success &&
        state.data &&
        state.data.appointments
          ? {
              ...state.data,
              appointments: state.data.appointments.map((appointment) => {
                if (appointment.id === payload.appointmentId) {
                  return {
                    ...appointment,
                    checkedIn: AppointmentCheckedInStatuses.STAFF_CONTACTED.name
                  };
                }
                return appointment;
              })
            }
          : state.data;

      return {
        ...state,
        data: newData,
        updatesLoading: newUpdatesLoading
      };
    }
    case CREATE_UNINTEGRATED_APPOINTMENTS: {
      const { status } = action as CreateUnintegratedAppointmentsAction;

      return { ...state, createUnintegratedLoading: status === ActionStatus.loading };
    }
    case UPDATE_UNINTEGRATED_APPOINTMENTS: {
      const { status } = action as UpdateUnintegratedAppointmentsAction;

      return { ...state, updateUnintegratedLoading: status === ActionStatus.loading };
    }
    case DELETE_UNINTEGRATED_APPOINTMENTS: {
      const { status, payload } = action as DeleteUnintegratedAppointmentsAction;

      const newData =
        payload &&
        payload.appointmentId &&
        status === ActionStatus.success &&
        state.data &&
        state.data.appointments
          ? {
              ...state.data,
              appointments: state.data.appointments.filter(
                (appointment) => appointment.id !== payload.appointmentId
              )
            }
          : state.data;

      const newUpdatesLoading =
        payload && payload.appointmentId
          ? {
              ...state.updatesLoading,
              [payload.appointmentId]: {
                ...state.updatesLoading[payload.appointmentId],
                unintegratedDelete: status === ActionStatus.loading
              }
            }
          : state.updatesLoading;

      return { ...state, data: newData, updatesLoading: newUpdatesLoading };
    }
    case GET_APPOINTMENT_HISTORY: {
      const { status, payload } = action as GetAppointmentHistoryAction;

      return {
        ...state,
        appointmentHistory: payload || state.appointmentHistory,
        appointmentHistoryLoading: status === ActionStatus.loading
      };
    }
    case CLEAR_APPOINTMENT_HISTORY: {
      return {
        ...state,
        appointmentHistory: initialAppointments.appointmentHistory
      };
    }
    default:
      return state;
  }
};
