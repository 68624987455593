import React from "react";
import cx from "classnames";
import isEqual from "lodash/isEqual";

import { useFormState } from "informed";
import Button from "../../../ui/Button";
import Drawer from "../../../ui/Drawer";
import Heading from "../../../ui/Heading";
import { Info, Plus } from "../../../ui/Icon";
import Status from "../../../ui/Status";
import Text from "../../../ui/Text";
import Tooltip from "../../../ui/Tooltip";
import { SelectInput, TextInput, ToggleInput } from "../../../ui/Input";

import { OrganizationContextType } from "../../../providers/OrganizationProvider";

import { OpenModal } from "../../../../actions";

import { PermissionsGuard } from "../../../../hooks/usePermissions";
import { isEmail, combine, isGreaterThan, isLessThan } from "../../../../utils/validators";
import { resetPasswordButtonText } from "../../../../utils/resetPasswordButtonText";
import { userTypeDisplayMap } from "../../../../utils/userTypeDisplayMap";

import { ModalTypes, userStatusConfigMap, userStatusOptions } from "../../../../constants";

import { Practitioner, Permissions, ScribeBilling, Option } from "../../../../types";

import styles from "./index.module.scss";

type FormState = {
  displayName: string;
  email: string;
  workPhone: string;
  roleId: string;
  scribeStatus: boolean;
  active: boolean;
  fteFactor: number;
  defaultLocationId: string;
};

type PropsType = {
  provider: Practitioner;
  scribeBilling: ScribeBilling;
  updateLoading: boolean;
  initialFormState: FormState;
  roleOptions: Option[];
  locationOptions: Option[];
  canEditProviderEmail: boolean;
  canAddProviderEmail: boolean;
  hasUpdateUserLevel30?: boolean;
  editEmail: boolean;
  setEditEmail: (editEmail: boolean) => void;
  openModal: OpenModal;
  deactivateUser: () => void;
  resetPassword: () => void;
  reactivateUser: (email?: string) => void;
  canChangeRole: boolean;
  organization: OrganizationContextType;
  saveAndSendInvite: (email: string) => void;
};

const emailValidator = isEmail("Please enter a valid email");
const fteFactorValidator = combine([
  isGreaterThan(0, "FTE must be a decimal greater than 0"),
  isLessThan(1, "FTE must be a decimal no more than 1.0")
]);

const getScribeDataRemovalPolicy = (provider: Practitioner) => {
  const policyInDays = provider?.settings?.features?.scribe?.dataRemovalPolicyInDays;
  return policyInDays ? `${policyInDays} days` : "Don't Delete";
};

const EditProviderForm = ({
  provider,
  scribeBilling,
  updateLoading,
  openModal,
  initialFormState,
  roleOptions,
  locationOptions,
  canEditProviderEmail,
  canAddProviderEmail,
  editEmail,
  hasUpdateUserLevel30,
  setEditEmail,
  canChangeRole,
  organization,
  deactivateUser,
  resetPassword,
  reactivateUser,
  saveAndSendInvite
}: PropsType) => {
  const formState = useFormState();
  const values = formState.values as FormState;
  const hasFormChanges = !isEqual({ ...values }, initialFormState);
  const formDisabled = updateLoading;
  const scribeBillingNoScribeFeature = scribeBilling && !values.scribeStatus;
  const scribeFeatureNoScribeBilling = !scribeBilling && values.scribeStatus;

  return (
    <>
      <div className={styles.StatusRow}>
        <div>
          <Heading size="META">status</Heading>
          <Status
            value={provider.status}
            options={userStatusOptions}
            configMap={userStatusConfigMap}
          />
        </div>
        <div>
          <Heading size="META">type</Heading>
          <Text>{userTypeDisplayMap(provider.type)}</Text>
        </div>
      </div>
      <div className={styles.Email}>
        <div className={styles.HeadingTooltip}>
          <Heading size="META">email</Heading>
          {!canEditProviderEmail && (
            <Tooltip
              icon={
                <div className={styles.Icon}>
                  <Info size={15} />
                </div>
              }
              position="topRight"
            >
              {!provider.email
                ? "Add a provider's email to invite them to use Mikata"
                : "To change an email address please contact Mikata Health"}
            </Tooltip>
          )}
        </div>
        {!provider.email && !editEmail && canAddProviderEmail && (
          <Button
            id="editProviderEmail"
            inline
            disabled={!provider.practitionerActive}
            onClick={() => {
              setEditEmail(true);
            }}
          >
            <Plus size={16} />
            <span className={styles.AddButtonText}>Add email</span>
          </Button>
        )}
        {provider.email && !editEmail && (
          <div className={styles.ViewEmail}>
            <Text>{provider.email}</Text>
            <div className={styles.EmailButtons}>
              {hasUpdateUserLevel30 && (
                <Button
                  id="changeEmail"
                  inline
                  disabled={!provider.practitionerActive}
                  onClick={() => setEditEmail(true)}
                >
                  Change email
                </Button>
              )}
              <Button
                id="resetPasswordButton"
                inline
                disabled={!provider.practitionerActive}
                onClick={!provider.userActive ? () => reactivateUser() : resetPassword}
              >
                {resetPasswordButtonText(provider.status)}
              </Button>
            </div>
            <div />
          </div>
        )}
        {editEmail && (
          <>
            <div className={styles.EditEmail}>
              <TextInput
                fieldName="email"
                placeholder="Enter provider's email"
                disabled={formDisabled}
                validate={emailValidator}
              />
              <Button
                id="saveAndSendInvite"
                className={styles.SendInvite}
                inline
                onClick={() =>
                  !provider.userActive
                    ? reactivateUser(values.email)
                    : saveAndSendInvite(values.email)
                }
              >
                Save & send invite
              </Button>
            </div>
            {!hasUpdateUserLevel30 && (
              <Text size="M" className={cx(styles.Text, styles.TextWarning)}>
                Please make sure the email is correct before adding and sending. If you want to
                change the email, you will have to contact us.
              </Text>
            )}
          </>
        )}
      </div>
      <TextInput
        fieldName="displayName"
        label="display name"
        placeholder="Enter display name (for text messages)"
        disabled={formDisabled}
      />
      {hasUpdateUserLevel30 && (
        <>
          <SelectInput
            fieldName="defaultLocationId"
            label="Default Location"
            disabled={formDisabled}
            options={locationOptions}
          />
          <TextInput
            fieldName="workPhone"
            label="phone"
            placeholder="Enter provider's phone number"
            disabled={formDisabled}
          />
        </>
      )}
      <SelectInput
        fieldName="roleId"
        label="role"
        disabled={!canChangeRole}
        options={roleOptions}
      />
      {hasUpdateUserLevel30 && (
        <TextInput
          fieldName="fteFactor"
          label="FTE factor"
          placeholder="Enter provider's FTE number"
          disabled={formDisabled}
          validate={fteFactorValidator}
        />
      )}

      <hr className={styles.Divider} />

      <div className={styles.Features}>
        <Drawer
          headerContent={
            <Heading size="S" bold component="h3">
              Features
            </Heading>
          }
          togglePosition="left"
          initialOpen
          extraMargin={false}
        >
          <div className={styles.FeatureHeading}>
            <Heading size="S" className={styles.ScribeHeading}>
              Mikata Platform
            </Heading>
            <div className={styles.FeatureStatus}>
              <ToggleInput
                key={`${provider.id}`}
                fieldName="active"
                labelChecked="On"
                labelUnchecked="Off"
                disabled={!hasUpdateUserLevel30}
              />
            </div>
          </div>
          <Text className={styles.Text}>Activate Mikata Platform features for this provider. </Text>
          <div className={styles.FeatureHeading}>
            <Heading size="S" className={styles.ScribeHeading}>
              Scribe
            </Heading>
            <div className={styles.FeatureStatus}>
              <ToggleInput
                key={`${provider.id}`}
                fieldName="scribeStatus"
                labelChecked="On"
                labelUnchecked="Off"
                disabled={!hasUpdateUserLevel30}
              />
            </div>
          </div>
          <PermissionsGuard
            requiresVerifiedOrg={false}
            requiredPermissions={[Permissions.UPDATE_USER_OTHER_LEVEL_30]}
          >
            <div className={styles.ScribeGroup}>
              <div>
                <Text size="S" className={styles.SubscriptionHeading}>
                  ADVANCED SUBSCRIPTION ID
                </Text>
                <Text size="S">
                  {scribeBilling
                    ? `${provider.fullName} (${scribeBilling.advancedSubscriptionId})`
                    : "-"}
                </Text>
              </div>

              <div>
                <Text size="S" className={styles.SubscriptionHeading}>
                  PAID BY
                </Text>
                <Text size="S">{scribeBilling ? scribeBilling.paidBy : "-"}</Text>
              </div>
            </div>
          </PermissionsGuard>
          {(scribeBillingNoScribeFeature || scribeFeatureNoScribeBilling) && (
            <Text size="M" className={cx(styles.Text, styles.TextWarning)}>
              {scribeBilling
                ? "This provider has an active subscription to the scribe. Please contact the billing team to make a changeover for this provider."
                : "This provider is not linked to a subscription. Please contact the billing team to ensure a subscription exists and link it."}
            </Text>
          )}
          <PermissionsGuard
            requiresVerifiedOrg={false}
            requiredPermissions={[Permissions.UPDATE_USER_OTHER_LEVEL_30]}
          >
            <div className={styles.FeatureHeading}>
              <Heading size="S" className={styles.ScribeHeading}>
                Quick start for Mika AI Scribe
              </Heading>
              <div className={styles.QuickStartStatus}>
                <Text>{organization?.hasQuickStart ? "On" : "Off"}</Text>
              </div>
            </div>
            <Text className={styles.Text}>
              Quick start is only available when the organization’s EMR type is &quot;Hybrid.&quot;
              Please go to Settings &gt; General to ensure the correct EMR type is selected.
            </Text>
            <div className={styles.FeatureHeading}>
              <Heading size="S" className={styles.ScribeHeading}>
                Data retention for Mika AI Scribe
              </Heading>
              <div className={styles.ScribeDataRemovalPolicy}>
                <Text>{getScribeDataRemovalPolicy(provider)}</Text>
              </div>
            </div>
            <Text className={styles.Text}>
              The amount of time scribe data will be kept before it is automatically deleted from
              the provider’s account. This setting is configured by the provider.
            </Text>
          </PermissionsGuard>
        </Drawer>
      </div>

      <hr className={styles.Divider} />

      <div className={styles.ButtonGroup}>
        {provider.userActive && (
          <Button
            inline
            danger
            onClick={() =>
              openModal(ModalTypes.CONFIRMATION, {
                onConfirmation: deactivateUser,
                title: "Deactivate user",
                confirmButtonText: "Deactivate",
                content: `You are about to deactivate ${provider.fullName}. Is this what you want to do?`,
                confirmButtonVariant: "danger",
                mustCheckConfirm: true,
                confirmationText: "Yes, I want to deactivate this user"
              })
            }
          >
            Deactivate user
          </Button>
        )}
        <Button
          className={styles.SaveButton}
          type="submit"
          disabled={!hasFormChanges || updateLoading}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default EditProviderForm;
