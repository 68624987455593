import React from "react";
import { ArrayField } from "informed";

import Button from "../../../../../ui/Button";
import Heading from "../../../../../ui/Heading";
import { CreatableSelectInput, SelectInput, RadioGroup, TextInput } from "../../../../../ui/Input";
import { Option, InputPropsType } from "../../../../../../types";

import styles from "./index.module.scss";

import Tooltip from "../../../../../ui/Tooltip";
import Info from "../../../../../ui/Icon/Info";
import { isRequired } from "../../../../../../utils/validators";
import Loader from "../../../../../ui/Loader";

export type Options = Array<Option>;

export const mustSelectValidator = isRequired("Must be selected");

type FormOptionsConfig = {
  emrFieldName: string;
  validMessageTemplateTagNames?: string[];
  canMapToInputName?: boolean;
  isRequired?: boolean;

  defaultOptions?: Option[];
  allowCustomDefaultOption?: boolean;
}[];

type AddChatFlowOutputFormData = {
  type: string;
  reportTemplateId?: string;
  title?: string;
  letterTypeId?: string;
  fileCSVForm?: Array<DataMappingsOption>;
  demographicPropertiesArray: Array<DemographicPropertyMapping>;
};

type DataMappingsOption = {
  inputName: string;
  mappedName: string;
};

type DemographicPropertyMapping = {
  emrFieldName?: string;
  mappingChoice?: string;
  inputName?: string;
  tagName?: string;
  required?: boolean;
  defaultValue?: string;
};

type TagOption = {
  label: string;
  value: string;
};

type InputNameOption = {
  label: string;
  value: string;
};

type PropsType = InputPropsType & {
  config: FormOptionsConfig | null;
  availableMessageTemplateTags: TagOption[];
  availableInputNames: InputNameOption[];
  formValues: AddChatFlowOutputFormData;
};

const PushDemographics = ({
  fieldName,
  config,
  availableMessageTemplateTags,
  availableInputNames,
  formValues
}: PropsType): JSX.Element => {
  if (!config) {
    return <Loader screen />;
  }

  return (
    <>
      <Heading size="META">Push Demographics Configuration</Heading>
      <hr className={styles.Divider} />
      <ArrayField name={fieldName}>
        {({ add }) => {
          return (
            <>
              <div>
                <ArrayField.Items>
                  {({ remove, index }) => {
                    return (
                      <React.Fragment key={`pushDemographics_${index}`}>
                        <div className={styles.OptionsTop}>
                          <Tooltip
                            icon={
                              <div className={styles.Icon}>
                                <Info size={15} />
                              </div>
                            }
                            position="topRight"
                          >
                            <b>
                              {(config?.find((optionConfig) => {
                                return (
                                  optionConfig.emrFieldName ===
                                  formValues?.demographicPropertiesArray?.[index]?.emrFieldName
                                );
                              })?.isRequired &&
                                "This is a required field.") ||
                                "This is not a required field."}
                            </b>
                            <br />
                            <br />
                            Valid Tags:
                            <br />
                            {(config?.find((optionConfig) => {
                              return (
                                optionConfig.emrFieldName ===
                                formValues?.demographicPropertiesArray?.[index]?.emrFieldName
                              );
                            })?.validMessageTemplateTagNames?.length !== 0 &&
                              config?.find((optionConfig) => {
                                return (
                                  optionConfig.emrFieldName ===
                                  formValues?.demographicPropertiesArray?.[index]?.emrFieldName
                                );
                              })?.validMessageTemplateTagNames) ||
                              "N/A"}
                            <br />
                            <br />
                            Options:
                            <br />
                            {(config?.find((optionConfig) => {
                              return (
                                optionConfig.emrFieldName ===
                                formValues?.demographicPropertiesArray?.[index]?.emrFieldName
                              );
                            })?.defaultOptions?.length !== 0 &&
                              config
                                ?.find((optionConfig) => {
                                  return (
                                    optionConfig.emrFieldName ===
                                    formValues?.demographicPropertiesArray?.[index]?.emrFieldName
                                  );
                                })
                                ?.defaultOptions?.map((option) => {
                                  return (
                                    <div key={option.value}>
                                      {`${option.label} (value = "${option.value}")`}
                                    </div>
                                  );
                                })) ||
                              "N/A"}
                          </Tooltip>

                          {config?.find((optionConfig) => {
                            return (
                              optionConfig.emrFieldName ===
                              formValues?.demographicPropertiesArray?.[index]?.emrFieldName
                            );
                          })?.isRequired && <TextInput fieldName="emrFieldName" disabled />}

                          {!config?.find((optionConfig) => {
                            return (
                              optionConfig.emrFieldName ===
                              formValues?.demographicPropertiesArray?.[index]?.emrFieldName
                            );
                          })?.isRequired && (
                            <SelectInput
                              fieldName="emrFieldName"
                              label=""
                              validate={mustSelectValidator}
                              options={
                                config.flatMap((option) => {
                                  if (!option.isRequired) {
                                    return {
                                      label: option.emrFieldName,
                                      value: option.emrFieldName
                                    };
                                  }
                                  return [];
                                }) || []
                              }
                              disabled={
                                config?.find((optionConfig) => {
                                  return (
                                    optionConfig.emrFieldName ===
                                    formValues?.demographicPropertiesArray?.[index]?.emrFieldName
                                  );
                                })?.isRequired
                              }
                              placeholder="Select Optional EMR Field Name"
                            />
                          )}

                          {!config?.find((optionConfig) => {
                            return (
                              optionConfig.emrFieldName ===
                              formValues?.demographicPropertiesArray?.[index]?.emrFieldName
                            );
                          })?.allowCustomDefaultOption && (
                            <SelectInput
                              fieldName="defaultValue"
                              label=""
                              options={
                                config
                                  ?.find((optionConfig) => {
                                    return (
                                      optionConfig.emrFieldName ===
                                      formValues?.demographicPropertiesArray?.[index]?.emrFieldName
                                    );
                                  })
                                  ?.defaultOptions?.concat({
                                    label: " <Deselect>",
                                    value: ""
                                  })
                                  .sort((a, b) => a.label.localeCompare(b.label)) || []
                              }
                              placeholder="Select Default Option"
                            />
                          )}

                          {config?.find((optionConfig) => {
                            return (
                              optionConfig.emrFieldName ===
                              formValues?.demographicPropertiesArray?.[index]?.emrFieldName
                            );
                          })?.allowCustomDefaultOption && (
                            <CreatableSelectInput
                              fieldName="defaultValue"
                              label=""
                              options={
                                config
                                  ?.find((optionConfig) => {
                                    return (
                                      optionConfig.emrFieldName ===
                                      formValues?.demographicPropertiesArray?.[index]?.emrFieldName
                                    );
                                  })
                                  ?.defaultOptions?.concat({
                                    label:
                                      formValues?.demographicPropertiesArray?.[index]
                                        ?.defaultValue || "",
                                    value:
                                      formValues?.demographicPropertiesArray?.[index]
                                        ?.defaultValue || ""
                                  }) || []
                              }
                              placeholder="Select or Specify Default Option"
                            />
                          )}
                        </div>

                        <div className={styles.OptionsBottom}>
                          {(config?.find((optionConfig) => {
                            return (
                              optionConfig.emrFieldName ===
                              formValues?.demographicPropertiesArray?.[index]?.emrFieldName
                            );
                          })?.canMapToInputName ||
                            !formValues?.demographicPropertiesArray?.[index]?.emrFieldName) && (
                            <RadioGroup
                              fieldName="mappingChoice"
                              label="Map to:"
                              options={[
                                { label: "Tag Name", value: "Tag Name" },
                                { label: "Input Name", value: "Input Name" }
                              ]}
                              disabled={
                                !config?.find((optionConfig) => {
                                  return (
                                    optionConfig.emrFieldName ===
                                    formValues?.demographicPropertiesArray?.[index]?.emrFieldName
                                  );
                                })?.canMapToInputName
                              }
                            />
                          )}

                          {(!config?.find((optionConfig) => {
                            return (
                              optionConfig.emrFieldName ===
                              formValues?.demographicPropertiesArray?.[index]?.emrFieldName
                            );
                          })?.canMapToInputName ||
                            formValues?.demographicPropertiesArray?.[index]?.mappingChoice ===
                              "Tag Name" ||
                            !formValues?.demographicPropertiesArray?.[index]?.mappingChoice) && (
                            <SelectInput
                              fieldName="tagName"
                              label=""
                              options={
                                availableMessageTemplateTags
                                  .filter((availableTagName) => {
                                    return config
                                      ?.find((optionConfig) => {
                                        return (
                                          optionConfig.emrFieldName ===
                                          formValues?.demographicPropertiesArray?.[index]
                                            ?.emrFieldName
                                        );
                                      })
                                      ?.validMessageTemplateTagNames?.includes(
                                        availableTagName.value
                                      );
                                  })
                                  ?.concat({
                                    label: " <Deselect>",
                                    value: ""
                                  })
                                  .sort((a, b) => a.label.localeCompare(b.label)) || []
                              }
                              placeholder="Select Tag Name to Map To"
                            />
                          )}

                          {formValues?.demographicPropertiesArray?.[index]?.mappingChoice ===
                            "Input Name" && (
                            <SelectInput
                              fieldName="inputName"
                              label=""
                              options={availableInputNames
                                ?.concat({
                                  label: " <Deselect>",
                                  value: ""
                                })
                                .sort((a, b) => a.label.localeCompare(b.label))}
                              placeholder="Select Input Name to Map To"
                            />
                          )}
                        </div>
                        <div className={styles.OptionsRemove}>
                          {!config?.find((optionConfig) => {
                            return (
                              optionConfig.emrFieldName ===
                              formValues?.demographicPropertiesArray?.[index]?.emrFieldName
                            );
                          })?.isRequired && (
                            <Button inline type="button" onClick={remove}>
                              Remove
                            </Button>
                          )}
                        </div>
                        <hr className={styles.Divider} />
                      </React.Fragment>
                    );
                  }}
                </ArrayField.Items>
              </div>
              <br />
              <Button inline type="button" onClick={() => add()}>
                Add Optional Mapping
              </Button>
            </>
          );
        }}
      </ArrayField>
    </>
  );
};

export default PushDemographics;
