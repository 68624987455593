import React, { useEffect } from "react";

type PropsType = {
  modalRef?: React.MutableRefObject<HTMLDivElement | undefined>;
  isOpen: boolean;
  onClose: () => void;
};

export const useFocusTrap = ({ modalRef, isOpen, onClose }: PropsType) => {
  const handleTabKey = (e: KeyboardEvent) => {
    if (modalRef && modalRef.current) {
      // Custom noteEditor must be included in focusable list to skip default change in focused element while using editor
      const focusableModalElements = modalRef.current.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), #noteEditor'
      );
      const firstElement = focusableModalElements[0] as HTMLInputElement;
      const lastElement = focusableModalElements[
        focusableModalElements.length - 1
      ] as HTMLInputElement;

      let currentFocusIndex;
      focusableModalElements.forEach((node: Element, index: number) => {
        if (
          node === document.activeElement ||
          node.getAttribute("data-skip-default-tab") === "true"
        ) {
          currentFocusIndex = index;
        }
      });

      const currentFocusElement = currentFocusIndex
        ? focusableModalElements[currentFocusIndex]
        : null;

      const shouldSkipTab =
        currentFocusElement && currentFocusElement.getAttribute("data-skip-default-tab");

      if (shouldSkipTab) {
        return e.preventDefault();
      }

      // const currentFocusIndex = focusableModalElements.indexOf(document.activeElement);
      if (currentFocusIndex === undefined) {
        if (!e.shiftKey) {
          firstElement.focus();
          return e.preventDefault();
        }

        if (e.shiftKey) {
          lastElement.focus();
          e.preventDefault();
        }
      } else {
        const adjustment = e.shiftKey ? -1 : 1;
        const nextFocusElement = (focusableModalElements[currentFocusIndex + adjustment] ||
          firstElement) as HTMLInputElement;
        nextFocusElement.focus();
        e.preventDefault();
      }
    }
    return null;
  };

  const keyListenersMap = new Map([
    [27, onClose],
    [9, handleTabKey]
  ]);

  useEffect(() => {
    function keyListener(e: KeyboardEvent) {
      const listener = keyListenersMap.get(e.keyCode);
      return listener && listener(e);
    }
    if (isOpen) {
      document.addEventListener("keydown", keyListener);
    } else {
      document.removeEventListener("keydown", keyListener);
    }

    return () => document.removeEventListener("keydown", keyListener);
  }, [modalRef, isOpen]);
};
