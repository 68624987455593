import React from "react";

type PropsType = {
  size?: number;
};

const Map = ({ size = 24 }: PropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={`${size}px`}
      height={`${size}px`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.496 22.868l6.535-3.734 7.504 3.751A.994.994 0 0016 23a.994.994 0 00.511-.14l6.985-3.992A1 1 0 0024 18V2a1 1 0 00-1.496-.868l-6.536 3.734-7.503-3.751A.995.995 0 008.007 1H8a.995.995 0 00-.511.14L.504 5.132A1 1 0 000 6v16a1 1 0 001.496.868zM17 20.277l5-2.857V3.723L17 6.58v13.697zM15 6.618l-6-3v13.764l6 3V6.618zM2 6.58l5-2.857V17.42l-5 2.857V6.58z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Map;
