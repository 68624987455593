import React from "react";
import { connect } from "react-redux";

import {
  XYPlot,
  VerticalGridLines,
  HorizontalGridLines,
  XAxis,
  YAxis,
  VerticalBarSeries
} from "react-vis";

import styles from "./index.module.scss";

import Heading from "../../../../../components/ui/Heading";
import Text from "../../../../../components/ui/Text";

import { ReduxStateType } from "../../../../../types";

const labelMap = {
  firstName: "First Name",
  lastName: "Last Name",
  birthday: "Birthday",
  phoneNumber: "Phone Number",
  reasons: "Reasons",
  totalAbandoned: "Total Abandoned"
};

type ChatDropoff = {
  firstName: number;
  lastName: number;
  birthday: number;
  phoneNumber: number;
  reasons: number;
  totalAbandoned: number;
};

type PropsType = {
  chatDropoff: ChatDropoff;
  chatDropoffLoading: boolean;
};

const ChatAbandonment = ({ chatDropoff, chatDropoffLoading }: PropsType) => {
  const data: { x: string; y: number }[] = Object.keys(chatDropoff)
    .map((key) => ({
      x: labelMap[key as keyof ChatDropoff],
      y: chatDropoff[key as keyof ChatDropoff]
    }))
    .filter((item) => item.x && item.y && item.x !== "Total Abandoned")
    .sort((a, b) => b.y - a.y);
  const chatDropoffDisplay = chatDropoffLoading ? "..." : chatDropoff.totalAbandoned;
  const yMax = data[0] && data[0].y ? Math.round(Number(data[0].y) * 1.1) : 10;
  return (
    <div className={styles.Card}>
      <div className={styles.Wrapper}>
        <Heading size="M" component="h2">
          Chat Abandonment
        </Heading>
      </div>
      <XYPlot height={400} width={1000} xType="ordinal" margin={80} yDomain={[0, yMax]}>
        <XAxis tickLabelAngle={-25} />
        <YAxis />
        <VerticalGridLines />
        <HorizontalGridLines />
        <VerticalBarSeries data={data} barWidth={0.5} animation="noWobble" />
      </XYPlot>
      <div className={styles.Divider} />
      <Text size="M">Total chats abandoned without submitting: {chatDropoffDisplay}</Text>
    </div>
  );
};

const mapStateToProps = ({ analytics }: ReduxStateType) => {
  const { chatDropoff, chatDropoffLoading } = analytics;
  return { chatDropoff, chatDropoffLoading };
};

export default connect(mapStateToProps, {})(ChatAbandonment);
