import React, { useMemo, useContext } from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

import Heading from "../../../ui/Heading";
import { FloatModal } from "../../../ui/Modal";
import Button from "../../../ui/Button";
import Loader from "../../../ui/Loader";
import {
  Form,
  TextInput,
  SelectInput,
  FormGrid,
  FormMoreDetails,
  DateSelectInput,
  TimeSelectInput,
  getCurrentTimeIntervalInitialValue
} from "../../../ui/Input";
import Text from "../../../ui/Text";

import { UserContext } from "../../../providers/UserProvider";

import {
  createUnintegratedAppointment as createUnintegratedAppointmentAction,
  CreateUnintegratedAppointmentData,
  closeModal as closeModalAction
} from "../../../../actions";

import { generatePractitionerLabel } from "../../../../utils/generatePractitionerLabel";
import { generateLocationLabel } from "../../../../utils/generateLocationLabel";
import { generateReasonLabel } from "../../../../utils/generateReasonLabel";
import { updateQueryString } from "../../../../utils/queryStringHelpers";

import { isRequired, isPhone, isEmail } from "../../../../utils/validators";

import {
  Option,
  ReduxStateType,
  Practitioner,
  Location,
  Reason,
  CustomOnChangeHandler
} from "../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  practitioners: Practitioner[];
  practitionersLoading: boolean;
  locations: Location[];
  locationsLoading: boolean;
  reasons: Reason[];
  reasonsLoading: boolean;
  createUnintegratedAppointment: (
    data: CreateUnintegratedAppointmentData,
    options?: { onSuccess?: (appointmentId?: string) => void }
  ) => void;
  createUnintegratedLoading: boolean;
  closeModal: () => void;
};

type FormState = {
  firstName: string;
  lastName: string;
  mobilePhone: string;
  email: string;
  phn: string;
  startDate: string;
  startTime: string;
  practitionerId: number | null;
  locationId: number | null;
  reasonId: number | null;
};

const firstNameValidator = isRequired("Please enter a first name");
const lastNameValidator = isRequired("Please enter a last name");
const mobilePhoneValidator = isPhone("Please enter a valid phone number");
const startTimeValidator = isRequired("Please enter a start time");
const startDateValidator = isRequired("Please enter a start date");
const providerValidator = isRequired("Please enter a provider");
const locationValidator = isRequired("Please enter a location");
const reasonValidator = isRequired("Please enter a reason");
const emailValidator = isEmail("Please enter a valid email");

const formValidator = (values: FormState) => {
  return {
    firstName: firstNameValidator(values.firstName),
    lastName: lastNameValidator(values.lastName),
    mobilePhone: mobilePhoneValidator(values.mobilePhone),
    email: emailValidator(values.email),
    phn: undefined,
    startTime: startTimeValidator(values.startTime),
    startDate: startDateValidator(values.startDate),
    practitionerId: providerValidator(values.practitionerId),
    locationId: locationValidator(values.locationId),
    reasonId: reasonValidator(values.reasonId)
  };
};

const AddUnintegratedAppointment = ({
  practitioners,
  practitionersLoading,
  locations,
  locationsLoading,
  reasons,
  reasonsLoading,
  createUnintegratedAppointment,
  createUnintegratedLoading,
  closeModal
}: PropsType) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { providerId } = useContext(UserContext);

  const providerOptions: Option[] = practitioners
    .filter((practitioner) => practitioner.practitionerActive)
    .map((practitioner) => ({
      label: generatePractitionerLabel(practitioner),
      value: practitioner.id
    }));
  const locationOptions: Option[] = locations
    .filter((location) => location.active)
    .map((location) => ({
      label: generateLocationLabel(location),
      value: location.id
    }));
  const reasonOptions: Option[] = reasons
    .filter((reason) => reason.active)
    .map((reason) => ({
      label: generateReasonLabel(reason),
      value: reason.id
    }));
  const formInitializing = practitionersLoading || locationsLoading || reasonsLoading;

  const initialFormState: FormState | null = useMemo(() => {
    if (formInitializing) return null;

    // initial selected practitionerId (1st current user, 2nd first option)
    let practitionerId = null;
    const currentProviderOption = providerId
      ? providerOptions.find((option) => option.value == providerId)
      : null;
    if (currentProviderOption) {
      practitionerId = currentProviderOption.value as number;
    } else if (providerOptions?.[0]?.value) {
      practitionerId = providerOptions?.[0]?.value as number;
    }

    // initial selected locationId (1st last selected location, 2nd first option)
    let locationId = null;
    const userPreferenceLocationId = localStorage.getItem(
      "userPreference_unintegratedCreate_locationId"
    );
    const preferredLocationOption = userPreferenceLocationId
      ? locationOptions.find((option) => option.value == userPreferenceLocationId)
      : null;
    if (preferredLocationOption) {
      locationId = preferredLocationOption.value as number;
    } else if (locationOptions?.[0]?.value) {
      locationId = locationOptions?.[0]?.value as number;
    }

    // initial selected reasonId (1st last selected reason, 2nd first option)
    let reasonId = null;
    const userPreferenceReasonId = localStorage.getItem(
      "userPreference_unintegratedCreate_reasonId"
    );
    const preferredReasonOption = userPreferenceReasonId
      ? reasonOptions.find((option) => option.value == userPreferenceReasonId)
      : null;
    if (preferredReasonOption) {
      reasonId = preferredReasonOption.value as number;
    } else if (reasonOptions?.[0]?.value) {
      reasonId = reasonOptions?.[0]?.value as number;
    }

    return {
      firstName: "",
      lastName: "",
      mobilePhone: "",
      email: "",
      phn: "",
      startTime: getCurrentTimeIntervalInitialValue() || "",
      startDate: "",
      practitionerId,
      locationId,
      reasonId
    };
  }, [formInitializing]);

  const save = async (formValues: FormState) => {
    const formattedStartDate = moment(formValues.startDate).format("ddd MMM DD YYYY");

    const data: CreateUnintegratedAppointmentData = {
      patient: {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        mobilePhone: formValues.mobilePhone,
        email: formValues.email,
        phn: formValues.phn
      },
      appointment: {
        start: moment(`${formattedStartDate} ${formValues.startTime}`).utc().toISOString(),
        practitionerId: formValues.practitionerId,
        locationId: formValues.locationId,
        reasonId: formValues.reasonId
      }
    };

    createUnintegratedAppointment(data, {
      onSuccess: (appointmentId?: string) => {
        if (appointmentId) {
          updateQueryString({ appointmentId }, setSearchParams);
        }

        return closeModal();
      }
    });
  };

  const updateUserPreference: CustomOnChangeHandler = (selection) => {
    const selectionField = Object.keys(selection)?.[0];

    if (selectionField) {
      const storageKey = `userPreference_unintegratedCreate_${selectionField}`;
      const storageValue = selection[selectionField]?.toString();
      if (storageValue) localStorage.setItem(storageKey, storageValue);
    }
  };

  const insufficientData =
    !formInitializing &&
    (providerOptions.length === 0 || locationOptions.length === 0 || reasonOptions.length === 0);

  return (
    <FloatModal isOpen onClose={closeModal}>
      {insufficientData && (
        <>
          <Heading size="L">Add New Appointment</Heading>
          <Text className={styles.SupportText}>
            Your account has insufficient provider, location, or reason data to use this feature.
            Please contact Mikata support team.
          </Text>
          <a
            className={styles.SupportLink}
            type="button"
            href="https://mikatahealth.com/help"
            rel="noreferrer"
            target="_blank"
          >
            <Button className={styles.SupportLinkButton} inline>
              Request Help
            </Button>
          </a>
        </>
      )}
      {!insufficientData && formInitializing && (
        <>
          <Heading size="L">Add New Appointment</Heading>
          <Loader screen center />
        </>
      )}
      {!insufficientData && !formInitializing && initialFormState && (
        <div className={styles.FormWrapper}>
          <Form
            onSubmit={(formState) => save(formState.values as FormState)}
            initialValues={initialFormState}
            validateFields={(values) => formValidator(values as FormState)}
          >
            <div className={styles.FormContent}>
              <Heading size="L">Add New Appointment</Heading>
              <section className={styles.FormSection}>
                <FormGrid>
                  <TextInput
                    fieldName="firstName"
                    label="first name"
                    placeholder="Enter first name"
                    validate={firstNameValidator}
                  />
                  <TextInput
                    fieldName="lastName"
                    label="last name"
                    placeholder="Enter last name"
                    validate={lastNameValidator}
                  />
                  <DateSelectInput
                    fieldName="startDate"
                    label="Date"
                    customMinDate={moment().toDate()}
                  />
                  <TimeSelectInput
                    fieldName="startTime"
                    label="Time"
                    placeholder="Select a start time"
                  />
                </FormGrid>
              </section>
              <section className={styles.FormSection}>
                <FormMoreDetails
                  editable
                  formSummaryFields={[
                    { label: "Mobile number (Optional)", fieldName: "mobilePhone" },
                    { label: "Email (Optional)", fieldName: "email" },
                    { label: "Healthcare number (Optional)", fieldName: "phn" },
                    { label: "Provider", fieldName: "practitionerId", options: providerOptions },
                    { label: "Location", fieldName: "locationId", options: locationOptions },
                    { label: "Reason", fieldName: "reasonId", options: reasonOptions }
                  ]}
                  formSection={
                    <div className={styles.FormMoreDetailsOpen}>
                      <TextInput
                        fieldName="mobilePhone"
                        label="Mobile number (Optional)"
                        validate={mobilePhoneValidator}
                      />
                      <TextInput
                        fieldName="email"
                        label="Email (Optional)"
                        validate={emailValidator}
                      />
                      <TextInput fieldName="phn" label="Healthcare number (Optional)" />
                      <SelectInput
                        fieldName="practitionerId"
                        label="Provider"
                        placeholder="Enter a provider"
                        validate={providerValidator}
                        options={providerOptions}
                      />
                      <SelectInput
                        fieldName="locationId"
                        label="Location"
                        placeholder="Enter a location"
                        validate={locationValidator}
                        options={locationOptions}
                        customOnChange={updateUserPreference}
                      />
                      <SelectInput
                        fieldName="reasonId"
                        label="Reason"
                        placeholder="Enter an appointment reason"
                        validate={reasonValidator}
                        options={reasonOptions}
                        customOnChange={updateUserPreference}
                      />
                    </div>
                  }
                />
              </section>
            </div>
            <div className={styles.FormFooter}>
              <Button inline onClick={closeModal}>
                Cancel
              </Button>
              <Button type="submit" disabled={createUnintegratedLoading}>
                Create
              </Button>
            </div>
          </Form>
        </div>
      )}
    </FloatModal>
  );
};

const mapStateToProps = ({ appointments, practitioners, locations, reasons }: ReduxStateType) => {
  return {
    createUnintegratedLoading: appointments.createUnintegratedLoading,
    practitioners: practitioners.data,
    practitionersLoading: practitioners.loading,
    locations: locations.data,
    locationsLoading: locations.locationsFetchLoading,
    reasons: reasons.data,
    reasonsLoading: !reasons.initialized
  };
};

export default connect(mapStateToProps, {
  createUnintegratedAppointment: createUnintegratedAppointmentAction,
  closeModal: closeModalAction
})(AddUnintegratedAppointment);
