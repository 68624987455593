import { flatten } from "lodash";

import { InputValue } from "../../../../../types";

import { getOperatorType, isCompositionOperator, getOperatorConfig } from ".";
import { ConditionJSON, ConditionValue, ContextVariable, PLACEHOLDER_OPERATOR } from "../types";

export type JsonLogicInputError = { errorMessage: string; path: string };

const validateCondition = (
  contextVariables: ContextVariable[],
  data: ConditionJSON | ConditionValue,
  path = "",
  errors: JsonLogicInputError[] = []
): JsonLogicInputError[] => {
  // handle condition values
  if (Array.isArray(data)) {
    const conditionValuesErrors: JsonLogicInputError[] = flatten(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data.map((item, idx) => {
        return validateCondition(
          contextVariables,
          item as ConditionJSON,
          `${path}[${idx}]`,
          errors
        );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      })
    );

    return [...errors, ...conditionValuesErrors];
  }

  // handle condition
  if (typeof data === "object") {
    const operatorType = getOperatorType(data as ConditionJSON);

    // handle var object (return early )
    if (operatorType === "var") {
      const varErrors: JsonLogicInputError[] = [];

      if (!data[operatorType]) {
        varErrors.push({ errorMessage: `Condition requires a defined variable`, path });
      }

      return [...errors, ...varErrors];
    }

    const conditionErrors = validateCondition(
      contextVariables,
      data[operatorType],
      `${path}${path ? "." : ""}${operatorType}`,
      errors
    );

    if (operatorType === PLACEHOLDER_OPERATOR.value) {
      conditionErrors.push({ errorMessage: "Please remove placeholder condition", path });
    }

    if (!isCompositionOperator(operatorType)) {
      const operatorConfig = getOperatorConfig(operatorType);
      const { validValueRange = null } = operatorConfig || {};
      // Return with no error if operator is unknown (non-editable, but still potentially valid state)
      if (!operatorConfig || !validValueRange) {
        conditionErrors.push({ errorMessage: `Invalid operator: ${operatorType}`, path });
      } else {
        // Ensure that answers relate to contextVariables if non extensible
        const conditionItems: ConditionValue = data[operatorType];

        const variableValueItem = conditionItems.find(
          (valueItem) =>
            !Array.isArray(valueItem) && typeof valueItem !== "string" && Boolean(valueItem?.var)
        );
        const variableValue = variableValueItem ? (variableValueItem as { var: string }).var : "";
        const contextVariable = contextVariables.find(
          (contextVar) => contextVar.inputName === variableValue
        );
        if (!contextVariable) {
          conditionErrors.push({
            errorMessage: "Variable no longer in context, please update.",
            path
          });
        }
        const definedConditionValues = conditionItems.filter(
          (value) => value && !(Array.isArray(value) && value.length === 0)
        );
        const [minLength, maxLength] = validValueRange;
        if (
          definedConditionValues.length < minLength ||
          definedConditionValues.length > maxLength
        ) {
          conditionErrors.push({
            errorMessage: "Condition missing required fields",
            path
          });
        }
      }
    }

    return [...errors, ...conditionErrors];
  }

  return [...errors];
};

export const buildDefaultValidator = (
  contextVariables: ContextVariable[],
  setErrorInfo: (errors: JsonLogicInputError[]) => void
) => {
  return (fieldValue: InputValue) => {
    if (fieldValue === null || fieldValue === undefined) return undefined;
    const errors = validateCondition(contextVariables, fieldValue);

    setErrorInfo(errors);

    return errors && errors.length > 0 ? errors[0].errorMessage : undefined;
  };
};
