import React, { useMemo, useState, useEffect } from "react";
import { connect } from "react-redux";
import cx from "classnames";

import moment from "moment";
import ChatSummaries from "../../../ChatSummaries";
import DirectMessageSummaries from "../../../DirectMessageSummaries";
import Heading from "../../../../ui/Heading";
import Text from "../../../../ui/Text";
import Card from "../../../../ui/Card";
import TranscriptMessages from "../../../../ui/TranscriptMessages";
import PageLayout from "../../../../ui/PageLayout";
import Button from "../../../../ui/Button";
import Timeline from "../../../Timeline";
import Drawer from "../../../../ui/Drawer";

import ContactPreferences from "./ContactPreferences";
import DirectPatientMessageButton from "../../../DirectPatientMessageButton";

import {
  Appointment,
  AppointmentDetail,
  ReduxStateType,
  AppointmentDetailTabModes,
  Permissions,
  HistoryEvent
} from "../../../../../types";
import getPatientFullName from "../../../../../utils/getPatientFullName";
import filterDirectMessageConversations from "../../../../../utils/filterDirectMessageConversations";
import { PermissionsGuard } from "../../../../../hooks/usePermissions";

import { getAppointmentHistory as getAppointmentHistoryAction } from "../../../../../actions";

import styles from "./index.module.scss";

type PropsType = {
  simpleAppointment?: Appointment;
  appointment?: AppointmentDetail;
  detailsLoading?: boolean;
  getAppointmentHistory?: (id: number) => void;
  appointmentHistoryLoading?: boolean;
  appointmentHistory?: HistoryEvent[];
};

const AppointmentDetails = ({
  simpleAppointment,
  appointment: appointmentDetails,
  detailsLoading,
  getAppointmentHistory,
  appointmentHistory,
  appointmentHistoryLoading
}: PropsType): JSX.Element | null => {
  /*
   * "appointmentData" is a combination of the simpleAppointment, if passed in as a prop,
   * and the results of the fetch appointment details. This pattern allows for quick rendering
   * of available information while waiting on detailed fetch results.
   */
  const appointmentData: AppointmentDetail | undefined =
    simpleAppointment || appointmentDetails
      ? ({ ...simpleAppointment, ...appointmentDetails } as AppointmentDetail)
      : undefined;

  const directMessageConversations = useMemo(
    () =>
      (appointmentDetails &&
        filterDirectMessageConversations(appointmentDetails.conversations, true)) ||
      [],
    [appointmentDetails]
  );

  const regularConversations = useMemo(
    () =>
      (appointmentDetails &&
        filterDirectMessageConversations(appointmentDetails.conversations, false)) ||
      [],
    [appointmentDetails]
  );

  useEffect(() => {
    if (getAppointmentHistory && simpleAppointment) {
      getAppointmentHistory(simpleAppointment.id);
    }
  }, [getAppointmentHistory, simpleAppointment]);

  const patientFirstName = appointmentData?.patientFirstName || "";
  const patientLastName = appointmentData?.patientLastName || "";

  const patientDisplayName = getPatientFullName(patientFirstName, patientLastName);

  const [tabMode, setTabMode] = useState(AppointmentDetailTabModes.CHAT);

  return (
    <PageLayout>
      <Heading size="L" component="h1">
        Appointment Details
      </Heading>
      <div className={styles.Wrapper}>
        <div className={styles.ContainerSide}>
          <Card>
            <div className={cx(styles.Heading, styles.BottomBorder)}>
              <Heading size="M" component="h5" className={styles.HeadingTitle}>
                Patient Detail
              </Heading>
              <DirectPatientMessageButton appointmentData={appointmentData} />
            </div>
            <div className={styles.PatientDetails}>
              <div className={styles.InfoGroup}>
                <Heading size="META" component="h5">
                  Patient Name
                </Heading>
                {/* Some EMR do not split first an last names.
                  In these cases the fist and last names are the
                  same and include both first and last names. */}
                <Text>{patientDisplayName || "-"}</Text>
              </div>
              <div className={styles.InfoGroup}>
                <Heading size="META" component="h5">
                  Date of Birth
                </Heading>
                <Text>
                  {appointmentData?.dateOfBirth
                    ? moment(appointmentData.dateOfBirth).format("YYYY-MM-DD")
                    : "-"}
                </Text>
              </div>
              <div className={styles.InfoGroup}>
                <Heading size="META" component="h5">
                  PHN
                </Heading>
                <Text>
                  {appointmentData?.healthcareIdentifier
                    ? appointmentData.healthcareIdentifier
                    : "-"}
                </Text>
              </div>
              <div className={styles.InfoGroup}>
                <Heading size="META" component="h5">
                  Primary Provider
                </Heading>
                <Text>
                  {appointmentData
                    ? appointmentData.practitionerDisplayName || appointmentData.practitionerName
                    : "-"}
                </Text>
              </div>
              <div className={styles.InfoGroup}>
                <Heading size="META" component="h5">
                  Reason
                </Heading>
                <Text>{appointmentData ? appointmentData.reasonName : "-"}</Text>
              </div>
              {appointmentData?.emrPatientId && (
                <div className={styles.InfoGroup}>
                  <Heading size="META" component="h5">
                    Patient Record Id
                  </Heading>
                  <Text>{appointmentData?.emrPatientId}</Text>
                </div>
              )}
            </div>
            <Drawer
              headerContent={
                <Heading size="S" bold component="h3">
                  Contact Preferences
                </Heading>
              }
              togglePosition="right"
              initialOpen
              collapse={false}
              toggleLine={false}
            >
              <div className={styles.Divider} />
              <Text size="S" className={styles.ContactPreferencesText}>
                Mikata will send notifications to every contact method that has a green checkmark.
              </Text>
              <div className={styles.Wrapper}>
                <div className={styles.ContainerSide}>
                  <ContactPreferences appointmentData={appointmentData} />
                </div>
              </div>
            </Drawer>
          </Card>

          {appointmentData && directMessageConversations.length > 0 && (
            <DirectMessageSummaries conversations={directMessageConversations} />
          )}

          {appointmentDetails && regularConversations.length > 0 && (
            <Card>
              <ChatSummaries
                appointment={appointmentDetails}
                conversations={regularConversations}
              />
            </Card>
          )}
        </div>
        <div className={styles.Column}>
          <div className={styles.Tabs}>
            <Button
              id="chatTranscriptsTab"
              inline
              className={cx(styles.Tab, {
                [styles.TabActive]: tabMode === AppointmentDetailTabModes.CHAT
              })}
              onClick={() => setTabMode(AppointmentDetailTabModes.CHAT)}
            >
              Chat Transcripts
            </Button>
            <PermissionsGuard requiredPermissions={[Permissions.GET_APPOINTMENTS_HISTORY]}>
              <Button
                id="timelineTab"
                inline
                className={cx(styles.Tab, {
                  [styles.TabActive]: tabMode === AppointmentDetailTabModes.TIMELINE
                })}
                onClick={() => setTabMode(AppointmentDetailTabModes.TIMELINE)}
              >
                Timeline
              </Button>
            </PermissionsGuard>
          </div>

          {tabMode === AppointmentDetailTabModes.CHAT && (
            <div>
              <Card className={styles.CardTranscripts}>
                <Heading
                  size="M"
                  component="h1"
                  className={cx(styles.Heading, styles.BottomBorder)}
                >
                  Chat Transcripts
                </Heading>
                <TranscriptMessages
                  transcript={appointmentData?.transcript}
                  loading={detailsLoading}
                />
              </Card>
            </div>
          )}
          {tabMode === AppointmentDetailTabModes.TIMELINE && (
            <div>
              <Timeline history={appointmentHistory} historyLoading={appointmentHistoryLoading} />
            </div>
          )}
        </div>
      </div>
    </PageLayout>
  );
};
const mapStateToProps = ({ appointments }: ReduxStateType) => {
  return {
    detailsLoading: appointments.details.loading,
    appointmentHistoryLoading: appointments.appointmentHistoryLoading,
    appointmentHistory: appointments.appointmentHistory
  };
};

export default connect(mapStateToProps, {
  getAppointmentHistory: getAppointmentHistoryAction
})(AppointmentDetails);
