import React, { useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";

import Heading from "../../../../ui/Heading";
import Button from "../../../../ui/Button";
import Loader from "../../../../ui/Loader";
import Text from "../../../../ui/Text";
import Card, { CardSection } from "../../../../ui/Card";
import TableGrid from "../../../../ui/TableGrid";
import Status from "../../../../ui/Status";
import TelusAuthSetup from "./Sections/TelusAuthSetup";
import AccuroAuthSetup from "./Sections/AccuroAuthSetup";
import StatusMapping from "./Sections/StatusMapping";
import useInterval from "../../../../../hooks/useInterval";

import {
  fetchOrgSettings,
  getIntegrationSummary as getIntegrationSummaryAction,
  startSync as startSyncAction,
  StartSyncOptions
} from "../../../../../actions";
import {
  IntegrationCategories,
  IntegrationTypes,
  SettingNames,
  SyncRecordStatuses
} from "../../../../../constants";

import {
  StatusComponentConfigMap,
  ReduxStateType,
  OrganizationData,
  SyncRecord,
  Setting
} from "../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  organization?: OrganizationData;
  settings?: Setting[];
  fetchOrganizationSettings: (organizationId: string) => void;
  getIntegrationSummary: (organizationId: string, options?: { silent: boolean }) => void;
  startSync: (organizationId: string, options: StartSyncOptions) => void;
  bulkSyncRecords: Array<SyncRecord>;
  incrementalSyncRecords: Array<SyncRecord>;
  organizationLoading: boolean;
};

const bulkHeaders = [
  { colName: "syncType", content: "Type of Sync" },
  { colName: "startTime", content: "Start Time" },
  { colName: "duration", content: "Time to Complete" },
  { colName: "recordCount", content: "Number of Appointments" },
  { colName: "status", content: "Sync Status" }
];

const incrementalHeaders = [
  { colName: "startTime", content: "Start Time" },
  { colName: "duration", content: "Time to Complete" },
  { colName: "recordCount", content: "Number of Appointments" },
  { colName: "status", content: "Sync Status" }
];

const statusConfigMap: StatusComponentConfigMap = {
  processing: "blue",
  success: "green",
  fail: "red",
  "fail no active locations": "red",
  "error no appts found": "red",
  stale: "blue"
};

const getDuration = (syncRecord: SyncRecord) => {
  if (syncRecord.endTime) {
    return `${Math.trunc(
      moment.duration(moment(syncRecord.endTime).diff(moment(syncRecord.startTime))).asMinutes()
    )} min`;
  }
  if (Math.trunc(moment.duration(moment().diff(moment(syncRecord.startTime))).asMinutes()) > 120) {
    return "-";
  }
  return `${Math.trunc(
    moment.duration(moment().diff(moment(syncRecord.startTime))).asMinutes()
  )} min`;
};

const getSyncType = (syncRecord: SyncRecord) => {
  if (syncRecord.bulkAppointmentsSynced) {
    return "Bulk";
  }
  if (syncRecord.sample) {
    return "Initial";
  }
  if (syncRecord.fetchAllAppts) {
    return "Fetch All";
  }
};

const IntegrationPage = ({
  organization,
  settings,
  fetchOrganizationSettings,
  getIntegrationSummary,
  startSync,
  bulkSyncRecords,
  incrementalSyncRecords,
  organizationLoading
}: PropsType): JSX.Element | null => {
  if (!organization) return <Loader screen />;

  useEffect(() => {
    if (organization.id) {
      fetchOrganizationSettings(organization.id.toString());
      getIntegrationSummary(organization.id.toString());
    }
  }, [organization.id]);

  const POLL_INTERVAL_MS = 30000;

  useInterval(
    () => {
      getIntegrationSummary(organization.id.toString(), { silent: true });
    },
    [bulkSyncRecords, incrementalSyncRecords],
    POLL_INTERVAL_MS
  );

  const integrationType = organization?.emrType?.integrationType;
  const { integrationCategory, canUpload } = organization;

  const bulkSyncRangeSetting = settings?.find(
    (setting) => setting.settingName === SettingNames.BULK_SYNC_RANGE_WEEKS
  );
  const bulkSyncRange = bulkSyncRangeSetting?.settingValue;

  const bulkSyncRows = bulkSyncRecords.map((syncRecord: SyncRecord) => {
    return {
      ...syncRecord,
      startTime: `${moment(syncRecord.startTime).format("MMMM D, YYYY")} at ${moment(
        syncRecord.startTime
      ).format("LT")}`,
      syncType: getSyncType(syncRecord),
      duration: getDuration(syncRecord),
      recordCount:
        syncRecord.status === "processing" || syncRecord.recordCount === 0
          ? "-"
          : syncRecord.recordCount,
      status: (
        <Status
          value={
            // If the elapsed time since the sync was started has exceeded two hours, we want to stop displaying it as in progress and display it as failed.
            syncRecord.status === SyncRecordStatuses.PROCESSING &&
            Math.trunc(moment.duration(moment().diff(moment(syncRecord.startTime))).asMinutes()) >
              120
              ? SyncRecordStatuses.STALE
              : syncRecord.status
          }
          options={[
            { label: "In Progress", value: SyncRecordStatuses.PROCESSING },
            { label: "Complete", value: SyncRecordStatuses.SUCCESS },
            { label: "Fail", value: SyncRecordStatuses.FAIL },
            { label: "Fail - No Locations", value: SyncRecordStatuses.FAIL_NO_LOCATIONS },
            { label: "No Appts Found", value: SyncRecordStatuses.NO_APPTS_FOUND },
            { label: "Stale", value: SyncRecordStatuses.STALE }
          ]}
          configMap={statusConfigMap}
        />
      )
    };
  });

  const incrementalSyncRows = incrementalSyncRecords.map((syncRecord: SyncRecord) => {
    return {
      ...syncRecord,
      startTime: `${moment(syncRecord.startTime).format("MMMM D, YYYY")} at ${moment(
        syncRecord.startTime
      ).format("LT")}`,
      duration: getDuration(syncRecord),
      recordCount:
        syncRecord.status === "processing" || syncRecord.recordCount === 0
          ? "-"
          : syncRecord.recordCount,
      status: (
        <Status
          value={
            // If the elapsed time since the sync was started has exceeded two hours, we want to stop displaying it as in progress and display it as failed.
            syncRecord.status === SyncRecordStatuses.PROCESSING &&
            Math.trunc(moment.duration(moment().diff(moment(syncRecord.startTime))).asMinutes()) >
              120
              ? SyncRecordStatuses.STALE
              : syncRecord.status
          }
          options={[
            { label: "In Progress", value: SyncRecordStatuses.PROCESSING },
            { label: "Complete", value: SyncRecordStatuses.SUCCESS },
            { label: "Fail", value: SyncRecordStatuses.FAIL },
            { label: "Fail - No Locations", value: SyncRecordStatuses.FAIL_NO_LOCATIONS },
            { label: "No Appts Found", value: SyncRecordStatuses.NO_APPTS_FOUND },
            { label: "Stale", value: SyncRecordStatuses.STALE }
          ]}
          configMap={statusConfigMap}
        />
      )
    };
  });

  return (
    <Card fullWidth>
      {integrationType === IntegrationTypes.NONE && canUpload && (
        <CardSection title="Integration">
          <div className={styles.FlexColumn}>
            <Heading size="META" component="span">
              Type
            </Heading>
            <Heading size="S" component="span">
              Upload
            </Heading>
          </div>
        </CardSection>
      )}
      {integrationType === IntegrationTypes.NONE && !canUpload && (
        <CardSection title="Integration">
          <div className={styles.FlexColumn}>
            <Heading size="META" component="span">
              Type
            </Heading>
            <Heading size="S" component="span">
              Unintegrated
            </Heading>
          </div>
        </CardSection>
      )}
      {integrationType === IntegrationTypes.PUSH && (
        <CardSection title="Integration">
          <div className={styles.FlexColumn}>
            <Heading size="META" component="span">
              Type
            </Heading>
            <Heading size="S" component="span">
              Push
            </Heading>
          </div>
        </CardSection>
      )}
      {integrationType === IntegrationTypes.PULL && (
        <>
          <CardSection title="Integration">
            <div className={styles.FlexColumn} id="integrationType">
              <Heading size="META" component="span">
                Type
              </Heading>
              <Heading size="S" component="span">
                Pull
              </Heading>
              <Heading size="META" component="span">
                Category
              </Heading>
              <Heading size="S" component="span">
                {integrationCategory === IntegrationCategories.TELUS && "Telus"}
                {integrationCategory === IntegrationCategories.ACCURO && "Accuro"}
              </Heading>
              <div className={styles.ButtonRow}>
                <Button
                  onClick={() => {
                    return startSync(organization.id.toString(), { sample: true });
                  }}
                  id="syncAccountInfo"
                >
                  Sync Account Information
                </Button>
                <Button
                  onClick={() => {
                    return startSync(organization.id.toString(), { fetchAllAppts: true });
                  }}
                >
                  Sync All Appointments
                </Button>
              </div>
            </div>
          </CardSection>

          <CardSection>
            <div className={styles.FlexColumn}>
              <Heading size="S" component="span">
                Sync History
              </Heading>
              {organizationLoading ? (
                <Loader center />
              ) : (
                <>
                  <div>
                    <Button
                      onClick={() => {
                        return startSync(organization.id.toString(), {
                          manualBulkSync: true
                        });
                      }}
                      className={styles.BulkSyncButton}
                    >
                      Bulk Sync
                    </Button>
                  </div>
                  <TableGrid
                    id="bulkSyncHistory"
                    headers={bulkHeaders}
                    rows={bulkSyncRows}
                    maxPageRows={5}
                    emptyContent={
                      <Heading className={styles.NoSyncHistory}>No available sync history</Heading>
                    }
                  />
                  <br />
                  <Text>
                    Bulk sync time range:{" "}
                    {bulkSyncRange ? `${bulkSyncRange} weeks` : "4 weeks (Default)"}
                  </Text>
                </>
              )}
            </div>
          </CardSection>

          <CardSection>
            <div className={styles.FlexColumn}>
              <Heading size="S" component="span">
                Incremental Sync History
              </Heading>
              {organizationLoading ? (
                <Loader center />
              ) : (
                <TableGrid
                  id="incrementalSyncHistory"
                  headers={incrementalHeaders}
                  rows={incrementalSyncRows}
                  maxPageRows={5}
                  emptyContent={
                    <Heading className={styles.NoSyncHistory}>No available sync history</Heading>
                  }
                />
              )}
            </div>
          </CardSection>
          {integrationCategory === IntegrationCategories.TELUS && (
            <>
              <CardSection title="Authentication" id="telusAuthentication">
                <TelusAuthSetup />
              </CardSection>
              <CardSection title="Status Mapping" id="telusStatusMapping">
                <StatusMapping integrationCategory={IntegrationCategories.TELUS} />
              </CardSection>
              <CardSection title="Check-in Status Mapping">
                <StatusMapping integrationCategory={IntegrationCategories.TELUS} isCheckinMapping />
              </CardSection>
            </>
          )}
          {integrationCategory === IntegrationCategories.ACCURO && (
            <>
              <CardSection title="Authentication">
                <AccuroAuthSetup />
              </CardSection>
              <CardSection title="Status Mapping" id="accuroStatusMapping">
                <StatusMapping
                  key={`statusMapping-${organization?.accuroUUID}`}
                  integrationCategory={IntegrationCategories.ACCURO}
                />
              </CardSection>
              <CardSection title="Check-in Status Mapping">
                <StatusMapping
                  key={`checkInMapping-${organization?.accuroUUID}`}
                  integrationCategory={IntegrationCategories.ACCURO}
                  isCheckinMapping
                />
              </CardSection>
            </>
          )}
        </>
      )}
    </Card>
  );
};

const mapStateToProps = ({ organizationDetails }: ReduxStateType) => {
  return {
    organization: organizationDetails.data,
    settings: organizationDetails.settings,
    bulkSyncRecords: organizationDetails.bulkSyncs,
    incrementalSyncRecords: organizationDetails.incrementalSyncs,
    organizationLoading: organizationDetails.organizationLoading
  };
};

export default connect(mapStateToProps, {
  fetchOrganizationSettings: fetchOrgSettings,
  startSync: startSyncAction,
  getIntegrationSummary: getIntegrationSummaryAction
})(IntegrationPage);
