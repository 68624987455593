import { SEND_DIRECT_MESSAGE, FETCH_DIRECT_MESSAGE_DETAILS } from "../constants";

import {
  SendDirectMessageAction,
  FetchDirectMessageDetailsAction,
  DeleteSecureDirectMessageItemAction
} from "../actions";
import { ActionStatus, AppointmentConversation, SecureMessageItemData } from "../types";

export type MessagesAction =
  | SendDirectMessageAction
  | FetchDirectMessageDetailsAction
  | DeleteSecureDirectMessageItemAction;

export type MessagesReduxState = {
  sendDirectMessageLoading: boolean;
  directMessageDetailsLoading: boolean;
  directMessageDetails: {
    conversationSiblings: Array<AppointmentConversation>;
    emailPayloadContent: string;
    senderDisplayName: string;
    smsPayloadContent: string;
    secureMessageItems: Array<SecureMessageItemData>;
  };
};

const initialMessagesState: MessagesReduxState = {
  sendDirectMessageLoading: false,
  directMessageDetailsLoading: false,
  directMessageDetails: {
    conversationSiblings: [],
    emailPayloadContent: "",
    senderDisplayName: "",
    smsPayloadContent: "",
    secureMessageItems: []
  }
};

export const messagesReducer = (
  state = initialMessagesState,
  action: MessagesAction
): MessagesReduxState => {
  switch (action.type) {
    case SEND_DIRECT_MESSAGE: {
      const { status } = action as SendDirectMessageAction;
      return {
        ...state,
        sendDirectMessageLoading: status === "loading"
      };
    }
    case FETCH_DIRECT_MESSAGE_DETAILS: {
      const { status, payload } = action as FetchDirectMessageDetailsAction;
      return {
        ...state,
        directMessageDetailsLoading: status === ActionStatus.loading,
        directMessageDetails: {
          ...state.directMessageDetails,
          conversationSiblings:
            payload?.conversationSiblings || state.directMessageDetails.conversationSiblings,
          emailPayloadContent:
            payload?.emailPayloadContent || state.directMessageDetails.emailPayloadContent,
          senderDisplayName:
            payload?.senderDisplayName || state.directMessageDetails.senderDisplayName,
          smsPayloadContent:
            payload?.smsPayloadContent || state.directMessageDetails.smsPayloadContent,
          secureMessageItems:
            payload?.secureMessageItems || state.directMessageDetails.secureMessageItems
        }
      };
    }

    default:
      return state;
  }
};
