import React from "react";
import cx from "classnames";

import { useFormState } from "informed";
import Button, { ButtonRow } from "../../../../ui/Button";
import Text from "../../../../ui/Text";
import Card, { CardSection } from "../../../../ui/Card";
import { RadioGroup, SelectInput, TextInput } from "../../../../ui/Input";
import Tooltip from "../../../../ui/Tooltip";

import TableGrid from "../../../../ui/TableGrid";

import { isRequired } from "../../../../../utils/validators";

import SubscriptionsInput, { SubscriptionsFormState } from "./SubscriptionsInput";

import { OrganizationContextType } from "../../../../providers/OrganizationProvider";
import { OrganizationTypes } from "../../../../../constants";
import {
  BillingInvoiceTypes,
  PricingItem,
  Practitioner,
  Option,
  BillingConfigSetting
} from "../../../../../types";

import styles from "./index.module.scss";

const customerValidator = isRequired("Please select from customer list");

type FormState = {
  chargeOverCustomerId?: string;
  chargeOverSubscriptionId?: string; // this is the simple billing config subscriptionId that is still in use
  chargeOverUserId?: string;
  invoiceType?: BillingInvoiceTypes;
  pricing: PricingItem[];
  subscriptions: SubscriptionsFormState;
};

type PropsType = {
  organization: OrganizationContextType;
  pricingLabels: string[];
  parentBillingConfig: BillingConfigSetting;
  billingConfigLoading?: boolean;
  billingConfig: BillingConfigSetting;
  urlLoading: boolean;
  url: string;
  backfill: () => void;
  customerOptions: Option[];
  invoiceTypeOptions: Option[];
  userCanEditBilling?: boolean;
  practitioners: Practitioner[];
};

const defaultPricing: PricingItem[] = [
  { minimum: 1, maximum: 500, price: "0.45" },
  { minimum: 501, maximum: 1000, price: "0.38" },
  { minimum: 1001, maximum: 2000, price: "0.35" },
  { minimum: 2001, maximum: 3000, price: "0.30" },
  { minimum: 3001, maximum: 5000, price: "0.28" },
  { minimum: 5001, maximum: 7500, price: "0.27" },
  { minimum: 7501, maximum: 10000, price: "0.26" },
  { minimum: 10001, maximum: 20000, price: "0.24" },
  { minimum: 20001, maximum: 50000, price: "0.22" },
  { minimum: 50001, maximum: 100000, price: "0.20" },
  { minimum: 100001, maximum: 200000, price: "0.18" },
  { minimum: 200001, maximum: 500000, price: "0.16" }
];

const BillingPageForm = ({
  organization,
  pricingLabels,
  parentBillingConfig,
  billingConfigLoading,
  billingConfig,
  urlLoading,
  backfill,
  url,
  customerOptions,
  invoiceTypeOptions,
  userCanEditBilling,
  practitioners
}: PropsType) => {
  const formState = useFormState();
  const values = formState.values as FormState;

  const isParent = organization?.type === OrganizationTypes.PARENT;
  const hasParent = !!organization?.parentOrganizationId;
  const isSingleInvoice = values.invoiceType === BillingInvoiceTypes.SINGLE;
  const isMultiInvoice = values.invoiceType === BillingInvoiceTypes.MULTI;
  const pricingRows = defaultPricing.map((pricingItem, index) => {
    return {
      ...pricingItem,
      label: pricingLabels[index],
      input: <TextInput fieldName={`pricing.${index}.price`} />
    };
  });
  const parentInvoiceType = parentBillingConfig?.invoiceType;
  const isChildOfSingleInvoiceParent =
    !isParent && hasParent && parentInvoiceType === BillingInvoiceTypes.SINGLE;

  const showChargeOverCustomerIdInput = !isChildOfSingleInvoiceParent;
  const showChargeOverUserId =
    !isChildOfSingleInvoiceParent && Boolean(billingConfig?.chargeOverCustomerId);
  const showInvoiceTypeInput = isParent;
  const showPricingInput = isParent && isMultiInvoice;
  const showSubscriptionsInput =
    Boolean(billingConfig?.chargeOverCustomerId) &&
    (!hasParent ||
      (isParent && isSingleInvoice) ||
      (hasParent && parentBillingConfig?.invoiceType === BillingInvoiceTypes.MULTI));

  return (
    <Card fullWidth>
      <CardSection title="Billing">
        <div className={styles.LinkBackfillRow}>
          <div>
            {!urlLoading && (
              <>
                {url ? (
                  <Button inline>
                    <a href={url} rel="noreferrer" target="_blank">
                      <Text>Billing Link</Text>
                    </a>
                  </Button>
                ) : (
                  <Text>No billing link available</Text>
                )}
              </>
            )}
          </div>
          <Tooltip
            contentClassName={styles.TooltipContent}
            position="topRight"
            icon={
              <Button
                inline
                danger
                onClick={() => {
                  backfill();
                }}
              >
                Backfill
              </Button>
            }
            size="S"
          >
            Backfills usage data for every day in the current month. If within the first week of the
            month, also backfills the previous month.
          </Tooltip>
        </div>
        <br />

        {isChildOfSingleInvoiceParent && (
          <>
            <Text>
              This organization belongs to a group with a single invoice. To make changes to the
              billing configuration, visit the billing page for:
            </Text>
            <Text className={styles.BoldText}>{organization?.parent?.fullName}</Text>
          </>
        )}

        <div className={cx({ [styles.Hide]: !showChargeOverCustomerIdInput })}>
          <SelectInput
            fieldName="chargeOverCustomerId"
            label="Chargeover Customer Id"
            options={customerOptions}
            placeholder="Select Customer"
            validate={customerValidator}
          />
        </div>

        <div className={cx({ [styles.Hide]: !showChargeOverUserId })}>
          <TextInput fieldName="chargeOverUserId" label="Chargeover User Id" disabled />
        </div>

        <div className={cx({ [styles.Hide]: !showInvoiceTypeInput })}>
          <RadioGroup
            fieldName="invoiceType"
            label="Invoice Setting"
            options={invoiceTypeOptions}
          />
        </div>

        <div className={cx({ [styles.Hide]: !showPricingInput })}>
          <Text bold>Group rates</Text>

          <TableGrid
            headers={[
              { colName: "label", content: "Touchpoints" },
              { colName: "input", content: "Cost per Touchpoint ($)" }
            ]}
            rows={pricingRows}
          />
        </div>
      </CardSection>
      <div className={cx({ [styles.Hide]: !showSubscriptionsInput })}>
        <SubscriptionsInput
          fieldName="subscriptions"
          practitioners={practitioners}
          chargeOverSubscriptionId={values.chargeOverSubscriptionId}
        />
      </div>

      <ButtonRow>
        <Button type="submit" disabled={billingConfigLoading || !userCanEditBilling}>
          Save
        </Button>
      </ButtonRow>
    </Card>
  );
};

export default BillingPageForm;
