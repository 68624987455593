import axios from "axios";

import getToken from "../utils/getToken";

import { addNotification } from "./notifications";
import { closeModal } from "./modals";

import {
  ROOT_URL,
  SEND_DIRECT_MESSAGE,
  FETCH_DIRECT_MESSAGE_DETAILS,
  DELETE_SECURE_DIRECT_MESSAGE_ITEM,
  DirectMessageModes
} from "../constants";
import { Dispatch, ActionStatus, AppointmentConversation, SecureMessageItemData } from "../types";

import { fetchAppointmentsDetail } from "./appointments";

export type SendDirectMessageData = {
  mode: string;
  patientId?: number;
  appointmentId?: number;
  chatFlowIds?: number[];
  payloadContent: string;
  attachments?: { s3FileName: string; clinicFileName: string }[] | [];
  mobilePhone: string | null;
  emailAddress: string | null;
  assignedStaffUserId?: string | null;
};

export type SendDirectMessageAction = {
  type: typeof SEND_DIRECT_MESSAGE;
  status?: ActionStatus;
};

type SendDirectMessageResponse = {
  status: number;
  data: {
    success: boolean;
    smsRecordId: number | null;
    emailRecordId: number | null;
  };
};

export type FetchDirectMessageDetailsAction = {
  type: typeof FETCH_DIRECT_MESSAGE_DETAILS;
  status?: ActionStatus;
  payload?: {
    success: boolean;
    senderDisplayName: string;
    smsPayloadContent: string;
    emailPayloadContent: string;
    conversationSiblings: Array<AppointmentConversation>;
    secureMessageItems: Array<SecureMessageItemData>;
  };
};

export type DeleteSecureDirectMessageItemAction = {
  type: typeof DELETE_SECURE_DIRECT_MESSAGE_ITEM;
  status?: ActionStatus;
  payload?: {
    success: boolean;
  };
};

export const sendDirectMessage = (data: SendDirectMessageData) => (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };
  const appointmentRequestDirectMessage = data.mode === DirectMessageModes.APPOINTMENT_REQUEST;

  const handleFailure = (errorMessage?: string) => {
    dispatch(
      addNotification({
        type: "error",
        title: appointmentRequestDirectMessage
          ? "Failed to send request link"
          : `Failed to send direct message`,
        subtitle: appointmentRequestDirectMessage
          ? errorMessage || "Something went wrong. Please try another phone number or use an email."
          : errorMessage || "Please try again",
        autoDismiss: true
      })
    );

    dispatch({
      type: SEND_DIRECT_MESSAGE,
      status: ActionStatus.error
    });
  };

  dispatch({
    type: SEND_DIRECT_MESSAGE,
    status: ActionStatus.loading
  });

  return axios
    .post(`${ROOT_URL}/messages`, data, config)
    .then((payload: SendDirectMessageResponse) => {
      if (!payload || !payload.data.success) {
        handleFailure();
        return;
      }
      closeModal()(dispatch);

      dispatch(
        addNotification({
          type: "success",
          title: appointmentRequestDirectMessage ? "Request link sent" : "Success",
          subtitle: appointmentRequestDirectMessage
            ? "If he/she can’t receive the message, make sure the contact detail is correct and the email hasn’t been marked as spam."
            : "Direct message was sent successfully",
          autoDismiss: true
        })
      );
      dispatch({
        type: SEND_DIRECT_MESSAGE,
        status: ActionStatus.success
      });

      if (data.appointmentId) fetchAppointmentsDetail(data.appointmentId.toString())(dispatch);
    })
    .catch(() => {
      return handleFailure();
    });
};

export const fetchDirectMessageDetails = (conversationId: string) => async (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  dispatch({
    type: FETCH_DIRECT_MESSAGE_DETAILS,
    status: ActionStatus.loading
  });

  try {
    const response = await axios.get(
      `${ROOT_URL}/messages/secure-message-items/find-for-conversation/${conversationId}`,
      config
    );

    dispatch({
      type: FETCH_DIRECT_MESSAGE_DETAILS,
      status: ActionStatus.success,
      payload: response.data
    });
  } catch (e) {
    dispatch(
      addNotification({
        type: "error",
        title: "Error fetching direct message details",
        subtitle: "Please try again",
        autoDismiss: true
      })
    );

    dispatch({
      type: FETCH_DIRECT_MESSAGE_DETAILS,
      status: ActionStatus.error
    });
  }
};

export const deleteSecureDirectMessageItem =
  (secureMessageId: number, onSuccess?: () => void) => async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: DELETE_SECURE_DIRECT_MESSAGE_ITEM,
      status: ActionStatus.loading
    });

    try {
      const response = await axios.delete(
        `${ROOT_URL}/messages/secure-message-items/${secureMessageId}`,
        config
      );

      if (!response || !response.data.success) {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to delete secure message item",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );

        return dispatch({
          type: DELETE_SECURE_DIRECT_MESSAGE_ITEM,
          status: ActionStatus.error
        });
      }

      dispatch({
        type: DELETE_SECURE_DIRECT_MESSAGE_ITEM,
        status: ActionStatus.success,
        payload: response.data
      });

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      dispatch(
        addNotification({
          type: "error",
          title: "Failed to delete secure message item",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );

      dispatch({
        type: DELETE_SECURE_DIRECT_MESSAGE_ITEM,
        status: ActionStatus.error
      });
    }
  };
