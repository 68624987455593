import React, { useEffect } from "react";
import { connect } from "react-redux";

import { User, ReduxStateType, Permissions } from "../../../../types";
import TableGrid from "../../../ui/TableGrid";

import { fetchUsers as fetchUsersAction, OpenModal, openModal } from "../../../../actions";
import { AdminModalTypes, UserTypeConstants } from "../../../../constants";
import Loader from "../../../ui/Loader";
import { usePermissions } from "../../../../hooks/usePermissions";

type PropsType = {
  users: Array<User>;
  usersLoading: boolean;
  fetchUsers: (organizationId: number | null, userType: UserTypeConstants) => void;
  openModalConnect: OpenModal;
};

const MikataAdminUsersTable = ({
  users,
  usersLoading,
  fetchUsers,
  openModalConnect
}: PropsType) => {
  const canUpdateAdmin = usePermissions([Permissions.UPDATE_ADMIN_USER], false);
  const headers = [
    { colName: "name", content: "Name" },
    { colName: "email", content: "Email" },
    { colName: "status", content: "Status" },
    { colName: "roleTitle", content: "Role" }
  ];

  useEffect(() => {
    fetchUsers(null, UserTypeConstants.MIKATA_ADMIN);
  }, []);

  const userRows = users
    .sort((a: User, b: User) => a.firstName.localeCompare(b.firstName))
    .map((user) => ({
      __onRowClick: canUpdateAdmin
        ? () => {
            openModalConnect(AdminModalTypes.EDIT_ADMIN_USER, {
              userInfo: user,
              onSuccess: () => {
                fetchUsers(null, UserTypeConstants.MIKATA_ADMIN);
              }
            });
          }
        : undefined,
      ...user,
      name: `${user.firstName} ${user.lastName}`
    }));

  if (usersLoading) return <Loader screen />;

  return <TableGrid headers={headers} rows={userRows} maxPageRows={10} showRowFocus />;
};

const mapStateToProps = ({ users }: ReduxStateType) => {
  return {
    users: users.users,
    usersLoading: users.usersLoading
  };
};

export default connect(mapStateToProps, {
  fetchUsers: fetchUsersAction,
  openModalConnect: openModal
})(MikataAdminUsersTable);
