import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import cx from "classnames";

import Button from "../../ui/Button";

import Heading from "../../ui/Heading";
import { Check } from "../../ui/Icon";
import Text from "../../ui/Text";
import Tags from "../../ui/Tags";
import Loader from "../../ui/Loader";

import {
  fetchNoteTemplateSuggestions as fetchNoteTemplateSuggestionsAction,
  createNote as createNoteAction,
  openModal as openModalAction,
  OpenModal,
  CreateNoteData
} from "../../../actions";

import { Note, NoteTemplate, ReduxStateType } from "../../../types";
import { getNoteTemplateIcon } from "../../mikata_admin/OrganizationDetails/views/NoteTemplatesTable";
import {
  ModalTypes,
  NoteTemplateActionTags,
  NoteTemplateTagsConfig,
  NoteTemplateTypeTags
} from "../../../constants";

import styles from "./index.module.scss";

type PropsType = {
  noteTemplateSuggestionsLoading: boolean;
  noteTemplateSuggestion: NoteTemplate[];
  appointmentId: number;
  patientId: number;
  notes: Note[];
  createNotesLoading: boolean;
  openModal: OpenModal;
  setActiveAppointmentNoteId: (noteId: number) => void;
  fetchNoteTemplateSuggestions: () => void;
  createNote: (body: CreateNoteData, onSuccess?: (noteId: number) => void) => void;
};

const DoMoreWithMika = ({
  noteTemplateSuggestionsLoading,
  appointmentId,
  patientId,
  noteTemplateSuggestion,
  notes,
  setActiveAppointmentNoteId,
  createNotesLoading,
  openModal,
  fetchNoteTemplateSuggestions,
  createNote
}: PropsType) => {
  const [selectedTemplateId, setSelectedTemplateId] = useState<number>();
  const isTemplateAlreadyAdded = React.useCallback(
    (templateTitle: string) => {
      return notes?.some((item) => item.title === templateTitle);
    },
    [notes]
  );

  const getApplicableTags = (tags: string[]) => {
    return tags?.filter(
      (item) =>
        Object.values({ ...NoteTemplateTypeTags, ...NoteTemplateActionTags }).indexOf(item) === -1
    );
  };
  const initializeNote = (noteTemplateId: number) => {
    createNote(
      {
        noteTemplateId,
        patientId,
        appointmentId
      },
      (noteId: number) => {
        if (setActiveAppointmentNoteId) {
          setActiveAppointmentNoteId(noteId);
        }
        setSelectedTemplateId(undefined);
      }
    );
  };

  useEffect(() => {
    fetchNoteTemplateSuggestions();
  }, []);

  if (noteTemplateSuggestionsLoading) {
    return <Loader screen small />;
  }

  return (
    <>
      {noteTemplateSuggestion?.length > 0 && (
        <Heading component="h6" size="META">
          TRY THESE DOCUMENTS
        </Heading>
      )}
      <div className={styles.NoteTemplatesContainer}>
        {noteTemplateSuggestion.length > 0 ? (
          noteTemplateSuggestion.map((template) => (
            <div key={template.id}>
              <div className={styles.Template}>
                <div className={styles.TemplateDetailsContainer}>
                  {getNoteTemplateIcon(
                    template?.tags?.find((item) =>
                      Object.values(NoteTemplateTypeTags).includes(item)
                    ),
                    24
                  )}
                  <div>
                    <Text size="S" bold>
                      {template.title}
                    </Text>
                    <Text size="XS" className={styles.TemplateTitle}>
                      {template.description}
                    </Text>
                    {template?.tags && getApplicableTags(template.tags)?.length > 0 && (
                      <Tags
                        tags={getApplicableTags(template.tags)}
                        configMap={NoteTemplateTagsConfig}
                      />
                    )}
                  </div>

                  <div className={styles.ActionIconRow}>
                    <Button
                      inline
                      className={cx(styles.ActionButton, {
                        [styles.TemplateAdded]: isTemplateAlreadyAdded(template.title)
                      })}
                      onClick={(event) => {
                        event.stopPropagation();
                        setSelectedTemplateId(template.id);
                        initializeNote(template.id);
                      }}
                    >
                      {isTemplateAlreadyAdded(template.title) ? (
                        <Check size={24} strokeWidth={3} />
                      ) : (
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        <>
                          {createNotesLoading && selectedTemplateId === template.id ? (
                            <Loader className={styles.Loader} small />
                          ) : (
                            "Add"
                          )}
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <Text size="S" className={styles.TemplateTableMessage}>
            No template suggestions found
          </Text>
        )}
      </div>
      <Button
        type="button"
        inline
        className={cx(styles.ActionButton, styles.Footer)}
        onClick={() => {
          if (appointmentId) {
            openModal(ModalTypes.INITIALIZE_NOTE, {
              patientId,
              appointmentId,
              setActiveAppointmentNoteId: (noteId: number) => {
                setActiveAppointmentNoteId(noteId);
              }
            });
          }
        }}
      >
        View all documents
      </Button>
    </>
  );
};

const mapStateToProps = ({ notes, noteTemplates }: ReduxStateType) => {
  return {
    notes: notes.notes || [],
    noteTemplateSuggestion: noteTemplates.noteTemplateSuggestions,
    noteTemplateSuggestionsLoading: noteTemplates.noteTemplateSuggestionsLoading,
    createNotesLoading: notes.createLoading
  };
};

export default connect(mapStateToProps, {
  createNote: createNoteAction,
  fetchNoteTemplateSuggestions: fetchNoteTemplateSuggestionsAction,
  openModal: openModalAction
})(DoMoreWithMika);
