import React from "react";

import ElementInput from "../ElementInput";

import { defaultConditionOperators } from "../defaultConfigurations";

import { ContextVariable, UpdateCondition, ElementInputValue } from "../types";

type PropsType = {
  className?: string;
  conditionPath: string;
  operatorValue: string;
  variableValue: string;
  contextVariables: ContextVariable[];
  updateCondition: UpdateCondition;
  disabled: boolean;
};

const Operator = ({
  className,
  conditionPath,
  operatorValue,
  variableValue,
  contextVariables,
  updateCondition,
  disabled
}: PropsType) => {
  const selectedContextVariable = variableValue
    ? contextVariables.find((contextVariable) => contextVariable.inputName === variableValue)
    : null;
  const operatorOptions = defaultConditionOperators
    .filter((conditionOperator) => {
      return (
        selectedContextVariable &&
        conditionOperator.validContextTypes.includes(selectedContextVariable.type)
      );
    })
    .map((conditionOperator) => {
      return { label: conditionOperator.label, value: conditionOperator.jsonLogicKey };
    });

  const onOperatorSelection = (selection: ElementInputValue) => {
    updateCondition(conditionPath, "operator", selection);
  };

  return (
    <div className={className}>
      <ElementInput
        elementType="operator"
        placeholder="choose operator"
        options={operatorOptions}
        value={operatorValue}
        onChange={onOperatorSelection}
        disabled={disabled}
      />
    </div>
  );
};

export default Operator;
