import {
  TouchpointSettingRangeTime,
  TouchpointSettingRangeTimeType,
  TouchpointSettingRangeTimeUnit
} from "../../types";

const SECONDS_PER_HOUR = 60 * 60;
const SECONDS_PER_DAY = 60 * 60 * 24;

const getRelativeSecondsFromTouchpoint = (range: TouchpointSettingRangeTime) => {
  let absoluteStartSecondsFromTouchpoint = range.value;
  if (range.unit === TouchpointSettingRangeTimeUnit.HOURS)
    absoluteStartSecondsFromTouchpoint = range.value * SECONDS_PER_HOUR;
  if (range.unit === TouchpointSettingRangeTimeUnit.DAYS)
    absoluteStartSecondsFromTouchpoint = range.value * SECONDS_PER_DAY;

  return range.type === TouchpointSettingRangeTimeType.BEFORE_TOUCHPOINT
    ? -absoluteStartSecondsFromTouchpoint
    : absoluteStartSecondsFromTouchpoint;
};

export const patientHasOtherAppointmentRangeTime = (
  rangeStart?: TouchpointSettingRangeTime,
  rangeEnd?: TouchpointSettingRangeTime
) => {
  if (
    !rangeStart ||
    (rangeStart && (!rangeStart.type || rangeStart.value === undefined || !rangeStart.unit)) ||
    !rangeEnd ||
    (rangeEnd && (!rangeEnd.type || rangeStart.value === undefined || !rangeEnd.unit))
  )
    return "Please select time range.";

  const relativeStartTimeFromTouchpoint = getRelativeSecondsFromTouchpoint(rangeStart);
  const relativeEndTimeFromTouchpoint = getRelativeSecondsFromTouchpoint(rangeEnd);

  if (relativeStartTimeFromTouchpoint >= relativeEndTimeFromTouchpoint) {
    return "Time range start must be before time range end.";
  }

  return undefined;
};
