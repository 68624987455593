// http://chir.ag/projects/name-that-color/
// Use the above link to name colours

export const colors = {
  abbey: "#494b50",
  alto: "#d8d8d8",
  athensGray: "#f2f2f3",
  biscay: "#153b5c",
  blueChalk: "#eddbff",
  chathamsBlue: "#1a4b74",
  cornflowerLilac: "#ffb4ac",
  desertStorm: "#fafaf9",
  emerald: "#54c08a",
  $flesh: "#ffcda3",
  frangipani: "#ffdab5",
  frenchPass: "#c8e3fd",
  ghost: "#c7cad1",
  harp: "#deede8",
  hawaiianTan: "#8f4d16",
  iron: "#d7d8db",
  karry: "#ffebd3",
  korma: "#904c0e",
  lavender: "#e5e5ff",
  macaroniAndCheese: "#fdc084",
  malibu: "#7ec0ff",
  manatee: "#94979e",
  meteorite: "#50416c",
  mineralGreen: "#385a51",
  mirage: "#1d263c",
  patina: "#679186",
  purpleHeart: "#763ae5",
  reef: "#d8fdb1",
  roman: "#d9605e",
  royalBlue: "#4072de",
  sandyBeach: "#ffebcc",
  selago: "#f1f7fd",
  serenade: "#fff7eb",
  shark: "#1d2029",
  shuttleGray: "#5c6270",
  sidecar: "#f2ddba",
  sulu: "#aaef62",
  tamarillo: "#a31d18",
  white: "#ffffff"
};
