import React, { ChangeEvent, useEffect, useState } from "react";
import cx from "classnames";

import { InputPropsType } from "../../../../../types";

import styles from "./index.module.scss";

type PropsType = Omit<InputPropsType, "customOnChange"> & {
  id?: string;
  error?: string;
  size: "S" | "M" | "L";
  warning?: boolean;
  initialValue?: string;
  onChange?: (value?: string) => void;
};

const TextAreaInputBase = ({
  fieldName,
  id,
  label,
  warning = false,
  error,
  placeholder = "",
  size = "S",
  initialValue = "",
  disabled = false,
  onChange: onChangeProp,
  ...props
}: PropsType) => {
  const [value, setValue] = useState<string | undefined>(initialValue);
  const onChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const inputValue = event.target.value || "";
    setValue(inputValue);
    if (onChangeProp) {
      onChangeProp(inputValue);
    }
  };

  useEffect(() => {
    if (initialValue !== value) setValue(initialValue);
  }, [initialValue]);

  return (
    <textarea
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      id={id || label}
      name={fieldName}
      className={cx(styles.Input, {
        [styles.InputError]: error,
        [styles.InputWarning]: !error && warning,
        [styles.Small]: size === "S",
        [styles.Medium]: size === "M",
        [styles.Large]: size === "L"
      })}
      placeholder={placeholder}
      disabled={disabled}
      aria-label={label}
      aria-required="true"
      onChange={onChangeHandler}
      value={value}
    />
  );
};

export default TextAreaInputBase;
