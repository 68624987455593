import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import Text from "../../ui/Text";

import { OrganizationContext } from "../../providers/OrganizationProvider";
import { UserContext } from "../../providers/UserProvider";

import { daysUntilExpiry, isDemoAccountExpired } from "../../../utils/demoAccountExpiry";

import { UserTypeConstants } from "../../../constants";

import styles from "./index.module.scss";
import { ResponsiveHide } from "../../ui/Responsive";

const DemoDaysRemaining = () => {
  const organization = useContext(OrganizationContext);
  const user = useContext(UserContext);
  const isAdmin = user.userType === UserTypeConstants.MIKATA_ADMIN;
  const demoAccountExpiry = organization?.demoAccount?.expiresAt;

  if (!demoAccountExpiry) {
    return null;
  }

  const demoAccountExpired = isDemoAccountExpired(demoAccountExpiry);
  const remainingDays = daysUntilExpiry(demoAccountExpiry);
  const demoAccountMessage =
    remainingDays > 0 ? `Your trial ends in ${remainingDays} days` : "Your trial ends in 24 hours";

  return (
    <div className={styles.DemoAccount}>
      {!demoAccountExpired && (
        <>
          {!isAdmin ? (
            <>
              <Text size="S" align="right">
                {demoAccountMessage}
              </Text>
              <ResponsiveHide hideOnMobile>
                {!demoAccountExpired && (
                  <NavLink
                    to={{
                      pathname: "/settings/billing",
                      search: "?form=scribeSignUp"
                    }}
                    className={styles.DropdownListItem}
                  >
                    <Text id="upgradeNow" className={styles.BillingLink} size="S" component="span">
                      Upgrade Now
                    </Text>
                  </NavLink>
                )}
              </ResponsiveHide>
            </>
          ) : (
            <Text size="S">{demoAccountMessage}</Text>
          )}
        </>
      )}

      {isAdmin && demoAccountExpired && (
        <Text className={styles.TrialExpired} size="S">
          Trial Expired
        </Text>
      )}

      {!isAdmin && demoAccountExpired && (
        <>
          <Text className={styles.TrialExpired} size="S">
            Trial Expired
          </Text>
          <NavLink
            to={{
              pathname: "/settings/billing",
              search: "?form=scribeSignUp"
            }}
            className={styles.DropdownListItem}
          >
            <Text className={styles.BillingLink} size="S" component="span">
              Upgrade Now
            </Text>
          </NavLink>
        </>
      )}
    </div>
  );
};

export default DemoDaysRemaining;
