import React from "react";

type PropsType = {
  size?: number;
};

const Sadness = ({ size = 299 }: PropsType) => {
  return (
    <svg
      width={size}
      height={size * (353 / 383)}
      viewBox="0 0 383 353"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Layer_2" data-name="Layer 2">
        <circle cx="136.33" cy="217.5" r="136" fill="#c3ddff" />
        <path
          fill="#a4ccff"
          d="M136.33 353.5a135.74 135.74 0 0 0 105.6-50.3A136 136 0 0 1 41.74 119.8a136 136 0 0 0 94.59 233.7Z"
        />
        <path
          fill="#edf4ff"
          d="M297.87 200.67a6.09 6.09 0 0 1-1.87-.3 6 6 0 0 1-3.83-7.58l26-79.16a6 6 0 1 1 11.4 3.74l-26 79.17a6 6 0 0 1-5.7 4.13ZM269.14 189.57a5.92 5.92 0 0 1-1.88-.31 6 6 0 0 1-3.82-7.57l22.36-68.06a6 6 0 0 1 11.4 3.74l-22.36 68.07a6 6 0 0 1-5.7 4.13ZM242.52 179.28a6.15 6.15 0 0 1-1.88-.3 6 6 0 0 1-3.82-7.57l19-57.78a6 6 0 0 1 11.4 3.74l-19 57.78a6 6 0 0 1-5.7 4.13ZM214.12 168.31a6.09 6.09 0 0 1-1.87-.3 6 6 0 0 1-3.83-7.58l15.38-46.8a6 6 0 0 1 11.4 3.74l-15.38 46.81a6 6 0 0 1-5.7 4.13ZM187.5 158a5.8 5.8 0 0 1-1.87-.3 6 6 0 0 1-3.83-7.57l12-36.52a6 6 0 0 1 11.4 3.74l-12 36.53a6 6 0 0 1-5.7 4.12ZM160 147.4a5.82 5.82 0 0 1-1.87-.31 6 6 0 0 1-3.83-7.57l8.51-25.89a6 6 0 0 1 11.4 3.74l-8.51 25.9a6 6 0 0 1-5.7 4.13Z"
        />
        <path
          fill="#739ad6"
          d="M94.75 280.5a6 6 0 0 1-4.23-10.26c9.6-9.54 26.73-15.24 45.81-15.24s36.21 5.7 45.82 15.24a6 6 0 0 1-8.46 8.51c-7.19-7.14-21.85-11.75-37.36-11.75s-30.17 4.61-37.33 11.75a6 6 0 0 1-4.25 1.75ZM188.83 211.5a35.7 35.7 0 0 1-33.17-22.86 6 6 0 1 1 11.21-4.28 23.5 23.5 0 0 0 43.93 0 6 6 0 1 1 11.2 4.28 35.7 35.7 0 0 1-33.17 22.86ZM83.83 211.5a35.7 35.7 0 0 1-33.17-22.86 6 6 0 1 1 11.21-4.28 23.5 23.5 0 0 0 43.93 0 6 6 0 1 1 11.2 4.28 35.7 35.7 0 0 1-33.17 22.86Z"
        />
        <path
          fill="#a4ccff"
          d="M136.33 81.5a136.28 136.28 0 0 0-30.57 3.5 18.57 18.57 0 0 0 18.57 18h85.42a135.36 135.36 0 0 0-73.42-21.5Z"
        />
        <path
          fill="#edf4ff"
          d="M378 93.55H121.65a5.16 5.16 0 0 1-5.05-6.28c3.7-16.81 18.89-61.07 75.4-53.67 0 0 20.5-33.1 69.5-33.1 0 0 33-3 65 31 0 0 46.68.1 56.6 56a5.17 5.17 0 0 1-5.1 6.05Z"
        />
        <path
          d="M381.61 80.55H161.66a12.7 12.7 0 0 1-12-16.91c3.4-9.53 9.44-21.26 20-30.19-38.37 5.19-49.89 39.49-53.05 53.82a5.16 5.16 0 0 0 5.05 6.28H378a5.17 5.17 0 0 0 5.1-6 95.26 95.26 0 0 0-1.49-7Z"
          fill="#dbeaff"
        />
      </g>
    </svg>
  );
};

export default Sadness;
