import React, { useEffect, useMemo, useContext, useState } from "react";
import { connect } from "react-redux";
import uniqueBy from "lodash/uniqBy";

import { Form, FormGrid, SelectInput, CreatableSelectInput } from "../../../../../ui/Input";
import Button, { ButtonRow } from "../../../../../ui/Button";
import Loader from "../../../../../ui/Loader";
import Text from "../../../../../ui/Text";
import { OrganizationContext } from "../../../../../providers/OrganizationProvider";

import { getTelusStatuses, getAccuroStatuses } from "../../../../../../lib";
import { updateOrgSettings as updateOrgSettingsAction } from "../../../../../../actions";

import {
  SettingNames,
  AppointmentStates,
  AppointmentCheckedInStatuses,
  IntegrationCategories,
  EMRCategories
} from "../../../../../../constants";
import { ReduxStateType, Setting, Option, StatusMappingSetting } from "../../../../../../types";

type FormState = {
  [status: string]: string;
};

type PropsType = {
  integrationCategory: string;
  emrCategory?: string;
  loading?: boolean;
  settings?: Array<Setting>;
  isCheckinMapping?: boolean;
  updateOrgSettings: (organizationId: string, settings: Setting[]) => void;
};

const failedOptionsFetchMessage =
  "Failed to fetch status options for the EMR. Please ensure that the clinic is authenticated and try again";

const StatusMapping = ({
  loading,
  settings,
  integrationCategory,
  emrCategory,
  isCheckinMapping,
  updateOrgSettings
}: PropsType) => {
  const organization = useContext(OrganizationContext);
  const [validEmrStatusOptions, setValidEmrStatusOptions] = useState<Option[] | null>(null);
  const [errorMessage, setErrorMessage] = useState("");

  const statusConstants = isCheckinMapping ? AppointmentCheckedInStatuses : AppointmentStates;
  const settingName = isCheckinMapping
    ? SettingNames.INTEGRATED_CHECK_IN_STATUS_MAP
    : SettingNames.INTEGRATED_STATUS_MAP;

  useEffect(() => {
    (async () => {
      if (organization?.id) {
        if (integrationCategory === IntegrationCategories.TELUS) {
          const telusStatuses = await getTelusStatuses();
          setValidEmrStatusOptions(
            telusStatuses.map((status: string) => ({ label: status, value: status }))
          );
        }
        if (integrationCategory === IntegrationCategories.ACCURO) {
          try {
            const accuroStatuses = await getAccuroStatuses();
            if (accuroStatuses) {
              setErrorMessage("");
              return setValidEmrStatusOptions(
                accuroStatuses.map((status: { statusId: string; statusName: string }) => ({
                  label: status.statusName,
                  value: status.statusId
                }))
              );
            }
            setErrorMessage(failedOptionsFetchMessage);
          } catch (error) {
            setErrorMessage(failedOptionsFetchMessage);
          }
        }
      }
    })();
  }, [organization?.id]);

  const initialFormState: FormState | null = useMemo(() => {
    if (!settings) return null;

    const mappingSetting = settings.find((setting) => setting.settingName === settingName);

    return (mappingSetting?.settingValue || {}) as FormState;
  }, [organization?.id, settings]);

  const save = (formValues: FormState) => {
    if (organization) {
      updateOrgSettings(organization.id.toString(), [
        {
          settingName,
          settingValue: formValues as StatusMappingSetting,
          organizationId: organization.id
        }
      ]);
    }
  };

  const statusOptions = validEmrStatusOptions
    ? [{ label: "None", value: "" }, ...validEmrStatusOptions]
    : [];

  if (errorMessage) return <Text>{errorMessage}</Text>;

  if (!initialFormState || !validEmrStatusOptions) return <Loader />;

  const isCustomizable = emrCategory === EMRCategories.MEDACCESS;

  return (
    <Form
      initialValues={initialFormState}
      onSubmit={(formState) => save(formState.values as FormState)}
    >
      <FormGrid>
        {Object.values(statusConstants).map((mikataStatus) => {
          return isCustomizable ? (
            <CreatableSelectInput
              key={mikataStatus.name}
              fieldName={mikataStatus.name}
              label={mikataStatus.label}
              options={uniqueBy(
                [
                  ...statusOptions,
                  {
                    label: initialFormState[mikataStatus.name],
                    value: initialFormState[mikataStatus.name]
                  }
                ],
                "value"
              ).filter((option) => Boolean(option.value))}
            />
          ) : (
            <SelectInput
              key={mikataStatus.name}
              fieldName={mikataStatus.name}
              label={mikataStatus.label}
              options={statusOptions}
            />
          );
        })}
      </FormGrid>
      <ButtonRow>
        <Button
          type="submit"
          disabled={loading}
          id={`statusMappingSave${isCheckinMapping ? "Checkin" : ""}`}
        >
          Save
        </Button>
      </ButtonRow>
    </Form>
  );
};

const mapStateToProps = ({ organizationDetails }: ReduxStateType) => {
  return {
    settings: organizationDetails.settings,
    loading: organizationDetails.settingsLoading,
    emrCategory: organizationDetails?.data?.emrType?.category
  };
};

export default connect(mapStateToProps, { updateOrgSettings: updateOrgSettingsAction })(
  StatusMapping
);
