import React from "react";

import Heading from "../../../Heading";
import Text from "../../../Text";
import Image from "../../../Image";

const CreateUnintegratedAppointment = (): React.ReactElement => {
  return (
    <div>
      <Heading size="S" darkMode>
        Add a new appointment
      </Heading>
      <Text size="S" darkMode>
        You can easily add and remove appointments from your list.
      </Text>
      <br />
      <Image src="/img/announcements/addNewAppointment.png" />
    </div>
  );
};

export default CreateUnintegratedAppointment;
