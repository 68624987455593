import { SetURLSearchParams, useLocation } from "react-router-dom";
import qs from "query-string";
import { isEqual } from "lodash";

type ModalType = "addMessageTemplate";

type QueryStringState = {
  messageTemplateId?: string;
  startDay?: string;
  endDay?: string;
  chatId?: string;
  page?: string;
  pageRows?: string;
  sortBy?: string;
  sortByDirection?: string;
  activeStatuses?: string;
  activeCheckinStatuses?: string;
  locationIds?: string;
  locations?: string;
  practitionerIds?: string;
  reasonIds?: string;
  reasons?: string;
  instructionId?: string;
  automationId?: string;
  appointmentId?: string;
  modal?: ModalType;
  activeFilter?: string;
  appointmentRequestId?: string;
  searchValue?: string;
  code?: string;
  form?: "scribeSignUp";
  noteTemplateId?: string;
  promptStart?: "y" | "n";
  requestBookingMode?: string;
  taskType?: string;

  // for Telus Launch
  EmrType?: string;
  InstanceId?: string;
  ContextualLaunchToken?: string;

  mode?: string;
};

export const useQueryString = (): { parsed: QueryStringState } => {
  const location = useLocation();
  const parsed = qs.parse(location.search);
  return { parsed };
};

export const getQueryString = (): QueryStringState => {
  const parsed = qs.parse(window.location.search);
  return parsed;
};

export const updateQueryString = (
  update: QueryStringState,
  setSearchParams: SetURLSearchParams
): void => {
  const parsed = getQueryString();

  const newQueryState: QueryStringState = {
    ...parsed
  };

  (Object.keys(update) as Array<keyof QueryStringState>).forEach((key) => {
    if (update[key] === undefined) {
      delete newQueryState[key];
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      newQueryState[key] = update[key] as any;
    }
  });
  const isUpdatedQueryStateSame = isEqual(parsed, newQueryState);
  if (!isUpdatedQueryStateSame) {
    setSearchParams({ ...newQueryState });
  }
};
