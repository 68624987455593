import React from "react";

import styles from "./index.module.scss";

type PropsType = {
  children: React.ReactNode;
};

const Scrollable = ({ children }: PropsType) => {
  return (
    <div className={styles.ScrollableContainer}>
      <div className={styles.ScrollableContent}>{children}</div>
    </div>
  );
};

export default Scrollable;
