/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from "react";
import { connect } from "react-redux";

import cx from "classnames";

import Button from "../Button";
import Card from "../Card";
import { CheckboxInput } from "../Input";
import { FloatModal } from "../Modal";
import { ResponsiveHide } from "../Responsive";

import FeedbackType from "./FeedbackType";
import ReportIssue from "./ReportIssue";
import Suggestion from "./Suggestion";
import ThankYou from "./ThankYou";
import ScribeFeedback from "./ScribeFeedback";
import ScribeTemplateRequest from "./ScribeTemplateRequest";

import { setFeedbackMode } from "../../../actions";

import { FeedbackFormModes } from "../../../typedConstants";
import { ReduxStateType } from "../../../types";

import styles from "./index.module.scss";
import { LayoutContext, LayoutViewMode } from "../../providers/LayoutProvider";

type PropsType = {
  children?: React.ReactNode;
  position?: "bottomLeft" | "bottomRight" | "floatCenter";
  feedbackMode: FeedbackFormModes | null;
  context: {
    appointmentId: string | undefined;
    noteIds: number[] | null;
    activeNoteId: number | null;
  };
  feedbackModalOpen: boolean;
  setFeedbackModalOpen: (value: boolean) => void;
};

type SubmitButtonPropsType = {
  fullWidth?: boolean;
  disabled: boolean;
};

export const FeedbackSubmitButton = ({
  disabled,
  fullWidth = false
}: SubmitButtonPropsType): JSX.Element => (
  <div className={cx(styles.FeedbackSubmitButton, { [styles.FullWidth]: fullWidth })}>
    <Button type="submit" disabled={disabled} className={cx({ [styles.FullWidth]: fullWidth })}>
      Submit
    </Button>
  </div>
);

export const UserConsentCheckbox = () => (
  <div>
    <CheckboxInput
      fieldName="consent"
      label={
        <span>
          I grant permission to Mikata team members to access all data associated with this
          appointment to better understand and address my feedback.
        </span>
      }
    />
  </div>
);

const FeedbackManager = ({
  feedbackMode,
  children,
  position = "bottomRight",
  context,
  feedbackModalOpen,
  setFeedbackModalOpen
}: PropsType): JSX.Element => {
  const { viewMode } = useContext(LayoutContext);

  const currentMode = (mode: FeedbackFormModes | null, context: any) => {
    switch (mode) {
      case FeedbackFormModes.SELECTION:
        return <FeedbackType />;
      case FeedbackFormModes.REPORT_ISSUE:
        return <ReportIssue context={context} />;
      case FeedbackFormModes.SUGGESTION:
        return <Suggestion context={context} />;
      case FeedbackFormModes.SCRIBE_FEEDBACK:
        return <ScribeFeedback context={context} />;
      case FeedbackFormModes.SCRIBE_TEMPLATE_REQUEST:
        return <ScribeTemplateRequest context={context} />;
      case FeedbackFormModes.SUBMITTED:
        return (
          <ThankYou
            closeModal={() => {
              setFeedbackModalOpen(false);
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <ResponsiveHide hideOnMobile>
        {feedbackMode && (
          <Card
            className={cx(styles.FeedbackCard, {
              [styles.FeedbackCardBottomLeft]: position === "bottomLeft",
              [styles.FeedbackCardBottomRight]: position === "bottomRight",
              [styles.FeedbackCardFloatCenter]: position === "floatCenter"
            })}
          >
            {currentMode(feedbackMode, context)}
          </Card>
        )}
      </ResponsiveHide>
      <FloatModal
        isOpen={viewMode === LayoutViewMode.MOBILE && feedbackModalOpen}
        onClose={() => {
          setFeedbackModalOpen(false);
        }}
      >
        {currentMode(feedbackMode, context)}
      </FloatModal>
      {children}
    </>
  );
};

const mapStateToProps = ({ feedback }: ReduxStateType) => {
  return {
    feedbackMode: feedback.feedbackMode
  };
};

export default connect(mapStateToProps, { setFeedbackMode })(FeedbackManager);
