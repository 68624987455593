import React, { ChangeEvent } from "react";
import { useField } from "informed";
import cx from "classnames";

import BaseInput from "../BaseInput";

import styles from "./index.module.scss";
import { InputPropsType } from "../../../../types";

type PropsType = InputPropsType & {
  maxCharacters?: number;
  size?: "S" | "M" | "L";
  initialValue?: string;
  warning?: boolean;
};

const JsonInput = ({
  fieldName,
  label,
  info,
  infoStatus,
  placeholder,
  disabled = false,
  validate,
  showField = true,
  size = "S",
  initialValue,
  warning = false,
  warningMessage = "",
  customOnChange,
  ...props
}: PropsType) => {
  const { fieldState, fieldApi } = useField({
    ...props,
    name: fieldName,
    validate,
    validateOn: "blur",
    initialValue
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const value = fieldState.value as any;
  const error = fieldState.error as string | undefined;
  const { setValue, setTouched, validate: validateInput } = fieldApi;

  const onChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const inputValue = event.target.value || "";
    setValue(inputValue);

    if (customOnChange) {
      customOnChange({ [fieldName]: inputValue });
    }
  };

  const onBlurHandler = () => {
    setTouched(true);
    validateInput();
  };

  const stringValue = typeof value === "string" ? value : JSON.stringify(value, undefined, 2);

  return (
    <BaseInput
      error={error}
      fieldName={fieldName}
      label={label}
      showField={showField}
      info={info}
      infoStatus={infoStatus}
      warningMessage={warningMessage}
    >
      <textarea
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        id={label}
        name={fieldName}
        className={cx(styles.Input, {
          [styles.InputError]: error,
          [styles.InputWarning]: !error && warning,
          [styles.Small]: size === "S",
          [styles.Medium]: size === "M",
          [styles.Large]: size === "L"
        })}
        placeholder={placeholder}
        disabled={disabled}
        aria-label={label}
        aria-required="true"
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        value={stringValue}
      />
    </BaseInput>
  );
};

export default JsonInput;
