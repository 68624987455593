import { ContentBlock } from "draft-js";

export const customBlockRenderer = (contentBlock: ContentBlock) => {
  const type = contentBlock.getType();

  if (type === "MIKA_ANCHOR") {
    return {
      editable: false
    };
  }
};
