import React from "react";

type PropsType = {
  size?: number;
  color?: string;
};

const OpenMail = ({ size = 24, color = "currentColor" }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.331 2.94714C11.7113 2.60486 12.2887 2.60486 12.669 2.94713L12.7274 2.99969H11.2726L11.331 2.94714ZM8.28294 2.99969L9.9931 1.46055C11.134 0.433725 12.866 0.433723 14.0069 1.46055L15.7171 2.99969H17C18.6569 2.99969 20 4.34284 20 5.99969V6.85434L22.0069 8.66055C22.639 9.22947 23 10.04 23 10.8904V10.9997V19.9997C23 21.6566 21.6569 22.9997 20 22.9997H4C2.34315 22.9997 1 21.6566 1 19.9997V10.9997V10.8904C1 10.04 1.36096 9.22947 1.99311 8.66055L4 6.85434V5.99969C4 4.34284 5.34315 2.99969 7 2.99969H8.28294ZM4 9.54507L3.33104 10.1471C3.21378 10.2527 3.12452 10.3831 3.0684 10.5269L4 11.179V9.54507ZM6 12.579L11.4265 16.3776C11.7709 16.6186 12.2291 16.6186 12.5735 16.3776L18 12.579V5.99969C18 5.44741 17.5523 4.99969 17 4.99969H7C6.44772 4.99969 6 5.44741 6 5.99969V12.579ZM20 11.179L20.9316 10.5269C20.8755 10.3831 20.7862 10.2527 20.669 10.1471L20 9.54507V11.179ZM3 12.9204V19.9997C3 20.552 3.44772 20.9997 4 20.9997H20C20.5523 20.9997 21 20.552 21 19.9997V12.9204L13.7204 18.0161C12.6874 18.7392 11.3126 18.7392 10.2796 18.0161L3 12.9204ZM8 6.99969C7.44772 6.99969 7 7.44741 7 7.99969C7 8.55198 7.44772 8.99969 8 8.99969H12C12.5523 8.99969 13 8.55198 13 7.99969C13 7.44741 12.5523 6.99969 12 6.99969H8ZM7 10.9997C7 10.4474 7.44772 9.99969 8 9.99969H16C16.5523 9.99969 17 10.4474 17 10.9997C17 11.552 16.5523 11.9997 16 11.9997H8C7.44772 11.9997 7 11.552 7 10.9997Z"
        fill={color}
      />
    </svg>
  );
};

export default OpenMail;
