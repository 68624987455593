import { MessageTopics } from "../../../../../constants";
import getTopicLabel from "../../../../../utils/getTopicLabel";
import getTopicMediums from "../../../../../utils/getTopicMediums";

// Define the Message Template cards to show

export const automationDeprecatedMessageOptions = [
  // Booked Messages
  {
    label: getTopicLabel(MessageTopics.BOOKED),
    topic: MessageTopics.BOOKED,
    mediums: getTopicMediums(MessageTopics.BOOKED)
  },
  {
    label: getTopicLabel(MessageTopics.MULTI_BOOKED),
    topic: MessageTopics.MULTI_BOOKED,
    mediums: getTopicMediums(MessageTopics.MULTI_BOOKED)
  },
  // 7-day Reminders Messages
  {
    label: getTopicLabel(MessageTopics.SEVEN_DAY_SINGLE_REMINDER),
    topic: MessageTopics.SEVEN_DAY_SINGLE_REMINDER,
    mediums: getTopicMediums(MessageTopics.SEVEN_DAY_SINGLE_REMINDER)
  },
  {
    label: getTopicLabel(MessageTopics.SEVEN_DAY_MULTI_REMINDER),
    topic: MessageTopics.SEVEN_DAY_MULTI_REMINDER,
    mediums: getTopicMediums(MessageTopics.SEVEN_DAY_MULTI_REMINDER)
  },
  // 2-day Reminders Messages
  {
    label: getTopicLabel(MessageTopics.TWO_DAY_SINGLE_REMINDER),
    topic: MessageTopics.TWO_DAY_SINGLE_REMINDER,
    mediums: getTopicMediums(MessageTopics.TWO_DAY_SINGLE_REMINDER)
  },
  {
    label: getTopicLabel(MessageTopics.TWO_DAY_MULTI_REMINDER),
    topic: MessageTopics.TWO_DAY_MULTI_REMINDER,
    mediums: getTopicMediums(MessageTopics.TWO_DAY_MULTI_REMINDER)
  },
  {
    label: getTopicLabel(MessageTopics.TWO_DAY_SINGLE_REMINDER_NO_SEVEN),
    topic: MessageTopics.TWO_DAY_SINGLE_REMINDER_NO_SEVEN,
    mediums: getTopicMediums(MessageTopics.TWO_DAY_SINGLE_REMINDER_NO_SEVEN)
  },
  {
    label: getTopicLabel(MessageTopics.TWO_DAY_MULTI_REMINDER_NO_SEVEN),
    topic: MessageTopics.TWO_DAY_MULTI_REMINDER_NO_SEVEN,
    mediums: getTopicMediums(MessageTopics.TWO_DAY_MULTI_REMINDER_NO_SEVEN)
  },
  // Checkin Messages
  {
    label: getTopicLabel(MessageTopics.CHECK_IN_NOTICE),
    topic: MessageTopics.CHECK_IN_NOTICE,
    mediums: getTopicMediums(MessageTopics.CHECK_IN_NOTICE)
  },
  // Post Visit Link
  {
    label: getTopicLabel(MessageTopics.POST_VISIT_LINK),
    topic: MessageTopics.POST_VISIT_LINK,
    mediums: getTopicMediums(MessageTopics.POST_VISIT_LINK)
  },
  // Cancelled Messages
  {
    label: getTopicLabel(MessageTopics.CANCELLED),
    topic: MessageTopics.CANCELLED,
    mediums: getTopicMediums(MessageTopics.CANCELLED)
  },
  {
    label: getTopicLabel(MessageTopics.MULTI_CANCELLED),
    topic: MessageTopics.MULTI_CANCELLED,
    mediums: getTopicMediums(MessageTopics.MULTI_CANCELLED)
  },
  {
    // Deprecated topic
    label: getTopicLabel(MessageTopics.TWO_DAY_PREVISIT_LINK),
    topic: MessageTopics.TWO_DAY_PREVISIT_LINK,
    mediums: getTopicMediums(MessageTopics.TWO_DAY_PREVISIT_LINK)
  }
];

export const automationResponseMessageOptions = [
  // Confirm, Reschedule, Checkin Responses
  {
    label: getTopicLabel(MessageTopics.REMINDER_CONFIRM),
    topic: MessageTopics.REMINDER_CONFIRM,
    mediums: getTopicMediums(MessageTopics.REMINDER_CONFIRM)
  },
  {
    label: getTopicLabel(MessageTopics.REMINDER_RESPONSE_RESCHEDULE),
    topic: MessageTopics.REMINDER_RESPONSE_RESCHEDULE,
    mediums: getTopicMediums(MessageTopics.REMINDER_RESPONSE_RESCHEDULE)
  },
  {
    label: getTopicLabel(MessageTopics.USER_CHECK_IN_RESPONSE),
    topic: MessageTopics.USER_CHECK_IN_RESPONSE,
    mediums: getTopicMediums(MessageTopics.USER_CHECK_IN_RESPONSE)
  },
  // Checkin Notify user
  {
    label: getTopicLabel(MessageTopics.CHECK_IN_NOTIFY_USER),
    topic: MessageTopics.CHECK_IN_NOTIFY_USER,
    mediums: getTopicMediums(MessageTopics.CHECK_IN_NOTIFY_USER)
  },
  // General Response Messages
  {
    label: getTopicLabel(MessageTopics.BOOKING_RESPONSE),
    topic: MessageTopics.BOOKING_RESPONSE,
    mediums: getTopicMediums(MessageTopics.BOOKING_RESPONSE)
  },
  {
    label: getTopicLabel(MessageTopics.BOOKING_NOT_ENABLED_RESPONSE),
    topic: MessageTopics.BOOKING_NOT_ENABLED_RESPONSE,
    mediums: getTopicMediums(MessageTopics.BOOKING_NOT_ENABLED_RESPONSE)
  },
  {
    label: getTopicLabel(MessageTopics.BOOKING_NOT_ENABLED_FEEDBACK),
    topic: MessageTopics.BOOKING_NOT_ENABLED_FEEDBACK,
    mediums: getTopicMediums(MessageTopics.BOOKING_NOT_ENABLED_FEEDBACK)
  },

  {
    label: getTopicLabel(MessageTopics.CHECK_IN_NO_APPOINTMENTS),
    topic: MessageTopics.CHECK_IN_NO_APPOINTMENTS,
    mediums: getTopicMediums(MessageTopics.CHECK_IN_NO_APPOINTMENTS)
  },
  {
    label: getTopicLabel(MessageTopics.CHECK_IN_UNCLEAR),
    topic: MessageTopics.CHECK_IN_UNCLEAR,
    mediums: getTopicMediums(MessageTopics.CHECK_IN_UNCLEAR)
  },
  {
    label: getTopicLabel(MessageTopics.REMINDER_RESPONSE_UNCLEAR),
    topic: MessageTopics.REMINDER_RESPONSE_UNCLEAR,
    mediums: getTopicMediums(MessageTopics.REMINDER_RESPONSE_UNCLEAR)
  },
  {
    label: getTopicLabel(MessageTopics.REMINDER_RESPONSE_NO_APPT),
    topic: MessageTopics.REMINDER_RESPONSE_NO_APPT,
    mediums: getTopicMediums(MessageTopics.REMINDER_RESPONSE_NO_APPT)
  },
  {
    label: getTopicLabel(MessageTopics.USER_RESTART_REMINDERS),
    topic: MessageTopics.USER_RESTART_REMINDERS,
    mediums: getTopicMediums(MessageTopics.USER_RESTART_REMINDERS)
  },
  {
    label: getTopicLabel(MessageTopics.USER_SURVEY_OPT_OUT),
    topic: MessageTopics.USER_SURVEY_OPT_OUT,
    mediums: getTopicMediums(MessageTopics.USER_SURVEY_OPT_OUT)
  },
  {
    label: getTopicLabel(MessageTopics.CAMPAIGN_RESPONSE_UNCLEAR),
    topic: MessageTopics.CAMPAIGN_RESPONSE_UNCLEAR,
    mediums: getTopicMediums(MessageTopics.CAMPAIGN_RESPONSE_UNCLEAR)
  }
];
