import React from "react";

type PropsType = {
  size?: number;
};

const List = ({ size = 24 }: PropsType) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={`${size}px`}
      height={`${size}px`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm3-1a1 1 0 000 2h13a1 1 0 100-2H8zm0 5a1 1 0 000 2h13a1 1 0 100-2H8zm0 5a1 1 0 100 2h13a1 1 0 100-2H8zm-1 6a1 1 0 011-1h13a1 1 0 110 2H8a1 1 0 01-1-1zM3.5 11a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM5 14.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM3.5 21a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
        fill="#1A4B74"
      />
    </svg>
  );
};

export default List;
