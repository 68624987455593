import React from "react";
import cx from "classnames";

import styles from "./index.module.scss";

import { CircleCheck, CircleExclamation } from "../../../../../ui/Icon";
import Heading from "../../../../../ui/Heading";
import Text from "../../../../../ui/Text";
import Tooltip from "../../../../../ui/Tooltip";

import { Patient } from "../../../../../../types";

import formatPhone from "../../../../../../utils/formatPhone";

type PropsType = {
  type: "selection" | "selected";
  patient: Patient;
  setMatchedPatient: (patient: Patient | null) => void;
};

const PatientCard = ({ type, patient, setMatchedPatient }: PropsType) => {
  const isSelection = type === "selection";
  const warning = type === "selected" && !patient.isVerifiedMobile;
  return (
    <button
      type="button"
      className={cx(styles.PatientDetails, {
        [styles.PatientDetailsSelection]: isSelection,
        [styles.PatientDetailsWarning]: warning
      })}
      onClick={isSelection ? () => setMatchedPatient(patient) : undefined}
      key={`patient-${patient.emrPatientId}`}
    >
      <div className={styles.InfoGroup}>
        <Heading size="META" component="h5">
          Patient Name
        </Heading>
        <Text>{`${patient.firstName} ${patient.lastName}`}</Text>
      </div>
      <div className={styles.InfoGroup}>
        <Heading size="META" component="h5">
          Phone Number
        </Heading>
        <div className={styles.InfoValueRow}>
          <Text>
            {patient.phoneNumberToDisplay ? formatPhone(patient.phoneNumberToDisplay) || "-" : null}
          </Text>
          <Tooltip
            icon={
              <div
                className={cx(styles.InfoValueIcon, {
                  [styles.InfoValueIconCheck]: patient.isVerifiedMobile,
                  [styles.InfoValueIconExclamation]: !patient.isVerifiedMobile
                })}
              >
                {patient.isVerifiedMobile ? (
                  <CircleCheck size={18} />
                ) : (
                  <CircleExclamation size={18} />
                )}
              </div>
            }
            size="S"
          >
            <Text size="S">
              {patient.isVerifiedMobile ? "Verified mobile number" : "Unverified mobile number"}
            </Text>
          </Tooltip>
        </div>
      </div>
      <div className={styles.InfoGroup}>
        <Heading size="META" component="h5">
          Primary Provider(s)
        </Heading>
        {patient.primaryProviders?.length ? (
          patient.primaryProviders.map((provider) => {
            return <Text key={provider.id}>{provider.practitionerName}</Text>;
          })
        ) : (
          <Text>-</Text>
        )}
      </div>
      <div className={styles.InfoGroup}>
        <Heading size="META" component="h5">
          Personal Health Number
        </Heading>
        <Text>{patient.personalHealthNumber}</Text>
      </div>
      <div className={styles.InfoGroup}>
        <Heading size="META" component="h5">
          Email Address
        </Heading>
        <Text className={styles.Email}>{patient.patientEmail}</Text>
      </div>
      {!isSelection && (
        <div className={styles.UnmatchPatient} onClick={() => setMatchedPatient(null)}>
          <Text size="XS" className={styles.UnmatchPatientText}>
            Not the correct patient?
          </Text>
        </div>
      )}
    </button>
  );
};

export default PatientCard;
