import axios from "axios";

import { Dispatch, Campaign, ActionStatus } from "../types";

import getToken from "../utils/getToken";
import {
  FETCH_CAMPAIGNS,
  FETCH_CAMPAIGN,
  ROOT_URL,
  CREATE_CAMPAIGN,
  UPDATE_CAMPAIGN
} from "../constants";
import { addNotification } from "./notifications";

export type FetchCampaignsAction = {
  type: typeof FETCH_CAMPAIGNS;
  status: ActionStatus;
  payload?: {
    data: Array<Campaign>;
  };
};

export type CampaignsFetchOptions = {
  silent?: boolean;
};

export const fetchCampaigns = (options?: CampaignsFetchOptions) => async (dispatch: Dispatch) => {
  const config = {
    headers: { Authorization: `${getToken()}`, "Content-Type": "application/json" }
  };

  const isSilentFetch = options?.silent;

  dispatch({
    type: FETCH_CAMPAIGNS,
    status: isSilentFetch ? ActionStatus.silentLoading : ActionStatus.loading
  });

  try {
    const payload = await axios.get(`${ROOT_URL}/campaigns/find`, config);
    return dispatch({
      type: FETCH_CAMPAIGNS,
      status: ActionStatus.success,
      payload
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return dispatch({
      type: FETCH_CAMPAIGNS,
      status: ActionStatus.error
    });
  }
};

export type FetchCampaignAction = {
  type: typeof FETCH_CAMPAIGN;
  status: ActionStatus;
  payload?: {
    data: Campaign;
  };
};

export const fetchCampaign = async (campaignId: number) => async (dispatch: Dispatch) => {
  const config = {
    headers: { Authorization: `${getToken()}`, "Content-Type": "application/json" }
  };

  dispatch({
    type: FETCH_CAMPAIGNS,
    status: ActionStatus.loading
  });

  try {
    const payload = await axios.get(`${ROOT_URL}/campaigns/${campaignId}`, config);
    return dispatch({
      type: FETCH_CAMPAIGN,
      status: ActionStatus.success,
      payload
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return dispatch({
      type: FETCH_CAMPAIGN,
      status: ActionStatus.error
    });
  }
};

const buildErrorMessage = (errorMessages = []) => {
  const messagesToDisplay = errorMessages.splice(0, 2);
  const messagesNotIncluded = errorMessages.splice(3);

  return `${messagesToDisplay.join(", ")}. ${
    messagesNotIncluded && messagesNotIncluded.length > 0
      ? `...${messagesNotIncluded.length} more errors`
      : ""
  }`;
};

export type CreateCampaignData = {
  fileName: string;
  messageTemplateId: number | null;
  name: string;
  organizationId: number;
  sendRate: number;
  startDate: string | null;
  uploadId: number | null;
};

export type CreateCampaignAction = {
  type: typeof CREATE_CAMPAIGN;
  status: ActionStatus;
};

export const createCampaign =
  async (data: CreateCampaignData, onSuccess?: () => void) => (dispatch: Dispatch) => {
    const config = {
      headers: { Authorization: `${getToken()}`, "Content-Type": "application/json" }
    };
    dispatch({
      type: CREATE_CAMPAIGN,
      status: ActionStatus.loading
    });
    return axios
      .post(`${ROOT_URL}/campaigns`, data, config)
      .then((payload) => {
        if (!payload.data.success) {
          dispatch({ type: CREATE_CAMPAIGN, status: ActionStatus.error });
          dispatch(
            addNotification({
              type: "error",
              title: "Failed to create the campaign",
              subtitle: buildErrorMessage(payload.data.errorMessages),
              autoDismiss: false
            })
          );
        } else {
          dispatch({ type: CREATE_CAMPAIGN, status: ActionStatus.success, payload });
          fetchCampaigns({ silent: false })(dispatch).then(() => {
            dispatch(
              addNotification({
                type: "success",
                title: "Success",
                subtitle: `Successfully created campaign`,
                autoDismiss: true
              })
            );
          });
          if (onSuccess) {
            onSuccess();
          }
        }
      })
      .catch(() => {
        dispatch({ type: CREATE_CAMPAIGN, status: ActionStatus.error });
        dispatch(
          addNotification({
            type: "error",
            title: "Unfortunately could not create the campaign",
            subtitle: "Please reload the page and try again",
            autoDismiss: true
          })
        );
      });
  };

export type UpdateCampaignData = {
  fileName: string;
  messageTemplateId: number | null;
  name: string;
  organizationId: number;
  sendRate: number;
  startDate: string | null;
  uploadId: number | null;
};

export const updateCampaign =
  async (id: number, data: UpdateCampaignData, onSuccess?: () => void) => (dispatch: Dispatch) => {
    const config = {
      headers: { Authorization: `${getToken()}`, "Content-Type": "application/json" }
    };

    return axios
      .patch(`${ROOT_URL}/campaigns/${id}`, data, config)
      .then((payload) => {
        dispatch({ type: UPDATE_CAMPAIGN, status: ActionStatus.success, payload });
        fetchCampaigns({ silent: false })(dispatch).then(() => {
          dispatch(
            addNotification({
              type: "success",
              title: "Success",
              subtitle: `Successfully started campaign`,
              autoDismiss: true
            })
          );
        });
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch(
          addNotification({
            type: "error",
            title: "Unfortunately could not start the campaign",
            subtitle: "Please reload the page and try again",
            autoDismiss: true
          })
        );
      });
  };
