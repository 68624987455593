import React from "react";

type PropsType = {
  size?: number;
};

const Clinic = ({ size = 28 }: PropsType) => {
  return (
    <svg
      width={`${size * (28 / size)}px`}
      height={`${size * (28 / size)}px`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
    >
      <circle cx="14" cy="14" r="14" fill="currentColor" />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M9.333 8.667a2 2 0 0 1 2-2h5.334a2 2 0 0 1 2 2v4h2c.736 0 1.333.597 1.333 1.333v6c0 .736-.597 1.333-1.333 1.333H7.333A1.333 1.333 0 0 1 6 20v-6c0-.736.597-1.333 1.333-1.333h2v-4Zm4 9.333v2h1.334v-2h-1.334ZM16 20h1.333V8.667A.667.667 0 0 0 16.667 8h-5.334a.667.667 0 0 0-.666.667V20H12v-2c0-.736.597-1.333 1.333-1.333h1.334c.736 0 1.333.597 1.333 1.333v2Zm2.667 0v-6h2v6h-2Zm-9.334-6v6h-2v-6h2ZM12 11.333c0-.368.298-.666.667-.666h.666V10a.667.667 0 1 1 1.334 0v.667h.666a.667.667 0 0 1 0 1.333h-.666v.667a.667.667 0 0 1-1.334 0V12h-.666a.667.667 0 0 1-.667-.667Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default Clinic;
