import { InputValue } from "../../types";

/**
 * @method isJson
 * * This method validates if an input is a valid JSON string
 * * Following are considered Valid JSON inputs by this validator
 * *  - empty field, empty string, empty json
 * @param customErrorMessage
 *
 */
export const isJson = (customErrorMessage?: string) => (value: InputValue) => {
  const errorMessage = customErrorMessage || "Invalid JSON string";

  if (!value) return undefined;
  if (!Number.isNaN(Number(value))) return errorMessage;

  try {
    if (typeof value === "string") {
      JSON.parse(value);
    }
  } catch (e) {
    return errorMessage;
  }
};
