import React, { useEffect, useState } from "react";
import cx from "classnames";

import { ChevronDown, ChevronUp } from "../Icon";

import styles from "./index.module.scss";

type PropsType = {
  children: React.ReactNode;
  headerContent?: React.ReactNode;
  collapse?: boolean;
  initialOpen: boolean;
  togglePosition?: "left" | "right";
  toggleLine?: boolean;
  size?: number;
  extraMargin?: boolean;
  headerClickable?: boolean;
};

const Drawer = ({
  children,
  headerContent,
  collapse = false,
  initialOpen = true,
  togglePosition = "left",
  toggleLine = true,
  size = 24,
  extraMargin = true,
  headerClickable = false
}: PropsType) => {
  const [isOpen, setIsOpen] = useState(initialOpen);

  const toggle = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  useEffect(() => {
    if (collapse === false) setIsOpen(true);
    if (collapse === true) setIsOpen(false);
  }, [collapse]);

  return (
    <div
      className={cx(styles.Drawer, {
        [styles.DrawerOpen]: isOpen && toggleLine,
        [styles.DrawerLeft]: togglePosition === "left",
        [styles.DrawerRight]: togglePosition === "right",
        [styles.DrawerLeftOpen]: isOpen && toggleLine && togglePosition === "left",
        [styles.DrawerRightOpen]: isOpen && toggleLine && togglePosition === "right",
        [styles.DrawerLeftExtraMargin]: extraMargin && togglePosition === "left",
        [styles.DrawerRightExtraMargin]: extraMargin && togglePosition === "right"
      })}
    >
      {children && togglePosition === "left" && (
        <button
          type="button"
          className={cx(styles.ToggleButtonChevron, styles.ToggleButtonChevronLeft)}
          onClick={toggle}
        >
          {isOpen ? <ChevronUp size={size} /> : <ChevronDown size={size} />}
        </button>
      )}
      {headerContent && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          className={cx({ [styles.HeaderToggleLine]: toggleLine })}
          onClick={headerClickable ? toggle : undefined}
        >
          {headerContent}
        </div>
      )}
      {children && togglePosition === "right" && (
        <button
          type="button"
          className={cx(styles.ToggleButtonChevron, styles.ToggleButtonChevronRight)}
          onClick={toggle}
        >
          {isOpen ? <ChevronUp size={size} /> : <ChevronDown size={size} />}
        </button>
      )}
      <div className={cx(styles.Content, { [styles.ContentOpen]: isOpen })}>{children}</div>
    </div>
  );
};

export default Drawer;
