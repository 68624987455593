import React from "react";
import { useFormApi, useFormState } from "informed";
import cx from "classnames";

import Button from "../../../../ui/Button";
import Heading from "../../../../ui/Heading";
import Text from "../../../../ui/Text";
import { PhoneGraphic, Trash, Lock } from "../../../../ui/Icon";
import CardBanner from "../../../../ui/CardBanner";
import { FormGrid, TextAreaInput, SelectInput, BaseInput, UploadInput } from "../../../../ui/Input";

import { MessageTemplate, Option } from "../../../../../types";
import { SecureDirectMessageFormData } from ".";

import styles from "./index.module.scss";

type PropsType = {
  messageTemplates?: Array<MessageTemplate>;
  closeModal?: () => void;
  messageTemplateOptions: Option[];
  chatOptions: Option[];
  errorMessage: string;
  formDisabled?: boolean;
  dpmContentSuggestion?: string;
  updateError: () => void;
  canSendSecureMessage: boolean;
  contentUploadList: string[];
  onSend: (values: SecureDirectMessageFormData) => void;
  setContentUploadList: (fileNames: string[]) => void;
  patientDisplayName: string;
};

const FormContent = ({
  closeModal,
  messageTemplates,
  formDisabled,
  messageTemplateOptions,
  patientDisplayName,
  contentUploadList,
  dpmContentSuggestion,
  chatOptions,
  canSendSecureMessage,
  errorMessage,
  updateError,
  onSend,
  setContentUploadList
}: PropsType) => {
  const formState = useFormState();
  const formApi = useFormApi();
  const values = formState.values as SecureDirectMessageFormData;
  return (
    <div>
      {canSendSecureMessage ? (
        <>
          <CardBanner className={styles.CardBannerBasic} inputIcon={<Lock />}>
            Send a message that{" "}
            <span className={styles.FontBold}>may include personal health information</span> and
            attachments for your patients to view securely.
          </CardBanner>
          <FormGrid>
            <BaseInput fieldName="patientDisplayName" label="RECIPIENT">
              {patientDisplayName}
            </BaseInput>
            <SelectInput
              fieldName="messageTemplateId"
              label="TEMPLATE"
              options={messageTemplateOptions}
              placeholder="None"
              disabled={formDisabled}
              customOnChange={(event) => {
                const { messageTemplateId } = event;
                if (!messageTemplateId || messageTemplateId === "none") {
                  formApi.setValue("payloadContent", " ");
                  return;
                }

                const selectedTemplate = messageTemplates?.find(
                  (template) => template.id.toString() === messageTemplateId
                );
                const payloadContent = selectedTemplate?.payloadContent;
                if (payloadContent) {
                  formApi.setValue("payloadContent", payloadContent);
                }
              }}
            />
          </FormGrid>
          <TextAreaInput
            fieldName="payloadContent"
            placeholder="Type your message here"
            size="M"
            disabled={formDisabled}
            warningMessage={
              dpmContentSuggestion && values.payloadContent === dpmContentSuggestion
                ? "This message was suggested using generative AI software and may contain errors. You are responsible for ensuring accuracy before sending."
                : ""
            }
            customOnChange={() => {
              updateError();
            }}
          />
          <SelectInput
            fieldName="chatFlowIds"
            label="Chats (optional)"
            options={chatOptions}
            placeholder="None"
            isMulti
            disabled={formDisabled}
          />
          <div className={styles.ErrorMessage}>{errorMessage}</div>
          <Heading size="META">ATTACHMENTS (OPTIONAL)</Heading>
          <UploadInput
            fieldName="uploadSecureFiles"
            label="Upload file(s)"
            acceptedFileTypes={["image/png", "application/pdf", "video/mp4"]}
            disabled={formDisabled}
            multiple
            customOnChange={(event) => {
              if (event.uploadSecureFiles && event.uploadSecureFiles.length) {
                const currentFileList = values.uploadSecureFiles as File[];
                let updatedFileListNames;

                if (currentFileList && currentFileList.length) {
                  const currentFileNames = currentFileList.map((file: File) => file.name);

                  event.uploadSecureFiles.forEach((newFile: File) => {
                    if (!currentFileNames.includes(newFile.name)) {
                      currentFileList.push(newFile);
                    }
                  });
                  formApi.setValue("uploadSecureFiles", currentFileList);

                  updatedFileListNames = currentFileList.map((file: File) => file.name);
                } else {
                  formApi.setValue("uploadSecureFiles", event.uploadSecureFiles);
                  updatedFileListNames = event.uploadSecureFiles.map((file: File) => file.name);
                }

                setContentUploadList(updatedFileListNames);
              }
            }}
          />
          <div>
            {contentUploadList.map((content, idx) => (
              <div key={`content_${idx}`} className={cx(styles.FlexEnd, styles.UploadListItem)}>
                <div>{content}</div>
                <Button
                  className={styles.IconButton}
                  inline
                  disabled={formDisabled}
                  onClick={() => {
                    const currentFileList = values.uploadSecureFiles as File[];
                    const updatedFileList = currentFileList.filter(
                      (file: File) => file.name !== content
                    );
                    formApi.setValue("uploadSecureFiles", updatedFileList);

                    const updatedFileListNames = updatedFileList.map((file: File) => file.name);
                    setContentUploadList(updatedFileListNames);
                  }}
                >
                  <Trash />
                </Button>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className={styles.SecureFormErrorPage}>
          <div className={styles.GraphicWrapper}>
            <PhoneGraphic />
          </div>
          <Heading className={styles.SecureFormErrorPageHeading} size="M" component="h2">
            Contact details needed
          </Heading>
          <Text>
            You need to add a personal healthcare number to the patient&apos;s contact details to
            enable secure messaging.
          </Text>
          <br />
          <Text>
            You may send a basic message to your patient without updating their contact details, but
            remember to NEVER INCLUDE personal information in a basic message.
          </Text>
        </div>
      )}
      <div className={styles.ButtonGroup}>
        <Button inline onClick={closeModal}>
          Cancel
        </Button>
        <Button
          id="sendSecureMessage"
          type="submit"
          disabled={
            formDisabled ||
            !canSendSecureMessage ||
            (!values.payloadContent?.trim() && !values.uploadSecureFiles?.length)
          }
          onClick={() => onSend(values)}
        >
          Send secure message
        </Button>
      </div>
    </div>
  );
};

export default FormContent;
