import React from "react";
import { isRequired } from "../../../../utils/validators";
import { FloatModal } from "../../../ui/Modal";
import Heading from "../../../ui/Heading";
import Text from "../../../ui/Text";
import { Form, TextInput } from "../../../ui/Input";
import Button from "../../../ui/Button";
import Image from "../../../ui/Image";

import styles from "./index.module.scss";

type PropsType = {
  qrCode: string;
  recoveryCodes: string[];
  isOpen: boolean;
  closeModal: () => void;
  error?: string;
  onSubmit: (formState: FormState) => Promise<void>;
  loginLoading: boolean;
};

type FormState = {
  twoFAToken: string;
};

const initialState = {
  twoFAToken: ""
};

const twoFAValidator = isRequired("Please enter a passcode");

const EnrollTwoFA = ({
  qrCode,
  recoveryCodes,
  isOpen,
  closeModal,
  error,
  onSubmit,
  loginLoading
}: PropsType) => {
  return (
    <FloatModal isOpen={isOpen} onClose={closeModal}>
      <Heading size="L" className={styles.Heading}>
        Enroll for 2FA
      </Heading>
      <Text className={styles.Text}>
        Open the following QR code using an authenticator app of your choice.
      </Text>
      <Image src={qrCode} />
      <Text className={styles.Text}>Please store these recovery codes in a secure location.</Text>
      {recoveryCodes.map((recoveryCode) => {
        return (
          <Text bold key={recoveryCode}>
            {recoveryCode}
          </Text>
        );
      })}
      <Form
        initialValues={initialState}
        onSubmit={(formState) => onSubmit(formState.values as FormState)}
      >
        <Text className={styles.Text}>
          Using your authenticator app, please enter the 2FA passcode.
        </Text>
        <TextInput
          fieldName="twoFAToken"
          label="2FA Token"
          validate={twoFAValidator}
          warningMessage={error}
          autoFocus
        />
        <Button type="submit" disabled={loginLoading}>
          Verify One-Time Passcode
        </Button>
      </Form>
    </FloatModal>
  );
};

export default EnrollTwoFA;
