import axios from "axios";
import getToken from "../utils/getToken";

import { addNotification } from "./notifications";

import {
  ROOT_URL,
  ADD_CHAT_FLOW,
  ADD_CHAT_NODE,
  CLEAR_CHAT_FLOW_DETAILS,
  CLONE_CHAT_FLOW,
  FETCH_CHATS,
  GET_CHAT_FLOW_DETAILS,
  GET_AVAILABLE_CHAT_FLOWS,
  PUBLISH_CHAT_FLOW,
  UPDATE_CHAT_FLOWS_NODE,
  DELETE_CHAT_NODE
} from "../constants";

import {
  Dispatch,
  ActionStatus,
  Chat,
  ChatEdge,
  ChatFlowsNodes,
  CreateChatFlowNode,
  ReportTemplate,
  ChatFlowOutput,
  PayloadOptions
} from "../types";

export type FetchChatsAction = {
  type: typeof FETCH_CHATS;
  status: ActionStatus;
  payload?: { chats: Array<Chat> };
};

export type GetChatFlowDetailsAction = {
  type: typeof GET_CHAT_FLOW_DETAILS;
  status: ActionStatus;
  payload?: {
    chatFlow: Chat;
    chatFlowsNodes: Array<ChatFlowsNodes>;
    chatEdges: Array<ChatEdge>;
    chatTags: Array<string>;
    reportTemplates: Array<ReportTemplate>;
    outputs: Array<ChatFlowOutput>;
    allMessageTemplates: Array<ChatFlowsNodes>;
  };
};

export type GetAvailableChatFlowsAction = {
  type: typeof GET_AVAILABLE_CHAT_FLOWS;
  status: ActionStatus;
  payload?: {
    availableChats: Array<Chat>;
  };
};

export type ClearChatFlowDetailsAction = {
  type: typeof CLEAR_CHAT_FLOW_DETAILS;
};

export type UpdateChatFlowsNodeAction = {
  type: typeof UPDATE_CHAT_FLOWS_NODE;
  status: ActionStatus;
  payload?: ChatFlowsNodes;
};

export type CloneChatFlowAction = {
  type: typeof CLONE_CHAT_FLOW;
  status: ActionStatus;
  payload?: {
    clonedChat: Chat;
  };
};

export type PublishChatFlowAction = {
  type: typeof PUBLISH_CHAT_FLOW;
  status: ActionStatus;
  payload?: Chat;
};

export type AddChatNodeAction = {
  type: typeof ADD_CHAT_NODE;
  status: ActionStatus;
  payload?: string | undefined;
};

export type AddChatFlowAction = {
  type: typeof ADD_CHAT_FLOW;
  status?: ActionStatus;
};

export type DeleteChatNodeAction = {
  type: typeof DELETE_CHAT_NODE;
  status: ActionStatus;
  payload?: { data: ChatFlowsNodes };
};

export const fetchChats = () => (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: `${token}`, "Content-Type": "application/json" }
  };

  dispatch({
    type: FETCH_CHATS,
    status: ActionStatus.loading
  });

  return axios
    .get(`${ROOT_URL}/chat-flows`, config)
    .then((response) => {
      return dispatch({
        type: FETCH_CHATS,
        status: ActionStatus.success,
        payload: { chats: response.data.chats }
      });
    })
    .catch(() => {
      dispatch(
        addNotification({
          type: "error",
          title: "Failed to fetch chats",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: FETCH_CHATS,
        status: ActionStatus.error
      });
    });
};

export const getChatFlowDetails = (chatId: string) => async (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: `${token}`, "Content-Type": "application/json" }
  };

  dispatch({
    type: GET_CHAT_FLOW_DETAILS,
    status: ActionStatus.loading
  });

  return axios
    .get(`${ROOT_URL}/chat-flows/${chatId}`, config)
    .then((response) => {
      return dispatch({
        type: GET_CHAT_FLOW_DETAILS,
        status: ActionStatus.success,
        payload: response.data
      });
    })
    .catch((err) => {
      dispatch(
        addNotification({
          type: "error",
          title: "Failed to fetch chat details",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: GET_CHAT_FLOW_DETAILS,
        status: ActionStatus.error
      });
    });
};

export type GetAvailableChatsData = {
  topic?: string;
  includeMikataChats?: boolean;
};

export const getAvailableChats = (data: GetAvailableChatsData) => async (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: `${token}`, "Content-Type": "application/json" }
  };

  dispatch({
    type: GET_AVAILABLE_CHAT_FLOWS,
    status: ActionStatus.loading
  });

  return axios
    .post(`${ROOT_URL}/chat-flows/get-available`, data, config)
    .then((response) => {
      return dispatch({
        type: GET_AVAILABLE_CHAT_FLOWS,
        status: ActionStatus.success,
        payload: response.data
      });
    })
    .catch((err) => {
      dispatch(
        addNotification({
          type: "error",
          title: "Failed to fetch available chats",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: GET_AVAILABLE_CHAT_FLOWS,
        status: ActionStatus.error
      });
    });
};

export type ChatFlowsNodeUpdateData = {
  displayName: string;
  payloadOptions: PayloadOptions;
  payloadContent: string;
  tags: string[];
};

export const clearChatFlowDetails = () => ({
  type: CLEAR_CHAT_FLOW_DETAILS
});

export const updateChatFlowsNode =
  (id: number, chatId: string, data: ChatFlowsNodeUpdateData) => (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: UPDATE_CHAT_FLOWS_NODE,
      status: ActionStatus.loading
    });

    return axios
      .patch(`${ROOT_URL}/chat-flows/${chatId}/nodes/${id}`, data, config)
      .then((res) => {
        if (res.data.error) {
          dispatch({
            type: UPDATE_CHAT_FLOWS_NODE,
            status: ActionStatus.error
          });
          return dispatch(
            addNotification({
              type: "error",
              title: "Failed to update chat node details",
              subtitle: "Please try again",
              autoDismiss: true
            })
          );
        }

        dispatch({
          type: UPDATE_CHAT_FLOWS_NODE,
          status: ActionStatus.success
        });
        getChatFlowDetails(chatId)(dispatch);
        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: `Successfully updated chat node information`,
            autoDismiss: true
          })
        );
      })
      .catch((error) => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to update chat node details",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
        return dispatch({
          type: UPDATE_CHAT_FLOWS_NODE,
          status: ActionStatus.error
        });
      });
  };

export const cloneChatFlow =
  (chatId: string, onSuccess?: (cloneChatFlowId: string) => void, toOrganizationId?: number) =>
  async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: `${token}`, "Content-Type": "application/json" }
    };

    dispatch({
      type: CLONE_CHAT_FLOW,
      status: ActionStatus.loading
    });

    return axios
      .post(`${ROOT_URL}/chat-flows/${chatId}`, { toOrganizationId }, config)
      .then((response) => {
        fetchChats()(dispatch);
        dispatch({
          type: CLONE_CHAT_FLOW,
          status: ActionStatus.success,
          payload: response.data
        });
        if (toOrganizationId) {
          dispatch(
            addNotification({
              type: "success",
              title: "Success",
              subtitle: `Successfully cloned chat to organization ${toOrganizationId}`,
              autoDismiss: true
            })
          );
        } else {
          dispatch(
            addNotification({
              type: "success",
              title: "Success",
              subtitle: `Successfully created a draft version`,
              autoDismiss: true
            })
          );
        }

        if (onSuccess) {
          onSuccess(response.data.clonedChat.id);
        }
      })
      .catch((err) => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to clone chats",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
        return dispatch({
          type: CLONE_CHAT_FLOW,
          status: ActionStatus.error
        });
      });
  };

export const publishChatFlow =
  (chatFlowId: string, onSuccess?: () => void) => (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: PUBLISH_CHAT_FLOW,
      status: ActionStatus.loading
    });

    return axios
      .patch(`${ROOT_URL}/chat-flows/${chatFlowId}`, null, config)
      .then((res) => {
        if (res.data.error) {
          dispatch({
            type: PUBLISH_CHAT_FLOW,
            status: ActionStatus.error
          });
          return dispatch(
            addNotification({
              type: "error",
              title: "Failed to publish chat flow",
              subtitle: "Please try again",
              autoDismiss: true
            })
          );
        }

        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: `Successfully published chat flow`,
            autoDismiss: true
          })
        );
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to publish chat flow",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
        return dispatch({
          type: PUBLISH_CHAT_FLOW,
          status: ActionStatus.error
        });
      });
  };

export const addChatNode =
  (newNode: CreateChatFlowNode, parentChatFlowId: number, onSuccess?: () => void) =>
  (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: `${token}`, "Content-Type": "application/json" }
    };
    dispatch({
      type: ADD_CHAT_NODE,
      status: ActionStatus.loading
    });
    return axios
      .post(`${ROOT_URL}/chat-flows/${parentChatFlowId}/nodes`, newNode, config)
      .then((response) => {
        getChatFlowDetails(parentChatFlowId.toString())(dispatch);
        if (onSuccess) onSuccess();

        return dispatch({
          type: ADD_CHAT_NODE,
          status: ActionStatus.success,
          payload: response.data
        });
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to add chat node",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
        return dispatch({
          type: ADD_CHAT_NODE,
          status: ActionStatus.error
        });
      });
  };

export type AddChatFlowData = {
  title: string;
  organizationId: number | null;
  chatFlowType: string;
};

type AddChatFlowResponse = {
  status: number;
  data: Chat;
};

export const addChatFlow = (data: AddChatFlowData) => async (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };
  dispatch({
    type: ADD_CHAT_FLOW,
    status: ActionStatus.loading
  });

  return axios
    .post(`${ROOT_URL}/chat-flows`, data, config)
    .then((payload: AddChatFlowResponse) => {
      if (!payload || payload.status !== 200) {
        dispatch({
          type: ADD_CHAT_FLOW,
          status: ActionStatus.error
        });

        return dispatch(
          addNotification({
            type: "error",
            title: `Failed to add a new chat flow`,
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
      }

      dispatch(
        addNotification({
          type: "success",
          title: "Success",
          subtitle: `Successfully added a new chat flow`,
          autoDismiss: true
        })
      );

      return fetchChats()(dispatch).then(() => {
        dispatch({
          type: ADD_CHAT_FLOW,
          status: ActionStatus.success
        });
      });
    })
    .catch(() => {
      dispatch(
        addNotification({
          type: "error",
          title: `Failed to add a new chat flow`,
          subtitle: "Please try again",
          autoDismiss: true
        })
      );

      dispatch({
        type: ADD_CHAT_FLOW,
        status: ActionStatus.error
      });
    });
};

export type DeleteChatNodeData = {
  id: number;
  chatFlowId: string | undefined;
  onSuccess?: () => void;
};

type DeleteChatNodeResponse = {
  status: number;
  data: {
    error?: boolean;
  };
};

export const deleteChatNode = (data: DeleteChatNodeData) => (dispatch: Dispatch) => {
  const { id, chatFlowId, onSuccess } = data;
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  dispatch({
    type: DELETE_CHAT_NODE,
    status: ActionStatus.loading
  });

  return axios
    .delete(`${ROOT_URL}/chat-flows/${chatFlowId}/nodes/${id}`, config)
    .then((payload: DeleteChatNodeResponse) => {
      if (!payload || payload.data.error) {
        dispatch({
          type: DELETE_CHAT_NODE,
          status: ActionStatus.error
        });

        return dispatch(
          addNotification({
            type: "error",
            title: "Failed to delete chat card",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
      }

      if (onSuccess) {
        onSuccess();
      }

      dispatch(
        addNotification({
          type: "success",
          title: "Success",
          subtitle: "Successfully deleted chat card",
          autoDismiss: true
        })
      );

      dispatch({
        type: DELETE_CHAT_NODE,
        status: ActionStatus.success
      });

      return getChatFlowDetails(chatFlowId as string)(dispatch);
    })
    .catch(() => {
      dispatch({
        type: DELETE_CHAT_NODE,
        status: ActionStatus.error
      });

      return dispatch(
        addNotification({
          type: "error",
          title: "Failed to delete chat card",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
    });
};
