import React, { useEffect } from "react";
import { connect } from "react-redux";
import QRCode from "react-qr-code";

import Heading from "../../../ui/Heading";
import { QRCodeBackgroundImage } from "../../../ui/Icon";
import Text from "../../../ui/Text";
import Loader from "../../../ui/Loader";

import { FloatModal } from "../../../ui/Modal";

import {
  closeModal as closeModalAction,
  fetchAppointmentChatLink as fetchAppointmentChatLinkAction,
  FetchAppointmentChatLinkData
} from "../../../../actions";

import { ReduxStateType } from "../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  appointmentId: number;
  chatFlowIds: number[];
  conversationsLink?: string;
  loading: boolean;
  closeModal: () => void;
  fetchAppointmentChatLink: (data: FetchAppointmentChatLinkData) => void;
};

const ViewAppointmentChatDetails = ({
  appointmentId,
  chatFlowIds,
  conversationsLink,
  loading,
  closeModal,
  fetchAppointmentChatLink
}: PropsType) => {
  useEffect(() => {
    if (appointmentId && typeof appointmentId === "number") {
      fetchAppointmentChatLink({ id: appointmentId, chatFlowIds });
    }
  }, [appointmentId]);

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L">View appointment details</Heading>

      <div className={styles.Container}>
        {loading && <Loader small center />}
        {!loading && conversationsLink && (
          <div className={styles.QRCodeContainer}>
            <Text size="M" className={styles.QRCodeText}>
              <Text component="span" bold>
                Scan the QR code below
              </Text>
              &nbsp; using the camera app on an internet-connected device to view the details for
              this appointment.
            </Text>
            <div className={styles.QRCode}>
              <QRCode value={conversationsLink} size={160} />
            </div>
            <QRCodeBackgroundImage />
            <Text size="S" className={styles.Link}>
              {conversationsLink}
            </Text>
          </div>
        )}
      </div>
    </FloatModal>
  );
};

const mapStateToProps = ({ appointments }: ReduxStateType) => {
  return {
    fetchAppointmentChatLink: fetchAppointmentChatLinkAction,
    conversationsLink: appointments.details.conversationsLink,
    loading: appointments.details.conversationsLinkLoading
  };
};

export default connect(mapStateToProps, {
  fetchAppointmentChatLink: fetchAppointmentChatLinkAction,
  closeModal: closeModalAction
})(ViewAppointmentChatDetails);
