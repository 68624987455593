import React from "react";
import cx from "classnames";

import Text from "../../ui/Text";

import styles from "./index.module.scss";

import { StatusComponentColorModes, StatusComponentConfigMap, Option } from "../../../types";

type PropsType = {
  value: string;
  options: Array<Option>;
  fullWidth?: boolean;
  configMap?: StatusComponentConfigMap;
  className?: string;
  placeholder?: string;
  defaultColor?: StatusComponentColorModes;
  id?: string;
  size?: "M" | "S" | "XS";
  bold?: boolean;
};

const getStatusMode = (
  value: string,
  configMap?: StatusComponentConfigMap,
  defaultColor?: StatusComponentColorModes
):
  | "blue"
  | "green"
  | "yellow"
  | "red"
  | "gray"
  | "purple"
  | "pink"
  | "black"
  | "default"
  | undefined => {
  if (!configMap) return "default";

  return configMap && configMap[value] ? configMap[value] : defaultColor || "default";
};

const Status = ({
  value,
  options,
  configMap,
  className,
  placeholder,
  defaultColor,
  fullWidth = false,
  id,
  size = "S",
  bold = true
}: PropsType) => {
  if (!value && !placeholder) return null;

  const mode = getStatusMode(value, configMap, defaultColor);

  const selectedOption = options.find((option) => option.value === value);
  const placeholderMode = !selectedOption && placeholder;

  return (
    <div
      id={id}
      className={cx(
        styles.Status,
        {
          [styles.StatusBlue]: mode === "blue",
          [styles.StatusGreen]: mode === "green",
          [styles.StatusYellow]: mode === "yellow",
          [styles.StatusRed]: mode === "red",
          [styles.StatusPurple]: mode === "purple",
          [styles.StatusPink]: mode === "pink",
          [styles.StatusGray]: mode === "gray",
          [styles.StatusBlack]: mode === "black",
          [styles.StatusFullWidth]: fullWidth
        },
        className
      )}
    >
      <Text
        className={cx(styles.Text, {
          [styles.TextBlue]: mode === "blue",
          [styles.TextGreen]: mode === "green",
          [styles.TextYellow]: mode === "yellow",
          [styles.TextRed]: mode === "red",
          [styles.TextPurple]: mode === "purple",
          [styles.StatusPink]: mode === "pink",
          [styles.TextGray]: mode === "gray",
          [styles.TextBlack]: mode === "black",
          [styles.TextPlaceholder]: placeholderMode
        })}
        size={size}
        component="span"
        bold={bold}
      >
        {selectedOption?.label || placeholder || ""}
      </Text>
    </div>
  );
};

export default Status;
