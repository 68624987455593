import React from "react";

type PropsType = {
  size?: number;
};

const QRCode = ({ size = 24 }: PropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3 5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5Zm6 0H5v4h4V5ZM3 15a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4Zm6 0H5v4h4v-4Zm6-12a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-4Zm0 2h4v4h-4V5Zm-1 11a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1Zm0-3a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2.5a1.5 1.5 0 0 0-1.5-1.5H14Zm6 3.5a1 1 0 0 1 1 1v2a1.5 1.5 0 0 1-1.5 1.5H17a1 1 0 1 1 0-2h2v-1.5a1 1 0 0 1 1-1Zm1-2.5a1 1 0 1 0-2 0v.5a1 1 0 1 0 2 0V14Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default QRCode;
