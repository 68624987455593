import { NoteTemplate } from "../../../../../types";

export const getNoteTemplateOwnerDisplayName = (noteTemplate: NoteTemplate) => {
  let ownerDisplayName = "Mikata";

  if (noteTemplate?.userId) {
    ownerDisplayName = noteTemplate?.providerName || "";
  }

  if (!noteTemplate?.userId && noteTemplate.organizationId) {
    ownerDisplayName = "Your Clinic";
  }

  return ownerDisplayName;
};
