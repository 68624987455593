import { v4 as uuid } from "uuid";
import cloneDeep from "lodash/cloneDeep";
import {
  ChatNodeTypes,
  ChatNode,
  ContentNode,
  TextNode,
  HeadingNode,
  ListNode,
  ListItemNode,
  ImportantNode,
  DurationNode,
  LinkNode,
  InfoModalNode
} from "../../../../../types";

const typeTemplateMap = {
  [ChatNodeTypes.content]: {
    type: ChatNodeTypes.content,
    id: "placeholder",
    children: []
  } as ContentNode,
  [ChatNodeTypes.text]: {
    type: ChatNodeTypes.text,
    id: "placeholder",
    properties: { value: "" }
  } as TextNode,
  [ChatNodeTypes.heading]: {
    type: ChatNodeTypes.heading,
    id: "placeholder",
    properties: { value: "", size: "m" }
  } as HeadingNode,
  [ChatNodeTypes.important]: {
    type: ChatNodeTypes.important,
    id: "placeholder",
    properties: { title: "Important", details: "" }
  } as ImportantNode,
  [ChatNodeTypes.duration]: {
    type: ChatNodeTypes.duration,
    id: "placeholder",
    properties: { duration: "", discomfort: "" }
  } as DurationNode,
  [ChatNodeTypes.link]: {
    type: ChatNodeTypes.link,
    id: "placeholder",
    properties: { label: "" }
  } as LinkNode,
  [ChatNodeTypes.infoModal]: {
    type: ChatNodeTypes.infoModal,
    id: "placeholder",
    properties: { label: "Tap here to open the guide" },
    children: [
      {
        type: ChatNodeTypes.heading,
        id: "placeholder",
        properties: { value: "", size: "l" }
      }
    ]
  } as InfoModalNode,
  [ChatNodeTypes.list]: {
    type: ChatNodeTypes.list,
    id: "placeholder",
    properties: { title: "" },
    children: []
  } as ListNode,
  [ChatNodeTypes.listItem]: {
    type: ChatNodeTypes.listItem,
    id: "placeholder",
    children: [
      {
        type: ChatNodeTypes.heading,
        id: "placeholder",
        properties: { value: "", size: "m" }
      }
    ]
  } as ListItemNode
};

export const getNodeTemplate = (type: ChatNodeTypes): ChatNode => {
  const nodeTemplate = cloneDeep(typeTemplateMap[type]);

  // inject new UUID
  nodeTemplate.id = uuid();

  if (nodeTemplate && nodeTemplate.children && nodeTemplate.children[0]) {
    nodeTemplate.children[0].id = uuid();
  }

  return nodeTemplate;
};
