import React from "react";
import { connect } from "react-redux";
import cx from "classnames";
import moment from "moment-timezone";

import Button from "../../../../../ui/Button";
import Text from "../../../../../ui/Text";
import Card from "../../../../../ui/Card";
import { Calendar, DownloadCloud, PaperClip, Profile } from "../../../../../ui/Icon";
import Heading from "../../../../../ui/Heading";

import { AppointmentRequest, ReduxStateType } from "../../../../../../types";

import { AppointmentRequestModes } from "../../../../../../constants";

import formatPhone from "../../../../../../utils/formatPhone";
import formatDate from "../../../../../../utils/formatDate";

import styles from "./index.module.scss";

type PropsType = {
  appointmentRequest: AppointmentRequest;
  mode: string;
  timezone?: string;
  preferQuickView?: boolean;
  downloadRequisition: (requestId: number, uploadId: number, uploadIdx: number) => void;
  requisitionLoading: { [id: string]: boolean[] };
};

const renderPatientFileDownloads = (
  appointmentRequest: AppointmentRequest,
  downloadRequisition: (requestId: number, uploadId: number, uploadIdx: number) => void,
  requisitionLoading: boolean[],
  isExpired: boolean
) => {
  return appointmentRequest.uploadIds.map((uploadId: number, idx: number) => (
    <div className={styles.DownloadRow} key={`req-download-${uploadId}`}>
      <Text>{`Patient attachment ${idx + 1}`}</Text>
      {isExpired ? (
        <Text className={styles.ExpiredLabel}>Expired</Text>
      ) : (
        <Button
          key={`patient-file-download-${uploadId}`}
          inline
          onClick={() => downloadRequisition(appointmentRequest.id, uploadId, idx)}
          disabled={requisitionLoading ? requisitionLoading[idx] : false}
        >
          <DownloadCloud size={24} />
        </Button>
      )}
    </div>
  ));
};

const ChatSummary = ({
  appointmentRequest,
  mode,
  timezone,
  preferQuickView,
  downloadRequisition,
  requisitionLoading
}: PropsType) => {
  const createdAtDisplay = appointmentRequest?.created_at
    ? formatDate(appointmentRequest?.created_at, "default")
    : "-";
  const expiryDate = appointmentRequest && moment(appointmentRequest.created_at).add(30, "days");
  const isExpired = appointmentRequest && moment().isAfter(expiryDate);
  const selectedAppointmentSlotDisplays: string[] = [];
  if (appointmentRequest?.bookingData) {
    appointmentRequest.bookingData.selectedAppointmentSlots.forEach((appointmentSlot) => {
      const startTimeDisplay = formatDate(appointmentSlot.startTime, "dateTime", timezone);
      selectedAppointmentSlotDisplays.push(
        `${startTimeDisplay}, ${appointmentSlot.practitionerName}, ${appointmentSlot.locationName}`
      );
    });
  }

  const getPatientNameLabel = (firstName: string, lastName: string) => {
    if (!firstName && !lastName) {
      return "No name provided";
    }

    return `${firstName || ""} ${lastName || ""}`;
  };

  return (
    <>
      <Card
        headerContent={
          !preferQuickView ? (
            <Heading size="M" component="span" className={cx(styles.Heading)}>
              Summary
              <Heading size="META" component="div" className={styles.MetaHeading}>
                {`CREATED: ${createdAtDisplay}`}
              </Heading>
            </Heading>
          ) : null
        }
        className={preferQuickView ? styles.QuickView : ""}
      >
        {appointmentRequest && (
          <div>
            <Heading size="S" bold className={styles.IconHeading}>
              {preferQuickView && <Profile />}
              <span className={preferQuickView ? styles.IconHeadingText : ""}>Patient details</span>
            </Heading>
            <div className={cx(styles.GroupDetails, styles.BottomBorder)}>
              <div className={styles.InfoGroup}>
                <Heading size="META" component="h5">
                  Patient Name
                </Heading>
                <Text>
                  {getPatientNameLabel(
                    appointmentRequest.patientFirstName,
                    appointmentRequest.patientLastName
                  )}
                </Text>
              </div>
              <div className={styles.InfoGroup}>
                <Heading size="META" component="h5">
                  Phone Number
                </Heading>
                <Text>{formatPhone(appointmentRequest.patientNumber)}</Text>
              </div>
              <div className={styles.InfoGroup}>
                <Heading size="META" component="h5">
                  Personal Health Number
                </Heading>
                <Text>{appointmentRequest.patientHealthcareNumber}</Text>
              </div>
            </div>
            <Heading size="S" bold className={styles.IconHeading}>
              {preferQuickView && <Calendar />}
              <span className={preferQuickView ? styles.IconHeadingText : ""}>Request details</span>
            </Heading>
            <div
              className={cx(styles.GroupDetails, {
                [styles.BottomBorder]: preferQuickView
              })}
            >
              <div className={styles.InfoGroup}>
                <Heading size="META" component="h5">
                  Reason
                </Heading>
                <Text>
                  {appointmentRequest.reasons ? appointmentRequest.reasons.join(", ") : ""}
                </Text>
              </div>
              {mode === AppointmentRequestModes.SIMPLE_REQUEST ? (
                <>
                  <div className={styles.PreferenceDetails}>
                    <div className={styles.InfoGroup}>
                      <Heading size="META" component="h5">
                        Time Preference
                      </Heading>
                      <Text>
                        {appointmentRequest.dateTimePreference
                          ? appointmentRequest.dateTimePreference
                              .map((dtp) => {
                                const displayValue = dtp.date || dtp.time || dtp.option;

                                return Array.isArray(displayValue)
                                  ? displayValue.join(", ")
                                  : displayValue;
                              })
                              .join(", ")
                          : ""}
                      </Text>
                    </div>
                  </div>
                  <div className={styles.PreferenceDetails}>
                    <div className={styles.InfoGroup}>
                      <Heading size="META" component="h5">
                        Location Preference
                      </Heading>
                      <Text>
                        {appointmentRequest.locationPreference
                          ? appointmentRequest.locationPreference.join(", ")
                          : ""}
                      </Text>
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  <Heading size="META" component="h5">
                    Selections made by the patient
                  </Heading>
                  {selectedAppointmentSlotDisplays.length && (
                    <div>
                      {selectedAppointmentSlotDisplays.map((slotDisplayText, idx) => {
                        return <Text key={`${slotDisplayText}-${idx}`}>{slotDisplayText}</Text>;
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
            {preferQuickView && (
              <div>
                <Heading size="S" bold className={styles.IconHeading}>
                  {preferQuickView && <PaperClip />}
                  <span className={preferQuickView ? styles.IconHeadingText : ""}>Attachments</span>
                </Heading>
                <div className={cx(styles.GroupDetails, styles.BottomBorder)}>
                  <div className={cx(styles.TableCell, styles.ChatIcon)}>
                    {appointmentRequest.hasRequisition ? (
                      renderPatientFileDownloads(
                        appointmentRequest,
                        downloadRequisition,
                        requisitionLoading[appointmentRequest.id],
                        isExpired
                      )
                    ) : (
                      <Text size="M">None</Text>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Card>
      {!preferQuickView && (
        <Card>
          <Heading size="S" bold>
            Attachments
          </Heading>
          <div className={cx(styles.TableCell, styles.ChatIcon)}>
            {appointmentRequest.hasRequisition ? (
              renderPatientFileDownloads(
                appointmentRequest,
                downloadRequisition,
                requisitionLoading[appointmentRequest.id],
                isExpired
              )
            ) : (
              <Text size="M">None</Text>
            )}
          </div>
        </Card>
      )}
    </>
  );
};

const mapStateToProps = ({ organizationData }: ReduxStateType) => {
  return {
    timezone: organizationData.organizationData ? organizationData.organizationData.timezone : ""
  };
};

export default connect(mapStateToProps, null)(ChatSummary);
