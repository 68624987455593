import React from "react";
import { connect } from "react-redux";
import { ArrayField } from "informed";

import Button from "../../../ui/Button";
import Heading from "../../../ui/Heading";
import { FloatModal } from "../../../ui/Modal";
import Text from "../../../ui/Text";
import { Form, TextInput } from "../../../ui/Input";

import {
  sendMessageTemplate as sendMessageTemplateAction,
  MessageTemplateSendData,
  closeModal as closeModalAction
} from "../../../../actions";

import { isPhone, isEmail, combine, isRequired } from "../../../../utils/validators";
import styles from "./index.module.scss";
import { AutomationJourneyTestData, MessageTemplate, ReduxStateType } from "../../../../types";

type SendMessageTemplateFormData = {
  phoneNumbers: Array<string>;
  emails: Array<string>;
};

type PropsType = {
  messageTemplate: MessageTemplate;
  loading?: boolean;
  closeModal: () => void;
  sendMessageTemplate: (data: MessageTemplateSendData) => void;
  chatFlowId?: number;
  messageConfigurationId?: number;
  automationJourneyData?: AutomationJourneyTestData;
};

const initialFormState: SendMessageTemplateFormData = {
  phoneNumbers: [],
  emails: []
};

const phoneNumberValidator = combine([
  isRequired("Please enter a phone number"),
  isPhone("Please enter a valid phone number")
]);
const emailValidator = combine([
  isRequired("Please enter an email"),
  isEmail("Please enter a valid email")
]);

const SendMessageTemplate = ({
  loading,
  closeModal,
  sendMessageTemplate,
  messageTemplate,
  chatFlowId,
  messageConfigurationId,
  automationJourneyData
}: PropsType) => {
  const onSend = (values: SendMessageTemplateFormData) => {
    sendMessageTemplate({
      messageTemplateId: messageTemplate?.id,
      phoneNumbers: values.phoneNumbers,
      emails: values.emails,
      testChatFlowId: chatFlowId || undefined,
      messageConfigurationId,
      automationJourneyData
    });
  };

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L">Send Message Template</Heading>
      {messageConfigurationId && (
        <Text component="p">
          {messageConfigurationId
            ? `Send a test message for the following touchpoint: ${messageConfigurationId}`
            : `Send a test message for the following template: ${messageTemplate.displayName}`}
        </Text>
      )}
      {chatFlowId && (
        <Text component="p">
          {chatFlowId
            ? `Send a test message for the following chat: ${chatFlowId}`
            : `Send a test message for the following template: ${messageTemplate.displayName}`}
        </Text>
      )}
      <Form
        className={styles.Form}
        onSubmit={(formState) => onSend(formState.values as SendMessageTemplateFormData)}
        initialValues={initialFormState}
      >
        <Heading size="META">Phone Numbers</Heading>
        <ArrayField name="phoneNumbers">
          {({ add }) => {
            return (
              <>
                <div className={styles.PhoneNumberList}>
                  <ArrayField.Items>
                    {({ name, index, remove }) => {
                      return (
                        <React.Fragment key={`phoneNumber_${index}`}>
                          <TextInput fieldName={name} validate={phoneNumberValidator} />
                          <Button inline type="button" onClick={remove}>
                            Remove
                          </Button>
                        </React.Fragment>
                      );
                    }}
                  </ArrayField.Items>
                </div>
                <Button inline type="button" onClick={() => add()}>
                  Add Number
                </Button>
              </>
            );
          }}
        </ArrayField>
        <br />
        <br />
        <Heading size="META">Emails</Heading>
        <ArrayField name="emails">
          {({ add }) => {
            return (
              <>
                <div className={styles.PhoneNumberList}>
                  <ArrayField.Items>
                    {({ name, index, remove }) => {
                      return (
                        <React.Fragment key={`emails_${index}`}>
                          <TextInput fieldName={name} validate={emailValidator} />
                          <Button inline type="button" onClick={remove}>
                            Remove
                          </Button>
                        </React.Fragment>
                      );
                    }}
                  </ArrayField.Items>
                </div>
                <Button inline type="button" onClick={() => add()}>
                  Add Email
                </Button>
              </>
            );
          }}
        </ArrayField>
        <div className={styles.ButtonGroup}>
          <Button inline onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit" disabled={loading}>
            Send
          </Button>
        </div>
      </Form>
    </FloatModal>
  );
};
const mapStateToProps = ({ messageTemplates }: ReduxStateType) => {
  return {
    loading: messageTemplates.messageTemplateSendLoading
  };
};

export default connect(mapStateToProps, {
  sendMessageTemplate: sendMessageTemplateAction,
  closeModal: closeModalAction
})(SendMessageTemplate);
