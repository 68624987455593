import React from "react";

type PropsType = {
  size?: number;
};

const Chats = ({ size = 20 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.54386 10C3.54386 6.68629 6.23015 4 9.54386 4C12.8576 4 15.5439 6.68629 15.5439 10C15.5439 13.1843 13.0633 15.7892 9.92919 15.9878C9.8233 15.9945 9.71666 15.9985 9.60934 15.9997C9.58762 15.9999 9.56587 16 9.54409 16C9.29831 16 9.0564 15.9853 8.81912 15.9567L8.80037 15.9545C7.91822 15.8456 7.09671 15.5456 6.37687 15.0972C6.05548 14.897 5.65446 14.8121 5.2532 14.8987L3.4823 15.2806L4.0788 13.426C4.19555 13.063 4.16494 12.6852 4.02629 12.3618C3.71611 11.6382 3.54386 10.8405 3.54386 10ZM8.06231 17.8629C7.1375 17.6896 6.26804 17.3571 5.48436 16.8948L3.05738 17.4182C1.92863 17.6617 0.959641 16.5919 1.31319 15.4927L2.11968 12.9852C1.748 12.0616 1.54386 11.0535 1.54386 10C1.54386 5.58172 5.12558 2 9.54386 2C12.8609 2 15.7064 4.01875 16.9188 6.89465C19.8851 7.89829 22.0213 10.7038 22.0213 14.0105C22.0213 14.9905 21.8331 15.9287 21.4901 16.7895L22.214 19.0401C22.5675 20.1393 21.5985 21.2091 20.4698 20.9656L18.2983 20.4973C17.1856 21.1482 15.8903 21.5211 14.5107 21.5211C11.7696 21.5211 9.37305 20.0525 8.06231 17.8629ZM17.5441 9.99999C17.5441 9.79625 17.5365 9.59419 17.5214 9.39409C19.0269 10.3781 20.0213 12.0789 20.0213 14.0105C20.0213 14.7785 19.8647 15.5074 19.5828 16.169C19.4456 16.491 19.4145 16.868 19.5313 17.2313L20.0449 18.828L18.5306 18.5014C18.1289 18.4148 17.7288 18.5002 17.4087 18.6986C16.5674 19.2199 15.5758 19.5211 14.5107 19.5211C12.9996 19.5211 11.6297 18.9129 10.6334 17.9264C14.5364 17.3951 17.5441 14.0488 17.5441 9.99999Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Chats;
