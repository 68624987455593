import React from "react";

type PropsType = {
  size?: number;
};

const MikataSignupLogo = ({ size = 100 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size * (347 / 461)}px`}
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_12642_42267)">
        <circle cx="75" cy="65" r="60" fill="white" />
      </g>
      <path
        d="M110.284 90.125C112.889 90.125 115 87.9974 115 85.3729C115 82.7483 112.889 80.6207 110.284 80.6207C107.679 80.6207 105.568 82.7483 105.568 85.3729C105.568 87.9974 107.679 90.125 110.284 90.125Z"
        fill="#FF878F"
      />
      <path
        d="M85.4669 44.1875C81.1647 44.1859 77.0202 45.8218 73.8607 48.7686C73.5921 49.0201 73.2393 49.1598 72.8729 49.1598C72.5065 49.1598 72.1536 49.0201 71.885 48.7686C68.7255 45.8218 64.581 44.1859 60.2789 44.1875C50.7353 44.1875 43 52.0912 43 61.8403V85.2259C43 87.8211 44.9741 90.0303 47.5125 90.1228C48.1458 90.1423 48.7765 90.0328 49.3669 89.8006C49.9572 89.5685 50.4951 89.2187 50.9484 88.7719C51.4017 88.3252 51.7611 87.7908 52.005 87.2007C52.2489 86.6106 52.3724 85.9769 52.3681 85.3375V62.0493C52.3681 57.583 55.9101 53.967 60.271 53.967C64.6397 53.967 68.1817 57.5862 68.1817 62.0493V85.2259C68.1817 87.8211 70.1559 90.0303 72.6943 90.1228C73.3275 90.1423 73.9581 90.0327 74.5484 89.8006C75.1386 89.5685 75.6765 89.2186 76.1296 88.7719C76.5828 88.3251 76.942 87.7907 77.1857 87.2006C77.4295 86.6105 77.5528 85.9768 77.5482 85.3375V62.0493C77.5482 57.583 81.0903 53.967 85.459 53.967C89.8277 53.967 93.3698 57.5862 93.3698 62.0493V85.2259C93.3698 87.8211 95.3455 90.0303 97.8839 90.1228C98.5171 90.1423 99.1477 90.0327 99.738 89.8006C100.328 89.5685 100.866 89.2186 101.319 88.7719C101.772 88.3251 102.132 87.7907 102.375 87.2006C102.619 86.6105 102.742 85.9768 102.738 85.3375V61.8403C102.746 52.0912 95.0104 44.1875 85.4669 44.1875Z"
        fill="#003125"
      />
      <path
        d="M47.7162 44.5043C50.3208 44.5043 52.4323 42.3767 52.4323 39.7522C52.4323 37.1276 50.3208 35 47.7162 35C45.1115 35 43 37.1276 43 39.7522C43 42.3767 45.1115 44.5043 47.7162 44.5043Z"
        fill="#00D598"
      />
      <path
        d="M112.094 3.94165C112.453 3.1722 113.547 3.1722 113.906 3.94165L118.892 14.6247C118.991 14.8376 119.162 15.0087 119.375 15.108L130.058 20.0938C130.828 20.4529 130.828 21.5471 130.058 21.9062L119.375 26.892C119.162 26.9913 118.991 27.1624 118.892 27.3752L113.906 38.0583C113.547 38.8278 112.453 38.8278 112.094 38.0583L107.108 27.3752C107.009 27.1624 106.838 26.9913 106.625 26.892L95.9417 21.9062C95.1722 21.5471 95.1722 20.4529 95.9416 20.0938L106.625 15.108C106.838 15.0087 107.009 14.8376 107.108 14.6248L112.094 3.94165Z"
        fill="#FFD440"
      />
      <path
        d="M30.5938 98.9416C30.9529 98.1722 32.0471 98.1722 32.4062 98.9416L35.3237 105.193C35.423 105.406 35.5941 105.577 35.807 105.676L42.0584 108.594C42.8278 108.953 42.8278 110.047 42.0584 110.406L35.807 113.324C35.5941 113.423 35.423 113.594 35.3237 113.807L32.4062 120.058C32.0471 120.828 30.9529 120.828 30.5938 120.058L27.6763 113.807C27.577 113.594 27.4059 113.423 27.193 113.324L20.9416 110.406C20.1722 110.047 20.1722 108.953 20.9416 108.594L27.193 105.676C27.4059 105.577 27.577 105.406 27.6763 105.193L30.5938 98.9416Z"
        fill="#FFD440"
      />
      <defs>
        <filter
          id="filter0_d_12642_42267"
          x="0"
          y="0"
          width="150"
          height="150"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.545098 0 0 0 0 0.545098 0 0 0 0 0.545098 0 0 0 0.160784 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12642_42267" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12642_42267"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default MikataSignupLogo;
