import React from "react";
import { useFormState } from "informed";

import Button from "../../../ui/Button";
import Text from "../../../ui/Text";
import { SelectInput } from "../../../ui/Input";
import FileCSV from "./FormComponents/FileCSV";
import PushNote from "./FormComponents/PushNote";
import PushDemographics from "./FormComponents/PushDemographics";

import { ChatFlowOutput, Option } from "../../../../types";
import { ChatFlowOutputTypes, IntegrationCategories } from "../../../../constants";
import { isRequired } from "../../../../utils/validators/isRequired";
import { FormOptionsConfig, InputNameOption, TagOption } from ".";
import { OrganizationContextType } from "../../../providers/OrganizationProvider";

import styles from "./index.module.scss";

type AddChatFlowOutputFormData = {
  type: string;
  reportTemplateId?: string;
  title?: string;
  letterTypeId?: string;
  fileCSVForm?: Array<DataMappingsOption>;
  demographicPropertiesArray: Array<DemographicPropertyMapping>;
};

type DataMappingsOption = {
  inputName: string;
  mappedName: string;
};

type DemographicPropertyMapping = {
  emrFieldName?: string;
  mappingChoice?: string;
  inputName?: string;
  tagName?: string;
  required?: boolean;
  defaultValue?: string;
};

type PropsType = {
  loading?: boolean;
  closeModalAction: () => void;
  chatFlowOutput?: ChatFlowOutput;
  outputTypesObjects: Option[];
  reportTemplateOptions: Option[];
  letterTypeOptions: Option[];
  formOptionsConfig: FormOptionsConfig | null;
  availableMessageTemplateTags: TagOption[];
  outputType: string;
  setOutputType: (outputType: string) => void;
  availableInputNames: InputNameOption[];
  appointmentOutputs: Option[];
  globalError: string;
  organization: OrganizationContextType;
};

const FormContent = ({
  loading,
  closeModalAction,
  chatFlowOutput,
  outputTypesObjects,
  reportTemplateOptions,
  letterTypeOptions,
  formOptionsConfig,
  availableMessageTemplateTags,
  outputType,
  setOutputType,
  availableInputNames,
  globalError,
  appointmentOutputs,
  organization
}: PropsType) => {
  const formState = useFormState();
  const values = formState.values as AddChatFlowOutputFormData;

  return (
    <>
      <SelectInput
        fieldName="type"
        label="Type"
        options={outputTypesObjects}
        customOnChange={(event) => {
          setOutputType(event.type);
        }}
        placeholder="Choose Output Type"
        validate={isRequired("Please select output type")}
      />

      {outputType === ChatFlowOutputTypes.PUSH_NOTE && organization && (
        <PushNote
          fieldName="pushNoteForm"
          reportTemplateOptions={reportTemplateOptions}
          letterTypeOptions={letterTypeOptions}
          integrationCategory={organization.integrationCategory}
        />
      )}

      {outputType === ChatFlowOutputTypes.PUSH_DEMOGRAPHICS &&
        organization &&
        organization.integrationCategory === IntegrationCategories.ACCURO && (
          <PushDemographics
            fieldName="demographicPropertiesArray"
            config={formOptionsConfig}
            availableMessageTemplateTags={availableMessageTemplateTags}
            availableInputNames={availableInputNames}
            formValues={values}
          />
        )}

      {outputType === ChatFlowOutputTypes.FILE_CSV && organization && (
        <FileCSV
          fieldName="fileCSVForm"
          options={[...availableInputNames, ...appointmentOutputs]}
        />
      )}

      <div className={styles.ButtonGroup}>
        <Button inline onClick={closeModalAction} disabled={loading}>
          Cancel
        </Button>
        <Button type="submit">{chatFlowOutput?.id ? "Save" : "Create Output"}</Button>
      </div>
      <div>
        {globalError && (
          <Text className={styles.ErrorMessage} size="M">
            {globalError}
          </Text>
        )}
      </div>
    </>
  );
};

export default FormContent;
