import React from "react";

type PropsType = {
  size?: number;
};

const Settings = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0a3 3 0 013 3v.086c.001.26.156.493.404.6a.647.647 0 00.709-.123l.06-.06a3 3 0 114.244 4.244l-.052.052a.65.65 0 00-.13.717 1 1 0 01.064.2.647.647 0 00.531.284H21a3 3 0 010 6h-.086a.65.65 0 00-.595.394.65.65 0 00.118.719l.06.06a3 3 0 11-4.244 4.244l-.052-.052a.654.654 0 00-.727-.126.649.649 0 00-.394.591V21a3 3 0 01-6 0 .698.698 0 00-.484-.685.647.647 0 00-.709.122l-.06.06a3 3 0 11-4.244-4.244l.052-.052a.654.654 0 00.126-.727.649.649 0 00-.591-.394H3a3 3 0 010-6 .698.698 0 00.685-.484.647.647 0 00-.122-.709l-.06-.06a3 3 0 114.244-4.244l.052.052a.65.65 0 00.717.13 1 1 0 01.2-.064A.647.647 0 009 3.17V3a3 3 0 013-3zm0 2a1 1 0 00-1 1v.174a2.65 2.65 0 01-1.606 2.425 1 1 0 01-.264.073 2.655 2.655 0 01-2.737-.615l-.06-.06a1 1 0 10-1.416 1.416l.068.068c.757.774.967 1.932.554 2.864A2.657 2.657 0 013.09 11.08H3a1 1 0 000 2h.174a2.646 2.646 0 012.42 1.596 2.654 2.654 0 01-.537 2.931l-.06.06a1 1 0 101.416 1.416l.068-.068c.774-.757 1.932-.967 2.864-.554a2.657 2.657 0 011.735 2.449V21a1 1 0 002 0v-.174a2.646 2.646 0 011.596-2.42 2.654 2.654 0 012.931.537l.06.06a1 1 0 101.416-1.416l-.068-.068a2.65 2.65 0 01-.53-2.923A2.648 2.648 0 0120.91 13H21a1 1 0 000-2h-.174a2.65 2.65 0 01-2.425-1.606 1 1 0 01-.073-.264 2.655 2.655 0 01.615-2.737l.06-.06a1 1 0 10-1.416-1.416l-.068.068a2.646 2.646 0 01-2.913.534A2.651 2.651 0 0113 3.09V3a1 1 0 00-1-1zm0 6a4 4 0 110 8 4 4 0 010-8zm0 6a2 2 0 100-4 2 2 0 000 4z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Settings;
