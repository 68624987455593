import React from "react";
import { Scope, useFormState } from "informed";
import cx from "classnames";

import Button, { ButtonRow } from "../../../../../ui/Button";
import Heading from "../../../../../ui/Heading";
import Card from "../../../../../ui/Card";
import { Send } from "../../../../../ui/Icon";
import { SelectInput, ToggleInput } from "../../../../../ui/Input";
import Drawer from "../../../../../ui/Drawer";

import { PermissionsGuard } from "../../../../../../hooks/usePermissions";

import { MessageMediums } from "../../../../../../constants";
import { JourneyResponseMessagesFormState } from "../JourneyFormValidator";
import { AutomationDetails, MessageTemplate, Permissions, Option } from "../../../../../../types";

import styles from "./index.module.scss";

type MessageTemplatesByTopic = { [topic: string]: Array<MessageTemplate> };

type PropsType = {
  automation: AutomationDetails;
  automationResponsesConfig: { label: string; topic: string; mediums: string[] }[];
  messageTemplatesByTopic: MessageTemplatesByTopic;
  isDefaultAutomation: boolean;
  formDisabled: boolean;
  getTemplateOptionsByMedium: (
    medium: string,
    isDefaultAutomation: boolean,
    templates: Array<MessageTemplate>
  ) => Array<Option>;
  openSendTestModal: (testModal: MessageTemplate) => void;
};

const ResponseMessagesForm = ({
  automation,
  automationResponsesConfig,
  messageTemplatesByTopic,
  isDefaultAutomation,
  formDisabled,
  getTemplateOptionsByMedium,
  openSendTestModal
}: PropsType): JSX.Element | null => {
  const formState = useFormState();
  const values = formState.values as JourneyResponseMessagesFormState;

  return (
    <div className={styles.ContainerMain}>
      <Drawer
        collapse={false}
        initialOpen
        headerContent={<Heading size="M">Response Messages</Heading>}
      >
        {automationResponsesConfig.map((cardConfig) => {
          const topicMessageTemplates = messageTemplatesByTopic[cardConfig.topic];
          const selectedMessageTemplateId =
            values &&
            values.messageTemplates &&
            values.messageTemplates[cardConfig.topic] &&
            values.messageTemplates[cardConfig.topic].messageTemplateId
              ? values.messageTemplates[cardConfig.topic].messageTemplateId
              : null;
          const selectedMessageTemplate =
            selectedMessageTemplateId && topicMessageTemplates
              ? topicMessageTemplates.find(
                  (messageTemplate) => messageTemplate.id === selectedMessageTemplateId
                )
              : null;

          const smsOptions = !topicMessageTemplates
            ? []
            : getTemplateOptionsByMedium(
                MessageMediums.SMS,
                isDefaultAutomation,
                topicMessageTemplates
              );

          const hasDefaultSmsOption = !!smsOptions.find(
            (option) => option.label.toLowerCase() === "default"
          );

          return isDefaultAutomation && smsOptions.length === 0 ? null : (
            <Card>
              <div className={styles.CardTitleRow}>
                <Heading size="S" component="p">
                  {cardConfig.label}
                </Heading>
                <div className={styles.CardToggle}>
                  <Scope scope="excludedTopics">
                    <ToggleInput
                      fieldName={cardConfig.topic}
                      labelChecked="Active"
                      labelUnchecked="Inactive"
                      disabled={formDisabled}
                    />
                  </Scope>
                </div>
              </div>

              <Scope scope={`messageTemplates.${cardConfig.topic}`}>
                {cardConfig.mediums.includes(MessageMediums.SMS) ? (
                  <SelectInput
                    info={
                      selectedMessageTemplate
                        ? selectedMessageTemplate.payloadContent
                        : !hasDefaultSmsOption
                          ? "No default message template exists for this topic. This can be added upon request."
                          : undefined
                    }
                    infoStatus={!hasDefaultSmsOption ? "error" : "info"}
                    fieldName="messageTemplateId"
                    label="Template"
                    labelDetails={
                      selectedMessageTemplate ? (
                        <Button
                          inline
                          className={cx(styles.IconButton, styles.SendTestMessage)}
                          onClick={() => openSendTestModal(selectedMessageTemplate)}
                        >
                          <Send size={18} />
                        </Button>
                      ) : undefined
                    }
                    options={smsOptions}
                    placeholder={
                      !hasDefaultSmsOption ? "No default message template exists" : "Default"
                    }
                    disabled={formDisabled}
                  />
                ) : (
                  <></>
                )}
              </Scope>
            </Card>
          );
        })}
        <PermissionsGuard requiredPermissions={[Permissions.UPDATE_AUTOMATION]}>
          <ButtonRow>
            <Button type="submit" id="saveJourneyResponseMessages" disabled={formDisabled}>
              Save
            </Button>
          </ButtonRow>
        </PermissionsGuard>
      </Drawer>
    </div>
  );
};

export default ResponseMessagesForm;
