import React, { Dispatch, SetStateAction, useEffect } from "react";
import { connect } from "react-redux";
import Heading from "../../../../../../ui/Heading";

import styles from "./index.module.scss";
import { NoteTemplate, ReduxStateType } from "../../../../../../../types";
import Text from "../../../../../../ui/Text";
import { Insights } from "../../../../../../ui/Icon";

type PropsType = {
  addAnchor: (anchorData: {
    anchorName: string;
    anchorTitle: { default: string; loading: string };
  }) => void;
  noteTemplate?: NoteTemplate;
  setHasAnchorSuggestions: Dispatch<SetStateAction<boolean>>;
};

const generateAnchorDisplayName = (anchorName: string) => {
  // Generates a display name by converting insightCategory (a camelCase string) into a Title Case string
  const result = anchorName.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

const insertAnchorTitleIntoLanguageWrapper = (
  anchorTitle: { "en-CA": string; [outputLanguageCode: "fr-CA" | string]: string | undefined },
  outputLanguageCode?: string
) => {
  // Insert the anchor title into a relevant language wrapper text
  if (outputLanguageCode && anchorTitle[outputLanguageCode]) {
    switch (outputLanguageCode) {
      case "fr-CA":
        return {
          default: `Mika ajoutera des suggestions: ${anchorTitle[outputLanguageCode]}`,
          loading: `Mika génère des suggestions: ${anchorTitle[outputLanguageCode]}`
        };
      case "en-CA":
      default:
        // Fall back to en-CA if wrapper for output language does not exist
        return {
          default: `Mika will add ${anchorTitle["en-CA"]} suggestions here`,
          loading: `Mika is generating ${anchorTitle["en-CA"]} suggestions`
        };
    }
  }

  // Fall back to en-CA if scribe model's anchors do not support the output language of note template
  return {
    default: `Mika will add ${anchorTitle["en-CA"]} suggestions here`,
    loading: `Mika is generating ${anchorTitle["en-CA"]} suggestions`
  };
};

const AnchorSuggestions = ({ addAnchor, noteTemplate, setHasAnchorSuggestions }: PropsType) => {
  if (!noteTemplate || !noteTemplate.anchorData) {
    return null;
  }

  useEffect(() => {
    if (noteTemplate.anchorData && noteTemplate.anchorData.length > 0) {
      setHasAnchorSuggestions(true);
    } else {
      setHasAnchorSuggestions(false);
    }
  }, [noteTemplate]);

  return (
    <div className={styles.AnchorSuggestions}>
      <Heading size="META">Available Suggestions</Heading>
      <div className={styles.AnchorButtonsContainer}>
        {noteTemplate.anchorData.map((anchor) => {
          return (
            <button
              key={anchor.anchorName}
              type="button"
              className={styles.AnchorButton}
              onClick={() => {
                addAnchor({
                  anchorName: anchor.anchorName,
                  anchorTitle: insertAnchorTitleIntoLanguageWrapper(
                    anchor.anchorTitle,
                    noteTemplate.outputLanguage
                  )
                });
              }}
            >
              <div className={styles.AnchorButtonIcon}>
                <Insights size={16} />
              </div>
              <Text bold size="XS" className={styles.AnchorButtonText}>
                {generateAnchorDisplayName(anchor.anchorName)}
              </Text>
            </button>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = ({ noteTemplates }: ReduxStateType) => {
  return {
    noteTemplate: noteTemplates.noteTemplate
  };
};

export default connect(mapStateToProps, {})(AnchorSuggestions);
