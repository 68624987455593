import React from "react";

import { useFormApi, useFormState } from "informed";
import Button from "../../../../ui/Button";
import Heading from "../../../../ui/Heading";
import Text from "../../../../ui/Text";
import Tooltip from "../../../../ui/Tooltip";
import { Info, Unlock } from "../../../../ui/Icon";
import CardBanner from "../../../../ui/CardBanner";
import { FormGrid, TextAreaInput, SelectInput, BaseInput } from "../../../../ui/Input";

import { MessageTemplate, Option } from "../../../../../types";
import { BasicDirectMessageFormData } from ".";

import styles from "./index.module.scss";

type PropsType = {
  messageTemplates?: Array<MessageTemplate>;
  closeModal?: () => void;
  messageTemplateOptions: Option[];
  chatOptions: Option[];
  errorMessage: string;
  updateTextMessageCount: (textMessage: string) => void;
  setShowWarning: (value: boolean) => void;
  formDisabled?: boolean;
  textMessageCount: number;
  showWarning: boolean;
  updateError: () => void;
  patientDisplayName: string;
};

const FormContent = ({
  closeModal,
  messageTemplates,
  updateTextMessageCount,
  setShowWarning,
  formDisabled,
  messageTemplateOptions,
  patientDisplayName,
  textMessageCount,
  showWarning,
  updateError,
  chatOptions,
  errorMessage
}: PropsType) => {
  const formState = useFormState();
  const formApi = useFormApi();

  const values = formState.values as BasicDirectMessageFormData;
  return (
    <div>
      <CardBanner className={styles.CardBannerBasic} inputIcon={<Unlock />}>
        Send a basic SMS or Email message to your patient that includes{" "}
        <span className={styles.FontBold}>no personal health information</span>.
      </CardBanner>
      <FormGrid>
        <BaseInput fieldName="patientDisplayName" label="RECIPIENT">
          {patientDisplayName}
        </BaseInput>
        <SelectInput
          fieldName="messageTemplateId"
          label="TEMPLATE"
          options={messageTemplateOptions}
          placeholder="None"
          disabled={formDisabled}
          customOnChange={(event) => {
            const { messageTemplateId } = event;
            if (!messageTemplateId || messageTemplateId === "none") {
              formApi.setValue("payloadContent", " ");
              updateTextMessageCount("");
              setShowWarning(false);
              return;
            }

            const selectedTemplate = messageTemplates?.find(
              (template) => template.id.toString() === messageTemplateId
            );
            const payloadContent = selectedTemplate?.payloadContent;

            if (payloadContent) {
              formApi.setValue("payloadContent", payloadContent);
              updateTextMessageCount(payloadContent);
              setShowWarning(false);
            }
          }}
        />
      </FormGrid>

      <div>
        <div className={styles.FlexEnd}>
          <Heading size="META">MESSAGE</Heading>
          <div className={styles.TextMessageCount}>
            <Text component="span" size="S">
              {`Text message count: ${textMessageCount}`}
            </Text>
            <Tooltip
              icon={
                <div className={styles.Icon}>
                  <Info size={15} />
                </div>
              }
              position="topLeft"
            >
              A standard SMS text message uses a maximum of 160 characters. By adding more than 160
              characters, you will be billed for additional messages. The number of text messages
              you will be billed for can be seen here.
            </Tooltip>
          </div>
        </div>

        <TextAreaInput
          fieldName="payloadContent"
          placeholder="Type your message here"
          size="M"
          warning={showWarning}
          disabled={formDisabled}
          customOnChange={(event) => {
            updateTextMessageCount(event.payloadContent);
            updateError();
            setShowWarning(true);
          }}
        />
        <SelectInput
          fieldName="chatFlowIds"
          label="Chats (optional)"
          options={chatOptions}
          placeholder="None"
          isMulti
          disabled={formDisabled}
        />
        <div className={styles.ErrorMessage}>{errorMessage}</div>
        {!errorMessage && showWarning && (
          <div className={styles.WarningMessage}>
            REMINDER: Never include personal health information in your message.
          </div>
        )}
        <div className={styles.ButtonGroup}>
          <Button inline onClick={closeModal}>
            Cancel
          </Button>
          <Button
            id="sendBasicMessage"
            type="submit"
            disabled={formDisabled || !values.payloadContent?.trim()}
          >
            Send basic message
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FormContent;
