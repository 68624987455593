import React from "react";
import { connect } from "react-redux";

import Button from "../../../ui/Button";
import Heading from "../../../ui/Heading";
import { FloatModal } from "../../../ui/Modal";
import Text from "../../../ui/Text";
import { Form, CheckboxGroup } from "../../../ui/Input";
import { Setting } from "../../../../types";
import { closeModal } from "../../../../actions";

import { isRequired } from "../../../../utils/validators";

import styles from "./index.module.scss";

type ActivateTestModeFormData = {
  confirmed: Array<string>;
};

type PropsType = {
  loading?: boolean;
  closeModalAction: () => void;
  onSuccess: (settings: Array<Setting>) => void;
  settings: Array<Setting>;
};

const initialFormState: ActivateTestModeFormData = {
  confirmed: []
};

const confirmedValidator = isRequired("Confirmation required");

const ActivateTestMode = ({ loading, closeModalAction, onSuccess, settings }: PropsType) => {
  const onActivate = () => {
    onSuccess(settings);
  };

  return (
    <FloatModal isOpen onClose={closeModalAction}>
      <Heading size="L">Activate Test Mode</Heading>
      <Text component="p">
        You are about to activate test mode. This will prevent all outbound messaging. Those
        messages are not queued and will not be sent later. Test mode is intended for verifying
        organization configuration during on-boarding. Is this what you want to do?
      </Text>
      <Form className={styles.Form} onSubmit={onActivate} initialValues={initialFormState}>
        <CheckboxGroup
          fieldName="confirmed"
          options={[{ label: "Yes, I want to activate", value: "true" }]}
          validate={confirmedValidator}
        />
        <div className={styles.ButtonGroup}>
          <Button inline onClick={closeModalAction} disabled={loading}>
            Cancel
          </Button>
          <Button type="submit">Activate</Button>
        </div>
      </Form>
    </FloatModal>
  );
};
const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { closeModalAction: closeModal })(ActivateTestMode);
