import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";

import {
  updateReasons as updateReasonsAction,
  fetchReasons as fetchReasonsAction,
  UpdateReasonsData,
  openModal as openModalAction,
  OpenModal
} from "../../../../actions";
import { ToggleInput, Form, SelectInput } from "../../../ui/Input";
import TableGrid from "../../../ui/TableGrid";
import Search from "../../../ui/Search";
import Loader from "../../../ui/Loader";
import Text from "../../../ui/Text";

import useSearchFilter from "../../../../hooks/useSearchFilter";

import { ReduxStateType, Permissions, Reason } from "../../../../types";

import styles from "./index.module.scss";
import { usePermissions } from "../../../../hooks/usePermissions";
import Button from "../../../ui/Button";
import { AdminModalTypes } from "../../../../constants";

type PropsType = {
  reasons: Array<Reason>;
  reasonsLoading: boolean;
  updateError: boolean;
  fetchReasons: () => void;
  updateReasons: (data: UpdateReasonsData) => void;
  openModal: OpenModal;
};

const headers = [
  { colName: "id", content: "ID" },
  { colName: "value", content: "Name" },
  { colName: "checklistDisplayName", content: "Instructions" },
  { colName: "emrReasonId", content: "EMR ID" },
  { colName: "status", content: "Status" }
];

const ReasonsTable = ({
  reasons,
  reasonsLoading,
  updateError,
  fetchReasons,
  updateReasons,
  openModal
}: PropsType) => {
  const hasUpdateReasonPermission = usePermissions([Permissions.UPDATE_REASONS]);
  const hasCreateReasonsPermission = usePermissions([Permissions.CREATE_REASONS]);
  const [statusFilter, setStatusFilter] = useState<boolean | null>(null);
  const statusFilteredRows = useMemo(() => {
    return reasons.filter((row) => {
      return statusFilter !== null ? row.active === statusFilter : true;
    });
  }, [reasons, statusFilter]);

  const { filteredRows, onSearchChange, onSearchClear } = useSearchFilter<Reason>(
    statusFilteredRows,
    (search: string, reason: Reason) => {
      return !!(
        (reason.value && reason.value.toLowerCase().includes(search.toLowerCase())) ||
        (reason.emrReasonId && reason.emrReasonId.toLowerCase().includes(search.toLowerCase())) ||
        (reason.checklistDisplayName && reason.checklistDisplayName.includes(search.toLowerCase()))
      );
    }
  );

  // Fetch reasons on first load
  useEffect(() => {
    fetchReasons();
  }, []);

  const reasonRows = filteredRows.map((reason) => {
    const fieldName = `reason-${reason.id}`;
    return {
      ...reason,
      checklistDisplayName: (
        <div className={styles.TableCell}>
          <Text bold align="left" component="span" size="S">
            {reason.checklistDisplayName || ""}
          </Text>
          <br />
          <Text bold align="left" component="span" size="S">
            {reason.checklistDescription || ""}
          </Text>
        </div>
      ),
      // eslint-disable-next-line react/display-name
      status: (
        <Form
          key={`${reason.id}-${reason.active}-${updateError}`}
          initialValues={{ [fieldName]: reason.active }}
        >
          <ToggleInput
            key={`${reason.id}`}
            fieldName={fieldName}
            labelChecked="Active"
            labelUnchecked="Inactive"
            disabled={!hasUpdateReasonPermission}
            customOnChange={(value) => {
              updateReasons({ updates: [{ id: reason.id, active: Object.values(value)[0] }] });
            }}
          />
        </Form>
      )
    };
  });

  if (reasonsLoading) {
    return <Loader screen />;
  }

  // Re-render the form if the update error state changes. this aligns the form state with the latest org reasons data.
  return (
    <>
      <div className={styles.FilterRow}>
        <Form initialValues={{ statusFilter: "" }}>
          <div className={styles.Filters}>
            <div className={styles.StatusFilter}>
              <SelectInput
                fieldName="statusFilter"
                options={[
                  { label: "All statuses", value: "" },
                  { label: "Active", value: "true" },
                  { label: "Inactive", value: "false" }
                ]}
                placeholder="All statuses"
                customOnChange={(event) => {
                  return event.statusFilter === ""
                    ? setStatusFilter(null)
                    : setStatusFilter(JSON.parse(event.statusFilter));
                }}
              />
            </div>
            <Search
              id="searchReason"
              placeholder="Search a reason"
              classNames={styles.Search}
              onChange={onSearchChange}
              onClear={onSearchClear}
            />
          </div>
        </Form>
        {hasCreateReasonsPermission && (
          <Button
            onClick={() => {
              openModal(AdminModalTypes.ADD_REASONS);
            }}
          >
            + Add Reasons
          </Button>
        )}
      </div>

      <TableGrid
        id="reasonsTable"
        headers={headers}
        rows={reasonRows}
        maxPageRows={15}
        showRowFocus
      />
    </>
  );
};

const mapStateToProps = ({ reasons }: ReduxStateType) => {
  return {
    reasons: reasons.data,
    reasonsLoading: !reasons.initialized,
    updateError: reasons.updateError
  };
};

export default connect(mapStateToProps, {
  fetchReasons: fetchReasonsAction,
  updateReasons: updateReasonsAction,
  openModal: openModalAction
})(ReasonsTable);
