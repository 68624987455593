import React, { useState } from "react";
import cx from "classnames";
import { connect } from "react-redux";

import Text from "../../../../../../ui/Text";
import { ChevronDown, DoubleChevronDown, Plus } from "../../../../../../ui/Icon";
import { AnnouncementTooltip } from "../../../../../../ui/Announcements";
import { ResponsiveHide, ResponsiveMobileDrawer } from "../../../../../../ui/Responsive";
import Button from "../../../../../../ui/Button";

import NoteTab from "./NoteTab";

import { openModal as openModalAction, OpenModal } from "../../../../../../../actions";

import { ModalTypes } from "../../../../../../../constants";
import {
  AnnouncementName,
  Appointment,
  AppointmentDetail,
  Note,
  RecordingSessionStatus,
  RecordingState
} from "../../../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  notes: Note[];
  recording: RecordingState | null;
  setActiveAppointmentNoteId: (noteId: number) => void;
  activeAppointmentNoteId: number | null;
  appointmentId?: string;
  appointmentData?: Appointment & AppointmentDetail;
  openModal: OpenModal;
};

const NoteTabs = ({
  notes,
  recording,
  setActiveAppointmentNoteId,
  activeAppointmentNoteId,
  appointmentId,
  appointmentData,
  openModal
}: PropsType) => {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen((isOpen) => !isOpen);
  };

  const showActiveOrCompleteSessionTutorials =
    recording?.status === RecordingSessionStatus.RECORDING ||
    recording?.status === RecordingSessionStatus.PAUSED ||
    recording?.status === RecordingSessionStatus.COMPLETE;
  const activeNote = notes.find((note) => note.id === activeAppointmentNoteId);

  return (
    <div className={styles.TabsWrapper}>
      <div className={styles.TabsGroup}>
        {notes && (
          <div className={cx(styles.DropdownWrapper)}>
            <button
              type="button"
              className={cx(styles.DropdownButton)}
              onClick={() => {
                toggle();
              }}
            >
              {activeNote && (
                <>
                  <div
                    className={cx(styles.DropdownButtonIcon, {
                      [styles.DropdownButtonIconOpen]: open
                    })}
                  >
                    <DoubleChevronDown size={16} />
                  </div>
                  <div
                    className={cx(styles.DropdownButtonMobile, {
                      [styles.DropdownButtonMobileOpen]: open
                    })}
                  >
                    <Text className={cx(styles.DropdownButtonMobileText)}>{activeNote?.title}</Text>
                    <ChevronDown size={16} />
                  </div>
                </>
              )}
            </button>
            <ResponsiveHide hideOnMobile>
              <div className={cx(styles.DropdownList, { [styles.DropdownListOpen]: open })}>
                {notes.map((overFlowNote) => (
                  <NoteTab
                    key={`noteTab-${overFlowNote.id}`}
                    customTabWrapperClass={styles.DropdownTabWrapper}
                    note={overFlowNote}
                    isActive={activeAppointmentNoteId === overFlowNote.id}
                    setActiveAppointmentNoteId={setActiveAppointmentNoteId}
                    onTabSwitch={toggle}
                  />
                ))}
                <Button
                  inline
                  className={styles.TabAddDropdown}
                  onClick={() => {
                    if (appointmentData && appointmentId) {
                      openModal(ModalTypes.INITIALIZE_NOTE, {
                        patientId: appointmentData.patientId,
                        appointmentId: parseInt(appointmentId, 10),
                        setActiveAppointmentNoteId: (noteId: number) => {
                          setActiveAppointmentNoteId(noteId);
                        }
                      });
                    }
                    toggle();
                  }}
                >
                  <Text className={styles.AddBtnTabTitleDropDown} size="S">
                    <Plus size={16} />
                    Add new
                  </Text>
                </Button>
              </div>
            </ResponsiveHide>
            <ResponsiveHide hideOnTablet hideOnDesktop>
              <ResponsiveMobileDrawer
                header="Notes"
                isOpen={open}
                closeMobileDrawer={() => setOpen(false)}
              >
                {notes.map((overFlowNote) => (
                  <NoteTab
                    key={`noteTab-${overFlowNote.id}`}
                    note={overFlowNote}
                    isActive={activeAppointmentNoteId === overFlowNote.id}
                    setActiveAppointmentNoteId={setActiveAppointmentNoteId}
                    onTabSwitch={toggle}
                  />
                ))}
                <button
                  type="button"
                  className={styles.TabAddMobile}
                  onClick={() => {
                    if (appointmentData && appointmentId) {
                      openModal(ModalTypes.INITIALIZE_NOTE, {
                        patientId: appointmentData.patientId,
                        appointmentId: parseInt(appointmentId, 10),
                        setActiveAppointmentNoteId: (noteId: number) => {
                          setActiveAppointmentNoteId(noteId);
                        }
                      });
                    }
                  }}
                >
                  <Text className={styles.TabTitleMobile} size="S">
                    <Plus size={16} />
                    Add new
                  </Text>
                </button>
              </ResponsiveMobileDrawer>
            </ResponsiveHide>
          </div>
        )}
        <AnnouncementTooltip
          arrowPosition="topLeftAbove"
          announcementTooltipClassName={styles.announcementTooltipClassName}
          announcementName={AnnouncementName.SCRIBE_MULTI_TEMPLATES}
          announcementShowable={showActiveOrCompleteSessionTutorials}
        >
          <div className={styles.Tabs}>
            {notes.map((note) => (
              <NoteTab
                key={`noteTab-${note.id}`}
                note={note}
                isActive={activeAppointmentNoteId === note.id}
                setActiveAppointmentNoteId={setActiveAppointmentNoteId}
              />
            ))}
          </div>
          <Button
            inline
            aria-label="Add Note"
            className={styles.TabAdd}
            onClick={() => {
              if (appointmentData && appointmentId) {
                openModal(ModalTypes.INITIALIZE_NOTE, {
                  patientId: appointmentData.patientId,
                  appointmentId: parseInt(appointmentId, 10),
                  setActiveAppointmentNoteId: (noteId: number) => {
                    setActiveAppointmentNoteId(noteId);
                  }
                });
              }
            }}
          >
            <Text bold className={styles.TabTitle} size="S">
              <Plus size={16} />
              Add new
            </Text>
          </Button>
        </AnnouncementTooltip>
      </div>
    </div>
  );
};

export default connect(null, {
  openModal: openModalAction
})(NoteTabs);
