import React from "react";

type PropsType = {
  size?: number;
};

const HeartBlank = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="heart">
        <path
          id="Subtract"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.173 4.74033C12.1155 4.79965 12.0575 4.86539 12 4.93473C11.9425 4.86539 11.8845 4.79965 11.827 4.74033C10.7513 3.62992 9.28542 3 7.75 3C6.21458 3 4.74871 3.62992 3.673 4.74033C2.59834 5.84966 2 7.34766 2 8.90323C2 13.0798 4.5142 16.1094 6.86721 18.0323C8.05398 19.0022 9.23562 19.7216 10.1186 20.1984C10.5612 20.4375 10.9319 20.6172 11.1947 20.7383L11.6267 20.9277C11.8658 21.0237 12.1342 21.0237 12.3733 20.9277L12.8053 20.7383C13.0681 20.6172 13.4388 20.4375 13.8814 20.1984C14.7644 19.7216 15.946 19.0022 17.1328 18.0323C19.4858 16.1094 22 13.0798 22 8.90323C22 7.34766 21.4017 5.84966 20.327 4.74033C19.2513 3.62992 17.7854 3 16.25 3C14.7146 3 13.2487 3.62992 12.173 4.74033ZM5.10948 6.13192C5.81537 5.40326 6.76587 5 7.75 5C8.73413 5 9.68463 5.40326 10.3905 6.13192C10.4984 6.24323 10.6748 6.48753 10.8532 6.76164L11.1366 7.21802C11.3159 7.52485 11.6446 7.71347 12 7.71347C12.3554 7.71347 12.6841 7.52476 12.8634 7.21792L13.1468 6.76164C13.3252 6.48753 13.5016 6.24323 13.6095 6.13192C14.3154 5.40326 15.2659 5 16.25 5C17.2341 5 18.1846 5.40326 18.8905 6.13192C19.5974 6.86165 20 7.85796 20 8.90323C20 12.2105 18.0142 14.7292 15.8672 16.4837C14.804 17.3526 13.7356 18.0041 12.9311 18.4386C12.5482 18.6454 12.2278 18.8015 12 18.9072C11.7722 18.8015 11.4518 18.6454 11.0689 18.4386C10.2644 18.0041 9.19602 17.3526 8.13278 16.4837C5.9858 14.7292 4 12.2105 4 8.90322C4 7.85796 4.40255 6.86165 5.10948 6.13192Z"
          fill="#94979E"
        />
      </g>
    </svg>
  );
};

export default HeartBlank;
