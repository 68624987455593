import axios from "axios";

import getToken from "../utils/getToken";

import {
  addNotification,
  invalidAutomationAssociationsNotification,
  InvalidAutomationAssociation
} from "./notifications";
import { closeModal } from "./modals";

import {
  ROOT_URL,
  FETCH_MESSAGE_TEMPLATES,
  UPDATE_MESSAGE_TEMPLATE,
  ADD_MESSAGE_TEMPLATE,
  DELETE_MESSAGE_TEMPLATE,
  SEND_MESSAGE_TEMPLATE,
  FETCH_MESSAGE_TEMPLATE_DETAIL
} from "../constants";
import {
  AutomationJourneyTestData,
  MessageTemplateUpdateData,
  MessageTemplateAddData,
  Dispatch,
  ActionStatus,
  MessageTemplate,
  ChatFlow
} from "../types";

export type FetchMessageTemplateDetailAction = {
  type: typeof FETCH_MESSAGE_TEMPLATE_DETAIL;
  status: ActionStatus;
  payload?: { messageTemplate: MessageTemplate | null; availableChats: Array<ChatFlow> };
};
type FetchMessageTemplateDetailResponse = {
  status: number;
  data: {
    success: boolean;
    messageTemplate: MessageTemplate | null;
    availableChats: Array<ChatFlow>;
    hasInvalidChatAssociation: boolean;
  };
};

export const fetchMessageTemplateDetail = (messageTemplateId: number) => (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  const handleError = () => {
    dispatch(
      addNotification({
        type: "error",
        title: "Failed to fetch message details",
        subtitle: "Please try again",
        autoDismiss: true
      })
    );

    return dispatch({
      type: FETCH_MESSAGE_TEMPLATE_DETAIL,
      status: ActionStatus.error
    });
  };

  dispatch({
    type: FETCH_MESSAGE_TEMPLATE_DETAIL,
    status: ActionStatus.loading
  });

  return axios
    .get(`${ROOT_URL}/messages/templates/${messageTemplateId}`, config)
    .then((response: FetchMessageTemplateDetailResponse) => {
      if (!response.data || !response.data.success) {
        return handleError();
      }

      if (response.data.hasInvalidChatAssociation) {
        dispatch(
          addNotification({
            type: "error",
            title: "Associated chat is no longer available",
            subtitle: "Please update or remove the message templates associated chat",
            autoDismiss: true
          })
        );
      }

      return dispatch({
        type: FETCH_MESSAGE_TEMPLATE_DETAIL,
        status: ActionStatus.success,
        payload: response.data
      });
    })
    .catch(() => {
      return handleError();
    });
};

export type FetchMessageTemplateAction = {
  type: typeof FETCH_MESSAGE_TEMPLATES;
  status?: ActionStatus;
  payload?: { data: Array<MessageTemplate> };
};

export type FetchMessageTemplateFilters = {
  topic?: string;
};

export const fetchMessageTemplates =
  (filters?: FetchMessageTemplateFilters) => (dispatch: Dispatch) => {
    const token = getToken();

    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" },
      params: filters || undefined
    };
    dispatch({
      type: FETCH_MESSAGE_TEMPLATES,
      status: ActionStatus.loading
    });

    return axios
      .get(`${ROOT_URL}/messages/templates/find`, config)
      .then((payload) => {
        return dispatch({
          type: FETCH_MESSAGE_TEMPLATES,
          payload,
          status: ActionStatus.success
        });
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to fetch message templates",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
      });
  };

export type UpdateMessageTemplateAction = {
  type: typeof UPDATE_MESSAGE_TEMPLATE;
  status?: ActionStatus;
  payload?: { messageTemplate: MessageTemplate };
};

type UpdateMessageTemplateResponse = {
  status: number;
  data: {
    error?: boolean;
    messageTemplate?: MessageTemplate;
    invalidAssociations?: Array<InvalidAutomationAssociation>;
  };
};

export const updateMessageTemplate =
  (id: number, data: MessageTemplateUpdateData) => (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: UPDATE_MESSAGE_TEMPLATE,
      status: ActionStatus.loading
    });

    return axios
      .patch(`${ROOT_URL}/messages/templates/${id}`, data, config)
      .then((payload: UpdateMessageTemplateResponse) => {
        if (!payload || payload.data.error) {
          dispatch({
            type: UPDATE_MESSAGE_TEMPLATE,
            status: ActionStatus.error
          });

          if (
            payload &&
            payload.data &&
            payload.data.invalidAssociations &&
            payload.data.invalidAssociations.length > 0
          ) {
            return dispatch(
              invalidAutomationAssociationsNotification(payload.data.invalidAssociations)
            );
          }

          return dispatch(
            addNotification({
              type: "error",
              title: "Failed to save the changes to the message template",
              subtitle: "Please try again",
              autoDismiss: true
            })
          );
        }
        closeModal()(dispatch);
        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: "Message template saved",
            autoDismiss: true
          })
        );
        return dispatch({
          type: UPDATE_MESSAGE_TEMPLATE,
          status: ActionStatus.success,
          payload: payload.data.messageTemplate
            ? { messageTemplate: payload.data.messageTemplate }
            : undefined
        });
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to save the changes to the message template",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );

        return dispatch({
          type: UPDATE_MESSAGE_TEMPLATE,
          status: ActionStatus.error
        });
      });
  };

export type AddMessageTemplateAction = {
  type: typeof ADD_MESSAGE_TEMPLATE;
  status?: ActionStatus;
  payload?: { data: MessageTemplate };
};

export type addMessageTemplateOptions = {
  onSuccess?: (templateId: number) => void;
};

type AddMessageTemplateResponse = {
  status: number;
  data: MessageTemplate;
};

export const addMessageTemplate =
  (data: MessageTemplateAddData, options?: addMessageTemplateOptions) => (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    const { onSuccess } = options || {};

    const handleFailure = () => {
      dispatch(
        addNotification({
          type: "error",
          title: "Failed to create message template",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );

      dispatch({
        type: ADD_MESSAGE_TEMPLATE,
        status: ActionStatus.error
      });
    };

    dispatch({
      type: ADD_MESSAGE_TEMPLATE,
      status: ActionStatus.loading
    });

    return axios
      .post(`${ROOT_URL}/messages/templates`, data, config)
      .then((payload: AddMessageTemplateResponse) => {
        if (!payload || !payload.data.id) {
          handleFailure();
          return;
        }
        if (onSuccess) {
          onSuccess(payload.data.id);
        }
        closeModal()(dispatch);
        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: "Message template created",
            autoDismiss: true
          })
        );
        dispatch({
          type: ADD_MESSAGE_TEMPLATE,
          status: ActionStatus.success
        });

        fetchMessageTemplates()(dispatch);
      })
      .catch(() => {
        return handleFailure();
      });
  };

export type MessageTemplateDeleteData = {
  id: number;
  onSuccess?: () => void;
};

export type DeleteMessageTemplateAction = {
  type: typeof DELETE_MESSAGE_TEMPLATE;
  status?: ActionStatus;
  payload?: { data: MessageTemplate };
};

type DeleteMessageTemplateResponse = {
  status: number;
  data: {
    error?: boolean;
  };
};

export const deleteMessageTemplate = (data: MessageTemplateDeleteData) => (dispatch: Dispatch) => {
  const { id, onSuccess } = data;
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  const handleFailure = () => {
    dispatch(
      addNotification({
        type: "error",
        title: "Failed to delete message template",
        subtitle: "Please try again",
        autoDismiss: true
      })
    );

    dispatch({
      type: DELETE_MESSAGE_TEMPLATE,
      status: ActionStatus.error
    });
  };

  dispatch({
    type: DELETE_MESSAGE_TEMPLATE,
    status: ActionStatus.loading
  });

  return axios
    .delete(`${ROOT_URL}/messages/templates/${id}`, config)
    .then((payload: DeleteMessageTemplateResponse) => {
      if (!payload || payload.data.error) {
        handleFailure();
        return;
      }
      closeModal()(dispatch);

      if (onSuccess) {
        onSuccess();
      }

      dispatch(
        addNotification({
          type: "success",
          title: "Success",
          subtitle: "Message template deleted",
          autoDismiss: true
        })
      );
      dispatch({
        type: DELETE_MESSAGE_TEMPLATE,
        status: ActionStatus.success
      });

      fetchMessageTemplates()(dispatch);
    })
    .catch(() => {
      return handleFailure();
    });
};

export type MessageTemplateSendData = {
  messageTemplateId?: number;
  testChatFlowId?: number;
  messageConfigurationId?: number;
  automationJourneyData?: AutomationJourneyTestData;
  phoneNumbers: string[];
  emails: string[];
};

export type SendMessageTemplateAction = {
  type: typeof SEND_MESSAGE_TEMPLATE;
  status?: ActionStatus;
};

type SendMessageTemplateResponse = {
  status: number;
  data: {
    success: boolean;
    errorMessage?: string;
  };
};

export const sendMessageTemplate = (data: MessageTemplateSendData) => (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  const handleFailure = (errorMessage?: string) => {
    dispatch(
      addNotification({
        type: "error",
        title: `Failed to send test message template`,
        subtitle: errorMessage || "Please try again",
        autoDismiss: true
      })
    );

    dispatch({
      type: SEND_MESSAGE_TEMPLATE,
      status: ActionStatus.error
    });
  };

  dispatch({
    type: SEND_MESSAGE_TEMPLATE,
    status: ActionStatus.loading
  });

  return axios
    .post(`${ROOT_URL}/messages/templates/send`, data, config)
    .then((payload: SendMessageTemplateResponse) => {
      if (!payload || !payload.data.success) {
        handleFailure(payload.data.errorMessage);
        return;
      }
      closeModal()(dispatch);

      dispatch(
        addNotification({
          type: "success",
          title: "Success",
          subtitle: "Message template sent",
          autoDismiss: true
        })
      );
      dispatch({
        type: SEND_MESSAGE_TEMPLATE,
        status: ActionStatus.success
      });
    })
    .catch(() => {
      return handleFailure();
    });
};
