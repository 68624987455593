import axios from "axios";

import getToken from "../utils/getToken";

import { addNotification } from "./notifications";

import {
  ROOT_URL,
  FETCH_BILLING_CONFIG,
  FETCH_ALL_BILLING_CUSTOMERS,
  CREATE_CHARGEOVER_SUBSCRIPTION,
  UPDATE_PAYMENT_METHOD,
  FETCH_ORGANIZATION_SUBSCRIPTIONS,
  FETCH_PRODUCT_LIST
} from "../constants";
import {
  ActionStatus,
  Dispatch,
  Setting,
  Invoice,
  BillingCustomer,
  BillingSubscription,
  ChargeOverProductPlan
} from "../types";

export const backfillUsage = (organizationId: number) => async (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: `${token}`, "Content-Type": "application/json" }
  };
  try {
    await axios.post(
      `${ROOT_URL}/organizations/${organizationId}/billing/backfill`,
      { organizationId },
      config
    );

    dispatch(
      addNotification({
        type: "success",
        title: "Success",
        subtitle: "Success. The backfill has started.",
        autoDismiss: true
      })
    );
  } catch (e) {
    dispatch(
      addNotification({
        type: "error",
        title: "Failed to start backfill",
        subtitle: "Please try again",
        autoDismiss: true
      })
    );
  }
};

export type BillingActionType = { setting: Setting; parentBillingConfig: Setting | undefined };

export type LineItemsType = { [lineItems: string]: { [lineItemName: string]: number }[] };

export type FetchBillingConfigurationAction = {
  type: typeof FETCH_BILLING_CONFIG;
  status?: ActionStatus;
  payload?: BillingActionType;
};

export const fetchBillingConfiguration = (organizationId: string) => async (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  dispatch({
    type: FETCH_BILLING_CONFIG,
    status: ActionStatus.loading
  });

  try {
    const response = await axios.get(
      `${ROOT_URL}/organizations/${organizationId}/billing/config`,
      config
    );
    dispatch({
      type: FETCH_BILLING_CONFIG,
      status: ActionStatus.success,
      payload: response.data
    });
  } catch (e) {
    dispatch(
      addNotification({
        type: "error",
        title: "Error fetching organization billing configuration",
        subtitle: "Please try again",
        autoDismiss: true
      })
    );

    dispatch({
      type: FETCH_BILLING_CONFIG,
      status: ActionStatus.error
    });
  }
};

export type FetchAllBillingCustomersAction = {
  type: typeof FETCH_ALL_BILLING_CUSTOMERS;
  status?: ActionStatus;
  payload?: BillingCustomer[];
};

export const fetchAllBillingCustomers = (organizationId: string) => async (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  dispatch({
    type: FETCH_ALL_BILLING_CUSTOMERS,
    status: ActionStatus.loading
  });

  try {
    const response = await axios.post(
      `${ROOT_URL}/organizations/${organizationId}/billing/proxy`,
      { request: "customerList" },
      config
    );
    const { data } = response;
    dispatch({
      type: FETCH_ALL_BILLING_CUSTOMERS,
      status: ActionStatus.success,
      payload: data
    });
  } catch (e) {
    return e as string;
  }
};

export type FetchOrganizationSubscriptionsAction = {
  type: typeof FETCH_ORGANIZATION_SUBSCRIPTIONS;
  status?: ActionStatus;
  payload?: BillingSubscription[];
};

export const fetchOrganizationSubscriptions =
  (organizationId: string, customerId: string) => async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: FETCH_ORGANIZATION_SUBSCRIPTIONS,
      status: ActionStatus.loading
    });

    try {
      const response = await axios.post(
        `${ROOT_URL}/organizations/${organizationId}/billing/proxy`,
        { request: "subscriptions", customerId },
        config
      );
      const { data } = response;
      dispatch({
        type: FETCH_ORGANIZATION_SUBSCRIPTIONS,
        status: ActionStatus.success,
        payload: data
      });
    } catch (e) {
      return e as string;
    }
  };

export type CreateChargeOverSubscriptionAction = {
  type: typeof CREATE_CHARGEOVER_SUBSCRIPTION;
  status?: ActionStatus;
  payload?: { billingConfig: Setting; subscriptionId: number; proratedInvoice: Invoice };
};

export type CreateChargeOverSubscriptionData = {
  productKey: string;
  address: string;
  suite?: string;
  city: string;
  provinceState: string;
  country: string;
  postalZipCode: string;
};

export const createChargeOverSubscription =
  (organizationId: string, data: CreateChargeOverSubscriptionData, onError?: () => void) =>
  async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: CREATE_CHARGEOVER_SUBSCRIPTION,
      status: ActionStatus.loading
    });

    return axios
      .post(`${ROOT_URL}/organizations/${organizationId}/billing/signup`, data, config)
      .then((payload) => {
        if (!payload || !payload.data) {
          if (onError) {
            onError();
          }

          dispatch({
            type: CREATE_CHARGEOVER_SUBSCRIPTION,
            status: ActionStatus.error
          });

          return dispatch(
            addNotification({
              type: "error",
              title: `Failed to create ChargeOver subscription and customer.`,
              subtitle: "Please try again. If this persists, contact us.",
              autoDismiss: false
            })
          );
        }
        if (payload.data) {
          dispatch({
            type: CREATE_CHARGEOVER_SUBSCRIPTION,
            status: ActionStatus.success,
            payload: payload.data
          });
        }
      })
      .catch(() => {
        if (onError) {
          onError();
        }

        dispatch(
          addNotification({
            type: "error",
            title: `Failed to create ChargeOver subscription and customer.`,
            subtitle: "Please try again. If this persists, contact us.",
            autoDismiss: false
          })
        );

        dispatch({
          type: CREATE_CHARGEOVER_SUBSCRIPTION,
          status: ActionStatus.error
        });
      });
  };

export type UpdatePaymentMethodAction = {
  type: typeof UPDATE_PAYMENT_METHOD;
  status?: ActionStatus;
  payload?: {
    success: boolean;
    subscriptionId: number;
    invoiceId: number;
    paymentSuccess: boolean;
    transactionId: number;
    paymentAmount: string;
  };
};

export type UpdatePaymentMethodData = {
  subscriptionId?: number;
  creditCardToken?: string;
  invoiceId?: string;
};

export const updatePaymentMethod =
  (organizationId: string, data: UpdatePaymentMethodData, onError?: () => void) =>
  async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: UPDATE_PAYMENT_METHOD,
      status: ActionStatus.loading
    });

    return axios
      .post(
        `${ROOT_URL}/organizations/${organizationId}/billing/update-payment-method`,
        data,
        config
      )
      .then((payload) => {
        if (!payload || !payload.data) {
          if (onError) {
            onError();
          }

          dispatch({
            type: UPDATE_PAYMENT_METHOD,
            status: ActionStatus.error
          });

          return dispatch(
            addNotification({
              type: "error",
              title: `Failed to update subscription payment method and pay invoice.`,
              subtitle: "Please try again. If this persists, contact us.",
              autoDismiss: false
            })
          );
        }
        if (payload.data) {
          let fullSuccess = payload.data.success;
          if (payload.data.paymentSuccess !== undefined) {
            fullSuccess = fullSuccess && payload.data.paymentSuccess;
          }
          if (fullSuccess) {
            dispatch({
              type: UPDATE_PAYMENT_METHOD,
              status: ActionStatus.success,
              payload: payload.data
            });

            return dispatch(
              addNotification({
                type: "success",
                title: `Thanks for your subscription!`,
                subtitle:
                  "You have successfully subscribed. We will email a copy of your receipt to your email address. Plans renew automatically until you cancel. To make changes to your subscription, contact us.",
                autoDismiss: false
              })
            );
          }
          if (onError) {
            onError();
          }

          dispatch(
            addNotification({
              type: "error",
              title: `Failed to update subscription payment method and pay invoice.`,
              subtitle: "Please try again. If this persists, contact us.",
              autoDismiss: false
            })
          );

          dispatch({
            type: UPDATE_PAYMENT_METHOD,
            status: ActionStatus.error
          });
        }
      })
      .catch(() => {
        if (onError) {
          onError();
        }

        dispatch(
          addNotification({
            type: "error",
            title: `Failed to update subscription payment method and pay invoice.`,
            subtitle: "Please try again. If this persists, contact us.",
            autoDismiss: false
          })
        );

        dispatch({
          type: UPDATE_PAYMENT_METHOD,
          status: ActionStatus.error
        });
      });
  };

export type FetchProductListAction = {
  type: typeof FETCH_PRODUCT_LIST;
  status?: ActionStatus;
  payload?: ChargeOverProductPlan[];
};

export const fetchProductList = (organizationId: string) => async (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  dispatch({
    type: FETCH_PRODUCT_LIST,
    status: ActionStatus.loading
  });

  try {
    const response = await axios.post(
      `${ROOT_URL}/organizations/${organizationId}/billing/proxy`,
      { request: "products" },
      config
    );
    const { data } = response;
    dispatch({
      type: FETCH_PRODUCT_LIST,
      status: ActionStatus.success,
      payload: data
    });
  } catch (e) {
    return e as string;
  }
};
