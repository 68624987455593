import axios from "axios";

import { jwtDecode } from "jwt-decode";
import getToken from "../utils/getToken";

import {
  ADD_FEEDBACK,
  CLEAR_NOTE_FROM_QUEUE,
  QUEUE_NOTE_FOR_FEEDBACK,
  ROOT_URL,
  SET_FEEDBACK_MODE
} from "../constants";
import { ActionStatus, AddFeedbackData, Dispatch, SetFeedbackModeData } from "../types";
import { FeedbackFormModes } from "../typedConstants";

export type AddFeedbackAction = {
  type: typeof ADD_FEEDBACK;
  status?: ActionStatus;
};

export const addFeedback = (data: AddFeedbackData) => async (dispatch: Dispatch) => {
  const token = getToken();
  const decodedToken: { userId: string } | null = token ? jwtDecode(token) : null;
  const currentUserId = decodedToken?.userId;
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  const payloadData = {
    ...data,
    context: {
      ...data.context,
      userId: currentUserId
    }
  };

  return axios
    .post(`${ROOT_URL}/users/feedback`, payloadData, config)
    .then((payload) => {
      if (!payload.data.success) {
        dispatch({ type: ADD_FEEDBACK, status: ActionStatus.error });
      }

      dispatch({
        type: ADD_FEEDBACK,
        status: ActionStatus.success
      });
    })
    .catch(() => {
      dispatch({ type: ADD_FEEDBACK, status: ActionStatus.error });
    });
};

export type SetFeedbackModeAction = {
  type: typeof SET_FEEDBACK_MODE;
  payload?: FeedbackFormModes | null;
};

export const setFeedbackMode = (data: SetFeedbackModeData) => async (dispatch: Dispatch) => {
  dispatch({
    type: SET_FEEDBACK_MODE,
    payload: data?.feedbackMode
  });
};

export type QueueNoteForFeedbackAction = {
  type: typeof QUEUE_NOTE_FOR_FEEDBACK;
  payload: { noteId: number };
};

export const queueNoteForFeedback = (noteId: number) => async (dispatch: Dispatch) => {
  dispatch({
    type: QUEUE_NOTE_FOR_FEEDBACK,
    payload: { noteId }
  });
};

export type ClearNoteFromQueueAction = {
  type: typeof CLEAR_NOTE_FROM_QUEUE;
  payload: { noteId: number };
};

export const clearNoteFromQueue = (noteId: number) => async (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_NOTE_FROM_QUEUE,
    payload: { noteId }
  });
};
