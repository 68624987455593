import React from "react";
import { useField } from "informed";

import BaseInput from "../BaseInput";
import CheckboxInputBase from "./CheckboxInputBase";

import { CustomOnChangeHandler, Validate } from "../../../../types";

type PropsType = {
  fieldName: string;
  id?: string;
  label?: string | React.ReactElement;
  info?: React.ReactNode;
  infoStatus?: "info" | "warning" | "error";
  placeholder?: string;
  disabled?: boolean;
  warningMessage?: string;
  showField?: boolean;
  validate?: Validate;
  customOnChange?: CustomOnChangeHandler;
  labelClickable?: boolean;
};

const CheckboxInput = ({
  fieldName,
  id,
  label,
  info,
  infoStatus,
  disabled = false,
  warningMessage = "",
  showField = true,
  validate,
  customOnChange,
  labelClickable,
  ...props
}: PropsType): React.ReactElement => {
  const { fieldState, fieldApi } = useField({
    ...props,
    validate,
    name: fieldName
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const value = fieldState.value as any;
  const error = fieldState.error as string | undefined;
  const { setValue, setTouched, validate: validateInput } = fieldApi;

  const updateFormState = (newValue: boolean) => {
    setValue(newValue);
    setTouched(true);
    validateInput();
  };

  const onChangeHandler = (checked: boolean): void => {
    updateFormState(checked);

    if (customOnChange) {
      customOnChange({ [fieldName]: checked });
    }
  };

  return (
    <BaseInput
      fieldName={fieldName}
      error={error}
      info={info}
      showField={showField}
      infoStatus={infoStatus}
      warningMessage={warningMessage}
    >
      <CheckboxInputBase
        fieldName={fieldName}
        id={id}
        label={label}
        initialValue={value}
        disabled={disabled}
        onChange={onChangeHandler}
        labelClickable={labelClickable}
      />
    </BaseInput>
  );
};

export default CheckboxInput;
