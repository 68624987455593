import React, { useEffect, useContext } from "react";
import { connect } from "react-redux";

import { UserContext } from "./UserProvider";
import {
  clearCurrentOrgData as clearCurrentOrgDataAction,
  fetchOrganization as fetchOrganizationAction
} from "../../actions";

import { OrganizationData, OrganizationEmrFeatures, ReduxStateType } from "../../types";

type PropsType = {
  organization?: OrganizationData;
  fetchOrganization: (organizationId: string) => void;
  clearCurrentOrgData: () => void;
  children: React.ReactNode;
};

export type OrganizationContextType = (OrganizationData & { hasQuickStart: boolean }) | null;

const initialOrganizationContext: OrganizationContextType = null;

export const OrganizationContext = React.createContext<OrganizationContextType>(
  initialOrganizationContext
);

const OrganizationProvider = ({
  children,
  organization,
  clearCurrentOrgData,
  fetchOrganization
}: PropsType) => {
  const { organizationId, loginLoading } = useContext(UserContext);

  // Fetch organization information
  useEffect(() => {
    clearCurrentOrgData();

    if (organizationId) {
      fetchOrganization(organizationId.toString());
    }
  }, [organizationId]);

  let hasQuickStart = false;
  if (organizationId && organization && organization.id === organizationId) {
    const emrFeatures = organization?.emrFeatures || [];
    hasQuickStart =
      emrFeatures.includes(OrganizationEmrFeatures.UNINTEGRATED_APPOINTMENTS) &&
      emrFeatures.includes(OrganizationEmrFeatures.UNINTEGRATED_PATIENTS);
  }

  const value =
    !loginLoading && organizationId && organization && organization.id === organizationId
      ? { ...organization, hasQuickStart }
      : null;

  return (
    <OrganizationContext.Provider value={value as OrganizationContextType}>
      {children}
    </OrganizationContext.Provider>
  );
};

const mapStateToProps = ({ organizationData }: ReduxStateType) => {
  return {
    organization: organizationData.organizationData
  };
};

export default connect(mapStateToProps, {
  clearCurrentOrgData: clearCurrentOrgDataAction,
  fetchOrganization: fetchOrganizationAction
})(OrganizationProvider);
