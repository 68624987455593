/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useEffect } from "react";
import cx from "classnames";

import Button from "../../../Button";
import Heading from "../../../Heading";
import { Plus } from "../../../Icon";

import { getValidChildTypes } from "../helpers/getValidChildTypes";

import styles from "./index.module.scss";

import { ChatNodeTypes } from "../../../../../types";
import { getNodeIcon } from "../helpers/getNodeIcon";

type PropsType = {
  parentId: string;
  typePath: Array<ChatNodeTypes>;
  onAddNode: (id: string, nodeType: ChatNodeTypes) => void;
};

const getAddText = (typePath: ChatNodeTypes[]) => {
  const parentType = typePath[typePath.length - 1];
  if (parentType === ChatNodeTypes.list) {
    return "list item";
  }

  if (parentType === ChatNodeTypes.listItem) {
    return "list item content";
  }

  return "item";
};

const AddNode = ({ parentId, typePath, onAddNode }: PropsType) => {
  const ref = useRef<any>();
  const [open, setOpen] = useState(false);
  const validChildTypes = getValidChildTypes(typePath);

  const handleClick = (e: any) => {
    if (ref && ref.current && (ref.current as any).contains(e.target)) {
      // inside modal click
      return;
    }
    // outside modal click
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      // add when mounted
      document.addEventListener("mousedown", handleClick);
      return () => {
        document.removeEventListener("mousedown", handleClick);
      };
    }

    document.removeEventListener("mousedown", handleClick);

    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [open]);

  if (!validChildTypes) return null;

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <div ref={ref} className={styles.Wrapper}>
      <Button inline className={styles.Toggle} onClick={toggleOpen}>
        <Plus size={16} />
        {getAddText(typePath)}
      </Button>
      {open && (
        <div className={cx(styles.Modal)}>
          <Heading size="S" bold>
            Add Element
          </Heading>
          <div className={styles.NodeList}>
            {validChildTypes.map((nodeType) => {
              return (
                <Button
                  key={nodeType}
                  inline
                  secondary
                  className={styles.NodeButton}
                  onClick={() => {
                    onAddNode(parentId, nodeType);
                    toggleOpen();
                  }}
                >
                  {getNodeIcon(nodeType, { size: 32 })}
                  {nodeType}
                </Button>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddNode;
