import React from "react";

import Filters from "../../../ui/Filters/FiltersWithCount";
import { InboxModes } from "../../../../constants";
import { TaskFilterOptions, TaskCounts } from "../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  counts: TaskCounts;
  filter: TaskFilterOptions;
  handleFilterChange: (filter: TaskFilterOptions) => void;
  resetSelectAll: () => void;
};

const InboxMode = ({ counts, filter, handleFilterChange, resetSelectAll }: PropsType) => {
  const inboxFilterOptions = [
    { label: "To Do", value: InboxModes.TODO, count: counts[InboxModes.TODO] },
    { label: "Done", value: InboxModes.DONE },
    { label: "All", value: InboxModes.ALL }
  ];

  const handleModeChange = (filterValue: string) => {
    resetSelectAll();
    handleFilterChange({ ...filter, mode: filterValue });
  };

  return (
    <div className={styles.FilterHeaderRow}>
      <Filters
        selectedFilter={filter.mode}
        onFilterChange={(selected) => handleModeChange(selected)}
        filterOptions={inboxFilterOptions}
        showMissingCounts={false}
      />
    </div>
  );
};

export default InboxMode;
