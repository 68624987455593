import React from "react";

import ElementInput from "../ElementInput";

import { ContextVariable, UpdateCondition, ElementInputValue } from "../types";

type PropsType = {
  className?: string;
  conditionPath: string;
  variableValue: string;
  contextVariables: ContextVariable[];
  updateCondition: UpdateCondition;
  disabled: boolean;
};

const Variable = ({
  className,
  conditionPath,
  variableValue,
  contextVariables,
  updateCondition,
  disabled
}: PropsType) => {
  const variableOptions = contextVariables.map((contextVariable) => ({
    label: contextVariable.label || contextVariable.inputName,
    value: contextVariable.inputName
  }));

  const onVariableSelection = (selection: ElementInputValue) => {
    updateCondition(conditionPath, "variable", { var: selection });
  };

  return (
    <div className={className}>
      <ElementInput
        elementType="variable"
        options={variableOptions}
        value={variableValue}
        placeholder="Choose Input"
        onChange={onVariableSelection}
        disabled={disabled}
      />
    </div>
  );
};

export default Variable;
