import moment from "moment";
import { FeatureConfiguration, User } from "../types";

export default (user: User, featureName: string): FeatureConfiguration | false => {
  const featureSetting = user.settings.features?.[featureName];
  if (!featureSetting) return false;

  const featureActive = featureSetting.active;
  const featureExpiry = featureSetting.expiresAt;
  const expiresInFuture = featureExpiry ? moment(featureExpiry).isAfter(moment()) : null;

  if (expiresInFuture) return featureSetting;

  if (featureActive && (expiresInFuture || expiresInFuture === null)) return featureSetting;

  return false;
};
