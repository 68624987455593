import { FETCH_PRACTITIONERS } from "../constants";

import { FetchPractitionersAction } from "../actions";

import { Practitioner, ActionStatus } from "../types";

export type PractitionersAction = FetchPractitionersAction;

export type PractitionersReduxState = {
  data: Array<Practitioner>;
  loading: boolean;
};

const initialModalsState: PractitionersReduxState = {
  data: [],
  loading: false
};

export const practitionersReducer = (
  state = initialModalsState,
  action: PractitionersAction
): PractitionersReduxState => {
  const { type } = action;

  switch (type) {
    case FETCH_PRACTITIONERS: {
      const { payload, status } = action as FetchPractitionersAction;
      return {
        ...state,
        data: payload?.practitioners || state.data,
        loading: status === ActionStatus.loading
      };
    }

    default:
      return state;
  }
};
