import { jwtDecode } from "jwt-decode";
import getToken from "./getToken";

import { UserTypeConstants } from "../constants";
import { AuthToken } from "../types";

const isAdminUser = (): boolean => {
  const authToken = getToken();
  if (!authToken) return false;

  const decodedToken: AuthToken = jwtDecode(authToken);

  return decodedToken.userType === UserTypeConstants.MIKATA_ADMIN;
};

export default isAdminUser;
