import React from "react";

import "./Loading.css";

const Loading = ({ text = "Loading...", overrideStyle = {} }) => (
  <div className="loading-container" style={overrideStyle}>
    <div>
      <img src="/img/loading.svg" alt="Mikata Health Logo" />
      {text}
    </div>
  </div>
);

export default Loading;
