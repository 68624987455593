import React, { useEffect, ChangeEvent, useState } from "react";
import cx from "classnames";

import { InputPropsType } from "../../../../../types";

import styles from "./index.module.scss";

type PropsType = Omit<InputPropsType, "customOnChange"> & {
  id?: string;
  label?: string;
  type?: string;
  error?: string;
  initialValue?: string | number;
  autoFocus?: boolean;
  inputIcon?: React.ReactElement;
  onChange?: (value?: string | number) => void;
  setTouched?: (touched: boolean) => void;
};

const TextInputBase = ({
  fieldName,
  id,
  label,
  type = "text",
  error,
  placeholder = "",
  initialValue = "",
  inputIcon,
  disabled = false,
  autoFocus = false,
  setTouched,
  onChange: onChangeProp,
  ...props
}: PropsType) => {
  const [value, setValue] = useState<string | number | undefined>(initialValue);
  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const inputValue = event.target.value || "";
    setValue(inputValue);
    if (onChangeProp) {
      onChangeProp(inputValue);
    }
  };
  useEffect(() => {
    if (initialValue !== value) setValue(initialValue);
  }, [initialValue]);
  return (
    <>
      <input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        id={id || fieldName}
        className={cx(styles.Input, {
          [styles.InputError]: error
        })}
        type={type || "text"}
        placeholder={placeholder}
        aria-label={label}
        aria-required="true"
        disabled={disabled}
        onChange={onChangeHandler}
        onBlur={() => {
          if (setTouched) setTouched(true);
        }}
        value={!value && value !== 0 ? "" : value}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
      />
      {inputIcon && <div className={styles.colorIcon}>{inputIcon}</div>}
    </>
  );
};

export default TextInputBase;
