import React, { useEffect, useCallback, useContext, useRef, useState } from "react";

import { EncounterSocketContext } from "../../../../providers/EncounterSocketProvider";
import { UserContext } from "../../../../providers/UserProvider";
import useAppAnalytics, {
  AppMetricType,
  saveAppMetrics
} from "../../../../../hooks/useAppAnalytics";
import { getProviderMicPreference } from "../../../../../hooks/useAudioSettings";

import { RecordingSessionStatus, MicStreamErrorType } from "../../../../../types";

import getAudioVolume from "./getAudioVolume";

const useAudioInput = ({ appointmentIdContext }: { appointmentIdContext?: number }) => {
  const { organizationId, userId, userType } = useContext(UserContext);
  const { queueMetric } = useAppAnalytics({
    organizationId,
    userId,
    userType
  });
  const [recordingOn, setRecordingOn] = useState<boolean>(false);
  const [micStreamErrorType, setMicStreamErrorType] = useState<MicStreamErrorType | null>(null);
  const [volume, setVolume] = useState<number>(0);
  const audioWorkletNodeRef = useRef<AudioWorkletNode | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const onMessageHandlerRef = useRef<((event: MessageEvent) => void) | null>(null);
  const audioChunkCount = useRef<number>(0);

  const { recording, sendAudioData, pauseSession } = useContext(EncounterSocketContext);
  const errorInfo = `Appointment id ${appointmentIdContext}, user id ${userId}, organization id ${organizationId}`;

  const handleFailureToEstablishMicStream = ({
    error,
    knownErrorType,
    message
  }: {
    error?: Error;
    knownErrorType?: MicStreamErrorType;
    message?: string;
  }) => {
    // eslint-disable-next-line no-console
    console.error("Error accessing media devices.", error);
    pauseSession(true);
    let micErrorType = knownErrorType;
    if (!micErrorType) {
      const isLackOfPermissions = (error as Error)?.name?.match(/NotAllowedError/gim);
      micErrorType = isLackOfPermissions
        ? MicStreamErrorType.NEED_MIC_PERMISSIONS
        : MicStreamErrorType.UNKNOWN;
    }
    let errorMessage = message;
    if (!errorMessage) {
      errorMessage = error?.message || "Unknown error";
    }
    saveAppMetrics([
      {
        type: AppMetricType.APPLICATION_ERROR,
        errorMessage: `Failure to establish mic stream: ${errorMessage}`,
        errorInfo,
        userAgent: window.navigator.userAgent || "",
        timestamp: new Date().toISOString()
      }
    ]);

    setMicStreamErrorType(micErrorType || MicStreamErrorType.UNKNOWN);
  };

  const startRecording = useCallback(() => {
    // Clear previous Mic Steam Errors
    if (micStreamErrorType) setMicStreamErrorType(null);

    if (!recording) return;

    const recordingShouldBeOn = recording.status === RecordingSessionStatus.RECORDING;

    const shouldStartRecording = recordingShouldBeOn && !recordingOn;

    if (shouldStartRecording) {
      const deviceId = getProviderMicPreference();
      if (appointmentIdContext)
        queueMetric({
          type: AppMetricType.SESSION_USER_AGENT,
          appointmentId: appointmentIdContext,
          userAgent: window?.navigator?.userAgent || "",
          deviceId
        });

      try {
        navigator.mediaDevices
          .getUserMedia({
            audio: { deviceId },
            video: false
          })
          .then(async (stream) => {
            const audioContext: AudioContext = new AudioContext();
            await audioContext.audioWorklet.addModule("js/audioProcessor.js");

            const audioWorkletNode: AudioWorkletNode = new AudioWorkletNode(
              audioContext,
              "audio-processor",
              { processorOptions: { sampleRate: audioContext.sampleRate } }
            );
            const source: MediaStreamAudioSourceNode = audioContext.createMediaStreamSource(stream);

            // Analyser node to get the amplitude data
            const analyser: AnalyserNode = audioContext.createAnalyser();
            analyser.fftSize = 128; // Default is 2048. Lower value means fewer computations
            source.connect(analyser);
            analyser.connect(audioWorkletNode);

            source.connect(audioWorkletNode);
            audioWorkletNode.connect(audioContext.destination);

            audioWorkletNodeRef.current = audioWorkletNode;
            mediaStreamRef.current = stream;

            setRecordingOn(true);

            const onMessage = (event: MessageEvent) => {
              if (typeof event.data === "string") {
                // eslint-disable-next-line no-console
                console.log("event:", event.data);
                return;
              }
              const { pcmEncodedAudioChunk, durationSeconds } = event.data;

              audioChunkCount.current += 1;
              sendAudioData({
                rawAudioChunk: pcmEncodedAudioChunk,
                sequenceNumber: audioChunkCount.current,
                durationSeconds,
                sentAtEpoch: Date.now()
              });

              const volume = getAudioVolume(analyser);
              setVolume(volume);
            };

            onMessageHandlerRef.current = onMessage;
            audioWorkletNode.port.onmessage = onMessage;

            try {
              // Pause recording and notify user if the mic is unplugged
              navigator.mediaDevices.ondevicechange = async () => {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const audioDevices = devices?.filter((device) => device.kind === "audioinput");
                const selectedDeviceInfo = audioDevices?.filter(
                  (item) => item.deviceId === deviceId
                );
                // IHA-7207 only interrupting if the selected device was no longer available in the list
                if (!selectedDeviceInfo || selectedDeviceInfo?.length === 0) {
                  handleFailureToEstablishMicStream({
                    knownErrorType: MicStreamErrorType.MIC_CHANGED,
                    message: "Microphone change detected."
                  });
                }
              };
            } catch (error) {
              // This try-catch block is necessary because navigator will still try to set up the
              // event listener even if the user has not granted microphone permissions
              // eslint-disable-next-line no-console
              console.error("Error setting up ondevicechange event listener", error);
            }
          })
          .catch((error) => {
            handleFailureToEstablishMicStream({ error: error as Error });
          });
      } catch (error) {
        handleFailureToEstablishMicStream({ error: error as Error });
      }
    }
  }, [recording?.status, sendAudioData, micStreamErrorType, recordingOn, appointmentIdContext]);

  const endRecording = useCallback(() => {
    // Clear previous Mic Steam Errors
    if (micStreamErrorType) setMicStreamErrorType(null);

    try {
      navigator.mediaDevices.ondevicechange = null;

      if (audioWorkletNodeRef.current) {
        audioWorkletNodeRef.current.port.removeEventListener(
          "message",
          onMessageHandlerRef.current as EventListener
        );
        audioWorkletNodeRef.current.port.close();
        audioWorkletNodeRef.current = null;
      }
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => {
          track.stop();
        });
        mediaStreamRef.current = null;
      }
      setRecordingOn(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      saveAppMetrics([
        {
          type: AppMetricType.APPLICATION_ERROR,
          errorMessage: `Failure to end mic stream: ${(error as Error).message || "Unknown error"}`,
          errorInfo,
          userAgent: window.navigator.userAgent || "",
          timestamp: new Date().toISOString()
        }
      ]);
    }
  }, [micStreamErrorType]);

  // align recording state with audio input status
  useEffect(() => {
    if (
      recordingOn &&
      (!appointmentIdContext || recording?.status !== RecordingSessionStatus.RECORDING)
    ) {
      endRecording();
    }

    if (
      !recordingOn &&
      recording?.status === RecordingSessionStatus.RECORDING &&
      appointmentIdContext
    ) {
      startRecording();
    }
  }, [recordingOn, recording?.status, appointmentIdContext]);

  // end recording on dismount
  useEffect(() => {
    return () => {
      endRecording();
      setMicStreamErrorType(null);
    };
  }, []);

  return { startRecording, endRecording, micStreamErrorType, setMicStreamErrorType, volume };
};

export default useAudioInput;
