import React, { useContext, useEffect, useCallback, useMemo, useRef } from "react";
import { EditorState } from "draft-js";
import throttle from "lodash/throttle";

import { EncounterSocketContext } from "../../../../../providers/EncounterSocketProvider";

import {
  getMikaAnchorDataMap,
  getMikaAnchorStatuses,
  injectMikaContent
} from "../RichTextEditor/helpers";

import { EditorInsightItem } from "../RichTextEditor/types";
import {
  SetFeedbackModeData,
  EventData,
  SocketMessageType,
  EventTypes
} from "../../../../../../types";

type PropsType = {
  editorState: EditorState | null;
  setFeedbackMode: (data: SetFeedbackModeData) => void;
  queueNoteForFeedback: (data: number) => void;
  setEditorState?: (fn: (currentEditorState: EditorState) => EditorState) => void;
  noteId: number;
  isActive: boolean;
};

const INJECT_INSIGHTS_THROTTLE_MS = 1000;

const useNoteInsightInjection = ({
  editorState,
  setEditorState,
  queueNoteForFeedback,
  noteId,
  isActive
}: PropsType) => {
  const { insights, sendMessage } = useContext(EncounterSocketContext);
  const timerRef = useRef<NodeJS.Timeout>();

  const sendProviderActivityMessage = useCallback(
    (eventType: EventTypes, eventData: EventData = {}) => {
      sendMessage(SocketMessageType.providerActivity, {
        eventType,
        eventData: { ...eventData, activeNoteId: noteId }
      });
    },
    [noteId]
  );

  useEffect(() => {
    if (isActive) {
      sendProviderActivityMessage(EventTypes.ACTIVE_NOTE_CHANGED, {
        activeNoteId: noteId
      });
    }
  }, [noteId, isActive, sendProviderActivityMessage]);

  const anchorStatuses = getMikaAnchorStatuses(noteId, editorState).join("-");

  const processInsights = (insightsToProcess: EditorInsightItem[]) => {
    if (setEditorState) {
      setEditorState((currentEditorState) => {
        const { nextEditorState, injectedInsights } = injectMikaContent(
          currentEditorState,
          insightsToProcess,
          noteId
        );

        const shouldQueueForFeedback = Boolean(
          injectedInsights.find((item: EditorInsightItem) => item.shouldPromptFeedback === true)
        );
        if (shouldQueueForFeedback) {
          queueNoteForFeedback(noteId);
        }

        sendProviderActivityMessage(EventTypes.NOTE_CONTENT_INJECTED, {
          activeNoteId: noteId,
          injectedInsightCategories: injectedInsights.map(
            (item: EditorInsightItem) => item.category
          )
        });

        return nextEditorState;
      });
    }
  };

  // Throttled process insights
  const throttledInjectMikaContent = useCallback(
    throttle(processInsights, INJECT_INSIGHTS_THROTTLE_MS, { leading: false }),
    []
  );

  const editorInsightItems: EditorInsightItem[] = useMemo(() => {
    const anchorEntityDataMap = getMikaAnchorDataMap(noteId, editorState);
    const anchorNames = Object.keys(anchorEntityDataMap);

    return insights
      .filter((insight) => {
        return (
          insight.type === "noteContent" && // Insight is a "noteContent" type
          insight.output.category &&
          anchorNames.includes(insight.output.category) // Note is "listening" for insight anchorName
        );
      })
      .map((insight) => {
        return {
          id: insight.id,
          appointmentId: insight.appointmentId,
          text: insight?.output.text,
          shouldPromptFeedback: insight?.output.shouldPromptFeedback,
          category: insight.output.category,
          createdAt: insight.createdAt,
          language: insight.output.language
        } as EditorInsightItem;
      });
  }, [insights]);

  useEffect(() => {
    if (noteId && editorInsightItems?.length > 0) {
      throttledInjectMikaContent(editorInsightItems);
    }
  }, [editorInsightItems?.length, noteId]);

  useEffect(() => {
    return () => {
      // clear timer when component is removed from the DOM
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return { editorInsights: editorInsightItems, anchorStatuses };
};

export default useNoteInsightInjection;
