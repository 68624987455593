import React from "react";
import { connect } from "react-redux";

import { Form } from "../../Input";
import ReportIssueForm from "./ReportIssueForm";

import { addFeedback, setFeedbackMode } from "../../../../actions";

import { FeedbackFormTypes } from "../../../../constants";
import { FeedbackFormModes } from "../../../../typedConstants";
import { AddFeedbackData, SetFeedbackModeData } from "../../../../types";

type PropsType = {
  context: {
    appointmentId: string | undefined;
    noteIds: number[] | null;
    activeNoteId: number | null;
  };
  addFeedback: (data: AddFeedbackData) => void;
  setFeedbackMode: (data: SetFeedbackModeData) => void;
};

type FeedbackFormData = {
  feedbackData: string;
  consent: boolean;
};

const initialFormState = {
  feedbackData: "",
  consent: false
};

const ReportIssue = ({ context, addFeedback, setFeedbackMode }: PropsType): JSX.Element => {
  const { appointmentId, noteIds, activeNoteId } = context;
  const onSubmit = (data: FeedbackFormData) => {
    const formData: AddFeedbackData = {
      formType: FeedbackFormTypes.SCRIBE_ISSUE,
      context: {
        appointmentIds: appointmentId ? [Number(appointmentId)] : [],
        noteIds: noteIds || [],
        activeNoteId
      },
      feedbackData: { consent: data.consent, userComment: data.feedbackData }
    };
    addFeedback(formData);
    setFeedbackMode({ feedbackMode: FeedbackFormModes.SUBMITTED });
  };

  return (
    <Form
      initialValues={initialFormState}
      onSubmit={(formState) => onSubmit(formState.values as FeedbackFormData)}
    >
      <ReportIssueForm setFeedbackMode={setFeedbackMode} />
    </Form>
  );
};

export default connect(null, { addFeedback, setFeedbackMode })(ReportIssue);
