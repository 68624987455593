import React from "react";
import { connect } from "react-redux";

import styles from "./index.module.scss";

import Heading from "../../../../../components/ui/Heading";
import Text from "../../../../../components/ui/Text";

import { ReduxStateType } from "../../../../../types";

type PropsType = {
  bookedMessages?: number;
  multiBookedMessages?: number;
  cancelMessages?: number;
  multiCancelMessages?: number;
  patientConfirmationMessages?: number;
  patientRescheduleMessages?: number;
  bookedMessagesLoading?: boolean;
  cancelMessagesLoading?: boolean;
  patientConfirmationMessagesLoading?: boolean;
  patientRescheduleMessagesLoading?: boolean;
};

const PatientInteractions = ({
  bookedMessages,
  multiBookedMessages,
  cancelMessages,
  multiCancelMessages,
  patientConfirmationMessages,
  patientRescheduleMessages,
  bookedMessagesLoading,
  cancelMessagesLoading,
  patientConfirmationMessagesLoading,
  patientRescheduleMessagesLoading
}: PropsType) => {
  let bookedCount = 0;
  if (bookedMessages) bookedCount += bookedMessages;
  if (multiBookedMessages) bookedCount += multiBookedMessages;

  let cancelCount = 0;
  if (cancelMessages) cancelCount += cancelMessages;
  if (multiCancelMessages) cancelCount += multiCancelMessages;

  return (
    <div className={styles.Card}>
      <div className={styles.Wrapper}>
        <Heading size="M" component="h2">
          Patient Interactions
        </Heading>
      </div>
      <Text size="M">
        Booking confirmations sent to patients: {bookedMessagesLoading ? "..." : bookedCount}
      </Text>
      <Text size="M">
        Cancellation messages sent: {cancelMessagesLoading ? "..." : cancelCount}
      </Text>
      <Text size="M">
        Patient confirmation responses:{" "}
        {patientConfirmationMessagesLoading ? "..." : patientConfirmationMessages}
      </Text>
      <Text size="M">
        Patient reschedule requests:{" "}
        {patientRescheduleMessagesLoading ? "..." : patientRescheduleMessages}
      </Text>
    </div>
  );
};

const mapStateToProps = ({ analytics }: ReduxStateType) => {
  const {
    bookedMessages,
    multiBookedMessages,
    cancelMessages,
    multiCancelMessages,
    patientConfirmationMessages,
    patientRescheduleMessages,
    bookedMessagesLoading,
    cancelMessagesLoading,
    patientConfirmationMessagesLoading,
    patientRescheduleMessagesLoading
  } = analytics;
  return {
    bookedMessages,
    multiBookedMessages,
    cancelMessages,
    multiCancelMessages,
    patientConfirmationMessages,
    patientRescheduleMessages,
    bookedMessagesLoading,
    cancelMessagesLoading,
    patientConfirmationMessagesLoading,
    patientRescheduleMessagesLoading
  };
};

export default connect(mapStateToProps, null)(PatientInteractions);
