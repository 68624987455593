/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useField } from "informed";

import { Calendar, ChevronLeft, ChevronRight } from "../../Icon";
import Text from "../../Text";
import Button from "../../Button";

import "./custom.scss";
import styles from "./index.module.scss";
import BaseInput from "../BaseInput";
import { InputPropsType } from "../../../../types";

type PropsType = InputPropsType & {
  initialValue?: Date;
  customMinDate?: Date;
  customMaxDate?: Date;
};

const customHeader = (
  selectedDate: Date,
  date: Date,
  decreaseMonth: React.MouseEventHandler<HTMLButtonElement> | undefined,
  increaseMonth: React.MouseEventHandler<HTMLButtonElement> | undefined,
  prevMonthButtonDisabled: boolean | undefined,
  nextMonthButtonDisabled: boolean | undefined
) => {
  return (
    <div className={styles.CalendarHeader}>
      <div className={styles.CalendarHeaderDate}>
        <Calendar size={24} />
        <Text size="S" bold className={styles.CalendarHeaderText}>
          {moment(selectedDate).format("dddd, MMMM D")}
        </Text>
      </div>
      <div className={styles.CalendarHeaderMonth}>
        <Button
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          className={styles.CalendarButton}
        >
          <ChevronLeft />
        </Button>
        <Text size="S" component="span" bold className={styles.CalendarDate}>
          {moment(date).format("MMMM YYYY")}
        </Text>
        <Button
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
          className={styles.CalendarButton}
        >
          <ChevronRight />
        </Button>
      </div>
    </div>
  );
};

// eslint-disable-next-line react/display-name
const ExampleCustomInput = forwardRef<any, any>(
  ({ value, onClick }: { value: string; onClick: () => void }, ref: any) => {
    return (
      <button
        className={styles.CustomInputButton}
        type="button"
        onClick={onClick}
        ref={ref.current as any}
      >
        <Calendar size={24} />
        <Text size="S" bold className={styles.CustomInputButtonText}>
          {value}
        </Text>
      </button>
    );
  }
);

const DateSelectInput = ({
  fieldName,
  label,
  showField = true,
  initialValue = moment().toDate(),
  customMinDate,
  customMaxDate,
  disabled = false
}: PropsType) => {
  const { fieldState, fieldApi } = useField({
    name: fieldName,
    initialValue: moment(initialValue).format("dddd, MMMM D, YYYY").toString()
  });

  const value = fieldState.value as any;
  const error = fieldState.error as string | undefined;
  const { setValue, setTouched, validate: validateInput } = fieldApi;

  const onChangeHandler = (date: Date | null) => {
    setValue(moment(date).format("dddd, MMMM D, YYYY").toString());
    setTouched(true);
    if (error) {
      validateInput();
    }
  };

  return (
    <BaseInput showField={showField} fieldName={fieldName} label={label}>
      <div className={styles.DateSelector}>
        <div id="ChevronLeft" className={styles.DateSelectorSide}>
          <Button
            className={styles.CalendarButton}
            onClick={() => {
              onChangeHandler(moment(value).subtract(1, "days").toDate());
            }}
            disabled={
              customMinDate
                ? moment(value).format().toString() === moment(customMinDate).format().toString() ||
                  disabled
                : disabled
            }
          >
            <ChevronLeft size={26} />
          </Button>
        </div>

        <div className={styles.DateSelectorMain}>
          {/* Please see the following docs for more information of react datepicker this.props
          https://github.com/Hacker0x01/react-datepicker/tree/master/docs
          https://reactdatepicker.com/#example-custom-input */}
          <DatePicker
            selected={moment(value).toDate()}
            onChange={onChangeHandler}
            className={styles.DatePicker}
            calendarClassName={styles.Calendar}
            dateFormat="eeee, MMMM d"
            minDate={customMinDate}
            maxDate={customMaxDate}
            customInput={<ExampleCustomInput />}
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled
            }) =>
              customHeader(
                moment(value).toDate(),
                date,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled
              )
            }
            showPopperArrow={false}
            popperClassName={styles.Popper}
            disabled={disabled}
          />
        </div>

        <div id="ChevronRight" className={styles.DateSelectorSide}>
          <Button
            className={styles.CalendarButton}
            onClick={() => {
              onChangeHandler(moment(value).add(1, "days").toDate());
            }}
            disabled={
              customMaxDate
                ? moment(value).format().toString() === moment(customMaxDate).format().toString() ||
                  disabled
                : disabled
            }
          >
            <ChevronRight size={26} />
          </Button>
        </div>
      </div>
    </BaseInput>
  );
};

export default DateSelectInput;
