import {
  Modifier,
  EditorState,
  SelectionState,
  ContentState,
  genKey,
  ContentBlock
} from "draft-js";

import { getMikaAnchorKeyMap } from ".";

import {
  MikaAnchorEntityData,
  MikaContentEntityData,
  EditorInsightItem,
  MikaAnchorStatus,
  MikaContentStatus,
  MikaAnchorKeyMap
} from "../types";

export const updateEntityData = (
  editorState: EditorState,
  mergeData: { [key: string]: string | number | number[] },
  entityKey: string
): EditorState => {
  const contentState = editorState.getCurrentContent();

  const contentStateWithNewAnchorData = contentState.mergeEntityData(entityKey, mergeData);

  const editorStateWithUpdatedData = EditorState.push(
    editorState,
    contentStateWithNewAnchorData,
    "change-block-data"
  );

  return editorStateWithUpdatedData;
};

const getAnchorEntityKey = (currentAnchorKeyMap: MikaAnchorKeyMap, insightAnchorName: string) => {
  return currentAnchorKeyMap[insightAnchorName] || null;
};

const isEligibleForInsightInjection = (
  editorState: EditorState,
  insight: EditorInsightItem,
  anchorKeyMap: { [mikaContentEntityType: string]: string }
): boolean => {
  const contentState = editorState.getCurrentContent();

  // This anchorDataMap is mapping anchors to other note anchorEntityData
  const anchorDataMap = Object.keys(anchorKeyMap).reduce((map, anchorName) => {
    const anchorEntityKey = anchorKeyMap[anchorName];
    const anchorEntityData = contentState.getEntity(anchorEntityKey).getData();

    map[anchorName] = anchorEntityData;
    return map;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, {} as any);

  // The anchorName of the note entity should match the insight category
  const matchingAnchor = anchorDataMap?.[insight.category];
  if (!matchingAnchor) return false;

  // If a language is specified then the insight language should match the anchor language
  const hasMatchingLanguage =
    !matchingAnchor.language || insight.language === matchingAnchor.language;
  if (!hasMatchingLanguage) return false;

  // Don't inject more than once
  const hasAnchorInsightsInjected = (matchingAnchor.insightIdsInjected?.length || 0) > 0;
  if (hasAnchorInsightsInjected) return false;

  return true;
};

const getAnchorBlockKey = (editorState: EditorState, insight: EditorInsightItem): string | null => {
  const insightAnchorName = insight.category;
  const contentState = editorState.getCurrentContent();
  const blocksArray = contentState.getBlocksAsArray();

  const categoryBlock = blocksArray.find((block) => {
    let hasMatchingEntity = false;
    block.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();

        if (entityKey === null) {
          return false;
        }

        const entityType: string = contentState.getEntity(entityKey)?.getType();
        const entityData: MikaAnchorEntityData = contentState.getEntity(entityKey)?.getData();

        const isMatchingAnchor =
          Boolean(entityType) &&
          entityType === "ANCHOR" &&
          entityData?.anchorName === insightAnchorName &&
          (!entityData.insightIdsInjected || entityData.insightIdsInjected?.length === 0);

        if (isMatchingAnchor) {
          hasMatchingEntity = true;
          console.log(
            "Found MatchingEntity for anchorName:",
            insightAnchorName,
            entityKey,
            block.getText()
          );
        }
        return isMatchingAnchor;
      },
      (start, end) => {
        return null;
      }
    );

    return hasMatchingEntity;
  });
  return categoryBlock?.getKey() || null;
};

const applyMikaContentEntity = (
  editorState: EditorState,
  mikaContentBlockKey: string,
  insight: EditorInsightItem
): EditorState => {
  const insightId = insight?.id;
  const insightAnchorName = insight?.category || "";

  const contentState = editorState.getCurrentContent();
  const contentStateWithMikaContentEntity = contentState.createEntity("MIKA_CONTENT", "MUTABLE", {
    status: MikaContentStatus.PROVISIONAL,
    insightId,
    insightAnchorName
  } as MikaContentEntityData);

  const mikaContentEntityKey = contentStateWithMikaContentEntity.getLastCreatedEntityKey();
  const newMikaContentBlock = contentState.getBlockForKey(mikaContentBlockKey);
  const endOfMikaContentBlockLength = newMikaContentBlock.getLength();

  const mikaContentSelectionState = SelectionState.createEmpty(mikaContentBlockKey).merge({
    anchorOffset: 0,
    focusOffset: endOfMikaContentBlockLength,
    anchorKey: mikaContentBlockKey,
    focusKey: mikaContentBlockKey
  });

  const contentStateWithAppliedMikaContentEntity = Modifier.applyEntity(
    contentStateWithMikaContentEntity,
    mikaContentSelectionState,
    mikaContentEntityKey
  );

  const editorStateWithMikaContentEntity = EditorState.push(
    editorState,
    contentStateWithAppliedMikaContentEntity,
    "apply-entity"
  );

  return editorStateWithMikaContentEntity;
};

const addMikaContentAfterBlockKey = (
  currentEditorState: EditorState,
  insertAfterBlockKey: string,
  insight: EditorInsightItem
) => {
  const insightText = insight.text;
  // Insert new block with styles after category title or after last block
  const contentState = currentEditorState.getCurrentContent();
  const currentBlock = contentState.getBlockForKey(insertAfterBlockKey);

  const blockMap = contentState.getBlockMap();
  const blocksBefore = blockMap.toSeq().takeUntil((block) => block === currentBlock);
  const blocksAfter = blockMap
    .toSeq()
    .skipUntil((block) => block === currentBlock)
    .rest();
  const newBlockKey = genKey();
  const newBlocks = [
    [currentBlock.getKey(), currentBlock],
    [
      newBlockKey,
      new ContentBlock({
        key: newBlockKey,
        type: "unstyled",
        text: ""
      })
    ]
  ];
  const newBlockMap = blocksBefore.concat(newBlocks, blocksAfter).toOrderedMap();
  const newSelection = SelectionState.createEmpty(newBlockKey).merge({
    anchorKey: newBlockKey,
    anchorOffset: 0,
    focusKey: newBlockKey
  });
  const updatedContentStateWithBlock = contentState.merge({
    blockMap: newBlockMap,
    selectionBefore: newSelection,
    selectionAfter: newSelection
  });

  const newContentState = updatedContentStateWithBlock;

  const editorStateWithNewBlock = EditorState.push(
    currentEditorState,
    newContentState as ContentState,
    "insert-fragment"
  );

  // Add insight content to new block
  const lastCreatedBlockKey = newBlockKey;
  const updatedSelectionState = editorStateWithNewBlock.getSelection().merge({
    anchorKey: lastCreatedBlockKey,
    anchorOffset: 0,
    focusKey: lastCreatedBlockKey,
    focusOffset: 0
  });

  const updatedContentState = Modifier.replaceText(
    editorStateWithNewBlock.getCurrentContent(),
    updatedSelectionState.merge({
      anchorOffset: updatedSelectionState.getAnchorOffset(),
      focusOffset: updatedSelectionState.getFocusOffset()
    }),
    insightText
  );

  const editorStateWithNewCharacters = EditorState.push(
    editorStateWithNewBlock,
    updatedContentState,
    "insert-characters"
  );

  const editorStateWithMikaContentEntity = applyMikaContentEntity(
    editorStateWithNewCharacters,
    newBlockKey,
    insight
  );

  return editorStateWithMikaContentEntity;
};

const addInsightContent = (
  cleanEditorState: EditorState,
  insight: EditorInsightItem,
  noteId: number
): { editorState: EditorState; injectedInsight: EditorInsightItem | null } => {
  const insightAnchorName = insight.category;
  const currentAnchorKeyMap = getMikaAnchorKeyMap(noteId, cleanEditorState);

  const insightEligibleToInject = isEligibleForInsightInjection(
    cleanEditorState,
    insight,
    currentAnchorKeyMap
  );

  if (!insightEligibleToInject) {
    return { editorState: cleanEditorState, injectedInsight: null };
  }

  const insertAfterBlockKey = getAnchorBlockKey(cleanEditorState, insight);

  if (!insertAfterBlockKey) {
    return { editorState: cleanEditorState, injectedInsight: null };
  }

  const nextEditorStateWithContent = addMikaContentAfterBlockKey(
    cleanEditorState,
    insertAfterBlockKey,
    insight
  );

  const anchorEntityKey: string | null = getAnchorEntityKey(currentAnchorKeyMap, insightAnchorName);
  if (!anchorEntityKey) {
    return { editorState: cleanEditorState, injectedInsight: null };
  }

  const editorStateWithNewAnchorData = updateEntityData(
    nextEditorStateWithContent,
    {
      status: MikaAnchorStatus.INJECTED,
      insightIdsInjected: [insight.id]
    },
    anchorEntityKey
  );

  return { editorState: editorStateWithNewAnchorData, injectedInsight: insight };
};

export const injectMikaContent = (
  currentEditorState: EditorState,
  insights: EditorInsightItem[],
  noteId: number
): { nextEditorState: EditorState; injectedInsights: EditorInsightItem[] } => {
  const injectedInsights: EditorInsightItem[] = [];
  if (insights.length === 0) return { nextEditorState: currentEditorState, injectedInsights };

  const initialSelectionState = currentEditorState.getSelection();
  const initialSelectionStateHasFocus = initialSelectionState.getHasFocus();

  let nextEditorState: EditorState = currentEditorState;

  insights.forEach((insightOption) => {
    const { editorState, injectedInsight } = addInsightContent(
      nextEditorState,
      insightOption,
      noteId
    );
    nextEditorState = editorState;
    if (injectedInsight) injectedInsights.push(injectedInsight);
  });

  if (initialSelectionStateHasFocus) {
    // IF previous selection still valid then apply preexisting cursor selection
    nextEditorState = EditorState.forceSelection(nextEditorState, initialSelectionState);
  }

  return { nextEditorState, injectedInsights };
};
