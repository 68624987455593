import React, { useContext } from "react";
import cx from "classnames";

import { LayoutContext, LayoutViewMode } from "../../../providers/LayoutProvider";

import styles from "./index.module.scss";

type PropsType = {
  children: React.ReactNode;
  hideOnMobile?: boolean;
  hideOnTablet?: boolean;
  hideOnDesktop?: boolean;
};

const ResponsiveHide = ({
  children,
  hideOnMobile = false,
  hideOnTablet = false,
  hideOnDesktop = false
}: PropsType): React.ReactElement => {
  const { viewMode } = useContext(LayoutContext);

  const hideChildren =
    (hideOnMobile && viewMode === LayoutViewMode.MOBILE) ||
    (hideOnTablet && viewMode === LayoutViewMode.TABLET) ||
    (hideOnDesktop && viewMode === LayoutViewMode.DESKTOP);

  if (hideChildren) {
    return <div className={cx(styles.Hide)}>{children}</div>;
  }

  return <>{children}</>;
};

export default ResponsiveHide;
