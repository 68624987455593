import React, { Dispatch, SetStateAction } from "react";

import { SingleSelect, SingleSelectChatFlowsNode } from "./Cards/SingleSelect";
import Default from "./Cards/Default";

import { ChatCardTypes } from "../../../../../../../constants";

import { ChatFlowsNodes } from "../../../../../../../types";

import MultiSelect, { MultiSelectChatFlowsNode } from "./Cards/MultiSelect";
import TextInputCard, { TextInputChatFlowsNode } from "./Cards/TextInput";
import Text from "./Cards/Text";
import End, { EndChatFlowsNode } from "./Cards/End";
import FlaggedEnd, { FlaggedEndChatFlowsNode } from "./Cards/FlaggedEnd";
import BookingCard, { BookingChatFlowsNode } from "./Cards/Booking";
import { DateInput, DateInputChatFlowsNode } from "./Cards/DateInput";
import NumberInput, { NumberInputChatFlowsNode } from "./Cards/NumberInput";
import PhoneInput, { PhoneInputChatFlowsNode } from "./Cards/PhoneInput";
import PhotoUpload, { PhotoUploadChatFlowsNode } from "./Cards/PhotoUpload";
import ChatFlow, { ChatFlowFormNode } from "./Cards/ChatFlow";
import Landing, { LandingFormNode } from "./Cards/Landing";
import PatientVerification, { PatientVerificationFormNode } from "./Cards/PatientVerification";
import Instructions, { InstructionsFormNode } from "./Cards/Instructions";
import DeadEnd, { DeadEndChatFlowsNode } from "./Cards/DeadEnd";
import ScaleInput, { ScaleInputChatFlowsNode } from "./Cards/ScaleInput";
import ScribeVisit, { ScribeVisitNode } from "./Cards/ScribeVisit";
import CheckInCard from "./Cards/CheckIn";
import InstructionReasons, { InstructionReasonsFormNode } from "./Cards/InstructionReasons";
import InstructionsFromReasons, {
  InstructionsFromReasonsFormNode
} from "./Cards/InstructionsFromReasons";

import styles from "./chatNodeCard.module.scss";

type PropsType = {
  loading: boolean;
  chatId: string | undefined;
  cardType: string | null;
  node: ChatFlowsNodes | null;
  viewOnly: boolean;
  setSelectedNodeId: Dispatch<SetStateAction<number | null>>; // TODO: should be just a function that takes a number or null and returns void, will need to be changed for all the card types
};

const ChatNodeCard = ({
  chatId,
  cardType,
  node,
  viewOnly,
  loading,
  setSelectedNodeId
}: PropsType) => {
  if (!cardType) return null;

  const renderCardType = (cardtype: string) => {
    if (!node) return null;

    switch (cardtype) {
      case ChatCardTypes.SINGLE_SELECT:
        return (
          <SingleSelect
            node={node as SingleSelectChatFlowsNode}
            chatId={chatId}
            viewOnly={viewOnly}
            setSelectedNodeId={setSelectedNodeId}
          />
        );
      case ChatCardTypes.MULTI_SELECT:
        return (
          <MultiSelect
            node={node as MultiSelectChatFlowsNode}
            chatId={chatId}
            viewOnly={viewOnly}
            setSelectedNodeId={setSelectedNodeId}
          />
        );
      case ChatCardTypes.TEXT_INPUT:
        return (
          <TextInputCard
            node={node as TextInputChatFlowsNode}
            chatId={chatId}
            viewOnly={viewOnly}
            setSelectedNodeId={setSelectedNodeId}
          />
        );
      case ChatCardTypes.TEXT:
        return (
          <Text
            node={node}
            chatId={chatId}
            viewOnly={viewOnly}
            setSelectedNodeId={setSelectedNodeId}
          />
        );
      case ChatCardTypes.DEAD_END:
        return (
          <DeadEnd
            node={node as DeadEndChatFlowsNode}
            chatId={chatId}
            viewOnly={viewOnly}
            setSelectedNodeId={setSelectedNodeId}
          />
        );
      case ChatCardTypes.END:
        return (
          <End
            node={node as EndChatFlowsNode}
            chatId={chatId}
            viewOnly={viewOnly}
            setSelectedNodeId={setSelectedNodeId}
          />
        );
      case ChatCardTypes.FLAGGED_END:
        return (
          <FlaggedEnd
            node={node as FlaggedEndChatFlowsNode}
            chatId={chatId}
            viewOnly={viewOnly}
            setSelectedNodeId={setSelectedNodeId}
          />
        );
      case ChatCardTypes.BOOKING:
        return (
          <BookingCard
            node={node as BookingChatFlowsNode}
            chatId={chatId}
            viewOnly={viewOnly}
            setSelectedNodeId={setSelectedNodeId}
          />
        );
      case ChatCardTypes.DATE_INPUT:
        return (
          <DateInput
            node={node as DateInputChatFlowsNode}
            chatId={chatId}
            viewOnly={viewOnly}
            setSelectedNodeId={setSelectedNodeId}
          />
        );
      case ChatCardTypes.NUMBER_INPUT:
        return (
          <NumberInput
            node={node as NumberInputChatFlowsNode}
            chatId={chatId}
            viewOnly={viewOnly}
            setSelectedNodeId={setSelectedNodeId}
          />
        );
      case ChatCardTypes.PHONE_INPUT:
        return (
          <PhoneInput
            node={node as PhoneInputChatFlowsNode}
            chatId={chatId}
            viewOnly={viewOnly}
            setSelectedNodeId={setSelectedNodeId}
          />
        );
      case ChatCardTypes.PHOTO_UPLOAD:
        return (
          <PhotoUpload
            node={node as PhotoUploadChatFlowsNode}
            chatId={chatId}
            viewOnly={viewOnly}
            setSelectedNodeId={setSelectedNodeId}
          />
        );
      case ChatCardTypes.CHAT_FLOW:
        return (
          <ChatFlow
            node={node as ChatFlowFormNode}
            chatId={chatId}
            viewOnly={viewOnly}
            setSelectedNodeId={setSelectedNodeId}
          />
        );
      case ChatCardTypes.LANDING:
        return (
          <Landing
            node={node as LandingFormNode}
            chatId={chatId}
            viewOnly={viewOnly}
            setSelectedNodeId={setSelectedNodeId}
          />
        );
      case ChatCardTypes.PATIENT_VERIFICATION:
        return (
          <PatientVerification
            node={node as PatientVerificationFormNode}
            chatId={chatId}
            viewOnly={viewOnly}
            setSelectedNodeId={setSelectedNodeId}
          />
        );
      case ChatCardTypes.INSTRUCTIONS:
        return (
          <Instructions
            node={node as InstructionsFormNode}
            chatId={chatId}
            viewOnly={viewOnly}
            setSelectedNodeId={setSelectedNodeId}
          />
        );
      case ChatCardTypes.INSTRUCTION_REASONS:
        return (
          <InstructionReasons
            node={node as InstructionReasonsFormNode}
            chatId={chatId}
            viewOnly={viewOnly}
            setSelectedNodeId={setSelectedNodeId}
          />
        );
      case ChatCardTypes.INSTRUCTIONS_FROM_REASONS:
        return (
          <InstructionsFromReasons
            node={node as InstructionsFromReasonsFormNode}
            chatId={chatId}
            viewOnly={viewOnly}
            setSelectedNodeId={setSelectedNodeId}
          />
        );
      case ChatCardTypes.SCALE_INPUT:
        return (
          <ScaleInput
            node={node as ScaleInputChatFlowsNode}
            chatId={chatId}
            viewOnly={viewOnly}
            setSelectedNodeId={setSelectedNodeId}
          />
        );
      case ChatCardTypes.SCRIBE_VISIT:
        return (
          <ScribeVisit
            node={node as ScribeVisitNode}
            chatId={chatId}
            viewOnly={viewOnly}
            setSelectedNodeId={setSelectedNodeId}
          />
        );
      case ChatCardTypes.CHECK_IN:
        return (
          <CheckInCard
            node={node as ScribeVisitNode}
            chatId={chatId}
            viewOnly={viewOnly}
            setSelectedNodeId={setSelectedNodeId}
          />
        );
      default:
        return (
          <Default
            node={node}
            chatId={chatId}
            viewOnly={viewOnly}
            setSelectedNodeId={setSelectedNodeId}
          />
        );
    }
  };

  return <div className={styles.NodeDetails}>{!loading && renderCardType(cardType)}</div>;
};

export default ChatNodeCard;
