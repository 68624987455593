import formatPhone from "./formatPhone";

const getUserFormattedNumbers = ({
  verifiedMobile = "",
  userMobileNumber = "",
  userHomeNumber = ""
}: {
  verifiedMobile: string;
  userMobileNumber: string;
  userHomeNumber: string;
}) => {
  const verifiedMobileNumberFormatted = verifiedMobile ? formatPhone(verifiedMobile) : "";

  let otherNumberFormatted =
    userHomeNumber && userHomeNumber !== verifiedMobile ? formatPhone(userHomeNumber) : "";

  // otherNumber should fall back to unverified user mobile number if no user homePhone exits
  if (!otherNumberFormatted && userMobileNumber && userMobileNumber !== verifiedMobile) {
    otherNumberFormatted = formatPhone(userMobileNumber);
  }

  return { verifiedMobileNumberFormatted, otherNumberFormatted };
};

export default getUserFormattedNumbers;
