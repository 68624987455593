import React from "react";

import { useFormState } from "informed";
import Button, { ButtonRow } from "../../../../../ui/Button";

import Heading from "../../../../../ui/Heading";
import Card from "../../../../../ui/Card";

import { SelectInput, TextInput, TextAreaInput, ToggleInput } from "../../../../../ui/Input";
import Drawer from "../../../../../ui/Drawer";

import { PermissionsGuard } from "../../../../../../hooks/usePermissions";

import { capitalize } from "../../../../../../utils/stringTransformations";
import {
  displayNameValidator,
  statusValidator,
  JourneyGeneralFormState
} from "../JourneyFormValidator";
import {
  Location,
  Option,
  AutomationStatus,
  AutomationActionRangeTimeType,
  AutomationSettingAction,
  ChatFlow,
  Permissions
} from "../../../../../../types";
import ActionSettingSection from "../ActionSettingSection";

import styles from "./index.module.scss";

type PropsType = {
  locations: Array<Location>;
  checkInChats: Array<ChatFlow>;
  formDisabled: boolean;
  isDefaultAutomation: boolean;
  openDeleteModal: () => void;
};

const GeneralFormContent = ({
  formDisabled,
  isDefaultAutomation,
  locations,
  checkInChats,
  openDeleteModal
}: PropsType): JSX.Element | null => {
  const formState = useFormState();
  const values = formState.values as JourneyGeneralFormState;

  const statusOptions: Array<Option> = Object.values(AutomationStatus).map((status) => {
    return {
      label: capitalize(status),
      value: status
    };
  });

  return (
    <div className={styles.ContainerMain}>
      <Card>
        <Heading size="M" component="h4">
          General configuration
        </Heading>
        <TextInput
          fieldName="displayName"
          label="name"
          disabled={formDisabled}
          validate={displayNameValidator}
        />
        <TextAreaInput
          size="S"
          fieldName="description"
          label="description"
          disabled={formDisabled}
        />
        <SelectInput
          fieldName="status"
          label="status"
          options={statusOptions}
          validate={statusValidator}
          disabled={formDisabled || isDefaultAutomation}
        />

        <div className={styles.Divider} />
        <Drawer
          headerContent={
            <Heading size="M" bold component="h4" className={styles.Heading}>
              Appointment Hub
            </Heading>
          }
          initialOpen
          collapse={false}
        >
          {locations && (
            <SelectInput
              fieldName="settings.locationId"
              label="Location Override"
              options={[
                { label: "None", value: "" },
                ...locations.map((location) => ({
                  label: `${location.displayName} (ID:${location.id})${
                    !location.active ? " (inactive)" : ""
                  }`,
                  value: location.id.toString()
                }))
              ]}
              disabled={formDisabled}
            />
          )}
          <div className={styles.ToggleList}>
            <ToggleInput
              fieldName="settings.hideAddress"
              label="Address"
              labelChecked="Hidden"
              labelUnchecked="Visible"
              disabled={formDisabled}
              displayInverted
              labelsAfter
            />
            <ToggleInput
              fieldName="settings.hideDate"
              label="Date"
              labelChecked="Hidden"
              labelUnchecked="Visible"
              disabled={formDisabled}
              displayInverted
              labelsAfter
            />
            <ToggleInput
              fieldName="settings.hideTime"
              label="Time"
              labelChecked="Hidden"
              labelUnchecked="Visible"
              disabled={formDisabled}
              displayInverted
              labelsAfter
            />
            <ToggleInput
              fieldName="settings.hidePhoneNumber"
              label="Phone Number"
              labelChecked="Hidden"
              labelUnchecked="Visible"
              disabled={formDisabled}
              displayInverted
              labelsAfter
            />
            <ToggleInput
              fieldName="settings.showPatients"
              label="Patients Names"
              labelChecked="Visible"
              labelUnchecked="Hidden"
              disabled={formDisabled}
              labelsAfter
            />
          </div>
        </Drawer>

        <div className={styles.Divider} />
        <Drawer
          headerContent={
            <Heading size="M" bold component="h4" className={styles.Heading}>
              Actions
            </Heading>
          }
          initialOpen
          collapse={false}
        >
          <ActionSettingSection
            formDisabled={formDisabled}
            settingName={AutomationSettingAction.CONFIRM}
            active={!!values.settings?.actions?.confirm?.active}
            startType={
              values.settings?.actions?.confirm?.rangeStart.type as AutomationActionRangeTimeType
            }
            endType={
              values.settings?.actions?.confirm?.rangeEnd.type as AutomationActionRangeTimeType
            }
          />
          <ActionSettingSection
            formDisabled={formDisabled}
            settingName={AutomationSettingAction.RESCHEDULE}
            active={!!values.settings?.actions?.reschedule?.active}
            startType={
              values.settings?.actions?.reschedule?.rangeStart.type as AutomationActionRangeTimeType
            }
            endType={
              values.settings?.actions?.reschedule?.rangeEnd.type as AutomationActionRangeTimeType
            }
          />
          <ActionSettingSection
            formDisabled={formDisabled}
            settingName={AutomationSettingAction.CHECK_IN}
            active={!!values.settings?.actions?.checkin?.active}
            startType={
              values.settings?.actions?.checkin?.rangeStart.type as AutomationActionRangeTimeType
            }
            endType={
              values.settings?.actions?.checkin?.rangeEnd.type as AutomationActionRangeTimeType
            }
            checkInChats={checkInChats}
          />
        </Drawer>
        <PermissionsGuard requiredPermissions={[Permissions.UPDATE_AUTOMATION]}>
          <div className={styles.Divider} />
          <ButtonRow>
            <Button inline danger disabled={formDisabled} onClick={openDeleteModal}>
              Delete Journey
            </Button>
            <Button type="submit" id="saveJourneyGeneral" disabled={formDisabled}>
              Save
            </Button>
          </ButtonRow>
        </PermissionsGuard>
      </Card>
    </div>
  );
};

export default GeneralFormContent;
