import React from "react";
import { ArrayField } from "informed";

import Button from "../../../../../ui/Button";
import Heading from "../../../../../ui/Heading";
import Trash from "../../../../../ui/Icon/Trash";

import { TextInput, SelectInput } from "../../../../../ui/Input";
import { Option, InputPropsType } from "../../../../../../types";
import { isRequired } from "../../../../../../utils/validators";

import styles from "./index.module.scss";

export type Options = Array<Option>;

type PropsType = InputPropsType & {
  options: Options;
};

const mappedNameValidator = isRequired("Must be input");
const inputNameValidator = isRequired("Must be selected");

const FileCSV = ({ fieldName, options }: PropsType): JSX.Element => {
  return (
    <>
      <Heading size="META">MAP INPUT NAMES TO CSV COLUMN NAMES</Heading>

      <ArrayField name={fieldName}>
        {({ add }) => {
          return (
            <>
              <div className={styles.InputNamesList}>
                <ArrayField.Items>
                  {({ remove, index }) => {
                    return (
                      <React.Fragment key={`fileCSV_${index}`}>
                        <SelectInput
                          fieldName="inputName"
                          label=""
                          validate={inputNameValidator}
                          options={options}
                          placeholder="Choose Input Name"
                        />
                        <TextInput
                          fieldName="mappedName"
                          label=""
                          validate={mappedNameValidator}
                          placeholder="CSV Column Name"
                        />
                        <Button inline type="button" onClick={remove}>
                          <Trash />
                        </Button>
                      </React.Fragment>
                    );
                  }}
                </ArrayField.Items>
              </div>
              <br />
              <Button inline type="button" onClick={() => add()}>
                Add Input Mapping
              </Button>
            </>
          );
        }}
      </ArrayField>
    </>
  );
};

export default FileCSV;
