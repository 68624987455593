import axios from "axios";
import { jwtDecode } from "jwt-decode";
import getToken from "../utils/getToken";

import { addNotification } from "./notifications";
import { closeModal } from "./modals";

import {
  ROOT_URL,
  UPDATE_USER_SETTINGS,
  FETCH_USER,
  FETCH_USERS,
  FETCH_ROLES,
  UserTypeConstants,
  CREATE_USER,
  FETCH_PRACTITIONERS
} from "../constants";

import {
  Dispatch,
  ActionStatus,
  User,
  AnnouncementsSetting,
  UserRole,
  FeaturesSetting
} from "../types";

import { fetchPractitioners } from "./practitioners";

export type FetchUserAction = {
  type: typeof FETCH_USER;
  status: ActionStatus;
  payload?: { user: User };
};

export type FetchUserResponse = {
  status: number;
  data: {
    success: boolean;
    user: User;
  };
};

export const fetchUser = (userId: number) => (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  dispatch({
    type: FETCH_USER,
    status: ActionStatus.loading
  });

  return axios
    .get(`${ROOT_URL}/users/${userId}`, config)
    .then((response: FetchUserResponse) => {
      return dispatch({
        type: FETCH_USER,
        status: ActionStatus.success,
        payload: { user: response?.data?.user }
      });
    })
    .catch(() => {
      dispatch(
        addNotification({
          type: "error",
          title: "Failed to fetch users",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: FETCH_USER,
        status: ActionStatus.error
      });
    });
};

export type UpdateUsersSettingsData = {
  announcements?: AnnouncementsSetting;
  features?: FeaturesSetting;
  contextData?: { noteId?: number };
};

export type UpdateUserAction = {
  type: typeof UPDATE_USER_SETTINGS;
  status: ActionStatus;
  payload?: { user: User };
};

export type UpdateUserSettingsResponse = {
  status: number;
  data: {
    success: boolean;
    user: User;
  };
};

export type UpdateUserSettingsOptions = {
  silent?: boolean;
};

export const updateUserSettings =
  (
    userId: number,
    data: UpdateUsersSettingsData,
    options?: UpdateUserSettingsOptions,
    customSuccessMessage: string = "User's setting updated"
  ) =>
  (dispatch: Dispatch) => {
    const token = getToken();
    const decodedToken: { userId: string } | null = token ? jwtDecode(token) : null;
    const currentUserId = decodedToken?.userId;

    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };
    const isSilentFetch = options?.silent;

    const handleError = () => {
      dispatch({
        type: UPDATE_USER_SETTINGS,
        status: ActionStatus.error
      });
    };

    dispatch({
      type: UPDATE_USER_SETTINGS,
      status: ActionStatus.loading
    });

    return axios
      .patch(`${ROOT_URL}/users/${userId}/settings`, data, config)
      .then((payload: UpdateUserSettingsResponse) => {
        if (!payload || !payload.data.success) {
          return handleError();
        }

        fetchPractitioners()(dispatch).then(() => {
          dispatch({
            type: FETCH_PRACTITIONERS,
            status: ActionStatus.success
          });
          if (!isSilentFetch) {
            dispatch(
              addNotification({
                type: "success",
                title: "Success",
                subtitle: customSuccessMessage,
                autoDismiss: true
              })
            );
          }

          closeModal()(dispatch);
        });

        const isUpdatingSelf = currentUserId ? parseInt(currentUserId, 10) === userId : false;

        return dispatch({
          type: UPDATE_USER_SETTINGS,
          status: ActionStatus.success,
          payload: isUpdatingSelf ? { user: payload.data.user } : undefined
        });
      })
      .catch(() => {
        return handleError();
      });
  };

export type FetchUsersAction = {
  type: typeof FETCH_USERS;
  status: ActionStatus;
  payload?: { users: Array<User> };
};

export type FetchUsersResponse = {
  status: number;
  data: Array<User>;
};

export const fetchUsers =
  (organizationId: number | null, userType: UserTypeConstants) => (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: FETCH_USERS,
      status: ActionStatus.loading
    });

    return axios
      .get(`${ROOT_URL}/users/?organizationId=${organizationId}&userType=${userType}`, config)
      .then((response: FetchUsersResponse) => {
        return dispatch({
          type: FETCH_USERS,
          status: ActionStatus.success,
          payload: { users: response?.data }
        });
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to fetch users",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
        return dispatch({
          type: FETCH_USERS,
          status: ActionStatus.error
        });
      });
  };

export type FetchRolesAction = {
  type: typeof FETCH_ROLES;
  status: ActionStatus;
  payload?: { roles: Array<UserRole> };
};

export type FetchRolesResponse = {
  status: number;
  data: Array<UserRole>;
};

export const fetchRoles = (organizationId: number | null) => (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  dispatch({
    type: FETCH_ROLES,
    status: ActionStatus.loading
  });

  return axios
    .get(`${ROOT_URL}/users/roles?organizationId=${organizationId}`, config)
    .then((response: FetchRolesResponse) => {
      return dispatch({
        type: FETCH_ROLES,
        status: ActionStatus.success,
        payload: { roles: response?.data }
      });
    })
    .catch(() => {
      dispatch(
        addNotification({
          type: "error",
          title: "Failed to fetch roles",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: FETCH_ROLES,
        status: ActionStatus.error
      });
    });
};

export type CreateUserSettingsData = {
  data: User;
};

export type CreateUserAction = {
  type: typeof CREATE_USER;
  status: ActionStatus;
  payload?: { user: User };
};

export type CreateUserResponse = {
  status: number;
  data: {
    success: boolean;
    user: User;
  };
};

export const createUser = (data: User) => (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  const handleError = () => {
    dispatch(
      addNotification({
        type: "error",
        title: "Failed to create user",
        subtitle: "Please try again",
        autoDismiss: true
      })
    );
    dispatch({
      type: CREATE_USER,
      status: ActionStatus.error
    });
  };

  dispatch({
    type: CREATE_USER,
    status: ActionStatus.loading
  });

  return axios
    .post(`${ROOT_URL}/users/admin`, data, config)
    .then((payload: CreateUserResponse) => {
      if (!payload || !payload.data.success) {
        return handleError();
      }
      closeModal()(dispatch);

      return fetchUsers(
        data.organizationId || null,
        data.type
      )(dispatch).then(() => {
        dispatch({
          type: CREATE_USER,
          status: ActionStatus.success,
          payload: { user: payload.data.user }
        });
      });
    })
    .catch(() => {
      return handleError();
    });
};
