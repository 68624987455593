import { InputValue } from "../../types";

export const combine =
  (validators: Array<(value: InputValue) => string | undefined>) =>
  (value: InputValue): string | undefined => {
    const errorMessage = validators.reduce<string | undefined>((eMsg, validator) => {
      if (!eMsg) {
        eMsg = validator(value);
      }
      return eMsg;
    }, undefined);

    return errorMessage;
  };
