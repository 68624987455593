import axios from "axios";
import { ROOT_URL } from "../constants";

export const createAppointmentRequest = async (params) => {
  const config = {
    headers: { "Content-Type": "application/json" },
    params
  };
  try {
    const payload = await axios.post(`${ROOT_URL}/requests/appointments`, null, config);

    return { success: true, chatToken: payload.data.chatToken };
  } catch (e) {
    return { success: false, msg: "Failed to retrieve authorization information" };
  }
};
