/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState } from "react";
import { UserContext } from "../../providers/UserProvider";

import Text from "../../ui/Text";
import Button from "../../ui/Button";
import Card from "../../ui/Card";
import Heading from "../../ui/Heading";
import { MikataSimple, PeopleBackgroundImage } from "../../ui/Icon";
import { Form, TextInput } from "../../ui/Input";

import { useQueryString } from "../../../utils/queryStringHelpers";
import { isRequired } from "../../../utils/validators";

import styles from "./index.module.scss";
import ResponsiveComponentSwitch from "../../ui/Responsive/ResponsiveComponentSwitch";
import { ResponsiveHide } from "../../ui/Responsive";
import EnrollTwoFA from "./EnrollTwoFA";
import SignInTwoFA from "./SignInTwoFA";

type FormState = {
  email?: string;
  password?: string;
  twoFAToken?: string;
  twoFARecoveryCode?: string;
};

const emailValidator = isRequired("Please enter an email");
const passwordValidator = isRequired("Please enter a password");

const CardWrapper = ({ children }: any) => {
  return <Card className={styles.Card}>{children}</Card>;
};

const MobileWrapper = ({ children }: any) => {
  return <div className={styles.MobileWrapper}>{children}</div>;
};

const Login = () => {
  const userContext = useContext(UserContext);
  const { parsed } = useQueryString();
  const { EmrType, InstanceId, ContextualLaunchToken } = parsed;
  const [openEnrollModal, setOpenEnrollModal] = useState(false);
  const [openSignInModal, setOpenSignInModal] = useState(false);
  const [qrCode, setQRCode] = useState("");
  const [recoveryCodes, setRecoveryCodes] = useState<string[]>([]);
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [twoFAError, setTwoFAError] = useState("");

  const handleSubmit = async (formState: FormState) => {
    setTwoFAError("");

    let contextualLoginData;
    if (EmrType && InstanceId && ContextualLaunchToken) {
      contextualLoginData = {
        telusEMRCategory: EmrType,
        telusInstanceId: InstanceId,
        contextualLaunchToken: ContextualLaunchToken
      };
    }

    const loginResponse = await userContext.attemptLogin(
      {
        email: formState.email || credentials.email,
        password: formState.password || credentials.password
      },
      contextualLoginData,
      {
        twoFAToken: formState.twoFAToken || undefined,
        twoFARecoveryCode: formState.twoFARecoveryCode || undefined
      }
    );

    if (formState.email && formState.password) {
      setCredentials({ email: formState.email, password: formState.password });
    }

    if (loginResponse?.twoFAError) {
      setTwoFAError(loginResponse.twoFAError);
    } else if (loginResponse?.qrCode && loginResponse?.recoveryCodes) {
      setOpenSignInModal(false);
      setOpenEnrollModal(true);
      setQRCode(loginResponse.qrCode);
      setRecoveryCodes(loginResponse.recoveryCodes);
    } else if (loginResponse?.needTwoFA) {
      setOpenSignInModal(true);
      setCredentials({
        email: formState.email as string,
        password: formState.password as string
      });
    }
  };

  return (
    <div className={styles.Page}>
      <EnrollTwoFA
        qrCode={qrCode}
        recoveryCodes={recoveryCodes}
        isOpen={openEnrollModal}
        closeModal={() => {
          setOpenEnrollModal(false);
          setCredentials({ email: "", password: "" });
          setQRCode("");
          setRecoveryCodes([]);
          setTwoFAError("");
        }}
        error={twoFAError}
        onSubmit={(formState) => handleSubmit(formState)}
        loginLoading={userContext.loginLoading}
      />
      <SignInTwoFA
        isOpen={openSignInModal}
        closeModal={() => {
          setOpenSignInModal(false);
          setTwoFAError("");
        }}
        error={twoFAError}
        onSubmit={(formState) => handleSubmit(formState)}
        loginLoading={userContext.loginLoading}
      />

      <ResponsiveComponentSwitch DefaultComponent={CardWrapper} MobileComponent={MobileWrapper}>
        <Form
          onSubmit={(formState) => handleSubmit(formState.values as FormState)}
          initialValues={credentials}
        >
          <div className={styles.Logo}>
            <MikataSimple size={50} />
          </div>
          <Heading size="L" component="h1" className={styles.Title}>
            Welcome back!
          </Heading>

          {ContextualLaunchToken && (
            <Text className={styles.ContextualLaunchText}>Login to link your Mikata account</Text>
          )}

          <TextInput
            label="Email"
            fieldName="email"
            autoComplete="username"
            placeholder="Enter email"
            validate={emailValidator}
          />
          <TextInput
            label="Password"
            type="password"
            fieldName="password"
            autoComplete="current-password"
            placeholder="Enter password"
            validate={passwordValidator}
          />
          {userContext.loginErrorMessage && (
            <Text className={styles.ErrorMessage}>{userContext.loginErrorMessage}</Text>
          )}
          <Button
            className={styles.ButtonLogin}
            type="submit"
            disabled={userContext.loginLoading || openEnrollModal || openSignInModal}
          >
            Log In
          </Button>
        </Form>
        <div className={styles.NoAccountText}>
          <Text>Don&apos;t have an account? &nbsp;</Text>
          <a
            className={styles.ButtonNoCode}
            type="button"
            href="https://mikatahealth.com/contact"
            rel="noreferrer"
            target="_blank"
          >
            <Button inline> Sign up now</Button>
          </a>
        </div>
      </ResponsiveComponentSwitch>

      <ResponsiveHide hideOnMobile hideOnTablet>
        <PeopleBackgroundImage className={styles.Background} />
      </ResponsiveHide>
    </div>
  );
};

export default Login;
