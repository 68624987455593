import React from "react";
import Status from "../../../../ui/Status";

import { StatusComponentConfigMap } from "../../../../../types";

import styles from "./chatTags.module.scss";

type PropsType = { tagNames: string[]; isSubFlow: boolean };

const tagConfigMap: StatusComponentConfigMap = {
  isSubFlow: "pink",
  useGraphSystem: "purple",
  Instructions: "blue"
};

const ChatTags = ({ tagNames, isSubFlow }: PropsType) => {
  const tags = [];
  if (isSubFlow) tags.push("isSubFlow");
  tags.push(...tagNames);

  return (
    <>
      {tags.sort().map((name) => {
        const key = `${name}`;
        if (["Previsit", "Conversation", "Assessment", "useGraphSystem"].includes(name))
          return null;

        let label = name;
        if (name === "isSubFlow") label = "Sub Flow";

        return (
          <Status
            className={styles.Tag}
            key={key}
            value={name}
            options={[{ label, value: name }]}
            configMap={tagConfigMap}
            defaultColor="gray"
          />
        );
      })}
    </>
  );
};

export default ChatTags;
