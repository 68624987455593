import React, { useState, useEffect, useMemo } from "react";
import cx from "classnames";
import { connect } from "react-redux";

import Button from "../../../../../../ui/Button";
import Card from "../../../../../../ui/Card";
import ElementInput from "../../../../../../ui/Input/JsonLogicInput/ElementInput";
import { ElementInputValue } from "../../../../../../ui/Input/JsonLogicInput/types";
import Heading from "../../../../../../ui/Heading";
import { Check } from "../../../../../../ui/Icon";
import Text from "../../../../../../ui/Text";
import Loader from "../../../../../../ui/Loader";
import Scrollable from "../../../../../../ui/Scrollable";
import ReportTemplateCardForm from "./ReportTemplateCardForm";
import useChatContextVariables from "../../../../../../../hooks/useChatContextVariables";

import {
  ReduxStateType,
  ReportTemplate,
  ReportTemplateCard,
  ReportTemplateCardType,
  ChatFlowsNodes,
  NewNotification,
  Tag
} from "../../../../../../../types";
import { AdminModalTypes } from "../../../../../../../constants";

import {
  openModal as OpenModalAction,
  OpenModal,
  getReportTemplateDetails as getReportTemplateDetailsAction,
  updateReportTemplate as updateReportTemplateAction,
  ReportTemplateUpdateData,
  UpdateReportTemplatesOptions,
  clearReportTemplateDetails as clearReportTemplateDetailsAction,
  addNotification,
  AddNotificationAction
} from "../../../../../../../actions";

import styles from "./index.module.scss";
import AddReportTemplateCardButton from "./AddReportTemplateCardButton";

type PropsType = {
  published: boolean;
  isSubFlow: boolean;
  reportTemplateAddLoading: boolean;
  openModal: OpenModal;
  reportTemplates: Array<ReportTemplate>;
  reportTemplateCards: Array<ReportTemplateCard>;
  allChatNodes: ChatFlowsNodes[];
  allSubReportTags: Tag[];
  availableSubReportTags: Tag[];
  getReportTemplateDetails: (id: string) => void;
  updateReportTemplate: (
    id: number,
    data: ReportTemplateUpdateData,
    options: UpdateReportTemplatesOptions
  ) => void;
  clearReportTemplateDetails: () => void;
  reportsLoading: boolean;
  sendNotification: (notification: NewNotification) => AddNotificationAction;
};

const ReportBuilder = ({
  published,
  isSubFlow,
  reportTemplateAddLoading,
  openModal,
  reportTemplates,
  reportTemplateCards,
  allChatNodes,
  allSubReportTags,
  availableSubReportTags,
  getReportTemplateDetails,
  updateReportTemplate,
  clearReportTemplateDetails,
  reportsLoading,
  sendNotification
}: PropsType) => {
  const [selectedReportTemplateId, setSelectedReportTemplateId] = useState<number | null>(null);
  const [selectedReportTemplateName, setSelectedReportTemplateName] = useState<string | null>(null);
  const [selectedCategoryTagName, setSelectedCategoryTagName] = useState<string | null>(null);
  const filteredRows = reportTemplates || [];
  const viewOnly = published;

  const subReportTagIdsInUse = reportTemplateCards.map((card) => card.categoryTagId);

  const tagOptions = allSubReportTags.map((tag) => {
    return {
      label: tag.name,
      value: tag.name
    };
  });

  const { contextVariables } = useChatContextVariables(allChatNodes);
  const [temporaryCards, setTemporaryCards] = useState<ReportTemplateCard[]>([]);

  useEffect(() => {
    if (selectedReportTemplateId) {
      clearReportTemplateDetails();
      setTemporaryCards([]);
      getReportTemplateDetails(selectedReportTemplateId.toString());
    }

    return () => {
      clearReportTemplateDetails();
      setTemporaryCards([]);
    };
  }, [selectedReportTemplateId]);

  const combinedCards = useMemo(() => {
    return [...reportTemplateCards, ...temporaryCards];
  }, [reportTemplateCards, temporaryCards]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const orderRTC: any = (parentId: number, depth: number) => {
    const orderedList = combinedCards
      .filter((card) => card.parentId === parentId)
      .sort((a, b) => a.order - b.order);

    return orderedList.map((card) => {
      if (card.type === ReportTemplateCardType.SECTION) {
        return { ...card, children: orderRTC(card.id, depth + 1), depth };
      }
      return { ...card, depth };
    });
  };

  const nestedReportTemplateCards = useMemo(() => {
    return orderRTC(null, 0);
  }, [reportTemplateCards, temporaryCards]);

  const onAddCard = (card: ReportTemplateCard) => {
    setTemporaryCards([...temporaryCards, { ...card }]);
  };

  const latestCreatedCardId = temporaryCards[temporaryCards.length - 1]?.id;

  const copyToClipboard = (variable: string) => {
    navigator.clipboard.writeText(variable);
  };

  const onChangeSubReportTag = (value: ElementInputValue) => {
    if (selectedReportTemplateId) {
      const tagName = typeof value === "string" ? value.trim() : null;
      const data: ReportTemplateUpdateData = { tagName };
      setSelectedCategoryTagName(tagName);
      updateReportTemplate(selectedReportTemplateId, data, { silent: true });
    }
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.ColumnWrapper}>
        <Scrollable>
          <Card>
            <Heading size="M" component="h1" className={cx(styles.Heading, styles.BottomBorder)}>
              Reports
              {!published && (
                <Button
                  id="addReport"
                  inline
                  onClick={() => {
                    openModal(AdminModalTypes.ADD_REPORT_TEMPLATE, { chatFlowId: 1 });
                  }}
                >
                  Add Report +
                </Button>
              )}
            </Heading>

            {reportTemplateAddLoading && <Loader small center />}

            <div className={styles.InfoGroup}>
              {filteredRows &&
                filteredRows.map((reportTemplate) => {
                  return (
                    <div
                      className={cx(styles.InfoGroup, {
                        [styles.InfoGroupActive]: selectedReportTemplateId === reportTemplate.id
                      })}
                      key={reportTemplate.id}
                    >
                      <button
                        id={`id-${reportTemplate.id}`}
                        key={reportTemplate.id}
                        type="button"
                        className={cx(styles.InfoValueText, {
                          [styles.InfoValueTextActive]:
                            selectedReportTemplateId === reportTemplate.id
                        })}
                        onClick={async () => {
                          setSelectedReportTemplateId(reportTemplate.id);
                          setSelectedReportTemplateName(reportTemplate.name);
                          setSelectedCategoryTagName(reportTemplate.categoryTagName);
                        }}
                      >
                        {reportTemplate.name}
                      </button>
                    </div>
                  );
                })}
            </div>
          </Card>
          {availableSubReportTags && availableSubReportTags.length !== 0 && (
            <Card>
              <Heading size="M" component="h1" className={cx(styles.Heading, styles.BottomBorder)}>
                Tags
              </Heading>
              <div className={styles.InfoGroup}>
                {availableSubReportTags.map((tag) => {
                  const showCheck = subReportTagIdsInUse.includes(tag.id);
                  return (
                    <div key={tag.id} className={styles.TagListItem}>
                      <div className={styles.InfoValueTag}>{tag.name}</div>
                      {showCheck && <Check size={18} />}
                    </div>
                  );
                })}
              </div>
            </Card>
          )}
          {contextVariables && contextVariables.length !== 0 && (
            <Card>
              <Heading size="M" component="h1" className={cx(styles.Heading, styles.BottomBorder)}>
                Available Variables
              </Heading>
              <div className={styles.InfoGroup}>
                {contextVariables &&
                  contextVariables.map((variable) => {
                    return (
                      <div className={cx(styles.InfoGroup)} key={variable.inputName}>
                        <button
                          key={variable.inputName}
                          type="button"
                          className={cx(styles.InfoValueText)}
                          onClick={async () => {
                            copyToClipboard(`[${variable.inputName}]`);
                            sendNotification({
                              type: "success",
                              title: "Variable copied",
                              subtitle: `[${variable.inputName}] has been copied to your clipboard`,
                              autoDismiss: true
                            });
                          }}
                        >
                          <Text>{variable.inputName}</Text>
                        </button>
                      </div>
                    );
                  })}
              </div>
            </Card>
          )}
        </Scrollable>
      </div>

      {reportsLoading && <Loader small center />}
      {!reportsLoading && (
        <div className={styles.ColumnWrapper}>
          <Scrollable>
            <div className={styles.FormWrapper}>
              <div className={styles.HeadingWrapper}>
                <Heading className={styles.ReportTemplateHeading}>
                  {selectedReportTemplateName}
                </Heading>
                {isSubFlow && selectedReportTemplateId && (
                  <div className={styles.ReportTag}>
                    <Heading size="META" component="h5" className={styles.ReportTagHeading}>
                      tagged
                    </Heading>
                    <ElementInput
                      elementType="variable"
                      value={selectedCategoryTagName || ""}
                      options={tagOptions}
                      onChange={onChangeSubReportTag}
                      placeholder="Add Tag"
                      isExtensible
                      isClearable
                      disabled={viewOnly}
                    />
                  </div>
                )}
              </div>
              {nestedReportTemplateCards &&
                nestedReportTemplateCards.length > 0 &&
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                nestedReportTemplateCards.map((card: any) => {
                  return (
                    <ReportTemplateCardForm
                      key={card.id}
                      card={card}
                      viewOnly={viewOnly}
                      onAddCard={onAddCard}
                      setTemporaryCards={setTemporaryCards}
                      contextVariables={contextVariables}
                      latestCreatedCardId={latestCreatedCardId}
                    />
                  );
                })}

              {!viewOnly && selectedReportTemplateId && nestedReportTemplateCards.length === 0 && (
                <div className={styles.ButtonWrapper}>
                  <AddReportTemplateCardButton
                    onAddCard={onAddCard}
                    direction="first"
                    reportTemplateId={selectedReportTemplateId}
                  />
                </div>
              )}
            </div>
          </Scrollable>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ chats, reportTemplates }: ReduxStateType) => {
  const selectedChat = chats.chats.filter((chat) => chat.id === chats.chatDetails.chatFlowId);
  return {
    published: !!chats.chatDetails.published,
    isSubFlow: selectedChat[0] ? selectedChat[0].isSubFlow : false,
    reportTemplateAddLoading: reportTemplates.reportTemplateAddLoading,
    reportTemplates: chats.chatDetails.reportTemplates,
    reportTemplateCards: reportTemplates.reportTemplateCards,
    allSubReportTags: reportTemplates.allSubReportTags,
    availableSubReportTags: reportTemplates.availableSubReportTags,
    allChatNodes: chats.chatDetails.allMessageTemplates,
    reportsLoading: reportTemplates.reportTemplateFetchLoading
  };
};

export default connect(mapStateToProps, {
  openModal: OpenModalAction,
  getReportTemplateDetails: getReportTemplateDetailsAction,
  updateReportTemplate: updateReportTemplateAction,
  clearReportTemplateDetails: clearReportTemplateDetailsAction,
  sendNotification: addNotification
})(ReportBuilder);
