import React from "react";
import cx from "classnames";

import Text from "../Text";

import styles from "./index.module.scss";
import { OptionWithIcon } from "../../../types";

type PropsType = {
  selectedFilters: string[];
  filterOptions: OptionWithIcon[];
  className?: string;
  filterOptionClassName?: string;
  filterBadgeClassName?: string;
  onClick: (value: string) => void;
};

export const Filters = ({
  selectedFilters,
  filterOptions,
  className,
  filterOptionClassName,
  filterBadgeClassName,
  onClick
}: PropsType) => {
  return (
    <div className={cx(styles.FilterList, className)}>
      {filterOptions.map((filterOption) => {
        return (
          <button
            id={`filter-${filterOption.value}`}
            key={filterOption.value}
            type="button"
            className={cx(styles.Filter, filterOptionClassName)}
            onClick={() => {
              onClick(filterOption.value.toString());
            }}
          >
            <span className={styles.FilterBadgeWrapper} key={filterOption.value}>
              <div
                className={cx(styles.FilterBadge, filterBadgeClassName, {
                  [styles.FilterBadgeActive]: selectedFilters.includes(
                    filterOption.value.toString()
                  )
                })}
              >
                {filterOption.icon && filterOption.icon}
                <Text
                  align="left"
                  component="span"
                  size="S"
                  bold
                  className={cx(styles.FilterLabel, {
                    [styles.FilterLabelActive]: selectedFilters.includes(
                      filterOption.value.toString()
                    )
                  })}
                >
                  {filterOption.label}
                </Text>
              </div>
            </span>
          </button>
        );
      })}
    </div>
  );
};
