import React, { useContext, useState } from "react";

import { connect } from "react-redux";
import { jwtDecode } from "jwt-decode";

import {
  closeModal as closeModalAction,
  openModal as openModalAction,
  OpenModal,
  updateUserSettings as updateUserSettingsAction,
  UpdateUsersSettingsData
} from "../../../../../actions";

import getToken from "../../../../../utils/getToken";
import { UserContext } from "../../../../providers/UserProvider";
import { ModalTypes } from "../../../../../constants";

import { ReduxStateType } from "../../../../../types";

import Button from "../../../../ui/Button";
import Card, { CardSection } from "../../../../ui/Card";
import Heading from "../../../../ui/Heading";
import { Info } from "../../../../ui/Icon";
import { Form, SelectInput } from "../../../../ui/Input";
import Loader from "../../../../ui/Loader";
import Text from "../../../../ui/Text";
import Tooltip from "../../../../ui/Tooltip";
import AddToCustomVocabForm from "./AddToCustomVocabForm";

import styles from "./index.module.scss";

const NEVER_DELETE_POLICY = "never";
const confirmationModalTitle = "Are you sure?";
const confirmationModalWarningText =
  "Once data is deleted, it cannot be recovered. Are you sure you want to update this setting? The change will take effect within 48 hours.";

type PropsType = {
  updateLoading: boolean;
  closeModal: () => void;
  openModal: OpenModal;
  updateUserSettings: (userId: number, updates: UpdateUsersSettingsData) => void;
};

type FormState = {
  dataRemovalCycleInDays: string | number;
  customVocabulary: string[];
};

const dataRemovalCycleInDaysOptions = [
  { label: "1 day", value: "1" },
  {
    label: "7 days",
    value: "7"
  },
  {
    label: "1 month",
    value: "30"
  },
  {
    label: "3 months",
    value: "90"
  },
  {
    label: "6 months",
    value: "180"
  },
  {
    label: "1 year",
    value: "365"
  },
  {
    label: "Don't Delete",
    value: NEVER_DELETE_POLICY
  }
];

const dataRemovalCycleDefaultOption = dataRemovalCycleInDaysOptions.filter(
  (option) => option.value === NEVER_DELETE_POLICY
);

const General = ({ updateLoading, closeModal, openModal, updateUserSettings }: PropsType) => {
  const { settings } = useContext(UserContext);
  const { features } = settings;
  const token = getToken();
  const decodedToken: { userId: string; organizationId: string } | null = token
    ? jwtDecode(token)
    : null;
  const currentUserId = decodedToken?.userId;

  const dataRemovalPolicy = features?.scribe?.dataRemovalPolicyInDays ?? NEVER_DELETE_POLICY;
  const [customVocabulary, setCustomVocabulary] = useState<string[]>(
    features?.scribe?.customVocabulary || []
  );

  const initialFormState: FormState = {
    dataRemovalCycleInDays:
      dataRemovalPolicy === NEVER_DELETE_POLICY
        ? NEVER_DELETE_POLICY
        : dataRemovalPolicy?.toString() ?? dataRemovalCycleDefaultOption[0].value,
    customVocabulary
  };

  const save = async (formValues: FormState) => {
    const { dataRemovalCycleInDays } = formValues;
    const neverDeletePolicy = dataRemovalCycleInDays === NEVER_DELETE_POLICY;
    const dataRemovalPolicyInDays = neverDeletePolicy ? null : Number(dataRemovalCycleInDays);

    const updateSettings = async () => {
      if (currentUserId && settings?.features?.scribe) {
        await updateUserSettings(Number(currentUserId), {
          features: {
            scribe: {
              ...settings.features.scribe,
              dataRemovalPolicyInDays,
              customVocabulary
            }
          }
        });
      }
    };

    if (neverDeletePolicy) {
      await updateSettings();
    } else {
      openModal(ModalTypes.CONFIRMATION, {
        title: confirmationModalTitle,
        warningText: confirmationModalWarningText,
        onConfirmation: async () => {
          await updateSettings();
          closeModal();
        }
      });
    }
  };

  if (updateLoading) {
    return <Loader center />;
  }

  return (
    <Form
      initialValues={initialFormState}
      onSubmit={(formState) => save(formState.values as FormState)}
    >
      <Card className={styles.Card}>
        <CardSection title="Data Management" classname={styles.CustomCardSection}>
          <div className={styles.CardSection}>
            <div className={styles.HeadingRow}>
              <Heading size="META">Data Retention</Heading>
              <Tooltip
                icon={
                  <div className={styles.Icon}>
                    <Info size={15} />
                  </div>
                }
                position="topRight"
              >
                This data includes audio transcripts and any notes, letters, or other documents
                generated with Mika AI Scribe.
              </Tooltip>
            </div>

            <div className={styles.TextMarginBottom}>
              <Text size="S">Automatically delete Mika AI Scribe data from my account after</Text>
            </div>
            <SelectInput
              fieldName="dataRemovalCycleInDays"
              options={dataRemovalCycleInDaysOptions}
            />
          </div>
        </CardSection>
        <AddToCustomVocabForm
          customVocabulary={customVocabulary}
          setCustomVocabulary={setCustomVocabulary}
        />
        <div className={styles.SaveButtonRow}>
          <Button id="saveUserSettings" type="submit">
            Save
          </Button>
        </div>
      </Card>
    </Form>
  );
};

const mapStateToProps = ({ users }: ReduxStateType) => ({
  updateLoading: users.updateLoading
});

export default connect(mapStateToProps, {
  closeModal: closeModalAction,
  openModal: openModalAction,
  updateUserSettings: updateUserSettingsAction
})(General);
