import React, { useState } from "react";
import { connect } from "react-redux";
import cx from "classnames";

import Button from "../../../../../ui/Button";
import { Plus } from "../../../../../ui/Icon";
import Card from "../../../../../ui/Card";
import Heading from "../../../../../ui/Heading";
import Loader from "../../../../../ui/Loader";

import AddTouchpoint from "./AddTouchpoint";
import EditTouchpoint from "./EditTouchpoint";
import JourneyTimeline from "../../JourneyTimeline";

import { PermissionsGuard } from "../../../../../../hooks/usePermissions";

import {
  fetchAutomation as fetchAutomationAction,
  FetchAutomationOptions
} from "../../../../../../actions";

import {
  AutomationDetails,
  AutomatedMessageConfiguration,
  FormModes,
  MessageSchedule,
  Permissions
} from "../../../../../../types";

import styles from "./index.module.scss";

type TouchpointsFormModes = FormModes | null;

type PropsType = {
  automation?: AutomationDetails;
  fetchAutomation: (automationId: string, options?: FetchAutomationOptions) => void;
  initialTouchpointId: number | null;
};

const TouchpointsForm = ({
  automation,
  fetchAutomation,
  initialTouchpointId = null
}: PropsType) => {
  const [mode, setMode] = useState<TouchpointsFormModes>(FormModes.EDIT);
  const [selectedEditTouchpointId, setSelectedEditTouchpointId] = useState<number | null>(
    initialTouchpointId
  );

  const { messageConfigurations = [] } = automation || {};

  const selectedMessageConfiguration = messageConfigurations?.find(
    (messageConfig) => messageConfig.id === selectedEditTouchpointId
  );

  const touchpoints: AutomatedMessageConfiguration[] = messageConfigurations
    ? messageConfigurations.map((messageConfiguration) => {
        return {
          id: messageConfiguration.id,
          active: messageConfiguration.active,
          displayName: messageConfiguration.displayName,
          note: messageConfiguration.note,
          topic: messageConfiguration.topic,
          automationId: messageConfiguration.automationId,
          scheduleId: messageConfiguration.scheduleId,
          templateId: messageConfiguration.templateId,
          multiTemplateId: messageConfiguration.multiTemplateId,
          templateDisplayName: messageConfiguration?.templateData?.displayName,
          multiTemplateDisplayName: messageConfiguration?.multiTemplateData?.displayName || "",
          createdAt: messageConfiguration.createdAt,
          updatedAt: messageConfiguration.updatedAt,
          chatFlows: messageConfiguration.chatFlows
        } as AutomatedMessageConfiguration;
      })
    : [];

  const messageSchedules: MessageSchedule[] = messageConfigurations
    ? messageConfigurations.map((messageConfiguration) => {
        return { ...messageConfiguration.scheduleData } as MessageSchedule;
      })
    : [];

  const selectTouchpoint = (touchpointId: number) => {
    setMode(FormModes.EDIT);
    setSelectedEditTouchpointId(touchpointId);
  };

  if (!automation) return <Loader screen />;

  return (
    <div className={styles.Wrapper}>
      <Card>
        <Heading size="M">Touchpoint Configuration</Heading>
        <Heading size="S" bold>
          Appointment Journey
        </Heading>
        {messageConfigurations && (
          <div className={styles.TouchpointsTimelineRow}>
            <JourneyTimeline
              published={automation?.status === "published"}
              touchpoints={touchpoints}
              messageSchedules={messageSchedules}
              onTouchpointSelection={selectTouchpoint}
              selectedTouchpointId={selectedEditTouchpointId?.toString() || undefined}
            />
          </div>
        )}

        <PermissionsGuard
          requiredPermissions={[Permissions.CREATE_AUTOMATED_MESSAGE_CONFIGURATION]}
        >
          <Button
            id="addTouchpoint"
            inline
            disabled={mode === FormModes.CREATE}
            onClick={() => {
              if (automation) {
                setMode(FormModes.CREATE);
                setSelectedEditTouchpointId(null);
              }
            }}
          >
            <Plus size={16} />
            <span className={styles.AddButtonText}>Touchpoint</span>
          </Button>
        </PermissionsGuard>
      </Card>

      <Card
        className={cx({
          [styles.TouchpointFormHidden]:
            !mode || (mode === FormModes.EDIT && !selectedMessageConfiguration)
        })}
      >
        {automation && mode === FormModes.CREATE && (
          <AddTouchpoint
            automationId={parseInt(automation.id, 10)}
            onSuccess={() => {
              setMode(null);
              fetchAutomation(automation.id);
            }}
          />
        )}
        {automation && selectedMessageConfiguration && FormModes.EDIT && (
          <div key={`editTouchpoint-${selectedMessageConfiguration.id}`}>
            <EditTouchpoint
              automationId={parseInt(automation.id, 10)}
              touchpoint={selectedMessageConfiguration}
            />
          </div>
        )}
      </Card>
    </div>
  );
};

export default connect(null, {
  fetchAutomation: fetchAutomationAction
})(TouchpointsForm);
