import React from "react";

type PropsType = {
  size?: number;
};

const Book = ({ size = 30 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 4.5C3 2.29086 4.79086 0.5 7 0.5H19C20.1046 0.5 21 1.39543 21 2.5V18.5V21.5C21 22.6046 20.1046 23.5 19 23.5H7H6.5C4.567 23.5 3 21.933 3 20C3 19.9188 3.00277 19.8382 3.00821 19.7584C3.00276 19.6729 3 19.5868 3 19.5V4.5ZM19 2.5V16.5H6.5C5.9632 16.5 5.45463 16.6208 5 16.8368V4.5C5 3.39543 5.89543 2.5 7 2.5H19ZM5.01782 19.7681C5.14882 20.7459 5.98634 21.5 7 21.5H19V18.5H6.5C5.75044 18.5 5.12929 19.0498 5.01782 19.7681ZM8 6C7.44772 6 7 6.44772 7 7C7 7.55228 7.44772 8 8 8L16 8C16.5523 8 17 7.55229 17 7C17 6.44772 16.5523 6 16 6L8 6Z"
        fill="#1D2029"
      />
    </svg>
  );
};

export default Book;
