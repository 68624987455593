import axios from "axios";
import { ROOT_URL } from "../constants";

const getJwt = () => {
  return localStorage.getItem("jwt");
};

export const getRequisitionUrl = async (requestId, uploadId) => {
  const config = {
    headers: { Authorization: `${getJwt()}`, "Content-Type": "application/json" }
  };
  try {
    const resp = await axios.get(
      `${ROOT_URL}/requests/appointments/${requestId}/download-attachment/${uploadId}`,
      config
    );
    return resp.data;
  } catch (e) {
    return { success: false };
  }
};
