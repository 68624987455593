import React, { Component } from "react";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

import { getOAuthAccessToken } from "../../lib";
import Loading from "../Loading";
import { UserTypeConstants } from "../../constants";

class OAuthCallback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      oauthResult: null,
      userType: "",
      organizationId: ""
    };
  }

  async componentDidMount() {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const tempCode = params.get("code");
    const error = params.get("error");
    const errorDesc = params.get("error_description");

    const jwt = localStorage.getItem("jwt");
    if (jwt) {
      const payload = jwtDecode(jwt);
      if (payload) {
        this.setState({ userType: payload.userType, organizationId: payload.organizationId });
      }
    }

    if (tempCode && !error) {
      const result = await getOAuthAccessToken(tempCode);

      this.setState({ oauthResult: result });
    } else {
      this.setState({ oauthResult: { success: false, msg: errorDesc } });
    }
  }

  render() {
    const { oauthResult, userType, organizationId } = this.state;
    const styles = {
      img: {
        width: "10%",
        textAlign: "left",
        marginTop: 100
      },
      container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }
    };

    let clickHereLink;
    if (userType === UserTypeConstants.MIKATA_ADMIN) {
      clickHereLink = `/mikata-admin-dashboard/organizations/${organizationId}/integration`;
    } else {
      clickHereLink = `/settings`;
    }

    if (!oauthResult) {
      return <Loading text="Completing OAuth2 authorization..." />;
    }
    if (oauthResult.success) {
      return (
        <div style={styles.container}>
          <img src="/img/success.svg" alt="" style={styles.img} />
          <h3>Successfully completed OAuth2 authorization.</h3>
          <p>
            <Link style={{ textDecoration: "underline" }} to={clickHereLink}>
              Click here
            </Link>{" "}
            to return to the settings page.
          </p>
        </div>
      );
    }
    return (
      <div style={styles.container}>
        <img src="/img/failure.svg" alt="" style={styles.img} />
        <h3>Failed to completed OAuth2 authorization.</h3>
        <p>{oauthResult.msg} </p>
        <br />
        <p>
          <Link style={{ textDecoration: "underline" }} to="/settings">
            Click here
          </Link>{" "}
          to return to the settings page to try again.
        </p>
      </div>
    );
  }
}

export default OAuthCallback;
