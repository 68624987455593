import { useEffect, useRef, useState } from "react";
import { AppMetricType, saveAppMetrics } from "./useAppAnalytics";

/**
 * Screen Wake Lock API - https://developer.mozilla.org/en-US/docs/Web/API/Screen_Wake_Lock_API#interfaces
 * Document: visibilitychange event - https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event
 */

const useScreenLock = async (keepAwake: boolean, context: { appointmentId?: string }) => {
  const wakeLock = useRef<WakeLockSentinel | null>();
  const [isVisible, setIsVisible] = useState(true);

  const getLock = async () => {
    try {
      wakeLock.current = await navigator?.wakeLock?.request("screen");
    } catch (error) {
      const message = `Failed to establish wakeLock: ${error}`;
      const errorInfo = `AppointmentId: ${context?.appointmentId}`;
      saveAppMetrics([
        {
          type: AppMetricType.APPLICATION_ERROR,
          errorMessage: message,
          errorInfo,
          userAgent: window.navigator.userAgent || "",
          timestamp: new Date().toISOString()
        }
      ]);
    }
  };

  const releaseLock = async () => {
    try {
      await wakeLock.current?.release();
      wakeLock.current = null;
    } catch (error) {
      const message = `Failed to release wakeLock: ${error}`;
      const errorInfo = `AppointmentId: ${context?.appointmentId}`;
      saveAppMetrics([
        {
          type: AppMetricType.APPLICATION_ERROR,
          errorMessage: message,
          errorInfo,
          userAgent: window.navigator.userAgent || "",
          timestamp: new Date().toISOString()
        }
      ]);
    }
  };

  // maintain understanding of application window visibility
  useEffect(() => {
    const handleVisibilityEvent = (event: Event) => {
      const documentRef = event.target;
      const appDocumentIsVisible = !(documentRef as Document)?.hidden;

      setIsVisible(appDocumentIsVisible);
    };

    document.addEventListener("visibilitychange", handleVisibilityEvent);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityEvent);
    };
  }, []);

  // request and release screen lock
  useEffect(() => {
    if (keepAwake && isVisible) {
      getLock();
    }

    return () => {
      releaseLock();
    };
  }, [keepAwake, isVisible]);
};

export default useScreenLock;
