import React from "react";
import isEqual from "lodash/isEqual";
import { useFormState } from "informed";

import Heading from "../../../ui/Heading";
import { RadioGroup, SelectInput, TextInput, JsonContentInput } from "../../../ui/Input";
import Button from "../../../ui/Button";

import { timezones } from "../../../../constants";
import { FormState } from ".";
import { isEmail, isRequired } from "../../../../utils/validators";

import styles from "./index.module.scss";

type PropsType = {
  initialFormState: FormState;
  closeModal: () => void;
};

const timezoneOptions = timezones.map((timezone) => {
  return {
    label: timezone,
    value: timezone
  };
});

const syncLockOptions = [
  { label: "Locked", value: "true" },
  { label: "Unlocked", value: "false" }
];

const nonRequiredEmailValidator = isEmail("Please enter a valid email");

const EditLocationForm = ({ initialFormState, closeModal }: PropsType) => {
  const formState = useFormState();
  const values = formState.values as FormState;
  const hasFormChanges = !isEqual({ ...values }, initialFormState);

  return (
    <>
      <TextInput
        fieldName="displayName"
        label="display name"
        placeholder="Enter display name (for text messages)"
        validate={isRequired("Please enter a display name")}
      />
      <TextInput
        fieldName="street"
        label="street address"
        placeholder="Enter street address"
        validate={isRequired("Please enter a street address")}
      />
      <TextInput
        fieldName="city"
        label="city"
        placeholder="Enter city"
        validate={isRequired("Please enter a city")}
      />
      <TextInput
        fieldName="state"
        label="state/province"
        placeholder="Enter state/province"
        validate={isRequired("Please enter a state/province")}
      />
      <TextInput
        fieldName="country"
        label="country"
        placeholder="Enter country"
        validate={isRequired("Please enter a country")}
      />
      <TextInput
        fieldName="postalCode"
        label="postal code"
        placeholder="Enter postal code"
        validate={isRequired("Please enter a postal code")}
      />
      <SelectInput
        fieldName="timezone"
        label="timezone"
        options={timezoneOptions}
        placeholder="Select timezone"
        validate={isRequired("Please select a timezone")}
      />
      <TextInput
        fieldName="email"
        label="email"
        placeholder="Enter email"
        validate={nonRequiredEmailValidator}
      />
      <TextInput
        fieldName="telecom"
        label="phone number"
        placeholder="Enter phone number"
        validate={isRequired("Please enter a phone number")}
      />
      <TextInput
        fieldName="telecomExtension"
        label="phone extension"
        placeholder="Enter phone extension"
      />
      <Heading size="META">SPECIAL INSTRUCTIONS</Heading>
      <JsonContentInput fieldName="information" />
      <RadioGroup fieldName="syncLock" label="Location Sync Data Lock" options={syncLockOptions} />

      <hr className={styles.Divider} />

      <div className={styles.ButtonGroup}>
        <Button inline onClick={closeModal}>
          Cancel
        </Button>
        <Button type="submit" disabled={!hasFormChanges}>
          Save
        </Button>
      </div>
    </>
  );
};

export default EditLocationForm;
