import React from "react";

type PropsType = {
  size?: number;
};

const Rules = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.926 2H15.74a.74.74 0 0 0-.741.74v2.093h-1.22a2.5 2.5 0 0 0-2.5 2.5v3.964H8.668V9.741A.74.74 0 0 0 7.926 9H2.74A.74.74 0 0 0 2 9.74v5.186c0 .409.332.74.74.74h5.186a.74.74 0 0 0 .74-.74v-2.629h2.615v4.37a2.5 2.5 0 0 0 2.5 2.5H15v1.759c0 .409.332.74.74.74h5.186a.74.74 0 0 0 .74-.74V15.74a.74.74 0 0 0-.74-.741H15.74a.74.74 0 0 0-.741.74v2.427h-1.22a1.5 1.5 0 0 1-1.5-1.5V7.333a1.5 1.5 0 0 1 1.5-1.5H15v2.093c0 .409.332.74.74.74h5.186a.74.74 0 0 0 .74-.74V2.74a.74.74 0 0 0-.74-.741Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Rules;
