import { ScribeProductKeys } from "../constants";
import { BillingConfigSetting, Subscriptions } from "../types";

const PAID_BY_PROVIDER = "Provider";
const PAID_BY_CLINIC = "Clinic";

export const getProviderScribeBilling = (
  providerUserId: number,
  orgBilling?: BillingConfigSetting
) => {
  const scribeSubscriptions: Subscriptions = [];

  orgBilling?.subscriptions?.forEach((sub) => {
    const hasScribePlan = sub?.lineItems?.some((lineItem) =>
      ScribeProductKeys.includes(lineItem.productKey)
    );
    if (hasScribePlan) {
      scribeSubscriptions.push(sub);
    }
  });

  const providerScribeBilling = scribeSubscriptions?.find((sub) => sub.userId === providerUserId);
  const orgScribeBilling = scribeSubscriptions?.find((sub) => sub.userId === null);

  if (providerScribeBilling) {
    return {
      advancedSubscriptionId: providerScribeBilling.id,
      paidBy: PAID_BY_PROVIDER
    };
  }

  if (orgScribeBilling) {
    return {
      advancedSubscriptionId: orgScribeBilling.id,
      paidBy: PAID_BY_CLINIC
    };
  }

  return undefined;
};

export default getProviderScribeBilling;
