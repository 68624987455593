import React, { useMemo } from "react";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";

import { Form } from "../../../../../ui/Input";
import Loader from "../../../../../ui/Loader";

import { usePermissions } from "../../../../../../hooks/usePermissions";

import {
  updateAutomation,
  UpdateAutomationData,
  openModal as openModalAction,
  OpenModal
} from "../../../../../../actions";

import { AdminModalTypes } from "../../../../../../constants";

import { JourneyGeneralFormState, journeyGeneralFormValidator } from "../JourneyFormValidator";

import styles from "./index.module.scss";

import {
  AutomationDetails,
  Location,
  ReduxStateType,
  AutomationStatus,
  AutomationActionRangeTimeType,
  AutomationActionRangeTimeTriggerUnit,
  ChatFlow,
  Permissions
} from "../../../../../../types";

import GeneralFormContent from "./GeneralFormContent";

type PropsType = {
  automation?: AutomationDetails;
  locations: Array<Location>;
  checkInChats: Array<ChatFlow>;
  loading?: boolean;
  closeModal: () => void;
  openModal: OpenModal;
  updateAutomationDetails: (automationId: string, automationData: UpdateAutomationData) => void;
};

const initialState = {
  displayName: "",
  description: "",
  status: AutomationStatus.draft,
  settings: {
    locationId: null,
    hideAddress: false,
    hidePhoneNumber: false,
    hideDate: false,
    hideTime: false,
    showPatients: false,
    actions: {
      reschedule: {
        active: true,
        rangeStart: {
          type: AutomationActionRangeTimeType.BOOKING
        },
        rangeEnd: {
          type: AutomationActionRangeTimeType.BEFORE_APPOINTMENT,
          value: 0,
          unit: AutomationActionRangeTimeTriggerUnit.HOURS
        }
      },
      confirm: {
        active: true,
        rangeStart: {
          type: AutomationActionRangeTimeType.BOOKING
        },
        rangeEnd: {
          type: AutomationActionRangeTimeType.BEFORE_APPOINTMENT,
          value: 0,
          unit: AutomationActionRangeTimeTriggerUnit.HOURS
        }
      },
      checkin: {
        active: false,
        rangeStart: {
          type: AutomationActionRangeTimeType.BEFORE_APPOINTMENT,
          value: 1,
          unit: AutomationActionRangeTimeTriggerUnit.HOURS
        },
        rangeEnd: {
          type: AutomationActionRangeTimeType.AFTER_APPOINTMENT,
          value: 1,
          unit: AutomationActionRangeTimeTriggerUnit.HOURS
        },
        chatFlowId: null
      }
    }
  }
};

const General = ({
  automation,
  locations,
  checkInChats,
  updateAutomationDetails,
  loading,
  closeModal,
  openModal
}: PropsType): JSX.Element | null => {
  const isDefaultAutomation = Boolean(
    automation &&
      automation.status === "published" &&
      automation.locations?.length === 0 &&
      automation.reasons?.length === 0 &&
      automation.practitioners?.length === 0
  );
  const userCanEdit = usePermissions([Permissions.UPDATE_AUTOMATION]);

  const formDisabled = loading || !userCanEdit;

  const initialFormState = useMemo(() => {
    if (automation) {
      const newFormState: JourneyGeneralFormState = cloneDeep(initialState);

      newFormState.displayName = automation.displayName;
      newFormState.description = automation.description;
      newFormState.status = automation.status;

      newFormState.settings = automation.settings
        ? {
            ...automation.settings,
            locationId: automation.settings.locationId
              ? automation.settings.locationId.toString()
              : null,
            hideAddress: automation.settings.hideAddress || false,
            hidePhoneNumber: automation.settings.hidePhoneNumber || false,
            hideDate: automation.settings.hideDate || false,
            hideTime: automation.settings.hideTime || false,
            showPatients: automation.settings.showPatients || false,
            actions: {
              reschedule: {
                active: automation.settings.actions?.reschedule?.active ?? true,
                rangeStart: {
                  type:
                    automation.settings.actions?.reschedule?.rangeStart.type ??
                    AutomationActionRangeTimeType.BOOKING,
                  value: automation.settings.actions?.reschedule?.rangeStart.value ?? undefined,
                  unit: automation.settings.actions?.reschedule?.rangeStart.unit ?? undefined
                },
                rangeEnd: {
                  type:
                    automation.settings.actions?.reschedule?.rangeEnd.type ??
                    AutomationActionRangeTimeType.UPON_APPOINTMENT_START,
                  value: automation.settings.actions?.reschedule?.rangeEnd.value ?? undefined,
                  unit: automation.settings.actions?.reschedule?.rangeEnd.unit ?? undefined
                }
              },
              confirm: {
                active: automation.settings.actions?.confirm?.active ?? true,
                rangeStart: {
                  type:
                    automation.settings.actions?.confirm?.rangeStart.type ??
                    AutomationActionRangeTimeType.BOOKING,
                  value: automation.settings.actions?.confirm?.rangeStart.value ?? undefined,
                  unit: automation.settings.actions?.confirm?.rangeStart.unit ?? undefined
                },
                rangeEnd: {
                  type:
                    automation.settings.actions?.confirm?.rangeEnd.type ??
                    AutomationActionRangeTimeType.UPON_APPOINTMENT_START,
                  value: automation.settings.actions?.confirm?.rangeEnd.value ?? undefined,
                  unit: automation.settings.actions?.confirm?.rangeEnd.unit ?? undefined
                }
              },
              checkin: {
                active: automation.settings.actions?.checkin?.active ?? false,
                rangeStart: {
                  type:
                    automation.settings.actions?.checkin?.rangeStart.type ??
                    AutomationActionRangeTimeType.BEFORE_APPOINTMENT,
                  value: automation.settings.actions?.checkin?.rangeStart.value ?? 1,
                  unit:
                    automation.settings.actions?.checkin?.rangeStart.unit ??
                    AutomationActionRangeTimeTriggerUnit.HOURS
                },
                rangeEnd: {
                  type:
                    automation.settings.actions?.checkin?.rangeEnd.type ??
                    AutomationActionRangeTimeType.AFTER_APPOINTMENT,
                  value: automation.settings.actions?.checkin?.rangeEnd.value ?? 1,
                  unit:
                    automation.settings.actions?.checkin?.rangeEnd.unit ??
                    AutomationActionRangeTimeTriggerUnit.HOURS
                },
                chatFlowId: automation.settings.actions?.checkin?.chatFlowId ?? null
              }
            }
          }
        : initialState.settings;

      return newFormState;
    }

    return null;
  }, [automation]);

  const journeyFormValidator = useMemo(() => {
    return journeyGeneralFormValidator(isDefaultAutomation);
  }, [isDefaultAutomation]);

  const save = (formValues: JourneyGeneralFormState) => {
    const settings = formValues.settings
      ? {
          ...formValues.settings,
          locationId: formValues.settings.locationId
            ? parseInt(formValues.settings.locationId, 10)
            : null
        }
      : null;

    if (automation) {
      updateAutomationDetails(automation.id, {
        displayName: formValues.displayName || "",
        description: formValues.description || "",
        status: formValues.status,
        settings
      });
    }
    return null;
  };

  const openDeleteModal = () => {
    if (automation) {
      openModal(AdminModalTypes.DELETE_AUTOMATION, {
        automation,
        onSuccess: () => closeModal()
      });
    }
  };

  if (!automation || !initialFormState) return <Loader screen />;

  return (
    <div className={styles.Wrapper}>
      <Form
        onSubmit={(formState) => save(formState.values as JourneyGeneralFormState)}
        initialValues={initialFormState}
        validateFields={(values) => journeyFormValidator(values as JourneyGeneralFormState)}
      >
        <GeneralFormContent
          locations={locations}
          checkInChats={checkInChats}
          formDisabled={formDisabled}
          isDefaultAutomation={isDefaultAutomation}
          openDeleteModal={openDeleteModal}
        />
      </Form>
    </div>
  );
};

const mapStateToProps = ({ organizationData, automatedMessages }: ReduxStateType) => {
  return {
    locations: organizationData.organizationData ? organizationData.organizationData.locations : [],
    automation: automatedMessages.automationDetails,
    checkInChats: automatedMessages.checkInChats,
    loading: automatedMessages.automationDetailsLoading
  };
};

export default connect(mapStateToProps, {
  updateAutomationDetails: updateAutomation,
  openModal: openModalAction
})(General);
