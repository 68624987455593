import axios from "axios";
import { ROOT_URL } from "../constants";

const getJwt = () => {
  return localStorage.getItem("jwt");
};

export const getDocumentSignedUrl = async (documentId: number): Promise<string> => {
  const config = {
    headers: { Authorization: `${getJwt()}`, "Content-Type": "application/json" }
  };

  const response = await axios.get(`${ROOT_URL}/documents/${documentId}/download`, config);

  if (response.status === 200) return response.data;
  return "";
};
