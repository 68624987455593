import React from "react";

type PropsType = {
  size?: number;
};

const Heart = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="heart_red">
        <path
          id="Union"
          d="M4.39124 5.43612C3.50045 6.35565 3 7.60281 3 8.90323C3 16.387 12 20 12 20C12 20 21 16.387 21 8.90323C21 7.60281 20.4996 6.35566 19.6088 5.43612C18.718 4.51659 17.5098 4 16.25 4C14.9902 4 13.782 4.51659 12.8912 5.43612C12.526 5.81313 12 6.71347 12 6.71347C12 6.71347 11.474 5.81313 11.1088 5.43612C10.218 4.51659 9.00978 4 7.75 4C6.49022 4 5.28204 4.51659 4.39124 5.43612Z"
          fill="#FF716F"
        />
      </g>
    </svg>
  );
};

export default Heart;
