import React from "react";
import cx from "classnames";

import Text from "../Text";
import Button from "../Button";
import { ChevronLeft, ChevronRight, DoubleChevronLeft, DoubleChevronRight } from "../Icon";

import styles from "./pageNavigator.module.scss";

type PageLink = {
  page: number;
  label: string | number;
  active?: boolean;
};

type PropsType = {
  currentPage: number;
  totalPages: number;
  className?: string;
  setCurrentPage: (page: number) => void;
};

const MAX_NAVIGATE_TO_PAGES_PER_SIDE = 4;

const PageNavigator = ({ currentPage, totalPages, setCurrentPage, className = "" }: PropsType) => {
  const hasMoreToLeft = currentPage - MAX_NAVIGATE_TO_PAGES_PER_SIDE > 1;
  const hasMoreToRight = currentPage + MAX_NAVIGATE_TO_PAGES_PER_SIDE < totalPages;
  const pageLinksLeft: PageLink[] = [...Array(MAX_NAVIGATE_TO_PAGES_PER_SIDE).keys()]
    .reverse()
    .reduce<PageLink[]>((links, difference) => {
      const page = currentPage - (difference + 1);
      if (page > 0) {
        links.push({ page, label: page });
      }
      return links;
    }, []);
  const pageLinksRight: PageLink[] = [...Array(MAX_NAVIGATE_TO_PAGES_PER_SIDE).keys()].reduce<
    PageLink[]
  >((links, difference) => {
    const page = currentPage + difference + 1;
    if (page <= totalPages) {
      links.push({ page, label: page });
    }

    return links;
  }, []);

  const pageLinks: PageLink[] = [
    ...pageLinksLeft,
    { page: currentPage, label: currentPage, active: true },
    ...pageLinksRight
  ];

  return (
    <div className={cx(styles.PageChanger, className)}>
      {totalPages > MAX_NAVIGATE_TO_PAGES_PER_SIDE && (
        <Button
          inline
          type="button"
          onClick={() => setCurrentPage(1)}
          disabled={currentPage === 1}
          className={styles.Arrow}
        >
          <DoubleChevronLeft size={14} />
        </Button>
      )}
      <Button
        inline
        type="button"
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={currentPage === 1}
        className={styles.Arrow}
      >
        <ChevronLeft size={20} />
      </Button>
      {hasMoreToLeft && (
        <Text align="center" component="span" className={styles.PageText}>
          ...
        </Text>
      )}
      {pageLinks.map((link) => {
        return (
          <Button
            key={`${link.page}`}
            inline
            type="button"
            onClick={() => setCurrentPage(link.page)}
            className={styles.PageLink}
          >
            <Text
              bold={link.active}
              align="center"
              component="span"
              className={cx(styles.PageText, { [styles.PageTextActive]: link.active })}
            >
              {link.label}
            </Text>
          </Button>
        );
      })}
      {hasMoreToRight && (
        <Text align="center" component="span" className={styles.PageText}>
          ...
        </Text>
      )}
      <Button
        inline
        type="button"
        onClick={() => setCurrentPage(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={styles.Arrow}
      >
        <ChevronRight size={20} />
      </Button>
      {totalPages > MAX_NAVIGATE_TO_PAGES_PER_SIDE && (
        <Button
          inline
          type="button"
          onClick={() => setCurrentPage(totalPages)}
          disabled={currentPage === totalPages}
          className={styles.Arrow}
        >
          <DoubleChevronRight size={14} />
        </Button>
      )}
    </div>
  );
};

export default PageNavigator;
