import React from "react";

type PropsType = {
  size?: number;
  className?: string;
};

const MikataPaperAirplane = ({ size = 30, className }: PropsType) => {
  return (
    <svg
      className={className}
      width={`${size}px`}
      height={`${size}px`}
      preserveAspectRatio="xMidYMid"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8889_12951)">
        <path
          d="M185.679 86.9058C169.026 93.3342 131.067 100.659 112.458 78.5318C89.1968 50.8726 158.588 39.9948 150.526 70.7904C144.075 95.427 94.1543 110.109 70.0001 114.371"
          stroke="#00D29D"
          strokeWidth="3.2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="9.6 12.8"
        />
        <path
          d="M167.785 30.2258L298.565 30.6555L193.261 60.5345L167.785 30.2258Z"
          fill="#F2F2F3"
        />
        <path
          d="M190.259 89.5869L193.261 60.5343L298.566 30.6554L190.259 89.5869Z"
          fill="#D7D8DB"
        />
        <circle
          cx="246.567"
          cy="46.7323"
          r="20.2647"
          transform="rotate(-29.5742 246.567 46.7323)"
          fill="#5DF5C9"
        />
        <circle
          cx="4.12164"
          cy="4.12164"
          r="4.12164"
          transform="matrix(-0.869717 0.493551 0.493551 0.869717 261.16 33.3169)"
          fill="white"
        />
        <circle
          cx="4.12164"
          cy="4.12164"
          r="4.12164"
          transform="matrix(-0.869717 0.493551 0.493551 0.869717 250.568 39.3271)"
          fill="white"
        />
        <circle
          cx="2.74776"
          cy="2.74776"
          r="2.74776"
          transform="matrix(-0.869717 0.493551 0.493551 0.869717 261.742 35.356)"
          fill="#003125"
        />
        <circle
          cx="2.74776"
          cy="2.74776"
          r="2.74776"
          transform="matrix(-0.869717 0.493551 0.493551 0.869717 250.988 41.4587)"
          fill="#003125"
        />
        <circle
          cx="217.123"
          cy="59.0963"
          r="20.2647"
          transform="rotate(-26.2824 217.123 59.0963)"
          fill="#FF878F"
        />
        <circle
          cx="4.12164"
          cy="4.12164"
          r="4.12164"
          transform="matrix(-0.896622 0.442796 0.442796 0.896622 232.463 46.5413)"
          fill="white"
        />
        <circle
          cx="4.12164"
          cy="4.12164"
          r="4.12164"
          transform="matrix(-0.896622 0.442796 0.442796 0.896622 221.544 51.9333)"
          fill="white"
        />
        <circle
          cx="2.74776"
          cy="2.74776"
          r="2.74776"
          transform="matrix(-0.896622 0.442796 0.442796 0.896622 232.927 48.6101)"
          fill="#003125"
        />
        <circle
          cx="2.74776"
          cy="2.74776"
          r="2.74776"
          transform="matrix(-0.896622 0.442796 0.442796 0.896622 221.841 54.0854)"
          fill="#003125"
        />
        <path
          d="M210.055 79.1898L298.565 30.6558L250.052 112.452L210.055 79.1898Z"
          fill="#F2F2F3"
        />
      </g>
      <defs>
        <clipPath id="clip0_8889_12951">
          <rect width="368" height="140" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MikataPaperAirplane;
