import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Card from "../../../ui/Card";
import Text from "../../../ui/Text";
import Button from "../../../ui/Button";
import Heading from "../../../ui/Heading";
import { MikataSimple } from "../../../ui/Icon";
import { Form, TextInput } from "../../../ui/Input";
import Loader from "../../../ui/Loader";

import { useQueryString } from "../../../../utils/queryStringHelpers";

import {
  verifyInviteCode as verifyInviteCodeAction,
  VerifyInviteCodeData
} from "../../../../actions";

import { ReduxStateType } from "../../../../types";

import styles from "./index.module.scss";

type FormState = { inviteCode: string };

type PropsType = {
  inviteCodeVerified?: boolean;
  inviteCodeLoading: boolean;
  setInviteCode: (inviteCode: string) => void;
  verifyInviteCode: (data: VerifyInviteCodeData) => void;
};

const InviteCodeForm = ({
  inviteCodeVerified,
  inviteCodeLoading,
  setInviteCode,
  verifyInviteCode
}: PropsType) => {
  const { parsed } = useQueryString();
  const { code: inviteCode } = parsed;
  const [attemptedVerification, setAttemptedVerification] = useState(false);

  useEffect(() => {
    if (inviteCode) {
      /**
       * setTimeout is used here to allow CLEAR_ORGANIZATION to be called first
       * and then we trigger the inviteCode verification
       */
      setTimeout(() => {
        setAttemptedVerification(true);
        setInviteCode(inviteCode);
        verifyInviteCode({ inviteCode });
      }, 0);
    }
  }, [inviteCode]);

  const handleSubmit = (formState: FormState) => {
    setAttemptedVerification(true);
    setInviteCode(formState.inviteCode);
    verifyInviteCode({ inviteCode: formState.inviteCode });
  };

  if (inviteCodeLoading) {
    return <Loader screen center />;
  }

  return (
    <Card className={styles.Card}>
      <Form
        onSubmit={(formState) => handleSubmit(formState.values as FormState)}
        initialValues={{ inviteCode: "" }}
      >
        <div className={styles.Logo}>
          <MikataSimple size={50} />
        </div>
        <Heading size="L" component="h1">
          Sign up for Mika Scribe
        </Heading>
        <div className={styles.SignIn}>
          <Text className={styles.SignInText}>Already have an account?</Text>
          <a type="button" href={`${process.env.REACT_APP_CLINIC_URL}/login`} rel="noreferrer">
            <Button inline>Sign in</Button>
          </a>
        </div>
        <TextInput
          label="Invite Code"
          id="inviteCode"
          fieldName="inviteCode"
          initialValue={inviteCode}
        />
        {attemptedVerification && !inviteCodeLoading && !inviteCodeVerified && (
          <Text className={styles.ErrorMessage}>
            Invalid invite code.{" "}
            <a
              className={styles.Link}
              href="https://mikatahealth.com/contact"
              target="_blank"
              rel="noreferrer"
            >
              Contact us
            </a>
          </Text>
        )}
        <Button
          className={styles.ButtonLogin}
          id="continueSignUp"
          type="submit"
          disabled={inviteCodeLoading}
        >
          Continue
        </Button>
        <a
          className={styles.ButtonNoCode}
          type="button"
          href="https://mikatahealth.com/scribe-access"
          rel="noreferrer"
          target="_blank"
        >
          <Button inline>I don&apos;t have an invite code</Button>
        </a>
      </Form>
    </Card>
  );
};

const mapStateToProps = ({ organizationDetails }: ReduxStateType) => {
  return {
    inviteCodeVerified: organizationDetails.inviteCodeVerified,
    inviteCodeLoading: organizationDetails.inviteCodeLoading
  };
};

export default connect(mapStateToProps, {
  verifyInviteCode: verifyInviteCodeAction
})(InviteCodeForm);
