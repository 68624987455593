import React from "react";

import { useFormApi, useFormState } from "informed";
import Button from "../../../ui/Button";
import { TextInput, SelectInput, TextAreaInput } from "../../../ui/Input";

import { getDefaultEmailSubject } from "../../../../utils/getDefaultEmailSubject";

import { MessageTemplateAddData, Option } from "../../../../types";
import SmsMessageInfo from "../../OrganizationDetails/views/MessageTemplatesTable/MessageTemplateDetails/SmsMessageInfo";
import {
  displayNameValidator,
  payloadContentValidator,
  topicValidator,
  subjectValidator
} from "./formValidator";

import styles from "./index.module.scss";

type AddMessageTemplateFormData = MessageTemplateAddData;

type PropsType = {
  formDisabled?: boolean;
  inTouchpointForm: boolean;
  topicOptions: Option[];
  closeModal: () => void;
  payloadContentWarning: (value: string) => string | undefined;
};

const FormContent = ({
  formDisabled,
  inTouchpointForm,
  topicOptions,
  payloadContentWarning,
  closeModal
}: PropsType) => {
  const formState = useFormState();
  const formApi = useFormApi();
  const values = formState.values as AddMessageTemplateFormData;

  return (
    <>
      <TextInput
        id="displayName"
        fieldName="displayName"
        label="name"
        placeholder="Enter name"
        validate={displayNameValidator}
        disabled={formDisabled}
      />
      {!inTouchpointForm && (
        <SelectInput
          id="topic"
          fieldName="topic"
          label="Topic"
          options={topicOptions}
          placeholder="Please choose"
          disabled={formDisabled}
          validate={topicValidator}
          customOnChange={() => {
            const topic = formApi.getValue("topic") as string;
            const defaultSubject = getDefaultEmailSubject(topic);
            formApi.setValue("subject", defaultSubject);
          }}
          info={
            <span>
              When creating a template for a schedule-based message, choose &quot;Schedule-based
              Message&quot; topic here. You will choose the actual topic (ex: &apos;reminder&apos;)
              when creating the schedule-based message for an automation.
            </span>
          }
        />
      )}
      <TextInput
        id="subject"
        fieldName="subject"
        label="Subject"
        placeholder="Enter subject"
        disabled={formDisabled}
        validate={subjectValidator}
        info={<span>Appears in the subject line of emails. Irrelevant for SMS messages.</span>}
      />
      <TextAreaInput
        id="content"
        size="M"
        fieldName="payloadContent"
        label="Content"
        info="Standard messages contain max.160 characters. Non-standard messages contain max.70 characters."
        labelDetails={<SmsMessageInfo messageContent={values.payloadContent} />}
        placeholder="Message content"
        disabled={formDisabled}
        validate={payloadContentValidator}
        warningMessage={payloadContentWarning(values.payloadContent)}
      />
      <div className={styles.ButtonGroup}>
        <Button inline onClick={closeModal}>
          Cancel
        </Button>
        <Button id="createMessageTemplate" type="submit">
          Create
        </Button>
      </div>
    </>
  );
};

export default FormContent;
