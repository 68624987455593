import React, { useEffect, useState } from "react";
import cx from "classnames";

import Text from "../../../Text";
import { Circle, CircleCheck } from "../../../Icon";

import { InputPropsType, Option } from "../../../../../types";

import styles from "./index.module.scss";

type PropsType = Omit<InputPropsType, "customOnChange"> & {
  label?: string;
  initialValue?: string;
  options: Array<Option>;
  onChange?: (value: string | number) => void;
};

const RadioGroupBase = ({
  fieldName,
  label,
  options,
  initialValue = "",
  disabled = false,
  onChange: onChangeProp,
  ...props
}: PropsType): React.ReactElement => {
  const [value, setValue] = useState<string | number>(initialValue);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const changeValue = String(event.target.value);

    setValue(changeValue);
    if (onChangeProp) {
      onChangeProp(changeValue);
    }
  };

  useEffect(() => {
    if (initialValue !== value) setValue(initialValue);
  }, [initialValue]);

  return (
    <fieldset className={styles.Fieldset} id={fieldName} disabled={disabled}>
      <div className={styles.Wrapper}>
        {options.map((option) => {
          const isChecked =
            option.value === value ||
            option.value.toString() === value ||
            (option.value === "" && value === undefined);
          const optionId = `${fieldName}-${option.label ? option.label.replace(" ", "") : ""}`;
          return (
            // eslint-disable-next-line jsx-a11y/label-has-associated-control
            <label
              id={optionId}
              key={`${fieldName}-${option.value}`}
              className={styles.RadioOption}
            >
              <input
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                id={`${fieldName}-${option.value}`}
                className={styles.RadioOptionInput}
                type="radio"
                aria-label={label}
                name={fieldName}
                value={option.value}
                checked={isChecked}
                onChange={onChangeHandler}
                onSelect={onChangeHandler}
                disabled={disabled}
              />
              <div
                className={cx(styles.Icon, {
                  [styles.IconChecked]: isChecked,
                  [styles.IconDisabled]: disabled
                })}
              >
                {isChecked ? <CircleCheck /> : <Circle />}
              </div>
              <Text
                className={cx(styles.Label, {
                  [styles.LabelChecked]: isChecked,
                  [styles.IconDisabled]: disabled
                })}
              >
                {option.label}
              </Text>
            </label>
          );
        })}
      </div>
    </fieldset>
  );
};

export default RadioGroupBase;
