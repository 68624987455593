import React, { Dispatch, SetStateAction, useMemo } from "react";

import { TextInput, TextAreaInput, SelectInput, FormGrid } from "../../../../../../../../ui/Input";
import { ChatFlowsNodes, Option, NumberInputFormat } from "../../../../../../../../../types";

import BaseChatNodeForm, {
  BaseFormValues,
  NumberInputFormValues,
  FormValues
} from "../../BaseChatNodeForm";

type NumberInputPayloadOptions = null | { maxDigits?: number; inputFormat?: NumberInputFormat };

export type NumberInputChatFlowsNode = ChatFlowsNodes & {
  payloadOptions: NumberInputPayloadOptions;
};

type PropsType = {
  chatId: string | undefined;
  node: NumberInputChatFlowsNode;
  viewOnly: boolean;
  setSelectedNodeId: Dispatch<SetStateAction<number | null>>;
};

const numberModeOptions: Option[] = [
  { label: "Default", value: NumberInputFormat.NUMBER },
  { label: "Personal Health Number", value: NumberInputFormat.PERSONAL_HEALTH_NUMBER }
];

const onSaveCustomTransformer = (formValues: FormValues) => {
  const values = formValues as BaseFormValues & NumberInputFormValues;
  return {
    payloadContent: values.payloadContent || "",
    payloadOptions: {
      maxDigits: values.maxDigits || 0,
      inputFormat: values.inputFormat || NumberInputFormat.NUMBER
    }
  };
};

export const NumberInput = ({ chatId, node, viewOnly, setSelectedNodeId }: PropsType) => {
  const initialFormValues: NumberInputFormValues = useMemo(() => {
    return {
      payloadContent: node?.payloadContent || "",
      payloadOptions: node?.payloadOptions || null,
      maxDigits: node?.payloadOptions?.maxDigits || 0,
      inputFormat: node?.payloadOptions?.inputFormat || NumberInputFormat.NUMBER
    };
  }, [node.id]);

  return (
    <BaseChatNodeForm
      chatId={chatId}
      key={node.id}
      initialValues={initialFormValues}
      onSaveCustomTransformer={onSaveCustomTransformer}
      node={node}
      isInput
      viewOnly={viewOnly}
      setSelectedNodeId={setSelectedNodeId}
    >
      <>
        <TextAreaInput
          id="chatNodePayloadContent"
          size="S"
          fieldName="payloadContent"
          label="Mikata Message"
          disabled={viewOnly}
        />
        <FormGrid>
          <TextInput fieldName="maxDigits" label="Max Digits" type="number" disabled={viewOnly} />
          <SelectInput
            fieldName="inputFormat"
            label="Input Format Mode"
            options={numberModeOptions}
            disabled={viewOnly}
          />
        </FormGrid>
      </>
    </BaseChatNodeForm>
  );
};

export default NumberInput;
