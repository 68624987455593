import React, { useContext, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { useSearchParams } from "react-router-dom";

import TableGrid, { StopClickPropagation } from "../../../ui/TableGrid";
import Loader from "../../../ui/Loader";
import Text from "../../../ui/Text";
import Button from "../../../ui/Button";
import { Plus, MapPin, User, Clipboard } from "../../../ui/Icon";
import Status from "../../../ui/Status";

import PreviewJourneyButton from "../../PreviewJourneyButton";
import AppointmentJourneysDetails from "./AppointmentJourneysDetails";
import JourneyTimeline from "./JourneyTimeline";

import { PermissionsGuard } from "../../../../hooks/usePermissions";

import { updateQueryString, useQueryString } from "../../../../utils/queryStringHelpers";
import formatDate from "../../../../utils/formatDate";
import { capitalize } from "../../../../utils/stringTransformations";

import {
  fetchAutomatedMessages,
  openModal as openModalAction,
  OpenModal,
  fetchReasons as fetchReasonsAction,
  fetchLocations as fetchLocationsAction
} from "../../../../actions";

import { AdminModalTypes } from "../../../../constants";

import {
  AutomationDashboard,
  StatusComponentConfigMap,
  ReduxStateType,
  AutomationStatus,
  MessageSchedule,
  AutomatedMessageConfiguration,
  Permissions
} from "../../../../types";

import styles from "./index.module.scss";
import Tooltip from "../../../ui/Tooltip";
import { OrganizationContext } from "../../../providers/OrganizationProvider";

type PropsType = {
  automations: AutomationDashboard[];
  messageSchedules: MessageSchedule[];
  loading: boolean;
  fetchAutomations: () => void;
  openModal: OpenModal;
  fetchReasons: () => void;
  fetchLocations: (organizationId: number) => void;
};

const statusConfigMap: StatusComponentConfigMap = {
  [AutomationStatus.published]: "green",
  [AutomationStatus.draft]: "gray"
};

const statusOptions = Object.values(AutomationStatus).map((status) => {
  return {
    label: capitalize(status),
    value: status
  };
});

const AppointmentJourneysTable = ({
  automations,
  messageSchedules,
  loading,
  fetchAutomations,
  openModal,
  fetchReasons,
  fetchLocations
}: PropsType) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { parsed } = useQueryString();
  const { automationId } = parsed;
  const [selectedTouchpointId, setSelectedTouchpointId] = useState<number | null>(null);
  const organization = useContext(OrganizationContext);

  const headers = [
    { colName: "displayName", content: "Journey Name" },
    { colName: "triggers", content: "Triggers" },
    { colName: "journeyTimeline", content: "Touchpoints" },
    { colName: "updatedAt", content: "Last Updated" },
    { colName: "status", content: "Status" }
  ];
  const isModalOpen = Boolean(parsed.automationId);

  const messageSchedulesInUse = useMemo(() => {
    const messageSchedulesInUseById: {
      [messageScheduleId: string]: AutomatedMessageConfiguration;
    } = {};
    automations.forEach((automation) => {
      automation.automatedMessageConfigurations.forEach((automatedMessageConfiguration) => {
        messageSchedulesInUseById[automatedMessageConfiguration.scheduleId.toString()] =
          automatedMessageConfiguration;
      });
    });

    return messageSchedules.filter((messageSchedule) => {
      const isInUse = Boolean(messageSchedulesInUseById[messageSchedule.id.toString()]);

      return isInUse;
    });
  }, [messageSchedules, automations]);

  const openDetailsModal = (id: number): void => {
    updateQueryString({ automationId: id.toString() }, setSearchParams);
  };

  const closeDetailsModal = (): void => {
    updateQueryString({ automationId: undefined }, setSearchParams);
  };

  const getTriggerPriority = (automation: AutomationDashboard) => {
    // PRL - Triggers for Practitioner, Reason, Location
    switch (true) {
      // Newly created journey - no triggers, draft state
      case automation.practitionerIds.length === 0 &&
        automation.reasonIds.length === 0 &&
        automation.locationIds.length === 0 &&
        automation.status === "draft":
        return 0;
      // PRL
      case automation.practitionerIds.length > 0 &&
        automation.reasonIds.length > 0 &&
        automation.locationIds.length > 0:
        return 1;
      // PR
      case automation.practitionerIds.length > 0 &&
        automation.reasonIds.length > 0 &&
        automation.locationIds.length === 0:
        return 2;
      // PL
      case automation.practitionerIds.length > 0 &&
        automation.reasonIds.length === 0 &&
        automation.locationIds.length > 0:
        return 3;
      // RL
      case automation.practitionerIds.length === 0 &&
        automation.reasonIds.length > 0 &&
        automation.locationIds.length > 0:
        return 4;
      // P
      case automation.practitionerIds.length > 0 &&
        automation.reasonIds.length === 0 &&
        automation.locationIds.length === 0:
        return 5;
      // R
      case automation.practitionerIds.length === 0 &&
        automation.reasonIds.length > 0 &&
        automation.locationIds.length === 0:
        return 6;
      // L
      case automation.practitionerIds.length === 0 &&
        automation.reasonIds.length === 0 &&
        automation.locationIds.length > 0:
        return 7;
      // No triggers
      default:
        return 8;
    }
  };

  const sortByTriggerPriority = (
    automationA: AutomationDashboard,
    automationB: AutomationDashboard
  ) => {
    const automationAPriority = getTriggerPriority(automationA);
    const automationBPriority = getTriggerPriority(automationB);

    return automationAPriority - automationBPriority;
  };

  // Fetch messages on first load
  useEffect(() => {
    fetchAutomations();
    fetchReasons();
    if (organization) {
      fetchLocations(organization.id);
    }
  }, [automationId]);

  const rowsData = automations.sort(sortByTriggerPriority).map((row) => {
    const handleClick = (): void => {
      setSelectedTouchpointId(null);
      openDetailsModal(row.id);
    };

    const selectTouchpoint = (touchpointId: number) => {
      setSelectedTouchpointId(touchpointId);
      openDetailsModal(row.id);
    };

    const modalButton = (
      <StopClickPropagation>
        <button type="button" className={styles.Button} onClick={handleClick}>
          <div>
            <Text bold className={styles.DisplayName}>
              {row.displayName}
            </Text>
          </div>
          {row.description && (
            <Text className={styles.Description} size="XS">
              {row.description}
            </Text>
          )}
        </button>
      </StopClickPropagation>
    );

    return {
      ...row,
      __onRowClick: handleClick,
      status: <Status value={row.status} options={statusOptions} configMap={statusConfigMap} />,
      displayName: modalButton,
      triggers: (
        <div className={styles.Triggers}>
          <Tooltip
            contentClassName={styles.TooltipContent}
            icon={
              <div
                className={cx(styles.TriggerIcon, {
                  [styles.TriggerIconActive]: row.practitionerIds.length > 0
                })}
              >
                <User size={16} />
              </div>
            }
            position="bottomRight"
          >
            {row.practitionerIds.length > 0
              ? `${row.practitionerIds.length} Provider Triggers`
              : "No Provider Triggers"}
          </Tooltip>
          <Tooltip
            contentClassName={styles.TooltipContent}
            icon={
              <div
                className={cx(styles.TriggerIcon, {
                  [styles.TriggerIconActive]: row.reasonIds.length > 0
                })}
              >
                <Clipboard size={16} />
              </div>
            }
            position="bottomRight"
          >
            {row.reasonIds.length > 0
              ? `${row.reasonIds.length} Reason Triggers`
              : "No Reason Triggers"}
          </Tooltip>
          <Tooltip
            contentClassName={styles.TooltipContent}
            icon={
              <div
                className={cx(styles.TriggerIcon, {
                  [styles.TriggerIconActive]: row.locationIds.length > 0
                })}
              >
                <MapPin size={16} />
              </div>
            }
            position="bottomRight"
          >
            {row.locationIds.length > 0
              ? `${row.locationIds.length} Location Triggers`
              : "No Location Triggers"}
          </Tooltip>
        </div>
      ),
      journeyTimeline: (
        <JourneyTimeline
          touchpoints={row.automatedMessageConfigurations}
          messageSchedules={messageSchedulesInUse}
          published={row.status === AutomationStatus.published}
          onTouchpointSelection={selectTouchpoint}
        />
      ),
      updatedAt: formatDate(row.updatedAt, "simpleDate")
    };
  });

  if (loading && rowsData.length === 0) {
    return <Loader screen />;
  }

  return (
    <>
      <div className={styles.ViewHeader}>
        <PermissionsGuard requiredPermissions={[Permissions.CREATE_AUTOMATION]}>
          <Button
            id="addAutomation"
            secondary
            size="S"
            onClick={() => openModal(AdminModalTypes.ADD_AUTOMATION)}
          >
            <Plus />
            <span className={styles.AddButtonText}>Journey</span>
          </Button>
        </PermissionsGuard>
        <PermissionsGuard
          requiredPermissions={[Permissions.CREATE_AUTOMATED_MESSAGE_CONFIGURATION]}
        >
          <Button
            id="addTouchpoints"
            secondary
            size="S"
            onClick={() => openModal(AdminModalTypes.ADD_MESSAGE_CONFIGURATION)}
          >
            <Plus />
            <span className={styles.AddButtonText}>Touchpoint</span>
          </Button>
        </PermissionsGuard>
        <PreviewJourneyButton />
      </div>

      <TableGrid id="automationsTable" headers={headers} rows={rowsData} showRowFocus />
      <AppointmentJourneysDetails
        automationId={parsed.automationId}
        isModalOpen={isModalOpen}
        closeModal={closeDetailsModal}
        touchpointId={selectedTouchpointId}
      />
    </>
  );
};

const mapStateToProps = ({ automatedMessages }: ReduxStateType) => {
  return {
    automations: automatedMessages.automations,
    messageSchedules: automatedMessages.messageSchedules,
    loading: automatedMessages.automatedMessagesLoading
  };
};

export default connect(mapStateToProps, {
  fetchAutomations: fetchAutomatedMessages,
  fetchReasons: fetchReasonsAction,
  fetchLocations: fetchLocationsAction,
  openModal: openModalAction
})(AppointmentJourneysTable);
