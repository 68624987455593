import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from "../constants";

import { AddNotificationAction, RemoveNotificationAction } from "../actions";
import { Notification } from "../types";

export type NotificationsAction = RemoveNotificationAction | AddNotificationAction;

export type NotificationsReduxState = {
  data: Array<Notification>;
};

const initialNotificationsState: NotificationsReduxState = {
  data: []
};

export const notificationsReducer = (
  state = initialNotificationsState,
  { type, payload }: NotificationsAction
): NotificationsReduxState => {
  switch (type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        data: [...state.data, payload as AddNotificationAction["payload"]]
      };

    case REMOVE_NOTIFICATION: {
      const { notificationId } = payload as RemoveNotificationAction["payload"];
      const updatedNotifications = state.data.filter(
        (notification) => notification.id !== notificationId
      );
      return {
        ...state,
        data: updatedNotifications
      };
    }

    default:
      return state;
  }
};
