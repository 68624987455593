import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { FloatModal } from "../../../ui/Modal";
import Heading from "../../../ui/Heading";
import { Form, SelectInput, TextInput, UploadInput } from "../../../ui/Input";
import Button from "../../../ui/Button";

import {
  createCampaign as CreateCampaignAction,
  CreateCampaignData,
  fetchCampaigns,
  fetchMessageTemplates,
  closeModal as closeModalAction,
  addNotification as addNotificationAction
} from "../../../../actions";
import { isRequired } from "../../../../utils/validators";
import { BucketNameSuffixes, MessageTopics } from "../../../../constants";

import styles from "./index.module.scss";
import DateSelector from "../../../ui/DateSelector";
import { getSignedUrl, uploadFiles } from "../../../../lib";
import {
  MessageTemplate,
  OrganizationData,
  ReduxStateType,
  NewNotification
} from "../../../../types";
import Loader from "../../../ui/Loader";

type PropsType = {
  organizationData: OrganizationData;
  messageTemplates: Array<MessageTemplate>;
  messageTemplatesLoading: boolean;
  createLoading: boolean;
  createCampaign: (data: CreateCampaignData, onSuccess?: () => void) => void;
  fetchMessageTemplates: () => void;
  closeModal: () => void;
  addNotification: (notification: NewNotification) => void;
};

type FormState = {
  name: string;
  messageTemplate: string;
  uploadFile: File | null;
  frequency: string;
};

const initialFormState = {
  name: "",
  messageTemplate: "",
  uploadFile: null,
  frequency: ""
};

const messageFrequency = [
  {
    label: "Slow (~50 patients messaged/day)",
    value: 50
  },
  {
    label: "Medium (~100 patients messaged/day)",
    value: 100
  },
  {
    label: "Fast (~500 patients messaged/day)",
    value: 500
  },
  {
    label: "Very Fast (~1000 patients messaged/day)",
    value: 1000
  },
  {
    label: "Super Fast (~1500 patients messaged/day)",
    value: 1500
  }
];

const formValidator = (values: FormState) => {
  return {
    name: isRequired("Please enter a name")(values.name),
    messageTemplate: isRequired("Please select a message")(values.messageTemplate),
    uploadFile: isRequired("Please upload a file")(values.uploadFile),
    frequency: isRequired("Please select a frequency")(values.frequency)
  };
};

const AddCampaign = ({
  organizationData,
  messageTemplates,
  createCampaign,
  fetchMessageTemplates,
  messageTemplatesLoading,
  createLoading,
  closeModal,
  addNotification
}: PropsType) => {
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const [uploadingFile, setUploadingFile] = useState(false);

  const campaignMessageTemplates =
    messageTemplates
      .filter((mt: MessageTemplate) => mt.topic === MessageTopics.CAMPAIGN && mt.displayName)
      .map((campaignMT: MessageTemplate) => {
        return {
          label: campaignMT.displayName,
          value: campaignMT.id
        };
      }) || [];

  const noCampaignMessages = campaignMessageTemplates.length === 0;

  useEffect(() => {
    fetchMessageTemplates();
  }, []);

  const save = async (formValues: FormState) => {
    let s3FileName;
    let s3UploadRecordId;

    setUploadingFile(true);
    try {
      const {
        url,
        filename,
        uploadRecordId,
        error: getSignedUrlError
      } = await getSignedUrl({
        bucketName: BucketNameSuffixes.CAMPAIGN_UPLOADS,
        fileExt: "csv"
      });
      s3FileName = filename;
      s3UploadRecordId = uploadRecordId;

      await uploadFiles(url, formValues.uploadFile);
    } catch (error) {
      setUploadingFile(false);

      return addNotification({
        type: "error",
        title: "Failed to upload file",
        subtitle: "Please try again"
      });
    }

    const data: CreateCampaignData = {
      name: formValues.name,
      messageTemplateId: parseInt(formValues.messageTemplate, 10),
      organizationId: organizationData.id,
      fileName: s3FileName,
      uploadId: s3UploadRecordId,
      startDate: selectedDate.toISOString(),
      sendRate: parseInt(formValues.frequency, 10)
    };

    createCampaign(data, () => closeModal());
    setUploadingFile(false);
  };

  const handleDateChange = (date: Date | null) => {
    date && setSelectedDate(date);
  };

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L" className={styles.Heading}>
        Create New Campaign
      </Heading>
      {messageTemplatesLoading && <Loader small screen />}
      {noCampaignMessages && !messageTemplatesLoading && (
        <>
          <Heading size="S">
            No campaign message templates available. Please have one created before proceeding.
          </Heading>
          <Heading size="S">
            Contact our team to create a custom message by emailing help@mikatahealth.com or calling
            1-877-749-3222.
          </Heading>
        </>
      )}
      {!noCampaignMessages && !messageTemplatesLoading && (
        <Form
          initialValues={initialFormState}
          validateFields={(values) => formValidator(values as FormState)}
          onSubmit={(formState) => save(formState.values as FormState)}
        >
          <>
            <TextInput
              id="campaignName"
              fieldName="name"
              label="Name"
              placeholder="Enter Campaign Name"
            />
            <SelectInput
              id="campaignMessageTemplate"
              fieldName="messageTemplate"
              label="Message"
              options={campaignMessageTemplates}
              placeholder="Choose Message"
            />

            <Heading size="META">Upload your list of recipients</Heading>
            <UploadInput
              fieldName="uploadFile"
              label="Upload file"
              acceptedFileTypes={["text/csv", ".csv"]}
            />
            <Heading size="META"> When do you want to start sending these messages?</Heading>
            <DateSelector
              selectedDate={selectedDate}
              customMinDate={moment().toDate()}
              onChange={handleDateChange}
            />
            <br />

            <SelectInput
              fieldName="frequency"
              label="Message Frequency"
              options={messageFrequency}
              placeholder="How frequently would you like to send these messages?"
            />
            <hr className={styles.Divider} />

            <div className={styles.ButtonGroup}>
              <Button inline onClick={closeModal}>
                Cancel
              </Button>
              <Button id="createCampaign" type="submit" disabled={createLoading || uploadingFile}>
                Create
              </Button>
            </div>
          </>
        </Form>
      )}
    </FloatModal>
  );
};

const mapStateToProps = ({ messageTemplates, campaigns }: ReduxStateType) => ({
  messageTemplates: messageTemplates.data,
  messageTemplatesLoading: messageTemplates.messageTemplateFetchLoading,
  createLoading: campaigns.createLoading
});

export default connect(mapStateToProps, {
  createCampaign: CreateCampaignAction,
  fetchCampaigns,
  fetchMessageTemplates,
  closeModal: closeModalAction,
  addNotification: addNotificationAction
})(AddCampaign);
