import React from "react";

type PropsType = {
  size?: number;
};

const Search = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.523 10.066C20.523 4.506 15.93 0 10.262 0 4.594 0 0 4.507 0 10.066c0 5.559 4.594 10.066 10.262 10.066 5.667 0 10.261-4.507 10.261-10.066zm-18.813 0c0-4.633 3.829-8.388 8.552-8.388 4.722 0 8.551 3.755 8.551 8.388 0 4.632-3.829 8.388-8.551 8.388-4.723 0-8.552-3.756-8.552-8.388z"
        fill="currentColor"
      />
      <path
        d="M17.283 16.953L23 23"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Search;
