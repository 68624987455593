import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { createAppointmentRequest } from "../../../actions";

import Text from "../../ui/Text";
import Heading from "../../ui/Heading";
import Button from "../../ui/Button";
import Loader from "../../ui/Loader";

import styles from "./index.module.scss";

type StateType = {
  error: string;
  loading: boolean;
};

const BookingLandingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [bookingState, setBookingState] = useState<StateType>({
    error: "",
    loading: true
  });

  useEffect(() => {
    const initialize = async () => {
      const query = new URLSearchParams(location.search);
      const token = query.get("token");
      const referrerType = query.get("referrerType");
      const recipientIds = query.get("recipientIds");
      const conversationId = query.get("conversationId");
      const testOrganizationId = query.get("testOrganizationId");
      const themeName = query.get("themeName");

      if (!token) {
        setBookingState({
          loading: false,
          error: "Please return to the clinic website and follow the link from there."
        });
        return;
      }
      const params = { token, referrerType, recipientIds, conversationId, testOrganizationId };
      const { success, chatToken, msg } = await createAppointmentRequest(params);

      if (success) {
        const domain = window.location.origin.replace("app.", "chat.").replace("3002", "3000");
        const url = `${domain}/chat/${chatToken}/messages${
          themeName ? `?themeName=${themeName}` : ""
        }`;
        window.location.href = url;
      } else {
        setBookingState({ loading: false, error: msg || "" });
      }
    };
    initialize();
  }, []);

  return (
    <div className={styles.Wrapper}>
      {bookingState.error && (
        <>
          <Heading component="h3">Error Encountered</Heading>
          <Text align="center" component="p" className={styles.ErrorMessage}>
            {bookingState.error}
          </Text>
          <Button inline onClick={() => navigate(-1)}>
            Click here to return to the previous page.
          </Button>
        </>
      )}
      {bookingState.loading && <Loader screen />}
    </div>
  );
};

export default BookingLandingPage;
