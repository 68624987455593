import React, { useContext, useState } from "react";
import { connect } from "react-redux";

import { AnnouncementTooltip } from "../../../../../../ui/Announcements";

import {
  ResponsiveComponentSwitch,
  ResponsiveHide,
  ResponsiveMobileDrawer
} from "../../../../../../ui/Responsive";
import {
  Clock,
  Transcript,
  Profile,
  Chats,
  Mail,
  StarChat,
  Document,
  History,
  MikaAI
} from "../../../../../../ui/Icon";
import Text from "../../../../../../ui/Text";

import { ToolButton } from "./ToolButton";
import MobileMenu from "./MobileMenu";

import { LayoutContext, LayoutViewMode } from "../../../../../../providers/LayoutProvider";
import { OrganizationContext } from "../../../../../../providers/OrganizationProvider";

import DirectPatientMessageButton from "../../../../../DirectPatientMessageButton";
import FeedbackManager from "../../../../../../ui/FeedbackManager";

import {
  openModal as openModalAction,
  OpenModal,
  setFeedbackMode
} from "../../../../../../../actions";
import formatDate from "../../../../../../../utils/formatDate";

import { ModalTypes } from "../../../../../../../constants";
import { FeedbackFormModes } from "../../../../../../../typedConstants";

import {
  AnnouncementName,
  OrganizationEmrFeatures,
  RecordingSessionStatus,
  RecordingState,
  ScribeToolMode,
  AppointmentDetail,
  Appointment,
  SetFeedbackModeData
} from "../../../../../../../types";

import styles from "./index.module.scss";

interface PropsType {
  appointmentData?: AppointmentDetail & Appointment;
  recording: RecordingState | null;
  activeTool: ScribeToolMode | null;
  appointmentId?: string;
  noteIds: number[] | null;
  activeNoteId: number | null;
  setActiveTool: (tool: ScribeToolMode | null) => void;
  setToolOpen: (isOpen: boolean) => void;
  setFeedbackMode: (data: SetFeedbackModeData) => void;
  openModal: OpenModal;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DefaultToolsWrapper = ({ children }: any) => {
  return <div className={styles.ToolsWrapper}>{children}</div>;
};

const MobileToolsWrapper = ({
  header,
  mobileDrawerOpen,
  closeMobileDrawer,
  children
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) => {
  return (
    <ResponsiveMobileDrawer
      header={header}
      isOpen={mobileDrawerOpen}
      closeMobileDrawer={closeMobileDrawer}
    >
      {children}
    </ResponsiveMobileDrawer>
  );
};

const ToolsNav = ({
  recording,
  appointmentData,
  activeTool,
  appointmentId,
  noteIds,
  activeNoteId,
  setActiveTool,
  setToolOpen,
  setFeedbackMode,
  openModal
}: PropsType) => {
  const organization = useContext(OrganizationContext);
  const { viewMode } = useContext(LayoutContext);
  const emrFeatures = organization?.emrFeatures || [];
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState<boolean>(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState<boolean>(false);

  const orgIsUnintegrated =
    emrFeatures.includes(OrganizationEmrFeatures.UNINTEGRATED_APPOINTMENTS) &&
    emrFeatures.includes(OrganizationEmrFeatures.UNINTEGRATED_PATIENTS);
  const orgIsVerified = organization?.verified;
  const isMobile = viewMode === LayoutViewMode.MOBILE;

  const showCompletedSessionTutorials = recording?.status === RecordingSessionStatus.COMPLETE;
  const recordingStartedOrCompleted = recording?.startedAt || recording?.sessionCompleted;

  const appointmentFullDateTime = appointmentData
    ? `${formatDate(appointmentData.start, "fullDateTime", appointmentData.timezone)}`
    : "";

  return (
    <>
      <ResponsiveHide hideOnDesktop hideOnTablet>
        <MobileMenu openMobileDrawer={setMobileDrawerOpen} />
      </ResponsiveHide>
      <ResponsiveComponentSwitch
        DefaultComponent={DefaultToolsWrapper}
        MobileComponent={MobileToolsWrapper}
        // other props
        header={appointmentFullDateTime}
        mobileDrawerOpen={mobileDrawerOpen}
        closeMobileDrawer={() => setMobileDrawerOpen(false)}
      >
        <div className={styles.ToolsList}>
          <div className={styles.ToolsSection}>
            <ToolButton
              toolTipContent={<Text size="S">Do more with mika</Text>}
              isActive={activeTool === ScribeToolMode.DO_MORE_WITH_MIKA}
              onClick={() => {
                setActiveTool(ScribeToolMode.DO_MORE_WITH_MIKA);
                setToolOpen(true);
                setMobileDrawerOpen(false);
              }}
              icon={<MikaAI size={24} />}
            />
            {recordingStartedOrCompleted && (
              <>
                <ToolButton
                  toolTipContent={<Text size="S">Time</Text>}
                  isActive={activeTool === ScribeToolMode.TIMERS}
                  onClick={() => {
                    setActiveTool(ScribeToolMode.TIMERS);
                    setToolOpen(true);
                    setMobileDrawerOpen(false);
                  }}
                  icon={<Clock size={24} />}
                />
                <ToolButton
                  toolTipContent={<Text size="S">Audio Transcript</Text>}
                  isActive={activeTool === ScribeToolMode.AUDIO_TRANSCRIPT}
                  onClick={() => {
                    setActiveTool(ScribeToolMode.AUDIO_TRANSCRIPT);
                    setToolOpen(true);
                    setMobileDrawerOpen(false);
                  }}
                  icon={<Transcript size={24} />}
                />
              </>
            )}
          </div>

          <div className={styles.ToolsSection}>
            <ToolButton
              toolTipContent={<Text size="S">Appointment Details</Text>}
              isActive={false}
              onClick={() => {
                openModal(ModalTypes.VIEW_EDIT_APPOINTMENT_DETAILS);
                setMobileDrawerOpen(false); // ?
              }}
              icon={<Profile size={24} />}
            />
            <ToolButton
              toolTipContent={<Text size="S">Appointment History</Text>}
              isActive={activeTool === ScribeToolMode.CHAT_TRANSCRIPTS}
              onClick={() => {
                setActiveTool(ScribeToolMode.CHAT_TRANSCRIPTS);
                setToolOpen(true);
                setMobileDrawerOpen(false);
              }}
              icon={<Chats size={24} />}
            />
            <ToolButton
              toolTipContent={<Text size="S">Chat Summaries</Text>}
              isActive={activeTool === ScribeToolMode.CHAT_SUMMARIES}
              onClick={() => {
                setActiveTool(ScribeToolMode.CHAT_SUMMARIES);
                setToolOpen(true);
                setMobileDrawerOpen(false);
              }}
              icon={<Document size={24} />}
            />
          </div>
          <div className={styles.ToolsSection}>
            <ToolButton
              toolTipContent={<Text size="S">Sent Messages</Text>}
              isActive={activeTool === ScribeToolMode.SENT_MESSAGES}
              onClick={() => {
                setActiveTool(ScribeToolMode.SENT_MESSAGES);
                setToolOpen(true);
                setMobileDrawerOpen(false);
              }}
              icon={<History size={24} />}
            />

            <DirectPatientMessageButton
              appointmentData={appointmentData}
              customButton={(props: { disabled?: boolean; onClick: () => void }) => {
                const { disabled, onClick } = props;
                if (disabled && orgIsUnintegrated && orgIsVerified) {
                  return (
                    <ToolButton
                      toolTipContent={
                        isMobile ? (
                          <Text size="S">Message Patient</Text>
                        ) : (
                          <Text size="S">
                            You need to add more contact details before you can message the patient.
                          </Text>
                        )
                      }
                      isActive={false}
                      onClick={onClick}
                      disabled={disabled}
                      icon={<Mail size={24} />}
                    />
                  );
                }
                if (disabled && orgIsUnintegrated && !orgIsVerified) {
                  return (
                    <ToolButton
                      toolTipContent={
                        isMobile ? (
                          <Text size="S">Message Patient</Text>
                        ) : (
                          <Text size="S">
                            Hi! If you are interested in learning more about our on demand messaging
                            feature, please email clinic advisor at demo@mikatahealth.com.
                          </Text>
                        )
                      }
                      isActive={false}
                      onClick={onClick}
                      disabled={disabled}
                      icon={<Mail size={24} />}
                    />
                  );
                }

                return (
                  <ToolButton
                    toolTipContent={<Text size="S">Message Patient</Text>}
                    isActive={false}
                    onClick={onClick}
                    disabled={disabled}
                    icon={<Mail size={24} />}
                  />
                );
              }}
            />
            <div className={styles.ToolColumnFooter}>
              <AnnouncementTooltip
                arrowPosition="bottomRight"
                announcementName={AnnouncementName.SCRIBE_FEEDBACK_TUTORIAL}
                announcementShowable={showCompletedSessionTutorials}
              >
                <FeedbackManager
                  position="bottomRight"
                  context={{ appointmentId, noteIds, activeNoteId }}
                  feedbackModalOpen={feedbackModalOpen}
                  setFeedbackModalOpen={(value: boolean) => {
                    setFeedbackModalOpen(value);
                  }}
                >
                  <ToolButton
                    toolTipContent={<Text size="S">Give Feedback</Text>}
                    toolTipPosition="topLeft"
                    isActive={false}
                    onClick={() => {
                      setFeedbackMode({ feedbackMode: FeedbackFormModes.SELECTION });
                      setFeedbackModalOpen(true);
                      setMobileDrawerOpen(false);
                    }}
                    icon={<StarChat size={24} />}
                  />
                </FeedbackManager>
              </AnnouncementTooltip>
            </div>
          </div>
        </div>
      </ResponsiveComponentSwitch>
    </>
  );
};

export default connect(null, { openModal: openModalAction, setFeedbackMode })(ToolsNav);
