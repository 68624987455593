import React from "react";
import cx from "classnames";

import styles from "./index.module.scss";

type PropsType = {
  inputIcon: React.ReactElement;
  children: React.ReactNode;
  className?: string;
  id?: string;
};

const CardBanner = ({ className, id, children, inputIcon }: PropsType): React.ReactElement => {
  return (
    <div id={id} className={cx(styles.CardBanner, className)}>
      <div className={styles.CardBannerIcon}>{inputIcon}</div>
      <div className={styles.CardBannerContent}>{children}</div>
    </div>
  );
};

export default CardBanner;
