import React from "react";
import cx from "classnames";

import Text from "../../../../../ui/Text";
import { Check } from "../../../../../ui/Icon";

import { Conversation, ConversationStatuses } from "../../../../../../types";

import styles from "./index.module.scss";

const { IN_PROGRESS, COMPLETE, NOT_STARTED, NEEDS_HELP, FLAGGED, LOCKED_OUT, NO_CONTACT } =
  ConversationStatuses;

type PropsType = {
  conversations: Conversation[];
};

const ChatStatuses = ({ conversations }: PropsType) => {
  return (
    <div className={styles.ChatStatuses}>
      {conversations.slice(0, 3).map((conversation) => {
        if (conversation.title === "Basic Direct Message") return <div />;
        const { status, title } = conversation;

        return (
          <div
            key={conversation.id}
            className={cx(styles.Chat, {
              [styles.ChatBlue]: status === IN_PROGRESS,
              [styles.ChatGreen]: status === COMPLETE,
              [styles.ChatGrey]: status === NOT_STARTED,
              [styles.ChatRed]: [NEEDS_HELP, FLAGGED, LOCKED_OUT, NO_CONTACT].includes(status),
              [styles.ChatCheck]: status === COMPLETE
            })}
          >
            {status === ConversationStatuses.COMPLETE && <Check size={12} strokeWidth={6} />}
            <div className={styles.Content}>
              <Text bold>{status}</Text>
              <Text size="S">{title}</Text>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ChatStatuses;
