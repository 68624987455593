import React, { useMemo, useContext } from "react";
import { connect } from "react-redux";

import { Form, FormGrid, TextInput } from "../../../../../ui/Input";
import Button, { ButtonRow } from "../../../../../ui/Button";
import Loader from "../../../../../ui/Loader";
import { OrganizationContext } from "../../../../../providers/OrganizationProvider";

import {
  openModal as OpenModalAction,
  OpenModal,
  closeModal as closeModalAction,
  updateOrgSettings as updateOrgSettingsAction
} from "../../../../../../actions";

import { ModalTypes, SettingNames } from "../../../../../../constants";
import { ReduxStateType, Setting } from "../../../../../../types";

type FormState = {
  instanceId: string;
};

type PropsType = {
  loading?: boolean;
  settings?: Array<Setting>;
  openModal: OpenModal;
  closeModal: () => void;
  updateOrgSettings: (organizationId: string, settings: Setting[]) => void;
};

const TelusAuthSetup = ({
  loading,
  settings,
  openModal,
  closeModal,
  updateOrgSettings
}: PropsType) => {
  const organization = useContext(OrganizationContext);

  const initialFormState: FormState | null = useMemo(() => {
    if (!settings) return null;

    const instanceIdSetting = settings.find(
      (setting) => setting.settingName === SettingNames.TELUS_INSTANCE_ID
    );

    return { instanceId: instanceIdSetting ? (instanceIdSetting.settingValue as string) : "" };
  }, [organization?.id, settings]);

  const save = (formValues: FormState) => {
    if (organization) {
      openModal(ModalTypes.CONFIRMATION, {
        title: `Are you sure you want to integrate with ${organization.fullName}?`,
        content:
          "Sensitive data will be pulled from the clinic’s EMR. Please ensure that you are integrating with the right organization.",
        onConfirmation: () => {
          updateOrgSettings(organization.id.toString(), [
            {
              settingName: SettingNames.TELUS_INSTANCE_ID,
              settingValue: formValues.instanceId.trim(),
              organizationId: organization.id
            }
          ]);
          closeModal();
        }
      });
    }
  };

  if (!initialFormState) return <Loader />;

  return (
    <Form
      initialValues={initialFormState}
      onSubmit={(formState) => save(formState.values as FormState)}
    >
      <FormGrid>
        <TextInput fieldName="instanceId" label="Telus Instance ID" />
      </FormGrid>

      <ButtonRow>
        <Button id="telusSave" type="submit" disabled={loading}>
          Save
        </Button>
      </ButtonRow>
    </Form>
  );
};

const mapStateToProps = ({ organizationDetails }: ReduxStateType) => {
  return {
    settings: organizationDetails.settings,
    loading: organizationDetails.settingsLoading
  };
};

export default connect(mapStateToProps, {
  openModal: OpenModalAction,
  closeModal: closeModalAction,
  updateOrgSettings: updateOrgSettingsAction
})(TelusAuthSetup);
