import React, { useState } from "react";
import { connect } from "react-redux";

import Card from "../../../ui/Card";
import Text from "../../../ui/Text";
import Button from "../../../ui/Button";
import Heading from "../../../ui/Heading";
import { ChevronLeft, MikataSimple } from "../../../ui/Icon";
import { CheckboxInput, Form, TextInput, SelectInput, FormGrid } from "../../../ui/Input";
import PromotionalCard from "../PromotionalCard";

import { combine, isEmail, isPhone, isRequired } from "../../../../utils/validators";

import {
  addSelfServeOrganization as addSelfServeOrganizationAction,
  AddSelfServeOrganizationData
} from "../../../../actions";

import { ReduxStateType, Option } from "../../../../types";

import styles from "./index.module.scss";

type FormState = {
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  email: string;
  organizationName?: string;
  specialty: string;
  address?: string;
  suite?: string;
  city: string;
  province?: string;
  country: string;
  timezone?: string;
  emrType?: string;
  other?: string;
  acceptedTerms: boolean;
};

type PropsType = {
  inviteCode: string;
  setNewOrganizationId: (orgId: number) => void;
  setEmail: (email: string) => void;
  error: boolean;
  loading: boolean;
  addSelfServeOrganization: (
    data: AddSelfServeOrganizationData,
    onSuccess?: (orgId: number) => void
  ) => void;
};

const initialFormState = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  organizationName: undefined,
  specialty: "",
  address: "",
  suite: "",
  city: "",
  province: "",
  country: "",
  timezone: undefined,
  emrType: "",
  other: "",
  acceptedTerms: false
};

const firstNameValidator = isRequired("Please enter your first name");
const lastNameValidator = isRequired("Please enter your last name");
const phoneNumberValidator = isPhone("Please enter a valid phone number");
const emailValidator = combine([
  isRequired("Please enter an email"),
  isEmail("Please enter a valid email")
]);
const specialtyValidator = isRequired("Please select a specialty");
const cityValidator = isRequired("Please enter a city");
const countryValidator = isRequired("Please enter a country");
const termsOfUseValidator = isRequired("Confirmation required");

const formValidator = (values: FormState) => {
  const validationResult = {
    firstName: firstNameValidator(values.firstName),
    lastName: lastNameValidator(values.lastName),
    phoneNumber: phoneNumberValidator(values.phoneNumber),
    email: emailValidator(values.email),
    organizationName: undefined,
    specialty: specialtyValidator(values.specialty),
    address: undefined,
    suite: undefined,
    city: cityValidator(values.city),
    province: undefined,
    country: countryValidator(values.country),
    timezone: undefined,
    emrType: undefined,
    other: undefined,
    acceptedTerms: termsOfUseValidator(values.acceptedTerms)
  };

  return validationResult;
};

const CreateSelfServeOrganizationForm = ({
  inviteCode,
  error,
  loading,
  addSelfServeOrganization,
  setEmail,
  setNewOrganizationId
}: PropsType) => {
  const [activeStep, setActiveStep] = useState(0);
  const onSave = (formState: FormState) => {
    setEmail(formState.email);
    addSelfServeOrganization(
      {
        inviteCode,
        firstName: formState.firstName,
        lastName: formState.lastName,
        organizationName: formState.organizationName,
        organizationPhoneNumber: formState.phoneNumber,
        organizationEmailAddress: formState.email,
        organizationSpecialty: formState.specialty,
        organizationAddress: formState.address,
        organizationSuite: formState.suite,
        organizationCity: formState.city,
        organizationProvince: formState.province,
        organizationCountry: formState.country,
        organizationTimezone: formState.timezone,
        organizationEmrType: formState.emrType || formState.other
      },
      (newOrgId) => {
        setNewOrganizationId(newOrgId);
      }
    );
  };

  const specialtyOptions: Option[] = [
    { label: "General/family medicine", value: "General/family medicine" },
    { label: "Multiple specialities", value: "Multiple specialities" },
    { label: "Radiology/Medical imaging", value: "Radiology/Medical imaging" },
    { label: "Aesthetics", value: "Aesthetics" },
    { label: "Allergy/Immunology", value: "Allergy/Immunology" },
    { label: "Cardiology", value: "Cardiology" },
    { label: "Chronic pain management", value: "Chronic pain management" },
    { label: "Dermatology", value: "Dermatology" },
    {
      label: "Endocrinology/Chronic disease management",
      value: "Endocrinology/Chronic disease management"
    },
    { label: "ENT (Ear, Nose, Throat)", value: "ENT (Ear, Nose, Throat)" },
    { label: "Hospital medicine", value: "Hospital medicine" },
    {
      label: "Natural/Integrative/Holistic medicine",
      value: "Natural/Integrative/Holistic medicine"
    },
    { label: "Orthopaedic", value: "Orthopaedic" },
    { label: "Paediatrics", value: "Paediatrics" },
    { label: "Podiatry", value: "Podiatry" },
    { label: "Psychology/Psychiatry/Mental health", value: "Psychology/Psychiatry/Mental health" },
    { label: "Sports medicine/Physiotherapy", value: "Sports medicine/Physiotherapy" },
    { label: "Urology", value: "Urology" },
    { label: "Walk-in only", value: "Walk-in only" },
    { label: "Women’s health/OBGYN", value: "Women’s health/OBGYN" },
    { label: "Other", value: "Other" }
  ];

  const emrTypeOptions: Option[] = [
    { label: "Accuro", value: "Accuro" },
    { label: "Juno", value: "Juno" },
    { label: "Med Access", value: "Med Access" },
    { label: "Oscar", value: "Oscar" },
    { label: "PS Suite", value: "PS Suite" },
    { label: "Wolf", value: "Wolf" }
  ];

  return (
    <div className={styles.SignupContainer}>
      <Card className={styles.Card}>
        <Form
          onSubmit={(formState) => onSave(formState.values as FormState)}
          initialValues={initialFormState}
          validateFields={(values) => {
            const validationResult = formValidator(values as FormState);
            const firstPageFields = ["firstName", "lastName", "phoneNumber", "email"];
            const hasErrorInFirstPage = Object.entries(validationResult).some(
              ([key, value]) => value && firstPageFields.includes(key)
            );
            if (hasErrorInFirstPage) {
              setActiveStep(0);
            }
            return validationResult;
          }}
        >
          <div className={styles.Logo}>
            <MikataSimple size={50} />
          </div>

          <div className={activeStep === 0 ? styles.ShowStep : styles.HideStep}>
            <Heading size="L" component="h2" className={styles.HeadingContainer}>
              Sign up for your 14-day free trial
              <Text size="M" className={styles.NoPaymentText}>
                No payment required
              </Text>
            </Heading>
            <Heading size="S" component="h5" bold>
              Step 1 of 2 - Contact method
            </Heading>
            <div className={styles.Box}>
              <TextInput
                label="First Name"
                id="firstName"
                fieldName="firstName"
                placeholder="Enter first name"
              />
              <TextInput
                label="Last Name"
                id="lastName"
                fieldName="lastName"
                placeholder="Enter last name"
              />
            </div>
            <TextInput
              label="Email"
              id="email"
              fieldName="email"
              placeholder="Enter email address"
            />
            <TextInput
              label="Phone Number (Optional)"
              id="phoneNumber"
              fieldName="phoneNumber"
              placeholder="Enter phone number"
            />
            <div className={styles.ButtonContainer}>
              <div className={styles.SignIn}>
                <Text className={styles.SignInText}>Already have an account?</Text>
                <a
                  type="button"
                  href={`${process.env.REACT_APP_CLINIC_URL}/login`}
                  rel="noreferrer"
                >
                  <Button inline>Sign in</Button>
                </a>
              </div>
              <Button
                className={styles.Button}
                id="signUpNext"
                onClick={() => setActiveStep(1)}
                disabled={loading}
              >
                Next
              </Button>
            </div>
          </div>
          <div className={activeStep === 1 ? styles.ShowStep : styles.HideStep}>
            <Button
              inline
              onClick={() => {
                setActiveStep(0);
              }}
              className={styles.BackButton}
            >
              <ChevronLeft size={24} />
              <Text size="M" bold className={styles.BackLink}>
                Back
              </Text>
            </Button>
            <Heading size="S" component="h5" bold>
              Step 2 of 2 - Setup your account
            </Heading>
            <FormGrid>
              <SelectInput
                fieldName="specialty"
                id="selfServeSpecialty"
                label="Specialty"
                options={specialtyOptions}
              />

              <SelectInput
                label="EMR Type (Optional)"
                fieldName="emrType"
                id="selfServeEMR"
                placeholder="Select or type an EMR type"
                options={emrTypeOptions}
              />
            </FormGrid>

            <TextInput
              label="Clinic Name (Optional)"
              fieldName="organizationName"
              id="selfServeClinicName"
              placeholder="Enter clinic name"
            />
            <FormGrid>
              <TextInput
                label="City"
                id="selfServeCity"
                fieldName="city"
                placeholder="Enter city name"
              />
              <TextInput
                label="Country"
                id="selfServeCountry"
                fieldName="country"
                placeholder="Enter country name"
              />
            </FormGrid>
            <CheckboxInput
              fieldName="acceptedTerms"
              id="acceptedTerms"
              label={
                <Text>
                  I have read and accept the{" "}
                  <a
                    type="button"
                    onClick={(event) => event.stopPropagation()}
                    href="https://mikatahealth.com/terms/mikata-terms-of-use"
                    target="_blank"
                    className={styles.InlineLink}
                    rel="noreferrer"
                  >
                    Terms of Use
                  </a>{" "}
                  and{" "}
                  <a
                    type="button"
                    onClick={(event) => event.stopPropagation()}
                    href="https://mikatahealth.com/privacy_policy"
                    target="_blank"
                    className={styles.InlineLink}
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                  <br />
                  (If I am an Alberta-based provider, I have also read and accept the{" "}
                  <a
                    type="button"
                    onClick={(event) => event.stopPropagation()}
                    href="https://mikatahealth.com/terms/information-management-agreement-ab"
                    target="_blank"
                    className={styles.InlineLink}
                    rel="noreferrer"
                  >
                    Information Management Agreement
                  </a>
                  )
                </Text>
              }
            />
            <div className={styles.SignupButtonContainer}>
              <Button type="submit" id="scribeSignUp" disabled={loading}>
                Sign up
              </Button>
            </div>
          </div>
          {error && (
            <Text className={styles.ErrorMessage}>
              Oops! Something went wrong{" "}
              <a
                className={styles.Link}
                href="https://mikatahealth.com/contact"
                target="_blank"
                rel="noreferrer"
              >
                Contact us
              </a>
            </Text>
          )}
        </Form>
      </Card>
      <PromotionalCard />
    </div>
  );
};

const mapStateToProps = ({ organizationDetails }: ReduxStateType) => {
  return {
    error: organizationDetails.addSelfServeOrganizationError,
    loading: organizationDetails.addSelfServeOrganizationLoading
  };
};

export default connect(mapStateToProps, {
  addSelfServeOrganization: addSelfServeOrganizationAction
})(CreateSelfServeOrganizationForm);
