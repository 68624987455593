import React from "react";

type PropsType = {
  size?: number;
};

const History = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.35 6.08034C19.9428 3.87138 17.7935 2.23704 15.2888 1.47128C12.7841 0.705522 10.0884 0.858597 7.6865 1.90297C5.41522 2.89056 3.54764 4.61384 2.38039 6.78887L1.96569 5.24118C1.82274 4.70771 1.27441 4.39113 0.740941 4.53407C0.207475 4.67701 -0.109108 5.22535 0.0338341 5.75882L1.13191 9.85689C1.27485 10.3904 1.82319 10.7069 2.35666 10.564L6.45473 9.46592C6.9882 9.32298 7.30478 8.77465 7.16184 8.24118C7.0189 7.70771 6.47056 7.39113 5.93709 7.53407L3.97659 8.05939C3.97896 8.05472 3.98129 8.05002 3.9836 8.04529C4.92299 6.11924 6.51881 4.59158 8.484 3.73709C10.4492 2.8826 12.6548 2.75736 14.7041 3.38389C16.7534 4.01042 18.5119 5.34761 19.6633 7.15494C20.8147 8.96227 21.2834 11.1211 20.9852 13.2432C20.6869 15.3653 19.6413 17.3113 18.0363 18.7312C16.4314 20.1512 14.3724 20.9519 12.2298 20.9893C10.0872 21.0267 8.00158 20.2983 6.34804 18.9353C4.97098 17.8001 3.96885 16.2867 3.45731 14.5935C3.29759 14.0648 2.73953 13.7657 2.21085 13.9254C1.68216 14.0851 1.38305 14.6431 1.54277 15.1718C2.16798 17.2414 3.39278 19.0911 5.07588 20.4785C7.09687 22.1445 9.64599 23.0347 12.2647 22.989C14.8835 22.9432 17.3999 21.9646 19.3616 20.2291C21.3232 18.4937 22.6012 16.1152 22.9657 13.5215C23.3302 10.9279 22.7573 8.28929 21.35 6.08034ZM12.0712 5C12.6235 5 13.0712 5.44771 13.0712 6V11.5858L15.7783 14.2929C16.1689 14.6834 16.1689 15.3166 15.7783 15.7071C15.3878 16.0976 14.7547 16.0976 14.3641 15.7071L11.3641 12.7071C11.1766 12.5196 11.0712 12.2652 11.0712 12V6C11.0712 5.44771 11.5189 5 12.0712 5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default History;
