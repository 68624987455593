import axios from "axios";
import getToken from "../utils/getToken";
import { ROOT_URL } from "../constants";
import { BillingCustomer, BillingSubscription } from "../types";

export const getBillingUrl = async (organizationId: number) => {
  const token = getToken();
  const config = {
    headers: { Authorization: `${token}`, "Content-Type": "application/json" }
  };

  try {
    const response = await axios.get(
      `${ROOT_URL}/organizations/${organizationId}/billing/url`,
      config
    );
    const { data } = response;
    return data;
  } catch (e) {
    console.log("Could not get billing redirect url: ", e);
    return { error: e };
  }
};

export const fetchBillingCustomers = async (
  organizationId: number
): Promise<BillingCustomer[] | string> => {
  const token = getToken();
  const config = {
    headers: { Authorization: `${token}`, "Content-Type": "application/json" }
  };

  try {
    const response = await axios.post(
      `${ROOT_URL}/organizations/${organizationId}/billing/proxy`,
      { request: "customerList" },
      config
    );
    const { data } = response;
    return data;
  } catch (e) {
    return e as string;
  }
};

export const getOrganizationSubscriptions = async (
  organizationId: number,
  customerId: string
): Promise<BillingSubscription[] | string> => {
  const token = getToken();
  const config = {
    headers: { Authorization: `${token}`, "Content-Type": "application/json" }
  };

  try {
    const response = await axios.post(
      `${ROOT_URL}/organizations/${organizationId}/billing/proxy`,
      { request: "subscriptions", customerId },
      config
    );
    const { data } = response;
    return data;
  } catch (e) {
    return e as string;
  }
};
