import React from "react";

const Analytics = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 17C8.44772 17 8 16.5523 8 16L8 12C8 11.4477 8.44772 11 9 11C9.55228 11 10 11.4477 10 12L10 16C10 16.5523 9.55228 17 9 17Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 17C12.4477 17 12 16.5523 12 16L12 10C12 9.44772 12.4477 9 13 9C13.5523 9 14 9.44772 14 10L14 16C14 16.5523 13.5523 17 13 17Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 17C16.4477 17 16 16.5523 16 16L16 8C16 7.44771 16.4477 7 17 7C17.5523 7 18 7.44771 18 8L18 16C18 16.5523 17.5523 17 17 17Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.125 20C4.64175 20 4.25 19.6082 4.25 19.125L4.25 5.875C4.25 5.39175 4.64175 5 5.125 5C5.60825 5 6 5.39175 6 5.875L6 19.125C6 19.6082 5.60825 20 5.125 20Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.29999 19.125C4.29999 18.6418 4.69174 18.25 5.17499 18.25L19.925 18.25C20.4082 18.25 20.8 18.6418 20.8 19.125C20.8 19.6083 20.4082 20 19.925 20L5.17499 20C4.69174 20 4.29999 19.6082 4.29999 19.125Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Analytics;
