import React, { useState, useReducer, Dispatch, useEffect, useContext } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

import Heading from "../../../ui/Heading";
import Card, { CardDivider } from "../../../ui/Card";
import { FullScreenModal } from "../../../ui/Modal";
import Button from "../../../ui/Button";
import Text from "../../../ui/Text";
import { ChevronLeft } from "../../../ui/Icon";
import Loader from "../../../ui/Loader";
import { ResponsiveHide } from "../../../ui/Responsive";

import {
  BillingSummary,
  ConfirmAndPay,
  PaymentBillingInformation,
  SelectPlan
} from "./ScribeSubscriptionSteps";

import { OrganizationContext } from "../../../providers/OrganizationProvider";

import { updateQueryString } from "../../../../utils/queryStringHelpers";
import { fetchProductList as fetchProductListAction } from "../../../../actions";

import { ChargeOverProductPlan, Invoice, ReduxStateType } from "../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  proratedInvoice?: Invoice;
  billingLoading: boolean;
  isModalOpen: boolean;
  productsLoading: boolean;
  fetchProductList: (organizationId: string) => void;
  products?: ChargeOverProductPlan[];
};

const calculateProratedSubtotal = (
  planRenewalPeriod: string,
  planRate: number,
  canProrate: boolean
) => {
  if (!canProrate) return planRate;
  const dayOfPeriod = planRenewalPeriod === "month" ? moment().date() : moment().dayOfYear();

  const lastDayOfPeriod =
    planRenewalPeriod === "month"
      ? moment().endOf("month").date()
      : moment().endOf("year").dayOfYear();

  const proratedSubtotal = ((lastDayOfPeriod - dayOfPeriod) / lastDayOfPeriod) * planRate;

  return proratedSubtotal;
};

const getBillingStartDate = (payCycleUnit: string, canProrate: boolean) => {
  let startDate = moment();
  if (canProrate) {
    startDate = moment()
      .add(1, payCycleUnit as "month" | "year")
      .startOf(payCycleUnit as "month" | "year");
  }
  return startDate.format("MMM D, YYYY");
};

export type CreateScribeSubscriptionPlanModalSteps =
  | "selectPlan"
  | "paymentBillingInformation"
  | "confirmAndPay"
  | "billingSummary";

export type CreateScribeSubscriptionFormState = {
  selectPlan?: {
    loading?: boolean;
    values?: { productKey?: string; selectedPlan?: ChargeOverProductPlan };
  };
  paymentBillingInformation?: {
    loading?: boolean;
    values?: {
      cardNumber?: string;
      expiryMonth?: string;
      expiryYear?: string;
      cvv?: string;
      cardName?: string;
      address?: string;
      suite?: string;
      city?: string;
      provinceState?: string;
      country?: string;
      postalZipCode?: string;
    };
  };
  confirmAndPay?: {
    loading?: boolean;
    values?: {
      acceptedRenewal?: boolean;
      acceptedTerms?: boolean;
    };
  };
};

const CreateScribeSubscriptionPlanInitialFormState: CreateScribeSubscriptionFormState = {
  selectPlan: {
    loading: false,
    values: { productKey: "", selectedPlan: undefined }
  },
  paymentBillingInformation: {
    loading: false,
    values: {
      cardNumber: "",
      expiryMonth: "",
      expiryYear: "",
      cvv: "",
      cardName: "",
      address: "",
      suite: "",
      city: "",
      provinceState: "",
      country: "Canada",
      postalZipCode: ""
    }
  },
  confirmAndPay: {
    loading: false,
    values: {
      acceptedRenewal: false,
      acceptedTerms: false
    }
  }
};

export type CreateScribeSubscriptionPlanFormData = {
  type: CreateScribeSubscriptionPlanModalSteps | "clearState";
  payload:
    | CreateScribeSubscriptionFormState["selectPlan"]
    | CreateScribeSubscriptionFormState["paymentBillingInformation"]
    | CreateScribeSubscriptionFormState["confirmAndPay"]
    | CreateScribeSubscriptionFormState;
};

const FormSteps = (
  formState: CreateScribeSubscriptionFormState,
  setFormState: Dispatch<CreateScribeSubscriptionPlanFormData>,
  submitStep: (
    stepName: CreateScribeSubscriptionPlanModalSteps,
    formState: CreateScribeSubscriptionPlanFormData
  ) => void,
  possiblePlans: ChargeOverProductPlan[]
) => [
  {
    stepName: "selectPlan",
    title: "Select your plan",
    content: (
      <SelectPlan
        formState={formState}
        setFormState={setFormState}
        plans={possiblePlans}
        submitStep={submitStep}
      />
    )
  },
  {
    stepName: "paymentBillingInformation",
    title: "Payment and billing information",
    content: (
      <PaymentBillingInformation
        formState={formState}
        setFormState={setFormState}
        submitStep={submitStep}
      />
    ),
    backButton: (
      <Button
        inline
        onClick={() => {
          submitStep("selectPlan", {
            type: "paymentBillingInformation",
            payload: CreateScribeSubscriptionPlanInitialFormState.paymentBillingInformation
          });
        }}
        className={styles.BackButton}
      >
        <ChevronLeft />
      </Button>
    )
  },
  {
    stepName: "confirmAndPay",
    title: "Confirm and pay",
    content: (
      <ConfirmAndPay formState={formState} setFormState={setFormState} submitStep={submitStep} />
    ),
    backButton: (
      <Button
        inline
        onClick={() => {
          submitStep("paymentBillingInformation", {
            type: "confirmAndPay",
            payload: CreateScribeSubscriptionPlanInitialFormState.confirmAndPay
          });
        }}
        className={styles.BackButton}
      >
        <ChevronLeft />
      </Button>
    )
  },
  {
    stepName: "billingSummary",
    title: "Billing Summary",
    content: <BillingSummary formState={formState} />
  }
];

export const CreateScribeSubscriptionPlanReducer = (
  formState: CreateScribeSubscriptionFormState,
  { type, payload }: CreateScribeSubscriptionPlanFormData
): CreateScribeSubscriptionFormState => {
  switch (type) {
    case "selectPlan":
      return {
        ...formState,
        selectPlan: {
          ...(payload as CreateScribeSubscriptionFormState["selectPlan"])
        }
      };
    case "paymentBillingInformation":
      return {
        ...formState,
        paymentBillingInformation: {
          ...(payload as CreateScribeSubscriptionFormState["paymentBillingInformation"])
        }
      };
    case "confirmAndPay":
      return {
        ...formState,
        confirmAndPay: {
          ...(payload as CreateScribeSubscriptionFormState["confirmAndPay"])
        }
      };
    case "clearState":
      return CreateScribeSubscriptionPlanInitialFormState;

    default:
      return formState;
  }
};

const CreateScribeSubscriptionPlan = ({
  proratedInvoice,
  billingLoading,
  isModalOpen,
  productsLoading,
  fetchProductList,
  products
}: PropsType) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalStep, setModalStep] = useState<CreateScribeSubscriptionPlanModalSteps>("selectPlan");
  const [formState, setFormState] = useReducer(
    CreateScribeSubscriptionPlanReducer,
    CreateScribeSubscriptionPlanInitialFormState
  );

  const organization = useContext(OrganizationContext);
  const organizationId = organization?.id;

  useEffect(() => {
    if (organizationId) {
      fetchProductList(organizationId.toString());
    }
  }, [organizationId]);

  const possiblePlans =
    !productsLoading && products ? products?.filter((product) => product.isSelfServe) : undefined;

  const submitStep = (
    stepName: CreateScribeSubscriptionPlanModalSteps,
    formData: CreateScribeSubscriptionPlanFormData
  ) => {
    setFormState(formData);

    setModalStep(stepName);

    const scrollRef = document.getElementById("fullScreenModalScrollContainer");
    scrollRef?.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const steps = FormSteps(formState, setFormState, submitStep, possiblePlans || []);

  const currentStep = steps.find((step) => modalStep === step.stepName);
  return (
    <FullScreenModal
      isOpen={isModalOpen}
      onClose={() => {
        updateQueryString({ form: undefined }, setSearchParams);
        setModalStep("selectPlan");
        setFormState({ type: "clearState", payload: CreateScribeSubscriptionPlanInitialFormState });
      }}
      title={currentStep?.title}
      contentClassName={styles.ModalContent}
    >
      <ResponsiveHide hideOnTablet hideOnDesktop>
        <Card>
          <Text>
            Billing page is not available on small screens yet, we are working on it. Please log in
            on a desktop or tablet to complete your billing sign-up.
          </Text>
        </Card>
      </ResponsiveHide>

      <ResponsiveHide hideOnMobile>
        <div className={styles.FlexRow}>
          {currentStep?.backButton}
          <Heading size="L" className={styles.BackButtonHeading}>
            {currentStep?.title}
          </Heading>
        </div>

        <div className={styles.Cards}>
          <Card className={styles.Card}>{currentStep?.content}</Card>

          {formState.selectPlan?.values?.selectedPlan && (
            <Card className={styles.SummaryCard}>
              {billingLoading ? (
                <>
                  <Heading>Payment Summary</Heading>
                  <Loader small center />
                </>
              ) : (
                <>
                  <Heading>Payment Summary</Heading>
                  <div className={styles.CardRow}>
                    <Text>{formState.selectPlan?.values.selectedPlan?.name}</Text>
                    <Text
                      className={
                        formState.selectPlan?.values.selectedPlan?.canProrate
                          ? styles.Strikethrough
                          : ""
                      }
                    >
                      $
                      {formState.selectPlan?.values.selectedPlan?.rate
                        ? formState.selectPlan?.values.selectedPlan.rate.toFixed(2)
                        : ""}
                    </Text>
                  </div>
                  {formState.selectPlan?.values.selectedPlan?.canProrate && (
                    <div className={cx(styles.CardRow, styles.TextMarginBottom)}>
                      <Text className={styles.ProratedTextGrey}>
                        Prorated {moment().format("MMM DD")} -&nbsp;
                        {moment()
                          .endOf(
                            formState.selectPlan?.values.selectedPlan?.payCycleUnit as
                              | "month"
                              | "year"
                          )
                          .format("MMM DD")}
                      </Text>
                      <Text className={styles.ProratedTextGreen}>
                        $
                        {proratedInvoice
                          ? Number(proratedInvoice.subtotal).toFixed(2)
                          : calculateProratedSubtotal(
                              formState.selectPlan?.values.selectedPlan?.payCycleUnit as string,
                              formState.selectPlan?.values.selectedPlan?.rate as number,
                              formState.selectPlan?.values.selectedPlan?.canProrate
                            ).toFixed(2)}
                      </Text>
                    </div>
                  )}
                  <div className={styles.CardRow}>
                    <Text>Tax</Text>
                    <Text className={styles.ProratedTextGrey}>
                      {proratedInvoice
                        ? `$${Number(proratedInvoice.taxes).toFixed(2)}`
                        : "To be calculated"}
                    </Text>
                  </div>
                  <CardDivider />
                  <div className={cx(styles.CardRow, styles.TextMarginBottom)}>
                    <Heading bold size="S">
                      Today's charge
                    </Heading>
                    <Heading bold size="S">
                      CAD $
                      {proratedInvoice
                        ? Number(proratedInvoice.total).toFixed(2)
                        : `${calculateProratedSubtotal(
                            formState.selectPlan?.values.selectedPlan?.payCycleUnit as string,
                            formState.selectPlan?.values.selectedPlan?.rate as number,
                            formState.selectPlan?.values.selectedPlan?.canProrate
                          ).toFixed(2)} + Tax`}
                    </Heading>
                  </div>
                  Starting&nbsp;
                  {getBillingStartDate(
                    formState.selectPlan?.values.selectedPlan?.payCycleUnit,
                    formState.selectPlan?.values.selectedPlan?.canProrate
                  )}
                  , you’ll be charged CAD $
                  {formState.selectPlan?.values.selectedPlan?.rate?.toFixed(2)}
                  &nbsp;+ Tax {formState.selectPlan?.values.selectedPlan?.payCycleUnit}ly until you
                  cancel your subscription.
                </>
              )}
            </Card>
          )}
        </div>
      </ResponsiveHide>
    </FullScreenModal>
  );
};

const mapStateToProps = ({ billing }: ReduxStateType) => {
  return {
    proratedInvoice: billing.proratedInvoice,
    billingLoading: billing.billingLoading,
    paymentLoading: billing.paymentLoading,
    productsLoading: billing.productsLoading,
    products: billing.products
  };
};

export default connect(mapStateToProps, {
  fetchProductList: fetchProductListAction
})(CreateScribeSubscriptionPlan);
