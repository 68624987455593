import {
  FETCH_INSTRUCTIONS,
  UPDATE_INSTRUCTIONS,
  FETCH_INSTRUCTION_DETAILS,
  ADD_INSTRUCTION,
  DELETE_INSTRUCTION
} from "../constants";

import {
  UpdateInstructionsAction,
  FetchInstructionsAction,
  FetchInstructionDetailsAction,
  AddInstructionAction,
  DeleteInstructionAction
} from "../actions";

import { ActionStatus, InstructionDetailed, InstructionWithReasons, Option } from "../types";

export type InstructionsAction =
  | UpdateInstructionsAction
  | FetchInstructionsAction
  | FetchInstructionDetailsAction
  | AddInstructionAction
  | DeleteInstructionAction;

export type InstructionsReduxState = {
  data: Array<InstructionWithReasons>;
  contentTemplateOptions: Option[];
  initialized: boolean;
  updateError: boolean;
  updateLoading: boolean;
  addLoading: boolean;
  deleteLoading: boolean;
  instructionDetail: { instruction: InstructionDetailed | null };
};

const initialInstructionsState: InstructionsReduxState = {
  data: [],
  contentTemplateOptions: [],
  initialized: false,
  updateError: false,
  updateLoading: false,
  addLoading: false,
  deleteLoading: false,
  instructionDetail: { instruction: null }
};

export const instructionsReducer = (
  state = initialInstructionsState,
  action: InstructionsAction
): InstructionsReduxState => {
  const { type } = action;

  switch (type) {
    case FETCH_INSTRUCTIONS: {
      const { payload, status } = action as FetchInstructionsAction;
      return {
        ...state,
        data: payload?.instructions || state.data,
        contentTemplateOptions: payload?.contentTemplateOptions || state.contentTemplateOptions,
        initialized: state.initialized || status !== ActionStatus.loading
      };
    }

    case FETCH_INSTRUCTION_DETAILS: {
      const { payload, status } = action as FetchInstructionDetailsAction;
      return {
        ...state,
        instructionDetail:
          payload?.checklist && status === ActionStatus.success
            ? { instruction: payload.checklist || null }
            : initialInstructionsState.instructionDetail
      };
    }

    case UPDATE_INSTRUCTIONS: {
      const { status, payload } = action as UpdateInstructionsAction;

      return {
        ...state,
        instructionDetail:
          payload?.instructionDetail && status === ActionStatus.success
            ? { instruction: payload.instructionDetail || null }
            : state.instructionDetail,
        updateLoading: status === ActionStatus.loading,
        updateError: status === ActionStatus.error
      };
    }

    case ADD_INSTRUCTION: {
      const { status } = action as AddInstructionAction;

      return {
        ...state,
        addLoading: status === ActionStatus.loading
      };
    }

    case DELETE_INSTRUCTION: {
      const { status } = action as DeleteInstructionAction;

      return {
        ...state,
        deleteLoading: status === ActionStatus.loading
      };
    }

    default:
      return state;
  }
};
