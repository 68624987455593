/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import cx from "classnames";

import * as d3 from "d3";

import {
  openModal as OpenModalAction,
  getChatFlowDetails as getChatFlowDetailsAction,
  clearChatFlowDetails as clearChatFlowDetailsAction
} from "../../../../../../../../actions";

import {
  addEdgeElements,
  addNodeElements,
  buildLinkPath,
  constrainXPosition,
  constrainYPosition,
  getMaxProcessedXLevel,
  getMaxProcessedYLevel,
  processNodes,
  getMapSizeData
} from "./helpers";

import {
  ChatFlowsNodes,
  ReduxStateType,
  MessageTemplateConnection
} from "../../../../../../../../types";
import { MapSizeData, D3Node, D3Edge, ChatViewMode } from "./types";

import styles from "./index.module.scss";

type PropsType = {
  viewMode: ChatViewMode;
  nodes?: Array<ChatFlowsNodes>;
  edges?: Array<MessageTemplateConnection>;
  onNodeSelect: (nodeId: number | null) => void;
  selectedNodeId?: number | null;
};

const ChatMap = ({ viewMode, nodes, edges, onNodeSelect, selectedNodeId }: PropsType) => {
  useEffect(() => {
    const { processedNodes, edgesWithConnectedNodes } = processNodes(nodes || [], edges || []);

    const maxXLevel = getMaxProcessedXLevel(processedNodes);
    const maxYLevel = getMaxProcessedYLevel(processedNodes);

    const mapSizeData: MapSizeData = getMapSizeData(viewMode);

    const mapWidth = maxXLevel * mapSizeData.nodeSpacingX + 2 * mapSizeData.nodeWidth;
    const mapHeight = maxYLevel * mapSizeData.nodeSpacingY + 2 * mapSizeData.nodeHeight;

    const d3Nodes: D3Node[] = [...(nodes || [])]
      .filter((node) => Boolean(node))
      .map((node) => {
        const nodePositionDetails = processedNodes[node.id.toString()];

        if (!nodePositionDetails) {
          return {
            id: node.id,
            inputName: node.inputName,
            displayName: node.displayName,
            cardType: node.cardType,
            payloadContent: node.payloadContent,
            payloadOptions: node.payloadOptions,
            tagNames: node.tagNames,
            subChatFlow: node.subChatFlow,
            processedOrder: 0,
            size: mapSizeData.nodeWidth, // TODO: sizeX
            fixed: false,
            x: 1 * mapSizeData.nodeSpacingX,
            y: 1 * mapSizeData.nodeSpacingY,
            fx: 1 * mapSizeData.nodeSpacingX,
            fy: 1 * mapSizeData.nodeSpacingY
          };
        }

        return {
          id: node.id,
          inputName: node.inputName,
          displayName: node.displayName,
          cardType: node.cardType,
          payloadContent: node.payloadContent,
          payloadOptions: node.payloadOptions,
          tagNames: node.tagNames,
          subChatFlow: node.subChatFlow,
          processedOrder: nodePositionDetails.processedOrder,
          size: mapSizeData.nodeWidth, // TODO: sizeX
          fixed: nodePositionDetails.fixed,
          fx: nodePositionDetails.xLevel * mapSizeData.nodeSpacingX + 1 * mapSizeData.nodeWidth,
          x: nodePositionDetails.xLevel * mapSizeData.nodeSpacingX + 1 * mapSizeData.nodeWidth,
          fy: nodePositionDetails.yLevel * mapSizeData.nodeSpacingY + 1 * mapSizeData.nodeHeight,
          y: nodePositionDetails.yLevel * mapSizeData.nodeSpacingY + 1 * mapSizeData.nodeHeight
        };
      })
      .filter((node) => !!node);

    const d3Edges: D3Edge[] = (edgesWithConnectedNodes || [])
      .map((edge) => {
        if ((edge as MessageTemplateConnection).fromMessageTemplateId) {
          const targetData =
            processedNodes[(edge as MessageTemplateConnection).toMessageTemplateId.toString()];
          const targetX = targetData
            ? targetData.xLevel * mapSizeData.nodeSpacingX + 1 * mapSizeData.nodeWidth
            : 0;
          const targetY = targetData
            ? targetData.yLevel * mapSizeData.nodeSpacingY + 1 * mapSizeData.nodeHeight
            : 0;

          return {
            source: (edge as MessageTemplateConnection).fromMessageTemplateId,
            target: (edge as MessageTemplateConnection).toMessageTemplateId,
            targetPosition: { x: targetX, y: targetY },
            isDefaultConnection: !edge.input && !edge.jsonLogic,
            input: edge.input || null,
            jsonLogic: edge?.jsonLogic || null
          };
        }

        return null;
      })
      .filter((edge) => !!edge) as D3Edge[];

    d3.select("#chatGraphRoot").select("svg").remove();

    const svg = d3
      .select("#chatGraphRoot")
      .append("svg")
      .attr("width", mapWidth)
      .attr("height", mapHeight)
      .attr("overflow", "visible");

    const linkSelection = addEdgeElements(
      svg,
      d3Edges,
      selectedNodeId || null,
      viewMode,
      mapSizeData,
      mapWidth,
      mapHeight
    );

    const nodeSelection = addNodeElements(
      svg,
      d3Nodes,
      selectedNodeId,
      onNodeSelect,
      viewMode,
      mapSizeData,
      mapWidth,
      mapHeight
    );

    let simulation: any = null;

    const ticked = () => {
      nodeSelection
        .attr("x", (d: D3Node) =>
          constrainXPosition(d.x - mapSizeData.nodeWidth / 2, mapSizeData, mapWidth)
        )
        .attr("y", (d: D3Node) =>
          constrainYPosition(d.y - mapSizeData.nodeHeight / 2, mapSizeData, mapHeight)
        );
      linkSelection.attr("d", buildLinkPath(mapSizeData, mapWidth, mapHeight));

      // stop simulation after first tick
      simulation?.stop();
    };

    simulation = d3
      .forceSimulation(d3Nodes)
      .force(
        "links",
        d3
          .forceLink(d3Edges)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .id((d: any) => d.id)
      )
      .on("tick", ticked);
  }, [nodes, edges, selectedNodeId, viewMode]);

  return (
    <div className={cx(styles.Wrapper)}>
      <div className={cx(styles.Content)}>
        <div
          id="chatGraphRoot"
          className={styles.Graph}
          onClick={() => {
            onNodeSelect(null);
          }}
        >
          <p id="tooltip" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ chats }: ReduxStateType) => {
  return {
    chatFlowId: chats.chatDetails.chatFlowId,
    published: !!chats.chatDetails.published,
    title: chats.chatDetails.title,
    chatFlowsNodes: chats.chatDetails.nodes,
    loading: chats.chatDetails.loading,
    draftChatFlowId: chats.chatDetails.draftChatFlowId,
    publishedChatFlowId: chats.chatDetails.publishedChatFlowId
  };
};

export default connect(mapStateToProps, {
  openModal: OpenModalAction,
  getChatFlowDetails: getChatFlowDetailsAction,
  clearChatFlowDetails: clearChatFlowDetailsAction
})(ChatMap);
