import React from "react";

type PropsType = {
  size?: number;
};

const Letters = ({ size = 24 }: PropsType) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={`${size}px`}
      height={`${size}px`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.688 18.805l1.068-3h5.327l1.058 3h2.66L8.336 5H6.493L1 18.805h2.688zm5.56-5.12H5.59l1.834-5.04 1.825 5.04zM17.877 19c1.155 0 2.184-.371 3.018-1.593l.155 1.398H23v-5.92c0-2.385-1.553-4.046-4.192-4.046-1.912 0-3.145.86-4.076 2.14l1.815 1.319c.417-.713 1.164-1.378 2.28-1.378 1.252 0 1.912.821 1.912 2.062h-2.523c-2.174 0-3.863.703-3.863 2.823 0 2.042 1.534 3.195 3.523 3.195zm.34-2.013c-.922 0-1.437-.459-1.437-1.103 0-.616.495-1.183 1.573-1.183h2.387c-.039 1.221-1.174 2.286-2.523 2.286z"
        fill="#494B50"
      />
    </svg>
  );
};

export default Letters;
