import React from "react";

type PropsType = {
  size?: number;
};

const Platform = ({ size = 24 }: PropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}px`}
      height={`${size + 1}px`}
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6.5C2 4.84315 3.34315 3.5 5 3.5H19C20.6569 3.5 22 4.84315 22 6.5V14.5C22 16.1569 20.6569 17.5 19 17.5H13V19.5H16C16.5523 19.5 17 19.9477 17 20.5C17 21.0523 16.5523 21.5 16 21.5H8C7.44772 21.5 7 21.0523 7 20.5C7 19.9477 7.44772 19.5 8 19.5H11V17.5H5C3.34315 17.5 2 16.1569 2 14.5V6.5ZM5 5.5C4.44772 5.5 4 5.94772 4 6.5V14.5C4 15.0523 4.44772 15.5 5 15.5H19C19.5523 15.5 20 15.0523 20 14.5V6.5C20 5.94772 19.5523 5.5 19 5.5H5Z"
        fill="#1D2029"
      />
    </svg>
  );
};

export default Platform;
