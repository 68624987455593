import React from "react";

import Card from "../../../ui/Card";
import Text from "../../../ui/Text";
import Heading from "../../../ui/Heading";
import { MikataSimple } from "../../../ui/Icon";

import styles from "./index.module.scss";

type PropsType = {
  email: string;
};

const CheckEmailCard = ({ email }: PropsType) => {
  return (
    <Card className={styles.Card}>
      <div className={styles.Logo}>
        <MikataSimple size={50} />
      </div>
      <Heading size="L" component="h1" id="checkEmailHeading">
        Check your email!
      </Heading>
      <Text>
        To start using Mikata Health, create a password with the link in the email we sent to{" "}
        <Text component="span" bold>
          {email}
        </Text>
        .
      </Text>
      <Text className={styles.Text}>
        Can't see the email? Please check your spam folders or{" "}
        <a
          className={styles.Link}
          href="https://mikatahealth.com/contact"
          target="_blank"
          rel="noreferrer"
        >
          contact us
        </a>
      </Text>
    </Card>
  );
};

export default CheckEmailCard;
