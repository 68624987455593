import React from "react";

import styles from "./index.module.scss";

type PropsType = {
  children: React.ReactNode;
};

export const FormGrid = ({ children }: PropsType) => {
  return <div className={styles.FormGrid}> {children}</div>;
};
