import React, { Dispatch, SetStateAction, useMemo } from "react";

import { JsonInput, TextAreaInput } from "../../../../../../../../ui/Input";

import BaseChatNodeForm, {
  BaseFormValues,
  DefaultCardFormValues,
  FormValues
} from "../../BaseChatNodeForm";

import { isJson } from "../../../../../../../../../utils/validators";

import { ChatNodeOptions } from "../../../../../../../../../constants";

import { ChatFlowsNodes } from "../../../../../../../../../types";

type PropsType = {
  chatId: string | undefined;
  node: ChatFlowsNodes;
  viewOnly: boolean;
  setSelectedNodeId: Dispatch<SetStateAction<number | null>>;
};

const jsonValidator = isJson("Please enter a valid JSON string");

const isInputNode = (cardType: string) => {
  return cardType
    ? ChatNodeOptions.find((node) => node.cardType === cardType)?.isInput || false
    : false;
};

const onSaveCustomTransformer = (formValues: FormValues) => {
  const values = formValues as BaseFormValues & DefaultCardFormValues;

  let payloadOptionsJSON = null;

  if (values.payloadOptions) {
    payloadOptionsJSON =
      typeof values.payloadOptions === "string"
        ? JSON.parse(values.payloadOptions as string)
        : values.payloadOptions;
  }

  return {
    payloadContent: values.payloadContent,
    payloadOptions: payloadOptionsJSON
  };
};

export const Default = ({ chatId, node, viewOnly, setSelectedNodeId }: PropsType) => {
  const initialFormValues: DefaultCardFormValues = useMemo(() => {
    return {
      payloadContent: node?.payloadContent ? node.payloadContent : "",
      payloadOptions: node?.payloadOptions || null
    };
  }, [node.id]);

  return (
    <BaseChatNodeForm
      chatId={chatId}
      initialValues={initialFormValues}
      onSaveCustomTransformer={onSaveCustomTransformer}
      node={node}
      key={node.id}
      isInput={isInputNode(node.cardType)}
      viewOnly={viewOnly}
      setSelectedNodeId={setSelectedNodeId}
    >
      <>
        <TextAreaInput
          fieldName="payloadContent"
          label="Payload Content"
          disabled={viewOnly}
          size="M"
        />
        <JsonInput
          label="Payload Options"
          size="M"
          fieldName="payloadOptions"
          validate={jsonValidator}
          disabled={viewOnly}
        />
      </>
    </BaseChatNodeForm>
  );
};

export default Default;
