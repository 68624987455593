import { getSignedUrl } from "../lib";

const downloadFile = async (bucketName, downloadingFilename) => {
  const response = await getSignedUrl({
    bucketName,
    downloadingFilename
  });
  const { url } = response;
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default downloadFile;
