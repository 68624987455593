import React from "react";
import cx from "classnames";

import { useFormApi, useFormState } from "informed";
import { CheckboxGroup, SelectInput, TextInput, ToggleInput } from "../../../../../../ui/Input";
import Button, { ButtonRow } from "../../../../../../ui/Button";
import { Send } from "../../../../../../ui/Icon";
import Heading from "../../../../../../ui/Heading";
import Tooltip from "../../../../../../ui/Tooltip";

import { PermissionsGuard } from "../../../../../../../hooks/usePermissions";

import { getTouchpointDescription } from "../../../JourneyTimeline/helpers";

import { OpenModal } from "../../../../../../../actions";

import { generateReasonLabel } from "../../../../../../../utils/generateReasonLabel";
import { isRequired, isGreaterThan } from "../../../../../../../utils/validators";

import { AdminModalTypes } from "../../../../../../../constants";

import RestrictTouchpointSummary from "../../RestrictTouchpointSummary";

import {
  AutomatedMessageConfigurationTopic,
  MessageConfigurationDetails,
  MessageTemplate,
  MessageTemplateMedium,
  Permissions,
  Reason,
  TouchpointExclusionCases,
  Option
} from "../../../../../../../types";
import { EditTouchpointFormState, PreviewMediumTypes } from ".";

import styles from "./index.module.scss";

type EditTouchpointProps = {
  automationId?: number;
  formDisabled: boolean;
  touchpoint: MessageConfigurationDetails;
  messageTemplates: MessageTemplate[];
  showMultiTemplate: boolean;
  previewMedium: PreviewMediumTypes;
  setPreviewMedium: (previewMed: PreviewMediumTypes) => void;
  multiPreviewMedium: PreviewMediumTypes;
  setMultiPreviewMedium: (multiPreviewMed: PreviewMediumTypes) => void;
  exclusionHoursUnitOptions: Option[];
  exclusionHoursOptions: Option[];
  touchpointSettingRangeTimeUnitOptions: Option[];
  touchpointSettingRangeTimeTypeOptions: Option[];
  excludeAppointmentsOptions: Option[];
  messageTemplateOptions: Option[];
  availableChatOptions: Option[];
  initialFormState: EditTouchpointFormState;
  userCanEdit?: boolean;
  deleteTouchpoint: () => void;
  setShowMultiTemplate: (showMultiTemp: boolean) => void;
  openModal: OpenModal;
  openSendTestModal: () => void;
  displaySelectedMessageTemplate: (
    messageTemplates: MessageTemplate[],
    templateId: string,
    medium: string
  ) => JSX.Element;
  reasons: Reason[];
};

const supportsExclusionHours = (topic?: string): boolean => {
  return (
    topic === AutomatedMessageConfigurationTopic.BOOKED ||
    topic === AutomatedMessageConfigurationTopic.CANCELLED
  );
};
const supportsExcludeAppointments = (topic?: string): boolean => {
  return topic === AutomatedMessageConfigurationTopic.POST_VISIT_LINK;
};

const templateValidator = isRequired("Please select from message templates");
const exclusionValueValidator = isGreaterThan(0, "Please enter a number greater than zero.");

const EditTouchpointForm = ({
  automationId,
  touchpoint,
  formDisabled,
  messageTemplates,
  openModal,
  openSendTestModal,
  messageTemplateOptions,
  previewMedium,
  setPreviewMedium,
  showMultiTemplate,
  setShowMultiTemplate,
  multiPreviewMedium,
  setMultiPreviewMedium,
  exclusionHoursUnitOptions,
  exclusionHoursOptions,
  touchpointSettingRangeTimeUnitOptions,
  touchpointSettingRangeTimeTypeOptions,
  availableChatOptions,
  initialFormState,
  userCanEdit,
  deleteTouchpoint,
  displaySelectedMessageTemplate,
  excludeAppointmentsOptions,
  reasons
}: EditTouchpointProps) => {
  const formState = useFormState();
  const formApi = useFormApi();
  const values = formState.values as EditTouchpointFormState;
  const showExclusionHours = supportsExclusionHours(touchpoint.topic);
  const showExcludeAppointments = supportsExcludeAppointments(touchpoint.topic);
  const restrictTouchpointActive =
    (showExcludeAppointments && !!values.settings?.exclude?.patientHasOtherAppt?.active) ||
    (showExclusionHours && !!values.exclusionHoursActive);

  return (
    <>
      <div className={styles.Row}>
        <Heading size="M">{getTouchpointDescription(touchpoint.scheduleData)}</Heading>
        <PermissionsGuard requiredPermissions={[Permissions.SEND_TEST_MESSAGE_TEMPLATES]}>
          <Tooltip
            icon={
              <Button className={styles.IconButton} inline onClick={openSendTestModal}>
                <Send />
              </Button>
            }
            size="S"
            position="bottomLeft"
            className={styles.RowButton}
          >
            Send Test Touchpoint
          </Tooltip>
        </PermissionsGuard>
      </div>

      <Heading size="S">Message</Heading>
      <div className={styles.MessageRow}>
        <SelectInput
          fieldName="templateId"
          label="Select Message Template"
          options={messageTemplateOptions}
          placeholder="Select Message Template"
          validate={templateValidator}
          disabled={formDisabled}
        />
        <PermissionsGuard
          requiredPermissions={[
            Permissions.UPDATE_AUTOMATED_MESSAGE_CONFIGURATION,
            Permissions.CREATE_MESSAGE_TEMPLATE
          ]}
        >
          <Button
            inline
            onClick={() =>
              openModal(AdminModalTypes.ADD_SMS_MESSAGE_TEMPLATE, {
                inTouchpointForm: true,
                onSuccess: (templateId: number) => {
                  formApi.setValue("templateId", templateId?.toString());
                }
              })
            }
            className={styles.MessageCreateButton}
          >
            Create
          </Button>
        </PermissionsGuard>
      </div>
      {values.templateId && (
        <>
          <Heading size="META">Preview Message Template</Heading>

          <div className={styles.Tabs}>
            <Button
              id="touchpoint-mode-sms"
              inline
              className={cx(styles.Tab, {
                [styles.TabActive]: previewMedium === MessageTemplateMedium.sms
              })}
              onClick={() => setPreviewMedium(MessageTemplateMedium.sms)}
            >
              SMS
            </Button>
            <Button
              id="touchpoint-mode-email"
              inline
              className={cx(styles.Tab, {
                [styles.TabActive]: previewMedium === MessageTemplateMedium.email
              })}
              onClick={() => setPreviewMedium(MessageTemplateMedium.email)}
            >
              Email
            </Button>
            <PermissionsGuard
              requiredPermissions={[
                Permissions.UPDATE_AUTOMATED_MESSAGE_CONFIGURATION,
                Permissions.UPDATE_MESSAGE_TEMPLATE
              ]}
            >
              <Button
                inline
                onClick={() =>
                  openModal(AdminModalTypes.EDIT_MESSAGE_TEMPLATE, {
                    automationId,
                    messageTemplateId: parseInt(values.templateId),
                    onSuccess: (templateId: number) => {
                      formApi.setValue("templateId", templateId?.toString());
                    }
                  })
                }
                className={styles.MessageEditButton}
              >
                Edit
              </Button>
            </PermissionsGuard>
          </div>
          <div className={styles.PreviewMessage}>
            {displaySelectedMessageTemplate(
              messageTemplates,
              values.templateId?.toString(),
              previewMedium
            )}
          </div>
        </>
      )}
      {!showMultiTemplate && (
        <PermissionsGuard
          requiredPermissions={[Permissions.UPDATE_AUTOMATED_MESSAGE_CONFIGURATION]}
        >
          <Button
            inline
            onClick={() => setShowMultiTemplate(true)}
            className={styles.MultiTemplateButton}
          >
            + Multi Message Template
          </Button>
        </PermissionsGuard>
      )}

      {showMultiTemplate && (
        <>
          <div className={styles.MessageRow}>
            <SelectInput
              fieldName="multiTemplateId"
              label="Select Multi Message Template (optional)"
              options={[{ label: "None", value: 0 }, ...messageTemplateOptions]}
              placeholder="Select Message Template"
              disabled={formDisabled}
            />
            <PermissionsGuard
              requiredPermissions={[
                Permissions.UPDATE_AUTOMATED_MESSAGE_CONFIGURATION,
                Permissions.CREATE_MESSAGE_TEMPLATE
              ]}
            >
              <Button
                inline
                onClick={() =>
                  openModal(AdminModalTypes.ADD_SMS_MESSAGE_TEMPLATE, {
                    inTouchpointForm: true,
                    onSuccess: (templateId: number) => {
                      formApi.setValue("multiTemplateId", templateId?.toString());
                    }
                  })
                }
                className={styles.MessageCreateButton}
              >
                Create
              </Button>
            </PermissionsGuard>
          </div>
          {values.multiTemplateId && (
            <>
              <Heading size="META">Preview Multi Message Template</Heading>

              <div className={styles.Tabs}>
                <Button
                  id="touchpoint-mode-multi-sms"
                  inline
                  className={cx(styles.Tab, {
                    [styles.TabActive]: multiPreviewMedium === MessageTemplateMedium.sms
                  })}
                  onClick={() => setMultiPreviewMedium(MessageTemplateMedium.sms)}
                >
                  SMS
                </Button>
                <Button
                  id="touchpoint-mode-multi-email"
                  inline
                  className={cx(styles.Tab, {
                    [styles.TabActive]: multiPreviewMedium === MessageTemplateMedium.email
                  })}
                  onClick={() => setMultiPreviewMedium(MessageTemplateMedium.email)}
                >
                  Email
                </Button>
                <PermissionsGuard
                  requiredPermissions={[
                    Permissions.UPDATE_AUTOMATED_MESSAGE_CONFIGURATION,
                    Permissions.UPDATE_MESSAGE_TEMPLATE
                  ]}
                >
                  <Button
                    inline
                    onClick={() =>
                      openModal(AdminModalTypes.EDIT_MESSAGE_TEMPLATE, {
                        automationId,
                        messageTemplateId: parseInt(values.multiTemplateId),
                        onSuccess: (templateId: number) => {
                          formApi.setValue("multiTemplateId", templateId?.toString());
                        }
                      })
                    }
                    className={styles.MessageEditButton}
                  >
                    Edit
                  </Button>
                </PermissionsGuard>
              </div>
              <div className={styles.PreviewMessage}>
                {displaySelectedMessageTemplate(
                  messageTemplates,
                  values.multiTemplateId?.toString(),
                  multiPreviewMedium
                )}
              </div>
            </>
          )}
        </>
      )}
      <Heading size="S">Chat</Heading>
      <SelectInput
        fieldName="chatFlowIds"
        label="Select Chat Flows"
        options={[{ label: "None", value: 0 }, ...availableChatOptions]}
        placeholder="Select Chat Flows"
        disabled={formDisabled}
        isMulti
      />

      {showExclusionHours && (
        <div>
          <PermissionsGuard
            requiredPermissions={[Permissions.UPDATE_AUTOMATED_MESSAGE_CONFIGURATION]}
          >
            <div className={styles.RestrictTouchpoint}>
              <Heading size="S">Restrict touchpoint</Heading>
              <ToggleInput
                fieldName="exclusionHoursActive"
                labelChecked="On"
                labelUnchecked="Off"
                disabled={formDisabled}
              />
            </div>
          </PermissionsGuard>
          {restrictTouchpointActive && userCanEdit && (
            <div>
              <Heading size="META">Don't send message when:</Heading>
              <div className={styles.ExclusionHoursRow}>
                <SelectInput
                  fieldName="exclusionHoursOption"
                  options={exclusionHoursOptions}
                  disabled={formDisabled}
                />
                <TextInput
                  fieldName="exclusionHours"
                  placeholder="0"
                  validate={exclusionValueValidator}
                  disabled={formDisabled}
                />
                <SelectInput
                  fieldName="exclusionHoursUnit"
                  options={exclusionHoursUnitOptions}
                  disabled={formDisabled}
                />
              </div>
            </div>
          )}
          <RestrictTouchpointSummary
            exclusionHours={values.exclusionHours || initialFormState.exclusionHours}
            exclusionHoursOption={
              values.exclusionHoursOption || initialFormState.exclusionHoursOption
            }
            exclusionHoursUnit={values.exclusionHoursUnit || initialFormState.exclusionHoursUnit}
          />
        </div>
      )}
      {showExcludeAppointments && (
        <div>
          <PermissionsGuard
            requiredPermissions={[Permissions.UPDATE_AUTOMATED_MESSAGE_CONFIGURATION]}
          >
            <div className={styles.RestrictTouchpoint}>
              <Heading size="S">Restrict touchpoint</Heading>
              <ToggleInput
                fieldName="settings.exclude.patientHasOtherAppt.active"
                labelChecked="On"
                labelUnchecked="Off"
                disabled={formDisabled}
              />
            </div>
          </PermissionsGuard>
          {restrictTouchpointActive && userCanEdit && (
            <div>
              <SelectInput
                fieldName="excludesApptsWhen"
                label="Don't send message when"
                options={excludeAppointmentsOptions}
                disabled={formDisabled}
              />
              {formState.values.excludesApptsWhen ===
                TouchpointExclusionCases.PATIENT_OTHER_APPT && (
                <div>
                  <Heading size="META" bold component="h4">
                    Time range start
                  </Heading>
                  <div className={styles.SettingRangeTimeRow}>
                    <SelectInput
                      fieldName="settings.exclude.patientHasOtherAppt.rangeStart.type"
                      options={touchpointSettingRangeTimeTypeOptions}
                      disabled={formDisabled}
                    />
                    <TextInput
                      fieldName="settings.exclude.patientHasOtherAppt.rangeStart.value"
                      placeholder="Enter Value"
                      disabled={formDisabled}
                    />
                    <SelectInput
                      fieldName="settings.exclude.patientHasOtherAppt.rangeStart.unit"
                      options={touchpointSettingRangeTimeUnitOptions}
                      placeholder="Select Unit"
                      disabled={formDisabled}
                    />
                  </div>
                  <Heading size="META" bold component="h4">
                    Time range end
                  </Heading>
                  <div className={styles.SettingRangeTimeRow}>
                    <SelectInput
                      fieldName="settings.exclude.patientHasOtherAppt.rangeEnd.type"
                      options={touchpointSettingRangeTimeTypeOptions}
                      disabled={formDisabled}
                    />
                    <TextInput
                      fieldName="settings.exclude.patientHasOtherAppt.rangeEnd.value"
                      placeholder="Enter Value"
                      disabled={formDisabled}
                    />
                    <SelectInput
                      fieldName="settings.exclude.patientHasOtherAppt.rangeEnd.unit"
                      options={touchpointSettingRangeTimeUnitOptions}
                      placeholder="Select Unit"
                      disabled={formDisabled}
                    />
                  </div>
                  {reasons && reasons.length > 0 && (
                    <CheckboxGroup
                      fieldName="reasonIds"
                      label="Other appointment reason(s)"
                      showCheckAll={reasons.length > 2}
                      options={reasons.map((reason) => ({
                        label: generateReasonLabel(reason),
                        value: reason.id?.toString()
                      }))}
                      disabled={formDisabled}
                      searchPlaceholder="Find a reason"
                    />
                  )}
                </div>
              )}
            </div>
          )}

          {restrictTouchpointActive && (
            <RestrictTouchpointSummary
              excludesApptsWhen={
                excludeAppointmentsOptions.find((option) => {
                  return (
                    option.value === formState.values.excludesApptsWhen ||
                    option.value === TouchpointExclusionCases.PATIENT_OTHER_APPT
                  );
                })?.label
              }
              settings={values.settings || initialFormState.settings}
              reasonIds={values.reasonIds || initialFormState.reasonIds}
            />
          )}
        </div>
      )}

      <PermissionsGuard requiredPermissions={[Permissions.UPDATE_AUTOMATED_MESSAGE_CONFIGURATION]}>
        <ButtonRow>
          <Button
            id="createScheduleBasedMessage"
            inline
            danger
            disabled={formDisabled}
            onClick={deleteTouchpoint}
          >
            Delete Touchpoint
          </Button>
          <Button id="createScheduleBasedMessage" type="submit" disabled={formDisabled}>
            Save
          </Button>
        </ButtonRow>
      </PermissionsGuard>
    </>
  );
};

export default EditTouchpointForm;
