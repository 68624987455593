import uniq from "lodash/uniq";
import { InputValue } from "../../types";

export const chatPlaceholders =
  (availablePlaceholders: string[], message?: string) => (value: InputValue) => {
    if (!value) return undefined;
    const regexPattern = /\[([A-Z,a-z,0-9]+)]/gim;

    const regexMatches = value?.match(regexPattern);

    const receivedPlaceholders = regexMatches?.map((item: string) =>
      item.substring(item.indexOf("[") + 1, item.lastIndexOf("]"))
    );

    const invalidPlaceholders = receivedPlaceholders
      ?.map((item: string) => {
        if (!availablePlaceholders.includes(item)) {
          return item;
        }
      })
      .filter((item: string) => !!item);

    const errorMessage = message || `Invalid placeholders: ${uniq(invalidPlaceholders).join(", ")}`;

    if (invalidPlaceholders?.length > 0) return errorMessage;

    return undefined;
  };
