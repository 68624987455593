import React from "react";

import Text from "../../../ui/Text";
import Heading from "../../../ui/Heading";
import Image from "../../../ui/Image";
import { CheckCircle, MikataSignupLogo } from "../../../ui/Icon";

import styles from "./index.module.scss";

const PromotionalCard = () => {
  const promotionalContent: string[] = [
    "Real-time transcription",
    "Customizable notes and letters",
    "Supports multiple languages",
    "Private and secure",
    "Exclusive EMR integrations available"
  ];
  return (
    <div className={styles.PromotionalCard}>
      <div className={styles.PromotionalLogo}>
        <MikataSignupLogo size={150} />
      </div>
      <div className={styles.PromotionalContainer}>
        <Heading size="S" component="h5" className={styles.PromotionalTextMain} darkMode bold>
          Reduce charting time with Mika AI
        </Heading>
        {promotionalContent?.map((item) => {
          return (
            <div key={item} className={styles.PromotionalItem}>
              <div className={styles.IconCenter}>
                <CheckCircle size={24} />
              </div>
              <Text size="M" align="left" darkMode className={styles.PromotionalText}>
                {item}
              </Text>
            </div>
          );
        })}
      </div>
      <div className={styles.PromotionalFooter}>
        <Image src="/img/announcements/heroSignupImage.png" />
      </div>
    </div>
  );
};

export default PromotionalCard;
