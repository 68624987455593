import moment from "moment";

type FormatType =
  | "fullDateTime"
  | "simpleDate"
  | "compactDateTime"
  | "timeOnly"
  | "dateOnly"
  | "dateTime"
  | "default";

const formatDate = (date: string, formatType?: FormatType, timezone?: string): string => {
  const parsedDate = timezone ? moment(date).tz(timezone) : moment(date);

  switch (formatType) {
    case "fullDateTime":
      return parsedDate.format("dddd, MMMM D - h:mmA");
    case "dateTime":
      return parsedDate.format("MMMM D, h:mma");
    case "simpleDate":
      return parsedDate.format("MM.DD.YYYY");
    case "compactDateTime":
      return parsedDate.format("MMM D - h:mma");
    case "timeOnly":
      return parsedDate.format("h:mm A");
    case "dateOnly":
      return parsedDate.format("MM.DD.YYYY");
    case "default":
    default:
      return parsedDate.format("MM.DD.YYYY, h:mm A");
  }
};

export default formatDate;
