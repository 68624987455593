import React from "react";

type PropsType = {
  size?: number;
  color?: string;
};

const Filter = ({ size = 18, color }: PropsType) => {
  return (
    <svg
      width={`${size * (16 / 18)}px`}
      height={`${size}px`}
      viewBox="0 0 16 18"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M3 15.874a4.002 4.002 0 010-7.748V1a1 1 0 112 0v7.126a4.002 4.002 0 010 7.748V17a1 1 0 01-2 0v-1.126zm8-13.748V1a1 1 0 012 0v1.126a4.002 4.002 0 010 7.748V17a1 1 0 01-2 0V9.874a4.002 4.002 0 010-7.748zM4 14a2 2 0 100-4 2 2 0 000 4zm8-6a2 2 0 100-4 2 2 0 000 4z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Filter;
