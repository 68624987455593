import React from "react";

type PropsType = {
  size?: number;
};

const DoubleChevronRight = ({ size = 16 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 10.0858L6.70711 4.79289C6.31658 4.40237 5.68342 4.40237 5.29289 4.79289C4.90237 5.18342 4.90237 5.81658 5.29289 6.20711L11.2929 12.2071C11.6834 12.5976 12.3166 12.5976 12.7071 12.2071L18.7071 6.20711C19.0976 5.81658 19.0976 5.18342 18.7071 4.79289C18.3166 4.40237 17.6834 4.40237 17.2929 4.79289L12 10.0858Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 17.0859L6.70711 11.793C6.31658 11.4025 5.68342 11.4025 5.29289 11.793C4.90237 12.1835 4.90237 12.8167 5.29289 13.2072L11.2929 19.2072C11.6834 19.5978 12.3166 19.5978 12.7071 19.2072L18.7071 13.2072C19.0976 12.8167 19.0976 12.1835 18.7071 11.793C18.3166 11.4025 17.6834 11.4025 17.2929 11.793L12 17.0859Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DoubleChevronRight;
