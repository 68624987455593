/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import { cloneDeep, orderBy } from "lodash";

import Heading from "../../ui/Heading";
import Text from "../../ui/Text";
import Button from "../../ui/Button";
import Search from "../../ui/Search";
import { Filter, Heart, HeartBlank, Pin, PinBlank } from "../../ui/Icon";
import { ResponsiveHide } from "../../ui/Responsive";
import Tags from "../../ui/Tags";
import PageNavigator from "../../ui/TableGrid/PageNavigator";
import FilterTemplates from "./FilterTemplates";

import { getNoteTemplateIcon } from "../../mikata_admin/OrganizationDetails/views/NoteTemplatesTable";
import { NoteTemplate, NoteTemplateUpdateData } from "../../../types";

import {
  ALL,
  NoteTemplateActionTags,
  NoteTagsOptions,
  NoteTemplateTagsConfig,
  NoteTemplateTypeTags,
  NoteTemplateSystemTags
} from "../../../constants";

import styles from "./index.module.scss";

type PropsType = {
  noteTemplates: NoteTemplate[];
  noteTemplateTags: Record<string, string[]> | undefined;
  disableActionIcons?: boolean;
  updateNoteTemplateDetails?: (
    noteTemplate: NoteTemplateUpdateData,
    noteTemplateId: string,
    successMessage: string
  ) => void;
  disabled?: boolean;
  templateNavs: string[];
  templateNavFilter: string;
  setTemplateNavFilter: (templateNavFilter: string) => void;
  footerComponent?: React.ReactNode;
  headerTitle?: string;
  onTemplateSelect: (noteTemplateId: number) => void;
};

const TemplateSearchSelect = ({
  noteTemplates = [],
  noteTemplateTags = {},
  templateNavFilter,
  setTemplateNavFilter,
  disableActionIcons = false,
  headerTitle,
  templateNavs,
  disabled = false,
  footerComponent,
  updateNoteTemplateDetails,
  onTemplateSelect = () => {}
}: PropsType) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [activePage, setActivePage] = useState<number>(1);
  const [activeHoverRow, setActiveHoverRow] = useState<number>();
  const [selectedTypes, setSelectedTypes] = useState<string[]>(["All"]);
  const [selectedOwners, setSelectedOwners] = useState<string[]>(["All"]);
  const [selectedStyling, setSelectedStyling] = useState<string[]>([]);
  const [selectedSpecialty, setSelectedSpecialty] = useState<string[]>([]);
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);

  const templateCategoriesFilter = useMemo(() => {
    return noteTemplateTags?.type;
  }, [noteTemplateTags]);

  const filteredNoteTemplates = searchValue
    ? noteTemplates.filter(
        (template) => !!template.title.toLowerCase()?.includes(searchValue.toLowerCase())
      )
    : noteTemplates;

  const providerNoteTemplates = filteredNoteTemplates.filter((template) => !!template.userId);

  const finalFilteredList = useMemo(() => {
    if (templateNavFilter === "Favourite") {
      return providerNoteTemplates?.filter((item) => item.isFavourited);
    }
    if (templateNavFilter === "My library") {
      return providerNoteTemplates;
    }
    return filteredNoteTemplates;
  }, [templateNavFilter, providerNoteTemplates, filteredNoteTemplates]);

  const templateList = useMemo(() => {
    let tempFilterList = finalFilteredList;

    if (selectedTypes?.length > 0 && selectedTypes[0] !== ALL) {
      tempFilterList = tempFilterList?.filter((template) => {
        return template?.tags?.some((item) => selectedTypes?.includes(item));
      });
    }
    if (selectedOwners?.length > 0 && selectedOwners[0] !== ALL) {
      tempFilterList = tempFilterList?.filter((template) => {
        return template?.tags?.some((item) => selectedOwners?.includes(item));
      });
    }
    if (selectedStyling?.length > 0) {
      tempFilterList = tempFilterList?.filter((template) => {
        return template?.tags?.some((item) => selectedStyling?.includes(item));
      });
    }
    if (selectedSpecialty?.length > 0) {
      tempFilterList = tempFilterList?.filter((template) => {
        return template?.tags?.some((item) => selectedSpecialty?.includes(item));
      });
    }
    tempFilterList = orderBy(tempFilterList, ({ userId }) => userId || "", ["desc"]);
    return tempFilterList;
  }, [finalFilteredList, selectedTypes, selectedOwners, selectedStyling, selectedSpecialty]);

  const onTypesChanges = (types: string[]) => {
    setActivePage(1);
    setSelectedTypes(types);
  };
  const onSpecialtyChange = (specialty: string[]) => {
    setActivePage(1);
    setSelectedSpecialty(specialty);
  };
  const onOwnersChange = (owners: string[]) => {
    setActivePage(1);
    setSelectedOwners(owners);
  };
  const onStylingChange = (styling: string[]) => {
    setActivePage(1);
    setSelectedStyling(styling);
  };

  const toggle = () => {
    setFiltersOpen((filtersOpen) => !filtersOpen);
  };

  const offDropdownClick = (event: MouseEvent | KeyboardEvent) => {
    const dropdownEl = elementRef.current;
    const isOutsideClick = dropdownEl && !dropdownEl.contains(event.target as Node);

    if (isOutsideClick || (event as KeyboardEvent).key === "Escape") {
      toggle();
      document.removeEventListener("mouseup", offDropdownClick, false);
      document.removeEventListener("keyup", offDropdownClick, false);
    }
  };

  useEffect(() => {
    if (filtersOpen) {
      // add when mounted
      document.addEventListener("mouseup", offDropdownClick);
      document.addEventListener("keyup", offDropdownClick);
      return () => {
        document.removeEventListener("mouseup", offDropdownClick, false);
        document.removeEventListener("keyup", offDropdownClick, false);
      };
    }

    document.removeEventListener("mouseup", offDropdownClick, false);
    document.removeEventListener("keyup", offDropdownClick, false);

    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mouseup", offDropdownClick, false);
      document.removeEventListener("keyup", offDropdownClick, false);
    };
  }, [filtersOpen]);

  useEffect(() => {
    // resetting pagination on search
    setActivePage(1);
  }, [searchValue]);

  const activeFilterCount = useMemo(() => {
    let count = 0;
    count += selectedTypes?.filter((item) => item !== ALL)?.length || 0;
    count += selectedOwners?.filter((item) => item !== ALL)?.length || 0;
    count += selectedStyling?.length || 0;
    count += selectedSpecialty?.length || 0;
    return count;
  }, [selectedOwners, selectedSpecialty, selectedStyling, selectedTypes]);

  const getApplicableTags = (tags: string[]) => {
    return tags?.filter(
      (item) =>
        Object.values({
          ...NoteTemplateSystemTags,
          ...NoteTemplateTypeTags,
          ...NoteTemplateActionTags
        }).indexOf(item) === -1
    );
  };

  const updateDefaultStatus = (noteTemplate: NoteTemplate, updatedStatus: boolean) => {
    const noteTemplateId = noteTemplate?.id?.toString();
    const updateData: NoteTemplateUpdateData = {
      title: noteTemplate.title,
      tags: noteTemplate.tags || [],
      description: noteTemplate.description,
      includeAlways: updatedStatus,
      content: noteTemplate.content,
      isFavourited: noteTemplate.isFavourited,
      outputLanguage: noteTemplate.outputLanguage as string
    };
    if (noteTemplateId && updateNoteTemplateDetails) {
      updateNoteTemplateDetails(
        updateData,
        noteTemplateId,
        updatedStatus ? "Added as default template" : "Removed as default template"
      );
    }
  };

  const updateFavouriteStatus = (noteTemplate: NoteTemplate, updatedStatus: boolean) => {
    const noteTemplateId = noteTemplate?.id?.toString();
    const updateData: NoteTemplateUpdateData = {
      title: noteTemplate.title,
      description: noteTemplate.description,
      tags: noteTemplate.tags || [],
      includeAlways: noteTemplate?.includeAlways,
      content: noteTemplate.content,
      isFavourited: updatedStatus,
      outputLanguage: noteTemplate.outputLanguage as string
    };
    if (noteTemplateId && updateNoteTemplateDetails) {
      updateNoteTemplateDetails(
        updateData,
        noteTemplateId,
        updatedStatus ? "Added as favourite template" : "Removed as favourite template"
      );
    }
  };

  return (
    <div>
      {headerTitle && (
        <Heading size="M" className={styles.HeadingClass}>
          {headerTitle}
        </Heading>
      )}
      <div className={styles.FilterSearchContainer}>
        <Search
          inputClassName={styles.SearchContainer}
          classNames={cx(styles.Search, {
            [styles.SearchActiveFilter]: activeFilterCount > 0
          })}
          id="templateSearch"
          searchIconClass={styles.SearchIconClass}
          searchIconSize={20}
          placeholder="Find a template"
          onChange={(searchValue) => {
            setSearchValue(searchValue);
          }}
          onClear={() => {
            setSearchValue(null);
          }}
          onEnter={(searchValue: string) => {
            setSearchValue(searchValue);
          }}
          initialValue=""
        />
        <button
          id="filters-dropdown"
          type="button"
          className={cx(styles.MoreFilters, {
            [styles.MoreFiltersActive]: activeFilterCount > 0,
            [styles.MoreFiltersOpen]: filtersOpen
          })}
          onClick={() => {
            setFiltersOpen(true);
          }}
        >
          <Filter size={20} />
          <Text
            component="span"
            size="M"
            bold={activeFilterCount > 0}
            className={cx(styles.FiltersLabel, {
              [styles.FiltersLabelActive]: activeFilterCount > 0
            })}
          >
            {activeFilterCount > 0 ? `(${activeFilterCount}) Filters` : "Filters"}
          </Text>
        </button>
      </div>
      <ResponsiveHide hideOnMobile>
        <div className={styles.TemplateType}>
          {templateCategoriesFilter?.map((item, index) => {
            return (
              <Button
                key={`templateButton_${index}`}
                inline
                className={
                  selectedTypes?.includes(item)
                    ? styles.TemplateTypeActiveFilter
                    : styles.TemplateTypeFilter
                }
                onClick={() => {
                  let tempTypesList = cloneDeep(selectedTypes);
                  tempTypesList = tempTypesList?.filter((item) => item !== ALL);
                  if (selectedTypes?.includes(item)) {
                    tempTypesList.splice(selectedTypes.indexOf(item), 1);
                    if (tempTypesList?.length === 0) {
                      tempTypesList.push(ALL);
                    }
                    setSelectedTypes(tempTypesList);
                  } else {
                    setSelectedTypes([...tempTypesList, item]);
                  }
                  setActivePage(1);
                }}
                disabled={disabled}
              >
                {getNoteTemplateIcon(item, 24, selectedTypes?.includes(item))}
                <Text
                  size="M"
                  className={
                    selectedTypes?.includes(item)
                      ? styles.TemplateTypeActiveFilterText
                      : styles.TemplateTypeFilterText
                  }
                >
                  {item}
                </Text>
              </Button>
            );
          })}
        </div>
      </ResponsiveHide>
      {activeFilterCount > 0 && templateList?.length > 0 && (
        <Text
          className={styles.TemplateTableMessage}
          bold
          size="M"
        >{`Showing ${templateList?.length} results`}</Text>
      )}
      {activeFilterCount === 0 && (
        <div className={styles.TemplateNavFilter}>
          {templateNavs?.map((item, index) => {
            return (
              <Button
                className={styles.TemplateNavFilterButton}
                inline
                key={`templateButton_${index}`}
                onClick={() => {
                  if (item !== templateNavFilter) {
                    setTemplateNavFilter(item);
                    setActivePage(1);
                  }
                }}
              >
                <Text
                  className={cx({
                    [styles.TemplateNavFilterActive]: templateNavFilter === item
                  })}
                  bold={templateNavFilter === item}
                  size="S"
                >
                  {item}
                </Text>
              </Button>
            );
          })}
        </div>
      )}
      <div
        className={cx(styles.NoteTemplatesContainer, {
          [styles.NoteTemplatesContainerFooter]: Boolean(footerComponent)
        })}
      >
        {templateList.length > 0 ? (
          templateList
            .slice((activePage - 1) * 5, (activePage - 1) * 5 + 5)
            .map((template, index) => (
              <div
                key={template.id}
                onMouseOver={() => setActiveHoverRow(index)}
                onMouseLeave={() => setActiveHoverRow(undefined)}
              >
                <Button
                  inline
                  className={cx(styles.TemplateButton, {
                    [styles.TemplateButtonDisabled]: disabled
                  })}
                  onClick={() => {
                    onTemplateSelect(template.id);
                  }}
                  disabled={disabled}
                >
                  <div className={styles.TemplateDetailsContainer}>
                    {getNoteTemplateIcon(
                      template?.tags?.find((item) =>
                        Object.values(NoteTemplateTypeTags).includes(item)
                      ),
                      24
                    )}
                    <div>
                      <Text size="M" className={styles.TemplateTitle}>
                        {template.title}
                      </Text>
                      <Text size="XS" className={styles.TemplateTitle}>
                        {template.description}
                      </Text>
                      {template?.tags && getApplicableTags(template.tags)?.length > 0 && (
                        <Tags
                          tags={getApplicableTags(template.tags)}
                          configMap={NoteTemplateTagsConfig}
                        />
                      )}
                    </div>
                    {template.userId && (
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      <>
                        {disableActionIcons ? (
                          <div
                            className={cx(styles.ActionIconRow, {
                              [styles.ActionIconRowDisabled]: disableActionIcons
                            })}
                          >
                            {template.includeAlways && <Pin />}
                            {template.isFavourited && <Heart />}
                          </div>
                        ) : (
                          <div
                            className={cx(styles.ActionIconRow, {
                              [styles.ActionIconRowDisabled]: disableActionIcons
                            })}
                          >
                            <Button
                              inline
                              className={styles.ActionIconButton}
                              onClick={(event) => {
                                event.stopPropagation();
                                updateDefaultStatus(template, !template.includeAlways);
                              }}
                            >
                              {template.includeAlways ? (
                                <Pin />
                              ) : (
                                <div
                                  className={cx({ [styles.HideIcon]: activeHoverRow !== index })}
                                >
                                  <PinBlank />
                                </div>
                              )}
                            </Button>
                            <Button
                              inline
                              className={styles.ActionIconButton}
                              onClick={(event) => {
                                event.stopPropagation();
                                updateFavouriteStatus(template, !template.isFavourited);
                              }}
                            >
                              {template.isFavourited ? (
                                <Heart />
                              ) : (
                                <div
                                  className={cx({ [styles.HideIcon]: activeHoverRow !== index })}
                                >
                                  <HeartBlank />
                                </div>
                              )}
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </Button>
              </div>
            ))
        ) : (
          <Text size="S" className={styles.TemplateTableMessage}>
            No note templates found
          </Text>
        )}
      </div>
      {templateList?.length > 0 && (
        <PageNavigator
          className={styles.PaginationContainer}
          currentPage={activePage}
          totalPages={Math.ceil(templateList.length / 5)}
          setCurrentPage={setActivePage}
        />
      )}
      {footerComponent && footerComponent}
      <div
        ref={elementRef}
        className={cx(styles.AdvanceFilter, { [styles.AdvanceFilterOpen]: filtersOpen })}
      >
        <FilterTemplates
          selectedTypes={selectedTypes}
          typesOptions={NoteTagsOptions?.filter((item) =>
            noteTemplateTags?.type?.includes(item.value)
          )}
          onTypesChanges={onTypesChanges}
          selectedOwners={selectedOwners}
          ownerOptions={NoteTagsOptions?.filter((item) =>
            noteTemplateTags?.system?.includes(item.value)
          )}
          onOwnersChange={onOwnersChange}
          selectedSpecialty={selectedSpecialty}
          specialtyOptions={NoteTagsOptions?.filter((item) =>
            noteTemplateTags?.content?.includes(item.value)
          )}
          onSpecialtyChange={onSpecialtyChange}
          selectedStyling={selectedStyling}
          stylingOptions={NoteTagsOptions?.filter((item) =>
            noteTemplateTags?.style?.includes(item.value)
          )}
          onStylingChange={onStylingChange}
          isFilterOpen={filtersOpen}
          closeFilterDropdown={() => setFiltersOpen(false)}
        />
      </div>
    </div>
  );
};

export default TemplateSearchSelect;
