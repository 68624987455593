import { MessageMediums } from "../constants";

// Keep handleMediumSpecificTags aligned with the back-end function of same name
const handleMediumSpecificTags = (text: string, medium: string) => {
  let mediumSpecificTagsCount = 0;
  let mediumsToRemoveCount = 0;

  if (!text) return text;

  let newText = text;

  const mediumSpecificTag = medium === MessageMediums.SMS ? "[SMS" : "[EMAIL";
  const tagLength = mediumSpecificTag.length;

  const mediumTagToRemove = medium === MessageMediums.SMS ? "[EMAIL" : "[SMS";

  while (newText.includes(mediumSpecificTag) && mediumSpecificTagsCount < 100) {
    const startIndex = newText.indexOf(mediumSpecificTag);
    const endIndex = newText.indexOf("]", startIndex);
    if (endIndex === -1) break;
    const tag = newText.substring(startIndex, endIndex + 1);
    const tagWithoutBrackets = tag.substring(tagLength + 1, tag.length - 1);

    newText = newText.replace(tag, tagWithoutBrackets);
    mediumSpecificTagsCount += 1;
  }

  while (newText.includes(mediumTagToRemove) && mediumsToRemoveCount < 100) {
    const startIndex = newText.indexOf(mediumTagToRemove);
    const endIndex = newText.indexOf("]", startIndex);
    if (endIndex === -1) break;
    const tag = newText.substring(startIndex, endIndex + 1);

    newText = newText.replace(tag, "");
    mediumsToRemoveCount += 1;
  }

  return newText;
};

export default handleMediumSpecificTags;
