/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, useState, useEffect, useRef } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import cx from "classnames";

import { ChevronLeft, ChevronRight } from "../Icon";
import Text from "../Text";
import Button from "../Button";
import Status from "../Status";

import "./custom.scss";
import styles from "./index.module.scss";

export type DateRangePresetType = {
  label: string;
  start: Date;
  end: Date;
};

type PropsType = {
  onChange: (dates: [Date | null, Date | null]) => void;
  startDate?: Date;
  endDate?: Date;
  customMinDate?: Date;
  customMaxDate?: Date;
  presets?: Array<DateRangePresetType>;
};

const customHeader = (
  date: Date | undefined,
  decreaseMonth: React.MouseEventHandler<HTMLButtonElement> | undefined,
  increaseMonth: React.MouseEventHandler<HTMLButtonElement> | undefined,
  prevMonthButtonDisabled: boolean | undefined,
  nextMonthButtonDisabled: boolean | undefined
) => {
  return (
    <div className={styles.CalendarHeader}>
      <div className={styles.CalendarHeaderMonth}>
        <Text size="S" component="span" bold className={styles.CalendarDate}>
          {moment(date).format("MMMM YYYY")}
        </Text>
        <Button
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          className={styles.CalendarButton}
        >
          <ChevronLeft />
        </Button>
        <Button
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
          className={styles.CalendarButton}
        >
          <ChevronRight />
        </Button>
      </div>
    </div>
  );
};

const DateRangeSelector = ({
  onChange,
  startDate,
  endDate,
  customMinDate,
  customMaxDate,
  presets
}: PropsType) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((isOpen) => !isOpen);
  };

  const switchTo = (newStart: Date, newEnd: Date) => {
    onChange([newStart, newEnd]);
    setOpen(false);
  };

  const offDropdownClick = (event: MouseEvent | KeyboardEvent) => {
    const dropdownEl = elementRef.current;
    const isOutsideClick = dropdownEl && !dropdownEl.contains(event.target as Node);

    if (isOutsideClick || (event as KeyboardEvent).key === "Escape") {
      toggle();
      document.removeEventListener("mouseup", offDropdownClick, false);
      document.removeEventListener("keyup", offDropdownClick, false);
    }
  };

  useEffect(() => {
    if (open) {
      // add when mounted
      document.addEventListener("mouseup", offDropdownClick);
      document.addEventListener("keyup", offDropdownClick);
      return () => {
        document.removeEventListener("mouseup", offDropdownClick, false);
        document.removeEventListener("keyup", offDropdownClick, false);
      };
    }

    document.removeEventListener("mouseup", offDropdownClick, false);
    document.removeEventListener("keyup", offDropdownClick, false);

    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mouseup", offDropdownClick, false);
      document.removeEventListener("keyup", offDropdownClick, false);
    };
  }, [open]);

  const handleChange = (newDates: [Date | null, Date | null]) => {
    onChange(newDates);
    if (newDates[1]) setOpen(false);
  };

  // eslint-disable-next-line react/display-name
  const SelectorInput = forwardRef<any, any>(
    ({ value, onClick }: { value: string; onClick: () => void }, ref: any) => {
      const start = moment(startDate);
      const end = endDate ? moment(endDate) : null;
      let statusValue = `${start.format("DD-MM-YYYY")}`;
      if (end && !start.isSame(end, "day")) {
        statusValue = `${start.format("DD-MM-YYYY")} to ${end.format("DD-MM-YYYY")}`;
      }
      return (
        <div className={styles.DropdownWrapper}>
          <button
            type="button"
            id="datePicker"
            className={cx(styles.Dropdown, { [styles.DropdownLoading]: false })}
            onClick={() => {
              toggle();
              onClick();
            }}
            ref={ref.current as any}
          >
            <Status
              value={statusValue}
              placeholder={statusValue}
              options={[{ label: statusValue, value: statusValue }]}
              configMap={{ statusValue: "gray" }}
              className={cx(styles.DropdownStatus, { [styles.DropdownStatusActive]: open })}
              defaultColor="gray"
            />
          </button>
        </div>
      );
    }
  );

  const CalendarContainer = ({ children }: { children: any }) => {
    return (
      <div className={styles.Container}>
        {presets && presets.length > 0 && (
          <div className={styles.RangeOptions}>
            {presets.map((preset, index) => (
              <Button
                key={`date-preset-${index}`}
                type="button"
                className={styles.RangeOption}
                onClick={() => {
                  switchTo(preset.start, preset.end);
                }}
              >
                {preset.label}
              </Button>
            ))}
          </div>
        )}
        <div>{children}</div>
      </div>
    );
  };

  return (
    <div className={styles.DateSelector}>
      {/* Please see the following docs for more information of react datepicker this.props
          https://github.com/Hacker0x01/react-datepicker/tree/master/docs
          https://reactdatepicker.com/#example-custom-input */}
      <DatePicker
        selected={startDate}
        onChange={handleChange}
        onClickOutside={() => {
          toggle();
        }}
        className={styles.DatePicker}
        calendarClassName={styles.Calendar}
        minDate={customMinDate}
        maxDate={customMaxDate}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        customInput={<SelectorInput />}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) =>
          customHeader(
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          )
        }
        showPopperArrow={false}
        popperClassName={styles.Popper}
        calendarContainer={CalendarContainer}
        open={open}
      />
    </div>
  );
};

export default DateRangeSelector;
