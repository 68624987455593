import axios from "axios";

import getToken from "../utils/getToken";
import { addNotification } from "./notifications";
import { ROOT_URL, FETCH_LOCATIONS, UPDATE_LOCATION, ADD_LOCATION } from "../constants";
import { Dispatch, ActionStatus, Location, XOR, JsonContent } from "../types";

export type FetchLocationsAction = {
  type: typeof FETCH_LOCATIONS;
  status?: ActionStatus;
  payload?: { data: Array<Location> };
};

export const fetchLocations =
  (organizationId: number) =>
  (dispatch: Dispatch): Promise<void> => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: FETCH_LOCATIONS,
      status: ActionStatus.loading
    });

    return axios
      .get(`${ROOT_URL}/organizations/${organizationId}/locations`, config)
      .then((payload) => {
        return dispatch({
          type: FETCH_LOCATIONS,
          status: ActionStatus.success,
          payload
        });
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to fetch locations",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
        return dispatch({
          type: FETCH_LOCATIONS,
          status: ActionStatus.error
        });
      });
  };

export type UpdateLocationAction = {
  type: typeof UPDATE_LOCATION;
  status?: ActionStatus;
  payload?: Location | { error: boolean };
};

export type UpdateLocationData = {
  active?: boolean;
  displayName?: string;
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
  timezone?: string;
  email?: string;
  telecom?: string;
  telecomExtension?: string;
  note?: string;
  syncLock?: boolean;
  information?: JsonContent;
};

type UpdateLocationResponse = {
  status: number;
  data: XOR<Location, { error: boolean }>;
};

export const updateLocation =
  (
    organizationId: number,
    locationId: number,
    data: UpdateLocationData,
    onSuccess?: () => void,
    onError?: () => void
  ) =>
  (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };
    dispatch({
      type: UPDATE_LOCATION,
      status: ActionStatus.loading
    });

    return axios
      .patch(`${ROOT_URL}/organizations/${organizationId}/locations/${locationId}`, data, config)
      .then((payload: UpdateLocationResponse) => {
        if (!payload || payload.data.error) {
          if (onError) {
            onError();
          }

          dispatch({
            type: UPDATE_LOCATION,
            status: ActionStatus.error
          });

          return dispatch(
            addNotification({
              type: "error",
              title: `Failed to update the location ID: ${locationId}`,
              subtitle: "Please try again",
              autoDismiss: true
            })
          );
        }

        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: `Successfully updated location ID: ${locationId}`,
            autoDismiss: true
          })
        );

        if (onSuccess) {
          onSuccess();
        }

        return fetchLocations(organizationId)(dispatch).then(() => {
          dispatch({
            type: UPDATE_LOCATION,
            status: ActionStatus.success,
            payload: payload.data
          });
        });
      })
      .catch(() => {
        if (onError) {
          onError();
        }

        dispatch(
          addNotification({
            type: "error",
            title: `Failed to update location ID: ${locationId}`,
            subtitle: "Please try again",
            autoDismiss: true
          })
        );

        dispatch({
          type: UPDATE_LOCATION,
          status: ActionStatus.error
        });
      });
  };

export type AddLocationAction = {
  type: typeof ADD_LOCATION;
  status?: ActionStatus;
};

export type AddLocationData = {
  organizationId: number;
  fullName: string;
  displayName: string;
  street: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  timezone: string;
  email: string;
  telecom: string;
  telecomExtension: string;
  note: string;
  information?: JsonContent;
};

type AddLocationResponse = {
  status: number;
  data: Location;
};

export const addLocation = (data: AddLocationData) => async (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };
  dispatch({
    type: ADD_LOCATION,
    status: ActionStatus.loading
  });

  const { organizationId } = data;

  return axios
    .post(`${ROOT_URL}/organizations/${organizationId}/locations`, data, config)
    .then((payload: AddLocationResponse) => {
      if (!payload || payload.status !== 200) {
        dispatch({
          type: ADD_LOCATION,
          status: ActionStatus.error
        });

        return dispatch(
          addNotification({
            type: "error",
            title: `Failed to add a new location`,
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
      }

      dispatch(
        addNotification({
          type: "success",
          title: "Success",
          subtitle: `Successfully added a new Location`,
          autoDismiss: true
        })
      );

      return fetchLocations(organizationId)(dispatch).then(() => {
        dispatch({
          type: ADD_LOCATION,
          status: ActionStatus.success
        });
      });
    })
    .catch(() => {
      dispatch(
        addNotification({
          type: "error",
          title: `Failed to add a new location`,
          subtitle: "Please try again",
          autoDismiss: true
        })
      );

      dispatch({
        type: ADD_LOCATION,
        status: ActionStatus.error
      });
    });
};
