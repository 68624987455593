import React from "react";

import { TextInput, TextAreaInput, RadioGroup } from "../..";

import { isCardLevel } from "../helpers/nodeContext";

import { isRequired } from "../../../../../utils/validators";

import { ChatNodeTypes, ChatNode, CustomOnChangeHandler } from "../../../../../types";

type PropsType = {
  data: ChatNode;
  typePath: Array<ChatNodeTypes>;
  disabled: boolean;
  onChange: CustomOnChangeHandler;
};

export const JSON_CONTENT_INPUT_FORM_FIELD_NAME = "jsonContentInputState";

const headingValueValidator = isRequired("Please enter a heading");
const headingSizeValidator = isRequired("Please choose a size");
const textValueValidator = isRequired("Please enter text");
const importantTitleValidator = isRequired("Please enter a title");
const durationDurationValidator = isRequired("Please enter duration");
const linkLabelValidator = isRequired("Please enter a label");
const linkUrlValidator = isRequired("URL is required");
const infoModalLabelValidator = isRequired("Please enter a label");

const NodeTypeInput = ({ data, typePath, disabled, onChange, ...otherProps }: PropsType) => {
  const cardLevel = isCardLevel(typePath);

  if (data.type === ChatNodeTypes.heading) {
    // Only show large heading option at card level
    const sizeOptions = [
      { label: "Small", value: "s" },
      { label: "Medium", value: "m" },
      { label: "Large", value: "l" }
    ].filter((option) => !(!cardLevel && option.value === "l"));

    return (
      <>
        <TextInput
          fieldName={`${JSON_CONTENT_INPUT_FORM_FIELD_NAME}.${data.id}.value`}
          placeholder="Enter heading"
          disabled={disabled}
          customOnChange={onChange}
          validate={headingValueValidator}
          initialValue={data.properties.value}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...otherProps}
        />
        <RadioGroup
          fieldName={`${JSON_CONTENT_INPUT_FORM_FIELD_NAME}.${data.id}.size`}
          customOnChange={onChange}
          options={sizeOptions}
          validate={headingSizeValidator}
          initialValue={data.properties.size}
        />
      </>
    );
  }

  if (data.type === ChatNodeTypes.important) {
    return (
      <>
        <TextInput
          fieldName={`${JSON_CONTENT_INPUT_FORM_FIELD_NAME}.${data.id}.title`}
          placeholder="Enter title"
          disabled={disabled}
          customOnChange={onChange}
          validate={importantTitleValidator}
          initialValue={data.properties.title}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...otherProps}
        />
        <TextAreaInput
          fieldName={`${JSON_CONTENT_INPUT_FORM_FIELD_NAME}.${data.id}.details`}
          label="Details"
          placeholder="Enter details"
          disabled={disabled}
          customOnChange={onChange}
          initialValue={data.properties.details}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...otherProps}
        />
      </>
    );
  }

  if (data.type === ChatNodeTypes.duration) {
    return (
      <>
        <TextInput
          fieldName={`${JSON_CONTENT_INPUT_FORM_FIELD_NAME}.${data.id}.duration`}
          placeholder="Enter expected duration"
          disabled={disabled}
          customOnChange={onChange}
          validate={durationDurationValidator}
          initialValue={data.properties.duration}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...otherProps}
        />
        <TextInput
          fieldName={`${JSON_CONTENT_INPUT_FORM_FIELD_NAME}.${data.id}.discomfort`}
          label="Discomfort"
          placeholder="Enter discomfort level"
          disabled={disabled}
          customOnChange={onChange}
          initialValue={data.properties.discomfort}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...otherProps}
        />
      </>
    );
  }

  if (data.type === ChatNodeTypes.text) {
    return (
      <>
        <TextAreaInput
          fieldName={`${JSON_CONTENT_INPUT_FORM_FIELD_NAME}.${data.id}.value`}
          placeholder="Add text"
          disabled={disabled}
          customOnChange={onChange}
          validate={textValueValidator}
          initialValue={data.properties.value}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...otherProps}
        />
      </>
    );
  }

  if (data.type === ChatNodeTypes.list) {
    return (
      <>
        <TextInput
          fieldName={`${JSON_CONTENT_INPUT_FORM_FIELD_NAME}.${data.id}.title`}
          placeholder="Add title"
          disabled={disabled}
          customOnChange={onChange}
          initialValue={data.properties.title}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...otherProps}
        />
      </>
    );
  }

  if (data.type === ChatNodeTypes.listItem) {
    return null;
  }

  if (data.type === ChatNodeTypes.link) {
    return (
      <>
        <TextInput
          fieldName={`${JSON_CONTENT_INPUT_FORM_FIELD_NAME}.${data.id}.label`}
          placeholder="Enter label"
          disabled={disabled}
          customOnChange={onChange}
          validate={linkLabelValidator}
          initialValue={data.properties.label}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...otherProps}
        />
        <TextInput
          fieldName={`${JSON_CONTENT_INPUT_FORM_FIELD_NAME}.${data.id}.url`}
          label="URL"
          placeholder="Enter link url"
          disabled={disabled}
          customOnChange={onChange}
          validate={linkUrlValidator}
          initialValue={data.properties.url}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...otherProps}
        />
      </>
    );
  }

  if (data.type === ChatNodeTypes.infoModal) {
    return (
      <>
        <TextInput
          fieldName={`${JSON_CONTENT_INPUT_FORM_FIELD_NAME}.${data.id}.label`}
          placeholder="Enter label"
          disabled={disabled}
          customOnChange={onChange}
          validate={infoModalLabelValidator}
          initialValue={data.properties.label}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...otherProps}
        />
      </>
    );
  }

  return null;
};

export default NodeTypeInput;
