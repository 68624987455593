import React, { useState, useRef, useEffect } from "react";
import cx from "classnames";
import { isEqual } from "lodash";

import Status from "../../Status";
import Form from "../Form";
import FilterSelectForm from "./FilterSelectForm";

import { StatusComponentConfigMap, Option } from "../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  onChange: (values: number[] | string[]) => void;
  selectedValues: number[] | string[];
  options: Array<Option>;
  placeholder?: string;
  id?: string;
  allSelectedLabel?: string;
  fieldName?: string;
};

const defaultFieldName = "filterInput";

const FilterSelectInput = ({
  onChange,
  selectedValues,
  options,
  id,
  placeholder,
  allSelectedLabel = "All selected",
  fieldName = defaultFieldName
}: PropsType) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((isOpen) => !isOpen);
  };

  const selectedOptions = selectedValues
    ? selectedValues.filter((value) => {
        return options.find((option) => {
          return isEqual(option.value, value);
        });
      })
    : [];

  const selectedOptionsLabel = options
    .filter((option) => {
      return (selectedValues as string[]).includes(option.value as string);
    })
    .map((staffUserOption) => staffUserOption.label)
    .join(", ");

  const initialFormState = {
    [fieldName]: selectedValues
  };

  const configMapping: StatusComponentConfigMap = options.reduce((config, option) => {
    // eslint-disable-next-line no-param-reassign
    config[option.value.toString()] = "gray";
    return config;
  }, {} as StatusComponentConfigMap);

  const onSave = (formData: Record<string, unknown>) => {
    const selectedValues = formData[fieldName] as string[] | number[];
    toggle();
    onChange(selectedValues);
  };

  const offDropdownClick = (event: MouseEvent | KeyboardEvent) => {
    const dropdownEl = elementRef.current;
    const isOutsideClick = dropdownEl && !dropdownEl.contains(event.target as Node);

    if (isOutsideClick || (event as KeyboardEvent).key === "Escape") {
      toggle();
      document.removeEventListener("mouseup", offDropdownClick, false);
      document.removeEventListener("keyup", offDropdownClick, false);
    }
  };

  useEffect(() => {
    if (open) {
      // add when mounted
      document.addEventListener("mouseup", offDropdownClick);
      document.addEventListener("keyup", offDropdownClick);
      return () => {
        document.removeEventListener("mouseup", offDropdownClick, false);
        document.removeEventListener("keyup", offDropdownClick, false);
      };
    }

    document.removeEventListener("mouseup", offDropdownClick, false);
    document.removeEventListener("keyup", offDropdownClick, false);

    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mouseup", offDropdownClick, false);
      document.removeEventListener("keyup", offDropdownClick, false);
    };
  }, [open]);

  return (
    <div ref={elementRef} className={cx(styles.DropdownWrapper)}>
      <button
        id={id ? `dropdown-${id}` : undefined}
        type="button"
        className={cx(styles.Dropdown)}
        onClick={() => {
          toggle();
        }}
      >
        <Status
          value={selectedOptionsLabel}
          placeholder={placeholder}
          options={[
            {
              label: allSelectedLabel,
              value: ""
            },
            {
              label: `(${selectedOptions.length}) ${selectedOptionsLabel}`,
              value: selectedOptionsLabel
            }
          ]}
          configMap={configMapping}
          className={cx(styles.DropdownStatus, { [styles.DropdownStatusActive]: open })}
          defaultColor="gray"
        />
      </button>

      <div className={cx(styles.DropdownList, { [styles.DropdownListOpen]: open })}>
        <Form
          key={selectedOptions.join(",")}
          initialValues={initialFormState}
          onSubmit={(formState) => onSave(formState.values)}
        >
          <FilterSelectForm options={options} fieldName={fieldName || ""} />
        </Form>
      </div>
    </div>
  );
};

export default FilterSelectInput;
