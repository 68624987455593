import React, { ReactNode } from "react";
import cx from "classnames";

import { ResponsiveHide } from "../../../../../../../ui/Responsive";

import styles from "./index.module.scss";
import Tooltip from "../../../../../../../ui/Tooltip";

type PropsType = {
  icon: ReactNode;
  toolTipContent: ReactNode;
  toolTipPosition?: "topRight" | "topLeft" | "bottomRight" | "bottomLeft";
  isActive: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

export const ToolButton = ({
  onClick,
  icon,
  toolTipContent,
  toolTipPosition = "bottomLeft",
  isActive,
  disabled = false
}: PropsType) => {
  return (
    <div className={styles.ToolButtonWrapper}>
      <button
        type="button"
        className={cx(styles.ToolButton, {
          [styles.ToolButtonActive]: isActive
        })}
        onClick={onClick}
        disabled={disabled}
      >
        <Tooltip icon={icon} position={toolTipPosition} contentClickable>
          <div>{toolTipContent}</div>
        </Tooltip>
        <ResponsiveHide hideOnDesktop hideOnTablet>
          <div className={cx(styles.ToolTipContent, { [styles.ToolTipContentDisabled]: disabled })}>
            {toolTipContent}
          </div>
        </ResponsiveHide>
      </button>
    </div>
  );
};
