import React from "react";
import { connect } from "react-redux";

import { FloatModal } from "../../../ui/Modal";
import Heading from "../../../ui/Heading";
import { Form, TextInput, ToggleInput } from "../../../ui/Input";
import Button from "../../../ui/Button";

import {
  createReportTemplate as CreateReportTemplateAction,
  closeModal as closeModalAction
} from "../../../../actions";
import { isRequired } from "../../../../utils/validators";

import styles from "./index.module.scss";
import { useQueryString } from "../../../../utils/queryStringHelpers";

import { CreateReportTemplate } from "../../../../types";

type PropsType = {
  createReportTemplate: (data: CreateReportTemplate, chatId: number) => void;
  closeModal: () => void;
};

type FormState = {
  displayName: string;
  autoPush: boolean;
  noteTemplate: boolean;
};

const initialFormState = {
  displayName: "",
  autoPush: true,
  noteTemplate: false
};

const validateName = isRequired("Please enter a display name");

const AddReportTemplate = ({ closeModal, createReportTemplate }: PropsType) => {
  const { parsed } = useQueryString();
  const { chatId } = parsed;

  const save = async (formValues: FormState) => {
    const data: CreateReportTemplate = {
      displayName: formValues.displayName,
      autoPush: formValues.autoPush,
      noteTemplate: formValues.noteTemplate
    };
    createReportTemplate(data, Number(chatId));
    closeModal();
  };

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L" className={styles.Heading}>
        Report Template Details
      </Heading>
      <Form
        onSubmit={(formState) => save(formState.values as FormState)}
        initialValues={initialFormState}
      >
        <TextInput
          fieldName="displayName"
          label="Report Name"
          placeholder="Enter report name"
          validate={validateName}
        />

        <div className={styles.Row}>
          <ToggleInput
            fieldName="autoPush"
            label="Auto Push"
            labelChecked="On"
            labelUnchecked="Off"
          />
          <ToggleInput
            fieldName="noteTemplate"
            label="Note Template"
            labelChecked="On"
            labelUnchecked="Off"
          />
        </div>

        <div className={styles.ButtonGroup}>
          <Button id="createReport" type="submit">
            Create Report
          </Button>
        </div>
      </Form>
    </FloatModal>
  );
};

export default connect(null, {
  createReportTemplate: CreateReportTemplateAction,
  closeModal: closeModalAction
})(AddReportTemplate);
