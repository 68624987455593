import axios from "axios";

import getToken from "../utils/getToken";

import { addNotification } from "./notifications";
import { clearNotes } from "./notes";

import {
  FETCH_APPOINTMENTS,
  FETCH_APPOINTMENTS_DETAIL,
  FETCH_APPOINTMENT_CHAT_LINK,
  CLEAR_APPOINTMENTS_DETAIL,
  UPDATE_APPOINTMENTS,
  SEND_PARKING_LOT_READY,
  CREATE_UNINTEGRATED_APPOINTMENTS,
  UPDATE_UNINTEGRATED_APPOINTMENTS,
  DELETE_UNINTEGRATED_APPOINTMENTS,
  ROOT_URL,
  GET_APPOINTMENT_HISTORY,
  CLEAR_APPOINTMENT_HISTORY
} from "../constants";
import {
  ActionStatus,
  Appointment,
  Dispatch,
  AppointmentCheckedInStatus,
  AppointmentDetail,
  PractitionerTaskStatus,
  AppointmentStatus,
  HistoryEvent
} from "../types";

export type FetchAppointmentFilters = {
  organizationId: number;
  startDay: string;
  endDay: string;
  locationIds: string;
  practitionerIds: string;
  reasonIds: string;
  activeStatuses: string;
  activeCheckinStatuses: string;
  searchValue: string;
  sortBy: string;
  sortByDirection: "asc" | "desc" | "none" | undefined;
  currentPage: number;
  pageSize: number;
  includeFilteredOut: boolean;
};

export type AppointmentFetchData = {
  appointments?: Array<Appointment>;
  allApptsCount: number;
};

export type AppointmentFetchOptions = {
  silent?: boolean;
};

export type FetchAppointmentsAction = {
  type: typeof FETCH_APPOINTMENTS;
  status?: ActionStatus;
  filters: FetchAppointmentFilters;
  payload?: AppointmentFetchData;
};

export const fetchAppointments =
  (filters: FetchAppointmentFilters, options?: AppointmentFetchOptions) =>
  async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };
    const isSilentFetch = options?.silent;

    dispatch({
      type: FETCH_APPOINTMENTS,
      status: isSilentFetch ? ActionStatus.silentLoading : ActionStatus.loading,
      filters
    });

    // Try and load appointments
    try {
      const response = await axios.post(`${ROOT_URL}/appointments`, filters, config);
      return dispatch({
        type: FETCH_APPOINTMENTS,
        status: ActionStatus.success,
        filters,
        payload: response.data
      });
    } catch (error) {
      dispatch(
        addNotification({
          type: "error",
          title: "Error fetching appointments",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: FETCH_APPOINTMENTS,
        status: ActionStatus.error,
        filters
      });
    }
  };

export type AppointmentDetailsFetchOptions = {
  silent?: boolean;
};

export type FetchAppointmentsDetailAction = {
  type: typeof FETCH_APPOINTMENTS_DETAIL;
  status?: ActionStatus;
  payload?: { data: AppointmentDetail };
};

export const fetchAppointmentsDetail =
  (appointmentId: string, options?: AppointmentDetailsFetchOptions) =>
  async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };
    const isSilentFetch = options?.silent;

    dispatch({
      type: FETCH_APPOINTMENTS_DETAIL,
      status: isSilentFetch ? ActionStatus.silentLoading : ActionStatus.loading
    });

    // Try and load appointments
    try {
      const response = await axios.get(`${ROOT_URL}/appointments/${appointmentId}`, config);
      return dispatch({
        type: FETCH_APPOINTMENTS_DETAIL,
        status: ActionStatus.success,
        payload: { data: response.data.appointment }
      });
    } catch (error) {
      dispatch(
        addNotification({
          type: "error",
          title: "Error fetching appointment detail",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: FETCH_APPOINTMENTS_DETAIL,
        status: ActionStatus.error
      });
    }
  };

export type UpdateAppointmentData = {
  status?: AppointmentStatus;
  checkedIn?: AppointmentCheckedInStatus;
  practitionerTaskStatus?: PractitionerTaskStatus;
};

export type ClearAppointmentsDetailAction = {
  type: typeof CLEAR_APPOINTMENTS_DETAIL;
};

export const clearAppointmentsDetail = () => async (dispatch: Dispatch) => {
  dispatch(clearNotes());

  dispatch({
    type: CLEAR_APPOINTMENTS_DETAIL
  });
};

export type UpdateAppointmentsAction = {
  type: typeof UPDATE_APPOINTMENTS;
  status?: ActionStatus;
  payload?: { appointmentId: number; update: UpdateAppointmentData };
};

export const updateAppointment =
  async (data: UpdateAppointmentData, id: number, onSuccess?: () => void) =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: { Authorization: getToken(), "Content-Type": "application/json" }
    };
    const handleError = () => {
      dispatch(
        addNotification({
          type: "error",
          title: "Error updating appointment",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: UPDATE_APPOINTMENTS,
        status: ActionStatus.error
      });
    };

    try {
      dispatch({
        type: UPDATE_APPOINTMENTS,
        status: ActionStatus.loading,
        payload: {
          appointmentId: id,
          update: data
        }
      });
      const response = await axios.patch(`${ROOT_URL}/appointments/${id}`, data, config);
      if (response && !response.data.success) {
        handleError();
      }
      if (onSuccess) {
        onSuccess();
      }

      return dispatch({
        type: UPDATE_APPOINTMENTS,
        status: ActionStatus.success,
        payload: {
          appointmentId: id,
          update: data
        }
      });
    } catch (error) {
      return handleError();
    }
  };

export type SendParkingLotReadyData = {
  id: number;
  organizationId: number;
  patientId: number;
  verifiedPhoneNumber: string;
};

export type SendParkingLotReadyAction = {
  type: typeof SEND_PARKING_LOT_READY;
  status?: ActionStatus;
  payload?: { appointmentId: number };
};

export const sendParkingLotReady =
  async (data: SendParkingLotReadyData) => async (dispatch: Dispatch) => {
    const config = {
      headers: { Authorization: getToken(), "Content-Type": "application/json" }
    };

    const handleError = () => {
      dispatch(
        addNotification({
          type: "error",
          title: "Error sending parking lot ready message",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: SEND_PARKING_LOT_READY,
        status: ActionStatus.error
      });
    };

    try {
      dispatch({
        type: SEND_PARKING_LOT_READY,
        status: ActionStatus.loading,
        payload: {
          appointmentId: data.id
        }
      });
      const response = await axios.post(`${ROOT_URL}/appointments/${data.id}`, data, config);
      if (response && !response.data.success) {
        handleError();
      }
      return dispatch({
        type: SEND_PARKING_LOT_READY,
        status: ActionStatus.success,
        payload: {
          appointmentId: data.id
        }
      });
    } catch (error) {
      return handleError();
    }
  };

export type FetchAppointmentChatLinkData = {
  id: number;
  chatFlowIds: number[];
};

export type FetchAppointmentChatLinkAction = {
  type: typeof FETCH_APPOINTMENT_CHAT_LINK;
  status?: ActionStatus;
  payload?: { conversationsLink: string };
};

export const fetchAppointmentChatLink =
  async (data: FetchAppointmentChatLinkData) => async (dispatch: Dispatch) => {
    const config = {
      headers: { Authorization: getToken(), "Content-Type": "application/json" }
    };

    const handleError = () => {
      dispatch(
        addNotification({
          type: "error",
          title: "Error fetching appointment chat link",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: FETCH_APPOINTMENT_CHAT_LINK,
        status: ActionStatus.error
      });
    };

    try {
      dispatch({
        type: FETCH_APPOINTMENT_CHAT_LINK,
        status: ActionStatus.loading
      });
      const response = await axios.post(
        `${ROOT_URL}/appointments/${data.id}/conversations-link`,
        data,
        config
      );
      if (response && !response.data.success) {
        handleError();
      }
      return dispatch({
        type: FETCH_APPOINTMENT_CHAT_LINK,
        status: ActionStatus.success,
        payload: {
          conversationsLink: response.data.conversationsLink
        }
      });
    } catch (error) {
      return handleError();
    }
  };

export type CreateUnintegratedAppointmentData = {
  patient: {
    firstName: string;
    lastName: string;
    mobilePhone: string;
    email: string;
    phn: string;
  };
  appointment: {
    start: string;
    practitionerId: number | null;
    locationId: number | null;
    reasonId: number | null;
  };
};

export type CreateUnintegratedAppointmentsAction = {
  type: typeof CREATE_UNINTEGRATED_APPOINTMENTS;
  status?: ActionStatus;
};

export const createUnintegratedAppointment =
  async (
    data: CreateUnintegratedAppointmentData,
    options?: { onSuccess?: (appointmentId?: string) => void }
  ) =>
  async (dispatch: Dispatch) => {
    const { onSuccess } = options || {};

    const config = {
      headers: { Authorization: getToken(), "Content-Type": "application/json" }
    };
    const handleError = () => {
      dispatch(
        addNotification({
          type: "error",
          title: "Error creating appointment",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: CREATE_UNINTEGRATED_APPOINTMENTS,
        status: ActionStatus.error
      });
    };

    try {
      dispatch({
        type: CREATE_UNINTEGRATED_APPOINTMENTS,
        status: ActionStatus.loading
      });
      const response = await axios.post(`${ROOT_URL}/appointments/unintegrated`, data, config);
      if (response && !response.data.success) {
        handleError();
      }

      dispatch({
        type: CREATE_UNINTEGRATED_APPOINTMENTS,
        status: ActionStatus.success
      });

      if (onSuccess) {
        const createdAppointmentId = response?.data?.appointment?.id;
        onSuccess(createdAppointmentId);
      }
    } catch (error) {
      return handleError();
    }
  };

export type UpdateUnintegratedAppointmentsData = {
  patient: {
    firstName: string;
    lastName: string;
    mobilePhone: string;
    email: string;
    phn: string;
  };
  appointment: {
    practitionerId: number | null;
    locationId: number | null;
    reasonId: number | null;
  };
};

export type UpdateUnintegratedAppointmentsAction = {
  type: typeof UPDATE_UNINTEGRATED_APPOINTMENTS;
  status?: ActionStatus;
};

export const updateUnintegratedAppointment =
  async (
    appointmentId: number,
    data: UpdateUnintegratedAppointmentsData,
    options?: { onSuccess?: (appointmentId?: string) => void }
  ) =>
  async (dispatch: Dispatch) => {
    const { onSuccess } = options || {};

    const config = {
      headers: { Authorization: getToken(), "Content-Type": "application/json" }
    };
    const handleError = () => {
      dispatch(
        addNotification({
          type: "error",
          title: "Error updating appointment",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: UPDATE_UNINTEGRATED_APPOINTMENTS,
        status: ActionStatus.error
      });
    };

    try {
      dispatch({
        type: UPDATE_UNINTEGRATED_APPOINTMENTS,
        status: ActionStatus.loading
      });
      const response = await axios.patch(
        `${ROOT_URL}/appointments/unintegrated/${appointmentId}`,
        data,
        config
      );
      if (response && !response.data.success) {
        handleError();
      }

      fetchAppointmentsDetail(appointmentId.toString(), {
        silent: false
      })(dispatch);

      if (onSuccess) {
        onSuccess();
      }

      return dispatch({
        type: UPDATE_UNINTEGRATED_APPOINTMENTS,
        status: ActionStatus.success
      });
    } catch (error) {
      return handleError();
    }
  };

export type DeleteUnintegratedAppointmentsAction = {
  type: typeof DELETE_UNINTEGRATED_APPOINTMENTS;
  status: ActionStatus;
  payload: { appointmentId: number };
};

export const deleteUnintegratedAppointment =
  async (appointmentId: number, options?: { onSuccess?: () => void }) =>
  async (dispatch: Dispatch) => {
    const { onSuccess } = options || {};

    const config = {
      headers: { Authorization: getToken(), "Content-Type": "application/json" }
    };
    const handleError = () => {
      dispatch(
        addNotification({
          type: "error",
          title: "Error deleting appointment",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: DELETE_UNINTEGRATED_APPOINTMENTS,
        status: ActionStatus.error,
        payload: { appointmentId }
      });
    };

    try {
      dispatch({
        type: DELETE_UNINTEGRATED_APPOINTMENTS,
        status: ActionStatus.loading,
        payload: { appointmentId }
      });
      const response = await axios.delete(
        `${ROOT_URL}/appointments/unintegrated/${appointmentId}`,
        config
      );
      if (response && !response.data.success) {
        handleError();
      }

      if (onSuccess) {
        onSuccess();
      }

      dispatch(
        addNotification({
          type: "success",
          title: "Success",
          subtitle: "Appointment deleted",
          autoDismiss: true
        })
      );

      return dispatch({
        type: DELETE_UNINTEGRATED_APPOINTMENTS,
        status: ActionStatus.success,
        payload: { appointmentId }
      });
    } catch (error) {
      return handleError();
    }
  };

export type GetAppointmentHistoryAction = {
  type: typeof GET_APPOINTMENT_HISTORY;
  status: ActionStatus;
  payload: HistoryEvent[];
};

export const getAppointmentHistory = (id: number) => async (dispatch: Dispatch) => {
  const config = {
    headers: { Authorization: `${getToken()}`, "Content-Type": "application/json" }
  };

  dispatch({
    type: GET_APPOINTMENT_HISTORY,
    status: ActionStatus.loading
  });

  try {
    const payload = await axios.get(`${ROOT_URL}/appointments/${id}/history`, config);
    return dispatch({
      type: GET_APPOINTMENT_HISTORY,
      status: ActionStatus.success,
      payload: payload?.data?.appointmentHistory
    });
  } catch (error) {
    return dispatch({
      type: GET_APPOINTMENT_HISTORY,
      status: ActionStatus.error
    });
  }
};

export type ClearAppointmentHistoryAction = {
  type: typeof CLEAR_APPOINTMENT_HISTORY;
};

export const clearAppointmentHistory = () => ({
  type: CLEAR_APPOINTMENT_HISTORY
});
