import React, { useState } from "react";
import { useField } from "informed";
import { OnChangeValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import uniqueBy from "lodash/uniqBy";

import BaseInput from "../BaseInput";
import { styles } from "./styles";

import { InputPropsType } from "../../../../types";

export type Option = { label: string; value: string | number };

export type Options = Array<Option>;

type PropsType = InputPropsType & {
  options: Options;
  isMulti?: boolean;
};

const CreatableSelectInput = ({
  fieldName,
  label,
  info,
  infoStatus,
  options,
  placeholder,
  disabled = false,
  isMulti = false,
  warningMessage = "",
  validate,
  showField = true,
  customOnChange,
  ...props
}: PropsType): JSX.Element => {
  const { fieldState, fieldApi } = useField({
    ...props,
    name: fieldName,
    validate
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const value = fieldState.value as any;
  const error = fieldState.error as string | undefined;
  const { setValue, setTouched, validate: validateInput } = fieldApi;

  const [newOptions, setNewOptions] = useState<Option[]>([]);

  const onChangeHandler = (selection: OnChangeValue<Option, boolean>): void => {
    // eslint-disable-next-line no-nested-ternary
    const inputValue = isMulti
      ? ((selection as Option[]) || []).map((option) => (option && option.value) || "")
      : selection
        ? (selection as Option).value
        : "";
    setValue(inputValue);
    setTouched(true);
    validateInput();

    if (customOnChange && selection) {
      customOnChange({ [fieldName]: inputValue });
    }
  };
  const onCreateOptionHandler = (inputValue: string): void => {
    const newValue: OnChangeValue<Option, boolean> = isMulti
      ? [
          ...(value || []).map((optionValue: string) => ({
            label: optionValue,
            value: optionValue
          })),
          { label: inputValue, value: inputValue }
        ]
      : { label: inputValue, value: inputValue };

    setNewOptions([...newOptions, { label: inputValue, value: inputValue }]);
    onChangeHandler(newValue);
  };

  const allOptions = uniqueBy([...options, ...newOptions], "value");
  const selectedValue = isMulti
    ? allOptions.filter((option) => value && value.includes(option.value))
    : allOptions.find((option) => option.value === value);
  return (
    <BaseInput
      fieldName={fieldName}
      error={error}
      label={label}
      showField={showField}
      info={info}
      infoStatus={infoStatus}
      warningMessage={warningMessage}
    >
      <CreatableSelect
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        id={fieldName}
        styles={styles({ hasError: !!error })}
        isClearable
        isMulti={isMulti}
        options={allOptions}
        placeholder={placeholder}
        aria-label={label}
        aria-required="true"
        isDisabled={disabled}
        onChange={onChangeHandler}
        onCreateOption={onCreateOptionHandler}
        value={selectedValue || null}
      />
    </BaseInput>
  );
};

export default CreatableSelectInput;
