import { useEffect, useMemo, useState, useCallback } from "react";

import { LanguageOptions, defaultLanguageOptionValue } from "../constants";

type MicOption = {
  deviceId: string;
  groupId: string;
  kind: string;
  label: string;
};

export const updateInputLanguage = (newInputLanguage: string) => {
  localStorage.setItem("inputLanguage", newInputLanguage);
};

export const getInputLanguage = () => {
  let inputLanguage = localStorage.getItem("inputLanguage") || defaultLanguageOptionValue;
  if (inputLanguage === "en-US") {
    // Originally we used en-US as the default language.
    // Some users may still have it in their localStorage, which we should align with our new options
    inputLanguage = "en-CA";
    updateInputLanguage(inputLanguage);
  }
  return inputLanguage;
};

export const getProviderMicPreference = () => {
  return localStorage.getItem("providerMicPreference") || "";
};

export const updateProviderMicPreference = (newMicPreference: string) => {
  localStorage.setItem("providerMicPreference", newMicPreference);
};
export const getLanguageDisplay = () => {
  const inputLanguage = getInputLanguage();
  const selectedLanguageOption = LanguageOptions.find((option) => option.value === inputLanguage);
  const languageDisplay =
    selectedLanguageOption?.simpleDisplay || selectedLanguageOption?.label || "";

  return languageDisplay;
};

const useAudioSettings = () => {
  const inputLanguage = getInputLanguage();
  const providerMicPreference = getProviderMicPreference();
  const [availableMics, setAvailableMics] = useState<MicOption[]>([]);
  const [findMicsRetryCount, setFindMicsRetryCount] = useState(0);

  const getAvailableMics = useCallback(async () => {
    if (findMicsRetryCount >= 10) {
      return;
    }
    // Check if we have user permission for media devices, prompt user for permission if needed
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices.filter(
        (device) => device.kind === "audioinput" && device.label !== ""
      );
      if (!audioDevices.length) {
        setFindMicsRetryCount(findMicsRetryCount + 1);
        setTimeout(getAvailableMics, 1000);
      } else {
        setFindMicsRetryCount(0);
        setAvailableMics(audioDevices);
      }
    } catch (error) {
      console.error("Error accessing media devices.", error);
      setFindMicsRetryCount(findMicsRetryCount + 1);
      setTimeout(getAvailableMics, 1000);
    }
  }, []);

  // Find existing settings
  useEffect(() => {
    getAvailableMics();
  }, []);

  const { options: micSelectOptions, initialValue: initialMicPreferenceSelectValue } =
    useMemo(() => {
      const options = availableMics
        ?.filter((item) => item.deviceId !== "default")
        .map((micOption) => ({
          label: micOption.label,
          value: micOption.deviceId
        }));
      let initialValue = options?.[0]?.value;
      if (providerMicPreference) {
        initialValue = providerMicPreference;
      } else if (initialValue) {
        updateProviderMicPreference(initialValue);
      }

      return { options, initialValue };
    }, [availableMics]);

  return {
    inputLanguage,
    initialMicPreferenceSelectValue,
    micSelectOptions,
    getAvailableMics
  };
};

export default useAudioSettings;
