import React, { useState, useMemo, useEffect } from "react";

type PropsType = {
  children: React.ReactNode;
};

export const mobileViewportMaxWidth = 768;
export const tabletViewportMaxWidth = 1024;

export enum LayoutViewMode {
  MOBILE = "mobile",
  TABLET = "tablet",
  DESKTOP = "desktop"
}

type LayoutContextType = {
  viewMode: LayoutViewMode;
};

const initialLayoutContext: LayoutContextType = { viewMode: LayoutViewMode.DESKTOP };

export const LayoutContext = React.createContext<LayoutContextType>(initialLayoutContext);

const getViewMode = (widthPx: number): LayoutViewMode => {
  if (widthPx <= mobileViewportMaxWidth) {
    return LayoutViewMode.MOBILE;
  }
  if (widthPx > mobileViewportMaxWidth && widthPx <= tabletViewportMaxWidth) {
    return LayoutViewMode.TABLET;
  }

  return LayoutViewMode.DESKTOP;
};

const LayoutProvider = ({ children }: PropsType) => {
  const [viewMode, setViewMode] = useState<LayoutViewMode>(getViewMode(visualViewport?.width || 0));

  useEffect(() => {
    const resizeEventListener = (event: Event) => {
      const currentViewportWidthPx = (event.target as VisualViewport).width;
      const currentViewMode = getViewMode(currentViewportWidthPx);

      setViewMode(currentViewMode);
    };

    if (visualViewport) {
      visualViewport.addEventListener("resize", resizeEventListener);
    }

    return () => {
      if (visualViewport) {
        visualViewport.removeEventListener("resize", resizeEventListener);
      }
    };
  }, []);

  const value = useMemo(() => {
    return {
      viewMode
    };
  }, [viewMode]);

  return (
    <LayoutContext.Provider value={value as LayoutContextType}>{children}</LayoutContext.Provider>
  );
};

export default LayoutProvider;
