import { UserTypeConstants } from "../constants";

export const userTypeDisplayMap = (type: string): string => {
  switch (type) {
    case UserTypeConstants.STAFF:
      return "Staff";
    case UserTypeConstants.PRACTITIONER:
      return "Provider";
    default:
      return "";
  }
};
