import { AppointmentCheckedInStatus } from "./types";

export * from "./typedConstants";
// // Status definitions:

// https://docs.google.com/spreadsheets/d/13EuqrCl3Ox4hWiBB6ny9LqF6aYeKMUNgyxObjLuIx1U/edit?folder=1dHH3EkGRcObwp_NnCgcmOexWcZaAKx4Q#gid=0
export const ROOT_URL = process.env.REACT_APP_API_URL;
export const MAIN_URL = process.env.REACT_APP_CLINIC_URL || "https://app.mikatahealth.com";
export const PATIENT_APP_URL = process.env.REACT_APP_ALLY_CLIENT_URL || "https://mika.care";

// Organizations
export const FETCH_DATA_FOR_MIKATA_ADMIN = "FETCH_DATA_FOR_MIKATA_ADMIN";
export const FETCH_DATA_FOR_ORGANIZATION = "FETCH_DATA_FOR_ORGANIZATION";
export const CLEAR_ORGANIZATION = "CLEAR_ORGANIZATION";
export const FETCH_CAMPAIGNS = "FETCH_CAMPAIGNS";
export const FETCH_CAMPAIGN = "FETCH_CAMPAIGN";
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
export const SEND_CAMPAIGN = "SEND_CAMPAIGN";
export const CREATE_ORGANIZATION = "CREATE_ORGANIZATION";
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
export const FETCH_UPLOAD_RECORD = "FETCH_UPLOAD_RECORD";
export const FETCH_ORGANIZATION_SETTINGS = "FETCH_ORGANIZATION_SETTINGS";
export const UPDATE_ORGANIZATION_SETTINGS = "UPDATE_ORGANIZATION_SETTINGS";
export const FETCH_ORGANIZATION_DETAILS = "FETCH_ORGANIZATION_DETAILS";
export const UPDATE_ORGANIZATION_DETAILS = "UPDATE_ORGANIZATION_DETAILS";
export const UPLOAD_ORGANIZATION_LOGO = "UPLOAD_ORGANIZATION_LOGO";
export const DELETE_ORGANIZATION = "DELETE_ORGANIZATION";
export const FETCH_ORGANIZATION_LOGO_URL = "FETCH_ORGANIZATION_LOGO_URL";
export const FETCH_SIGNED_URL = "FETCH_SIGNED_URL";
export const ADD_ORGANIZATION = "ADD_ORGANIZATION";
export const ADD_SELF_SERVE_ORGANIZATION = "ADD_SELF_SERVE_ORGANIZATION";
export const VERIFY_INVITE_CODE = "VERIFY_INVITE_CODE";

// Billing
export const FETCH_BILLING_CONFIG = "FETCH_BILLING_CONFIG";
export const FETCH_ALL_BILLING_CUSTOMERS = "FETCH_ALL_BILLING_CUSTOMERS";
export const FETCH_ORGANIZATION_SUBSCRIPTIONS = "FETCH_ORGANIZATION_SUBSCRIPTIONS";
export const CREATE_CHARGEOVER_SUBSCRIPTION = "CREATE_CHARGEOVER_SUBSCRIPTION";
export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const FETCH_PRODUCT_LIST = "FETCH_PRODUCT_LIST";

// Appointments
export const FETCH_APPOINTMENTS = "FETCH_APPOINTMENTS";
export const TRIGGER_FETCH_APPOINTMENTS = "TRIGGER_FETCH_APPOINTMENTS";
export const FETCH_APPOINTMENTS_DETAIL = "FETCH_APPOINTMENTS_DETAIL";
export const FETCH_APPOINTMENT_CHAT_LINK = "FETCH_APPOINTMENT_CHAT_LINK";
export const CLEAR_APPOINTMENTS_DETAIL = "CLEAR_APPOINTMENTS_DETAIL";
export const UPDATE_APPOINTMENTS = "UPDATE_APPOINTMENTS";
export const SEND_PARKING_LOT_READY = "SEND_PARKING_LOT_READY";
export const CREATE_UNINTEGRATED_APPOINTMENTS = "CREATE_UNINTEGRATED_APPOINTMENTS";
export const UPDATE_UNINTEGRATED_APPOINTMENTS = "UPDATE_UNINTEGRATED_APPOINTMENTS";
export const DELETE_UNINTEGRATED_APPOINTMENTS = "DELETE_UNINTEGRATED_APPOINTMENTS";
export const GET_APPOINTMENT_HISTORY = "GET_APPOINTMENT_HISTORY";
export const CLEAR_APPOINTMENT_HISTORY = "CLEAR_APPOINTMENT_HISTORY";
export const UPDATE_PATIENT_CONTACT_DETAILS = "UPDATE_PATIENT_CONTACT_DETAILS";

// Appointment Requests
export const FETCH_APPOINTMENT_REQUESTS_DETAIL = "FETCH_APPOINTMENT_REQUESTS_DETAIL";
export const CLEAR_APPOINTMENT_REQUESTS_DETAIL = "CLEAR_APPOINTMENT_REQUESTS_DETAIL";
export const APPOINTMENT_REQUESTS = "APPOINTMENT_REQUESTS";
export const UPDATE_APPOINTMENT_REQUEST = "UPDATE_APPOINTMENT_REQUEST";
export const FETCH_PATIENTS = "FETCH_PATIENTS";
export const BOOK_APPOINTMENT = "BOOK_APPOINTMENT";
export const FETCH_BOOKING_CONFIGURATION = "FETCH_BOOKING_CONFIGURATION";
export const GET_APPOINTMENT_REQUEST_HISTORY = "GET_APPOINTMENT_REQUEST_HISTORY";
export const CLEAR_APPOINTMENT_REQUEST_HISTORY = "CLEAR_APPOINTMENT_REQUEST_HISTORY";

// Appointment Notes
export const FETCH_NOTES = "FETCH_NOTES";
export const CREATE_NOTE = "CREATE_NOTE";
export const UPDATE_NOTE = "UPDATE_NOTE";
export const SYNC_NOTE = "SYNC_NOTE";
export const CLEAR_NOTES = "CLEAR_NOTES";
export const DELETE_NOTE = "DELETE_NOTE";

// Scribe Note Templates
export const CLONE_NOTE_TEMPLATE = "CLONE_NOTE_TEMPLATE";
export const FETCH_NOTE_TEMPLATES = "FETCH_NOTE_TEMPLATES";
export const FETCH_NOTE_TEMPLATE_SUGGESTIONS = "FETCH_NOTE_TEMPLATE_SUGGESTIONS";
export const GET_NOTE_TEMPLATE = "GET_NOTE_TEMPLATE";
export const UPDATE_NOTE_TEMPLATE = "UPDATE_NOTE_TEMPLATE";
export const DELETE_NOTE_TEMPLATE = "DELETE_NOTE_TEMPLATE";
export const SET_DEFAULT_NOTE_TEMPLATES = "SET_DEFAULT_NOTE_TEMPLATES";

// Documents
export const DOCUMENTS_FETCH = "DOCUMENTS_FETCH";
export const DOCUMENTS_FETCH_DOCUMENT_SIGNED_URL = "DOCUMENTS_FETCH_DOCUMENT_SIGNED_URL";
export const DOCUMENTS_CREATE = "DOCUMENTS_CREATE";

// Message Templates Action Types
export const FETCH_MESSAGE_TEMPLATE_DETAIL = "FETCH_MESSAGE_TEMPLATE_DETAIL";
export const FETCH_MESSAGE_TEMPLATES = "FETCH_MESSAGE_TEMPLATES";
export const UPDATE_MESSAGE_TEMPLATE = "UPDATE_MESSAGE_TEMPLATE";
export const ADD_MESSAGE_TEMPLATE = "ADD_MESSAGE_TEMPLATE";
export const DELETE_MESSAGE_TEMPLATE = "DELETE_MESSAGE_TEMPLATE";
export const SEND_MESSAGE_TEMPLATE = "SEND_MESSAGE_TEMPLATE";

// Messages Action Types
export const SEND_DIRECT_MESSAGE = "SEND_DIRECT_MESSAGE";

// Automated Messages Action Types
export const FETCH_AUTOMATED_MESSAGES = "FETCH_AUTOMATED_MESSAGES";
export const FETCH_AUTOMATION = "FETCH_AUTOMATION";
export const UPDATE_AUTOMATION = "UPDATE_AUTOMATION";
export const ADD_AUTOMATION = "ADD_AUTOMATION";
export const DELETE_AUTOMATION = "DELETE_AUTOMATION";
export const GET_AUTOMATION_JOURNEY = "GET_AUTOMATION_JOURNEY";
export const CLEAR_AUTOMATION_JOURNEY = "CLEAR_AUTOMATION_JOURNEY";

// Direct Messages Action Types
export const FETCH_DIRECT_MESSAGE_DETAILS = "FETCH_DIRECT_MESSAGE_DETAILS";
export const DELETE_SECURE_DIRECT_MESSAGE_ITEM = "DELETE_SECURE_DIRECT_MESSAGE_ITEM";

// Automated Message Configuration Action Types
export const ADD_MESSAGE_CONFIGURATION = "ADD_MESSAGE_CONFIGURATION";
export const UPDATE_MESSAGE_CONFIGURATION = "UPDATE_MESSAGE_CONFIGURATION";
export const DELETE_MESSAGE_CONFIGURATION = "DELETE_MESSAGE_CONFIGURATION";

// Location Action Types
export const FETCH_LOCATIONS = "FETCH_LOCATIONS";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const ADD_LOCATION = "ADD_LOCATION";

// Staff Users Action Types
export const ADD_STAFF_USER = "ADD_STAFF_USER";

// Notification Action Types
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

// Modal Action Types
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

// Reason Action Types
export const UPDATE_REASONS = "UPDATE_REASONS";
export const FETCH_REASONS = "FETCH_REASONS";
export const ADD_REASONS = "ADD_REASONS";

// Chat Action Types
export const ADD_CHAT_FLOW = "ADD_CHAT_FLOW";
export const ADD_CHAT_NODE = "ADD_CHAT_NODE";
export const CLEAR_CHAT_FLOW_DETAILS = "CLEAR_CHAT_FLOW_DETAILS";
export const CLONE_CHAT_FLOW = "CLONE_CHAT_FLOW";
export const FETCH_CHATS = "FETCH_CHATS";
export const GET_CHAT_FLOW_DETAILS = "GET_CHAT_FLOW_DETAILS";
export const GET_AVAILABLE_CHAT_FLOWS = "GET_AVAILABLE_CHAT_FLOWS";
export const PUBLISH_CHAT_FLOW = "PUBLISH_CHAT_FLOW";
export const UPDATE_CHATS = "UPDATE_CHATS";
export const UPDATE_CHAT_FLOWS_NODE = "UPDATE_CHAT_FLOWS_NODE";
export const DELETE_CHAT_NODE = "DELETE_CHAT_NODE";

// Practitioner Action Types
export const FETCH_PRACTITIONERS = "FETCH_PRACTITIONERS";

// User Action Types
export const UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS";
export const FETCH_USER = "FETCH_USER";
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_ROLES = "FETCH_ROLES";
export const CREATE_USER = "CREATE_USER";

// Instruction Action Types
export const UPDATE_INSTRUCTIONS = "UPDATE_INSTRUCTIONS";
export const FETCH_INSTRUCTIONS = "FETCH_INSTRUCTIONS";
export const FETCH_INSTRUCTION_DETAILS = "FETCH_INSTRUCTION_DETAILS";
export const ADD_INSTRUCTION = "ADD_INSTRUCTION";
export const DELETE_INSTRUCTION = "DELETE_INSTRUCTION";

// Integration Action Types
export const GET_INTEGRATION_SUMMARY = "GET_INTEGRATION_SUMMARY";

// Report Template Action Types
export const FETCH_REPORT_TEMPLATES = "FETCH_REPORT_TEMPLATES";
export const GET_REPORT_TEMPLATE_DETAILS = "GET_REPORT_TEMPLATE_DETAILS";
export const UPDATE_REPORT_TEMPLATES = "UPDATE_REPORT_TEMPLATES";
export const CLEAR_REPORT_TEMPLATE_DETAILS = "CLEAR_REPORT_TEMPLATE_DETAILS";
export const UPDATE_REPORT_TEMPLATE_CARD = "UPDATE_REPORT_TEMPLATE_CARD";
export const CREATE_REPORT_TEMPLATE_CARD = "CREATE_REPORT_TEMPLATE_CARD";
export const CREATE_REPORT_TEMPLATE = "CREATE_REPORT_TEMPLATE";
export const REMOVE_REPORT_TEMPLATE_CARD = "REMOVE_REPORT_TEMPLATE_CARD";

// Analytics Action Types
export const ANALYTICS_BOOKING_MESSAGES = "ANALYTICS_BOOKING_MESSAGES";
export const ANALYTICS_MULTI_BOOKING_MESSAGES = "ANALYTICS_MULTI_BOOKING_MESSAGES";
export const ANALYTICS_CANCEL_MESSAGES = "ANALYTICS_CANCEL_MESSAGES";
export const ANALYTICS_MULTI_CANCEL_MESSAGES = "ANALYTICS_MULTI_CANCEL_MESSAGES";
export const ANALYTICS_PATIENT_CONFIRMATION_MESSAGES = "ANALYTICS_PATIENT_CONFIRMATION_MESSAGES";
export const ANALYTICS_PATIENT_RESCHEDULE_MESSAGES = "ANALYTICS_PATIENT_RESCHEDULE_MESSAGES";
export const ANALYTICS_REQUESTS_REASONS_COUNT = "ANALYTICS_REQUESTS_REASONS_COUNT";
export const ANALYTICS_REQUESTS_REASONS_COUNT_FILTER = "ANALYTICS_REQUESTS_REASONS_COUNT_FILTER";
export const ANALYTICS_REQUESTS_COUNT = "ANALYTICS_REQUESTS_COUNT";
export const ANALYTICS_REQUISITIONS_COUNT = "ANALYTICS_REQUISITIONS_COUNT";
export const ANALYTICS_AVERAGE_PROCESSING_TIME = "ANALYTICS_AVERAGE_PROCESSING_TIME";
export const ANALYTICS_QUEUE_TIME = "ANALYTICS_QUEUE_TIME";
export const ANALYTICS_PROCESSING_TIME_BY_REASON = "ANALYTICS_PROCESSING_TIME_BY_REASON";
export const ANALYTICS_SET_PROCESSING_START_TIME = "ANALYTICS_SET_PROCESSING_START_TIME";
export const ANALYTICS_CHAT_COMPLETION_TIME = "ANALYTICS_CHAT_COMPLETION_TIME";
export const ANALYTICS_CHAT_DROPOFF = "ANALYTICS_CHAT_DROPOFF";

// Services Action Types
export const SERVICES_FETCH = "SERVICES_FETCH";
export const SERVICES_ADD = "SERVICES_ADD";
export const SERVICES_FIND_NUMBERS = "SERVICES_FIND_NUMBERS";
export const SERVICES_CLEAR_NUMBER_FIND = "SERVICES_CLEAR_NUMBER_FIND";
export const SERVICES_BUY_NUMBERS = "SERVICES_BUY_NUMBERS";
export const SERVICES_DELETE = "SERVICES_DELETE";
export const SERVICES_UPDATE = "SERVICES_UPDATE";

// Feedback Action Types
export const ADD_FEEDBACK = "ADD_FEEDBACK";
export const SET_FEEDBACK_MODE = "SET_FEEDBACK_MODE";
export const QUEUE_NOTE_FOR_FEEDBACK = "QUEUE_NOTE_FOR_FEEDBACK";
export const CLEAR_NOTE_FROM_QUEUE = "CLEAR_NOTE_FROM_QUEUE";

export const RESCHEDULE = "RESCHEDULE";
export const CONFIRMED = "CONFIRMED";
export const UNCLEAR = "UNCLEAR";
export const NEED_CONFIRM = "NEED_CONFIRM";
export const NONE = "NONE";
export const ALL = "All";

// Chat Flow Output Types
export const CREATE_CHAT_FLOW_OUTPUT = "CREATE_CHAT_FLOW_OUTPUT";
export const UPDATE_CHAT_FLOW_OUTPUT = "UPDATE_CHAT_FLOW_OUTPUT";

// Tasks Action Types
export const TASKS_FETCH = "TASKS_FETCH";
export const TASKS_FETCH_COUNT = "TASKS_FETCH_COUNT";
export const TASK_GET = "TASK_GET";
export const TASKS_ASSIGN_TO = "TASKS_ASSIGN_TO";

export const Environments = Object.freeze({
  PRODUCTION: "production",
  STAGING: "staging",
  DEVELOPMENT: "development",
  TEST: "test"
});

export const DashboardPages = Object.freeze({
  APPOINTMENTS: "Appointments",
  APPOINTMENT_REQUESTS: "Appointment Requests",
  CAMPAIGNS: "Campaigns"
});

export const ReportCardTypes = Object.freeze({
  TITLE: "title",
  HEADER: "header",
  SUBHEADER_1: "subHeader1", // underline large
  SUBHEADER_2: "subHeader2", // underline normal
  SUBHEADER_3: "subHeader3", // bold normal
  TEXT: "text",
  TEXT_2: "text2", // half padding
  TEXT_3: "text3", // no padding
  TRANSCRIPT: "transcript",
  TABLE: "table",
  IMAGESELECT: "imageSelect"
});

export const AppointmentStates = Object.freeze({
  RESCHEDULE: { name: "RESCHEDULE", label: "Reschedule" },
  CONFIRMED: { name: "CONFIRMED", label: "Confirmed" },
  UNCLEAR: { name: "UNCLEAR", label: "Unclear" },
  NEED_CONFIRM: { name: "NEED_CONFIRM", label: "No Contact Info" },
  NEEDS_CALL: { name: "NEEDS_CALL", label: "Unsubscribed" },
  NONE: { name: "NONE", label: "No response" },
  PENDING: { name: "PENDING", label: "Pending" },
  CONTACTED: { name: "CONTACTED", label: "Staff Contacted" },
  COMPLETE: { name: "COMPLETE", label: "Complete" },
  ON_HOLD: { name: "ON_HOLD", label: "On hold" }
});

export const AppointmentCheckedInStatuses = Object.freeze({
  NONE: { name: AppointmentCheckedInStatus.NONE, label: "None" },
  CHECKED_IN: { name: AppointmentCheckedInStatus.CHECKED_IN, label: "Waiting" },
  NOTICE_SENT: { name: AppointmentCheckedInStatus.NOTICE_SENT, label: "No response" },
  NO_CONTACT_INFO: { name: AppointmentCheckedInStatus.NO_CONTACT_INFO, label: "No Contact Info" },
  STAFF_CONTACTED: { name: AppointmentCheckedInStatus.STAFF_CONTACTED, label: "Staff contacted" },
  UNCLEAR: { name: AppointmentCheckedInStatus.UNCLEAR, label: "Unclear" }
});

export const ConversationStatuses = Object.freeze({
  NOT_APPLICABLE: "Not applicable",
  NOT_STARTED: "Not started",
  NO_CONTACT: "No contact",
  NEEDS_HELP: "Needs help",
  WRONG_PROCEDURE: "Wrong Procedure",
  IN_PROGRESS: "In progress",
  ENTERED_GUIDE: "Entered Guide",
  COMPLETED_GUIDE: "Completed Guide",
  ABANDONED: "Abandoned",
  COMPLETE: "Complete",
  FLAGGED: "Flagged",
  LOCKED_OUT: "Locked Out"
});

export const AppointmentPrepStatuses = Object.freeze({
  NOT_OPENED: "not opened",
  ENTERED_CHAT: "entered chat",
  OPENED: "opened",
  CONFUSED: "confused",
  ENTERED_GUIDE: "entered guide",
  SEEN_GUIDE: "seen guide",
  NOT_APPLICABLE: "not applicable"
});

export const FETCH_PROCEDURE_INFO = "FETCH_PROCEDURE_INFO";

export const UploadRecordStatuses = Object.freeze({
  PROCESSING: "processing",
  SUCCESS: "success",
  READY_FOR_REMINDERS: "ready for reminders",
  VERIFIED: "verified",
  VERIFYING: "verifying",
  PROBLEM_SENDING: "error problem sending reminders",
  NO_APPTS_FOUND: "error no appts found",
  BAD_FILE: "bad file",
  DUPLICATE: "duplicate"
});

export const AppointmentRequestModes = Object.freeze({
  APPOINTMENT_BOOKING: "Appointment Booking",
  VIEW_BOOKING: "View Booking",
  SIMPLE_REQUEST: "Simple Request"
});

export const AppointmentRequestFilters = Object.freeze({
  REQUESTS: "Requests",
  ARCHIVE: "Archive"
});

export const AppointmentRequestColumnSort = Object.freeze({
  CREATED_AT: "created_at",
  PATIENT_LAST_NAME: "patientLastName",
  PATIENT_NUMBER: "patientNumber",
  PATIENT_HEALTHCARE_NUMBER: "patientHealthcareNumber",
  MOBILE: "mobileFirst",
  REASON: "reasons",
  LOCATION_PREFERENCE: "locationPreference",
  DATETIME_PREFERENCE: "dateTimePreference",
  BOOKING_STATUS: "status",
  REQUISITION: "hasRequisition",
  ASSIGNED_TO_USER_ID: "assignedStaffUserId"
});

export const BookingModes = Object.freeze({
  REQUEST: "request",
  AVAILABLE_TIMES: "availableTimes",
  AUTO_BOOK: "autoBook"
});

export const DirectMessageModes = Object.freeze({
  SECURE: "secure",
  BASIC: "basic",
  APPOINTMENT_REQUEST: "appointmentRequest"
});

export const StatusFilterMapping = Object.freeze({
  noReminderApptsCount: [AppointmentStates.NEED_CONFIRM.name, AppointmentStates.NEEDS_CALL.name],
  confirmedApptsCount: [AppointmentStates.CONFIRMED.name, AppointmentStates.CONTACTED.name],
  rescheduleApptsCount: [AppointmentStates.RESCHEDULE.name],
  noResponseApptsCount: [
    AppointmentStates.NONE.name,
    AppointmentStates.UNCLEAR.name,
    AppointmentStates.PENDING.name
  ]
});

export const EMRTypeConstants = Object.freeze({
  WOLF: {
    name: "Wolf",
    integrated: false
  },
  WOLF_HYBRID: {
    name: "Wolf (Hybrid)",
    integrated: false
  },
  PS_SUITE: {
    name: "PS Suite",
    integrated: false
  },
  ACCURO: {
    name: "Accuro",
    integrated: false
  },
  ACCURO_INTEGRATED_REASON: {
    name: "Accuro Integrated - Reason",
    integrated: true
  },
  ACCURO_INTEGRATED_REASON_HYBRID: {
    name: "Accuro Integrated - Reason (Hybrid)",
    integrated: true
  },
  ACCURO_INTEGRATED_TYPE: {
    name: "Accuro Integrated - Type",
    integrated: true
  },
  ACCURO_INTEGRATED_TYPE_HYBRID: {
    name: "Accuro Integrated - Type (Hybrid)",
    integrated: true
  },
  MED_ACCESS: {
    name: "Med Access",
    integrated: false
  },
  MED_ACCESS_UPLOAD_HYBRID: {
    name: "Med Access Upload (Hybrid)",
    integrated: false
  },
  MIKATA_UNINTEGRATED: {
    name: "Mikata Unintegrated",
    integrated: false,
    canUpload: false
  },
  INTELERAD: {
    name: "Intelerad",
    integrated: true
  },
  INTELERAD_NON_INTEGRATED: {
    name: "Intelerad Non-Integrated",
    integrated: false
  },
  OSCAR: {
    name: "Oscar", // Oscar - Reason but it's not called that in the back end
    integrated: false
  },
  OSCAR_TYPE: {
    name: "Oscar - Type",
    integrated: false
  },
  JUNO: {
    name: "Juno", // Juno - Reason but it's not called that in the back end
    integrated: false
  },
  JUNO_TYPE: {
    name: "Juno - Type",
    integrated: false
  },
  JUNO_TYPE_HYBRID: {
    name: "Juno - Type (Hybrid)",
    integrated: false
  },
  INTRAHEALTH: {
    name: "Intrahealth Profile",
    integrated: false
  },
  WOLF_INTEGRATED: {
    name: "Wolf - Integrated",
    integrated: true
  },
  WOLF_INTEGRATED_HYBRID: {
    name: "Wolf - Integrated (Hybrid)",
    integrated: true
  },
  MED_ACCESS_INTEGRATED: {
    name: "MA - Integrated",
    integrated: true
  },
  MED_ACCESS_INTEGRATED_HYBRID: {
    name: "MA - Integrated (Hybrid)",
    integrated: true
  },
  PS_SUITE_INTEGRATED: {
    name: "PSS - Integrated",
    integrated: true
  },
  PS_SUITE_INTEGRATED_HYBRID: {
    name: "PSS - Integrated (Hybrid)",
    integrated: true
  },
  PSS_INTEGRATED_SCHEDULE: {
    name: "PSS Integrated Schedule",
    integrated: true
  },
  PSS_INTEGRATED_SCHEDULE_HYBRID: {
    name: "PSS Integrated Schedule (Hybrid)",
    integrated: true
  }
});

export const EMRCategories = Object.freeze({
  WOLF: "Wolf",
  ACCURO: "Accuro",
  PS_SUITE: "PS Suite",
  INTELERAD: "Intelerad",
  MEDACCESS: "Med Access",
  JUNO: "Juno",
  OSCAR: "Oscar",
  INTRAHEALTH: "Intrahealth Profile",
  INTELERAD_UPLOAD: "Intelerad Upload"
});

export const IntegrationTypes = Object.freeze({
  PULL: "pull",
  NONE: "none",
  PUSH: "push"
});

export const ChatFlowTitles = Object.freeze({
  FEEDBACK: "Feedback",
  PREVISIT_INSTRUCTIONS: "previsit instructions",
  PREVISIT_INSTRUCTIONS_MULTI: "previsit instructions multi",
  GP_HEALTH_CHAT: "GP Health Chat"
});

export const ChatFlowTags = Object.freeze({
  PREVISIT: "Previsit",
  POSTVISIT: "postvisit",
  APPOINTMENT_REQUEST: "Appointment Request",
  CONVERSATION: "Conversation",
  BULK: "bulk",
  BASIC_DIRECT_MESSAGE: "Basic Direct Message",
  SECURE_DIRECT_MESSAGE: "Secure Direct Message",
  SCRIBE_VISIT: "Scribe Visit"
});

export const timezones = [
  "Canada/Atlantic",
  "Canada/Central",
  "Canada/Eastern",
  "Canada/Mountain",
  "Canada/Newfoundland",
  "Canada/Pacific",
  "Canada/Saskatchewan",
  "Canada/Yukon",
  "US/Alaska",
  "US/Aleutian",
  "US/Arizona",
  "US/Central",
  "US/East-Indiana",
  "US/Eastern",
  "US/Hawaii",
  "US/Indiana-Starke",
  "US/Michigan",
  "US/Mountain",
  "US/Pacific",
  "US/Samoa"
];

export const AccuroURLs = Object.freeze({
  [Environments.PRODUCTION]: {
    AUTH: `https://accapi.accuroemr.com/oauth/authorize`,
    OFFICES: `https://accapi.accuroemr.com/rest/v1/offices`,
    LOCATIONS: `https://accapi.accuroemr.com/rest/v1/locations`,
    PRACTITIONERS: `https://accapi.accuroemr.com/rest/v1/providers/offices`,
    REASONS: `https://accapi.accuroemr.com/rest/v1/scheduler/reasons`,
    STATUSES: `https://accapi.accuroemr.com/rest/v1/scheduler/statuses`,
    APPOINTMENTS: `https://accapi.accuroemr.com/rest/v2/materials/appointments`,
    APPOINTMENT: `https://accapi.accuroemr.com/rest/v1/provider-portal/scheduler/appointments`,
    LETTERS: `https://accapi.accuroemr.com/rest/v1/provider-portal/letters`
  },
  [Environments.STAGING]: {
    AUTH: `https://extdev-accapi.accuroemr.com/oauth/authorize`,
    OFFICES: `https://extdev-accapi.accuroemr.com/rest/v1/offices`,
    LOCATIONS: `https://extdev-accapi.accuroemr.com/rest/v1/locations`,
    PRACTITIONERS: `https://extdev-accapi.accuroemr.com/rest/v1/providers/offices`,
    REASONS: `https://extdev-accapi.accuroemr.com/rest/v1/scheduler/reasons`,
    STATUSES: `https://extdev-accapi.accuroemr.com/rest/v1/scheduler/statuses`,
    APPOINTMENTS: `https://extdev-accapi.accuroemr.com/rest/v2/materials/appointments`,
    APPOINTMENT: `https://extdev-accapi.accuroemr.com/rest/v1/provider-portal/scheduler/appointments`,
    LETTERS: `https://extdev-accapi.accuroemr.com/rest/v1/provider-portal/letters`
  },
  [Environments.DEVELOPMENT]: {
    AUTH: `https://extdev-accapi.accuroemr.com/oauth/authorize`,
    OFFICES: `https://extdev-accapi.accuroemr.com/rest/v1/offices`,
    LOCATIONS: `https://extdev-accapi.accuroemr.com/rest/v1/locations`,
    PRACTITIONERS: `https://extdev-accapi.accuroemr.com/rest/v1/providers/offices`,
    REASONS: `https://extdev-accapi.accuroemr.com/rest/v1/scheduler/reasons`,
    STATUSES: `https://extdev-accapi.accuroemr.com/rest/v1/scheduler/statuses`,
    APPOINTMENTS: `https://extdev-accapi.accuroemr.com/rest/v2/materials/appointments`,
    APPOINTMENT: `https://extdev-accapi.accuroemr.com/rest/v1/provider-portal/scheduler/appointments`,
    LETTERS: `https://extdev-accapi.accuroemr.com/rest/v1/provider-portal/letters`
  },
  [Environments.TEST]: {
    AUTH: `https://extdev-accapi.accuroemr.com/oauth/authorize`,
    OFFICES: `https://extdev-accapi.accuroemr.com/rest/v1/offices`,
    LOCATIONS: `https://extdev-accapi.accuroemr.com/rest/v1/locations`,
    PRACTITIONERS: `https://extdev-accapi.accuroemr.com/rest/v1/providers/offices`,
    REASONS: `https://extdev-accapi.accuroemr.com/rest/v1/scheduler/reasons`,
    STATUSES: `https://extdev-accapi.accuroemr.com/rest/v1/scheduler/statuses`,
    APPOINTMENTS: `https://extdev-accapi.accuroemr.com/rest/v2/materials/appointments`,
    APPOINTMENT: `https://extdev-accapi.accuroemr.com/rest/v1/provider-portal/scheduler/appointments`,
    LETTERS: `https://extdev-accapi.accuroemr.com/rest/v1/provider-portal/letters`
  }
});

export const AdminOrganizationDetailsPageTabs = Object.freeze({
  ACCOUNT: {
    label: "General",
    path: "/mikata-admin-dashboard/organizations/:organizationId/account"
  },
  SERVICES: {
    label: "Services",
    path: "/mikata-admin-dashboard/organizations/:organizationId/services"
  },
  ANALYTICS: {
    label: "Analytics",
    path: "/mikata-admin-dashboard/organizations/:organizationId/analytics"
  },
  INTEGRATION: {
    label: "Integration",
    path: "/mikata-admin-dashboard/organizations/:organizationId/integration"
  },
  BILLING: {
    label: "Billing",
    path: "/mikata-admin-dashboard/organizations/:organizationId/billing"
  },
  FEATURES: {
    label: "Features",
    path: "/mikata-admin-dashboard/organizations/:organizationId/features"
  },
  PREFERENCES: {
    label: "Preferences",
    path: "/mikata-admin-dashboard/organizations/:organizationId/preferences"
  },
  USERS: {
    label: "Users",
    path: "/mikata-admin-dashboard/organizations/:organizationId/users"
  },
  LOCATIONS: {
    label: "Locations",
    path: "/mikata-admin-dashboard/organizations/:organizationId/locations"
  },
  REASONS: {
    label: "Reasons",
    path: "/mikata-admin-dashboard/organizations/:organizationId/reasons"
  },
  PROVIDERS: {
    label: "Providers",
    path: "/mikata-admin-dashboard/organizations/:organizationId/providers"
  },
  INSTRUCTIONS: {
    label: "Instructions",
    path: "/mikata-admin-dashboard/organizations/:organizationId/instructions"
  },
  APPOINTMENT_REQUESTS: {
    label: "Appointment Requests",
    path: "/mikata-admin-dashboard/organizations/:organizationId/appointment-requests"
  },
  INBOX: {
    label: "Inbox",
    path: "/mikata-admin-dashboard/organizations/:organizationId/inbox"
  },
  APPOINTMENTS: {
    label: "Appointments",
    path: "/mikata-admin-dashboard/organizations/:organizationId/appointments"
  },
  APPOINTMENT_JOURNEYS: {
    label: "Journeys",
    path: "/mikata-admin-dashboard/organizations/:organizationId/journeys"
  },
  MESSAGE_LIBRARY: {
    label: "Message Library",
    path: "/mikata-admin-dashboard/organizations/:organizationId/message-library"
  },
  CAMPAIGNS: {
    label: "Campaigns",
    path: "/mikata-admin-dashboard/organizations/:organizationId/campaigns"
  },
  CHATS: {
    label: "Chats",
    path: "/mikata-admin-dashboard/organizations/:organizationId/chats"
  },
  DOCUMENTS: {
    label: "Documents",
    path: "/mikata-admin-dashboard/organizations/:organizationId/documents"
  },
  SCRIBE_TEMPLATES: {
    label: "Scribe Templates",
    path: "/mikata-admin-dashboard/organizations/:organizationId/scribe-templates"
  }
});

export const StaffSettingsPageTabs = Object.freeze({
  USERS: {
    label: "Users",
    path: "/settings/users"
  },
  APPOINTMENT_REQUESTS_CONFIG: {
    label: "Appointment Requests",
    path: "/settings/appointment-requests-config"
  },
  DOCUMENTS: {
    label: "Documents",
    path: "/mikata-admin-dashboard/organizations/:organizationId/documents"
  },
  GENERAL: {
    label: "General",
    path: "/settings/general"
  },
  REASONS: {
    label: "Reasons",
    path: "/settings/reasons"
  },
  BILLING: {
    label: "Billing",
    path: "/settings/billing"
  },
  SCRIBE_TEMPLATES: {
    label: "Scribe Templates",
    path: "/settings/scribe-templates"
  },
  SCRIBE_TEMPLATES_ALL: {
    label: "All templates",
    path: "/settings/scribe-templates/all"
  },
  SCRIBE_TEMPLATES_FAVOURITES: {
    label: "Favourite",
    path: "/settings/scribe-templates/favourites"
  }
});

export const SettingNames = Object.freeze({
  APPOINTMENT_BOOKING_CONFIG: "appointmentBookingConfig",
  BILLING_CONFIG: "billingConfig",
  BULK_SYNC_RANGE_WEEKS: "bulkSyncRangeWeeks",
  CHARGE_OVER_SUBSCRIPTION_ID: "chargeOverSubscriptionId", // deprecated in favour of BILLING_CONFIG
  CHARGE_OVER_SUBSCRIPTION_LINE_ITEMS: "chargeOverSubscriptionInfo", // deprecated in favour of BILLING_CONFIG
  CHARGE_OVER_USER_ID: "chargeOverUserId", // deprecated in favour of BILLING_CONFIG
  DEMO_ACCOUNT: "demoAccount",
  FEATURES: "features",
  FILTER_IN_APPTS_WITHOUT_REASON: "filterInApptsWithoutReasons",
  INTEGRATED_CHECK_IN_STATUS_MAP: "integratedCheckInStatusMap",
  INTEGRATED_STATUS_MAP: "integratedStatusMap",
  NUMBER_MIGRATION: "numberMigration",
  REMINDER_TYPE_PREFERENCE: "reminderTypePreference",
  REPORT_MAPPING: "reportMapping",
  STAFF_NOTIFICATIONS: "staffNotifications",
  TELUS_INSTANCE_ID: "telusInstanceId",
  TEST_MODE_EMAIL_WHITELIST: "testModeEmailWhitelist",
  TEST_MODE_ENABLED: "testModeEnabled",
  TEST_MODE_PHONE_WHITELIST: "testModePhoneWhitelist",
  USE_LOCATION_ADDRESS_IN_EMAIL_FOOTER: "useLocationAddressInEmailFooter"
});

export const BucketNameSuffixes = Object.freeze({
  ACCURO_FILE_SYNC: "accuro-sync-files",
  TELUS_FILE_SYNC: "telus-sync-files",
  PATIENT_UPLOADS: "patient-uploads",
  STAFF_UPLOADS: "staff-uploads",
  DATA_SCI_RAW_DATA: "data-sci-raw-data",
  FILE_UPLOADS: "file-uploads",
  ORG_SAMPLE_EXPORT: "org-sample-export",
  CAMPAIGN_UPLOADS: "campaign-uploads",
  ORG_LOGO_UPLOADS: "org-logo-uploads",
  PUBLIC_DOCUMENTS: "public-documents"
});

export const CampaignStatuses = Object.freeze({
  DRAFT: "draft",
  SENT: "sent",
  ERROR: "error",
  ACTIVE: "active",
  COMPLETE: "complete",
  CREATING_RECIPIENTS: "creating_recipients",
  FILE_ERROR: "file_error"
});

export const CampaignTemplatePlaceholders = Object.freeze({
  ORG_DISPLAY_NAME: "[orgDisplayName]",
  LINK: "[link]"
});

export const ShortLinkLength = 39;

export const ToolTipContent = Object.freeze({
  TEXT_TOOLTIP: "Send standard SMS to waiting patient to indicate ready to enter"
});

// Message Templates
export const MessageTopics = Object.freeze({
  SEVEN_DAY_SINGLE_REMINDER: "seven day reminder",
  SEVEN_DAY_MULTI_REMINDER: "seven day reminder multi",
  TWO_DAY_SINGLE_REMINDER: "two day reminder - sent 7 day",
  TWO_DAY_SINGLE_REMINDER_NO_SEVEN: "two day reminder - no 7 day",
  TWO_DAY_MULTI_REMINDER: "two day reminder multi - sent 7 day",
  TWO_DAY_MULTI_REMINDER_NO_SEVEN: "two day reminder multi - no 7 day",
  TWO_DAY_PREVISIT_LINK: "previsit instructions link - with 2 day",
  BOOKED: "booked message",
  MULTI_BOOKED: "multi booked message",
  CANCELLED: "cancelled message",
  MULTI_CANCELLED: "multi cancelled message",
  POST_VISIT_LINK: "postvisit chat",
  REMINDER_RESPONSE_NO_APPT: "reminder response no appt",
  REMINDER_CONFIRM: "previsit instructions link - 7 day confirm",
  REMINDER_RESPONSE_RESCHEDULE: "reminder response reschedule",
  REMINDER_RESPONSE_UNCLEAR: "reminder response unclear",
  USER_RESTART_REMINDERS: "user restarting reminders",
  USER_SURVEY_OPT_OUT: "user declined surveys",
  CAMPAIGN: "bulk message",
  CAMPAIGN_RESPONSE_UNCLEAR: "campaign response unclear",
  CHECK_IN_NOTICE: "parking lot daily",
  USER_CHECK_IN_RESPONSE: "parking lot check in response",
  CHECK_IN_NOTIFY_USER: "parking lot notify user",
  BOOKING_RESPONSE: "booking response",
  BOOKING_NOT_ENABLED_RESPONSE: "booking not enabled",
  BOOKING_NOT_ENABLED_FEEDBACK: "booking not enabled feedback",
  CHECK_IN_NO_APPOINTMENTS: "parking lot no today appt",
  CHECK_IN_UNCLEAR: "parking lot unclear",
  SCHEDULE_BASED_MESSAGE: "schedule-based message",
  DIRECT_MESSAGE: "direct message",
  SECURE_DIRECT_MESSAGE: "secure direct message",
  APPOINTMENT_REQUEST_DIRECT_MESSAGE: "appointment request direct message"
});

export const MessageTemplateActionTags = Object.freeze([
  { value: "associatePatient", label: "Associate Patient" },
  { value: "completeChat", label: "Mark Chat Complete" },
  { value: "pushDemographics", label: "Push Demographics" }
]);

export const MessageTemplateAppointmentRequestTags = Object.freeze([
  { value: "appointmentReasons", label: "Appointment Reasons" },
  { value: "dayOfWeekPreference", label: "Day of Week Preference" },
  { value: "locationPreferences", label: "Location Preferences" },
  { value: "patientFirstName", label: "Patient First Name" },
  { value: "patientLastName", label: "Patient Last Name" },
  { value: "patientHealthcareNumber", label: "Patient Healthcare Number" },
  { value: "patientDateOfBirth", label: "Patient Date of Birth" },
  { value: "patientPhoneNumber", label: "Patient Phone Number" },
  { value: "timeOfDayPreference", label: "Time of Day Preference" }
]);

export const MessageTemplateTags = Object.freeze([
  ...MessageTemplateAppointmentRequestTags,
  ...MessageTemplateActionTags
]);

export const NoteTemplateActionTags = {
  SEND_DPM: "sendDPM"
};

export const NoteTemplateTypeTags = {
  NOTES: "notes",
  LETTERS: "letters",
  FORMS: "forms",
  PATIENT: "patient"
};

export const NoteTemplateSystemTags = {
  DEFAULT: "default",
  RECOMMENDED: "recommended"
};

export const NoteTemplateSpecialtyTags = {
  FAMILY_MEDICINE: "familyMedicine",
  PSYCHIATRY: "psychiatry",
  PEDIATRICS: "pediatrics",
  OBGYN: "obgyn",
  PRENATAL: "prenatal",
  ORTHOPEDICS: "orthopedics",
  MENTAL_HEALTH: "mentalHealth",
  GERIATRICS: "geriatrics"
};

export const NoteTemplateStylingTags = {
  POINT_FORM: "pointForm",
  PROSE_FORM: "proseForm",
  CONCISE: "concise",
  DETAILED: "detailed"
};

export const NoteTemplatePseudoTags = {
  MIKATA: "mikata",
  FRENCH: "french",
  SPANISH: "spanish",
  CHINESE: "chinese"
};

export const NoteTemplateTagsConfig = Object.freeze({
  beta: { label: "Beta", value: "beta", color: "black" },
  mikata: { label: "Mikata", value: NoteTemplatePseudoTags.MIKATA, color: "black" },
  recommended: { label: "Recommended*", value: NoteTemplateSystemTags.RECOMMENDED, color: "black" },
  default: { label: "Default*", value: NoteTemplateSystemTags.DEFAULT, color: "black" },
  notes: { label: "Notes", value: NoteTemplateTypeTags.NOTES, color: "purple" },
  letters: { label: "Letters", value: NoteTemplateTypeTags.LETTERS, color: "purple" },
  forms: { label: "Forms", value: NoteTemplateTypeTags.FORMS, color: "purple" },
  patient: { label: "Patient", value: NoteTemplateTypeTags.PATIENT, color: "purple" },
  familyMedicine: {
    label: "Family Medicine",
    value: NoteTemplateSpecialtyTags.FAMILY_MEDICINE,
    color: "green"
  },
  psychiatry: { label: "Psychiatry", value: NoteTemplateSpecialtyTags.PSYCHIATRY, color: "green" },
  pediatrics: { label: "Pediatrics", value: NoteTemplateSpecialtyTags.PEDIATRICS, color: "green" },
  obgyn: { label: "OBGYN", value: NoteTemplateSpecialtyTags.OBGYN, color: "green" },
  prenatal: { label: "Prenatal", value: NoteTemplateSpecialtyTags.PRENATAL, color: "green" },
  orthopedics: {
    label: "Orthopedics",
    value: NoteTemplateSpecialtyTags.ORTHOPEDICS,
    color: "green"
  },
  mentalHealth: {
    label: "Mental Health",
    value: NoteTemplateSpecialtyTags.MENTAL_HEALTH,
    color: "green"
  },
  geriatrics: { label: "Geriatrics", value: NoteTemplateSpecialtyTags.GERIATRICS, color: "green" },
  pointForm: { label: "Point Form", value: NoteTemplateStylingTags.POINT_FORM, color: "yellow" },
  proseForm: { label: "Prose Form", value: NoteTemplateStylingTags.PROSE_FORM, color: "yellow" },
  concise: { label: "Concise", value: NoteTemplateStylingTags.CONCISE, color: "yellow" },
  detailed: { label: "Detailed", value: NoteTemplateStylingTags.DETAILED, color: "yellow" },
  french: { label: "French", value: NoteTemplatePseudoTags.FRENCH, color: "gray" },
  spanish: { label: "Spanish", value: NoteTemplatePseudoTags.SPANISH, color: "gray" },
  chinese: { label: "Chinese", value: NoteTemplatePseudoTags.CHINESE, color: "gray" },
  sendDPM: { label: "Send DPM", value: NoteTemplateActionTags.SEND_DPM, color: "gray" }
});

export const NoteTagsOptions = Object.values(NoteTemplateTagsConfig);

export const MessageMediums = Object.freeze({
  WEB_CHAT: "web/chat",
  SMS: "sms",
  EMAIL: "email",
  ANY: "any",
  VOICE: "voice"
});

export const BookingTopics = Object.freeze([
  MessageTopics.BOOKED,
  MessageTopics.MULTI_BOOKED,
  MessageTopics.CANCELLED,
  MessageTopics.MULTI_CANCELLED,
  MessageTopics.BOOKING_RESPONSE,
  MessageTopics.BOOKING_NOT_ENABLED_RESPONSE,
  MessageTopics.BOOKING_NOT_ENABLED_FEEDBACK
]);

export const CampaignTopics = Object.freeze([MessageTopics.CAMPAIGN_RESPONSE_UNCLEAR]);

export const ReminderTopics = Object.freeze([
  MessageTopics.SEVEN_DAY_SINGLE_REMINDER,
  MessageTopics.SEVEN_DAY_MULTI_REMINDER,
  MessageTopics.TWO_DAY_SINGLE_REMINDER,
  MessageTopics.TWO_DAY_SINGLE_REMINDER_NO_SEVEN,
  MessageTopics.TWO_DAY_MULTI_REMINDER,
  MessageTopics.TWO_DAY_MULTI_REMINDER_NO_SEVEN,
  MessageTopics.TWO_DAY_PREVISIT_LINK,
  MessageTopics.REMINDER_CONFIRM,
  MessageTopics.REMINDER_RESPONSE_RESCHEDULE,
  MessageTopics.REMINDER_RESPONSE_UNCLEAR,
  MessageTopics.REMINDER_RESPONSE_NO_APPT,
  MessageTopics.USER_RESTART_REMINDERS,
  MessageTopics.CHECK_IN_NOTICE,
  MessageTopics.USER_CHECK_IN_RESPONSE,
  MessageTopics.CHECK_IN_NOTIFY_USER,
  MessageTopics.CHECK_IN_NO_APPOINTMENTS,
  MessageTopics.CHECK_IN_UNCLEAR
]);

export const SurveyTopics = Object.freeze([
  MessageTopics.POST_VISIT_LINK,
  MessageTopics.USER_SURVEY_OPT_OUT
]);

export const ChatCardTypes = Object.freeze({
  ADDRESS_INPUT: "addressInput",
  APPT_INFO: "apptInfo",
  APPT: "appt",
  BACK: "back",
  BOOKING: "booking",
  CHAT_FLOW: "chatFlow",
  CONTACT: "contact",
  CHECK_IN: "checkInAction",
  DATE_INPUT: "dateInput",
  DEAD_END: "deadEnd",
  DETAILED_SELECT: "detailedSelect",
  DONE_BUTTON: "doneButton",
  EMAIL_INPUT: "emailInput",
  END: "end",
  FLAGGED_END: "flaggedEnd",
  INSTRUCTIONS: "instructions",
  INSTRUCTION_REASONS: "instructionReasons",
  INSTRUCTIONS_FROM_REASONS: "instructionsFromReasons",
  IMAGE_SELECT: "imageSelect",
  LANDING: "landing",
  MULTI_SELECT: "multiSelect",
  NEXT_BUTTON: "nextButton",
  NUMBER_INPUT: "numberInput",
  PHONE_INPUT: "phoneInput",
  PHOTO_UPLOAD: "photoUpload",
  PROCEDURE_INFO_NEW: "procedureInfoNew",
  PROCEDURE_INFO: "procedureInfo", // deprecated. See IHA-298 and IHA-380
  SCALE_NUMBERS: "scaleNumbers", // deprecated. See IHA-4908
  SCALE_INPUT: "scaleInput",
  SCRIBE_VISIT: "scribeVisit",
  SINGLE_SELECT: "singleSelect",
  SUB_FLOW_START: "subFlowStart",
  SUMMARY: "summary",
  TEXT_INPUT: "textInput",
  TEXT: "text",
  UNITS_INPUT: "unitsInput",
  USER_RESPONSE: "userResponse",
  PATIENT_VERIFICATION: "patientVerification",
  SECURE_MESSAGE: "secureMessage"
});

export const ChatFlowControlFlags = Object.freeze({
  FIRST: "first",
  RESUME: "resume",
  MULTI_SELECT_OPTIONS_PREFACE: "multiSelectOptionsPreface",
  PREVISIT_PROCEDURE_SELECTED: "previsitProcedureSelected",
  PREVISIT_NO_PROCEDURE: "previsitNoProcedure",
  IM_NOT_SURE: `I'm not sure`,
  SKIP: "Skip",
  NO_AVAILABLE_SLOTS_SELECTED: "noAvailableSlotsSelected",
  AVAILABLE_SLOTS_SELECTED: "availableSlotsSelected",
  APPT_AUTO_BOOKED: "appointmentAutoBooked",
  SCRIBE_VISIT_COMPLETED: "scribeVisitCompleted",
  SCRIBE_VISIT_CONSENT_DENIED: "scribeVisitConsentDenied"
});

export const ChatNodeOptions = [
  {
    label: "Address Input",
    cardType: ChatCardTypes.ADDRESS_INPUT,
    isInput: true
  },
  {
    label: "Chat Flow Section",
    cardType: ChatCardTypes.CHAT_FLOW,
    isInput: false
  },
  {
    label: "Check In",
    cardType: ChatCardTypes.CHECK_IN,
    isInput: true
  },
  {
    label: "Date Input",
    cardType: ChatCardTypes.DATE_INPUT,
    isInput: true
  },
  {
    label: "Email Input",
    cardType: ChatCardTypes.EMAIL_INPUT,
    isInput: true
  },
  {
    label: "Booking",
    cardType: ChatCardTypes.BOOKING,
    isInput: true
  },
  {
    label: "End",
    cardType: ChatCardTypes.END,
    isInput: false
  },
  {
    label: "Flagged End",
    cardType: ChatCardTypes.FLAGGED_END,
    isInput: false
  },
  {
    label: "Dead End",
    cardType: ChatCardTypes.DEAD_END,
    isInput: false
  },
  {
    label: "Image Select",
    cardType: ChatCardTypes.IMAGE_SELECT,
    isInput: true
  },
  {
    label: "Instructions",
    cardType: ChatCardTypes.INSTRUCTIONS,
    isInput: false
  },
  {
    label: "Instruction Reasons",
    cardType: ChatCardTypes.INSTRUCTION_REASONS,
    isInput: true
  },
  {
    label: "Instructions From Reasons",
    cardType: ChatCardTypes.INSTRUCTIONS_FROM_REASONS,
    isInput: false
  },
  {
    label: "Multi Select",
    cardType: ChatCardTypes.MULTI_SELECT,
    isInput: true
  },
  {
    label: "Next Button",
    cardType: ChatCardTypes.NEXT_BUTTON,
    isInput: false
  },
  {
    label: "Number Input",
    cardType: ChatCardTypes.NUMBER_INPUT,
    isInput: true
  },
  {
    label: "Patient Verification",
    cardType: ChatCardTypes.PATIENT_VERIFICATION,
    isInput: true
  },
  {
    label: "Phone Input",
    cardType: ChatCardTypes.PHONE_INPUT,
    isInput: true
  },
  {
    label: "Photo Upload",
    cardType: ChatCardTypes.PHOTO_UPLOAD,
    isInput: true
  },
  {
    label: "Scale Input",
    cardType: ChatCardTypes.SCALE_INPUT,
    isInput: true
  },
  {
    label: "Scribe Visit",
    cardType: ChatCardTypes.SCRIBE_VISIT,
    isInput: true
  },
  {
    label: "Single Select",
    cardType: ChatCardTypes.SINGLE_SELECT,
    isInput: true
  },
  {
    label: "Summary",
    cardType: ChatCardTypes.SUMMARY,
    isInput: true
  },
  {
    label: "Text Input",
    cardType: ChatCardTypes.TEXT_INPUT,
    isInput: true
  },
  {
    label: "Text",
    cardType: ChatCardTypes.TEXT,
    isInput: false
  },
  {
    label: "Units Input",
    cardType: ChatCardTypes.UNITS_INPUT,
    isInput: true
  }
];

// PERMITTED MESSAGE PLACEHOLDERS
export const MessagePlaceholders = Object.freeze([
  "[address]",
  "[date]",
  "[firsttime]",
  "[link]",
  "[locationName]",
  "[locationNumber]",
  "[number]",
  "[numOfAppts]",
  "[orgDisplayName]",
  "[place]",
  "[time]",
  "[times]",
  "[uniqueTimes]",
  "[providerName]",
  "[year]",
  "[noLink]"
]);

// Automated Message Configuration Trigger Values
export const TriggerUnitTypes = Object.freeze({
  DAYS: "days",
  HOURS: "hours",
  MINUTES: "minutes"
});

export const TriggerUnitFormOptions = [
  TriggerUnitTypes.DAYS,
  TriggerUnitTypes.HOURS,
  TriggerUnitTypes.MINUTES
].map((triggerUnitType) => ({
  label: triggerUnitType,
  value: triggerUnitType
}));

// Automated Message Configuration Trigger Order
export const TriggerOrderTypes = Object.freeze({
  BEFORE: "Before",
  AFTER: "After"
});

export const TriggerOrderFormOptions = [TriggerOrderTypes.AFTER, TriggerOrderTypes.BEFORE].map(
  (triggerOrderType) => ({
    label: triggerOrderType,
    value: triggerOrderType
  })
);

// Automated Message Configuration Trigger Event
export const TriggerEventTypes = Object.freeze({
  START: "Start",
  CREATED: "Created",
  CANCELLED: "Cancelled",
  COMPLETE: "Complete",
  BOOKED: "Booked"
});

export const TriggerEventFormOptions = [
  TriggerEventTypes.START,
  TriggerEventTypes.CREATED,
  TriggerEventTypes.COMPLETE,
  TriggerEventTypes.CANCELLED,
  TriggerEventTypes.BOOKED
].map((triggerEventType) => ({
  label: triggerEventType,
  value: triggerEventType
}));

// Modals
export const ModalTypes = Object.freeze({
  SEND_APPOINTMENT_REQUEST_DIRECT_MESSAGE: "SEND_APPOINTMENT_REQUEST_DIRECT_MESSAGE",
  SEND_DIRECT_MESSAGE: "SEND_DIRECT_MESSAGE",
  UPLOAD_APPOINTMENTS: "UPLOAD_APPOINTMENTS",
  CAMPAIGN_DETAILS: "CAMPAIGN_DETAILS",
  BOOK_APPOINTMENT: "BOOK_APPOINTMENT",
  CONFIRMATION: "CONFIRMATION",
  DIRECT_MESSAGE_DETAILS: "DIRECT_MESSAGE_DETAILS",
  SIMULATE_JOURNEY: "SIMULATE_JOURNEY",
  SEND_MESSAGE_TEMPLATE: "SEND_SMS_MESSAGE_TEMPLATE",
  VIEW_APPOINTMENT_CHAT_DETAILS: "VIEW_APPOINTMENT_CHAT_DETAILS",
  UPLOAD_DOCUMENT: "UPLOAD_DOCUMENT",
  FILTER_APPOINTMENTS: "FILTER_APPOINTMENTS",
  FILTER_TEMPLATES: "FILTER_TEMPLATES",
  ADD_UNINTEGRATED_APPOINTMENT: "ADD_UNINTEGRATED_APPOINTMENT",
  VIEW_EDIT_APPOINTMENT_DETAILS: "VIEW_EDIT_APPOINTMENT_DETAILS",
  EDIT_PROVIDER: "EDIT_PROVIDER",
  ADD_STAFF_USER: "ADD_STAFF_USER",
  ADD_TO_VOCABULARY: "ADD_TO_VOCABULARY",
  EDIT_STAFF_USER: "EDIT_STAFF_USER",
  ADD_CAMPAIGN: "ADD_CAMPAIGN",
  CREATE_SCRIBE_SUBSCRIPTION_PLAN: "CREATE_SCRIBE_SUBSCRIPTION_PLAN",
  INITIALIZE_NOTE: "INITIALIZE_NOTE",
  ADD_NOTE_TEMPLATE: "ADD_NOTE_TEMPLATE",
  DELETE_NOTE: "DELETE_NOTE",
  MIC_SETUP: "MIC_SETUP"
});

export const AdminModalTypes = Object.freeze({
  ADD_SMS_MESSAGE_TEMPLATE: "ADD_SMS_MESSAGE_TEMPLATE",
  EDIT_MESSAGE_TEMPLATE: "EDIT_MESSAGE_TEMPLATE",
  DELETE_SMS_MESSAGE_TEMPLATE: "DELETE_SMS_MESSAGE_TEMPLATE",
  START_CAMPAIGN: "START_CAMPAIGN",
  ADD_AUTOMATION: "ADD_AUTOMATION",
  DELETE_AUTOMATION: "DELETE_AUTOMATION",
  ADD_INSTRUCTION: "ADD_INSTRUCTION",
  DELETE_INSTRUCTION: "DELETE_INSTRUCTION",
  ADD_MESSAGE_CONFIGURATION: "ADD_MESSAGE_CONFIGURATION",
  DELETE_MESSAGE_CONFIGURATION: "DELETE_MESSAGE_CONFIGURATION",
  ADD_REPORT_TEMPLATE: "ADD_REPORT_TEMPLATE",
  DELETE_REPORT_TEMPLATE_CARD: "DELETE_REPORT_TEMPLATE_CARED",
  ADD_LOCATION: "ADD_LOCATION",
  EDIT_LOCATION: "EDIT_LOCATION",
  PUBLISH_CHAT: "PUBLISH_CHAT",
  ACTIVATE_TEST_MODE: "ACTIVATE_TEST_MODE",
  EDIT_BOOKING_CONFIGURATION: "EDIT_BOOKING_CONFIGURATION",
  APPOINTMENT_REQUESTS_CONFIGURATION: "APPOINTMENT_REQUESTS_CONFIGURATION",
  ADD_CHAT_NODE: "ADD_CHAT_NODE",
  ADD_CHAT_FLOW: "ADD_CHAT_FLOW",
  CLONE_CHAT_FLOW_TO_ORGANIZATION: "CLONE_CHAT_FLOW_TO_ORGANIZATION",
  ADD_SERVICE: "ADD_SERVICE",
  EDIT_SERVICE: "EDIT_SERVICE",
  PURCHASE_NUMBERS: "PURCHASE_NUMBERS",
  MIGRATE_SERVICE: "MIGRATE_SERVICE",
  DELETE_SERVICE: "DELETE_SERVICE",
  ADD_CHAT_FLOW_OUTPUT: "ADD_CHAT_FLOW_OUTPUT",
  DELETE_CHAT_FLOW_OUTPUT: "DELETE_CHAT_FLOW_OUTPUT",
  ADD_REASONS: "ADD_REASONS",
  ADD_ADMIN_USER: "ADD_ADMIN_USER",
  EDIT_ADMIN_USER: "EDIT_ADMIN_USER",
  ADD_NOTE_TEMPLATE: "ADD_NOTE_TEMPLATE",
  DELETE_NOTE_TEMPLATE: "DELETE_NOTE_TEMPLATE",
  LINK_ORGANIZATION: "LINK_ORGANIZATION"
});

// Integrations
export const IntegrationCategories = Object.freeze({
  TELUS: "Telus",
  ACCURO: "Accuro"
});

export const SyncRecordStatuses = Object.freeze({
  PROCESSING: "processing",
  SUCCESS: "success",
  FAIL: "fail",
  FAIL_NO_LOCATIONS: "fail no active locations",
  PROBLEM_SENDING: "error problem sending reminders",
  NO_APPTS_FOUND: "error no appts found",
  STALE: "stale"
});

export const EMRFeatures = Object.freeze({
  PUSH_RTF_REPORT: "push_rtf_report",
  PUSH_HTML_REPORT: "push_html_report",
  PULL_LOCATIONS: "pull_locations",
  PULL_REASONS: "pull_reasons",
  PULL_PRACTITIONERS: "pull_practitioners",
  PUSH_PDF: "push_pdf"
});

export const PSSStatusDisplayNames = Object.freeze({
  1: "Confirmed",
  2: "Arrived",
  3: "In Room",
  4: "Ready",
  5: "Finished"
});

// Message Configuration Topics
export const MessageConfigurationTopics = Object.freeze({
  REMINDER: "reminder",
  BOOKED: "booked message",
  CANCELLED: "cancelled message",
  POST_VISIT_LINK: "postvisit chat",
  COMPLETION: "Appointment completion",
  CHECK_IN_NOTICE: "parking lot daily",
  CUSTOM_MESSAGE: "custom message"
});

export const FEATURES = Object.freeze({
  APPOINTMENT_REQUESTS: {
    value: "appointment_requests",
    name: "Appointment Requests",
    description: "Allows patients to request an appointment",
    configurationModalName: AdminModalTypes.APPOINTMENT_REQUESTS_CONFIGURATION
  },
  INBOX: {
    value: "inbox",
    name: "Inbox",
    description: "Allows users to manage their daily tasks related to appointments and patients"
  },
  APPOINTMENT_BOOKING: {
    value: "appointment_booking",
    name: "Appointment Booking",
    description: "Allows patients to book an appointment",
    configurationModalName: AdminModalTypes.EDIT_BOOKING_CONFIGURATION
  },
  PARKING_LOT_CHECKIN: {
    value: "parking_lot_checkin",
    name: "Parking Check-in",
    description: "Sends check-in notification an hour or two before the appointment"
  },
  SCHEDULE_BASED_MESSAGES: {
    value: "schedule_based_messages",
    name: "Schedule Based Messages",
    description: "Able to configure exact timing of when messages are sent out"
  },
  REFRESH_LINKS: {
    value: "refresh_links",
    name: "Refresh Links",
    description: "Patients are able to request new links when they have expired"
  },
  DIRECT_PATIENT_MESSAGES: {
    value: "direct_patient_messages",
    name: "Direct Patient Messages",
    description: "Staff can send a message directly to a patient"
  },
  VOICE_FALLBACK: {
    value: "voice_fallback",
    name: "Voice Reminders",
    description: "Use voice calls as a fallback when unable to send SMS or email reminders"
  },
  ANALYTICS: {
    value: "analytics",
    name: "Analytics",
    description: "Access to the clinic analytics page"
  }
});

export const ChatFlowOutputTypes = Object.freeze({
  PUSH_NOTE: "pushNote",
  PUSH_DEMOGRAPHICS: "pushDemographics",
  SYNC_SCRIBE_NOTE: "syncScribeNote",
  SCRIBE_PREFILL: "scribePrefill",
  STAFF_DETAILS_APPT: "staffDetailsAppt",
  STAFF_DETAILS_APPT_REQUEST: "staffDetailsApptRequest",
  PUSH_APPOINTMENT_REQUEST: "pushAppointmentRequest",
  FILE_CSV: "fileCSV"
});

export const StartCardVariants = Object.freeze({
  CARD: "card",
  TASK: "task",
  PRIORITY_TASK: "priorityTask"
});

export const ChargeOverProducts = Object.freeze({
  SCRIBE_UNLIMITED_MONTHLY_75: {
    key: "SS-SCRIBE-UNL-MON-75" // align with key of plan in ChargeOver
  },
  SCRIBE_UNLIMITED_MONTHLY_50: {
    key: "SS-SCRIBE-UNL-MON-50" // align with key of plan in ChargeOver
  },
  SCRIBE_UNLIMITED_MONTHLY_25: {
    key: "SS-SCRIBE-UNL-MON-25" // align with key of plan in ChargeOver
  },
  SCRIBE_UNLIMITED_MONTHLY_00: {
    key: "SS-SCRIBE-UNL-MON-00" // align with key of plan in ChargeOver
  },
  SCRIBE_UNLIMITED_ANNUAL: {
    key: "SS-SCRIBE-UNL-ANN" // align with key of plan in ChargeOver
  }
});
export const ScribeProductKeys = [
  ChargeOverProducts.SCRIBE_UNLIMITED_MONTHLY_75.key,
  ChargeOverProducts.SCRIBE_UNLIMITED_MONTHLY_50.key,
  ChargeOverProducts.SCRIBE_UNLIMITED_MONTHLY_25.key,
  ChargeOverProducts.SCRIBE_UNLIMITED_MONTHLY_00.key,
  ChargeOverProducts.SCRIBE_UNLIMITED_ANNUAL.key
];

export const Provinces = Object.freeze([
  "Alberta",
  "British Columbia",
  "Manitoba",
  "Montreal",
  "New Brunswick",
  "Newfoundland & Labrador",
  "Northwest Territories",
  "Nova Scotia",
  "Nunavut",
  "Ontario",
  "Prince Edward Island",
  "Saskatchewan",
  "Yukon"
]);

export const States = Object.freeze([
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
]);

export const MikataDefaultNoteTemplateTitles = Object.freeze({
  BLANK_NOTE_TEMPLATE: "Blank Note",
  SOAP_NOTE_TEMPLATE: "SOAP Note - Basic",
  REFERRAL_LETTER_TEMPLATE_POINT_FORM: "Referral Letter - Point Form",
  REFERRAL_LETTER_TEMPLATE_PROSE: "Referral Letter - Prose Form"
});

export const ScribeNotificationType = Object.freeze({
  LONG_RESPONSE_TIME: "longResponseTime",
  VERY_LONG_RESPONSE_TIME: "veryLongResponseTime",
  MULTIPLE_CONNECTIONS: "multipleConnections"
});

// https://learn.microsoft.com/en-us/azure/ai-services/speech-service/language-support?tabs=stt
// Limiting to languages that support display text formatting: https://learn.microsoft.com/en-us/azure/ai-services/speech-service/display-text-format
export const LanguageOptions = [
  {
    label: "English",
    value: "en-CA"
  },
  {
    label: "French (Bilingual - English)",
    value: "fr-CA",
    simpleDisplay: "French"
  },
  // {
  //   label: "Cantonese, Traditional",
  //   value: "zh-HK",
  //   simpleDisplay: "Chinese"
  // },
  {
    label: "German",
    value: "de-DE"
  },
  {
    label: "Hindi",
    value: "hi-IN"
  },
  {
    label: "Italian",
    value: "it-IT"
  },
  // {
  //   label: "Japanese",
  //   value: "ja-JP"
  // },
  // {
  //   label: "Korean",
  //   value: "ko-KR"
  // },
  // {
  //   label: "Mandarin, Simplified",
  //   value: "zh-CN",
  //   simpleDisplay: "Chinese"
  // },
  {
    label: "Norwegian Bokmål",
    value: "nb-NO",
    simpleDisplay: "Norwegian"
  },
  {
    label: "Polish",
    value: "pl-PL"
  },
  {
    label: "Portuguese",
    value: "pt-BR"
  },
  {
    label: "Punjabi",
    value: "pa-IN"
  },
  {
    label: "Spanish",
    value: "es-MX"
  },
  {
    label: "Swedish",
    value: "sv-SE"
  },
  {
    label: "Turkish",
    value: "tr-TR"
  },
  {
    label: "Ukrainian",
    value: "uk-UA"
  },
  {
    label: "Vietnamese",
    value: "vi-VN"
  }
];

export const defaultLanguageOptionValue = "en-CA";

export const FeedbackFormTypes = Object.freeze({
  SCRIBE_MODEL_FEEDBACK: "scribeModelFeedback",
  SCRIBE_ISSUE: "scribeIssue",
  SCRIBE_TEMPLATE_REQUEST: "scribeTemplateRequest",
  SCRIBE_SUGGESTION: "scribeSuggestion"
});

export const PermissionLevels = Object.freeze({
  TEN: 10,
  TWENTY: 20,
  THIRTY: 30
});

export const PermissionPrefixes = Object.freeze({
  UPDATE_USER_OTHER: "update_user_other"
});
