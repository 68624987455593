import React, { Dispatch, SetStateAction } from "react";
import { useFormApi, useFormState } from "informed";

import Button from "../../../../ui/Button";
import { CardSection } from "../../../../ui/Card";
import Heading from "../../../../ui/Heading";
import { Trash } from "../../../../ui/Icon";
import { TextInput } from "../../../../ui/Input";
import Text from "../../../../ui/Text";

import styles from "./index.module.scss";

type PropsType = {
  customVocabulary: string[];
  setCustomVocabulary: Dispatch<SetStateAction<string[]>>;
};

const AddToCustomVocabForm = ({ customVocabulary, setCustomVocabulary }: PropsType) => {
  const formApi = useFormApi();
  const formState = useFormState();

  return (
    <CardSection title="AI Vocabulary" classname={styles.CustomCardSection}>
      <div className={styles.CardSection}>
        <div className={styles.HeadingRow}>
          <Heading size="META">Vocabulary</Heading>
        </div>

        <div>
          <Text size="S" className={styles.TextMarginBottom}>
            Improve Mika AI by adding words and short phrases to the vocabulary
          </Text>
          <div className={styles.AddCustomWord}>
            <TextInput fieldName="addCustomVocabularyWord" />
            <Button
              inline
              onClick={() => {
                const newWord: string = (formState.values.addCustomVocabularyWord as string) || "";
                if (!newWord) {
                  formApi.setError("addCustomVocabularyWord", "Please enter a word");
                } else if (newWord.split(" ")?.length > 3) {
                  formApi.setError(
                    "addCustomVocabularyWord",
                    "Phrase cannot contain more than 3 words"
                  );
                } else if (newWord.length > 30) {
                  formApi.setError("addCustomVocabularyWord", "this is too long.");
                } else if (customVocabulary.includes(newWord)) {
                  formApi.setError(
                    "addCustomVocabularyWord",
                    "Word already present in the list below"
                  );
                } else {
                  setCustomVocabulary((prev) => [...prev, newWord]);
                  formApi.resetField("addCustomVocabularyWord");
                }
              }}
            >
              Add
            </Button>
          </div>
        </div>
        <div className={styles.CustomVocab}>
          {customVocabulary?.map((item, index) => {
            return (
              <Text size="S" key={`customVocabulary_${index}`} className={styles.CustomVocabRow}>
                {item}
                <Button
                  inline
                  className={styles.CustomVocabRowButton}
                  onClick={() => {
                    const clonedList = [...customVocabulary];
                    clonedList.splice(index, 1);
                    setCustomVocabulary(clonedList);
                  }}
                >
                  <Trash size={16} />
                </Button>
              </Text>
            );
          })}
        </div>
      </div>
    </CardSection>
  );
};

export default AddToCustomVocabForm;
