import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

import { UserContext } from "../../providers/UserProvider";

import Text from "../../ui/Text";
import Button from "../../ui/Button";
import Card from "../../ui/Card";
import Heading from "../../ui/Heading";
import { MikataSimple, PeopleBackgroundImage } from "../../ui/Icon";
import { Form, TextInput } from "../../ui/Input";

import { isRequired } from "../../../utils/validators";

import styles from "./index.module.scss";

type FormState = { password: string; passwordConfirm: string };

const passwordValidator = isRequired("Password required");
const passwordConfirmValidator = isRequired("Password required");
const formValidator = ({ password, passwordConfirm }: FormState) => {
  let passwordError;
  let passwordConfirmError;

  if (password.length < 9) {
    passwordError = "Password must be at least 9 characters!";
  }

  if (password !== passwordConfirm) {
    passwordConfirmError = "Passwords must match";
  }

  return { password: passwordError, passwordConfirm: passwordConfirmError };
};

const PasswordReset = () => {
  const { token } = useParams<{ token?: string }>();
  const userContext = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [suggestionsArray, setSuggestionsArray] = useState<string[]>([]);

  const handleSubmit = async (formState: FormState) => {
    setLoading(true);
    setErrorMessage("");
    setSuggestionsArray([]);

    if (token) {
      localStorage.setItem("jwt", token);
      const decoded: { userId: string; email: string } = jwtDecode(token);
      const { userId, email } = decoded;
      const { success, msg, warning, suggestions } = await userContext.attemptPasswordUpdate(
        userId,
        formState.password
      );

      if (success) {
        const loginResponse = await userContext.attemptLogin({
          email,
          password: formState.password
        });

        if (loginResponse && loginResponse.needTwoFA) {
          userContext.logout();
        } else if (loginResponse && loginResponse.twoFAError) {
          setErrorMessage("Unexpected error, please try again.");
        }
      } else {
        if (msg && warning) setErrorMessage(`${msg} ${warning}.`);
        else setErrorMessage(msg || "Password update unsuccessful. Please try again.");

        setSuggestionsArray(suggestions || []);
      }
    }
    setLoading(false);
  };

  return (
    <div className={styles.Page}>
      <Card className={styles.Card}>
        <Form
          onSubmit={(formState) => handleSubmit(formState.values as FormState)}
          validateFields={(values) => formValidator(values as FormState)}
          initialValues={{ password: "", passwordConfirm: "" }}
        >
          <div className={styles.Logo}>
            <MikataSimple size={50} />
          </div>
          <Heading size="L" component="h1">
            Update Your Password
          </Heading>

          <TextInput
            id="password"
            label="Password"
            type="password"
            fieldName="password"
            autoComplete="new-password"
            placeholder="Enter password"
            validate={passwordValidator}
          />
          <TextInput
            id="passwordConfirm"
            label="Confirm Password"
            type="password"
            fieldName="passwordConfirm"
            autoComplete="new-password"
            placeholder="Confirm password"
            validate={passwordConfirmValidator}
          />
          {errorMessage && (
            <Text className={styles.ErrorMessage}>
              {errorMessage} {suggestionsArray.join(". ")}
            </Text>
          )}
          <Button
            id="resetPassword"
            className={styles.ButtonLogin}
            type="submit"
            disabled={loading || userContext.loginLoading}
          >
            Log In
          </Button>
        </Form>
      </Card>
      <PeopleBackgroundImage className={styles.Background} />
    </div>
  );
};

export default PasswordReset;
