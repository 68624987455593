import axios from "axios";

import getToken from "../utils/getToken";

import { addNotification } from "./notifications";
import { getChatFlowDetails } from "./chats";

import { ROOT_URL, CREATE_CHAT_FLOW_OUTPUT, UPDATE_CHAT_FLOW_OUTPUT } from "../constants";
import { ActionStatus, Dispatch, CreateChatFlowOutput, UpdateChatFlowOutput } from "../types";

export const createChatFlowOutput =
  (newOutput: CreateChatFlowOutput, chatFlowId: number) => (dispatch: Dispatch) => {
    if (!chatFlowId) return Promise.reject();
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: CREATE_CHAT_FLOW_OUTPUT,
      status: ActionStatus.loading
    });

    return axios
      .post(`${ROOT_URL}/chats/outputs/`, newOutput, config)
      .then((payload) => {
        getChatFlowDetails(chatFlowId.toString())(dispatch);
        dispatch({
          type: CREATE_CHAT_FLOW_OUTPUT,
          status: ActionStatus.success,
          payload
        });
        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: "Chat flow output created",
            autoDismiss: true
          })
        );
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to create chat flow output",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
      });
  };

export const updateChatFlowOutput =
  (chatFlowOutputId: number, updateOutputData: UpdateChatFlowOutput, chatFlowId: number) =>
  (dispatch: Dispatch) => {
    if (!chatFlowOutputId) return Promise.reject();
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: UPDATE_CHAT_FLOW_OUTPUT,
      status: ActionStatus.loading
    });

    return axios
      .patch(`${ROOT_URL}/chats/outputs/${chatFlowOutputId}`, updateOutputData, config)
      .then((payload) => {
        getChatFlowDetails(chatFlowId.toString())(dispatch);
        dispatch({
          type: UPDATE_CHAT_FLOW_OUTPUT,
          status: ActionStatus.success,
          payload
        });
        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: "Chat flow output updated",
            autoDismiss: true
          })
        );
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to update chat flow output",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
      });
  };
