import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import Button from "../../ui/Button";
import Heading from "../../ui/Heading";
import Card from "../../ui/Card";
import { Form, TextInput, SelectInput, RadioGroup } from "../../ui/Input";

import { isRequired } from "../../../utils/validators";

import { addOrganization as addOrganizationAction, AddOrganizationData } from "../../../actions";

import { timezones, OrganizationTypes } from "../../../constants";

import { ReduxStateType } from "../../../types";

import styles from "./index.module.scss";

type CreateOrganizationFormState = {
  organizationType: string;
  displayName: string;
  phoneNumber: string;
  email: string;
  address: string;
  suite: string;
  city: string;
  province: string;
  country: string;
  postalCode: string;
  timezone: string;
};
type PropsType = {
  loading: boolean;
  addOrganization: (data: AddOrganizationData, onSuccess?: (orgId: number) => void) => void;
};

const OrganizationCreatePage = ({ loading, addOrganization }: PropsType) => {
  const navigate = useNavigate();
  const initialFormState = {
    organizationType: OrganizationTypes.ORGANIZATION,
    displayName: "",
    phoneNumber: "",
    email: "",
    address: "",
    suite: "",
    city: "",
    province: "",
    country: "Canada",
    postalCode: "",
    timezone: "Canada/Mountain"
  };

  const typeOptions = [
    { label: "Parent of a group", value: OrganizationTypes.PARENT },
    { label: "Organization", value: OrganizationTypes.ORGANIZATION }
  ];

  const onSave = (formState: CreateOrganizationFormState) => {
    addOrganization(
      {
        organizationAddress: formState.address,
        organizationCity: formState.city,
        organizationCountry: formState.country,
        organizationEmailAddress: formState.email,
        organizationName: formState.displayName,
        organizationPhoneNumber: formState.phoneNumber,
        organizationPostalCode: formState.postalCode,
        organizationState: formState.province,
        organizationSuite: formState.suite,
        organizationTimezone: formState.timezone,
        organizationType:
          formState.organizationType === OrganizationTypes.PARENT
            ? formState.organizationType
            : null
      },
      (newOrgId) => {
        navigate(`/mikata-admin-dashboard/organizations/${newOrgId}`);
      }
    );
  };

  const displayNameValidator = isRequired("Please enter a name");
  const phoneNumberValidator = isRequired("Please enter a phone number");
  const emailValidator = isRequired("Please enter an email");
  const addressValidator = isRequired("Please enter an address");
  const cityValidator = isRequired("Please enter a city");
  const provinceValidator = isRequired("Please enter a province");
  const countryValidator = isRequired("Please enter a country");
  const postalCodeValidator = isRequired("Please enter a postal code");
  const timezoneValidator = isRequired("Please select a timezone");

  return (
    <div className={styles.Container}>
      <Card>
        <Heading size="L">Add new organization</Heading>
        <Form
          onSubmit={(formState) => onSave(formState.values as CreateOrganizationFormState)}
          initialValues={initialFormState}
        >
          <RadioGroup fieldName="organizationType" label="Role" options={typeOptions} />
          <TextInput
            id="organizationName"
            fieldName="displayName"
            label="name"
            validate={displayNameValidator}
          />
          <TextInput
            id="organizationPhoneNumber"
            fieldName="phoneNumber"
            label="phone number"
            validate={phoneNumberValidator}
          />
          <TextInput
            id="organizationEmail"
            fieldName="email"
            label="email"
            validate={emailValidator}
          />
          <TextInput
            id="organizationAddress"
            fieldName="address"
            label="address"
            validate={addressValidator}
          />
          <TextInput id="organizationSuite" fieldName="suite" label="suite" />
          <TextInput id="organizationCity" fieldName="city" label="city" validate={cityValidator} />
          <TextInput
            id="organizationState"
            fieldName="province"
            label="province"
            validate={provinceValidator}
          />
          <TextInput
            id="organizationCountry"
            fieldName="country"
            label="country"
            validate={countryValidator}
          />
          <TextInput
            id="organizationPostalCode"
            fieldName="postalCode"
            label="postal code"
            validate={postalCodeValidator}
          />
          <SelectInput
            fieldName="timezone"
            label="timezone"
            options={timezones.map((timezone) => {
              return {
                label: timezone,
                value: timezone
              };
            })}
            validate={timezoneValidator}
          />

          <div className={styles.ButtonGroup}>
            <Button id="createOrganization" type="submit" disabled={loading}>
              Save
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

const mapStateToProps = ({ organizationDetails }: ReduxStateType) => {
  return {
    loading: organizationDetails.addOrganizationLoading
  };
};

export default connect(mapStateToProps, {
  addOrganization: addOrganizationAction
})(OrganizationCreatePage);
