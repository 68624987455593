import React from "react";
import { connect } from "react-redux";
import { ReduxStateType, Service } from "../../../../types";

import { FloatModal } from "../../../ui/Modal";
import Heading from "../../../ui/Heading";
import Text from "../../../ui/Text";
import { Form, TextInput } from "../../../ui/Input";
import Button from "../../../ui/Button";
import Loader from "../../../ui/Loader";

import {
  closeModal as closeModalAction,
  findPhoneNumbers as findPhoneNumbersAction,
  FindPhoneNumbersData,
  buyPhoneNumbers as buyPhoneNumbersAction,
  BuyPhoneNumbersData,
  clearNumbersFindResult as clearNumbersFindResultAction
} from "../../../../actions";

import { capitalize } from "../../../../utils/stringTransformations";

import { ServiceProviders } from "../../../../constants";

import styles from "./index.module.scss";

type PropsType = {
  organizationId: number;
  service: Service;
  closeModal: () => void;
  findPhoneNumbers: (data: FindPhoneNumbersData) => void;
  buyPhoneNumbers: (data: BuyPhoneNumbersData, onSuccess: () => void) => void;
  clearNumbersFindResult: () => void;
  findingNumbers: boolean;
  availableNumbers: Array<string> | undefined;
};

type FormState = {
  search: string;
};

const initialFormState = {
  search: ""
};

const PurchaseNumbers = ({
  organizationId,
  service,
  closeModal,
  findPhoneNumbers,
  buyPhoneNumbers,
  clearNumbersFindResult,
  findingNumbers,
  availableNumbers
}: PropsType): JSX.Element => {
  const canSearchByCity = [ServiceProviders.TWILIO.value].includes(service.provider);
  const canSearchByAreaCode = [
    ServiceProviders.PLIVO.value,
    ServiceProviders.VONAGE.value
  ].includes(service.provider);

  const getSearchPlaceholderText = (): string => {
    if (canSearchByCity) {
      return "Enter search city";
    }
    if (canSearchByAreaCode) {
      return "Enter area codes to search";
    }
    return "Enter search criteria";
  };

  const searchForNumbers = async (formValues: FormState) => {
    const data: FindPhoneNumbersData = {
      organizationId,
      newPhoneCount: 1,
      serviceRecordId: service.id,
      city: undefined,
      areaCodeList: undefined
    };

    if (canSearchByCity) {
      data.city = formValues.search;
    } else if (canSearchByAreaCode) {
      data.areaCodeList = formValues.search.split(",");
    }

    findPhoneNumbers(data);
  };

  const purchaseNumbers = (number: string) => {
    const numberData: BuyPhoneNumbersData = {
      organizationId,
      serviceRecordId: service.id,
      numbersList: number ? [number] : []
    };
    buyPhoneNumbers(numberData, closeModal);
  };

  const clearAndClose = () => {
    clearNumbersFindResult();
    closeModal();
  };

  return (
    <FloatModal isOpen onClose={() => clearAndClose()}>
      <Heading size="L" className={styles.Heading}>
        Purchase Phone Numbers - {capitalize(service.provider)}
      </Heading>
      <Form
        onSubmit={(formState) => searchForNumbers(formState.values as FormState)}
        initialValues={initialFormState}
      >
        <TextInput fieldName="search" label="search" placeholder={getSearchPlaceholderText()} />
        <Button type="submit">Search</Button>
      </Form>
      <div>
        <hr className={styles.Divider} />
        {findingNumbers && <Loader small center />}
        {!findingNumbers && availableNumbers && (
          <>
            <Heading size="S">Found numbers:</Heading>
            {availableNumbers.length > 0 ? (
              availableNumbers.map((number: string) => {
                return (
                  <div key={`purchaseOption-${number}`} className={styles.PurchaseOption}>
                    <Text className={styles.TextList} key={number} size="M">
                      {number}
                    </Text>
                    <Button inline onClick={() => purchaseNumbers(number)}>
                      Buy
                    </Button>
                  </div>
                );
              })
            ) : (
              <Text>No numbers available for search criteria</Text>
            )}
          </>
        )}
      </div>
    </FloatModal>
  );
};

const mapStateToProps = ({ services }: ReduxStateType) => {
  return {
    findingNumbers: services.servicesFindNumbersLoading,
    availableNumbers: services.findResult
  };
};

export default connect(mapStateToProps, {
  closeModal: closeModalAction,
  findPhoneNumbers: findPhoneNumbersAction,
  buyPhoneNumbers: buyPhoneNumbersAction,
  clearNumbersFindResult: clearNumbersFindResultAction
})(PurchaseNumbers);
