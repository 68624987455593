import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import cx from "classnames";
import { connect } from "react-redux";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import flatten from "lodash/flatten";

import Text from "../../../ui/Text";
import Heading from "../../../ui/Heading";
import TableGrid, {
  SortByValue,
  SortByDirection,
  StopClickPropagation
} from "../../../ui/TableGrid";
import Dropdown from "../../../ui/Dropdown";
import Button from "../../../ui/Button";
import Loader from "../../../ui/Loader";
import Filters from "../../../ui/Filters/FiltersWithCount";
import { Clock, User, Document, DownloadCloud, EmptyContentImage, Refresh } from "../../../ui/Icon";

import AppointmentRequestDetails from "./AppointmentRequestDetails";

import formatDate from "../../../../utils/formatDate";
import formatPhone from "../../../../utils/formatPhone";
import getToken from "../../../../utils/getToken";
import { updateQueryString, useQueryString } from "../../../../utils/queryStringHelpers";
import { getRequisitionUrl } from "../../../../lib";
import useInterval from "../../../../hooks/useInterval";

import {
  fetchAppointmentRequests as fetchAppointmentRequestsAction,
  FetchAppointmentRequestsData,
  updateAppointmentRequest as updateAppointmentRequestAction,
  addNotification as addNotificationAction,
  UpdateAppointmentRequestData,
  openModal as OpenModalAction,
  OpenModal
} from "../../../../actions";

import {
  appointmentRequestStatusConfigMap,
  apptRequestStatusOptions,
  ModalTypes
} from "../../../../constants";

import {
  ReduxStateType,
  AppointmentRequest,
  NewNotification,
  AppointmentRequestsUpdatesLoading,
  Permissions,
  Staff
} from "../../../../types";

import styles from "./index.module.scss";
import { PermissionsGuard } from "../../../../hooks/usePermissions";
import StaffSelector from "./StaffSelector";
import Tooltip from "../../../ui/Tooltip";

const headers = [
  { colName: "created", content: "Created", sortable: true },
  { colName: "patient", content: "Patient", sortable: true },
  { colName: "locations", content: "Location", sortable: true },
  { colName: "reasons", content: "Reason", sortable: true },
  { colName: "timePreference", content: "Time" },
  { colName: "bookedStatus", content: "Booked Status", sortable: true },
  { colName: "requisition", content: "Req.", sortable: true },
  { colName: "assignedTo", content: "Assigned to", sortable: true },
  { colName: "chat", content: "" }
];

type AppointmentRequestFilter = "Requests" | "Archive" | "All";

type PropsType = {
  openModal: OpenModal;
  appointmentRequests: AppointmentRequest[];
  allRequestsCount: number;
  archivedRequestsCount: number;
  readyRequestsCount: number;
  staff: Array<Staff>;
  loading: boolean;
  updatesLoading: AppointmentRequestsUpdatesLoading;
  fetchAppointmentRequests: (
    data: FetchAppointmentRequestsData,
    options?: { silent: boolean }
  ) => void;
  updateAppointmentRequest: (
    id: number,
    update: UpdateAppointmentRequestData,
    onSuccess?: () => void
  ) => void;
  addNotification: (data: NewNotification) => void;
};
const getSortByForFetch = (sortBy: SortByValue) => {
  if (!sortBy) return "created_at";

  const columnNameMap: { [key: string]: string } = {
    assignedTo: "assignedStaffUserId",
    bookedStatus: "status",
    created: "created_at",
    healthcareNumber: "patientHealthcareNumber",
    locations: "locationPreference",
    patient: "patientLastName",
    requisition: "hasRequisition"
  };

  return columnNameMap[sortBy.columnName] ? columnNameMap[sortBy.columnName] : sortBy.columnName;
};

const displayFirstSlice = (fullArray: string[], maxShown: number) => {
  const notShownCount = fullArray.length - maxShown;
  return `${fullArray.slice(0, maxShown).join(", ")}${
    notShownCount > 0 ? ` +${notShownCount}` : ""
  }`;
};

const renderRequisitionCell = (
  appointmentRequest: AppointmentRequest,
  downloadRequisition: (
    requestId: number,
    uploadId: number,
    uploadIdx: number
  ) => Promise<void | null>,
  requisitionLoading: { [id: number]: boolean[] }
) => {
  const { hasRequisition, created_at: createdAt, uploadIds } = appointmentRequest;
  const expiryDate = moment(createdAt).add(30, "days");
  const isExpired = moment().isAfter(expiryDate);
  const numberOfUploads = uploadIds ? uploadIds.length : 0;
  const downloading = requisitionLoading[appointmentRequest.id]
    ? requisitionLoading[appointmentRequest.id][0]
    : false;

  if (hasRequisition && !isExpired && numberOfUploads === 1) {
    return (
      <Button
        id={`downloadRequisition-${appointmentRequest.id}`}
        inline
        onClick={() =>
          downloadRequisition(appointmentRequest.id, appointmentRequest.uploadIds[0], 0)
        }
        disabled={downloading}
      >
        <DownloadCloud size={24} />
      </Button>
    );
  }
  if (hasRequisition && !isExpired && numberOfUploads > 1) {
    return <Text size="S">Multiple</Text>;
  }
  if (hasRequisition && isExpired) {
    return <Text size="S">Expired</Text>;
  }
  return <Text size="S">None</Text>;
};

const DEFAULT_APPOINTMENTS_PER_PAGE = 25;
const POLL_INTERVAL_MS = 30000;

const AppointmentRequestsTable = ({
  openModal,
  appointmentRequests,
  allRequestsCount,
  archivedRequestsCount,
  readyRequestsCount,
  staff,
  loading,
  updatesLoading,
  fetchAppointmentRequests,
  updateAppointmentRequest,
  addNotification
}: PropsType): JSX.Element => {
  const { parsed } = useQueryString();
  const rowsPerPageLocalStorage = localStorage.getItem("appointmentRequestFilters_rowsPerPage");
  const assignedStaffUserIdsLocalStorage = localStorage.getItem(
    "appointmentRequestFilters_assignedStaffUserIds"
  );
  const token = getToken();
  const decodedToken: { userId: string } | null = token ? jwtDecode(token) : null;
  const currentUserId = decodedToken?.userId;
  const [searchParams, setSearchParams] = useSearchParams();

  const loadingState: { [id: string]: boolean[] } = {};
  appointmentRequests.forEach((apptReq) => {
    loadingState[apptReq.id] = new Array(apptReq.uploadIds.length).fill(false);
  });
  const [requisitionLoading, setRequisitionLoading] = useState<{ [id: string]: boolean[] }>(
    loadingState
  );

  const isDetailsModalOpen = Boolean(parsed.appointmentRequestId);
  const [selectedStaffUsers, setSelectedStaffUsers] = useState<string>(
    assignedStaffUserIdsLocalStorage || ""
  );
  const [selectedFilter, setSelectedFilter] = useState<AppointmentRequestFilter>(
    (parsed.activeFilter as AppointmentRequestFilter) || "Requests"
  );
  const [sortBy, setSortBy] = useState<SortByValue>(
    parsed.sortBy && parsed.sortByDirection
      ? {
          columnName: parsed.sortBy,
          direction: parsed.sortByDirection as SortByDirection
        }
      : null
  );
  const [page, setPage] = useState<number>(parsed.page ? Number.parseInt(parsed.page, 10) : 1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    rowsPerPageLocalStorage
      ? Number.parseInt(rowsPerPageLocalStorage, 10)
      : DEFAULT_APPOINTMENTS_PER_PAGE
  );

  const getTotalCount = (filter: AppointmentRequestFilter) => {
    const countMap = {
      Requests: readyRequestsCount,
      Archive: archivedRequestsCount,
      All: allRequestsCount
    };

    return countMap[filter] || 0;
  };

  const appointmentRequestFilters = {
    activeFilter: selectedFilter,
    assignedStaffUserIds: selectedStaffUsers,
    sortBy: getSortByForFetch(sortBy),
    sortByDirection: sortBy?.direction || "desc",
    page: page.toString(),
    pageRows: rowsPerPage.toString()
  };

  // start interval for silent re-fetching of appointment requests
  useInterval(
    () => {
      if (appointmentRequestFilters) {
        fetchAppointmentRequests(
          {
            activeRequestFilter: appointmentRequestFilters.activeFilter,
            assignedStaffUserIds: appointmentRequestFilters.assignedStaffUserIds,
            requestSortBy: appointmentRequestFilters.sortBy,
            requestSortDirection: appointmentRequestFilters.sortByDirection,
            requestCurrentPage: (page - 1).toString(),
            requestPageSize: appointmentRequestFilters.pageRows.toString()
          },
          { silent: true }
        );
      }
    },
    [selectedFilter, selectedStaffUsers, sortBy, page, rowsPerPage],
    POLL_INTERVAL_MS
  );

  // fetch appt requests
  useEffect(() => {
    updateQueryString(appointmentRequestFilters, setSearchParams);
    fetchAppointmentRequests({
      activeRequestFilter: appointmentRequestFilters.activeFilter,
      assignedStaffUserIds: appointmentRequestFilters.assignedStaffUserIds,
      requestSortBy: appointmentRequestFilters.sortBy,
      requestSortDirection: appointmentRequestFilters.sortByDirection,
      requestCurrentPage: (page - 1).toString(),
      requestPageSize: rowsPerPage.toString()
    });
  }, [selectedFilter, selectedStaffUsers, sortBy, page, rowsPerPage]);

  // update localStorage on rows per page changes
  useEffect(() => {
    localStorage.setItem("appointmentRequestFilters_rowsPerPage", rowsPerPage.toString());
    localStorage.setItem("appointmentRequestFilters_assignedStaffUserIds", selectedStaffUsers);
  }, [rowsPerPage, selectedStaffUsers]);

  const openDetailsModal = (appointmentRequestId: number): void => {
    updateQueryString({ appointmentRequestId: appointmentRequestId.toString() }, setSearchParams);
  };

  const closeDetailsModal = (): void => {
    updateQueryString({ appointmentRequestId: undefined }, setSearchParams);
    fetchAppointmentRequests(
      {
        activeRequestFilter: appointmentRequestFilters.activeFilter,
        assignedStaffUserIds: appointmentRequestFilters.assignedStaffUserIds,
        requestSortBy: appointmentRequestFilters.sortBy,
        requestSortDirection: appointmentRequestFilters.sortByDirection,
        requestCurrentPage: (page - 1).toString(),
        requestPageSize: rowsPerPage.toString()
      },
      { silent: true }
    );
  };

  const onStaffUsersChange = (assignedStaffUserIds: string) => {
    setPage(1);
    setSelectedStaffUsers(assignedStaffUserIds);
  };

  const downloadRequisition = async (requestId: number, uploadId: number, uploadIdx: number) => {
    setRequisitionLoading((state) => {
      let newState = state[requestId];
      if (!newState || !newState[uploadIdx]) newState = new Array(uploadIdx).fill(false);
      newState[uploadIdx] = true;
      return { ...state, [requestId]: newState };
    });
    const { success, url } = await getRequisitionUrl(requestId, uploadId);
    setRequisitionLoading((state) => {
      const newState = state[requestId];
      newState[uploadIdx] = false;
      return { ...state, [requestId]: newState };
    });
    if (!success || !url) {
      return addNotification({
        type: "error",
        title: "Failed to get download url",
        subtitle: "Please try again",
        autoDismiss: true
      });
    }

    // https://stackoverflow.com/questions/49040247/download-binary-file-with-axios
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.setAttribute("download", `request-attachment-${requestId}-${uploadId}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return null;
  };

  const handleFilterChange = (filterValue: AppointmentRequestFilter) => {
    setPage(1);
    setSelectedFilter(filterValue);
  };

  const handleSortChange = (sortByValue: SortByValue) => {
    setPage(1);
    setSortBy(sortByValue);
  };

  const handleRowsPerPageChange = (rowsPerPageSelection: number) => {
    setPage(1);
    setRowsPerPage(rowsPerPageSelection);
  };

  const updateApptReq = (requestId: number, update: UpdateAppointmentRequestData) => {
    updateAppointmentRequest(requestId, update);
  };

  const currentStaffUser = currentUserId
    ? staff?.find((staffUser) => staffUser.userId === parseInt(currentUserId, 10))
    : null;

  const currentStaffUserOption = currentStaffUser
    ? [
        {
          label: `Me (${currentStaffUser.firstName} ${currentStaffUser.lastName})`,
          value: currentStaffUser.userId.toString()
        }
      ]
    : [];

  const staffOptions = staff
    ? [
        ...currentStaffUserOption,
        ...staff
          .filter((staffUser) => {
            return currentStaffUser
              ? staffUser.userId !== currentStaffUser.userId && !!staffUser.active
              : !!staffUser.active;
          })
          .map((staffUser) => ({
            label: `${staffUser.firstName} ${staffUser.lastName}`,
            value: staffUser.userId.toString()
          }))
          .sort((a, b) => {
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;
            return 0;
          })
      ]
    : [];

  const rowData = appointmentRequests.map((appointmentRequest: AppointmentRequest) => {
    return {
      __onRowClick: () => openDetailsModal(appointmentRequest.id),
      created: (
        <div className={styles.TableCell}>
          <Clock size={20} />
          <div className={cx(styles.TableCellIconText, styles.TableCellCreated)}>
            <Text align="right" component="span" size="S">
              <span>{formatDate(appointmentRequest.created_at, "timeOnly")}</span>
            </Text>
            <Text align="right" component="span" size="S">
              <span>{formatDate(appointmentRequest.created_at, "dateOnly")}</span>
            </Text>
          </div>
        </div>
      ),
      patient: (
        <div className={styles.TableCell}>
          <User size={18} />
          <div className={styles.TableCellPatientPhoneGroup}>
            <Text
              id={`patientName-${appointmentRequest.id}`}
              className={styles.TableCellPatientName}
              bold
              align="left"
              component="span"
              size="M"
            >
              {`${appointmentRequest.patientFirstName || ""} ${
                appointmentRequest.patientLastName || ""
              }`}
            </Text>
            <Text align="left" component="span" size="S">
              {formatPhone(appointmentRequest.patientNumber)}
            </Text>
            {appointmentRequest.patientHealthcareNumber && (
              <Text align="left" component="span" size="S">
                {appointmentRequest.patientHealthcareNumber}
              </Text>
            )}
          </div>
        </div>
      ),
      locations: (
        <div className={styles.TableCell}>
          <Text align="left" component="span" size="S">
            {appointmentRequest.locationPreference
              ? displayFirstSlice(appointmentRequest.locationPreference, 2)
              : ""}
          </Text>
        </div>
      ),
      reasons: (
        <div className={styles.TableCell}>
          <Text align="left" component="span" size="S">
            {appointmentRequest.reasons ? displayFirstSlice(appointmentRequest.reasons, 2) : ""}
          </Text>
        </div>
      ),
      timePreference: (
        <div className={styles.TableCell}>
          <Text align="left" component="span" size="S">
            {appointmentRequest.dateTimePreference
              ? displayFirstSlice(
                  flatten(
                    appointmentRequest.dateTimePreference.map((dtp) => {
                      const displayValue = dtp.date || dtp.time || dtp.option || "";
                      return Array.isArray(displayValue) ? displayValue : [displayValue];
                    })
                  ),
                  2
                )
              : ""}
          </Text>
        </div>
      ),
      bookedStatus: (
        <StopClickPropagation>
          <div className={styles.TableCell}>
            <Dropdown
              id="apptReqStatus"
              className={styles.DropdownStatus}
              onChange={(selectedStatus) =>
                updateApptReq(appointmentRequest.id, {
                  status: selectedStatus
                })
              }
              value={appointmentRequest.status}
              options={apptRequestStatusOptions}
              configMap={appointmentRequestStatusConfigMap}
              loading={updatesLoading[appointmentRequest.id]?.status}
            />
          </div>
        </StopClickPropagation>
      ),
      requisition: (
        <StopClickPropagation>
          <div className={cx(styles.TableCell, styles.ChatIcon)}>
            {renderRequisitionCell(appointmentRequest, downloadRequisition, requisitionLoading)}
          </div>
        </StopClickPropagation>
      ),
      assignedTo: (
        <StopClickPropagation>
          <div className={cx(styles.TableCell, styles.Assign)}>
            <Dropdown
              id={`assignBtn-${appointmentRequest.id}`}
              className={styles.DropdownStatus}
              onChange={(selectedStaffUserId) =>
                updateApptReq(appointmentRequest.id, {
                  assignedStaffUserId: selectedStaffUserId !== "None" ? selectedStaffUserId : null
                })
              }
              configMap={{
                None: "gray"
              }}
              defaultColor="blue"
              value={appointmentRequest.assignedStaffUserId?.toString() || "None"}
              options={[{ label: "None", value: "None" }, ...staffOptions]}
              loading={updatesLoading && updatesLoading[appointmentRequest.id]?.assignedStaffUserId}
            />
          </div>
        </StopClickPropagation>
      ),
      chat: (
        <StopClickPropagation>
          <div className={cx(styles.TableCell, styles.ChatIcon)}>
            <Button
              id={`apptReqDetailsBtn-${appointmentRequest.id}`}
              inline
              onClick={() => openDetailsModal(appointmentRequest.id)}
              className={styles.DetailsButton}
            >
              <Document size={22} />
            </Button>
          </div>
        </StopClickPropagation>
      )
    };
  });

  return (
    <>
      <div className={styles.Row}>
        <div className={styles.FiltersSection}>
          <div className={styles.Filters}>
            <Filters
              selectedFilter={selectedFilter}
              onFilterChange={(selected) =>
                handleFilterChange(selected as AppointmentRequestFilter)
              }
              filterOptions={[
                {
                  label: "Requests",
                  value: "Requests",
                  count: readyRequestsCount
                },
                {
                  label: "Archived",
                  value: "Archive",
                  count: archivedRequestsCount
                },
                {
                  label: "All",
                  value: "All",
                  count: allRequestsCount
                }
              ]}
            />
          </div>

          <StaffSelector
            selectedStaffUsers={selectedStaffUsers}
            onStaffUsersChange={onStaffUsersChange}
            staffUserOptions={staffOptions || []}
            placeholder="All assignees"
          />
          <Button
            className={styles.RefreshButton}
            inline
            onClick={() => {
              fetchAppointmentRequests({
                activeRequestFilter: appointmentRequestFilters.activeFilter,
                assignedStaffUserIds: appointmentRequestFilters.assignedStaffUserIds,
                requestSortBy: appointmentRequestFilters.sortBy,
                requestSortDirection: appointmentRequestFilters.sortByDirection,
                requestCurrentPage: (page - 1).toString(),
                requestPageSize: rowsPerPage.toString()
              });
            }}
          >
            <Tooltip
              contentClassName={styles.TooltipContent}
              position="bottomRight"
              icon={<Refresh size={20} />}
              size="S"
            >
              Refresh
            </Tooltip>
          </Button>
        </div>

        <PermissionsGuard
          requiredPermissions={[Permissions.SEND_APPOINTMENT_REQUEST_DIRECT_MESSAGE]}
        >
          <Button
            id="sendApptRequestLink"
            className={styles.ApptRequestLinkButton}
            secondary
            onClick={async () => {
              openModal(ModalTypes.SEND_APPOINTMENT_REQUEST_DIRECT_MESSAGE, { staffOptions });
            }}
          >
            Send Request
          </Button>
        </PermissionsGuard>
      </div>

      {loading ? (
        <Loader screen />
      ) : (
        <TableGrid
          headers={headers}
          rows={rowData}
          onSortChange={handleSortChange}
          sortBy={sortBy}
          emptyContent={
            <div className={styles.NoAppointments}>
              <Heading>There are no appointments</Heading> <EmptyContentImage />
            </div>
          }
          showRowFocus
          page={page}
          onPageChange={setPage}
          totalPages={Math.ceil(getTotalCount(selectedFilter) / rowsPerPage)}
          maxPageRows={rowsPerPage}
          rowsPerPageOptions={[25, 50, 100]}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
      <AppointmentRequestDetails
        isModalOpen={isDetailsModalOpen}
        closeModal={closeDetailsModal}
        currentUserId={currentUserId}
        appointmentRequest={
          appointmentRequests && parsed.appointmentRequestId
            ? appointmentRequests.find(
                (apptReq) => apptReq.id.toString() === parsed.appointmentRequestId
              )
            : undefined
        }
        downloadRequisition={downloadRequisition}
        requisitionLoading={requisitionLoading}
      />
    </>
  );
};

const mapStateToProps = ({ appointmentRequestsInfo, organizationData }: ReduxStateType) => {
  return {
    appointmentRequests: appointmentRequestsInfo.appointmentRequests,
    allRequestsCount: appointmentRequestsInfo.allRequestsCount,
    archivedRequestsCount: appointmentRequestsInfo.archivedRequestsCount,
    readyRequestsCount: appointmentRequestsInfo.readyRequestsCount,
    loading: appointmentRequestsInfo.fetchLoading,
    updatesLoading: appointmentRequestsInfo.updatesLoading,
    staff: organizationData?.organizationData?.staff
  };
};

export default connect(mapStateToProps, {
  openModal: OpenModalAction,
  fetchAppointmentRequests: fetchAppointmentRequestsAction,
  updateAppointmentRequest: updateAppointmentRequestAction,
  addNotification: addNotificationAction
})(AppointmentRequestsTable);
