import React, { useMemo } from "react";
import cx from "classnames";

import { useFormApi, useFormState } from "informed";
import Button, { ButtonRow } from "../../../../../../ui/Button";
import Heading from "../../../../../../ui/Heading";
import Text from "../../../../../../ui/Text";
import RestrictTouchpointSummary from "../../RestrictTouchpointSummary";
import { CheckboxGroup, SelectInput, TextInput, ToggleInput } from "../../../../../../ui/Input";

import { PermissionsGuard } from "../../../../../../../hooks/usePermissions";
import { AppointmentTouchpointType, OpenModal } from "../../../../../../../actions";
import { generateReasonLabel } from "../../../../../../../utils/generateReasonLabel";
import handleMediumSpecificTags from "../../../../../../../utils/handleMediumSpecificTags";
import { isRequired, isInteger, isGreaterThan } from "../../../../../../../utils/validators";
import { AdminModalTypes, TriggerUnitFormOptions } from "../../../../../../../constants";
import { PreviewMediumTypes } from ".";
import {
  MessageTemplate,
  MessageTemplateMedium,
  Permissions,
  Reason,
  TouchpointExclusionCases,
  TouchpointSettingRangeTime,
  Option
} from "../../../../../../../types";

import styles from "./index.module.scss";

type AddTouchpointFormState = {
  type: AppointmentTouchpointType | "";
  automationIds: string[];
  templateId: string;
  multiTemplateId: string;
  triggerValue: string;
  triggerUnit: string;
  chatFlowIds: number[];
  exclusionHours: number;
  exclusionHoursOption: string;
  exclusionHoursUnit: string;
  exclusionHoursActive: boolean;
  note: string;
  excludesApptsWhen?: string;
  settings: {
    exclude?: {
      patientHasOtherAppt?: {
        active: boolean;
        rangeStart: TouchpointSettingRangeTime;
        rangeEnd: TouchpointSettingRangeTime;
      };
    };
  };
  reasonIds?: string[];
};

type PropsType = {
  automationId?: number;
  inModal: boolean;
  messageTemplates: Array<MessageTemplate>;
  reasons: Array<Reason>;
  messageTemplateOptions: Option[];
  addToMultiAutomationsMode: boolean;
  messageConfigurationOptions: Option[];
  formDisabled: boolean;
  previewMedium: PreviewMediumTypes;
  updateError: () => void;
  findJourneysWithMatchingTouchpoints: (
    automationIds: string[],
    values: AddTouchpointFormState
  ) => {
    id: number | undefined;
    displayName: string | undefined;
  }[];
  automationOptions: Option[];
  setPreviewMedium: (previewMed: PreviewMediumTypes) => void;
  showMultiTemplate: boolean;
  setShowMultiTemplate: (showMultiTemp: boolean) => void;
  chatOptions: Option[];
  multiPreviewMedium: PreviewMediumTypes;
  setMultiPreviewMedium: (multiPreviewMed: PreviewMediumTypes) => void;
  exclusionHoursUnitOptions: Option[];
  exclusionHoursOptions: Option[];
  touchpointSettingRangeTimeUnitOptions: Option[];
  touchpointSettingRangeTimeTypeOptions: Option[];
  errorMessage: string;
  excludeAppointmentsOptions: Option[];
  openModal: OpenModal;
};

const requiresScheduleValues = (type?: string): boolean => {
  return (
    type === AppointmentTouchpointType.BEFORE_APPOINTMENT_START ||
    type === AppointmentTouchpointType.AFTER_APPOINTMENT_START
  );
};
const supportsExclusionHours = (type?: string): boolean => {
  return (
    type === AppointmentTouchpointType.BOOK_EVENT || type === AppointmentTouchpointType.CANCEL_EVENT
  );
};
const supportsExcludeAppointments = (type?: string): boolean => {
  return type === AppointmentTouchpointType.AFTER_APPOINTMENT_START;
};

const triggerValueValidator = isInteger("Please input a valid number");
const templateValidator = isInteger("Please select from message templates");
const typeValidator = isRequired("Please select from types");
const triggerUnitValidator = isRequired("Please select time unit");
const automationIdsValidator = isRequired("Please select from journeys");
const exclusionValueValidator = isGreaterThan(0, "Please enter a number greater than zero.");

const AddTouchpointForm = ({
  automationId,
  inModal = false,
  messageTemplates,
  reasons,
  messageTemplateOptions,
  addToMultiAutomationsMode,
  messageConfigurationOptions,
  formDisabled,
  previewMedium,
  setPreviewMedium,
  updateError,
  findJourneysWithMatchingTouchpoints,
  automationOptions,
  showMultiTemplate,
  setShowMultiTemplate,
  chatOptions,
  multiPreviewMedium,
  setMultiPreviewMedium,
  exclusionHoursUnitOptions,
  exclusionHoursOptions,
  touchpointSettingRangeTimeUnitOptions,
  touchpointSettingRangeTimeTypeOptions,
  errorMessage,
  excludeAppointmentsOptions,
  openModal
}: PropsType) => {
  const formState = useFormState();
  const formApi = useFormApi();
  const values = formState.values as AddTouchpointFormState;
  const hideScheduleInputs = !requiresScheduleValues(values.type);
  const showExclusionHours = supportsExclusionHours(values.type);
  const showExcludeAppointments = supportsExcludeAppointments(values.type);
  const restrictTouchpointActive =
    (showExcludeAppointments && !!values.settings?.exclude?.patientHasOtherAppt?.active) ||
    (showExclusionHours && !!values.exclusionHoursActive);

  const automationIds = automationId ? [automationId?.toString()] : values.automationIds || [];

  const journeysWithMatchingTouchpoints = useMemo(() => {
    return findJourneysWithMatchingTouchpoints(automationIds, values);
  }, [automationIds, values.triggerUnit, values.triggerValue, values.type]);

  const displaySelectedMessageTemplate = (templateId: string, medium: string) => {
    const messageTemplate = messageTemplates.find(
      (template) => template.id === parseInt(templateId, 10)
    );

    const previewText = messageTemplate?.payloadContent ?? "";
    const subject = messageTemplate?.subject ?? "A message from [place]";

    const isEmail = medium === MessageTemplateMedium.email;

    return isEmail ? (
      <>
        <Text className={styles.PreviewMessageSubject}>{subject}</Text>
        Hello,
        <br />
        {handleMediumSpecificTags(previewText, medium)}
      </>
    ) : (
      <>{handleMediumSpecificTags(previewText, medium)}</>
    );
  };

  return (
    <>
      <Heading size={inModal ? "L" : "M"}>Add touchpoint</Heading>
      <SelectInput
        fieldName="type"
        label="Touchpoint Type"
        options={messageConfigurationOptions}
        placeholder="Select Type"
        validate={typeValidator}
        disabled={formDisabled}
      />

      {!hideScheduleInputs && (
        <>
          <Heading size="META">Schedule</Heading>
          <div className={styles.ScheduleGroupRow}>
            <TextInput
              id="triggerValue"
              fieldName="triggerValue"
              validate={triggerValueValidator}
              disabled={formDisabled}
              customOnChange={updateError}
            />
            <SelectInput
              fieldName="triggerUnit"
              options={TriggerUnitFormOptions}
              placeholder="Select time unit"
              disabled={formDisabled}
              validate={triggerUnitValidator}
              customOnChange={updateError}
            />
          </div>
        </>
      )}
      {automationId && journeysWithMatchingTouchpoints.length > 0 && (
        <Text size="S" className={styles.OverwriteWarning}>
          You will replace an existing touchpoint in this journey.
        </Text>
      )}
      {addToMultiAutomationsMode && (
        <CheckboxGroup
          fieldName="automationIds"
          label="Add Touchpoint to journey(s)"
          validate={automationIdsValidator}
          options={automationOptions}
          showCheckAll={automationOptions.length > 9}
          disabled={formDisabled}
          warningMessage={
            !automationId && journeysWithMatchingTouchpoints.length > 0
              ? `You will replace an existing touchpoint in:${journeysWithMatchingTouchpoints.map(
                  (journey) => ` ${journey.displayName}`
                )}`
              : ""
          }
        />
      )}

      <Heading size="S">Message</Heading>
      <div className={styles.MessageRow}>
        <SelectInput
          fieldName="templateId"
          label="Select Message Template"
          options={messageTemplateOptions}
          placeholder="Select Message Template"
          validate={templateValidator}
          disabled={formDisabled}
        />
        <PermissionsGuard
          requiredPermissions={[
            Permissions.CREATE_AUTOMATED_MESSAGE_CONFIGURATION,
            Permissions.CREATE_MESSAGE_TEMPLATE
          ]}
        >
          <Button
            inline
            onClick={() =>
              openModal(AdminModalTypes.ADD_SMS_MESSAGE_TEMPLATE, {
                inTouchpointForm: true,
                onSuccess: (templateId: number) => {
                  formApi.setValue("templateId", templateId?.toString());
                }
              })
            }
            className={styles.MessageCreateButton}
          >
            Create
          </Button>
        </PermissionsGuard>
      </div>
      {values.templateId && (
        <>
          <Heading size="META">Preview Message Template</Heading>

          <div className={styles.Tabs}>
            <Button
              id="touchpoint-mode-sms"
              inline
              className={cx(styles.Tab, {
                [styles.TabActive]: previewMedium === MessageTemplateMedium.sms
              })}
              onClick={() => setPreviewMedium(MessageTemplateMedium.sms)}
            >
              SMS
            </Button>
            <Button
              id="touchpoint-mode-email"
              inline
              className={cx(styles.Tab, {
                [styles.TabActive]: previewMedium === MessageTemplateMedium.email
              })}
              onClick={() => setPreviewMedium(MessageTemplateMedium.email)}
            >
              Email
            </Button>
            <PermissionsGuard
              requiredPermissions={[
                Permissions.CREATE_AUTOMATED_MESSAGE_CONFIGURATION,
                Permissions.UPDATE_MESSAGE_TEMPLATE
              ]}
            >
              <Button
                inline
                onClick={() =>
                  openModal(AdminModalTypes.EDIT_MESSAGE_TEMPLATE, {
                    automationId,
                    messageTemplateId: parseInt(values.templateId),
                    onSuccess: (templateId: number) => {
                      formApi.setValue("templateId", templateId?.toString());
                    }
                  })
                }
                className={styles.MessageEditButton}
              >
                Edit
              </Button>
            </PermissionsGuard>
          </div>
          <div className={styles.PreviewMessage}>
            {displaySelectedMessageTemplate(values.templateId, previewMedium)}
          </div>
        </>
      )}

      {!showMultiTemplate && (
        <PermissionsGuard
          requiredPermissions={[Permissions.CREATE_AUTOMATED_MESSAGE_CONFIGURATION]}
        >
          <Button
            inline
            onClick={() => setShowMultiTemplate(true)}
            className={styles.MultiTemplateButton}
          >
            + Multi Message Template
          </Button>
        </PermissionsGuard>
      )}

      {showMultiTemplate && (
        <>
          <div className={styles.MessageRow}>
            <SelectInput
              fieldName="multiTemplateId"
              label="Select Multi Message Template (optional)"
              options={[{ label: "None", value: "" }, ...messageTemplateOptions]}
              placeholder="Select Message Template"
              disabled={formDisabled}
            />
            <PermissionsGuard
              requiredPermissions={[
                Permissions.CREATE_AUTOMATED_MESSAGE_CONFIGURATION,
                Permissions.CREATE_MESSAGE_TEMPLATE
              ]}
            >
              <Button
                inline
                onClick={() =>
                  openModal(AdminModalTypes.ADD_SMS_MESSAGE_TEMPLATE, {
                    inTouchpointForm: true,
                    onSuccess: (templateId: number) => {
                      formApi.setValue("multiTemplateId", templateId.toString());
                    }
                  })
                }
                className={styles.MessageCreateButton}
              >
                Create
              </Button>
            </PermissionsGuard>
          </div>
          {values.multiTemplateId && (
            <>
              <Heading size="META">Preview Multi Message Template</Heading>

              <div className={styles.Tabs}>
                <Button
                  id="touchpoint-mode-multi-sms"
                  inline
                  className={cx(styles.Tab, {
                    [styles.TabActive]: multiPreviewMedium === MessageTemplateMedium.sms
                  })}
                  onClick={() => setMultiPreviewMedium(MessageTemplateMedium.sms)}
                >
                  SMS
                </Button>
                <Button
                  id="touchpoint-mode-multi-email"
                  inline
                  className={cx(styles.Tab, {
                    [styles.TabActive]: multiPreviewMedium === MessageTemplateMedium.email
                  })}
                  onClick={() => setMultiPreviewMedium(MessageTemplateMedium.email)}
                >
                  Email
                </Button>
                <PermissionsGuard
                  requiredPermissions={[
                    Permissions.CREATE_AUTOMATED_MESSAGE_CONFIGURATION,
                    Permissions.UPDATE_MESSAGE_TEMPLATE
                  ]}
                >
                  <Button
                    inline
                    onClick={() =>
                      openModal(AdminModalTypes.EDIT_MESSAGE_TEMPLATE, {
                        automationId,
                        messageTemplateId: parseInt(values.multiTemplateId),
                        onSuccess: (templateId: number) => {
                          formApi.setValue("multiTemplateId", templateId.toString());
                        }
                      })
                    }
                    className={styles.MessageEditButton}
                  >
                    Edit
                  </Button>
                </PermissionsGuard>
              </div>

              <div className={styles.PreviewMessage}>
                {displaySelectedMessageTemplate(values.multiTemplateId, multiPreviewMedium)}
              </div>
            </>
          )}
        </>
      )}

      <Heading size="S">Chat Flows</Heading>
      <SelectInput
        fieldName="chatFlowIds"
        label="Select Chat Flows"
        options={chatOptions}
        placeholder="None"
        isMulti
        disabled={formDisabled}
      />

      {showExclusionHours && (
        <div>
          <div className={styles.RestrictTouchpoint}>
            <Heading size="S">Restrict touchpoint</Heading>
            <ToggleInput
              fieldName="exclusionHoursActive"
              labelChecked="On"
              labelUnchecked="Off"
              disabled={formDisabled}
            />
          </div>
          {restrictTouchpointActive && (
            <div>
              <Heading size="META">Don't send message when:</Heading>
              <div className={styles.ExclusionHoursRow}>
                <SelectInput
                  fieldName="exclusionHoursOption"
                  options={exclusionHoursOptions}
                  disabled={formDisabled}
                />
                <TextInput
                  fieldName="exclusionHours"
                  placeholder="0"
                  validate={exclusionValueValidator}
                  disabled={formDisabled}
                  customOnChange={updateError}
                />
                <SelectInput
                  fieldName="exclusionHoursUnit"
                  options={exclusionHoursUnitOptions}
                  disabled={formDisabled}
                />
              </div>
              <RestrictTouchpointSummary
                exclusionHours={values.exclusionHours}
                exclusionHoursOption={values.exclusionHoursOption}
                exclusionHoursUnit={values.exclusionHoursUnit}
              />
            </div>
          )}
        </div>
      )}

      {showExcludeAppointments && (
        <div>
          <div className={styles.RestrictTouchpoint}>
            <Heading size="S">Restrict touchpoint</Heading>
            <ToggleInput
              fieldName="settings.exclude.patientHasOtherAppt.active"
              labelChecked="On"
              labelUnchecked="Off"
              disabled={formDisabled}
            />
          </div>
          {restrictTouchpointActive && (
            <div>
              <SelectInput
                fieldName="excludesApptsWhen"
                label="Don't send message when:"
                options={excludeAppointmentsOptions}
                disabled={formDisabled}
              />
              {formState.values.excludesApptsWhen ===
                TouchpointExclusionCases.PATIENT_OTHER_APPT && (
                <div>
                  <Heading size="META" bold component="h4">
                    Time range start
                  </Heading>
                  <div className={styles.SettingRangeTimeRow}>
                    <SelectInput
                      fieldName="settings.exclude.patientHasOtherAppt.rangeStart.type"
                      options={touchpointSettingRangeTimeTypeOptions}
                      disabled={formDisabled}
                    />
                    <TextInput
                      fieldName="settings.exclude.patientHasOtherAppt.rangeStart.value"
                      placeholder="Enter Value"
                      disabled={formDisabled}
                    />
                    <SelectInput
                      fieldName="settings.exclude.patientHasOtherAppt.rangeStart.unit"
                      options={touchpointSettingRangeTimeUnitOptions}
                      placeholder="Select Unit"
                      disabled={formDisabled}
                    />
                  </div>
                  <Heading size="META" bold component="h4">
                    Time range end
                  </Heading>
                  <div className={styles.SettingRangeTimeRow}>
                    <SelectInput
                      fieldName="settings.exclude.patientHasOtherAppt.rangeEnd.type"
                      options={touchpointSettingRangeTimeTypeOptions}
                      disabled={formDisabled}
                    />
                    <TextInput
                      fieldName="settings.exclude.patientHasOtherAppt.rangeEnd.value"
                      placeholder="Enter Value"
                      disabled={formDisabled}
                    />
                    <SelectInput
                      fieldName="settings.exclude.patientHasOtherAppt.rangeEnd.unit"
                      options={touchpointSettingRangeTimeUnitOptions}
                      placeholder="Select Unit"
                      disabled={formDisabled}
                    />
                  </div>
                  {reasons && reasons.length > 0 && (
                    <CheckboxGroup
                      fieldName="reasonIds"
                      label="Other appointment reason(s)"
                      showCheckAll={reasons.length > 2}
                      options={reasons.map((reason) => ({
                        label: generateReasonLabel(reason),
                        value: reason.id?.toString()
                      }))}
                      disabled={formDisabled}
                      searchPlaceholder="Find a reason"
                    />
                  )}
                  <RestrictTouchpointSummary
                    excludesApptsWhen={
                      excludeAppointmentsOptions.find((option) => {
                        return option.value === formState.values.excludesApptsWhen;
                      })?.label
                    }
                    settings={values.settings}
                    reasonIds={values.reasonIds}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <div className={styles.ErrorMessage}>{errorMessage}</div>
      <PermissionsGuard requiredPermissions={[Permissions.CREATE_AUTOMATED_MESSAGE_CONFIGURATION]}>
        <ButtonRow>
          <Button id="createScheduleBasedMessage" type="submit" disabled={formDisabled}>
            Create
          </Button>
        </ButtonRow>
      </PermissionsGuard>
    </>
  );
};

export default AddTouchpointForm;
