import {
  FETCH_BILLING_CONFIG,
  CREATE_CHARGEOVER_SUBSCRIPTION,
  UPDATE_PAYMENT_METHOD,
  FETCH_ALL_BILLING_CUSTOMERS,
  FETCH_ORGANIZATION_SUBSCRIPTIONS,
  FETCH_PRODUCT_LIST
} from "../constants";

import {
  FetchBillingConfigurationAction,
  BillingActionType,
  CreateChargeOverSubscriptionAction,
  UpdatePaymentMethodAction,
  FetchAllBillingCustomersAction,
  FetchOrganizationSubscriptionsAction,
  FetchProductListAction
} from "../actions/billing";
import {
  ActionStatus,
  BillingCustomer,
  BillingSubscription,
  Invoice,
  ChargeOverProductPlan
} from "../types";

export type BillingAction =
  | FetchBillingConfigurationAction
  | FetchAllBillingCustomersAction
  | FetchOrganizationSubscriptionsAction
  | CreateChargeOverSubscriptionAction
  | UpdatePaymentMethodAction
  | FetchProductListAction;

export type BillingReduxState = {
  billing?: BillingActionType;
  billingCustomers?: BillingCustomer[];
  organizationSubscriptions?: BillingSubscription[];
  billingLoading: boolean;
  billingConfigLoading: boolean;
  billingCustomersLoading: boolean;
  organizationSubscriptionsLoading: boolean;
  proratedInvoice?: Invoice;
  subscriptionId?: number;
  paymentLoading: boolean;
  products?: ChargeOverProductPlan[];
  productsLoading: boolean;
};

const initialState: BillingReduxState = {
  billing: undefined,
  billingCustomers: undefined,
  organizationSubscriptions: undefined,
  billingLoading: false,
  billingConfigLoading: false,
  billingCustomersLoading: false,
  organizationSubscriptionsLoading: false,
  proratedInvoice: undefined,
  subscriptionId: undefined,
  paymentLoading: false,
  products: undefined,
  productsLoading: false
};

export const billingReducer = (state = initialState, action: BillingAction): BillingReduxState => {
  const { type } = action;
  switch (type) {
    case FETCH_BILLING_CONFIG: {
      const { status, payload } = action as FetchBillingConfigurationAction;
      return {
        ...state,
        billing: status === ActionStatus.success ? payload : undefined,
        billingConfigLoading: status === ActionStatus.loading
      };
    }
    case FETCH_ALL_BILLING_CUSTOMERS: {
      const { status, payload } = action as FetchAllBillingCustomersAction;
      return {
        ...state,
        billingCustomers: status === ActionStatus.success ? payload : undefined,
        billingCustomersLoading: status === ActionStatus.loading
      };
    }
    case FETCH_ORGANIZATION_SUBSCRIPTIONS: {
      const { status, payload } = action as FetchOrganizationSubscriptionsAction;
      return {
        ...state,
        organizationSubscriptions: status === ActionStatus.success ? payload : undefined,
        organizationSubscriptionsLoading: status === ActionStatus.loading
      };
    }
    case CREATE_CHARGEOVER_SUBSCRIPTION: {
      const { status, payload } = action as CreateChargeOverSubscriptionAction;
      return {
        ...state,
        billing:
          status === ActionStatus.success && payload
            ? { setting: payload.billingConfig, parentBillingConfig: undefined }
            : state.billing,
        proratedInvoice:
          status === ActionStatus.success ? payload?.proratedInvoice : state.proratedInvoice,
        billingLoading: status === ActionStatus.loading,
        subscriptionId:
          status === ActionStatus.success ? payload?.subscriptionId : state.subscriptionId
      };
    }
    case UPDATE_PAYMENT_METHOD: {
      const { status, payload } = action as UpdatePaymentMethodAction;
      return {
        ...state,
        paymentLoading: status === ActionStatus.loading,
        subscriptionId:
          status === ActionStatus.success ? payload?.subscriptionId : state.subscriptionId
      };
    }
    case FETCH_PRODUCT_LIST: {
      const { status, payload } = action as FetchProductListAction;
      return {
        ...state,
        productsLoading: status === ActionStatus.loading,
        products: status === ActionStatus.success && payload ? payload : state.products
      };
    }

    default:
      return state;
  }
};
