import React from "react";
import cx from "classnames";

import styles from "./SortableHeader.module.scss";

export type SortByDirection = "asc" | "desc" | "none";
export type SortByValue = {
  columnName: string;
  direction: SortByDirection;
} | null;

type PropsType = {
  columnName: string;
  sortBy?: SortByValue;
  content?: React.ReactNode;
  sortable?: boolean;
  onSortChange?: (sortByValue: SortByValue) => void;
};

const SORT_BY_DIRECTIONS: SortByDirection[] = ["none", "asc", "desc"];

const SortableHeader = ({
  columnName,
  content,
  sortable = false,
  onSortChange,
  sortBy
}: PropsType) => {
  const active = sortBy && sortBy.columnName === columnName;
  const direction = sortBy ? sortBy.direction : null;

  const sortBySelection = () => {
    if (onSortChange) {
      const currentDirectionIndex = direction && active ? SORT_BY_DIRECTIONS.indexOf(direction) : 0;
      const nextDirectionIndex = (currentDirectionIndex + 1) % SORT_BY_DIRECTIONS.length;
      const nextDirection = SORT_BY_DIRECTIONS[nextDirectionIndex];
      onSortChange(nextDirection !== "none" ? { columnName, direction: nextDirection } : null);
    }
  };

  return (
    <button
      type="button"
      className={cx(styles.Header)}
      onClick={sortable ? sortBySelection : undefined}
    >
      <span
        className={cx(styles.Content, {
          [styles.ContentSortable]: sortable,
          [styles.ContentSortableAsc]: active && direction === "asc",
          [styles.ContentSortableDesc]: active && direction === "desc"
        })}
      >
        {content}
      </span>
    </button>
  );
};

export default SortableHeader;
