import { InputValue } from "../../types";

export const hasNoOpenBrackets = (message?: string) => (value: InputValue) => {
  const stringValue = value ? value.toString() : "";
  const stack: { char: string; index: number }[] = [];
  const bracketPairs: { [key: string]: string } = {
    "(": ")",
    "[": "]",
    "{": "}"
  };

  let errorMessage = "";

  for (let i = 0; i < stringValue.length; i += 1) {
    const char = stringValue[i];

    if (bracketPairs[char]) {
      stack.push({ char, index: i });
    } else if (Object.values(bracketPairs).includes(char)) {
      const lastBracket = stack.pop();

      if (!lastBracket) {
        errorMessage += `Missing opening bracket for ${stringValue.substring(i, i + 7)}... `;
      } else if (bracketPairs[lastBracket.char] !== char) {
        errorMessage += `Mismatched bracket at ${stringValue.substring(
          lastBracket.index,
          lastBracket.index + 7
        )}... `;
      }
    }
  }

  if (stack.length > 0) {
    stack.map((openBracket) => {
      errorMessage += `Missing closing bracket for ${stringValue.substring(
        openBracket.index,
        openBracket.index + 7
      )}... `;
    });
  }

  return errorMessage ? message || errorMessage : undefined;
};
