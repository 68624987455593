import React from "react";
import cx from "classnames";

import { useFormApi, useFormState } from "informed";
import styles from "./index.module.scss";
import Heading from "../../../../ui/Heading";
import Button from "../../../../ui/Button";
import Card, { CardSection } from "../../../../ui/Card";
import Text from "../../../../ui/Text";
import {
  FormGrid,
  TextInput,
  ToggleInput,
  SelectInput,
  UploadInput,
  BaseInput,
  DateSelectInput
} from "../../../../ui/Input";

import { PermissionsGuard } from "../../../../../hooks/usePermissions";

import { OpenModal } from "../../../../../actions";
import { isEmail, isRequired, combine } from "../../../../../utils/validators";

import { OrganizationStates, AdminModalTypes, OrganizationTypes } from "../../../../../constants";

import { OrganizationData, Option, Permissions } from "../../../../../types";
import { FormState } from ".";

type PropsType = {
  initialFormState: FormState;
  isParent: boolean;
  hasParent: boolean;
  emrTypeOptions: Option[];
  productOptions: Option[];
  organizationStateOptions: Option[];
  canChangeOrganizationState: boolean;
  onFileChange: (
    event: {
      logo: File;
    },
    handleFileName: (fileName: string) => void
  ) => void;
  logoFileName: string;
  handleDeleteOrganization: () => void;
  organization: OrganizationData;
  loading: boolean;
  openModalAction: OpenModal;
};

const hexCodeValidator = (hexCode?: string): string | undefined => {
  if (hexCode && hexCode.match(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i) === null) {
    return "Not a valid hex colour code";
  }
  return undefined;
};

const phoneValidator = isRequired("Phone number is required");
const emailValidator = combine([
  isRequired("Organization email is required"),
  isEmail("Please enter a valid email")
]);

const HexColourIcon = ({ hexColour }: { hexColour: string }) => {
  let colour = hexColour;
  let stroke = "none";
  if (hexCodeValidator(hexColour)) {
    colour = "none";
    stroke = "black";
  }
  return (
    <svg>
      <circle cx={11} cy={11} r={10} fill={colour} stroke={stroke} />
    </svg>
  );
};

const LogoPreviewComponent = ({
  logoFileName,
  backgroundColor
}: {
  logoFileName: string;
  backgroundColor: string | null;
}) => {
  const src = logoFileName ? `${process.env.REACT_APP_AWS_LOGO_BUCKET_URL}${logoFileName}` : "";
  return (
    <div
      className={styles.PreviewContainer}
      style={{ backgroundColor: backgroundColor || "#f2f2f3" }}
    >
      {src ? <img className={styles.Preview} src={src} alt="logo" /> : null}{" "}
    </div>
  );
};

const AccountPageForm = ({
  initialFormState,
  isParent,
  hasParent,
  emrTypeOptions,
  organizationStateOptions,
  canChangeOrganizationState,
  productOptions,
  onFileChange,
  logoFileName,
  handleDeleteOrganization,
  organization,
  loading,
  openModalAction
}: PropsType) => {
  const formState = useFormState();
  const formApi = useFormApi();
  const values = formState.values as FormState;
  const isDemo = !!initialFormState.demoAccountExpiry;

  return (
    <>
      {values.organizationState === OrganizationStates.TEST.value && (
        <>
          <Card fullWidth>
            <div className={styles.TestModeSection}>
              <div className={styles.TestModeSectionHeading}>
                <Heading size="M" component="h4">
                  You&apos;re in test mode!
                </Heading>
                <span className={styles.TestModeSectionSpan}>
                  Test mode enables you to review customizations for messages, instructions, and
                  chats before going live with patients. In test mode, messages will only be sent to
                  the email addresses and phone numbers added to the lists below. When you&apos;re
                  ready to go live, use the drop-down in General Settings to fully activate your
                  account!
                </span>
              </div>
              <div className={styles.TestModeSectionSettings}>
                <TextInput
                  fieldName="phoneWhitelist"
                  label="Phone Number Whitelist"
                  placeholder="Enter phone numbers separated by commas"
                  info="Multiple phone numbers can be entered when separated by commas.  Please enter phone numbers without any symbols like ( or -."
                />
                <TextInput
                  fieldName="emailWhitelist"
                  label="Email Whitelist"
                  placeholder="Enter emails separated by commas"
                  info="Multiple email addresses can be entered when separated by commas."
                />
              </div>
            </div>
          </Card>
          <br />
        </>
      )}
      <Card fullWidth>
        <CardSection title="General">
          <FormGrid>
            {isParent && (
              <>
                <BaseInput fieldName="groupName" label="Group">
                  <Text>{organization?.fullName}</Text>
                </BaseInput>
                <BaseInput fieldName="role" label="Role">
                  <Text>{OrganizationTypes.PARENT}</Text>
                </BaseInput>
              </>
            )}
            {hasParent && (
              <>
                <BaseInput fieldName="groupName" label="Group">
                  <Text>{organization?.parent?.fullName}</Text>
                </BaseInput>
                <BaseInput fieldName="role" label="Role">
                  <Text>Child</Text>
                </BaseInput>
              </>
            )}
            <SelectInput
              fieldName="organizationState"
              label="Organization State"
              disabled={!canChangeOrganizationState}
              warningMessage={
                !canChangeOrganizationState
                  ? "SMS service with phone number required to activate"
                  : ""
              }
              info="Deactivated state is for initial configuration. Test Mode is for verifying configuration. Active is fully operational."
              options={organizationStateOptions}
            />
            <div>
              <ToggleInput
                fieldName="verified"
                label="Verification"
                labelChecked="Verified"
                labelUnchecked="Not verified"
                toggleLabelPosition="right"
                info="Please follow Mikata Regulations to verify the account. Account needs to be verified to use the full features."
              />
            </div>
            {!isParent && (
              <>
                <SelectInput
                  fieldName="emrType"
                  label="EMR type"
                  options={emrTypeOptions}
                  disabled={!values.verified}
                />
                <div />
              </>
            )}
            <TextInput
              fieldName="phoneNumber"
              label="Organization Phone Number"
              placeholder={organization?.phoneNumber || "Enter a number"}
              validate={phoneValidator}
            />
            <TextInput
              fieldName="email"
              label="Organization Email"
              placeholder={organization?.email || "Enter an email"}
              validate={emailValidator}
            />
          </FormGrid>
        </CardSection>
        <hr />
        {!isParent && (
          <>
            <CardSection title="Address">
              {organization?.address && (
                <>
                  <Text>{organization?.address.street}</Text>
                  <Text>
                    {organization?.address.city}, {organization?.address.state}
                  </Text>
                  <Text>{organization?.address.postalCode}</Text>
                </>
              )}
            </CardSection>
            <hr />
            <CardSection title="UI Customization">
              <div className={styles.UICustomization}>
                <div className={cx(styles.UICustomizationColumn, styles.UICustomizationColumnLeft)}>
                  <TextInput
                    fieldName="primaryColour"
                    label="Primary Brand Colour"
                    placeholder="Enter a colour hex code"
                    inputIcon={<HexColourIcon hexColour={values.primaryColour || ""} />}
                    validate={() => undefined}
                  />
                  <TextInput
                    fieldName="secondaryColour"
                    label="Secondary Brand Colour"
                    placeholder="Enter a colour hex code"
                    inputIcon={<HexColourIcon hexColour={values.secondaryColour || ""} />}
                    validate={() => undefined}
                  />
                </div>
                <div className={styles.UICustomizationColumn}>
                  <div className={styles.FileUploadWrapper}>
                    <UploadInput
                      fieldName="logo"
                      acceptedFileTypes={["image/png", "image/jpg"]}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      customOnChange={(change: any) => {
                        onFileChange(change as { logo: File }, (fileName: string) => {
                          formApi.setValue("logo", fileName);
                        });
                      }}
                    />
                  </div>
                  <LogoPreviewComponent
                    logoFileName={logoFileName || organization?.logo || ""}
                    backgroundColor={
                      values.secondaryColour && !hexCodeValidator(values.secondaryColour)
                        ? values.secondaryColour
                        : null
                    }
                  />
                </div>
              </div>
            </CardSection>
            {isDemo && (
              <>
                <hr />
                <CardSection title="Demo Account">
                  <DateSelectInput
                    fieldName="demoAccountExpiry"
                    label="Demo Expiry"
                    initialValue={initialFormState.demoAccountExpiry}
                  />
                  <SelectInput
                    fieldName="demoAccountProduct"
                    label="Demo Product"
                    info="The product associated with the demo"
                    options={productOptions}
                  />
                </CardSection>
              </>
            )}
          </>
        )}
        {isParent && (
          <CardSection title={`Group members (${organization?.children?.length})`}>
            {organization?.children?.length === 0 && <Text>No group members</Text>}
            {organization &&
              organization?.children?.length > 0 &&
              organization?.children.map((child) => (
                <Text key={`org-${child.id}`}>
                  {child.fullName} ({child.id})
                </Text>
              ))}
            <PermissionsGuard
              requiredPermissions={[Permissions.UPDATE_ORGANIZATION, Permissions.UPDATE_BILLING]}
            >
              <Button
                className={styles.TopPadButton}
                inline
                onClick={() => {
                  openModalAction(AdminModalTypes.LINK_ORGANIZATION, {
                    parentOrganization: organization
                  });
                }}
              >
                + Organization
              </Button>
            </PermissionsGuard>
          </CardSection>
        )}
        <hr />

        <div className={styles.Footer}>
          <Button type="button" onClick={handleDeleteOrganization}>
            Delete
          </Button>
          <Button type="submit" id="saveAccount" disabled={loading}>
            Save
          </Button>
        </div>
      </Card>
    </>
  );
};

export default AccountPageForm;
