import React from "react";
import { useField } from "informed";

import BaseInput from "../BaseInput";
import TextAreaInputBase from "./TextAreaInputBase";

import { InputPropsType } from "../../../../types";

type PropsType = InputPropsType & {
  id?: string;
  size?: "S" | "M" | "L";
  initialValue?: string;
  validateOnChange?: boolean;
  warning?: boolean;
  labelDetails?: React.ReactNode;
};

const TextAreaInput = ({
  id,
  fieldName,
  label,
  info,
  infoStatus,
  placeholder,
  disabled = false,
  validate,
  validateOnChange = true,
  size = "S",
  initialValue,
  warning = false,
  warningMessage = "",
  labelDetails,
  customOnChange,
  ...props
}: PropsType) => {
  const { fieldState, fieldApi } = useField({
    ...props,
    name: fieldName,
    validate,
    validateOn: "change",
    initialValue
  });
  const value = fieldState.value as string | undefined;
  const error = fieldState.error as string | undefined;

  const { setValue, setTouched, validate: validateInput } = fieldApi;

  const onChangeHandler = (updatedValue: string = ""): void => {
    setValue(updatedValue);
    setTouched(true);
    if (error || validateOnChange) {
      validateInput();
    }

    if (customOnChange) {
      customOnChange({ [fieldName]: updatedValue });
    }
  };

  return (
    <BaseInput
      error={error}
      fieldName={fieldName}
      label={label}
      info={info}
      infoStatus={infoStatus}
      warningMessage={warningMessage}
      labelDetails={labelDetails}
    >
      <TextAreaInputBase
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        fieldName={fieldName}
        id={id}
        label={label}
        warning={warning}
        error={error}
        placeholder={placeholder}
        size={size}
        initialValue={value}
        disabled={disabled}
        onChange={onChangeHandler}
      />
    </BaseInput>
  );
};

export default TextAreaInput;
