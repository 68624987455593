import React from "react";

type PropsType = {
  size?: number;
};

const Document = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 23a3 3 0 0 1-3-3V4a3 3 0 0 1 3-3h8a1 1 0 0 1 .574.181l.008.006c.043.03.084.063.122.1l6 6c.037.038.07.079.1.122l.007.009a1 1 0 0 1 .189.557V20a3 3 0 0 1-3 3H6ZM5 4v16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9h-5a1 1 0 0 1-1-1V3H6a1 1 0 0 0-1 1Zm10 3h2.587L15 4.414V7ZM8 18a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2H8Zm0-4a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2H8Zm0-4a1 1 0 0 1 0-2h2a1 1 0 1 1 0 2H8Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Document;
