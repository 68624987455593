import { MessageTemplate, Practitioner, Reason, Location, ChatFlow } from "./redux";

export enum AutomationStatus {
  published = "published",
  draft = "draft"
}

export enum MessageScheduleObject {
  APPOINTMENTS = "appointments"
}

export enum MessageScheduleEvent {
  START = "start",
  CREATED = "created",
  CANCELLED = "cancelled",
  COMPLETE = "complete",
  BOOKED = "booked"
}

export enum MessageScheduleTriggerUnit {
  DAYS = "days",
  HOURS = "hours",
  MINUTES = "minutes"
}

export type MessageSchedule = {
  id: number;
  object: MessageScheduleObject;
  event: MessageScheduleEvent;
  triggerUnit: MessageScheduleTriggerUnit;
  triggerValue: number;
};
export enum AutomatedMessageConfigurationTopic {
  BOOKED = "booked message",
  CANCELLED = "cancelled message",
  CHECK_IN_NOTICE = "parking lot daily",
  CUSTOM_MESSAGE = "custom message",
  POST_VISIT_LINK = "postvisit chat",
  REMINDER = "reminder"
}

export type AutomatedMessageConfiguration = {
  id: number;
  active: boolean;
  displayName: string | null;
  note: string | null;
  topic: AutomatedMessageConfigurationTopic;
  automationId: number;
  scheduleId: number;
  templateId: number;
  multiTemplateId: number | null;
  templateDisplayName: string;
  multiTemplateDisplayName: string | null;
  createdAt: string;
  updatedAt: string;
  chatFlows: { id: number; title: string; version: string }[] | null;
};

export type Automation = {
  id: number;
  organizationId: number;
  displayName: string;
  description: string;
  status: "published" | "draft";
  createdAt: string;
  updatedAt: string;
};

export type AutomationDashboard = Automation & {
  hasDeprecatedTopics: boolean;
  locationIds: number[];
  practitionerIds: number[];
  reasonIds: number[];
  automatedMessageConfigurations: AutomatedMessageConfiguration[];
};

export type AutomationDetails = {
  id: string;
  status: AutomationStatus;
  displayName: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  excludedTopics: Array<string>;
  messages: Array<MessageTemplate>;
  locations: Array<Location>;
  practitioners: Array<Practitioner>;
  reasons: Array<Reason>;
  messageConfigurations: Array<MessageConfigurationDetails>;
  settings: {
    locationId?: number | null;
    hideAddress?: boolean;
    hidePhoneNumber?: boolean;
    hideDate?: boolean;
    hideTime?: boolean;
    showPatients?: boolean;
    actions?: {
      reschedule?: {
        active: boolean;
        rangeStart: AutomationActionRangeTime;
        rangeEnd: AutomationActionRangeTime;
      };
      confirm?: {
        active: boolean;
        rangeStart: AutomationActionRangeTime;
        rangeEnd: AutomationActionRangeTime;
      };
      checkin?: {
        active: boolean;
        rangeStart: AutomationActionRangeTime;
        rangeEnd: AutomationActionRangeTime;
        chatFlowId: number | null;
      };
    };
  } | null;
  checkInChats?: Array<ChatFlow>;
};

export type AutomationJourneyDetails = {
  automationId: number | null;
  automationName: string | null;
  template: MessageTemplate | null;
  chatFlowTitles: string[] | null;
  topic: string;
  selectedBy: { location: boolean; practitioner: boolean; reason: boolean };
  excludedByAutomation: boolean;
  triggerDetails: { event: MessageScheduleEvent; value: number; unit: MessageScheduleTriggerUnit };
  features: { name: string; enabled: boolean }[] | undefined;
  messageConfigurationId?: number;
};

export type AutomationJourneyTestData = {
  automationId?: number | null;
  locationId?: number;
  practitionerId?: number;
  reasonId?: number;
};

export type MessageConfigurationDetails = {
  id: number;
  active: boolean;
  displayName: string;
  topic: string;
  automationId: number;
  note: string;
  scheduleId: number;
  templateId: number;
  multiTemplateId: number | null;
  createdAt: string;
  updatedAt: string;
  scheduleData: MessageSchedule;
  templateData: {
    id: number;
    payloadContent: string;
    medium: string;
    displayName: string;
    isDefault: true;
    chatFlowId: number | null;
  };
  multiTemplateData: {
    id: number;
    payloadContent: string;
    medium: string;
    displayName: string;
    isDefault: true;
    chatFlowId: number | null;
  } | null;
  chatFlows: { id: number; title: string; version: string }[] | null;
  settings: { exclusionHoursBeforeStart: number; exclude?: TouchpointSettingExclude };
};

export type MessageConfigurationFormDetails = {
  id: number;
  active: boolean;
  displayName: string;
  topic: string;
  automationId: number;
  scheduleId: number;
  templateId: number;
  createdAt: string;
  updatedAt: string;
  scheduleData: {
    id: number;
    object: string;
    event: string;
    triggerValue: string;
    triggerUnit: string;
    triggerOrder: string;
  };
  templateData: {
    id: number;
    payloadContent: string;
    medium: string;
    displayName: string;
    isDefault: true;
    chatFlowId: number | null;
  };
};

export type MessageConfigurationUpdateData = {
  id: number;
  active: boolean;
  templateId: number;
  scheduleData: {
    event: string;
    triggerValue: number;
    triggerUnit: string;
  };
};

export type MessageConfigurationDeleteData = {
  id: number;
  displayName: string;
};

export enum AutomationActionRangeTimeType {
  BEFORE_APPOINTMENT = "beforeAppointment",
  AFTER_APPOINTMENT = "afterAppointment",
  UPON_APPOINTMENT_START = "uponAppointmentStart",
  BOOKING = "booking"
}

export enum AutomationActionRangeTimeTriggerUnit {
  DAYS = "days",
  HOURS = "hours",
  MINUTES = "minutes"
}

export type AutomationActionRangeTime = {
  type: AutomationActionRangeTimeType;
  value?: number;
  unit?: AutomationActionRangeTimeTriggerUnit;
};

export enum AutomationSettingAction {
  CHECK_IN = "checkin",
  CONFIRM = "confirm",
  RESCHEDULE = "reschedule"
}

export enum AutomationActionRangeType {
  RANGE_START = "rangeStart",
  RANGE_END = "rangeEnd"
}

export enum TouchpointExclusionCases {
  PATIENT_OTHER_APPT = "patientHasOtherAppt"
}

export enum TouchpointSettingRangeTimeType {
  BEFORE_TOUCHPOINT = "beforeTouchpoint",
  AFTER_TOUCHPOINT = "afterTouchpoint"
}

export enum TouchpointSettingRangeTimeUnit {
  DAYS = "days",
  HOURS = "hours",
  MINUTES = "minutes"
}

export type TouchpointSettingRangeTime = {
  type: TouchpointSettingRangeTimeType;
  value: number;
  unit: TouchpointSettingRangeTimeUnit;
};

export type TouchpointSettingExclude = {
  patientHasOtherAppt: {
    active: boolean;
    rangeStart: TouchpointSettingRangeTime;
    rangeEnd: TouchpointSettingRangeTime;
    reasonIds: number[];
  };
};
