import React, { useState, useContext, useMemo } from "react";
import { connect } from "react-redux";

import Heading from "../../../ui/Heading";
import { FloatModal } from "../../../ui/Modal";
import { Form } from "../../../ui/Input";
import EditProviderForm from "./EditProviderForm";

import { OrganizationContext } from "../../../providers/OrganizationProvider";

import {
  closeModal as closeModalAction,
  openModal as openModalAction,
  OpenModal,
  fetchPractitioners as fetchPractitionersAction,
  FetchPractitionersOptions,
  updateUserSettings as updateUserSettingsAction,
  UpdateUsersSettingsData,
  addNotification,
  AddNotificationAction
} from "../../../../actions";

import { resetUserPassword, updateUser } from "../../../../lib";

import { usePermissions } from "../../../../hooks/usePermissions";

import { UserRoleConstants, UserStatuses } from "../../../../constants";

import {
  Practitioner,
  Location,
  Permissions,
  NewNotification,
  ScribeBilling,
  ReduxStateType
} from "../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  provider: Practitioner;
  scribeFeature: boolean;
  scribeBilling: ScribeBilling;
  closeModal: () => void;
  openModal: OpenModal;
  fetchPractitioners: (options?: FetchPractitionersOptions) => void;
  locations: Location[];
  updateUserSettings: (userId: number, updates: UpdateUsersSettingsData) => void;
  addNotif: (notification: NewNotification) => AddNotificationAction;
};

type FormState = {
  displayName: string;
  email: string;
  workPhone: string;
  roleId: string;
  scribeStatus: boolean;
  active: boolean;
  fteFactor: number;
  defaultLocationId: string;
};

const EditProvider = ({
  provider,
  scribeFeature,
  scribeBilling,
  closeModal,
  openModal,
  fetchPractitioners,
  locations,
  updateUserSettings,
  addNotif
}: PropsType) => {
  const currentOrg = useContext(OrganizationContext);
  const { userRoles = [] } = currentOrg || {};
  const hasUpdateUserLevel30 = usePermissions([Permissions.UPDATE_USER_OTHER_LEVEL_30], false);
  const hasUpdateUserLevel20 = usePermissions([Permissions.UPDATE_USER_OTHER_LEVEL_20], false);
  const organization = useContext(OrganizationContext);

  const [editEmail, setEditEmail] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  const canAddProviderEmail = !!(hasUpdateUserLevel20 || hasUpdateUserLevel30);
  const canEditProviderEmail = !!hasUpdateUserLevel30;
  const canChangeRole = !!hasUpdateUserLevel30;

  const locationOptions = useMemo(() => {
    return [
      { label: "None", value: "" },
      ...locations.map((location) => {
        return { label: `${location.fullName} (${location.id})`, value: location.id.toString() };
      })
    ];
  }, [locations]);

  const roleOptions = useMemo(() => {
    // Mapping role id to strings for the radio buttons
    const practitionerRole = userRoles.filter(
      (role) => role.title === UserRoleConstants.PROVIDER
    )[0];
    const practitionerManagerRole = userRoles.filter(
      (role) => role.title === UserRoleConstants.PROVIDER_MANAGER
    )[0];

    return [
      { label: "Practitioner", value: practitionerRole.id ? practitionerRole.id.toString() : "" },
      {
        label: "Practitioner Manager",
        value: practitionerManagerRole?.id ? practitionerManagerRole?.id.toString() : ""
      }
    ];
  }, [userRoles]);

  const initialFormState: FormState = useMemo(() => {
    const currentRoleId: string = provider?.role
      ? userRoles.find((role) => role.title === provider?.role)?.id?.toString() || ""
      : "";

    return {
      displayName: provider.displayName || "",
      workPhone: provider.workPhone || "",
      email: provider.email || "",
      scribeStatus: scribeFeature || false,
      active: provider.practitionerActive || false,
      fteFactor: provider.fteFactor || 0,
      roleId: currentRoleId,
      defaultLocationId: provider.defaultLocationId?.toString() || ""
    };
  }, [provider]);

  const resetPassword = async () => {
    const response = await resetUserPassword(provider.userId);

    if (response.success) {
      addNotif({
        type: "success",
        title: "Success",
        subtitle: `We sent an email to ${provider.displayName} inviting them to reset their password.`,
        autoDismiss: true
      });
      fetchPractitioners({ silent: true });
    } else {
      addNotif({
        type: "error",
        title: "Password reset failed",
        subtitle: "Please try again",
        autoDismiss: true
      });
    }
  };

  const saveAndSendInvite = async (email: string) => {
    setUpdateLoading(true);
    const result = await updateUser(provider.userId, {
      userUpdateData: { email }
    });
    if (result && result.success) {
      setUpdateLoading(false);
      resetPassword();
    }
  };

  const save = async (formValues: FormState) => {
    const providerExistingScribeFeatures = provider.settings?.features?.scribe;
    setUpdateLoading(true);
    if (hasUpdateUserLevel30) {
      updateUserSettings(provider.userId, {
        features: {
          scribe: { ...providerExistingScribeFeatures, active: formValues.scribeStatus }
        }
      });
    }
    const response = await updateUser(provider.userId, {
      userUpdateData: {
        email: editEmail ? formValues.email || "" : provider.email,
        roleId: parseInt(formValues.roleId, 10)
      },
      practitionerUpdateData: {
        displayName: formValues.displayName || "",
        workPhone: formValues.workPhone || "",
        active: formValues.active,
        fteFactor: formValues.fteFactor || 1,
        defaultLocationId: parseInt(formValues.defaultLocationId, 10) || null
      }
    });
    if (response && response.success) {
      setUpdateLoading(false);
      fetchPractitioners({ silent: true });
      closeModal();
      addNotif({
        type: "success",
        title: "Success",
        subtitle: "User updates saved",
        autoDismiss: true
      });
    } else {
      setUpdateLoading(false);
      addNotif({
        type: "error",
        title: "Failed to update the user",
        subtitle: "Please try again",
        autoDismiss: true
      });
    }
  };

  const toggleUserActive = async (params: {
    active: boolean;
    status: string;
    deactivatedAt?: string | null;
  }) => {
    const response = await updateUser(provider.userId, { userUpdateData: params });

    if (response.success) {
      // If we're reactivating a user we'll also invite them to reset their password
      if (params.active) {
        await resetPassword();
      }
      addNotif({
        type: "success",
        title: "Success",
        subtitle: `The user has been ${params.active ? "reactivated" : "deactivated"}`,
        autoDismiss: true
      });
      fetchPractitioners({ silent: true });
      closeModal();
    } else {
      addNotif({
        type: "error",
        title: `Failed to ${params.active ? "reactivate" : "deactivate"} the user`,
        subtitle: "Please try again",
        autoDismiss: true
      });
    }
  };

  const deactivateUser = () => {
    toggleUserActive({ active: false, status: UserStatuses.DEACTIVATED });
  };

  const reactivateUser = async (email?: string | null) => {
    if (email) {
      const response = await updateUser(provider.userId, { userUpdateData: { email } });
      if (response.success) {
        toggleUserActive({
          active: true,
          status: UserStatuses.AWAITING_PASSWORD_RESET,
          deactivatedAt: null
        });
      } else {
        addNotif({
          type: "error",
          title: "Failed to reactivate the user",
          subtitle: "Please try again",
          autoDismiss: true
        });
      }
    } else {
      toggleUserActive({
        active: true,
        status: UserStatuses.AWAITING_PASSWORD_RESET,
        deactivatedAt: null
      });
    }
  };

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L" className={styles.Heading}>
        {`Edit Provider - ${provider.fullName}`}
      </Heading>

      <Form
        onSubmit={(formState) => save(formState.values as FormState)}
        initialValues={initialFormState}
      >
        <EditProviderForm
          provider={provider}
          scribeBilling={scribeBilling}
          updateLoading={updateLoading}
          initialFormState={initialFormState}
          roleOptions={roleOptions}
          locationOptions={locationOptions}
          canEditProviderEmail={canEditProviderEmail}
          canAddProviderEmail={canAddProviderEmail}
          hasUpdateUserLevel30={hasUpdateUserLevel30}
          editEmail={editEmail}
          setEditEmail={setEditEmail}
          openModal={openModal}
          deactivateUser={deactivateUser}
          resetPassword={resetPassword}
          reactivateUser={reactivateUser}
          canChangeRole={canChangeRole}
          organization={organization}
          saveAndSendInvite={saveAndSendInvite}
        />
      </Form>
    </FloatModal>
  );
};

const mapStateToProps = ({ organizationData }: ReduxStateType) => {
  return {
    locations: organizationData?.organizationData?.locations || []
  };
};

export default connect(mapStateToProps, {
  addNotif: addNotification,
  closeModal: closeModalAction,
  openModal: openModalAction,
  fetchPractitioners: fetchPractitionersAction,
  updateUserSettings: updateUserSettingsAction
})(EditProvider);
