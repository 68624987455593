import React, { useState, useRef, useEffect } from "react";
import cx from "classnames";

import Status from "../../../../ui/Status";
import { Form } from "../../../../ui/Input";
import StaffSelectorForm from "./StaffSelectorForm";

import { StatusComponentConfigMap, Option } from "../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  onStaffUsersChange: (value: string) => void;
  selectedStaffUsers: string;
  staffUserOptions: Array<Option>;
  placeholder?: string;
  id?: string;
};

type StaffUserFiltersFormData = {
  assignedStaffUserIds: Array<string>;
};

const StaffSelector = ({
  onStaffUsersChange,
  selectedStaffUsers: selectedStaffUsersStr,
  staffUserOptions,
  id,
  placeholder
}: PropsType) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((isOpen) => !isOpen);
  };

  const selectedStaffUsers = selectedStaffUsersStr
    ? selectedStaffUsersStr.split(",").filter((assignedStaffUserId) => {
        return staffUserOptions.find(
          (staffUserOption) => staffUserOption.value.toString() === assignedStaffUserId
        );
      })
    : [];

  const selectedStaffUsersLabel = staffUserOptions
    .filter((staffUserOption) => {
      return selectedStaffUsers.includes(staffUserOption.value.toString());
    })
    .map((staffUserOption) => staffUserOption.label)
    .join(", ");

  const initialFormState = {
    assignedStaffUserIds: selectedStaffUsers
  };

  const configMapping: StatusComponentConfigMap = staffUserOptions.reduce((config, option) => {
    // eslint-disable-next-line no-param-reassign
    config[option.value.toString()] = "gray";
    return config;
  }, {} as StatusComponentConfigMap);

  const onSave = (formData: StaffUserFiltersFormData) => {
    const staffUserIdsStr = formData.assignedStaffUserIds.join();
    toggle();
    onStaffUsersChange(staffUserIdsStr);
  };

  const offDropdownClick = (event: MouseEvent | KeyboardEvent) => {
    const dropdownEl = elementRef.current;
    const isOutsideClick = dropdownEl && !dropdownEl.contains(event.target as Node);

    if (isOutsideClick || (event as KeyboardEvent).key === "Escape") {
      toggle();
      document.removeEventListener("mouseup", offDropdownClick, false);
      document.removeEventListener("keyup", offDropdownClick, false);
    }
  };

  useEffect(() => {
    if (open) {
      // add when mounted
      document.addEventListener("mouseup", offDropdownClick);
      document.addEventListener("keyup", offDropdownClick);
      return () => {
        document.removeEventListener("mouseup", offDropdownClick, false);
        document.removeEventListener("keyup", offDropdownClick, false);
      };
    }

    document.removeEventListener("mouseup", offDropdownClick, false);
    document.removeEventListener("keyup", offDropdownClick, false);

    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mouseup", offDropdownClick, false);
      document.removeEventListener("keyup", offDropdownClick, false);
    };
  }, [open]);

  return (
    <div ref={elementRef} className={cx(styles.DropdownWrapper)}>
      <button
        id={id ? `dropdown-${id}` : undefined}
        type="button"
        className={cx(styles.Dropdown)}
        onClick={() => {
          toggle();
        }}
      >
        <Status
          value={selectedStaffUsersStr}
          placeholder={placeholder}
          options={[
            {
              label: "All assignees",
              value: ""
            },
            ...staffUserOptions,
            {
              label: `(${selectedStaffUsers.length}) ${selectedStaffUsersLabel}`,
              value: selectedStaffUsersStr
            }
          ]}
          configMap={configMapping}
          className={cx(styles.DropdownStatus, { [styles.DropdownStatusActive]: open })}
          defaultColor="gray"
        />
      </button>

      <div className={cx(styles.DropdownList, { [styles.DropdownListOpen]: open })}>
        <Form
          key={selectedStaffUsers.join(",")}
          initialValues={initialFormState}
          onSubmit={(formState) => onSave(formState.values as StaffUserFiltersFormData)}
        >
          <StaffSelectorForm staffUserOptions={staffUserOptions} />
        </Form>
      </div>
    </div>
  );
};

export default StaffSelector;
