import React, { useState, useEffect, useContext, useMemo } from "react";
import { connect } from "react-redux";
import { orderBy } from "lodash";

import Loader from "../../../../ui/Loader";
import { Form } from "../../../../ui/Input";
import BillingPageForm from "./BillingPageForm";

import { OrganizationContext } from "../../../../providers/OrganizationProvider";
import { usePermissions } from "../../../../../hooks/usePermissions";
import { getBillingUrl } from "../../../../../lib";

import { SubscriptionFormState, SubscriptionsFormState } from "./SubscriptionsInput";

import {
  fetchBillingConfiguration as fetchBillingConfigurationAction,
  fetchAllBillingCustomers as fetchAllBillingCustomersAction,
  fetchOrganizationSubscriptions as fetchOrganizationSubscriptionsAction,
  updateOrgSettings,
  UpdateOrganizationSettingsOptions,
  backfillUsage,
  BillingActionType,
  fetchPractitioners as fetchPractitionersAction
} from "../../../../../actions";

import { SettingNames } from "../../../../../constants";
import {
  BillingConfigSetting,
  BillingInvoiceTypes,
  ReduxStateType,
  Setting,
  BillingCustomer,
  BillingSubscription,
  PricingItem,
  Subscription,
  Practitioner,
  Permissions
} from "../../../../../types";

type FormState = {
  chargeOverCustomerId?: string;
  chargeOverSubscriptionId?: string; // this is the simple billing config subscriptionId that is still in use
  chargeOverUserId?: string;
  invoiceType?: BillingInvoiceTypes;
  pricing: PricingItem[];
  subscriptions: SubscriptionsFormState;
};

type PropsType = {
  billingConfigLoading?: boolean;
  billingCustomersLoading?: boolean;
  organizationSubscriptionsLoading?: boolean;
  settings?: BillingActionType;
  practitioners: Practitioner[];
  practitionersLoading: boolean;
  billingCustomers?: BillingCustomer[];
  chargeOverOrganizationSubscriptions?: BillingSubscription[];
  fetchPractitioners: () => void;
  fetchBillingConfiguration: (organizationId: string) => void;
  fetchAllBillingCustomers: (organizationId: string) => void;
  fetchOrganizationSubscriptions: (organizationId: string, customerId: string) => void;
  updateOrganizationSettings: (
    organizationId: string,
    settings: Setting[],
    options?: UpdateOrganizationSettingsOptions
  ) => void;
  backfillBillingUsage: (organizationId: number) => void;
};

const defaultPricing: PricingItem[] = [
  { minimum: 1, maximum: 500, price: "0.45" },
  { minimum: 501, maximum: 1000, price: "0.38" },
  { minimum: 1001, maximum: 2000, price: "0.35" },
  { minimum: 2001, maximum: 3000, price: "0.30" },
  { minimum: 3001, maximum: 5000, price: "0.28" },
  { minimum: 5001, maximum: 7500, price: "0.27" },
  { minimum: 7501, maximum: 10000, price: "0.26" },
  { minimum: 10001, maximum: 20000, price: "0.24" },
  { minimum: 20001, maximum: 50000, price: "0.22" },
  { minimum: 50001, maximum: 100000, price: "0.20" },
  { minimum: 100001, maximum: 200000, price: "0.18" },
  { minimum: 200001, maximum: 500000, price: "0.16" }
];

const BillingPage = ({
  settings,
  billingConfigLoading,
  billingCustomersLoading,
  organizationSubscriptionsLoading,
  practitioners,
  practitionersLoading,
  billingCustomers,
  chargeOverOrganizationSubscriptions,
  fetchPractitioners,
  fetchBillingConfiguration,
  fetchAllBillingCustomers,
  fetchOrganizationSubscriptions,
  updateOrganizationSettings,
  backfillBillingUsage
}: PropsType): JSX.Element | null => {
  const userCanEditBilling = usePermissions([Permissions.UPDATE_BILLING], false);

  const organization = useContext(OrganizationContext);

  const billingConfig: BillingConfigSetting | undefined = settings?.setting
    ?.settingValue as BillingConfigSetting;
  const parentBillingConfig: BillingConfigSetting | undefined = settings?.parentBillingConfig
    ?.settingValue as BillingConfigSetting;

  const [urlLoading, setUrlLoading] = useState(false);
  const [url, setUrl] = useState("");

  const invoiceTypeOptions = [
    { label: "Separate invoices at a group rate", value: BillingInvoiceTypes.MULTI },
    { label: "Single invoice at a group rate", value: BillingInvoiceTypes.SINGLE }
  ];

  const pricingLabels = defaultPricing.map((item) => `${item.minimum}-${item.maximum}`);

  const customerOptions = (billingCustomers || []).map((customer) => ({
    label: `${customer.customerName} (${customer.customerId})`,
    value: customer.customerId.toString()
  }));

  const allSubscriptions = useMemo(() => {
    const orgSubscriptions = [...(billingConfig?.subscriptions || [])];
    (chargeOverOrganizationSubscriptions || []).forEach((sub: BillingSubscription) => {
      // if we have a match then do nothing? or ensure alignment?
      const matchingIndex = orgSubscriptions.findIndex((orgSub) => {
        return orgSub.id.toString() === sub.subscriptionId.toString();
      });

      if (matchingIndex >= 0) {
        // If subscription does exists in the organization config, then update the lineItems with the latest from Charge Over
        const orgConfigSub = orgSubscriptions[matchingIndex];

        return {
          ...orgConfigSub,
          lineItems: sub?.lineItems.map((subItem) => {
            const existingLineItem = orgConfigSub?.lineItems?.find((lineItem) => {
              return lineItem.id.toString() === subItem.id.toString();
            });
            return {
              id: subItem.id,
              productName: subItem.productName,
              productKey: subItem.productName,
              cycle: existingLineItem?.cycle || ""
            };
          })
        } as Subscription;
      }

      // If subscription does NOT exists in the organization config, then push in a transformed sub into the config data
      orgSubscriptions.push({
        id: parseInt(sub.subscriptionId, 10),
        lineItems: sub?.lineItems.map((item) => ({
          id: item.id,
          productName: item.productName,
          productKey: item.productKey,
          cycle: ""
        })),
        userId: "unassigned",
        arrears: undefined
      } as Subscription);
    });

    return orderBy(orgSubscriptions, "id");
  }, [billingConfig?.subscriptions, chargeOverOrganizationSubscriptions]);

  const initialFormState: FormState | null = useMemo(() => {
    if (
      billingCustomersLoading ||
      organizationSubscriptionsLoading ||
      billingConfigLoading ||
      practitionersLoading ||
      !organization?.id
    ) {
      return null;
    }

    const subscriptionsFormState = allSubscriptions.map((subscription) => {
      const typeFormValue =
        // eslint-disable-next-line no-nested-ternary
        subscription.arrears === true
          ? "arrears"
          : subscription.arrears === false
            ? "advance"
            : "unknown";
      const userIdFormValue: string | undefined | null =
        // eslint-disable-next-line no-nested-ternary
        subscription?.userId === null
          ? "assignedToOrganization"
          : !subscription?.userId || subscription?.userId === "unassigned"
            ? "unassigned"
            : subscription?.userId?.toString();
      return {
        id: subscription.id.toString(),
        type: typeFormValue,
        userId: userIdFormValue,
        lineItems: subscription.lineItems || []
      } as SubscriptionFormState;
    });
    const customerData =
      billingConfig?.chargeOverCustomerId && billingCustomers
        ? billingCustomers.find(
            (customer) =>
              customer.customerId.toString() === billingConfig?.chargeOverCustomerId?.toString()
          )
        : null;
    const chargeOverUserIdFormState =
      customerData && customerData.superUserId
        ? customerData.superUserId?.toString()
        : billingConfig?.chargeOverUserId?.toString() || "";

    return {
      chargeOverCustomerId: billingConfig?.chargeOverCustomerId?.toString() || "",
      chargeOverUserId: chargeOverUserIdFormState,
      invoiceType: billingConfig?.invoiceType || BillingInvoiceTypes.SINGLE,
      pricing: billingConfig?.pricing || defaultPricing,
      subscriptions: subscriptionsFormState || []
    };
  }, [
    organization?.id,
    billingConfig?.chargeOverCustomerId,
    billingConfigLoading,
    practitionersLoading,
    billingCustomersLoading,
    organizationSubscriptionsLoading
  ]);

  useEffect(() => {
    if (organization) {
      fetchBillingConfiguration(organization.id.toString());
      fetchPractitioners();
      fetchAllBillingCustomers(organization.id.toString());
    }

    if (organization?.id) {
      setUrlLoading(true);
      getBillingUrl(organization.id).then((billingUrl: string | { error: string }) => {
        if (typeof billingUrl === "string") {
          setUrl(billingUrl);
        }
        setUrlLoading(false);
      });
    }
  }, [organization?.id]);

  useEffect(() => {
    if (organization) {
      if (billingConfig?.chargeOverCustomerId) {
        fetchOrganizationSubscriptions(
          organization.id.toString(),
          billingConfig.chargeOverCustomerId?.toString()
        );
      }
    }
  }, [organization?.id, billingConfig?.chargeOverCustomerId]);

  const save = (formValues: FormState) => {
    const newSettingValue: BillingConfigSetting = {
      ...billingConfig,
      chargeOverCustomerId: formValues?.chargeOverCustomerId
        ? parseInt(formValues.chargeOverCustomerId, 10)
        : null,
      chargeOverUserId: formValues?.chargeOverUserId
        ? parseInt(formValues.chargeOverUserId, 10)
        : null,
      invoiceType: formValues?.invoiceType,
      pricing: formValues.pricing.map((priceData, idx) => {
        return {
          price: priceData.price || "",
          minimum: defaultPricing[idx].minimum,
          maximum: defaultPricing[idx].maximum
        };
      }),
      subscriptions: formValues.subscriptions.map((sub) => {
        const subscriptionConfig: Subscription = {
          id: parseInt(sub.id, 10),
          lineItems: sub.lineItems,
          arrears: sub.type === "unknown" ? undefined : sub.type === "arrears",
          userId:
            // eslint-disable-next-line no-nested-ternary
            sub.userId === "unassigned"
              ? "unassigned"
              : sub.userId === "assignedToOrganization"
                ? null
                : parseInt(sub.userId, 10)
        };
        return subscriptionConfig;
      })
    };

    if (organization) {
      updateOrganizationSettings(
        organization.id.toString(),
        [
          {
            settingName: SettingNames.BILLING_CONFIG,
            settingValue: newSettingValue,
            organizationId: organization.id
          }
        ],
        {
          onSuccess: () => {
            fetchBillingConfiguration(organization.id.toString());
          }
        }
      );
    }
  };

  const backfill = () => {
    if (organization) {
      backfillBillingUsage(organization.id);
    }
  };

  if (!initialFormState) return <Loader screen />;

  return (
    <Form
      key={`customerId-${billingConfig?.chargeOverCustomerId}-${organization?.id}`}
      initialValues={initialFormState}
      onSubmit={(formState) => save(formState.values as FormState)}
    >
      <BillingPageForm
        organization={organization}
        pricingLabels={pricingLabels}
        parentBillingConfig={parentBillingConfig}
        billingConfigLoading={billingConfigLoading}
        billingConfig={billingConfig}
        urlLoading={urlLoading}
        url={url}
        backfill={backfill}
        customerOptions={customerOptions}
        invoiceTypeOptions={invoiceTypeOptions}
        userCanEditBilling={userCanEditBilling}
        practitioners={practitioners}
      />
    </Form>
  );
};

const mapStateToProps = ({ billing, practitioners }: ReduxStateType) => {
  return {
    settings: billing.billing,
    billingConfigLoading: billing.billingConfigLoading,
    billingCustomersLoading: billing.billingCustomersLoading,
    organizationSubscriptionsLoading: billing.organizationSubscriptionsLoading,
    practitioners: practitioners.data,
    practitionersLoading: practitioners.loading,
    billingCustomers: billing.billingCustomers,
    chargeOverOrganizationSubscriptions: billing.organizationSubscriptions
  };
};

export default connect(mapStateToProps, {
  fetchPractitioners: fetchPractitionersAction,
  fetchBillingConfiguration: fetchBillingConfigurationAction,
  fetchAllBillingCustomers: fetchAllBillingCustomersAction,
  fetchOrganizationSubscriptions: fetchOrganizationSubscriptionsAction,
  updateOrganizationSettings: updateOrgSettings,
  backfillBillingUsage: backfillUsage
})(BillingPage);
