import React from "react";
import { connect } from "react-redux";

import Heading from "../../../ui/Heading";
import Text from "../../../ui/Text";
import { FloatModal } from "../../../ui/Modal";
import Button from "../../../ui/Button";

import { closeModal as closeModalAction } from "../../../../actions";

import { MicStreamErrorType } from "../../../../types";

import styles from "./index.module.scss";

import useAudioInput from "../../tables/AppointmentsTable/ProviderAppointmentDetails/useAudioInput";
import AudioSettings from "../../tables/AppointmentsTable/ProviderAppointmentDetails/AudioSettings";

type PropsType = {
  appointmentId?: number;
  micStreamErrorType: MicStreamErrorType;
  closeModal: () => void;
};

const getHeading = (micStreamErrorType: MicStreamErrorType) => {
  if (!micStreamErrorType) return "Oops. We failed to establish mic connection";

  switch (micStreamErrorType) {
    case MicStreamErrorType.MIC_CHANGED: {
      return "Microphone change detected";
    }
    case MicStreamErrorType.UNKNOWN:
    default: {
      return "Oops. We failed to establish mic connection";
    }
  }
};

const getSubheading = (micStreamErrorType: MicStreamErrorType) => {
  if (!micStreamErrorType)
    return "Please try reloading the page and ensure microphone permissions are enabled.";

  switch (micStreamErrorType) {
    case MicStreamErrorType.MIC_CHANGED: {
      return "We noticed a change microphone input source. Please select your preferred device. If you do not see your preferred device listed, please ensure it is connected, and it should appear in the list.";
    }
    case MicStreamErrorType.UNKNOWN:
    default: {
      return "Please try reloading the page and ensure microphone permissions are enabled.";
    }
  }
};

const getErrorMessage = (micStreamErrorType: MicStreamErrorType) => {
  if (!micStreamErrorType) return "";

  switch (micStreamErrorType) {
    case MicStreamErrorType.NEED_MIC_PERMISSIONS: {
      return "Mika lacks microphone permissions to start a scribe session.";
    }
    case MicStreamErrorType.MIC_CHANGED: {
      return "Once you have confirmed the input device, you will need to unpause the session.";
    }
    case MicStreamErrorType.UNKNOWN:
    default: {
      return "";
    }
  }
};

const MicSetup = ({ appointmentId, micStreamErrorType, closeModal }: PropsType) => {
  const headingText = getHeading(micStreamErrorType);
  const subheadingText = getSubheading(micStreamErrorType);
  const displayErrorMessage = getErrorMessage(micStreamErrorType);

  const { setMicStreamErrorType } = useAudioInput({
    appointmentIdContext: appointmentId
  });

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L">{headingText}</Heading>
      <Text size="M">{subheadingText}</Text>
      <br />
      {displayErrorMessage && (
        <Text size="M" className={styles.Warning}>
          {displayErrorMessage}
        </Text>
      )}
      {micStreamErrorType === MicStreamErrorType.MIC_CHANGED && (
        <>
          <AudioSettings availableMicsInterval />
          <br />
          <div className={styles.ButtonGroup}>
            <Button
              type="button"
              onClick={() => {
                setMicStreamErrorType(null);
                closeModal();
              }}
            >
              Confirm
            </Button>
          </div>
        </>
      )}
    </FloatModal>
  );
};

export default connect(null, {
  closeModal: closeModalAction
})(MicSetup);
