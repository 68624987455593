import React from "react";
import cx from "classnames";

import styles from "./index.module.scss";

type PropsType = {
  id?: string;
  children: React.ReactNode;
  type?: "button" | "submit";
  disabled?: boolean;
  className?: string;
  inline?: boolean;
  size?: "M" | "S" | "XS";
  secondary?: boolean;
  darkMode?: boolean;
  danger?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

// Types: "standard" | "inline"
// Variants: "primary" | "secondary" | "danger"
// Sizes: "M" | "S" | "XS"
// DarkMode: boolean

const Button = ({
  className,
  id,
  type = "button",
  inline = false,
  secondary = false,
  size = "M",
  darkMode = false,
  danger = false,
  children,
  onClick,
  disabled = false,
  ...props
}: PropsType): JSX.Element => {
  return (
    <button
      id={id}
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={cx(
        styles.Button,
        {
          // type
          [styles.Inline]: inline,
          // sizing
          [styles.Small]: !inline && size === "S",
          [styles.XSmall]: !inline && size === "XS",
          // variant
          [styles.Secondary]: secondary && !danger,
          [styles.Danger]: danger,
          // background context (darkMode)
          [styles.DarkMode]: darkMode
        },
        className
      )}
      onClick={onClick}
      disabled={disabled}
      // Enable prop spreading on this generalized element type
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </button>
  );
};

export { ButtonRow } from "./ButtonRow";

export default Button;
