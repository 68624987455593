import React, { useEffect, useState } from "react";

import { FloatModal } from "../../../ui/Modal";
import Heading from "../../../ui/Heading";
import { Form } from "../../../ui/Input";
import SignInTwoFAForm from "./SignInTwoFAForm";

import styles from "./index.module.scss";

type PropsType = {
  isOpen: boolean;
  closeModal: () => void;
  error?: string;
  onSubmit: (formState: FormState) => Promise<void>;
  loginLoading: boolean;
};

type FormState = {
  twoFAToken?: string;
  twoFARecoveryCode?: string;
};

const initialState = {
  twoFAToken: undefined,
  twoFARecoveryCode: undefined
};

const SignInTwoFA = ({ isOpen, closeModal, error, onSubmit, loginLoading }: PropsType) => {
  const [recoveryMode, setRecoveryMode] = useState(false);
  const [twoFAError, setTwoFAError] = useState("");

  useEffect(() => {
    if (error) {
      setTwoFAError(error);
    }
  }, [error]);

  return (
    <FloatModal
      isOpen={isOpen}
      onClose={() => {
        setRecoveryMode(false);
        closeModal();
      }}
    >
      <Heading size="L" className={styles.Heading}>
        {recoveryMode ? "Recover Your Account" : " Verify Your Account"}
      </Heading>
      <Form
        initialValues={initialState}
        onSubmit={(formState) => onSubmit(formState.values as FormState)}
      >
        <SignInTwoFAForm
          loginLoading={loginLoading}
          recoveryMode={recoveryMode}
          setRecoveryMode={setRecoveryMode}
          twoFAError={twoFAError}
          setTwoFAError={setTwoFAError}
        />
      </Form>
    </FloatModal>
  );
};

export default SignInTwoFA;
