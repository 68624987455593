import React from "react";

import ElementInput from "../ElementInput";
import { getOperatorConfig } from "../helpers";
import {
  ComparatorValue,
  ContextVariable,
  ComparatorFieldType,
  UpdateCondition,
  ContextVariableOption,
  ElementInputValue
} from "../types";

type ComparatorProps = {
  className?: string;
  conditionPath: string;
  operatorValue: string;
  variableValue: string;
  comparatorValue: ComparatorValue;
  contextVariables: ContextVariable[];
  updateCondition: UpdateCondition;
  disabled: boolean;
};

const Comparator = ({
  className,
  conditionPath,
  operatorValue,
  variableValue,
  comparatorValue,
  contextVariables,
  updateCondition,
  disabled
}: ComparatorProps) => {
  const conditionOperatorConfig = getOperatorConfig(operatorValue);
  const comparatorFieldType = conditionOperatorConfig?.comparatorFieldType || null;
  const foundContextVariable = contextVariables
    ? contextVariables.find((contextVariable) => contextVariable.inputName === variableValue)
    : null;
  const comparatorOptions: ContextVariableOption[] = foundContextVariable?.options || [];
  const isExtensible = foundContextVariable?.isExtendable;

  const onComparatorSelection = (selection: ElementInputValue) => {
    updateCondition(conditionPath, "comparator", selection);
  };

  switch (comparatorFieldType) {
    case ComparatorFieldType.STRING: {
      return (
        <div className={className}>
          <ElementInput
            elementType="comparator"
            isClearable
            isExtensible={isExtensible}
            placeholder="enter value"
            options={comparatorOptions}
            value={comparatorValue}
            onChange={onComparatorSelection}
            disabled={disabled}
          />
        </div>
      );
    }
    case ComparatorFieldType.ARRAY: {
      return (
        <div className={className}>
          <ElementInput
            elementType="comparator"
            isMulti
            isClearable
            isExtensible={isExtensible}
            placeholder="enter value"
            options={comparatorOptions}
            value={comparatorValue}
            onChange={onComparatorSelection}
            disabled={disabled}
          />
        </div>
      );
    }
    default: {
      return null;
    }
  }
};

export default Comparator;
