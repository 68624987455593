import React, { useEffect, useState } from "react";
import cx from "classnames";

import { ChevronUp, ChevronDown } from "../Icon";

import styles from "./index.module.scss";

type PropsType = {
  children?: React.ReactNode;
  headerContent?: React.ReactNode;
  collapsible?: boolean;
  fullWidth?: boolean;
  className?: string;
  id?: string;
};

const Card = ({
  className,
  id,
  headerContent,
  collapsible = false,
  fullWidth = false,
  children
}: PropsType): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(!collapsible);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!collapsible && !isOpen) {
      setIsOpen(!collapsible);
    }
  }, [collapsible]);
  return (
    <div
      id={id}
      className={cx(
        styles.Card,
        { [styles.CardOpen]: isOpen, [styles.CardFullWidth]: fullWidth },
        className
      )}
    >
      {headerContent && (
        <div className={cx(styles.Header, { [styles.HeaderOpen]: isOpen })}>
          {headerContent}
          {collapsible && (
            <button
              id={`${id}-toggleCollapsed`}
              type="button"
              className={cx(styles.ToggleIcon, { [styles.IconOpen]: isOpen })}
              onClick={toggleOpen}
            >
              <span className={styles.Chevron}>
                {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
              </span>
            </button>
          )}
        </div>
      )}
      <div className={cx(styles.CardContent, { [styles.CardContentOpen]: isOpen })}>{children}</div>
    </div>
  );
};

export { CardSection } from "./CardSection";
export { CardDivider } from "./CardDivider";
export default Card;
