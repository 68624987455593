import React from "react";

import Heading from "../../../../ui/Heading";
import { SelectInput, TextInput, ToggleInput } from "../../../../ui/Input";
import {
  AutomationActionRangeTimeType,
  AutomationActionRangeTimeTriggerUnit,
  AutomationSettingAction,
  ChatFlow
} from "../../../../../types";

import styles from "./actionSettingSection.module.scss";
import Tooltip from "../../../../ui/Tooltip";
import { Info } from "../../../../ui/Icon";

type PropsType = {
  formDisabled: boolean;
  settingName: string;
  active: boolean;
  startType: AutomationActionRangeTimeType;
  endType: AutomationActionRangeTimeType;
  checkInChats?: Array<ChatFlow>;
};

const automationActionRangeTimeStartTypeOptions = [
  { label: "Before appointment", value: AutomationActionRangeTimeType.BEFORE_APPOINTMENT },
  { label: "Upon booking", value: AutomationActionRangeTimeType.BOOKING }
];

const automationActionRangeTimeEndTypeOptions = [
  { label: "Before appointment", value: AutomationActionRangeTimeType.BEFORE_APPOINTMENT },
  { label: "Upon start time", value: AutomationActionRangeTimeType.UPON_APPOINTMENT_START }
];

const automationActionRangeTimeUnitOptions = [
  { label: "Days", value: AutomationActionRangeTimeTriggerUnit.DAYS },
  { label: "Hours", value: AutomationActionRangeTimeTriggerUnit.HOURS },
  { label: "Minutes", value: AutomationActionRangeTimeTriggerUnit.MINUTES }
];

const showValueUnitInputs = (settingType?: AutomationActionRangeTimeType): boolean => {
  return (
    settingType === AutomationActionRangeTimeType.BEFORE_APPOINTMENT ||
    settingType === AutomationActionRangeTimeType.AFTER_APPOINTMENT
  );
};

const settingTitles = (settingName: string) => {
  switch (settingName) {
    case AutomationSettingAction.CONFIRM:
      return "Confirm Appointment";
    case AutomationSettingAction.RESCHEDULE:
      return "Reschedule Appointment";
    case AutomationSettingAction.CHECK_IN:
      return "Check-in";
    default:
      return "";
  }
};

const ActionSettingSection = ({
  formDisabled,
  settingName,
  active,
  startType,
  endType,
  checkInChats
}: PropsType) => {
  const chatOptions = checkInChats
    ? checkInChats.map((chat) => {
        return {
          label: `${chat.title}${chat.version ? ` (${chat.version})` : ""}`,
          value: chat.id
        };
      })
    : [];
  return (
    <>
      <div className={styles.ActionSettingHeading}>
        <Heading size="S" bold component="h4">
          {settingTitles(settingName)}
        </Heading>
        <ToggleInput
          fieldName={`settings.actions.${settingName}.active`}
          labelChecked="On"
          labelUnchecked="Off"
          disabled={formDisabled}
        />
      </div>
      <div className={styles.ActionSettingGridRow}>
        <div className={styles.HeadingTooltip}>
          <Heading size="META" bold component="h4">
            Action Starts
          </Heading>
          <Tooltip
            icon={
              <div className={styles.Icon}>
                <Info size={15} />
              </div>
            }
            position="topLeft"
          >
            Action Starts are used to specify the start of the time window when patients can start
            can start performing this action through the conversation hub.
            <ul>
              <li>
                Upon booking - Patients can start performing this action as soon as the appointment
                has been booked.
              </li>
              <li>
                Before appointment start - Patients can start performing this action at the
                specified amount of time before the appointment start time. e.g. confirm 24 hours
                before the appointment.
              </li>
            </ul>
          </Tooltip>
        </div>
      </div>
      <div className={styles.ActionSettingGridRow}>
        <SelectInput
          fieldName={`settings.actions.${settingName}.rangeStart.type`}
          options={automationActionRangeTimeStartTypeOptions}
          placeholder="Select Event Type"
          disabled={formDisabled || !active}
        />

        {showValueUnitInputs(startType) && (
          <>
            <TextInput
              fieldName={`settings.actions.${settingName}.rangeStart.value`}
              placeholder="Enter Value"
              disabled={formDisabled || !active}
            />
            <SelectInput
              fieldName={`settings.actions.${settingName}.rangeStart.unit`}
              options={automationActionRangeTimeUnitOptions}
              placeholder="Select Unit"
              disabled={formDisabled || !active}
            />
          </>
        )}
      </div>
      <div className={styles.ActionSettingGridRow}>
        <div className={styles.HeadingTooltip}>
          <Heading size="META" bold component="h4">
            Action Ends
          </Heading>
          <Tooltip
            icon={
              <div className={styles.Icon}>
                <Info size={15} />
              </div>
            }
            position="topLeft"
          >
            Action Ends are used to specify the end of the time window when patients can perform
            this action through the conversation hub.
            <ul>
              <li>
                Upon start time - Patients can perform this action up to the appointment start time.
              </li>
              <li>
                Before appointment - Patients can perform this action up to the specified amount of
                time before the start time of the appointment. e.g. reschedule up to 1 hour before
                the appointment start time
              </li>
              <li>
                After appointment - Patients can perform this action up to the specified amount of
                time after the appointment start time. Available only for certain actions. e.g. 1
                hour after the appointment for a check-in
              </li>
            </ul>
          </Tooltip>
        </div>
      </div>

      <div className={styles.ActionSettingGridRow}>
        <SelectInput
          fieldName={`settings.actions.${settingName}.rangeEnd.type`}
          options={
            settingName === AutomationSettingAction.CHECK_IN
              ? [
                  ...automationActionRangeTimeEndTypeOptions,
                  {
                    label: "After appointment",
                    value: AutomationActionRangeTimeType.AFTER_APPOINTMENT
                  }
                ]
              : automationActionRangeTimeEndTypeOptions
          }
          placeholder="Select Event Type"
          disabled={formDisabled || !active}
        />
        {showValueUnitInputs(endType) && (
          <>
            <TextInput
              fieldName={`settings.actions.${settingName}.rangeEnd.value`}
              placeholder="Enter Value"
              disabled={formDisabled || !active}
            />
            <SelectInput
              fieldName={`settings.actions.${settingName}.rangeEnd.unit`}
              options={automationActionRangeTimeUnitOptions}
              placeholder="Select Unit"
              disabled={formDisabled || !active}
            />
          </>
        )}
      </div>

      {settingName === AutomationSettingAction.CHECK_IN && (
        <SelectInput
          label="Select Check-In Chat Template"
          fieldName={`settings.actions.${settingName}.chatFlowId`}
          options={chatOptions}
          placeholder="Select Chat"
          disabled={formDisabled || !active}
        />
      )}
    </>
  );
};

export default ActionSettingSection;
