import React from "react";
import cx from "classnames";
import moment from "moment-timezone";

import { useFormState } from "informed";
import { OpenModal } from "../../../../../../actions";

import Button from "../../../../../ui/Button";
import Heading from "../../../../../ui/Heading";
import { CircleExclamation, Clock, Search, Sadness } from "../../../../../ui/Icon";
import { SelectInput, TextInput } from "../../../../../ui/Input";
import Loader from "../../../../../ui/Loader";
import Text from "../../../../../ui/Text";
import Tooltip from "../../../../../ui/Tooltip";

import PatientCard from "../PatientCard";

import { ModalTypes } from "../../../../../../constants";

import { hasMinCharacters } from "../../../../../../utils/validators";

import { Reason, Patient, Option, AvailableSlot } from "../../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  appointmentRequestId: number;
  reasons: Array<Reason>;
  openModal: OpenModal;
  closeFullScreenModal: () => void;
  organizationId: number;
  timezone: string;
  patients: Patient[];
  noMoreAvailableSlots: boolean;
  matchedPatient: Patient | null;
  setMatchedPatient: (matchedPatient: Patient | null) => void;
  patientSectionText: string;
  handlePHNChange: (personalHealthNumber: string) => void;
  patientResultsMessage: string;
  reasonOptions: Option[];
  availableAppointmentSlots: AvailableSlot[];
  primaryProviderIds: number[];
  patientsLoading: boolean;
  preferQuickView?: boolean;
};

type FormState = {
  personalHealthNumber: string;
  emrReasonId: string;
};

const phnValidator = hasMinCharacters(
  7,
  "Attention: You must enter at a PHN with at least 7 digits"
);

const FormContent = ({
  appointmentRequestId,
  reasons,
  patients,
  patientsLoading,
  noMoreAvailableSlots,
  matchedPatient,
  setMatchedPatient,
  patientSectionText,
  handlePHNChange,
  patientResultsMessage,
  reasonOptions,
  availableAppointmentSlots,
  primaryProviderIds,
  openModal,
  closeFullScreenModal,
  organizationId,
  timezone,
  preferQuickView
}: PropsType) => {
  const formState = useFormState();
  const values = formState.values as FormState;
  const reason = reasons.find(
    (reason) =>
      (reason.emrReasonId && reason.emrReasonId === values.emrReasonId) ||
      reason.value === values.emrReasonId
  );
  return (
    <>
      {!preferQuickView && (
        <Heading size="M" component="h1" className={cx(styles.CardHeading, styles.BottomBorder)}>
          Book an appointment
        </Heading>
      )}
      {noMoreAvailableSlots ? (
        <>
          <div className={styles.NoMoreAvailableSlots}>
            <div className={styles.NoMoreAvailableSlotsIcon}>
              <Sadness size={106} />
            </div>
            <Heading size="S" component="h4">
              No dates and times available!
            </Heading>
          </div>
          <Text className={styles.MarginBottom}>
            The dates and times requested by the patients are no longer available. Please contact
            the patient to complete their booking.
          </Text>
        </>
      ) : (
        <>
          <Heading size="S" bold>
            Step 1 - Select a patient from your records
          </Heading>
          <div className={cx(styles.BottomBorder, styles.CardPaddingBottom)}>
            <Text className={styles.MarginBottom}>{patientSectionText}</Text>
            {matchedPatient ? (
              <div>
                <PatientCard
                  type="selected"
                  patient={matchedPatient}
                  setMatchedPatient={setMatchedPatient}
                />
              </div>
            ) : (
              <div>
                <TextInput
                  fieldName="personalHealthNumber"
                  label="Search patient by personal health number"
                  customOnChange={(e) => handlePHNChange(e.personalHealthNumber)}
                  validate={phnValidator}
                  inputIcon={<Search />}
                />
                <div className={styles.PatientResults}>
                  {patientsLoading ? (
                    <Loader small center />
                  ) : (
                    <div>
                      <Text size="S" bold className={styles.PatientResultsMessage}>
                        {patientResultsMessage}
                      </Text>
                      {!patients.length ? (
                        <div className={styles.NoPatients}>
                          <Text size="S" className={cx(styles.MarginBottom, styles.NoPatientsText)}>
                            We did not find a patient associated in your records!
                          </Text>
                          <Text size="S" className={styles.NoPatientsText}>
                            We recommend either:
                            <br />
                            a) Update an existing record,
                            <br />
                            b) Create a new patient, OR
                            <br />
                            c) Reject this appointment and contact the patient.
                          </Text>
                        </div>
                      ) : (
                        <div>
                          {patients.map((patient) => {
                            return (
                              <PatientCard
                                key={patient.emrPatientId}
                                type="selection"
                                patient={patient}
                                setMatchedPatient={setMatchedPatient}
                              />
                            );
                          })}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <Heading size="S" bold className={styles.CardPaddingTop}>
            Step 2 - Select appointment reason
          </Heading>
          <div className={styles.BottomBorder}>
            <Text className={styles.MarginBottom}>
              The appointment will be booked under the following reason in your records.
            </Text>
            <SelectInput
              fieldName="emrReasonId"
              label="Associated Reason"
              options={reasonOptions}
              disabled={patientsLoading || !matchedPatient}
            />
          </div>
          <Heading size="S" bold className={styles.CardPaddingTop}>
            Step 3 - Select a time to book appointment
          </Heading>
          <div>
            <Text className={styles.MarginBottom}>
              The patient has requested the following times. Please select one to book the
              appointment.
            </Text>
            {availableAppointmentSlots.map((appointmentSlot) => {
              const key = JSON.stringify(appointmentSlot.slotIds);
              const startTimeDisplay = moment(appointmentSlot.startTime)
                .tz(timezone)
                .format("MMMM D, h:mma");
              return (
                <div
                  key={key}
                  className={cx(styles.AppointmentSlotContainer, {
                    [styles.AppointmentSlotContainerActive]: !patientsLoading && matchedPatient
                  })}
                >
                  <div className={styles.AppointmentSlot}>
                    <div className={styles.Date}>
                      <Heading size="META" component="h5">
                        Date &amp; Time
                      </Heading>
                      <Text>{startTimeDisplay}</Text>
                      <Text size="XS" bold className={styles.Duration}>
                        <Clock size={10} />
                        <span className={styles.DurationText}>{appointmentSlot.duration} MIN</span>
                      </Text>
                    </div>
                    <div>
                      <Heading className={styles.AppointmentSlotHeading} size="META" component="h5">
                        Provider
                        {matchedPatient &&
                          !!primaryProviderIds.length &&
                          !primaryProviderIds.includes(appointmentSlot.practitionerId) && (
                            <Tooltip
                              icon={
                                <div className={styles.AppointmentSlotHeadingIcon}>
                                  <CircleExclamation size={14} />
                                </div>
                              }
                              size="S"
                            >
                              <Text className={styles.AppointmentSlotHeadingTooltip} size="S">
                                Not a primary provider of patient.
                              </Text>
                            </Tooltip>
                          )}
                      </Heading>
                      <Text>{appointmentSlot.practitionerName}</Text>
                    </div>
                    <div>
                      <Heading size="META" component="h5">
                        Location
                      </Heading>
                      <Text>{appointmentSlot.locationName}</Text>
                    </div>
                  </div>
                  <Button
                    className={styles.BookButton}
                    disabled={patientsLoading || !matchedPatient}
                    onClick={() => {
                      openModal(ModalTypes.BOOK_APPOINTMENT, {
                        organizationId,
                        appointmentRequestId,
                        appointmentSlot,
                        patient: matchedPatient,
                        reason,
                        timezone,
                        onSuccess: () => {
                          closeFullScreenModal();
                        }
                      });
                    }}
                  >
                    Book
                  </Button>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default FormContent;
