import {
  ContextVariable,
  ContextVariableType
} from "../../../../../../../ui/Input/JsonLogicInput/types";

// These are potentially available keys of session context variables that are not input names of nodes
const computedContextVariables: ContextVariable[] = [
  // Available after the associatePatient incomingMessageAction
  {
    inputName: "__PATIENT_ASSOCIATED__",
    options: [
      { label: "true", value: true },
      { label: "false", value: false }
    ],
    type: ContextVariableType.BOOLEAN,
    isExtendable: false,
    label: "Patient Associated"
  },
  // Available after the first response to PATIENT_VERIFICATION ChatCardType
  {
    inputName: "__PATIENT_VERIFIED__",
    options: [
      { label: "true", value: true },
      { label: "false", value: false }
    ],
    type: ContextVariableType.BOOLEAN,
    isExtendable: false,
    label: "Patient Verified"
  }
];

export default computedContextVariables;
