import React from "react";

type PropsType = {
  size?: number;
};

const Square = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.022 2c.844 0 1.556.285 2.134.856.578.57.867 1.285.867 2.145V19c0 .844-.289 1.555-.867 2.134-.578.578-1.29.867-2.134.867H5.002c-.845 0-1.556-.29-2.134-.867-.579-.579-.868-1.29-.868-2.134V5c0-.86.29-1.575.868-2.145C3.446 2.286 4.158 2 5 2h14.021zm.985 16.999V5c0-.312-.09-.559-.27-.738-.18-.18-.418-.27-.715-.27H5.002c-.298 0-.536.09-.716.27-.18.18-.27.426-.27.738V19c0 .297.09.54.27.727.18.187.418.281.715.281h14.021c.297 0 .536-.094.715-.281.18-.188.27-.43.27-.727z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Square;
