import { Instruction, JsonContent } from "../../../../../../types";
import { isRequired } from "../../../../../../utils/validators";

export type InstructionFormState = {
  id: number;
  displayName: string;
  description: string;
  groupName: string;
  type: Instruction["type"];
  filler: boolean;
  reasons: Array<string>;
  content: JsonContent;
};

export const displayNameValidator = isRequired("Please enter a name");

export const formValidator = (values: InstructionFormState) => {
  const formErrors: {
    id: string | undefined;
    displayName: string | undefined;
    description: string | undefined;
  } = {
    id: undefined,
    displayName: displayNameValidator(values.displayName),
    description: undefined
  };

  return formErrors;
};
