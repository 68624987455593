import React, { useContext, useMemo } from "react";
import { connect } from "react-redux";

import Heading from "../../../../../ui/Heading";
import { CircleCheck } from "../../../../../ui/Icon";
import { Form, ToggleInput } from "../../../../../ui/Input";
import Loader from "../../../../../ui/Loader";
import Text from "../../../../../ui/Text";

import { determineValidMessageMediums } from "../../helpers";
import { OrganizationContext } from "../../../../../providers/OrganizationProvider";
import {
  PermissionLevels,
  PermissionPrefixes,
  ReminderTypePreference
} from "../../../../../../constants";
import { hasPermissionLevelOrHigher } from "../../../../../../hooks/usePermissions";
import { AppointmentDetail, InputValue } from "../../../../../../types";
import getUserFormattedNumbers from "../../../../../../utils/getUserFormattedNumbers";

import {
  updatePatientContactDetails as updatePatientContactDetailsAction,
  fetchAppointmentsDetail as fetchAppointmentsDetailAction,
  PatientContactDetailsUpdateData
} from "../../../../../../actions";

import styles from "./index.module.scss";

type BasePropsType = {
  appointmentData: AppointmentDetail | undefined;
};

type ContactPreferencesPropsType = BasePropsType & {
  fetchAppointmentsDetail: (appointmentId: string, options?: { silent: boolean }) => void;
  updatePatientContactDetails: (
    updateData: PatientContactDetailsUpdateData,
    onSuccess?: () => void
  ) => void;
};

type EmailSMSPreferencePropsType = BasePropsType & {
  canUpdatePatientContactDetails: boolean | undefined;
  emailValid?: boolean;
  smsValid?: boolean;
  optOutStatusHandler: (event: { [fieldName: string]: InputValue }) => void;
  reminderTypePreference: string;
  verifiedMobileNumberFormatted: string;
};

const EmailSMSPreference = ({
  smsValid,
  emailValid,
  verifiedMobileNumberFormatted,
  optOutStatusHandler,
  canUpdatePatientContactDetails,
  appointmentData,
  reminderTypePreference
}: EmailSMSPreferencePropsType) => {
  switch (reminderTypePreference) {
    case ReminderTypePreference.SMS_ONLY.value:
      return (
        <div className={styles.OptOutRow}>
          <div className={styles.InfoGroup}>
            <div className={styles.HeadingRow}>
              <Heading size="META" component="h5">
                SMS
              </Heading>
              {smsValid && (
                <div className={styles.InfoValueIcon}>
                  <CircleCheck size={16} />
                </div>
              )}
            </div>
            <div>
              {verifiedMobileNumberFormatted ? (
                <div className={styles.InfoValueRow}>
                  <Text className={styles.InfoValueText}>{verifiedMobileNumberFormatted}</Text>
                </div>
              ) : (
                "-"
              )}
            </div>
          </div>
          <div className={styles.InfoGroup}>
            <div id={styles.Toggle}>
              <ToggleInput
                labelChecked="Unsubscribed"
                labelUnchecked=" "
                fieldName="smsOptOut"
                displayInverted
                customOnChange={optOutStatusHandler}
                disabled={!canUpdatePatientContactDetails || !appointmentData?.patientId}
              />
            </div>
          </div>
        </div>
      );

    case ReminderTypePreference.PREFER_EMAIL.value:
      return (
        <div>
          <div className={styles.OptOutRow}>
            <div className={styles.InfoGroup}>
              <div className={styles.HeadingRow}>
                <Heading size="META" component="h5">
                  Email
                </Heading>
                {emailValid && (
                  <div className={styles.InfoValueIcon}>
                    <CircleCheck size={16} />
                  </div>
                )}
              </div>
              <div>
                {appointmentData?.email ? (
                  <div className={styles.InfoValueRow}>
                    <Text className={styles.InfoValueText}>{appointmentData.email}</Text>
                  </div>
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={styles.Toggle}>
              <ToggleInput
                labelChecked="Unsubscribed"
                labelUnchecked=" "
                fieldName="emailOptOut"
                displayInverted
                customOnChange={optOutStatusHandler}
                disabled={!canUpdatePatientContactDetails || !appointmentData?.patientId}
              />
            </div>
          </div>
          <div className={styles.OptOutRow}>
            <div className={styles.InfoGroup}>
              <div className={styles.HeadingRow}>
                <Heading size="META" component="h5">
                  SMS
                </Heading>
                {smsValid && (
                  <div className={styles.InfoValueIcon}>
                    <CircleCheck size={16} />
                  </div>
                )}
              </div>
              <div>
                {verifiedMobileNumberFormatted ? (
                  <div className={styles.InfoValueRow}>
                    <Text className={styles.InfoValueText}>{verifiedMobileNumberFormatted}</Text>
                  </div>
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={styles.InfoGroup}>
              <div id={styles.Toggle}>
                <ToggleInput
                  labelChecked="Unsubscribed"
                  labelUnchecked=" "
                  fieldName="smsOptOut"
                  displayInverted
                  customOnChange={optOutStatusHandler}
                  disabled={!canUpdatePatientContactDetails || !appointmentData?.patientId}
                />
              </div>
            </div>
          </div>
        </div>
      );

    default:
      return (
        <>
          <div className={styles.OptOutRow}>
            <div className={styles.InfoGroup}>
              <div className={styles.HeadingRow}>
                <Heading size="META" component="h5">
                  SMS
                </Heading>
                {smsValid && (
                  <div className={styles.InfoValueIcon}>
                    <CircleCheck size={16} />
                  </div>
                )}
              </div>
              <div>
                {verifiedMobileNumberFormatted ? (
                  <div className={styles.InfoValueRow}>
                    <Text className={styles.InfoValueText}>{verifiedMobileNumberFormatted}</Text>
                  </div>
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={styles.Toggle}>
              <ToggleInput
                labelChecked="Unsubscribed"
                labelUnchecked=" "
                fieldName="smsOptOut"
                displayInverted
                customOnChange={optOutStatusHandler}
                disabled={!canUpdatePatientContactDetails || !appointmentData?.patientId}
              />
            </div>
          </div>
          <div className={styles.OptOutRow}>
            <div className={styles.InfoGroup}>
              <div className={styles.HeadingRow}>
                <Heading size="META" component="h5">
                  Email
                </Heading>
                {emailValid && (
                  <div className={styles.InfoValueIcon}>
                    <CircleCheck size={16} />
                  </div>
                )}
              </div>
              <div>
                {appointmentData?.email ? (
                  <div className={styles.InfoValueRow}>
                    <Text className={styles.InfoValueText}>{appointmentData.email}</Text>
                  </div>
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className={styles.Toggle}>
              <ToggleInput
                labelChecked="Unsubscribed"
                labelUnchecked=" "
                fieldName="emailOptOut"
                displayInverted
                customOnChange={optOutStatusHandler}
                disabled={!canUpdatePatientContactDetails || !appointmentData?.patientId}
              />
            </div>
          </div>
        </>
      );
  }
};

const ContactPreferences = ({
  appointmentData,
  updatePatientContactDetails,
  fetchAppointmentsDetail
}: ContactPreferencesPropsType) => {
  const { verifiedMobileNumberFormatted, otherNumberFormatted } = getUserFormattedNumbers({
    verifiedMobile: appointmentData?.verifiedPhoneNumber || "",
    userMobileNumber: appointmentData?.mobilePhone || "",
    userHomeNumber: appointmentData?.homePhone || ""
  });

  const canUpdatePatientContactDetails = hasPermissionLevelOrHigher(
    PermissionPrefixes.UPDATE_USER_OTHER,
    PermissionLevels.TEN
  );

  const optOutStatusHandler = (event: { [fieldName: string]: InputValue }) => {
    if (appointmentData && appointmentData.patientId) {
      if (event.smsOptOut !== undefined) {
        updatePatientContactDetails(
          {
            id: appointmentData.patientId,
            smsOptOut: event.smsOptOut
          },
          () => {
            if (appointmentData?.id) {
              fetchAppointmentsDetail(appointmentData?.id.toString(), {
                silent: true
              });
            }
          }
        );
      } else if (event.emailOptOut !== undefined) {
        updatePatientContactDetails(
          {
            id: appointmentData.patientId,
            emailOptOut: event.emailOptOut
          },
          () => {
            if (appointmentData?.id) {
              fetchAppointmentsDetail(appointmentData?.id.toString(), {
                silent: true
              });
            }
          }
        );
      } else if (event.voiceOptOut !== undefined) {
        updatePatientContactDetails(
          {
            id: appointmentData.patientId,
            voiceOptOut: event.voiceOptOut
          },
          () => {
            if (appointmentData?.id) {
              fetchAppointmentsDetail(appointmentData?.id.toString(), {
                silent: true
              });
            }
          }
        );
      }
    }
  };

  const currentOrg = useContext(OrganizationContext);
  const reminderTypePreference = currentOrg?.reminderTypePreference || "";
  const features = currentOrg?.features || [];

  const patientContactData = {
    mobilePhone: appointmentData?.verifiedPhoneNumber,
    smsOptOut: appointmentData?.smsOptOut,
    email: appointmentData?.email,
    emailOptOut: appointmentData?.emailOptOut,
    voicePhone: otherNumberFormatted,
    voiceOptOut: appointmentData?.voiceOptOut
  };

  const { smsValid, emailValid, voiceValid, voiceFallbackEnabled } = determineValidMessageMediums(
    reminderTypePreference,
    features,
    patientContactData
  );

  const initialFormState = useMemo(
    () =>
      appointmentData
        ? {
            smsOptOut: appointmentData?.smsOptOut,
            emailOptOut: appointmentData?.emailOptOut,
            voiceOptOut: appointmentData?.voiceOptOut
          }
        : undefined,
    [appointmentData]
  );

  if (!initialFormState) {
    return <Loader center small />;
  }

  return (
    <Form initialValues={initialFormState}>
      <EmailSMSPreference
        smsValid={smsValid}
        emailValid={emailValid}
        verifiedMobileNumberFormatted={verifiedMobileNumberFormatted}
        optOutStatusHandler={optOutStatusHandler}
        canUpdatePatientContactDetails={canUpdatePatientContactDetails}
        appointmentData={appointmentData}
        reminderTypePreference={reminderTypePreference}
      />
      {voiceFallbackEnabled && (
        <div className={styles.OptOutRow}>
          <div className={styles.InfoGroup}>
            <div className={styles.HeadingRow}>
              <Heading size="META" component="h5">
                Voice
              </Heading>
              {voiceValid && (
                <div className={styles.InfoValueIcon}>
                  <CircleCheck size={16} />
                </div>
              )}
            </div>
            <div>
              {otherNumberFormatted ? (
                <div className={styles.InfoValueRow}>
                  <Text className={styles.InfoValueText}>{otherNumberFormatted}</Text>
                </div>
              ) : (
                "-"
              )}
            </div>
          </div>
          <div className={styles.Toggle}>
            <ToggleInput
              labelChecked="Unsubscribed"
              labelUnchecked=" "
              fieldName="voiceOptOut"
              displayInverted
              customOnChange={optOutStatusHandler}
              disabled={!canUpdatePatientContactDetails}
            />
          </div>
        </div>
      )}
    </Form>
  );
};

export default connect(null, {
  updatePatientContactDetails: updatePatientContactDetailsAction,
  fetchAppointmentsDetail: fetchAppointmentsDetailAction
})(ContactPreferences);
