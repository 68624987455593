import { StylesConfig } from "react-select";
import { colors } from "../../../../css/_colors";

export type Option = { label: string; value: string | number };
export const styles: (props: { hasError: boolean }) => StylesConfig<Option, boolean> = ({
  hasError
}) => ({
  container: (provided, state) => ({
    ...provided,
    width: "100%",
    height: "48px",
    opacity: state.isDisabled ? 0.5 : 1
  }),
  control: (provided) => {
    return {
      ...provided,
      fontFamily: "hk-grotesk-regular, sans-serif",
      backgroundColor: hasError ? colors.cornflowerLilac : colors.athensGray,
      borderRadius: "8px",
      borderStyle: "none",
      fontSize: "16px",
      lineHeight: "16px",
      fontWeight: 400
    };
  },
  valueContainer: (provided) => ({ ...provided, padding: "10px 16px" }),
  indicatorSeparator: (provided) => ({ ...provided, display: "none" }),
  placeholder: (provided) => ({ ...provided, margin: "0", color: colors.manatee }),
  singleValue: (provided) => ({ ...provided, margin: "0" }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? colors.selago : "#ffffff",
    color: state.isSelected ? colors.royalBlue : colors.shark,
    fontWeight: state.isSelected ? 700 : 400,
    padding: "12px 30px"
  }),
  menu: (provided) => {
    return {
      ...provided,
      borderRadius: "8px",
      marginTop: "0",
      overflow: "hidden"
    };
  },
  menuList: (provided) => {
    return { ...provided, padding: "8px 0", maxHeight: "200px" };
  }
});
