import React from "react";

import { Task } from "../../../../../types";
import TaskDetailsLayout from "./TaskDetailsLayout";

type PropsType = {
  task: Task;
};

const DirectMessageTask = ({ task }: PropsType) => {
  return <TaskDetailsLayout task={task}>{JSON.stringify(task)}</TaskDetailsLayout>;
};

export default DirectMessageTask;
