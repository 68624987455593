import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";

import { OrganizationContext } from "../../providers/OrganizationProvider";

import Button from "../../ui/Button";
import Tooltip from "../../ui/Tooltip";
import { Info, Mail } from "../../ui/Icon";

import { openModal as openModalAction, OpenModal } from "../../../actions";

import { FEATURES, ModalTypes } from "../../../constants";

import {
  AppointmentDetail,
  DirectPatientMessagingModalProps,
  ReduxStateType
} from "../../../types";

import styles from "./index.module.scss";

type PropsType = {
  openModal?: OpenModal;
  appointmentData?: AppointmentDetail;
  customButton?: (props: { disabled?: boolean; onClick: () => void }) => React.ReactElement;
  organizationFeatures: string[];
  dpmContentSuggestion?: string;
};

const tooltipText = (
  customButton: boolean,
  canSendDirectMessage: boolean,
  hasDirectMessagingFeature: boolean
) => {
  if (customButton && !hasDirectMessagingFeature) {
    return "Hi! If you are interested in learning more about our on demand messaging feature, please email clinic advisor at demo@mikatahealth.com.";
  }
  if (!canSendDirectMessage) {
    return "This patient does not have a validated mobile phone or email to send a direct message to.";
  }

  return "";
};
const DirectPatientMessageButton = ({
  openModal,
  appointmentData,
  customButton,
  organizationFeatures,
  dpmContentSuggestion
}: PropsType) => {
  const organization = useContext(OrganizationContext);

  const patientFirstName = appointmentData?.patientFirstName || "";
  const patientLastName = appointmentData?.patientLastName || "";

  /* Some EMR do not split first and last names.
    In these cases the first and last names are the same and include both first and last names. */
  let patientFullName = appointmentData ? `${patientFirstName} ${patientLastName}` : "";
  if (patientFirstName === patientLastName)
    patientFullName = appointmentData ? patientFirstName : "";

  const sendDirectMessageModalProps: DirectPatientMessagingModalProps | undefined = appointmentData
    ? {
        patientDisplayName: patientFullName,
        patientId: appointmentData?.patientId,
        appointmentId: appointmentData?.id,
        mobilePhone: appointmentData?.verifiedPhoneNumber,
        emailAddress: appointmentData?.email,
        canSendSecureMessage: appointmentData?.canSendSecureMessage || false,
        dpmContentSuggestion
      }
    : undefined;

  const hasDirectMessagingFeature = organizationFeatures?.includes(
    FEATURES.DIRECT_PATIENT_MESSAGES.value
  );

  const canSendDirectMessage = appointmentData?.canSendEmail || appointmentData?.canSendSms;

  const directMessageButtonEnabled =
    hasDirectMessagingFeature &&
    canSendDirectMessage &&
    organization?.setupComplete &&
    organization.verified;

  const buttonDisabled = !directMessageButtonEnabled;

  if (customButton) {
    return customButton({
      disabled: buttonDisabled,
      onClick: () => {
        if (openModal) openModal(ModalTypes.SEND_DIRECT_MESSAGE, sendDirectMessageModalProps);
      }
    });
  }

  return (
    <div className={styles.DirectMessage}>
      <Tooltip
        icon={
          <Button
            secondary
            size="S"
            disabled={buttonDisabled}
            onClick={() => {
              if (openModal) openModal(ModalTypes.SEND_DIRECT_MESSAGE, sendDirectMessageModalProps);
            }}
            id="messagePatient"
          >
            <Mail />
            <span className={styles.DirectMessageText}>Message patient</span>
          </Button>
        }
        disabled={
          customButton
            ? hasDirectMessagingFeature && canSendDirectMessage
            : hasDirectMessagingFeature || !canSendDirectMessage
        }
        position="bottomLeft"
      >
        {tooltipText(!!customButton, !!canSendDirectMessage, hasDirectMessagingFeature)}
      </Tooltip>
      {(!hasDirectMessagingFeature || !organization?.verified) && (
        <Tooltip
          icon={
            <div className={styles.Icon}>
              <Info size={15} />
            </div>
          }
          position="bottomLeft"
        >
          Hi! If you are interested in learning more about our on-demand messaging feature, please
          email your clinic advisor at demo@mikatahealth.com.
        </Tooltip>
      )}
    </div>
  );
};

const mapStateToProps = ({ organizationData }: ReduxStateType) => {
  return {
    organizationFeatures: organizationData.organizationData
      ? organizationData.organizationData.features
      : []
  };
};

export default connect(mapStateToProps, {
  openModal: openModalAction
})(DirectPatientMessageButton);
