import React, { Dispatch, SetStateAction, useMemo } from "react";

import { ArrayField } from "informed";
import { Trash, Info } from "../../../../../../../../ui/Icon";
import Button from "../../../../../../../../ui/Button";
import { TextInput } from "../../../../../../../../ui/Input";
import Heading from "../../../../../../../../ui/Heading";
import ToolTip from "../../../../../../../../ui/Tooltip";
import { isRequired } from "../../../../../../../../../utils/validators/isRequired";

import { ChatFlowsNodes, ScaleInputOption } from "../../../../../../../../../types";
import BaseChatNodeForm, {
  BaseFormValues,
  FormValues,
  ScaleInputFormValues
} from "../../BaseChatNodeForm";

import styles from "../index.module.scss";
import scaleInputStyles from "./index.module.scss";

const questionValidator = isRequired("Please enter a question");
const textValidator = isRequired("Please enter a label");
const subTextValidator = isRequired("Please enter a sub label");
const transformedResponseValidator = isRequired("Please enter a transformed response");

type ScaleInputPayloadOptions = { answers: ScaleInputOption[] };

export type ScaleInputChatFlowsNode = ChatFlowsNodes & {
  payloadOptions: ScaleInputPayloadOptions;
};

type PropsType = {
  chatId: string | undefined;
  node: ScaleInputChatFlowsNode;
  viewOnly: boolean;
  setSelectedNodeId: Dispatch<SetStateAction<number | null>>;
};

const onSaveCustomTransformer = (formValues: FormValues) => {
  const values = formValues as BaseFormValues & ScaleInputFormValues;
  return {
    payloadOptions: { answers: values.answers },
    payloadContent: values.payloadContent
  };
};

const ScaleInput = ({ chatId, node, viewOnly, setSelectedNodeId }: PropsType) => {
  const initialFormValues: ScaleInputFormValues = useMemo(() => {
    const answers = node?.payloadOptions?.answers
      ? (node.payloadOptions.answers.map((answer) => {
          return {
            text: typeof answer === "string" ? answer : (answer as ScaleInputOption).text || "",
            subText:
              typeof answer === "string" ? answer : (answer as ScaleInputOption).subText || "",
            value: typeof answer === "string" ? answer : (answer as ScaleInputOption).value || ""
          };
        }) as ScaleInputOption[])
      : ([{ text: "", subText: "", value: "" }] as ScaleInputOption[]);

    return {
      answers: answers as ScaleInputOption[],
      payloadContent: node?.payloadContent || ""
    };
  }, [node.id]);

  return (
    <BaseChatNodeForm
      key={node.id}
      initialValues={initialFormValues}
      onSaveCustomTransformer={onSaveCustomTransformer}
      node={node}
      chatId={chatId}
      isInput
      viewOnly={viewOnly}
      setSelectedNodeId={setSelectedNodeId}
    >
      <TextInput
        fieldName="payloadContent"
        label="Question"
        disabled={viewOnly}
        validate={questionValidator}
      />
      <ArrayField name="answers">
        {({ add, fields }) => {
          return (
            <>
              <div className={scaleInputStyles.Row}>
                <div className={styles.HeaderItem}>
                  <Heading size="META">Label:</Heading>
                  <ToolTip
                    icon={
                      <div className={styles.Icon}>
                        <Info size={15} />
                      </div>
                    }
                  >
                    Label is what the user will see
                  </ToolTip>
                </div>
                <div className={styles.HeaderItem}>
                  <Heading size="META">Sub Label:</Heading>
                  <ToolTip
                    icon={
                      <div className={styles.Icon}>
                        <Info size={15} />
                      </div>
                    }
                  >
                    The sub label is what the user will see under the label.
                  </ToolTip>
                </div>
                <div className={styles.HeaderItem}>
                  <Heading size="META">TRANSFORM RESPONSE:</Heading>
                  <ToolTip
                    icon={
                      <div className={styles.Icon}>
                        <Info size={15} />
                      </div>
                    }
                  >
                    Transformed response is what will be mapped into the database.
                  </ToolTip>
                </div>
                <div className={styles.HeaderItem} />
                <ArrayField.Items>
                  {({ remove, index }) => {
                    return (
                      <React.Fragment key={`scaleInputOption_${index}`}>
                        <div className={scaleInputStyles.item}>
                          <TextInput
                            fieldName="text"
                            disabled={viewOnly}
                            validate={textValidator}
                          />
                        </div>

                        <div className={scaleInputStyles.item}>
                          <TextInput
                            fieldName="subText"
                            disabled={viewOnly}
                            validate={subTextValidator}
                          />
                        </div>

                        <div className={scaleInputStyles.item}>
                          <TextInput
                            fieldName="value"
                            disabled={viewOnly}
                            validate={transformedResponseValidator}
                          />
                        </div>

                        {!viewOnly && fields.length > 1 && (
                          <div className={scaleInputStyles.item}>
                            <Button inline className={styles.icon} onClick={remove}>
                              <Trash size={30} />
                            </Button>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  }}
                </ArrayField.Items>
              </div>

              {!viewOnly && (
                <Button inline type="button" className={styles.addResponse} onClick={() => add()}>
                  + Add Response
                </Button>
              )}
            </>
          );
        }}
      </ArrayField>
    </BaseChatNodeForm>
  );
};

export default ScaleInput;
