import React, { Dispatch, SetStateAction, useState, useMemo } from "react";
import { connect } from "react-redux";

import { ArrayField, useFormState } from "informed";

import Button from "../../../../../../../ui/Button";
import CollapsibleCard from "./CollapsibleCard";
import { Plus, Trash, Refresh } from "../../../../../../../ui/Icon";
import { SelectInput, TextInput, JsonLogicInput } from "../../../../../../../ui/Input";
import Text from "../../../../../../../ui/Text";
import { ChatFlowsNodes, ReduxStateType, InputValue } from "../../../../../../../../types";
import { ChatCardTypes, ChatFlowControlFlags } from "../../../../../../../../constants";

import {
  ContextVariable,
  ConditionValue
} from "../../../../../../../ui/Input/JsonLogicInput/types";
import useChatContextVariables from "../../../../../../../../hooks/useChatContextVariables";
import computedContextVariables from "./computedContextVariables";
import { combine, isJson, isRequired } from "../../../../../../../../utils/validators";

import { convertLogicToReadableString } from "../../../../../../../ui/Input/JsonLogicInput/helpers";

import styles from "./index.module.scss";

const {
  SINGLE_SELECT,
  MULTI_SELECT,
  SCALE_INPUT,
  BOOKING,
  SUMMARY,
  SCRIBE_VISIT,
  INSTRUCTION_REASONS
} = ChatCardTypes;

type FilteredEdge = {
  id: number;
  toMessageTemplateId: number;
  input: string;
  jsonLogic: ConditionValue;
};

type PropsType = {
  node: ChatFlowsNodes;
  chatFlowNodes?: ChatFlowsNodes[];
  filteredEdgesOut?: FilteredEdge[];
  setSelectedNodeId: Dispatch<SetStateAction<number | null>>;
  viewOnly: boolean;
  allChatNodes?: ChatFlowsNodes[];
};

const jsonValidator = combine([isRequired(), isJson("Please enter a valid JSON string")]);

const getEdgeOptions = (
  chatElements: Array<ChatFlowsNodes>,
  selectedNode: ChatFlowsNodes,
  isDefault: boolean,
  defaultConnection: { id: number; toMessageTemplateId: number; input: string }
) => {
  let filteredChatElements;

  if (chatElements && isDefault) {
    filteredChatElements = chatElements.filter(
      (chatFlowNode) =>
        chatFlowNode.cardType !== ChatCardTypes.LANDING &&
        chatFlowNode.cardType !== ChatCardTypes.SUB_FLOW_START &&
        chatFlowNode.id !== selectedNode.id
    );
  } else if (chatElements && !isDefault && defaultConnection) {
    filteredChatElements = chatElements.filter(
      (chatFlowNode) =>
        chatFlowNode.cardType !== ChatCardTypes.LANDING &&
        chatFlowNode.cardType !== ChatCardTypes.SUB_FLOW_START &&
        chatFlowNode.id !== selectedNode.id &&
        chatFlowNode.id !== defaultConnection.toMessageTemplateId
    );
  }

  return filteredChatElements
    ? filteredChatElements.map((chatFlowNode) => {
        if (chatFlowNode.displayName) {
          return {
            label: `${String(chatFlowNode.id)} - ${chatFlowNode.displayName}`,
            value: chatFlowNode.id
          };
        }
        if (chatFlowNode.inputName) {
          return {
            label: `${String(chatFlowNode.id)} - ${chatFlowNode.inputName}`,
            value: chatFlowNode.id
          };
        }
        return { label: String(chatFlowNode.id), value: chatFlowNode.id };
      })
    : [{ label: "", value: "" }];
};

const canAddConnections = (selectedNode: ChatFlowsNodes) => {
  if (
    selectedNode.cardType !== ChatCardTypes.END &&
    selectedNode.cardType !== ChatCardTypes.FLAGGED_END
  ) {
    return true;
  }
  return false;
};

const canAddConnectionBasedOnInput = (selectedNode: ChatFlowsNodes): boolean => {
  return Boolean(
    [
      SINGLE_SELECT,
      MULTI_SELECT,
      SCALE_INPUT,
      BOOKING,
      SUMMARY,
      SCRIBE_VISIT,
      INSTRUCTION_REASONS
    ].find((elem) => elem === selectedNode.cardType)
  );
};

const EdgesOutComponent = ({
  setSelectedNodeId,
  node,
  chatFlowNodes,
  viewOnly,
  filteredEdgesOut,
  allChatNodes
}: PropsType) => {
  const formState = useFormState();

  const [initialOpen, setInitialOpen] = useState<boolean>(false);

  const edgesOut = formState.values.edgesOut
    ? JSON.parse(JSON.stringify(formState.values.edgesOut))
    : [];

  const nodeCanAddConnectionsBasedOnInput = canAddConnectionBasedOnInput(node);
  const initialAdvancedMode =
    filteredEdgesOut?.map((edge: FilteredEdge) => {
      return Boolean(edge.jsonLogic) || !nodeCanAddConnectionsBasedOnInput;
    }) || [];
  const [advancedMode, setAdvancedMode] = useState<boolean[]>(initialAdvancedMode);

  let inputNameContextVariables: ContextVariable[] = [];
  if (allChatNodes) {
    ({ contextVariables: inputNameContextVariables } = useChatContextVariables(allChatNodes));
  }
  const contextVariables = [...inputNameContextVariables, ...computedContextVariables];

  const defaultConnection = edgesOut ? edgesOut[0] : null;

  const defaultInputOptions = [{ label: "", value: "" }];

  // TODO: duplicates logic from `useChatContextVariables.ts`
  // We should only have 1 function that does the below work
  const inputOptions = useMemo(() => {
    let options = [];
    switch (node.cardType) {
      case ChatCardTypes.SINGLE_SELECT:
      case ChatCardTypes.MULTI_SELECT:
      case ChatCardTypes.SCALE_INPUT: {
        options = formState.values.answers
          ? JSON.parse(JSON.stringify(formState.values.answers))
              .filter((answer: { text?: string; value?: string } | null) => {
                return answer?.value;
              })
              .map((answer: { text: string; value: string }) => {
                return { label: answer.text, value: answer.value };
              })
          : defaultInputOptions;
        break;
      }
      case ChatCardTypes.BOOKING: {
        options = [
          { label: "Appointment Auto-booked", value: ChatFlowControlFlags.APPT_AUTO_BOOKED },
          { label: "Time slot(s) selected", value: ChatFlowControlFlags.AVAILABLE_SLOTS_SELECTED },
          {
            label: "No time slot selected",
            value: ChatFlowControlFlags.NO_AVAILABLE_SLOTS_SELECTED
          }
        ];
        break;
      }
      case ChatCardTypes.SUMMARY: {
        if (formState.values.payloadOptions) {
          const payloadOptions = JSON.parse(JSON.stringify(formState.values.payloadOptions));
          options =
            payloadOptions && payloadOptions.answers
              ? payloadOptions.answers.map((answer: { text: string; value: string }) => {
                  return { label: answer.text, value: answer.value };
                })
              : defaultInputOptions;
        } else {
          options = defaultInputOptions;
        }
        break;
      }
      case ChatCardTypes.SCRIBE_VISIT: {
        options = [
          { label: "Scribe Visit - Completed", value: ChatFlowControlFlags.SCRIBE_VISIT_COMPLETED },
          {
            label: "Scribe Visit - Consent Denied",
            value: ChatFlowControlFlags.SCRIBE_VISIT_CONSENT_DENIED
          }
        ];
        break;
      }
      case ChatCardTypes.INSTRUCTION_REASONS: {
        options = [
          { label: ChatFlowControlFlags.IM_NOT_SURE, value: ChatFlowControlFlags.IM_NOT_SURE }
        ];
        break;
      }
      default:
        options = defaultInputOptions;
    }

    if (node?.payloadOptions?.skippable) {
      options.push({
        label: node?.payloadOptions?.skipMessage || "Skip",
        value: ChatFlowControlFlags.SKIP
      });
    }

    return options;
  }, [node.id, formState]);

  const errors = formState.errors ? JSON.parse(JSON.stringify(formState.errors)) : null;

  const errorConnections = errors.edgesOut
    ? errors.edgesOut
        .map((error: null | { toMessageTemplateId: string; input: string }, index: number) => {
          if (error !== null) {
            return index;
          }
          return null;
        })
        .filter((index: number) => index !== null)
    : null;

  const toMessageTemplateIdValidator = (value: InputValue) => {
    // If no element is selected
    if (
      (Array.isArray(value) && value.length === 0) ||
      !value ||
      (typeof value === "string" && !value.trim())
    ) {
      return "Please select an element to connect to";
    }

    // If the element has been overwritten as a result of the default connection being changed, and no new element is selected
    const defaultConnectionCheck = edgesOut
      ? edgesOut.filter(
          (edge: { id: number; toMessageTemplateId: number; input: string } | null) =>
            edge !== null &&
            edge.toMessageTemplateId === value &&
            edge.toMessageTemplateId === defaultConnection.toMessageTemplateId
        )
      : [];

    if (defaultConnectionCheck.length > 1) {
      return "Connection was overwritten by default";
    }

    return undefined;
  };

  const inputValidator = (value: InputValue) => {
    // If no input option is selected
    if (
      (Array.isArray(value) && value.length === 0) ||
      !value ||
      (typeof value === "string" && !value.trim())
    ) {
      return "Please select an input";
    }

    // If a connection has an input option that has been removed
    const validInputCheck =
      inputOptions.find((input: { label: string; value: string }) => input.value === value) || null;

    if (validInputCheck === null) {
      return "This input option was removed";
    }

    // If there are multiple connections for a single input option
    const duplicatesCheck = edgesOut
      ? edgesOut.filter(
          (edge: { id: number; toMessageTemplateId: number; input: string } | null) =>
            edge !== null && edge.input === value
        )
      : [];

    if (duplicatesCheck.length > 1) {
      return "There are multiple connections for this input";
    }

    return undefined;
  };

  return (
    <div className={styles.edgesComponent}>
      <ArrayField name="edgesOut">
        {({ add, fields }) => {
          return (
            <>
              {/* Connections should not be added to an end or flagged end card. */}
              {canAddConnections(node) && (
                <div className={styles.addConnectionButtonWrapper}>
                  <Button
                    id="addConnection"
                    size="S"
                    onClick={async () => {
                      setInitialOpen(true);
                      add();
                      setAdvancedMode([...advancedMode, !nodeCanAddConnectionsBasedOnInput]);
                    }}
                    disabled={viewOnly}
                    className={styles.addConnectionButton}
                  >
                    <Plus size={16} />
                    {fields.length === 0 && "Add connection"}
                  </Button>
                </div>
              )}
              {chatFlowNodes && chatFlowNodes.length === 1 && (
                <h5>You have no elements to connect to, please create an element to connect</h5>
              )}
              {/* Fields are reversed in order for the default connection to be rendered at the bottom. */}
              <ArrayField.Items>
                {({ remove, index }) => {
                  const connection = edgesOut[index];
                  const connectedNode = chatFlowNodes?.find(
                    (node) => node.id === connection?.toMessageTemplateId
                  );
                  const edgeOptions = getEdgeOptions(
                    chatFlowNodes || [],
                    node,
                    true,
                    defaultConnection
                  );

                  let whenDisplay = "";
                  if (connection?.input) whenDisplay = `When: ${connection.input}`;
                  if (connection?.jsonLogic) {
                    const readableString = convertLogicToReadableString(connection.jsonLogic);
                    whenDisplay = `When: ${readableString}`;
                  }
                  return (
                    <React.Fragment key={`edgeOutComponent_${index}`}>
                      <CollapsibleCard
                        collapsible
                        secondary={index > 0}
                        headerContent={index > 0 ? "Additional Connection" : "Default Connection"}
                        summaryContent={
                          <>
                            <div>
                              {connection
                                ? `Connects to: ${index === 0 && connectedNode?.displayName ? connectedNode.displayName : connection.toMessageTemplateId}`
                                : ""}
                            </div>
                            <div>{whenDisplay}</div>
                          </>
                        }
                        initialOpen={initialOpen}
                        forceOpen={errorConnections ? errorConnections.includes(index) : false}
                      >
                        {/* This div is used to track which connection cards to update/add/delete. */}
                        <div style={{ display: "none" }}>
                          <TextInput
                            fieldName="id"
                            label="Connection Id"
                            disabled
                            placeholder="ID will be assigned when changes are saved"
                          />
                        </div>
                        <SelectInput
                          fieldName="toMessageTemplateId"
                          label="connect to"
                          options={edgeOptions}
                          placeholder="Search elements"
                          validate={toMessageTemplateIdValidator}
                          disabled={viewOnly}
                        />

                        {index > 0 &&
                          (advancedMode[index] ? (
                            <JsonLogicInput
                              label="when"
                              fieldName="jsonLogic"
                              contextVariables={contextVariables}
                              validate={jsonValidator}
                            />
                          ) : (
                            <SelectInput
                              fieldName="input"
                              label="when"
                              options={inputOptions || [{ label: "", value: "" }]}
                              placeholder="Search response"
                              validate={combine([isRequired(), inputValidator])}
                              disabled={viewOnly}
                            />
                          ))}
                        {index > 0 && nodeCanAddConnectionsBasedOnInput && (
                          <div className={styles.switchAdvancedButton}>
                            <Button
                              type="button"
                              inline
                              onClick={() => {
                                const newAdvancedMode = [...advancedMode];
                                newAdvancedMode[index] = !newAdvancedMode[index];
                                setAdvancedMode(newAdvancedMode);
                              }}
                              disabled={false}
                            >
                              <Refresh size={16} />
                              <Text className={styles.switchAdvancedText}>
                                {`Switch to ${advancedMode[index] ? "basic" : "advanced"}`}
                              </Text>
                            </Button>
                          </div>
                        )}

                        <div className={styles.edgeRow}>
                          <Button
                            type="button"
                            inline
                            onClick={async () => {
                              setSelectedNodeId(edgesOut[index].toMessageTemplateId);
                            }}
                            disabled={connection ? !connection.toMessageTemplateId : true}
                          >
                            Go to Connection
                          </Button>
                          {!viewOnly && (
                            <Button
                              inline
                              onClick={() => {
                                const newAdvancedMode = [...advancedMode];
                                newAdvancedMode.splice(index, 1);
                                setAdvancedMode(newAdvancedMode);
                                if (remove) remove();
                              }}
                              className={styles.item}
                            >
                              <Trash size={24} />
                            </Button>
                          )}
                        </div>
                      </CollapsibleCard>
                    </React.Fragment>
                  );
                }}
              </ArrayField.Items>
            </>
          );
        }}
      </ArrayField>
    </div>
  );
};

const mapStateToProps = ({ chats }: ReduxStateType) => {
  return {
    chatFlowNodes: chats.chatDetails.nodes,
    allChatNodes: chats.chatDetails.allMessageTemplates
  };
};

export default connect(mapStateToProps, null)(EdgesOutComponent);
