import React from "react";

type PropsType = {
  size?: number;
};

const ChevronDown = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      fill="none"
      viewBox="0 0 24 24"
      preserveAspectRatio="xMidYMid"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 14.086L6.707 8.793a1 1 0 00-1.414 1.414l6 6a1 1 0 001.414 0l6-6a1 1 0 00-1.414-1.414L12 14.086z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChevronDown;
