import React, { useEffect } from "react";
import { useField, useFormApi, useFormState } from "informed";

import NodeGroup from "./NodeGroup";

import { getNodeTemplate } from "./helpers/getNodeTemplate";

import { JSON_CONTENT_INPUT_FORM_FIELD_NAME } from "./NodeInput";

import { JsonContent, ChatNode, CustomChangeEvent, ChatNodeTypes } from "../../../../types";
import Heading from "../../../ui/Heading";

type PropsType = {
  fieldName: string;
  disabled?: boolean;
  label?: string;
};

const arrayMove = <T,>(array: T[], fromIdx: number, toIdx: number): T[] => {
  if (toIdx < 0 || toIdx > array.length - 1) {
    // Bypass move operation if to index out of range
    return array;
  }

  const item = array[fromIdx];
  array.splice(fromIdx, 1);
  array.splice(toIdx, 0, item);
  return array;
};

const getJsonContentInputValues = (
  chatData: ChatNode[] | ChatNode,
  inputValues: { [key: string]: string } = {}
) => {
  if (Array.isArray(chatData)) {
    chatData.forEach((node: ChatNode) => {
      getJsonContentInputValues(node, inputValues);
    });
    return inputValues;
  }

  if (chatData && chatData.id && chatData.properties) {
    Object.entries(chatData.properties).forEach(([key, value]) => {
      if (chatData.properties) {
        // eslint-disable-next-line no-param-reassign
        inputValues[`${JSON_CONTENT_INPUT_FORM_FIELD_NAME}.${chatData.id}.${key}`] = value;
      }
    });
  }

  if (chatData.children) {
    chatData.children.forEach((node: ChatNode) => {
      getJsonContentInputValues(node, inputValues);
    });
  }

  return inputValues;
};

const updateNodeRecursively = (
  chatData: ChatNode[] | ChatNode,
  nodeId: string,
  func: (node: ChatNode) => ChatNode | undefined
): ChatNode[] | ChatNode | undefined => {
  if (Array.isArray(chatData)) {
    // chatData is an array of chat nodes
    return chatData
      .map((node) => {
        return updateNodeRecursively(node, nodeId, func);
      })
      .filter((node) => !!node) as ChatNode[];
  }

  // chatData is a chat node
  if (chatData.id === nodeId) {
    const updatedNode = func(chatData);

    return updatedNode;
  }

  return {
    ...chatData,
    children: (chatData as any).children // eslint-disable-line  @typescript-eslint/no-explicit-any
      ? (chatData as any).children // eslint-disable-line  @typescript-eslint/no-explicit-any
          .map((node: ChatNode) => {
            return updateNodeRecursively(node, nodeId, func);
          })
          .filter((node: ChatNode | undefined) => !!node)
      : undefined
  };
};

const JsonContentInput = ({ fieldName, disabled = false, label }: PropsType) => {
  const formState = useFormState();
  const formApi = useFormApi();
  const { fieldState, fieldApi } = useField({ name: fieldName });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const value = fieldState.value as any;
  const { setValue, setTouched } = fieldApi;

  const { errors } = formState;
  const forceOpen = Boolean(errors && errors[JSON_CONTENT_INPUT_FORM_FIELD_NAME]);

  // Initialize data field values
  useEffect(() => {
    const initialJsonContentInputState = getJsonContentInputValues(value);
    // setTheseValues merges the original and the updated state so prevents from overriding the entire values state
    formApi.setTheseValues(initialJsonContentInputState);
  }, []);

  const onInputChange = (e: CustomChangeEvent) => {
    setTouched(true);
    const inputName = Object.keys(e)[0];
    const changeValue = Object.values(e)[0];
    const inputId: string = inputName.split(".")[1];
    const inputNodeProperty: string = inputName.split(".")[2];

    const updatedChat = updateNodeRecursively(value, inputId, (node): ChatNode => {
      return {
        ...node,
        properties:
          node && node.properties
            ? {
                ...node.properties,
                [inputNodeProperty]: changeValue
              }
            : undefined
      } as ChatNode;
    });

    if (updatedChat && Array.isArray(updatedChat)) setValue(updatedChat as JsonContent);
  };

  const onAddNode = (id: string, nodeType: ChatNodeTypes) => {
    const nodeTemplate: ChatNode = getNodeTemplate(nodeType);

    const updatedChat = updateNodeRecursively(value as JsonContent, id, (nodeData): ChatNode => {
      return {
        ...nodeData,
        children: nodeData.children ? [...nodeData.children, nodeTemplate] : [nodeTemplate]
      } as ChatNode;
    });
    if (updatedChat && Array.isArray(updatedChat)) setValue(updatedChat as JsonContent);
  };

  const onRemoveNode = (id: string) => {
    const updatedChat = updateNodeRecursively(value as JsonContent, id, () => undefined);
    if (updatedChat && Array.isArray(updatedChat)) setValue(updatedChat as JsonContent);
  };

  const onMoveNode = (parentId: string, nodeId: string, direction: "up" | "down") => {
    const updatedChat = updateNodeRecursively(
      value as JsonContent,
      parentId,
      (nodeData): ChatNode => {
        if (!nodeData.children) {
          return { ...nodeData };
        }

        const fromIdx = nodeData.children.findIndex((node: ChatNode) => node.id === nodeId);
        const toIndex = direction === "up" ? fromIdx - 1 : fromIdx + 1;

        return {
          ...nodeData,
          children: [...arrayMove<ChatNode>(nodeData.children, fromIdx, toIndex)]
        } as ChatNode;
      }
    );
    if (updatedChat && Array.isArray(updatedChat)) setValue(updatedChat as JsonContent);
  };

  return (
    <>
      {label && <Heading size="META">{label}</Heading>}
      {value &&
        Array.isArray(value) &&
        value.map((chatNodeData: ChatNode) => {
          return (
            <div key={chatNodeData.id}>
              <NodeGroup
                data={chatNodeData}
                parentId={chatNodeData.id}
                typePath={[chatNodeData.type]}
                forceOpen={forceOpen}
                onChange={onInputChange}
                onAddNode={onAddNode}
                onRemoveNode={onRemoveNode}
                onMoveNode={onMoveNode}
                disabled={disabled}
              />
            </div>
          );
        })}
    </>
  );
};

export default JsonContentInput;
