import React from "react";

import AdvancedInboxFiltersForm from "./AdvancedInboxFiltersForm";
import { Form } from "../../../../../ui/Input";

import { Option } from "../../../../../../types";

import styles from "./index.module.scss";

type InboxFilterType = {
  requestBookingMode: string[];
  locations: string[];
  reasons: string[];
  practitionerIds: number[];
};

type PropsType = {
  filters: InboxFilterType;
  onFiltersChange: (filters: InboxFilterType) => void;
  bookingTypeOptions: Array<Option>;
  providerOptions: Array<Option>;
  locationOptions: Array<Option>;
  reasonOptions: Array<Option>;
  isFilterOpen: boolean;
  closeFilterDropdown: () => void;
};

type MoreFiltersFormData = {
  requestBookingMode: Array<string>;
  locations: Array<string>;
  reasons: Array<string>;
  practitionerIds: Array<number>;
};

const AdvancedInboxFilters = ({
  filters,
  onFiltersChange,
  bookingTypeOptions,
  providerOptions,
  locationOptions,
  reasonOptions,
  isFilterOpen,
  closeFilterDropdown
}: PropsType) => {
  const initialFormState = {
    requestBookingMode: filters.requestBookingMode,
    locations: filters.locations,
    reasons: filters.reasons,
    practitionerIds: filters.practitionerIds
  };

  const onSave = (formData: MoreFiltersFormData) => {
    onFiltersChange(formData);
    closeFilterDropdown();
  };

  return (
    <div className={styles.ModalContainerClass}>
      <Form
        key={filters.requestBookingMode.join(",")}
        onSubmit={(formState) => onSave(formState.values as MoreFiltersFormData)}
        initialValues={initialFormState}
      >
        <AdvancedInboxFiltersForm
          bookingTypeOptions={bookingTypeOptions}
          selectedBookingTypes={filters.requestBookingMode}
          providerOptions={providerOptions}
          locationOptions={locationOptions}
          reasonOptions={reasonOptions}
          isFilterOpen={isFilterOpen}
          onFilterClose={closeFilterDropdown}
        />
      </Form>
    </div>
  );
};

export default AdvancedInboxFilters;
