import { InputValue } from "../../types";

export const isPhone = (message?: string) => (value: InputValue) => {
  if (!value) return undefined;

  const errorMessage = message || "Please enter a valid phone number";
  const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
  const isValidPhone = phoneRegex.test(value);

  if (!isValidPhone) {
    return errorMessage;
  }
  return undefined;
};
