import React from "react";

type PropsType = {
  size?: number;
  color?: string;
};

const Italic = ({ size = 24, color = "currentColor" }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M20 5H10a1 1 0 0 0 0 2h4.84l-2.47 14H8a1 1 0 0 0 0 2h10a1 1 0 0 0 0-2h-3.6l2.47-14H20a1 1 0 0 0 0-2Z"
        fill={color}
      />
    </svg>
  );
};

export default Italic;
