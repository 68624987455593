import { FETCH_PATIENTS } from "../constants";
import isEqual from "lodash/isEqual";

import { FetchPatientsAction, FetchPatients } from "../actions";

import { ActionStatus, Patient } from "../types";

export type PatientsAction = FetchPatientsAction;

export type PatientsSyncLoading = {
  [id: string]: boolean;
};

export type PatientsReduxState = {
  patients: Patient[];
  searchParams?: FetchPatients;
  patientsLoading: boolean;
  syncLoading: PatientsSyncLoading;
};

const initialPatients: PatientsReduxState = {
  patients: [],
  searchParams: undefined,
  patientsLoading: false,
  syncLoading: {}
};

export const patientsReducer = (
  state = initialPatients,
  action: PatientsAction
): PatientsReduxState => {
  const { type } = action;
  switch (type) {
    case FETCH_PATIENTS: {
      const { status, payload } = action as FetchPatientsAction;
      const isLatestSearch =
        !state.searchParams ||
        (payload?.searchParams && isEqual(payload.searchParams, state.searchParams));

      const searchParams = status === "loading" ? payload?.searchParams : state.searchParams;

      let patients = state.patients;
      if (isLatestSearch && payload?.patients) {
        patients = payload.patients;
      }

      return {
        ...state,
        patients,
        searchParams,
        patientsLoading: status === ActionStatus.loading
      };
    }

    default:
      return state;
  }
};
