import { ChatViewMode, MapSizeData } from "../types";

export const constrainXPosition = (x: number, { nodeWidth }: MapSizeData, mapWidth: number) => {
  const minXPosition = 0 + nodeWidth / 2;
  const maxXPosition = mapWidth - nodeWidth / 2;

  if (x < minXPosition) {
    return minXPosition;
  }
  if (x > maxXPosition) {
    return maxXPosition;
  }
  return x;
};

export const constrainYPosition = (y: number, { nodeHeight }: MapSizeData, mapHeight: number) => {
  const minYPosition = 0 + nodeHeight / 2;
  const maxYPosition = mapHeight - nodeHeight / 2;

  if (y < minYPosition) {
    return minYPosition;
  }
  if (y > maxYPosition) {
    return maxYPosition;
  }
  return y;
};

export const getMapSizeData = (viewMode: ChatViewMode) => {
  switch (viewMode) {
    case ChatViewMode.MAP_LARGE: {
      return {
        nodeWidth: 290,
        nodeHeight: 140,
        nodeSpacingX: 290 + 60,
        nodeSpacingY: 140 + 60
      };
    }
    case ChatViewMode.MAP_SMALL:
    default: {
      return {
        nodeWidth: 80,
        nodeHeight: 80,
        nodeSpacingX: 80 + 40,
        nodeSpacingY: 80 + 40
      };
    }
  }
};
