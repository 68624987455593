import React, { useEffect } from "react";
import { connect } from "react-redux";

import MikataAdminUsersTable from "./MikataAdminUsersTable";
import { PermissionsGuard } from "../../../hooks/usePermissions";
import Heading from "../../ui/Heading";
import Button from "../../ui/Button";
import {
  openModal as openModalAction,
  OpenModal,
  fetchRoles as fetchRolesAction
} from "../../../actions";
import { AdminModalTypes } from "../../../constants";
import { Permissions, ReduxStateType } from "../../../types";

import styles from "./index.module.scss";

type PropsType = {
  fetchRoles: (organizationId: number | null) => void;
  openModal: OpenModal;
};

const MikataUsersPage = ({ fetchRoles, openModal }: PropsType) => {
  useEffect(() => {
    fetchRoles(null);
  }, []);

  return (
    <>
      <PermissionsGuard
        requiresVerifiedOrg={false}
        requiredPermissions={[Permissions.CREATE_ADMIN_USER]}
      >
        <div className={styles.Header}>
          <Heading>Manage Mikata Users</Heading>
          <Button
            className={styles.Button}
            onClick={() => {
              openModal(AdminModalTypes.ADD_ADMIN_USER);
            }}
          >
            <span className={styles.ButtonText}>Add User</span>
          </Button>
        </div>
      </PermissionsGuard>
      <MikataAdminUsersTable />
    </>
  );
};

const mapStateToProps = ({ users }: ReduxStateType) => {
  return {
    roles: users.roles
  };
};

export default connect(mapStateToProps, {
  fetchRoles: fetchRolesAction,
  openModal: openModalAction
})(MikataUsersPage);
