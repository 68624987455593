import {
  ContentBlock,
  ContentState,
  EditorState,
  Modifier,
  SelectionState,
  genKey
} from "draft-js";
import { MikaAnchorEntityData, MikaAnchorStatus } from "../types";

type PropsType = {
  editorState: EditorState;
  anchorData: {
    anchorName: string;
    anchorTitle: { default: string; loading: string };
  };
};

const splitBlock = (editorState: EditorState): EditorState => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const currentBlockKey = selectionState.getAnchorKey();

  const currentBlock = contentState.getBlockForKey(currentBlockKey);
  const currentBlockType = currentBlock.getType();

  if (currentBlockType === "MIKA_ANCHOR") {
    return editorState;
  }

  const updatedContentState = Modifier.splitBlock(contentState, selectionState);

  const editorStateWithSplit = EditorState.push(editorState, updatedContentState, "split-block");

  return editorStateWithSplit;
};

const applyMikaAnchorEntity = (
  editorState: EditorState,
  mikaAnchorBlockKey: string,
  anchorData: {
    anchorName: string;
    anchorTitle: { default: string; loading: string };
  }
): EditorState => {
  const contentState = editorState.getCurrentContent();

  const contentStateWithMikaAnchorEntity = contentState.createEntity("ANCHOR", "IMMUTABLE", {
    status: MikaAnchorStatus.PENDING,
    anchorName: anchorData.anchorName,
    anchorTitle: anchorData.anchorTitle
  } as MikaAnchorEntityData);

  const mikaAnchorEntityKey = contentStateWithMikaAnchorEntity.getLastCreatedEntityKey();
  const newMikaAnchorBlock = contentState.getBlockForKey(mikaAnchorBlockKey);
  const endOfMikaAnchorBlockLength = newMikaAnchorBlock.getLength();

  const mikaAnchorSelectionState = SelectionState.createEmpty(mikaAnchorBlockKey).merge({
    anchorOffset: 0,
    focusOffset: endOfMikaAnchorBlockLength,
    anchorKey: mikaAnchorBlockKey,
    focusKey: mikaAnchorBlockKey
  });

  const contentStateWithAppliedMikaAnchorEntity = Modifier.applyEntity(
    contentStateWithMikaAnchorEntity,
    mikaAnchorSelectionState,
    mikaAnchorEntityKey
  );

  const editorStateWithMikaAnchorEntity = EditorState.push(
    editorState,
    contentStateWithAppliedMikaAnchorEntity,
    "apply-entity"
  );

  return editorStateWithMikaAnchorEntity;
};

export const injectAnchorContent = ({ editorState, anchorData }: PropsType) => {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const currentBlockKey = selectionState.getAnchorKey();

  // Split block at selection
  const editorStateWithSplit = splitBlock(editorState);
  const contentStateWithSplit = editorStateWithSplit.getCurrentContent();
  const currentBlock = contentStateWithSplit.getBlockForKey(currentBlockKey);

  // Create and inject new anchor block at split
  const blockMap = contentStateWithSplit.getBlockMap();
  const blocksBefore = blockMap.toSeq().takeUntil((block) => block === currentBlock);
  const blocksAfter = blockMap
    .toSeq()
    .skipUntil((block) => block === currentBlock)
    .rest();

  const newBlockKey = genKey();
  const newBlocks = [
    [currentBlock.getKey(), currentBlock],
    [
      newBlockKey,
      new ContentBlock({
        key: newBlockKey,
        text: " ",
        type: "MIKA_ANCHOR",
        entityRanges: [{ key: newBlockKey, length: 1, offset: 0 }]
      })
    ]
  ];

  const newBlockMap = blocksBefore.concat(newBlocks, blocksAfter).toOrderedMap();

  const selectionStateWithBlock = SelectionState.createEmpty(newBlockKey).merge({
    anchorKey: newBlockKey,
    anchorOffset: 0,
    focusKey: newBlockKey
  });

  const contentStateWithBlock = contentState.merge({
    blockMap: newBlockMap,
    selectionBefore: selectionStateWithBlock,
    selectionAfter: selectionStateWithBlock
  });

  const editorStateWithBlock = EditorState.push(
    editorState,
    contentStateWithBlock as ContentState,
    "insert-fragment"
  );

  // Create and apply anchor entity to block
  const editorStateWithMikaAnchorEntity = applyMikaAnchorEntity(
    editorStateWithBlock,
    newBlockKey,
    anchorData
  );

  return editorStateWithMikaAnchorEntity;
};
