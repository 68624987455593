import { InputValue } from "../../types";

export const isEmail = (message?: string) => (value: InputValue) => {
  const errorMessage = message || "Please enter a valid email";

  if (!value) return undefined;

  if (typeof value !== "string") return errorMessage;

  if (!value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/gim)) {
    return errorMessage;
  }
  return undefined;
};
