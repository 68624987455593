import {
  CLONE_NOTE_TEMPLATE,
  FETCH_NOTE_TEMPLATES,
  FETCH_NOTE_TEMPLATE_SUGGESTIONS,
  GET_NOTE_TEMPLATE,
  UPDATE_NOTE_TEMPLATE,
  DELETE_NOTE_TEMPLATE,
  SET_DEFAULT_NOTE_TEMPLATES
} from "../constants";

import {
  CloneNoteTemplateAction,
  FetchNoteTemplatesAction,
  FetchNoteTemplateSuggestionsAction,
  GetNoteTemplateAction,
  UpdateNoteTemplateAction,
  DeleteNoteTemplateAction,
  SetDefaultNoteTemplateAction
} from "../actions";

import { NoteTemplate } from "../types";

export type NoteTemplatesAction =
  | CloneNoteTemplateAction
  | FetchNoteTemplatesAction
  | FetchNoteTemplateSuggestionsAction
  | GetNoteTemplateAction
  | UpdateNoteTemplateAction
  | SetDefaultNoteTemplateAction
  | DeleteNoteTemplateAction;

export type NoteTemplatesReduxState = {
  noteTemplates: NoteTemplate[];
  noteTemplateSuggestions: NoteTemplate[];
  noteTemplateSuggestionsLoading: boolean;
  noteTemplateTags?: Record<string, string[]>;
  noteTemplatesLoading: boolean;
  noteTemplateDetailsLoading: boolean;
  noteTemplatesUpdateLoading: boolean;
  noteTemplatesDeleteLoading: boolean;
  noteTemplate?: NoteTemplate;
};

const initialNotes: NoteTemplatesReduxState = {
  noteTemplates: [],
  noteTemplateSuggestions: [],
  noteTemplateSuggestionsLoading: false,
  noteTemplateTags: undefined,
  noteTemplatesLoading: false,
  noteTemplateDetailsLoading: false,
  noteTemplatesUpdateLoading: false,
  noteTemplatesDeleteLoading: false,
  noteTemplate: undefined
};

export const noteTemplatesReducer = (
  state = initialNotes,
  action: NoteTemplatesAction
): NoteTemplatesReduxState => {
  const { type } = action;
  switch (type) {
    case FETCH_NOTE_TEMPLATES: {
      const { status, payload } = action as FetchNoteTemplatesAction;

      return {
        ...state,
        noteTemplates: payload?.noteTemplates || state.noteTemplates,
        noteTemplateTags: payload?.noteTemplateTags ||
          state.noteTemplateTags || { type: ["patient", "Letters"] },
        noteTemplatesLoading: status === "loading"
      };
    }
    case FETCH_NOTE_TEMPLATE_SUGGESTIONS: {
      const { status, payload } = action as FetchNoteTemplateSuggestionsAction;

      return {
        ...state,
        noteTemplateSuggestions: payload?.noteTemplateSuggestions || state.noteTemplateSuggestions,
        noteTemplateSuggestionsLoading: status === "loading"
      };
    }
    case CLONE_NOTE_TEMPLATE: {
      const { status, payload } = action as CloneNoteTemplateAction;

      return {
        ...state,
        noteTemplate: payload?.noteTemplate || state.noteTemplate,
        noteTemplatesLoading: status === "loading"
      };
    }
    case SET_DEFAULT_NOTE_TEMPLATES: {
      const { status, payload } = action as SetDefaultNoteTemplateAction;

      return {
        ...state,
        noteTemplate: payload?.noteTemplate || state.noteTemplate,
        noteTemplatesLoading: status === "loading"
      };
    }
    case GET_NOTE_TEMPLATE: {
      const { status, payload } = action as GetNoteTemplateAction;

      return {
        ...state,
        noteTemplate: payload?.noteTemplate || state.noteTemplate,
        noteTemplateDetailsLoading: status === "loading"
      };
    }
    case UPDATE_NOTE_TEMPLATE: {
      const { status, payload } = action as UpdateNoteTemplateAction;

      return {
        ...state,
        noteTemplate: payload?.noteTemplate || state.noteTemplate,
        noteTemplatesUpdateLoading: status === "loading"
      };
    }
    case DELETE_NOTE_TEMPLATE: {
      const { status, payload } = action as DeleteNoteTemplateAction;

      return {
        ...state,
        noteTemplate: payload?.noteTemplate || state.noteTemplate,
        noteTemplatesDeleteLoading: status === "loading"
      };
    }
    default:
      return state;
  }
};
