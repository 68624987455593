import React from "react";

import Heading from "../../../Heading";
import Text from "../../../Text";
import Image from "../../../Image";

const ScribeMultiTemplate = (): React.ReactElement => {
  return (
    <div>
      <Heading size="M" component="h3" darkMode>
        Multi-templates
      </Heading>
      <Text size="S" darkMode>
        You can choose different templates offered by Mikata for your appointments.
      </Text>
      <br />
      <Text size="S" darkMode>
        Go beyond a basic SOAP note with customizable templates for referrals and more
      </Text>
      <br />
      <Image src="/img/announcements/multiTemplate.png" />
    </div>
  );
};

export default ScribeMultiTemplate;
