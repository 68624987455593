import React from "react";

type PropsType = {
  size?: number;
  color?: string;
};

const Notes = ({ size = 24, color = "currentColor" }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 1C7.11929 1 6 2.11929 6 3.5V4C4.34315 4 3 5.34315 3 7V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V7C21 5.34315 19.6569 4 18 4H11V3.5C11 2.11929 9.88071 1 8.5 1ZM9 4V3.5C9 3.22386 8.77614 3 8.5 3C8.22386 3 8 3.22386 8 3.5V4H9ZM6 6V7.5C6 8.88071 7.11929 10 8.5 10C9.88071 10 11 8.88071 11 7.5V7.25C11 6.69772 10.5523 6.25 10 6.25C9.44772 6.25 9 6.69772 9 7.25V7.5C9 7.77614 8.77614 8 8.5 8C8.22386 8 8 7.77614 8 7.5V6H18C18.5523 6 19 6.44772 19 7V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V7C5 6.44772 5.44772 6 6 6ZM7 12C6.44772 12 6 12.4477 6 13C6 13.5523 6.44772 14 7 14H11C11.5523 14 12 13.5523 12 13C12 12.4477 11.5523 12 11 12H7ZM6 16C6 15.4477 6.44772 15 7 15H15C15.5523 15 16 15.4477 16 16C16 16.5523 15.5523 17 15 17H7C6.44772 17 6 16.5523 6 16Z"
        fill={color}
      />
    </svg>
  );
};

export default Notes;
