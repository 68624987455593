import React, { Dispatch, SetStateAction, useState } from "react";
import { useFormApi, ArrayField } from "informed";

import Heading from "../../../ui/Heading";
import Text from "../../../ui/Text";
import Card from "../../../ui/Card";
import Button from "../../../ui/Button";
import Drawer from "../../../ui/Drawer";
import Status from "../../../ui/Status";

import {
  TextInput,
  ToggleInput,
  SelectInput,
  CheckboxGroup,
  TextAreaInput,
  JsonLogicInput
} from "../../../ui/Input";

import {
  reasonLabelValidator,
  availabilityRangeValidator,
  cardTextValidator,
  durationValidator,
  defaultEmrReasonValidator
} from "./validators/formValidators";
import { ContextVariable } from "../../../ui/Input/JsonLogicInput/types";
import { isJson } from "../../../../utils/validators/isJson";

import {
  BookingReason,
  Reason,
  Location,
  Practitioner,
  ScheduleTag,
  ChatNodePayloadOptions
} from "../../../../types";

import styles from "./index.module.scss";

type FormState = {
  bookingReasons: BookingReason[];
};

type PropsType = {
  schedules: ScheduleTag[] | null;
  reasons: Array<Reason>;
  practitioners: Array<Practitioner>;
  locations: Array<Location>;
  values: ChatNodePayloadOptions;
  forceClose: boolean;
  setForceClose: Dispatch<SetStateAction<boolean>>;
  contextVariables: ContextVariable[];
};

const defaultInitialBookingReason: BookingReason = {
  excludedLocationIds: [],
  excludedPractitionerIds: [],
  cardText: "",
  note: "",
  duration: null,
  defaultEmrReasonId: "",
  isDefault: false,
  skipBooking: false,
  autoBookable: false,
  requiresPrimaryPractitioner: false,
  hideLocationOptions: true,
  hidePractitionerOptions: false,
  selectionConditions: null,
  includedScheduleTags: [],
  availabilityRangeEndWeeks: 4
};

const BookingReasonsConfig = ({
  schedules,
  reasons,
  locations,
  practitioners,
  values,
  forceClose,
  setForceClose,
  contextVariables
}: PropsType) => {
  const { setValue } = useFormApi();
  const [rangeCount, setRangeCount] = useState(0);
  const practitionerOptions = practitioners.map((practitioner) => ({
    label: practitioner.displayName || practitioner.fullName,
    value: practitioner.id.toString()
  }));
  const locationOptions = locations.map((location) => ({
    label: location.displayName || location.fullName,
    value: location.id.toString()
  }));
  const scheduleOptions = (schedules || []).map((scheduleTag) => ({
    label: scheduleTag.name,
    value: scheduleTag.name
  }));

  const jsonValidator = isJson("Please enter a valid JSON string");

  const getBookingMode = (values: FormState, idx: number) => {
    if (!values.bookingReasons?.[idx]?.skipBooking && values.bookingReasons?.[idx]?.autoBookable) {
      return "autoBooking";
    }
    if (!values.bookingReasons?.[idx]?.skipBooking) {
      return "booking";
    }
    if (values.bookingReasons?.[idx]?.skipBooking) {
      return "request";
    }
    return "";
  };

  return (
    <ArrayField name="bookingReasons">
      {({ addWithInitialValue, fields }) => {
        return (
          <>
            <div>
              {fields.length === 0 && <Text>No Booking reasons configured</Text>}
              <ArrayField.Items>
                {({ remove, index }) => {
                  const cardTextPlaceholder = values?.bookingReasons?.[index]?.skipBooking
                    ? `Great! looks like we need to create an appointment request for you.` // TODO: Update placeholder copy
                    : `Great! Continue to see dates and times.`;

                  return (
                    <Card className={styles.BookingConfigCard} key={`bookingReasons_${index}`}>
                      <Drawer
                        headerContent={
                          <div className={styles.DrawerHeaderContent}>
                            <Heading className={styles.Heading}>
                              {values?.bookingReasons?.[index]?.label || "New Reason"}
                            </Heading>
                            <div className={styles.DrawerHeaderContentTags}>
                              <Status
                                className={styles.DrawerHeaderContentTag}
                                value={
                                  values?.bookingReasons?.[index]?.isDefault ? "isDefault" : ""
                                }
                                options={[
                                  {
                                    label: "Default",
                                    value: "isDefault"
                                  },
                                  {
                                    label: "",
                                    value: ""
                                  }
                                ]}
                                configMap={{ isDefault: "green" }}
                              />
                              <Status
                                className={styles.DrawerHeaderContentTag}
                                value={
                                  values?.bookingReasons
                                    ? getBookingMode(
                                        { bookingReasons: values?.bookingReasons },
                                        index
                                      )
                                    : ""
                                }
                                options={[
                                  {
                                    label: "Auto-booking",
                                    value: "autoBooking"
                                  },
                                  {
                                    label: "Booking",
                                    value: "booking"
                                  },
                                  {
                                    label: "Request",
                                    value: "request"
                                  }
                                ]}
                                configMap={{
                                  autoBooking: "purple",
                                  booking: "blue",
                                  request: "gray"
                                }}
                              />
                            </div>
                          </div>
                        }
                        collapse={forceClose}
                        initialOpen
                      >
                        <TextInput
                          label="Reason Name"
                          fieldName="label"
                          validate={(values: FormState) => {
                            const error = reasonLabelValidator(values);
                            if (error) {
                              setForceClose(false);
                            }
                            return error;
                          }}
                        />
                        <TextInput
                          label="Card Text"
                          info="This text will show in on the appointment booking card in the chat flow."
                          fieldName="cardText"
                          placeholder={cardTextPlaceholder}
                          validate={(values: FormState) => {
                            const error = cardTextValidator(values);
                            if (error) {
                              setForceClose(false);
                            }
                            return error;
                          }}
                        />
                        <TextInput
                          label="Availability Range End (Weeks)"
                          type="number"
                          fieldName="availabilityRangeEndWeeks"
                          warningMessage={`${
                            rangeCount > 12 ? "more than 3 months range is not recommended" : ""
                          }`}
                          validate={(values: FormState) => {
                            setRangeCount(Number(values));
                            const error = availabilityRangeValidator(values);
                            if (error) {
                              setForceClose(false);
                            }
                            return error;
                          }}
                        />
                        <div className={styles.CardGrid}>
                          <ToggleInput
                            fieldName="isDefault"
                            labelUnchecked="Not Default"
                            labelChecked="Default"
                            wrapperClassName={styles.ToggleWrapper}
                          />
                          <ToggleInput
                            fieldName="skipBooking"
                            labelUnchecked="Booking Reason"
                            labelChecked="Request Reason"
                            wrapperClassName={styles.ToggleWrapper}
                            customOnChange={(event) => {
                              if (event[`bookingReasons[${index}].skipBooking`]) {
                                setValue(`bookingReasons[${index}].autoBookable`, false);
                                setValue(
                                  `bookingReasons[${index}].requiresPrimaryPractitioner`,
                                  false
                                );
                              }
                            }}
                          />
                          <ToggleInput
                            fieldName="autoBookable"
                            labelUnchecked="Not Auto-bookable"
                            labelChecked="Auto-bookable"
                            wrapperClassName={styles.ToggleWrapper}
                            disabled={values?.bookingReasons?.[index]?.skipBooking}
                          />
                          <ToggleInput
                            fieldName="requiresPrimaryPractitioner"
                            labelUnchecked="Does not require primary practitioner"
                            labelChecked="Requires primary practitioner"
                            wrapperClassName={styles.ToggleWrapper}
                            disabled={values?.bookingReasons?.[index]?.skipBooking}
                          />

                          <TextInput
                            label="Duration"
                            type="number"
                            info="Duration in minutes. Use multiples of 5."
                            fieldName="duration"
                            validate={(values: FormState) => {
                              const error = durationValidator(values);
                              if (error) {
                                setForceClose(false);
                              }
                              return error;
                            }}
                          />
                          <SelectInput
                            label="Emr Reason Id"
                            fieldName="defaultEmrReasonId"
                            options={reasons.map((reason) => ({
                              label: reason.value,
                              value: reason.emrReasonId || reason.value
                            }))}
                            validate={(values: FormState) => {
                              const error = defaultEmrReasonValidator(values);
                              if (error) {
                                setForceClose(false);
                              }
                              return error;
                            }}
                          />
                          <ToggleInput
                            fieldName="hidePractitionerOptions"
                            labelUnchecked="Show Practitioners"
                            labelChecked="Hide Practitioners"
                            wrapperClassName={styles.ToggleWrapper}
                          />
                          <ToggleInput
                            fieldName="hideLocationOptions"
                            labelUnchecked="Show Locations"
                            labelChecked="Hide Locations"
                            wrapperClassName={styles.ToggleWrapper}
                          />
                          <CheckboxGroup
                            label="Excluded Providers"
                            fieldName="excludedPractitionerIds"
                            showCheckAll={practitionerOptions.length > 9}
                            options={practitionerOptions}
                          />
                          <CheckboxGroup
                            label="Excluded Locations"
                            fieldName="excludedLocationIds"
                            showCheckAll={locationOptions.length > 9}
                            options={locationOptions}
                          />
                        </div>
                        <TextAreaInput label="Notes" fieldName="note" />
                        <JsonLogicInput
                          label="Match Conditions"
                          fieldName="selectionConditions"
                          contextVariables={contextVariables}
                          validate={jsonValidator}
                        />
                        <CheckboxGroup
                          label="Included Schedules"
                          fieldName="includedScheduleTags"
                          showCheckAll={scheduleOptions.length > 9}
                          options={scheduleOptions}
                        />

                        <Button inline type="button" onClick={remove}>
                          Remove
                        </Button>
                      </Drawer>
                    </Card>
                  );
                }}
              </ArrayField.Items>
            </div>
            <Button
              inline
              type="button"
              onClick={() => addWithInitialValue(defaultInitialBookingReason)}
            >
              + Add Booking Reason
            </Button>
          </>
        );
      }}
    </ArrayField>
  );
};

export default BookingReasonsConfig;
