import React from "react";

type PropsType = {
  size?: number;
};
const Phn = ({ size = 28 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7C1 5.34315 2.34315 4 4 4H20C21.6569 4 23 5.34315 23 7V17C23 18.6569 21.6569 20 20 20H4C2.34315 20 1 18.6569 1 17V7ZM4 6C3.44772 6 3 6.44772 3 7V17C3 17.5523 3.44772 18 4 18H20C20.5523 18 21 17.5523 21 17V7C21 6.44772 20.5523 6 20 6H4ZM14 10.5C14 9.94772 14.4477 9.5 15 9.5L19 9.5C19.5523 9.5 20 9.94771 20 10.5C20 11.0523 19.5523 11.5 19 11.5L15 11.5C14.4477 11.5 14 11.0523 14 10.5ZM15 12.5C14.4477 12.5 14 12.9477 14 13.5C14 14.0523 14.4477 14.5 15 14.5H17C17.5523 14.5 18 14.0523 18 13.5C18 12.9477 17.5523 12.5 17 12.5H15ZM8.5 9C8.08579 9 7.75 9.33579 7.75 9.75C7.75 10.1642 8.08579 10.5 8.5 10.5C8.91421 10.5 9.25 10.1642 9.25 9.75C9.25 9.33579 8.91421 9 8.5 9ZM5.75 9.75C5.75 8.23122 6.98122 7 8.5 7C10.0188 7 11.25 8.23122 11.25 9.75C11.25 11.2688 10.0188 12.5 8.5 12.5C6.98122 12.5 5.75 11.2688 5.75 9.75ZM6 16C6 15.4477 6.44772 15 7 15H10C10.5523 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16C13 14.3431 11.6569 13 10 13H7C5.34315 13 4 14.3431 4 16C4 16.5523 4.44772 17 5 17C5.55228 17 6 16.5523 6 16Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Phn;
