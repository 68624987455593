import React from "react";

type PropsType = {
  size?: number;
};

const Exclamation = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      fill="none"
      viewBox="0 0 24 24"
      preserveAspectRatio="xMidYMid"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 6a1 1 0 112 0v8a1 1 0 11-2 0V6zm.293 12.707a1 1 0 111.414-1.414 1 1 0 01-1.414 1.414z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Exclamation;
