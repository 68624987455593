import { EditorState } from "draft-js";

import { MikaAnchorEntityData, MikaAnchorKeyMap } from "../types";

const getEntities = (editorState: EditorState | null, entityType: string) => {
  const content = editorState?.getCurrentContent();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const entities: any = [];
  content?.getBlocksAsArray().forEach((block) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let selectedEntity: any = null;
    block.findEntityRanges(
      (character) => {
        if (character.getEntity() !== null) {
          const entity = content.getEntity(character.getEntity());
          if (!entityType || (entityType && entity.getType() === entityType)) {
            selectedEntity = {
              entityKey: character.getEntity(),
              blockKey: block.getKey(),
              entity: content.getEntity(character.getEntity())
            };
            return true;
          }
        }
        return false;
      },
      (start, end) => {
        entities.push({ ...selectedEntity, start, end });
      }
    );
  });
  return entities;
};

export const getMikaAnchorDataMap = (noteId: number, editorState: EditorState | null) => {
  const anchorDataMap: { [anchorName: string]: MikaAnchorEntityData } = {};

  const allBlockEntities = getEntities(editorState, "ANCHOR");

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allBlockEntities.forEach((blockEntityData: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const entityData = (blockEntityData as any).entity.getData();
    const entityAnchorName = entityData.anchorName;

    anchorDataMap[entityAnchorName] = entityData;
  });

  return anchorDataMap;
};

export const getMikaAnchorStatuses = (noteId: number, editorState: EditorState | null) => {
  const anchorDataMap = getMikaAnchorDataMap(noteId, editorState);

  return Object.values(anchorDataMap).map(
    (anchorEntityData: MikaAnchorEntityData) => anchorEntityData?.status
  );
};

export const getMikaAnchorKeyMap = (noteId: number, editorState: EditorState | null) => {
  const anchorKeyMap: MikaAnchorKeyMap = {};

  const allBlockEntities = getEntities(editorState, "ANCHOR");

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allBlockEntities.forEach((blockEntityData: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const anchorEntityKey: any = (blockEntityData as any).entityKey;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const entityData = (blockEntityData as any).entity.getData();
    const entityAnchorName = entityData.anchorName;

    anchorKeyMap[entityAnchorName] = anchorEntityKey;
  });

  return anchorKeyMap;
};
