import React from "react";

type PropsType = {
  size?: number;
  color?: string;
};

const OrderedList = ({ size = 24, color = "currentColor" }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path d="M6.86 5.12V10h-.94V6.64H4.8v-.77h.6c.34 0 .52-.14.52-.49v-.26Z" fill={color} />
      <path
        d="M7.8 15.1v.9H4.37v-.77c.78-.47 2.29-1.47 2.29-2.56 0-.4-.19-.7-.63-.7s-.73.52-.76 1l-.93-.07a1.73 1.73 0 0 1 1.71-1.8 1.49 1.49 0 0 1 1.56 1.56 3.2 3.2 0 0 1-1.69 2.44Z"
        fill={color}
      />
      <path
        d="m4.2 20.43.94-.06c0 .52.4.79.93.79s.86-.19.86-.71-.6-.63-1.1-.63v-.74c.67 0 .84-.24.84-.55A.57.57 0 0 0 6 18a.61.61 0 0 0-.66.63l-.94-.06a1.49 1.49 0 0 1 1.62-1.47 1.43 1.43 0 0 1 1.6 1.3 1 1 0 0 1-.52.94 1.16 1.16 0 0 1 .78 1.18A1.64 1.64 0 0 1 6 22.07a1.65 1.65 0 0 1-1.8-1.64Z"
        fill={color}
      />
      <path
        d="M10 12h12a1 1 0 0 1 1 1 1 1 0 0 1-1 1H10a1 1 0 0 1-1-1 1 1 0 0 1 1-1Z"
        fill={color}
      />
      <path
        d="M10 18h12a1 1 0 0 1 1 1 1 1 0 0 1-1 1H10a1 1 0 0 1-1-1 1 1 0 0 1 1-1Z"
        fill={color}
      />
      <path d="M10 6h12a1 1 0 0 1 1 1 1 1 0 0 1-1 1H10a1 1 0 0 1-1-1 1 1 0 0 1 1-1Z" fill={color} />
    </svg>
  );
};

export default OrderedList;
