import React, { useContext } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import moment from "moment-timezone";

import Heading from "../../../ui/Heading";
import { FloatModal } from "../../../ui/Modal";
import Button from "../../../ui/Button";
import Loader from "../../../ui/Loader";
import Text from "../../../ui/Text";
import Tooltip from "../../../ui/Tooltip";
import { Clock, CircleCheck, CircleExclamation } from "../../../ui/Icon";

import { Form } from "../../../ui/Input";

import { Patient, AvailableSlot, ReduxStateType, Reason } from "../../../../types";

import {
  closeModal,
  bookAppointment as bookAppointmentAction,
  BookAppointmentData
} from "../../../../actions";

import styles from "./index.module.scss";
import { OrganizationContext } from "../../../providers/OrganizationProvider";
import formatPhone from "../../../../utils/formatPhone";
import { generateReasonLabel } from "../../../../utils/generateReasonLabel";

type PropsType = {
  loading?: boolean;
  appointmentRequestId: number;
  appointmentSlot: AvailableSlot;
  patient: Patient;
  reason: Reason;
  timezone: string;
  closeModalConnect: () => void;
  bookAppointment: (data: BookAppointmentData, onSuccess: () => void) => void;
  onSuccess: () => void;
};

const BookAppointment = ({
  loading,
  appointmentRequestId,
  appointmentSlot,
  patient,
  reason,
  timezone,
  closeModalConnect,
  bookAppointment,
  onSuccess
}: PropsType) => {
  // Organization data will be available on different objects depending if current user is mikata or clinic manager
  const currentOrg = useContext(OrganizationContext);

  const primaryProviderIds = patient.primaryProviders.map((provider) => provider.id);

  if (!currentOrg) return <Loader small center />;

  const save = async () => {
    const data = {
      appointmentRequestId,
      patient,
      reason,
      availableSlot: appointmentSlot
    };
    bookAppointment(data, onSuccess);
  };

  const buttonDisabled = loading;

  return (
    <FloatModal isOpen onClose={closeModalConnect}>
      <Heading size="M" className={styles.NoPadding}>
        Booking Summary
      </Heading>
      <div className={styles.InfoGroup}>
        <Heading size="META" component="h5" className={styles.Heading}>
          {`Patient ID: ${patient?.emrPatientId}`}
        </Heading>
      </div>
      <hr className={styles.Divider} />

      <Form onSubmit={() => save()}>
        <div className={styles.MainGrid}>
          <div className={styles.Column}>
            <Text bold>Patient details</Text>
            <div className={styles.InfoGroup}>
              <Heading size="META" component="h5">
                Patient Name
              </Heading>
              <Text>{`${patient?.firstName} ${patient?.lastName}`}</Text>
            </div>
            <div className={styles.InfoGroup}>
              <Heading size="META" component="h5">
                Personal Health Number
              </Heading>
              <Text>{patient.personalHealthNumber}</Text>
            </div>
            <div className={styles.InfoGroup}>
              <Heading size="META" component="h5">
                Phone Number
              </Heading>
              <div className={styles.InfoValueRow}>
                <Text>
                  {patient.phoneNumberToDisplay
                    ? formatPhone(patient.phoneNumberToDisplay) || "-"
                    : null}
                </Text>
                <Tooltip
                  icon={
                    <div
                      className={cx(styles.InfoValueIcon, {
                        [styles.InfoValueIconCheck]: patient.isVerifiedMobile,
                        [styles.InfoValueIconExclamation]: !patient.isVerifiedMobile
                      })}
                    >
                      {patient.isVerifiedMobile ? (
                        <CircleCheck size={18} />
                      ) : (
                        <CircleExclamation size={18} />
                      )}
                    </div>
                  }
                  size="S"
                >
                  <Text size="S">
                    {patient.isVerifiedMobile
                      ? "Verified mobile number"
                      : "Unverified mobile number"}
                  </Text>
                </Tooltip>
              </div>
            </div>
            <div className={styles.InfoGroup}>
              <Heading size="META" component="h5">
                Email Address
              </Heading>
              <Text>{patient.patientEmail}</Text>
            </div>
            <div className={styles.InfoGroup}>
              <Heading size="META" component="h5">
                Primary Provider(s)
              </Heading>
              {patient.primaryProviders.length ? (
                patient.primaryProviders.map((provider) => {
                  return <Text key={provider.id}>{provider.practitionerName}</Text>;
                })
              ) : (
                <Text>-</Text>
              )}
            </div>
          </div>
          <div className={styles.Column}>
            <Text bold>Appointment reason</Text>
            <div className={styles.InfoGroup}>
              <Heading size="META" component="h5">
                Associated Reason
              </Heading>
              <Text>{generateReasonLabel(reason)}</Text>
            </div>

            <Text bold className={styles.SubHeading}>
              Appointment time
            </Text>

            <div className={styles.InfoGroup}>
              <Heading size="META" component="h5">
                Date and Time
              </Heading>
              <Text>
                {`${moment(appointmentSlot.startTime)
                  .tz(timezone)
                  .format("MMMM DD, YYYY")} at ${moment(appointmentSlot.startTime).format(
                  "h:mma"
                )}`}
              </Text>
              <Text size="XS" bold className={styles.Clock}>
                <Clock size={10} />
                {`${appointmentSlot.duration} MIN`}
              </Text>
            </div>

            <div className={styles.InfoGroup}>
              <Heading size="META" component="h5">
                Location
              </Heading>
              <Text>{appointmentSlot.locationName}</Text>
            </div>

            <div className={styles.InfoGroup}>
              <Heading className={styles.ProviderHeading} size="META" component="h5">
                Provider
                {!!primaryProviderIds.length &&
                  !primaryProviderIds.includes(appointmentSlot.practitionerId) && (
                    <Tooltip
                      icon={
                        <div className={styles.ProviderHeadingIcon}>
                          <CircleExclamation size={14} />
                        </div>
                      }
                      size="S"
                    >
                      <Text className={styles.ProviderHeadingTooltip} size="S">
                        Not a primary provider of patient.
                      </Text>
                    </Tooltip>
                  )}
              </Heading>
              <Text>{appointmentSlot.practitionerName}</Text>
            </div>
          </div>
        </div>

        {!!primaryProviderIds.length &&
          !primaryProviderIds.includes(appointmentSlot.practitionerId) && (
            <div>
              <Text
                size="S"
                className={!patient.isVerifiedMobile ? styles.WarningNoPadding : styles.Warning}
              >
                This patient will not be booked under their main provider.
              </Text>
            </div>
          )}
        {!patient.isVerifiedMobile && (
          <div>
            <Text size="S" className={styles.Warning}>
              WARNING: This patient doesn’t have valid contact information and no automated messages
              will be sent. Consider updating your records before completing this request, or
              contact the patient directly to inform them of the status of their appointment.
            </Text>
          </div>
        )}
        <hr className={styles.Divider} />
        <div className={styles.ButtonGroup}>
          <Button inline onClick={closeModalConnect}>
            Go back
          </Button>
          <Button id="bookAppointmentButton" type="submit" size="S" disabled={buttonDisabled}>
            Confirm and book
          </Button>
        </div>
      </Form>
    </FloatModal>
  );
};

const mapStateToProps = ({ appointmentRequestsInfo }: ReduxStateType) => {
  return {
    loading: appointmentRequestsInfo.bookedLoading
  };
};

export default connect(mapStateToProps, {
  closeModalConnect: closeModal,
  bookAppointment: bookAppointmentAction
})(BookAppointment);
