import React, { Dispatch, SetStateAction, useMemo } from "react";

import { ArrayField } from "informed";
import { Trash, Info } from "../../../../../../../../ui/Icon";
import Button from "../../../../../../../../ui/Button";
import { TextInput } from "../../../../../../../../ui/Input";
import Heading from "../../../../../../../../ui/Heading";
import ToolTip from "../../../../../../../../ui/Tooltip";
import { isRequired } from "../../../../../../../../../utils/validators/isRequired";

import { ChatFlowsNodes } from "../../../../../../../../../types";
import BaseChatNodeForm, {
  BaseFormValues,
  FormValues,
  SingleSelectFormValues
} from "../../BaseChatNodeForm";

import styles from "../index.module.scss";

const questionValidator = isRequired("Please enter a question");
const responseValidator = isRequired("Please enter a response");
const transformedResponseValidator = isRequired("Please enter a transformed response");

type SingleSelectPayloadOptions =
  | undefined
  | { answers: (string | { text: string; value: string })[] };

export type SingleSelectChatFlowsNode = ChatFlowsNodes & {
  payloadOptions: SingleSelectPayloadOptions;
};

type PropsType = {
  chatId: string | undefined;
  node: SingleSelectChatFlowsNode;
  viewOnly: boolean;
  setSelectedNodeId: Dispatch<SetStateAction<number | null>>;
};

const onSaveCustomTransformer = (formValues: FormValues) => {
  const values = formValues as BaseFormValues & SingleSelectFormValues;
  return {
    payloadOptions: { answers: values.answers },
    payloadContent: values.payloadContent
  };
};

export const SingleSelect = ({ chatId, node, viewOnly, setSelectedNodeId }: PropsType) => {
  const initialFormValues: SingleSelectFormValues = useMemo(() => {
    return node?.payloadOptions?.answers && node?.payloadContent
      ? {
          answers: node.payloadOptions.answers.map((answer) => {
            return {
              text:
                typeof answer === "string"
                  ? answer
                  : (answer as { text: string; value: string }).text,
              value:
                typeof answer === "string"
                  ? answer
                  : (answer as { text: string; value: string }).value
            };
          }),
          payloadContent: node.payloadContent
        }
      : { answers: [{ text: "", value: "" }], payloadContent: "" };
  }, [node.id]);

  return (
    <BaseChatNodeForm
      key={node.id}
      initialValues={initialFormValues}
      onSaveCustomTransformer={onSaveCustomTransformer}
      node={node}
      chatId={chatId}
      isInput
      viewOnly={viewOnly}
      setSelectedNodeId={setSelectedNodeId}
    >
      <TextInput
        fieldName="payloadContent"
        label="Question"
        disabled={viewOnly}
        validate={questionValidator}
      />
      <ArrayField name="answers">
        {({ add, fields }) => {
          return (
            <>
              <div className={styles.List}>
                <div className={styles.HeaderRow}>
                  <div className={styles.HeaderItem}>
                    <Heading size="META">RESPONSE:</Heading>
                    <ToolTip
                      icon={
                        <div className={styles.Icon}>
                          <Info size={15} />
                        </div>
                      }
                    >
                      Response is what the user will see.
                    </ToolTip>
                  </div>
                  <div className={styles.HeaderItem}>
                    <Heading size="META">TRANSFORM RESPONSE:</Heading>
                    <ToolTip
                      icon={
                        <div className={styles.Icon}>
                          <Info size={15} />
                        </div>
                      }
                    >
                      Transformed response is what will be mapped into the database.
                    </ToolTip>
                  </div>
                </div>
                <ArrayField.Items>
                  {({ remove, index }) => {
                    return (
                      <div className={styles.Row} key={`SingleSelectResponseOption_${index}`}>
                        <div className={styles.item}>
                          <TextInput
                            fieldName="text"
                            disabled={viewOnly}
                            validate={responseValidator}
                          />
                        </div>

                        <div className={styles.item}>
                          <TextInput
                            fieldName="value"
                            disabled={viewOnly}
                            validate={transformedResponseValidator}
                          />
                        </div>

                        {!viewOnly && fields.length > 1 && (
                          <div className={styles.item}>
                            <Button inline className={styles.icon} onClick={remove}>
                              <Trash size={30} />
                            </Button>
                          </div>
                        )}
                      </div>
                    );
                  }}
                </ArrayField.Items>
              </div>
              {!viewOnly && (
                <Button inline type="button" className={styles.addResponse} onClick={() => add()}>
                  + Add Response
                </Button>
              )}
            </>
          );
        }}
      </ArrayField>
    </BaseChatNodeForm>
  );
};
