import axios from "axios";
import getToken from "../utils/getToken";

import { addNotification } from "./notifications";

import { ROOT_URL, UPDATE_REASONS, FETCH_REASONS, ADD_REASONS } from "../constants";

import { Dispatch, ActionStatus, Reason } from "../types";

export type FetchReasonsAction = {
  type: typeof FETCH_REASONS;
  status: ActionStatus;
  payload?: { reasons: Array<Reason> };
};

export const fetchReasons = () => (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  dispatch({
    type: FETCH_REASONS,
    status: ActionStatus.loading
  });

  return axios
    .get(`${ROOT_URL}/reasons`, config)
    .then((response) => {
      return dispatch({
        type: FETCH_REASONS,
        status: ActionStatus.success,
        payload: { reasons: response.data.reasons }
      });
    })
    .catch(() => {
      dispatch(
        addNotification({
          type: "error",
          title: "Failed to fetch reasons",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: FETCH_REASONS,
        status: ActionStatus.error
      });
    });
};

export type UpdateReasonsAction = {
  type: typeof UPDATE_REASONS;
  status: ActionStatus;
  payload?: { updates: Array<Reason> };
};

export type UpdateReasonsData = { updates: Array<{ id: number; active: boolean }> };

type UpdateReasonsResponse = {
  status: number;
  data: {
    success: boolean;
    updates: Array<Reason>;
  };
};

export const updateReasons = (data: UpdateReasonsData) => (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  const handleError = () => {
    dispatch({
      type: UPDATE_REASONS,
      status: ActionStatus.error
    });

    dispatch(
      addNotification({
        type: "error",
        title: `Failed to update reasons: ${data.updates.map((reason) => reason.id).join(", ")}`,
        subtitle: "Please try again",
        autoDismiss: true
      })
    );

    return fetchReasons()(dispatch).then(() => {
      dispatch({
        type: UPDATE_REASONS,
        status: ActionStatus.success
      });
    });
  };

  dispatch({
    type: UPDATE_REASONS,
    status: ActionStatus.loading
  });

  return axios
    .patch(`${ROOT_URL}/reasons`, data, config)
    .then((payload: UpdateReasonsResponse) => {
      if (!payload || !payload.data.success) {
        return handleError();
      }

      return dispatch({
        type: UPDATE_REASONS,
        status: ActionStatus.success,
        payload: { updates: payload.data.updates }
      });
    })
    .catch(() => {
      return handleError();
    });
};

export type AddReasonsAction = {
  type: typeof ADD_REASONS;
  status?: ActionStatus;
};

export type AddReasonsData = {
  fileName: string;
};

const buildSubtitle = (messages: string[] = []) => {
  const messagesToDisplay = messages.splice(0, 2);
  const messagesNotIncluded = messages.splice(3);

  return `${messagesToDisplay.join(", ")}. ${
    messagesNotIncluded && messagesNotIncluded.length > 0
      ? `...${messagesNotIncluded.length} more errors`
      : ""
  }`;
};

export const addReasons =
  (data: AddReasonsData, onSuccess?: () => void) => async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };
    dispatch({
      type: ADD_REASONS,
      status: ActionStatus.loading
    });

    return axios
      .post(`${ROOT_URL}/reasons`, data, config)
      .then((payload) => {
        if (!payload || !payload.data.success) {
          dispatch({
            type: ADD_REASONS,
            status: ActionStatus.error
          });

          return dispatch(
            addNotification({
              type: "error",
              title: `Failed to add new reasons`,
              subtitle: buildSubtitle(payload.data.errorMessages),
              autoDismiss: false
            })
          );
        }
        if (payload.data.success) {
          dispatch(
            addNotification({
              type: "success",
              title: "Success",
              subtitle:
                payload.data.errorMessages.length > 0 || payload.data.warningMessages.length > 0
                  ? buildSubtitle([
                      ...(payload.data.errorMessages as string[]),
                      ...(payload.data.warningMessages as string[])
                    ])
                  : "Successfully added new reasons",
              autoDismiss: payload.data.errorMessages.length === 0
            })
          );

          fetchReasons()(dispatch).then(() => {
            dispatch({
              type: ADD_REASONS,
              status: ActionStatus.success
            });
          });

          if (onSuccess) {
            onSuccess();
          }
        }
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: "error",
            title: `Failed to add new reasons`,
            subtitle: "Please try again",
            autoDismiss: false
          })
        );

        dispatch({
          type: ADD_REASONS,
          status: ActionStatus.error
        });
      });
  };
