/* eslint-disable @typescript-eslint/no-explicit-any */
import { constrainXPosition, constrainYPosition } from "./constraints";

import { MapSizeData } from "../types";

export const buildLinkPath =
  (mapSizeData: MapSizeData, mapWidth: number, mapHeight: number) => (d: any) => {
    const constrainedSourceX = constrainXPosition(d.source.x, mapSizeData, mapWidth);
    const constrainedSourceY = constrainYPosition(d.source.y, mapSizeData, mapHeight);
    const constrainedTargetX = constrainXPosition(d.target.x, mapSizeData, mapWidth);
    const constrainedTargetY = constrainYPosition(d.target.y, mapSizeData, mapHeight);
    const dx = constrainedTargetX - constrainedSourceX;
    const dy = constrainedTargetY - constrainedSourceY;

    const cardCenterOffsetY = mapSizeData.nodeHeight / 2;
    const isSkippingNodesInColumn = dx === 0 && (dy <= 0 || dy > mapSizeData.nodeSpacingY);

    const bendRadius = (mapSizeData.nodeSpacingY - mapSizeData.nodeHeight) / 2;
    const goingRight = dx > 0;
    const columnGapX = goingRight
      ? d.target.x - mapSizeData.nodeWidth / 2
      : d.target.x + mapSizeData.nodeWidth / 2;
    const isClusterReturn = dy < 0 && d.source.cardType === "chatFlow";

    const linkContainedInOneRow = isClusterReturn || (dy > 0 && dy === mapSizeData.nodeSpacingY);
    const needsToTravelColumnGap = !linkContainedInOneRow;
    const goingUp = dy <= 0;

    // Handle "same X level" to next node
    if (dx === 0 && !isSkippingNodesInColumn) {
      if (isClusterReturn) {
        return `M${constrainedSourceX},${
          constrainedSourceY - cardCenterOffsetY
        } ${constrainedTargetX},${constrainedTargetY + cardCenterOffsetY}`;
      }

      return `M${constrainedSourceX},${
        constrainedSourceY + cardCenterOffsetY
      } ${constrainedTargetX},${constrainedTargetY - cardCenterOffsetY}`;
    }

    // There are 7 sections to a connection
    // startBend (upLeft,upRight,downLeft,downRight)
    // toColumnGap (left, right)
    // enterColumnGapBend (leftUp, leftDown,rightUp, rightDown)
    // travelColumnGap (up, down)
    // existColumnGapBend (upLeft,upRight,downLeft,downRight)
    // fromColumnGap(left, right)
    // endBend (leftUp, leftDown,rightUp, rightDown)

    type PathBuilder = (args: { latestX: number; latestY: number }) => {
      latestX: number;
      latestY: number;
      pathSection: string;
    };
    const lineSections: PathBuilder[] = [
      // startBend (upLeft,upRight,downLeft,downRight)
      ({ latestX, latestY }) => {
        const goingRight = dx > 0 || isSkippingNodesInColumn;
        const startAtCardTop = goingUp && d.source.cardType === "chatFlow";

        const startX = d.source.x;
        const startY = startAtCardTop
          ? d.source.y - cardCenterOffsetY
          : d.source.y + cardCenterOffsetY;
        const qx1 = startX;
        const qy1 = startAtCardTop ? startY - bendRadius : startY + bendRadius;
        const qx2 = goingRight ? startX + bendRadius : startX - bendRadius;
        const qy2 = qy1;

        const pathSection = `M${startX},${startY} Q ${qx1},${qy1} ${qx2},${qy2}`;
        return { latestX: qx2, latestY: qy2, pathSection };
      },
      // toColumnGap (left, right)
      ({ latestX, latestY }) => {
        let endX = goingRight
          ? columnGapX - bendRadius - bendRadius
          : columnGapX + bendRadius + bendRadius;

        if (isSkippingNodesInColumn) {
          endX = columnGapX;
        }

        const endY = latestY;
        const pathSection = `L ${endX},${endY}`;
        return { latestX: endX, latestY: endY, pathSection };
      },
      // enterColumnGapBend (leftUp, leftDown,rightUp, rightDown)
      ({ latestX, latestY }) => {
        if (!needsToTravelColumnGap) {
          const endX = goingRight ? latestX + 2 * bendRadius : latestX - 2 * bendRadius;
          const endY = latestY;
          const pathSection = `L ${endX},${endY}`;
          return { latestX: endX, latestY: endY, pathSection };
        }

        const startX = latestX;
        const startY = latestY;
        const qx1 =
          goingRight || isSkippingNodesInColumn ? startX + bendRadius : startX - bendRadius;
        const qy1 = startY;
        const qx2 = qx1;
        const qy2 = goingUp ? qy1 - bendRadius : qy1 + bendRadius;

        const pathSection = `M${startX},${startY} Q ${qx1},${qy1} ${qx2},${qy2}`;
        return { latestX: qx2, latestY: qy2, pathSection };
      },
      // travelColumnGap (up, down)
      ({ latestX, latestY }) => {
        if (!needsToTravelColumnGap) {
          return { latestX, latestY, pathSection: "" };
        }
        const columnGapY = d.target.y - mapSizeData.nodeHeight / 2 - bendRadius;

        const endX = latestX;
        const endY = goingUp ? columnGapY + bendRadius : columnGapY - bendRadius;
        const pathSection = `L ${endX},${endY}`;
        return { latestX: endX, latestY: endY, pathSection };
      },
      // existColumnGapBend (upLeft,upRight,downLeft,downRight)
      ({ latestX, latestY }) => {
        if (!needsToTravelColumnGap) {
          return { latestX, latestY, pathSection: "" };
        }

        const startX = latestX;
        const startY = latestY;
        const qx1 = startX;
        const qy1 = goingUp ? startY - bendRadius : startY + bendRadius;
        const qx2 = goingRight ? qx1 + bendRadius : qx1 - bendRadius;
        const qy2 = qy1;

        const pathSection = `M${startX},${startY} Q ${qx1},${qy1} ${qx2},${qy2}`;
        return { latestX: qx2, latestY: qy2, pathSection };
      },
      // fromColumnGap(left, right)
      ({ latestX, latestY }) => {
        const endX = goingRight ? d.target.x - bendRadius : d.target.x + bendRadius;
        const endY = latestY;
        const pathSection = `L ${endX},${endY}`;
        return { latestX: endX, latestY: endY, pathSection };
      },
      // endBend (leftUp, leftDown,rightUp, rightDown)
      ({ latestX, latestY }) => {
        const bendDown = !isClusterReturn;

        const qx1 = goingRight ? latestX + bendRadius : latestX - bendRadius;
        const qy1 = latestY;
        const qx2 = qx1;
        const qy2 = bendDown ? qy1 + bendRadius : qy1 - bendRadius;

        const pathSection = `M${latestX},${latestY} Q ${qx1},${qy1} ${qx2},${qy2}`;
        return { latestX: qx2, latestY: qy2, pathSection };
      }
    ];

    const pathData = lineSections.reduce(
      (acc, cur) => {
        const nextPathSection = cur({ latestX: acc.latestX, latestY: acc.latestY });
        acc = {
          latestX: nextPathSection.latestX,
          latestY: nextPathSection.latestY,
          path: `${acc.path} ${nextPathSection.pathSection}`
        };
        return acc;
      },
      { latestX: constrainedSourceX, latestY: constrainedSourceY + cardCenterOffsetY, path: "" }
    );

    return pathData.path;
  };
