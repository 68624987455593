import * as d3 from "d3";
import { D3Node } from "../types";
import { getNodeSummaryContent } from "./getNodeSummaryContent";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addTooltip = (i: any, d: D3Node, cssId: string) => {
  const { title, details } = getNodeSummaryContent(d);
  const maxPayloadContentDisplay = 100;
  const payloadContent =
    details?.length > maxPayloadContentDisplay
      ? ` - ${details.slice(0, maxPayloadContentDisplay)}...`
      : ` - ${details}`;

  d3.select(cssId)
    .style("opacity", 1)
    .style("position", "absolute")
    .text(`${d.cardType} - ${title} (${d.processedOrder})${payloadContent}`)
    .style("border-width", "0.5px")
    .style("border-style", "solid")
    .style("border-radius", "8px")
    .style("background", "white")
    .style("width", "200px")
    .style("padding", "6px")
    .style("pointer-events", "none")
    .style("left", `0px`)
    .style("top", `0px`);
};
