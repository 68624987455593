import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";

import Button from "../../../../../ui/Button";
import Text from "../../../../../ui/Text";
import { Heart, HeartBlank, Pin, PinBlank } from "../../../../../ui/Icon";
import RichTextEditor from "../RichTextEditor";

import {
  addNotification as addNotificationAction,
  updateNote as updateNoteAction,
  updateNoteTemplateDetails as updateNoteTemplateDetailsAction,
  getNoteTemplateDetails as getNoteTemplateDetailsAction,
  cloneNoteTemplate as cloneNoteTemplateAction,
  setFeedbackMode,
  UpdateNoteData,
  UpdateNoteOptions,
  queueNoteForFeedback,
  clearNoteFromQueue,
  CloneNoteTemplateData
} from "../../../../../../actions";
import { UserContext } from "../../../../../providers/UserProvider";

import useScribeNotifications from "./useScribeNotifications";
import useFeedbackQueue from "./useFeedbackQueue";
import useNoteInsightInjection from "./useNoteInsightInjection";

import {
  NewNotification,
  Note,
  NoteTemplate,
  NoteTemplateUpdateData,
  RecordingSessionStatus,
  ReduxStateType,
  SetFeedbackModeData
} from "../../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  note: Note;
  isActive: boolean;
  noteTemplate?: NoteTemplate;
  setFeedbackMode: (data: SetFeedbackModeData) => void;
  queueNoteForFeedback: (data: number) => void;
  clearNoteFromQueue: (data: number) => void;
  addNotification: (notification: NewNotification) => void;
  getNoteTemplateDetails: (
    noteTemplateId: string,
    showErrorToast: boolean,
    onErrorCallback?: (noteTemplateId: string) => void
  ) => void;
  updateNoteTemplateDetails: (
    noteTemplate: NoteTemplateUpdateData,
    noteTemplateId: string,
    successMessage: string
  ) => void;
  updateNote: (noteId: number, note: UpdateNoteData, options?: UpdateNoteOptions) => void;
  cloneNoteTemplate: (data: CloneNoteTemplateData, successMessage: string) => void;
  syncLoading?: { [key: string]: boolean };
  recordingSessionStatus?: RecordingSessionStatus;
  feedbackQueue: number[];
};

const CLONED_TEMPLATE_MAP = "clonedTemplateMap";

const NoteEditor = ({
  note,
  isActive,
  noteTemplate,
  setFeedbackMode,
  queueNoteForFeedback,
  clearNoteFromQueue,
  addNotification,
  getNoteTemplateDetails,
  updateNoteTemplateDetails,
  updateNote,
  cloneNoteTemplate,
  syncLoading,
  recordingSessionStatus,
  feedbackQueue
}: PropsType) => {
  const [editorState, setEditorState] = useState<EditorState | null>(
    EditorState.createWithContent(convertFromRaw(note.content))
  );
  const { userId } = useContext(UserContext);

  const clonedTemplateLocalStorageKey = `${CLONED_TEMPLATE_MAP}_${userId}`;

  const isMikataTemplate = !noteTemplate?.userId && !noteTemplate?.organizationId;
  const isOrganizationTemplate = !noteTemplate?.userId && noteTemplate?.organizationId;
  const { noteTemplateId } = note;

  const getTemplatePreferenceFromLocalStorage = () => {
    return JSON.parse(localStorage.getItem(clonedTemplateLocalStorageKey) || "{}");
  };

  useEffect(() => {
    if (noteTemplateId && isActive) {
      const oldUserTempPref = getTemplatePreferenceFromLocalStorage();
      const clonedTemplateId = oldUserTempPref?.[noteTemplateId];
      getNoteTemplateDetails(
        clonedTemplateId || noteTemplateId?.toString(),
        false,
        (errorTemplateId) => {
          if (clonedTemplateId === errorTemplateId) {
            const oldUserTempPrefTemp = getTemplatePreferenceFromLocalStorage();
            delete oldUserTempPrefTemp[noteTemplateId?.toString()];
            localStorage.setItem(
              clonedTemplateLocalStorageKey,
              JSON.stringify(oldUserTempPrefTemp)
            );
          }
        }
      );
    }
  }, [noteTemplateId, isActive]);

  const { anchorStatuses } = useNoteInsightInjection({
    editorState,
    setFeedbackMode,
    queueNoteForFeedback,
    setEditorState: setEditorState as (editorState: React.SetStateAction<EditorState>) => void,
    noteId: note.id,
    isActive
  });

  const onSave = (noteId: number, content: Note["content"], options?: UpdateNoteOptions) => {
    if (note.lastSyncedAt) return;

    updateNote(noteId, { content: JSON.stringify(content) }, options);
  };

  useScribeNotifications(addNotification, note.id, note.title, editorState || null, isActive);

  useFeedbackQueue(note.id, isActive, feedbackQueue, setFeedbackMode, clearNoteFromQueue);

  // save on change in editorInsights, anchorStatuses
  useEffect(() => {
    if (editorState) {
      const currentContent = editorState.getCurrentContent();
      const rawContent = convertToRaw(currentContent);
      onSave(note.id, rawContent);
    }
  }, [anchorStatuses]);

  if (!editorState || !setEditorState) return null;

  const isEditMode = Boolean(!syncLoading?.[note.id] && !note.lastSyncedAt);
  return (
    <div className={styles.Container}>
      {note.noteTemplateId && (
        <div className={styles.ToggleActionRow}>
          <Button
            inline
            onClick={() => {
              if (noteTemplateId) {
                if (isMikataTemplate) {
                  cloneNoteTemplate(
                    {
                      cloneFromTemplateId: noteTemplateId,
                      cloneToUserId: userId,
                      isFavourited: false,
                      includeAlways: true,
                      onSuccess: (clonedTemplateId) => {
                        getNoteTemplateDetails(clonedTemplateId, false);
                        const oldUserTempPrefParsed = getTemplatePreferenceFromLocalStorage();
                        if (note.noteTemplateId && clonedTemplateId) {
                          const clonedPref = {
                            ...oldUserTempPrefParsed,
                            [note.noteTemplateId]: clonedTemplateId
                          };
                          localStorage.setItem(
                            clonedTemplateLocalStorageKey,
                            JSON.stringify(clonedPref)
                          );
                        }
                      }
                    },
                    "Added as default template"
                  );
                } else {
                  const updateData: NoteTemplateUpdateData = {
                    ...(noteTemplate as NoteTemplateUpdateData),
                    includeAlways: !noteTemplate?.includeAlways
                  };
                  const templateIdFromMap =
                    getTemplatePreferenceFromLocalStorage()?.[noteTemplateId?.toString()];
                  updateNoteTemplateDetails(
                    updateData,
                    templateIdFromMap || noteTemplateId?.toString(),
                    noteTemplate?.includeAlways
                      ? "Removed as default template"
                      : "Added as default template"
                  );
                }
              }
            }}
          >
            <Text size="S" className={styles.ToggleAction}>
              {noteTemplate?.includeAlways ? <Pin /> : <PinBlank />}
              {noteTemplate?.includeAlways ? "Remove as default" : "Add as default"}
            </Text>
          </Button>
          <Button
            inline
            onClick={() => {
              if (noteTemplateId) {
                if (isMikataTemplate || isOrganizationTemplate) {
                  cloneNoteTemplate(
                    {
                      cloneFromTemplateId: noteTemplateId,
                      cloneToUserId: userId,
                      isFavourited: true,
                      includeAlways: false,
                      onSuccess: (clonedTemplateId) => {
                        const oldUserTempPrefParsed = getTemplatePreferenceFromLocalStorage();
                        if (note.noteTemplateId && clonedTemplateId) {
                          const clonedPref = {
                            ...oldUserTempPrefParsed,
                            [note.noteTemplateId]: clonedTemplateId
                          };
                          localStorage.setItem(
                            clonedTemplateLocalStorageKey,
                            JSON.stringify(clonedPref)
                          );
                        }
                      }
                    },
                    "Added as favourite template"
                  );
                } else {
                  const updateData: NoteTemplateUpdateData = {
                    ...(noteTemplate as NoteTemplateUpdateData),
                    isFavourited: !noteTemplate?.isFavourited
                  };
                  const templateIdFromMap =
                    getTemplatePreferenceFromLocalStorage()?.[noteTemplateId?.toString()];

                  updateNoteTemplateDetails(
                    updateData,
                    templateIdFromMap || noteTemplateId?.toString(),
                    noteTemplate?.isFavourited
                      ? "Removed as favourite template"
                      : "Added as favourite template"
                  );
                }
              }
            }}
          >
            <Text size="S" className={styles.ToggleAction}>
              {noteTemplate?.isFavourited ? <Heart /> : <HeartBlank />}
              {noteTemplate?.isFavourited ? "Remove as favourite" : "Add as favourite"}
            </Text>
          </Button>
        </div>
      )}
      <RichTextEditor
        key={`richTextEditor-${note.id}`}
        note={note}
        editorState={editorState}
        setEditorState={setEditorState as (editorState: React.SetStateAction<EditorState>) => void}
        onSave={(content, options) => onSave(note.id, content, options)}
        recordId={note.id}
        isActive={isActive}
        mode={isEditMode ? "edit" : "view"}
        showMenuOnTextSelect // todo - check if we need to show these only when recording session is complete
        mikaContentLoading={recordingSessionStatus === RecordingSessionStatus.COMPLETE}
      />
    </div>
  );
};

const mapStateToProps = ({ notes, noteTemplates, feedback }: ReduxStateType) => {
  return {
    noteTemplate: noteTemplates.noteTemplate,
    syncLoading: notes.syncLoading,
    feedbackQueue: feedback.notesInQueue
  };
};

export default connect(mapStateToProps, {
  addNotification: addNotificationAction,
  setFeedbackMode,
  queueNoteForFeedback,
  clearNoteFromQueue,
  getNoteTemplateDetails: getNoteTemplateDetailsAction,
  updateNoteTemplateDetails: updateNoteTemplateDetailsAction,
  cloneNoteTemplate: cloneNoteTemplateAction,
  updateNote: updateNoteAction
})(NoteEditor);
