import { combine } from "./combine";
import { isRequired } from "./isRequired";
import { hasNoOpenBrackets } from "./hasNoOpenBrackets";
import { MessagePlaceholders, MessageMediums, MessageTopics } from "../../constants";
import { MessageTemplateMedium } from "../../types";

export type MessageTemplateFormState = {
  id: number;
  displayName: string;
  topic: string;
  payloadContent: string;
  medium: MessageTemplateMedium;
  createdAt: string;
  updatedAt: string;
  chatFlowId: number | null;
  subject: string;
  saveAsNew?: Array<string>;
};

export const displayNameValidator = isRequired("Please enter a name");
export const payloadContentValidator = combine([
  isRequired("Please enter message content"),
  hasNoOpenBrackets()
]);
export const topicValidator = isRequired("Topic required");
export const mediumValidator = isRequired("Medium required");
export const subjectValidator = isRequired("Subject required");

export const formValidator = (values: MessageTemplateFormState) => {
  const formErrors: {
    id: string | undefined;
    displayName: string | undefined;
    topic: string | undefined;
    payloadContent: string | undefined;
    medium: string | undefined;
    createdAt: string | undefined;
    updatedAt: string | undefined;
    chatFlowId: string | undefined;
    subject: string | undefined;
    saveAsNew: Array<string> | undefined;
  } = {
    id: undefined,
    displayName: undefined,
    topic: undefined,
    payloadContent: undefined,
    medium: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    chatFlowId: undefined,
    subject: undefined,
    saveAsNew: undefined
  };

  if (
    !values.chatFlowId &&
    values.topic !== MessageTopics.SCHEDULE_BASED_MESSAGE &&
    values.topic !== MessageTopics.APPOINTMENT_REQUEST_DIRECT_MESSAGE &&
    values.payloadContent?.includes("[link]")
  ) {
    formErrors.payloadContent = `You must have an associated chat to include a "[link]" placeholder`;
  }
  const allowedPlaceholders = MessagePlaceholders;
  const foundPlaceholders = (values.payloadContent || "").match(/\[([^\]]*)\]/gm);
  const invalidPlaceholders: Array<string> = (foundPlaceholders || []).filter(
    (foundPlaceholder) =>
      !allowedPlaceholders.includes(foundPlaceholder) &&
      !(foundPlaceholder.substring(0, 4) === "[SMS") &&
      !(foundPlaceholder.substring(0, 6) === "[EMAIL")
  );
  if (invalidPlaceholders.length > 0) {
    formErrors.payloadContent = `Invalid placeholders: ${invalidPlaceholders.join(", ")}`;
  }

  if (values.medium === MessageMediums.EMAIL) {
    formErrors.subject = subjectValidator(values.subject);
  }

  return formErrors;
};
