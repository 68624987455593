import React from "react";

type PropsType = {
  size?: number;
  color?: string;
};

const Heading1 = ({ size = 24, color = "currentColor" }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M17 5a1 1 0 0 0-1 1v8H8V6a1 1 0 0 0-2 0v16a1 1 0 0 0 2 0v-6h8v6a1 1 0 0 0 2 0V6a1 1 0 0 0-1-1ZM21.75 11.84v.95c0 .65-.36.85-.92.85H19v1.08h2.78V23h1.11V11.84Z"
        fill={color}
      />
    </svg>
  );
};

export default Heading1;
