import React from "react";
import { connect } from "react-redux";

import Button from "../../../ui/Button";
import Heading from "../../../ui/Heading";
import { FloatModal } from "../../../ui/Modal";
import Text from "../../../ui/Text";
import { Form, CheckboxGroup } from "../../../ui/Input";

import {
  removeReportTemplateCard as RemoveReportTemplateCardAction,
  closeModal as closeModalAction
} from "../../../../actions";

import { isRequired } from "../../../../utils/validators";

import styles from "./index.module.scss";

import { ReduxStateType } from "../../../../types";

type DeleteReportTemplateCardFormData = {
  confirmed: Array<string>;
};

type PropsType = {
  loading?: boolean;
  reportId: number;
  cardId: number;
  closeModal: () => void;
  removeReportTemplateCard: (reportId: number, cardId: number) => void;
};

const initialFormState: DeleteReportTemplateCardFormData = {
  confirmed: []
};

const confirmedValidator = isRequired("Confirmation required");

const DeleteReportTemplateCard = ({
  loading,
  closeModal,
  removeReportTemplateCard,
  reportId,
  cardId
}: PropsType) => {
  const removeRTC = () => {
    if (reportId) {
      removeReportTemplateCard(reportId, cardId);
      closeModal();
    }
  };

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L">Delete Report Template Card</Heading>
      <Text component="p">
        You are about to permanently delete this Report Template Card. Is this what you want to do?
      </Text>
      <Form className={styles.Form} onSubmit={removeRTC} initialValues={initialFormState}>
        <CheckboxGroup
          fieldName="confirmed"
          options={[{ label: "Yes, I want to delete", value: "true" }]}
          validate={confirmedValidator}
        />
        <div className={styles.ButtonGroup}>
          <Button inline onClick={closeModal} disabled={loading}>
            Cancel
          </Button>
          <Button type="submit">Delete</Button>
        </div>
      </Form>
    </FloatModal>
  );
};
const mapStateToProps = ({ messageTemplates }: ReduxStateType) => {
  return {
    loading: messageTemplates.messageTemplateDeleteLoading
  };
};

export default connect(mapStateToProps, {
  removeReportTemplateCard: RemoveReportTemplateCardAction,
  closeModal: closeModalAction
})(DeleteReportTemplateCard);
