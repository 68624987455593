import { OPEN_MODAL, CLOSE_MODAL } from "../constants";

import { CloseModalAction, OpenModalAction } from "../actions";

import { ModalType } from "../types";

export type ModalsAction = OpenModalAction | CloseModalAction;

export type ModalsReduxState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modals: Array<{ modalType?: ModalType; modalProps?: any }>;
};

const initialModalsState: ModalsReduxState = {
  modals: []
};

export const modalsReducer = (
  state = initialModalsState,
  action: ModalsAction
): ModalsReduxState => {
  const { type } = action;
  switch (type) {
    case OPEN_MODAL: {
      const { payload } = action as OpenModalAction;

      const cleanedPreviousModals = [...state.modals].filter(
        (modal) => modal.modalType !== payload.modalType
      );
      return {
        ...state,
        modals: [
          ...cleanedPreviousModals,
          { modalType: payload?.modalType, modalProps: payload?.modalProps }
        ]
      };
    }
    case CLOSE_MODAL:
      state.modals.pop();
      return {
        ...state,
        modals: state.modals
      };

    default:
      return state;
  }
};
