import React from "react";

type PropsType = {
  size?: number;
};

const EditPencil = ({ size = 16 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.364 16H2.182A2.184 2.184 0 0 1 0 13.818V3.637a2.184 2.184 0 0 1 2.182-2.183h3.884a.727.727 0 0 1 0 1.454H2.182a.728.728 0 0 0-.727.728v10.182a.728.728 0 0 0 .727.727h10.182a.728.728 0 0 0 .727-.727V9.935a.727.727 0 1 1 1.454 0v3.884A2.184 2.184 0 0 1 12.364 16ZM8 11.637H5.091a.728.728 0 0 1-.727-.727V8a.732.732 0 0 1 .213-.514L11.85.213a.729.729 0 0 1 1.029 0l2.909 2.91a.728.728 0 0 1 0 1.028l-7.273 7.272a.73.73 0 0 1-.515.214Zm4.364-9.881L5.818 8.3v1.881H7.7l6.546-6.546-1.882-1.88Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EditPencil;
