import React from "react";
import moment from "moment";

import { useFormState } from "informed";

import Button from "../../../ui/Button";
import Loader from "../../../ui/Loader";
import { UploadInput } from "../../../ui/Input";

import styles from "./index.module.scss";

type UploadAppointmentsFormData = {
  uploadFile: string;
};

type PropsType = {
  loading: boolean;
  onUpload: (values: UploadAppointmentsFormData) => void;
  closeModal: () => void;
};

const UploadAppointmentsForm = ({ loading, onUpload, closeModal }: PropsType) => {
  const formState = useFormState();
  const values = formState.values as UploadAppointmentsFormData;
  const hasValidFile = Boolean(values.uploadFile);
  return (
    <>
      <UploadInput
        fieldName="uploadFile"
        label="Upload file"
        acceptedFileTypes={["text/csv"]}
        validate={(file: File) => {
          if (!file) return undefined;

          const lastModifiedDate = file.lastModified;
          const isCreatedToday = moment().isSame(lastModifiedDate, "day");
          return isCreatedToday ? undefined : "Oops! Your file must have been created today.";
        }}
      />
      <div className={styles.ButtonGroup}>
        <Button inline onClick={closeModal}>
          Cancel
        </Button>
        {loading && <Loader small center />}
        <Button
          id="submitFileUpload"
          type="submit"
          disabled={!hasValidFile || loading}
          onClick={() => onUpload(values)}
        >
          Upload File
        </Button>
      </div>
    </>
  );
};

export default UploadAppointmentsForm;
