import { StylesConfig } from "react-select";
import { colors } from "../../../../../css/_colors";

import { ContextVariableOption } from "../types";

type PropsType = {
  elementType:
    | "operator"
    | "variable"
    | "comparator"
    | "compositionZero"
    | "compositionOne"
    | "compositionTwo";
};

export const styles = ({
  elementType
}: PropsType): StylesConfig<ContextVariableOption, boolean> => {
  let defaultBackgroundColor = colors.white;
  let defaultColor = colors.shark;

  switch (elementType) {
    case "operator":
    case "variable":
    case "comparator":
    case "compositionZero": {
      defaultBackgroundColor = colors.athensGray;
      defaultColor = colors.shark;
      break;
    }
    case "compositionOne": {
      defaultBackgroundColor = colors.frenchPass;
      defaultColor = colors.royalBlue;
      break;
    }
    case "compositionTwo": {
      defaultBackgroundColor = colors.blueChalk;
      defaultColor = colors.purpleHeart;
      break;
    }
    default:
      defaultBackgroundColor = colors.white;
      defaultColor = colors.shark;
  }

  return {
    container: (provided, state) => ({
      ...provided,
      width: "100%",
      height: "auto",
      opacity: state.isDisabled ? 0.8 : 1
    }),
    control: (provided) => {
      return {
        ...provided,
        minHeight: "24px",
        fontFamily: "hk-grotesk-regular, sans-serif",
        backgroundColor: defaultBackgroundColor,
        color: defaultColor,
        "&:hover": { color: defaultColor, opacity: 0.7 },
        borderRadius: "12px",
        borderStyle: "none",
        fontSize: "14px",
        lineHeight: "14px",
        fontWeight: 400
      };
    },
    valueContainer: (provided) => ({ ...provided, padding: "2px 8px" }),
    indicatorSeparator: (provided) => ({ ...provided, display: "none" }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "0px 4px",
      color: defaultColor,
      opacity: 0.8,
      "&:hover": { color: defaultColor }
    }),
    clearIndicator: (provided) => ({ ...provided, padding: "0px 4px" }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "transparent"
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      borderRadius: "10px"
    }),
    placeholder: (provided) => ({ ...provided, margin: "0", color: colors.manatee }),
    singleValue: (provided) => ({
      ...provided,
      margin: "0",
      color: defaultColor,
      "&:hover": { color: defaultColor }
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? colors.selago : "#ffffff",
      color: state.isSelected ? colors.royalBlue : colors.shark,
      fontWeight: state.isSelected ? 700 : 400,
      fontSize: "14px",
      padding: "8px 12px"
    }),
    menu: (provided) => {
      return {
        ...provided,
        borderRadius: "8px",
        marginTop: "0",
        overflow: "hidden"
      };
    },
    menuList: (provided) => {
      return { ...provided, padding: "8px 0", maxHeight: "200px" };
    }
  };
};
