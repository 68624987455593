import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { connect } from "react-redux";

import Card from "../../../../../../ui/Card";
import Heading from "../../../../../../ui/Heading";
import Status from "../../../../../../ui/Status";
import Button from "../../../../../../ui/Button";
import Text from "../../../../../../ui/Text";
import ToolTip from "../../../../../../ui/Tooltip";
import { Info, Trash } from "../../../../../../ui/Icon";
import { TitleInput, Form } from "../../../../../../ui/Input";
import { ConditionValue } from "../../../../../../ui/Input/JsonLogicInput/types";
import AdvancedSection from "./AdvancedSection";
import EdgesOutComponent from "./EdgesOutComponent";

import { MessageTemplateActionTags, ChatCardTypes } from "../../../../../../../constants";

import {
  updateChatFlowsNode as updateChatFlowsNodeAction,
  deleteChatNode as deleteChatNodeAction
} from "../../../../../../../actions";
import {
  BookingReason,
  ChatEdge,
  ChatFlowsNodes,
  ChatNodePayloadOptions,
  JsonContent,
  MessageTemplateConnection,
  NumberInputFormat,
  ReduxStateType,
  TextInputFormat
} from "../../../../../../../types";

import styles from "./baseChatNodeForm.module.scss";

export type SingleSelectFormValues = {
  payloadContent: string;
  answers: { text: string; value: string }[];
};
export type MultiSelectFormValues = {
  payloadContent: string;
  answers: { text: string; value: string }[] | string[];
};
export type EndFormValues = {
  headerContent: string;
  bodyContent: string;
  redirectURL: string;
};
export type TextFormValue = {
  payloadContent: string;
  contentJson: JsonContent | [];
};
export type TextInputFormValues = {
  payloadContent: string;
  inputFormat: TextInputFormat;
};

export type DeadEndFormValue = {
  payloadContent: string;
  contentJson: JsonContent | [];
};
export type FlaggedEndFormValues = {
  headerContent: string;
  bodyContent: string;
  redirectURL: string;
};

export type NumberInputFormValues = {
  payloadContent: string;
  maxDigits: number;
  inputFormat: NumberInputFormat;
};

export type DateInputFormValues = {
  payloadContent: string;
  format: string;
};

export type PhoneInputFormValues = {
  payloadContent: string;
  maxDigits: number;
};

export type PhotoUploadFormValues = {
  payloadContent: string;
  uploadText: string;
};

export type ScaleInputFormValues = {
  payloadContent: string;
  answers: { text: string; subText: string; value: string }[];
};

export type DefaultCardFormValues = {
  payloadContent: string;
  payloadOptions: ChatNodePayloadOptions;
};

export type DeprecatedLandingContent = { header: string; paragraphs: string[] };

export type LandingFormValues = {
  payloadContent: DeprecatedLandingContent;
  contentJson: JsonContent | [];
  variant: string;
  taskContent: { title: string; description: string };
};

export type PatientVerificationFormValues = {
  verificationMethod: string;
  insufficientPatientDataMessage: string;
};

export type InstructionsFormValues = {
  payloadContent: string;
};

export type InstructionReasonsFormValues = {
  payloadContent: string;
  multiAppointmentMessage?: string;
};

export type InstructionsFromReasonsFormValues = {
  payloadContent: string;
};

export type ChatFlowFormValues = {
  payloadContent: string;
  subChatFlowId: number;
};

export type ScribeVisitFormValues = {
  information: JsonContent | [];
};

export type BookingFormValues = {
  bookingReasons: BookingReason[];
};

export type CheckInFormValues = {
  payloadContent: string;
};

export type BaseFormValues = {
  displayName: string;
  inputName: string;
  edgesOut: {
    toMessageTemplateId: number;
    input: string | null;
    jsonLogic: ConditionValue;
  }[];
  actions: string[];
  tagName?: string | undefined;
  skippable?: boolean;
  skipMessage?: string;
};

export type CustomFormValues =
  | SingleSelectFormValues
  | MultiSelectFormValues
  | EndFormValues
  | FlaggedEndFormValues
  | TextFormValue
  | TextInputFormValues
  | NumberInputFormValues
  | DateInputFormValues
  | PhoneInputFormValues
  | PhotoUploadFormValues
  | ChatFlowFormValues
  | LandingFormValues
  | PatientVerificationFormValues
  | InstructionsFormValues
  | InstructionReasonsFormValues
  | InstructionsFromReasonsFormValues
  | ScribeVisitFormValues
  | BookingFormValues
  | DefaultCardFormValues;

export type FormValues = BaseFormValues & CustomFormValues;

type PayloadContent =
  | string
  | { headerContent: string; bodyContent: string; redirectURL: string }
  | DeprecatedLandingContent;

type PropsType = {
  chatId: string | undefined;
  node: ChatFlowsNodes;
  chatFlowNodes: ChatFlowsNodes[];
  chatEdges?: Array<MessageTemplateConnection> | Array<ChatEdge>;
  initialValues: CustomFormValues;
  onSaveCustomTransformer: (formValues: FormValues) => {
    payloadContent?: PayloadContent;
    payloadOptions?: ChatNodePayloadOptions;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateChatFlowsNode: (id: number, chatId: string, data: any) => void;
  children: React.ReactNode;
  isInput?: boolean;
  viewOnly: boolean;
  setSelectedNodeId: Dispatch<SetStateAction<number | null>>;
  chatFlowTags: Array<string>;
  deleteChatNode: (data: {
    id: number;
    chatFlowId: string | undefined;
    onSuccess?: () => void;
  }) => void;
  validate?: (values: FormValues) => string | undefined;
};

/* ****************************
 * * Component definition below
 ******************************
 */
const BaseChatNodeForm = ({
  chatId,
  node,
  chatFlowNodes,
  chatEdges,
  initialValues,
  onSaveCustomTransformer,
  updateChatFlowsNode,
  children,
  isInput = false,
  viewOnly = true,
  setSelectedNodeId,
  chatFlowTags,
  deleteChatNode,
  validate
}: PropsType) => {
  const { cardType } = node;
  const [formError, setFormError] = useState("");

  const canDeleteCard = () => {
    if (
      node.edgesIn === null &&
      node.edgesOut === null &&
      !(viewOnly || cardType === ChatCardTypes.LANDING || cardType === ChatCardTypes.SUB_FLOW_START)
    ) {
      return true;
    }
    return false;
  };

  const save = (formValues: FormValues) => {
    const customTransformedValues = onSaveCustomTransformer(formValues);
    const baseTransformedValues = customTransformedValues;

    baseTransformedValues.payloadOptions = {
      ...(node.payloadOptions || {}), // Existing payload options
      ...(customTransformedValues.payloadOptions || {}), // Custom payload options
      skippable: !!formValues.skippable, // Base payload options
      skipMessage: formValues.skipMessage || "" // Base payload options
    };

    if (chatId) {
      const tagNames = [...formValues.actions, formValues.tagName].filter((tagName) =>
        Boolean(tagName)
      );

      updateChatFlowsNode(node.id, chatId, {
        chatFlowId: chatId,
        displayName: formValues.displayName ? formValues.displayName : "",
        inputName: formValues.inputName,
        edgesOut: formValues.edgesOut,
        tagNames,
        ...baseTransformedValues
      });
    }
  };

  const filteredEdgesOut = chatEdges
    ? (chatEdges as MessageTemplateConnection[])
        .filter((edge) => edge.fromMessageTemplateId === node.id)
        .map((edge) => ({
          id: edge.id,
          toMessageTemplateId: edge.toMessageTemplateId,
          input: edge.input,
          jsonLogic: edge.jsonLogic
        }))
    : [];

  const formInitialValues = useMemo(() => {
    const actionOptionValues = MessageTemplateActionTags.map((tag) => tag.value);
    const initialActions = node.tagNames.filter((tagName) => actionOptionValues.includes(tagName));
    const initialTagName = node.tagNames.find((tagName) => !actionOptionValues.includes(tagName));
    const skippable = Boolean(node.payloadOptions?.skippable);
    const skipMessage = node.payloadOptions?.skipMessage || "";

    return {
      displayName: node.displayName,
      inputName: node.inputName,
      edgesOut: filteredEdgesOut,
      actions: initialActions,
      tagName: initialTagName,
      skippable,
      skipMessage,
      ...initialValues
    };
  }, []);

  return (
    <Form
      id="chatNodeDetailsForm"
      initialValues={formInitialValues}
      onSubmit={(formState) => {
        const values = formState.values as FormValues;
        if (validate) {
          const formError = validate(values);
          if (formError) {
            setFormError(formError);
            return;
          }
        }
        save(formState.values as FormValues);
      }}
    >
      <>
        {node.edgesIn !== null && (
          <Card>
            <Heading size="S" component="h1" className={styles.Heading}>
              Incoming Connections:
            </Heading>
            <div className={styles.IncomingConnections}>
              {node.edgesIn?.map((fromMessageTemplateId) => {
                const connectedNode = chatFlowNodes.find(
                  (node) => node.id === fromMessageTemplateId
                );
                const connectedNodeName = connectedNode?.displayName || fromMessageTemplateId;
                return (
                  <Button
                    // inline
                    key={`incomingConnection-${fromMessageTemplateId}`}
                    className={styles.IncomingConnectionsLink}
                    onClick={async () => {
                      setSelectedNodeId(fromMessageTemplateId);
                    }}
                  >
                    {connectedNodeName}
                  </Button>
                );
              })}
            </div>
          </Card>
        )}

        {canDeleteCard() && (
          <div className={styles.DeleteCardButton}>
            <Button
              secondary
              onClick={async () => {
                setSelectedNodeId(null);
                deleteChatNode({ id: node.id, chatFlowId: chatId });
              }}
            >
              <div className={styles.TrashIcon}>
                <Trash />
              </div>
              Delete Card
            </Button>
          </div>
        )}
        <Card className={styles.FormCard}>
          <Heading size="M" component="h1" className={styles.Heading}>
            <div className={styles.CardHeader}>
              <TitleInput
                fieldName="displayName"
                label="Display Name"
                disabled={viewOnly}
                placeholder={node.id.toString()}
              />

              <Status
                value={cardType === ChatCardTypes.LANDING ? "start" : cardType}
                options={
                  cardType === ChatCardTypes.LANDING
                    ? [{ label: "start", value: "start" }]
                    : [{ label: cardType, value: cardType }]
                }
              />
            </div>
          </Heading>
          {isInput && (
            <div className={styles.Input}>
              <div className={styles.MetaHeading}>
                <Heading size="META">Store User Response As</Heading>
                <ToolTip
                  icon={
                    <div className={styles.Icon}>
                      <Info size={15} />
                    </div>
                  }
                >
                  This field shows the input name.
                </ToolTip>
              </div>
              <Text>{node.inputName || "--"}</Text>
            </div>
          )}
          {children}
          <div>
            {formError && (
              <Text className={styles.ErrorMessage} size="M">
                {formError}
              </Text>
            )}
          </div>
          <AdvancedSection node={node} viewOnly={viewOnly} />

          {!viewOnly && (
            <Button id="saveChatNodeChanges" className={styles.SaveButton} type="submit">
              Save Changes
            </Button>
          )}
        </Card>
        {chatFlowTags.includes("useGraphSystem") && (
          <EdgesOutComponent
            node={node}
            setSelectedNodeId={setSelectedNodeId}
            viewOnly={viewOnly}
            filteredEdgesOut={filteredEdgesOut}
          />
        )}
      </>
    </Form>
  );
};

const mapStateToProps = ({ chats }: ReduxStateType) => {
  return {
    chatFlowNodes: chats.chatDetails.nodes,
    chatEdges: chats.chatDetails.edges,
    chatFlowTags: chats.chatDetails.tags
  };
};

export default connect(mapStateToProps, {
  updateChatFlowsNode: updateChatFlowsNodeAction,
  deleteChatNode: deleteChatNodeAction
})(BaseChatNodeForm);
