import React from "react";
import { useField } from "informed";

import BaseInput from "../BaseInput";
import SelectInputBase from "./SelectInputBase";

import { InputPropsType } from "../../../../types";

import { selectStyles } from "./styles";

export type Option = { label: string; value: string | number };

export type Options = Array<Option>;

type PropsType = InputPropsType & {
  id?: string;
  options: Options;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValue?: any;
  labelDetails?: React.ReactNode;
  isMulti?: boolean;
  isClearable?: boolean;
};

const SelectInput = ({
  id,
  fieldName,
  label,
  labelDetails,
  info,
  infoStatus,
  options,
  placeholder,
  initialValue,
  isMulti = false,
  isClearable = false,
  disabled = false,
  warningMessage = "",
  showField = true,
  validate,
  customOnChange,
  ...props
}: PropsType): JSX.Element => {
  const { fieldState, fieldApi } = useField({
    ...props,
    name: fieldName,
    validate,
    initialValue
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const value = fieldState.value as any;
  const error = fieldState.error as string | undefined;
  const { setValue, setTouched, validate: validateInput } = fieldApi;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeHandler = (inputValue: any): void => {
    setValue(inputValue);
    setTouched(true);
    validateInput();

    if (customOnChange && inputValue) {
      customOnChange({ [fieldName]: inputValue });
    }
  };

  const selectedValue = isMulti
    ? options.filter((option) => value && value.includes(option.value))
    : options.find((option) => option.value === value);
  return (
    <BaseInput
      fieldName={fieldName}
      error={error}
      showField={showField}
      label={label}
      labelDetails={labelDetails}
      info={info}
      infoStatus={infoStatus}
      warningMessage={warningMessage}
    >
      <SelectInputBase
        {...props}
        id={id || fieldName}
        fieldName={fieldName}
        styles={selectStyles({ hasError: !!error })}
        options={options}
        isClearable={isClearable}
        isMulti={isMulti}
        placeholder={placeholder}
        aria-label={label}
        aria-required="true"
        disabled={disabled}
        onChange={onChangeHandler}
        initialValue={value}
        classNamePrefix="SelectInput" // Helper for Integration test element selection
      />
    </BaseInput>
  );
};

export default SelectInput;
