import { BookingReason } from "../../../../types";

export type FormState = {
  bookingReasons: BookingReason[];
};

export const globalFormValidator = (values: FormState) => {
  let errorMessage;

  const defaultBookingReasons = values?.bookingReasons
    ? values.bookingReasons.filter((bookingReason) => bookingReason.isDefault)
    : [];

  if (defaultBookingReasons.length !== 1) {
    errorMessage = `One default booking reason required. We found ${defaultBookingReasons.length} defaults`;
  }

  const invalidRequestReasons = values?.bookingReasons
    ? values.bookingReasons.filter(
        (bookingReason) =>
          bookingReason.skipBooking &&
          (bookingReason.autoBookable || bookingReason.requiresPrimaryPractitioner)
      )
    : [];

  if (invalidRequestReasons.length) {
    errorMessage = `Request reasons cannot be auto-bookable or require primary practitioners. Invalid Request reasons: ${invalidRequestReasons.map(
      (reason) => reason.label
    )}`;
  }

  return errorMessage;
};

export const formValidator = (values: FormState) => {
  return undefined;
};
