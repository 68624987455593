import React from "react";

type PropsType = {
  size?: number;
};
const Phone = ({ size = 28 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="phone">
        <path
          d="M20.8881 16.9201C20.901 16.3877 20.5312 15.9512 20.0374 15.8815C19.0025 15.745 17.9865 15.4919 17.0063 15.1262C16.6408 14.9888 16.2289 15.0766 15.9552 15.3472L14.6852 16.6172C14.3669 16.9355 13.8752 17.0019 13.4838 16.7794C10.8238 15.2669 8.62136 13.0644 7.10883 10.4044C6.88632 10.0131 6.95271 9.52131 7.27102 9.20299L8.53708 7.93696C8.81158 7.65936 8.89944 7.2474 8.76123 6.8797C8.39631 5.90177 8.1432 4.88569 8.00797 3.86005C7.93772 3.36303 7.5099 2.99511 6.99813 3.0001L3.99907 3.0001C3.71837 3.00036 3.4507 3.11859 3.26145 3.32591C3.07221 3.53322 2.97881 3.81053 3.00254 4.0745C3.31419 7.0093 4.31278 9.82943 5.92214 12.3138C7.37874 14.606 9.32218 16.5495 11.6222 18.0111C14.088 19.6101 16.8945 20.6085 19.7981 20.9242C20.0786 20.9495 20.3567 20.8554 20.5642 20.6651C20.7717 20.4747 20.8893 20.2057 20.8881 19.9201V16.9201ZM22.8881 19.916C22.8915 20.7608 22.5387 21.5678 21.9162 22.1388C21.2938 22.7099 20.4594 22.9921 19.6001 22.9142C16.3674 22.563 13.2623 21.4584 10.5418 19.6941C8.00173 18.08 5.84819 15.9265 4.23885 13.3938C2.46343 10.6532 1.35855 7.53288 1.01219 4.2701C0.936396 3.43141 1.21659 2.59948 1.78433 1.97753C2.35208 1.35558 3.15508 1.00089 3.99813 1.0001L6.98829 1.00015C8.49408 0.985327 9.77754 2.0891 9.98954 3.58934C10.1063 4.47464 10.3228 5.34388 10.6342 6.17824C11.0463 7.27455 10.7827 8.51045 9.95523 9.34721L9.23814 10.0643C10.414 11.9089 11.9793 13.4742 13.8239 14.6501L14.545 13.929C15.3778 13.1055 16.6137 12.8419 17.7077 13.2532C18.5443 13.5654 19.4136 13.7819 20.3078 13.8999C21.8117 14.1121 22.919 15.4143 22.8881 16.9313L22.8881 19.916Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default Phone;
