import React from "react";
import { connect } from "react-redux";

import { FloatModal } from "../../../ui/Modal";
import AddTouchpoint from "../../../common/tables/AppointmentJourneysTable/AppointmentJourneysDetails/Touchpoints/AddTouchpoint";

import { closeModal as closeModalAction } from "../../../../actions";

type PropsType = {
  closeModal: () => void;
};

const AddMessageConfiguration = ({ closeModal }: PropsType) => {
  return (
    <FloatModal isOpen onClose={closeModal}>
      <AddTouchpoint inModal={true} />
    </FloatModal>
  );
};

export default connect(null, {
  closeModal: closeModalAction
})(AddMessageConfiguration);
