import React from "react";

type PropsType = {
  color?: string;
  size?: number;
};

const Check = ({ size = 30, color = "#54c08a" }: PropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
    >
      <g id="Group_17" data-name="Group 17" transform="translate(0)">
        <path
          id="Combined_Shape-2"
          data-name="Combined Shape"
          style={{ fill: color, clipPath: "url(#clip-path)" }}
          d="M1.849,18.531A12.432,12.432,0,0,1,2.753,4.2,11.145,11.145,0,0,1,16.15,1.04a1.11,1.11,0,0,1,.528,1.44,1.027,1.027,0,0,1-1.378.553A9.116,9.116,0,0,0,4.34,5.621,10.172,10.172,0,0,0,3.6,17.344a9.2,9.2,0,0,0,10.542,4.07,9.768,9.768,0,0,0,6.728-9.408V10.992a1.045,1.045,0,1,1,2.088,0v1.015a11.943,11.943,0,0,1-8.224,11.5A11,11,0,0,1,11.479,24,11.363,11.363,0,0,1,1.849,18.531Zm8.892-3.572L7.61,11.687a1.127,1.127,0,0,1,0-1.543,1.014,1.014,0,0,1,1.476,0l2.392,2.5L22.219,1.417a1.011,1.011,0,0,1,1.475,0,1.126,1.126,0,0,1,0,1.542l-11.478,12a1.011,1.011,0,0,1-1.475,0Z"
        />
      </g>
    </svg>
  );
};

export default Check;
