import { FETCH_DATA_FOR_ORGANIZATION, ADD_STAFF_USER } from "../constants";

export const dataForOrganizationReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case FETCH_DATA_FOR_ORGANIZATION:
      return { ...state, organizationData: payload.data };
    case ADD_STAFF_USER:
      return { ...state };
    default:
      return state;
  }
};
