import React, { useState } from "react";
import { connect } from "react-redux";
import cx from "classnames";

import Button from "../../../ui/Button";
import { FloatModal } from "../../../ui/Modal";
import BasicDirectMessageForm from "./BasicDirectMessageForm";
import SecureDirectMessageForm from "./SecureDirectMessageForm";

import { closeModal as closeModalAction } from "../../../../actions";

import { EventData } from "../../../../types";

import { DirectMessageModes } from "../../../../constants";

import styles from "./index.module.scss";

type PropsType = {
  closeModal: () => void;
  sendProviderActivityMessage?: (eventType: string, eventData?: EventData) => void;
  patientDisplayName: string;
  patientId: number;
  appointmentId: number;
  mobilePhone: string | null;
  emailAddress: string;
  canSendSecureMessage: boolean;
  dpmContentSuggestion?: string;
};

const SendDirectMessage = ({
  closeModal,
  sendProviderActivityMessage,
  patientDisplayName,
  patientId,
  appointmentId,
  mobilePhone,
  emailAddress,
  canSendSecureMessage,
  dpmContentSuggestion
}: PropsType) => {
  const [mode, setMode] = useState<string>(DirectMessageModes.SECURE);

  const childProps = {
    patientDisplayName,
    patientId,
    appointmentId,
    mobilePhone,
    emailAddress,
    canSendSecureMessage,
    dpmContentSuggestion
  };

  return (
    <FloatModal isOpen onClose={closeModal}>
      <div className={styles.Tab}>
        <Button
          className={cx(styles.TabLink, {
            [styles.TabLinkActive]: mode === DirectMessageModes.SECURE
          })}
          inline
          onClick={() => setMode("secure")}
        >
          Secure Message
        </Button>
        <Button
          className={cx(styles.TabLink, {
            [styles.TabLinkActive]: mode === DirectMessageModes.BASIC
          })}
          inline
          onClick={() => setMode("basic")}
          id="basicMessage"
        >
          Basic Message
        </Button>
      </div>
      {mode === DirectMessageModes.SECURE && (
        <SecureDirectMessageForm
          sendProviderActivityMessage={sendProviderActivityMessage}
          {...childProps}
        />
      )}
      {mode === DirectMessageModes.BASIC && <BasicDirectMessageForm {...childProps} />}
    </FloatModal>
  );
};

export default connect(undefined, {
  closeModal: closeModalAction
})(SendDirectMessage);
