import { combineReducers } from "redux";
import { CLEAR_ORGANIZATION } from "../constants";

import { appointmentRequestsReducer } from "./appointmentRequestsReducer";
import { automatedMessagesReducer } from "./automatedMessagesReducer.ts";
import { billingReducer } from "./billingReducer";
import { messageConfigurationsReducer } from "./messageConfigurationsReducer.ts";
import { campaignsReducer } from "./campaignsReducer";
import { chatsReducer } from "./chatsReducer";
import { dataForOrganizationReducer } from "./dataForOrganizationReducer";
import { messageTemplatesReducer } from "./messageTemplatesReducer.ts";
import { messagesReducer } from "./messagesReducer";
import { mikataAdminReducer } from "./mikataAdminReducer";
import { modalsReducer } from "./modalsReducer";
import { reasonsReducer } from "./reasonsReducer";
import { practitionersReducer } from "./practitionersReducer";
import { instructionsReducer } from "./instructionsReducer";
import { locationsReducer } from "./locationsReducer";
import { notificationsReducer } from "./notificationsReducer";
import { uploadRecordReducer } from "./uploadRecordReducer";
import { reportTemplatesReducer } from "./reportTemplatesReducer.ts";
import { organizationReducer } from "./organizationReducer.ts";
import { appointmentDetailsReducer } from "./appointmentReducer.ts";
import { notesReducer } from "./notesReducer.ts";
import { noteTemplatesReducer } from "./noteTemplatesReducer.ts";
import { patientsReducer } from "./patientsReducer.ts";
import { patientContactDetailsReducer } from "./patientContactDetailsReducer";
import { analyticsReducer } from "./analyticsReducer.ts";
import { servicesReducer } from "./servicesReducer.ts";
import { documentsReducer } from "./documentsReducer.ts";
import { usersReducer } from "./usersReducer.ts";
import { feedbackReducer } from "./feedbackReducer";
import { tasksReducer } from "./tasksReducer";

const appReducer = combineReducers({
  adminData: mikataAdminReducer,
  analytics: analyticsReducer,
  appointmentRequestsInfo: appointmentRequestsReducer,
  appointments: appointmentDetailsReducer,
  automatedMessages: automatedMessagesReducer,
  billing: billingReducer,
  campaigns: campaignsReducer,
  chats: chatsReducer,
  documents: documentsReducer,
  feedback: feedbackReducer,
  instructions: instructionsReducer,
  locations: locationsReducer,
  messageConfigurations: messageConfigurationsReducer,
  messages: messagesReducer,
  messageTemplates: messageTemplatesReducer,
  modals: modalsReducer,
  notes: notesReducer,
  noteTemplates: noteTemplatesReducer,
  notifications: notificationsReducer,
  organizationData: dataForOrganizationReducer,
  organizationDetails: organizationReducer,
  patients: patientsReducer,
  patientContactDetails: patientContactDetailsReducer,
  practitioners: practitionersReducer,
  reasons: reasonsReducer,
  reportTemplates: reportTemplatesReducer,
  services: servicesReducer,
  tasks: tasksReducer,
  uploadRecord: uploadRecordReducer,
  users: usersReducer
});

const rootReducer = (state, action) => {
  if (action.type === CLEAR_ORGANIZATION) {
    return appReducer({}, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
