import React, { useContext, useEffect } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";

import NavBar from "../NavBar";
import SessionManager from "../SessionManager";
import { AnnouncementsModalManager } from "../ui/Announcements";
import { MultiModalManager } from "../ui/Modal";
import Notifications from "../ui/Notifications";
import PageLayout from "../ui/PageLayout";

import Loader from "../ui/Loader";

// Clinic Pages
import AnalyticsPage from "../org_dashboard/pages/AnalyticsPage";
import AppointmentJourneysPage from "../org_dashboard/pages/AppointmentJourneysPage";
import AppointmentRequestsPage from "../org_dashboard/pages/AppointmentRequestsPage";
import AppointmentsPage from "../org_dashboard/pages/AppointmentsPage";
import InboxPage from "../org_dashboard/pages/InboxPage";
import DocumentsPage from "../org_dashboard/pages/DocumentsPage";

import CampaignsPage from "../common/Campaigns";
import AdminDocumentsPage from "../common/tables/DocumentsTable";

// Admin Pages
import AppointmentJourneysTable from "../common/tables/AppointmentJourneysTable";
import AppointmentRequestsTable from "../common/tables/AppointmentRequestsTable";
import AppointmentsTable from "../common/tables/AppointmentsTable";
import InboxTable from "../common/tables/InboxTable";
import ProvidersTable from "../common/tables/ProvidersTable";
import ReasonsTable from "../common/tables/ReasonsTable";
import UsersTable from "../common/tables/UsersTable";
import MikataChatsPage from "../mikata_admin/MikataChatsPage";
import MikataNoteTemplatesPage from "../mikata_admin/MikataNoteTemplatesPage";
import MikataUsersPage from "../mikata_admin/MikataUsersPage";
import OrganizationCreatePage from "../mikata_admin/OrganizationCreatePage";
import OrganizationDetailsNavBar from "../mikata_admin/OrganizationDetails/OrganizationDetailsNavBar";
import AccountPage from "../mikata_admin/OrganizationDetails/views/AccountPage";
import BillingPage from "../mikata_admin/OrganizationDetails/views/BillingPage";
import ChatsTable from "../mikata_admin/OrganizationDetails/views/ChatsTable";
import FeaturesPage from "../mikata_admin/OrganizationDetails/views/FeaturesPage";
import InstructionsTable from "../mikata_admin/OrganizationDetails/views/InstructionsTable";
import IntegrationPage from "../mikata_admin/OrganizationDetails/views/IntegrationPage";
import LocationsPage from "../mikata_admin/OrganizationDetails/views/LocationsPage";
import MessageTemplatesTable from "../mikata_admin/OrganizationDetails/views/MessageTemplatesTable";
import NoteTemplatesTable from "../mikata_admin/OrganizationDetails/views/NoteTemplatesTable";
import PreferencesPage from "../mikata_admin/OrganizationDetails/views/PreferencesPage";
import ServicesPage from "../mikata_admin/OrganizationDetails/views/ServicesPage";
import OrganizationsListPage from "../mikata_admin/OrganizationsPage";

import { OrganizationContext } from "../providers/OrganizationProvider";
import { UserContext } from "../providers/UserProvider";

import {
  AdminOrganizationDetailsPageTabs,
  StaffSettingsPageTabs,
  UserTypeConstants,
  FEATURES as ORGANIZATION_FEATURES
} from "../../constants";
import StaffSettingsNavbar from "../org_dashboard/pages/StaffSettingsPage/StaffSettingsNavbar";
import { PermissionsGuard, usePermissions } from "../../hooks/usePermissions";
import { Permissions } from "../../types";
import AppointmentRequestsConfig from "../common/AppointmentRequestsConfig";
import BillingProvider from "../providers/BillingProvider";
import Billing from "../org_dashboard/pages/StaffSettingsPage/Billing";
import General from "../org_dashboard/pages/StaffSettingsPage/General";
import Heading from "../ui/Heading";

type OrganizationGuardPropsType = {
  children: React.ReactNode;
};

// Ensures that the user token and params organization id are aligned
const OrganizationGuard = ({ children }: OrganizationGuardPropsType) => {
  const { organizationId: userOrganizationId, updateAdminOrg, logout } = useContext(UserContext);
  const organization = useContext(OrganizationContext);
  const { organizationId: paramsOrganizationId } = useParams<{
    organizationId: string;
  }>();

  // Update Admin Token if token organization context does not match path params
  useEffect(() => {
    if (
      userOrganizationId &&
      paramsOrganizationId &&
      userOrganizationId?.toString() !== paramsOrganizationId
    ) {
      // logout users switching directly from one org context to another
      logout();
    } else if (userOrganizationId?.toString() !== paramsOrganizationId) {
      updateAdminOrg(paramsOrganizationId);
    }
  }, [userOrganizationId, paramsOrganizationId, logout, updateAdminOrg]);

  if (
    userOrganizationId?.toString() !== paramsOrganizationId ||
    (paramsOrganizationId && !organization) ||
    organization?.id !== userOrganizationId
  ) {
    return <Loader screen center />;
  }

  return children;
};

const AuthenticatedRoutes: React.FC = () => {
  const user = useContext(UserContext);
  const organization = useContext(OrganizationContext);
  const isClinic = user.userType === UserTypeConstants.STAFF;
  const isAdmin = user.userType === UserTypeConstants.MIKATA_ADMIN;
  const isPractitioner = user.userType === UserTypeConstants.PRACTITIONER;
  const hasScribeFeature = user?.settings?.features?.scribe?.active || false;
  const hasUserAnalyticsPermission = usePermissions([Permissions.VIEW_ANALYTICS]);
  const hasOrgAnalyticsFeature =
    organization?.features?.includes(ORGANIZATION_FEATURES.ANALYTICS.value) || false;
  // expectation for below flag is the setting would not have this key if scribe was never activated
  const hasScribeSettings = user?.settings?.features?.scribe || false;
  const {
    ACCOUNT,
    SERVICES,
    ANALYTICS,
    PREFERENCES,
    USERS,
    LOCATIONS,
    REASONS,
    PROVIDERS,
    INSTRUCTIONS,
    INTEGRATION,
    FEATURES,
    BILLING,
    APPOINTMENT_REQUESTS,
    APPOINTMENTS,
    APPOINTMENT_JOURNEYS,
    MESSAGE_LIBRARY,
    CAMPAIGNS,
    CHATS,
    DOCUMENTS,
    SCRIBE_TEMPLATES,
    INBOX
  } = AdminOrganizationDetailsPageTabs;
  const {
    BILLING: NON_ADMIN_BILLING,
    USERS: NON_ADMIN_USERS,
    APPOINTMENT_REQUESTS_CONFIG,
    REASONS: NON_ADMIN_REASONS,
    SCRIBE_TEMPLATES: NON_ADMIN_SCRIBE_TEMPLATES,
    SCRIBE_TEMPLATES_ALL,
    SCRIBE_TEMPLATES_FAVOURITES,
    GENERAL: NON_ADMIN_SCRIBE_GENERAL
  } = StaffSettingsPageTabs;
  return (
    <SessionManager>
      <Notifications />
      <MultiModalManager />
      <AnnouncementsModalManager />
      <NavBar>
        <PageLayout>
          {isClinic && (
            <Routes>
              <Route path="/analytics" Component={AnalyticsPage} />
              <Route path="/appointments" Component={AppointmentsPage} />
              <Route path="/journeys" Component={AppointmentJourneysPage} />
              <Route path="/requests" Component={AppointmentRequestsPage} />
              <Route path="/inbox" Component={InboxPage} />
              <Route path="/campaigns" Component={CampaignsPage} />
              <Route path="/documents" Component={DocumentsPage} />
              <Route path="/settings" Component={StaffSettingsNavbar} />
              <Route
                path={NON_ADMIN_USERS.path}
                element={
                  <>
                    <StaffSettingsNavbar />
                    <PermissionsGuard
                      requiredPermissions={[
                        Permissions.CREATE_STAFF,
                        Permissions.UPDATE_USER_OTHER_LEVEL_20
                      ]}
                    >
                      <UsersTable />
                    </PermissionsGuard>
                  </>
                }
              />
              <Route
                path={APPOINTMENT_REQUESTS_CONFIG.path}
                element={
                  <>
                    <StaffSettingsNavbar />
                    <AppointmentRequestsConfig />
                  </>
                }
              />
              <Route
                path={NON_ADMIN_REASONS.path}
                element={
                  <>
                    <StaffSettingsNavbar />
                    <PermissionsGuard
                      requiredPermissions={[Permissions.FIND_REASONS]}
                      requiresVerifiedOrg={false}
                    >
                      <ReasonsTable />
                    </PermissionsGuard>
                  </>
                }
              />
              {hasScribeFeature && (
                <Route
                  path={SCRIBE_TEMPLATES_ALL.path}
                  element={
                    <>
                      <StaffSettingsNavbar />
                      <PermissionsGuard
                        requiredPermissions={[Permissions.FIND_NOTE_TEMPLATES]}
                        requiresVerifiedOrg={false}
                      >
                        <NoteTemplatesTable />
                      </PermissionsGuard>
                    </>
                  }
                />
              )}
              {hasScribeFeature && (
                <Route
                  path={SCRIBE_TEMPLATES_FAVOURITES.path}
                  element={
                    <>
                      <StaffSettingsNavbar />
                      <PermissionsGuard
                        requiredPermissions={[Permissions.FIND_NOTE_TEMPLATES]}
                        requiresVerifiedOrg={false}
                      >
                        <NoteTemplatesTable />
                      </PermissionsGuard>
                    </>
                  }
                />
              )}
              {hasScribeFeature && (
                <Route
                  path={NON_ADMIN_SCRIBE_TEMPLATES.path}
                  element={<Navigate to={SCRIBE_TEMPLATES_ALL.path} replace />}
                />
              )}
              <Route
                path={NON_ADMIN_BILLING.path}
                element={
                  <>
                    <Heading size="XL" component="h1">
                      Billing
                    </Heading>
                    <PermissionsGuard
                      requiredPermissions={[Permissions.VIEW_BILLING_SELF]}
                      requiresVerifiedOrg={false}
                    >
                      <BillingProvider>
                        <Billing />
                      </BillingProvider>
                    </PermissionsGuard>
                  </>
                }
              />
              <Route path="/*" element={<Navigate to="/appointments" replace />} />
            </Routes>
          )}

          {isPractitioner && (
            <Routes>
              {hasOrgAnalyticsFeature && hasUserAnalyticsPermission && (
                <Route path="/analytics" Component={AnalyticsPage} />
              )}
              <Route path="/appointments" Component={AppointmentsPage} />
              <Route path="/journeys" Component={AppointmentJourneysPage} />
              <Route path="/campaigns" Component={CampaignsPage} />
              <Route path="/settings" Component={StaffSettingsNavbar} />
              <Route
                path={NON_ADMIN_USERS.path}
                element={
                  <>
                    <StaffSettingsNavbar />
                    <PermissionsGuard
                      requiredPermissions={[
                        Permissions.CREATE_STAFF,
                        Permissions.UPDATE_USER_OTHER_LEVEL_20
                      ]}
                    >
                      <UsersTable />
                    </PermissionsGuard>
                  </>
                }
              />
              {hasScribeSettings && (
                <Route
                  path={NON_ADMIN_SCRIBE_GENERAL.path}
                  element={
                    <>
                      <StaffSettingsNavbar />
                      <PermissionsGuard
                        requiresVerifiedOrg={false}
                        requiredPermissions={[Permissions.UPDATE_USER_SELF]}
                      >
                        <General />
                      </PermissionsGuard>
                    </>
                  }
                />
              )}
              <Route
                path={APPOINTMENT_REQUESTS_CONFIG.path}
                element={
                  <>
                    <StaffSettingsNavbar />
                    <AppointmentRequestsConfig />
                  </>
                }
              />
              <Route
                path={NON_ADMIN_REASONS.path}
                element={
                  <>
                    <StaffSettingsNavbar />
                    <PermissionsGuard
                      requiredPermissions={[Permissions.FIND_REASONS]}
                      requiresVerifiedOrg={false}
                    >
                      <ReasonsTable />
                    </PermissionsGuard>
                  </>
                }
              />
              {hasScribeFeature && (
                <Route
                  path={SCRIBE_TEMPLATES_ALL.path}
                  element={
                    <>
                      <StaffSettingsNavbar />
                      <PermissionsGuard
                        requiredPermissions={[Permissions.FIND_NOTE_TEMPLATES]}
                        requiresVerifiedOrg={false}
                      >
                        <NoteTemplatesTable />
                      </PermissionsGuard>
                    </>
                  }
                />
              )}
              {hasScribeFeature && (
                <Route
                  path={SCRIBE_TEMPLATES_FAVOURITES.path}
                  element={
                    <>
                      <StaffSettingsNavbar />
                      <PermissionsGuard
                        requiredPermissions={[Permissions.FIND_NOTE_TEMPLATES]}
                        requiresVerifiedOrg={false}
                      >
                        <NoteTemplatesTable />
                      </PermissionsGuard>
                    </>
                  }
                />
              )}
              {hasScribeFeature && (
                <Route
                  path={NON_ADMIN_SCRIBE_TEMPLATES.path}
                  element={<Navigate to={SCRIBE_TEMPLATES_ALL.path} replace />}
                />
              )}
              <Route
                path={NON_ADMIN_BILLING.path}
                element={
                  <>
                    <Heading size="XL" component="h1">
                      Billing
                    </Heading>
                    <PermissionsGuard
                      requiredPermissions={[Permissions.VIEW_BILLING_SELF]}
                      requiresVerifiedOrg={false}
                    >
                      <BillingProvider>
                        <Billing />
                      </BillingProvider>
                    </PermissionsGuard>
                  </>
                }
              />
              <Route path="/*" element={<Navigate to="/appointments" replace />} />
            </Routes>
          )}
          {isAdmin && (
            <Routes>
              <Route
                path="/mikata-admin-dashboard"
                element={
                  <OrganizationGuard>
                    <OrganizationsListPage />
                  </OrganizationGuard>
                }
              />
              <Route
                path="/mikata-admin-dashboard/organizations"
                element={
                  <OrganizationGuard>
                    <OrganizationsListPage />
                  </OrganizationGuard>
                }
              />
              <Route
                path="/mikata-admin-dashboard/organizations/create"
                element={
                  <OrganizationGuard>
                    <OrganizationCreatePage />
                  </OrganizationGuard>
                }
              />
              <Route
                path={ACCOUNT.path}
                element={
                  <OrganizationGuard>
                    <OrganizationDetailsNavBar
                      organizationId={organization?.id?.toString() || ""}
                      displayName={organization?.fullName}
                      organizationType={organization?.type || ""}
                    />
                    <AccountPage />
                  </OrganizationGuard>
                }
              />
              <Route
                path={SERVICES.path}
                element={
                  <OrganizationGuard>
                    <OrganizationDetailsNavBar
                      organizationId={organization?.id?.toString() || ""}
                      displayName={organization?.fullName}
                      organizationType={organization?.type || ""}
                    />
                    <ServicesPage />
                  </OrganizationGuard>
                }
              />
              <Route
                path={ANALYTICS.path}
                element={
                  <OrganizationGuard>
                    <OrganizationDetailsNavBar
                      organizationId={organization?.id?.toString() || ""}
                      displayName={organization?.fullName}
                      organizationType={organization?.type || ""}
                    />
                    <AnalyticsPage />
                  </OrganizationGuard>
                }
              />
              <Route
                path={INTEGRATION.path}
                element={
                  <OrganizationGuard>
                    <OrganizationDetailsNavBar
                      organizationId={organization?.id?.toString() || ""}
                      displayName={organization?.fullName}
                      organizationType={organization?.type || ""}
                    />
                    <IntegrationPage />
                  </OrganizationGuard>
                }
              />
              <Route
                path={FEATURES.path}
                element={
                  <OrganizationGuard>
                    <OrganizationDetailsNavBar
                      organizationId={organization?.id?.toString() || ""}
                      displayName={organization?.fullName}
                      organizationType={organization?.type || ""}
                    />
                    <FeaturesPage />
                  </OrganizationGuard>
                }
              />
              <Route
                path={BILLING.path}
                element={
                  <OrganizationGuard>
                    <OrganizationDetailsNavBar
                      organizationId={organization?.id?.toString() || ""}
                      displayName={organization?.fullName}
                      organizationType={organization?.type || ""}
                    />
                    <BillingPage />
                  </OrganizationGuard>
                }
              />
              <Route
                path={PREFERENCES.path}
                element={
                  <OrganizationGuard>
                    <OrganizationDetailsNavBar
                      organizationId={organization?.id?.toString() || ""}
                      displayName={organization?.fullName}
                      organizationType={organization?.type || ""}
                    />
                    <PreferencesPage />
                  </OrganizationGuard>
                }
              />
              <Route
                path={USERS.path}
                element={
                  <OrganizationGuard>
                    <OrganizationDetailsNavBar
                      organizationId={organization?.id?.toString() || ""}
                      displayName={organization?.fullName}
                      organizationType={organization?.type || ""}
                    />
                    <UsersTable />
                  </OrganizationGuard>
                }
              />
              <Route
                path={LOCATIONS.path}
                element={
                  <OrganizationGuard>
                    <OrganizationDetailsNavBar
                      organizationId={organization?.id?.toString() || ""}
                      displayName={organization?.fullName}
                      organizationType={organization?.type || ""}
                    />
                    <LocationsPage />
                  </OrganizationGuard>
                }
              />
              <Route
                path={REASONS.path}
                element={
                  <OrganizationGuard>
                    <OrganizationDetailsNavBar
                      organizationId={organization?.id?.toString() || ""}
                      displayName={organization?.fullName}
                      organizationType={organization?.type || ""}
                    />
                    <ReasonsTable />
                  </OrganizationGuard>
                }
              />
              <Route
                path={PROVIDERS.path}
                element={
                  <OrganizationGuard>
                    <OrganizationDetailsNavBar
                      organizationId={organization?.id?.toString() || ""}
                      displayName={organization?.fullName}
                      organizationType={organization?.type || ""}
                    />
                    <ProvidersTable />
                  </OrganizationGuard>
                }
              />
              <Route
                path={INSTRUCTIONS.path}
                element={
                  <OrganizationGuard>
                    <OrganizationDetailsNavBar
                      organizationId={organization?.id?.toString() || ""}
                      displayName={organization?.fullName}
                      organizationType={organization?.type || ""}
                    />
                    <InstructionsTable />
                  </OrganizationGuard>
                }
              />
              <Route
                path={APPOINTMENT_REQUESTS.path}
                element={
                  <OrganizationGuard>
                    <OrganizationDetailsNavBar
                      organizationId={organization?.id?.toString() || ""}
                      displayName={organization?.fullName}
                      organizationType={organization?.type || ""}
                    />
                    <AppointmentRequestsTable />
                  </OrganizationGuard>
                }
              />
              <Route
                path={INBOX.path}
                element={
                  <OrganizationGuard>
                    <OrganizationDetailsNavBar
                      organizationId={organization?.id?.toString() || ""}
                      displayName={organization?.fullName}
                      organizationType={organization?.type || ""}
                    />
                    <InboxTable />
                  </OrganizationGuard>
                }
              />
              <Route
                path={APPOINTMENTS.path}
                element={
                  <OrganizationGuard>
                    <OrganizationDetailsNavBar
                      organizationId={organization?.id?.toString() || ""}
                      displayName={organization?.fullName}
                      organizationType={organization?.type || ""}
                    />
                    <AppointmentsTable />
                  </OrganizationGuard>
                }
              />
              <Route
                path={APPOINTMENT_JOURNEYS.path}
                element={
                  <OrganizationGuard>
                    <OrganizationDetailsNavBar
                      organizationId={organization?.id?.toString() || ""}
                      displayName={organization?.fullName}
                      organizationType={organization?.type || ""}
                    />
                    <AppointmentJourneysTable />
                  </OrganizationGuard>
                }
              />
              <Route
                path={MESSAGE_LIBRARY.path}
                element={
                  <OrganizationGuard>
                    <OrganizationDetailsNavBar
                      organizationId={organization?.id?.toString() || ""}
                      displayName={organization?.fullName}
                      organizationType={organization?.type || ""}
                    />
                    <MessageTemplatesTable />
                  </OrganizationGuard>
                }
              />
              <Route
                path={CAMPAIGNS.path}
                element={
                  <OrganizationGuard>
                    <OrganizationDetailsNavBar
                      organizationId={organization?.id?.toString() || ""}
                      displayName={organization?.fullName}
                      organizationType={organization?.type || ""}
                    />
                    <CampaignsPage />
                  </OrganizationGuard>
                }
              />
              <Route
                path={CHATS.path}
                element={
                  <OrganizationGuard>
                    <OrganizationDetailsNavBar
                      organizationId={organization?.id?.toString() || ""}
                      displayName={organization?.fullName}
                      organizationType={organization?.type || ""}
                    />
                    <ChatsTable />
                  </OrganizationGuard>
                }
              />
              <Route
                path={DOCUMENTS.path}
                element={
                  <OrganizationGuard>
                    <OrganizationDetailsNavBar
                      organizationId={organization?.id?.toString() || ""}
                      displayName={organization?.fullName}
                      organizationType={organization?.type || ""}
                    />
                    <AdminDocumentsPage />
                  </OrganizationGuard>
                }
              />
              <Route
                path={SCRIBE_TEMPLATES.path}
                element={
                  <OrganizationGuard>
                    <OrganizationDetailsNavBar
                      organizationId={organization?.id?.toString() || ""}
                      displayName={organization?.fullName}
                      organizationType={organization?.type || ""}
                    />
                    <NoteTemplatesTable />
                  </OrganizationGuard>
                }
              />
              <Route
                path="/mikata-admin-dashboard/organizations/:organizationId/*"
                element={
                  <OrganizationGuard>
                    <OrganizationDetailsNavBar
                      organizationId={organization?.id?.toString() || ""}
                      displayName={organization?.fullName}
                      organizationType={organization?.type || ""}
                    />
                    <AccountPage />
                  </OrganizationGuard>
                }
              />
              <Route
                path="/mikata-admin-dashboard/chats/*"
                element={
                  <OrganizationGuard>
                    <MikataChatsPage />
                  </OrganizationGuard>
                }
              />
              <Route
                path="/mikata-admin-dashboard/scribe-templates/*"
                element={
                  <OrganizationGuard>
                    <MikataNoteTemplatesPage />
                  </OrganizationGuard>
                }
              />
              <Route
                path="/mikata-admin-dashboard/users/*"
                element={
                  <OrganizationGuard>
                    <MikataUsersPage />
                  </OrganizationGuard>
                }
              />
              <Route path="/" element={<Navigate to="/mikata-admin-dashboard" />} />
            </Routes>
          )}
        </PageLayout>
      </NavBar>
    </SessionManager>
  );
};

export default AuthenticatedRoutes;
