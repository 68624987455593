import axios from "axios";
import { ROOT_URL, AccuroURLs } from "../constants";
import getToken from "../utils/getToken";

const getJwt = () => {
  return localStorage.getItem("jwt");
};

export const getAccuroLetterTypes = async () => {
  const body = JSON.stringify({
    endpointUrl: AccuroURLs[process.env.REACT_APP_ENV].LETTERS,
    pathAddition: "/types"
  });
  const resp = await fetch(`${ROOT_URL}/accuro/proxy`, {
    method: "POST",
    headers: { Authorization: `${getJwt()}`, "Content-Type": "application/json" },
    body
  });
  const payload = await resp.json();
  return payload;
};

export const getAccuroStatuses = async () => {
  const body = JSON.stringify({ endpointUrl: AccuroURLs[process.env.REACT_APP_ENV].STATUSES });
  const resp = await fetch(`${ROOT_URL}/accuro/proxy`, {
    method: "POST",
    headers: { Authorization: `${getJwt()}`, "Content-Type": "application/json" },
    body
  });
  const payload = resp.ok ? await resp.json() : null;
  return payload;
};

export const getTelusStatuses = async () => {
  const resp = await fetch(`${ROOT_URL}/integrations/telus/statuses`, {
    method: "GET",
    headers: { Authorization: `${getJwt()}`, "Content-Type": "application/json" }
  });
  const payload = await resp.json();
  return payload;
};

export const getAccuroClientId = async () => {
  const resp = await fetch(`${ROOT_URL}/oauth2/clientid`, {
    method: "GET",
    headers: { Authorization: `${getJwt()}`, "Content-Type": "application/json" }
  });
  const payload = await resp.json();
  return payload;
};

export const getOAuthAccessToken = async (code) => {
  const config = {
    headers: { Authorization: `${getJwt()}`, "Content-Type": "application/json" }
  };

  try {
    const response = await axios.post(`${ROOT_URL}/oauth2/temptoken`, { code }, config);
    const { data } = response;
    return data;
  } catch (e) {
    console.log("error getting auth token: ", e);
    return { error: "error getting OAuth tokens" };
  }
};

export const pushReport = async (reportSnapshotId, pushPdf) => {
  const config = {
    headers: { Authorization: `${getJwt()}`, "Content-Type": "application/json" }
  };

  try {
    const response = await axios.post(
      `${ROOT_URL}/integration/push/report`,
      { reportSnapshotId, pushPdf },
      config
    );
    const { data } = response;
    return data;
  } catch (e) {
    console.log("error pushing report: ", e);
    return { error: "error pushing report" };
  }
};

export const getPushableDemographicOptionsConfig = async (organizationId) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  let requestResponse;

  try {
    requestResponse = await axios.get(
      `${ROOT_URL}/chats/outputs/get-demographics-config/${organizationId}`,
      config
    );
  } catch (error) {
    console.log(error);
    return undefined;
  }

  if (requestResponse) {
    return requestResponse.data;
  }
};
