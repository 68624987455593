import { MessageTopics, MessageConfigurationTopics } from "../constants";

const topicLabelMap: { [topic: string]: string } = {
  [MessageTopics.SEVEN_DAY_SINGLE_REMINDER]: "Reminder 7 Day",
  [MessageTopics.SEVEN_DAY_MULTI_REMINDER]: "Reminder 7 Day Multi",
  [MessageTopics.TWO_DAY_SINGLE_REMINDER]: "Reminder 2 Day",
  [MessageTopics.TWO_DAY_SINGLE_REMINDER_NO_SEVEN]: "Reminder 2 Day No Seven Day",
  [MessageTopics.TWO_DAY_MULTI_REMINDER]: "Reminder 2 Day Multi",
  [MessageTopics.TWO_DAY_MULTI_REMINDER_NO_SEVEN]: "Reminder 2 Day No Seven Day Multi",
  [MessageTopics.TWO_DAY_PREVISIT_LINK]: "Previsit Link 2 day",
  [MessageTopics.BOOKED]: "Booked Message",
  [MessageTopics.MULTI_BOOKED]: "Multi Booked message",
  [MessageTopics.CANCELLED]: "Cancelled Message",
  [MessageTopics.MULTI_CANCELLED]: "Multi Cancelled Message",
  [MessageTopics.POST_VISIT_LINK]: "Postvisit Chat",
  [MessageTopics.REMINDER_RESPONSE_NO_APPT]: "Reminder No Appts Found",
  [MessageTopics.REMINDER_CONFIRM]: "Reminder Confirmed",
  [MessageTopics.REMINDER_RESPONSE_RESCHEDULE]: "Reminder Rescheduled",
  [MessageTopics.REMINDER_RESPONSE_UNCLEAR]: "Reminder Unclear Response",
  [MessageTopics.USER_RESTART_REMINDERS]: "User Restarted Reminders",
  [MessageTopics.USER_SURVEY_OPT_OUT]: "User Declined Surveys",
  [MessageTopics.CAMPAIGN]: "Campaign",
  [MessageTopics.CAMPAIGN_RESPONSE_UNCLEAR]: "Campaign Unclear Response",
  [MessageTopics.CHECK_IN_NOTICE]: "Parking Lot Check-in",
  [MessageTopics.USER_CHECK_IN_RESPONSE]: "Parking Lot Check-in Confirmed",
  [MessageTopics.CHECK_IN_NOTIFY_USER]: "Parking Lot Check-in Notify Patient",
  [MessageTopics.BOOKING_RESPONSE]: "Booking Link",
  [MessageTopics.BOOKING_NOT_ENABLED_RESPONSE]: "Booking Not Enabled",
  [MessageTopics.BOOKING_NOT_ENABLED_FEEDBACK]: "Booking Not Enabled Feedback",
  [MessageTopics.CHECK_IN_NO_APPOINTMENTS]: "Parking Lot Check-in No Appts Found",
  [MessageTopics.CHECK_IN_UNCLEAR]: "Parking Lot Check-in Unclear Response",
  [MessageTopics.SCHEDULE_BASED_MESSAGE]: "Schedule-based Message",
  [MessageTopics.DIRECT_MESSAGE]: "Direct Patient Message",
  [MessageConfigurationTopics.REMINDER]: "Reminder",
  [MessageConfigurationTopics.CUSTOM_MESSAGE]: "Custom Message"
  // [MessageConfigurationTopics.BOOKED]: "Booked Message",
  // [MessageConfigurationTopics.CANCELLED]: "Cancelled Message",
  // [MessageConfigurationTopics.POST_VISIT_LINK]: "Postvisit Chat"
};

const getTopicLabel = (topic: string): string => {
  return topicLabelMap[topic] || topic;
};

export default getTopicLabel;
