import { InputValue } from "../../types";

export const isGreaterThan = (minValue: number, message?: string) => (value: InputValue) => {
  const errorMessage = message || "Value is too low";
  if (value) {
    const newValue = Number(value);
    return newValue <= minValue || Number.isNaN(newValue) ? errorMessage : undefined;
  }
  return errorMessage;
};
