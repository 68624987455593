import React, { useEffect, useContext, useState } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import cx from "classnames";

import NoteEditor from "../NoteEditor";
import ToolsNav from "./ToolsNav";

import { EncounterSocketContext } from "../../../../../providers/EncounterSocketProvider";

import useScreenLock from "../../../../../../hooks/useScreenLock";

import Text from "../../../../../ui/Text";
import { AnnouncementTooltip } from "../../../../../ui/Announcements";
import Loader from "../../../../../ui/Loader";

import ScribeTools from "../ScribeTools";
import AppointmentRecordingButton from "../AppointmentRecordingButton";

import NoteTabs from "./NoteTabs";
import getPatientFullName from "../../../../../../utils/getPatientFullName";

import {
  ReduxStateType,
  Appointment,
  AppointmentDetail,
  Note,
  SocketMessageType,
  InsightActions,
  TranscriptActions,
  ScribeToolMode,
  RecordingSessionStatus,
  AnnouncementName,
  EventTypes
} from "../../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  notes?: Note[];
  notesLoading?: boolean;
  appointmentId?: string;
  appointment?: AppointmentDetail & Appointment;
};

const ScribeToolsManager = ({
  appointmentId,
  appointment: appointmentData,
  notes = [],
  notesLoading = false
}: PropsType) => {
  const { connected, recording, sendMessage } = useContext(EncounterSocketContext);
  const noteIdsForFeedback: number[] = notes.map((note) => note.id);

  // Note state
  const [activeAppointmentNoteId, setActiveAppointmentNoteId] = useState<number | null>(
    notes?.[0]?.id || null
  );

  const [activeTool, setActiveTool] = useState<ScribeToolMode | null>(
    recording?.status === RecordingSessionStatus.RECORDING ? ScribeToolMode.AUDIO_TRANSCRIPT : null
  );
  const setActiveToolWithEvent = (tool: ScribeToolMode | null) => {
    setActiveTool(tool);
    sendMessage(SocketMessageType.providerActivity, {
      eventType: EventTypes.ACTIVE_TOOL_CHANGED,
      eventData: { tool: tool || undefined }
    });
  };

  const [toolOpen, setToolOpen] = useState<boolean>(Boolean(activeTool));

  useScreenLock(recording?.status === RecordingSessionStatus.RECORDING, { appointmentId });

  const patientFirstName = appointmentData?.patientFirstName || "";
  const patientLastName = appointmentData?.patientLastName || "";

  const patientDisplayName = getPatientFullName(patientFirstName, patientLastName);

  // Select first note when available
  useEffect(() => {
    if (!notesLoading && notes.length > 0 && !activeAppointmentNoteId) {
      setActiveAppointmentNoteId(notes?.[0]?.id || null);
    }
  }, [notes.length, notesLoading]);

  // Initialize Insights and Transcripts
  useEffect(() => {
    if (appointmentId) {
      sendMessage(SocketMessageType.insights, {
        action: InsightActions.GET_INITIAL_INSIGHTS,
        appointmentId
      });
      sendMessage(SocketMessageType.transcripts, {
        action: TranscriptActions.GET_TRANSCRIPTS,
        appointmentId
      });
    }
  }, [appointmentId, connected]);

  // Open audio transcripts tool when recording status changes to RECORDING
  useEffect(() => {
    if (recording?.status === RecordingSessionStatus.RECORDING) {
      setActiveToolWithEvent(ScribeToolMode.AUDIO_TRANSCRIPT);
      setToolOpen(true);
    } else if (recording?.status === RecordingSessionStatus.COMPLETE) {
      setActiveToolWithEvent(ScribeToolMode.DO_MORE_WITH_MIKA);
      setToolOpen(true);
    }
  }, [recording?.status]);

  return (
    <div className={cx(styles.GridContainer, { [styles.GridContainerOpen]: toolOpen })}>
      {recording?.status === RecordingSessionStatus.DELETED ? (
        <div className={styles.DataDeletionSettingRow}>
          This data was deleted according to your data retention settings. &nbsp;
          <NavLink
            to={{
              pathname: "/settings/general"
            }}
            className={styles.DataDeletionSetting}
          >
            <Text className={styles.DataDeletionSetting} size="S" component="span">
              Change Setting
            </Text>
          </NavLink>
        </div>
      ) : (
        <div className={styles.NotesColumn}>
          <NoteTabs
            notes={notes}
            recording={recording}
            setActiveAppointmentNoteId={setActiveAppointmentNoteId}
            activeAppointmentNoteId={activeAppointmentNoteId}
            appointmentId={appointmentId}
            appointmentData={appointmentData}
          />

          <div className={styles.Editors}>
            {notesLoading ? (
              <Loader screen center />
            ) : (
              notes.map((note) => (
                <div
                  key={`content-${note.id}`}
                  className={cx(styles.EditorContainer, {
                    [styles.EditorContainerInactive]: activeAppointmentNoteId !== note.id
                  })}
                >
                  <NoteEditor
                    key={`noteEditor-${note.id}`}
                    note={note}
                    isActive={Boolean(
                      activeAppointmentNoteId && activeAppointmentNoteId === note.id
                    )}
                    recordingSessionStatus={recording?.status}
                  />
                </div>
              ))
            )}
          </div>
        </div>
      )}

      <div className={cx(styles.SecondColumn, { [styles.SecondColumnOpen]: toolOpen })}>
        {Boolean(activeTool) && (
          <ScribeTools
            toolMode={activeTool}
            clearToolMode={() => {
              setActiveTool(null);
              setToolOpen(false);
            }}
            recording={recording}
            setActiveAppointmentNoteId={setActiveAppointmentNoteId}
          />
        )}
      </div>

      <div className={styles.NavColumn}>
        <ToolsNav
          recording={recording}
          appointmentData={appointmentData || undefined}
          activeTool={activeTool}
          setActiveTool={setActiveToolWithEvent}
          setToolOpen={setToolOpen}
          appointmentId={appointmentId}
          noteIds={noteIdsForFeedback}
          activeNoteId={activeAppointmentNoteId}
        />
      </div>

      <div className={styles.RecordingButtonContainer}>
        <AnnouncementTooltip announcementName={AnnouncementName.START_SCRIBE_SESSION_TUTORIAL}>
          <AppointmentRecordingButton
            key={`arb-${appointmentId}`}
            patientFullName={patientDisplayName}
          />
        </AnnouncementTooltip>
      </div>
    </div>
  );
};

const mapStateToProps = ({ notes }: ReduxStateType) => {
  return {
    notes: notes.notes || [],
    notesLoading: notes.notesLoading
  };
};

export default connect(mapStateToProps, null)(ScribeToolsManager);
