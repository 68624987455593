import React from "react";

type PropsType = {
  size?: number;
};

const Chat = ({ size = 20 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        fill="currentColor"
        transform="translate(-2 -2)"
        fillRule="evenodd"
        d="M4.583 19.419l3.803-1.268a1 1 0 01.767.056A7.382 7.382 0 0012.5 19a7.502 7.502 0 006.708-4.15 7.38 7.38 0 00.793-3.347l.001-.448c-.21-3.807-3.25-6.846-7.001-7.055h-.503a7.385 7.385 0 00-3.35.795 7.5 7.5 0 00-4.147 6.708 7.38 7.38 0 00.792 3.346 1 1 0 01.056.767L4.583 19.42zm17.419-7.92a9.376 9.376 0 01-1.006 4.248A9.5 9.5 0 0112.504 21a9.38 9.38 0 01-3.868-.824L3.318 21.95a1 1 0 01-1.265-1.265l1.773-5.318A9.498 9.498 0 018.25 3.007 9.378 9.378 0 0112.5 2l.556.002c4.825.266 8.677 4.118 8.945 8.998v.499z"
        id="a"
      />
    </svg>
  );
};

export default Chat;
