import axios from "axios";

import getToken from "../utils/getToken";
import { addNotification } from "./notifications";

import { ROOT_URL, DOCUMENTS_FETCH, DOCUMENTS_CREATE } from "../constants";

import { Document, ActionStatus, Dispatch } from "../types";

export type FetchDocumentsAction = {
  type: typeof DOCUMENTS_FETCH;
  status?: ActionStatus;
  payload?: { documents: Array<Document> };
};

export type FetchDocumentsOptions = {
  silent?: boolean;
};

export const fetchDocuments =
  (options?: FetchDocumentsOptions) =>
  (dispatch: Dispatch): Promise<void> => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    const isSilentFetch = options?.silent;

    dispatch({
      type: DOCUMENTS_FETCH,
      status: isSilentFetch ? ActionStatus.silentLoading : ActionStatus.loading
    });

    return axios
      .get(`${ROOT_URL}/documents`, config)
      .then((payload) => {
        return dispatch({
          type: DOCUMENTS_FETCH,
          status: ActionStatus.success,
          payload: payload.data
        });
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to fetch documents",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
        return dispatch({
          type: DOCUMENTS_FETCH,
          status: ActionStatus.error
        });
      });
  };

export type CreateDocumentData = {
  filename: string;
  organizationId: number;
  s3FileName: string;
  bucketName: string;
};

export type CreateDocumentAction = {
  type: typeof DOCUMENTS_CREATE;
  status: ActionStatus;
};

export const createDocument =
  (documentData: CreateDocumentData, onSuccess?: () => void) =>
  (dispatch: Dispatch): Promise<void> => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: DOCUMENTS_FETCH,
      status: ActionStatus.loading
    });

    return axios
      .post(`${ROOT_URL}/documents`, documentData, config)
      .then((payload) => {
        if (!payload.data) {
          dispatch({ type: DOCUMENTS_CREATE, status: ActionStatus.error });
          dispatch(
            addNotification({
              type: "error",
              title: "Failed to create the document",
              subtitle: payload.data.errorMessages,
              autoDismiss: false
            })
          );
        } else {
          dispatch({ type: DOCUMENTS_CREATE, status: ActionStatus.success, payload });
          fetchDocuments({ silent: false })(dispatch).then(() => {
            dispatch(
              addNotification({
                type: "success",
                title: "Success",
                subtitle: `Successfully created document`,
                autoDismiss: true
              })
            );
          });
          if (onSuccess) {
            onSuccess();
          }
        }
      })
      .catch(() => {
        dispatch({ type: DOCUMENTS_CREATE, status: ActionStatus.error });
        dispatch(
          addNotification({
            type: "error",
            title: "Unfortunately could not create the document",
            subtitle: "Please reload the page and try again",
            autoDismiss: true
          })
        );
      });
  };
