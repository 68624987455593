import { UPDATE_PATIENT_CONTACT_DETAILS } from "../constants";

import { UpdatePatientContactDetailsAction } from "../actions";

import { ActionStatus, PatientContactDetails } from "../types";

export type PatientContactDetailsAction = UpdatePatientContactDetailsAction;

export type PatientContactDetailsReduxState = {
  patientContactDetails?: PatientContactDetails;
  patientContactDetailsLoading?: boolean;
};

const initialPatientContactDetails: PatientContactDetailsReduxState = {
  patientContactDetails: undefined,
  patientContactDetailsLoading: false
};

export const patientContactDetailsReducer = (
  state = initialPatientContactDetails,
  action: PatientContactDetailsAction
): PatientContactDetailsReduxState => {
  const { type } = action;
  switch (type) {
    case UPDATE_PATIENT_CONTACT_DETAILS: {
      const { status, payload } = action as UpdatePatientContactDetailsAction;

      return {
        ...state,
        patientContactDetails: { ...payload } || state.patientContactDetails,
        patientContactDetailsLoading: status === ActionStatus.loading
      };
    }

    default:
      return state;
  }
};
