import React from "react";

import Heading from "../../../ui/Heading";
import AppointmentRequestsTable from "../../../common/tables/AppointmentRequestsTable";

import styles from "./index.module.scss";

const AppointmentRequestsPage = () => {
  return (
    <div>
      <Heading className={styles.Heading} size="XL">
        Appointment Requests
      </Heading>
      <AppointmentRequestsTable />
    </div>
  );
};

export default AppointmentRequestsPage;
