import React from "react";

type PropsType = {
  size?: number;
};

const PinBlank = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13216_7887)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.7895 1.37492C15.0084 0.593869 13.7421 0.593869 12.961 1.37492L11.5468 2.78913C10.7658 3.57018 10.7658 4.83651 11.5468 5.61756L12.2539 6.32466L10.8838 7.69478C9.96663 7.38585 8.99722 7.22402 8.01116 7.22402C6.00131 7.22402 4.06084 7.89628 2.49027 9.11626C1.51164 9.87642 1.60082 11.2279 2.3543 11.9814L6.63616 16.2632L2.39351 20.5059C2.00299 20.8964 2.00299 21.5296 2.39351 21.9201C2.78404 22.3106 3.4172 22.3106 3.80773 21.9201L8.05037 17.6775L12.2538 21.8809C13.0073 22.6344 14.3588 22.7235 15.1189 21.7449C16.3389 20.1743 17.0112 18.2339 17.0112 16.224C17.0112 15.2381 16.8494 14.2688 16.5405 13.3518L17.9108 11.9815L18.6179 12.6886C19.3989 13.4697 20.6653 13.4697 21.4463 12.6886L22.8605 11.2744C23.6416 10.4934 23.6416 9.22703 22.8605 8.44598L15.7895 1.37492ZM14.3753 2.78913L21.4463 9.8602L20.0321 11.2744L18.6179 9.8602C18.2274 9.46967 17.5942 9.46967 17.2037 9.8602L14.6695 12.3944C14.3814 12.6825 14.2969 13.1166 14.4559 13.4918C14.8189 14.348 15.0112 15.2761 15.0112 16.224C15.0112 17.7439 14.5169 19.2129 13.6173 20.4159L3.81926 10.6179C5.02231 9.71832 6.49128 9.22402 8.01116 9.22402C8.95923 9.22402 9.88742 9.41633 10.7437 9.7794C11.1189 9.93847 11.553 9.85399 11.8412 9.56585L14.3753 7.03177C14.7658 6.64125 14.7658 6.00808 14.3753 5.61756L12.961 4.20334L14.3753 2.78913Z"
          fill="#94979E"
        />
      </g>
      <defs>
        <clipPath id="clip0_13216_7887">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PinBlank;
