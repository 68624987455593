import React from "react";

type PropsType = {
  size?: number;
};

const Clipboard = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M15 1a2 2 0 012 2h1a3 3 0 013 3v14a3 3 0 01-3 3H6a3 3 0 01-3-3V6a3 3 0 013-3h1a2 2 0 012-2h6zM7 5H6a1 1 0 00-1 1v14a1 1 0 001 1h12a1 1 0 001-1V6a1 1 0 00-1-1h-1a2 2 0 01-2 2H9a2 2 0 01-2-2zm8-2H9v2h6V3z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Clipboard;
