import axios from "axios";

import getToken from "../utils/getToken";

import { closeModal } from "./modals";
import {
  addNotification,
  invalidAutomationAssociationsNotification,
  InvalidAutomationAssociation
} from "./notifications";

import {
  ROOT_URL,
  FETCH_AUTOMATED_MESSAGES,
  FETCH_AUTOMATION,
  UPDATE_AUTOMATION,
  ADD_AUTOMATION,
  DELETE_AUTOMATION,
  GET_AUTOMATION_JOURNEY,
  CLEAR_AUTOMATION_JOURNEY
} from "../constants";
import {
  Dispatch,
  Automation,
  AutomationDashboard,
  AutomationDetails,
  AutomationStatus,
  ActionStatus,
  ChatFlow,
  AutomationJourneyDetails,
  MessageSchedule,
  AutomationActionRangeTime
} from "../types";

export type FetchAutomatedMessagesAction = {
  type: typeof FETCH_AUTOMATED_MESSAGES;
  status?: ActionStatus;
  payload?: {
    automations: Array<AutomationDashboard>;
    availableChats: Array<ChatFlow>;
    messageSchedules: MessageSchedule[];
  };
};

export const fetchAutomatedMessages = () => async (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  dispatch({
    type: FETCH_AUTOMATED_MESSAGES,
    status: ActionStatus.loading
  });

  try {
    const response = await axios.get(`${ROOT_URL}/automations/find`, config);

    dispatch({
      type: FETCH_AUTOMATED_MESSAGES,
      status: ActionStatus.success,
      payload: response.data
    });
  } catch (e) {
    dispatch(
      addNotification({
        type: "error",
        title: "Error fetching automations data",
        subtitle: "Please try again",
        autoDismiss: true
      })
    );

    dispatch({
      type: FETCH_AUTOMATED_MESSAGES,
      status: ActionStatus.error
    });
  }
};

export type FetchAutomationOptions = {
  silent?: boolean;
};

export type FetchAutomationAction = {
  type: typeof FETCH_AUTOMATION;
  status?: ActionStatus;
  payload?: AutomationDetails;
  checkInChats?: Array<ChatFlow>;
};

export const fetchAutomation =
  (automationId: string, options?: FetchAutomationOptions) => async (dispatch: Dispatch) => {
    const { silent = false } = options || {};

    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: FETCH_AUTOMATION,
      status: silent ? ActionStatus.silentLoading : ActionStatus.loading
    });

    try {
      const response = await axios.get(`${ROOT_URL}/automations/${automationId}`, config);

      dispatch({
        type: FETCH_AUTOMATION,
        status: ActionStatus.success,
        payload: { ...response.data.automation, checkInChats: response.data.checkInChats },
        checkInChats: response.data.checkInChats
      });
    } catch (e) {
      dispatch(
        addNotification({
          type: "error",
          title: "Error fetching automation details",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );

      dispatch({
        type: FETCH_AUTOMATED_MESSAGES,
        status: ActionStatus.error
      });
    }
  };

export type AddAutomationAction = {
  type: typeof ADD_AUTOMATION;
  status?: ActionStatus;
};

export type AddAutomationData = {
  displayName: string;
  description: string;
  cloneAutomationId?: string;
};

type AddAutomationResponse = {
  status: number;
  data: {
    success: boolean;
    conflictingTriggers: Array<Automation>;
  };
};

export const addAutomation = (automationData: AddAutomationData) => async (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };
  dispatch({
    type: ADD_AUTOMATION,
    status: ActionStatus.loading
  });

  return axios
    .post(`${ROOT_URL}/automations`, automationData, config)
    .then((payload: AddAutomationResponse) => {
      if (!payload || !payload.data.success) {
        dispatch({
          type: ADD_AUTOMATION,
          status: ActionStatus.error
        });

        const errorMessage =
          payload.data.conflictingTriggers && payload.data.conflictingTriggers.length > 0
            ? `The selected triggers are identical to those in the following automation: ${payload.data.conflictingTriggers
                .map((automation) => automation.displayName)
                .join(", ")}.`
            : "Please try again";

        return dispatch(
          addNotification({
            type: "error",
            title: "Failed to add the journey",
            subtitle: errorMessage,
            autoDismiss: true
          })
        );
      }
      fetchAutomatedMessages()(dispatch);
      closeModal()(dispatch);
      dispatch(
        addNotification({
          type: "success",
          title: "Success",
          subtitle: "Journey created",
          autoDismiss: true
        })
      );
      return dispatch({
        type: ADD_AUTOMATION,
        status: ActionStatus.success
      });
    })
    .catch(() => {
      dispatch(
        addNotification({
          type: "error",
          title: "Failed to update the journey",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );

      dispatch({
        type: ADD_AUTOMATION,
        status: ActionStatus.error
      });
    });
};

export type UpdateAutomationAction = {
  type: typeof UPDATE_AUTOMATION;
  status?: ActionStatus;
  payload?: AutomationDetails;
};

export type UpdateAutomationData = {
  displayName?: string;
  description?: string;
  status?: AutomationStatus;
  locationIds?: Array<string>;
  practitionerIds?: Array<string>;
  reasonIds?: Array<string>;
  messageTemplateIds?: Array<string>;
  excludedTopics?: Array<string>;
  settings?: {
    locationId?: number | null;
    hideAddress?: boolean;
    hidePhoneNumber?: boolean;
    hideDate?: boolean;
    hideTime?: boolean;
    actions?: {
      reschedule?: {
        active: boolean;
        rangeStart: AutomationActionRangeTime;
        rangeEnd: AutomationActionRangeTime;
      };
      confirm?: {
        active: boolean;
        rangeStart: AutomationActionRangeTime;
        rangeEnd: AutomationActionRangeTime;
      };
      checkin?: {
        active: boolean;
        rangeStart: AutomationActionRangeTime;
        rangeEnd: AutomationActionRangeTime;
      };
    };
  } | null;
};

type UpdateAutomationResponse = {
  status: number;
  data: {
    success: boolean;
    conflictingTriggers: Array<Automation>;
    conflictingTriggersForSchedule?: Array<Automation>;
    invalidAssociations?: Array<InvalidAutomationAssociation>;
    updatedAutomation?: AutomationDetails;
  };
};

export const updateAutomation =
  (automationId: string, automationData: UpdateAutomationData) => async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };
    dispatch({
      type: UPDATE_AUTOMATION,
      status: ActionStatus.loading
    });

    return axios
      .patch(`${ROOT_URL}/automations/${automationId}`, automationData, config)
      .then((payload: UpdateAutomationResponse) => {
        if (!payload || !payload.data.success || !payload.data.updatedAutomation) {
          dispatch({
            type: UPDATE_AUTOMATION,
            status: ActionStatus.error
          });

          if (payload.data.invalidAssociations && payload.data.invalidAssociations.length > 0) {
            return dispatch(
              invalidAutomationAssociationsNotification(payload.data.invalidAssociations)
            );
          }

          let errorMessage = "";
          if (payload.data.conflictingTriggers && payload.data.conflictingTriggers.length > 0) {
            errorMessage = `The selected triggers are identical to those in the following automation: ${payload.data.conflictingTriggers
              .map((automation) => automation.displayName)
              .join(", ")}.`;
          } else if (
            payload.data.conflictingTriggersForSchedule &&
            payload.data.conflictingTriggersForSchedule.length > 0
          ) {
            errorMessage = `The following automations have the same triggers and are already using a selected schedule for a schedule-based message: ${payload.data.conflictingTriggersForSchedule
              .map((automation) => automation.displayName)
              .join(", ")}.`;
          } else {
            errorMessage = "Please try again";
          }

          return dispatch(
            addNotification({
              type: "error",
              title: "Failed to update the journey",
              subtitle: errorMessage,
              autoDismiss: true
            })
          );
        }

        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: "Journey saved",
            autoDismiss: true
          })
        );
        dispatch({
          type: UPDATE_AUTOMATION,
          status: ActionStatus.success,
          payload: payload.data.updatedAutomation
        });

        return fetchAutomation(automationId, { silent: true })(dispatch);
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to update the journey",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );

        dispatch({
          type: UPDATE_AUTOMATION,
          status: ActionStatus.error
        });
      });
  };

export type AutomationDeleteData = {
  id: string;
  onSuccess?: () => void;
};

export type DeleteAutomationAction = {
  type: typeof DELETE_AUTOMATION;
  status?: ActionStatus;
};

type DeleteAutomationResponse = {
  status: number;
  data: {
    error?: boolean;
  };
};

export const deleteAutomation = (data: AutomationDeleteData) => (dispatch: Dispatch) => {
  const { id, onSuccess } = data;
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  const handleFailure = () => {
    dispatch(
      addNotification({
        type: "error",
        title: "Failed to delete journey",
        subtitle: "Please try again",
        autoDismiss: true
      })
    );

    dispatch({
      type: DELETE_AUTOMATION,
      status: ActionStatus.error
    });
  };

  dispatch({
    type: DELETE_AUTOMATION,
    status: ActionStatus.loading
  });

  return axios
    .delete(`${ROOT_URL}/automations/${id}`, config)
    .then((payload: DeleteAutomationResponse) => {
      if (!payload || payload.data.error) {
        handleFailure();
        return;
      }
      closeModal()(dispatch);

      if (onSuccess) {
        onSuccess();
      }

      dispatch(
        addNotification({
          type: "success",
          title: "Success",
          subtitle: "Journey deleted",
          autoDismiss: true
        })
      );
      dispatch({
        type: DELETE_AUTOMATION,
        status: ActionStatus.success
      });

      fetchAutomatedMessages()(dispatch);
    })
    .catch(() => {
      return handleFailure();
    });
};

export type GetAutomationJourneyAction = {
  type: typeof GET_AUTOMATION_JOURNEY;
  status?: ActionStatus;
  payload?: AutomationJourneyDetails[];
};

export type AutomationJourneyGetData = {
  locationId: string | null;
  reasonId: string | null;
  practitionerId: string | null;
  onSuccess?: () => void;
};

export const getAutomationJourney =
  (data: AutomationJourneyGetData) => async (dispatch: Dispatch) => {
    const { onSuccess, locationId, reasonId, practitionerId } = data;
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: GET_AUTOMATION_JOURNEY,
      status: ActionStatus.loading
    });

    try {
      const response = await axios.get(
        `${ROOT_URL}/automations/journey?locationId=${locationId}&reasonId=${reasonId}&practitionerId=${practitionerId}`,
        config
      );

      dispatch({
        type: GET_AUTOMATION_JOURNEY,
        status: ActionStatus.success,
        payload: response.data.journey
      });

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      dispatch(
        addNotification({
          type: "error",
          title: "Error fetching appointment journey",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );

      dispatch({
        type: GET_AUTOMATION_JOURNEY,
        status: ActionStatus.error
      });
    }
  };

export type ClearAutomationJourneyAction = {
  type: typeof CLEAR_AUTOMATION_JOURNEY;
};

export const clearAutomationJourney = () => ({ type: CLEAR_AUTOMATION_JOURNEY });
