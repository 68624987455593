import React from "react";
import cx from "classnames";

import Heading from "../../../../../../ui/Heading";
import Text from "../../../../../../ui/Text";
import { SelectInput } from "../../../../../../ui/Input";
import Drawer from "../../../../../../ui/Drawer";
import Button from "../../../../../../ui/Button";
import { Close } from "../../../../../../ui/Icon";

import { usePermissions } from "../../../../../../../hooks/usePermissions";

import { SubscriptionFormState, SUBSCRIPTIONS_NAMESPACE } from "..";

import { CustomOnChangeHandler, Option, Permissions } from "../../../../../../../types";

import styles from "./index.module.scss";

type ProviderSubscriptionsType = {
  subscriptions: SubscriptionFormState[];
  providerOptions?: Option[];
  onChange: CustomOnChangeHandler;
};

const subscriptionType = [
  { label: "Arrears", value: "arrears" },
  { label: "Advance", value: "advance" }
];

const ProviderSubscriptions = ({
  subscriptions: providerSubscriptions,
  providerOptions,
  onChange
}: ProviderSubscriptionsType) => {
  const userCanViewBilling = usePermissions([Permissions.VIEW_BILLING], false);
  const userCanEditBilling = usePermissions([Permissions.UPDATE_BILLING], false);

  return (
    <>
      {providerSubscriptions.length === 0 && <Text size="M">No provider subscriptions</Text>}
      {providerSubscriptions.map((sub) => (
        <React.Fragment key={sub.id}>
          <div
            className={cx(styles.ChargeOverSubscriptionCard, {
              [styles.ChargeOverSubscriptionCardNoLineItems]: sub?.lineItems?.length === 0
            })}
          >
            <div className={styles.ProviderSubscriptionInfo}>
              <div className={styles.Unassign}>
                <Button
                  inline
                  onClick={() => {
                    onChange({ [`${SUBSCRIPTIONS_NAMESPACE}.${sub.id}.userId`]: "_unassign" });
                  }}
                >
                  <Close size={24} />
                </Button>
              </div>
              <div className={styles.SubscriptionIdGroup}>
                <Text size="S" className={styles.SubscriptionHeading}>
                  PROVIDER SUBSCRIPTION ID
                </Text>
                <Text size="M" className={styles.SubscriptionId}>
                  {sub.id}
                </Text>
              </div>

              <div>
                <SelectInput
                  fieldName={`${SUBSCRIPTIONS_NAMESPACE}.${sub.id}.userId`}
                  label="provider"
                  options={providerOptions || []}
                  initialValue={sub.userId?.toString()}
                  customOnChange={onChange}
                  disabled={userCanViewBilling && !userCanEditBilling}
                />
              </div>
              <div>
                <SelectInput
                  fieldName={`${SUBSCRIPTIONS_NAMESPACE}.${sub.id}.type`}
                  label="type"
                  options={subscriptionType}
                  initialValue={sub.type?.toString()}
                  customOnChange={onChange}
                  disabled={userCanViewBilling && !userCanEditBilling}
                />
              </div>
            </div>
            {sub?.lineItems?.length && sub?.lineItems?.length > 0 ? (
              <Drawer
                initialOpen
                headerContent={
                  <div>
                    <Heading size="S" className={styles.DrawerHeaderContent}>
                      Line Items
                    </Heading>
                  </div>
                }
              >
                <div className={styles.LineItemGroup}>
                  {sub?.lineItems?.map((lineItem) => (
                    <div key={lineItem.productName} className={styles.LineItemCard}>
                      <Heading size="META">{lineItem.productName}</Heading>
                      <Text>{lineItem.id}</Text>
                    </div>
                  ))}
                </div>
              </Drawer>
            ) : (
              <div className={styles.NoLineItems}>
                <Text>No line Items</Text>
              </div>
            )}
          </div>
        </React.Fragment>
      ))}
    </>
  );
};

export default ProviderSubscriptions;
