import React from "react";
import { connect } from "react-redux";

import cx from "classnames";

import Button from "../../Button";
import Heading from "../../Heading";
import { Book, BugTwo, Close, ExternalLink, Insights, Message } from "../../Icon";
import { ResponsiveHide } from "../../Responsive";

import { setFeedbackMode } from "../../../../actions";

import { FeedbackFormModes } from "../../../../typedConstants";
import { SetFeedbackModeData } from "../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  setFeedbackMode: (data: SetFeedbackModeData) => void;
};

const FeedbackType = ({ setFeedbackMode }: PropsType): JSX.Element => {
  return (
    <>
      <div className={styles.FeedbackTypeHeaderRow}>
        <Heading size="S" bold className={styles.FeedbackTypeHeading}>
          Give Feedback
        </Heading>

        <ResponsiveHide hideOnMobile>
          <div className={styles.FeedbackFormCloseButtonWrapper}>
            <Button
              id="closeFeedbackForm"
              className={styles.CloseButton}
              type="button"
              inline
              onClick={() => setFeedbackMode({ feedbackMode: null })}
            >
              <Close size={20} />
            </Button>
          </div>
        </ResponsiveHide>
      </div>
      <button
        type="submit"
        className={styles.FeedbackTypeButton}
        onClick={() => setFeedbackMode({ feedbackMode: FeedbackFormModes.SCRIBE_FEEDBACK })}
      >
        <Insights size={24} />
        Feedback on Mika AI
      </button>
      <button
        type="submit"
        className={styles.FeedbackTypeButton}
        onClick={() => setFeedbackMode({ feedbackMode: FeedbackFormModes.REPORT_ISSUE })}
      >
        <BugTwo size={24} />
        Report a bug
      </button>
      <button
        type="button"
        className={styles.FeedbackTypeButton}
        onClick={() => setFeedbackMode({ feedbackMode: FeedbackFormModes.SUGGESTION })}
      >
        <Message size={24} />
        Suggest an idea
      </button>
      <button
        type="button"
        className={styles.FeedbackTypeButton}
        onClick={() => setFeedbackMode({ feedbackMode: FeedbackFormModes.SCRIBE_TEMPLATE_REQUEST })}
      >
        <Message size={24} />
        Request a template
      </button>
      <a
        className={styles.ToolColumnFooterLink}
        type="button"
        href="https://mikata-health.gitbook.io/mikata-product-guide-for-customers/mika-scribe/introducing-mika"
        rel="noreferrer"
        target="_blank"
      >
        <div className={cx(styles.FeedbackTypeButton, styles.Guide)}>
          <div className={styles.GuideBookRow}>
            <Book size={24} />
            Check the guide book
          </div>
          <ExternalLink size={24} />
        </div>
      </a>
    </>
  );
};

export default connect(null, { setFeedbackMode })(FeedbackType);
