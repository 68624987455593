import { EditorState } from "draft-js";

import { updateEntityData } from "./injectMikaContent";

import { MikaContentStatus } from "../types";

export const transformMikaContentEntityDataOnChange = (
  currentEditorState: EditorState
): EditorState => {
  let transformedEditorState = currentEditorState;

  const currentSelection = transformedEditorState.getSelection();
  if (currentSelection.getHasFocus()) {
    const currentFocusBlockKey = currentSelection.getAnchorKey();
    const currentContent = transformedEditorState.getCurrentContent();
    const focusBlock = currentContent.getBlockForKey(currentFocusBlockKey);
    const focusEntityKey = focusBlock?.getEntityAt(0);

    if (focusEntityKey) {
      const focusEntityData = currentContent.getEntity(focusEntityKey)?.getData();

      if (
        focusEntityData &&
        focusEntityData.insightAnchorName &&
        focusEntityData.status !== MikaContentStatus.ACCEPTED
      ) {
        // Update the mikaContentEntityData.status
        transformedEditorState = updateEntityData(
          currentEditorState,
          { status: MikaContentStatus.ACCEPTED },
          focusEntityKey
        );
      }
    }
  }

  return transformedEditorState;
};
