import React from "react";
import cx from "classnames";
import isEqual from "lodash/isEqual";

import { useFormState } from "informed";
import Button from "../../../ui/Button";
import Heading from "../../../ui/Heading";
import { TextInput, CheckboxGroup, SelectInput } from "../../../ui/Input";
import Status from "../../../ui/Status";
import Text from "../../../ui/Text";
import Tooltip from "../../../ui/Tooltip";
import { Info, Plus } from "../../../ui/Icon";

import { isEmail, isRequired } from "../../../../utils/validators";
import { resetPasswordButtonText } from "../../../../utils/resetPasswordButtonText";

import { OpenModal } from "../../../../actions";

import { userTypeDisplayMap } from "../../../../utils/userTypeDisplayMap";

import { ModalTypes, userStatusConfigMap, userStatusOptions } from "../../../../constants";

import { Location, Option, Staff } from "../../../../types";

import styles from "./index.module.scss";

type FormState = {
  firstName: string;
  lastName: string;
  email: string | null;
  roleId: number | undefined;
  locationIds: string[];
};

type PropsType = {
  staffInfo: Staff;
  status: string;
  type: string;
  locations: Array<Location>;
  isMikataAdmin: boolean;
  email: string | null;
  editEmail: boolean;
  firstName: string;
  lastName: string;
  roleOptions: Option[];
  active: boolean;
  initialFormState: FormState;
  formDisabled: boolean;
  setEditEmail: (editEmail: boolean) => void;
  openModal: OpenModal;
  deactivateUser: () => void;
  resetPassword: () => void;
  saveAndSendInvite: (email: string | null) => void;
  reactivateUser: (email?: string | null) => void;
};

const emailValidator = isEmail("Please enter a valid email");
const firstNameValidator = isRequired("Please enter a first name");
const lastNameValidator = isRequired("Please enter a last name");
const locationValidator = isRequired("Please select a location");

const EditStaffUserForm = ({
  staffInfo,
  locations,
  openModal,
  setEditEmail,
  active,
  type,
  firstName,
  lastName,
  roleOptions,
  email,
  reactivateUser,
  resetPassword,
  saveAndSendInvite,
  deactivateUser,
  formDisabled,
  editEmail,
  isMikataAdmin,
  initialFormState,
  status
}: PropsType) => {
  const formState = useFormState();
  const values = formState.values as FormState;
  const hasFormChanges = !isEqual({ ...values }, initialFormState);

  return (
    <div>
      <div className={styles.StatusRow}>
        <div>
          <Heading size="META">status</Heading>
          <Status value={status} options={userStatusOptions} configMap={userStatusConfigMap} />
        </div>
        <div>
          <Heading size="META">type</Heading>
          <Text>{userTypeDisplayMap(type)}</Text>
        </div>
      </div>
      <div className={styles.Email}>
        <div className={styles.HeadingTooltip}>
          <Heading size="META">email</Heading>
          {!isMikataAdmin && (
            <Tooltip
              icon={
                <div className={styles.Icon}>
                  <Info size={15} />
                </div>
              }
              position="topRight"
            >
              {!email
                ? "Add a staff member's email to invite them to use Mikata"
                : "To change an email address please contact Mikata Health"}
            </Tooltip>
          )}
        </div>
        {!email && !editEmail && (
          <Button
            id="editStaffEmail"
            inline
            disabled={!active}
            onClick={() => {
              setEditEmail(true);
            }}
          >
            <Plus size={16} />
            <span className={styles.AddButtonText}>Add email</span>
          </Button>
        )}
        {email && !editEmail && (
          <div className={styles.ViewEmail}>
            <Text>{email}</Text>
            <div className={styles.EmailButtons}>
              {isMikataAdmin && (
                <Button id="changeEmail" inline onClick={() => setEditEmail(true)}>
                  Change email
                </Button>
              )}
              <Button
                id="resetPasswordButton"
                inline
                onClick={!active ? () => reactivateUser() : () => resetPassword()}
              >
                {resetPasswordButtonText(status)}
              </Button>
            </div>
            <div />
          </div>
        )}
        {editEmail && (
          <>
            <div className={styles.EditEmail}>
              <TextInput fieldName="email" placeholder="Enter email" validate={emailValidator} />
              <Button
                id="saveAndSendInvite"
                className={styles.SendInvite}
                inline
                onClick={() =>
                  !active ? reactivateUser(values.email) : saveAndSendInvite(values.email)
                }
              >
                Save & send invite
              </Button>
            </div>
            {!isMikataAdmin && (
              <Text size="M" className={cx(styles.Text, styles.TextWarning)}>
                Please make sure the email is correct before adding and sending. If you want to
                change the email, you will have to contact us.
              </Text>
            )}
          </>
        )}
      </div>
      <TextInput
        fieldName="firstName"
        label="first name"
        placeholder="Enter first name"
        disabled={formDisabled}
        validate={firstNameValidator}
      />
      <TextInput
        fieldName="lastName"
        label="last name"
        placeholder="Enter last name"
        disabled={formDisabled}
        validate={lastNameValidator}
      />
      <SelectInput fieldName="roleId" label="role" disabled={formDisabled} options={roleOptions} />

      {locations && locations.length > 0 && (
        <CheckboxGroup
          fieldName="locationIds"
          label="choose location(s)"
          disabled={formDisabled}
          validate={locationValidator}
          showCheckAll={locations.length > 2}
          options={locations.map((location) => ({
            label: `${location.displayName || location.fullName} ${
              !location.active ? " (inactive)" : ""
            }`,
            value: location.id.toString()
          }))}
          searchPlaceholder="Find a location"
        />
      )}

      <hr className={styles.Divider} />

      <div className={styles.ButtonGroup}>
        {staffInfo.active && (
          <Button
            inline
            danger
            disabled={formDisabled}
            onClick={() =>
              openModal(ModalTypes.CONFIRMATION, {
                onConfirmation: deactivateUser,
                title: "Deactivate user",
                confirmButtonText: "Deactivate",
                content: `You are about to deactivate ${firstName} ${lastName}. Is this what you want to do?`,
                confirmButtonVariant: "danger",
                mustCheckConfirm: true,
                confirmationText: "Yes, I want to deactivate this user"
              })
            }
          >
            Deactivate user
          </Button>
        )}
        <Button
          className={styles.SaveButton}
          type="submit"
          disabled={!hasFormChanges || !active || formDisabled}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default EditStaffUserForm;
