import React, { useState } from "react";
import { connect } from "react-redux";

import { FloatModal } from "../../../ui/Modal";
import Heading from "../../../ui/Heading";
import Text from "../../../ui/Text";
import { Form, UploadInput } from "../../../ui/Input";
import Button from "../../../ui/Button";

import {
  closeModal as closeModalAction,
  addReasons as addReasonsAction,
  addNotification,
  AddReasonsData
} from "../../../../actions";
import { BucketNameSuffixes } from "../../../../constants";

import styles from "./index.module.scss";
import { getSignedUrl, uploadFiles } from "../../../../lib";
import { isRequired } from "../../../../utils/validators";

type PropsType = {
  closeModal: () => void;
  addReasons: (data: AddReasonsData, onSuccess?: () => void) => void;
};

type FormState = {
  uploadFile: File | null;
};

const initialFormState = {
  uploadFile: null
};

const formValidator = (values: FormState) => {
  return {
    uploadFile: isRequired("Please upload a file")(values.uploadFile)
  };
};

const AddReasons = ({ closeModal, addReasons }: PropsType): JSX.Element => {
  const [uploadingFile, setUploadingFile] = useState(false);

  const save = async (formValues: FormState) => {
    let s3FileName;

    try {
      const { url, filename } = await getSignedUrl({
        bucketName: BucketNameSuffixes.STAFF_UPLOADS,
        fileExt: "csv"
      });
      s3FileName = filename;

      setUploadingFile(true);
      await uploadFiles(url, formValues.uploadFile);

      const data: AddReasonsData = {
        fileName: s3FileName
      };
      addReasons(data, () => closeModal());
      setUploadingFile(false);
    } catch (error) {
      setUploadingFile(false);

      return addNotification({
        type: "error",
        title: "Failed to upload file",
        subtitle: "Please try again"
      });
    }
  };

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L" className={styles.Heading}>
        Add New Reasons
      </Heading>
      <Form
        onSubmit={(formState) => save(formState.values as FormState)}
        initialValues={initialFormState}
        validateFields={(values) => formValidator(values as FormState)}
      >
        <Text className={styles.Heading}>
          Please upload a CSV file of procedure codes and their descriptions.
        </Text>

        <UploadInput
          fieldName="uploadFile"
          label="Upload file"
          acceptedFileTypes={["text/csv", ".csv"]}
        />

        <hr className={styles.Divider} />

        <div className={styles.ButtonGroup}>
          <Button inline onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit" disabled={uploadingFile}>
            Create
          </Button>
        </div>
      </Form>
    </FloatModal>
  );
};

export default connect(null, {
  closeModal: closeModalAction,
  addReasons: addReasonsAction
})(AddReasons);
