import React, { Dispatch, SetStateAction, useMemo } from "react";

import { TextInput, TextAreaInput } from "../../../../../../../../ui/Input";
import { ChatFlowsNodes } from "../../../../../../../../../types";
import BaseChatNodeForm, {
  BaseFormValues,
  FormValues,
  FlaggedEndFormValues
} from "../../BaseChatNodeForm";

type FlaggedEndPayloadJSONContent = {
  headerContent?: string;
  bodyContent?: string;
  redirectURL?: string;
};
type FlaggedEndPayloadContent = undefined | string | FlaggedEndPayloadJSONContent;

export type FlaggedEndChatFlowsNode = ChatFlowsNodes & {
  payloadContent: FlaggedEndPayloadContent;
};

type PropsType = {
  chatId: string | undefined;
  node: FlaggedEndChatFlowsNode;
  viewOnly: boolean;
  setSelectedNodeId: Dispatch<SetStateAction<number | null>>;
};

const isJson = (data: string) => {
  try {
    JSON.parse(data);
  } catch (e) {
    return false;
  }
  return true;
};

const onSaveCustomTransformer = (formValues: FormValues) => {
  return {
    payloadContent: formValues as BaseFormValues & FlaggedEndFormValues
  };
};

const FlaggedEnd = ({ chatId, node, viewOnly, setSelectedNodeId }: PropsType) => {
  const { payloadContent } = node;
  const initialFormValues: FlaggedEndFormValues = useMemo(() => {
    const defaultPayloadContent = {
      headerContent: "",
      bodyContent: "",
      redirectURL: ""
    };

    if (payloadContent === null) {
      return defaultPayloadContent;
    }

    if (isJson(payloadContent)) {
      return {
        ...defaultPayloadContent,
        ...(JSON.parse(payloadContent) as FlaggedEndPayloadJSONContent)
      };
    }

    return {
      ...defaultPayloadContent,
      headerContent: payloadContent
    };
  }, [node.id]);

  return (
    <BaseChatNodeForm
      chatId={chatId}
      key={node.id}
      initialValues={initialFormValues}
      onSaveCustomTransformer={onSaveCustomTransformer}
      node={node}
      viewOnly={viewOnly}
      setSelectedNodeId={setSelectedNodeId}
    >
      <TextAreaInput size="S" fieldName="headerContent" label="Title" disabled={viewOnly} />
      <TextAreaInput size="S" fieldName="bodyContent" label="Subtitle" disabled={viewOnly} />
      <TextInput fieldName="redirectURL" label="redirect URL" disabled={viewOnly} />
    </BaseChatNodeForm>
  );
};

export default FlaggedEnd;
