import React, { useEffect, useMemo, useState } from "react";
import cx from "classnames";
import { useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

import { isEqual } from "lodash";
import {
  fetchTasks as fetchTaskAction,
  TaskFindParameters,
  fetchTaskCounts as fetchTaskCountsAction,
  getTask as getTaskAction,
  assignTasks as assignTasksAction,
  AssignTasksData
} from "../../../../actions";
import {
  Task,
  ReduxStateType,
  TaskCounts,
  TaskFilterOptions,
  TaskAppointmentRequestContext,
  TaskDirectMessageContext,
  Staff,
  Practitioner,
  Location,
  Reason
} from "../../../../types";
import { ALL, BookingModes, InboxModes, TaskTypes, TaskOpenStates } from "../../../../constants";

import Button from "../../../ui/Button";
import { AutoBook, ApptBookWithTime, Calendar, Chat, PipOff } from "../../../ui/Icon";
import { CheckboxInputBase } from "../../../ui/Input";
import Loader from "../../../ui/Loader";
import { SortByValue, SortByDirection, StopClickPropagation } from "../../../ui/TableGrid";
import Status from "../../../ui/Status";

import formatDate from "../../../../utils/formatDate";
import { updateQueryString, useQueryString } from "../../../../utils/queryStringHelpers";

import InboxDetailsSwitch from "./InboxDetails/InboxDetailsSwitch";

import InboxMode from "./InboxMode";
import InboxTaskGrid from "./InboxTaskGrid";
import InboxFilters from "./InboxFilters";
import InboxPatient from "./InboxPatient";

import AppointmentRequestContext from "./TaskContext/AppointmentRequestContext";
import DirectMessageContext from "./TaskContext/DirectMessageContext";

import styles from "./index.module.scss";
import AssignUser from "./AssignUser";
import Text from "../../../ui/Text";
import useInterval from "../../../../hooks/useInterval";

type PropsType = {
  organizationId: number;
  tasks: Array<Task>;
  taskQueryCount: number;
  task?: Task;
  counts: TaskCounts;
  loading: boolean;
  getTask: (
    taskId: number,
    onSuccess?: (task: Task) => void,
    options?: { silent: boolean }
  ) => void;
  fetchTasks: (
    filter: TaskFindParameters,
    options?: { silent: boolean },
    onSuccess?: () => void
  ) => void;
  fetchTaskCounts: () => void;
  assignTasks: (
    data: AssignTasksData,
    onSuccess: () => void,
    options?: { silent: boolean }
  ) => void;
  staff: Array<Staff>;
  practitioners: Array<Practitioner>;
  locations: Array<Location>;
  reasons: Array<Reason>;
  requestBookingLoading?: boolean;
};

type TaskRowItem = {
  id: number;
  openStatus: React.ReactElement;
  selected: React.ReactElement;
  type: React.ReactElement | undefined;
  patient: React.ReactElement;
  assignedTo?: string | React.ReactElement;
  context: React.ReactElement | string;
  detailsView: React.ReactElement;
  createdAt: React.ReactElement;
};

const DEFAULT_TASKS_PER_PAGE = 25;
const POLL_INTERVAL_MS =
  parseInt(process.env.REACT_APP_APPOINTMENTS_POLLING_INTERVAL || "", 10) || 60 * 1000;

const InboxTable = ({
  organizationId,
  tasks,
  taskQueryCount,
  task,
  counts,
  loading,
  getTask,
  fetchTasks,
  fetchTaskCounts,
  assignTasks,
  staff,
  practitioners,
  locations,
  reasons,
  requestBookingLoading
}: PropsType) => {
  const { parsed } = useQueryString();
  const rowsPerPageLocalStorage = localStorage.getItem("appointmentRequestFilters_rowsPerPage");
  const [searchParams, setSearchParams] = useSearchParams();
  const [availableScreenWidth, setAvailableScreenWidth] = useState(0);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<Task | undefined>(task);
  const [preferQuickView, setPreferQuickView] = useState<boolean>(true);
  const [loadingForAssignUser, setLoadingForAssignUser] = useState<{ [id: number]: boolean }>({});
  const [waitingForUpdate, setWaitingForUpdate] = useState<boolean>(false);
  const [needRefetch, setNeedRefetch] = useState<boolean>(false);

  const initialFilterOptions: TaskFilterOptions =
    Object.keys(parsed).length > 0
      ? {
          ...(parsed as TaskFilterOptions),
          mode: parsed.mode || InboxModes.TODO,
          requestBookingMode: (parsed.requestBookingMode &&
            parsed.requestBookingMode?.split(",")) || [ALL],
          locations: (parsed.locations && parsed.locations?.split(",")) || [],
          reasons: (parsed.reasons && parsed.reasons?.split(",")) || [],
          practitionerIds:
            (parsed.practitionerIds && parsed.practitionerIds?.split(",")?.map(Number)) || []
        }
      : {
          mode: InboxModes.TODO,
          taskType: TaskTypes.APPOINTMENT_REQUEST,
          requestBookingMode: [ALL]
        };

  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [filter, setFilter] = useState<TaskFilterOptions>(initialFilterOptions);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
  const [page, setPage] = useState<number>(parsed.page ? Number.parseInt(parsed.page, 10) : 1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    rowsPerPageLocalStorage ? Number.parseInt(rowsPerPageLocalStorage, 10) : DEFAULT_TASKS_PER_PAGE
  );
  const [sortBy, setSortBy] = useState<SortByValue>(
    parsed.sortBy && parsed.sortByDirection
      ? {
          columnName: parsed.sortBy,
          direction: parsed.sortByDirection as SortByDirection
        }
      : { columnName: "openStatus", direction: "asc" }
  );

  const getFindParameters = (): TaskFindParameters => {
    return {
      organizationId,
      mode: filter.mode,
      currentPage: page,
      pageSize: rowsPerPage,
      sortBy: sortBy?.columnName,
      sortByDirection: sortBy?.direction,
      taskType: filter.taskType,
      searchString: filter.searchValue,
      assignee: filter.assignedStaffUserIds,
      locations: filter.locations,
      reasons: filter.reasons,
      providers: filter.practitionerIds,
      requestBookingMode: filter.requestBookingMode?.includes(ALL) ? [] : filter.requestBookingMode
    };
  };

  const refetchTasks = (silent: boolean = false, fetchingForAssignedUser: boolean = false) => {
    const findParams = getFindParameters();
    fetchTasks(findParams, { silent }, () => {
      if (fetchingForAssignedUser) {
        setLoadingForAssignUser({});
      }
    });
  };

  // Fetch tasks on first load
  useEffect(() => {
    const params = getFindParameters();
    fetchTasks(params);
    fetchTaskCounts();

    if (initialFilterOptions.taskId) {
      getTask(initialFilterOptions.taskId, (task?: Task) => {
        setSelectedTask(task);
        setDetailsOpen(true);
      });
    }
  }, []);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
    } else {
      refetchTasks(detailsOpen);
    }
  }, [filter, page, sortBy]);

  // start interval for silent re-fetching of appointments
  if (!(process.env.REACT_APP_SUPPRESS_POLLING === "true")) {
    useInterval(
      () => {
        refetchTasks(true);
      },
      [filter, page, sortBy],
      POLL_INTERVAL_MS
    );
  }

  useEffect(() => {
    setWaitingForUpdate(requestBookingLoading || false);
  }, [requestBookingLoading]);

  useEffect(() => {
    if (!waitingForUpdate && !needRefetch) {
      setNeedRefetch(true);
    }
  }, [waitingForUpdate]);

  useEffect(() => {
    if (needRefetch) {
      refetchTasks(true);
      setNeedRefetch(false);
    }
  }, [needRefetch]);

  const handleTaskSelectChange = (taskId: number, checked: boolean) => {
    setSelectedRows({ ...selectedRows, [taskId]: checked });
  };

  const getTaskTypeIcon = (type: string, context: TaskAppointmentRequestContext) => {
    switch (type) {
      case TaskTypes.APPOINTMENT_REQUEST:
        return (
          <div>
            {context.bookingMode === BookingModes.REQUEST && <Calendar />}
            {context.bookingMode === BookingModes.AVAILABLE_TIMES && <ApptBookWithTime size={24} />}
            {context.bookingMode === BookingModes.AUTO_BOOK && <AutoBook size={24} />}
          </div>
        );
      case TaskTypes.DIRECT_MESSAGE_CONVERSATION:
        return (
          <div>
            <Chat />
          </div>
        );
      default:
        return undefined;
    }
  };

  const onAssign = (taskIds: number[], userId: number | null) => {
    const loadingForAssignUser: { [id: number]: boolean } = {};
    taskIds.map((taskId: number) => {
      loadingForAssignUser[taskId] = true;
    });
    setLoadingForAssignUser(loadingForAssignUser);
    const assignedUserIds = userId ? [userId] : [];
    assignTasks({ taskIds, assignedUserIds }, () => {
      if (taskIds.length === 1 && detailsOpen) {
        // Update the details view with the latest info for the selected task
        getTask(
          taskIds[0],
          (task?: Task) => {
            setSelectedTask(task);
            setLoadingForAssignUser({});
          },
          { silent: true }
        );
      }

      refetchTasks(true, true);
    });
  };

  const clearTaskSelection = () => {
    const newSelection = Object.keys(selectedRows).reduce((acc: Record<string, boolean>, key) => {
      acc[`${key}`] = false;
      return acc;
    }, {});
    setSelectedRows(newSelection);

    setAllSelected(false);
  };

  const getTaskAssignee = (task: Task) => {
    const taskIsLoadingForAssignedUser = Object.keys(loadingForAssignUser).find(
      (taskId: string) => {
        if (task?.id === Number(taskId)) {
          return loadingForAssignUser[Number(taskId)];
        }
      }
    );

    if (task.assignedUsers && task.assignedUsers.length > 0) {
      return (
        <div
          className={cx({
            [styles.AssigneeLoading]: !!taskIsLoadingForAssignedUser
          })}
        >
          {task.assignedUsers?.map((user) => `${user.firstName} ${user.lastName}`).join(",")}
        </div>
      );
    }

    const currentAssignedStaff = staff?.find((staffUser) => {
      if (task.assignedUsers?.length) {
        return staffUser.userId === task.assignedUsers[0].id;
      }
      return false;
    });

    return (
      <StopClickPropagation>
        <AssignUser
          currentAssignedStaff={currentAssignedStaff}
          value={currentAssignedStaff?.userId || null}
          onChange={(selectedUserId) => onAssign([task.id], selectedUserId)}
          loading={!!taskIsLoadingForAssignedUser}
        />
      </StopClickPropagation>
    );
  };

  const taskRows: Array<TaskRowItem> =
    tasks?.map((task) => {
      let contextComponent;
      switch (task.type) {
        case TaskTypes.APPOINTMENT_REQUEST:
          contextComponent = (
            <AppointmentRequestContext
              appointmentRequestId={task.appointmentRequestId}
              context={task.filterContext as TaskAppointmentRequestContext}
              refetchTasks={refetchTasks}
            />
          );
          break;
        case TaskTypes.DIRECT_MESSAGE_CONVERSATION:
          contextComponent = (
            <DirectMessageContext context={task.filterContext as TaskDirectMessageContext} />
          );
          break;

        default:
          contextComponent = JSON.stringify(task.filterContext);
          break;
      }
      return {
        __onRowClick: () => {
          setPreferQuickView(true);
          setSelectedTask(task);
          setDetailsOpen(true);
        },
        id: task.id,
        openStatus: (
          <Status
            value={task.openStatus}
            options={[
              {
                label: "Closed",
                value: TaskOpenStates.CLOSED
              },
              {
                label: "Open",
                value: TaskOpenStates.OPEN
              },
              {
                label: "Pending",
                value: TaskOpenStates.PENDING
              }
            ]}
            configMap={{
              [TaskOpenStates.CLOSED]: "green",
              [TaskOpenStates.OPEN]: "blue",
              [TaskOpenStates.PENDING]: "gray",
              default: "gray"
            }}
            defaultColor="gray"
          />
        ),
        selected: (
          <StopClickPropagation>
            <CheckboxInputBase
              fieldName={`selected-${task.id}`}
              initialValue={selectedRows[task.id]}
              onChange={(checked) => {
                handleTaskSelectChange(task.id, checked);
              }}
            />
          </StopClickPropagation>
        ),
        type: getTaskTypeIcon(task.type, task.filterContext),
        patient: (
          <InboxPatient
            patientFullName={task.filterContext?.patientFullName}
            patientNumber={task.filterContext?.patientNumber}
            patientVerified={task.filterContext?.patientVerified}
          />
        ),
        assignedTo: getTaskAssignee(task),
        context: contextComponent,
        createdAt: (
          <div>
            <div>{formatDate(moment(task.created_at).toISOString(), "dateOnly")}</div>
            <div>{formatDate(moment(task.created_at).toISOString(), "timeOnly")}</div>
          </div>
        ),
        detailsView: (
          <Button
            inline
            className={styles.DetailsViewButton}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setSelectedTask(task);
              setPreferQuickView(false);
              setDetailsOpen(true);
            }}
          >
            <PipOff />
          </Button>
        )
      };
    }) || [];

  const handleFilterChange = (newFilter: TaskFilterOptions) => {
    if (!isEqual(newFilter, filter)) {
      setPage(1);
      const updatedFilter = { ...filter, ...newFilter };
      setFilter(updatedFilter);

      updateQueryString(
        {
          taskType: updatedFilter.taskType,
          requestBookingMode: updatedFilter?.requestBookingMode?.join(","),
          locations: updatedFilter.locations?.join(","),
          practitionerIds: updatedFilter.practitionerIds?.join(","),
          reasons: updatedFilter?.reasons?.join(",")
        },
        setSearchParams
      );
    }
  };

  const handleSortChange = (sortByValue: SortByValue) => {
    setPage(1);
    setSortBy(sortByValue);
  };

  const handleRowsPerPageChange = (rowsPerPageSelection: number) => {
    setPage(1);
    setRowsPerPage(rowsPerPageSelection);
  };

  const handleSelectAllChange = (selected: boolean) => {
    setPage(1);
    setAllSelected(selected);
    const newSelection = tasks.reduce((acc: Record<string, boolean>, task) => {
      acc[`${task.id}`] = selected;
      return acc;
    }, {});
    setSelectedRows(newSelection);
  };

  const resetSelectAll = () => {
    setPage(1);
    setAllSelected(false);
    setSelectedRows({});
  };

  const onBulkAssign = (selectedUserId: number | null) => {
    const selectedTaskIds = Object.keys(selectedRows).reduce((acc: number[], key) => {
      if (selectedRows[key]) {
        acc.push(parseInt(key, 10));
      }
      return acc;
    }, []);
    onAssign(selectedTaskIds, selectedUserId);
    clearTaskSelection();
  };

  const getSelectedCount = (): number => {
    const selectedCount = Object.keys(selectedRows).reduce((count, key) => {
      return selectedRows[key] ? count + 1 : count;
    }, 0);
    return selectedCount;
  };

  const getHeaderContent = (): React.ReactNode => {
    const selectedCount = getSelectedCount();
    if (allSelected || selectedCount > 0) {
      return (
        <div className={styles.HeaderContent}>
          <span style={{ display: "flex" }}>
            <CheckboxInputBase
              fieldName="selectAllInput"
              onChange={handleSelectAllChange}
              initialValue={allSelected}
            />
            <AssignUser value={null} onChange={onBulkAssign} />
          </span>
          <Text>{selectedCount} tasks selected</Text>
        </div>
      );
    }
    return "";
  };

  const headers = useMemo(() => {
    const headerCols = [
      {
        colName: "selected",
        content: (
          <CheckboxInputBase
            fieldName="selectAllInput"
            initialValue={allSelected}
            onChange={handleSelectAllChange}
          />
        )
      },
      { colName: "type", content: "" },
      { colName: "patient", content: "" },
      { colName: "assignedTo", content: "Assignee", sortable: true },
      { colName: "openStatus", content: "Status", sortable: true },
      { colName: "context", content: "" },
      { colName: "createdAt", content: "Created", sortable: true }
    ];
    if (availableScreenWidth > 1300) {
      headerCols.push({ colName: "detailsView", content: "" });
    }
    return headerCols;
  }, [availableScreenWidth, tasks]);

  useEffect(() => {
    const resizeEventListener = (event: Event) => {
      const currentViewportWidthPx = (event.target as VisualViewport).width;
      setAvailableScreenWidth(currentViewportWidthPx);
    };

    if (visualViewport) {
      visualViewport.addEventListener("resize", resizeEventListener);
    }

    return () => {
      if (visualViewport) {
        visualViewport.removeEventListener("resize", resizeEventListener);
      }
    };
  }, []);

  return (
    <>
      <InboxMode
        counts={counts}
        filter={filter}
        handleFilterChange={handleFilterChange}
        resetSelectAll={resetSelectAll}
      />
      <InboxFilters
        filter={filter}
        handleFilterChange={handleFilterChange}
        staff={staff}
        practitioners={practitioners}
        locations={locations}
        reasons={reasons}
      />
      {loading ? (
        <Loader screen />
      ) : (
        <InboxTaskGrid
          id="inboxTable"
          headers={headers}
          rows={taskRows}
          showRowFocus
          onSortChange={handleSortChange}
          headerContent={getHeaderContent()}
          page={page}
          sortBy={sortBy}
          onPageChange={setPage}
          maxPageRows={rowsPerPage}
          rowsPerPageOptions={[25, 50, 100]}
          onRowsPerPageChange={handleRowsPerPageChange}
          totalPages={Math.ceil(taskQueryCount / rowsPerPage)}
        />
      )}
      <InboxDetailsSwitch
        selectedTask={selectedTask}
        setSelectedTask={setSelectedTask}
        detailsOpen={detailsOpen}
        closeDrawer={() => {
          setDetailsOpen(false);
        }}
        preferQuickView={preferQuickView}
        setPreferQuickView={setPreferQuickView}
        onAssign={onAssign}
        loadingForAssignUser={loadingForAssignUser}
        refetchTasks={refetchTasks}
      />
    </>
  );
};

const mapStateToProps = ({ tasks, organizationData, appointmentRequestsInfo }: ReduxStateType) => {
  return {
    organizationId: organizationData?.organizationData?.id
      ? organizationData?.organizationData.id
      : 0,
    tasks: tasks.data,
    taskQueryCount: tasks.taskQueryCount,
    task: tasks.task,
    counts: tasks.counts,
    loading: tasks.tasksLoading,
    staff: organizationData?.organizationData?.staff,
    practitioners: organizationData?.organizationData?.practitioners,
    locations: organizationData?.organizationData?.locations,
    reasons: organizationData?.organizationData?.reasons,
    requestBookingLoading: appointmentRequestsInfo.bookedLoading
  };
};

export default connect(mapStateToProps, {
  getTask: getTaskAction,
  fetchTasks: fetchTaskAction,
  fetchTaskCounts: fetchTaskCountsAction,
  assignTasks: assignTasksAction
})(InboxTable);
