import React, { useState } from "react";
import { connect } from "react-redux";
import { ReduxStateType, Service } from "../../../../types";

import { FloatModal } from "../../../ui/Modal";
import Heading from "../../../ui/Heading";
import { CheckboxInput, Form, TextInput } from "../../../ui/Input";
import Button from "../../../ui/Button";

import {
  closeModal as closeModalAction,
  updateService as updateServiceAction,
  UpdateServiceData
} from "../../../../actions";
import { isPhone } from "../../../../utils/validators";

import styles from "./index.module.scss";

type PropsType = {
  organizationId: number;
  service: Service;
  closeModal: () => void;
  updateService: (data: UpdateServiceData, numberToAdd: string) => void;
};

type FormState = {
  numberToAdd: string;
  confirmationCheck: boolean;
};

const initialFormState = {
  numberToAdd: "",
  confirmationCheck: false
};

const phoneNumberValidator = isPhone("Please enter a valid phone number");

const EditService = ({
  organizationId,
  service,
  closeModal,
  updateService
}: PropsType): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState("");

  const save = async (formValues: FormState) => {
    const data: UpdateServiceData = { organizationId, serviceId: service.id };
    const { numberToAdd, confirmationCheck } = formValues;

    if (!confirmationCheck && numberToAdd) {
      setErrorMessage("Please click on checkbox to confirm that the number is valid");
    } else if (confirmationCheck && !numberToAdd) {
      setErrorMessage("Please enter a phone number");
    } else if (!confirmationCheck && !numberToAdd) {
      setErrorMessage("Please enter a phone number and confirm that the number is valid");
    } else {
      updateService(data, formValues.numberToAdd);
      closeModal();
    }
  };

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L" className={styles.Heading}>
        Edit Service
      </Heading>
      <Form
        onSubmit={(formState) => save(formState.values as FormState)}
        initialValues={initialFormState}
      >
        <TextInput
          fieldName="numberToAdd"
          label="Toll Free Number"
          placeholder="Add Toll Free Number"
          validate={phoneNumberValidator}
        />

        <CheckboxInput
          fieldName="confirmationCheck"
          label={<span>I confirm the above toll free number is correct</span>}
        />

        {errorMessage !== "" && <div className={styles.ErrorMessage}>{errorMessage}</div>}
        <hr className={styles.Divider} />

        <div className={styles.ButtonGroup}>
          <Button inline onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit">Add</Button>
        </div>
      </Form>
    </FloatModal>
  );
};

const mapStateToProps = ({ services }: ReduxStateType) => {
  return {
    loading: services.servicesUpdateLoading
  };
};

export default connect(mapStateToProps, {
  closeModal: closeModalAction,
  updateService: updateServiceAction
})(EditService);
