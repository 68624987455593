import React, { useState } from "react";

import Button from "../../ui/Button";
import Text from "../../ui/Text";

import styles from "./index.module.scss";

import SimulateJourneyModal from "../Modals/SimulateJourneyModal";

const PreviewJourneyButton = () => {
  const [openModal, setOpenModal] = useState(false);

  const closeJourneyModal = (): void => {
    setOpenModal(false);
  };

  return (
    <>
      <Button
        className={styles.JourneyButton}
        onClick={() => {
          setOpenModal(true);
        }}
        inline
      >
        <Text className={styles.JourneyButtonText}>Preview Journey</Text>
      </Button>
      <SimulateJourneyModal isModalOpen={openModal} closeModal={closeJourneyModal} />
    </>
  );
};

export default PreviewJourneyButton;
