import React from "react";

type PropsType = {
  size?: number;
  color?: string;
};

const Bold = ({ size = 24, color = "currentColor" }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M17.92,13.65A5,5,0,0,0,14.56,5H9.5a2,2,0,0,0-2,2V9.28h0V21a2,2,0,0,0,2,2h6a5,5,0,0,0,2.42-9.35ZM10.5,8h4.06a2,2,0,0,1,0,4H10.5Zm5,12h-5V16h5a2,2,0,0,1,0,4Z"
        fill={color}
      />
    </svg>
  );
};

export default Bold;
