import React from "react";
import { connect } from "react-redux";

import Heading from "../../../ui/Heading";
import { FullScreenModal } from "../../../ui/Modal";
import AppointmentRequestsConfig from "../../../common/AppointmentRequestsConfig";

import { closeModal as closeModalAction } from "../../../../actions";

type PropsType = {
  closeModal: () => void;
};

const AppointmentRequestsConfigModal = ({ closeModal }: PropsType) => (
  <FullScreenModal isOpen onClose={closeModal}>
    <Heading size="L" component="h1">
      Appointment Requests Configuration
    </Heading>
    <AppointmentRequestsConfig />
  </FullScreenModal>
);

export default connect(undefined, {
  closeModal: closeModalAction
})(AppointmentRequestsConfigModal);
