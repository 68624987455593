export const PLACEHOLDER_OPERATOR = {
  label: "__unknown_operator__",
  value: "__unknown_operator__"
};
export const INITIAL_CONDITION_VALUE: ConditionJSON = { [PLACEHOLDER_OPERATOR.value]: [] };
export type ConditionValue = (
  | ConditionJSON
  | ConditionValue
  | { var: string }
  | string
  | string[]
)[];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ComparatorValue = any;

export type ConditionJSON = { [conditionType: string]: ConditionValue };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UpdateConditionValue = any;

export type UpdateCondition = (
  conditionPath: string,
  updateType:
    | "initialize"
    | "clear"
    | "operator"
    | "variable"
    | "comparator"
    | "addCompositionOperator"
    | "updateCompositionOperator",
  value?: UpdateConditionValue
) => void;

export enum ContextVariableType {
  STRING = "string",
  ARRAY = "array",
  BOOLEAN = "boolean"
}
export enum ComparatorFieldType {
  STRING = "string",
  ARRAY = "array"
}

export type ContextVariableOption = { label: string; value: string | number | boolean };

export type ContextVariable = {
  inputName: string;
  options: ContextVariableOption[];
  type: ContextVariableType;
  isExtendable: boolean;
  label?: string;
};

export type ConditionOperatorConfig = {
  label: string;
  jsonLogicKey: string;
  validContextTypes: ContextVariableType[];
  comparatorFieldType: ComparatorFieldType | null;
};

export type CompositionOperatorConfig = {
  label: string;
  jsonLogicKey: string;
};

export type ElementInputValue = string | string[];
