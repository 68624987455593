import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import Button from "../../ui/Button";
import Heading from "../../ui/Heading";
import { Info } from "../../ui/Icon";
import Loader from "../../ui/Loader";
import Text from "../../ui/Text";
import TableGrid, { StopClickPropagation } from "../../ui/TableGrid";
import Tooltip from "../../ui/Tooltip";
import Search from "../../ui/Search";
import Status from "../../ui/Status";

import { fetchDataForMikataAdmin as fetchDataForMikataAdminAction } from "../../../actions";

import useSearchFilter from "../../../hooks/useSearchFilter";

import { OrganizationTypes } from "../../../constants";
import { StatusComponentConfigMap, ReduxStateType, AdminOrgData } from "../../../types";

import styles from "./index.module.scss";

type PropsType = {
  organizations: AdminOrgData[];
  fetchDataForMikataAdmin: () => void;
};

const headers = [
  { colName: "id", content: "Id" },
  { colName: "displayName", content: "Name" },
  { colName: "groupName", content: "Group" },
  { colName: "emrCategory", content: "EMR Category" },
  { colName: "emrIntegrationType", content: "Integration Type" },
  { colName: "timezone", content: "Timezone" },
  { colName: "status", content: "Status" },
  { colName: "verification", content: "Verification" }
];

const organizationStatusOptions = [
  { label: "Activated", value: "true" },
  { label: "Test Mode", value: "testMode" },
  { label: "Deactivated", value: "false" }
];

const organizationStatusConfigMap: StatusComponentConfigMap = {
  true: "green",
  testMode: "yellow",
  false: "red"
};

const OrganizationsPage = ({ organizations, fetchDataForMikataAdmin }: PropsType) => {
  const navigate = useNavigate();
  const { filteredRows, onSearchChange, onSearchClear } = useSearchFilter<AdminOrgData>(
    organizations,
    (search: string, organization: AdminOrgData) => {
      return !!(
        (organization.fullName &&
          organization.fullName.toLowerCase().includes(search.toLowerCase())) ||
        (organization.groupName &&
          organization.groupName.toLowerCase().includes(search.toLowerCase())) ||
        (organization.id && organization.id.toString().includes(search))
      );
    }
  );

  useEffect(() => {
    fetchDataForMikataAdmin();
  }, []);

  const rowData = filteredRows.map((organization) => {
    const organizationToPath = `/mikata-admin-dashboard/organizations/${organization.id}/account`;
    return {
      __onRowClick: () => {
        navigate(organizationToPath, { replace: true });
      },
      id: organization.id,
      displayName: (
        <StopClickPropagation>
          <Link to={organizationToPath}>
            <Button inline type="button" id={`organization-${organization.id}`}>
              <Text bold>{organization.fullName}</Text>
            </Button>
          </Link>
        </StopClickPropagation>
      ),
      groupName:
        organization.type === OrganizationTypes.PARENT
          ? `${organization.fullName} (Parent)`
          : organization.groupName,
      emrIntegrationType: organization.emrIntegrationType,
      emrCategory: organization.emrCategory,
      timezone: organization.timezone,
      status: (
        <Status
          value={organization.inTestMode ? "testMode" : organization.active.toString()}
          options={organizationStatusOptions}
          configMap={organizationStatusConfigMap}
        />
      ),
      verification: !organization.verified && (
        <Tooltip
          icon={
            <div className={styles.Unverified}>
              <Info size={15} />
            </div>
          }
        >
          Account not verified
        </Tooltip>
      )
    };
  });

  return (
    <>
      <div className={styles.Header}>
        <Heading>Manage Organizations</Heading>
        <Link id="addNewOrganization" to="/mikata-admin-dashboard/organizations/create">
          <Button>Add Organization</Button>
        </Link>
      </div>
      {organizations.length === 0 ? (
        <Loader screen />
      ) : (
        <TableGrid
          headers={headers}
          rows={rowData}
          showRowFocus
          headerContent={
            <div className={styles.TableHeader}>
              <Search
                id="organizationsSearch"
                classNames={styles.Search}
                onChange={onSearchChange}
                onClear={onSearchClear}
              />
            </div>
          }
          maxPageRows={20}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ adminData }: ReduxStateType) => {
  return {
    organizations: adminData?.adminData?.data || []
  };
};

export default connect(mapStateToProps, {
  fetchDataForMikataAdmin: fetchDataForMikataAdminAction
})(OrganizationsPage);
