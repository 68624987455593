import React, { useRef } from "react";
import Modal from "react-modal";
import cx from "classnames";

import Card from "../../Card";
import Button from "../../Button";
import { Close } from "../../Icon";

import { useFocusTrap } from "../useFocusTrap";

import styles from "./index.module.scss";

type PropsType = {
  onClose: () => void;
  children: React.ReactNode;
  containerClass?: string;
  isWideModal?: boolean;
  isOpen: boolean;
  title?: string;
};

const FloatModal = ({
  onClose,
  children,
  containerClass = "",
  isOpen,
  title,
  isWideModal = false
}: PropsType): React.ReactElement => {
  const modalRef = useRef<HTMLDivElement>();
  useFocusTrap({ modalRef, isOpen, onClose });
  Modal.setAppElement("#root");

  return (
    <Modal
      isOpen={isOpen}
      portalClassName={styles.Portal}
      className={cx(styles.Modal, { [styles.WideModal]: isWideModal })}
      overlayClassName={styles.Overlay}
      contentLabel={title}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      shouldFocusAfterRender
      shouldCloseOnEsc
      shouldReturnFocusAfterClose
      contentRef={(ref): void => {
        modalRef.current = ref;
      }}
    >
      <Card
        className={cx(
          styles.MainContent,
          { [styles.WideCardContent]: isWideModal },
          containerClass
        )}
      >
        <Button id="close" inline className={styles.CloseButton} onClick={onClose}>
          <Close />
        </Button>
        {children}
      </Card>
    </Modal>
  );
};

export default FloatModal;
