import { InputValue } from "../../types";

export const isSmsFriendly = (message?: string) => (value: InputValue) => {
  if (!value) return undefined;

  const regexPattern =
    /[^A-Za-z0-9 [:space:\]\n/.,_!"#%&''*+:;<=>^~|?¡¿@$£¥¤èéùìòÇØøÆæßÉÅåÄÖÑÜ§äöñüàΔΦΞΓΩΠΨΣΘΛ)(}{-]/gim;

  const invalidCharacters = value?.match(regexPattern);

  return invalidCharacters && invalidCharacters.length > 0
    ? `${message ? `${message}. ` : ""}There are non-standard characters: ${invalidCharacters
        .slice(0, 3)
        .join(", ")}`
    : undefined;
};
