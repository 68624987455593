import React, { useMemo, useState, useEffect } from "react";
import cx from "classnames";
import intersection from "lodash/intersection";

import Text from "../../../Text";
import { Square, SquareCheck } from "../../../Icon";

import { InputPropsType, Option } from "../../../../../types";

import styles from "./index.module.scss";

type PropsType = Omit<InputPropsType, "customOnChange"> & {
  searchSize?: "S" | "M" | "L";
  options: Array<Option>;
  showCheckAll?: boolean;
  narrowList?: boolean;
  wideList?: boolean;
  searchValue?: string;
  isValueTypeNumber?: boolean;
  initialValue?: (string | number)[];
  filteredRows?: Option[];
  onChange: (updatedValues: (string | number)[]) => void;
};

const HighlightSearchLabel = ({ search, label }: { search: string; label: string }) => {
  const structuredLabel: Array<{ str: string; heighLight: boolean }> = [];

  const searchLowercase = search.toLowerCase();
  const searchLength = search.length;
  let remainingString = label;

  while (remainingString) {
    const matchIndex = remainingString.toLocaleLowerCase().indexOf(searchLowercase);

    if (matchIndex === -1) {
      if (remainingString) {
        structuredLabel.push({
          str: remainingString,
          heighLight: false
        });
      }
      remainingString = "";
    } else {
      const beforeMatchStr = remainingString.slice(0, matchIndex);
      const matchString = remainingString.slice(matchIndex, matchIndex + searchLength);
      const afterMatchStr = remainingString.slice(matchIndex + searchLength);

      if (beforeMatchStr) {
        structuredLabel.push({
          str: beforeMatchStr,
          heighLight: false
        });
      }

      if (matchString) {
        structuredLabel.push({
          str: matchString,
          heighLight: true
        });
      }

      remainingString = afterMatchStr;
    }
  }

  return (
    <>
      {structuredLabel.map((labelSlice, idx) => {
        return labelSlice.heighLight ? (
          // eslint-disable-next-line react/no-array-index-key
          <b key={`${labelSlice.str}-${idx}`}>{labelSlice.str}</b>
        ) : (
          <span key={`${labelSlice.str}-${idx}`}>{labelSlice.str}</span>
        );
      })}
    </>
  );
};

const CheckboxGroupBase = ({
  fieldName,
  label,
  searchSize = "S",
  options = [],
  showCheckAll = false,
  narrowList = false,
  disabled = false,
  wideList = false,
  initialValue = [],
  searchValue,
  filteredRows,
  isValueTypeNumber = false,
  onChange,
  ...props
}: PropsType): React.ReactElement => {
  const [showOnlySelected, setShowOnlySelected] = useState(false);
  const [value, setValue] = useState<Array<string | number>>(initialValue);

  useEffect(() => {
    if (
      typeof initialValue === "object" &&
      JSON.stringify(initialValue) !== JSON.stringify(value)
    ) {
      setValue(initialValue);
    } else if (typeof initialValue === "string" && initialValue !== value) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const visibleOptions = filteredRows?.filter((option: { value: string | number }) => {
    return !showOnlySelected ? true : value.includes(option.value);
  });

  const allSelected = useMemo(() => {
    const optionValues = options.map((option) => option.value);
    const inter = intersection(value, optionValues);
    return inter.length === options.length;
  }, [value, options]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const changeValue = (isValueTypeNumber ? Number(event.target.value) : event.target.value) || "";
    if (value.includes(changeValue)) {
      const newValue = value.filter((val: string | number) => val !== changeValue);
      setValue(newValue);
      onChange(newValue);
    } else {
      const newValue = [...value, changeValue];
      setValue(newValue);
      onChange(newValue);
    }
  };

  const onKeypressHandler = (
    event: React.KeyboardEvent<HTMLInputElement>,
    focusValue: Option["value"]
  ): void => {
    // Toggle selection on "enter" keypress
    if (event.nativeEvent.keyCode === 13) {
      const changeValue = focusValue;
      if (value.includes(changeValue)) {
        const newValue = value.filter((val: string | number) => val !== changeValue);
        setValue(newValue);
        onChange(newValue);
      } else {
        const newValue = [...value, changeValue];
        setValue(newValue);
        onChange(newValue);
      }
    }
  };

  const toggleAll = () => {
    if (!allSelected) {
      const optionValues = options.map((option) => option.value);
      setValue(optionValues);
      onChange(optionValues);
    } else {
      setShowOnlySelected(false);
      setValue([]);
      onChange([]);
    }
  };

  const toggleShowSelected = () => {
    setShowOnlySelected((currentValue) => !currentValue);
  };

  let listStyles = styles.List;
  if (narrowList) {
    listStyles = styles.NarrowList;
  } else if (wideList) {
    listStyles = styles.WideList;
  }

  return (
    <fieldset className={styles.Fieldset} id={fieldName} disabled={disabled}>
      {showCheckAll && (
        <div className={styles.CheckAllRow}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label
            id={`${fieldName}-checkAll`}
            key={`${fieldName}-checkAll`}
            className={cx(styles.CheckboxOption, styles.CheckAll)}
          >
            <input
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              id={fieldName}
              className={styles.CheckboxOptionInput}
              type="checkbox"
              aria-label={label}
              name={fieldName}
              checked={allSelected}
              onChange={toggleAll}
              onKeyPress={(e) => {
                if (e.nativeEvent.keyCode === 13) {
                  toggleAll();
                }
              }}
              disabled={disabled}
            />
            <div
              className={cx(styles.Icon, styles.SelectAllIcon, {
                [styles.IconChecked]: allSelected,
                [styles.IconDisabled]: disabled,
                [styles.SelectAllIconDisabled]: disabled
              })}
            >
              {allSelected ? <SquareCheck /> : <Square />}
            </div>
            <Text
              className={cx(styles.Label, {
                [styles.LabelChecked]: allSelected,
                [styles.LabelDisabled]: disabled
              })}
            >
              {`Select all (${options.length})`}
            </Text>
          </label>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label id={`${fieldName}-label`} key={fieldName} onClick={toggleShowSelected}>
            <Text className={styles.ShowSelectedLabel}>
              {showOnlySelected ? "Show all" : `Show selected (${value?.length || 0})`}
            </Text>
          </label>
        </div>
      )}
      <div
        className={cx(styles.Container, {
          [styles.ContainerDisabled]: disabled,
          [styles.ContainerSmall]: searchSize === "S",
          [styles.ContainerMedium]: searchSize === "M",
          [styles.ContainerLarge]: searchSize === "L"
        })}
      >
        <div className={listStyles}>
          {visibleOptions?.map((option) => {
            const isChecked = value?.includes(option.value);
            const optionId = `${fieldName}-${option.label}`;

            return (
              // eslint-disable-next-line jsx-a11y/label-has-associated-control
              <label
                id={optionId}
                key={`${fieldName}-${option.value}`}
                className={styles.CheckboxOption}
              >
                <input
                  id={`${fieldName}-${option.value}`}
                  className={styles.CheckboxOptionInput}
                  type="checkbox"
                  aria-label={label}
                  name={fieldName}
                  value={option.value}
                  checked={isChecked}
                  onChange={onChangeHandler}
                  onKeyPress={(e) => onKeypressHandler(e, option.value)}
                  disabled={disabled}
                />
                <div
                  id="checkbox"
                  className={cx(styles.Icon, {
                    [styles.IconChecked]: isChecked,
                    [styles.IconDisabled]: disabled
                  })}
                >
                  {isChecked ? <SquareCheck /> : <Square />}
                </div>
                <Text
                  className={cx(styles.Label, {
                    [styles.LabelChecked]: isChecked,
                    [styles.IconDisabled]: disabled
                  })}
                >
                  {!searchValue ? (
                    option.label
                  ) : (
                    <HighlightSearchLabel search={searchValue} label={option.label} />
                  )}
                </Text>
              </label>
            );
          })}
        </div>
      </div>
    </fieldset>
  );
};

export default CheckboxGroupBase;
