import React from "react";

import { useFormApi } from "informed";
import Button from "../../../ui/Button";
import { SelectInput } from "../../../ui/Input";

import { Option } from "../../../../types";
import { isRequired } from "../../../../utils/validators";

import styles from "./index.module.scss";

type PropsType = {
  loading?: boolean;
  locationOptions: Option[];
  practitionerOptions: Option[];
  reasonOptions: Option[];
};

const practitionerValidator = isRequired("Please select a practitioner");
const reasonValidator = isRequired("Please select a reason");
const locationValidator = isRequired("Please select a location");

const FormContent = ({
  loading,
  locationOptions,
  practitionerOptions,
  reasonOptions
}: PropsType) => {
  const formApi = useFormApi();
  return (
    <>
      <SelectInput
        fieldName="locationId"
        label="Location"
        options={[{ label: "None", value: "" }, ...locationOptions]}
        placeholder="Select location"
        validate={locationValidator}
      />
      <SelectInput
        fieldName="practitionerId"
        label="Provider"
        options={[{ label: "None", value: "" }, ...practitionerOptions]}
        placeholder="Select provider"
        validate={practitionerValidator}
      />
      <SelectInput
        fieldName="reasonId"
        label="Reason"
        options={[{ label: "None", value: "" }, ...reasonOptions]}
        placeholder="Select reason"
        validate={reasonValidator}
      />
      <div className={styles.ButtonGroup}>
        <Button inline onClick={() => formApi.reset()}>
          Clear
        </Button>
        <Button type="submit" disabled={loading}>
          View Journey
        </Button>
      </div>
    </>
  );
};

export default FormContent;
