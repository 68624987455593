import React, { useState, useEffect } from "react";
import Drawer from "../../../../ui/Drawer";
import Heading from "../../../../ui/Heading";
import Status from "../../../../ui/Status";
import CollapsibleJourneyTemplateCard from "../CollapsibleJourneyTemplateCard";

import { AutomationJourneyDetails } from "../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  templates: AutomationJourneyDetails[] | null;
  selectedLocationId?: number;
  selectedPractitionerId?: number;
  selectedReasonId?: number;
  featuresDisabled?: boolean;
  heading: string;
  close: boolean;
};

const JourneyDrawer = ({
  templates,
  selectedLocationId,
  selectedPractitionerId,
  selectedReasonId,
  featuresDisabled,
  heading,
  close
}: PropsType) => {
  const [forceClose, setForceClose] = useState(close);

  useEffect(() => {
    setForceClose(close);
  }, [close]);

  return (
    <Drawer
      headerContent={
        <div className={styles.DrawerHeaderContent}>
          <Heading>{heading}</Heading>
          {featuresDisabled && (
            <Status
              className={styles.DrawerHeaderContentTag}
              value="featureDisabled"
              options={[{ label: "Feature turned off", value: "featureDisabled" }]}
              configMap={{ featureDisabled: "gray" }}
            />
          )}
        </div>
      }
      collapse={forceClose}
      initialOpen
    >
      {templates?.map((template: AutomationJourneyDetails) => {
        return (
          <CollapsibleJourneyTemplateCard
            key={template.template?.id + template.topic}
            card={template}
            selectedLocationId={selectedLocationId}
            selectedPractitionerId={selectedPractitionerId}
            selectedReasonId={selectedReasonId}
          />
        );
      })}
    </Drawer>
  );
};

export default JourneyDrawer;
