import axios from "axios";
import { ROOT_URL } from "../constants";

const getJwt = () => {
  return localStorage.getItem("jwt");
};

export const getSignedUrl = async ({
  bucketName,
  fileExt = "",
  fileNamePrefix = "",
  downloadingFilename = "",
  token = ""
}) => {
  const resp = await fetch(
    `${ROOT_URL}/get-signed-url/${bucketName}?fileExt=${fileExt}&fileNamePrefix=${fileNamePrefix}&downloadingFilename=${downloadingFilename}`,
    {
      method: "GET",
      headers: { Authorization: `${token || getJwt()}`, "Content-Type": "application/json" }
    }
  );
  const payload = await resp.json();
  return payload;
};

export const uploadFiles = async (url, data) => {
  const resp = await axios.put(url, data);
  return resp;
};
