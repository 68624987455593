import React from "react";

import Heading from "../../../Heading";
import Text from "../../../Text";
import Image from "../../../Image";

const StartScribeSessionThreePointFive = (): React.ReactElement => {
  return (
    <div>
      <Heading size="M" component="h3" darkMode>
        Pause Mika while away
      </Heading>
      <Text size="S" darkMode>
        Need a break in the appointment? No worries, you can pause the session at any time. Mika
        will stop listening to the conversation until you&apos;re ready to resume (OR until you
        click resume).
      </Text>
      <br />
      <Text size="S" darkMode>
        Remember! If you want to dictate your voice for the scribe after the appointment, DON&apos;T
        end the session. Use pause when the appointment ends and resume it for dictation.
      </Text>
      <br />
      <Image src="/img/announcements/pauseMika.png" />
    </div>
  );
};

export default StartScribeSessionThreePointFive;
