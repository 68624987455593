import React from "react";

type PropsType = {
  size?: number;
};
const User = ({ size = 24 }: PropsType) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={`${size}px`}
      height={`${size}px`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12a5 5 0 110-10 5 5 0 010 10zm9 9v-2a5 5 0 00-5-5H8a5 5 0 00-5 5v2a1 1 0 102 0v-2a3 3 0 013-3h8a3 3 0 013 3v2a1 1 0 102 0zM15 7a3 3 0 11-6 0 3 3 0 016 0z"
        fill="currentColor"
      />
    </svg>
  );
};

export default User;
