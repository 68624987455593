import React from "react";

type PropsType = {
  size?: number;
  color?: string;
};

const Calendar = ({ size = 24, color = "currentColor" }: PropsType) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={`${size}px`}
      height={`${size}px`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 2V1a1 1 0 112 0v1h2a3 3 0 013 3v14a3 3 0 01-3 3H5a3 3 0 01-3-3V5a3 3 0 013-3h2V1a1 1 0 012 0v1h6zM4 10v9a1 1 0 001 1h14a1 1 0 001-1v-9H4zm16-2H4V5a1 1 0 011-1h2v1a1 1 0 002 0V4h6v1a1 1 0 102 0V4h2a1 1 0 011 1v3z"
        fill={color}
      />
    </svg>
  );
};

export default Calendar;
