import { ChatFlowsNodes } from "../../../../../../../../types";
import { ConditionValue } from "../../../../../../../ui/Input/JsonLogicInput/types";

export enum ChatViewMode {
  MAP_SMALL = "mapSmall",
  MAP_LARGE = "mapLarge",
  LIST = "list"
}

export type MapSizeData = {
  nodeWidth: number;
  nodeHeight: number;
  nodeSpacingX: number;
  nodeSpacingY: number;
};

export type D3Node = {
  id: number;
  inputName: string;
  displayName: string;
  cardType: string;
  payloadContent: ChatFlowsNodes["payloadContent"];
  payloadOptions: ChatFlowsNodes["payloadOptions"];
  subChatFlow?: ChatFlowsNodes["subChatFlow"];
  tagNames: string[];
  processedOrder: number;
  size: number;
  fixed: boolean;
  fx: number;
  x: number;
  fy: number;
  y: number;
};

export type D3Edge = {
  source: number;
  target: number;
  targetPosition: {
    x: number;
    y: number;
  };
  isDefaultConnection: boolean;
  input: string | null;
  jsonLogic: ConditionValue | null;
};
