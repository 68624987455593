import {
  FETCH_USER,
  UPDATE_USER_SETTINGS,
  FETCH_USERS,
  FETCH_ROLES,
  CREATE_USER
} from "../constants";

import {
  UpdateUserAction,
  FetchUserAction,
  FetchUsersAction,
  FetchRolesAction,
  CreateUserAction
} from "../actions";

import { User, ActionStatus, UserRole } from "../types";

export type UsersActions =
  | UpdateUserAction
  | FetchUserAction
  | FetchUsersAction
  | FetchRolesAction
  | CreateUserAction;

export type UsersReduxState = {
  users: Array<User>;
  roles: Array<UserRole>;
  user: User | null;
  loading: boolean;
  updateLoading: boolean;
  usersLoading: boolean;
};

const initialModalsState: UsersReduxState = {
  users: [],
  roles: [],
  user: null,
  loading: false,
  updateLoading: false,
  usersLoading: false
};

export const usersReducer = (state = initialModalsState, action: UsersActions): UsersReduxState => {
  const { type } = action;

  switch (type) {
    case FETCH_USER: {
      const { payload, status } = action as FetchUserAction;

      return {
        ...state,
        user: payload?.user || state.user,
        loading: status === ActionStatus.loading
      };
    }

    case FETCH_USERS: {
      const { payload } = action as FetchUsersAction;

      return {
        ...state,
        users: payload?.users || state.users
      };
    }

    case FETCH_ROLES: {
      const { payload, status } = action as FetchRolesAction;

      return {
        ...state,
        roles: payload?.roles || state.roles,
        usersLoading: status === ActionStatus.loading
      };
    }

    case UPDATE_USER_SETTINGS: {
      const { payload, status } = action as UpdateUserAction;
      return {
        ...state,
        user: payload?.user || state.user,
        updateLoading: status === ActionStatus.loading
      };
    }

    case CREATE_USER: {
      return state;
    }

    default:
      return state;
  }
};
