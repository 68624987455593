import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  fetchInstructions as fetchInstructionsAction,
  fetchReasons as fetchReasonsAction
} from "../../../../../actions";

import Text from "../../../../ui/Text";
import TableGrid from "../../../../ui/TableGrid";
import Status from "../../../../ui/Status";
import InstructionDetails from "./InstructionDetails";

import { updateQueryString, useQueryString } from "../../../../../utils/queryStringHelpers";

import { ReduxStateType, InstructionWithReasons } from "../../../../../types";
import Loader from "../../../../ui/Loader";

import styles from "./index.module.scss";

type PropsType = {
  instructions: Array<InstructionWithReasons>;
  instructionsLoading: boolean;
  reasonsLoading: boolean;
  fetchInstructions: () => void;
  fetchReasons: () => void;
};

const headers = [
  { colName: "id", content: "ID" },
  { colName: "displayName", content: "Name" },
  { colName: "description", content: "Description" },
  { colName: "type", content: "Type" },
  { colName: "groupName", content: "Group" },
  { colName: "filler", content: "Filler" },
  { colName: "reasonsCount", content: "Reason Count" }
];

const InstructionsTable = ({
  instructions,
  instructionsLoading,
  reasonsLoading,
  fetchInstructions,
  fetchReasons
}: PropsType): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { parsed } = useQueryString();
  const instructionModalOpen = Boolean(parsed.instructionId);

  const openModal = (instructionId: number): void => {
    updateQueryString({ instructionId: instructionId.toString() }, setSearchParams);
  };

  const closeModal = (): void => {
    updateQueryString({ instructionId: undefined }, setSearchParams);
  };

  // Fetch reasons on first load
  useEffect(() => {
    fetchInstructions();
    fetchReasons();
  }, [parsed.instructionId]);

  const rowData = instructions.map((instruction) => {
    return {
      ...instruction,
      __onRowClick: () => openModal(instruction.id),
      displayName: <Text bold>{instruction.displayName}</Text>,
      description: <div className={styles.Description}> {instruction.description} </div>,
      type: (
        <Status
          value={instruction.type}
          options={[
            { label: "Combo", value: "combo" },
            { label: "Primary", value: "primary" },
            { label: "Default", value: "default" }
          ]}
          configMap={{
            combo: "green",
            primary: "blue",
            default: "gray"
          }}
        />
      ),
      filler: (
        <Status
          value={instruction.filler ? "filler" : ""}
          options={[{ label: "yes", value: "filler" }]}
          configMap={{
            filler: "yellow"
          }}
        />
      ),
      reasonsCount: instruction.reasonIds.length || 0
    };
  });

  if (reasonsLoading || instructionsLoading) {
    return <Loader screen />;
  }

  return (
    <>
      <TableGrid headers={headers} rows={rowData} showRowFocus />
      <InstructionDetails isModalOpen={instructionModalOpen} closeModal={closeModal} />
    </>
  );
};

const mapStateToProps = ({ reasons, instructions }: ReduxStateType) => {
  return {
    reasons: reasons.data,
    reasonsLoading: !reasons.initialized,
    instructions: instructions.data,
    instructionsLoading: !instructions.initialized
  };
};

export default connect(mapStateToProps, {
  fetchReasons: fetchReasonsAction,
  fetchInstructions: fetchInstructionsAction
})(InstructionsTable);
