import React, { ElementType, ReactNode } from "react";
import cx from "classnames";

import styles from "./index.module.scss";

type PropsType = {
  children: ReactNode;
  component?: ElementType;
  className?: string;
  id?: string;
  size?: "XL" | "L" | "M" | "S" | "META";
  align?: "left" | "center" | "right";
  darkMode?: boolean;
  bold?: boolean;
};

const Heading = ({
  className,
  component = "h3",
  size = "M",
  darkMode = false,
  align = "left",
  bold,
  ...props
}: PropsType): React.ReactElement => {
  const Component = component;

  return (
    <Component
      className={cx(
        styles.Heading,
        {
          [styles.XLarge]: size === "XL",
          [styles.Large]: size === "L",
          [styles.Medium]: size === "M",
          [styles.Small]: size === "S",
          [styles.Meta]: size === "META",
          [styles.Bold]: bold,
          [styles.Left]: align === "left",
          [styles.Center]: align === "center",
          [styles.Right]: align === "right",
          [styles.DarkMode]: darkMode
        },
        className
      )}
      // Enable prop spreading on this generalized element type
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default Heading;
