import React, { useMemo, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";

import { Form, FormGrid, TextInput } from "../../../../../ui/Input";
import Button, { ButtonRow } from "../../../../../ui/Button";
import Text from "../../../../../ui/Text";
import Loader from "../../../../../ui/Loader";
import { OrganizationContext } from "../../../../../providers/OrganizationProvider";

import {
  updateOrganization as updateOrganizationAction,
  updateOrganizationData,
  openModal as OpenModalAction,
  OpenModal,
  closeModal as closeModalAction
} from "../../../../../../actions";
import { getAccuroClientId } from "../../../../../../lib";

import { AccuroURLs, ModalTypes } from "../../../../../../constants";
import { ReduxStateType } from "../../../../../../types";

const ENV: string = process.env.REACT_APP_ENV || "development";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const baseAccuroURL: string = (AccuroURLs as any)[ENV].AUTH;

type FormState = {
  accuroUUID: string;
};

type PropsType = {
  loading?: boolean;
  openModal: OpenModal;
  closeModal: () => void;
  updateOrganization: (organizationId: number, organizationData: updateOrganizationData) => void;
};

const failedFetchMessage = "Failed to fetch account info. Please reload to try again";

const AccuroAuthSetup = ({ loading, openModal, closeModal, updateOrganization }: PropsType) => {
  const organization = useContext(OrganizationContext);
  const [accuroAccountInfo, setAccuroAccountInfo] = useState<{
    clientId: string;
    callbackUri: string;
  } | null>(null);
  const [errorMessage, setErrorMessage] = useState("");

  // Get Accuro Account Info
  useEffect(() => {
    (async () => {
      if (organization?.id) {
        try {
          const accuroClientResponse = await getAccuroClientId();

          if (accuroClientResponse) {
            setErrorMessage("");
            return setAccuroAccountInfo(accuroClientResponse);
          }
          setErrorMessage(failedFetchMessage);
        } catch (error) {
          setErrorMessage(failedFetchMessage);
        }
      }
    })();
  }, [organization?.id]);

  const initialFormState: FormState | null = useMemo(() => {
    if (!organization || loading) return null;
    return { accuroUUID: organization.accuroUUID || "" };
  }, [organization?.id, organization?.accuroUUID, loading]);

  const save = (formValues: FormState) => {
    if (organization) {
      openModal(ModalTypes.CONFIRMATION, {
        title: `Are you sure you want to integrate with ${organization.fullName}?`,
        content:
          "Sensitive data will be pulled from the clinic’s EMR. Please ensure that you are integrating with the right organization.",
        onConfirmation: () => {
          updateOrganization(organization.id, { accuroUUID: formValues.accuroUUID || "" });
          closeModal();
        }
      });
    }
  };

  if (errorMessage) return <Text>{errorMessage}</Text>;

  if (!initialFormState) return <Loader />;

  return (
    <Form
      initialValues={initialFormState}
      key={`accuroAuthSetupForm-${organization?.accuroUUID}`} // needed to reset form on change in accuroUUID
      onSubmit={(formState) => save(formState.values as FormState)}
    >
      <FormGrid>
        <TextInput
          fieldName="accuroUUID"
          label="Accuro UUID"
          info={
            <div>
              <div>36 character ID containing letters, digits and hyphens.</div>
              <br />
              <div>ex: 73983261-54b2-430e-904d-4f78d3b4c97a</div>
            </div>
          }
        />
      </FormGrid>
      <ButtonRow>
        {organization?.accuroUUID && (
          <div>
            {accuroAccountInfo ? (
              <a
                href={`${baseAccuroURL}?response_type=code&client_id=${accuroAccountInfo.clientId}&redirect_uri=${accuroAccountInfo.callbackUri}`}
              >
                <Button id="accuroAuthorize" secondary>
                  Authorize
                </Button>
              </a>
            ) : (
              <Loader small />
            )}
          </div>
        )}

        <Button id="accuroSave" type="submit" disabled={loading}>
          Save
        </Button>
      </ButtonRow>
    </Form>
  );
};

const mapStateToProps = ({ organizationDetails }: ReduxStateType) => {
  return {
    loading: organizationDetails.organizationLoading
  };
};

export default connect(mapStateToProps, {
  closeModal: closeModalAction,
  openModal: OpenModalAction,
  updateOrganization: updateOrganizationAction
})(AccuroAuthSetup);
