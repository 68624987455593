import {
  FETCH_CHATS,
  GET_CHAT_FLOW_DETAILS,
  GET_AVAILABLE_CHAT_FLOWS,
  CLEAR_CHAT_FLOW_DETAILS,
  UPDATE_CHAT_FLOWS_NODE,
  CLONE_CHAT_FLOW,
  PUBLISH_CHAT_FLOW,
  ADD_CHAT_NODE,
  ADD_CHAT_FLOW,
  DELETE_CHAT_NODE
} from "../constants";

import {
  FetchChatsAction,
  GetChatFlowDetailsAction,
  GetAvailableChatFlowsAction,
  ClearChatFlowDetailsAction,
  UpdateChatFlowsNodeAction,
  CloneChatFlowAction,
  PublishChatFlowAction,
  AddChatNodeAction,
  AddChatFlowAction,
  DeleteChatNodeAction
} from "../actions";

import {
  Chat,
  ActionStatus,
  ChatFlowsNodes,
  ChatEdge,
  MessageTemplateConnection,
  ReportTemplate,
  ChatFlowOutput
} from "../types";

export type ChatsReduxState = {
  availableChats: Array<Chat>;
  chats: Array<Chat>;
  chatsLoading: boolean;
  chatDetails: {
    chatFlowId: number | null;
    title: string | null;
    published: boolean | null;
    organizationId: string | null;
    draftChatFlowId: number | null;
    publishedChatFlowId: number | null;
    nodes: Array<ChatFlowsNodes>;
    loading: boolean;
    updateLoading: boolean;
    tags: Array<string>;
    edges: Array<ChatEdge> | Array<MessageTemplateConnection>;
    reportTemplates: Array<ReportTemplate>;
    outputs: Array<ChatFlowOutput>;
    allMessageTemplates: Array<ChatFlowsNodes>;
  };
};

export type ChatsActions =
  | FetchChatsAction
  | GetChatFlowDetailsAction
  | GetAvailableChatFlowsAction
  | ClearChatFlowDetailsAction
  | UpdateChatFlowsNodeAction
  | CloneChatFlowAction
  | PublishChatFlowAction
  | AddChatNodeAction
  | AddChatFlowAction
  | DeleteChatNodeAction;

const initialChatsState: ChatsReduxState = {
  availableChats: [],
  chats: [],
  chatsLoading: false,
  chatDetails: {
    chatFlowId: null,
    title: null,
    published: null,
    organizationId: null,
    draftChatFlowId: null,
    publishedChatFlowId: null,
    nodes: [],
    loading: false,
    updateLoading: false,
    edges: [],
    tags: [],
    reportTemplates: [],
    outputs: [],
    allMessageTemplates: []
  }
};

export const chatsReducer = (state = initialChatsState, action: ChatsActions): ChatsReduxState => {
  const { type } = action;

  switch (type) {
    case FETCH_CHATS: {
      const { payload, status } = action as FetchChatsAction;
      return {
        ...state,
        chats: payload?.chats || state.chats,
        chatsLoading: status === ActionStatus.loading
      };
    }
    case GET_CHAT_FLOW_DETAILS: {
      const { payload, status } = action as GetChatFlowDetailsAction;

      return {
        ...state,
        chatDetails: {
          ...state.chatDetails,
          chatFlowId: payload?.chatFlow.id || state.chatDetails.chatFlowId,
          title: payload?.chatFlow.title || state.chatDetails.title,
          published: payload?.chatFlow.published || state.chatDetails.published,
          organizationId: payload?.chatFlow.organizationId || state.chatDetails.organizationId,
          draftChatFlowId: payload?.chatFlowsNodes?.[0]?.draftChatFlowId
            ? payload.chatFlowsNodes[0].draftChatFlowId
            : state.chatDetails.draftChatFlowId,
          publishedChatFlowId: payload?.chatFlowsNodes?.[0]?.publishedChatFlowId
            ? payload.chatFlowsNodes[0].publishedChatFlowId
            : state.chatDetails.publishedChatFlowId,
          nodes: payload?.chatFlowsNodes || state.chatDetails.nodes,
          edges: payload?.chatEdges || state.chatDetails.edges,
          loading: status === ActionStatus.loading,
          tags: payload?.chatTags || state.chatDetails.tags,
          reportTemplates: payload?.reportTemplates || state.chatDetails.reportTemplates,
          outputs: payload?.outputs || state.chatDetails.outputs,
          allMessageTemplates: payload?.allMessageTemplates || state.chatDetails.allMessageTemplates
        }
      };
    }
    case GET_AVAILABLE_CHAT_FLOWS: {
      const { payload } = action as GetAvailableChatFlowsAction;
      return {
        ...state,
        availableChats: payload?.availableChats || state.availableChats
      };
    }
    case UPDATE_CHAT_FLOWS_NODE: {
      const { status } = action as UpdateChatFlowsNodeAction;
      return {
        ...state,
        chatDetails: {
          ...state.chatDetails,
          loading: status === ActionStatus.loading
        }
      };
    }
    case CLEAR_CHAT_FLOW_DETAILS: {
      return {
        ...state,
        chatDetails: initialChatsState.chatDetails
      };
    }
    case CLONE_CHAT_FLOW: {
      const { payload, status } = action as CloneChatFlowAction;
      return {
        ...state,
        chatDetails: {
          ...state.chatDetails,
          chatFlowId: payload?.clonedChat.id || state.chatDetails.chatFlowId,
          loading: status === ActionStatus.loading
        }
      };
    }
    case PUBLISH_CHAT_FLOW: {
      const { status } = action as PublishChatFlowAction;
      return {
        ...state,
        chatDetails: {
          ...state.chatDetails,
          loading: status === ActionStatus.loading
        }
      };
    }
    case ADD_CHAT_FLOW: {
      const { status } = action as AddChatFlowAction;
      return {
        ...state,
        chatsLoading: status === ActionStatus.loading
      };
    }

    case ADD_CHAT_NODE: {
      const { status } = action as AddChatNodeAction;
      return {
        ...state,
        chatDetails: {
          ...state.chatDetails,
          loading: status === ActionStatus.loading
        }
      };
    }

    case DELETE_CHAT_NODE: {
      const { status } = action as DeleteChatNodeAction;
      return {
        ...state,
        chatDetails: {
          ...state.chatDetails,
          loading: status === ActionStatus.loading
        }
      };
    }

    default:
      return state;
  }
};
