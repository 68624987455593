import React, { Dispatch, useContext } from "react";
import { connect } from "react-redux";

import { CardSection } from "../../../../ui/Card";
import Heading from "../../../../ui/Heading";
import Text from "../../../../ui/Text";
import { ExternalLink, Info } from "../../../../ui/Icon";
import { CheckboxInput, Form } from "../../../../ui/Input";
import Tooltip from "../../../../ui/Tooltip";
import Button from "../../../../ui/Button";

import {
  BillingContext,
  CreditCardTokenizationResponse
} from "../../../../providers/BillingProvider";

import {
  CreateScribeSubscriptionFormState,
  CreateScribeSubscriptionPlanFormData,
  CreateScribeSubscriptionPlanModalSteps
} from "..";

import { capitalize } from "../../../../../utils/stringTransformations";
import { isChecked } from "../../../../../utils/validators";

import {
  updatePaymentMethod as updatePaymentMethodAction,
  UpdatePaymentMethodData,
  fetchUser as fetchUserAction,
  fetchOrganization as fetchOrganizationAction
} from "../../../../../actions";

import { InputValue, Invoice, ReduxStateType, User } from "../../../../../types";

import styles from "../index.module.scss";
import { OrganizationContext } from "../../../../providers/OrganizationProvider";

type PropsType = {
  formState: CreateScribeSubscriptionFormState;
  setFormState: Dispatch<CreateScribeSubscriptionPlanFormData>;
  user: User | null;
  billingLoading: boolean;
  updatePaymentMethod: (
    organizationId: string,
    data: UpdatePaymentMethodData,
    onError?: () => void
  ) => void;
  proratedInvoice?: Invoice;
  subscriptionId?: number;
  submitStep: (
    stepName: CreateScribeSubscriptionPlanModalSteps,
    formState: CreateScribeSubscriptionPlanFormData
  ) => void;
  fetchOrganization?: (organizationId: string) => void;
};

const renewalValidator = isChecked("Please authorize Mikata Health Inc.");
const termsValidator = isChecked("Please agree to the Terms of Use");

const ConfirmAndPay = ({
  formState,
  setFormState,
  user,
  billingLoading,
  updatePaymentMethod,
  proratedInvoice,
  subscriptionId,
  submitStep,
  fetchOrganization
}: PropsType) => {
  const organization = useContext(OrganizationContext);
  const organizationId = organization?.id;

  const billingProvider = useContext(BillingContext);

  const onChange = (event: { [fieldName: string]: InputValue }) => {
    setFormState({
      type: "confirmAndPay",
      payload: {
        values: {
          acceptedRenewal:
            event["confirmAndPay.values.acceptedRenewal"] ||
            formState.confirmAndPay?.values?.acceptedRenewal,
          acceptedTerms:
            event["confirmAndPay.values.acceptedTerms"] ||
            formState.confirmAndPay?.values?.acceptedTerms
        }
      }
    });
  };

  const onSubmit = async (event: { [fieldName: string]: InputValue }) => {
    let error = false;
    const onErrorCallback = () => {
      error = true;
    };

    const payInvoiceCallback = async (
      code: string,
      message: string,
      response: CreditCardTokenizationResponse
    ) => {
      if (organizationId) {
        await updatePaymentMethod(
          organizationId?.toString(),
          {
            subscriptionId,
            creditCardToken: response?.creditcard?.token,
            invoiceId: proratedInvoice?.id
          },
          onErrorCallback
        );
      }

      setFormState({
        type: "confirmAndPay",
        payload: {
          loading: false,
          ...event.confirmAndPay
        }
      });

      if (error) return;

      if (fetchOrganization && organizationId) {
        fetchOrganization(organizationId.toString());
      }

      submitStep("billingSummary", {
        type: "confirmAndPay",
        payload: {
          ...event.confirmAndPay
        }
      });
    };

    if (formState?.paymentBillingInformation?.values) {
      const { cardNumber, expiryMonth, expiryYear, cardName, cvv } =
        formState.paymentBillingInformation.values;

      setFormState({
        type: "confirmAndPay",
        payload: {
          loading: true,
          ...event.confirmAndPay
        }
      });

      billingProvider?.tokenizeCard(
        {
          organizationId: organizationId as number,
          number: cardNumber as string,
          expiryMonth: expiryMonth as string,
          expiryYear: expiryYear as string,
          name: cardName as string,
          cvv: cvv as string
        },
        payInvoiceCallback
      );
    }
  };

  return (
    <Form
      onSubmit={(formState) => onSubmit(formState.values as { [fieldName: string]: InputValue })}
      initialValues={formState}
    >
      <CardSection title="Plan">
        <Heading size="META">Plan</Heading>
        <Text className={styles.TextMarginBottom}>
          {formState.selectPlan?.values?.selectedPlan?.name}
        </Text>
        <div className={styles.TextMarginBottom}>
          <Heading size="META">Description</Heading>
          {formState.selectPlan?.values?.selectedPlan?.description}
        </div>
        <div className={styles.CardRow}>
          <div>
            <Heading size="META">Rate</Heading>$
            {formState.selectPlan?.values?.selectedPlan?.rate?.toFixed(2)}/provider/
            {formState.selectPlan?.values?.selectedPlan?.payCycleUnit}
          </div>
          <div>
            <Heading size="META">Renew Automatically</Heading>
            {capitalize(formState.selectPlan?.values?.selectedPlan?.payCycleUnit as string)}ly
          </div>
        </div>
      </CardSection>

      <CardSection title="Payment">
        <Heading size="META">Credit Card</Heading>
        <Text className={styles.TextMarginBottom}>
          ****&nbsp;
          {formState.paymentBillingInformation?.values?.cardNumber?.substring(
            formState.paymentBillingInformation?.values?.cardNumber?.length - 4
          )}
        </Text>
        <Heading size="META">Name on Card</Heading>
        <Text>{formState.paymentBillingInformation?.values?.cardName}</Text>
      </CardSection>

      <CardSection title="Billing details">
        <Heading size="META">Name</Heading>
        <Text className={styles.TextMarginBottom}>
          {user?.firstName} {user?.lastName}
        </Text>
        <div className={styles.FlexRow}>
          <Heading size="META" className={styles.ToolTipHeading}>
            Email
          </Heading>
          <Tooltip icon={<Info size={16} />} position="topRight" className={styles.ToolTip}>
            <span>
              Your invoice will be sent to your account email by default.&nbsp;
              <a
                type="button"
                href="https://mikatahealth.com/feedback/"
                target="_blank"
                className={styles.InlineLink}
                rel="noreferrer"
              >
                Contact us <ExternalLink size={12} />
                &nbsp;
              </a>
              to change your billing email address.
            </span>
          </Tooltip>
        </div>
        <Text className={styles.TextMarginBottom}>{user?.email}</Text>
        <Heading size="META">Address</Heading>
        <Text>{formState.paymentBillingInformation?.values?.address}</Text>
        <Text>{formState.paymentBillingInformation?.values?.suite}</Text>
        <Text>{`${formState.paymentBillingInformation?.values?.city}, ${formState.paymentBillingInformation?.values?.provinceState}, ${formState.paymentBillingInformation?.values?.country}`}</Text>
        <Text>{formState.paymentBillingInformation?.values?.postalZipCode}</Text>
      </CardSection>

      <CardSection title="Agreement">
        <CheckboxInput
          id="acceptedRenewal"
          fieldName="confirmAndPay.values.acceptedRenewal"
          label="I authorize Mikata Health Inc. to automatically charge my credit card on the payment due date"
          validate={renewalValidator}
          customOnChange={onChange}
        />
        <div className={styles.FlexStart}>
          <CheckboxInput
            id="acceptedTerms"
            fieldName="confirmAndPay.values.acceptedTerms"
            label={
              <Text>
                I have read and accept the{" "}
                <a
                  type="button"
                  onClick={(event) => event.stopPropagation()}
                  href="https://mikatahealth.com/terms/mikata-terms-of-use"
                  target="_blank"
                  className={styles.InlineLink}
                  rel="noreferrer"
                >
                  Terms of Use
                </a>{" "}
                and{" "}
                <a
                  type="button"
                  onClick={(event) => event.stopPropagation()}
                  href="https://mikatahealth.com/privacy_policy"
                  target="_blank"
                  className={styles.InlineLink}
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                .
                <br />
                (If I am an Alberta-based provider, I have also read and accept the{" "}
                <a
                  type="button"
                  onClick={(event) => event.stopPropagation()}
                  href="https://mikatahealth.com/terms/information-management-agreement-ab"
                  target="_blank"
                  className={styles.InlineLink}
                  rel="noreferrer"
                >
                  Information Management Agreement
                </a>
                )
              </Text>
            }
            validate={termsValidator}
            customOnChange={onChange}
          />
        </div>
      </CardSection>
      <div className={styles.Button}>
        <Button
          type="submit"
          id="confirmAndPay"
          disabled={billingLoading || formState.confirmAndPay?.loading}
        >
          Confirm & Pay
        </Button>
      </div>
    </Form>
  );
};

const mapStateToProps = ({ users, billing }: ReduxStateType) => {
  return {
    user: users.user,
    proratedInvoice: billing.proratedInvoice,
    billingLoading: billing.billingLoading,
    subscriptionId: billing.subscriptionId,
    paymentLoading: billing.paymentLoading
  };
};

export default connect(mapStateToProps, {
  fetchUser: fetchUserAction,
  updatePaymentMethod: updatePaymentMethodAction,
  fetchOrganization: fetchOrganizationAction
})(ConfirmAndPay);
