import React, { useContext } from "react";
import { connect } from "react-redux";

import Dropdown from "../../../../ui/Dropdown";
import { ReduxStateType, Staff } from "../../../../../types";
import { UserContext } from "../../../../providers/UserProvider";

import styles from "./index.module.scss";

type PropsType = {
  currentAssignedStaff?: Staff;
  loading?: boolean;
  value: number | null;
  isQuickView?: boolean;
  staff: Staff[];
  onChange: (userId: number | null) => void;
};

const AssignUser = ({
  currentAssignedStaff,
  loading,
  value,
  staff,
  isQuickView,
  onChange
}: PropsType) => {
  const { userId: currentUserId } = useContext(UserContext);

  const currentStaffUser = currentUserId
    ? staff?.find((staffUser) => {
        return staffUser.userId === currentUserId;
      })
    : null;

  const currentStaffUserOption = currentStaffUser
    ? {
        label: `Me (${currentStaffUser.firstName} ${currentStaffUser.lastName})`,
        value: currentStaffUser.userId.toString()
      }
    : undefined;

  const staffOptions = staff
    ? staff
        .filter((staffUser) => {
          return currentStaffUser
            ? staffUser.userId !== currentStaffUser.userId && !!staffUser.active
            : !!staffUser.active;
        })
        .map((staffUser) => ({
          label: `${staffUser.firstName} ${staffUser.lastName}`,
          value: staffUser.userId.toString()
        }))
        .sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        })
    : [];

  if (currentStaffUserOption) {
    staffOptions.unshift(currentStaffUserOption);
  }

  const currentAssignedStaffOption = staffOptions.find((staffOption) => {
    if (currentAssignedStaff) {
      return staffOption.value === currentAssignedStaff.userId.toString();
    }
    return false;
  });

  return (
    <div
      className={styles.AssigneeDropdown}
      aria-label={currentAssignedStaffOption ? currentAssignedStaffOption.label : "Assign to"}
    >
      <Dropdown
        onChange={(selectedStaffUserId) => {
          const assignToUserId =
            selectedStaffUserId === "None" ? null : parseInt(selectedStaffUserId, 10);
          onChange(assignToUserId);
        }}
        wrappingClassName={isQuickView ? "" : styles.DropdownWrapper}
        value={value?.toString() || "None"}
        options={[{ label: "None", value: "None" }, ...staffOptions]}
        inline
        inlineText={currentAssignedStaffOption ? currentAssignedStaffOption.label : "Assign to"}
        loading={loading}
      />
    </div>
  );
};

const mapStateToProps = ({ organizationData }: ReduxStateType) => {
  return {
    staff: organizationData?.organizationData?.staff
  };
};

export default connect(mapStateToProps, {})(AssignUser);
