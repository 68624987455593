import { ChatFlowTags } from "../constants";
import { AppointmentConversation } from "../types";

const filterDirectMessageConversations = (
  conversations: Array<AppointmentConversation>,
  isDirectMessage: boolean
): Array<AppointmentConversation> => {
  return conversations.filter((conversation) => {
    if (conversation.tagnames) {
      const checkDirectMessageTags =
        conversation.tagnames.includes(ChatFlowTags.BASIC_DIRECT_MESSAGE) ||
        conversation.tagnames.includes(ChatFlowTags.SECURE_DIRECT_MESSAGE);
      return isDirectMessage ? checkDirectMessageTags : !checkDirectMessageTags;
    }
    return false;
  });
};

export default filterDirectMessageConversations;
