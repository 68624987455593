import React from "react";

import Text from "../../../Text";
import Heading from "../../../Heading";
import Image from "../../../Image";

import styles from "./ScribeMultiLanguage.module.scss";
import { ExternalLink } from "../../../Icon";

const ScribeMultiLanguage = (): React.ReactElement => {
  return (
    <>
      <Heading size="M" darkMode>
        Mika works in multiple languages!
      </Heading>
      <Text darkMode size="S">
        Select the spoken language for your appointment when you activate Mika, change the spoken
        language during your appointment, and choose note and letter templates in other languages.
      </Text>
      <br />
      <Text darkMode size="S">
        Check out our{" "}
        <a
          className={styles.ProductGuideLink}
          type="button"
          href="https://mikata-health.gitbook.io/mikata-product-guide-for-customers/mika-scribe/product-guide/mika-scribe-multi-language"
          rel="noreferrer"
          target="_blank"
        >
          <div className={styles.ProductGuideTextAndIcon}>
            product guide
            <div className={styles.ExternalLinkSVG}>
              <ExternalLink size={16} />
            </div>
          </div>
        </a>{" "}
        for tutorials and more information.
      </Text>
      <br />
      <Image
        src="/img/announcements/scribeMultiLanguage.png"
        className={styles.MultiLanguageGraphic}
      />
    </>
  );
};

export default ScribeMultiLanguage;
