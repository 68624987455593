import { OPEN_MODAL, CLOSE_MODAL } from "../constants";

import { ModalProps, ModalType, Dispatch } from "../types";

export type OpenModalAction = {
  type: typeof OPEN_MODAL;
  payload: {
    modalType: ModalType;
    modalProps?: ModalProps;
  };
};

export type OpenModal = (modalType: ModalType, modalProps?: ModalProps) => void;

export const openModal: OpenModal = (
  modalType: ModalType,
  modalProps?: ModalProps
): OpenModalAction => {
  return {
    type: OPEN_MODAL,
    payload: {
      modalType,
      modalProps
    }
  };
};

export type CloseModalAction = {
  type: typeof CLOSE_MODAL;
};

export const closeModal = () => (dispatch: Dispatch) => {
  return dispatch({
    type: CLOSE_MODAL
  });
};
