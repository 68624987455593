import React from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { NavLink, useLocation } from "react-router-dom";

import Button from "../../../ui/Button";
import Heading from "../../../ui/Heading";
import Text from "../../../ui/Text";
import { Plus } from "../../../ui/Icon";

import { PermissionsGuard } from "../../../../hooks/usePermissions";

import { openModal as openModalAction, OpenModal } from "../../../../actions";

import {
  AdminOrganizationDetailsPageTabs,
  AdminModalTypes,
  FEATURES,
  OrganizationTypes
} from "../../../../constants";
import DataDropdown from "./DataDropdown";
import AccountDropdown from "./AccountDropdown";

import styles from "./index.module.scss";
import { Permissions, ReduxStateType } from "../../../../types";

const {
  ANALYTICS,
  APPOINTMENT_JOURNEYS,
  APPOINTMENT_REQUESTS,
  APPOINTMENTS,
  INBOX,
  INSTRUCTIONS,
  MESSAGE_LIBRARY
} = AdminOrganizationDetailsPageTabs;

type PropsType = {
  organizationId: string;
  displayName?: string;
  organizationType: string | null;
  organizationFeatures?: string[];
  verified?: boolean;
  openModal: OpenModal;
};

const OrganizationDetailsNavBar = ({
  organizationId,
  displayName,
  organizationType,
  organizationFeatures,
  verified,
  openModal
}: PropsType) => {
  const isParent = organizationType === OrganizationTypes.PARENT;
  const location = useLocation();
  const headerText = isParent
    ? `Manage Group - ${displayName}`
    : `Manage Organization - ${displayName}`;

  const handleNavClick = (e: React.MouseEvent, requiresVerifiedOrg: boolean) => {
    if (requiresVerifiedOrg && !verified) {
      e.preventDefault();
    }
  };

  return (
    <div className={styles.NavBar}>
      {displayName && (
        <div className={styles.Header} id="header">
          <Heading className={styles.OrganizationName} size="XL" component="h1">
            {headerText}
          </Heading>
          {isParent && (
            <div className={styles.Tabs}>
              <div className={styles.Link}>
                <AccountDropdown
                  organizationId={organizationId}
                  organizationType={organizationType}
                  verified={verified}
                />
              </div>
            </div>
          )}
          {!isParent && (
            <div className={styles.Tabs}>
              <div className={styles.Link}>
                <AccountDropdown
                  organizationId={organizationId}
                  organizationType={organizationType}
                  verified={verified}
                />
              </div>
              <div className={styles.Link}>
                <DataDropdown organizationId={organizationId} verified={verified} />
              </div>
              <NavLink
                key={APPOINTMENTS.label}
                to={APPOINTMENTS.path.replace(":organizationId", organizationId)}
                className={({ isActive }) => (isActive ? styles.LinkActive : styles.Link)}
                onClick={(e) => handleNavClick(e, false)}
              >
                <Text className={styles.LinkText} size="S" component="span" bold>
                  {APPOINTMENTS.label}
                </Text>
              </NavLink>
              {organizationFeatures &&
                organizationFeatures.includes(FEATURES.APPOINTMENT_REQUESTS.value) && (
                  <NavLink
                    id="adminApptReqLink"
                    key={APPOINTMENT_REQUESTS.label}
                    to={APPOINTMENT_REQUESTS.path.replace(":organizationId", organizationId)}
                    className={({ isActive }) =>
                      isActive
                        ? styles.LinkActive
                        : cx(styles.Link, {
                            [styles.LinkDisabled]: !verified
                          })
                    }
                    onClick={(e) => handleNavClick(e, true)}
                  >
                    <Text className={styles.LinkText} size="S" component="span" bold>
                      {APPOINTMENT_REQUESTS.label}
                    </Text>
                  </NavLink>
                )}

              {organizationFeatures &&
                (organizationFeatures.includes(FEATURES.INBOX.value) ||
                  organizationFeatures.includes(FEATURES.APPOINTMENT_REQUESTS.value)) && (
                  <NavLink
                    id="inboxLink"
                    key={INBOX.label}
                    to={INBOX.path.replace(":organizationId", organizationId)}
                    className={({ isActive }) =>
                      isActive
                        ? styles.LinkActive
                        : cx(styles.Link, {
                            [styles.LinkDisabled]: !verified
                          })
                    }
                    onClick={(e) => handleNavClick(e, true)}
                  >
                    <Text className={styles.LinkText} size="S" component="span" bold>
                      {INBOX.label}
                    </Text>
                  </NavLink>
                )}

              <NavLink
                key={APPOINTMENT_JOURNEYS.label}
                to={APPOINTMENT_JOURNEYS.path.replace(":organizationId", organizationId)}
                className={({ isActive }) =>
                  isActive
                    ? styles.LinkActive
                    : cx(styles.Link, {
                        [styles.LinkDisabled]: !verified
                      })
                }
                onClick={(e) => handleNavClick(e, true)}
              >
                <Text className={styles.LinkText} size="S" component="span" bold>
                  {APPOINTMENT_JOURNEYS.label}
                </Text>
              </NavLink>
              <NavLink
                key={MESSAGE_LIBRARY.label}
                to={MESSAGE_LIBRARY.path.replace(":organizationId", organizationId)}
                className={({ isActive }) =>
                  isActive
                    ? styles.LinkActive
                    : cx(styles.Link, {
                        [styles.LinkDisabled]: !verified
                      })
                }
                onClick={(e) => handleNavClick(e, true)}
              >
                <Text className={styles.LinkText} size="S" component="span" bold>
                  {MESSAGE_LIBRARY.label}
                </Text>
              </NavLink>
              {organizationFeatures && organizationFeatures.includes(FEATURES.ANALYTICS.value) && (
                <NavLink
                  key={ANALYTICS.label}
                  to={ANALYTICS.path.replace(":organizationId", organizationId)}
                  className={({ isActive }) =>
                    isActive
                      ? styles.LinkActive
                      : cx(styles.Link, {
                          [styles.LinkDisabled]: !verified
                        })
                  }
                  onClick={(e) => handleNavClick(e, true)}
                >
                  <Text className={styles.LinkText} size="S" component="span" bold>
                    {ANALYTICS.label}
                  </Text>
                </NavLink>
              )}
            </div>
          )}
        </div>
      )}
      {organizationId && displayName && (
        <div className={styles.SwitchContent}>
          {location.pathname ===
            MESSAGE_LIBRARY.path.replace(":organizationId", organizationId) && (
            <PermissionsGuard requiredPermissions={[Permissions.CREATE_MESSAGE_TEMPLATE]}>
              <Button
                id="openAddMessageTemplateModal"
                className={styles.Button}
                onClick={() => openModal(AdminModalTypes.ADD_SMS_MESSAGE_TEMPLATE)}
              >
                <span className={styles.ButtonText}>Add Message</span>
                <Plus />
              </Button>
            </PermissionsGuard>
          )}
          {location.pathname === INSTRUCTIONS.path.replace(":organizationId", organizationId) && (
            <PermissionsGuard requiredPermissions={[Permissions.CREATE_CHECKLISTS]}>
              <Button
                id="openAddMessageTemplateModal"
                className={styles.Button}
                onClick={() => openModal(AdminModalTypes.ADD_INSTRUCTION)}
              >
                <span className={styles.ButtonText}>Add Instruction</span>
                <Plus />
              </Button>
            </PermissionsGuard>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ organizationDetails }: ReduxStateType) => {
  return {
    organizationFeatures: organizationDetails.data?.features,
    verified: organizationDetails.data?.verified
  };
};

export default connect(mapStateToProps, {
  openModal: openModalAction
})(OrganizationDetailsNavBar);
