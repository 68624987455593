import React, { Dispatch, SetStateAction, useMemo } from "react";

import { TextAreaInput, TextInput } from "../../../../../../../../ui/Input";

import BaseChatNodeForm, {
  BaseFormValues,
  PhoneInputFormValues,
  FormValues
} from "../../BaseChatNodeForm";

import { isInteger, isRequired, combine } from "../../../../../../../../../utils/validators";

import { ChatFlowsNodes } from "../../../../../../../../../types";

type PhoneInputPayloadOptions = null | {
  maxDigits?: number;
};

export type PhoneInputChatFlowsNode = ChatFlowsNodes & {
  payloadOptions: PhoneInputPayloadOptions;
};

type PropsType = {
  chatId: string | undefined;
  node: PhoneInputChatFlowsNode;
  viewOnly: boolean;
  setSelectedNodeId: Dispatch<SetStateAction<number | null>>;
};
const maxDigitsValidator = combine([
  isInteger("Please enter an integer"),
  isRequired("Required field")
]);

const onSaveCustomTransformer = (formValues: FormValues) => {
  const values = formValues as BaseFormValues & PhoneInputFormValues;
  return {
    payloadContent: values.payloadContent || "",
    payloadOptions: { maxDigits: values.maxDigits || 0 }
  };
};

const PhoneInputCard = ({ chatId, node, viewOnly, setSelectedNodeId }: PropsType) => {
  const initialFormValues: PhoneInputFormValues = useMemo(() => {
    return {
      payloadContent: node?.payloadContent || "",
      payloadOptions: node?.payloadOptions || null,
      maxDigits: node?.payloadOptions?.maxDigits || 10
    };
  }, [node.id]);

  return (
    <BaseChatNodeForm
      chatId={chatId}
      initialValues={initialFormValues}
      onSaveCustomTransformer={onSaveCustomTransformer}
      node={node}
      key={node.id}
      isInput
      viewOnly={viewOnly}
      setSelectedNodeId={setSelectedNodeId}
    >
      <TextAreaInput
        size="S"
        fieldName="payloadContent"
        label="Mikata Message"
        disabled={viewOnly}
      />
      <TextInput
        type="number"
        fieldName="maxDigits"
        label="Maximum phone digits"
        disabled={viewOnly}
        validate={maxDigitsValidator}
      />
    </BaseChatNodeForm>
  );
};

export default PhoneInputCard;
