import React from "react";

type PropsType = {
  size?: number;
};

const Transcript = ({ size = 24 }: PropsType) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={`${size}px`}
      height={`${size}px`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 6C1 4.34315 2.34315 3 4 3H20C21.6569 3 23 4.34315 23 6V18C23 19.6569 21.6569 21 20 21H4C2.34315 21 1 19.6569 1 18V6ZM4 5C3.44772 5 3 5.44772 3 6V18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18V6C21 5.44772 20.5523 5 20 5H4ZM10 13C10 12.4477 10.4477 12 11 12H18C18.5523 12 19 12.4477 19 13C19 13.5523 18.5523 14 18 14H11C10.4477 14 10 13.5523 10 13ZM6 15C5.44772 15 5 15.4477 5 16C5 16.5523 5.44772 17 6 17H13C13.5523 17 14 16.5523 14 16C14 15.4477 13.5523 15 13 15H6ZM5 13C5 12.4477 5.44772 12 6 12H7C7.55228 12 8 12.4477 8 13C8 13.5523 7.55229 14 7 14H6C5.44772 14 5 13.5523 5 13ZM17 15C16.4477 15 16 15.4477 16 16C16 16.5523 16.4477 17 17 17H18C18.5523 17 19 16.5523 19 16C19 15.4477 18.5523 15 18 15H17Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Transcript;
