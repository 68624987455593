import React from "react";

type PropsType = {
  size?: number;
};

const ExternalLink = ({ size = 24 }: PropsType) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={`${size}px`}
      height={`${size}px`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.833 5.414V9c0 .552.467 1 1.042 1s1.042-.448 1.042-1V3a.977.977 0 00-.315-.716A1.076 1.076 0 0021.875 2h-6.25c-.575 0-1.042.448-1.042 1s.467 1 1.042 1h3.735l-9.68 9.293a.972.972 0 000 1.414c.407.39 1.066.39 1.473 0l9.68-9.293zM17.708 13v6c0 .552-.466 1-1.041 1H5.208c-.575 0-1.041-.448-1.041-1V8c0-.552.466-1 1.041-1h6.25c.576 0 1.042-.448 1.042-1s-.466-1-1.042-1h-6.25C3.482 5 2.083 6.343 2.083 8v11c0 1.657 1.4 3 3.125 3h11.459c1.726 0 3.125-1.343 3.125-3v-6c0-.552-.467-1-1.042-1s-1.042.448-1.042 1z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ExternalLink;
