import React from "react";

import Text from "../../../../../ui/Text";

import { Appointment } from "../../../../../../types";

import formatDate from "../../../../../../utils/formatDate";

import ResponsiveComponentSwitch from "../../../../../ui/Responsive/ResponsiveComponentSwitch";

import styles from "./index.module.scss";

type PropsType = {
  appointment: Appointment;
};

const Start = ({ appointment }: PropsType) => {
  return (
    <>
      {(!appointment.filteredIn || appointment.cancelled || appointment.deleted) && (
        <div className={styles.FilteredOut} />
      )}
      <ResponsiveComponentSwitch
        DefaultComponent={() => (
          <>
            <Text bold align="left" component="span" size="S">
              {formatDate(appointment.start, "timeOnly", appointment.timezone)}
            </Text>
            <Text align="left" component="span" size="XS">
              {formatDate(appointment.start, "dateOnly", appointment.timezone)}
            </Text>
          </>
        )}
        MobileComponent={() => (
          <Text align="left" component="span" size="XS">
            {formatDate(appointment.start, "compactDateTime", appointment.timezone)}
          </Text>
        )}
      />
    </>
  );
};

export default Start;
