import React, { useCallback, useContext, useMemo, useState, useEffect } from "react";
import {
  Announcement,
  AnnouncementName,
  AnnouncementsSetting,
  AnnouncementType
} from "../../../types";

import { UserContext } from "../UserProvider";

import { displayPriorityByAnnouncementName, displayTypeByAnnouncementName } from "./helpers";

export type PropsType = {
  children: React.ReactNode;
};

type AnnouncementsContextType = {
  modalAnnouncements: Announcement[];
  tooltipAnnouncements: Announcement[];
  markAnnouncementComplete?: (announcementName: AnnouncementName) => void;
  registerAnnouncement?: (announcementName: AnnouncementName) => void;
  deregisterAnnouncement?: (announcementName: AnnouncementName) => void;
};

const initialAnnouncementsContext: AnnouncementsContextType = {
  modalAnnouncements: [],
  tooltipAnnouncements: []
};

export const AnnouncementsContext = React.createContext<AnnouncementsContextType>(
  initialAnnouncementsContext
);

const AnnouncementsProvider = ({ children }: PropsType) => {
  const { settings, updateSettings } = useContext(UserContext);
  const { announcements } = settings;

  const [userSettingAnnouncements, setUserSettingAnnouncements] = useState<AnnouncementsSetting>(
    announcements || {}
  );

  const [registeredTooltipAnnouncements, setRegisteredTooltipAnnouncements] = useState<
    AnnouncementName[]
  >([]);

  // watch for user settings are first updated after logging in
  useEffect(() => {
    if (announcements) {
      setUserSettingAnnouncements(announcements);
    }
  }, [Boolean(announcements)]);

  // List of modal announcements to display
  const modalAnnouncements: Announcement[] = useMemo(() => {
    const modalAnnouncementsToDisplay = Object.keys(userSettingAnnouncements)
      .filter((announcementName) => {
        return (
          userSettingAnnouncements?.[announcementName]?.active &&
          displayTypeByAnnouncementName?.[announcementName as string] === AnnouncementType.MODAL
        );
      })
      .map((announcementName) => {
        return {
          ...userSettingAnnouncements?.[announcementName],
          name: announcementName as AnnouncementName,
          type: AnnouncementType.MODAL
        };
      })
      .sort((a: Announcement, b: Announcement) => {
        return (
          displayPriorityByAnnouncementName[a.name] - displayPriorityByAnnouncementName[b.name]
        );
      });

    return modalAnnouncementsToDisplay;
  }, [userSettingAnnouncements]);

  // List of tooltip announcements to display
  const tooltipAnnouncements: Announcement[] = useMemo(() => {
    const tooltipAnnouncementsToDisplay = Object.keys(userSettingAnnouncements)
      .filter((announcementName) => {
        return (
          userSettingAnnouncements?.[announcementName]?.active &&
          displayTypeByAnnouncementName?.[announcementName as string] ===
            AnnouncementType.TOOLTIP &&
          registeredTooltipAnnouncements.includes(announcementName as AnnouncementName)
        );
      })
      .map((announcementName) => {
        return {
          ...userSettingAnnouncements?.[announcementName],
          name: announcementName as AnnouncementName,
          type: AnnouncementType.TOOLTIP
        };
      })
      .sort((a: Announcement, b: Announcement) => {
        return (
          displayPriorityByAnnouncementName[a.name] - displayPriorityByAnnouncementName[b.name]
        );
      });

    return tooltipAnnouncementsToDisplay[0] ? [tooltipAnnouncementsToDisplay[0]] : [];
  }, [userSettingAnnouncements, registeredTooltipAnnouncements]);

  const markAnnouncementComplete = useCallback(
    (announcementName: AnnouncementName) => {
      updateSettings({ announcements: { [announcementName]: { active: false } } }, true);
      // Optimistically update up to reflect completion of announcement
      return setUserSettingAnnouncements((currentAnnouncementsSetting) => {
        return {
          ...currentAnnouncementsSetting,
          [announcementName]: {
            ...currentAnnouncementsSetting[announcementName],
            active: false
          }
        };
      });
    },
    [updateSettings]
  );

  const registerAnnouncement = useCallback((announcementName: AnnouncementName) => {
    return setRegisteredTooltipAnnouncements((currentRegistered) => {
      return [...currentRegistered, announcementName];
    });
  }, []);

  const deregisterAnnouncement = useCallback((announcementName: AnnouncementName) => {
    return setRegisteredTooltipAnnouncements((currentRegistered) => {
      return currentRegistered.filter(
        (registerAnnouncementName) => registerAnnouncementName !== announcementName
      );
    });
  }, []);

  const value = {
    modalAnnouncements,
    tooltipAnnouncements,
    markAnnouncementComplete,
    registerAnnouncement,
    deregisterAnnouncement
  };

  return (
    <>
      <AnnouncementsContext.Provider value={value as AnnouncementsContextType}>
        {children}
      </AnnouncementsContext.Provider>
    </>
  );
};

export default AnnouncementsProvider;
