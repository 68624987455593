import { InputValue } from "../../types";

export const hasMaxCharacters =
  (maxCharacters: number, message?: string) => (value: InputValue) => {
    const errorMessage = message || `Too many characters`;

    const stringValue = value ? value.toString() : "";
    const numberOfCharacters = stringValue.length;

    return numberOfCharacters > maxCharacters ? errorMessage : undefined;
  };
