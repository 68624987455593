import React from "react";
import moment from "moment";
import { SelectInput } from "..";
import { Validate } from "../../../../types";

const startTimePeriods = ["am", "pm"];
const startTimeHours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const startTimeMinutes = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"];

const startTimeOptions: { label: string; value: string }[] = [];

startTimePeriods.forEach((period) => {
  startTimeHours.forEach((hour) => {
    startTimeMinutes.forEach((interval) => {
      startTimeOptions.push({
        label: `${hour}:${interval} ${period}`,
        value: `${hour}:${interval} ${period}`
      });
    });
  });
});

type PropsType = {
  fieldName: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  validate?: Validate;
};

export const getCurrentTimeIntervalInitialValue = () => {
  const currentTime = moment();
  const remainder = 5 - (currentTime.minute() % 5);
  return currentTime.add(remainder, "minutes").format("h:mm a") || "";
};

export const TimeSelectInput = ({
  fieldName,
  label,
  placeholder,
  disabled = false,
  validate
}: PropsType) => {
  return (
    <SelectInput
      fieldName={fieldName}
      label={label}
      placeholder={placeholder}
      validate={validate}
      options={startTimeOptions}
      disabled={disabled}
    />
  );
};
