import React from "react";
import cx from "classnames";
import { EditorState, DraftInlineStyleType } from "draft-js";

import Button from "../../../../../../ui/Button";
import Text from "../../../../../../ui/Text";
import {
  Bold,
  Heading1,
  Heading2,
  Heading3,
  Underline,
  Italic,
  UnorderedList,
  OrderedList,
  Undo,
  Redo
} from "../../../../../../ui/Icon";

import { RichTextEditorTooltip } from "./Tooltip";
import ToolbarActionGroup from "./ToolbarActionGroup";
import { Note } from "../../../../../../../types";

import styles from "./index.module.scss";

const BLOCK_TYPES = [
  { label: "Header 1", value: "header-one", icon: <Heading1 size={24} /> },
  { label: "Header 2", value: "header-two", icon: <Heading2 size={24} /> },
  { label: "Header 3", value: "header-three", icon: <Heading3 size={24} /> }
];

const LIST_TYPES = [
  {
    label: "Ordered List",
    value: "ordered-list-item",
    icon: <OrderedList size={24} />
  },
  {
    label: "Unordered List",
    value: "unordered-list-item",
    icon: <UnorderedList size={24} />
  }
];

const INLINE_STYLES: { label: string; value: DraftInlineStyleType; icon: JSX.Element }[] = [
  { label: "Bold", value: "BOLD", icon: <Bold size={24} /> },
  { label: "Underline", value: "UNDERLINE", icon: <Underline size={24} /> },
  { label: "Italic", value: "ITALIC", icon: <Italic size={24} /> }
];

export type ToolbarProps = {
  editorState: EditorState;
  editorInFocus: boolean;
  toggleInlineStyle: (style: DraftInlineStyleType) => void;
  changeBlockStyle: (style: string) => void;
  mode: "edit" | "view";
  note?: Note;
  undo: () => void;
  redo: () => void;
};

export const Toolbar = ({
  editorState,
  editorInFocus,
  toggleInlineStyle,
  changeBlockStyle,
  mode,
  note,
  undo,
  redo
}: ToolbarProps) => {
  const isActiveInlineStyle = (inlineStyle: DraftInlineStyleType) => {
    return editorState.getCurrentInlineStyle().has(inlineStyle);
  };

  const isActiveBlockStyle = (blockType: string) => {
    const selection = editorState.getSelection();
    const currentBlockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();
    return currentBlockType === blockType;
  };

  return (
    <div className={styles.Toolbar}>
      {!note?.lastSyncedAt && (
        <>
          {BLOCK_TYPES.map((block) => (
            <RichTextEditorTooltip
              key={`tooltip-${block.value}`}
              icon={
                // Source: https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/3382059feb5367c79e049943772e3a6e27e77609/docs/rules/no-static-element-interactions.md#case-the-event-handler-is-only-being-used-to-capture-bubbled-events
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <span
                  key={block.value}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    if (editorInFocus) changeBlockStyle(block.value);
                  }}
                >
                  <Button
                    key={block.value}
                    className={cx(styles.ToolbarButton, {
                      [styles.ToolbarButtonActive]: isActiveBlockStyle(block.value) && editorInFocus
                    })}
                    inline
                    disabled={!editorInFocus}
                  >
                    {block.icon}
                  </Button>
                </span>
              }
            >
              <Text size="S" className={styles.ToolTipText}>
                {block.label}
              </Text>
            </RichTextEditorTooltip>
          ))}
          <hr className={styles.ToolbarSeparator} />
          {INLINE_STYLES.map((style) => (
            <RichTextEditorTooltip
              key={`tooltip-${style.value}`}
              icon={
                // Source: https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/3382059feb5367c79e049943772e3a6e27e77609/docs/rules/no-static-element-interactions.md#case-the-event-handler-is-only-being-used-to-capture-bubbled-events
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <span
                  key={style.value}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    if (editorInFocus) toggleInlineStyle(style.value);
                  }}
                >
                  <Button
                    className={cx(styles.ToolbarButton, {
                      [styles.ToolbarButtonActive]:
                        isActiveInlineStyle(style.value) && editorInFocus
                    })}
                    inline
                    disabled={!editorInFocus}
                  >
                    {style.icon}
                  </Button>
                </span>
              }
            >
              <Text size="S" className={styles.ToolTipText}>
                {style.label}
              </Text>
            </RichTextEditorTooltip>
          ))}
          <div className={styles.ToolbarSeparator} />
          {LIST_TYPES.map((list) => (
            <RichTextEditorTooltip
              key={`tooltip-${list.value}`}
              icon={
                // Source: https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/3382059feb5367c79e049943772e3a6e27e77609/docs/rules/no-static-element-interactions.md#case-the-event-handler-is-only-being-used-to-capture-bubbled-events
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <span
                  key={list.value}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    if (editorInFocus) changeBlockStyle(list.value);
                  }}
                >
                  <Button
                    key={list.value}
                    className={cx(styles.ToolbarButton, {
                      [styles.ToolbarButtonActive]: isActiveBlockStyle(list.value) && editorInFocus
                    })}
                    inline
                    disabled={!editorInFocus}
                  >
                    {list.icon}
                  </Button>
                </span>
              }
            >
              <Text size="S" className={styles.ToolTipText}>
                {list.label}
              </Text>
            </RichTextEditorTooltip>
          ))}
          <div className={styles.ToolbarSeparator} />
          <RichTextEditorTooltip
            icon={
              // Source: https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/3382059feb5367c79e049943772e3a6e27e77609/docs/rules/no-static-element-interactions.md#case-the-event-handler-is-only-being-used-to-capture-bubbled-events
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <span
                onMouseDown={(e) => {
                  e.preventDefault();
                  if (editorInFocus) undo();
                }}
              >
                <Button className={styles.ToolbarButton} inline disabled={!editorInFocus}>
                  <Undo size={24} />
                </Button>
              </span>
            }
          >
            <Text size="S" className={styles.ToolTipText}>
              Undo
            </Text>
          </RichTextEditorTooltip>
          <RichTextEditorTooltip
            icon={
              // Source: https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/3382059feb5367c79e049943772e3a6e27e77609/docs/rules/no-static-element-interactions.md#case-the-event-handler-is-only-being-used-to-capture-bubbled-events
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <span
                onMouseDown={(e) => {
                  e.preventDefault();
                  if (editorInFocus) redo();
                }}
              >
                <Button className={styles.ToolbarButton} inline disabled={!editorInFocus}>
                  <Redo size={24} />
                </Button>
              </span>
            }
          >
            <Text size="S" className={styles.ToolTipText}>
              Redo
            </Text>
          </RichTextEditorTooltip>
        </>
      )}
      <ToolbarActionGroup note={note} mode={mode} editorState={editorState} />
    </div>
  );
};
