import React from "react";
import ReactDOMServer from "react-dom/server";

import Text from "../../../../../../../../ui/Text";

import { convertLogicToReadableString } from "../../../../../../../../ui/Input/JsonLogicInput/helpers";

import { D3Edge } from "../types";

import styles from "./index.module.scss";

type PropsType = {
  edge: D3Edge;
};
const EdgeLogic = ({ edge }: PropsType) => {
  let description = "-";

  if (!edge.input && !edge.jsonLogic) {
    description = "Default";
  } else if (edge.jsonLogic) {
    // Advanced Navigation
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const jsonLogicSummary = convertLogicToReadableString(edge.jsonLogic as any);
    description = jsonLogicSummary || "Advanced Navigation";
  } else if (edge.input) {
    // Basic Navigation
    description = edge.input;
  }

  return (
    <div className={styles.Card}>
      <div className={styles.Description}>
        <Text size="XS" className={styles.DescriptionText}>
          {description}
        </Text>
      </div>
    </div>
  );
};

export const buildEdgeLogicHTML = (edge: D3Edge) => {
  return ReactDOMServer.renderToStaticMarkup(<EdgeLogic edge={edge} />);
};

export default EdgeLogic;
