import React from "react";

import Heading from "../../../ui/Heading";
import { ResponsiveHide } from "../../../ui/Responsive";

import AppointmentsTable from "../../../common/tables/AppointmentsTable";

import styles from "./index.module.scss";

const AppointmentsPage = () => {
  return (
    <>
      <ResponsiveHide hideOnMobile>
        <Heading className={styles.Heading} size="XL">
          Appointments
        </Heading>
      </ResponsiveHide>

      <AppointmentsTable />
    </>
  );
};

export default AppointmentsPage;
