import { ContentBlock } from "draft-js";
import styles from "../index.module.scss";

export const blockStyleFn = (contentBlock: ContentBlock) => {
  const type = contentBlock.getType();
  if (type === "ordered-list-item") {
    return styles.OrderedListItem;
  }
  if (type === "unordered-list-item") {
    return styles.UnorderedListItem;
  }
  if (type.includes("header")) {
    return styles.Header;
  }

  return "";
};
