import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Text from "../../../../ui/Text";
import { Form } from "../../../../ui/Input";
import FormContent from "./FormContent";

import {
  fetchMessageTemplates as fetchMessageTemplatesAction,
  sendDirectMessage as sendDirectMessageAction,
  SendDirectMessageData,
  closeModal as closeModalAction
} from "../../../../../actions";

import { MessageTopics, DirectMessageModes } from "../../../../../constants";
import { ReduxStateType, MessageTemplate, MessageTemplateMedium } from "../../../../../types";
import handleMediumSpecificTags from "../../../../../utils/handleMediumSpecificTags";

import styles from "./index.module.scss";

type AppointmentRequestDirectMessageFormData = {
  messageTemplateId: string;
  mobilePhone: string | null;
  emailAddress: string | null;
  assignedStaffUserId: string | null;
};

type PropsType = {
  loading?: boolean;
  messageTemplates?: Array<MessageTemplate>;
  fetchMessageTemplates?: () => void;
  sendDirectMessage?: (data: SendDirectMessageData) => void;
  closeModal?: () => void;
  staffOptions?: { label: string; value: string }[];
};

const initialFormState: AppointmentRequestDirectMessageFormData = {
  messageTemplateId: "",
  mobilePhone: null,
  emailAddress: null,
  assignedStaffUserId: ""
};

const templateTopicFilter = (topic: string) => (template: MessageTemplate) => {
  return template.topic === topic;
};

const AppointmentRequestDirectMessageForm = ({
  loading,
  closeModal,
  messageTemplates,
  fetchMessageTemplates,
  sendDirectMessage,
  staffOptions = []
}: PropsType) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [previewMedium, setPreviewMedium] = useState(MessageTemplateMedium.sms);

  const updateError = () => {
    setErrorMessage("");
  };

  const displaySelectedMessageTemplate = (templateId: string, medium: string) => {
    const messageTemplate = messageTemplates?.find(
      (template) => template.id === parseInt(templateId, 10)
    );

    if (!messageTemplate) {
      return "Please select a message template to preview.";
    }

    const previewText = messageTemplate?.payloadContent ?? "";
    const subject = messageTemplate?.subject ?? "A message from [place]";

    const isEmail = medium === MessageTemplateMedium.email;

    return isEmail ? (
      <>
        <Text className={styles.PreviewMessageSubject}>{subject}</Text>
        Hello,
        <br />
        {handleMediumSpecificTags(previewText, medium)}
      </>
    ) : (
      <>{handleMediumSpecificTags(previewText, medium)}</>
    );
  };

  // Fetch messages on first load
  useEffect(() => {
    if (fetchMessageTemplates) fetchMessageTemplates();
  }, []);

  const onSend = (formData: AppointmentRequestDirectMessageFormData) => {
    const { mobilePhone, emailAddress, messageTemplateId, assignedStaffUserId } = formData;

    const selectedTemplate = messageTemplates?.find(
      (template) => template.id.toString() === messageTemplateId
    );
    const payloadContent = selectedTemplate?.payloadContent;

    if (!mobilePhone && !emailAddress) {
      setErrorMessage("Please enter a phone number and/or email address.");
    } else if (!payloadContent) {
      setErrorMessage("The selected message template is empty.");
    } else if (sendDirectMessage) {
      sendDirectMessage({
        mode: DirectMessageModes.APPOINTMENT_REQUEST,
        payloadContent,
        mobilePhone,
        emailAddress,
        assignedStaffUserId: assignedStaffUserId || null
      });
    }
  };

  const formDisabled = loading;

  const messageTemplateOptions = messageTemplates
    ? messageTemplates
        .filter(templateTopicFilter(MessageTopics.APPOINTMENT_REQUEST_DIRECT_MESSAGE))
        .map((messageTemplate) => ({
          label: messageTemplate.isDefault
            ? `Default: ${messageTemplate.displayName} (${messageTemplate.id})`
            : `${messageTemplate.displayName} (${messageTemplate.id})`,
          value: messageTemplate.id.toString()
        }))
    : [];

  return (
    <Form
      onSubmit={(formState) => onSend(formState.values as AppointmentRequestDirectMessageFormData)}
      initialValues={initialFormState}
      id="basicMessageForm"
    >
      <FormContent
        setPreviewMedium={setPreviewMedium}
        previewMedium={previewMedium}
        updateError={updateError}
        formDisabled={formDisabled}
        errorMessage={errorMessage}
        messageTemplateOptions={messageTemplateOptions}
        displaySelectedMessageTemplate={displaySelectedMessageTemplate}
        closeModal={closeModal}
        staffOptions={staffOptions}
      />
    </Form>
  );
};

const mapStateToProps = ({ messageTemplates, messages }: ReduxStateType) => {
  return {
    messageTemplates: messageTemplates.data,
    loading: messages.sendDirectMessageLoading
  };
};

export default connect(mapStateToProps, {
  fetchMessageTemplates: fetchMessageTemplatesAction,
  sendDirectMessage: sendDirectMessageAction,
  closeModal: closeModalAction
})(AppointmentRequestDirectMessageForm);
