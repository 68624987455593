import React from "react";

type PropsType = {
  size?: number;
};

const Expand = ({ size = 32 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.666 17.333a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.96 20.96a1 1 0 0 1 1.414 0l3.292 3.292 3.293-3.293a1 1 0 0 1 1.415 1.415l-4 4a1 1 0 0 1-1.415 0l-4-4a1 1 0 0 1 0-1.415ZM15.666 14.667a1 1 0 0 1-1-1v-6a1 1 0 1 1 2 0v6a1 1 0 0 1-1 1Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.374 11.04a1 1 0 0 1-1.415 0l-3.293-3.292-3.292 3.292a1 1 0 1 1-1.415-1.414l4-4a1 1 0 0 1 1.415 0l4 4a1 1 0 0 1 0 1.415Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Expand;
