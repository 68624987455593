/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useContext, useEffect, useMemo } from "react";
import { connect } from "react-redux";

import ScribeToolsManager from "./ScribeToolsManager";

import EncounterSocketProvider, {
  EncounterSocketContext
} from "../../../../providers/EncounterSocketProvider";

import {
  fetchNotes as fetchAppointmentNotesAction,
  FetchNoteFilters,
  addNotification as addNotificationAction
} from "../../../../../actions";

import { useQueryString } from "../../../../../utils/queryStringHelpers";

import {
  Appointment,
  AppointmentDetail,
  RecordingSessionStatus,
  ReduxStateType
} from "../../../../../types";

type PropsType = {
  simpleAppointment?: Appointment;
  appointment?: AppointmentDetail;
  fetchNotes: (filters: FetchNoteFilters) => void;
};

const ProviderAppointmentDetails = ({
  simpleAppointment,
  appointment: appointmentDetails,
  fetchNotes
}: PropsType) => {
  const { recording } = useContext(EncounterSocketContext);
  const isRecordingSessionDeleted = recording?.status === RecordingSessionStatus.DELETED;
  const { parsed } = useQueryString();
  const { appointmentId } = parsed;
  /*
   * "appointmentData" is a combination of the simpleAppointment, if passed in as a prop,
   * and the results of the fetch appointment details. This pattern allows for quick rendering
   * of available information while waiting on detailed fetch results.
   */
  const appointmentData: AppointmentDetail | undefined = useMemo(
    () =>
      simpleAppointment || appointmentDetails
        ? ({ ...simpleAppointment, ...appointmentDetails } as AppointmentDetail)
        : undefined,
    [simpleAppointment, appointmentDetails]
  );

  const patientId = appointmentData?.patientId;

  // Fetch Notes
  useEffect(() => {
    if (appointmentId && patientId && !isRecordingSessionDeleted) {
      fetchNotes({
        patientId,
        appointmentId: parseInt(appointmentId, 10)
      });
    }
  }, [appointmentId, patientId]);

  return (
    <EncounterSocketProvider
      key={`encounterSocketProvider-${appointmentId as string}`}
      appointmentId={appointmentId as string}
    >
      <ScribeToolsManager appointmentId={appointmentId} appointment={appointmentData} />
    </EncounterSocketProvider>
  );
};

const mapStateToProps = ({ organizationData }: ReduxStateType) => {
  return {
    timezone: organizationData.organizationData ? organizationData.organizationData.timezone : ""
  };
};

export default connect(mapStateToProps, {
  fetchNotes: fetchAppointmentNotesAction,
  addNotification: addNotificationAction
})(ProviderAppointmentDetails);
