import React from "react";

type PropsType = {
  size?: number;
  color?: string;
};

const Redo = ({ size = 24, color = "currentColor" }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M24.47 17.36a1.1 1.1 0 0 0 .07-.36v-6a1 1 0 0 0-2 0v3.69l-2.95-2.76a10 10 0 0 0-16.53 3.74 1 1 0 0 0 .61 1.27 1 1 0 0 0 1.33-.6 8 8 0 0 1 13.25-3L21 16h-3.48a1 1 0 1 0 0 2h6a1 1 0 0 0 .93-.63Z"
        fill={color}
      />
    </svg>
  );
};

export default Redo;
