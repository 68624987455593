import React from "react";

import Heading from "../../../../../../ui/Heading";
import Text from "../../../../../../ui/Text";
import { SelectInput } from "../../../../../../ui/Input";
import Drawer from "../../../../../../ui/Drawer";
import Button from "../../../../../../ui/Button";
import { Close } from "../../../../../../ui/Icon";

import { usePermissions } from "../../../../../../../hooks/usePermissions";

import { SubscriptionFormState, SUBSCRIPTIONS_NAMESPACE } from "..";

import { Permissions, CustomOnChangeHandler } from "../../../../../../../types";

import styles from "./index.module.scss";

type OrganizationSubscriptionsType = {
  subscriptions: SubscriptionFormState[];
  onChange: CustomOnChangeHandler;
};

const subscriptionType = [
  { label: "Arrears", value: "arrears" },
  { label: "Advance", value: "advance" }
];

const OrganizationSubscriptions = ({
  subscriptions: organizationSubscriptions,
  onChange
}: OrganizationSubscriptionsType) => {
  const userCanViewBilling = usePermissions([Permissions.VIEW_BILLING], false);
  const userCanEditBilling = usePermissions([Permissions.UPDATE_BILLING], false);

  return (
    <>
      {organizationSubscriptions.length === 0 && (
        <Text size="M">No organization subscriptions</Text>
      )}
      {organizationSubscriptions.map((sub) => (
        <div key={sub.id} className={styles.ChargeOverSubscriptionCard}>
          <div className={styles.OrganizationSubscriptionInfo}>
            <div className={styles.Unassign}>
              <Button
                inline
                onClick={() => {
                  onChange({ [`${SUBSCRIPTIONS_NAMESPACE}.${sub.id}.userId`]: "_unassign" });
                }}
              >
                <Close size={24} />
              </Button>
            </div>
            <div className={styles.SubscriptionIdGroup}>
              <Text size="S" className={styles.SubscriptionHeading}>
                ORGANIZATION SUBSCRIPTION ID
              </Text>
              <Text size="M" className={styles.SubscriptionId}>
                {sub.id}
              </Text>
            </div>
            <div>
              <SelectInput
                fieldName={`${SUBSCRIPTIONS_NAMESPACE}.${sub.id}.type`}
                label="type"
                initialValue={sub.type?.toString()}
                options={subscriptionType}
                customOnChange={onChange}
                disabled={userCanViewBilling && !userCanEditBilling}
              />
            </div>
          </div>

          <Drawer
            initialOpen
            headerContent={
              <div>
                <Heading size="S" className={styles.DrawerHeaderContent}>
                  Line Items
                </Heading>
              </div>
            }
          >
            <div className={styles.LineItemGroup}>
              {sub?.lineItems?.map((lineItem) => (
                <div key={lineItem.productName} className={styles.LineItemCard}>
                  <Heading size="META">{lineItem.productName}</Heading>
                  <Text>{lineItem.id}</Text>
                </div>
              ))}
            </div>
          </Drawer>
        </div>
      ))}
    </>
  );
};

export default OrganizationSubscriptions;
