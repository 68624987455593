/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useContext, useRef, useEffect, useCallback } from "react";
import cx from "classnames";
import {
  Editor,
  EditorState,
  RichUtils,
  DraftHandleValue,
  DraftInlineStyleType,
  getDefaultKeyBinding,
  KeyBindingUtil,
  convertToRaw,
  CompositeDecorator,
  Modifier,
  RawDraftContentState,
  EditorProps,
  SelectionState
} from "draft-js";
import "draft-js/dist/Draft.css";
import throttle from "lodash/throttle";

import { connect } from "react-redux";
import { debounce } from "lodash";

import { ModalTypes } from "../../../../../../constants";

import {
  OpenModal,
  openModal as openModalAction,
  UpdateNoteOptions,
  addNotification as addNotificationAction
} from "../../../../../../actions";

import { EncounterSocketContext } from "../../../../../providers/EncounterSocketProvider";

import {
  mikaAnchorStrategy,
  MikaAnchorDecorator,
  MikaAnchorDecoratorProps,
  mikaContentStrategy,
  MikaContentDecorator,
  MikaContentDecoratorProps
} from "./Decorators";
import { Insights } from "../../../../../ui/Icon";
import MenuList, { ListItem } from "./MenuList"; // TODO - update to custom menu for editor
import { Toolbar } from "./Toolbar";
import AnchorSuggestions from "./AnchorSuggestions";
import {
  blockStyleFn,
  clearEntityContentBy,
  transformMikaContentEntityDataOnChange,
  customBlockRenderer,
  sanitizeClipboardOnCopy,
  injectAnchorContent,
  replaceContentByString
} from "./helpers";

import { Note, SocketMessageType, EventTypes, NewNotification } from "../../../../../../types";

import styles from "./index.module.scss";
import "./editorStyles.css";

enum KeyBindingTypes {
  "SAVE" = "save",
  "BOLD" = "bold",
  "ITALIC" = "italic",
  "UNDERLINE" = "underline",
  "BACKSPACE" = "backspace",
  "SPLIT_BLOCK" = "split-block",
  "UNDO" = "keyz-undo",
  "REDO" = "keyz-redo",
  "LIST_TAB" = "list-tab",
  "TEXT_TAB" = "text-tab",
  "HASHTAG" = "hashtag"
}

type PropsType = {
  editorState: EditorState;
  allowAnchorsEdit?: boolean;
  setEditorState: (editorState: React.SetStateAction<EditorState>) => void;
  onSave: (contentState: RawDraftContentState, options?: UpdateNoteOptions) => void;
  mode: "view" | "edit";
  recordId: number; // note or noteTemplateId
  isActive: boolean;
  mikaContentLoading: boolean;
  addNotification?: (notification: NewNotification) => void;
  saveThrottleMS?: number;
  note?: Note; // DEPRECATED to be removed from child dependencies
  showAnchorSuggestions?: boolean;
  showMenuOnTextSelect?: boolean;
  openModal: OpenModal;
};

const MAX_TAB_DEPTH = 2;

const INLINE_STYLE_MAP = {
  BOLD: {
    fontWeight: "bold",
    fontFamily: "hk-grotesk-bold, sans-serif"
  },
  ITALIC: {
    fontStyle: "italic"
  },
  UNDERLINE: {
    textDecoration: "underline"
  }
};

const LIST_BLOCK_TYPES = ["unordered-list-item", "ordered-list-item"];

const THROTTLE_LIMIT_MILLISECONDS = 5000;

const RichTextEditor = ({
  editorState,
  setEditorState,
  allowAnchorsEdit = false,
  isActive,
  onSave,
  mode,
  recordId,
  addNotification,
  mikaContentLoading,
  saveThrottleMS = 0,
  note,
  showMenuOnTextSelect,
  showAnchorSuggestions = false,
  openModal
}: PropsType) => {
  const [editorInFocus, setEditorInFocus] = useState(false);
  const [selectedAnchorText, setSelectedAnchorText] = useState<string>();
  const [hasAnchorSuggestions, setHasAnchorSuggestions] = useState<boolean>(false);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const editorContainerRef = useRef<HTMLDivElement>(null);
  const [selectedTextContent, setSelectedTextContent] = useState<string>();
  const [selectedTextDOMPosition, setSelectedTextDOMPosition] = useState<DOMRect>();
  const [showingPopupMenu, setShowPopupMenu] = useState(false);
  const { sendMessage } = useContext(EncounterSocketContext);

  const sendCopyEvent = async () => {
    const clipboardItems = await navigator.clipboard.read();
    const clipboardItem = clipboardItems?.[0];
    const clipboardPlainText = await (await clipboardItem.getType("text/plain")).text();

    sendMessage(SocketMessageType.providerActivity, {
      eventType: EventTypes.COPY_NOTE_TO_CLIPBOARD_MANUAL,
      eventData: {
        activeNoteId: note?.id,
        copiedTextLength: clipboardPlainText.length,
        noteTemplateId: note?.noteTemplateId
      }
    });
    if (addNotification) {
      addNotification({
        type: "success",
        title: "Copied",
        autoDismiss: true
      });
    }
  };

  const editorRef = useRef<Editor | null>(null);

  const currentContentState = editorState?.getCurrentContent();
  const currentRawEntityMap = currentContentState
    ? convertToRaw(currentContentState).entityMap
    : null;
  const rawEntityStatuses = currentRawEntityMap
    ? Object.values(currentRawEntityMap)
        .map((entity) => {
          return `${recordId}-${entity?.data?.status}-${entity?.data?.anchorName || ""}`;
        })
        .sort()
        .join(",")
    : "";

  // Add decorators
  useEffect(() => {
    const compositeDecorator = new CompositeDecorator([
      {
        strategy: mikaAnchorStrategy,
        // eslint-disable-next-line react/no-unstable-nested-components
        component: (props: MikaAnchorDecoratorProps) => {
          return (
            <MikaAnchorDecorator
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              mikaContentLoading={mikaContentLoading}
              toolbarBottomPosition={toolbarRef?.current?.getBoundingClientRect()?.bottom || 350}
              selectedAnchorText={selectedAnchorText}
              allowAnchorsEdit={allowAnchorsEdit}
              setSelectedAnchorText={setSelectedAnchorText}
              removeMikaAnchorByEntityKey={(contentEntityKey: string) => {
                if (allowAnchorsEdit) {
                  setEditorState((currentEditorState) => {
                    return clearEntityContentBy(
                      currentEditorState,
                      (entityKey) => entityKey === contentEntityKey
                    );
                  });
                }
              }}
            />
          );
        }
      },
      {
        strategy: mikaContentStrategy,
        // eslint-disable-next-line react/no-unstable-nested-components
        component: (props: MikaContentDecoratorProps) => {
          return (
            <MikaContentDecorator
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              removeMikaContentByEntityKey={(contentEntityKey: string) => {
                setEditorState((currentEditorState) => {
                  return clearEntityContentBy(
                    currentEditorState,
                    (entityKey) => entityKey === contentEntityKey
                  );
                });
              }}
            />
          );
        }
      }
    ]);

    setEditorState((currentEditorState) => {
      const updatedEditorState = EditorState.set(currentEditorState, {
        decorator: compositeDecorator
      });
      return updatedEditorState;
    });
  }, [
    recordId,
    isActive,
    rawEntityStatuses,
    mikaContentLoading,
    selectedAnchorText,
    setSelectedAnchorText
  ]);

  const save = (currentEditorState: EditorState, options?: UpdateNoteOptions) => {
    const currentContent = currentEditorState.getCurrentContent();
    const rawContent = convertToRaw(currentContent);
    onSave(rawContent, options);
  };

  // Throttle save events
  const throttledSave = useCallback(
    throttle(save, saveThrottleMS || THROTTLE_LIMIT_MILLISECONDS, { leading: false }),
    []
  );

  // Save note on unmount events
  useEffect(() => {
    return () => {
      if (mode === "edit" && recordId) {
        throttledSave.flush();
      }
    };
  }, [mode, recordId]);

  const toggleInlineStyle = (inlineStyle: DraftInlineStyleType) => {
    const newEditorState = RichUtils.toggleInlineStyle(editorState, inlineStyle);
    setEditorState(newEditorState);
  };

  const changeBlockStyle = (blockType: string) => {
    const newEditorState = RichUtils.toggleBlockType(editorState, blockType);
    const newContentState = newEditorState.getCurrentContent();
    const nextEditorState = EditorState.push(newEditorState, newContentState, "change-block-type");
    setEditorState(nextEditorState);
  };

  const undo = () => {
    const newEditorState = EditorState.undo(editorState);
    setEditorState(newEditorState);
  };

  const redo = () => {
    const newEditorState = EditorState.redo(editorState);
    setEditorState(newEditorState);
  };

  const handleKeyCommand = (command: string, editorStateCurrent: EditorState): DraftHandleValue => {
    if (command === KeyBindingTypes.SAVE) {
      save(editorStateCurrent);
      return "handled";
    }
    if (command === KeyBindingTypes.BOLD) {
      toggleInlineStyle("BOLD");
      return "handled";
    }
    if (command === KeyBindingTypes.ITALIC) {
      toggleInlineStyle("ITALIC");
      return "handled";
    }
    if (command === KeyBindingTypes.UNDERLINE) {
      toggleInlineStyle("UNDERLINE");
      return "handled";
    }
    if (command === KeyBindingTypes.LIST_TAB) {
      return "handled";
    }
    if (command === KeyBindingTypes.TEXT_TAB) {
      const currentSelection = editorStateCurrent.getSelection();
      // handle case when text is selected as we are unable to insertText with a non-collapsed selection
      if (!currentSelection.isCollapsed()) {
        return "handled";
      }

      const newContentState = Modifier.insertText(
        editorStateCurrent.getCurrentContent(),
        currentSelection,
        "  "
      );
      if (newContentState) {
        const newEditorState = EditorState.push(
          editorStateCurrent,
          newContentState,
          "insert-characters"
        );
        setEditorState(newEditorState);
      }

      return "handled";
    }

    return "not-handled";
  };
  const resetTextSelection = () => {
    const selectionRangeCount = document.getSelection()?.rangeCount || 0;
    if (selectionRangeCount > 0) {
      document.getSelection()?.collapseToEnd(); // resetting if dom selection is still tracking
    }
    setSelectedTextContent(undefined);
    setSelectedTextDOMPosition(undefined);
    setShowPopupMenu(false);
  };

  const keyBindingFn: EditorProps["keyBindingFn"] = (e) => {
    // cmd s
    if (e.nativeEvent.code === "KeyS" && KeyBindingUtil.hasCommandModifier(e)) {
      return KeyBindingTypes.SAVE;
    }

    // add hashtag
    if (e.nativeEvent.code === "Digit3" && e.nativeEvent.shiftKey) {
      return KeyBindingTypes.HASHTAG;
    }

    // tab
    if (e.nativeEvent.code === "Tab") {
      e.preventDefault();
      const selection = editorState.getSelection();
      const currentBlockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

      // handle Tab differently depending on whether we are in a list blockType or not
      if (LIST_BLOCK_TYPES.includes(currentBlockType)) {
        setEditorState(RichUtils.onTab(e, editorState, MAX_TAB_DEPTH));

        return KeyBindingTypes.LIST_TAB;
      }

      return KeyBindingTypes.TEXT_TAB;
    }

    return getDefaultKeyBinding(e);
  };

  const setEditorFocus = (isFocused: boolean) => {
    if (editorRef && editorRef.current) {
      if (isFocused) {
        editorRef.current.focus();
      } else {
        editorRef.current.blur();
      }
      setEditorInFocus(isFocused);
    }
  };

  const addAnchor = (anchorData: {
    anchorName: string;
    anchorTitle: {
      default: string;
      loading: string;
    };
  }) => {
    const editorStateWithMikaAnchorEntity = injectAnchorContent({
      editorState,
      anchorData
    });
    setEditorState(editorStateWithMikaAnchorEntity);
    throttledSave(editorStateWithMikaAnchorEntity);
  };

  const isReadOnly = mode === "view";

  // delaying menu popup to avoid it popping up all the time
  const debounceMenu = debounce(() => {
    setSelectedTextContent(document.getSelection()?.toString()?.trim());
    setShowPopupMenu(true);
  }, 650);

  const isValidSelection = () => {
    const selection = document.getSelection();
    const textS = selection?.toString()?.trim();
    const numberOfLineBreaks = (textS?.match(/\n/g) || []).length;
    const isValidPhraseWithNoLineBreaks = numberOfLineBreaks === 0;
    if (isValidPhraseWithNoLineBreaks) {
      if (selection && textS && textS.length < 31 && textS?.split(" ").length < 4) {
        return true;
      }
    }
    return false;
  };

  const updateClientRect = () => {
    if (isValidSelection()) {
      setSelectedTextDOMPosition(document.getSelection()?.getRangeAt?.(0).getBoundingClientRect());
    } else {
      setSelectedTextDOMPosition(undefined);
    }
  };

  const monitorTextSelection = () => {
    if (isValidSelection()) {
      debounceMenu();
      setSelectedTextDOMPosition(document.getSelection()?.getRangeAt?.(0).getBoundingClientRect());
    }
  };

  // needed for anchoring the position of menu to the selected text
  const {
    // needed to hide menu if selected text goes out of view
    top: editorContainerRefTop = 0,
    // needed to position menu
    left: editorContainerRefLeft = 0,
    // needed to hide menu if selected text goes out of view
    bottom: editorContainerRefBottom = 0
  } = editorContainerRef?.current?.getBoundingClientRect() || {
    top: 0,
    left: 0,
    bottom: 0
  };
  // needed to position menu
  const { bottom: toolbarRefBottom = 0 } = toolbarRef.current?.getBoundingClientRect() || {
    bottom: 0
  };

  const popupMenuDynamicStyle: React.CSSProperties =
    selectedTextContent &&
    selectedTextDOMPosition &&
    selectedTextDOMPosition?.top > editorContainerRefTop &&
    selectedTextDOMPosition?.bottom < editorContainerRefBottom
      ? {
          position: "absolute",
          left: `${selectedTextDOMPosition.left - editorContainerRefLeft}px`,
          top: `${selectedTextDOMPosition.top - toolbarRefBottom + 60}px`,

          borderRadius: "3px",
          zIndex: 110
        }
      : { display: "none" };

  const resetSelectionState = (editorState: EditorState) => {
    const firstKey = editorState.getSelection().getFocusKey();
    const length = editorState.getSelection().getFocusOffset();

    const afterSelectionMove = EditorState.acceptSelection(
      editorState,
      new SelectionState({
        anchorKey: firstKey,
        anchorOffset: length,
        focusKey: firstKey,
        focusOffset: length,
        isBackward: false
      })
    );
    return EditorState.forceSelection(afterSelectionMove, afterSelectionMove.getSelection());
  };

  const getEditorMenuList = useCallback(() => {
    const menuItems: ListItem[] = [];
    if (selectedTextContent) {
      if (selectedTextContent.length < 31 && selectedTextContent?.split(" ")?.length < 4) {
        menuItems.push({
          label: "Enhance Mika AI",
          icon: <Insights size={16} />,
          onClick: () => {
            openModal(ModalTypes.ADD_TO_VOCABULARY, {
              selectedWord: selectedTextContent,
              onReplaceWord: (oldWord: string, updatedWord?: string) => {
                resetTextSelection();
                if (updatedWord) {
                  const updatedEditorState = replaceContentByString(
                    oldWord,
                    updatedWord,
                    editorState
                  );
                  setEditorState(updatedEditorState);
                  throttledSave(updatedEditorState);
                } else {
                  setEditorState(resetSelectionState(editorState));
                }
              },
              noteId: note?.id
            });
          }
        });
      }
    }
    return menuItems;
  }, [selectedTextContent]);

  return (
    <>
      {showAnchorSuggestions && (
        <AnchorSuggestions
          setHasAnchorSuggestions={setHasAnchorSuggestions}
          addAnchor={addAnchor}
        />
      )}
      <div
        id={`richTextEditor-${recordId}`}
        data-skip-default-tab={isActive && editorInFocus}
        className={cx(styles.Editor, {
          [styles.EditorWithAnchorSuggestions]: showAnchorSuggestions && hasAnchorSuggestions
        })}
      >
        <div className={styles.InputColumn}>
          <div ref={toolbarRef}>
            <Toolbar
              editorState={editorState}
              editorInFocus={editorInFocus}
              toggleInlineStyle={toggleInlineStyle}
              changeBlockStyle={changeBlockStyle}
              mode={mode}
              note={note}
              undo={undo}
              redo={redo}
            />
          </div>
          {/* Source: https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/3382059feb5367c79e049943772e3a6e27e77609/docs/rules/no-static-element-interactions.md#case-the-event-handler-is-only-being-used-to-capture-bubbled-events */}
          <div
            className={cx(styles.InputScroll, {
              [styles.InputScrollWithAnchorSuggestions]:
                showAnchorSuggestions && hasAnchorSuggestions
            })}
            ref={editorContainerRef}
            onScroll={() => {
              if (selectedTextContent && selectedTextDOMPosition) {
                // realigning the position with scroll
                updateClientRect();
              }
            }}
            onClick={(e) => {
              if (showingPopupMenu) {
                resetTextSelection();
              } else if (e.currentTarget === e.target) {
                const newEditorState = EditorState.moveFocusToEnd(editorState);
                setEditorState(newEditorState);
              }
            }}
            onFocus={() => setEditorFocus(true)}
            onBlur={() => {
              setSelectedAnchorText(undefined);
              setEditorFocus(false);
            }}
          >
            <div className={styles.InputWrapper}>
              <div className={styles.Input}>
                <Editor
                  onCopy={(editor, event) => {
                    sendCopyEvent();
                    sanitizeClipboardOnCopy();
                  }}
                  ref={(ref) => {
                    if (ref) editorRef.current = ref;
                  }}
                  blockStyleFn={blockStyleFn}
                  blockRendererFn={customBlockRenderer}
                  customStyleMap={INLINE_STYLE_MAP}
                  editorState={editorState}
                  onChange={(currentEditorState) => {
                    if (showMenuOnTextSelect) {
                      monitorTextSelection();
                    }

                    const transformedEditorState =
                      transformMikaContentEntityDataOnChange(currentEditorState);

                    setEditorState(transformedEditorState);
                    throttledSave(transformedEditorState);
                  }}
                  handleKeyCommand={handleKeyCommand}
                  keyBindingFn={keyBindingFn}
                  readOnly={isReadOnly}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
        }}
      >
        <MenuList
          list={getEditorMenuList()}
          customStyle={popupMenuDynamicStyle}
          isOpen={showingPopupMenu}
        />
      </div>
    </>
  );
};

export default connect(null, {
  addNotification: addNotificationAction,
  openModal: openModalAction
})(RichTextEditor);
