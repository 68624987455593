/* eslint-disable no-useless-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";

import Text from "../ui/Text";

import styles from "./accountDropdown.module.scss";
import { ChevronRight } from "../ui/Icon";

type PropsType = {
  logout: () => void;
};

const AccountDropdown = ({ logout }: PropsType) => {
  const elementRef = useRef<any>();
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((isOpen) => !isOpen);
  };

  const offDropdownClick = (event: any) => {
    const dropdownEl = elementRef.current;
    const isOutsideClick = dropdownEl && !dropdownEl.contains(event.target);

    if (isOutsideClick) {
      toggle();
      document.removeEventListener("mouseup", offDropdownClick, false);
    }
  };

  useEffect(() => {
    if (open) {
      // add when mounted
      document.addEventListener("mouseup", offDropdownClick);
      return () => {
        document.removeEventListener("mouseup", offDropdownClick);
      };
    }

    document.removeEventListener("mouseup", offDropdownClick);

    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mouseup", offDropdownClick);
    };
  }, [open]);

  return (
    <div ref={elementRef} className={cx(styles.DropdownWrapper)}>
      <button
        type="button"
        className={cx(styles.Dropdown)}
        onClick={() => {
          toggle();
        }}
      >
        <Text className={cx(styles.DropdownText)} bold size="S" component="span">
          Your Account
        </Text>
        <div className={cx(styles.DropdownIcon, { [styles.DropdownIconOpen]: open })}>
          <ChevronRight size={16} />
        </div>
      </button>
      <div className={cx(styles.DropdownList, { [styles.DropdownListOpen]: open })}>
        <a
          type="button"
          href="https://mikatahealth.com/help"
          rel="noreferrer"
          target="_blank"
          className={styles.DropdownListItem}
        >
          <Text className={styles.DropdownListItemText} bold size="S" component="span">
            Help
          </Text>
        </a>
        <button type="button" className={styles.DropdownListItem} onClick={logout}>
          <Text className={styles.DropdownListItemText} bold size="S" component="span">
            Log out
          </Text>
        </button>
      </div>
    </div>
  );
};

export default AccountDropdown;
