import React, { Dispatch, SetStateAction, useMemo } from "react";
import { v4 as uuid } from "uuid";

import { TextAreaInput, JsonContentInput } from "../../../../../../../../ui/Input";
import BaseChatNodeForm, {
  BaseFormValues,
  FormValues,
  TextFormValue
} from "../../BaseChatNodeForm";

import { ChatFlowsNodes, ChatNodeTypes, JsonContent } from "../../../../../../../../../types";

type PropsType = {
  chatId: string | undefined;
  node: ChatFlowsNodes;
  viewOnly: boolean;
  setSelectedNodeId: Dispatch<SetStateAction<number | null>>;
};

const onSaveCustomTransformer = (formValues: FormValues) => {
  const values = formValues as BaseFormValues & TextFormValue;
  const contentJsonIsEmpty =
    values.contentJson &&
    values.contentJson.length === 1 &&
    values.contentJson[0].children.length === 0;

  return {
    payloadContent: values.payloadContent,
    payloadOptions: { contentJson: !contentJsonIsEmpty ? values.contentJson : undefined }
  };
};

const defaultContent: JsonContent = [
  {
    id: uuid(),
    type: ChatNodeTypes.content,
    properties: undefined,
    children: []
  }
];

const Text = ({ chatId, node, viewOnly, setSelectedNodeId }: PropsType) => {
  const initialFormValues: TextFormValue | null = useMemo(() => {
    if (!node) return null;
    const message = node?.payloadContent ? node.payloadContent : "";
    const contentJson = node?.payloadOptions?.contentJson
      ? node.payloadOptions.contentJson
      : defaultContent;

    return {
      payloadContent: message,
      contentJson
    };
  }, [node.id]);

  if (!initialFormValues) return null;

  return (
    <BaseChatNodeForm
      chatId={chatId}
      key={node.id}
      initialValues={initialFormValues}
      onSaveCustomTransformer={onSaveCustomTransformer}
      node={node}
      viewOnly={viewOnly}
      setSelectedNodeId={setSelectedNodeId}
    >
      <TextAreaInput
        id="chatNodePayloadContent"
        size="S"
        fieldName="payloadContent"
        label="Mikata Message"
        disabled={viewOnly}
      />

      <JsonContentInput fieldName="contentJson" disabled={viewOnly} />
    </BaseChatNodeForm>
  );
};

export default Text;
