import React from "react";
import cx from "classnames";

import Text from "../../ui/Text";
import { TagComponentConfigMap } from "../../../types";

import styles from "./index.module.scss";

type PropsType = {
  fullWidth?: boolean;
  tags: string[];
  configMap: TagComponentConfigMap;
  containerClass?: string;
  tagClass?: string;
  id?: string;
  size?: "M" | "S" | "XS";
  bold?: boolean;
};

const Tags = ({
  configMap,
  tags = [],
  containerClass,
  tagClass,
  fullWidth = false,
  id,
  size = "XS",
  bold = true
}: PropsType) => {
  return (
    <div className={cx(styles.Container, containerClass)}>
      {tags?.map((item, index) => {
        const mode = configMap[item]?.color || "default";
        const displayText = configMap[item]?.label || item;
        return (
          <div
            key={`tags_${item}_${index}`}
            id={id}
            className={cx(
              styles.Tag,
              {
                [styles.TagBlue]: mode === "blue",
                [styles.TagGreen]: mode === "green",
                [styles.TagYellow]: mode === "yellow",
                [styles.TagRed]: mode === "red",
                [styles.TagPurple]: mode === "purple",
                [styles.TagPink]: mode === "pink",
                [styles.TagGray]: mode === "gray",
                [styles.TagBlack]: mode === "black",
                [styles.TagFullWidth]: fullWidth
              },
              tagClass
            )}
          >
            <Text
              className={cx(styles.Text, {
                [styles.TextBlue]: mode === "blue",
                [styles.TextGreen]: mode === "green",
                [styles.TextYellow]: mode === "yellow",
                [styles.TextRed]: mode === "red",
                [styles.TextPurple]: mode === "purple",
                [styles.TextPink]: mode === "pink",
                [styles.TextGray]: mode === "gray",
                [styles.TextBlack]: mode === "black",
                [styles.TextPlaceholder]: mode === "default"
              })}
              size={size}
              component="span"
              bold={bold}
            >
              {displayText}
            </Text>
          </div>
        );
      })}
    </div>
  );
};

export default Tags;
