import React from "react";

import { CircleCheck } from "../../../../ui/Icon";
import Text from "../../../../ui/Text";

import styles from "./index.module.scss";

interface PropsType {
  patientFullName?: string;
  patientNumber?: string;
  patientVerified?: boolean;
}

const InboxPatient = ({ patientFullName, patientNumber, patientVerified }: PropsType) => {
  return (
    <div>
      <div className={styles.PatientHeading}>
        <Text className={styles.PatientHeadingText}>{patientFullName || ""}</Text>
        {patientVerified && (
          <div className={styles.PatientHeadingIcon}>
            <CircleCheck size={16} />
          </div>
        )}
      </div>
      {patientNumber && <Text size="S">{patientNumber}</Text>}
    </div>
  );
};

export default InboxPatient;
