import { FETCH_UPLOAD_RECORD, UploadRecordStatuses } from "../constants";

const initialState = {
  uploadInProgress: false
};

export const uploadRecordReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_UPLOAD_RECORD: {
      const uploadRecord = payload?.data?.uploadRecord;
      const uploadInProgress = uploadRecord
        ? uploadRecord.status === UploadRecordStatuses.PROCESSING ||
          uploadRecord.status === UploadRecordStatuses.VERIFIED ||
          uploadRecord.status === UploadRecordStatuses.VERIFYING
        : state.uploadInProgress;
      return { ...state, uploadRecord, uploadInProgress };
    }
    default:
      return state;
  }
};
