import React, { useContext } from "react";
import { toInteger } from "lodash";

import { OrganizationContext } from "../components/providers/OrganizationProvider";
import { UserContext } from "../components/providers/UserProvider";

export const hasPermissionLevelOrHigher = (permissionPrefix: string, permissionLevel: number) => {
  const { permissions } = useContext(UserContext);
  const userPermission = permissions.find((permission) => permission.startsWith(permissionPrefix));
  const userPermissionLevel = userPermission && userPermission.split("_").pop();

  return toInteger(userPermissionLevel) >= permissionLevel;
};

export const usePermissions = (requiredPermissions: string[], requiresVerifiedOrg = true) => {
  const { permissions } = useContext(UserContext);
  const organization = useContext(OrganizationContext);
  const isVerified = organization?.verified;

  const hasPermission = requiredPermissions.every((permission) => permissions.includes(permission));

  return requiresVerifiedOrg ? isVerified && hasPermission : hasPermission;
};

type PermissionsGuardPropsType = {
  requiresVerifiedOrg?: boolean;
  requiredPermissions: string[];
  children: React.ReactNode;
};

export const PermissionsGuard = ({
  requiresVerifiedOrg = true,
  requiredPermissions,
  children
}: PermissionsGuardPropsType) => {
  const hasPermission = usePermissions(requiredPermissions, requiresVerifiedOrg);
  if (!hasPermission) return null;

  return <>{children}</>;
};
