import axios from "axios";
import getToken from "../utils/getToken";

import { addNotification } from "./notifications";

import { getChatFlowDetails } from "./chats";

import {
  ROOT_URL,
  FETCH_REPORT_TEMPLATES,
  GET_REPORT_TEMPLATE_DETAILS,
  UPDATE_REPORT_TEMPLATES,
  CLEAR_REPORT_TEMPLATE_DETAILS,
  UPDATE_REPORT_TEMPLATE_CARD,
  CREATE_REPORT_TEMPLATE_CARD,
  CREATE_REPORT_TEMPLATE,
  REMOVE_REPORT_TEMPLATE_CARD
} from "../constants";

import {
  ReportTemplate,
  ReportTemplateCard,
  ActionStatus,
  Dispatch,
  CreateReportTemplate,
  ReportTemplateCardType,
  Tag
} from "../types";

export type CreateReportTemplateAction = {
  type: typeof CREATE_REPORT_TEMPLATE;
  status?: ActionStatus;
  payload?: { data: CreateReportTemplate };
};

export type GetReportTemplateDetailsAction = {
  type: typeof GET_REPORT_TEMPLATE_DETAILS;
  status?: ActionStatus;
  payload?: {
    reportTemplates: Array<ReportTemplate>;
    reportTemplateCards: Array<ReportTemplateCard>;
    allSubReportTags: Array<Tag>;
    availableSubReportTags: Array<Tag>;
  };
};

export type UpdateReportTemplateAction = {
  type: typeof UPDATE_REPORT_TEMPLATES;
  status?: ActionStatus;
};

export type RemoveReportTemplateCardAction = {
  type: typeof REMOVE_REPORT_TEMPLATE_CARD;
  status?: ActionStatus;
  payload?: { reportTemplateId: number };
};

export type ClearReportTemplateDetailsAction = {
  type: typeof CLEAR_REPORT_TEMPLATE_DETAILS;
};

export type UpdateReportTemplateCardAction = {
  type: typeof UPDATE_REPORT_TEMPLATE_CARD;
  status: ActionStatus;
  payload: { cardId: number };
};

export type CreateReportTemplateCardAction = {
  type: typeof CREATE_REPORT_TEMPLATE_CARD;
  status: ActionStatus;
  payload: {
    reportTemplateCardData: CreateReportTemplateCardData;
  };
};

export type GetReportTemplateDetailsOptions = {
  silent?: boolean;
};

export const getReportTemplateDetails =
  (id: string, options?: GetReportTemplateDetailsOptions) => async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: `${token}`, "Content-Type": "application/json" }
    };

    const isSilent = options?.silent;

    dispatch({
      type: GET_REPORT_TEMPLATE_DETAILS,
      status: isSilent ? ActionStatus.silentLoading : ActionStatus.loading
    });

    return axios
      .get(`${ROOT_URL}/reports/templates/${id}`, config)
      .then((response) => {
        return dispatch({
          type: GET_REPORT_TEMPLATE_DETAILS,
          status: ActionStatus.success,
          payload: response.data
        });
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to fetch report template details",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );

        return dispatch({
          type: GET_REPORT_TEMPLATE_DETAILS,
          status: ActionStatus.error
        });
      });
  };

export type ReportTemplateUpdateData = {
  tagName?: string | null;
};

export type UpdateReportTemplatesOptions = {
  silent?: boolean;
};

export const updateReportTemplate =
  async (
    templateId: number,
    data: ReportTemplateUpdateData,
    options?: UpdateReportTemplatesOptions
  ) =>
  async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    const handleError = () => {
      console.log("error updating report template! should see notification!");
      dispatch(
        addNotification({
          type: "error",
          title: "Error updating report template",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: UPDATE_REPORT_TEMPLATES,
        status: ActionStatus.error
      });
    };

    try {
      const isSilent = options?.silent;
      dispatch({
        type: UPDATE_REPORT_TEMPLATES,
        status: isSilent ? ActionStatus.silentLoading : ActionStatus.loading
      });
      const response = await axios.patch(
        `${ROOT_URL}/reports/templates/${templateId}`,
        data,
        config
      );
      if (response && !response.data.success) {
        handleError();
      }
      getReportTemplateDetails(templateId.toString(), { silent: true })(dispatch);
      return dispatch({
        type: UPDATE_REPORT_TEMPLATES,
        status: ActionStatus.success
      });
    } catch (error) {
      return handleError();
    }
  };

export const createReportTemplate =
  (newReport: CreateReportTemplate, chatId: number) => (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    const body = {
      name: newReport.displayName,
      chatFlowId: chatId,
      autoPush: newReport.autoPush,
      noteTemplate: newReport.noteTemplate
    };

    dispatch({
      type: CREATE_REPORT_TEMPLATE,
      status: ActionStatus.loading
    });

    return axios
      .post(`${ROOT_URL}/reports/templates/`, body, config)
      .then((payload) => {
        getChatFlowDetails(chatId.toString())(dispatch);
        dispatch({
          type: CREATE_REPORT_TEMPLATE,
          status: ActionStatus.success,
          payload
        });
        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: "Report Template Created",
            autoDismiss: true
          })
        );
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to create report template",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
      });
  };

export const removeReportTemplateCard =
  (reportTemplateId: number, cardId: number) => async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: `${token}`, "Content-Type": "application/json" }
    };

    const templateId = reportTemplateId;

    dispatch({
      type: REMOVE_REPORT_TEMPLATE_CARD,
      status: ActionStatus.loading
    });

    return axios
      .delete(`${ROOT_URL}/reports/templates/${templateId}/cards/${cardId}`, config)
      .then(() => {
        getReportTemplateDetails(reportTemplateId.toString())(dispatch);
        dispatch({
          type: REMOVE_REPORT_TEMPLATE_CARD,
          status: ActionStatus.success
        });
        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: `Report Template Card successfully deleted`,
            autoDismiss: true
          })
        );
      })
      .catch((error) => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to delete report template card",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
        return dispatch({
          type: REMOVE_REPORT_TEMPLATE_CARD,
          status: ActionStatus.error
        });
      });
  };

export const clearReportTemplateDetails = () => ({
  type: CLEAR_REPORT_TEMPLATE_DETAILS
});

export type ReportTemplateCardUpdateData = {
  order: number;
  content: string;
  type: string;
  condition: string;
  thenContent: string;
  elseContent: string;
  displayName: string;
  parentId: number;
};

export const updateReportTemplateCard =
  (templateId: number, cardId: number, data: ReportTemplateCardUpdateData) =>
  (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: UPDATE_REPORT_TEMPLATE_CARD,
      status: ActionStatus.loading,
      payload: {
        cardId
      }
    });

    return axios
      .put(`${ROOT_URL}/reports/templates/${templateId}/cards/${cardId}`, data, config)
      .then(async () => {
        await getReportTemplateDetails(templateId.toString(), { silent: true })(dispatch);
        dispatch({
          type: UPDATE_REPORT_TEMPLATE_CARD,
          status: ActionStatus.success,
          payload: {
            cardId
          }
        });
        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: `Successfully updated report template card`,
            autoDismiss: true
          })
        );
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to update report template card",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
        return dispatch({
          type: UPDATE_REPORT_TEMPLATE_CARD,
          status: ActionStatus.error,
          payload: { cardId }
        });
      });
  };

export type CreateReportTemplateCardData = {
  id: string;
  order: string;
  parentId: number | null;
  type: ReportTemplateCardType | string;
  displayName: string;
  condition?: JSON | null;
  thenContent?: string;
  elseContent?: string;
  content?: string;
};

export const createReportTemplateCard =
  (data: CreateReportTemplateCardData, templateId: number) => async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };
    dispatch({
      type: CREATE_REPORT_TEMPLATE_CARD,
      status: ActionStatus.loading,
      payload: { reportTemplateCardData: data }
    });

    return axios
      .post(`${ROOT_URL}/reports/templates/${templateId}/cards`, data, config)
      .then(async () => {
        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: `Successfully added a new report template card`,
            autoDismiss: true
          })
        );
        await getReportTemplateDetails(templateId.toString(), { silent: true })(dispatch);
        dispatch({
          type: CREATE_REPORT_TEMPLATE_CARD,
          status: ActionStatus.success,
          payload: { reportTemplateCardData: data }
        });
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: "error",
            title: `Failed to add a new report template card`,
            subtitle: "Please try again",
            autoDismiss: true
          })
        );

        dispatch({
          type: CREATE_REPORT_TEMPLATE_CARD,
          status: ActionStatus.error,
          payload: { reportTemplateCardData: data }
        });
      });
  };
