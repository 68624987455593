import React, { Dispatch, SetStateAction, useMemo } from "react";

import { SelectInput, TextAreaInput } from "../../../../../../../../ui/Input";
import { ChatFlowsNodes, Option, TextInputFormat } from "../../../../../../../../../types";

import BaseChatNodeForm, {
  BaseFormValues,
  TextInputFormValues,
  FormValues
} from "../../BaseChatNodeForm";

type TextInputPayloadOptions = null | { inputFormat?: TextInputFormat };

export type TextInputChatFlowsNode = ChatFlowsNodes & {
  payloadOptions: TextInputPayloadOptions;
};

type PropsType = {
  chatId: string | undefined;
  node: TextInputChatFlowsNode;
  viewOnly: boolean;
  setSelectedNodeId: Dispatch<SetStateAction<number | null>>;
};

const textModeOptions: Option[] = [
  { label: "Default", value: TextInputFormat.TEXT },
  { label: "Postal Code", value: TextInputFormat.POSTAL_CODE }
];

const onSaveCustomTransformer = (formValues: FormValues) => {
  const values = formValues as BaseFormValues & TextInputFormValues;
  return {
    payloadContent: values.payloadContent ? values.payloadContent : "",
    payloadOptions: {
      inputFormat: values.inputFormat || TextInputFormat.TEXT
    }
  };
};

const TextInputCard = ({ chatId, node, viewOnly, setSelectedNodeId }: PropsType) => {
  const initialFormValues: TextInputFormValues = useMemo(() => {
    return {
      payloadContent: node?.payloadContent || "",
      payloadOptions: node?.payloadOptions || null,
      inputFormat: node?.payloadOptions?.inputFormat || TextInputFormat.TEXT
    };
  }, [node.id]);

  return (
    <BaseChatNodeForm
      chatId={chatId}
      initialValues={initialFormValues}
      onSaveCustomTransformer={onSaveCustomTransformer}
      node={node}
      key={node.id}
      isInput
      viewOnly={viewOnly}
      setSelectedNodeId={setSelectedNodeId}
    >
      <TextAreaInput
        id="chatNodePayloadContent"
        size="S"
        fieldName="payloadContent"
        label="Mikata Message"
        disabled={viewOnly}
      />
      <SelectInput
        fieldName="inputFormat"
        label="Input Format Mode"
        options={textModeOptions}
        disabled={viewOnly}
      />
    </BaseChatNodeForm>
  );
};

export default TextInputCard;
