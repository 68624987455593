import React from "react";
import { useField } from "informed";

import BaseInput from "../BaseInput";
import Search from "../../Search";
import CheckboxGroupBase from "./CheckboxGroupBase";

import useSearchFilter from "../../../../hooks/useSearchFilter";
import { InputPropsType, Option } from "../../../../types";

import styles from "./index.module.scss";

type PropsType = InputPropsType & {
  searchPlaceholder?: string;
  searchSize?: "S" | "M" | "L";
  options: Array<Option>;
  showCheckAll?: boolean;
  narrowList?: boolean;
  wideList?: boolean;
  isValueTypeNumber?: boolean;
};

const MinSearchBySize = {
  S: 9,
  M: 17,
  L: 33
};

const CheckboxGroup = ({
  fieldName,
  label,
  info,
  infoStatus,
  searchPlaceholder,
  searchSize = "S",
  options,
  showCheckAll = false,
  narrowList = false,
  disabled = false,
  wideList = false,
  warningMessage = "",
  isValueTypeNumber = false,
  showField = true,
  validate,
  customOnChange,
  ...props
}: PropsType): React.ReactElement => {
  const { searchValue, filteredRows, onSearchChange, onSearchClear } = useSearchFilter<Option>(
    options,
    (search: string, option: Option) => {
      return !!option.label.toLowerCase().includes(search.toLowerCase());
    }
  );

  const { fieldState, fieldApi } = useField({
    ...props,
    name: fieldName,
    validate
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const value = fieldState.value as any;
  const error = fieldState.error as string | undefined;
  const { setValue, setTouched, validate: validateInput } = fieldApi;

  const MIN_SEARCHABLE = MinSearchBySize[searchSize];

  const showSearch = options?.length >= MIN_SEARCHABLE;

  const updateFormState = (newValue: Array<string | number>) => {
    setValue(newValue);
    setTouched(true);
    validateInput();
  };

  const onChangeHandler = (updatedValues: (string | number)[]): void => {
    updateFormState(updatedValues);

    if (customOnChange) {
      customOnChange({ [fieldName]: updatedValues });
    }
  };

  return (
    <BaseInput
      fieldName={fieldName}
      error={error}
      label={label}
      info={info}
      showField={showField}
      infoStatus={infoStatus}
      warningMessage={warningMessage}
    >
      {showSearch && (
        <Search
          inputClassName={styles.CheckboxGroupSearch}
          placeholder={searchPlaceholder}
          onChange={onSearchChange}
          onClear={onSearchClear}
        />
      )}
      <CheckboxGroupBase
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        fieldName={fieldName}
        initialValue={value}
        searchSize={searchSize}
        options={options}
        showCheckAll={showCheckAll}
        narrowList={narrowList}
        wideList={wideList}
        disabled={disabled}
        isValueTypeNumber={isValueTypeNumber}
        searchValue={searchValue}
        filteredRows={filteredRows}
        onChange={onChangeHandler}
      />
    </BaseInput>
  );
};

export default CheckboxGroup;
