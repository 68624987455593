import React from "react";

type PropsType = {
  size?: number;
  color?: string;
};

const DropdownArrow = ({ size = 24, color }: PropsType) => {
  return (
    <svg
      width={`${size / 2}px`}
      height={`${size}px`}
      viewBox="0 0 12 24"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.79997 15.6181C6.74311 15.6905 6.67577 15.7548 6.59997 15.809C6.15814 16.1253 5.53134 16.0399 5.19998 15.6181L1.2 10.5273C1.07018 10.362 1 10.1611 1 9.95454C1 9.42736 1.44771 9 1.99999 9H9.99995C10.2163 9 10.4268 9.06699 10.5999 9.19091C11.0418 9.50721 11.1313 10.1055 10.7999 10.5273L6.79997 15.6181Z"
        fill={color}
      />
    </svg>
  );
};

export default DropdownArrow;
