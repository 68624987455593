import React from "react";

type PropsType = {
  size?: number;
  color?: string;
};

const FileText = ({ size = 22, color }: PropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size * (18 / 22)}px`}
      viewBox="0 0 18 22"
      height={`${size}px`}
      fill="none"
    >
      <path
        fill="#1D2029"
        d="M3 22a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h8a1 1 0 0 1 .574.181l.008.006c.043.03.084.063.122.1l6 6c.037.038.07.079.1.122l.007.009a1 1 0 0 1 .189.557V19a3 3 0 0 1-3 3H3ZM2 3v16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V8h-5a1 1 0 0 1-1-1V2H3a1 1 0 0 0-1 1Zm10 3h2.587L12 3.414V6ZM5 17a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2H5Zm0-4a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2H5Zm0-4a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2H5Z"
      />
    </svg>
  );
};

export default FileText;
