import { useState } from "react";

import useDeepMemo from "./useDeepMemo";

const useSearchFilter = <T>(rows: Array<T>, filterBy?: (search: string, option: T) => boolean) => {
  const [searchValue, setSearchValue] = useState("");

  const onSearchChange = (search: string) => {
    setSearchValue(search);
  };
  const onSearchClear = () => {
    setSearchValue("");
  };

  const filteredRows = useDeepMemo(() => {
    if (!filterBy) return rows;

    return rows?.filter((row) => {
      return filterBy(searchValue, row);
    });
  }, [searchValue, rows, filterBy]);

  return { searchValue, filteredRows, onSearchChange, onSearchClear };
};

export default useSearchFilter;
