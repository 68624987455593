import React from "react";

import { connect } from "react-redux";
import Heading from "../../../../ui/Heading";
import Text from "../../../../ui/Text";

import {
  Reason,
  ReduxStateType,
  TouchpointSettingRangeTime,
  TouchpointSettingRangeTimeType
} from "../../../../../types";

import styles from "./restrictTouchpointSummary.module.scss";
import { generateReasonLabel } from "../../../../../utils/generateReasonLabel";

type PropsType = {
  heading?: string;
  exclusionHours?: number | undefined;
  exclusionHoursOption?: string;
  exclusionHoursUnit?: string;
  excludesApptsWhen?: string;
  settings?: {
    exclude?: {
      patientHasOtherAppt?: {
        active: boolean;
        rangeStart: TouchpointSettingRangeTime;
        rangeEnd: TouchpointSettingRangeTime;
      };
    };
  };
  reasonIds?: string[];
  reasons?: Reason[];
};

const touchpointSettingRangeTimeTypeOptions = [
  { label: "Before touchpoint", value: TouchpointSettingRangeTimeType.BEFORE_TOUCHPOINT },
  { label: "After touchpoint", value: TouchpointSettingRangeTimeType.AFTER_TOUCHPOINT }
];

const RestrictTouchpointSummary = ({
  heading,
  exclusionHoursOption,
  exclusionHours,
  exclusionHoursUnit,
  excludesApptsWhen,
  settings,
  reasonIds,
  reasons
}: PropsType): JSX.Element | null => {
  const hasExclusionHours = !!exclusionHours;
  const hasExcludesAppointments = !!excludesApptsWhen;

  if (hasExclusionHours)
    return (
      <div className={styles.SummaryWrapper}>
        <Heading size="META">{heading || "Preview Restrict Touchpoint"}</Heading>
        <div className={styles.RestrictTouchpointGridRow}>
          <Text>Restrict When:</Text>
          <Text>
            {`${exclusionHoursOption} ${exclusionHours || ""} ${exclusionHoursUnit || ""}`}
          </Text>
        </div>
      </div>
    );

  if (hasExcludesAppointments)
    return (
      <div className={styles.SummaryWrapper}>
        <Heading size="META">{heading || "Preview Restrict Touchpoint"}</Heading>
        <div className={styles.RestrictTouchpointGridRow}>
          <Text>Restrict When:</Text>
          <Text>{excludesApptsWhen ?? ""}</Text>
        </div>
        <div className={styles.RestrictTouchpointGridRow}>
          <Text>Within:</Text>
          <Text>
            {settings
              ? `${
                  settings?.exclude?.patientHasOtherAppt?.rangeStart?.type
                    ? touchpointSettingRangeTimeTypeOptions?.find((option) => {
                        return (
                          option.value === settings.exclude?.patientHasOtherAppt?.rangeStart.type
                        );
                      })?.label
                    : ""
                } ${settings?.exclude?.patientHasOtherAppt?.rangeStart?.value || 0} ${
                  settings?.exclude?.patientHasOtherAppt?.rangeStart?.unit?.charAt(0) || ""
                } to ${
                  settings?.exclude?.patientHasOtherAppt?.rangeEnd?.type
                    ? touchpointSettingRangeTimeTypeOptions?.find((option) => {
                        return (
                          option.value === settings.exclude?.patientHasOtherAppt?.rangeEnd.type
                        );
                      })?.label
                    : ""
                } ${settings?.exclude?.patientHasOtherAppt?.rangeEnd?.value || 0} ${
                  settings?.exclude?.patientHasOtherAppt?.rangeEnd?.unit?.charAt(0) || ""
                }`
              : ""}
          </Text>
        </div>
        <div className={styles.RestrictTouchpointGridRow}>
          <Text>
            Reasons
            {reasonIds && reasonIds.length > 0 ? ` (${reasonIds.length})` : ""}:
          </Text>
          <Text>
            {reasonIds && reasonIds.length > 0
              ? reasons
                  ?.filter((reason) => {
                    return reasonIds?.includes(reason.id.toString());
                  })
                  .map((reason) => {
                    return generateReasonLabel(reason);
                  })
                  .join(", ")
              : "Any"}
          </Text>
        </div>
      </div>
    );

  return null;
};

const mapStateToProps = ({ reasons }: ReduxStateType) => {
  return {
    reasons: reasons.data
  };
};

export default connect(mapStateToProps, null)(RestrictTouchpointSummary);
