import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";

import Text from "../../../../ui/Text";
import { Form } from "../../../../ui/Input";

import {
  fetchMessageTemplates as fetchMessageTemplatesAction,
  getAvailableChats as getAvailableChatsAction,
  GetAvailableChatsData,
  sendDirectMessage as sendDirectMessageAction,
  SendDirectMessageData,
  closeModal as closeModalAction
} from "../../../../../actions";

import { MessageTopics, DirectMessageModes, FEATURES } from "../../../../../constants";
import {
  AppointmentConversation,
  Chat,
  ReduxStateType,
  MessageTemplate
} from "../../../../../types";

import filterDirectMessageConversations from "../../../../../utils/filterDirectMessageConversations";
import FormContent from "./BasicDirectMessageFormContent";

export type BasicDirectMessageFormData = {
  chatFlowIds: Array<number>;
  messageTemplateId: string;
  payloadContent: string;
};

type PropsType = {
  loading?: boolean;
  availableChats?: Array<Chat>;
  conversations?: Array<AppointmentConversation>;
  messageTemplates?: Array<MessageTemplate>;
  fetchMessageTemplates?: () => void;
  getAvailableChats: (data: GetAvailableChatsData) => void;
  sendDirectMessage?: (data: SendDirectMessageData) => void;
  closeModal?: () => void;
  patientDisplayName: string;
  patientId: number;
  appointmentId: number;
  mobilePhone: string | null;
  emailAddress: string;
  organizationFeatures: string[];
};

const initialFormState: BasicDirectMessageFormData = {
  chatFlowIds: [],
  messageTemplateId: "",
  payloadContent: ""
};

const templateTopicFilter = (topic: string) => (template: MessageTemplate) => {
  return template.topic === topic;
};

const BasicDirectMessageForm = ({
  loading,
  closeModal,
  availableChats,
  conversations,
  messageTemplates,
  fetchMessageTemplates,
  getAvailableChats,
  sendDirectMessage,
  patientDisplayName,
  patientId,
  appointmentId,
  mobilePhone,
  emailAddress,
  organizationFeatures
}: PropsType) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [textMessageCount, setTextMessageCount] = useState(0);
  const [showWarning, setShowWarning] = useState(false);

  const updateError = () => {
    setErrorMessage("");
  };

  const updateTextMessageCount = (payloadContent: string) => {
    const charCount = payloadContent.length;
    const textMessageCount = Math.ceil(charCount / 160);
    setTextMessageCount(textMessageCount);
  };

  // Fetch messages on first load
  useEffect(() => {
    if (fetchMessageTemplates) fetchMessageTemplates();
    if (getAvailableChats) getAvailableChats({ includeMikataChats: false });
  }, [patientDisplayName]);

  const onSend = (formData: BasicDirectMessageFormData) => {
    const { chatFlowIds } = formData;
    const payloadContent = formData.payloadContent.trim();
    if (!payloadContent) {
      setErrorMessage("You must select an existing direct message template or write your message.");
    } else if (sendDirectMessage) {
      sendDirectMessage({
        mode: DirectMessageModes.BASIC,
        patientId,
        appointmentId,
        chatFlowIds,
        payloadContent,
        mobilePhone,
        emailAddress
      });
    }
  };

  const formDisabled = loading;

  const regularConversations = useMemo(
    () => (conversations && filterDirectMessageConversations(conversations, false)) || [],
    [conversations]
  );

  const chatOptions = regularConversations
    ? regularConversations.map((conversation) => {
        return {
          label: `${conversation.chatFlowTitle}`,
          value: conversation.chatFlowId
        };
      })
    : [];
  const chatOptionIds = chatOptions.map((option) => option.value);

  availableChats?.map((chat) => {
    if (!chatOptionIds.includes(chat.id)) {
      chatOptions.push({
        label: `${chat.title}${chat.version ? ` (${chat.version})` : ""}`,
        value: chat.id
      });
    }
  });

  const messageTemplateOptions = messageTemplates
    ? messageTemplates
        .filter(templateTopicFilter(MessageTopics.DIRECT_MESSAGE))
        .map((messageTemplate) => ({
          label: messageTemplate.isDefault
            ? `Default: ${messageTemplate.displayName} (${messageTemplate.id})`
            : `${messageTemplate.displayName} (${messageTemplate.id})`,
          value: messageTemplate.id.toString()
        }))
    : [];

  messageTemplateOptions.unshift({
    label: "None",
    value: "none"
  });

  const hasDirectMessagingFeature = organizationFeatures?.includes(
    FEATURES.DIRECT_PATIENT_MESSAGES.value
  );
  if (!hasDirectMessagingFeature) {
    return (
      <Text>
        Hi! If you are interested in learning more about our on-demand messaging feature, please
        email your clinic advisor at demo@mikatahealth.com.
      </Text>
    );
  }

  return (
    <Form
      onSubmit={(formState) => onSend(formState.values as BasicDirectMessageFormData)}
      initialValues={initialFormState}
      id="basicMessageForm"
    >
      <FormContent
        messageTemplates={messageTemplates}
        messageTemplateOptions={messageTemplateOptions}
        chatOptions={chatOptions}
        errorMessage={errorMessage}
        updateTextMessageCount={updateTextMessageCount}
        setShowWarning={setShowWarning}
        formDisabled={formDisabled}
        textMessageCount={textMessageCount}
        showWarning={showWarning}
        updateError={updateError}
        patientDisplayName={patientDisplayName}
        closeModal={closeModal}
      />
    </Form>
  );
};

const mapStateToProps = ({
  appointments,
  chats,
  messageTemplates,
  messages,
  organizationData
}: ReduxStateType) => {
  return {
    availableChats: chats.availableChats,
    conversations: appointments.details.data?.conversations,
    messageTemplates: messageTemplates.data,
    loading: messages.sendDirectMessageLoading,
    organizationFeatures: organizationData.organizationData
      ? organizationData.organizationData.features
      : []
  };
};

export default connect(mapStateToProps, {
  fetchMessageTemplates: fetchMessageTemplatesAction,
  getAvailableChats: getAvailableChatsAction,
  sendDirectMessage: sendDirectMessageAction,
  closeModal: closeModalAction
})(BasicDirectMessageForm);
