import React, { ElementType, ReactNode } from "react";
import cx from "classnames";

import styles from "./index.module.scss";

type PropsType = {
  children: ReactNode;
  component?: ElementType;
  id?: string;
  className?: string;
  size?: "M" | "S" | "XS";
  align?: "left" | "center" | "right";
  darkMode?: boolean;
  bold?: boolean;
  onClick?: () => void;
};

const Text = ({
  className,
  component = "p",
  size = "M",
  align = "left",
  darkMode = false,
  bold,
  ...props
}: PropsType) => {
  const Component = component;

  return (
    <Component
      className={cx(
        styles.Text,
        {
          [styles.Medium]: size === "M",
          [styles.Small]: size === "S",
          [styles.XSmall]: size === "XS",
          [styles.Bold]: bold,
          [styles.Left]: align === "left",
          [styles.Center]: align === "center",
          [styles.Right]: align === "right",
          [styles.DarkMode]: darkMode
        },
        className
      )}
      // Enable prop spreading on this generalized element type
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default Text;
