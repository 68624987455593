import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import cx from "classnames";

import { debounce } from "lodash";
import Search from "../../../../ui/Search";
import { FilterSelectInput } from "../../../../ui/Input";
import Text from "../../../../ui/Text";
import Filter from "../../../../ui/Icon/Filter";
import ResponsiveHide from "../../../../ui/Responsive/ResponsiveHide";
import SelectInputBase from "../../../../ui/Input/SelectInput/SelectInputBase";
import AdvancedInboxFilters from "./AdvancedInboxFilters";
import { selectStyles } from "../../../../ui/Input/SelectInput/styles";
import Button from "../../../../ui/Button";

import {
  Location,
  Option,
  Practitioner,
  Reason,
  Staff,
  TaskFilterOptions
} from "../../../../../types";
import { ALL, BookingModes, InboxModes, TaskTypes } from "../../../../../constants";

import styles from "../index.module.scss";

type PropsType = {
  filter: TaskFilterOptions;
  handleFilterChange: (filter: TaskFilterOptions) => void;
  staff: Array<Staff>;
  practitioners: Array<Practitioner>;
  locations: Array<Location>;
  reasons: Array<Reason>;
};

const taskTypeOptions = [
  {
    label: "Booking",
    value: TaskTypes.APPOINTMENT_REQUEST as string
  }
];

const InboxFilters = ({
  filter,
  handleFilterChange,
  staff,
  practitioners,
  locations,
  reasons
}: PropsType) => {
  const [advancedFiltersOpen, setAdvancedFiltersOpen] = useState<boolean>(false);
  const elementRef = useRef<HTMLDivElement>(null);

  const onSearchClear = () => {
    handleFilterChange({ ...filter, searchValue: undefined });
  };

  const onStaffUsersChange = (selectedUserIds: number[] | string[]) => {
    handleFilterChange({ ...filter, assignedStaffUserIds: selectedUserIds as number[] });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onTaskTypeChange = (inputValue: any) => {
    const selectedType = inputValue;
    handleFilterChange({ ...filter, taskType: selectedType });
  };

  const onFilterClear = () => {
    handleFilterChange({
      mode: InboxModes.TODO,
      taskType: TaskTypes.APPOINTMENT_REQUEST,
      assignedStaffUserIds: [],
      searchValue: undefined,
      locations: [],
      practitionerIds: [],
      reasons: [],
      requestBookingMode: [],
      taskId: undefined
    });
  };

  const staffOptions = staff
    ? [
        { label: "Unassigned", value: "Unassigned" },
        ...staff
          .filter((staffUser) => {
            return !!staffUser.active;
          })
          .map((staffUser) => ({
            label: `${staffUser.firstName} ${staffUser.lastName}`,
            value: staffUser.userId.toString()
          }))
          .sort((a, b) => {
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;
            return 0;
          })
      ]
    : [];

  const bookingTypeOptions: Option[] = [
    { label: "See all", value: ALL },
    { label: "Basic request", value: BookingModes.REQUEST },
    { label: "Request with times", value: BookingModes.AVAILABLE_TIMES },
    { label: "Autobooked", value: BookingModes.AUTO_BOOK }
  ];
  const locationOptions = locations
    ?.map((location) => ({
      label: location.displayName || location.fullName,
      value: location.displayName || location.fullName
    }))
    .filter((option) => !!option.label && !!option.value)
    .sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
  const reasonOptions = reasons
    ?.map((reason) => ({ label: reason.value, value: reason.value }))
    .filter((option) => !!option.label && !!option.value)
    .sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
  const practitionerOptions = practitioners
    ?.map((practitioner) => ({
      label: practitioner.displayName || practitioner.fullName,
      value: practitioner.id
    }))
    .filter((option) => !!option.label && !!option.value)
    .sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });

  const toggle = () => {
    setAdvancedFiltersOpen((filtersOpen) => !filtersOpen);
  };

  const offDropdownClick = (event: MouseEvent | KeyboardEvent) => {
    const dropdownEl = elementRef.current;
    const isOutsideClick = dropdownEl && !dropdownEl.contains(event.target as Node);

    if (isOutsideClick || (event as KeyboardEvent).key === "Escape") {
      toggle();
      document.removeEventListener("mouseup", offDropdownClick, false);
      document.removeEventListener("keyup", offDropdownClick, false);
    }
  };

  useEffect(() => {
    if (advancedFiltersOpen) {
      // add when mounted
      document.addEventListener("mouseup", offDropdownClick);
      document.addEventListener("keyup", offDropdownClick);
      return () => {
        document.removeEventListener("mouseup", offDropdownClick, false);
        document.removeEventListener("keyup", offDropdownClick, false);
      };
    }

    document.removeEventListener("mouseup", offDropdownClick, false);
    document.removeEventListener("keyup", offDropdownClick, false);

    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mouseup", offDropdownClick, false);
      document.removeEventListener("keyup", offDropdownClick, false);
    };
  }, [advancedFiltersOpen]);
  // debouncing for search input
  const debouncedFilterChange = useCallback(debounce(handleFilterChange, 650), []);
  const activeFilterCount = useMemo(() => {
    let count = 0;
    count += filter?.requestBookingMode?.filter((item) => item !== ALL)?.length || 0;
    count += filter?.reasons?.length || 0;
    count += filter?.locations?.length || 0;
    count += filter?.practitionerIds?.length || 0;
    return count;
  }, [filter]);

  return (
    <>
      <div className={styles.FilterHeaderRow}>
        <div className={styles.Filters}>
          <ResponsiveHide hideOnTablet>
            <div className={styles.FilterChild}>
              <SelectInputBase
                options={taskTypeOptions}
                fieldName="type"
                initialValue={TaskTypes.APPOINTMENT_REQUEST}
                onChange={onTaskTypeChange}
                styles={selectStyles({ hasError: false })} // todo - check for error scenario
                disabled
              />
            </div>
          </ResponsiveHide>
          <div className={styles.FilterChild}>
            <FilterSelectInput
              selectedValues={filter.assignedStaffUserIds || []}
              onChange={onStaffUsersChange}
              options={staffOptions || []}
              placeholder="All assignees"
              allSelectedLabel="All assignees"
              fieldName="staffSelection"
            />
          </div>
          <div className={styles.FilterChild}>
            <Search
              id="searchValue"
              placeholder="Search"
              classNames={styles.Search}
              onChange={(searchValue) => {
                debouncedFilterChange({ ...filter, searchValue: searchValue || undefined });
              }}
              onClear={onSearchClear}
              onEnter={(searchValue: string) => {
                handleFilterChange({ ...filter, searchValue: searchValue || undefined });
              }}
              initialValue={filter.searchValue}
            />
          </div>
          <button
            id="filters-dropdown"
            type="button"
            className={cx(styles.MoreFilters, {
              [styles.MoreFiltersActive]: activeFilterCount > 0,
              [styles.MoreFiltersOpen]: advancedFiltersOpen
            })}
            onClick={() => {
              setAdvancedFiltersOpen(true);
            }}
          >
            <Filter size={20} />
            <Text
              component="span"
              size="M"
              bold={activeFilterCount > 0}
              className={cx(styles.FiltersLabel, {
                [styles.FiltersLabelActive]: activeFilterCount > 0
              })}
            >
              {activeFilterCount > 0 ? `(${activeFilterCount}) Filters` : "Filters"}
            </Text>
          </button>
          <Button
            id="clear-all"
            type="button"
            className={styles.InlineButton}
            onClick={onFilterClear}
            inline
          >
            <Text component="span" size="S" bold className={styles.ClearFiltersLabel}>
              Clear
            </Text>
          </Button>
        </div>
      </div>
      <div
        ref={elementRef}
        className={cx(styles.AdvanceFilter, { [styles.AdvanceFilterOpen]: advancedFiltersOpen })}
      >
        <AdvancedInboxFilters
          filters={{
            requestBookingMode: filter.requestBookingMode || [],
            locations: filter.locations || [],
            reasons: filter.reasons || [],
            practitionerIds: filter.practitionerIds || []
          }}
          bookingTypeOptions={bookingTypeOptions}
          onFiltersChange={(updatedFilters) => handleFilterChange({ ...filter, ...updatedFilters })}
          locationOptions={locationOptions}
          providerOptions={practitionerOptions}
          reasonOptions={reasonOptions}
          isFilterOpen={advancedFiltersOpen}
          closeFilterDropdown={() => setAdvancedFiltersOpen(false)}
        />
      </div>
    </>
  );
};

export default InboxFilters;
