import React from "react";

import { useFormApi } from "informed";
import Button from "../../../../ui/Button";
import { CheckboxGroup } from "../../../../ui/Input";
import Heading from "../../../../ui/Heading";

import { Option } from "../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  locationOptions: Array<Option>;
};
const LocationSelectorForm = ({ locationOptions }: PropsType) => {
  const formApi = useFormApi();
  return (
    <>
      <Heading size="META">Location(s)</Heading>
      <CheckboxGroup fieldName="locations" options={locationOptions} />
      <div className={styles.Divider} />
      <div className={styles.ButtonWrapper}>
        <Button
          id="clear-filters"
          type="button"
          inline
          onClick={() => {
            formApi.setValue("locations", []);
          }}
        >
          Clear all
        </Button>

        <Button type="submit">Apply</Button>
      </div>
    </>
  );
};

export default LocationSelectorForm;
