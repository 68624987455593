import React from "react";
import { useField } from "informed";

import BaseInput from "../BaseInput";
import TextInputBase from "./TextInputBase";

import { InputPropsType } from "../../../../types";

type PropsType = InputPropsType & {
  type?: string;
  autoComplete?: string;
  inputIcon?: React.ReactElement;
  initialValue?: string | number;
  id?: string;
  autoFocus?: boolean;
};

const TextInput = ({
  id = "",
  fieldName,
  label,
  info,
  infoStatus,
  placeholder,
  showField = true,
  disabled = false,
  validate,
  inputIcon,
  initialValue,
  warningMessage = "",
  type,
  autoFocus,
  customOnChange,
  ...props
}: PropsType) => {
  const { fieldState, fieldApi } = useField({
    ...props,
    name: fieldName,
    validate,
    initialValue
  });
  const value = fieldState.value as string | number | undefined;
  const error = fieldState.error as string | undefined;

  const { setValue, setTouched, validate: validateInput } = fieldApi;

  const onChangeHandler = (updatedValue?: string | number): void => {
    setValue(updatedValue);
    setTouched(true);
    validateInput();

    if (customOnChange) {
      customOnChange({ [fieldName]: updatedValue });
    }
  };

  return (
    <BaseInput
      fieldName={fieldName}
      error={error}
      label={label}
      showField={showField}
      info={info}
      infoStatus={infoStatus}
      warningMessage={warningMessage}
    >
      <TextInputBase
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        fieldName={fieldName}
        id={id}
        label={label}
        error={error}
        type={type}
        placeholder={placeholder}
        initialValue={!value && value !== 0 ? "" : value}
        disabled={disabled}
        setTouched={setTouched}
        onChange={onChangeHandler}
        inputIcon={inputIcon}
        autoFocus={autoFocus}
      />
    </BaseInput>
  );
};

export default TextInput;
