import { InsightNoteContentCategory } from "../../../../../../types";

export type EditorInsightItem = {
  id: number;
  text: string;
  category: InsightNoteContentCategory;
  shouldPromptFeedback?: boolean;
  createdAt: Date;
  language?: string;
};
export type EditorInsights = EditorInsightItem[];

export enum NoteEditorModes {
  EDIT = "edit",
  VIEW = "view"
}

export type HashtagObject = {
  blockKey: string;
  startOffset: number;
};

export enum MikaAnchorStatus {
  PENDING = "pending",
  INJECTED = "injected",
  UNKNOWN = "unknown"
}

export type MikaAnchorEntityData = {
  status: MikaAnchorStatus;
  anchorName: string;
  noteId: number;
  anchorTitle?: string | { loading: string; default: string };
  insightIdsInjected?: number[];
};

export enum MikaContentStatus {
  PROVISIONAL = "provisional",
  ACCEPTED = "accepted",
  UNKNOWN = "unknown"
}

export type MikaContentEntityData = {
  status: MikaContentStatus;
  insightId?: number;
  insightAnchorName?: string;
};

export type MikaAnchorKeyMap = { [anchorName: string]: string };
