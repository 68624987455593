import { colors } from "../../../../../../../../../css/_colors";
import { ChatCardTypes } from "../../../../../../../../../constants";

export const getPrimaryCardColour = (cardType: string) => {
  switch (cardType) {
    case ChatCardTypes.LANDING:
    case ChatCardTypes.SUB_FLOW_START:
      return "#FAFEE9";

    case ChatCardTypes.CHAT_FLOW:
      return "#F9ECFF";
    // Special/Important Nodes
    case ChatCardTypes.BOOKING:
    case ChatCardTypes.INSTRUCTIONS_FROM_REASONS:
    case ChatCardTypes.INSTRUCTION_REASONS:
    case ChatCardTypes.PATIENT_VERIFICATION:
    case ChatCardTypes.CHECK_IN:
    case ChatCardTypes.SCRIBE_VISIT:
      return "#E5E5FF";
    // Input Nodes
    case ChatCardTypes.ADDRESS_INPUT:
    case ChatCardTypes.DATE_INPUT:
    case ChatCardTypes.EMAIL_INPUT:
    case ChatCardTypes.IMAGE_SELECT:
    case ChatCardTypes.MULTI_SELECT:
    case ChatCardTypes.PHONE_INPUT:
    case ChatCardTypes.PHOTO_UPLOAD:
    case ChatCardTypes.SCALE_INPUT:
    case ChatCardTypes.SCALE_NUMBERS:
    case ChatCardTypes.SINGLE_SELECT:
    case ChatCardTypes.TEXT_INPUT:
    case ChatCardTypes.UNITS_INPUT:
      return "#F1F7FD";
    case ChatCardTypes.DEAD_END:
    case ChatCardTypes.END:
      return colors.athensGray;
    case ChatCardTypes.FLAGGED_END:
      return "#FFE9EB";
    default:
      return "white";
  }
};
