/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from "react";
import cx from "classnames";

import Button from "../../Button";
import Heading from "../../Heading";
import { Close } from "../../Icon";
import Text from "../../Text";

import styles from "./index.module.scss";

interface PropsType {
  isOpen: boolean;
  closeMobileDrawer: () => void;
  header?: string;
  subheader?: string;
  children?: React.ReactNode;
}

const ResponsiveMobileDrawer = ({
  children,
  header,
  subheader,
  isOpen,
  closeMobileDrawer
}: PropsType) => {
  return (
    <>
      <div
        className={cx(styles.Background, { [styles.BackgroundHide]: !isOpen })}
        onClick={closeMobileDrawer}
        tabIndex={-1}
      />
      <div className={cx(styles.Container, { [styles.ContainerHide]: !isOpen })}>
        <div className={styles.Header}>
          <Button className={styles.CloseButton} type="button" inline onClick={closeMobileDrawer}>
            <Close />
          </Button>
          <div className={styles.HeaderSection}>
            <div className={styles.HeaderText}>
              <Text bold size="S">
                {header || ""}
              </Text>
            </div>
            {subheader && <Heading size="META">{subheader}</Heading>}
          </div>
        </div>
        <div className={styles.Contents}>{children}</div>
      </div>
    </>
  );
};

export default ResponsiveMobileDrawer;
