import React from "react";

import Heading from "../../../Heading";
import Text from "../../../Text";
import Image from "../../../Image";
import Tooltip from "../../../Tooltip";
import { Info } from "../../../Icon";

import styles from "./StartScribeSessionOne.module.scss";

const StartScribeSessionOne = (): React.ReactElement => {
  return (
    <div>
      <Heading size="M" component="h3" darkMode>
        Activating Mika
      </Heading>
      <br />
      <Text size="S" component="p" darkMode>
        Role-play an appointment with a buddy or scan the QR code with your phone to play a demo
        audio file on your phone.&nbsp;
        <div className={styles.AcknowledgementTooltip}>
          <Tooltip
            size="S"
            className={styles.ToolTipClass}
            contentClassName={styles.ContentClassName}
            icon={<Info size={16} />}
            position="bottomLeft"
          >
            <div>
              Acknowledgement: The demo audio file is a simulated medical interview from: Fareez,
              F., Parikh, T., Wavell, C. et al. A dataset of simulated patient-physician medical
              interviews with a focus on respiratory cases. Sci Data 9, 313 (2022).
              https://doi.org/10.1038/s41597-022-01423-1 License:
              https://creativecommons.org/licenses/by/4.0/
            </div>
          </Tooltip>
        </div>
      </Text>

      <br />
      <Image src="/img/announcements/ActivatingMikaTutorial.png" />
    </div>
  );
};

export default StartScribeSessionOne;
