import {
  AutomatedMessageConfiguration,
  MessageSchedule,
  MessageScheduleEvent,
  MessageScheduleTriggerUnit
} from "../../../../../types";

export const getTouchpointDescription = (messageSchedule: MessageSchedule | null) => {
  const eventType = messageSchedule?.event;
  const triggerValue = messageSchedule?.triggerValue;
  const triggerUnit = messageSchedule?.triggerUnit;

  switch (eventType) {
    case MessageScheduleEvent.BOOKED: {
      return `Upon appointment booking`;
    }
    case MessageScheduleEvent.CANCELLED: {
      return `Upon appointment cancelation`;
    }
    case MessageScheduleEvent.COMPLETE: {
      return `Upon completion event`;
    }
    case MessageScheduleEvent.START: {
      const displayTriggerValue = triggerValue ? Math.abs(triggerValue) : "";

      return `${displayTriggerValue} ${triggerUnit} ${
        triggerValue && triggerValue < 0 ? "before appointment" : "after appointment"
      }`;
    }
    default: {
      return eventType || "";
    }
  }
};

export const getTouchpointLabel = (
  touchpoint: AutomatedMessageConfiguration & { messageSchedule: MessageSchedule | null }
) => {
  const eventType = touchpoint?.messageSchedule?.event;
  const triggerValue = touchpoint?.messageSchedule?.triggerValue;
  const triggerUnit = touchpoint?.messageSchedule?.triggerUnit;
  switch (eventType) {
    case MessageScheduleEvent.BOOKED: {
      return `Book`;
    }
    case MessageScheduleEvent.CANCELLED: {
      return `Cancel`;
    }
    case MessageScheduleEvent.COMPLETE: {
      return `Complete`;
    }
    case MessageScheduleEvent.START: {
      const displayTriggerValue = triggerValue ? Math.abs(triggerValue) : "";
      const displayTriggerUnit =
        triggerUnit === MessageScheduleTriggerUnit.MINUTES ? "min" : triggerUnit;

      return `${displayTriggerValue} ${displayTriggerUnit} `;
    }
    default: {
      return eventType || "";
    }
  }
};

export const getTriggerUnitToMinutesFactor = (triggerUnit: MessageScheduleTriggerUnit) => {
  switch (triggerUnit) {
    case MessageScheduleTriggerUnit.MINUTES:
      return 1;
    case MessageScheduleTriggerUnit.HOURS:
      return 60;
    case MessageScheduleTriggerUnit.DAYS:
      return 60 * 24;
    default:
      return 1;
  }
};

export const orderByAppointmentOffset = (a: MessageSchedule, b: MessageSchedule) => {
  const aTriggerValueFactor = getTriggerUnitToMinutesFactor(a.triggerUnit);
  const aOffsetMinutes = a.triggerValue * aTriggerValueFactor;
  const bTriggerValueFactor = getTriggerUnitToMinutesFactor(b.triggerUnit);
  const bOffsetMinutes = b.triggerValue * bTriggerValueFactor;

  if (aOffsetMinutes > bOffsetMinutes) return 1;
  if (aOffsetMinutes < bOffsetMinutes) return -1;
  return 0;
};
