import React, { useRef } from "react";
import cx from "classnames";

import Text from "../../../../../../ui/Text";

import styles from "./index.module.scss";

export type ListItem = { label: string; icon?: React.ReactNode; onClick: () => void };

type PropsType = {
  list: ListItem[];
  isOpen: boolean;
  customStyle: React.CSSProperties;
};

const MenuList = ({ list, isOpen, customStyle }: PropsType) => {
  const elementRef = useRef<HTMLDivElement>(null);

  const handleOnClick = (item: ListItem) => {
    item.onClick();
  };

  return (
    <div style={customStyle}>
      <div ref={elementRef} className={cx(styles.MenuListWrapper)}>
        <div
          className={cx(styles.MenuList, {
            [styles.MenuListOpen]: isOpen
          })}
        >
          {list.map((item) => (
            <button
              type="button"
              className={styles.MenuListItem}
              key={item.label}
              onClick={() => handleOnClick(item)}
              onKeyPress={(e) => {
                if (e.nativeEvent.keyCode === 13) {
                  handleOnClick(item);
                }
              }}
            >
              <Text bold size="S" component="span" className={styles.MenuItemText}>
                {item.icon && item.icon}
                {item.label}
              </Text>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MenuList;
