import { FETCH_CAMPAIGNS, FETCH_CAMPAIGN, CREATE_CAMPAIGN } from "../constants";

import { FetchCampaignsAction, FetchCampaignAction, CreateCampaignAction } from "../actions";
import { Campaign, ActionStatus } from "../types";

export type CampaignsReduxState = {
  campaigns: Array<Campaign> | null;
  loading: boolean;
  campaign: Campaign | null;
  createLoading: boolean;
};
export type CampaignsAction = FetchCampaignsAction | FetchCampaignAction | CreateCampaignAction;

const initialCampaignsState: CampaignsReduxState = {
  campaigns: null,
  loading: false,
  campaign: null,
  createLoading: false
};

export const campaignsReducer = (state = initialCampaignsState, action: CampaignsAction) => {
  const { type } = action;

  switch (type) {
    case FETCH_CAMPAIGNS: {
      const { status, payload } = action as FetchCampaignsAction;
      return {
        ...state,
        campaigns: payload ? payload.data : state.campaigns,
        loading: status === ActionStatus.loading
      };
    }
    case FETCH_CAMPAIGN: {
      const { payload } = action as FetchCampaignAction;
      const campaignData = payload ? { ...payload.data } : null;
      return {
        ...state,
        campaign: campaignData
      };
    }
    case CREATE_CAMPAIGN: {
      const { status } = action as CreateCampaignAction;
      return {
        ...state,
        createLoading: status === ActionStatus.loading
      };
    }

    default:
      return state;
  }
};
