import React from "react";

type PropsType = {
  size?: number;
};

const Message = ({ size = 20 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5.5C2 4.11929 3.11929 3 4.5 3H19.5C20.8807 3 22 4.11929 22 5.5V16.5C22 17.8807 20.8807 19 19.5 19H7.5C7.39181 19 7.28655 19.0351 7.2 19.1L4.4 21.2C3.41115 21.9416 2 21.2361 2 20V18V5.5ZM4.5 5C4.22386 5 4 5.22386 4 5.5V18V19L6 17.5C6.43274 17.1754 6.95907 17 7.5 17H19.5C19.7761 17 20 16.7761 20 16.5V5.5C20 5.22386 19.7761 5 19.5 5H4.5ZM8 9.5C8 8.94772 8.44772 8.5 9 8.5H15C15.5523 8.5 16 8.94772 16 9.5C16 10.0523 15.5523 10.5 15 10.5H9C8.44772 10.5 8 10.0523 8 9.5ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H15C15.5523 14 16 13.5523 16 13C16 12.4477 15.5523 12 15 12H9Z"
        fill="#1D2029"
      />
    </svg>
  );
};

export default Message;
