import axios from "axios";
import getToken from "../utils/getToken";

import { addNotification } from "./notifications";

import { ROOT_URL, TASKS_FETCH, TASKS_FETCH_COUNT, TASK_GET, TASKS_ASSIGN_TO } from "../constants";

import { Dispatch, ActionStatus, Task, TaskCounts } from "../types";

export type FetchTasksAction = {
  type: typeof TASKS_FETCH;
  status: ActionStatus;
  payload?: { tasks: Array<Task>; taskQueryCount: number };
};

export type TaskFindParameters = {
  organizationId: number;
  mode: string;
  currentPage?: number;
  pageSize?: number;
  sortBy?: string;
  sortByDirection?: string;
  taskType?: string;
  assignee?: number[];
  searchString?: string;
  requestBookingMode?: string[];
  locations?: string[];
  reasons?: string[];
  providers?: number[];
};

export type FetchTasksResponse = {
  status: number;
  data: { tasks: Array<Task>; taskQueryCount: number };
};

export type FetchTasksOptions = {
  silent?: boolean;
};

export const fetchTasks =
  (filter: TaskFindParameters, options?: FetchTasksOptions, onSuccess?: () => void) =>
  (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };
    const isSilentFetch = options?.silent;

    dispatch({
      type: TASKS_FETCH,
      status: isSilentFetch ? ActionStatus.silentLoading : ActionStatus.loading
    });

    return axios
      .post(`${ROOT_URL}/tasks`, filter, config)
      .then((response: FetchTasksResponse) => {
        if (onSuccess) {
          onSuccess();
        }

        return dispatch({
          type: TASKS_FETCH,
          status: ActionStatus.success,
          payload: { tasks: response.data.tasks, taskQueryCount: response.data.taskQueryCount }
        });
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to fetch tasks",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
        return dispatch({
          type: TASKS_FETCH,
          status: ActionStatus.error
        });
      });
  };

export type FetchTaskCountsAction = {
  type: typeof TASKS_FETCH_COUNT;
  status: ActionStatus;
  payload?: TaskCounts;
};

export type FetchTaskCountsResponse = {
  status: number;
  data: TaskCounts;
};

export const fetchTaskCounts = () => (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  dispatch({
    type: TASKS_FETCH_COUNT,
    status: ActionStatus.loading
  });

  return axios
    .get(`${ROOT_URL}/tasks/counts`, config)
    .then((response: FetchTaskCountsResponse) => {
      return dispatch({
        type: TASKS_FETCH_COUNT,
        status: ActionStatus.success,
        payload: response.data
      });
    })
    .catch(() => {
      dispatch(
        addNotification({
          type: "error",
          title: "Failed to fetch task counts",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: TASKS_FETCH_COUNT,
        status: ActionStatus.error
      });
    });
};

export type GetTaskAction = {
  type: typeof TASK_GET;
  status: ActionStatus;
  payload?: Task;
};

export type GetTaskResponse = {
  status: number;
  data: Task;
};

export type GetTaskOptions = {
  silent?: boolean;
};

export const getTask =
  (taskId: number, onSuccess?: (task: Task) => void, options?: GetTaskOptions) =>
  (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };
    const isSilentFetch = options?.silent;

    dispatch({
      type: TASK_GET,
      status: isSilentFetch ? ActionStatus.silentLoading : ActionStatus.loading
    });

    return axios
      .get(`${ROOT_URL}/tasks/${taskId}`, config)
      .then((response: GetTaskResponse) => {
        dispatch({
          type: TASK_GET,
          status: ActionStatus.success,
          payload: response.data
        });

        if (onSuccess) {
          const task = response.data;
          onSuccess(task);
        }
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to get task details",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
        return dispatch({
          type: TASK_GET,
          status: ActionStatus.error
        });
      });
  };

export type AssignTasksAction = {
  type: typeof TASKS_ASSIGN_TO;
  status: ActionStatus;
  payload?: { data: { taskIds?: number[]; assignedUserIds?: number[] } };
};

export type AssignTasksData = {
  taskIds?: number[];
  assignedUserIds?: number[];
};

export type AssignTasksOptions = {
  silent?: boolean;
};

export const assignTasks =
  async (data: AssignTasksData, onSuccess?: () => void, options?: AssignTasksOptions) =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: { Authorization: getToken(), "Content-Type": "application/json" }
    };
    const isSilentFetch = options?.silent;

    try {
      dispatch({
        type: TASKS_ASSIGN_TO,
        status: isSilentFetch ? ActionStatus.silentLoading : ActionStatus.loading,
        payload: { data }
      });

      const response = await axios.patch(`${ROOT_URL}/tasks/assignees`, data, config);

      dispatch({
        type: TASKS_ASSIGN_TO,
        status: ActionStatus.success,
        payload: { data: response.data }
      });

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch(
        addNotification({
          type: "error",
          title: "Failed to assign task",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: TASKS_ASSIGN_TO,
        status: ActionStatus.error
      });
    }
  };
