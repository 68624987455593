import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";

import { TextAreaInput } from "../../../../../../../../ui/Input";
import {
  BookingReason,
  ChatFlowsNodes,
  Location,
  Practitioner,
  Reason,
  ReduxStateType,
  ScheduleTag
} from "../../../../../../../../../types";

import Heading from "../../../../../../../../ui/Heading";

import BaseChatNodeForm, {
  BaseFormValues,
  CustomFormValues,
  BookingFormValues,
  DefaultCardFormValues,
  FormValues
} from "../../BaseChatNodeForm";

import {
  fetchReasons as fetchReasonsAction,
  fetchLocations as fetchLocationsAction,
  fetchPractitioners as fetchPractitionersAction,
  getChatFlowDetails as getChatFlowDetailsAction,
  fetchBookingConfiguration as fetchBookingConfigurationAction
} from "../../../../../../../../../actions";

import BookingReasonsConfig from "../../../../../../../../common/Forms/BookingReasonConfig";

import Loader from "../../../../../../../../ui/Loader";
import { UserContext } from "../../../../../../../../providers/UserProvider";
import useChatContextVariables from "../../../../../../../../../hooks/useChatContextVariables";
import Button from "../../../../../../../../ui/Button";
import { Collapse, Expand } from "../../../../../../../../ui/Icon";

import styles from "./index.module.scss";

export type BookingChatFlowsNode = ChatFlowsNodes;

type PropsType = {
  chatId: string | undefined;
  node: BookingChatFlowsNode;
  viewOnly: boolean;
  setSelectedNodeId: Dispatch<SetStateAction<number | null>>;
  schedules: ScheduleTag[] | null;
  reasons: Array<Reason>;
  practitioners: Array<Practitioner>;
  locations: Array<Location>;
  allChatNodes: Array<ChatFlowsNodes>;
  loading: boolean;
  fetchReasons: () => void;
  fetchPractitioners: () => void;
  fetchLocations: (organizationId: number) => void;
  fetchBookingConfiguration: () => void;
};

const onSaveCustomTransformer = (formValues: FormValues) => {
  const values = formValues as BaseFormValues & DefaultCardFormValues & BookingFormValues;

  const parsedValues = {
    bookingReasons: values?.bookingReasons?.map((item) => {
      const returnItem = { ...item };
      returnItem.duration = Number(returnItem.duration);
      returnItem.availabilityRangeEndWeeks = Number(returnItem.availabilityRangeEndWeeks);
      if (returnItem.selectionConditions) {
        return {
          ...returnItem,
          selectionConditions:
            typeof returnItem.selectionConditions === "string"
              ? JSON.parse(returnItem.selectionConditions)
              : returnItem.selectionConditions
        };
      }
      return returnItem;
    })
  };

  return {
    payloadContent: values.payloadContent ? values.payloadContent : "",
    payloadOptions: {
      ...values.payloadOptions,
      ...parsedValues
    }
  };
};

const BookingCard = ({
  chatId,
  node,
  viewOnly,
  setSelectedNodeId,
  schedules,
  reasons,
  locations,
  practitioners,
  loading,
  allChatNodes,
  fetchReasons,
  fetchLocations,
  fetchPractitioners,
  fetchBookingConfiguration
}: PropsType) => {
  const formInitializing = loading;
  const { organizationId } = useContext(UserContext);
  const { contextVariables } = useChatContextVariables(allChatNodes);

  const initialFormValues: DefaultCardFormValues | null = useMemo(() => {
    if (formInitializing) {
      return null;
    }

    return {
      payloadContent: node?.payloadContent || "",
      payloadOptions: node?.payloadOptions || null,
      bookingReasons: node?.payloadOptions?.bookingReasons
    };
  }, [node.id, formInitializing]);

  const [forceClose, setForceClose] = useState(false);

  useEffect(() => {
    if (organizationId) {
      fetchBookingConfiguration();
      fetchReasons();
      fetchLocations(organizationId);
      fetchPractitioners();
    }
  }, [organizationId]);

  if (!initialFormValues) {
    return <Loader screen />;
  }

  const bookingConfigFormValidator = (values: BaseFormValues & CustomFormValues) => {
    let errorMessage;

    const defaultBookingReasons = (values as BookingFormValues)?.bookingReasons
      ? (values as BookingFormValues).bookingReasons.filter(
          (bookingReason: BookingReason) => bookingReason.isDefault
        )
      : [];

    if (defaultBookingReasons.length !== 1) {
      errorMessage = `One default booking reason required. We found ${defaultBookingReasons.length} defaults`;
    }

    const invalidRequestReasons = (values as BookingFormValues)?.bookingReasons
      ? (values as BookingFormValues).bookingReasons.filter(
          (bookingReason: BookingReason) =>
            bookingReason.skipBooking &&
            (bookingReason.autoBookable || bookingReason.requiresPrimaryPractitioner)
        )
      : [];

    if (invalidRequestReasons.length) {
      errorMessage = `Request reasons cannot be auto-bookable or require primary practitioners. Invalid Request reasons: ${invalidRequestReasons.map(
        (reason: BookingReason) => reason.label
      )}`;
    }

    return errorMessage;
  };

  return (
    <BaseChatNodeForm
      chatId={chatId}
      key={node.id}
      initialValues={initialFormValues}
      onSaveCustomTransformer={onSaveCustomTransformer}
      node={node}
      viewOnly={viewOnly}
      setSelectedNodeId={setSelectedNodeId}
      validate={bookingConfigFormValidator}
    >
      <>
        <TextAreaInput
          id="chatNodePayloadContent"
          size="S"
          fieldName="payloadContent"
          label="Mikata Message"
          disabled={viewOnly}
        />
        <div className={styles.Header}>
          <Heading size="META">Booking Configuration:</Heading>

          <Button
            type="button"
            inline
            secondary
            onClick={() => {
              setForceClose(!forceClose);
            }}
          >
            {forceClose ? <Expand /> : <Collapse />}
          </Button>
        </div>

        <BookingReasonsConfig
          schedules={schedules}
          reasons={reasons}
          practitioners={practitioners}
          locations={locations}
          values={node.payloadOptions}
          forceClose={forceClose}
          setForceClose={setForceClose}
          contextVariables={contextVariables}
        />
      </>
    </BaseChatNodeForm>
  );
};

const mapStateToProps = ({
  reasons,
  locations,
  practitioners,
  appointmentRequestsInfo,
  chats
}: ReduxStateType) => {
  return {
    reasons: reasons.data,
    locations: locations.data,
    practitioners: practitioners.data,
    loading: !reasons.initialized || locations.locationsFetchLoading || practitioners.loading,
    schedules: appointmentRequestsInfo?.bookingConfiguration?.schedules || null,
    allChatNodes: chats.chatDetails.allMessageTemplates
  };
};

export default connect(mapStateToProps, {
  fetchReasons: fetchReasonsAction,
  fetchLocations: fetchLocationsAction,
  fetchPractitioners: fetchPractitionersAction,
  getChatFlowDetails: getChatFlowDetailsAction,
  fetchBookingConfiguration: fetchBookingConfigurationAction
})(BookingCard);
