import React from "react";

type PropsType = {
  size?: number;
  color?: string;
};

const Heading3 = ({ size = 24, color = "currentColor" }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M14 5a1 1 0 0 0-1 1v8H5V6a1 1 0 0 0-2 0v16a1 1 0 0 0 2 0v-6h8v6a1 1 0 0 0 2 0V6a1 1 0 0 0-1-1ZM22.08 17.05a2.6 2.6 0 0 0 1.68-2.52c0-1.82-1.53-2.85-3.29-2.85a3.11 3.11 0 0 0-3.4 3.12l1.07.08a2.12 2.12 0 0 1 2.37-2.14 1.9 1.9 0 0 1 2.15 1.92c0 1.39-1.14 2-2.75 2v.93c1.44 0 3.34.26 3.34 2.14 0 1.52-1.2 2.37-2.78 2.37s-2.85-.91-2.85-2.49l-1.09.06c0 2.26 1.69 3.49 3.92 3.49s3.91-1.22 3.91-3.33a2.68 2.68 0 0 0-2.28-2.78Z"
        fill={color}
      />
    </svg>
  );
};

export default Heading3;
