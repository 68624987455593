import React, { useEffect, useState } from "react";
import { Helmet, HelmetData } from "react-helmet-async";

type PropsType = {
  children: React.ReactNode;
};

type CardData = {
  organizationId: number;
  number: string;
  expiryMonth: string;
  expiryYear: string;
  name: string;
  cvv: string;
};

export type CreditCardTokenizationResponse = {
  creditcard: {
    token?: string;
  };
};

type BillingContextType = {
  tokenizeCard: (
    cardData: CardData,
    payInvoiceCallback: (
      code: string,
      message: string,
      response: CreditCardTokenizationResponse
    ) => void
  ) => void;
  validateCard: (
    cardData: CardData,
    validationCallback: (
      code: string,
      message: string,
      response: CreditCardTokenizationResponse
    ) => void
  ) => void;
} | null;

const initialBillingContext = null;
const helmetData = new HelmetData({});

export const BillingContext = React.createContext<BillingContextType>(initialBillingContext);

const BillingProvider = ({ children }: PropsType) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const isScriptLoaded = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const loaded = !!(window as any).ChargeOver;
    if (!loaded) {
      setTimeout(isScriptLoaded, 100);
    } else {
      setScriptLoaded(true);
    }
  };

  useEffect(() => {
    setTimeout(isScriptLoaded, 100);
  }, []);

  useEffect(() => {
    if (scriptLoaded) {
      const chargeOverConfig = {
        instance: `${process.env.REACT_APP_CHARGE_OVER_URL}`,
        token: `${process.env.REACT_APP_CHARGE_OVER_TOKEN}`
      };

      // Typescript does not know what is loaded into the global window space so it's hard to type it.  ChargeOver.js also has no types.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).ChargeOver.Core.setup(chargeOverConfig);
    }
  }, [scriptLoaded]);

  const validateCardData = (
    cardData: CardData,
    validationCallback: (
      code: string,
      message: string,
      response: CreditCardTokenizationResponse
    ) => void
  ) => {
    const tokenizationData = {
      customer_external_key: cardData.organizationId.toString(),
      use_as_default_paymethod: false,
      number: cardData.number,
      expdate_month: cardData.expiryMonth,
      expdate_year: cardData.expiryYear,
      name: cardData.name,
      cvv: cardData.cvv
    };
    // Typescript does not know what is loaded into the global window space so it's hard to type it.  ChargeOver.js also has no types.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).ChargeOver.CreditCard.validate(tokenizationData, validationCallback);
  };

  const tokenizeCardData = (
    cardData: CardData,
    payInvoiceCallback: (
      code: string,
      message: string,
      response: CreditCardTokenizationResponse
    ) => void
  ) => {
    const tokenizationData = {
      customer_external_key: cardData.organizationId.toString(),
      use_as_default_paymethod: false,
      number: cardData.number,
      expdate_month: cardData.expiryMonth,
      expdate_year: cardData.expiryYear,
      name: cardData.name,
      cvv: cardData.cvv
    };
    // Typescript does not know what is loaded into the global window space so it's hard to type it.  ChargeOver.js also has no types.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).ChargeOver.CreditCard.tokenize(tokenizationData, payInvoiceCallback);
  };

  const value: BillingContextType = {
    tokenizeCard: tokenizeCardData,
    validateCard: validateCardData
  };

  return (
    <>
      <Helmet helmetData={helmetData}>
        <script src="https://assets-co-chargeover.global.ssl.fastly.net/minify/?g=chargeover.js" />
      </Helmet>
      <BillingContext.Provider value={value}>{children}</BillingContext.Provider>
    </>
  );
};

export default BillingProvider;
