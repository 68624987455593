import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import queryString from "query-string";

import { FullScreenModal } from "../../../../ui/Modal";
import Loader from "../../../../ui/Loader";

import AppointmentDetails from "../AppointmentDetails";
import ProviderAppointmentDetails from "../ProviderAppointmentDetails";

import { UserContext } from "../../../../providers/UserProvider";

import useInterval from "../../../../../hooks/useInterval";

import {
  fetchAppointmentsDetail as fetchAppointmentsDetailAction,
  clearAppointmentsDetail as clearAppointmentsDetailAction,
  clearAppointmentHistory as clearAppointmentHistoryAction
} from "../../../../../actions";

import formatDate from "../../../../../utils/formatDate";
import getPatientFullName from "../../../../../utils/getPatientFullName";

import styles from "./index.module.scss";

import { Appointment, AppointmentDetail, ReduxStateType } from "../../../../../types";

type PropsType = {
  simpleAppointment?: Appointment;
  appointment?: AppointmentDetail;
  fetchAppointmentsDetail: (appointmentId: string, options?: { silent: boolean }) => void;
  clearAppointmentsDetail: () => void;
  clearAppointmentHistory: () => void;
  isModalOpen: boolean;
  closeModal: (fetchApptsOnModalClose: boolean) => void;
};

const POLL_INTERVAL_MS =
  parseInt(process.env.REACT_APP_APPT_DETAILS_POLLING_INTERVAL || "", 10) || 20 * 1000;

// decide which details page to render and fetch appointmentDetails data
const AppointmentDetailsManager = ({
  simpleAppointment,
  appointment: appointmentDetails,
  fetchAppointmentsDetail,
  clearAppointmentsDetail,
  clearAppointmentHistory,
  isModalOpen,
  closeModal
}: PropsType) => {
  const parsed = queryString.parse(window.location.search);
  const { appointmentId } = parsed;
  const { providerId } = useContext(UserContext);

  /*
   * "appointmentData" is a combination of the simpleAppointment, if passed in as a prop,
   * and the results of the fetch appointment details. This pattern allows for quick rendering
   * of available information while waiting on detailed fetch results.
   */
  const appointmentData: AppointmentDetail | undefined =
    simpleAppointment || appointmentDetails
      ? ({ ...simpleAppointment, ...appointmentDetails } as AppointmentDetail)
      : undefined;

  useEffect(() => {
    if (appointmentId && typeof appointmentId === "string") {
      fetchAppointmentsDetail(appointmentId);
    }
  }, [appointmentId]);

  // start interval for silent re-fetching of appointments details
  if (!(process.env.REACT_APP_SUPPRESS_POLLING === "true")) {
    useInterval(
      () => {
        if (appointmentId && typeof appointmentId === "string") {
          fetchAppointmentsDetail(appointmentId, { silent: true });
        }
      },
      [appointmentId],
      POLL_INTERVAL_MS
    );
  }

  const patientFirstName = appointmentData?.patientFirstName || "";
  const patientLastName = appointmentData?.patientLastName || "";

  const patientDisplayName = getPatientFullName(patientFirstName, patientLastName);

  const title = appointmentData
    ? `${formatDate(appointmentData.start, "fullDateTime", appointmentData.timezone)}`
    : "";
  const subtitle = appointmentData
    ? `${patientDisplayName} - ${appointmentData.locationName}${
        appointmentData?.emrAppointmentId ? ` (ID: ${appointmentData?.emrAppointmentId})` : ""
      }`
    : "";

  const isLoading = !appointmentData;
  const showProviderDetails = appointmentData?.practitionerId === providerId;

  const handleModalClose = () => {
    clearAppointmentsDetail();
    clearAppointmentHistory();
    closeModal(showProviderDetails);
  };

  return (
    <FullScreenModal
      title={title}
      isOpen={isModalOpen}
      subtitle={subtitle}
      onClose={handleModalClose}
      fullView
      scrollableContent={!showProviderDetails}
    >
      <div className={styles.DetailsWrapper}>
        {isLoading && <Loader screen />}
        {!isLoading && (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {showProviderDetails ? (
              <ProviderAppointmentDetails
                simpleAppointment={simpleAppointment}
                appointment={appointmentDetails}
              />
            ) : (
              <AppointmentDetails
                simpleAppointment={simpleAppointment}
                appointment={appointmentDetails}
              />
            )}
          </>
        )}
      </div>
    </FullScreenModal>
  );
};
const mapStateToProps = ({ appointments }: ReduxStateType) => {
  return {
    appointment: appointments.details.data
  };
};

export default connect(mapStateToProps, {
  fetchAppointmentsDetail: fetchAppointmentsDetailAction,
  clearAppointmentsDetail: clearAppointmentsDetailAction,
  clearAppointmentHistory: clearAppointmentHistoryAction
})(AppointmentDetailsManager);
