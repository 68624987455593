import React, { useState } from "react";
import { useField } from "informed";
import cx from "classnames";

import { convertFromRaw, EditorState, RawDraftContentState } from "draft-js";

import RichTextEditor from "../../../common/tables/AppointmentsTable/ProviderAppointmentDetails/RichTextEditor";

import BaseInput from "../BaseInput";

import { InputPropsType } from "../../../../types";

import styles from "./index.module.scss";

type PropsType = InputPropsType & {
  recordId: number;
  containerClass?: string;
  id?: string;
};

const NoteTemplateInput = ({
  fieldName,
  label,
  info,
  infoStatus,
  disabled = false,
  validate,
  showField = true,
  containerClass,
  warningMessage = "",
  customOnChange,
  recordId,
  ...props
}: PropsType) => {
  const { fieldState, fieldApi } = useField({
    ...props,
    name: fieldName,
    validate
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const value = fieldState.value as any;
  const error = fieldState.error as string | undefined;

  const { setValue, setTouched, validate: validateInput } = fieldApi;

  const onChangeHandler = (contentState: RawDraftContentState): void => {
    const inputValue = contentState;
    setValue(inputValue);
    setTouched(true);
    validateInput();

    if (customOnChange) {
      customOnChange({ [fieldName]: inputValue });
    }
  };

  const [editorState, setEditorState] = useState<EditorState>(
    value ? EditorState.createWithContent(convertFromRaw(value)) : EditorState.createEmpty()
  );

  const mode = disabled ? "view" : "edit";

  return (
    <BaseInput
      fieldName={fieldName}
      error={error}
      label={label}
      showField={showField}
      info={info}
      infoStatus={infoStatus}
      warningMessage={warningMessage}
    >
      <div className={cx(styles.Container, containerClass)}>
        <RichTextEditor
          editorState={editorState}
          recordId={recordId}
          setEditorState={
            setEditorState as (editorState: React.SetStateAction<EditorState>) => void
          }
          allowAnchorsEdit={mode === "edit"}
          onSave={(content) => onChangeHandler(content)}
          mode={mode}
          isActive
          mikaContentLoading={false}
          saveThrottleMS={20}
          showAnchorSuggestions={mode === "edit"}
        />
      </div>
    </BaseInput>
  );
};

export default NoteTemplateInput;
