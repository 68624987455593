import React, { useMemo, useContext, useState } from "react";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";

import Heading from "../../../ui/Heading";
import Loader from "../../../ui/Loader";
import { FloatModal } from "../../../ui/Modal";
import { Form } from "../../../ui/Input";
import EditStaffUserForm from "./EditStaffUserForm";

import { OrganizationContext } from "../../../providers/OrganizationProvider";
import { UserContext } from "../../../providers/UserProvider";

import {
  addNotification,
  AddNotificationAction,
  closeModal as closeModalAction,
  openModal as openModalAction,
  OpenModal,
  fetchOrganization as fetchOrganizationAction
} from "../../../../actions";

import { resetUserPassword, updateUser } from "../../../../lib";

import { UserRoleConstants, UserStatuses, UserTypeConstants } from "../../../../constants";

import { Location, NewNotification, Staff } from "../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  organizationId: number;
  staffInfo: Staff;
  locations: Array<Location>;
  closeModal: () => void;
  openModal: OpenModal;
  fetchOrganization: (organizationId: string) => void;
  addNotif: (notification: NewNotification) => AddNotificationAction;
};

type FormState = {
  firstName: string;
  lastName: string;
  email: string | null;
  roleId: number | undefined;
  locationIds: string[];
};

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  roleId: undefined,
  locationIds: []
};

const EditStaffUser = ({
  organizationId,
  staffInfo,
  locations,
  closeModal,
  openModal,
  fetchOrganization,
  addNotif
}: PropsType) => {
  const user = useContext(UserContext);
  const { userId } = user;
  const isMikataAdmin = user.userType === UserTypeConstants.MIKATA_ADMIN;

  const formDisabled = staffInfo.userId === userId;

  // Organization data will be available on different objects depending if current user is mikata or clinic manager
  const currentOrg = useContext(OrganizationContext);

  const [editEmail, setEditEmail] = useState(false);

  const { active, firstName, lastName, email, role, locationIds, status, type } = staffInfo;

  const { userRoles = [] } = currentOrg || {};

  const roleId = userRoles.filter((r) => r.title === role)[0].id;

  const roleOptions = useMemo(() => {
    const generalRole = userRoles.filter((role) => role.title === UserRoleConstants.GENERAL)[0];
    const generalUploadRole = userRoles.filter(
      (role) => role.title === UserRoleConstants.GENERAL_UPLOAD
    )[0];
    const managerRole = userRoles.filter((role) => role.title === UserRoleConstants.MANAGER)[0];

    return [
      { label: "Basic", value: generalRole.id ? generalRole.id : "" },
      { label: "Basic + Upload", value: generalUploadRole.id ? generalUploadRole.id : "" },
      { label: "Clinic Manager", value: managerRole.id ? managerRole.id : "" }
    ];
  }, [userRoles]);

  const initialFormState = useMemo(() => {
    const newFormState: FormState = cloneDeep(initialState);

    newFormState.firstName = firstName;
    newFormState.lastName = lastName;
    newFormState.email = email;
    newFormState.roleId = roleId;
    newFormState.locationIds = locationIds.map((id) => id.toString());

    return newFormState;
  }, [firstName, lastName, email, roleId, locationIds]);

  if (!currentOrg || !roleId) return <Loader small center />;

  const save = async (formValues: FormState) => {
    const data = {
      userUpdateData: {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        email: formValues.email,
        roleId: formValues.roleId
      },
      staffUpdateData: {
        locationIds: formValues.locationIds ? formValues.locationIds.map((id) => Number(id)) : []
      }
    };

    const response = await updateUser(staffInfo.userId, data);

    if (response.success) {
      fetchOrganization(organizationId.toString());
      addNotif({
        type: "success",
        title: "Success",
        subtitle: "User updates saved",
        autoDismiss: true
      });
      closeModal();
    } else {
      addNotif({
        type: "error",
        title: "Failed to update the user",
        subtitle: "Please try again",
        autoDismiss: true
      });
    }
  };

  const resetPassword = async (newEmail?: string | null) => {
    const response = await resetUserPassword(staffInfo.userId);

    if (response.success) {
      addNotif({
        type: "success",
        title: "Success",
        subtitle: `We sent an email to ${firstName} (${newEmail || email}) inviting them to reset their password.`,
        autoDismiss: true
      });
      fetchOrganization(currentOrg.id.toString());
    } else {
      addNotif({
        type: "error",
        title: "Password reset failed",
        subtitle: "Please try again",
        autoDismiss: true
      });
    }
  };

  const saveAndSendInvite = async (email: string | null) => {
    const response = await updateUser(staffInfo.userId, { userUpdateData: { email } });

    if (response.success) {
      fetchOrganization(organizationId.toString());
      addNotif({
        type: "success",
        title: "Success",
        subtitle: "User updates saved",
        autoDismiss: true
      });
      resetPassword(email);
    } else {
      addNotif({
        type: "error",
        title: "Failed to update the user",
        subtitle: "Please try again",
        autoDismiss: true
      });
    }
  };

  const toggleUserActive = async (params: {
    active: boolean;
    status: string;
    deactivatedAt?: string | null;
    newEmail?: string;
  }) => {
    const response = await updateUser(staffInfo.userId, { userUpdateData: params });

    if (response.success) {
      // If we're reactivating a user we'll also invite them to reset their password
      if (params.active) {
        await resetPassword(params.newEmail);
      }
      fetchOrganization(organizationId.toString());
      addNotif({
        type: "success",
        title: "Success",
        subtitle: `The user has been ${params.active ? "reactivated" : "deactivated"}`,
        autoDismiss: true
      });
      closeModal();
    } else {
      addNotif({
        type: "error",
        title: `Failed to ${params.active ? "reactivate" : "deactivate"} the user`,
        subtitle: "Please try again",
        autoDismiss: true
      });
    }
  };

  const deactivateUser = () => {
    toggleUserActive({ active: false, status: UserStatuses.DEACTIVATED });
  };

  const reactivateUser = async (newEmail?: string | null) => {
    if (newEmail) {
      const response = await updateUser(staffInfo.userId, { userUpdateData: { email: newEmail } });
      if (response.success) {
        toggleUserActive({
          active: true,
          status: UserStatuses.AWAITING_PASSWORD_RESET,
          deactivatedAt: null,
          newEmail
        });
      } else {
        addNotif({
          type: "error",
          title: "Failed to reactivate the user",
          subtitle: "Please try again",
          autoDismiss: true
        });
      }
    } else {
      toggleUserActive({
        active: true,
        status: UserStatuses.AWAITING_PASSWORD_RESET,
        deactivatedAt: null
      });
    }
  };

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L" className={styles.EditStaffUser}>
        {`${firstName} ${lastName}`}
      </Heading>

      <Form
        onSubmit={(formState) => save(formState.values as FormState)}
        initialValues={initialFormState}
      >
        <EditStaffUserForm
          staffInfo={staffInfo}
          status={status}
          type={type}
          locations={locations}
          isMikataAdmin={isMikataAdmin}
          email={email}
          editEmail={editEmail}
          firstName={firstName}
          lastName={lastName}
          roleOptions={roleOptions}
          active={active}
          initialFormState={initialFormState}
          formDisabled={formDisabled}
          setEditEmail={setEditEmail}
          openModal={openModal}
          deactivateUser={deactivateUser}
          resetPassword={resetPassword}
          saveAndSendInvite={saveAndSendInvite}
          reactivateUser={reactivateUser}
        />
      </Form>
    </FloatModal>
  );
};

export default connect(null, {
  closeModal: closeModalAction,
  openModal: openModalAction,
  addNotif: addNotification,
  fetchOrganization: fetchOrganizationAction
})(EditStaffUser);
