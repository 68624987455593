import React from "react";

type PropsType = {
  size?: number;
};

const Play = ({ size = 24 }: PropsType) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={`${size}px`}
      height={`${size}px`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 6.7191C6 5.18529 7.6554 4.22233 8.98869 4.98057L18.2747 10.2615C19.6231 11.0283 19.6231 12.9717 18.2747 13.7385L8.98869 19.0194C7.6554 19.7777 6 18.8147 6 17.2809V6.7191ZM17.286 12L8 6.7191V17.2809L17.286 12Z"
        fill="#1D2029"
      />
    </svg>
  );
};

export default Play;
