const formatPhone = (phone: string | null) => {
  // Format a 10 digit string as a phone like so ###-###-####
  // Args: string
  // Returns: string
  if (!phone) return "";

  let phoneDigits = phone;
  if (phone.length === 12 && phone.charAt(0) === "+") {
    phoneDigits = phone.slice(2);
  }

  phoneDigits = phoneDigits.replace(/\D/g, "");
  return phoneDigits.length === 10
    ? `${phoneDigits.slice(0, 3)}-${phoneDigits.slice(3, 6)}-${phoneDigits.slice(6, 10)}`
    : phone;
};

export default formatPhone;
