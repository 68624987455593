import axios from "axios";

import getToken from "../utils/getToken";

import { closeModal } from "./modals";
import { addNotification } from "./notifications";

import { ActionStatus } from "../types";
import {
  ROOT_URL,
  FETCH_DATA_FOR_MIKATA_ADMIN,
  FETCH_DATA_FOR_ORGANIZATION,
  FETCH_UPLOAD_RECORD,
  ADD_STAFF_USER,
  UploadRecordStatuses
} from "../constants";

const getJwt = () => {
  return localStorage.getItem("jwt");
};

export const fetchDataForMikataAdmin = async () => {
  const token = getJwt();
  const config = {
    headers: { Authorization: `${token}`, "Content-Type": "application/json" }
  };

  try {
    const payload = await axios.get(`${ROOT_URL}/organizations`, config);
    return {
      type: FETCH_DATA_FOR_MIKATA_ADMIN,
      payload
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export const fetchDataForOrganization = async (id) => {
  const config = {
    headers: { Authorization: `${getJwt()}`, "Content-Type": "application/json" }
  };
  try {
    const payload = await axios.get(`${ROOT_URL}/organizations/${id}`, config);
    return {
      type: FETCH_DATA_FOR_ORGANIZATION,
      payload
    };
  } catch (e) {
    console.error(e);
  }
};

const getUploadErrorMessage = (status) => {
  switch (status) {
    case UploadRecordStatuses.NO_APPTS_FOUND:
      return `Hmmm… we couldn’t find any future appointments in your file. Please double check your file.`;
    case UploadRecordStatuses.PROBLEM_SENDING:
      return `Uh-oh! We weren’t able to send all of your reminders. Please check your file to make sure it was exported today.`;
    case UploadRecordStatuses.BAD_FILE:
      return `Uh-oh! We had trouble understanding the formatting of this file. Please make sure the file has standard formatting.`;

    default:
      return "Uh-oh! We had trouble uploading the file.";
  }
};

export const getUploadRecord = async (organizationId, uploadRecordId) => {
  const config = {
    headers: { Authorization: `${getJwt()}`, "Content-Type": "application/json" }
  };

  const payload = await axios.get(
    `${ROOT_URL}/organizations/${organizationId}/upload-records/${uploadRecordId}`,
    config
  );
  return {
    type: FETCH_UPLOAD_RECORD,
    payload
  };
};

const MAX_ATTEMPTS = 60;
export const pollUploadRecord =
  (organizationId, uploadRecordId, previousCount) => async (dispatch) => {
    const count = previousCount || 1;
    if (count === 1) {
      dispatch({
        type: FETCH_UPLOAD_RECORD,
        payload: { data: { uploadRecord: { status: UploadRecordStatuses.PROCESSING } } }
      });
    }

    const action = await getUploadRecord(organizationId, uploadRecordId);

    dispatch(action);
    const uploadRecord = action?.payload?.data?.uploadRecord;

    if (
      uploadRecord &&
      (uploadRecord.status === UploadRecordStatuses.SUCCESS ||
        uploadRecord.status === UploadRecordStatuses.READY_FOR_REMINDERS)
    ) {
      dispatch(
        addNotification({
          type: "success",
          title: "Appointments have been successfully uploaded",
          subtitle: "",
          autoDismiss: true
        })
      );
      dispatch(closeModal());
    } else if (
      uploadRecord &&
      !(
        uploadRecord.status === UploadRecordStatuses.PROCESSING ||
        uploadRecord.status === UploadRecordStatuses.VERIFIED ||
        uploadRecord.status === UploadRecordStatuses.VERIFYING
      )
    ) {
      const errorMessage = getUploadErrorMessage(uploadRecord.status);
      dispatch(
        addNotification({
          type: "error",
          title: errorMessage,
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      dispatch({
        type: FETCH_UPLOAD_RECORD,
        payload: { data: { uploadRecord: undefined } }
      });
    } else if (count >= MAX_ATTEMPTS) {
      dispatch(
        addNotification({
          type: "error",
          title: "Unfortunately the upload has taken much longer than expected.",
          subtitle: "Please reload the page and try again",
          autoDismiss: true
        })
      );
      dispatch(closeModal());
    } else if (count < MAX_ATTEMPTS && uploadRecord.status !== UploadRecordStatuses.DUPLICATE) {
      setTimeout(() => {
        pollUploadRecord(organizationId, uploadRecordId, count + 1)(dispatch);
      }, 1000);
    }
  };

export const addStaffUser = async (data) => async (dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  const handleFailure = () => {
    dispatch(
      addNotification({
        type: "error",
        title: "Failed to create a new staff user",
        subtitle: "Please try again",
        autoDismiss: true
      })
    );

    dispatch({
      type: ADD_STAFF_USER,
      status: ActionStatus.error
    });
  };

  dispatch({
    type: ADD_STAFF_USER,
    status: ActionStatus.loading
  });

  try {
    const payload = await axios.post(`${ROOT_URL}/staff`, data, config);
    if (!payload || payload.data.error) {
      handleFailure();
      return;
    }
    closeModal()(dispatch);

    dispatch(
      addNotification({
        type: "success",
        title: "Success",
        subtitle: "New staff user created",
        autoDismiss: true
      })
    );
    dispatch({
      type: ADD_STAFF_USER,
      status: ActionStatus.success,
      payload
    });
    dispatch(fetchDataForOrganization(data.organizationId));
  } catch (error) {
    handleFailure();
  }
};
