import React, { ReactNode, useState, useEffect, useContext } from "react";
import cx from "classnames";

import SortableHeader, { SortByValue } from "./SortableHeader";
import PageNavigator from "./PageNavigator";
import StopClickPropagation from "./StopClickPropagation";

import Heading from "../../../../ui/Heading";
import Dropdown from "../../../../ui/Dropdown";

import styles from "./index.module.scss";
import { LayoutContext, LayoutViewMode } from "../../../../providers/LayoutProvider";

export type { SortByValue, SortByDirection } from "./SortableHeader";

export type TableHeaderType = {
  colName: string;
  childColNames?: string[];
  content: ReactNode;
  sortable?: boolean;
};

export type TableRowType = {
  [colName: string]: ReactNode;
} & {
  __onRowClick?: () => void;
};

export { StopClickPropagation };

type PropsType = {
  id?: string;
  headers: Array<TableHeaderType>;
  mobileHeaders?: Array<TableHeaderType>;
  tabletHeaders?: Array<TableHeaderType>;
  rows: Array<TableRowType>;
  headerContent?: ReactNode;
  footerContent?: ReactNode;
  emptyContent?: ReactNode;
  showRowFocus?: boolean;
  maxPageRows?: number;
  onSortChange?: (sortByValue: SortByValue) => void;
  sortBy?: SortByValue;
  page?: number;
  onPageChange?: (pageNumber: number) => void;
  totalPages?: number;
  rowsPerPageOptions?: number[];
  onRowsPerPageChange?: (rowsPerPage: number) => void;
};

/*
 * Note: There are two ways to add pagination to this Table component, controlled and uncontrolled.  The first is to pass the "maxPageRows" prop.
 * If the maxPageRows prop is available, then the component will infer the page count and manage the currentPage value internally. Alternatively,
 * when the "page", "onPageChange", and "totalPages" props are provided the Table component passively shows the page data provided, but the state
 * management is handled in the parent component.
 */

const InboxTaskGrid = ({
  id,
  headers,
  mobileHeaders,
  tabletHeaders,
  rows,
  headerContent,
  footerContent,
  emptyContent,
  showRowFocus = false,
  maxPageRows,
  onSortChange,
  sortBy,
  page,
  onPageChange,
  totalPages,
  rowsPerPageOptions,
  onRowsPerPageChange
}: PropsType): React.ReactElement => {
  const [currentPage, setCurrentPage] = useState(1);
  const { viewMode } = useContext(LayoutContext);

  let headersToDisplay = headers;

  if (mobileHeaders && viewMode === LayoutViewMode.MOBILE) {
    headersToDisplay = mobileHeaders;
  }

  if (tabletHeaders && viewMode === LayoutViewMode.TABLET) {
    headersToDisplay = tabletHeaders;
  }

  const numberOfColumns = headersToDisplay.length;

  let rowsToDisplay = rows;
  let numPages = 1;
  let startRow;

  if (maxPageRows && maxPageRows > 0) {
    numPages = Math.ceil(rows.length / maxPageRows);
    startRow = (currentPage - 1) * maxPageRows;
    rowsToDisplay = rows.slice(startRow, startRow + maxPageRows);
  }

  useEffect(() => {
    if (currentPage > numPages) {
      setCurrentPage(1);
    }
  }, [rows]);

  return (
    <table
      id={id}
      className={styles.Table}
      style={{
        gridTemplateColumns: `repeat(${numberOfColumns - 1}, auto) min-content`
      }}
    >
      <thead className={styles.ExcludeFromGrid}>
        <tr
          className={cx(styles.ExcludeFromGrid, {
            [styles.HeaderRow]: rows.length > 0
          })}
        >
          {headersToDisplay.map((header) => {
            return (
              <th
                key={header.colName}
                className={cx(styles.Cell, styles.CellHeader, {
                  [styles.HideHeader]: headerContent
                })}
              >
                <SortableHeader
                  columnName={header.colName}
                  content={header.content}
                  sortable={header.sortable}
                  onSortChange={onSortChange}
                  sortBy={sortBy}
                />
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className={styles.ExcludeFromGrid}>
        {headerContent && (
          <tr className={cx(styles.ExcludeFromGrid, styles.HeaderContent)}>
            <td className={cx(styles.ExcludeFromGrid, styles.HeaderRow)}>
              <div
                className={cx(styles.Cell, styles.CellHeader)}
                style={{ gridColumn: `span ${numberOfColumns}`, paddingLeft: "40px" }}
              >
                {headerContent}
              </div>
            </td>
          </tr>
        )}
        {rowsToDisplay.length === 0 && emptyContent ? (
          <tr className={styles.ExcludeFromGrid}>
            <td className={styles.Footer} style={{ gridColumn: `span ${numberOfColumns}` }}>
              <div className={cx(styles.EmptyContent)}>{emptyContent}</div>
            </td>
          </tr>
        ) : (
          rowsToDisplay.map((row, rowIdx) => {
            const rowId = row.id
              ? `id-${row.id}-page-${currentPage}`
              : `row-${rowIdx}-page-${currentPage}`;

            const onRowClick =
              row && row.__onRowClick
                ? () => {
                    const hasSelection = window.getSelection()?.toString();
                    if (hasSelection) return;

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    return (row as any).__onRowClick();
                  }
                : undefined;

            return (
              <tr
                key={rowId}
                id={rowId}
                className={cx(styles.ExcludeFromGrid, styles.BodyRow, {
                  [styles.BodyRowShowFocus]: showRowFocus,
                  [styles.BodyRowClickable]: Boolean(onRowClick)
                })}
                onClick={onRowClick}
              >
                {headersToDisplay.map(({ colName, childColNames }, colIdx) => {
                  const content = row[colName] || "";

                  return (
                    <td
                      key={`${rowId}-col-${colIdx}`}
                      className={cx(styles.Cell, {
                        [styles.CellCompound]: childColNames
                      })}
                    >
                      {childColNames ? (
                        <>
                          {childColNames.map((childColName) => {
                            const childContent = row[childColName] || "";

                            return (
                              // eslint-disable-next-line react/no-array-index-key
                              <React.Fragment key={`${rowId}-col-${colIdx}-${childColName}`}>
                                {childContent}
                              </React.Fragment>
                            );
                          })}
                        </>
                      ) : (
                        content
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })
        )}
      </tbody>

      {(rowsToDisplay.length > 0 || footerContent) && (
        <tfoot className={styles.ExcludeFromGrid}>
          <tr className={styles.ExcludeFromGrid}>
            <td className={styles.Footer} style={{ gridColumn: `span ${numberOfColumns}` }}>
              {footerContent}
              <div className={styles.PageControlRow}>
                {!onPageChange && maxPageRows && maxPageRows > 0 && rowsToDisplay.length > 0 && (
                  <PageNavigator
                    currentPage={currentPage}
                    totalPages={numPages}
                    setCurrentPage={setCurrentPage}
                  />
                )}
                {onPageChange && page && totalPages && rowsToDisplay.length > 0 && (
                  <PageNavigator
                    currentPage={page}
                    totalPages={totalPages}
                    setCurrentPage={onPageChange}
                  />
                )}
                {rowsPerPageOptions && onRowsPerPageChange && (
                  <div className={styles.RowsPerPage}>
                    <Heading className={styles.RowsPerPageText} size="META">
                      Rows per page
                    </Heading>
                    <Dropdown
                      className={styles.RowsPerPageDropdown}
                      options={rowsPerPageOptions.map((rowsPerPageOption) => ({
                        label: rowsPerPageOption.toString(),
                        value: rowsPerPageOption.toString()
                      }))}
                      value={maxPageRows?.toString() || ""}
                      onChange={(selection: string) => onRowsPerPageChange(parseInt(selection, 10))}
                    />
                  </div>
                )}
              </div>
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  );
};

export default InboxTaskGrid;
