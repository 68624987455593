import uniqBy from "lodash/uniqBy";

import {
  FETCH_ORGANIZATION_DETAILS,
  UPDATE_ORGANIZATION_DETAILS,
  DELETE_ORGANIZATION,
  UPLOAD_ORGANIZATION_LOGO,
  FETCH_ORGANIZATION_LOGO_URL,
  FETCH_ORGANIZATION_SETTINGS,
  UPDATE_ORGANIZATION_SETTINGS,
  ADD_ORGANIZATION,
  ADD_SELF_SERVE_ORGANIZATION,
  GET_INTEGRATION_SUMMARY,
  VERIFY_INVITE_CODE
} from "../constants";

import {
  FetchOrganizationDataAction,
  UpdateOrganizationAction,
  DeleteOrganizationAction,
  UploadLogoAction,
  FetchLogoUrlAction,
  FetchOrganizationSettingsAction,
  UpdateOrganizationSettingsAction,
  AddOrganizationAction,
  AddSelfServeOrganizationAction,
  GetIntegrationSummaryAction,
  VerifyInviteCodeAction
} from "../actions/organization";
import { OrganizationData, Setting, SignedURLData, ActionStatus, SyncRecord } from "../types";

export type OrganizationAction =
  | FetchOrganizationDataAction
  | UpdateOrganizationAction
  | DeleteOrganizationAction
  | UploadLogoAction
  | FetchLogoUrlAction
  | FetchOrganizationSettingsAction
  | UpdateOrganizationSettingsAction
  | AddOrganizationAction
  | AddSelfServeOrganizationAction
  | GetIntegrationSummaryAction
  | VerifyInviteCodeAction;

export type OrganizationReduxState = {
  data?: OrganizationData;
  logoSignedURLData?: SignedURLData;
  uploadLogoDetails?: SignedURLData;
  organizationLoading: boolean;
  logoSignedURLDataLoading: boolean;
  logoUploaded: boolean;
  uploadLogoDetailsLoading: boolean;
  settings?: Array<Setting>;
  settingsLoading: boolean;
  addOrganizationLoading: boolean;
  addSelfServeOrganizationLoading: boolean;
  addSelfServeOrganizationError: boolean;
  bulkSyncs: Array<SyncRecord>;
  incrementalSyncs: Array<SyncRecord>;
  inviteCodeVerified?: boolean;
  inviteCodeLoading: boolean;
};

const initialOrganization: OrganizationReduxState = {
  data: undefined,
  logoSignedURLData: undefined,
  uploadLogoDetails: undefined,
  organizationLoading: false,
  logoSignedURLDataLoading: false,
  logoUploaded: false,
  uploadLogoDetailsLoading: false,
  settings: [],
  settingsLoading: false,
  addOrganizationLoading: false,
  addSelfServeOrganizationLoading: false,
  addSelfServeOrganizationError: false,
  bulkSyncs: [],
  incrementalSyncs: [],
  inviteCodeVerified: false,
  inviteCodeLoading: false
};

export const organizationReducer = (
  state = initialOrganization,
  action: OrganizationAction
): OrganizationReduxState => {
  const { type } = action;
  switch (type) {
    case FETCH_ORGANIZATION_DETAILS: {
      const { status, payload } = action as FetchOrganizationDataAction;

      return {
        ...state,
        data: status === ActionStatus.success ? payload : state.data,
        organizationLoading: status === ActionStatus.loading
      };
    }
    case UPDATE_ORGANIZATION_DETAILS: {
      const { status } = action as UpdateOrganizationAction;
      return {
        ...state,
        organizationLoading: status === ActionStatus.loading
      };
    }
    case UPLOAD_ORGANIZATION_LOGO: {
      const { status, payload } = action as UploadLogoAction;
      return {
        ...state,
        uploadLogoDetails: status === ActionStatus.success ? payload : state.uploadLogoDetails,
        uploadLogoDetailsLoading: status === ActionStatus.loading
      };
    }
    case FETCH_ORGANIZATION_LOGO_URL: {
      const { status, payload } = action as FetchLogoUrlAction;
      return {
        ...state,
        logoSignedURLData: status === ActionStatus.success ? payload : undefined,
        logoSignedURLDataLoading: status === ActionStatus.loading
      };
    }
    case FETCH_ORGANIZATION_SETTINGS: {
      const { status, payload } = action as FetchOrganizationSettingsAction;
      return {
        ...state,
        settings: status === ActionStatus.success ? payload : undefined,
        settingsLoading: status === ActionStatus.loading
      };
    }
    case UPDATE_ORGANIZATION_SETTINGS: {
      const { status, payload } = action as UpdateOrganizationSettingsAction;
      // Note the payload settings must be before the existing settings to ensure that the updated values are used
      const updatedSettings =
        status === ActionStatus.success
          ? uniqBy([...(payload || []), ...(state.settings || [])], "settingName")
          : state.settings;

      return {
        ...state,
        settings: updatedSettings,
        settingsLoading: status === ActionStatus.loading
      };
    }
    case ADD_ORGANIZATION: {
      const { status } = action as AddOrganizationAction;
      return {
        ...state,
        addOrganizationLoading: status === ActionStatus.loading
      };
    }
    case ADD_SELF_SERVE_ORGANIZATION: {
      const { status } = action as AddSelfServeOrganizationAction;
      return {
        ...state,
        addSelfServeOrganizationLoading: status === ActionStatus.loading,
        addSelfServeOrganizationError: status === ActionStatus.error
      };
    }
    case DELETE_ORGANIZATION: {
      const { status, payload } = action as DeleteOrganizationAction;
      return {
        ...state,
        data: status === ActionStatus.success ? payload : state.data,
        organizationLoading: status === ActionStatus.loading
      };
    }
    case GET_INTEGRATION_SUMMARY: {
      const { status, payload } = action as GetIntegrationSummaryAction;
      return {
        ...state,
        bulkSyncs: payload?.bulkSyncs || state.bulkSyncs,
        incrementalSyncs: payload?.incrementalSyncs || state.incrementalSyncs,
        organizationLoading: status === ActionStatus.loading
      };
    }
    case VERIFY_INVITE_CODE: {
      const { status, payload } = action as VerifyInviteCodeAction;
      return {
        ...state,
        inviteCodeVerified: status === ActionStatus.success ? payload?.verified : false,
        inviteCodeLoading: status === ActionStatus.loading
      };
    }

    default:
      return state;
  }
};
