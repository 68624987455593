import { MessageTopics, MessageMediums } from "../constants";

const smsAndEmailMediums = [MessageMediums.EMAIL, MessageMediums.SMS];
const smsMedium = [MessageMediums.SMS];
const anyMedium = [MessageMediums.ANY];
const webChatMedium = [MessageMediums.WEB_CHAT];
const topicMediumMap: { [topic: string]: Array<string> } = {
  [MessageTopics.SEVEN_DAY_SINGLE_REMINDER]: smsAndEmailMediums,
  [MessageTopics.SEVEN_DAY_MULTI_REMINDER]: smsAndEmailMediums,
  [MessageTopics.TWO_DAY_SINGLE_REMINDER]: smsAndEmailMediums,
  [MessageTopics.TWO_DAY_SINGLE_REMINDER_NO_SEVEN]: smsAndEmailMediums,
  [MessageTopics.TWO_DAY_MULTI_REMINDER]: smsAndEmailMediums,
  [MessageTopics.TWO_DAY_MULTI_REMINDER_NO_SEVEN]: smsAndEmailMediums,
  [MessageTopics.TWO_DAY_PREVISIT_LINK]: smsAndEmailMediums,
  [MessageTopics.BOOKED]: smsAndEmailMediums,
  [MessageTopics.MULTI_BOOKED]: smsAndEmailMediums,
  [MessageTopics.CANCELLED]: smsAndEmailMediums,
  [MessageTopics.MULTI_CANCELLED]: smsAndEmailMediums,
  [MessageTopics.POST_VISIT_LINK]: smsAndEmailMediums,
  [MessageTopics.REMINDER_RESPONSE_NO_APPT]: smsMedium,
  [MessageTopics.REMINDER_CONFIRM]: smsMedium,
  [MessageTopics.REMINDER_RESPONSE_RESCHEDULE]: smsMedium,
  [MessageTopics.REMINDER_RESPONSE_UNCLEAR]: smsMedium,
  [MessageTopics.USER_RESTART_REMINDERS]: smsMedium,
  [MessageTopics.USER_SURVEY_OPT_OUT]: smsMedium,
  [MessageTopics.CAMPAIGN]: smsMedium,
  [MessageTopics.CAMPAIGN_RESPONSE_UNCLEAR]: smsMedium,
  [MessageTopics.CHECK_IN_NOTICE]: smsAndEmailMediums,
  [MessageTopics.USER_CHECK_IN_RESPONSE]: smsMedium,
  [MessageTopics.CHECK_IN_NOTIFY_USER]: smsAndEmailMediums,
  [MessageTopics.BOOKING_RESPONSE]: smsMedium,
  [MessageTopics.BOOKING_NOT_ENABLED_RESPONSE]: smsMedium,
  [MessageTopics.BOOKING_NOT_ENABLED_FEEDBACK]: smsMedium,
  [MessageTopics.CHECK_IN_NO_APPOINTMENTS]: smsMedium,
  [MessageTopics.CHECK_IN_UNCLEAR]: smsMedium,
  [MessageTopics.SCHEDULE_BASED_MESSAGE]: smsMedium,
  [MessageTopics.DIRECT_MESSAGE]: anyMedium,
  [MessageTopics.SECURE_DIRECT_MESSAGE]: webChatMedium,
  [MessageTopics.APPOINTMENT_REQUEST_DIRECT_MESSAGE]: anyMedium
};

const getTopicMediums = (topic: string): Array<string> => {
  return topicMediumMap[topic] || topic;
};

export default getTopicMediums;
