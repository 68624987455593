import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { FloatModal } from "../../../ui/Modal";
import Loader from "../../../ui/Loader";
import TemplateSearchSelect from "../../../ui/TemplateSearchSelect";
import Text from "../../../ui/Text";
import FeedbackManager from "../../../ui/FeedbackManager";
import Button from "../../../ui/Button";

import {
  Note,
  NoteTemplate,
  NoteTemplateUpdateData,
  ReduxStateType,
  SetFeedbackModeData
} from "../../../../types";

import {
  closeModal as closeModalAction,
  fetchNoteTemplates as fetchNoteTemplatesAction,
  createNote as createNoteAction,
  CreateNoteData,
  updateNoteTemplateDetails as updateNoteTemplateDetailsAction,
  NoteTemplatesFetchOptions,
  setFeedbackMode
} from "../../../../actions";
import { FeedbackFormModes } from "../../../../typedConstants";

import styles from "./index.module.scss";

type PropsType = {
  closeModal: () => void;
  notes: Note[];
  noteTemplates: NoteTemplate[];
  noteTemplateTags: Record<string, string[]> | undefined;
  noteTemplatesLoading: boolean;
  fetchNoteTemplates: (options?: NoteTemplatesFetchOptions) => void;
  updateNoteTemplateDetails: (
    noteTemplate: NoteTemplateUpdateData,
    noteTemplateId: string,
    successMessage: string
  ) => void;
  createNote: (body: CreateNoteData, onSuccess?: (noteId: number) => void) => void;
  setFeedbackMode: (data: SetFeedbackModeData) => void;
  patientId: number;
  appointmentId: number;
  createLoading: boolean;
  setActiveAppointmentNoteId?: (noteId: number) => void;
};

const InitializeNote = ({
  closeModal,
  noteTemplates,
  noteTemplateTags,
  noteTemplatesLoading,
  fetchNoteTemplates,
  updateNoteTemplateDetails,
  setFeedbackMode,
  createNote,
  notes,
  patientId,
  appointmentId,
  createLoading,
  setActiveAppointmentNoteId
}: PropsType) => {
  useEffect(() => {
    if (fetchNoteTemplates) {
      fetchNoteTemplates({ silent: noteTemplates.length > 0 });
    }
  }, []);
  const templateNavs = ["All templates", "Favourite", "My library"];
  const [templateNavFilter, setTemplateNavFilter] = useState<string>("All templates");
  const [feedbackModalOpen, setFeedbackModalOpen] = useState<boolean>(false);
  return (
    <FloatModal containerClass={styles.ModalContainer} isWideModal isOpen onClose={closeModal}>
      {noteTemplatesLoading || createLoading ? (
        <Loader center className={styles.LoaderContainer} />
      ) : (
        <TemplateSearchSelect
          noteTemplates={noteTemplates}
          headerTitle="Add Note"
          noteTemplateTags={noteTemplateTags}
          templateNavFilter={templateNavFilter}
          setTemplateNavFilter={setTemplateNavFilter}
          disabled={createLoading}
          templateNavs={templateNavs}
          updateNoteTemplateDetails={updateNoteTemplateDetails}
          footerComponent={
            <FeedbackManager
              position="bottomRight"
              context={{
                appointmentId: appointmentId.toString(),
                noteIds: notes?.map((item) => item.id),
                activeNoteId: null
              }}
              feedbackModalOpen={feedbackModalOpen}
              setFeedbackModalOpen={(value: boolean) => {
                setFeedbackModalOpen(value);
              }}
            >
              <div className={styles.FooterFeedback}>
                <Text size="S">Can't find what you are looking for?</Text>
                <Button
                  inline
                  className={styles.FooterFeedbackButton}
                  onClick={() => {
                    setFeedbackMode({ feedbackMode: FeedbackFormModes.SCRIBE_TEMPLATE_REQUEST });
                    setFeedbackModalOpen(true);
                  }}
                >
                  Request a template
                </Button>
              </div>
            </FeedbackManager>
          }
          onTemplateSelect={(noteTemplateId) =>
            createNote(
              {
                noteTemplateId,
                patientId,
                appointmentId
              },
              (noteId: number) => {
                if (setActiveAppointmentNoteId) {
                  setActiveAppointmentNoteId(noteId);
                }
                closeModal();
              }
            )
          }
        />
      )}
    </FloatModal>
  );
};

const mapStateToProps = ({ noteTemplates, notes }: ReduxStateType) => {
  return {
    notes: notes.notes || [],
    noteTemplates: noteTemplates.noteTemplates,
    noteTemplateTags: noteTemplates.noteTemplateTags,
    noteTemplatesLoading: noteTemplates.noteTemplatesLoading,
    createLoading: notes.createLoading
  };
};

export default connect(mapStateToProps, {
  fetchNoteTemplates: fetchNoteTemplatesAction,
  updateNoteTemplateDetails: updateNoteTemplateDetailsAction,
  closeModal: closeModalAction,
  createNote: createNoteAction,
  setFeedbackMode
})(InitializeNote);
