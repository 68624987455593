import { DOCUMENTS_FETCH, DOCUMENTS_CREATE } from "../constants";

import { FetchDocumentsAction, CreateDocumentAction } from "../actions";

import { Document, ActionStatus } from "../types";

export type DocumentsReduxState = {
  documents: Array<Document>;
  documentsLoading: boolean;
  documentDetails: {
    id: number | null;
    filename: string | null;
    s3Path: string | null;
    signedUrl: string | null;
  };
};

export type DocumentsActions = FetchDocumentsAction | CreateDocumentAction;

const initialChatsState: DocumentsReduxState = {
  documents: [],
  documentsLoading: false,
  documentDetails: {
    id: null,
    filename: null,
    s3Path: null,
    signedUrl: null
  }
};

export const documentsReducer = (
  state = initialChatsState,
  action: DocumentsActions
): DocumentsReduxState => {
  const { type } = action;

  switch (type) {
    case DOCUMENTS_FETCH: {
      const { payload, status } = action as FetchDocumentsAction;
      return {
        ...state,
        documents: payload?.documents || state.documents,
        documentsLoading: status === ActionStatus.loading
      };
    }
    case DOCUMENTS_CREATE: {
      const { status } = action as CreateDocumentAction;
      return {
        ...state,
        documentsLoading: status === ActionStatus.loading
      };
    }

    default:
      return state;
  }
};
