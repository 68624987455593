/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";

import { Calendar, ChevronLeft, ChevronRight } from "../Icon";
import Text from "../Text";
import Button from "../Button";

import "./custom.scss";
import styles from "./index.module.scss";

type PropsType = {
  onChange: (date: Date | null) => void;
  selectedDate: Date;
  customMinDate?: Date;
  customMaxDate?: Date;
};

const customHeader = (
  selectedDate: Date,
  date: Date,
  decreaseMonth: React.MouseEventHandler<HTMLButtonElement> | undefined,
  increaseMonth: React.MouseEventHandler<HTMLButtonElement> | undefined,
  prevMonthButtonDisabled: boolean | undefined,
  nextMonthButtonDisabled: boolean | undefined
) => {
  return (
    <div className={styles.CalendarHeader}>
      <div className={styles.CalendarHeaderDate}>
        <Calendar size={24} />
        <Text size="S" bold className={styles.CalendarHeaderText}>
          {moment(selectedDate).format("dddd, MMMM D")}
        </Text>
      </div>
      <div className={styles.CalendarHeaderMonth}>
        <Button
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          className={styles.CalendarButton}
        >
          <ChevronLeft />
        </Button>
        <Text size="S" component="span" bold className={styles.CalendarDate}>
          {moment(date).format("MMMM YYYY")}
        </Text>
        <Button
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
          className={styles.CalendarButton}
        >
          <ChevronRight />
        </Button>
      </div>
    </div>
  );
};

// eslint-disable-next-line react/display-name
const ExampleCustomInput = forwardRef<any, any>(
  ({ value, onClick }: { value: string; onClick: () => void }, ref: any) => {
    return (
      <button
        className={styles.CustomInputButton}
        type="button"
        onClick={onClick}
        ref={ref.current as any}
      >
        <Calendar size={24} />
        <Text size="S" bold className={styles.CustomInputButtonText}>
          {value}
        </Text>
      </button>
    );
  }
);

const DateSelector = ({
  onChange,
  selectedDate = new Date(),
  customMinDate,
  customMaxDate
}: PropsType) => {
  return (
    <div className={styles.DateSelector}>
      <div id="ChevronLeft" className={styles.DateSelectorSide}>
        <Button
          className={styles.CalendarButton}
          onClick={() => onChange(moment(selectedDate).subtract(1, "days").toDate())}
        >
          <ChevronLeft size={26} />
        </Button>
      </div>

      <div className={styles.DateSelectorMain}>
        {/* Please see the following docs for more information of react datepicker this.props
          https://github.com/Hacker0x01/react-datepicker/tree/master/docs
          https://reactdatepicker.com/#example-custom-input */}
        <DatePicker
          selected={selectedDate}
          onChange={onChange}
          className={styles.DatePicker}
          calendarClassName={styles.Calendar}
          dateFormat="eeee, MMMM d"
          minDate={customMinDate}
          maxDate={customMaxDate}
          customInput={<ExampleCustomInput />}
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          }) =>
            customHeader(
              selectedDate,
              date,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled
            )
          }
          showPopperArrow={false}
          popperClassName={styles.Popper}
        />
      </div>

      <div id="ChevronRight" className={styles.DateSelectorSide}>
        <Button
          className={styles.CalendarButton}
          onClick={() => onChange(moment(selectedDate).add(1, "days").toDate())}
        >
          <ChevronRight size={26} />
        </Button>
      </div>
    </div>
  );
};

export default DateSelector;
