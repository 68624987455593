import moment from "moment-timezone";

export const isDemoAccountExpired = (demoAccountExpiry?: string) => {
  if (!demoAccountExpiry) return false;

  const now = moment();
  return moment(demoAccountExpiry).diff(now, "hours") < 0;
};

export const daysUntilExpiry = (demoAccountExpiry: string) => {
  const now = moment();
  const hoursTillExpiry = moment(demoAccountExpiry).diff(now, "hours");
  if (hoursTillExpiry < 24) {
    return 0;
  }
  return moment(demoAccountExpiry).diff(now, "days");
};
