import { ChatCardTypes } from "../../../../../../../../../constants";

import { D3Node } from "../types";

const getCardHeading = (d: D3Node) => {
  switch (d.cardType) {
    case ChatCardTypes.LANDING:
      return `START (${d.payloadOptions?.variant || "card"})`;
    case ChatCardTypes.SUB_FLOW_START:
      return "START CHAT SECTION";
    case ChatCardTypes.CHAT_FLOW:
      return "SUB FLOW";
    case ChatCardTypes.SINGLE_SELECT:
      return "SELECT (single)";
    case ChatCardTypes.MULTI_SELECT:
      return "SELECT (multi)";
    case ChatCardTypes.DEAD_END:
      return "DEAD END";
    case ChatCardTypes.END:
      return "END";
    case ChatCardTypes.FLAGGED_END:
      return "FLAGGED END";
    default:
      return d.cardType ? d.cardType.toUpperCase() : "-";
  }
};

export const getNodeSummaryContent = (
  node: D3Node
): { heading: string; title: string; details: string } => {
  if (!node) return { heading: getCardHeading(node), title: "", details: "" };

  switch (node.cardType) {
    case ChatCardTypes.LANDING: {
      if (
        node?.payloadOptions?.variant === "task" ||
        node?.payloadOptions?.variant === "priorityTask"
      ) {
        return {
          heading: getCardHeading(node),
          title: node.payloadOptions?.taskContent?.title || "",
          details: node.payloadOptions?.taskContent?.description || ""
        };
      }
      if (!node?.payloadOptions?.variant || node?.payloadOptions?.variant === "card") {
        let title = "";
        let details = "";
        try {
          const parsed = node?.payloadContent && JSON.parse(node?.payloadContent);
          title = parsed.header || "";
          details =
            parsed?.paragraphs && Array.isArray(parsed?.paragraphs)
              ? parsed?.paragraphs.join(" ")
              : "";
        } catch (e) {
          title = "";
          details = "";
        }

        return {
          heading: getCardHeading(node),
          title,
          details
        };
      }

      return { heading: getCardHeading(node), title: "", details: node.payloadContent || "" };
    }
    case ChatCardTypes.SUB_FLOW_START: {
      return { heading: getCardHeading(node), title: "", details: "Hidden Element" };
    }
    case ChatCardTypes.CHAT_FLOW: {
      return {
        heading: getCardHeading(node),
        title: node?.subChatFlow?.title || "",
        details: node?.subChatFlow?.id ? `Sub Chat Flow ID: ${node?.subChatFlow?.id}` : ""
      };
    }
    case ChatCardTypes.FLAGGED_END:
    case ChatCardTypes.END: {
      let details = "";
      try {
        const parsed = node?.payloadContent && JSON.parse(node?.payloadContent);

        details =
          parsed?.headerContent || parsed?.bodyContent
            ? `${parsed?.headerContent || ""}${parsed?.headerContent ? " " : ""}${
                parsed?.bodyContent || ""
              }`
            : "";
      } catch (e) {
        details = node.payloadContent || "";
      }
      return {
        heading: getCardHeading(node),
        title: node?.displayName || node?.inputName || "",
        details
      };
    }
    default:
      return {
        heading: getCardHeading(node),
        title: node.displayName || node.inputName || "",
        details: node.payloadContent || ""
      };
  }
};
