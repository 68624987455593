import React from "react";

import { Form } from "../../Input";
import FilterTemplatesForm from "./FilterTemplatesForm";

import { Option } from "../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  selectedTypes: string[];
  typesOptions: Array<Option>;
  onTypesChanges: (value: string[]) => void;
  selectedOwners: string[];
  ownerOptions: Array<Option>;
  onOwnersChange: (value: string[]) => void;
  selectedSpecialty: string[];
  specialtyOptions: Array<Option>;
  onSpecialtyChange: (value: string[]) => void;
  selectedStyling: string[];
  stylingOptions: Array<Option>;
  onStylingChange: (value: string[]) => void;
  isFilterOpen: boolean;
  closeFilterDropdown: () => void;
};

type MoreFiltersFormData = {
  owners: Array<string>;
  types: Array<string>;
  specialty: Array<string>;
  styling: Array<string>;
};

const FilterTemplates = ({
  selectedTypes,
  typesOptions,
  onTypesChanges,
  selectedOwners,
  ownerOptions,
  onOwnersChange,
  selectedSpecialty,
  specialtyOptions,
  onSpecialtyChange,
  selectedStyling,
  stylingOptions,
  onStylingChange,
  isFilterOpen,
  closeFilterDropdown
}: PropsType) => {
  const initialFormState = {
    types: selectedTypes,
    owners: selectedOwners,
    specialty: selectedSpecialty,
    styling: selectedStyling
  };

  const onSave = (formData: MoreFiltersFormData) => {
    const { owners, styling, types, specialty } = formData;

    onOwnersChange(owners);
    onSpecialtyChange(specialty);
    onStylingChange(styling);
    onTypesChanges(types);

    closeFilterDropdown();
  };

  return (
    <div className={styles.ModalContainerClass}>
      <Form
        key={selectedOwners.join(",")}
        onSubmit={(formState) => onSave(formState.values as MoreFiltersFormData)}
        initialValues={initialFormState}
      >
        <FilterTemplatesForm
          ownerOptions={ownerOptions}
          selectedOwners={selectedOwners}
          typesOptions={typesOptions}
          selectedTypes={selectedTypes}
          specialtyOptions={specialtyOptions}
          stylingOptions={stylingOptions}
          isFilterOpen={isFilterOpen}
          onFilterClose={closeFilterDropdown}
        />
      </Form>
    </div>
  );
};

export default FilterTemplates;
