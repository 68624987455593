import React, { useState } from "react";
import cx from "classnames";

import { EditPencil } from "../../../../../../../../ui/Icon";

import styles from "./index.module.scss";

type PropsType = {
  children: React.ReactNode;
  headerContent?: React.ReactNode;
  summaryContent?: React.ReactNode;
  initialOpen?: boolean;
  forceOpen: boolean;
  secondary?: boolean;
  collapsible?: boolean;
  className?: string;
  id?: string;
};

const CollapsibleCard = ({
  className,
  id,
  headerContent,
  summaryContent,
  secondary = false,
  collapsible = false,
  initialOpen = false,
  forceOpen,
  children
}: PropsType): React.ReactElement => {
  const [open, setOpen] = useState(initialOpen);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const isOpen = (forceOpen && !open) || open;
  return (
    <div id={id} className={cx(styles.Card, { [styles.Secondary]: secondary }, className)}>
      <span className={cx(styles.Connection)} />
      <div>
        {headerContent && (
          <div
            className={cx(styles.HeaderButtonBlock, {
              [styles.HeaderButtonBlockOpen]: isOpen
            })}
            onClick={toggleOpen}
          >
            <div className={cx(styles.HeaderContent, { [styles.HeaderContentOpen]: isOpen })}>
              {headerContent}
              {collapsible && (
                <span className={cx(styles.ToggleIcon, { [styles.IconOpen]: isOpen })}>
                  {!isOpen && <EditPencil size={16} />}
                </span>
              )}
            </div>

            <div
              className={cx(styles.SummaryContent, {
                [styles.SummaryContentOpen]: isOpen,
                [styles.SecondarySummaryContent]: secondary
              })}
            >
              {summaryContent}
            </div>
          </div>
        )}
        <div className={cx(styles.CardContent, { [styles.CardContentOpen]: isOpen })}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default CollapsibleCard;
