import React, { useEffect, useMemo, useState } from "react";
import Select, { OnChangeValue, StylesConfig } from "react-select";

import { InputPropsType, InputValue } from "../../../../../types";

export type Option = { label: string; value: string | number };

export type Options = Array<Option>;

type PropsType = InputPropsType & {
  id?: string;
  options: Options;
  fieldName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValue?: any;
  styles?: StylesConfig<Option, boolean>;
  labelDetails?: React.ReactNode;
  isMulti?: boolean;
  isClearable?: boolean;
  classNamePrefix?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (inputValue: any) => void;
};

const SelectInputBase = ({
  id,
  fieldName,
  label,
  options,
  placeholder,
  initialValue,
  isMulti = false,
  isClearable = false,
  disabled = false,
  styles,
  onChange,
  classNamePrefix,
  ...props
}: PropsType): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [value, setValue] = useState<any>(initialValue);

  const onChangeHandler = (selection: OnChangeValue<Option, boolean>): void => {
    let inputValue: InputValue = "";
    if (isMulti) {
      inputValue = ((selection as Option[]) || []).map((option) => (option && option.value) || "");
    } else {
      inputValue = selection ? (selection as Option).value : "";
    }

    setValue(inputValue);
    if (onChange) {
      onChange(inputValue);
    }
  };

  const selectedOption = useMemo(() => {
    const selectionOptions = options?.filter((item) =>
      isMulti ? value.includes(item.value) : value === item.value
    );
    return isMulti ? selectionOptions : selectionOptions[0];
  }, [value]);

  useEffect(() => {
    if (initialValue !== value) {
      setValue(initialValue);
    }
  }, [initialValue]);

  return (
    <Select
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      id={id || fieldName}
      styles={styles}
      options={options}
      isClearable={isClearable}
      isMulti={isMulti}
      placeholder={placeholder}
      aria-label={label}
      aria-required="true"
      isDisabled={disabled}
      onChange={onChangeHandler}
      value={selectedOption}
      classNamePrefix={classNamePrefix} // Helper for Integration test element selection
    />
  );
};

export default SelectInputBase;
