import React, { useContext, useMemo, useState } from "react";
import cx from "classnames";

import moment from "moment-timezone";
import Heading from "../../ui/Heading";
import Card from "../../ui/Card";
import Text from "../../ui/Text";
import Loader from "../../ui/Loader";

import { OrganizationContext } from "../../providers/OrganizationProvider";

import styles from "./index.module.scss";
import TimelineEvent from "./timelineEvent";

import { HistoryEvent } from "../../../types";
import Dropdown from "../../ui/Dropdown";

type PropsType = {
  historyLoading?: boolean;
  history?: HistoryEvent[];
  preferQuickView?: boolean;
};

const Timeline = ({ historyLoading, history, preferQuickView }: PropsType): JSX.Element | null => {
  const currentOrg = useContext(OrganizationContext);

  const [sortBy, setSortBy] = useState("desc");

  const sortedHistory = useMemo(
    () =>
      (history &&
        history.sort((a, b) => {
          const dateA = moment(a.eventTime, "dddd, MMMM D YYYY h:mmA", currentOrg?.timezone);
          const dateB = moment(b.eventTime, "dddd, MMMM D YYYY h:mmA", currentOrg?.timezone);

          if (sortBy === "asc") {
            return dateA.valueOf() - dateB.valueOf();
          }

          return dateB.valueOf() - dateA.valueOf();
        })) ||
      [],
    [history, sortBy]
  );

  if (historyLoading) {
    return (
      <Card className={styles.AppointmentHistory}>
        <Heading size="M" component="h1" className={cx(styles.Heading, styles.BottomBorder)}>
          Timeline
        </Heading>
        <Loader small center />
      </Card>
    );
  }

  return (
    <Card className={cx(styles.AppointmentHistory, { [styles.QuickView]: preferQuickView })}>
      <div
        className={cx(styles.BottomBorder, {
          [styles.Heading]: !preferQuickView,
          [styles.MetaHeading]: preferQuickView
        })}
      >
        {preferQuickView ? (
          <Heading size="META" className={styles.MetaHeadingText}>
            order by
          </Heading>
        ) : (
          <Heading size="M" component="h1">
            Timeline
          </Heading>
        )}
        <Dropdown
          id="sortBy"
          className={styles.SortByDropdown}
          onChange={(selectedSortBy) => setSortBy(selectedSortBy)}
          value={sortBy}
          options={[
            { label: "Newest to Oldest", value: "desc" },
            { label: "Oldest to Newest", value: "asc" }
          ]}
          configMap={{
            asc: "gray",
            desc: "gray"
          }}
        />
      </div>
      {sortedHistory?.length > 0 &&
        sortedHistory.map((event, index) => {
          return (
            <TimelineEvent
              key={`timelineEvent-${event.eventTime}-${event.changeType}-${index}`}
              event={event}
            />
          );
        })}

      {!history || (history?.length === 0 && <Text>No history events found.</Text>)}
    </Card>
  );
};

export default Timeline;
