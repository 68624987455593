export enum ChatFlowResponseType {
  "BOOKING" = "booking",
  "PATIENT_VERIFICATION_ATTEMPT" = "patientVerification",
  "PATIENT_VERIFICATION_RESULT" = "patientVerificationResult"
}

export enum MessageTemplateActionTag {
  "ASSOCIATE_PATIENT" = "associatePatient",
  "COMPLETE_CHAT" = "completeChat",
  "PUSH_DEMOGRAPHICS" = "pushDemographics"
}
