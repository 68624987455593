import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import Heading from "../../../../ui/Heading";
import Button from "../../../../ui/Button";
import Text from "../../../../ui/Text";
import Status from "../../../../ui/Status";
import Loader from "../../../../ui/Loader";

import {
  fetchLocations as fetchLocationsAction,
  OpenModal,
  openModal as openModalAction
} from "../../../../../actions";

import { StatusComponentConfigMap, Location, ReduxStateType } from "../../../../../types";
import { AdminModalTypes } from "../../../../../constants";

import styles from "./index.module.scss";

type PropsType = {
  locations: Array<Location>;
  loading: boolean;
  fetchLocations: (organizationId: number) => void;
  openModal: OpenModal;
};

const locationStatusConfigMap: StatusComponentConfigMap = {
  active: "green",
  inactive: "gray"
};

const locationStatusOptions = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" }
];

const LocationsPage = ({
  locations,
  loading,
  fetchLocations,
  openModal
}: PropsType): JSX.Element => {
  const { organizationId: orgIdString } = useParams<{
    organizationId: string;
  }>();

  const organizationId = Number(orgIdString);

  // Fetch reasons on first load
  useEffect(() => {
    fetchLocations(organizationId);
  }, []);

  if (loading) {
    return <Loader screen />;
  }

  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <Heading size="M" component="span" className={styles.Heading}>
          Locations
        </Heading>
        <Button
          inline
          onClick={() => {
            openModal(AdminModalTypes.ADD_LOCATION, { organizationId });
          }}
        >
          Add Location +
        </Button>
      </div>

      <div className={styles.CardList} id="orgLocation">
        {locations.map((loc) => {
          const locationName = loc.displayName ? loc.displayName : "--";
          return (
            <div key={loc.id} className={styles.Card}>
              <div className={styles.CardHeader}>
                <Status
                  value={loc.active ? "active" : "inactive"}
                  configMap={locationStatusConfigMap}
                  options={locationStatusOptions}
                />
                <Text bold>ID: {loc.id}</Text>
              </div>

              <div className={styles.CardBody}>
                <Text bold>{loc.fullName}</Text>
                <Text id={`#locationName-${locationName.replace(" ", "")}`}>{locationName}</Text>
                <Text>{loc.street ? loc.street : "--"}</Text>
                <Text>
                  {loc.city ? loc.city : "--"}
                  {loc.state ? `, ${loc.state}` : ""}
                </Text>
                <Text>
                  {loc.telecom ? loc.telecom : "--"}
                  {loc.telecomExtension ? `, Ext. ${loc.telecomExtension}` : ""}
                </Text>
                <Text>
                  {loc.timezone ? loc.timezone : "--"}
                  {loc.abbrev ? ` (${loc.abbrev})` : ""}
                </Text>
                <Text>Sync Data: {loc.syncLock ? "Locked" : "Unlocked"}</Text>
              </div>

              <Button
                inline
                className={styles.CardEdit}
                onClick={() => {
                  openModal(AdminModalTypes.EDIT_LOCATION, { organizationId, location: loc });
                }}
              >
                <Text className={styles.CardEditText}>Edit</Text>
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = ({ locations }: ReduxStateType) => {
  return {
    locations: locations.data,
    loading: locations.locationsFetchLoading
  };
};

export default connect(mapStateToProps, {
  fetchLocations: fetchLocationsAction,
  openModal: openModalAction
})(LocationsPage);
