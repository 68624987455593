/* eslint-disable @typescript-eslint/no-explicit-any */
import * as d3 from "d3";

import { buildEdgeLogicHTML } from "../EdgeLogic";

import { constrainXPosition, constrainYPosition } from "./constraints";

import { ChatViewMode, MapSizeData, D3Edge } from "../types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addEdgeElements = (
  svg: any,
  d3Edges: any,
  selectedNodeId: number | null,
  viewMode: ChatViewMode,
  mapSizeData: MapSizeData,
  mapWidth: number,
  mapHeight: number
) => {
  svg
    .append("defs")
    .append("marker")
    .attr("id", "edgeStart")
    .attr("viewBox", "0 0 10 10")
    .attr("refX", 0)
    .attr("refY", 0)
    .attr("orient", "auto")
    .attr("markerWidth", 6)
    .attr("markerHeight", 4)
    .attr("overflow", "visible")
    .append("svg:circle")
    .attr("r", "6")
    .style("fill", "white")
    .attr("stroke", "#4072DE")
    .attr("stroke-width", 2);

  svg
    .append("defs")
    .append("marker")
    .attr("id", "edgeEnd")
    .attr("viewBox", "0 0 10 10")
    .attr("refX", 12)
    .attr("refY", 5)
    .attr("orient", "auto")
    .attr("markerWidth", 6)
    .attr("markerHeight", 4)
    .attr("overflow", "visible")
    .append("svg:path")
    .attr("d", "M 0 0 L 10 5 L 0 10")
    .style("fill", "none")
    .attr("stroke", "#4072DE")
    .attr("stroke-width", 2);

  svg
    .selectAll(".edge")
    .data(d3Edges)
    .enter()
    .append("path")
    .attr("id", (e: D3Edge) => `${e.source}-${e.target}`)
    .attr("class", "edge")
    .attr("stroke", "#4072DE")
    .attr("stroke-width", (d: any) => {
      if (d.source === selectedNodeId) return 2;

      return 1.5;
    })
    .attr("opacity", (d: any) => {
      if (d.source === selectedNodeId) return 1;

      return 0.25;
    })
    .attr("fill", "none")
    .attr("marker-end", "url(#edgeEnd)")
    .attr("marker-start", "url(#edgeStart)")
    .style("z-index", "1");

  const edgeLogicHeight = 16;
  const edgeLogicWidth = mapSizeData.nodeWidth;

  svg
    .selectAll(".edgeLogic")
    .data(d3Edges)
    .enter()
    .append("foreignObject")
    .attr("class", "edgeLogic")
    .attr("x", (e: D3Edge) => {
      const targetX = e.targetPosition.x || 0;
      return constrainXPosition(targetX - mapSizeData.nodeWidth / 2, mapSizeData, mapWidth);
    })
    .attr("y", (e: D3Edge) => {
      const targetY = e.targetPosition.y || 0;
      return (
        constrainYPosition(targetY - mapSizeData.nodeHeight / 2, mapSizeData, mapHeight) -
        edgeLogicHeight -
        8
      );
    })
    .attr("width", edgeLogicWidth)
    .attr("height", edgeLogicHeight)
    .style("z-index", "10")
    .style("overflow", "visible")
    .append("xhtml:div")
    .style("width", "100%")
    .style("height", "100%")
    .style("position", "relative")
    .html((e: D3Edge) => {
      if (e.source === selectedNodeId) {
        return buildEdgeLogicHTML(e);
      }
      return null;
    });

  // Must return edge paths to render properly
  return svg.selectAll(".edge");
};
