import React from "react";

import { Task } from "../../../../../types";

type PropsType = {
  task: Task;
  children: React.ReactNode;
};

const TaskDetailsLayout = ({ task, children }: PropsType) => {
  return <div>{children}</div>;
};

export default TaskDetailsLayout;
