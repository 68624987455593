import { AnnouncementName, AnnouncementType } from "../../../types";

const orderedModalAnnouncements = [
  AnnouncementName.DEMO_EXPIRED,
  AnnouncementName.SCRIBE_WELCOME,
  AnnouncementName.DEMO_DAYS_REMAINING,
  AnnouncementName.NEW_SCRIBE_FEATURES
];
const orderedTooltipAnnouncements = [
  AnnouncementName.CREATE_UNINTEGRATED_APPOINTMENT_TUTORIAL,
  AnnouncementName.START_SCRIBE_SESSION_TUTORIAL,
  AnnouncementName.SCRIBE_MULTI_TEMPLATES,
  AnnouncementName.SCRIBE_FEEDBACK_TUTORIAL,
  AnnouncementName.SCRIBE_MULTI_LANGUAGE
];

type DisplayPriorityByAnnouncementName = { [announcementName: string]: number };

export const displayPriorityByAnnouncementName: DisplayPriorityByAnnouncementName = [
  ...orderedModalAnnouncements,
  ...orderedTooltipAnnouncements
].reduce<DisplayPriorityByAnnouncementName>((displayPriority, announcementName, index) => {
  // eslint-disable-next-line no-param-reassign
  displayPriority[announcementName as string] = index;

  return displayPriority;
}, {});

type DisplayTypeByAnnouncementName = { [announcementName: string]: AnnouncementType };

export const displayTypeByAnnouncementName: DisplayTypeByAnnouncementName = {};

orderedModalAnnouncements.forEach((announcementName) => {
  displayTypeByAnnouncementName[announcementName as string] = AnnouncementType.MODAL;
});
orderedTooltipAnnouncements.forEach((announcementName) => {
  displayTypeByAnnouncementName[announcementName as string] = AnnouncementType.TOOLTIP;
});
