import {
  FETCH_AUTOMATED_MESSAGES,
  FETCH_AUTOMATION,
  UPDATE_AUTOMATION,
  ADD_AUTOMATION,
  DELETE_AUTOMATION,
  GET_AUTOMATION_JOURNEY,
  CLEAR_AUTOMATION_JOURNEY
} from "../constants";

import {
  FetchAutomatedMessagesAction,
  FetchAutomationAction,
  UpdateAutomationAction,
  AddAutomationAction,
  DeleteAutomationAction,
  GetAutomationJourneyAction,
  ClearAutomationJourneyAction
} from "../actions";

import {
  ChatFlow,
  MessageSchedule,
  AutomationDashboard,
  AutomationDetails,
  AutomationJourneyDetails,
  ActionStatus
} from "../types";

export type AutomatedMessagesAction =
  | FetchAutomatedMessagesAction
  | FetchAutomationAction
  | AddAutomationAction
  | UpdateAutomationAction
  | DeleteAutomationAction
  | GetAutomationJourneyAction
  | ClearAutomationJourneyAction;

export type AutomatedMessagesReduxState = {
  automations: AutomationDashboard[];
  availableChats: ChatFlow[];
  checkInChats: ChatFlow[];
  messageSchedules: MessageSchedule[];
  automatedMessagesLoading: boolean;
  automationDetails?: AutomationDetails;
  automationDetailsLoading: boolean;
  automationAddLoading: boolean;
  automationDeleteLoading: boolean;
  automationJourneyDetails: AutomationJourneyDetails[] | undefined;
  automationJourneyDetailsLoading: boolean;
};

const initialAutomatedMessagesState: AutomatedMessagesReduxState = {
  automations: [],
  availableChats: [],
  checkInChats: [],
  messageSchedules: [],
  automatedMessagesLoading: true,
  automationDetails: undefined,
  automationDetailsLoading: false,
  automationAddLoading: false,
  automationDeleteLoading: false,
  automationJourneyDetails: undefined,
  automationJourneyDetailsLoading: false
};

export const automatedMessagesReducer = (
  state = initialAutomatedMessagesState,
  action: AutomatedMessagesAction
): AutomatedMessagesReduxState => {
  const { type } = action;
  switch (type) {
    case FETCH_AUTOMATED_MESSAGES: {
      const { status, payload } = action as FetchAutomatedMessagesAction;
      return {
        ...state,
        automations:
          payload && status === ActionStatus.success ? payload.automations : state.automations,
        availableChats:
          payload && status === ActionStatus.success
            ? payload.availableChats
            : state.availableChats,
        messageSchedules:
          payload && status === ActionStatus.success
            ? payload.messageSchedules
            : state.messageSchedules,
        automatedMessagesLoading: status === ActionStatus.loading
      };
    }

    case FETCH_AUTOMATION: {
      const { status, payload, checkInChats } = action as FetchAutomationAction;

      let newAutomationDetails;

      if (status === ActionStatus.success && payload) {
        newAutomationDetails = payload;
      } else if (status === ActionStatus.silentLoading) {
        newAutomationDetails = state.automationDetails;
      } else {
        newAutomationDetails = initialAutomatedMessagesState.automationDetails;
      }

      return {
        ...state,
        automationDetails: newAutomationDetails,
        automationDetailsLoading: status === ActionStatus.loading,
        checkInChats: checkInChats || []
      };
    }

    case ADD_AUTOMATION: {
      const { status } = action as AddAutomationAction;
      return {
        ...state,
        automationAddLoading: status === ActionStatus.loading
      };
    }

    case DELETE_AUTOMATION: {
      const { status } = action as DeleteAutomationAction;
      return {
        ...state,
        automationDeleteLoading: status === ActionStatus.loading,
        automationDetails:
          status === ActionStatus.success
            ? initialAutomatedMessagesState.automationDetails
            : state.automationDetails
      };
    }

    case UPDATE_AUTOMATION: {
      const { status, payload } = action as UpdateAutomationAction;
      const shouldUpdateAutomationDetails =
        !state.automationDetails || // automationDetails not populated
        (state.automationDetails && state.automationDetails.id === payload?.id); // updated automation id matches current automation in focus

      return {
        ...state,
        automationDetails:
          shouldUpdateAutomationDetails && status === ActionStatus.success && payload
            ? {
                ...payload,
                messageConfigurations: state.automationDetails?.messageConfigurations || []
              }
            : state.automationDetails,
        automationDetailsLoading: status === ActionStatus.loading
      };
    }

    case CLEAR_AUTOMATION_JOURNEY: {
      return {
        ...state,
        automationJourneyDetails: initialAutomatedMessagesState.automationJourneyDetails,
        automationJourneyDetailsLoading:
          initialAutomatedMessagesState.automationJourneyDetailsLoading
      };
    }

    case GET_AUTOMATION_JOURNEY: {
      const { status, payload } = action as GetAutomationJourneyAction;

      return {
        ...state,
        automationJourneyDetails:
          status === ActionStatus.success ? payload : state.automationJourneyDetails,
        automationJourneyDetailsLoading: status === ActionStatus.loading
      };
    }

    default:
      return state;
  }
};
