import axios from "axios";

import getToken from "../utils/getToken";

import { ROOT_URL, UPDATE_PATIENT_CONTACT_DETAILS } from "../constants";
import { ActionStatus, Dispatch } from "../types";
import { addNotification } from "./notifications";

export type PatientContactDetailsUpdateData = {
  id?: number;
  smsOptOut?: boolean;
  emailOptOut?: boolean;
  voiceOptOut?: boolean;
};

export type UpdatePatientContactDetailsAction = {
  type: typeof UPDATE_PATIENT_CONTACT_DETAILS;
  status?: ActionStatus;
  payload?: PatientContactDetailsUpdateData;
};

export const updatePatientContactDetails =
  (body: PatientContactDetailsUpdateData, onSuccess?: () => void) => async (dispatch: Dispatch) => {
    const config = {
      headers: { Authorization: `${getToken()}`, "Content-Type": "application/json" }
    };

    const { id: patientId, ...updateData } = body;

    dispatch({
      type: UPDATE_PATIENT_CONTACT_DETAILS,
      status: ActionStatus.loading
    });

    try {
      const response = await axios.patch(
        `${ROOT_URL}/users/patients/${patientId}/contactDetails`,
        updateData,
        config
      );

      dispatch({
        type: UPDATE_PATIENT_CONTACT_DETAILS,
        status: ActionStatus.success,
        payload: { ...response.data }
      });

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      dispatch(
        addNotification({
          type: "error",
          title: "Error updating patient contact details",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );

      return dispatch({
        type: UPDATE_PATIENT_CONTACT_DETAILS,
        status: ActionStatus.error
      });
    }
  };
