import React from "react";

import { useFormApi, useFormState } from "informed";
import Button from "../../Button";
import Heading from "../../Heading";
import Text from "../../Text";
import { TextAreaInput } from "../../Input";
import { ChevronLeft, Close } from "../../Icon";
import { ResponsiveHide } from "../../Responsive";
import { FeedbackSubmitButton } from "..";

import { FeedbackFormModes } from "../../../../typedConstants";
import { SetFeedbackModeData } from "../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  setFeedbackMode: (data: SetFeedbackModeData) => void;
};

type FeedbackFormData = {
  feedbackData: string;
  consent: boolean;
};

const ScribeRequestTemplateForm = ({ setFeedbackMode }: PropsType): JSX.Element => {
  const formState = useFormState();
  const formApi = useFormApi();
  const values = formState.values as FeedbackFormData;
  const { feedbackData } = values;
  return (
    <>
      <div className={styles.ReportIssueHeaderRow}>
        <ResponsiveHide hideOnMobile>
          <Button
            inline
            onClick={() => setFeedbackMode({ feedbackMode: FeedbackFormModes.SELECTION })}
            className={styles.FeedbackFormBackButton}
          >
            <ChevronLeft />
          </Button>
        </ResponsiveHide>
        <Heading size="S" bold className={styles.ReportIssueHeading}>
          Request a template
        </Heading>

        <ResponsiveHide hideOnMobile>
          <div className={styles.FeedbackFormCloseButtonWrapper}>
            <Button
              id="closeFeedbackForm"
              className={styles.CloseButton}
              type="button"
              inline
              onClick={() => setFeedbackMode({ feedbackMode: null })}
            >
              <Close size={20} />
            </Button>
          </div>
        </ResponsiveHide>
      </div>
      <div className={styles.ReportIssueContent}>
        <Text size="M" bold>
          What template would you like?
        </Text>

        <Text size="S">
          Give us more details about that. We may contact you if you can share us an example.
        </Text>

        <TextAreaInput
          size="M"
          fieldName="feedbackData"
          placeholder="Let us know more about the details"
          customOnChange={(event) => {
            formApi.setValue("feedbackData", event.feedbackData);
          }}
        />
      </div>
      <FeedbackSubmitButton disabled={!feedbackData} />
    </>
  );
};

export default ScribeRequestTemplateForm;
