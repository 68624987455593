/* eslint-disable @typescript-eslint/no-explicit-any */
import * as d3 from "d3";
import { addTooltip } from "./addToolTip";
import { constrainXPosition, constrainYPosition } from "./constraints";

import { ChatViewMode, MapSizeData, D3Node } from "../types";

import { getPrimaryCardColour } from "./getNodeStyles";

import { buildLargeMapNodeHTML } from "../LargeMapNode";

export const addNodeElements = (
  svg: any,
  d3Nodes: any,
  selectedNodeId: any,
  onNodeSelect: (nodeId: number | null) => void,
  viewMode: ChatViewMode,
  mapSizeData: MapSizeData,
  mapWidth: number,
  mapHeight: number
) => {
  switch (viewMode) {
    case ChatViewMode.MAP_LARGE: {
      svg
        .selectAll(".nodeRect")
        .data(d3Nodes)
        .enter()
        .append("rect")
        .attr("class", "nodeRect")
        .attr("width", mapSizeData.nodeWidth)
        .attr("height", mapSizeData.nodeHeight)
        .attr("rx", "10px")
        .attr("fill", (d: any) => {
          return getPrimaryCardColour(d.cardType);
        })
        .attr("opacity", 1)
        .attr("stroke-width", (d: any) => (d.id === selectedNodeId ? 2 : 0))
        .attr("stroke", (d: any) => {
          const tagNames = d.tagNames || [];

          if (d.id === selectedNodeId) return "#4072DE";

          if (tagNames.includes("completeChat")) {
            return "#54C08A";
          }

          return tagNames.length > 0 ? "#EF89E4" : "#1d263c";
        })
        .style("filter", "drop-shadow( 0px 1px 8px rgba(0, 0, 0, 0.08))");

      // Large Card
      svg
        .selectAll(".nodeContent")
        .data(d3Nodes)
        .enter()
        .append("foreignObject")
        .attr("class", "nodeContent")
        .attr("x", (d: D3Node) =>
          constrainXPosition(d.x - mapSizeData.nodeWidth / 2, mapSizeData, mapWidth)
        )
        .attr("y", (d: D3Node) =>
          constrainYPosition(d.y - mapSizeData.nodeHeight / 2, mapSizeData, mapHeight)
        )
        .attr("width", mapSizeData.nodeWidth)
        .attr("height", mapSizeData.nodeHeight)
        .append("xhtml:div")
        .style("width", "100%")
        .style("height", "100%")
        .style("position", "relative")
        .html((d: D3Node) => buildLargeMapNodeHTML(d));

      // Overlay to capture click events
      svg
        .selectAll(".nodeOverlay")
        .data(d3Nodes)
        .enter()
        .append("rect")
        .attr("class", "nodeOverlay")
        .attr("width", mapSizeData.nodeWidth)
        .attr("height", mapSizeData.nodeHeight)
        .attr("rx", "10px")
        .attr("opacity", 0)
        .attr("x", (d: D3Node) =>
          constrainXPosition(d.x - mapSizeData.nodeWidth / 2, mapSizeData, mapWidth)
        )
        .attr("y", (d: D3Node) =>
          constrainYPosition(d.y - mapSizeData.nodeHeight / 2, mapSizeData, mapHeight)
        )
        .on("click", (event: any, d: D3Node) => {
          return onNodeSelect(d.id || null);
        });

      // Must return outer rect to be positioned properly
      return svg.selectAll(".nodeRect");
    }
    case ChatViewMode.MAP_SMALL:
    default: {
      return svg
        .selectAll("rect")
        .data(d3Nodes)
        .enter()
        .append("rect")
        .attr("width", mapSizeData.nodeWidth)
        .attr("height", mapSizeData.nodeHeight)
        .attr("rx", "18px")
        .attr("fill", (d: any) => {
          return getPrimaryCardColour(d.cardType);
        })
        .attr("opacity", 0.75)
        .attr("stroke-width", (d: any) => (d.id === selectedNodeId ? 3 : 2))
        .attr("stroke", (d: any) => {
          const tagNames = d.tagNames || [];
          if (d.id === selectedNodeId) return "#4072DE";

          if (tagNames.includes("completeChat")) {
            return "#54C08A";
          }

          return tagNames.length > 0 ? "#EF89E4" : "#1d263c";
        })
        .style("filter", "drop-shadow( 0px 1px 8px rgba(0, 0, 0, 0.08))")
        .on("mouseover", (d: any, i: any) => addTooltip(d, i, "#tooltip"))
        .on("mousemove", (event: any) => {
          d3.select("#tooltip")
            .style("left", `${d3.pointer(event)[0]}px`)
            .style("top", `${d3.pointer(event)[1]}px`);
        })
        .on("mouseout", () => d3.select("#tooltip").style("opacity", 0))
        .on("click", (event: any, i: any) => onNodeSelect(i.id || null));
    }
  }
};
