import { MessagePlaceholders, MessageMediums, MessageTopics } from "../../../../constants";
import { combine, isRequired, hasNoOpenBrackets } from "../../../../utils/validators";

export type MessageTemplateFormState = {
  displayName: string;
  topic: string;
  payloadContent: string;
  medium: string;
  subject: string;
};

export const displayNameValidator = isRequired("Please enter a name");
export const payloadContentValidator = combine([
  isRequired("Please enter message content"),
  hasNoOpenBrackets()
]);
export const topicValidator = isRequired("Topic required");
export const subjectValidator = isRequired("Subject required");

export const formValidator = (values: MessageTemplateFormState) => {
  const formErrors: {
    displayName: string | undefined;
    topic: string | undefined;
    payloadContent: string | undefined;
    medium: string | undefined;
    subject: string | undefined;
  } = {
    displayName: displayNameValidator(values.displayName),
    topic: undefined,
    payloadContent: payloadContentValidator(values.payloadContent),
    medium: undefined,
    subject: undefined
  };

  if (
    values.topic !== MessageTopics.SCHEDULE_BASED_MESSAGE &&
    values.topic !== MessageTopics.APPOINTMENT_REQUEST_DIRECT_MESSAGE &&
    values.payloadContent?.includes("[link]")
  ) {
    formErrors.payloadContent = `You can only add a [link] placeholder after associating a chat.`;
  }

  const allowedPlaceholders = MessagePlaceholders;
  const foundPlaceholders = (values.payloadContent || "").match(/\[([^\]]*)\]/gm);
  const invalidPlaceholders: Array<string> = (foundPlaceholders || []).filter(
    (foundPlaceholder) =>
      !allowedPlaceholders.includes(foundPlaceholder) &&
      !(foundPlaceholder.substring(0, 4) === "[SMS") &&
      !(foundPlaceholder.substring(0, 6) === "[EMAIL")
  );
  if (invalidPlaceholders.length > 0) {
    formErrors.payloadContent = `Invalid placeholders: ${invalidPlaceholders.join(", ")}`;
  }
  if (values.medium === MessageMediums.EMAIL) {
    formErrors.subject = subjectValidator(values.subject);
  }

  return formErrors;
};
