import React from "react";

type PropsType = {
  size?: number;
};

const PipOff = ({ size = 24 }: PropsType) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={`${size}px`} height={`${size}px`} fill="none">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1 17a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3Zm8 0H3v3h6v-3Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1 6a3 3 0 0 1 3-3h16a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3h-6a1 1 0 1 1 0-2h6a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v6a1 1 0 1 1-2 0V6Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.349 8.173a1 1 0 0 1 1-1h4.345a1 1 0 0 1 1 1v4.346a1 1 0 1 1-2 0v-1.932l-3.814 3.814a1 1 0 1 1-1.414-1.414l3.814-3.814H13.35a1 1 0 0 1-1-1Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default PipOff;
