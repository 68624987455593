import React, { useState, useEffect } from "react";
import cx from "classnames";

import Text from "../../../Text";
import { Square, SquareCheck } from "../../../Icon";

import styles from "./index.module.scss";

type PropsType = {
  fieldName: string;
  id?: string;
  label?: string | React.ReactElement;
  initialValue?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
  labelClickable?: boolean;
};

const CheckboxInputBase = ({
  fieldName,
  id,
  label,
  initialValue = false,
  disabled = false,
  onChange: onChangeProp,
  labelClickable
}: PropsType): React.ReactElement => {
  const [checked, setChecked] = useState<boolean>(initialValue);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const changeValue = Boolean(event.target.checked);

    setChecked(changeValue);
    if (onChangeProp) {
      onChangeProp(changeValue);
    }
  };
  useEffect(() => {
    if (initialValue !== checked) setChecked(initialValue);
  }, [initialValue]);
  const inputId = `${id || fieldName}-input`;

  return (
    <div
      className={cx(styles.Container, {
        [styles.ContainerDisabled]: disabled
      })}
    >
      <label id={`${id || fieldName}-label`} htmlFor={inputId} className={styles.Checkbox}>
        <input
          id={inputId}
          className={styles.CheckboxInput}
          type="checkbox"
          name={fieldName}
          checked={checked}
          onChange={onChangeHandler}
          disabled={disabled}
        />
        <div
          className={cx(styles.Icon, {
            [styles.IconChecked]: checked,
            [styles.IconDisabled]: disabled
          })}
        >
          {checked ? <SquareCheck /> : <Square />}
        </div>
      </label>
      {label && (
        <Text
          className={cx(styles.Label, {
            [styles.LabelChecked]: checked,
            [styles.LabelDisabled]: disabled,
            [styles.LabelClickable]: labelClickable
          })}
          onClick={(): void => {
            if (labelClickable && onChangeProp) {
              setChecked(!checked);
              onChangeProp(!checked);
            }
          }}
        >
          {label}
        </Text>
      )}
    </div>
  );
};

export default CheckboxInputBase;
