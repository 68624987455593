import React from "react";

type PropsType = {
  size?: number;
  color?: string;
};

const UnorderedList = ({ size = 24, color = "currentColor" }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M4 21.5A1.5 1.5 0 1 1 5.5 23 1.5 1.5 0 0 1 4 21.5Zm6 1a1 1 0 0 1 0-2h13a1 1 0 0 1 0 2Zm-6-6A1.5 1.5 0 1 1 5.5 18 1.5 1.5 0 0 1 4 16.5Zm6 1a1 1 0 0 1 0-2h13a1 1 0 0 1 0 2Zm-6-6A1.5 1.5 0 1 1 5.5 13 1.5 1.5 0 0 1 4 11.5Zm6 1a1 1 0 0 1 0-2h13a1 1 0 0 1 0 2Zm-6-6A1.5 1.5 0 1 1 5.5 8 1.5 1.5 0 0 1 4 6.5Zm6 1a1 1 0 0 1 0-2h13a1 1 0 0 1 0 2Z"
        fill={color}
      />
    </svg>
  );
};

export default UnorderedList;
