import React from "react";
import cx from "classnames";
import Status from "../../ui/Status";
import Text from "../../ui/Text";
import Heading from "../../ui/Heading";

import styles from "./timelineEvent.module.scss";

import { StatusComponentConfigMap, HistoryEvent, HistoryEventChangeTypes } from "../../../types";
import {
  User,
  Provider,
  Calendar,
  MapPin,
  Clipboard,
  Clinic,
  MikataSimple,
  UserUnknown,
  ArrowRight,
  Phone,
  Mail,
  Phn
} from "../../ui/Icon";
import {
  AppointmentStates,
  AppointmentCheckedInStatuses,
  appointmentRequestStatusConfigMap,
  apptRequestStatusOptions
} from "../../../constants";
import { UserTypeConstants } from "../../../typedConstants";

type PropsType = {
  event: HistoryEvent;
};

const statusOptions = [
  {
    label: AppointmentStates.RESCHEDULE.label,
    value: AppointmentStates.RESCHEDULE.name
  },
  {
    label: AppointmentStates.CONFIRMED.label,
    value: AppointmentStates.CONFIRMED.name
  },
  {
    label: AppointmentStates.UNCLEAR.label,
    value: AppointmentStates.UNCLEAR.name
  },
  {
    label: AppointmentStates.NEED_CONFIRM.label,
    value: AppointmentStates.NEED_CONFIRM.name
  },
  {
    label: AppointmentStates.CONTACTED.label,
    value: AppointmentStates.CONTACTED.name
  },
  {
    label: AppointmentStates.COMPLETE.label,
    value: AppointmentStates.COMPLETE.name
  },
  {
    label: AppointmentStates.NEEDS_CALL.label,
    value: AppointmentStates.NEEDS_CALL.name
  },
  {
    label: AppointmentStates.PENDING.label,
    value: AppointmentStates.PENDING.name
  },
  {
    label: AppointmentStates.NONE.label,
    value: AppointmentStates.NONE.name
  },
  {
    label: AppointmentStates.ON_HOLD.label,
    value: AppointmentStates.ON_HOLD.name
  }
];

const checkedInOptions = [
  {
    value: AppointmentCheckedInStatuses.NONE.name,
    label: AppointmentCheckedInStatuses.NONE.label
  },
  {
    value: AppointmentCheckedInStatuses.CHECKED_IN.name,
    label: AppointmentCheckedInStatuses.CHECKED_IN.label
  },
  {
    value: AppointmentCheckedInStatuses.NOTICE_SENT.name,
    label: AppointmentCheckedInStatuses.NOTICE_SENT.label
  },
  {
    value: AppointmentCheckedInStatuses.NO_CONTACT_INFO.name,
    label: AppointmentCheckedInStatuses.NO_CONTACT_INFO.label
  },
  {
    value: AppointmentCheckedInStatuses.STAFF_CONTACTED.name,
    label: AppointmentCheckedInStatuses.STAFF_CONTACTED.label
  },
  {
    value: AppointmentCheckedInStatuses.UNCLEAR.name,
    label: AppointmentCheckedInStatuses.UNCLEAR.label
  }
];

const statusConfig: StatusComponentConfigMap = {
  [AppointmentStates.RESCHEDULE.name]: "yellow",
  [AppointmentStates.CONFIRMED.name]: "blue",
  [AppointmentStates.UNCLEAR.name]: "gray",
  [AppointmentStates.NEED_CONFIRM.name]: "red",
  [AppointmentStates.CONTACTED.name]: "green",
  [AppointmentStates.COMPLETE.name]: "green",
  [AppointmentStates.NEEDS_CALL.name]: "red",
  [AppointmentStates.PENDING.name]: "gray",
  [AppointmentStates.NONE.name]: "gray",
  [AppointmentStates.ON_HOLD.name]: "yellow"
};

const checkedInConfig: StatusComponentConfigMap = {
  [AppointmentCheckedInStatuses.NONE.name]: "gray",
  [AppointmentCheckedInStatuses.CHECKED_IN.name]: "blue",
  [AppointmentCheckedInStatuses.NOTICE_SENT.name]: "gray",
  [AppointmentCheckedInStatuses.NO_CONTACT_INFO.name]: "red",
  [AppointmentCheckedInStatuses.STAFF_CONTACTED.name]: "green",
  [AppointmentCheckedInStatuses.UNCLEAR.name]: "red"
};

const logo = (type: UserTypeConstants): JSX.Element => {
  if (type === UserTypeConstants.PATIENT) {
    return <User size={15} />;
  }

  if (type === UserTypeConstants.PRACTITIONER || type === UserTypeConstants.STAFF) {
    return <Clinic size={15} />;
  }

  if (type === UserTypeConstants.MIKATA_ADMIN) {
    return <MikataSimple size={15} />;
  }

  return <UserUnknown size={15} />;
};

const TimelineEvent = ({ event }: PropsType): JSX.Element | null => {
  return (
    <>
      <div className={styles.FlexRow}>
        <span className={styles.IconBorder}>{logo(event.initiatedByType)}</span>
        <Text bold size="S" className={styles.MarginRight}>
          {event.initiatedBy}
        </Text>
        <Heading size="META" align="center" className={styles.Heading}>
          {event.eventTime}
        </Heading>
      </div>

      {event.changeType === HistoryEventChangeTypes.APPOINTMENT_CREATION && (
        <div className={styles.EventRow}>
          <Text> {event.data.description} </Text>
          <div className={styles.FlexRowPadded}>
            <div className={styles.FlexColPadded}>
              <div className={cx(styles.FlexRow, styles.MarginBottom)}>
                <span className={cx(styles.Icon, styles.MarginRight)}>
                  <Provider size={15} />
                </span>
                <Text size="S" className={styles.CardText}>
                  {event.data.new.practitioner}
                </Text>
              </div>
              <div className={cx(styles.FlexRow, styles.MarginBottom)}>
                <span className={cx(styles.Icon, styles.MarginRight)}>
                  <MapPin size={15} />
                </span>
                <Text size="S" className={styles.CardText}>
                  {event.data.new.location}
                </Text>
              </div>
            </div>

            <div className={styles.FlexColPadded}>
              <div className={cx(styles.FlexRow, styles.MarginBottom)}>
                <span className={cx(styles.Icon, styles.MarginRight)}>
                  <Calendar size={15} />
                </span>
                <Text size="S" className={styles.CardText}>
                  {event.data.new.start}
                </Text>
              </div>
              <div className={cx(styles.FlexRow, styles.MarginBottom)}>
                <span className={cx(styles.Icon, styles.MarginRight)}>
                  <Clipboard size={15} />
                </span>
                <Text size="S" className={styles.CardText}>
                  {event.data.new.reason}
                </Text>
              </div>
            </div>
          </div>
        </div>
      )}

      {event.changeType === HistoryEventChangeTypes.APPOINTMENT_DATE && (
        <div className={styles.EventRow}>
          <Text className={styles.MarginRight}> {event.data.description} </Text>
          <div className={styles.FlexRow}>
            <span className={cx(styles.Icon)}>
              <Calendar size={15} />
            </span>
            <Text size="S" className={styles.TextCrossed}>
              {event.data.old?.start}
            </Text>
            <Text size="S">{event.data.new?.start}</Text>
          </div>
        </div>
      )}

      {event.changeType === HistoryEventChangeTypes.APPOINTMENT_PRACTITIONER && (
        <div className={styles.EventRow}>
          <Text className={styles.MarginRight}> {event.data.description} </Text>
          <div className={styles.FlexRow}>
            <span className={cx(styles.Icon)}>
              <Provider size={15} />
            </span>
            <Text size="S" className={styles.TextCrossed}>
              {event.data.old?.practitioner}
            </Text>
            <Text size="S">{event.data.new?.practitioner}</Text>
          </div>
        </div>
      )}

      {event.changeType === HistoryEventChangeTypes.APPOINTMENT_LOCATION && (
        <div className={styles.EventRow}>
          <Text className={styles.MarginRight}> {event.data.description} </Text>
          <div className={styles.FlexRow}>
            <span className={cx(styles.Icon)}>
              <MapPin size={15} />
            </span>
            <Text size="S" className={styles.TextCrossed}>
              {event.data.old?.location}
            </Text>
            <Text size="S">{event.data.new?.location}</Text>
          </div>
        </div>
      )}

      {event.changeType === HistoryEventChangeTypes.APPOINTMENT_REASON && (
        <div className={styles.EventRow}>
          <Text className={styles.MarginRight}> {event.data.description} </Text>
          <div className={styles.FlexRow}>
            <span className={cx(styles.Icon)}>
              <Clipboard size={15} />
            </span>
            <Text size="S" className={styles.TextCrossed}>
              {event.data.old?.reason}
            </Text>
            <Text size="S">{event.data.new?.reason}</Text>
          </div>
        </div>
      )}

      {event.changeType === HistoryEventChangeTypes.APPOINTMENT_STATUS && (
        <div className={styles.EventRow}>
          <Text className={styles.MarginRight}> {event.data.description} </Text>
          <div className={cx(styles.Icon, styles.MarginRight)}>
            <Status
              className={styles.MarginRight}
              value={event.data.old?.status || ""}
              options={statusOptions}
              configMap={statusConfig}
            />
            <div className={cx(styles.Icon, styles.MarginRight)}>
              <ArrowRight />
            </div>
            <Status
              value={event.data.new?.status || ""}
              options={statusOptions}
              configMap={statusConfig}
            />
          </div>
        </div>
      )}

      {event.changeType === HistoryEventChangeTypes.APPOINTMENT_CHECK_IN_STATUS && (
        <div className={styles.EventRow}>
          <Text className={styles.MarginRight}> {event.data.description} </Text>
          <div className={cx(styles.Icon, styles.MarginRight)}>
            <Status
              className={styles.MarginRight}
              value={event.data.old?.checkedIn || ""}
              options={checkedInOptions}
              configMap={checkedInConfig}
            />
            <div className={cx(styles.Icon, styles.MarginRight)}>
              <ArrowRight />
            </div>
            <Status
              value={event.data.new?.checkedIn || ""}
              options={checkedInOptions}
              configMap={checkedInConfig}
            />
          </div>
        </div>
      )}

      {(event.changeType === HistoryEventChangeTypes.APPOINTMENT_CANCELLED ||
        event.changeType === HistoryEventChangeTypes.APPOINTMENT_DELETED ||
        event.changeType === HistoryEventChangeTypes.APPOINTMENT_COMPLETED_ON ||
        event.changeType === HistoryEventChangeTypes.APPOINTMENT_FILTERED_IN) && (
        <div className={styles.EventRow}>
          <Text> {event.data.description} </Text>
        </div>
      )}

      {event.changeType === HistoryEventChangeTypes.PATIENT_CREATION && (
        <div className={styles.EventRow}>
          <Text>
            {event.data.description} {event.data.new?.patientName}
          </Text>
          <div className={styles.FlexRowPadded}>
            <div className={styles.FlexColPadded}>
              <div className={cx(styles.FlexRow, styles.MarginBottom)}>
                <span className={cx(styles.Icon, styles.MarginRight)}>
                  <User size={15} />
                </span>
                <Text size="S" className={styles.CardText}>
                  {event.data.new.patientName}
                </Text>
              </div>
              <div className={cx(styles.FlexRow, styles.MarginBottom)}>
                <span className={cx(styles.Icon, styles.MarginRight)}>
                  <Mail size={15} />
                </span>
                <Text size="S" className={styles.CardText}>
                  {event.data.new.patientEmail}
                </Text>
              </div>
            </div>

            <div className={styles.FlexColPadded}>
              <div className={cx(styles.FlexRow, styles.MarginBottom)}>
                <span className={cx(styles.Icon, styles.MarginRight)}>
                  <Phone size={15} />
                </span>
                <Text size="S" className={styles.CardText}>
                  {event.data.new.patientMobile}
                </Text>
              </div>
              <div className={cx(styles.FlexRow, styles.MarginBottom)}>
                <span className={cx(styles.Icon, styles.MarginRight)}>
                  <Phn size={15} />
                </span>
                <Text size="S" className={styles.CardText}>
                  {event.data.new.healthcareIdentifier}
                </Text>
              </div>
            </div>
          </div>
        </div>
      )}

      {event.changeType === HistoryEventChangeTypes.PATIENT_MOBILE && (
        <div className={styles.EventRow}>
          <Text className={styles.MarginRight}> {event.data.description} </Text>
          <div className={styles.FlexRow}>
            <span className={cx(styles.Icon)}>
              <Phone size={15} />
            </span>
            <Text size="S" className={styles.TextCrossed}>
              {event.data.old?.patientMobile}
            </Text>
            <Text size="S">{event.data.new.patientMobile}</Text>
          </div>
        </div>
      )}

      {event.changeType === HistoryEventChangeTypes.PATIENT_HEALTH_CARE_IDENTIFIER && (
        <div className={styles.EventRow}>
          <Text className={styles.MarginRight}> {event.data.description} </Text>
          <div className={styles.FlexRow}>
            <span className={cx(styles.Icon)}>
              <Phn size={15} />
            </span>
            <Text size="S" className={styles.TextCrossed}>
              {event.data.old?.healthcareIdentifier}
            </Text>
            <Text size="S">{event.data.new.healthcareIdentifier}</Text>
          </div>
        </div>
      )}

      {event.changeType === HistoryEventChangeTypes.PATIENT_EMAIL && (
        <div className={styles.EventRow}>
          <Text className={styles.MarginRight}> {event.data.description} </Text>
          <div className={styles.FlexRow}>
            <span className={cx(styles.Icon)}>
              <Mail size={15} />
            </span>
            <Text size="S" className={styles.TextCrossed}>
              {event.data.old?.patientEmail}
            </Text>
            <Text size="S">{event.data.new.patientEmail}</Text>
          </div>
        </div>
      )}

      {event.changeType === HistoryEventChangeTypes.PATIENT_SMS_OPT_OUT && (
        <div className={styles.EventRow}>
          <Text className={styles.MarginRight}> {event.data.description} </Text>
        </div>
      )}

      {event.changeType === HistoryEventChangeTypes.PATIENT_EMAIL_OPT_OUT && (
        <div className={styles.EventRow}>
          <Text className={styles.MarginRight}> {event.data.description} </Text>
        </div>
      )}

      {event.changeType === HistoryEventChangeTypes.PATIENT_VOICE_OPT_OUT && (
        <div className={styles.EventRow}>
          <Text className={styles.MarginRight}> {event.data.description} </Text>
        </div>
      )}

      {event.changeType === HistoryEventChangeTypes.PATIENT_SURVEY_OPT_OUT && (
        <div className={styles.EventRow}>
          <Text className={styles.MarginRight}> {event.data.description} </Text>
        </div>
      )}

      {event.changeType === HistoryEventChangeTypes.PATIENT_WELCOME_MESSAGE && (
        <div className={styles.EventRow}>
          <Text className={styles.MarginRight}> {event.data.description} </Text>
        </div>
      )}

      {event.changeType === HistoryEventChangeTypes.APPOINTMENT_PATIENT_ID && (
        <>
          <div className={styles.EventRow}>
            <Text className={cx(styles.MarginRight, styles.HawaiianTan)}>
              {event.data.description}
            </Text>
            <div className={cx(styles.HawaiianTan, styles.MarginTop)}>
              <User size={15} />
            </div>
            <Text size="S" className={styles.TextCrossed}>
              {event.data.old?.patientName}
            </Text>
            <Text size="S" className={styles.HawaiianTan}>
              {event.data.new.patientName} (ID: {event.data.new.emrPatientId})
            </Text>
          </div>
          <div className={cx(styles.FlexRowPaddedColored, styles.EventRow, styles.MarginLeft)}>
            <div className={styles.FlexColPadded}>
              <div className={cx(styles.FlexRow, styles.MarginBottom)}>
                <span className={cx(styles.Icon, styles.MarginRight)}>
                  <User size={15} />
                </span>
                <Text size="S" className={styles.CardText}>
                  {event.data.new.patientName}
                </Text>
              </div>
              <div className={styles.FlexRow}>
                <span className={cx(styles.Icon, styles.MarginRight)}>
                  <Mail size={15} />
                </span>
                <Text size="S" className={styles.CardText}>
                  {event.data.new.patientEmail}
                </Text>
              </div>
            </div>

            <div className={styles.FlexColPadded}>
              <div className={cx(styles.FlexRow, styles.MarginBottom)}>
                <span className={cx(styles.Icon, styles.MarginRight)}>
                  <Phone size={15} />
                </span>
                <Text size="S" className={styles.CardText}>
                  {event.data.new.patientMobile}
                </Text>
              </div>
              <div className={styles.FlexRow}>
                <span className={cx(styles.Icon, styles.MarginRight)}>
                  <Phn size={15} />
                </span>
                <Text size="S" className={styles.CardText}>
                  {event.data.new.healthcareIdentifier}
                </Text>
              </div>
            </div>
          </div>
        </>
      )}

      {event.changeType === HistoryEventChangeTypes.REQUEST_CREATION && (
        <div className={styles.EventRow}>
          <Text className={styles.MarginRight}> {event.data.description} </Text>
        </div>
      )}

      {event.changeType === HistoryEventChangeTypes.REQUEST_STATUS && (
        <div className={styles.EventRow}>
          <Text className={styles.MarginRight}> {event.data.description} </Text>
          <div className={cx(styles.Icon, styles.MarginRight)}>
            <Status
              className={styles.MarginRight}
              value={event.data.old?.status || ""}
              options={apptRequestStatusOptions}
              configMap={appointmentRequestStatusConfigMap}
            />
            <div className={cx(styles.Icon, styles.MarginRight)}>
              <ArrowRight />
            </div>
            <Status
              value={event.data.new?.status || ""}
              options={apptRequestStatusOptions}
              configMap={appointmentRequestStatusConfigMap}
            />
          </div>
        </div>
      )}

      {event.changeType === HistoryEventChangeTypes.REQUEST_ASSIGNED && (
        <div className={styles.EventRow}>
          <Text className={styles.MarginRight}> {event.data.description} </Text>
        </div>
      )}

      {event.changeType === HistoryEventChangeTypes.REQUEST_ASSIGNEE && (
        <div className={styles.EventRow}>
          <Text className={styles.MarginRight}> {event.data.description} </Text>
          <div className={styles.FlexRow}>
            <Text size="S" className={styles.TextCrossed}>
              {event.data.old?.assignedStaffUserName}
            </Text>
            <Text size="S">{event.data.new?.assignedStaffUserName}</Text>
          </div>
        </div>
      )}

      {event.changeType === HistoryEventChangeTypes.REQUEST_SUBMITTED && (
        <div className={styles.EventRow}>
          <Text className={styles.MarginRight}> {event.data.description} </Text>
        </div>
      )}

      {event.changeType === HistoryEventChangeTypes.REQUEST_PROCESSED && (
        <div className={styles.EventRow}>
          <Text className={styles.MarginRight}> {event.data.description} </Text>
        </div>
      )}
    </>
  );
};

export default TimelineEvent;
