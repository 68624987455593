import { InputValue } from "../../types";

export const hasMinCharacters =
  (minCharacters: number, message?: string) => (value: InputValue) => {
    const errorMessage = message || `Insufficient characters`;

    const stringValue = value ? value.toString() : "";
    const numberOfCharacters = stringValue.length;

    return numberOfCharacters < minCharacters ? errorMessage : undefined;
  };
