import React from "react";

type PropsType = {
  size?: number;
};

const GeneralErrorGraphic = ({ size = 400 }: PropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}`}
      height={`${size * (300 / 400)}`}
      viewBox="0 0 400 300"
    >
      <g clipPath="url(#a)">
        <rect
          x="1"
          y="1"
          width="398"
          height="298"
          rx="9"
          fill="#FAFAF9"
          stroke="#F2F2F3"
          strokeWidth="2"
        />
        <path d="M0 10C0 4.477 4.477 0 10 0h380c5.523 0 10 4.477 10 10v18H0V10Z" fill="#DEE1E6" />
        <circle cx="18" cy="14" r="6" fill="#FF6058" />
        <circle cx="38" cy="14" r="6" fill="#FFC130" />
        <circle cx="58" cy="14" r="6" fill="#27CA40" />
        <path fill="#F2F2F3" d="M0 28h400v28H0zM0 56h24v244H10c-5.523 0-10-4.477-10-10V56Z" />
        <rect
          x="53"
          y="145"
          width="318"
          height="128"
          rx="9"
          fill="#FAFAF9"
          stroke="#F2F2F3"
          strokeWidth="2"
        />
        <rect x="68" y="161" width="80" height="10" rx="5" fill="#F2F2F3" />
        <rect x="160" y="161" width="80" height="10" rx="5" fill="#F2F2F3" />
        <rect x="252" y="161" width="80" height="10" rx="5" fill="#F2F2F3" />
        <rect x="344" y="161" width="16" height="10" rx="5" fill="#F2F2F3" />
        <rect x="68" y="205" width="80" height="10" rx="5" fill="#F2F2F3" />
        <rect x="160" y="205" width="80" height="10" rx="5" fill="#F2F2F3" />
        <rect x="252" y="205" width="80" height="10" rx="5" fill="#F2F2F3" />
        <rect x="344" y="205" width="16" height="10" rx="5" fill="#F2F2F3" />
        <rect x="68" y="183" width="80" height="10" rx="5" fill="#F2F2F3" />
        <rect x="160" y="183" width="80" height="10" rx="5" fill="#F2F2F3" />
        <rect x="252" y="183" width="80" height="10" rx="5" fill="#F2F2F3" />
        <rect x="344" y="183" width="16" height="10" rx="5" fill="#F2F2F3" />
        <rect x="68" y="227" width="80" height="10" rx="5" fill="#F2F2F3" />
        <rect x="160" y="227" width="80" height="10" rx="5" fill="#F2F2F3" />
        <rect x="252" y="227" width="80" height="10" rx="5" fill="#F2F2F3" />
        <rect x="344" y="227" width="16" height="10" rx="5" fill="#F2F2F3" />
        <rect x="68" y="249" width="80" height="10" rx="5" fill="#F2F2F3" />
        <rect x="160" y="249" width="80" height="10" rx="5" fill="#F2F2F3" />
        <rect x="252" y="249" width="80" height="10" rx="5" fill="#F2F2F3" />
        <rect x="344" y="249" width="16" height="10" rx="5" fill="#F2F2F3" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M200 76c13.255 0 24 10.745 24 24s-10.745 24-24 24-24-10.745-24-24 10.745-24 24-24Zm0 3.429c-11.361 0-20.571 9.21-20.571 20.571s9.21 20.571 20.571 20.571 20.571-9.21 20.571-20.571-9.21-20.571-20.571-20.571ZM200 86a2 2 0 0 0-2 2v16a2 2 0 1 0 4 0V88a2 2 0 0 0-2-2Zm-1.414 24.586a2 2 0 1 0 2.828 2.828 2 2 0 0 0-2.828-2.828Z"
          fill="#D7D8DB"
        />
        <circle cx="12" cy="122" r="6" fill="#FAFAF9" />
        <circle cx="12" cy="150" r="6" fill="#FAFAF9" />
        <circle cx="12" cy="178" r="6" fill="#FAFAF9" />
        <circle cx="12" cy="206" r="6" fill="#FAFAF9" />
        <circle cx="12" cy="234" r="6" fill="#FAFAF9" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h400v300H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GeneralErrorGraphic;
