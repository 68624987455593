import React from "react";

type PropsType = {
  size?: number;
  rating: number;
};

const ScribeRatingSelected = ({ size = 60, rating }: PropsType) => {
  const ratingSelectedIcon = (ratingValue: number) => {
    switch (ratingValue) {
      case 1:
        return (
          <svg
            width={`${size}px`}
            height={`${size}px`}
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="88.5"
              cy="88.5"
              r="88.5"
              transform="matrix(-1 0 0 1 189 12)"
              fill="#5DF5C9"
            />
            <path
              d="M98.2066 143.634C98.9403 142.147 101.06 142.147 101.794 143.634L108.352 156.922C108.643 157.512 109.206 157.921 109.857 158.016L124.521 160.147C126.162 160.385 126.817 162.401 125.63 163.558L115.019 173.901C114.548 174.361 114.332 175.023 114.444 175.672L116.949 190.276C117.229 191.91 115.514 193.156 114.047 192.385L100.931 185.489C100.348 185.183 99.6521 185.183 99.0694 185.489L85.9535 192.385C84.4863 193.156 82.7714 191.91 83.0516 190.276L85.5565 175.672C85.6678 175.023 85.4527 174.361 84.9813 173.901L74.3703 163.558C73.1833 162.401 73.8383 160.385 75.4787 160.147L90.1428 158.016C90.7942 157.921 91.3574 157.512 91.6487 156.922L98.2066 143.634Z"
              fill="#FFD440"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M97.7043 143.386C98.6433 141.483 101.357 141.483 102.296 143.386L108.854 156.674C109.063 157.099 109.469 157.393 109.938 157.462L124.602 159.592C126.702 159.898 127.54 162.478 126.021 163.959L115.41 174.302C115.07 174.633 114.915 175.11 114.995 175.577L117.5 190.182C117.859 192.273 115.664 193.868 113.786 192.88L100.67 185.985C100.251 185.764 99.7494 185.764 99.3298 185.985L86.2139 192.88C84.3358 193.868 82.1408 192.273 82.4995 190.182L85.0044 175.577C85.0845 175.11 84.9297 174.633 84.5903 174.302L73.9793 163.959C72.4599 162.478 73.2983 159.898 75.398 159.592L90.0621 157.462C90.5311 157.393 90.9366 157.099 91.1463 156.674L97.7043 143.386ZM101.291 143.882C100.763 142.811 99.2369 142.811 98.7086 143.882L92.1507 157.17C91.7778 157.925 91.057 158.449 90.2231 158.57L75.5591 160.701C74.378 160.872 73.9064 162.324 74.761 163.157L85.372 173.5C85.9754 174.088 86.2507 174.936 86.1083 175.766L83.6034 190.371C83.4016 191.547 84.6363 192.444 85.6927 191.889L98.8087 184.993C99.5545 184.601 100.445 184.601 101.191 184.993L114.307 191.889C115.364 192.444 116.598 191.547 116.397 190.371L113.892 175.766C113.749 174.936 114.024 174.088 114.628 173.5L125.239 163.157C126.094 162.324 125.622 160.872 124.441 160.701L109.777 158.57C108.943 158.449 108.222 157.925 107.849 157.17L101.291 143.882Z"
              fill="#FFD440"
            />
            <path
              d="M45 90H75V99C75 107.284 68.2843 114 60 114C51.7157 114 45 107.284 45 99V90Z"
              fill="white"
            />
            <path
              d="M121 90H151V99C151 107.284 144.284 114 136 114C127.716 114 121 107.284 121 99V90Z"
              fill="white"
            />
            <path
              d="M45 90H64V98.5C64 103.747 59.7467 108 54.5 108C49.2533 108 45 103.747 45 98.5V90Z"
              fill="#003125"
            />
            <path
              d="M121 90H140V98.5C140 103.747 135.747 108 130.5 108C125.253 108 121 103.747 121 98.5V90Z"
              fill="#003125"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M44 90.5C44 89.3954 44.8954 88.5 46 88.5H80C81.1046 88.5 82 89.3954 82 90.5C82 91.6046 81.1046 92.5 80 92.5H46C44.8954 92.5 44 91.6046 44 90.5Z"
              fill="#003125"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M119 90.5C119 89.3954 119.895 88.5 121 88.5H155C156.105 88.5 157 89.3954 157 90.5C157 91.6046 156.105 92.5 155 92.5H121C119.895 92.5 119 91.6046 119 90.5Z"
              fill="#003125"
            />
          </svg>
        );
      case 2:
        return (
          <svg
            width={`${size}px`}
            height={`${size}px`}
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="88.5"
              cy="88.5"
              r="88.5"
              transform="matrix(-1 0 0 1 189 12)"
              fill="#5DF5C9"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M44 90.5C44 89.3954 44.8954 88.5 46 88.5H80C81.1046 88.5 82 89.3954 82 90.5C82 91.6046 81.1046 92.5 80 92.5H46C44.8954 92.5 44 91.6046 44 90.5Z"
              fill="#003125"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M119 90.5C119 89.3954 119.895 88.5 121 88.5H155C156.105 88.5 157 89.3954 157 90.5C157 91.6046 156.105 92.5 155 92.5H121C119.895 92.5 119 91.6046 119 90.5Z"
              fill="#003125"
            />
            <path
              d="M60.2066 143.634C60.9403 142.147 63.06 142.147 63.7936 143.634L70.3516 156.922C70.6429 157.512 71.206 157.921 71.8574 158.016L86.5215 160.147C88.1619 160.385 88.8169 162.401 87.6299 163.558L77.0189 173.901C76.5475 174.361 76.3324 175.023 76.4437 175.672L78.9486 190.276C79.2289 191.91 77.514 193.156 76.0467 192.385L62.9308 185.489C62.3482 185.183 61.6521 185.183 61.0694 185.489L47.9535 192.385C46.4863 193.156 44.7714 191.91 45.0516 190.276L47.5565 175.672C47.6678 175.023 47.4527 174.361 46.9813 173.901L36.3703 163.558C35.1833 162.401 35.8383 160.385 37.4787 160.147L52.1428 158.016C52.7942 157.921 53.3574 157.512 53.6487 156.922L60.2066 143.634Z"
              fill="#FFD440"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M59.7043 143.386C60.6433 141.483 63.3565 141.483 64.2956 143.386L70.8535 156.674C71.0633 157.099 71.4688 157.393 71.9378 157.462L86.6018 159.592C88.7016 159.898 89.54 162.478 88.0206 163.959L77.4096 174.302C77.0702 174.633 76.9154 175.11 76.9955 175.577L79.5004 190.182C79.8591 192.273 77.6641 193.868 75.786 192.88L62.67 185.985C62.2505 185.764 61.7494 185.764 61.3298 185.985L48.2139 192.88C46.3358 193.868 44.1408 192.273 44.4995 190.182L47.0044 175.577C47.0845 175.11 46.9297 174.633 46.5903 174.302L35.9793 163.959C34.4599 162.478 35.2983 159.898 37.398 159.592L52.0621 157.462C52.5311 157.393 52.9366 157.099 53.1463 156.674L59.7043 143.386ZM63.2912 143.882C62.763 142.811 61.2369 142.811 60.7086 143.882L54.1507 157.17C53.7778 157.925 53.057 158.449 52.2231 158.57L37.5591 160.701C36.378 160.872 35.9064 162.324 36.761 163.157L47.372 173.5C47.9754 174.088 48.2507 174.936 48.1083 175.766L45.6034 190.371C45.4016 191.547 46.6363 192.444 47.6927 191.889L60.8087 184.993C61.5545 184.601 62.4454 184.601 63.1912 184.993L76.3071 191.889C77.3636 192.444 78.5983 191.547 78.3965 190.371L75.8916 175.766C75.7492 174.936 76.0245 174.088 76.6278 173.5L87.2388 163.157C88.0935 162.324 87.6219 160.872 86.4408 160.701L71.7767 158.57C70.9429 158.449 70.2221 157.925 69.8492 157.17L63.2912 143.882Z"
              fill="#FFD440"
            />
            <path
              d="M136.207 143.634C136.94 142.147 139.06 142.147 139.794 143.634L146.352 156.922C146.643 157.512 147.206 157.921 147.857 158.016L162.521 160.147C164.162 160.385 164.817 162.401 163.63 163.558L153.019 173.901C152.548 174.361 152.332 175.023 152.444 175.672L154.949 190.276C155.229 191.91 153.514 193.156 152.047 192.385L138.931 185.489C138.348 185.183 137.652 185.183 137.069 185.489L123.954 192.385C122.486 193.156 120.771 191.91 121.052 190.276L123.557 175.672C123.668 175.023 123.453 174.361 122.981 173.901L112.37 163.558C111.183 162.401 111.838 160.385 113.479 160.147L128.143 158.016C128.794 157.921 129.357 157.512 129.649 156.922L136.207 143.634Z"
              fill="#FFD440"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M135.704 143.386C136.643 141.483 139.357 141.483 140.296 143.386L146.854 156.674C147.063 157.099 147.469 157.393 147.938 157.462L162.602 159.592C164.702 159.898 165.54 162.478 164.021 163.959L153.41 174.302C153.07 174.633 152.915 175.11 152.995 175.577L155.5 190.182C155.859 192.273 153.664 193.868 151.786 192.88L138.67 185.985C138.251 185.764 137.749 185.764 137.33 185.985L124.214 192.88C122.336 193.868 120.141 192.273 120.499 190.182L123.004 175.577C123.085 175.11 122.93 174.633 122.59 174.302L111.979 163.959C110.46 162.478 111.298 159.898 113.398 159.592L128.062 157.462C128.531 157.393 128.937 157.099 129.146 156.674L135.704 143.386ZM139.291 143.882C138.763 142.811 137.237 142.811 136.709 143.882L130.151 157.17C129.778 157.925 129.057 158.449 128.223 158.57L113.559 160.701C112.378 160.872 111.906 162.324 112.761 163.157L123.372 173.5C123.975 174.088 124.251 174.936 124.108 175.766L121.603 190.371C121.402 191.547 122.636 192.444 123.693 191.889L136.809 184.993C137.554 184.601 138.445 184.601 139.191 184.993L152.307 191.889C153.364 192.444 154.598 191.547 154.397 190.371L151.892 175.766C151.749 174.936 152.024 174.088 152.628 173.5L163.239 163.157C164.094 162.324 163.622 160.872 162.441 160.701L147.777 158.57C146.943 158.449 146.222 157.925 145.849 157.17L139.291 143.882Z"
              fill="#FFD440"
            />
          </svg>
        );
      case 3:
        return (
          <svg
            width={`${size}px`}
            height={`${size}px`}
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="88.5"
              cy="88.5"
              r="88.5"
              transform="matrix(-1 0 0 1 189 12)"
              fill="#5DF5C9"
            />
            <circle cx="63" cy="90" r="18" fill="white" />
            <circle cx="138" cy="90" r="18" fill="white" />
            <circle cx="63" cy="90" r="12" fill="#003125" />
            <circle cx="138" cy="90" r="12" fill="#003125" />
            <path
              d="M46.2066 143.634C46.9402 142.147 49.0599 142.147 49.7935 143.634L54.5882 153.349C54.8795 153.939 55.4426 154.348 56.094 154.443L66.8151 156.001C68.4555 156.239 69.1106 158.255 67.9235 159.412L60.1657 166.974C59.6943 167.434 59.4792 168.096 59.5905 168.744L61.4219 179.422C61.7021 181.056 59.9872 182.302 58.52 181.531L48.9308 176.489C48.3481 176.183 47.652 176.183 47.0694 176.489L37.4802 181.531C36.0129 182.302 34.2981 181.056 34.5783 179.422L36.4097 168.744C36.5209 168.096 36.3058 167.434 35.8345 166.974L28.0766 159.412C26.8896 158.255 27.5446 156.239 29.185 156.001L39.9061 154.443C40.5575 154.348 41.1207 153.939 41.412 153.349L46.2066 143.634Z"
              fill="#FFD440"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M45.7043 143.386C46.6433 141.483 49.3565 141.483 50.2955 143.386L55.0902 153.101C55.2999 153.526 55.7054 153.821 56.1744 153.889L66.8955 155.447C68.9952 155.752 69.8336 158.332 68.3142 159.813L60.5564 167.375C60.217 167.706 60.0621 168.183 60.1423 168.65L61.9736 179.327C62.3323 181.419 60.1373 183.014 58.2592 182.026L48.67 176.985C48.2505 176.764 47.7493 176.764 47.3298 176.985L37.7406 182.026C35.8625 183.014 33.6675 181.419 34.0262 179.328L35.8575 168.65C35.9377 168.183 35.7828 167.706 35.4434 167.375L27.6856 159.813C26.1662 158.332 27.0046 155.752 29.1043 155.447L39.8254 153.889C40.2944 153.821 40.6999 153.526 40.9096 153.101L45.7043 143.386ZM49.2912 143.882C48.763 142.811 47.2368 142.811 46.7086 143.882L41.914 153.597C41.5411 154.352 40.8203 154.876 39.9865 154.997L29.2654 156.555C28.0843 156.727 27.6127 158.178 28.4673 159.011L36.2252 166.573C36.8285 167.161 37.1038 168.009 36.9614 168.839L35.13 179.517C34.9283 180.693 36.163 181.59 37.2194 181.035L46.8086 175.993C47.5544 175.601 48.4454 175.601 49.1912 175.993L58.7804 181.035C59.8368 181.59 61.0715 180.693 60.8698 179.517L59.0384 168.839C58.8959 168.009 59.1713 167.161 59.7746 166.573L67.5325 159.011C68.3871 158.178 67.9155 156.727 66.7344 156.555L56.0133 154.997C55.1795 154.876 54.4587 154.352 54.0858 153.597L49.2912 143.882Z"
              fill="#FFD440"
            />
            <path
              d="M150.206 143.634C150.94 142.147 153.06 142.147 153.793 143.634L158.588 153.349C158.879 153.939 159.442 154.348 160.094 154.443L170.815 156.001C172.455 156.239 173.11 158.255 171.923 159.412L164.166 166.974C163.694 167.434 163.479 168.096 163.59 168.744L165.422 179.422C165.702 181.056 163.987 182.302 162.52 181.531L152.931 176.489C152.348 176.183 151.652 176.183 151.069 176.489L141.48 181.531C140.013 182.302 138.298 181.056 138.578 179.422L140.41 168.744C140.521 168.096 140.306 167.434 139.834 166.974L132.076 159.412C130.889 158.255 131.544 156.239 133.185 156.001L143.906 154.443C144.557 154.348 145.121 153.939 145.412 153.349L150.206 143.634Z"
              fill="#FFD440"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M149.704 143.386C150.643 141.483 153.357 141.483 154.296 143.386L159.09 153.101C159.3 153.526 159.705 153.821 160.175 153.889L170.896 155.447C172.995 155.752 173.834 158.332 172.314 159.813L164.557 167.375C164.217 167.706 164.062 168.183 164.142 168.65L165.974 179.327C166.332 181.419 164.137 183.014 162.259 182.026L152.67 176.985C152.251 176.764 151.749 176.764 151.33 176.985L141.741 182.026C139.863 183.014 137.668 181.419 138.026 179.328L139.858 168.65C139.938 168.183 139.783 167.706 139.444 167.375L131.686 159.813C130.166 158.332 131.005 155.752 133.104 155.447L143.826 153.889C144.295 153.821 144.7 153.526 144.91 153.101L149.704 143.386ZM153.291 143.882C152.763 142.811 151.237 142.811 150.709 143.882L145.914 153.597C145.541 154.352 144.82 154.876 143.987 154.997L133.266 156.555C132.084 156.727 131.613 158.178 132.467 159.011L140.225 166.573C140.829 167.161 141.104 168.009 140.962 168.839L139.13 179.517C138.928 180.693 140.163 181.59 141.22 181.035L150.809 175.993C151.555 175.601 152.446 175.601 153.191 175.993L162.781 181.035C163.837 181.59 165.072 180.693 164.87 179.517L163.039 168.839C162.896 168.009 163.171 167.161 163.775 166.573L171.533 159.011C172.387 158.178 171.916 156.727 170.735 156.555L160.013 154.997C159.18 154.876 158.459 154.352 158.086 153.597L153.291 143.882Z"
              fill="#FFD440"
            />
            <path
              d="M98.2065 155.634C98.9401 154.147 101.06 154.147 101.793 155.634L106.588 165.349C106.879 165.939 107.442 166.348 108.094 166.443L118.815 168.001C120.455 168.239 121.11 170.255 119.923 171.412L112.166 178.974C111.694 179.434 111.479 180.096 111.59 180.744L113.422 191.422C113.702 193.056 111.987 194.302 110.52 193.531L100.931 188.489C100.348 188.183 99.6519 188.183 99.0693 188.489L89.4801 193.531C88.0128 194.302 86.2979 193.056 86.5782 191.422L88.4095 180.744C88.5208 180.096 88.3057 179.434 87.8343 178.974L80.0765 171.412C78.8895 170.255 79.5445 168.239 81.1849 168.001L91.906 166.443C92.5574 166.348 93.1205 165.939 93.4119 165.349L98.2065 155.634Z"
              fill="#FFD440"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M97.7044 155.386C98.6434 153.483 101.357 153.483 102.296 155.386L107.09 165.101C107.3 165.526 107.705 165.821 108.175 165.889L118.896 167.447C120.995 167.752 121.834 170.332 120.314 171.813L112.557 179.375C112.217 179.706 112.062 180.183 112.142 180.65L113.974 191.327C114.332 193.419 112.137 195.014 110.259 194.026L100.67 188.985C100.251 188.764 99.7494 188.764 99.3299 188.985L89.7407 194.026C87.8626 195.014 85.6676 193.419 86.0263 191.328L87.8577 180.65C87.9378 180.183 87.7829 179.706 87.4435 179.375L79.6857 171.813C78.1663 170.332 79.0047 167.752 81.1045 167.447L91.8255 165.889C92.2946 165.821 92.7 165.526 92.9098 165.101L97.7044 155.386ZM101.291 155.882C100.763 154.811 99.2369 154.811 98.7087 155.882L93.9141 165.597C93.5412 166.352 92.8204 166.876 91.9866 166.997L81.2655 168.555C80.0844 168.727 79.6128 170.178 80.4675 171.011L88.2253 178.573C88.8286 179.161 89.104 180.009 88.9615 180.839L87.1302 191.517C86.9284 192.693 88.1631 193.59 89.2195 193.035L98.8087 187.993C99.5545 187.601 100.446 187.601 101.191 187.993L110.781 193.035C111.837 193.59 113.072 192.693 112.87 191.517L111.039 180.839C110.896 180.009 111.171 179.161 111.775 178.573L119.533 171.011C120.387 170.178 119.916 168.727 118.735 168.555L108.013 166.997C107.18 166.876 106.459 166.352 106.086 165.597L101.291 155.882Z"
              fill="#FFD440"
            />
          </svg>
        );
      case 4:
        return (
          <svg
            width={`${size}px`}
            height={`${size}px`}
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="88.5"
              cy="88.5"
              r="88.5"
              transform="matrix(-1 0 0 1 189 12)"
              fill="#5DF5C9"
            />
            <circle cx="63.5" cy="88.5" r="22.5" fill="white" />
            <ellipse cx="65" cy="86" rx="16" ry="18" fill="#003125" />
            <circle cx="68" cy="79" r="7" fill="white" />
            <circle cx="63.5" cy="91.5" r="4.5" fill="white" />
            <circle cx="137.5" cy="88.5" r="22.5" fill="white" />
            <ellipse cx="139" cy="86" rx="16" ry="18" fill="#003125" />
            <circle cx="142" cy="79" r="7" fill="white" />
            <circle cx="137.5" cy="91.5" r="4.5" fill="white" />
            <path
              d="M174.206 130.634C174.94 129.147 177.06 129.147 177.793 130.634L182.588 140.349C182.879 140.939 183.442 141.348 184.094 141.443L194.815 143.001C196.455 143.239 197.11 145.255 195.923 146.412L188.166 153.974C187.694 154.434 187.479 155.096 187.59 155.744L189.422 166.422C189.702 168.056 187.987 169.302 186.52 168.531L176.931 163.489C176.348 163.183 175.652 163.183 175.069 163.489L165.48 168.531C164.013 169.302 162.298 168.056 162.578 166.422L164.41 155.744C164.521 155.096 164.306 154.434 163.834 153.974L156.076 146.412C154.889 145.255 155.544 143.239 157.185 143.001L167.906 141.443C168.557 141.348 169.121 140.939 169.412 140.349L174.206 130.634Z"
              fill="#FFD440"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M173.704 130.386C174.643 128.483 177.357 128.483 178.296 130.386L183.09 140.101C183.3 140.526 183.705 140.821 184.175 140.889L194.896 142.447C196.995 142.752 197.834 145.332 196.314 146.813L188.557 154.375C188.217 154.706 188.062 155.183 188.142 155.65L189.974 166.327C190.332 168.419 188.137 170.014 186.259 169.026L176.67 163.985C176.251 163.764 175.749 163.764 175.33 163.985L165.741 169.026C163.863 170.014 161.668 168.419 162.026 166.328L163.858 155.65C163.938 155.183 163.783 154.706 163.444 154.375L155.686 146.813C154.166 145.332 155.005 142.752 157.104 142.447L167.826 140.889C168.295 140.821 168.7 140.526 168.91 140.101L173.704 130.386ZM177.291 130.882C176.763 129.811 175.237 129.811 174.709 130.882L169.914 140.597C169.541 141.352 168.82 141.876 167.987 141.997L157.266 143.555C156.084 143.727 155.613 145.178 156.467 146.011L164.225 153.573C164.829 154.161 165.104 155.009 164.962 155.839L163.13 166.517C162.928 167.693 164.163 168.59 165.22 168.035L174.809 162.993C175.555 162.601 176.446 162.601 177.191 162.993L186.781 168.035C187.837 168.59 189.072 167.693 188.87 166.517L187.039 155.839C186.896 155.009 187.171 154.161 187.775 153.573L195.533 146.011C196.387 145.178 195.916 143.727 194.735 143.555L184.013 141.997C183.18 141.876 182.459 141.352 182.086 140.597L177.291 130.882Z"
              fill="#FFD440"
            />
            <path
              d="M129.206 155.634C129.94 154.147 132.06 154.147 132.793 155.634L137.588 165.349C137.879 165.939 138.442 166.348 139.094 166.443L149.815 168.001C151.455 168.239 152.11 170.255 150.923 171.412L143.166 178.974C142.694 179.434 142.479 180.096 142.59 180.744L144.422 191.422C144.702 193.056 142.987 194.302 141.52 193.531L131.931 188.489C131.348 188.183 130.652 188.183 130.069 188.489L120.48 193.531C119.013 194.302 117.298 193.056 117.578 191.422L119.41 180.744C119.521 180.096 119.306 179.434 118.834 178.974L111.076 171.412C109.889 170.255 110.544 168.239 112.185 168.001L122.906 166.443C123.557 166.348 124.121 165.939 124.412 165.349L129.206 155.634Z"
              fill="#FFD440"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M128.704 155.386C129.643 153.483 132.357 153.483 133.296 155.386L138.09 165.101C138.3 165.526 138.705 165.821 139.175 165.889L149.896 167.447C151.995 167.752 152.834 170.332 151.314 171.813L143.557 179.375C143.217 179.706 143.062 180.183 143.142 180.65L144.974 191.327C145.332 193.419 143.137 195.014 141.259 194.026L131.67 188.985C131.251 188.764 130.749 188.764 130.33 188.985L120.741 194.026C118.863 195.014 116.668 193.419 117.026 191.328L118.858 180.65C118.938 180.183 118.783 179.706 118.444 179.375L110.686 171.813C109.166 170.332 110.005 167.752 112.104 167.447L122.826 165.889C123.295 165.821 123.7 165.526 123.91 165.101L128.704 155.386ZM132.291 155.882C131.763 154.811 130.237 154.811 129.709 155.882L124.914 165.597C124.541 166.352 123.82 166.876 122.987 166.997L112.266 168.555C111.084 168.727 110.613 170.178 111.467 171.011L119.225 178.573C119.829 179.161 120.104 180.009 119.962 180.839L118.13 191.517C117.928 192.693 119.163 193.59 120.22 193.035L129.809 187.993C130.555 187.601 131.446 187.601 132.191 187.993L141.781 193.035C142.837 193.59 144.072 192.693 143.87 191.517L142.039 180.839C141.896 180.009 142.171 179.161 142.775 178.573L150.533 171.011C151.387 170.178 150.916 168.727 149.735 168.555L139.013 166.997C138.18 166.876 137.459 166.352 137.086 165.597L132.291 155.882Z"
              fill="#FFD440"
            />
            <path
              d="M68.2065 155.634C68.9401 154.147 71.0598 154.147 71.7934 155.634L76.588 165.349C76.8794 165.939 77.4425 166.348 78.0939 166.443L88.815 168.001C90.4554 168.239 91.1104 170.255 89.9234 171.412L82.1656 178.974C81.6942 179.434 81.4791 180.096 81.5904 180.744L83.4218 191.422C83.702 193.056 81.9871 194.302 80.5199 193.531L70.9306 188.489C70.348 188.183 69.6519 188.183 69.0693 188.489L59.4801 193.531C58.0128 194.302 56.2979 193.056 56.5782 191.422L58.4095 180.744C58.5208 180.096 58.3057 179.434 57.8343 178.974L50.0765 171.412C48.8895 170.255 49.5445 168.239 51.1849 168.001L61.906 166.443C62.5574 166.348 63.1205 165.939 63.4119 165.349L68.2065 155.634Z"
              fill="#FFD440"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M67.7044 155.386C68.6434 153.483 71.3566 153.483 72.2957 155.386L77.0903 165.101C77.3 165.526 77.7055 165.821 78.1745 165.889L88.8956 167.447C90.9953 167.752 91.8338 170.332 90.3144 171.813L82.5565 179.375C82.2171 179.706 82.0623 180.183 82.1424 180.65L83.9738 191.327C84.3324 193.419 82.1374 195.014 80.2593 194.026L70.6701 188.985C70.2506 188.764 69.7494 188.764 69.3299 188.985L59.7407 194.026C57.8626 195.014 55.6676 193.419 56.0263 191.328L57.8577 180.65C57.9378 180.183 57.7829 179.706 57.4435 179.375L49.6857 171.813C48.1663 170.332 49.0047 167.752 51.1045 167.447L61.8255 165.889C62.2946 165.821 62.7 165.526 62.9098 165.101L67.7044 155.386ZM71.2913 155.882C70.7631 154.811 69.2369 154.811 68.7087 155.882L63.9141 165.597C63.5412 166.352 62.8204 166.876 61.9866 166.997L51.2655 168.555C50.0844 168.727 49.6128 170.178 50.4675 171.011L58.2253 178.573C58.8286 179.161 59.104 180.009 58.9615 180.839L57.1302 191.517C56.9284 192.693 58.1631 193.59 59.2195 193.035L68.8087 187.993C69.5545 187.601 70.4455 187.601 71.1913 187.993L80.7805 193.035C81.8369 193.59 83.0716 192.693 82.8699 191.517L81.0385 180.839C80.8961 180.009 81.1714 179.161 81.7747 178.573L89.5326 171.011C90.3873 170.178 89.9156 168.727 88.7345 168.555L78.0135 166.997C77.1796 166.876 76.4588 166.352 76.0859 165.597L71.2913 155.882Z"
              fill="#FFD440"
            />
            <path
              d="M22.2065 130.634C22.9401 129.147 25.0598 129.147 25.7934 130.634L30.588 140.349C30.8794 140.939 31.4425 141.348 32.0939 141.443L42.815 143.001C44.4554 143.239 45.1104 145.255 43.9234 146.412L36.1656 153.974C35.6942 154.434 35.4791 155.096 35.5904 155.744L37.4218 166.422C37.702 168.056 35.9871 169.302 34.5199 168.531L24.9306 163.489C24.348 163.183 23.6519 163.183 23.0693 163.489L13.4801 168.531C12.0128 169.302 10.2979 168.056 10.5782 166.422L12.4095 155.744C12.5208 155.096 12.3057 154.434 11.8343 153.974L4.0765 146.412C2.88947 145.255 3.54448 143.239 5.18492 143.001L15.906 141.443C16.5574 141.348 17.1205 140.939 17.4119 140.349L22.2065 130.634Z"
              fill="#FFD440"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.7044 130.386C22.6434 128.483 25.3566 128.483 26.2957 130.386L31.0903 140.101C31.3 140.526 31.7055 140.821 32.1745 140.889L42.8956 142.447C44.9953 142.752 45.8338 145.332 44.3144 146.813L36.5565 154.375C36.2171 154.706 36.0623 155.183 36.1424 155.65L37.9738 166.327C38.3324 168.419 36.1374 170.014 34.2593 169.026L24.6701 163.985C24.2506 163.764 23.7494 163.764 23.3299 163.985L13.7407 169.026C11.8626 170.014 9.66759 168.419 10.0263 166.328L11.8577 155.65C11.9378 155.183 11.7829 154.706 11.4435 154.375L3.68568 146.813C2.16628 145.332 3.0047 142.752 5.10446 142.447L15.8255 140.889C16.2946 140.821 16.7 140.526 16.9098 140.101L21.7044 130.386ZM25.2913 130.882C24.7631 129.811 23.2369 129.811 22.7087 130.882L17.9141 140.597C17.5412 141.352 16.8204 141.876 15.9866 141.997L5.26552 143.555C4.0844 143.727 3.61279 145.178 4.46745 146.011L12.2253 153.573C12.8286 154.161 13.104 155.009 12.9615 155.839L11.1302 166.517C10.9284 167.693 12.1631 168.59 13.2195 168.035L22.8087 162.993C23.5545 162.601 24.4455 162.601 25.1913 162.993L34.7805 168.035C35.8369 168.59 37.0716 167.693 36.8699 166.517L35.0385 155.839C34.8961 155.009 35.1714 154.161 35.7747 153.573L43.5326 146.011C44.3873 145.178 43.9156 143.727 42.7345 143.555L32.0135 141.997C31.1796 141.876 30.4588 141.352 30.0859 140.597L25.2913 130.882Z"
              fill="#FFD440"
            />
          </svg>
        );
      case 5:
        return (
          <svg
            width={`${size}px`}
            height={`${size}px`}
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="88.5"
              cy="88.5"
              r="88.5"
              transform="matrix(-1 0 0 1 189 12)"
              fill="#5DF5C9"
            />
            <path
              d="M24.57 131.558C25.3082 130.088 27.4061 130.088 28.1443 131.558L31.8706 138.976C32.1646 139.561 32.7266 139.965 33.3751 140.058L41.6482 141.239C43.2996 141.475 43.9536 143.509 42.7488 144.663L36.8079 150.353C36.3264 150.814 36.1063 151.484 36.221 152.141L37.6296 160.21C37.914 161.839 36.2109 163.09 34.7416 162.331L27.275 158.474C26.6992 158.177 26.0151 158.177 25.4393 158.474L17.9727 162.331C16.5034 163.09 14.8003 161.839 15.0847 160.21L16.4933 152.141C16.608 151.484 16.3879 150.814 15.9064 150.353L9.96546 144.663C8.76065 143.509 9.41468 141.475 11.0661 141.239L19.3392 140.058C19.9876 139.965 20.5497 139.561 20.8437 138.976L24.57 131.558Z"
              fill="#FFD440"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.0695 131.306C25.0145 129.425 27.6997 129.425 28.6447 131.306L32.3709 138.724C32.5826 139.146 32.9873 139.437 33.4542 139.503L41.7273 140.684C43.8411 140.986 44.6782 143.591 43.1361 145.068L37.1951 150.757C36.8485 151.089 36.69 151.572 36.7726 152.045L38.1812 160.113C38.5452 162.199 36.3652 163.8 34.4845 162.828L27.0179 158.972C26.6034 158.758 26.1108 158.758 25.6963 158.972L18.2296 162.828C16.3489 163.8 14.1689 162.199 14.533 160.113L15.9416 152.045C16.0241 151.572 15.8657 151.089 15.519 150.757L9.57808 145.068C8.03593 143.591 8.87308 140.986 10.9869 140.684L19.26 139.503C19.7269 139.437 20.1315 139.146 20.3432 138.724L24.0695 131.306ZM27.6439 131.809C27.1123 130.751 25.6019 130.751 25.0703 131.809L21.3441 139.227C20.9677 139.976 20.2483 140.494 19.4183 140.612L11.1452 141.793C9.95617 141.963 9.48527 143.428 10.3527 144.259L16.2937 149.948C16.91 150.538 17.1917 151.397 17.0449 152.237L15.6363 160.306C15.4315 161.479 16.6578 162.38 17.7157 161.833L25.1823 157.977C25.9192 157.596 26.7949 157.596 27.5319 157.977L34.9985 161.833C36.0564 162.38 37.2826 161.479 37.0779 160.306L35.6693 152.237C35.5225 151.397 35.8042 150.538 36.4205 149.948L42.3614 144.259C43.2289 143.428 42.758 141.963 41.569 141.793L33.2959 140.612C32.4659 140.494 31.7465 139.976 31.3701 139.227L27.6439 131.809Z"
              fill="#FFD440"
            />
            <path
              d="M177.57 131.558C178.308 130.088 180.406 130.088 181.144 131.558L184.871 138.976C185.165 139.561 185.727 139.965 186.375 140.058L194.648 141.239C196.3 141.475 196.954 143.509 195.749 144.663L189.808 150.353C189.326 150.814 189.106 151.484 189.221 152.141L190.63 160.21C190.914 161.839 189.211 163.09 187.742 162.331L180.275 158.474C179.699 158.177 179.015 158.177 178.439 158.474L170.973 162.331C169.503 163.09 167.8 161.839 168.085 160.21L169.493 152.141C169.608 151.484 169.388 150.814 168.906 150.353L162.965 144.663C161.761 143.509 162.415 141.475 164.066 141.239L172.339 140.058C172.988 139.965 173.55 139.561 173.844 138.976L177.57 131.558Z"
              fill="#FFD440"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M177.069 131.306C178.014 129.425 180.7 129.425 181.645 131.306L185.371 138.724C185.583 139.146 185.987 139.437 186.454 139.503L194.727 140.684C196.841 140.986 197.678 143.591 196.136 145.068L190.195 150.757C189.848 151.089 189.69 151.572 189.773 152.045L191.181 160.113C191.545 162.199 189.365 163.8 187.485 162.828L180.018 158.972C179.603 158.758 179.111 158.758 178.696 158.972L171.23 162.828C169.349 163.8 167.169 162.199 167.533 160.113L168.942 152.045C169.024 151.572 168.866 151.089 168.519 150.757L162.578 145.068C161.036 143.591 161.873 140.986 163.987 140.684L172.26 139.503C172.727 139.437 173.132 139.146 173.343 138.724L177.069 131.306ZM180.644 131.809C180.112 130.751 178.602 130.751 178.07 131.809L174.344 139.227C173.968 139.976 173.248 140.494 172.418 140.612L164.145 141.793C162.956 141.963 162.485 143.428 163.353 144.259L169.294 149.948C169.91 150.538 170.192 151.397 170.045 152.237L168.636 160.306C168.432 161.479 169.658 162.38 170.716 161.833L178.182 157.977C178.919 157.596 179.795 157.596 180.532 157.977L187.999 161.833C189.056 162.38 190.283 161.479 190.078 160.306L188.669 152.237C188.523 151.397 188.804 150.538 189.42 149.948L195.361 144.259C196.229 143.428 195.758 141.963 194.569 141.793L186.296 140.612C185.466 140.494 184.746 139.976 184.37 139.227L180.644 131.809Z"
              fill="#FFD440"
            />
            <path
              d="M139.57 157.558C140.308 156.088 142.406 156.088 143.144 157.558L146.871 164.976C147.165 165.561 147.727 165.965 148.375 166.058L156.648 167.239C158.3 167.475 158.954 169.509 157.749 170.663L151.808 176.353C151.326 176.814 151.106 177.484 151.221 178.141L152.63 186.21C152.914 187.839 151.211 189.09 149.742 188.331L142.275 184.474C141.699 184.177 141.015 184.177 140.439 184.474L132.973 188.331C131.503 189.09 129.8 187.839 130.085 186.21L131.493 178.141C131.608 177.484 131.388 176.814 130.906 176.353L124.965 170.663C123.761 169.509 124.415 167.475 126.066 167.239L134.339 166.058C134.988 165.965 135.55 165.561 135.844 164.976L139.57 157.558Z"
              fill="#FFD440"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M139.069 157.306C140.014 155.425 142.7 155.425 143.645 157.306L147.371 164.724C147.583 165.146 147.987 165.437 148.454 165.503L156.727 166.684C158.841 166.986 159.678 169.591 158.136 171.068L152.195 176.757C151.848 177.089 151.69 177.572 151.773 178.045L153.181 186.113C153.545 188.199 151.365 189.8 149.485 188.828L142.018 184.972C141.603 184.758 141.111 184.758 140.696 184.972L133.23 188.828C131.349 189.8 129.169 188.199 129.533 186.113L130.942 178.045C131.024 177.572 130.866 177.089 130.519 176.757L124.578 171.068C123.036 169.591 123.873 166.986 125.987 166.684L134.26 165.503C134.727 165.437 135.132 165.146 135.343 164.724L139.069 157.306ZM142.644 157.809C142.112 156.751 140.602 156.751 140.07 157.809L136.344 165.227C135.968 165.976 135.248 166.494 134.418 166.612L126.145 167.793C124.956 167.963 124.485 169.428 125.353 170.259L131.294 175.948C131.91 176.538 132.192 177.397 132.045 178.237L130.636 186.306C130.432 187.479 131.658 188.38 132.716 187.833L140.182 183.977C140.919 183.596 141.795 183.596 142.532 183.977L149.999 187.833C151.056 188.38 152.283 187.479 152.078 186.306L150.669 178.237C150.523 177.397 150.804 176.538 151.42 175.948L157.361 170.259C158.229 169.428 157.758 167.963 156.569 167.793L148.296 166.612C147.466 166.494 146.746 165.976 146.37 165.227L142.644 157.809Z"
              fill="#FFD440"
            />
            <path
              d="M57.57 157.558C58.3082 156.088 60.4061 156.088 61.1443 157.558L64.8706 164.976C65.1646 165.561 65.7266 165.965 66.3751 166.058L74.6482 167.239C76.2996 167.475 76.9536 169.509 75.7488 170.663L69.8079 176.353C69.3264 176.814 69.1063 177.484 69.221 178.141L70.6296 186.21C70.914 187.839 69.2109 189.09 67.7416 188.331L60.275 184.474C59.6992 184.177 59.0151 184.177 58.4393 184.474L50.9727 188.331C49.5034 189.09 47.8003 187.839 48.0847 186.21L49.4933 178.141C49.608 177.484 49.3879 176.814 48.9064 176.353L42.9655 170.663C41.7607 169.509 42.4147 167.475 44.0661 167.239L52.3392 166.058C52.9876 165.965 53.5497 165.561 53.8437 164.976L57.57 157.558Z"
              fill="#FFD440"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M57.0695 157.306C58.0145 155.425 60.6997 155.425 61.6447 157.306L65.3709 164.724C65.5826 165.146 65.9873 165.437 66.4542 165.503L74.7273 166.684C76.8411 166.986 77.6782 169.591 76.1361 171.068L70.1951 176.757C69.8485 177.089 69.69 177.572 69.7726 178.045L71.1812 186.113C71.5452 188.199 69.3652 189.8 67.4845 188.828L60.0179 184.972C59.6034 184.758 59.1108 184.758 58.6963 184.972L51.2296 188.828C49.3489 189.8 47.1689 188.199 47.533 186.113L48.9416 178.045C49.0241 177.572 48.8657 177.089 48.519 176.757L42.5781 171.068C41.0359 169.591 41.8731 166.986 43.9869 166.684L52.26 165.503C52.7269 165.437 53.1315 165.146 53.3432 164.724L57.0695 157.306ZM60.6439 157.809C60.1123 156.751 58.6019 156.751 58.0703 157.809L54.3441 165.227C53.9677 165.976 53.2483 166.494 52.4183 166.612L44.1452 167.793C42.9562 167.963 42.4853 169.428 43.3527 170.259L49.2937 175.948C49.91 176.538 50.1917 177.397 50.0449 178.237L48.6363 186.306C48.4315 187.479 49.6578 188.38 50.7157 187.833L58.1823 183.977C58.9192 183.596 59.7949 183.596 60.5319 183.977L67.9985 187.833C69.0564 188.38 70.2826 187.479 70.0779 186.306L68.6693 178.237C68.5225 177.397 68.8042 176.538 69.4205 175.948L75.3614 170.259C76.2289 169.428 75.758 167.963 74.569 167.793L66.2959 166.612C65.4659 166.494 64.7465 165.976 64.3701 165.227L60.6439 157.809Z"
              fill="#FFD440"
            />
            <path
              d="M98.57 163.558C99.3082 162.088 101.406 162.088 102.144 163.558L105.871 170.976C106.165 171.561 106.727 171.965 107.375 172.058L115.648 173.239C117.3 173.475 117.954 175.509 116.749 176.663L110.808 182.353C110.326 182.814 110.106 183.484 110.221 184.141L111.63 192.21C111.914 193.839 110.211 195.09 108.742 194.331L101.275 190.474C100.699 190.177 100.015 190.177 99.4393 190.474L91.9727 194.331C90.5034 195.09 88.8003 193.839 89.0847 192.21L90.4933 184.141C90.608 183.484 90.3879 182.814 89.9064 182.353L83.9655 176.663C82.7607 175.509 83.4147 173.475 85.0661 173.239L93.3392 172.058C93.9876 171.965 94.5497 171.561 94.8437 170.976L98.57 163.558Z"
              fill="#FFD440"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M98.0695 163.306C99.0145 161.425 101.7 161.425 102.645 163.306L106.371 170.724C106.583 171.146 106.987 171.437 107.454 171.503L115.727 172.684C117.841 172.986 118.678 175.591 117.136 177.068L111.195 182.757C110.848 183.089 110.69 183.572 110.773 184.045L112.181 192.113C112.545 194.199 110.365 195.8 108.485 194.828L101.018 190.972C100.603 190.758 100.111 190.758 99.6963 190.972L92.2296 194.828C90.3489 195.8 88.1689 194.199 88.533 192.113L89.9416 184.045C90.0241 183.572 89.8657 183.089 89.519 182.757L83.5781 177.068C82.0359 175.591 82.8731 172.986 84.9869 172.684L93.26 171.503C93.7269 171.437 94.1315 171.146 94.3432 170.724L98.0695 163.306ZM101.644 163.809C101.112 162.751 99.6019 162.751 99.0703 163.809L95.3441 171.227C94.9677 171.976 94.2483 172.494 93.4183 172.612L85.1452 173.793C83.9562 173.963 83.4853 175.428 84.3527 176.259L90.2937 181.948C90.91 182.538 91.1917 183.397 91.0449 184.237L89.6363 192.306C89.4315 193.479 90.6578 194.38 91.7157 193.833L99.1823 189.977C99.9192 189.596 100.795 189.596 101.532 189.977L108.999 193.833C110.056 194.38 111.283 193.479 111.078 192.306L109.669 184.237C109.523 183.397 109.804 182.538 110.42 181.948L116.361 176.259C117.229 175.428 116.758 173.963 115.569 173.793L107.296 172.612C106.466 172.494 105.746 171.976 105.37 171.227L101.644 163.809Z"
              fill="#FFD440"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M43.0854 67.9588C48.4928 62.6415 57.2149 62.6864 62.5666 68.0591L66 71.506L69.4334 68.0591C74.7851 62.6864 83.5072 62.6415 88.9146 67.9588C94.3221 73.2762 94.3673 81.9423 89.0156 87.315L66 110.421L42.9844 87.315C37.6327 81.9423 37.6779 73.2762 43.0854 67.9588Z"
              fill="#FF878F"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M115.085 67.9588C120.493 62.6415 129.215 62.6864 134.567 68.0591L138 71.506L141.433 68.0591C146.785 62.6864 155.507 62.6415 160.915 67.9588C166.322 73.2762 166.367 81.9423 161.016 87.315L138 110.421L114.984 87.315C109.633 81.9423 109.678 73.2762 115.085 67.9588Z"
              fill="#FF878F"
            />
          </svg>
        );
      default:
        return null;
    }
  };
  return ratingSelectedIcon(rating);
};

export default ScribeRatingSelected;
