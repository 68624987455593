import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";

import Heading from "../../../ui/Heading";
import { FloatModal } from "../../../ui/Modal";
import { Form } from "../../../ui/Input";
import Loader from "../../../ui/Loader";
import FormContent from "./FormContent";

import {
  ReduxStateType,
  ReportTemplate,
  Option,
  CreateChatFlowOutput,
  ChatFlowOutput,
  ChatFlowsNodes
} from "../../../../types";
import { closeModal, createChatFlowOutput, updateChatFlowOutput } from "../../../../actions";
import { OrganizationContext } from "../../../providers/OrganizationProvider";
import { getAccuroLetterTypes } from "../../../../lib";
import {
  ChatFlowOutputTypes,
  IntegrationCategories,
  MessageTemplateAppointmentRequestTags
} from "../../../../constants";
import { globalFormValidator } from "./formValidator";
import { getPushableDemographicOptionsConfig } from "../../../../lib/integration.requests";

import styles from "./index.module.scss";

type AccuroLetterType = {
  abbreviation: string;
  active: boolean;
  typeId: number;
  typeName: string;
};

type AddChatFlowOutputFormData = {
  type: string;
  reportTemplateId?: string;
  title?: string;
  letterTypeId?: string;
  fileCSVForm?: Array<DataMappingsOption>;
  demographicPropertiesArray: Array<DemographicPropertyMapping>;
};

export type TagOption = {
  label: string;
  value: string;
};

export type InputNameOption = {
  label: string;
  value: string;
};

type DataMappingsOption = {
  inputName: string;
  mappedName: string;
};

type DemographicPropertyMapping = {
  emrFieldName?: string;
  mappingChoice?: string;
  inputName?: string;
  tagName?: string;
  required?: boolean;
  defaultValue?: string;
};

export type FormOptionsConfig = {
  emrFieldName: string;
  validMessageTemplateTagNames?: string[];
  canMapToInputName?: boolean;
  isRequired?: boolean;

  defaultOptions?: Option[];
  allowCustomDefaultOption?: boolean;
}[];

type PropsType = {
  loading?: boolean;
  closeModalAction: () => void;
  createChatFlowOutput: (data: CreateChatFlowOutput, chatFlowId: number) => Promise<void>;
  updateChatFlowOutput: (
    chatFlowOutputId: number,
    data: CreateChatFlowOutput,
    chatFlowId: number
  ) => Promise<void>;
  reportTemplates: Array<ReportTemplate>;
  chatFlowId?: number | null;
  chatFlowOutput?: ChatFlowOutput;
  chatFlowNodes?: ChatFlowsNodes[];
};

const failedFetchMessage =
  "Failed to fetch letter types for the EMR. Please ensure that the clinic is authenticated and try again";

const appointmentOutputs = [
  { label: "Appointment EMR ID", value: "__emrAppointmentId__" },
  { label: "Appointment Start Times", value: "__appointmentStartTimes__" },
  { label: "Patient EMR ID", value: "__emrPatientId__" },
  { label: "Practitioner EMR ID", value: "__emrPractitionerIds__" },
  { label: "Completed At", value: "__convCompletedAt__" }
];

const AddChatFlowOutput = ({
  loading,
  closeModalAction,
  reportTemplates,
  createChatFlowOutput,
  updateChatFlowOutput,
  chatFlowId,
  chatFlowOutput,
  chatFlowNodes
}: PropsType) => {
  const organization = useContext(OrganizationContext);

  const [letterTypes, setLetterTypes] = useState<AccuroLetterType[] | null>(null);
  const [formOptionsConfig, setAvailableFormOptionsConfig] = useState<FormOptionsConfig | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [outputType, setOutputType] = useState("");
  const inputNameOptions: InputNameOption[] = [];
  const [availableMessageTemplateTags, setAvailableMessageTemplateTags] = useState<TagOption[]>([]);
  const [availableInputNames, setAvailableInputNames] = useState<InputNameOption[]>([]);
  const [isConfigLoaded, setIsConfigLoaded] = useState<boolean>(false);
  const [globalError, setGlobalError] = useState("");

  // Get pushDemographic config
  useEffect(() => {
    (async () => {
      if (organization?.id) {
        setAvailableFormOptionsConfig(
          (await getPushableDemographicOptionsConfig(organization.id)) || []
        );
        setIsConfigLoaded(true);
      }
    })();
  }, [organization]);

  // Get Accuro Letter types
  useEffect(() => {
    (async () => {
      if (organization?.id && organization.integrationCategory === IntegrationCategories.ACCURO) {
        try {
          const letterTypesResponse: AccuroLetterType[] = await getAccuroLetterTypes();

          if (letterTypesResponse) {
            setErrorMessage("");
            return setLetterTypes(letterTypesResponse);
          }
          setErrorMessage(failedFetchMessage);
        } catch (error) {
          setErrorMessage(failedFetchMessage);
        }
      }
    })();
  }, [organization]);

  // Gets all demographic tag names for chatFlow
  useEffect(() => {
    const availableTagOptions: TagOption[] = [];
    chatFlowNodes?.forEach((nodeElement) => {
      if (nodeElement.tagNames.length > 0) {
        nodeElement.tagNames.forEach((tagElement) => {
          const associatedMessageTemplateTag = MessageTemplateAppointmentRequestTags.find(
            (tag) => tag.value === tagElement
          ) ?? { label: "", value: "" };
          const field = {
            label: associatedMessageTemplateTag.label,
            value: tagElement
          };
          availableTagOptions.push(field);
        });
      }
    });
    setAvailableMessageTemplateTags(availableTagOptions);
  }, []);

  // Get all input names for chatFlow
  useEffect(() => {
    chatFlowNodes?.forEach((nodeElement) => {
      if (nodeElement.inputName != null) {
        inputNameOptions.push({ label: nodeElement.inputName, value: nodeElement.inputName });
      }
    });
    setAvailableInputNames(inputNameOptions);
  }, []);

  useEffect(() => {
    setOutputType(chatFlowOutput?.type || "");
  }, []);

  const save = async (formValues: AddChatFlowOutputFormData) => {
    if (!chatFlowId || !organization?.id) return;
    const data: CreateChatFlowOutput = {
      type: formValues.type,
      reportTemplateId: formValues.reportTemplateId,
      chatFlowId,
      config: {},
      organizationId: organization?.id
    };
    if (formValues.letterTypeId) {
      data.config.letterTypeId = Number(formValues.letterTypeId);
    }
    if (formValues.title) {
      data.config.title = formValues.title;
    }
    // chat flow output config for pushDemographics
    if (formValues.demographicPropertiesArray) {
      data.reportTemplateId = null;
      data.config.dataMappings = {};

      formValues.demographicPropertiesArray.map((properties) => {
        const { emrFieldName } = properties;
        delete properties.emrFieldName;
        delete properties.mappingChoice;

        if (data.config.dataMappings && emrFieldName) {
          data.config.dataMappings[emrFieldName] = properties;
        }
      });
    }
    // chat flow ouput config for file_csv push
    if (formValues.fileCSVForm) {
      const fileCSVConfig: { [mappedName: string]: { inputName: string } } = {};

      formValues.fileCSVForm.forEach((inputNameMapping) => {
        fileCSVConfig[inputNameMapping.mappedName] = { inputName: inputNameMapping.inputName };
      });

      data.config.dataMappings = fileCSVConfig;
      data.reportTemplateId = null;
    }
    if (chatFlowId === null || chatFlowId === undefined) return;
    if (chatFlowOutput?.id) {
      updateChatFlowOutput(chatFlowOutput.id, data, chatFlowId);
    } else {
      createChatFlowOutput(data, chatFlowId);
    }
    closeModalAction();
  };

  const outputTypesObjects = [
    {
      label: "Push Report",
      value: ChatFlowOutputTypes.PUSH_NOTE
    },
    {
      label: "Push Demographics",
      value: ChatFlowOutputTypes.PUSH_DEMOGRAPHICS
    },
    {
      label: "CSV File",
      value: ChatFlowOutputTypes.FILE_CSV
    }
  ];

  const reportTemplateOptions = reportTemplates.map((reportTemplate) => ({
    label: reportTemplate.name,
    value: reportTemplate.id
  }));

  const letterTypeOptions: Option[] = letterTypes
    ? letterTypes.map((letterType) => {
        return { label: letterType.typeName, value: letterType.typeId.toString() };
      })
    : [];

  let fileCSVArrayInitialState: Array<DataMappingsOption> = [];
  if (chatFlowOutput?.config?.dataMappings) {
    const existingDataMappings = chatFlowOutput.config.dataMappings;
    fileCSVArrayInitialState = Object.keys(existingDataMappings).flatMap((key) => {
      const { inputName } = existingDataMappings[key];
      if (inputName) return { inputName, mappedName: key };
      return [];
    });
  }

  let demographicPropertiesInitialState: Array<DemographicPropertyMapping> = [];
  if (chatFlowOutput?.config?.dataMappings) {
    const existingDemographicMappings = chatFlowOutput.config.dataMappings;
    demographicPropertiesInitialState = Object.keys(existingDemographicMappings).map(
      (emrFieldName) => {
        const properties: DemographicPropertyMapping = existingDemographicMappings[emrFieldName];
        properties.emrFieldName = emrFieldName;
        if (existingDemographicMappings[emrFieldName].inputName) {
          properties.mappingChoice = "Input Name";
        } else if (existingDemographicMappings[emrFieldName].tagName) {
          properties.mappingChoice = "Tag Name";
        }
        return properties;
      }
    );
    demographicPropertiesInitialState.sort((a, b) => {
      if (a.emrFieldName && b.emrFieldName) {
        if (
          formOptionsConfig?.find((optionConfig) => {
            return optionConfig.emrFieldName === a.emrFieldName;
          })?.isRequired &&
          !formOptionsConfig?.find((optionConfig) => {
            return optionConfig.emrFieldName === b.emrFieldName;
          })?.isRequired
        ) {
          return -1;
        }
        if (
          !formOptionsConfig?.find((optionConfig) => {
            return optionConfig.emrFieldName === a.emrFieldName;
          })?.isRequired &&
          formOptionsConfig?.find((optionConfig) => {
            return optionConfig.emrFieldName === b.emrFieldName;
          })?.isRequired
        ) {
          return 1;
        }
        return a.emrFieldName.localeCompare(b.emrFieldName);
      }
      return 0;
    });
  } else if (formOptionsConfig && formOptionsConfig.length > 0) {
    formOptionsConfig.forEach((options) => {
      if (options.isRequired) {
        const arrayFieldRow: DemographicPropertyMapping = { emrFieldName: options.emrFieldName };
        if (options?.validMessageTemplateTagNames?.length === 1) {
          arrayFieldRow.mappingChoice = "Tag Name";
          [arrayFieldRow.tagName] = options.validMessageTemplateTagNames;
        }
        demographicPropertiesInitialState.push(arrayFieldRow);
      }
      demographicPropertiesInitialState.sort((a, b) => {
        if (a.emrFieldName && b.emrFieldName) {
          return a.emrFieldName.localeCompare(b.emrFieldName);
        }
        return 0;
      });
    });
  }

  const initialFormState = {
    type: chatFlowOutput?.type || "",
    reportTemplateId: chatFlowOutput?.reportTemplateId || "",
    title: chatFlowOutput?.config?.title || "",
    letterTypeId: chatFlowOutput?.config?.letterTypeId?.toString() || undefined,
    fileCSVForm: fileCSVArrayInitialState,
    demographicPropertiesArray: demographicPropertiesInitialState
  };

  if (!isConfigLoaded) {
    return (
      <FloatModal isOpen onClose={closeModalAction}>
        <Loader screen />
      </FloatModal>
    );
  }

  return (
    <FloatModal isOpen onClose={closeModalAction}>
      <Heading size="L">Chat Flow Output</Heading>
      <Form
        className={styles.Form}
        onSubmit={(formState) => {
          const values = formState.values as AddChatFlowOutputFormData;
          const error = globalFormValidator(values);
          if (error) {
            setGlobalError(error);
            return;
          }
          save(values);
        }}
        initialValues={initialFormState}
      >
        <FormContent
          loading={loading}
          chatFlowOutput={chatFlowOutput}
          closeModalAction={closeModalAction}
          outputTypesObjects={outputTypesObjects}
          reportTemplateOptions={reportTemplateOptions}
          letterTypeOptions={letterTypeOptions}
          formOptionsConfig={formOptionsConfig}
          availableMessageTemplateTags={availableMessageTemplateTags}
          outputType={outputType}
          setOutputType={setOutputType}
          availableInputNames={availableInputNames}
          globalError={globalError}
          organization={organization}
          appointmentOutputs={appointmentOutputs}
        />
      </Form>
    </FloatModal>
  );
};

const mapStateToProps = ({ chats }: ReduxStateType) => {
  return {
    reportTemplates: chats.chatDetails.reportTemplates,
    chatFlowId: chats.chatDetails.chatFlowId,
    chatFlowNodes: chats.chatDetails.nodes
  };
};

export default connect(mapStateToProps, {
  closeModalAction: closeModal,
  createChatFlowOutput,
  updateChatFlowOutput
})(AddChatFlowOutput);
