import React, { useContext, useEffect } from "react";

import { UserContext } from "../../../providers/UserProvider";

import Heading from "../../../ui/Heading";
import Loader from "../../../ui/Loader";
import Text from "../../../ui/Text";

import { useQueryString } from "../../../../utils/queryStringHelpers";

import styles from "./index.module.scss";

const TelusLaunchLandingPage = () => {
  const userContext = useContext(UserContext);
  const { parsed } = useQueryString();
  const { EmrType, InstanceId, ContextualLaunchToken } = parsed;
  console.log("EmrType: ", EmrType);
  console.log("InstanceId: ", InstanceId);
  console.log("ContextualLaunchToken: ", ContextualLaunchToken);

  useEffect(() => {
    const params = {
      telusEMRCategory: EmrType,
      telusInstanceId: InstanceId,
      contextualLaunchToken: ContextualLaunchToken
    };

    if (!userContext.loginLoading && EmrType && InstanceId && ContextualLaunchToken) {
      userContext.handleContextualLogin(params);
    }
  }, [ContextualLaunchToken, userContext.loginLoading]);

  return (
    <div className={styles.Wrapper}>
      {userContext.loginErrorMessage && (
        <>
          <Heading component="h3">Error Encountered</Heading>
          <Text align="center" component="p" className={styles.ErrorMessage}>
            {userContext.loginErrorMessage}
          </Text>
        </>
      )}
      {userContext.loginLoading && <Loader screen />}
    </div>
  );
};

export default TelusLaunchLandingPage;
