import React from "react";

const SquareCheck = () => {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.719 3.29c.187.203.281.437.281.703 0 .266-.094.5-.281.703L12.71 14.708c-.11.094-.23.164-.363.211-.133.047-.246.07-.34.07-.094 0-.207-.023-.34-.07a1.135 1.135 0 01-.363-.21l-3-3.002A.966.966 0 018 11.003c0-.265.102-.5.305-.703a.966.966 0 01.703-.305c.265 0 .5.102.703.305l2.297 2.298 9.305-9.308a.966.966 0 01.703-.305c.265 0 .5.101.703.305zm-1.711 7.714c.297 0 .539.09.726.27.188.179.282.417.282.714v7.01c0 .845-.29 1.556-.868 2.134-.578.579-1.289.868-2.132.868H5c-.844 0-1.555-.29-2.133-.867C2.29 20.553 2 19.843 2 18.998V5c0-.86.29-1.575.867-2.145C3.445 2.286 4.157 2 5 2h11.016c.297 0 .535.09.715.27.18.18.269.418.269.715 0 .312-.09.559-.27.738-.18.18-.418.27-.714.27H5c-.297 0-.535.09-.715.27-.18.18-.27.426-.27.738V19c0 .297.09.54.27.727.18.187.418.281.715.281h14.016c.297 0 .535-.094.715-.281.18-.188.269-.43.269-.727v-7.01c0-.298.094-.536.281-.716.188-.18.43-.27.727-.27z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SquareCheck;
