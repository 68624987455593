import React, { useState } from "react";
import { connect } from "react-redux";
import {
  createDocument as CreateDocumentAction,
  CreateDocumentData,
  closeModal as closeModalAction,
  addNotification as addNotificationAction
} from "../../../../actions";
import { OrganizationData, ReduxStateType, NewNotification } from "../../../../types";
import { isRequired } from "../../../../utils/validators";
import { getSignedUrl, uploadFiles } from "../../../../lib";
import { BucketNameSuffixes } from "../../../../constants";

import { FloatModal } from "../../../ui/Modal";
import Heading from "../../../ui/Heading";
import Button from "../../../ui/Button";
import Text from "../../../ui/Text";
import { Form, TextInput, UploadInput } from "../../../ui/Input";

import styles from "./index.module.scss";

type PropsType = {
  organizationData: OrganizationData;
  createDocument: (data: CreateDocumentData, onSuccess?: () => void) => void;
  closeModal: () => void;
  addNotification: (data: NewNotification) => void;
};

type FormState = {
  filename: string;
  uploadFile: File | null;
};

const initialFormState = {
  filename: "",
  uploadFile: null
};

const formValidator = (values: FormState) => {
  return {
    filename: isRequired("Please enter a name")(values.filename),
    uploadFile: isRequired("Please upload a file")(values.uploadFile)
  };
};

const UploadDocument = ({
  organizationData,
  createDocument,
  closeModal,
  addNotification
}: PropsType) => {
  const [uploadingFile, setUploadingFile] = useState(false);

  const save = async (formValues: FormState) => {
    let s3FileName;

    setUploadingFile(true);
    try {
      const fileExt = formValues.uploadFile?.name.split(".").pop();
      const { url, filename } = await getSignedUrl({
        bucketName: BucketNameSuffixes.PUBLIC_DOCUMENTS,
        fileExt
      });
      s3FileName = filename;

      await uploadFiles(url, formValues.uploadFile);
    } catch (error) {
      setUploadingFile(false);

      return addNotification({
        type: "error",
        title: "Failed to upload file",
        subtitle: "Please try again"
      });
    }

    const data: CreateDocumentData = {
      filename: formValues.filename,
      organizationId: organizationData.id,
      s3FileName,
      bucketName: BucketNameSuffixes.PUBLIC_DOCUMENTS
    };

    createDocument(data, () => closeModal());
    setUploadingFile(false);
  };

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L" className={styles.Heading}>
        Create New Public Document
      </Heading>
      <Text className={styles.Warning}>
        The document created here will be publicly accessible on the internet. Do NOT upload any
        document containing PHI.
      </Text>
      <Form
        initialValues={initialFormState}
        validateFields={(values) => formValidator(values as FormState)}
        onSubmit={(formState) => save(formState.values as FormState)}
      >
        <TextInput fieldName="filename" label="Name" placeholder="Enter File Name" id="filename" />

        <Heading size="META">Upload your document</Heading>
        <UploadInput fieldName="uploadFile" label="Upload file" />
        <hr className={styles.Divider} />

        <div className={styles.ButtonGroup}>
          <Button inline onClick={closeModal}>
            Cancel
          </Button>
          <Button id="createDocument" type="submit" disabled={uploadingFile}>
            Create
          </Button>
        </div>
      </Form>
    </FloatModal>
  );
};

const mapStateToProps = ({ documents }: ReduxStateType) => {
  return {
    documents: documents.documents,
    loading: documents.documentsLoading
  };
};

export default connect(mapStateToProps, {
  createDocument: CreateDocumentAction,
  closeModal: closeModalAction,
  addNotification: addNotificationAction
})(UploadDocument);
