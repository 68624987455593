import React from "react";

type PropsType = {
  size?: number;
};

const CircleExclamation = ({ size = 14 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      fill="none"
      viewBox="0 0 14 14"
      preserveAspectRatio="xMidYMid"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0a7 7 0 1 1 0 14A7 7 0 0 1 7 0Zm0 1a6 6 0 1 0 0 12A6 6 0 0 0 7 1Zm.412 9.088a.583.583 0 1 1-.824.824.583.583 0 0 1 .824-.824ZM7 2.917c.322 0 .583.26.583.583v4.667a.583.583 0 0 1-1.166 0V3.5c0-.322.26-.583.583-.583Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default CircleExclamation;
