import React, { useContext, useEffect } from "react";
import moment from "moment-timezone";
import { connect } from "react-redux";

import {
  fetchDataForOrganization,
  fetchCampaigns,
  openModal as openModalAction,
  updateCampaign as updateCampaignAction,
  closeModal as closeModalAction,
  OpenModal
} from "../../../actions";

import Loader from "../../ui/Loader";
import TableGrid, { StopClickPropagation } from "../../ui/TableGrid";
import Heading from "../../ui/Heading";
import Button from "../../ui/Button";
import Status from "../../ui/Status";
import { EmptyContentImage } from "../../ui/Icon";

import { UserContext } from "../../providers/UserProvider";

import { PermissionsGuard } from "../../../hooks/usePermissions";

import formatDate from "../../../utils/formatDate";
import { ModalTypes } from "../../../constants";

import {
  OrganizationData,
  Campaign,
  ReduxStateType,
  CampaignStatus,
  Permissions
} from "../../../types";
import styles from "./campaigns.module.scss";
import useInterval from "../../../hooks/useInterval";

type PropsType = {
  fetchCampaigns: (options?: { silent: boolean }) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateCampaign: (id: number, data: any) => void;
  closeModal: () => void;
  openModal: OpenModal;
  loading: boolean;
  organizationData?: OrganizationData;
  campaigns: Array<Campaign> | null;
};

const POLL_INTERVAL_MS = 15000;
const CAMPAIGN_LAST_HOUR_OF_EXECUTION = 17;
const CAMPAIGN_INVOCATIONS_PER_HOUR = 4; // campaignSendMachine is setup to run every 15 min, hence 4 times per hour
const CAMPAIGN_INVOCATIONS_PER_DAY = 28; // this is based on 10am - 5pm rule for sending campaigns & so 4*7 = 28

const campaignLateStartWarning = "It is currently past 5 pm, so this campaign will start tomorrow.";
const campaignTwoDayWarning =
  "Due to the current time and number of recipients, this campaign will not be complete today.";

const CampaignsPage = ({
  fetchCampaigns,
  updateCampaign,
  closeModal,
  openModal,
  loading,
  organizationData,
  campaigns
}: PropsType) => {
  const user = useContext(UserContext);
  const { organizationId } = user;

  useEffect(() => {
    if (organizationId) {
      fetchCampaigns();
    }
  }, [organizationId]);

  // start interval for silent re-fetching of appointments
  useInterval(
    () => {
      if (organizationId) {
        fetchCampaigns({ silent: true });
      }
    },
    [organizationId],
    POLL_INTERVAL_MS
  );

  const openDetailsModal = (campaign: Campaign) => {
    openModal(ModalTypes.CAMPAIGN_DETAILS, { campaign });
  };

  const getWarningText = (campaign: Campaign) => {
    let warningText = null;
    if (organizationData) {
      const orgCurrentHour = moment().tz(organizationData?.timezone).hour();

      if (orgCurrentHour >= CAMPAIGN_LAST_HOUR_OF_EXECUTION) warningText = campaignLateStartWarning;

      if (orgCurrentHour < CAMPAIGN_LAST_HOUR_OF_EXECUTION) {
        const campaignInvocationsLeft =
          (CAMPAIGN_LAST_HOUR_OF_EXECUTION - orgCurrentHour) * CAMPAIGN_INVOCATIONS_PER_HOUR;
        const batchSize = Math.floor(campaign.sendRate / CAMPAIGN_INVOCATIONS_PER_DAY);
        const recipientsCanBeProcessed = campaignInvocationsLeft * batchSize;

        if (campaign.recipientCount > recipientsCanBeProcessed) {
          warningText = campaignTwoDayWarning;
        }
      }
    }
    return warningText;
  };

  const headers = [
    { colName: "title", content: "Title" },
    { colName: "createdAt", content: "Created" },
    { colName: "startedAt", content: "Start Date" },
    { colName: "sendRate", content: "Send Rate" },
    { colName: "recipients", content: "Recipients" },
    { colName: "status", content: "Status" },
    { colName: "startCampaign", content: "" }
  ];

  const campaignRows = (campaigns || [])
    .sort((a, b) => moment(b.created_at).unix() - moment(a.created_at).unix())
    .map((campaign) => {
      return {
        __onRowClick: () => openDetailsModal(campaign),
        title: campaign.name,
        createdAt: campaign.created_at ? formatDate(campaign.created_at) : "-",
        startedAt: campaign.startDate ? formatDate(campaign.startDate, "simpleDate") : "-",
        sendRate: `${campaign.sendRate} messages/day`,
        recipients: campaign.recipientCount || "-",
        status: campaign.status ? (
          <Status
            id={`campaignStatus-${campaign.id}`}
            value={campaign.status}
            options={[
              {
                label: "Active",
                value: CampaignStatus.ACTIVE
              },
              {
                label: "Complete",
                value: CampaignStatus.COMPLETE
              },
              {
                label: "Draft",
                value: CampaignStatus.DRAFT
              },
              {
                label: "Error",
                value: CampaignStatus.ERROR
              },
              {
                label: "File Error",
                value: CampaignStatus.FILE_ERROR
              },
              {
                label: "Creating Recipients",
                value: CampaignStatus.CREATING_RECIPIENTS
              }
            ]}
            configMap={{
              [CampaignStatus.COMPLETE]: "green",
              [CampaignStatus.ACTIVE]: "blue",
              [CampaignStatus.DRAFT]: "yellow",
              [CampaignStatus.ERROR]: "red",
              [CampaignStatus.FILE_ERROR]: "red",
              [CampaignStatus.CREATING_RECIPIENTS]: "yellow",
              default: "gray"
            }}
          />
        ) : (
          "-"
        ),
        startCampaign: campaign.status === CampaignStatus.DRAFT && (
          <PermissionsGuard requiredPermissions={[Permissions.CREATE_CAMPAIGN]}>
            <StopClickPropagation>
              <Button
                id="startCampaign"
                inline
                onClick={() => {
                  const warningText = getWarningText(campaign);
                  openModal(ModalTypes.CONFIRMATION, {
                    title: "Are you sure you want to start the Campaign?",
                    warningText,
                    onConfirmation: () => {
                      updateCampaign(Number(campaign.id), { status: CampaignStatus.ACTIVE });
                      closeModal();
                    }
                  });
                }}
              >
                Start Campaign
              </Button>
            </StopClickPropagation>
          </PermissionsGuard>
        )
      };
    });

  if (!organizationData) {
    return <Loader />;
  }

  return (
    <div>
      <div className={styles.Header}>
        <Heading size="XL" component="span" className={styles.Heading}>
          Campaigns
        </Heading>
        <PermissionsGuard requiredPermissions={[Permissions.CREATE_CAMPAIGN]}>
          <Button
            id="newCampaignBtn"
            onClick={async () => {
              openModal(ModalTypes.ADD_CAMPAIGN, { organizationData });
            }}
          >
            Create Campaign
          </Button>
        </PermissionsGuard>
      </div>
      {loading || !campaigns ? (
        <Loader screen />
      ) : (
        <TableGrid
          headers={headers}
          rows={campaignRows}
          maxPageRows={20}
          showRowFocus
          emptyContent={
            <div className={styles.NoAppointments}>
              <Heading>There are no campaigns</Heading> <EmptyContentImage />
            </div>
          }
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ organizationData, campaigns }: ReduxStateType) => ({
  organizationData: organizationData?.organizationData,
  campaigns: campaigns.campaigns,
  loading: campaigns.loading
});

export default connect(mapStateToProps, {
  fetchDataForOrganization,
  fetchCampaigns,
  closeModal: closeModalAction,
  updateCampaign: updateCampaignAction,
  openModal: openModalAction
})(CampaignsPage);
