import axios from "axios";

import getToken from "../utils/getToken";

import { addNotification } from "./notifications";

import {
  FETCH_NOTES,
  CREATE_NOTE,
  UPDATE_NOTE,
  SYNC_NOTE,
  CLEAR_NOTES,
  DELETE_NOTE,
  ROOT_URL
} from "../constants";
import { ActionStatus, Note, Dispatch } from "../types";

export type FetchNoteFilters = {
  patientId: number;
  appointmentId: number;
};

export type NoteFetchData = {
  note?: Note;
};

export type FetchNotesAction = {
  type: typeof FETCH_NOTES;
  status?: ActionStatus;
  payload?: { notes: Note[] };
};

export const fetchNotes = (body: FetchNoteFilters) => async (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  dispatch({
    type: FETCH_NOTES,
    status: ActionStatus.loading
  });

  // Try and load notes
  try {
    const response = await axios.post(`${ROOT_URL}/notes`, body, config);

    return dispatch({
      type: FETCH_NOTES,
      status: ActionStatus.success,
      payload: response.data
    });
  } catch (error) {
    dispatch(
      addNotification({
        type: "error",
        title: "Error fetching notes",
        subtitle: "Please try again",
        autoDismiss: true
      })
    );
    return dispatch({
      type: FETCH_NOTES,
      status: ActionStatus.error
    });
  }
};

export type CreateNoteData = {
  noteTemplateId: number;
  patientId: number;
  appointmentId: number;
};

export type CreateNoteResponse = {
  note: Note;
};

export type CreateNoteAction = {
  type: typeof CREATE_NOTE;
  status?: ActionStatus;
  payload?: CreateNoteResponse;
};

export const createNote =
  (body: CreateNoteData, onSuccess?: (noteId: number) => void) => async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: CREATE_NOTE,
      status: ActionStatus.loading
    });

    try {
      const response = await axios.post(`${ROOT_URL}/notes/initialize`, body, config);

      dispatch({
        type: CREATE_NOTE,
        status: ActionStatus.success,
        payload: response.data
      });

      if (onSuccess) {
        onSuccess(response.data.note.id);
      }
    } catch (error) {
      dispatch(
        addNotification({
          type: "error",
          title: "Error creating note",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
      return dispatch({
        type: CREATE_NOTE,
        status: ActionStatus.error
      });
    }
  };

export type UpdateNotesAction = {
  type: typeof UPDATE_NOTE;
  status: ActionStatus;
  payload?: {
    note: Note;
  };
};

export type UpdateNoteData = { content: string };

export type UpdateNoteOptions = {
  silent?: boolean;
};

type UpdateNoteResponse = {
  status: number;
  data: {
    success: boolean;
    note: Note;
  };
};

export const updateNote =
  (noteId: number, data: UpdateNoteData, options?: UpdateNoteOptions) => (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };
    const isSilentUpdate = options?.silent;

    const handleError = () => {
      dispatch({
        type: UPDATE_NOTE,
        status: ActionStatus.error
      });

      dispatch(
        addNotification({
          type: "error",
          title: `Failed to update note.`,
          subtitle: "Please try again",
          autoDismiss: true
        })
      );

      return dispatch({
        type: UPDATE_NOTE,
        status: ActionStatus.success
      });
    };

    dispatch({
      type: UPDATE_NOTE,
      status: isSilentUpdate ? ActionStatus.silentLoading : ActionStatus.loading
    });

    return axios
      .put(`${ROOT_URL}/notes/${noteId}`, { data }, config)
      .then((payload: UpdateNoteResponse) => {
        if (!payload || !payload.data.success || !payload.data.note) {
          return handleError();
        }

        return dispatch({
          type: UPDATE_NOTE,
          status: ActionStatus.success,
          payload: {
            note: payload.data.note
          }
        });
      })
      .catch((error) => {
        // Below catch is added to avoid throwing update error after deletion of a note [IHA-6506] Scribe Multi Templates
        if (error?.message?.includes("404")) {
          return;
        }
        return handleError();
      });
  };

export type SyncNoteAction = {
  type: typeof SYNC_NOTE;
  status: ActionStatus;
  payload: {
    note: Note;
    currentContent?: string;
  };
};

type SyncNoteResponse = {
  status: number;
  data: {
    success: boolean;
    note: Note;
  };
};

export const syncNote = (note: Note, currentContent: string) => (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  dispatch({
    type: SYNC_NOTE,
    status: ActionStatus.loading,
    payload: {
      note,
      currentContent
    }
  });

  const handleError = () => {
    dispatch({
      type: SYNC_NOTE,
      status: ActionStatus.error,
      payload: {
        note,
        currentContent
      }
    });

    dispatch(
      addNotification({
        type: "error",
        title: `Failed to sync note.`,
        subtitle: "Please try again",
        autoDismiss: true
      })
    );

    return dispatch({
      type: SYNC_NOTE,
      status: ActionStatus.success,
      payload: {
        note,
        currentContent
      }
    });
  };

  return axios
    .post(`${ROOT_URL}/notes/${note.id}`, { noteContent: currentContent }, config)
    .then((payload: SyncNoteResponse) => {
      if (!payload || !payload.data.success || !payload.data.note) {
        return handleError();
      }

      dispatch(
        addNotification({
          type: "success",
          title: `Success`,
          subtitle: "Note synced",
          autoDismiss: true
        })
      );

      return dispatch({
        type: SYNC_NOTE,
        status: ActionStatus.success,
        payload: {
          note: payload.data.note
        }
      });
    })
    .catch(() => {
      return handleError();
    });
};

export type ClearNotesAction = {
  type: typeof CLEAR_NOTES;
};

export const clearNotes = (): ClearNotesAction => ({
  type: CLEAR_NOTES
});

type DeleteNoteResponse = {
  status: number;
  data: {
    success?: boolean;
    deletedNote?: Note;
    error?: boolean;
  };
};

export type DeleteNoteAction = {
  type: typeof DELETE_NOTE;
  status?: ActionStatus;
  payload?: { deletedNote?: Note };
};

export const deleteNote = (noteId: number, onSuccess?: () => void) => (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  dispatch({
    type: DELETE_NOTE,
    status: ActionStatus.loading
  });

  return axios
    .delete(`${ROOT_URL}/notes/${noteId}`, config)
    .then((payload: DeleteNoteResponse) => {
      if (!payload || payload.data.error) {
        dispatch({
          type: DELETE_NOTE,
          status: ActionStatus.error
        });

        return dispatch(
          addNotification({
            type: "error",
            title: "Failed to delete note",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
      }

      dispatch(
        addNotification({
          type: "success",
          title: "Success",
          subtitle: "Successfully deleted note",
          autoDismiss: true
        })
      );

      dispatch({
        type: DELETE_NOTE,
        status: ActionStatus.success,
        payload: {
          deletedNote: payload.data.deletedNote
        }
      });

      if (onSuccess) {
        onSuccess();
      }
    })
    .catch(() => {
      dispatch({
        type: DELETE_NOTE,
        status: ActionStatus.error
      });

      return dispatch(
        addNotification({
          type: "error",
          title: "Failed to delete note",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
    });
};
