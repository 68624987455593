import React, { useEffect } from "react";
import { connect } from "react-redux";

import Button from "../../../../../ui/Button";
import { FullScreenModal } from "../../../../../ui/Modal";
import Loader from "../../../../../ui/Loader";
import { Form } from "../../../../../ui/Input";
import FormContent from "./FormContent";

import {
  fetchMessageTemplateDetail,
  updateMessageTemplate,
  openModal as OpenModalAction,
  OpenModal
} from "../../../../../../actions";

import { AdminModalTypes, ModalTypes } from "../../../../../../constants";

import {
  formValidator,
  MessageTemplateFormState
} from "../../../../../../utils/validators/editMessageTemplateFormValidator";

import {
  ChatFlow,
  MessageTemplate,
  MessageTemplateUpdateData,
  ReduxStateType
} from "../../../../../../types";
import { isSmsFriendly } from "../../../../../../utils/validators";

type PropsType = {
  messageTemplateId?: string;
  organizationName: string;
  messageTemplate: MessageTemplate | null;
  availableChats: Array<ChatFlow>;
  isModalOpen: boolean;
  loading?: boolean;
  closeModal: () => void;
  openModal: OpenModal;
  fetchMessage: (id: number) => void;
  updateMessage: (id: number, data: MessageTemplateUpdateData) => void;
};

const MessageTemplateDetails = ({
  messageTemplateId,
  organizationName,
  messageTemplate,
  availableChats,
  isModalOpen,
  loading,
  closeModal,
  openModal,
  fetchMessage,
  updateMessage
}: PropsType) => {
  const initialState: MessageTemplateFormState | null = messageTemplate;
  const payloadContentWarning = isSmsFriendly();
  useEffect(() => {
    if (messageTemplateId && typeof messageTemplateId === "string") {
      const id = Number.parseInt(messageTemplateId, 10);
      fetchMessage(id);
    }
  }, [messageTemplateId]);

  const chatOptions = [
    { label: "None", value: "" },
    ...availableChats.map((chat) => {
      return { label: `${chat.title}${chat.version ? ` (${chat.version})` : ""}`, value: chat.id };
    })
  ];

  const save = (formState: MessageTemplateFormState) => {
    if (messageTemplate) {
      const updateData = {
        id: messageTemplate.id,
        displayName: formState.displayName,
        payloadContent: formState.payloadContent,
        chatFlowId: formState.chatFlowId || null,
        topic: formState.topic,
        medium: formState.medium,
        subject: formState.subject
      };
      updateMessage(messageTemplate.id, updateData);
    }
  };

  const openSendTestModal = () => {
    if (messageTemplate) {
      openModal(ModalTypes.SEND_MESSAGE_TEMPLATE, {
        messageTemplate
      });
    }
  };

  const openDeleteModal = () => {
    if (messageTemplate) {
      openModal(AdminModalTypes.DELETE_SMS_MESSAGE_TEMPLATE, {
        messageTemplate,
        onSuccess: () => closeModal()
      });
    }
  };

  const formDisabled = loading;

  if (!initialState || !messageTemplate)
    return (
      <FullScreenModal
        subtitle={`${organizationName} > Edit Message`}
        isOpen={isModalOpen}
        onClose={closeModal}
        headerButton={
          <Button type="submit" disabled>
            Save
          </Button>
        }
      >
        <Loader screen />
      </FullScreenModal>
    );

  return (
    <Form
      onSubmit={(formState) => save(formState.values as MessageTemplateFormState)}
      initialValues={initialState}
      validateFields={(values) => formValidator(values as MessageTemplateFormState)}
    >
      <FormContent
        loading={loading}
        organizationName={organizationName}
        messageTemplate={messageTemplate}
        isModalOpen={isModalOpen}
        openSendTestModal={openSendTestModal}
        openDeleteModal={openDeleteModal}
        formDisabled={formDisabled}
        payloadContentWarning={payloadContentWarning}
        chatOptions={chatOptions}
        initialState={initialState}
        closeModal={closeModal}
      />
    </Form>
  );
};

const mapStateToProps = ({ messageTemplates, organizationData }: ReduxStateType) => {
  return {
    organizationName: organizationData.organizationData
      ? organizationData.organizationData.displayName
      : "",
    messageTemplate: messageTemplates.messageTemplateDetail.messageTemplate,
    availableChats: messageTemplates.messageTemplateDetail.availableChats,
    loading: messageTemplates.messageTemplateUpdateLoading
  };
};

export default connect(mapStateToProps, {
  fetchMessage: fetchMessageTemplateDetail,
  updateMessage: updateMessageTemplate,
  openModal: OpenModalAction
})(MessageTemplateDetails);
