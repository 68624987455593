import { Reason } from "../../../../types";
import { ReminderTypePreference, FEATURES } from "../../../../constants";

const EMPTY_REASON_LABEL = "[Nameless Reason]";
const NULL_REASON_LABEL = "[No Reason]";

export const getReasonLabel = (reason: Reason) => {
  if (reason.value === null) return NULL_REASON_LABEL;
  if (reason.value === "") return EMPTY_REASON_LABEL;
  return reason.value;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTotalCount = (appointmentFetchData?: any) => {
  return appointmentFetchData?.allApptsCount ?? 1;
};

const determineValidMessageMedium = (optOutStatus: boolean, mediumValue: string) => {
  if (optOutStatus === true) {
    return false;
  }

  if (mediumValue && mediumValue.length > 0) {
    return true;
  }

  return false;
};

export const determineValidMessageMediums = (
  reminderTypePreference: string,
  features: string[],
  patientContactData: {
    mobilePhone?: string | null;
    smsOptOut?: boolean;
    email?: string | null;
    emailOptOut?: boolean;
    voicePhone?: string;
    voiceOptOut?: boolean;
  }
) => {
  const { mobilePhone, smsOptOut, email, emailOptOut, voicePhone, voiceOptOut } =
    patientContactData;

  let smsValid = false;
  let emailValid = false;
  let voiceValid = false;

  const voiceFallbackEnabled = features?.find(
    (feature) => feature === FEATURES.VOICE_FALLBACK.value
  );

  if (reminderTypePreference === ReminderTypePreference.SMS_ONLY.value) {
    smsValid = determineValidMessageMedium(smsOptOut as boolean, mobilePhone as string);
  }
  if (reminderTypePreference === ReminderTypePreference.PREFER_SMS.value) {
    smsValid = determineValidMessageMedium(smsOptOut as boolean, mobilePhone as string);

    if (!smsValid) {
      emailValid = determineValidMessageMedium(emailOptOut as boolean, email as string);
    }
  }
  if (reminderTypePreference === ReminderTypePreference.PREFER_EMAIL.value) {
    emailValid = determineValidMessageMedium(emailOptOut as boolean, email as string);

    if (!emailValid) {
      smsValid = determineValidMessageMedium(smsOptOut as boolean, mobilePhone as string);
    }
  }
  if (reminderTypePreference === ReminderTypePreference.BOTH.value) {
    smsValid = determineValidMessageMedium(smsOptOut as boolean, mobilePhone as string);

    emailValid = determineValidMessageMedium(emailOptOut as boolean, email as string);
  }

  if (smsValid || emailValid) {
    return { smsValid, emailValid, voiceValid, voiceFallbackEnabled };
  }

  if (voiceFallbackEnabled && voicePhone && !voiceOptOut) {
    voiceValid = true;
  }

  return { smsValid, emailValid, voiceValid, voiceFallbackEnabled };
};
