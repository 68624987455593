import axios from "axios";

import getToken from "../utils/getToken";
import { addNotification } from "./notifications";

import {
  ROOT_URL,
  SERVICES_FETCH,
  SERVICES_ADD,
  SERVICES_FIND_NUMBERS,
  SERVICES_BUY_NUMBERS,
  SERVICES_CLEAR_NUMBER_FIND,
  SERVICES_DELETE,
  SERVICES_UPDATE
} from "../constants";

import { Service, ActionStatus, Dispatch } from "../types";

export type FetchServicesDataAction = {
  type: typeof SERVICES_FETCH;
  status?: ActionStatus;
  payload?: { data: Array<Service> };
};

export const fetchServices =
  (organizationId: number) =>
  (dispatch: Dispatch): Promise<void> => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: SERVICES_FETCH,
      status: ActionStatus.loading
    });

    return axios
      .get(`${ROOT_URL}/organizations/${organizationId}/services`, config)
      .then((payload) => {
        return dispatch({
          type: SERVICES_FETCH,
          status: ActionStatus.success,
          payload
        });
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to fetch services",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
        return dispatch({
          type: SERVICES_FETCH,
          status: ActionStatus.error
        });
      });
  };

export type AddServiceAction = {
  type: typeof SERVICES_ADD;
  status?: ActionStatus;
};

export type AddServiceData = {
  organizationId: number;
  provider: string;
};

type AddServiceResponse = {
  status: number;
  data: Service;
};

export const addService = (data: AddServiceData) => async (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };
  dispatch({
    type: SERVICES_ADD,
    status: ActionStatus.loading
  });

  const { organizationId } = data;

  return axios
    .post(`${ROOT_URL}/organizations/${organizationId}/create-message-service`, data, config)
    .then((payload: AddServiceResponse) => {
      if (!payload || payload.status !== 200) {
        dispatch({
          type: SERVICES_ADD,
          status: ActionStatus.error
        });

        return dispatch(
          addNotification({
            type: "error",
            title: `Failed to add a new service`,
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
      }

      dispatch(
        addNotification({
          type: "success",
          title: "Success",
          subtitle: `Successfully added a new service`,
          autoDismiss: true
        })
      );

      return fetchServices(organizationId)(dispatch).then(() => {
        dispatch({
          type: SERVICES_ADD,
          status: ActionStatus.success
        });
      });
    })
    .catch((error) => {
      dispatch(
        addNotification({
          type: "error",
          title: `Failed to add a new service.`,
          subtitle: error.response.data || "Please try again",
          autoDismiss: true
        })
      );

      dispatch({
        type: SERVICES_ADD,
        status: ActionStatus.error
      });
    });
};

export type FindPhoneNumbersAction = {
  type: typeof SERVICES_FIND_NUMBERS;
  status?: ActionStatus;
  payload?: { data: Array<string> };
};

export type FindPhoneNumbersData = {
  organizationId: number;
  newPhoneCount: number;
  city: string | undefined;
  areaCodeList: Array<string> | undefined;
  serviceRecordId: number;
};

type FindPhoneNumbersResponse = {
  status: number;
  data: Array<string>;
};

export const findPhoneNumbers = (data: FindPhoneNumbersData) => async (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };
  dispatch({
    type: SERVICES_FIND_NUMBERS,
    status: ActionStatus.loading
  });

  return axios
    .post(`${ROOT_URL}/organizations/${data.organizationId}/list-phone-numbers`, data, config)
    .then((payload: FindPhoneNumbersResponse) => {
      if (!payload || payload.status !== 200) {
        dispatch({
          type: SERVICES_FIND_NUMBERS,
          status: ActionStatus.error
        });

        return dispatch(
          addNotification({
            type: "error",
            title: `Failed to find phone numbers`,
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
      }

      return dispatch({
        type: SERVICES_FIND_NUMBERS,
        status: ActionStatus.success,
        payload
      });
    })
    .catch(() => {
      dispatch(
        addNotification({
          type: "error",
          title: `Failed to find phone numbers`,
          subtitle: "Please try again",
          autoDismiss: true
        })
      );

      dispatch({
        type: SERVICES_FIND_NUMBERS,
        status: ActionStatus.error
      });
    });
};

export type BuyPhoneNumbersAction = {
  type: typeof SERVICES_BUY_NUMBERS;
  status?: ActionStatus;
};

export type BuyPhoneNumbersData = {
  organizationId: number;
  numbersList: Array<string>;
  serviceRecordId: number;
};

type BuyPhoneNumbersResponse = {
  status: number;
  data: Array<string>;
};

export const buyPhoneNumbers =
  (data: BuyPhoneNumbersData, onSuccess?: () => void) => async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };
    dispatch({
      type: SERVICES_BUY_NUMBERS,
      status: ActionStatus.loading
    });

    const { organizationId } = data;

    return axios
      .post(`${ROOT_URL}/organizations/${organizationId}/register-twilio-numbers`, data, config)
      .then((payload: BuyPhoneNumbersResponse) => {
        if (!payload || payload.status !== 200) {
          dispatch({
            type: SERVICES_BUY_NUMBERS,
            status: ActionStatus.error
          });

          return dispatch(
            addNotification({
              type: "error",
              title: `Failed to purchase phone numbers`,
              subtitle: "Please try again",
              autoDismiss: true
            })
          );
        }

        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: `Successfully purchased phone numbers`,
            autoDismiss: true
          })
        );

        if (onSuccess) {
          onSuccess();
        }

        return fetchServices(organizationId)(dispatch).then(() => {
          dispatch({
            type: SERVICES_BUY_NUMBERS,
            status: ActionStatus.success
          });
        });
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: "error",
            title: `Failed to purchase phone numbers`,
            subtitle: "Please try again",
            autoDismiss: true
          })
        );

        dispatch({
          type: SERVICES_BUY_NUMBERS,
          status: ActionStatus.error
        });
      });
  };

export type ClearNumbersFindResultAction = {
  type: typeof SERVICES_CLEAR_NUMBER_FIND;
  status?: ActionStatus;
};

export const clearNumbersFindResult = () => async (dispatch: Dispatch) => {
  dispatch({
    type: SERVICES_CLEAR_NUMBER_FIND,
    status: ActionStatus.success
  });
};

export type DeleteServiceAction = {
  type: typeof SERVICES_DELETE;
  status?: ActionStatus;
};

export type DeleteServiceData = {
  organizationId: number;
  serviceId: number;
};

type DeleteServiceResponse = {
  status: number;
  data: Array<string>;
};

export const deleteService =
  (data: DeleteServiceData, onSuccess?: () => void) => async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };
    dispatch({
      type: SERVICES_DELETE,
      status: ActionStatus.loading
    });

    const { organizationId, serviceId } = data;

    return axios
      .delete(`${ROOT_URL}/organizations/${organizationId}/services/${serviceId}`, config)
      .then((payload: DeleteServiceResponse) => {
        if (!payload || payload.status !== 200) {
          dispatch({
            type: SERVICES_DELETE,
            status: ActionStatus.error
          });

          return dispatch(
            addNotification({
              type: "error",
              title: `Failed to delete service`,
              subtitle: "Please try again",
              autoDismiss: true
            })
          );
        }

        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: `Successfully deleted service`,
            autoDismiss: true
          })
        );

        if (onSuccess) {
          onSuccess();
        }

        return fetchServices(organizationId)(dispatch).then(() => {
          dispatch({
            type: SERVICES_DELETE,
            status: ActionStatus.success
          });
        });
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: "error",
            title: `Failed to delete service`,
            subtitle: "Please try again",
            autoDismiss: true
          })
        );

        dispatch({
          type: SERVICES_DELETE,
          status: ActionStatus.error
        });
      });
  };

export type UpdateServiceAction = {
  type: typeof SERVICES_UPDATE;
  status?: ActionStatus;
};

export type UpdateServiceData = {
  organizationId: number;
  serviceId: number;
};

export const updateService =
  (data: UpdateServiceData, numberToAdd: string, onSuccess?: () => void) =>
  async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };
    dispatch({
      type: SERVICES_UPDATE,
      status: ActionStatus.loading
    });

    const { organizationId, serviceId } = data;

    return axios
      .put(
        `${ROOT_URL}/organizations/${organizationId}/services/${serviceId}`,
        { numberToAdd },
        config
      )
      .then((payload) => {
        if (!payload || payload.status !== 200) {
          dispatch({
            type: SERVICES_UPDATE,
            status: ActionStatus.error
          });

          return dispatch(
            addNotification({
              type: "error",
              title: `Failed to update service`,
              subtitle: "Please try again",
              autoDismiss: true
            })
          );
        }

        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: `Successfully updated service`,
            autoDismiss: true
          })
        );

        if (onSuccess) {
          onSuccess();
        }

        return fetchServices(organizationId)(dispatch).then(() => {
          dispatch({
            type: SERVICES_UPDATE,
            status: ActionStatus.success
          });
        });
      })
      .catch((error) => {
        const { data: errorMessage } = error.response;
        dispatch(
          addNotification({
            type: "error",
            title: `Failed to update service`,
            subtitle: `${errorMessage}`,
            autoDismiss: true
          })
        );

        dispatch({
          type: SERVICES_UPDATE,
          status: ActionStatus.error
        });
      });
  };
