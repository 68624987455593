import React from "react";

import Heading from "../../../Heading";
import Text from "../../../Text";
import Image from "../../../Image";
import styles from "./StartScribeSessionThree.module.scss";
import Button from "../../../Button";
import { ExternalLink } from "../../../Icon";

const StartScribeSessionThree = (): React.ReactElement => {
  return (
    <div>
      <Heading size="M" component="h3" darkMode>
        Real time transcript
      </Heading>
      <Text size="S" darkMode>
        If everything’s working, you’ll see a real-time transcript of the conversation begin to
        appear.
      </Text>
      <br />
      <Text size="S" darkMode>
        Don’t see a transcript? There may be an issue with your microphone.
      </Text>
      <br />

      <a
        href="https://mikata-health.gitbook.io/mikata-product-guide-for-customers/mika-scribe/issues-and-ideas/audio-troubleshooting"
        rel="noreferrer"
        target="_blank"
      >
        <Button inline darkMode className={styles.LinkButton}>
          <Text size="S" darkMode className={styles.LinkText}>
            Click here for help.
          </Text>
          <ExternalLink size={18} />
        </Button>
      </a>
      <br />
      <Image src="/img/announcements/realTimeTranscript.png" />
    </div>
  );
};

export default StartScribeSessionThree;
