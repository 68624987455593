import React from "react";

type PropsType = {
  size?: number;
};

const Pin = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2634_5770)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.668 2.08237C14.0585 1.69185 14.6917 1.69185 15.0822 2.08237L22.1533 9.15344C22.5438 9.54396 22.5438 10.1771 22.1533 10.5677L20.7391 11.9819C20.3486 12.3724 19.7154 12.3724 19.3249 11.9819L17.9107 10.5677L15.3765 13.1018C15.7913 14.0803 16.011 15.141 16.011 16.2244C16.011 18.0109 15.4135 19.7358 14.3291 21.1318C13.9903 21.568 13.3513 21.5646 12.9608 21.1741L8.01103 16.2244L3.06128 11.2746C2.67076 10.8841 2.66743 10.2451 3.10359 9.90634C4.49965 8.82192 6.22453 8.22437 8.01103 8.22437C9.09454 8.22437 10.1554 8.44417 11.1339 8.85909L13.668 6.32501L12.2538 4.9108C11.8633 4.52027 11.8633 3.88711 12.2538 3.49659L13.668 2.08237Z"
          fill="#D9605E"
        />
        <path
          d="M2.39344 20.5062C2.00291 20.8968 2.00291 21.5299 2.39344 21.9204C2.78396 22.311 3.41713 22.311 3.80765 21.9204L2.39344 20.5062ZM7.34319 15.5565L2.39344 20.5062L3.80765 21.9204L8.7574 16.9707L7.34319 15.5565Z"
          fill="#D7D8DB"
        />
      </g>
      <defs>
        <clipPath id="clip0_2634_5770">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Pin;
