import React, { useState } from "react";
import { connect } from "react-redux";

import {
  XYPlot,
  VerticalGridLines,
  HorizontalGridLines,
  XAxis,
  YAxis,
  VerticalBarSeries,
  ChartLabel
} from "react-vis";

import { setProcessingStartTime as setProcessingStartTimeAction } from "../../../../../actions/analytics";

import styles from "./index.module.scss";

import Heading from "../../../../../components/ui/Heading";
import Text from "../../../../../components/ui/Text";
import Button from "../../../../../components/ui/Button";

import { ReduxStateType } from "../../../../../types";

type DataPoint = {
  x?: string;
  y?: number;
};

type PropsType = {
  averageProcessingTime?: number;
  queueTime?: number;
  processingTimeByReason: { x: string; y: number }[];
  chatCompletionTime: number;
  averageProcessingTimeLoading?: boolean;
  queueTimeLoading?: boolean;
  processingTimeByReasonLoading?: boolean;
  chatCompletionTimeLoading?: boolean;
  setProcessingStartTime: (filter: string) => void;
};

const RequestProcessingTime = ({
  averageProcessingTime,
  queueTime,
  processingTimeByReason,
  chatCompletionTime,
  averageProcessingTimeLoading,
  queueTimeLoading,
  processingTimeByReasonLoading,
  chatCompletionTimeLoading,
  setProcessingStartTime
}: PropsType) => {
  const [hoverPoint, setHoverPoint] = useState<DataPoint>({});

  const averageDisplay = averageProcessingTimeLoading
    ? "..."
    : Math.round((averageProcessingTime || 0) * 10) / 10;
  const completionTimeDisplay = chatCompletionTimeLoading
    ? "..."
    : Math.round((chatCompletionTime || 0) * 10) / 10;
  const queueTimeDisplay = queueTimeLoading ? "..." : Math.round((queueTime || 0) * 10) / 10;

  const yMax =
    processingTimeByReason?.length && processingTimeByReason.sort((a, b) => b.y - a.y)[0].y;
  const yAxisMax = yMax ? Math.round(Number(yMax)) * 1.1 : 10;

  return (
    <div className={styles.Card}>
      <div className={styles.Wrapper}>
        <Heading size="M" component="h2">
          Request Processing Time
        </Heading>
        <div>
          <Text>Organization average: {averageDisplay} hours</Text>
          {hoverPoint.x && (
            <Text>
              {hoverPoint.x}: {hoverPoint.y} hours
            </Text>
          )}
        </div>
        <div>
          <Button onClick={() => setProcessingStartTime("submittedAt")} inline>
            <Text>From submission</Text>
          </Button>
          <Button onClick={() => setProcessingStartTime("assignedAt")} inline>
            <Text>From assignment</Text>
          </Button>
        </div>
      </div>
      <XYPlot height={400} width={1000} xType="ordinal" margin={80} yDomain={[0, yAxisMax]}>
        <XAxis
          tickLabelAngle={-25}
          tickFormat={(label) => {
            const maxLength = 20;
            return label.length > maxLength ? `${label.slice(0, maxLength - 1)}...` : label;
          }}
        />
        <YAxis
          style={{
            line: { stroke: "#ADDDE1" }
          }}
        />
        <ChartLabel
          text="Processing Time (hrs)"
          className="alt-y-label"
          includeMargin
          xPercent={0.03}
          yPercent={-0.01}
          style={{
            transform: "rotate(-90)",
            textAnchor: "end"
          }}
        />
        <VerticalGridLines />
        <HorizontalGridLines tickTotal={10} />
        <VerticalBarSeries
          data={processingTimeByReason}
          barWidth={0.5}
          animation="noWobble"
          onValueMouseOver={(datapoint, event) => {
            const newHoverPoint = { x: datapoint.x, y: Math.round(datapoint.y * 10) / 10 };
            setHoverPoint(newHoverPoint as DataPoint);
          }}
        />
      </XYPlot>
      <div className={styles.Divider} />
      <Text size="M">
        Average time for patient to complete request chat: {completionTimeDisplay} minutes
      </Text>
      <Text size="M">Average request wait time in queue: {queueTimeDisplay} hours</Text>
    </div>
  );
};

const mapStateToProps = ({ analytics }: ReduxStateType) => {
  const { averageProcessingTime, queueTime, chatCompletionTime, processingTimeByReason } =
    analytics;
  return { averageProcessingTime, queueTime, chatCompletionTime, processingTimeByReason };
};

export default connect(mapStateToProps, {
  setProcessingStartTime: setProcessingStartTimeAction
})(RequestProcessingTime);
