import { ChatNodeTypes } from "../../../../../types";

export const isInInfoModal = (typePath: Array<ChatNodeTypes>): boolean => {
  const infoModalIndex = typePath.indexOf(ChatNodeTypes.infoModal);
  return infoModalIndex >= 0;
};
export const isNestedList = (typePath: Array<ChatNodeTypes>): boolean => {
  const infoModalIndex = typePath.indexOf(ChatNodeTypes.infoModal);
  const relevantTypePath = infoModalIndex >= 0 ? typePath.slice(infoModalIndex) : typePath;
  return relevantTypePath.filter((type) => type === ChatNodeTypes.list).length > 1;
};

export const isCardLevel = (typePath: Array<ChatNodeTypes>): boolean => {
  const infoModalIndex = typePath.indexOf(ChatNodeTypes.infoModal);
  const relevantTypePath = infoModalIndex ? typePath.slice(infoModalIndex) : typePath;

  return relevantTypePath.length <= 2;
};
