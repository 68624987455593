import React, { useContext, useRef } from "react";
import Modal from "react-modal";
import cx from "classnames";

import Button from "../../../ui/Button";
import Card from "../../../ui/Card";
import { Close } from "../../../ui/Icon";
import { useFocusTrap } from "../../Modal/useFocusTrap";

// Announcement Components
import ScribeWelcome from "./Announcements/ScribeWelcome";
import NewScribeFeatures from "./Announcements/NewScribeFeatures";

import { AnnouncementsContext } from "../../../providers/AnnouncementsProvider";

import { Announcement, AnnouncementName } from "../../../../types";

import styles from "./index.module.scss";

const announcementsComponentMap: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [announcementModalName: string]: (modalAnnouncementProps: any) => React.ReactNode;
} = {
  [AnnouncementName.SCRIBE_WELCOME]: ScribeWelcome,
  [AnnouncementName.NEW_SCRIBE_FEATURES]: NewScribeFeatures
};

const AnnouncementsModalManager = () => {
  const { modalAnnouncements, markAnnouncementComplete } = useContext(AnnouncementsContext);
  const modalRef = useRef<HTMLDivElement>();

  const isOpen = modalAnnouncements?.length > 0;

  const onClose = () => {
    modalAnnouncements.forEach((announcement) => {
      if (markAnnouncementComplete) markAnnouncementComplete(announcement.name);
    });
  };

  useFocusTrap({ modalRef, isOpen, onClose });
  Modal.setAppElement("#root");

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      portalClassName={styles.Portal}
      className={styles.Modal}
      overlayClassName={styles.Overlay}
      contentLabel="Announcements"
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      shouldFocusAfterRender
      shouldCloseOnEsc
      shouldReturnFocusAfterClose
      contentRef={(ref): void => {
        modalRef.current = ref;
      }}
    >
      <Card
        fullWidth={modalAnnouncements?.[0]?.name === AnnouncementName.SCRIBE_WELCOME}
        className={cx(styles.MainContent)}
      >
        <Button id="close" inline className={styles.CloseButton} onClick={onClose}>
          <Close />
        </Button>
        {modalAnnouncements.map((announcement: Announcement) => {
          const SpecificAnnouncement = announcementsComponentMap[announcement.name as string];

          if (!SpecificAnnouncement || modalAnnouncements?.[0]?.name !== announcement.name) {
            return null;
          }

          return (
            <SpecificAnnouncement
              key={`announcement-${announcement.name}`}
              announcement={announcement}
              markAnnouncementComplete={markAnnouncementComplete}
            />
          );
        })}
      </Card>
    </Modal>
  );
};

export default AnnouncementsModalManager;
