import React, { useEffect, useState, useContext } from "react";
import cx from "classnames";
import { useSearchParams } from "react-router-dom";
import { connect } from "react-redux";

import Button from "../../../../../ui/Button";
import { Send } from "../../../../../ui/Icon";
import { FullScreenModal } from "../../../../../ui/Modal";
import ChatBuilder from "./ChatBuilder";
import ReportBuilder from "./ReportBuilder";
import Outputs from "./Outputs";

import {
  openModal as OpenModalAction,
  OpenModal,
  getChatFlowDetails as getChatFlowDetailsAction,
  clearChatFlowDetails as clearChatFlowDetailsAction,
  cloneChatFlow as cloneChatFlowAction,
  clearReportTemplateDetails as clearReportTemplateDetailsAction
} from "../../../../../../actions";
import { updateQueryString, useQueryString } from "../../../../../../utils/queryStringHelpers";
import { AdminModalTypes, ModalTypes } from "../../../../../../constants";
import { OrganizationContext } from "../../../../../providers/OrganizationProvider";

import styles from "./index.module.scss";

import { ReduxStateType } from "../../../../../../types";

type PropsType = {
  loading: boolean;
  isModalOpen: boolean;
  closeModal: () => void;
  openModal: OpenModal;
  getChatFlowDetails: (chatId: string) => void;
  clearChatFlowDetails: () => void;
  clearReportTemplateDetails: () => void;
  cloneChatFlow: (
    chatId: string,
    onSuccess?: (chatFlowId: string) => void,
    toOrganizationId?: number
  ) => void;
  title: string | null;
  published: boolean;
  draftChatFlowId: number | null;
  publishedChatFlowId: number | null;
  chatFlowTags: Array<string>;
};

const ChatDetails = ({
  loading,
  isModalOpen,
  closeModal,
  openModal,
  getChatFlowDetails,
  clearChatFlowDetails,
  clearReportTemplateDetails,
  cloneChatFlow,
  title,
  published,
  draftChatFlowId,
  publishedChatFlowId,
  chatFlowTags
}: PropsType) => {
  const { parsed } = useQueryString();
  const { chatId } = parsed;
  const [searchParams, setSearchParams] = useSearchParams();
  const viewOnly = published;
  const [mode, setMode] = useState("chatBuilder");

  useEffect(() => {
    if (chatId) {
      clearChatFlowDetails();
      clearReportTemplateDetails();
      getChatFlowDetails(chatId);
    }
  }, [chatId]);

  const renderButton = () => {
    if (loading) {
      return null;
    }
    if (viewOnly) {
      if (draftChatFlowId === null) {
        return (
          <Button
            type="submit"
            onClick={() => {
              if (chatId) {
                cloneChatFlow(chatId, (cloneChatFlowId) => {
                  updateQueryString({ chatId: cloneChatFlowId.toString() }, setSearchParams);
                  clearChatFlowDetails();
                  clearReportTemplateDetails();
                });
              }
            }}
          >
            Create Draft
          </Button>
        );
      }
      return (
        <Button
          type="submit"
          onClick={async () => {
            updateQueryString({ chatId: draftChatFlowId.toString() }, setSearchParams);
            clearChatFlowDetails();
            clearReportTemplateDetails();
            getChatFlowDetails(draftChatFlowId.toString());
          }}
          inline
        >
          Go to Draft
        </Button>
      );
    }
    return (
      <>
        {publishedChatFlowId && (
          <Button
            type="submit"
            onClick={async () => {
              updateQueryString({ chatId: publishedChatFlowId.toString() }, setSearchParams);
              clearChatFlowDetails();
              clearReportTemplateDetails();
              getChatFlowDetails(publishedChatFlowId.toString());
            }}
            inline
          >
            Go to Published Version
          </Button>
        )}

        <Button
          type="submit"
          onClick={async () => {
            if (chatId) {
              openModal(AdminModalTypes.PUBLISH_CHAT, { chatId });
            }
          }}
        >
          Publish Version
        </Button>
      </>
    );
  };

  // eslint-disable-next-line no-nested-ternary
  const subtitle = loading ? "" : published ? "Published Version" : "Draft Version";

  const openSendTestModal = () => {
    const chatFlowId = Number(chatId);
    openModal(ModalTypes.SEND_MESSAGE_TEMPLATE, { chatFlowId });
  };

  const hasGraphSystemTag = chatFlowTags.includes("useGraphSystem");

  const organization = useContext(OrganizationContext);

  return (
    <FullScreenModal
      title={(!loading && title) || ""}
      subtitle={subtitle}
      isOpen={isModalOpen}
      onClose={() => {
        clearChatFlowDetails();
        clearReportTemplateDetails();
        closeModal();
      }}
      contentClassName={styles.ModalContent}
      headerButton={renderButton()}
      headerIconButtons={
        <>
          <Button
            id="openPaperAirplane"
            className={styles.IconButton}
            inline
            onClick={openSendTestModal}
          >
            <Send />
          </Button>
          {viewOnly && hasGraphSystemTag && organization === null && (
            <Button
              inline
              onClick={() => {
                openModal(AdminModalTypes.CLONE_CHAT_FLOW_TO_ORGANIZATION, {
                  chatFlowId: Number(chatId)
                });
              }}
            >
              Fork Chat
            </Button>
          )}
        </>
      }
    >
      <div className={styles.Tabs}>
        <Button
          id="chatBuilderToggle"
          inline
          className={cx(styles.Tab, { [styles.TabActive]: mode === "chatBuilder" })}
          onClick={() => {
            setMode("chatBuilder");
            clearReportTemplateDetails();
          }}
        >
          Chat Builder
        </Button>

        <Button
          id="reportBuilderTab"
          inline
          className={cx(styles.Tab, { [styles.TabActive]: mode === "reportBuilder" })}
          onClick={() => {
            setMode("reportBuilder");
          }}
        >
          Report Builder
        </Button>

        <Button
          inline
          className={cx(styles.Tab, { [styles.TabActive]: mode === "outputs" })}
          onClick={() => {
            setMode("outputs");
          }}
        >
          Outputs
        </Button>
      </div>

      {mode === "chatBuilder" && <ChatBuilder />}
      {mode === "reportBuilder" && <ReportBuilder />}
      {mode === "outputs" && <Outputs />}
    </FullScreenModal>
  );
};

const mapStateToProps = ({ chats }: ReduxStateType) => {
  return {
    published: !!chats.chatDetails.published,
    title: chats.chatDetails.title,
    loading: chats.chatDetails.loading,
    draftChatFlowId: chats.chatDetails.draftChatFlowId,
    publishedChatFlowId: chats.chatDetails.publishedChatFlowId,
    chatFlowTags: chats.chatDetails.tags
  };
};

export default connect(mapStateToProps, {
  openModal: OpenModalAction,
  getChatFlowDetails: getChatFlowDetailsAction,
  clearChatFlowDetails: clearChatFlowDetailsAction,
  cloneChatFlow: cloneChatFlowAction,
  clearReportTemplateDetails: clearReportTemplateDetailsAction
})(ChatDetails);
