import { defaultConditionOperators } from "../defaultConfigurations";
import { ComparatorFieldType, ConditionOperatorConfig } from "../types";

const getValidValueRange = (operatorConfig: ConditionOperatorConfig | null): [number, number] => {
  const comparatorFieldType = operatorConfig?.comparatorFieldType || null;
  if (!comparatorFieldType) {
    return [1, 1];
  }

  if (comparatorFieldType === ComparatorFieldType.ARRAY) {
    return [2, 2];
  }

  if (comparatorFieldType === ComparatorFieldType.STRING) {
    return [2, 2];
  }

  return [0, 0];
};

export const getOperatorConfig = (operatorType?: string) => {
  const operatorConfig = operatorType
    ? defaultConditionOperators.find(
        (conditionOperator) => conditionOperator.jsonLogicKey === operatorType
      )
    : null;

  return operatorConfig
    ? { ...operatorConfig, validValueRange: getValidValueRange(operatorConfig) }
    : null;
};
