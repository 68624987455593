import { UserTypeConstants } from "../typedConstants";

export enum AnnouncementType {
  MODAL = "modal",
  TOOLTIP = "tooltip"
}

export enum AnnouncementName {
  SCRIBE_WELCOME = "scribeWelcome",
  DEMO_DAYS_REMAINING = "demoDaysRemaining",
  DEMO_EXPIRED = "demoExpired",
  NEW_SCRIBE_FEATURES = "newScribeFeatures",
  CREATE_UNINTEGRATED_APPOINTMENT_TUTORIAL = "createUnintegratedAppointmentTutorial",
  START_SCRIBE_SESSION_TUTORIAL = "startScribeSessionTutorial",
  SCRIBE_FEEDBACK_TUTORIAL = "scribeFeedbackTutorial",
  SCRIBE_MULTI_TEMPLATES = "scribeMultiTemplates",
  SCRIBE_MULTI_LANGUAGE = "scribeMultiLanguage"
}

export type AnnouncementConfiguration = {
  active: boolean;
  dismissible?: boolean;
  expiryDate?: string;
};

export type AnnouncementsSetting = {
  [announcementName: string]: AnnouncementConfiguration;
};

export type Announcement = AnnouncementConfiguration & {
  name: AnnouncementName;
  type: AnnouncementType;
};

export enum UserFeatureNames {
  SCRIBE = "scribe"
}

export type FeatureConfiguration = {
  active: boolean;
  expiresAt?: string;
  dataRemovalPolicyInDays?: number | string | null;
  customVocabulary?: string[];
};

export type FeaturesSetting = {
  [featureName: string]: FeatureConfiguration;
};

export type UserSettings = {
  announcements?: AnnouncementsSetting;
  features?: FeaturesSetting;
};

export type User = {
  id: number;
  organizationId?: number;
  email: string;
  firstName: string;
  lastName: string;
  roleId: number;
  settings: UserSettings;
  type: UserTypeConstants;
  active: boolean;
};
