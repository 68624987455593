import React from "react";

import Heading from "../../../Heading";
import Text from "../../../Text";
import Image from "../../../Image";

const StartScribeSessionTwo = (): React.ReactElement => {
  return (
    <div>
      <Heading size="M" component="h3" darkMode>
        Start the conversation
      </Heading>
      <Text size="S" component="p" darkMode>
        Each time you activate Mika, you will be asked to confirm that both you and your patient
        consent.
      </Text>
      <br />
      <Text size="S" component="p" darkMode>
        Then, Mika will begin listening to the conversation through the microphone on your device
      </Text>
      <br />
      <Text size="S" component="p" darkMode>
        Your browser may ask you to give Mika permission to use the microphone.
      </Text>
      <br />
      <Image src="/img/announcements/startConversation.png" />
    </div>
  );
};

export default StartScribeSessionTwo;
