/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";

import Button from "../../Button";
import Heading from "../../Heading";
import Text from "../../Text";
import { Close, MikataPaperAirplane } from "../../Icon";

import { setFeedbackMode } from "../../../../actions";

import { SetFeedbackModeData } from "../../../../types";

import styles from "./index.module.scss";
import { ResponsiveHide } from "../../Responsive";

type PropsType = {
  setFeedbackMode: (data: SetFeedbackModeData) => void;
  closeModal: () => void;
};

const FEEDBACK_MODE_CLOSE_MS = 2000;

const ThankYou = ({ setFeedbackMode, closeModal }: PropsType): JSX.Element => {
  const timerRef = useRef<any>();

  useEffect(() => {
    if (setFeedbackMode) {
      timerRef.current = setTimeout(() => {
        setFeedbackMode({ feedbackMode: null });
        closeModal();
      }, FEEDBACK_MODE_CLOSE_MS);
    }

    return () => {
      // clear timer when component is removed from the DOM
      clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <>
      <div className={styles.ThankYouHeaderRow}>
        <Heading size="S" bold className={styles.ThankYouHeading}>
          Your feedback has been sent
        </Heading>

        <ResponsiveHide hideOnMobile>
          <div className={styles.FeedbackFormCloseButtonWrapper}>
            <Button
              id="closeFeedbackForm"
              className={styles.CloseButton}
              type="button"
              inline
              onClick={() => setFeedbackMode({ feedbackMode: null })}
            >
              <Close size={20} />
            </Button>
          </div>
        </ResponsiveHide>
      </div>
      <div className={styles.ThankYouContent}>
        <Text size="S">Thank you for the detail. We really appreciate your time.</Text>

        <MikataPaperAirplane className={styles.Background} />
      </div>
    </>
  );
};

export default connect(null, { setFeedbackMode })(ThankYou);
