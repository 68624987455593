import { useMemo } from "react";

import { ContextVariable, ContextVariableType } from "../components/ui/Input/JsonLogicInput/types";
import { ChatFlowsNodes, ScaleInputOption } from "../types";
import { ChatCardTypes, ChatFlowControlFlags } from "../constants";

const getNodeContextVariableType = (cardType: string) => {
  switch (cardType) {
    case ChatCardTypes.IMAGE_SELECT:
    case ChatCardTypes.MULTI_SELECT:
      return ContextVariableType.ARRAY;
    default:
      return ContextVariableType.STRING;
  }
};

const getNodeContextVariableOptions = (node: ChatFlowsNodes) => {
  const { cardType } = node;
  switch (cardType) {
    case ChatCardTypes.BOOKING: {
      return [
        { label: "Time slot(s) selected", value: ChatFlowControlFlags.AVAILABLE_SLOTS_SELECTED },
        {
          label: "No time slot selected",
          value: ChatFlowControlFlags.NO_AVAILABLE_SLOTS_SELECTED
        }
      ];
    }
    case ChatCardTypes.SINGLE_SELECT:
    case ChatCardTypes.MULTI_SELECT: {
      return node.payloadOptions?.answers
        ? node?.payloadOptions?.answers.map((answer) => ({
            label:
              typeof answer === "string"
                ? answer
                : (answer as { text: string; value: string }).text,
            value:
              typeof answer === "string"
                ? answer
                : (answer as { text: string; value: string }).value
          }))
        : [];
    }
    case ChatCardTypes.SCALE_INPUT: {
      const answers = (node?.payloadOptions?.answers || []) as ScaleInputOption[];

      return answers.map((answer) => ({
        label: answer.text || answer.subText || "",
        value: answer.value
      }));
    }
    case ChatCardTypes.INSTRUCTION_REASONS: {
      return [
        {
          label: ChatFlowControlFlags.IM_NOT_SURE,
          value: ChatFlowControlFlags.IM_NOT_SURE
        }
      ];
    }
    case ChatCardTypes.IMAGE_SELECT: {
      return node.payloadOptions?.zones
        ? node?.payloadOptions?.zones.map((zone) => ({
            label: typeof zone === "string" ? zone : (zone as { label: string }).label,
            value: typeof zone === "string" ? zone : (zone as { label: string }).label
          }))
        : [];
    }
    default:
      return [];
  }
};

const useChatContextVariables = (nodes: ChatFlowsNodes[]) => {
  const contextVariables: ContextVariable[] = useMemo(() => {
    return nodes
      .filter((node) => Boolean(node.inputName))
      .map((node) => {
        const isExtendable =
          node.cardType === ChatCardTypes.ADDRESS_INPUT ||
          node.cardType === ChatCardTypes.DATE_INPUT ||
          node.cardType === ChatCardTypes.EMAIL_INPUT ||
          node.cardType === ChatCardTypes.NUMBER_INPUT ||
          node.cardType === ChatCardTypes.PHONE_INPUT ||
          node.cardType === ChatCardTypes.TEXT_INPUT;
        const type = getNodeContextVariableType(node.cardType);
        const options = getNodeContextVariableOptions(node);

        return { inputName: node.inputName, options, type, isExtendable };
      });
  }, [nodes]);

  return { contextVariables };
};

export default useChatContextVariables;
