import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { ArrayField } from "informed";

import styles from "./index.module.scss";
import Heading from "../../../../ui/Heading";
import Button from "../../../../ui/Button";
import Loader from "../../../../ui/Loader";
import { Form, SelectInput, TextInput } from "../../../../ui/Input";
import { Option } from "../../../../ui/Input/SelectInput";
import { isRequired, isEmail } from "../../../../../utils/validators";

import {
  ReduxStateType,
  Setting,
  InputValue,
  OrganizationData,
  StaffNotificationsSetting
} from "../../../../../types";
import { fetchOrgSettings, updateOrgSettings } from "../../../../../actions/organization";
import { ReminderTypePreference, SettingNames } from "../../../../../constants";

type PropsType = {
  organization?: OrganizationData;
  settings?: Array<Setting>;
  fetchOrganizationSettings: (organizationId: string) => void;
  updateOrganizationSettings: (organizationId: string, settings: Array<Setting>) => void;
};

type FormValues = {
  reminderTypePreference: string;
  filterInApptsWithoutReasons: string;
  useLocationAddressInEmailFooter: string;
  appointmentRequestNotificationRecipients: string[];
  appointmentRequestNotificationSendAtHour: string;
};

const onSaveStaffNotificationTransformer = (formValues: FormValues) => {
  return {
    appointmentRequests: {
      recipients: formValues.appointmentRequestNotificationRecipients || [],
      sendAtHour: formValues.appointmentRequestNotificationSendAtHour || ""
    }
  };
};

const reminderTypePreferenceValidator =
  (organization?: OrganizationData) => (value: InputValue) => {
    const requiredMessage = isRequired("Please select a reminder type preference")(value);
    if (requiredMessage) return requiredMessage;

    if (value !== ReminderTypePreference.SMS_ONLY.value && organization) {
      if (!organization.phoneNumber)
        return "Organization phone number must be set to enable emails";
      if (!organization.email) return "Organization email must be set to enable emails";
    }
    return undefined;
  };
const filterInApptsWithoutReasonsValidator = isRequired("Please select an option");
const useLocationAddressInEmailFooterValidator = isRequired("Please select an option");
const emailValidator = isEmail("Please enter a valid email");

const formValidator = (organization?: OrganizationData) => (values: FormValues) => {
  return {
    reminderTypePreference: reminderTypePreferenceValidator(organization)(
      values.reminderTypePreference
    ),
    filterInApptsWithoutReasons: filterInApptsWithoutReasonsValidator(
      values.filterInApptsWithoutReasons
    ),
    useLocationAddressInEmailFooter: useLocationAddressInEmailFooterValidator(
      values.useLocationAddressInEmailFooter
    ),
    appointmentRequestNotificationRecipients: undefined,
    appointmentRequestNotificationSendAtHour:
      values.appointmentRequestNotificationRecipients &&
      !values.appointmentRequestNotificationSendAtHour
        ? "Please enter a notifications hour"
        : undefined
  };
};

const getReminderPrefOptions = () => {
  const starting: Array<Option> = [];
  return Object.keys(ReminderTypePreference).reduce((options, prefKey) => {
    const prefOption: Option = {
      label: ReminderTypePreference[prefKey].name,
      value: ReminderTypePreference[prefKey].value
    };
    options.push(prefOption);
    return options;
  }, starting);
};

const PreferencesPage = ({
  organization,
  settings,
  fetchOrganizationSettings,
  updateOrganizationSettings
}: PropsType): JSX.Element | null => {
  const { organizationId } = useParams<{
    organizationId: string;
  }>();

  // Fetch data on load
  useEffect(() => {
    if (organizationId) {
      fetchOrganizationSettings(organizationId);
    }
  }, [organizationId]);

  const initialFormState = useMemo(() => {
    if (settings && settings?.length > 0) {
      const reminderPreferenceSetting = settings.find(
        (setting) => setting.settingName === SettingNames.REMINDER_TYPE_PREFERENCE
      );

      const filterInNoReasonSetting = settings.find(
        (setting) => setting.settingName === SettingNames.FILTER_IN_APPTS_WITHOUT_REASON
      );

      const useLocationAddressInEmailFooter = settings.find(
        (setting) => setting.settingName === SettingNames.USE_LOCATION_ADDRESS_IN_EMAIL_FOOTER
      );

      const staffNotifications = settings.find(
        (setting) => setting.settingName === SettingNames.STAFF_NOTIFICATIONS
      );

      const newFormState = {
        reminderTypePreference: reminderPreferenceSetting
          ? String(reminderPreferenceSetting.settingValue)
          : ReminderTypePreference.PREFER_SMS.value,
        filterInApptsWithoutReasons: filterInNoReasonSetting
          ? String(filterInNoReasonSetting.settingValue)
          : "",
        useLocationAddressInEmailFooter: useLocationAddressInEmailFooter
          ? String(useLocationAddressInEmailFooter.settingValue)
          : "",
        appointmentRequestNotificationRecipients:
          staffNotifications &&
          (staffNotifications.settingValue as StaffNotificationsSetting).appointmentRequests
            ? (staffNotifications.settingValue as StaffNotificationsSetting).appointmentRequests
                .recipients
            : [],
        appointmentRequestNotificationSendAtHour:
          staffNotifications &&
          (staffNotifications.settingValue as StaffNotificationsSetting).appointmentRequests
            ? (staffNotifications.settingValue as StaffNotificationsSetting).appointmentRequests
                .sendAtHour
            : ""
      };

      return newFormState;
    }
    return undefined;
  }, [settings]);

  /**
   * @param formValues: Current form values
   */
  const save = async (formValues: FormValues) => {
    if (settings) {
      const transformedStaffNotifications = onSaveStaffNotificationTransformer(formValues);
      if (organizationId) {
        const updatedSettings = [
          {
            settingName: "reminderTypePreference",
            settingValue: formValues.reminderTypePreference,
            organizationId: parseInt(organizationId, 10)
          },
          {
            settingName: "filterInApptsWithoutReasons",
            settingValue: formValues.filterInApptsWithoutReasons,
            organizationId: parseInt(organizationId, 10)
          },
          {
            settingName: "useLocationAddressInEmailFooter",
            settingValue: formValues.useLocationAddressInEmailFooter,
            organizationId: parseInt(organizationId, 10)
          },
          {
            settingName: "staffNotifications",
            settingValue: { ...transformedStaffNotifications },
            organizationId: parseInt(organizationId, 10)
          }
        ];

        updateOrganizationSettings(organizationId, updatedSettings);
      }
    }
  };

  if (!settings || !initialFormState) return <Loader screen />;

  return (
    <Form
      onSubmit={(formState) => save(formState.values as FormValues)}
      initialValues={initialFormState}
      // validateFields={formValidator(organization)}
      validateFields={(values) => formValidator(organization)(values as FormValues)}
    >
      <div className={styles.Container}>
        <div className={styles.Section}>
          <div className={styles.SectionHeading}>
            <Heading size="S" component="span">
              Preferences
            </Heading>
          </div>

          <div className={styles.SectionContent}>
            <div>
              <SelectInput
                fieldName="reminderTypePreference"
                label="Reminder Type Preference"
                options={getReminderPrefOptions()}
                validate={reminderTypePreferenceValidator(organization)}
                info={
                  <span>
                    Ensure that patient emails information is available before selecting a
                    preference that includes email.
                  </span>
                }
              />
              <SelectInput
                fieldName="filterInApptsWithoutReasons"
                label="Filter In Appointments Without Reason"
                info="Should appointments with no reason be filtered in"
                options={[
                  { label: "Filter In", value: "true" },
                  { label: "Filter Out", value: "false" }
                ]}
                validate={filterInApptsWithoutReasonsValidator}
              />
              <SelectInput
                fieldName="useLocationAddressInEmailFooter"
                label="Use Location Address in Email Footer"
                info="Use the appt location address or the organization address in email footers"
                options={[
                  { label: "Use Location Address", value: "true" },
                  { label: "Use Organization Address", value: "false" }
                ]}
                validate={filterInApptsWithoutReasonsValidator}
              />
            </div>
          </div>
        </div>
        <div className={styles.Section}>
          <div className={styles.SectionHeading}>
            <Heading size="S" component="span">
              Notifications
            </Heading>
          </div>

          <div className={styles.SectionContent}>
            <SelectInput
              fieldName="appointmentRequestNotificationSendAtHour"
              label="Send Appointment Request Notifications at Hour"
              info="Set the hour when you want appointment request notifications to be sent"
              options={[
                { label: "None", value: "" },
                { label: "00:00", value: "0" },
                { label: "01:00", value: "1" },
                { label: "02:00", value: "2" },
                { label: "03:00", value: "3" },
                { label: "04:00", value: "4" },
                { label: "05:00", value: "5" },
                { label: "06:00", value: "6" },
                { label: "07:00", value: "7" },
                { label: "08:00", value: "8" },
                { label: "09:00", value: "9" },
                { label: "10:00", value: "10" },
                { label: "11:00", value: "11" },
                { label: "12:00", value: "12" },
                { label: "13:00", value: "13" },
                { label: "14:00", value: "14" },
                { label: "15:00", value: "15" },
                { label: "16:00", value: "16" },
                { label: "17:00", value: "17" },
                { label: "18:00", value: "18" },
                { label: "19:00", value: "19" },
                { label: "20:00", value: "20" },
                { label: "21:00", value: "21" },
                { label: "22:00", value: "22" },
                { label: "23:00", value: "23" }
              ]}
            />
            <Heading size="META">Appointment Request Notification Recipients</Heading>
            <ArrayField name="appointmentRequestNotificationRecipients">
              {({ add }) => {
                return (
                  <>
                    <div className={styles.EmailList}>
                      <ArrayField.Items>
                        {({ name, remove, index }) => {
                          return (
                            <div className={styles.EmailItem} key={`${name}_${index}`}>
                              <div className={styles.TextInput}>
                                <TextInput fieldName={name} validate={emailValidator} />
                              </div>
                              <Button inline type="button" onClick={remove}>
                                Remove
                              </Button>
                            </div>
                          );
                        }}
                      </ArrayField.Items>
                    </div>
                    <Button inline type="button" onClick={() => add()}>
                      Add Email
                    </Button>
                  </>
                );
              }}
            </ArrayField>
          </div>
        </div>

        <hr />

        <div className={styles.Footer}>
          <Button type="submit">Save</Button>
        </div>
      </div>
    </Form>
  );
};

const mapStateToProps = ({ organizationDetails }: ReduxStateType) => {
  return {
    organization: organizationDetails.data,
    settings: organizationDetails.settings
  };
};

export default connect(mapStateToProps, {
  fetchOrganizationSettings: fetchOrgSettings,
  updateOrganizationSettings: updateOrgSettings
})(PreferencesPage);
