import React from "react";
import { connect } from "react-redux";

import { FloatModal } from "../../../ui/Modal";
import Heading from "../../../ui/Heading";
import { Form, SelectInput, TextInput } from "../../../ui/Input";
import Button from "../../../ui/Button";

import {
  closeModal as closeModalAction,
  addChatFlow as addChatFlowAction,
  AddChatFlowData
} from "../../../../actions";
import { isRequired } from "../../../../utils/validators";

import styles from "./index.module.scss";

type PropsType = {
  organizationId: number | null;
  closeModal: () => void;
  addChatFlow: (data: AddChatFlowData) => void;
};

type FormState = {
  title: string;
  chatFlowType: string;
};

const initialFormState = {
  title: "",
  chatFlowType: ""
};

const formValidator = (values: FormState) => {
  return {
    title: isRequired("Please enter a title")(values.title),
    chatFlowType: isRequired("Please enter a chat flow type")(values.chatFlowType)
  };
};

const chatFlowTypeOptions = [
  { label: "General Chat", value: "chatFlow" },
  { label: "Appointment Request Chat", value: "appointmentRequest" },
  { label: "Appointment Instructions", value: "instructions" },
  { label: "Chat Section", value: "subFlow" },
  { label: "Scribe", value: "scribeVisit" },
  { label: "Check In", value: "checkIn" }
];

const AddChatFlow = ({ organizationId, closeModal, addChatFlow }: PropsType): JSX.Element => {
  const save = async (formValues: FormState) => {
    const data = {
      organizationId,
      title: formValues.title,
      chatFlowType: formValues.chatFlowType
    };
    addChatFlow(data);
    closeModal();
  };

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L" className={styles.Heading}>
        Add New Chat Flow
      </Heading>
      <Form
        onSubmit={(formState) => save(formState.values as FormState)}
        initialValues={initialFormState}
        validateFields={(values) => formValidator(values as FormState)}
      >
        <TextInput id="chatFlowTitle" fieldName="title" label="title" placeholder="Enter title" />
        <SelectInput
          id="chatFlowType"
          fieldName="chatFlowType"
          label="type"
          options={chatFlowTypeOptions}
          placeholder="Select chat flow type"
        />

        <hr className={styles.Divider} />

        <div className={styles.ButtonGroup}>
          <Button inline onClick={closeModal}>
            Cancel
          </Button>
          <Button id="createChatFlow" type="submit">
            Create
          </Button>
        </div>
      </Form>
    </FloatModal>
  );
};

export default connect(null, {
  closeModal: closeModalAction,
  addChatFlow: addChatFlowAction
})(AddChatFlow);
