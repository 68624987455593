/* Some EMR do not split first an last names.
    In these cases the first and last names are the same and include both first and last names. */
const getPatientFullName = (patientFirstName: string, patientLastName: string): string => {
  if (!patientFirstName) return "";

  return patientFirstName === patientLastName
    ? patientFirstName
    : `${patientFirstName} ${patientLastName}`;
};

export default getPatientFullName;
