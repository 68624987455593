import React from "react";

type PropsType = {
  size?: number;
};

const Send = ({ size = 24 }: PropsType) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 20"
      width={`${size}px`}
      height={`${size}px`}
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="1.75px"
        d="M15.62 15.23l-.45-.32a.19.19 0 00-.22.3l3.18 2.3.45.32c.19.15.41-.15.22-.3zM16.13 11.91c-.17-.16-.36.19-.19.35l1.47 1.37.2.2c.18.16.37-.18.2-.35l-1.47-1.37zM23.79 14.49l-4-3.14-.56-.44c-.21-.16-.44.19-.24.35L23 14.39l.56.44c.21.17.44-.18.23-.34zM19.39 5.75a.74.74 0 00-.19-1.43L1.28.88a1.76 1.76 0 00-.23 0A.14.14 0 00.88 1a1 1 0 00.12.42l8.22 17a.74.74 0 001.44-.2l1.21-7.43-3.6-3.33-5.89-5.38 10.43 6.79z"
      />
    </svg>
  );
};

export default Send;
