import React, { useContext } from "react";
import { connect } from "react-redux";
import cx from "classnames";

import Heading from "../../../ui/Heading";
import { FloatModal } from "../../../ui/Modal";
import Button from "../../../ui/Button";
import Loader from "../../../ui/Loader";
import { CheckboxGroup, Form, SelectInput, TextInput } from "../../../ui/Input";
import Text from "../../../ui/Text";

import { closeModal, addStaffUser as addStaffUserAction } from "../../../../actions";
import { isEmail, isRequired, combine } from "../../../../utils/validators";

import { UserRoleConstants, UserTypeConstants } from "../../../../constants";

import styles from "./index.module.scss";
import { OrganizationContext } from "../../../providers/OrganizationProvider";
import { UserContext } from "../../../providers/UserProvider";

type PropsType = {
  organizationId: number;
  closeModalConnect: () => void;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  addStaffConnect: (data: any) => void;
};

type FormState = {
  firstName: string;
  lastName: string;
  email: string | null;
  roleId: number | undefined;
  locationId: string[];
};

const initialFormState: FormState = {
  firstName: "",
  lastName: "",
  email: "",
  roleId: undefined,
  locationId: []
};

const firstNameValidator = isRequired("Please enter a first name");
const lastNameValidator = isRequired("Please enter a last name");
const emailValidator = combine([
  isRequired("Please enter a valid email"),
  isEmail("Please enter a valid email")
]);
const locationValidator = isRequired("Please select a location");

const formValidator = (values: FormState) => {
  return {
    firstName: firstNameValidator(values.firstName),
    lastName: lastNameValidator(values.lastName),
    email: emailValidator(values.email),
    roleId: undefined,
    locationId: undefined
  };
};

const AddStaffUser = ({ organizationId, closeModalConnect, addStaffConnect }: PropsType) => {
  // Organization data will be available on different objects depending if current user is mikata or clinic manager
  const user = useContext(UserContext);
  const isMikataAdmin = user.userType === UserTypeConstants.MIKATA_ADMIN;
  const currentOrg = useContext(OrganizationContext);

  if (!currentOrg) return <Loader small center />;

  const { locations } = currentOrg;
  const activeLocations = locations.filter((location) => location.active);
  initialFormState.locationId = locations
    ? activeLocations.map((location) => location.id.toString())
    : []; // All active locations are selected as default

  const { userRoles } = currentOrg;
  const generalRole = userRoles.filter((role) => role.title === UserRoleConstants.GENERAL)[0];
  const generalUploadRole = userRoles.filter(
    (role) => role.title === UserRoleConstants.GENERAL_UPLOAD
  )[0];
  const managerRole = userRoles.filter((role) => role.title === UserRoleConstants.MANAGER)[0];
  initialFormState.roleId = generalRole.id; // Basic role selected as default

  // Mapping role id to strings for the radio buttons
  const roleOptions = [
    { label: "Basic", value: generalRole.id ? generalRole.id : "" },
    { label: "Basic + Upload", value: generalUploadRole.id ? generalUploadRole.id : "" },
    { label: "Clinic Manager", value: managerRole.id ? managerRole.id : "" }
  ];

  const save = async (formValues: FormState) => {
    const data = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      roleId: formValues.roleId,
      locationId: formValues.locationId ? formValues.locationId.map((id) => Number(id)) : [],
      type: "staff",
      organizationId
    };

    addStaffConnect(data);
  };

  return (
    <FloatModal isOpen onClose={closeModalConnect}>
      <Heading size="L" className={styles.AddNewUser}>
        Add New Staff
      </Heading>
      <Form
        onSubmit={(formState) => save(formState.values as FormState)}
        initialValues={initialFormState}
        validateFields={(values) => formValidator(values as FormState)}
      >
        <TextInput
          fieldName="firstName"
          label="first name"
          placeholder="Enter first name"
          validate={firstNameValidator}
        />
        <TextInput
          fieldName="lastName"
          label="last name"
          placeholder="Enter last name"
          validate={lastNameValidator}
        />
        <TextInput
          fieldName="email"
          label="email"
          placeholder="Enter email"
          validate={emailValidator}
        />
        {!isMikataAdmin && (
          <Text size="M" className={cx(styles.Text, styles.TextWarning)}>
            Please make sure the email is correct. If you want to change the email, you will have to
            contact us.
          </Text>
        )}
        <SelectInput fieldName="roleId" label="role" options={roleOptions} />
        {locations && locations.length > 0 && (
          <CheckboxGroup
            fieldName="locationId"
            label="choose location(s)"
            showCheckAll={locations.length > 2}
            options={locations.map((location) => ({
              label: `${location.displayName || location.fullName} ${
                !location.active ? " (inactive)" : ""
              }`,
              value: location.id.toString()
            }))}
            searchPlaceholder="Find a location"
            validate={locationValidator}
          />
        )}

        <hr className={styles.Divider} />

        <div className={styles.ButtonGroup}>
          <Button inline onClick={closeModalConnect}>
            Cancel
          </Button>
          <Button id="createNewUserButton" type="submit">
            Create
          </Button>
        </div>
      </Form>
    </FloatModal>
  );
};

export default connect(null, {
  closeModalConnect: closeModal,
  addStaffConnect: addStaffUserAction
})(AddStaffUser);
