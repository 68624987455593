import React from "react";
import cx from "classnames";

import styles from "./index.module.scss";

type PropsType = {
  className?: string;
  small?: boolean;
  center?: boolean;
  screen?: boolean;
};

const Loader = ({
  className,
  small = false,
  center = false,
  screen = false
}: PropsType): React.ReactElement => {
  return (
    <div
      className={cx(
        styles.Wrapper,
        {
          [styles.Small]: small,
          [styles.Center]: center,
          [styles.Screen]: screen
        },
        className
      )}
    >
      <svg className={styles.Loader} viewBox="25 25 50 50">
        <circle className={styles.Background} cx="50" cy="50" r="20" />
        <circle className={styles.Foreground} cx="50" cy="50" r="20" />
      </svg>
    </div>
  );
};

export default Loader;
