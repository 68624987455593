import React from "react";

import Heading from "../Heading";
import Text from "../Text";

import { Content } from "../../../types";

import styles from "./contentRenderEngine.module.scss";

type PropsType = { content: Content[] };

const ContentRenderEngine = ({ content }: PropsType) => {
  return (
    <>
      {content.map((item, idx) => {
        if (item.type === "transcript" || item.content.toLocaleLowerCase() === "chat transcript")
          return null;
        const key = `${idx}-${item.content.slice(0, 10)}`;

        switch (item.type) {
          case "header": {
            return (
              <Heading
                id={`header-${idx}`}
                key={key}
                className={styles.Heading}
                size="M"
                component="h1"
              >
                {item.content}
              </Heading>
            );
          }
          case "text": {
            return (
              <Text id={`textCard-${idx}`} key={key} className={styles.Text} component="p">
                {item.content}
              </Text>
            );
          }
          case "paragraphBreak": {
            return <br key={key} />;
          }
          default:
            return null;
        }
      })}
    </>
  );
};

export default ContentRenderEngine;
