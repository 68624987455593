import React from "react";
import { connect } from "react-redux";

import { FloatModal } from "../../../ui/Modal";
import AppointmentRequestDirectMessageForm from "./AppointmentRequestDirectMessageForm";

import { closeModal as closeModalAction } from "../../../../actions";

type PropsType = {
  closeModal: () => void;
  staffOptions: { label: string; value: string }[];
};
const SendAppointmentRequestDirectMessage = ({ closeModal, staffOptions }: PropsType) => {
  return (
    <FloatModal isOpen onClose={closeModal}>
      <AppointmentRequestDirectMessageForm staffOptions={staffOptions} />
    </FloatModal>
  );
};

export default connect(undefined, {
  closeModal: closeModalAction
})(SendAppointmentRequestDirectMessage);
