/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import { ContentState, ContentBlock } from "draft-js";

import Text from "../../../../../../../ui/Text";
import MenuList, { ListItem } from "./MenuList";
import { DropdownArrow, Insights, Trash } from "../../../../../../../ui/Icon";

import { MikaAnchorEntityData, MikaAnchorStatus } from "../../types";

import styles from "./index.module.scss";

export type MikaAnchorDecoratorProps = {
  offsetKey?: string;
  contentState: ContentState;
  entityKey: string;
  mikaContentLoading: boolean;
  toolbarBottomPosition?: number;
  selectedAnchorText?: string;
  allowAnchorsEdit?: boolean;
  removeMikaAnchorByEntityKey: (contentEntityKey: string) => void;
  setSelectedAnchorText?: (selectedAnchorText?: string) => void;
};

export const mikaAnchorStrategy = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) => {
  const entityRanges = contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();

      if (entityKey === null) {
        return false;
      }

      const entityType: string = contentState.getEntity(entityKey)?.getType();
      const isMikaContent = Boolean(entityType) && entityType === "ANCHOR";

      return isMikaContent;
    },
    (start, end) => {
      callback(start, end);
    }
  );

  return entityRanges;
};

const getAnchorText = ({
  entityData,
  insightLoading,
  mikaAnchorStatus
}: {
  entityData: MikaAnchorEntityData;
  insightLoading: boolean;
  mikaAnchorStatus: MikaAnchorStatus;
}): string | null => {
  if (!mikaAnchorStatus || !(mikaAnchorStatus === MikaAnchorStatus.PENDING)) return null;

  let anchorText = insightLoading
    ? "Mika is generating suggestions"
    : "Mika will add suggestions here";
  if (typeof entityData?.anchorTitle === "object") {
    const anchorKey = insightLoading ? "loading" : "default";
    anchorText = entityData.anchorTitle[anchorKey] || anchorText;
  } else if (entityData?.anchorTitle || entityData?.anchorName) {
    const placeholderText = entityData?.anchorTitle || entityData?.anchorName;
    anchorText = insightLoading
      ? `Mika is generating ${placeholderText} suggestions`
      : `Mika will add ${placeholderText} suggestions here`;
  }
  return anchorText;
};

export const MikaAnchorDecorator = ({
  offsetKey,
  contentState,
  entityKey,
  mikaContentLoading,
  toolbarBottomPosition,
  allowAnchorsEdit = false,
  selectedAnchorText,
  setSelectedAnchorText,
  removeMikaAnchorByEntityKey
}: MikaAnchorDecoratorProps) => {
  const [anchorMenuOpen, setAnchorMenuOpen] = useState<boolean>(false);
  const elementRef = useRef<HTMLDivElement>(null);
  const entity = contentState.getEntity(entityKey);
  const entityData: MikaAnchorEntityData = entity.getData();

  const mikaAnchorStatus: MikaAnchorStatus = entityData?.status || MikaAnchorStatus.UNKNOWN;
  const mikaAnchorTitle = entityData?.anchorTitle || entityData?.anchorName || "Content";
  const insightLoading = mikaAnchorStatus === MikaAnchorStatus.PENDING && mikaContentLoading;

  const injectedInsightIds = entityData.insightIdsInjected || "";

  const hasInjectedInsights = injectedInsightIds?.length > 0;

  const anchorText = getAnchorText({
    entityData,
    insightLoading,
    mikaAnchorStatus
  });

  const toggle = () => {
    setAnchorMenuOpen((anchorMenuOpen) => !anchorMenuOpen);
  };

  const offDropdownClick = (event: MouseEvent | KeyboardEvent) => {
    const dropdownEl = elementRef.current;
    const isOutsideClick = dropdownEl && !dropdownEl.contains(event.target as Node);

    if (isOutsideClick || (event as KeyboardEvent).key === "Escape") {
      toggle();
      document.removeEventListener("mouseup", offDropdownClick, false);
      document.removeEventListener("keyup", offDropdownClick, false);
    }
  };

  useEffect(() => {
    if (anchorMenuOpen) {
      // add when mounted
      document.addEventListener("mouseup", offDropdownClick);
      document.addEventListener("keyup", offDropdownClick);
      return () => {
        if (setSelectedAnchorText && selectedAnchorText && selectedAnchorText === entityKey) {
          setSelectedAnchorText(undefined);
        }
        document.removeEventListener("mouseup", offDropdownClick, false);
        document.removeEventListener("keyup", offDropdownClick, false);
      };
    }

    document.removeEventListener("mouseup", offDropdownClick, false);
    document.removeEventListener("keyup", offDropdownClick, false);

    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mouseup", offDropdownClick, false);
      document.removeEventListener("keyup", offDropdownClick, false);
    };
  }, [anchorMenuOpen]);

  const menuList: ListItem[] = [
    {
      label: "Remove Item",
      icon: <Trash size={16} />,
      onClick: () => {
        if (selectedAnchorText) {
          removeMikaAnchorByEntityKey(selectedAnchorText);
        }
      }
    }
  ];

  useEffect(() => {
    if (selectedAnchorText) {
      setAnchorMenuOpen(true);
    } else {
      setAnchorMenuOpen(false);
    }
  }, [selectedAnchorText]);

  return (
    <div
      key={`mikaContent-${mikaAnchorTitle}-${mikaAnchorStatus}-${entityKey}`}
      data-offset-key={offsetKey}
      className={cx(styles.Container, {
        [styles.ContainerHasInjectedInsights]: hasInjectedInsights
      })}
    >
      <span
        className={cx(styles.MikaContentAnchor, {
          [styles.MikaContentAnchorLoading]: insightLoading,
          [styles.MikaContentAnchorHasInjectedInsights]: hasInjectedInsights,
          [styles.ActiveAnchorText]: selectedAnchorText === entityKey && anchorMenuOpen,
          [styles.ShowPointer]: allowAnchorsEdit
        })}
        onClick={() => {
          if (allowAnchorsEdit && setSelectedAnchorText) {
            setSelectedAnchorText(selectedAnchorText === entityKey ? undefined : entityKey);
          }
        }}
      >
        <div
          className={cx(styles.MikaContentAnchorIcon, {
            [styles.MikaContentAnchorIconLoading]: insightLoading,
            [styles.MikaContentAnchorIconHasInjectedInsights]: hasInjectedInsights
          })}
        >
          <Insights size={16} />
        </div>
        {anchorText && (
          <Text size="XS" bold className={cx(styles.MikaContentAnchorText)}>
            {anchorText}
            {allowAnchorsEdit && <DropdownArrow size={16} color="currentColor" />}
          </Text>
        )}
        {allowAnchorsEdit && (
          <MenuList
            isOpen={selectedAnchorText === entityKey && anchorMenuOpen}
            setOpen={setAnchorMenuOpen}
            toolbarBottomPosition={toolbarBottomPosition}
            list={menuList}
          />
        )}
      </span>
    </div>
  );
};
