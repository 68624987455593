import { EditorState, SelectionState, Modifier, ContentState } from "draft-js";
import flatten from "lodash/flatten";

type EntityData = { blockKey: string; range: [number, number] };

export const clearEntityContentBy = (
  currentEditorState: EditorState,
  clearBy: (entityKey: string, contentState?: ContentState) => boolean
) => {
  const currentContentState = currentEditorState.getCurrentContent();

  // Find all current entities
  const entitiesData = flatten(
    currentContentState
      .getBlocksAsArray()
      .map((block) => {
        const blockKey = block.getKey();
        const entitiesData: EntityData[] = [];

        block.findEntityRanges(
          (character) => {
            const entityKey = character.getEntity();
            const shouldClear = clearBy(entityKey, currentContentState);

            return shouldClear;
          },
          (start, end) => {
            entitiesData.push({ blockKey, range: [start, end] });
          }
        );
        return entitiesData;
      })
      .filter((blockData) => Boolean(blockData))
  );

  // Remove each hashtag entity
  const cleanedEditorState = entitiesData.reduce(
    (latestEditorState: EditorState, entityData: EntityData) => {
      const latestContentState = latestEditorState.getCurrentContent();
      const start = entityData.range[0];
      const end = entityData.range[1];
      const hashtagSelectionState = SelectionState.createEmpty(entityData.blockKey).merge({
        anchorOffset: start,
        focusOffset: end
      });

      // Remove Entity
      let updatedContentState = Modifier.applyEntity(
        latestContentState,
        hashtagSelectionState,
        null
      );

      // Remove hashtag entity content
      updatedContentState = Modifier.removeRange(
        updatedContentState,
        hashtagSelectionState,
        "forward"
      );

      // Update editor state
      let cleanEditorState = EditorState.push(
        latestEditorState,
        updatedContentState,
        "remove-range"
      );

      // apply preexisting cursor selection
      cleanEditorState = EditorState.forceSelection(
        cleanEditorState,
        latestEditorState.getSelection()
      );
      return cleanEditorState;
    },
    currentEditorState
  );
  return cleanedEditorState;
};
