import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";

import { FloatModal } from "../../../ui/Modal";
import Heading from "../../../ui/Heading";
import { Form, CheckboxGroup } from "../../../ui/Input";
import Button from "../../../ui/Button";

import {
  closeModal as closeModalAction,
  updateOrganization as updateOrganizationAction,
  fetchDataForMikataAdmin as fetchDataForMikataAdminAction,
  updateOrganizationData
} from "../../../../actions";
import { ReduxStateType, AdminOrgData, OrganizationData } from "../../../../types";
import { OrganizationTypes } from "../../../../constants";

import styles from "./index.module.scss";
import Loader from "../../../ui/Loader";

type FormState = {
  childOrganizations: string[];
};

type PropsType = {
  parentOrganization: OrganizationData;
  organizations: Array<AdminOrgData>;
  fetchDataForMikataAdmin: () => void;
  closeModal: () => void;
  updateOrganization: (organizationId: number, organizationData: updateOrganizationData) => void;
};

const LinkOrganization = ({
  parentOrganization,
  organizations,
  fetchDataForMikataAdmin,
  closeModal,
  updateOrganization
}: PropsType): JSX.Element => {
  useEffect(() => {
    fetchDataForMikataAdmin();
  }, []);

  const organizationOptions = organizations
    .filter((org) => org.type !== OrganizationTypes.PARENT)
    .sort((a, b) => a.fullName.localeCompare(b.fullName))
    .map((org) => {
      return {
        label: org.fullName,
        value: org.id.toString()
      };
    });

  const initialFormState: FormState | null = useMemo(() => {
    if (!organizations || organizations.length === 0) return null;

    const childIds = parentOrganization.children.map((child) => child.id.toString());

    return { childOrganizations: childIds };
  }, [organizations]);

  const save = async (formState: FormState) => {
    const childrenIds = formState.childOrganizations.map((childId) => Number(childId));
    await updateOrganization(parentOrganization.id, {
      childrenIds
    });

    closeModal();
  };

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L" className={styles.Heading}>
        Update Children
      </Heading>

      {initialFormState ? (
        <Form
          onSubmit={(formState) => save(formState.values as FormState)}
          initialValues={initialFormState}
        >
          <CheckboxGroup
            label="Child Organizations"
            fieldName="childOrganizations"
            options={organizationOptions}
          />
          <hr className={styles.Divider} />

          <div className={styles.ButtonGroup}>
            <Button inline onClick={closeModal}>
              Cancel
            </Button>
            <Button type="submit">Update Children</Button>
          </div>
        </Form>
      ) : (
        <Loader center />
      )}
    </FloatModal>
  );
};

const mapStateToProps = ({ adminData }: ReduxStateType) => {
  return {
    organizations: adminData?.adminData?.data || []
  };
};

export default connect(mapStateToProps, {
  closeModal: closeModalAction,
  updateOrganization: updateOrganizationAction,
  fetchDataForMikataAdmin: fetchDataForMikataAdminAction
})(LinkOrganization);
