import React, { useState } from "react";
import { connect } from "react-redux";

import Button, { ButtonRow } from "../../../../../ui/Button";
import Text from "../../../../../ui/Text";
import Heading from "../../../../../ui/Heading";
import { MikataSimple } from "../../../../Icon";
import SoapNoteSelector from "./TemplatePreviews/SoapNoteSelector";
import ConsultNoteSelector from "./TemplatePreviews/ConsultNoteSelector";

import {
  setDefaultNoteTemplate as setDefaultNoteTemplateAction,
  SetDefaultNoteTemplateData
} from "../../../../../../actions";
import { ReduxStateType } from "../../../../../../types";

import { AnnouncementModalBaseProps } from "..";

import styles from "./index.module.scss";

type PropsType = AnnouncementModalBaseProps & {
  setDefaultNoteTemplate: (setDefaultNoteTemplateData: SetDefaultNoteTemplateData) => void;
};

const ScribeWelcome = ({
  announcement,
  markAnnouncementComplete,
  setDefaultNoteTemplate
}: PropsType) => {
  const [selectedTemplate, setSelectedTemplate] = useState("notes");

  return (
    <div key={`announcement-${announcement.name}`} className={styles.Container}>
      <div className={styles.LogoContainer}>
        <MikataSimple size={40} />
      </div>
      <div className={styles.HeaderContainer}>
        <Heading component="h6" size="META" className={styles.MetaText}>
          Getting started with MIKA
        </Heading>
        <Heading size="XL" className={styles.HeadingText}>
          What kind of note do you like to use?
        </Heading>
        <Text className={styles.Text}>
          Pick the one that best describes your usage. You can change your mind later.
        </Text>
      </div>
      <div className={styles.Templates}>
        <div className={styles.TemplateChoiceContainer}>
          <SoapNoteSelector
            isSelected={selectedTemplate === "notes"}
            setSelectedTemplate={setSelectedTemplate}
          />
          <ConsultNoteSelector
            isSelected={selectedTemplate === "letters"}
            setSelectedTemplate={setSelectedTemplate}
          />
        </div>
        <ButtonRow>
          <Button
            inline
            id={`completeAnnouncement-${announcement.name}`}
            onClick={() => markAnnouncementComplete && markAnnouncementComplete(announcement.name)}
          >
            Skip
          </Button>
          <Button
            id={`completeAnnouncement-${announcement.name}`}
            onClick={() => {
              setDefaultNoteTemplate({
                noteTemplateTags: ["default", selectedTemplate],
                onSuccess: () => {
                  markAnnouncementComplete && markAnnouncementComplete(announcement.name);
                }
              });
            }}
          >
            Get Started
          </Button>
        </ButtonRow>
      </div>
    </div>
  );
};
const mapStateToProps = ({ notes, noteTemplates, feedback }: ReduxStateType) => {
  return {
    noteTemplate: noteTemplates.noteTemplate,
    syncLoading: notes.syncLoading,
    feedbackQueue: feedback.notesInQueue
  };
};

export default connect(mapStateToProps, {
  setDefaultNoteTemplate: setDefaultNoteTemplateAction
})(ScribeWelcome);
