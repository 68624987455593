const getAudioVolume = (analyser: AnalyserNode) => {
  // Get the amplitude data from the analyser
  const dataArray = new Uint8Array(analyser.frequencyBinCount);
  analyser.getByteTimeDomainData(dataArray);

  // Calculate the volume for the waveform animation
  let sum = 0;
  for (let i = 0; i < dataArray.length; i += 1) {
    const diff = dataArray[i] - 128; // 128 is the midpoint for 8-bit audio
    sum += diff * diff;
  }
  const rms = Math.sqrt(sum / dataArray.length);

  const volume = Math.min(1, (rms / 64) * 2);
  return volume;
};

export default getAudioVolume;
