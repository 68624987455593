import { FETCH_REASONS, UPDATE_REASONS } from "../constants";

import { UpdateReasonsAction, FetchReasonsAction } from "../actions";

import { Reason, ActionStatus } from "../types";

export type ReasonsAction = UpdateReasonsAction | FetchReasonsAction;

export type ReasonsReduxState = {
  data: Array<Reason>;
  initialized: boolean;
  updateError: boolean;
};

const initialModalsState: ReasonsReduxState = {
  data: [],
  initialized: false,
  updateError: false
};

export const reasonsReducer = (
  state = initialModalsState,
  action: ReasonsAction
): ReasonsReduxState => {
  const { type } = action;

  switch (type) {
    case FETCH_REASONS: {
      const { payload, status } = action as FetchReasonsAction;
      return {
        ...state,
        data: payload?.reasons || state.data,
        initialized: state.initialized || status !== ActionStatus.loading
      };
    }

    case UPDATE_REASONS: {
      const { status, payload } = action as UpdateReasonsAction;
      const updatesById: { [id: string]: Reason } = {};

      if (payload?.updates) {
        payload.updates.forEach((reason) => {
          updatesById[reason.id] = reason;
        });
      }

      const updatedData = state.data.map((reason) => {
        if (updatesById[reason.id]) {
          return { ...reason, ...updatesById[reason.id] };
        }
        return reason;
      });
      return { ...state, data: updatedData, updateError: status === ActionStatus.error };
    }

    default:
      return state;
  }
};
