import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import cx from "classnames";

import Text from "../Text";
import { Check, Exclamation } from "../Icon";

import { removeNotification } from "../../../actions";

import { Notification, ReduxStateType } from "../../../types";

import styles from "./index.module.scss";

type PropsType = {
  notifications: Array<Notification>;
  closeNotification: (id: string) => void;
};

type NotificationCardPropsType = Notification & { onClose: (id: string) => void };

const AUTO_DISMISS_TIME = 10000;

const NotificationCard = ({
  id,
  type,
  title,
  subtitle,
  autoDismiss = true,
  onClose
}: NotificationCardPropsType): React.ReactElement => {
  const [isClosing, setIsClosing] = useState(false);
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const timerRef = useRef<any>();
  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose(id);
    }, 300);
  };

  useEffect(() => {
    // Default to auto-dismiss the notifications
    if (autoDismiss) {
      timerRef.current = setTimeout(() => {
        handleClose();
      }, AUTO_DISMISS_TIME);
    }
    return () => {
      // clear timer
      clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <button
      type="button"
      className={cx(styles.NotificationCard, {
        [styles.NotificationCardError]: type === "error",
        [styles.NotificationCardWarning]: type === "warning",
        [styles.NotificationCardClosing]: isClosing
      })}
      onClick={handleClose}
    >
      <span
        className={cx(
          styles.IconCircle,
          { [styles.IconCircleError]: type === "error" },
          { [styles.IconCircleWarning]: type === "warning" }
        )}
      >
        {type === "error" || type === "warning" ? (
          <Exclamation />
        ) : (
          <Check size={20} strokeWidth={4} />
        )}
      </span>
      <div className={styles.TitleGroup}>
        <Text size="S" bold>
          {title}
        </Text>
        {subtitle && <Text size="S">{subtitle}</Text>}
      </div>
    </button>
  );
};

const Notifications = ({ notifications, closeNotification }: PropsType): React.ReactElement => {
  return (
    <div id="notifications" className={styles.Notifications}>
      <div className={styles.Container}>
        {notifications.map((notification) => {
          const { id, type, title, subtitle, autoDismiss } = notification;
          return (
            <NotificationCard
              key={notification.id}
              id={id}
              type={type}
              title={title}
              subtitle={subtitle}
              onClose={closeNotification}
              autoDismiss={autoDismiss}
            />
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = ({ notifications }: ReduxStateType) => {
  return { notifications: notifications.data };
};

export default connect(mapStateToProps, { closeNotification: removeNotification })(Notifications);
