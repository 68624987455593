import React from "react";

type PropsType = {
  size?: number;
};

const List = ({ size = 24 }: PropsType) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23 19"
      width={`${size}px`}
      height={`${size * (19 / 23)}px`}
    >
      <path
        d="M15.349.204a1.734 1.734 0 0 1 1.72.05c.531.32.847.88.847 1.499v14.363a1.753 1.753 0 0 1-1.746 1.749c-.279 0-.559-.067-.816-.203l-9.282-4.903H4.559v4.826a1 1 0 0 1-2 0v-4.827H1a1 1 0 0 1-1-1V6.106a1 1 0 0 1 1-1h5.072Zm.567 1.963L7.117 6.816v4.233l8.799 4.648V2.167Zm3.335 10.916a.999.999 0 0 1 1.377-.323l1.329.825a1 1 0 0 1-1.055 1.699l-1.329-.824a1 1 0 0 1-.322-1.377ZM5.117 7.105H2v3.652h3.117V7.106Zm16.32 1.505a1 1 0 1 1 0 2h-1.329a1 1 0 1 1 0-2Zm-.527-4.674a1 1 0 1 1 1.054 1.699l-.749.465-.58.36a.998.998 0 0 1-1.377-.323 1 1 0 0 1 .323-1.377Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default List;
