import {
  FETCH_MESSAGE_TEMPLATE_DETAIL,
  FETCH_MESSAGE_TEMPLATES,
  UPDATE_MESSAGE_TEMPLATE,
  ADD_MESSAGE_TEMPLATE,
  DELETE_MESSAGE_TEMPLATE,
  SEND_MESSAGE_TEMPLATE
} from "../constants";

import {
  FetchMessageTemplateDetailAction,
  FetchMessageTemplateAction,
  UpdateMessageTemplateAction,
  AddMessageTemplateAction,
  DeleteMessageTemplateAction,
  SendMessageTemplateAction
} from "../actions";
import { MessageTemplate, ChatFlow } from "../types";

export type MessageTemplatesAction =
  | FetchMessageTemplateDetailAction
  | FetchMessageTemplateAction
  | UpdateMessageTemplateAction
  | AddMessageTemplateAction
  | DeleteMessageTemplateAction
  | SendMessageTemplateAction;

export type MessageTemplatesReduxState = {
  data: Array<MessageTemplate>;
  messageTemplateFetchLoading: boolean;
  messageTemplateUpdateLoading: boolean;
  messageTemplateAddLoading: boolean;
  messageTemplateDeleteLoading: boolean;
  messageTemplateSendLoading: boolean;
  messageTemplateDetail: {
    messageTemplate: MessageTemplate | null;
    availableChats: Array<ChatFlow>;
  };
  messageTemplateDetailLoading: boolean;
};

const initialMessageTemplatesState: MessageTemplatesReduxState = {
  data: [],
  messageTemplateFetchLoading: false,
  messageTemplateUpdateLoading: false,
  messageTemplateAddLoading: false,
  messageTemplateDeleteLoading: false,
  messageTemplateSendLoading: false,
  messageTemplateDetail: {
    messageTemplate: null,
    availableChats: []
  },
  messageTemplateDetailLoading: false
};

export const messageTemplatesReducer = (
  state = initialMessageTemplatesState,
  action: MessageTemplatesAction
): MessageTemplatesReduxState => {
  switch (action.type) {
    case FETCH_MESSAGE_TEMPLATE_DETAIL: {
      const { status, payload } = action as FetchMessageTemplateDetailAction;

      const messageTemplate: MessageTemplate | null =
        payload && payload.messageTemplate ? payload.messageTemplate : null;
      const availableChats: Array<ChatFlow> =
        payload && payload.availableChats ? payload.availableChats : [];
      const messageTemplateDetail = {
        messageTemplate,
        availableChats
      };
      return {
        ...state,
        messageTemplateDetail,
        messageTemplateDetailLoading: status === "loading"
      };
    }
    case FETCH_MESSAGE_TEMPLATES: {
      const { status, payload } = action as FetchMessageTemplateAction;
      return {
        ...state,
        data: payload ? payload.data : [],
        messageTemplateFetchLoading: status === "loading"
      };
    }
    case UPDATE_MESSAGE_TEMPLATE: {
      const { status, payload } = action as UpdateMessageTemplateAction;
      return {
        ...state,
        messageTemplateUpdateLoading: status === "loading",
        data: state.data.map((messageTemplate) => {
          if (messageTemplate.id === payload?.messageTemplate.id) {
            return payload.messageTemplate;
          }
          return messageTemplate;
        }),
        messageTemplateDetail: {
          ...state.messageTemplateDetail,
          messageTemplate:
            state?.messageTemplateDetail &&
            state?.messageTemplateDetail?.messageTemplate?.id === payload?.messageTemplate?.id
              ? payload?.messageTemplate || null
              : state.messageTemplateDetail.messageTemplate || null
        }
      };
    }
    case ADD_MESSAGE_TEMPLATE: {
      const { status } = action as AddMessageTemplateAction;
      return {
        ...state,
        messageTemplateAddLoading: status === "loading"
      };
    }
    case DELETE_MESSAGE_TEMPLATE: {
      const { status } = action as DeleteMessageTemplateAction;
      return {
        ...state,
        messageTemplateDeleteLoading: status === "loading"
      };
    }
    case SEND_MESSAGE_TEMPLATE: {
      const { status } = action as SendMessageTemplateAction;
      return {
        ...state,
        messageTemplateSendLoading: status === "loading"
      };
    }

    default:
      return state;
  }
};
