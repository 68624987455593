import React from "react";
import cx from "classnames";

import { useFormState } from "informed";
import { SelectInput, TextInput } from "../../../ui/Input";
import Button from "../../../ui/Button";

import { Option } from "../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  isInputNode: (cardType: string) => boolean;
  cardTypeOptions: Option[];
};

type FormState = {
  displayName: string;
  cardType: string;
  inputName: string | null;
};

const AddChatNodeForm = ({ isInputNode, cardTypeOptions }: PropsType): JSX.Element => {
  const formState = useFormState();
  const values = formState.values as FormState;
  const { cardType } = values;
  const showInputQuestions = isInputNode(cardType as string);
  return (
    <>
      <SelectInput
        id="chatNodeCardType"
        fieldName="cardType"
        label="Element Type"
        options={cardTypeOptions}
        placeholder="Select type"
      />
      <TextInput
        id="chatNodeDisplayName"
        fieldName="displayName"
        label="Display Name"
        placeholder="Enter a display name"
      />
      <div className={cx({ [styles.Hide]: !showInputQuestions })}>
        <TextInput
          id="chatNodeInputName"
          fieldName="inputName"
          label="Input Name"
          placeholder="Enter a input name"
          disabled={!showInputQuestions}
        />
      </div>
      <div className={styles.ButtonGroup}>
        <Button id="createElement" type="submit">
          Create Element
        </Button>
      </div>
    </>
  );
};

export default AddChatNodeForm;
