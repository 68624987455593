import React from "react";
import cx from "classnames";

import Text from "../../../../../../ui/Text";
import styles from "./index.module.scss";

import { MessageMediums } from "../../../../../../../constants";
import handleMediumSpecificTags from "../../../../../../../utils/handleMediumSpecificTags";

type PropsType = {
  messageContent: string;
};

/**
 * Placeholder estimates referenced using
 * - common/facades/src/__tests__/message-templates/substitute-placeholders.test.js
 *
 * More about SMS character limit can be read in the Twilio article below
 * - https://www.twilio.com/docs/glossary/what-sms-character-limit#:~:text=Message%20concatenation%20allows%20you%20to,are%20limited%20to%2070%20characters.
 */

const SINGLE_SEGMENT_GSM_MAX = 160; // maximum allowed characters per segment when count is <=160
const SINGLE_SEGMENT_NON_GSM_MAX = 70; // maximum allowed characters per segment when count is <=70
const MULTI_SEGMENT_GSM_MAX = 153; // maximum allowed characters per segment when count is >160
const MULTI_SEGMENT_NON_GSM_MAX = 67; // maximum allowed characters per segment when count is >70
const regexPattern =
  /[^A-Za-z0-9 [:space:\]\n/.,_!"#%&''*+:;<=>^~|?¡¿@$£¥¤èéùìòÇØøÆæßÉÅåÄÖÑÜ§äöñüàΔΦΞΓΩΠΨΣΘΛ)(}{-]/gim;

const placeHolderEstimates = {
  LINK: 40,
  LOCATION_NAME: 20,
  LOCATION_NUMBER: 12, // 555-555-5555
  ORG_DISPLAY_NAME: 20,
  NUM_OF_APPTS: 1, // between 1 to 9 appts as a numerical value
  PLACE: 20,
  DATE: 10, // Wed Apr 12
  YEAR: 4, // 2028
  TIMES: 21, // TIME property times 3
  TIME: 7, // 11:30pm
  UNIQUE_TIMES: 25, // TIME property times 3
  NUMBER: 12, // 555-555-5555
  ADDRESS: 30, // estimate based avg character length of location displayNames on prod
  FIRST_TIME: 7, // 11:30pm
  AM_PM: 2,
  PROVIDER_NAME: 20 // based on how default messages work
};

const repeatAsterisk = (count: number) => {
  return new Array(count + 1).join("*");
};

const smsSegmentCount = (characterCount: number, isGSM: boolean): number => {
  const quotient = isGSM
    ? characterCount / MULTI_SEGMENT_GSM_MAX
    : characterCount / MULTI_SEGMENT_NON_GSM_MAX;
  const remainder = isGSM
    ? characterCount % MULTI_SEGMENT_GSM_MAX
    : characterCount % MULTI_SEGMENT_NON_GSM_MAX;
  if (remainder === 0) {
    return quotient;
  }
  return Math.floor(quotient) + 1;
};

export const smsEncodingWarning = (messageContent: string) => {
  let numberOfSegments;

  if (messageContent?.length === 0 || !messageContent?.length) numberOfSegments = 0;

  const maxNonGSMCharacterCount =
    messageContent?.length <= SINGLE_SEGMENT_NON_GSM_MAX || !messageContent?.length
      ? SINGLE_SEGMENT_NON_GSM_MAX
      : MULTI_SEGMENT_NON_GSM_MAX;

  const maxGSMCharacterCount =
    messageContent?.length <= SINGLE_SEGMENT_GSM_MAX || !messageContent?.length
      ? SINGLE_SEGMENT_GSM_MAX
      : MULTI_SEGMENT_GSM_MAX;

  const updatedContent = messageContent
    ? messageContent
        .replace(/\[link\]/gm, repeatAsterisk(placeHolderEstimates.LINK))
        .replace(/\[locationName\]/gm, repeatAsterisk(placeHolderEstimates.LOCATION_NAME))
        .replace(/\[locationNumber\]/gm, repeatAsterisk(placeHolderEstimates.LOCATION_NUMBER))
        .replace(/\[orgDisplayName\]/gm, repeatAsterisk(placeHolderEstimates.ORG_DISPLAY_NAME))
        .replace(/\[numOfAppts\]/gm, repeatAsterisk(placeHolderEstimates.NUM_OF_APPTS))
        .replace(/\[place\]/gm, repeatAsterisk(placeHolderEstimates.PLACE))
        .replace(/\[date\]/gm, repeatAsterisk(placeHolderEstimates.DATE))
        .replace(/\[year\]/gm, repeatAsterisk(placeHolderEstimates.YEAR))
        .replace(/\[times\]/gm, repeatAsterisk(placeHolderEstimates.TIMES))
        .replace(/\[time\]/gm, repeatAsterisk(placeHolderEstimates.TIME))
        .replace(/\[uniqueTimes\]/gm, repeatAsterisk(placeHolderEstimates.UNIQUE_TIMES))
        .replace(/\[number\]/gm, repeatAsterisk(placeHolderEstimates.NUMBER))
        .replace(/\[address\]/gm, repeatAsterisk(placeHolderEstimates.ADDRESS))
        .replace(/\[firsttime\]/gm, repeatAsterisk(placeHolderEstimates.FIRST_TIME))
        .replace(/\[am\/pm\]/gm, repeatAsterisk(placeHolderEstimates.AM_PM))
        .replace(/\[providerName\]/gm, repeatAsterisk(placeHolderEstimates.PROVIDER_NAME))
    : "";

  const smsContent = handleMediumSpecificTags(updatedContent, MessageMediums.SMS);
  const invalidCharacters = smsContent?.match(regexPattern);

  if (invalidCharacters && invalidCharacters.length > 0) {
    if (smsContent.length > SINGLE_SEGMENT_NON_GSM_MAX) {
      numberOfSegments = smsSegmentCount(smsContent?.length, false);
    } else if (smsContent.length > 0 && smsContent.length <= SINGLE_SEGMENT_NON_GSM_MAX)
      numberOfSegments = 1;

    return {
      nonGsm: true,
      maxCharacters: maxNonGSMCharacterCount,
      smsContent,
      segments: numberOfSegments
    };
  }

  if (smsContent.length > SINGLE_SEGMENT_GSM_MAX) {
    numberOfSegments = smsSegmentCount(smsContent?.length, true);
  } else if (smsContent.length > 0 && smsContent.length <= SINGLE_SEGMENT_GSM_MAX)
    numberOfSegments = 1;
  return {
    nonGsm: false,
    maxCharacters: maxGSMCharacterCount,
    smsContent,
    segments: numberOfSegments
  };
};

const SmsMessageInfo = ({ messageContent }: PropsType) => {
  const content = messageContent;
  const { smsContent, maxCharacters, segments = 0 } = smsEncodingWarning(content);

  return (
    <div className={styles.Row}>
      <Text
        className={cx(styles.Count, {
          [styles.CountOver]: content ? smsContent.length > maxCharacters : false
        })}
        size="XS"
        bold
      >
        {segments === 1 ? `${segments} Segment` : `${segments} Segments`}
      </Text>
      <Text
        className={cx(styles.Count, {
          [styles.CountOver]: content ? smsContent.length > maxCharacters : false
        })}
        size="XS"
        bold
      >
        {`${smsContent ? smsContent.length : 0}/${maxCharacters}`}
      </Text>
    </div>
  );
};

export default SmsMessageInfo;
