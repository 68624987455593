import React, { useContext, useEffect, useMemo } from "react";
import cx from "classnames";

import {
  LanguageOptions,
  MikataDefaultNoteTemplateTitles,
  NoteTemplateActionTags,
  NoteTemplateSpecialtyTags,
  NoteTemplateStylingTags,
  NoteTemplateSystemTags,
  NoteTemplateTagsConfig,
  NoteTemplateTypeTags,
  NoteTemplatePseudoTags
} from "../../../../../../constants";

import Heading from "../../../../../ui/Heading";
import Text from "../../../../../ui/Text";
import Card from "../../../../../ui/Card";
import Tags from "../../../../../ui/Tags";
import { Heart, HeartBlank, Info, Pin, PinBlank } from "../../../../../ui/Icon";
import Button from "../../../../../ui/Button";
import { SelectInput, TextAreaInput, TextInput } from "../../../../../ui/Input";
import Tooltip from "../../../../../ui/Tooltip";

import formatDate from "../../../../../../utils/formatDate";
import { usePermissions } from "../../../../../../hooks/usePermissions";
import { UserContext } from "../../../../../providers/UserProvider";
import { getNoteTemplateOwnerDisplayName } from "../helpers";
import { NoteTemplate, Permissions } from "../../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  noteTemplate: NoteTemplate;
  setIsFavourited: (isFavourited: boolean) => void;
  isFavourited: boolean;
  includeAlways: boolean;
  setIncludeAlways: (includeAlways: boolean) => void;
  mikataLevel: boolean;
  tags?: string[];
};

const NoteTemplateSummaryCard = ({
  mikataLevel,
  noteTemplate,
  tags,
  isFavourited,
  setIsFavourited,
  includeAlways,
  setIncludeAlways
}: PropsType) => {
  const { userId } = useContext(UserContext);
  const adminCanEdit = usePermissions([Permissions.UPDATE_NOTE_TEMPLATE], false);
  const hasUpdateSelfPermission = usePermissions([Permissions.UPDATE_NOTE_TEMPLATE_SELF], false);
  const providerCanEdit =
    !mikataLevel && hasUpdateSelfPermission && userId === noteTemplate?.userId;

  const getNoteTemplateTypeOptions = () => {
    return Object.values(NoteTemplateTagsConfig)?.filter((item) =>
      Object.values(NoteTemplateTypeTags).includes(item.value)
    );
  };

  const ownerDisplayName = getNoteTemplateOwnerDisplayName(noteTemplate);

  const enabledLanguageOptions = LanguageOptions.filter((option) => {
    return option.value === "en-CA" || option.value === "fr-CA";
  });

  const tagsToIgnore = [...Object.values({ ...NoteTemplateTypeTags, ...NoteTemplatePseudoTags })];

  const uniqueNoteTemplateTags = new Set([
    ...Object.values({
      ...NoteTemplateSystemTags,
      ...NoteTemplateActionTags,
      ...NoteTemplateSpecialtyTags,
      ...NoteTemplateStylingTags
    }),
    ...(noteTemplate?.tags?.filter((tag) => !tagsToIgnore.includes(tag)) || [])
  ]);

  const templateTagOptions =
    [...uniqueNoteTemplateTags].map((option) => {
      const configOption = Object.values(NoteTemplateTagsConfig).find(
        (configOption) => configOption.value === option
      );
      if (configOption) {
        return {
          label: `${configOption.label}`,
          value: `${option}`
        };
      }
      return { label: `${option}`, value: `${option}` };
    }) || [];

  const outputLanguage = useMemo(() => {
    const languageTag = noteTemplate?.outputLanguage || "en-CA";
    return LanguageOptions?.find((item) => item.value === languageTag)?.label;
  }, [noteTemplate]);

  useEffect(() => {
    setIsFavourited(noteTemplate.isFavourited || false);
    setIncludeAlways(noteTemplate.includeAlways || false);
  }, []);

  const getApplicableTags = (tags: string[]) => {
    return mikataLevel
      ? tags
      : tags?.filter((item) => Object.values(NoteTemplateSystemTags).indexOf(item) === -1);
  };

  return (
    <div className={styles.LeftColumn}>
      <Card>
        <div className={cx({ [styles.HideElement]: !providerCanEdit && !adminCanEdit })}>
          <div>
            <SelectInput
              label="Type"
              fieldName="templateTypeTag"
              options={getNoteTemplateTypeOptions()}
              disabled={!(adminCanEdit || providerCanEdit)}
            />
          </div>
          <div>
            <TextInput
              fieldName="name"
              label="Name"
              id="noteTemplateName"
              disabled={
                !(adminCanEdit || providerCanEdit) ||
                // Prevent user from editing the title of the Mikata-level blank and SOAP note templates
                noteTemplate?.title === MikataDefaultNoteTemplateTitles.BLANK_NOTE_TEMPLATE ||
                (noteTemplate?.title === MikataDefaultNoteTemplateTitles.SOAP_NOTE_TEMPLATE &&
                  !noteTemplate.organizationId)
              }
            />
          </div>
          <div>
            <TextAreaInput
              label="Description"
              fieldName="description"
              size="S"
              disabled={!(adminCanEdit || providerCanEdit)}
            />
          </div>
          {!mikataLevel && noteTemplate.userId && (
            <div className={styles.ToggleActionRow}>
              <Button
                className={styles.ToggleActionButton}
                inline
                onClick={() => setIncludeAlways(!includeAlways)}
              >
                <Text className={styles.ToggleAction}>
                  {includeAlways ? <Pin /> : <PinBlank />}
                  {includeAlways ? "Remove as default" : "Add as default"}
                </Text>
              </Button>
              <Button
                className={styles.ToggleActionButton}
                inline
                onClick={() => setIsFavourited(!isFavourited)}
              >
                <Text className={styles.ToggleAction}>
                  {isFavourited ? <Heart /> : <HeartBlank />}
                  {isFavourited ? "Remove as favourite" : "Add as favourite"}
                </Text>
              </Button>
            </div>
          )}
          <div className={cx({ [styles.HideElement]: !mikataLevel })}>
            <SelectInput
              label="Tags"
              fieldName="tags"
              isMulti
              options={templateTagOptions}
              disabled={!(adminCanEdit || providerCanEdit)}
            />
          </div>
          <div className={cx({ [styles.HideElement]: !mikataLevel })}>
            <SelectInput
              label="Output Language"
              fieldName="outputLanguage"
              options={enabledLanguageOptions}
              disabled={!(adminCanEdit || providerCanEdit)}
            />
          </div>
        </div>
        <div className={cx({ [styles.HideElement]: providerCanEdit || adminCanEdit })}>
          <Heading>{noteTemplate?.title || ""}</Heading>
          <Text className={styles.Description}>{noteTemplate?.description || ""}</Text>
        </div>
        <div className={styles.Divider} />
        {tags && !mikataLevel && (
          <div className={styles.Row}>
            <div className={styles.LabelInfoGroup}>
              <Heading size="META" className={styles.Heading}>
                Tags
              </Heading>
              {getApplicableTags(tags)?.length > 0 && (
                <Tags tags={getApplicableTags(tags)} configMap={NoteTemplateTagsConfig} />
              )}
            </div>
          </div>
        )}
        <div className={styles.Row}>
          <div className={cx(styles.LabelInfoGroup, { [styles.HideElement]: mikataLevel })}>
            <div style={{ display: "flex", gap: "8px" }}>
              <Heading size="META" className={styles.Heading}>
                LANGUAGE
              </Heading>
              <Tooltip
                icon={<Info size={16} />}
                contentClassName={styles.TooltipContent}
                position="topRight"
                className={styles.ToolTip}
              >
                <span>This is the language that Mika suggestion will generate</span>
              </Tooltip>
            </div>
            <Text>{outputLanguage}</Text>
          </div>
          <div className={cx(styles.LabelInfoGroup, { [styles.HideElement]: !adminCanEdit })}>
            <Heading size="META" className={styles.Heading}>
              ID
            </Heading>
            <Text>{noteTemplate?.id || ""}</Text>
          </div>
        </div>
        <div className={styles.Row}>
          <div className={styles.LabelInfoGroup}>
            <Heading size="META" className={styles.Heading}>
              Owner
            </Heading>
            <Text>{ownerDisplayName}</Text>
          </div>
        </div>
        <div className={styles.Row}>
          <div className={styles.LabelInfoGroup}>
            <Heading size="META" className={styles.Heading}>
              Created
            </Heading>
            <Text>{formatDate(noteTemplate?.created_at || "")}</Text>
          </div>
          <div className={styles.LabelInfoGroup}>
            <Heading size="META" className={styles.Heading}>
              Updated
            </Heading>
            <Text>{formatDate(noteTemplate?.updated_at || "")}</Text>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default NoteTemplateSummaryCard;
