/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useContext } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import moment from "moment";

import {
  clearAppointmentRequestsDetail as clearAppointmentRequestsDetailAction,
  clearAppointmentRequestHistory as clearAppointmentRequestHistoryAction
} from "../../../../../actions";

import { BookingModes, TaskTypes } from "../../../../../constants";
import { ReduxStateType, Staff, Task } from "../../../../../types";
import { UserContext } from "../../../../providers/UserProvider";

import Button from "../../../../ui/Button";
import Heading from "../../../../ui/Heading";
import { AutoBook, ApptBookWithTime, Calendar, Close, PipOff } from "../../../../ui/Icon";
import Loader from "../../../../ui/Loader";

import InboxPatient from "../InboxPatient";

import styles from "./index.module.scss";
import AssignUser from "../AssignUser";

interface PropsType {
  isOpen: boolean;
  closeDrawer: () => void;
  staff: Staff[];
  selectedTask?: Task;
  customHeaderContent?: React.ReactNode;
  children?: React.ReactNode;
  preferQuickView: boolean;
  setPreferQuickView: (preferQuickView: boolean) => void;
  onAssign: (taskIds: number[], userId: number | null) => void;
  loadingForAssignUser: { [id: number]: boolean };
  taskDetailsLoading: boolean;
  clearAppointmentRequestsDetail: () => void;
  clearAppointmentRequestHistory: () => void;
}

const InboxDetailsWrapper = ({
  children,
  selectedTask,
  customHeaderContent,
  isOpen,
  closeDrawer,
  staff,
  preferQuickView,
  setPreferQuickView,
  onAssign,
  loadingForAssignUser,
  taskDetailsLoading,
  clearAppointmentRequestsDetail,
  clearAppointmentRequestHistory
}: PropsType) => {
  const { userId: currentUserId } = useContext(UserContext);

  const bookingMode = selectedTask?.filterContext.bookingMode;

  const taskIsLoadingForAssignedUser = Object.keys(loadingForAssignUser).find((taskId: string) => {
    if (selectedTask?.id === Number(taskId)) {
      return loadingForAssignUser[Number(taskId)];
    }
  });

  const currentAssignedStaff = staff?.find((staffUser) => {
    if (selectedTask?.assignedUsers?.length) {
      return staffUser.userId === selectedTask.assignedUsers[0].id;
    }
    return false;
  });

  const onAssignUserChange = (newUserId: number | null) => {
    if (selectedTask) {
      onAssign([selectedTask.id], newUserId);
    }
  };

  return (
    <>
      <div
        className={cx(styles.Background, { [styles.BackgroundHide]: !isOpen })}
        onClick={() => {
          closeDrawer();
          if (selectedTask?.type === TaskTypes.APPOINTMENT_REQUEST) {
            clearAppointmentRequestsDetail();
            clearAppointmentRequestHistory();
          }
        }}
        tabIndex={-1}
      />
      <div
        className={cx(styles.Container, {
          [styles.ContainerHide]: !isOpen,
          [styles.FullView]: !preferQuickView
        })}
      >
        <div className={styles.Header}>
          <div className={styles.HeaderBlockLeft}>
            <Button
              className={styles.CloseButton}
              type="button"
              inline
              onClick={() => {
                closeDrawer();
                setPreferQuickView(true);
                if (selectedTask?.type === TaskTypes.APPOINTMENT_REQUEST) {
                  clearAppointmentRequestsDetail();
                  clearAppointmentRequestHistory();
                }
              }}
            >
              <Close size={24} />
            </Button>
            <div className={styles.CalendarIcon}>
              {bookingMode === BookingModes.REQUEST && <Calendar size={24} />}
              {bookingMode === BookingModes.AVAILABLE_TIMES && <ApptBookWithTime size={24} />}
              {bookingMode === BookingModes.AUTO_BOOK && <AutoBook size={24} />}
            </div>
            <div>
              <InboxPatient
                patientFullName={selectedTask?.filterContext.patientFullName}
                patientVerified={selectedTask?.filterContext.patientVerified}
              />
              <Heading size="META">
                {selectedTask?.filterContext.bookedAt ? (
                  <>
                    BOOKED ON:{" "}
                    {selectedTask?.filterContext.bookedAt
                      ? moment(selectedTask.filterContext.bookedAt)
                          .format("MM.DD.YYYY hh.mm A")
                          .toString()
                      : ""}
                  </>
                ) : (
                  <>
                    CREATED:{" "}
                    {selectedTask?.created_at
                      ? moment(selectedTask?.created_at).format("MM.DD.YYYY hh.mm A").toString()
                      : ""}
                  </>
                )}
              </Heading>
            </div>
          </div>
          <div className={styles.HeaderBlockRight}>
            {currentUserId &&
              // currentStaffUser && // to test with admin
              selectedTask && (
                <AssignUser
                  currentAssignedStaff={currentAssignedStaff}
                  onChange={onAssignUserChange}
                  value={currentAssignedStaff?.userId || null}
                  loading={!!taskIsLoadingForAssignedUser}
                  isQuickView
                />
              )}
            {customHeaderContent && (
              <>
                <div className={styles.VerticalDivider} />
                {customHeaderContent}
              </>
            )}
            {preferQuickView && (
              <Button
                className={styles.FullViewButton}
                type="button"
                inline
                onClick={() => {
                  setPreferQuickView(!preferQuickView);
                }}
              >
                <PipOff size={24} />
              </Button>
            )}
          </div>
        </div>

        {taskDetailsLoading ? (
          <div className={styles.Contents}>
            <Loader small center />
          </div>
        ) : (
          <div className={styles.Contents}>{children}</div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ tasks, organizationData }: ReduxStateType) => {
  return {
    taskDetailsLoading: tasks.taskDetailsLoading,
    staff: organizationData?.organizationData?.staff
  };
};

export default connect(mapStateToProps, {
  clearAppointmentRequestsDetail: clearAppointmentRequestsDetailAction,
  clearAppointmentRequestHistory: clearAppointmentRequestHistoryAction
})(InboxDetailsWrapper);
