import React, { Dispatch, SetStateAction, useMemo } from "react";

import { TextAreaInput, SelectInput } from "../../../../../../../../ui/Input";
import { ChatFlowsNodes } from "../../../../../../../../../types";

import BaseChatNodeForm, {
  BaseFormValues,
  DateInputFormValues,
  FormValues
} from "../../BaseChatNodeForm";

type DateInputPayloadOptions = undefined | { format: string };

export type DateInputChatFlowsNode = ChatFlowsNodes & {
  payloadOptions: DateInputPayloadOptions;
};

type PropsType = {
  chatId: string | undefined;
  node: DateInputChatFlowsNode;
  viewOnly: boolean;
  setSelectedNodeId: Dispatch<SetStateAction<number | null>>;
};

const onSaveCustomTransformer = (formValues: FormValues) => {
  const values = formValues as BaseFormValues & DateInputFormValues;
  return {
    payloadContent: values.payloadContent ? values.payloadContent : "",
    payloadOptions: { format: values.format ? values.format : "" }
  };
};

export const DateInput = ({ chatId, node, viewOnly, setSelectedNodeId }: PropsType) => {
  const initialFormValues: DateInputFormValues = useMemo(() => {
    return {
      payloadContent: node?.payloadContent ? node.payloadContent : "",
      format: node?.payloadOptions ? node?.payloadOptions.format : ""
    };
  }, [node.id]);

  const validFormatOptions = [
    { label: "YYYY-MM-DD", value: "YYYY-MM-DD" },
    { label: "MM-DD-YYYY", value: "MM-DD-YYYY" },
    { label: "DD-MM-YYYY", value: "DD-MM-YYYY" }
  ];

  return (
    <BaseChatNodeForm
      chatId={chatId}
      key={node.id}
      initialValues={initialFormValues}
      onSaveCustomTransformer={onSaveCustomTransformer}
      node={node}
      isInput
      viewOnly={viewOnly}
      setSelectedNodeId={setSelectedNodeId}
    >
      <>
        <TextAreaInput
          id="chatNodePayloadContent"
          size="S"
          fieldName="payloadContent"
          label="Mikata Message"
          disabled={viewOnly}
        />
        <SelectInput
          fieldName="format"
          label="Format"
          options={validFormatOptions}
          disabled={viewOnly}
        />
      </>
    </BaseChatNodeForm>
  );
};
