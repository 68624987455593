import React, { Dispatch } from "react";
import { connect } from "react-redux";
import styles from "../index.module.scss";
import { CardSection } from "../../../../ui/Card";
import Heading from "../../../../ui/Heading";
import { Form, SelectInput } from "../../../../ui/Input";
import { capitalize } from "../../../../../utils/stringTransformations";
import { ChargeOverProductPlan, InputValue, NewNotification } from "../../../../../types";
import { isRequired } from "../../../../../utils/validators";
import {
  CreateScribeSubscriptionFormState,
  CreateScribeSubscriptionPlanFormData,
  CreateScribeSubscriptionPlanModalSteps
} from "..";

import Button from "../../../../ui/Button";
import { addNotification as addNotificationAction } from "../../../../../actions";
import Loader from "../../../../ui/Loader";

type PropsType = {
  formState: CreateScribeSubscriptionFormState;
  setFormState: Dispatch<CreateScribeSubscriptionPlanFormData>;
  plans?: ChargeOverProductPlan[];
  selectedPlan?: ChargeOverProductPlan;
  submitStep: (
    stepNumber: CreateScribeSubscriptionPlanModalSteps,
    formState: CreateScribeSubscriptionPlanFormData
  ) => void;
  addNotification: (data: NewNotification) => void;
};

const planValidator = isRequired("Please select a plan");

const SelectPlan = ({ formState, setFormState, plans, submitStep, addNotification }: PropsType) => {
  const onChange = (event: { [fieldName: string]: InputValue }) => {
    const productKey = event["selectPlan.values.productKey"];

    const chargeOverPlan =
      plans?.find((subscriptionPlan) => subscriptionPlan.key === productKey) || undefined;
    setFormState({
      type: "selectPlan",
      payload: { values: { productKey, selectedPlan: chargeOverPlan } }
    });
  };

  const onSubmit = (event: { [fieldName: string]: InputValue }) => {
    let error = false;
    const { productKey } = event.selectPlan.values;

    const chargeOverPlan =
      plans?.find((subscriptionPlan) => subscriptionPlan.key === productKey) || undefined;

    if (!chargeOverPlan) {
      addNotification({
        type: "error",
        title: `Failed to select the plan.`,
        subtitle: "Please try again or select a different plan.",
        autoDismiss: false
      });
      error = true;
    }

    if (error) return;

    submitStep("paymentBillingInformation", {
      type: "selectPlan",
      payload: {
        values: {
          productKey,
          selectedPlan: chargeOverPlan
        }
      }
    });
  };

  if (!plans) return <Loader screen center />;

  return (
    <Form
      onSubmit={(formState) => onSubmit(formState.values as { [fieldName: string]: InputValue })}
      initialValues={formState}
    >
      <CardSection title="Plan">
        <SelectInput
          id="selectPlan"
          fieldName="selectPlan.values.productKey"
          label="Select a Plan"
          options={plans.map((plan) => ({ label: plan.name, value: plan.key }))}
          placeholder="Select plan"
          customOnChange={onChange}
          validate={planValidator}
        />
        {formState.selectPlan?.values?.selectedPlan && (
          <>
            <div className={styles.TextMarginBottom}>
              <Heading size="META">Description</Heading>
              {formState.selectPlan.values.selectedPlan.description}
            </div>
            <div className={styles.CardRow}>
              {formState.selectPlan.values.selectedPlan.rate &&
                formState.selectPlan.values.selectedPlan.payCycleUnit && (
                  <div>
                    <Heading size="META">Rate</Heading>$
                    {formState.selectPlan.values.selectedPlan.rate.toFixed(2)}
                    /provider/
                    {formState.selectPlan.values.selectedPlan.payCycleUnit}
                  </div>
                )}
              {formState.selectPlan.values.selectedPlan?.payCycleUnit && (
                <div>
                  <Heading size="META">Renews</Heading>
                  {capitalize(formState.selectPlan.values.selectedPlan.payCycleUnit)}ly
                </div>
              )}
            </div>
          </>
        )}
      </CardSection>
      <div className={styles.Button}>
        <Button id="checkOut" type="submit">
          Check Out
        </Button>
      </div>
    </Form>
  );
};

export default connect(null, {
  addNotification: addNotificationAction
})(SelectPlan);
