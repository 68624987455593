import React from "react";
import cx from "classnames";

import styles from "./index.module.scss";

type PropsType = {
  icon: React.ReactNode;
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
  position?: "topRight" | "topLeft" | "bottomRight" | "bottomLeft";
  size?: "S" | "M" | "L";
  contentClickable?: boolean;
};

const Tooltip = ({
  icon,
  disabled = false,
  children,
  className,
  contentClassName,
  position = "topRight",
  size = "L",
  contentClickable = false
}: PropsType) => {
  return (
    <div className={cx(styles.ToolTip, className)}>
      <div className={cx(styles.ToolTipIcon)}>{icon}</div>
      {/* HoverCapture  div is used so the content stays visible when it contains a link and user moves cursor off of the icon to click it */}
      {contentClickable && <div className={styles.HoverCapture} />}
      <div
        className={cx(styles.Content, contentClassName, {
          [styles.ContentTopRight]: position === "topRight",
          [styles.ContentTopLeft]: position === "topLeft",
          [styles.ContentBottomRight]: position === "bottomRight",
          [styles.ContentBottomLeft]: position === "bottomLeft",
          [styles.ContentSmall]: size === "S",
          [styles.ContentMedium]: size === "M",
          [styles.ContentLarge]: size === "L",
          [styles.ContentDisabled]: disabled
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default Tooltip;
