import { Letters, Paragraph, Section, SubReport, Circle } from "../../../../../../../ui/Icon";
import { ReportTemplateCardType } from "../../../../../../../../types";

const typeIconMap = {
  [ReportTemplateCardType.HEADER]: Letters,
  [ReportTemplateCardType.TEXT]: Paragraph,
  [ReportTemplateCardType.TEXT_NEWLINE]: Paragraph,
  [ReportTemplateCardType.TEXT_INLINE]: Paragraph,
  [ReportTemplateCardType.TRANSCRIPT]: Circle,
  [ReportTemplateCardType.CONVERSATION_SUMMARY]: Circle,
  [ReportTemplateCardType.SECURE_MESSAGE_ITEMS_SUMMARY]: Circle,
  [ReportTemplateCardType.SECTION]: Section,
  [ReportTemplateCardType.SUB_REPORT_SECTION]: SubReport
};

export const getCardTypeIcon = (
  type: ReportTemplateCardType,
  props: { size: number }
): JSX.Element => {
  const cardTypeIcon = typeIconMap[type];

  return cardTypeIcon(props);
};
