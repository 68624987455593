import React from "react";

type PropsType = {
  size?: number;
};

const Pause = ({ size = 24 }: PropsType) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={`${size}px`}
      height={`${size}px`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 7C5 5.89543 5.89543 5 7 5H9C10.1046 5 11 5.89543 11 7V17C11 18.1046 10.1046 19 9 19H7C5.89543 19 5 18.1046 5 17V7ZM9 7L7 7V17H9V7Z"
        fill="#1D2029"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 7C13 5.89543 13.8954 5 15 5H17C18.1046 5 19 5.89543 19 7V17C19 18.1046 18.1046 19 17 19H15C13.8954 19 13 18.1046 13 17V7ZM17 7L15 7V17H17V7Z"
        fill="#1D2029"
      />
    </svg>
  );
};

export default Pause;
