import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import cx from "classnames";

import Button from "../../../../ui/Button";

import { FullScreenModal } from "../../../../ui/Modal";

import JourneySummaryCard from "./JourneySummaryCard";
import General from "./General";
import Triggers from "./Triggers";
import Touchpoints from "./Touchpoints";
import ResponseMessages from "./ResponseMessages";

import { PermissionsGuard, usePermissions } from "../../../../../hooks/usePermissions";

import { fetchMessageTemplates, fetchAutomation } from "../../../../../actions";

import styles from "./index.module.scss";

import {
  AutomationDetails,
  JourneyDetailTabModes,
  Permissions,
  ReduxStateType
} from "../../../../../types";

type PropsType = {
  automationId?: string;
  isModalOpen: boolean;
  closeModal: () => void;
  automation?: AutomationDetails;
  fetchMessages: () => void;
  fetchAutomationDetails: (automationId: string) => void;
  touchpointId: number | null;
};

const JourneyTabPermissions = {
  [JourneyDetailTabModes.GENERAL]: Permissions.VIEW_AUTOMATION_GENERAL,
  [JourneyDetailTabModes.TRIGGERS]: Permissions.VIEW_AUTOMATION_TRIGGERS,
  [JourneyDetailTabModes.TOUCHPOINTS]: Permissions.VIEW_AUTOMATED_MESSAGE_CONFIGURATIONS,
  [JourneyDetailTabModes.RESPONSE_MESSAGES]: Permissions.VIEW_AUTOMATION_RESPONSE_MESSAGES
};

const AppointmentJourneysDetails = ({
  automationId,
  automation,
  isModalOpen,
  closeModal,
  fetchMessages,
  fetchAutomationDetails,
  touchpointId = null
}: PropsType): JSX.Element | null => {
  // find the first tab user has permission to view
  const initialTabMode = [
    JourneyDetailTabModes.GENERAL,
    JourneyDetailTabModes.TRIGGERS,
    JourneyDetailTabModes.TOUCHPOINTS,
    JourneyDetailTabModes.RESPONSE_MESSAGES
  ].find((tab) => {
    const permission = JourneyTabPermissions[tab];
    return usePermissions([permission]);
  });
  const [tabMode, setTabMode] = useState<JourneyDetailTabModes>(
    touchpointId
      ? JourneyDetailTabModes.TOUCHPOINTS
      : initialTabMode || JourneyDetailTabModes.GENERAL
  );

  // Reset tab mode on change in automation context
  useEffect(() => {
    setTabMode(
      touchpointId
        ? JourneyDetailTabModes.TOUCHPOINTS
        : initialTabMode || JourneyDetailTabModes.GENERAL
    );
  }, [automationId]);

  // Fetch data on load
  useEffect(() => {
    fetchMessages();

    if (automationId) {
      fetchAutomationDetails(automationId);
    }
  }, [automationId]);

  return (
    <FullScreenModal
      title={automation?.displayName}
      subtitle="Details page"
      isOpen={isModalOpen}
      onClose={closeModal}
    >
      <div className={styles.Wrapper}>
        <div className={styles.Column}>
          <JourneySummaryCard automation={automation} />
        </div>
        <div className={styles.Column}>
          <div className={styles.Tabs}>
            <PermissionsGuard requiredPermissions={[Permissions.VIEW_AUTOMATION_GENERAL]}>
              <Button
                id="journeyDetailsEditMode-general"
                inline
                className={cx(styles.Tab, {
                  [styles.TabActive]: tabMode === JourneyDetailTabModes.GENERAL
                })}
                onClick={() => setTabMode(JourneyDetailTabModes.GENERAL)}
              >
                General
              </Button>
            </PermissionsGuard>
            <PermissionsGuard requiredPermissions={[Permissions.VIEW_AUTOMATION_TRIGGERS]}>
              <Button
                id="journeyDetailsEditMode-triggers"
                inline
                className={cx(styles.Tab, {
                  [styles.TabActive]: tabMode === JourneyDetailTabModes.TRIGGERS
                })}
                onClick={() => setTabMode(JourneyDetailTabModes.TRIGGERS)}
              >
                Triggers
              </Button>
            </PermissionsGuard>
            <PermissionsGuard
              requiredPermissions={[Permissions.VIEW_AUTOMATED_MESSAGE_CONFIGURATIONS]}
            >
              <Button
                id="journeyDetailsEditMode-touchpoints"
                inline
                className={cx(styles.Tab, {
                  [styles.TabActive]: tabMode === JourneyDetailTabModes.TOUCHPOINTS
                })}
                onClick={() => setTabMode(JourneyDetailTabModes.TOUCHPOINTS)}
              >
                Touchpoints
              </Button>
            </PermissionsGuard>
            <PermissionsGuard requiredPermissions={[Permissions.VIEW_AUTOMATION_RESPONSE_MESSAGES]}>
              <Button
                id="journeyDetailsEditMode-responseMessages"
                inline
                className={cx(styles.Tab, {
                  [styles.TabActive]: tabMode === JourneyDetailTabModes.RESPONSE_MESSAGES
                })}
                onClick={() => setTabMode(JourneyDetailTabModes.RESPONSE_MESSAGES)}
              >
                Response messages
              </Button>
            </PermissionsGuard>
          </div>
          <div
            className={cx(styles.FormsContainer, {
              [styles.FormsContainerHidden]: tabMode !== JourneyDetailTabModes.GENERAL
            })}
          >
            <General closeModal={closeModal} />
          </div>
          <div
            className={cx(styles.FormsContainer, {
              [styles.FormsContainerHidden]: tabMode !== JourneyDetailTabModes.TRIGGERS
            })}
          >
            <Triggers />
          </div>
          <div
            className={cx(styles.FormsContainer, {
              [styles.FormsContainerHidden]: tabMode !== JourneyDetailTabModes.TOUCHPOINTS
            })}
          >
            <Touchpoints automation={automation} initialTouchpointId={touchpointId} />
          </div>
          <div
            className={cx(styles.FormsContainer, {
              [styles.FormsContainerHidden]: tabMode !== JourneyDetailTabModes.RESPONSE_MESSAGES
            })}
          >
            <ResponseMessages />
          </div>
        </div>
      </div>
    </FullScreenModal>
  );
};

const mapStateToProps = ({ organizationData, automatedMessages, reasons }: ReduxStateType) => {
  return {
    organizationName: organizationData.organizationData
      ? organizationData.organizationData.displayName
      : "",
    automation: automatedMessages.automationDetails
  };
};

export default connect(mapStateToProps, {
  fetchMessages: fetchMessageTemplates,
  fetchAutomationDetails: fetchAutomation
})(AppointmentJourneysDetails);
