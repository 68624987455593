/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";

import Text from "../../../../../../../../ui/Text";

import styles from "./index.module.scss";

export type ListItem = { label: string; icon?: React.ReactNode; onClick: () => void };

type PropsType = {
  list: ListItem[];
  isOpen: boolean;
  toolbarBottomPosition?: number;
  setOpen: (open: boolean) => void;
};

const MenuList = ({ list, isOpen, setOpen, toolbarBottomPosition = 350 }: PropsType) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [menuPosition, setMenuPosition] = useState<"top" | "bottom">("top");

  const toggle = () => {
    setOpen(!isOpen);
  };

  const offMenuListClick = (event: any) => {
    const menulistEl = elementRef.current;
    const isOutsideClick = menulistEl && !menulistEl.contains(event.target);

    if (isOutsideClick) {
      toggle();
      document.removeEventListener("mouseup", offMenuListClick, false);
    }
  };

  const handleOnClick = (item: ListItem) => {
    item.onClick();
    toggle();
  };

  useEffect(() => {
    if (isOpen) {
      // add when mounted
      document.addEventListener("mouseup", offMenuListClick);
      return () => {
        document.removeEventListener("mouseup", offMenuListClick);
      };
    }

    document.removeEventListener("mouseup", offMenuListClick);

    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mouseup", offMenuListClick);
    };
  }, [isOpen]);
  useEffect(() => {
    if (isOpen && elementRef.current) {
      const top = elementRef.current?.getBoundingClientRect()?.top;
      setMenuPosition(top - toolbarBottomPosition > 60 ? "top" : "bottom");
    }
  }, [isOpen]);

  return (
    <div ref={elementRef} className={cx(styles.MenuListWrapper)}>
      <div
        className={cx(
          styles.MenuList,
          menuPosition === "top" ? styles.MenuListPositionTop : styles.MenuListPositionBottom,
          {
            [styles.MenuListOpen]: isOpen
          }
        )}
      >
        {list.map((item) => (
          <button
            type="button"
            className={styles.MenuListItem}
            key={item.label}
            onClick={() => handleOnClick(item)}
            onKeyPress={(e) => {
              if (e.nativeEvent.keyCode === 13) {
                handleOnClick(item);
                toggle();
              }
            }}
          >
            <Text bold size="S" component="span" className={styles.MenuItemText}>
              {item.icon && item.icon}
              {item.label}
            </Text>
          </button>
        ))}
      </div>
    </div>
  );
};

export default MenuList;
