import React from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import cx from "classnames";

import Text from "../../../../../ui/Text";
import Card from "../../../../../ui/Card";
import Button from "../../../../../ui/Button";
import {
  Calendar,
  CircleCheck,
  CircleExclamation,
  Clipboard,
  Clock,
  Profile
} from "../../../../../ui/Icon";
import Heading from "../../../../../ui/Heading";
import Tooltip from "../../../../../ui/Tooltip";
import AppointmentDetailsManager from "../../../AppointmentsTable/AppointmentDetailsManager";

import {
  AppointmentRequest,
  ReduxStateType,
  AppointmentBookingData
} from "../../../../../../types";

import formatDate from "../../../../../../utils/formatDate";
import formatPhone from "../../../../../../utils/formatPhone";
import { updateQueryString, useQueryString } from "../../../../../../utils/queryStringHelpers";
import { generateReasonLabel } from "../../../../../../utils/generateReasonLabel";

import styles from "./index.module.scss";

type PropsType = {
  appointmentRequest: AppointmentRequest;
  appointmentId?: number;
  bookingData: AppointmentBookingData;
  timezone?: string;
  preferQuickView?: boolean;
  closeDrawer?: () => void;
};

const BookingSummary = ({
  appointmentRequest,
  appointmentId,
  bookingData,
  timezone,
  preferQuickView,
  closeDrawer
}: PropsType) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { parsed } = useQueryString();
  const appointmentDetailsOpen = Boolean(parsed.appointmentId);

  const { bookedAt, selectedAppointmentSlots, reasonUsedForBooking, emrPatientUsedForBooking } =
    bookingData;
  const bookedAtDay = bookedAt ? formatDate(bookedAt, "dateTime") : "-";
  const appointmentSlotUsedForBooking = selectedAppointmentSlots.find(
    (apptSlot) => apptSlot.usedForBooking
  );
  const startTimeDisplay = appointmentSlotUsedForBooking?.startTime
    ? formatDate(appointmentSlotUsedForBooking?.startTime, "dateTime", timezone)
    : "-";

  const openAppointmentDetailsModal = (): void => {
    if (appointmentId) {
      updateQueryString({ appointmentId: appointmentId.toString() }, setSearchParams);
    }
    if (closeDrawer) {
      closeDrawer();
    }
  };

  const closeAppointmentDetailsModal = (): void => {
    updateQueryString({ appointmentId: undefined }, setSearchParams);
  };

  return (
    <>
      <Card
        headerContent={
          !preferQuickView ? (
            <>
              <Heading size="M" component="h1" className={cx(styles.Heading)}>
                Booking summary
              </Heading>
              <div className={cx(styles.HeadingGroup)}>
                <Heading size="META" component="div" className={styles.MetaHeading}>
                  {`BOOKED ON: ${bookedAtDay}`}
                </Heading>
                {appointmentId && (
                  <Button inline onClick={openAppointmentDetailsModal}>
                    Go to appointment
                  </Button>
                )}
              </div>
            </>
          ) : null
        }
        className={preferQuickView ? styles.QuickView : ""}
      >
        <Heading size="S" bold className={styles.IconHeading}>
          {preferQuickView && <Profile />}
          <span className={preferQuickView ? styles.IconHeadingText : ""}>
            Patient details from records
          </span>
        </Heading>
        <div className={cx(styles.GroupDetails, styles.BottomBorder)}>
          <div className={styles.InfoGroup}>
            <Heading size="META" component="h5">
              Patient Name
            </Heading>
            <Text>
              {`${emrPatientUsedForBooking?.firstName} ${emrPatientUsedForBooking?.lastName}`}
            </Text>
          </div>
          <div className={styles.InfoGroup}>
            <Heading size="META" component="h5">
              Phone Number
            </Heading>
            <div className={styles.InfoValueRow}>
              <Text>
                {emrPatientUsedForBooking
                  ? formatPhone(emrPatientUsedForBooking.phoneNumberToDisplay)
                  : ""}
              </Text>
              {emrPatientUsedForBooking && (
                <Tooltip
                  icon={
                    <div
                      className={cx(styles.InfoValueIcon, {
                        [styles.InfoValueIconCheck]: emrPatientUsedForBooking.isVerifiedMobile,
                        [styles.InfoValueIconExclamation]:
                          !emrPatientUsedForBooking.isVerifiedMobile
                      })}
                    >
                      {emrPatientUsedForBooking.isVerifiedMobile ? (
                        <CircleCheck size={18} />
                      ) : (
                        <CircleExclamation size={18} />
                      )}
                    </div>
                  }
                  size="S"
                >
                  <Text size="S">
                    {emrPatientUsedForBooking.isVerifiedMobile
                      ? "Verified mobile number"
                      : "Unverified mobile number"}
                  </Text>
                </Tooltip>
              )}
            </div>
          </div>
          <div className={styles.InfoGroup}>
            <Heading size="META" component="h5">
              Primary Provider(s)
            </Heading>
            {emrPatientUsedForBooking?.primaryProviders?.length ? (
              emrPatientUsedForBooking?.primaryProviders.map((provider) => {
                return <Text key={provider.id}>{provider.practitionerName}</Text>;
              })
            ) : (
              <Text>-</Text>
            )}
          </div>
          <div className={styles.InfoGroup}>
            <Heading size="META" component="h5">
              Personal Health Number
            </Heading>
            <Text>{appointmentRequest.patientHealthcareNumber}</Text>
          </div>
          <div className={styles.InfoGroup}>
            <Heading size="META" component="h5">
              Email Address
            </Heading>
            <Text className={styles.Email}>{emrPatientUsedForBooking?.patientEmail}</Text>
          </div>
        </div>
        <Heading size="S" bold className={styles.IconHeading}>
          {preferQuickView && <Clipboard />}
          <span className={preferQuickView ? styles.IconHeadingText : ""}>Appointment reason</span>
        </Heading>
        <div className={cx(styles.GroupDetails, styles.BottomBorder)}>
          <div className={styles.InfoGroup}>
            <Heading size="META" component="h5">
              Associated Reason
            </Heading>
            <Text>{reasonUsedForBooking ? generateReasonLabel(reasonUsedForBooking) : ""}</Text>
          </div>
        </div>
        <Heading size="S" bold className={styles.IconHeading}>
          {preferQuickView && <Calendar />}
          <span className={preferQuickView ? styles.IconHeadingText : ""}>Appointment details</span>
        </Heading>
        <div className={styles.AppointmentSlot}>
          <div className={styles.Date}>
            <Heading size="META" component="h5">
              Date &amp; Time
            </Heading>
            <Text>{startTimeDisplay}</Text>
            <Text size="XS" bold className={styles.Duration}>
              <Clock size={10} />
              <span className={styles.DurationText}>
                {appointmentSlotUsedForBooking?.duration} MIN
              </span>
            </Text>
          </div>
          <div>
            <Heading size="META" component="h5">
              Provider
            </Heading>
            <Text>{appointmentSlotUsedForBooking?.practitionerName}</Text>
          </div>
          <div>
            <Heading size="META" component="h5">
              Location
            </Heading>
            <Text>{appointmentSlotUsedForBooking?.locationName}</Text>
          </div>
        </div>
        {preferQuickView && appointmentId && (
          <Button inline onClick={openAppointmentDetailsModal}>
            Go to appointment
          </Button>
        )}
      </Card>
      {appointmentId && (
        <AppointmentDetailsManager
          isModalOpen={appointmentDetailsOpen}
          closeModal={closeAppointmentDetailsModal}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ organizationData, appointmentRequestsInfo }: ReduxStateType) => {
  return {
    appointmentId: appointmentRequestsInfo?.details?.data?.appointmentId,
    timezone: organizationData.organizationData ? organizationData.organizationData.timezone : ""
  };
};

export default connect(mapStateToProps, null)(BookingSummary);
