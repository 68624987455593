import React from "react";
import { useSearchParams } from "react-router-dom";
import cx from "classnames";

import Button from "../../../../../ui/Button";
import { ResponsiveHide } from "../../../../../ui/Responsive";
import { CircleCheck, Pause } from "../../../../../ui/Icon";

import { updateQueryString } from "../../../../../../utils/queryStringHelpers";
import { Appointment, PractitionerTaskStatus } from "../../../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  appointment: Appointment;
  providerId?: number;
};

const ScribeStatus = ({ appointment, providerId }: PropsType) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const sessionComplete = appointment.practitionerTaskStatus === PractitionerTaskStatus.MIKA_USED;
  const sessionPaused = appointment.practitionerTaskStatus === PractitionerTaskStatus.MIKA_PAUSED;
  const isProvidersAppointment = appointment.practitionerId === providerId;
  const isEligibleForPromptStart = !sessionComplete && !sessionPaused && isProvidersAppointment;

  return (
    <>
      {isEligibleForPromptStart && (
        <Button
          className={styles.ScribeStartButton}
          size="S"
          onClick={() => {
            updateQueryString(
              { appointmentId: appointment.id.toString(), promptStart: "y" },
              setSearchParams
            );
          }}
          inline
        >
          Start
          <ResponsiveHide hideOnDesktop> Scribe</ResponsiveHide>
        </Button>
      )}
      {appointment.practitionerTaskStatus === PractitionerTaskStatus.MIKA_USED && (
        <div className={cx(styles.PractitionerTaskStatus)}>
          <CircleCheck size={20} />
          <ResponsiveHide hideOnDesktop> Scribe</ResponsiveHide>
        </div>
      )}
      {appointment.practitionerTaskStatus === PractitionerTaskStatus.MIKA_PAUSED && (
        <div className={cx(styles.PractitionerTaskStatus)}>
          <Pause size={20} />
          <ResponsiveHide hideOnDesktop> Scribe</ResponsiveHide>
        </div>
      )}
    </>
  );
};

export default ScribeStatus;
