import React, { Dispatch, SetStateAction, useMemo } from "react";
import { connect } from "react-redux";

import { SelectInput } from "../../../../../../../../ui/Input";
import { Chat, ChatFlowsNodes, ReduxStateType } from "../../../../../../../../../types";
import { fetchChats as fetchChatsAction } from "../../../../../../../../../actions";

import BaseChatNodeForm, {
  BaseFormValues,
  FormValues,
  ChatFlowFormValues
} from "../../BaseChatNodeForm";

type ChatFlowPayloadOptions = { subChatFlowId: number };

export type ChatFlowFormNode = ChatFlowsNodes & {
  payloadOptions: ChatFlowPayloadOptions;
};

type PropsType = {
  chatId: string | undefined;
  node: ChatFlowFormNode;
  viewOnly: boolean;
  setSelectedNodeId: Dispatch<SetStateAction<number | null>>;
  chats: Array<Chat>;
};

const onSaveCustomTransformer = (formValues: FormValues) => {
  const values = formValues as BaseFormValues & ChatFlowFormValues;

  return {
    payloadContent: values.payloadContent ? values.payloadContent : "",
    payloadOptions: { subChatFlowId: values.subChatFlowId ? values.subChatFlowId : 0 }
  };
};

const ChatFlow = ({ chatId, node, viewOnly, setSelectedNodeId, chats }: PropsType) => {
  const initialFormValues: ChatFlowFormValues = useMemo(() => {
    return {
      payloadContent: node?.payloadContent ? node.payloadContent : "",
      subChatFlowId: node?.payloadOptions ? node?.payloadOptions.subChatFlowId : 0
    };
  }, [node.id]);

  const validSubFlowIds = chats
    .filter((chat) => chat.isSubFlow === true && chat.published === true)
    .map((subFlow) => {
      return subFlow.id;
    });

  const subFlowOptions = chats
    .filter((chat) => chat.isSubFlow === true && chat.published === true)
    .map((subFlow) => {
      return { label: `${subFlow.id} - ${subFlow.title}`, value: Number(subFlow.id) };
    });

  return (
    <BaseChatNodeForm
      chatId={chatId}
      key={node.id}
      initialValues={initialFormValues}
      onSaveCustomTransformer={onSaveCustomTransformer}
      node={node}
      viewOnly={viewOnly}
      setSelectedNodeId={setSelectedNodeId}
    >
      <SelectInput
        fieldName="subChatFlowId"
        label="Linked SubFlow Id"
        disabled={viewOnly}
        options={
          validSubFlowIds.includes(initialFormValues.subChatFlowId)
            ? subFlowOptions
            : [
                ...subFlowOptions,
                {
                  label: `Mikata Sub Chat - ${initialFormValues.subChatFlowId}`,
                  value: initialFormValues.subChatFlowId
                }
              ]
        }
      />
    </BaseChatNodeForm>
  );
};

const mapStateToProps = ({ chats }: ReduxStateType) => {
  return {
    chats: chats.chats
  };
};

export default connect(mapStateToProps, {
  fetchChats: fetchChatsAction
})(ChatFlow);
