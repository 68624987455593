import React from "react";

import Heading from "../../../ui/Heading";
import DocumentsTable from "../../../common/tables/DocumentsTable";

import styles from "./index.module.scss";

const DocumentsPage = () => {
  return (
    <div>
      <Heading className={styles.Heading} size="XL">
        Documents
      </Heading>
      <DocumentsTable />
    </div>
  );
};

export default DocumentsPage;
