import React from "react";
import cx from "classnames";

import styles from "./index.module.scss";

type PropsType = {
  src: string;
  className?: string;
};

const Image = ({ src, className }: PropsType): React.ReactElement => {
  return (
    <div className={cx(styles.ImageWrapper, className)}>
      <img className={cx(styles.Image)} src={src} />
    </div>
  );
};

export default Image;
