import React, { Dispatch, SetStateAction, useMemo } from "react";
import { v4 as uuid } from "uuid";

import { JsonContentInput } from "../../../../../../../../ui/Input";
import BaseChatNodeForm, {
  BaseFormValues,
  FormValues,
  ScribeVisitFormValues
} from "../../BaseChatNodeForm";

import { ChatFlowsNodes, JsonContent, ChatNodeTypes } from "../../../../../../../../../types";

export type ScribeVisitNode = ChatFlowsNodes & {
  payloadOptions: {
    information?: JsonContent;
  };
};

type PropsType = {
  chatId: string | undefined;
  node: ChatFlowsNodes;
  viewOnly: boolean;
  setSelectedNodeId: Dispatch<SetStateAction<number | null>>;
};

const onSaveCustomTransformer = (formValues: FormValues) => {
  const values = formValues as BaseFormValues & ScribeVisitFormValues;

  return {
    payloadOptions: { information: values.information }
  };
};

const ScribeVisitCard = ({ chatId, node, viewOnly, setSelectedNodeId }: PropsType) => {
  const initialFormValues: ScribeVisitFormValues = useMemo(() => {
    const information = node?.payloadOptions?.information ?? [
      {
        id: uuid(),
        type: ChatNodeTypes.content,
        properties: undefined,
        children: []
      }
    ];

    return {
      payloadContent: node?.payloadContent || "",
      information
    };
  }, [node.id]);

  if (!node) return null;

  return (
    <BaseChatNodeForm
      chatId={chatId}
      key={node.id}
      initialValues={initialFormValues}
      onSaveCustomTransformer={onSaveCustomTransformer}
      node={node}
      viewOnly={viewOnly}
      setSelectedNodeId={setSelectedNodeId}
    >
      <JsonContentInput fieldName="information" label="Information" disabled={viewOnly} />
    </BaseChatNodeForm>
  );
};

export default ScribeVisitCard;
