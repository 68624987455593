import React from "react";

import { TaskDirectMessageContext } from "../../../../../types";

type PropsType = {
  context: TaskDirectMessageContext;
};

const DirectMessageContext = ({ context }: PropsType) => {
  return <div>{JSON.stringify(context)}</div>;
};

export default DirectMessageContext;
