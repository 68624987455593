import React from "react";
import { connect } from "react-redux";

import Button from "../../../ui/Button";
import Heading from "../../../ui/Heading";
import { FloatModal } from "../../../ui/Modal";
import Text from "../../../ui/Text";
import { Form, CheckboxGroup } from "../../../ui/Input";

import {
  deleteMessageConfiguration as deleteMessageConfigurationAction,
  MessageConfigurationDeleteData,
  closeModal as closeModalAction
} from "../../../../actions";

import { isRequired } from "../../../../utils/validators";

import styles from "./index.module.scss";

import { ReduxStateType } from "../../../../types";

type DeleteMessageConfigurationFormData = {
  confirmed: Array<string>;
};

type PropsType = {
  loading?: boolean;
  closeModal: () => void;
  deleteMessageConfiguration: (data: MessageConfigurationDeleteData) => void;
  messageConfigurationId: number;
  messageConfigurationDescription: string;
  automationId: number;
};

const initialFormState: DeleteMessageConfigurationFormData = {
  confirmed: []
};

const confirmedValidator = isRequired("Confirmation required");

const DeleteMessageConfiguration = ({
  loading,
  closeModal,
  messageConfigurationId,
  messageConfigurationDescription,
  automationId,
  deleteMessageConfiguration
}: PropsType) => {
  const onDelete = () => {
    deleteMessageConfiguration({
      configurationId: messageConfigurationId,
      automationId: automationId.toString()
    });
  };

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L">Delete Touchpoint</Heading>
      <Text component="p">{`You are about to permanently delete the "${messageConfigurationDescription}" touchpoint. Is this what you want to do?`}</Text>
      <Form className={styles.Form} onSubmit={onDelete} initialValues={initialFormState}>
        <CheckboxGroup
          fieldName="confirmed"
          options={[{ label: "Yes, I want to delete", value: "true" }]}
          validate={confirmedValidator}
        />
        <div className={styles.ButtonGroup}>
          <Button inline onClick={closeModal} disabled={loading}>
            Cancel
          </Button>
          <Button type="submit" disabled={loading}>
            Delete
          </Button>
        </div>
      </Form>
    </FloatModal>
  );
};
const mapStateToProps = ({ messageConfigurations, organizationData }: ReduxStateType) => {
  return {
    organizationName: organizationData.organizationData
      ? organizationData.organizationData.displayName
      : "",
    loading: messageConfigurations.messageConfigurationDeleteLoading
  };
};

export default connect(mapStateToProps, {
  deleteMessageConfiguration: deleteMessageConfigurationAction,
  closeModal: closeModalAction
})(DeleteMessageConfiguration);
