/* eslint-disable no-useless-escape */
import { Editor } from "draft-js";

const contentTypeHtml = "text/html";
const contentTypePlain = "text/plain";
const opacityInlineStyleReg = /(opacity\: 0\;)/gim;
const backgroundColorInlineStyleReg = /(background-color: [.|a-z|0-9|\(|\)|\s|,]*;)/gim;
const contentNotEditableReg = /(contenteditable="false")/gim;

export const sanitizeClipboardOnCopy = async () => {
  const clipboardItems = await navigator.clipboard.read();

  // retrieve current clipboard content
  const clipboardItem = clipboardItems?.[0];
  const clipboardHTML = await (await clipboardItem.getType("text/html")).text();
  const clipboardPlainText = await (await clipboardItem.getType("text/plain")).text();

  // sanitize content
  const sanitizedHTML = clipboardHTML
    .replaceAll(opacityInlineStyleReg, "")
    .replaceAll(backgroundColorInlineStyleReg, "")
    .replaceAll(contentNotEditableReg, `contenteditable="true"`);

  // update clipboard with sanitized content
  const blobHtml = new Blob([sanitizedHTML], { type: contentTypeHtml });
  const blobPlainText = new Blob([clipboardPlainText], { type: contentTypePlain });

  await navigator.clipboard.write([
    new ClipboardItem({
      [blobHtml.type]: blobHtml,
      [blobPlainText.type]: blobPlainText
    })
  ]);
};
