import React, { useState } from "react";
import { connect } from "react-redux";

import cx from "classnames";

import Heading from "../../../../ui/Heading";
import Text from "../../../../ui/Text";
import Status from "../../../../ui/Status";
import { ChevronDown, ChevronUp, Send, CircleExclamation } from "../../../../ui/Icon";
import getTopicLabel from "../../../../../utils/getTopicLabel";

import {
  AutomationJourneyDetails,
  StatusComponentConfigMap,
  MessageTemplate
} from "../../../../../types";

import styles from "./index.module.scss";
import { openModal as openModalAction, OpenModal } from "../../../../../actions";

import { ModalTypes } from "../../../../../constants";

type PropsType = {
  card: AutomationJourneyDetails;
  selectedLocationId?: number;
  selectedPractitionerId?: number;
  selectedReasonId?: number;
  isOpen?: boolean;
  openModal: OpenModal;
};

const CollapsibleJourneyTemplateCard = ({
  card,
  selectedLocationId,
  selectedPractitionerId,
  selectedReasonId,
  isOpen = false,
  openModal
}: PropsType): React.ReactElement => {
  const headerText = getTopicLabel(card.topic);

  const [open, setOpen] = useState(isOpen);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const triggerOptions = [
    { label: "Location", value: "location" },
    { label: "Provider", value: "provider" },
    { label: "Reason", value: "reason" }
  ];
  const triggerConfigMap: StatusComponentConfigMap = {
    location: "blue",
    provider: "purple",
    reason: "green"
  };

  const openSendTestModal = (messageTemplate: MessageTemplate) => {
    const automationJourneyData = {
      automationId: card.automationId,
      locationId: selectedLocationId,
      practitionerId: selectedPractitionerId,
      reasonId: selectedReasonId
    };
    if (messageTemplate) {
      openModal(ModalTypes.SEND_MESSAGE_TEMPLATE, {
        messageTemplate,
        automationJourneyData,
        messageConfigurationId: card.messageConfigurationId
      });
    }
  };

  return (
    <div className={styles.Card}>
      <Heading size="S">{headerText}</Heading>

      {!card.excludedByAutomation && (
        <button
          type="button"
          className={cx(styles.ToggleButton, { [styles.ToggleButtonOpen]: open })}
          onClick={toggleOpen}
        >
          {open ? (
            <div className={styles.ToggleButtonChevron}>
              <ChevronUp size={24} />
            </div>
          ) : (
            <div className={styles.ToggleButtonChevron}>
              <ChevronDown size={24} />
            </div>
          )}
        </button>
      )}

      <div className={styles.SummaryRow}>
        <div className={styles.Details}>
          <Heading size="META">Automation</Heading>
          <Text size="S">{card.automationName}</Text>
        </div>
        {!open && (
          <div className={styles.Details}>
            {card.excludedByAutomation ? (
              <>
                <div className={styles.MessageHeading}>
                  <Heading size="META">Message</Heading>
                  <div className={styles.CircleExclamation}>
                    <CircleExclamation size={14} />
                  </div>
                </div>
                <Text className={styles.MessageDeactivated} size="S">
                  This message template has been deactivated.
                </Text>
              </>
            ) : (
              <>
                <Heading size="META">Message</Heading>
                <Text size="S">{(card.template ?? ({} as MessageTemplate)).displayName}</Text>
              </>
            )}
          </div>
        )}
      </div>

      <div className={styles.SummaryRow}>
        <div className={styles.Filters}>
          {card.selectedBy.location && (
            <Status
              className={styles.Filter}
              value="location"
              options={triggerOptions}
              configMap={triggerConfigMap}
            />
          )}
          {card.selectedBy.practitioner && (
            <Status
              className={styles.Filter}
              value="provider"
              options={triggerOptions}
              configMap={triggerConfigMap}
            />
          )}
          {card.selectedBy.reason && (
            <Status
              className={styles.Filter}
              value="reason"
              options={triggerOptions}
              configMap={triggerConfigMap}
            />
          )}
        </div>

        {!open && (
          <div className={styles.Details}>
            <Heading size="META">Chat(s)</Heading>
            <Text size="S">{card.chatFlowTitles ? card.chatFlowTitles.join(", ") : "None"}</Text>
          </div>
        )}
      </div>

      {card.template && (
        <div className={cx(styles.Content, { [styles.ContentOpen]: open })}>
          <div className={styles.Details}>
            <Heading size="META">Message</Heading>
            <Text size="S">{card.template.displayName}</Text>
          </div>
          <div className={styles.Details}>
            <Text size="S">{card.template.payloadContent}</Text>
          </div>
          <div className={styles.Details}>
            <Heading size="META">Chat</Heading>
            <Text size="S">{card.chatFlowTitles ? card.chatFlowTitles.join(", ") : "None"}</Text>
          </div>
          {open && (
            <button
              type="button"
              onClick={() => {
                openSendTestModal(card.template as MessageTemplate);
              }}
              className={styles.SendTestButton}
            >
              <Send size={24} />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default connect(null, {
  openModal: openModalAction
})(CollapsibleJourneyTemplateCard);
