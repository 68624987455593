import React, { useState, useEffect } from "react";
import cx from "classnames";

import Button from "../Button";
import { Close, Search as SearchIcon } from "../Icon";

import styles from "./index.module.scss";

type PropsType = {
  classNames?: string;
  inputClassName?: string;
  id?: string;
  placeholder?: string;
  onChange?: (search: string) => void;
  onClear?: () => void;
  initialValue?: string;
  searchIconSize?: number;
  searchIconClass?: string;
  onEnter?: (searchValue: string) => void;
};

const Search = ({
  classNames,
  inputClassName,
  id,
  placeholder,
  onChange,
  onClear,
  initialValue,
  searchIconSize,
  searchIconClass = "",
  onEnter
}: PropsType) => {
  const [searchValue, setSearchValue] = useState(initialValue || "");

  useEffect(() => {
    setSearchValue(initialValue || "");
  }, [initialValue]);

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const search = event.target.value || "";
    if (onChange) {
      onChange(search);
    }

    setSearchValue(search);
  };

  const clearSearch = () => {
    if (onClear) {
      onClear();
    }
    if (onChange) {
      onChange("");
    }
    setSearchValue("");
  };

  return (
    <div className={cx(classNames, styles.Search)}>
      <input
        id={id}
        type="text"
        className={cx(inputClassName, styles.SearchInput)}
        placeholder={placeholder}
        disabled={false}
        onChange={onSearchChange}
        onKeyUp={(e) => {
          if (e.nativeEvent.key === "Enter" && onEnter) {
            onEnter(searchValue);
          }
        }}
        value={searchValue}
      />
      <span className={cx(styles.SearchIcon, searchIconClass)}>
        <SearchIcon size={searchIconSize} />
      </span>
      {searchValue && (
        <Button inline className={styles.SearchClear} onClick={clearSearch}>
          <Close />
        </Button>
      )}
    </div>
  );
};

export default Search;
