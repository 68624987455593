import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import cx from "classnames";

import Card from "../../../../ui/Card";
import Dropdown from "../../../../ui/Dropdown";
import Heading from "../../../../ui/Heading";
import Loader from "../../../../ui/Loader";
import ReportCard from "../../../../ui/ReportCard";
import ChatSummary from "./ChatSummary";
import Button from "../../../../ui/Button";
import TranscriptMessages from "../../../../ui/TranscriptMessages";
import { FullScreenModal } from "../../../../ui/Modal";
import AppointmentBookingCard from "./AppointmentBookingCard";
import BookingSummary from "./BookingSummary";
import Timeline from "../../../Timeline";

import {
  UpdateAppointmentRequestData,
  fetchAppointmentRequestsDetail as fetchAppointmentRequestsDetailAction,
  clearAppointmentRequestsDetail as clearAppointmentRequestsDetailAction,
  clearAppointmentsDetail as clearAppointmentsDetailAction,
  getAppointmentRequestHistory as getAppointmentRequestHistoryAction,
  clearAppointmentRequestHistory as clearAppointmentRequestHistoryAction,
  updateAppointmentRequest as updateAppointmentRequestAction
} from "../../../../../actions";

import {
  AppointmentRequestStatuses,
  appointmentRequestStatusConfigMap,
  AppointmentRequestModes,
  apptRequestStatusOptions
} from "../../../../../constants";

import styles from "./index.module.scss";

import {
  ReduxStateType,
  AppointmentRequest,
  AppointmentRequestsUpdatesLoading,
  AppointmentBookingData,
  Report,
  TranscriptMessage,
  Staff,
  AppointmentRequestDetailTabModes,
  Permissions,
  HistoryEvent
} from "../../../../../types";

import { PermissionsGuard } from "../../../../../hooks/usePermissions";

type PropsType = {
  appointmentRequest?: AppointmentRequest;
  currentUserId?: string;
  bookingData: AppointmentBookingData | null;
  reports: Report[];
  transcript: TranscriptMessage[];
  isModalOpen: boolean;
  closeModal: () => void;
  loading: boolean;
  updatesLoading?: AppointmentRequestsUpdatesLoading;
  updateAppointmentRequest: (
    id: number,
    update: UpdateAppointmentRequestData,
    onSuccess?: () => void
  ) => void;
  fetchAppointmentRequestsDetail?: (appointmentRequestId: number) => void;
  clearAppointmentRequestsDetail: () => void;
  clearAppointmentsDetail: () => void;
  downloadRequisition: (requestId: number, uploadId: number, uploadIdx: number) => void;
  requisitionLoading: { [id: string]: boolean[] };
  staff: Array<Staff>;
  getAppointmentRequestHistory?: (id: number) => void;
  clearAppointmentRequestHistory: () => void;
  appointmentRequestHistoryLoading: boolean;
  appointmentRequestHistory?: HistoryEvent[];
};

const AppointmentRequestDetails = ({
  appointmentRequest,
  currentUserId,
  bookingData,
  reports,
  transcript,
  isModalOpen,
  closeModal,
  updateAppointmentRequest,
  fetchAppointmentRequestsDetail,
  clearAppointmentRequestsDetail,
  clearAppointmentsDetail,
  loading,
  updatesLoading,
  downloadRequisition,
  requisitionLoading,
  staff,
  getAppointmentRequestHistory,
  clearAppointmentRequestHistory,
  appointmentRequestHistoryLoading,
  appointmentRequestHistory
}: PropsType) => {
  const parsed = queryString.parse(window.location.search);
  const { appointmentRequestId } = parsed;
  let mode = "";
  if (!loading && bookingData && bookingData.selectedAppointmentSlots.length > 0) {
    if (
      appointmentRequest?.status === AppointmentRequestStatuses.BOOKED ||
      appointmentRequest?.status === AppointmentRequestStatuses.AUTO_BOOKED
    ) {
      mode = AppointmentRequestModes.VIEW_BOOKING;
    } else {
      mode = AppointmentRequestModes.APPOINTMENT_BOOKING;
    }
  } else if (!loading) {
    mode = AppointmentRequestModes.SIMPLE_REQUEST;
  }

  const currentStaffUser = currentUserId
    ? staff?.find((staffUser) => staffUser.userId === parseInt(currentUserId, 10))
    : null;

  const currentStaffUserOption = currentStaffUser
    ? [
        {
          label: `Me (${currentStaffUser.firstName} ${currentStaffUser.lastName})`,
          value: currentStaffUser.userId.toString()
        }
      ]
    : [];

  const staffOptions = staff
    ? [
        ...currentStaffUserOption,
        ...staff
          .filter((staffUser) => {
            return currentStaffUser
              ? staffUser.userId !== currentStaffUser.userId && !!staffUser.active
              : !!staffUser.active;
          })
          .map((staffUser) => ({
            label: `${staffUser.firstName} ${staffUser.lastName}`,
            value: staffUser.userId.toString()
          }))
          .sort((a, b) => {
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;
            return 0;
          })
      ]
    : [];

  const [tabMode, setTabMode] = useState(AppointmentRequestDetailTabModes.CHAT);

  useEffect(() => {
    if (appointmentRequestId && fetchAppointmentRequestsDetail) {
      fetchAppointmentRequestsDetail(Number.parseInt(appointmentRequestId as string, 10));
    }
  }, [appointmentRequestId]);

  useEffect(() => {
    if (getAppointmentRequestHistory && appointmentRequestId) {
      getAppointmentRequestHistory(Number.parseInt(appointmentRequestId as string, 10));
    }
  }, [getAppointmentRequestHistory, appointmentRequestId]);

  return (
    <FullScreenModal
      isOpen={isModalOpen}
      onClose={() => {
        clearAppointmentRequestsDetail();
        clearAppointmentsDetail();
        setTabMode(AppointmentRequestDetailTabModes.CHAT);
        clearAppointmentRequestHistory();
        closeModal();
      }}
    >
      <div className={styles.Header}>
        <Heading size="L" component="h1">
          Appointment Request Details
        </Heading>

        {appointmentRequest && (
          <div className={styles.HeaderDetails}>
            <div className={styles.HeaderDetailGroup}>
              <Heading className={styles.HeaderDetailGroupTitle} size="META">
                Assigned To
              </Heading>
              <Dropdown
                className={styles.DropdownStatus}
                onChange={(selectedStaffUserId) => {
                  updateAppointmentRequest(
                    appointmentRequest.id,
                    {
                      assignedStaffUserId:
                        selectedStaffUserId !== "None" ? selectedStaffUserId : null
                    },
                    () => {
                      if (getAppointmentRequestHistory) {
                        getAppointmentRequestHistory(
                          Number.parseInt(appointmentRequestId as string, 10)
                        );
                      }
                    }
                  );
                }}
                value={appointmentRequest.assignedStaffUserId?.toString() || "None"}
                options={[{ label: "None", value: "None" }, ...staffOptions]}
                configMap={{
                  None: "gray"
                }}
                defaultColor="blue"
                loading={
                  updatesLoading && updatesLoading[appointmentRequest.id]?.assignedStaffUserId
                }
              />
            </div>
            <div className={styles.HeaderDetailGroup}>
              <Heading className={styles.HeaderDetailGroupTitle} size="META">
                Status
              </Heading>
              <Dropdown
                className={styles.DropdownStatus}
                onChange={(selectedStatus: string) => {
                  updateAppointmentRequest(
                    appointmentRequest.id,
                    {
                      status: selectedStatus
                    },
                    () => {
                      if (getAppointmentRequestHistory) {
                        getAppointmentRequestHistory(
                          Number.parseInt(appointmentRequestId as string, 10)
                        );
                      }
                    }
                  );
                }}
                value={appointmentRequest.status}
                options={apptRequestStatusOptions}
                configMap={appointmentRequestStatusConfigMap}
                loading={updatesLoading && updatesLoading[appointmentRequest.id]?.status}
              />
            </div>
          </div>
        )}
      </div>
      {loading ? (
        <Loader screen />
      ) : (
        <div className={styles.Wrapper}>
          <div>
            {appointmentRequest && (
              <ChatSummary
                appointmentRequest={appointmentRequest}
                mode={mode}
                downloadRequisition={downloadRequisition}
                requisitionLoading={requisitionLoading}
              />
            )}
            {reports.map((report) => {
              return <ReportCard key={report.id} report={report} />;
            })}
          </div>
          <div className={styles.Column}>
            <div className={styles.Tabs}>
              <Button
                id="chatTranscriptsTab"
                inline
                className={cx(styles.Tab, {
                  [styles.TabActive]: tabMode === AppointmentRequestDetailTabModes.CHAT
                })}
                onClick={() => setTabMode(AppointmentRequestDetailTabModes.CHAT)}
              >
                Transcripts
              </Button>
              <PermissionsGuard
                requiredPermissions={[Permissions.GET_APPOINTMENTS_REQUEST_HISTORY]}
              >
                <Button
                  id="timelineTab"
                  inline
                  className={cx(styles.Tab, {
                    [styles.TabActive]: tabMode === AppointmentRequestDetailTabModes.TIMELINE
                  })}
                  onClick={() => setTabMode(AppointmentRequestDetailTabModes.TIMELINE)}
                >
                  Timeline
                </Button>
              </PermissionsGuard>
            </div>

            {tabMode === AppointmentRequestDetailTabModes.CHAT && (
              <div>
                {appointmentRequest &&
                  bookingData &&
                  mode === AppointmentRequestModes.APPOINTMENT_BOOKING && (
                    <AppointmentBookingCard
                      appointmentRequestId={appointmentRequest.id}
                      personalHealthNumber={appointmentRequest.patientHealthcareNumber}
                      bookingData={bookingData}
                      closeFullScreenModal={closeModal}
                    />
                  )}
                {appointmentRequest &&
                  bookingData &&
                  mode === AppointmentRequestModes.VIEW_BOOKING && (
                    <BookingSummary
                      appointmentRequest={appointmentRequest}
                      bookingData={bookingData}
                    />
                  )}
                <Card
                  id="appointmentRequestTranscripts"
                  collapsible
                  headerContent={
                    <Heading
                      size="M"
                      component="span"
                      className={cx(styles.Heading, styles.BottomBorder)}
                    >
                      Transcripts
                    </Heading>
                  }
                >
                  <TranscriptMessages transcript={transcript} loading={false} />
                </Card>
              </div>
            )}
            {tabMode === AppointmentRequestDetailTabModes.TIMELINE && (
              <div>
                <Timeline
                  historyLoading={appointmentRequestHistoryLoading}
                  history={appointmentRequestHistory}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </FullScreenModal>
  );
};

const mapStateToProps = ({ appointmentRequestsInfo, organizationData }: ReduxStateType) => {
  return {
    loading: appointmentRequestsInfo.details.data ? appointmentRequestsInfo.details.loading : true,
    updatesLoading: appointmentRequestsInfo.updatesLoading,
    reports: appointmentRequestsInfo.details.data
      ? appointmentRequestsInfo.details.data.reports
      : [],
    transcript: appointmentRequestsInfo.details.data
      ? appointmentRequestsInfo.details.data.transcript
      : [],
    bookingData: appointmentRequestsInfo.details.data
      ? appointmentRequestsInfo.details.data.bookingData
      : null,
    staff: organizationData?.organizationData?.staff,
    appointmentRequestHistoryLoading: appointmentRequestsInfo.appointmentRequestHistoryLoading,
    appointmentRequestHistory: appointmentRequestsInfo.appointmentRequestHistory
  };
};

export default connect(mapStateToProps, {
  fetchAppointmentRequestsDetail: fetchAppointmentRequestsDetailAction,
  clearAppointmentRequestsDetail: clearAppointmentRequestsDetailAction,
  clearAppointmentsDetail: clearAppointmentsDetailAction,
  updateAppointmentRequest: updateAppointmentRequestAction,
  getAppointmentRequestHistory: getAppointmentRequestHistoryAction,
  clearAppointmentRequestHistory: clearAppointmentRequestHistoryAction
})(AppointmentRequestDetails);
