import React from "react";

type PropsType = {
  size?: number;
};

const Duplicate = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2H17C18.1046 2 19 2.89543 19 4V5H20C21.1046 5 22 5.89543 22 7V20C22 21.1046 21.1046 22 20 22H7C5.89543 22 5 21.1046 5 20V19H4C2.89543 19 2 18.1046 2 17V4C2 2.89543 2.89543 2 4 2ZM7 17V19V20H20V7H19H17H7V17ZM17 5H7C5.89543 5 5 5.89543 5 7V17H4V4H17V5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Duplicate;
