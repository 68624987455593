import React from "react";

import { useFormApi } from "informed";
import Button from "../../Button";
import { CheckboxGroup } from "..";
import Heading from "../../Heading";

import { Option } from "../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  options: Array<Option>;
  fieldName: string;
};

const FilterSelectForm = ({ options, fieldName }: PropsType) => {
  const formApi = useFormApi();
  return (
    <>
      <Heading size="META">Assignee(s)</Heading>
      <CheckboxGroup fieldName={fieldName} options={options} />
      <div className={styles.Divider} />
      <div className={styles.ButtonWrapper}>
        <Button
          id="clear-filters"
          type="button"
          inline
          onClick={() => {
            formApi.setValue(fieldName, []);
          }}
        >
          Clear all
        </Button>

        <Button type="submit">Apply</Button>
      </div>
    </>
  );
};

export default FilterSelectForm;
