import React from "react";

type PropsType = {
  size?: number;
  color?: string;
};

const Heading2 = ({ size = 24, color = "currentColor" }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M15 5a1 1 0 0 0-1 1v8H6V6a1 1 0 0 0-2 0v16a1 1 0 0 0 2 0v-6h8v6a1 1 0 0 0 2 0V6a1 1 0 0 0-1-1ZM19.42 22c2.83-1.78 5.36-4.05 5.36-6.9a3.13 3.13 0 0 0-3.35-3.38c-2.3 0-3.57 1.67-3.57 3.86l1.09.08c0-1.65.79-2.88 2.48-2.88A2.08 2.08 0 0 1 23.66 15c0 3-3.47 5.3-6.08 6.94v1h7.71V22Z"
        fill={color}
      />
    </svg>
  );
};

export default Heading2;
