import React, { useEffect, useState, createRef } from "react";
import cx from "classnames";

import styles from "./index.module.scss";
import { ChevronDown, ChevronUp, Plus } from "../../Icon";
import Text from "../../Text";

import { ResponsiveHide } from "../../Responsive";

export type ButtonOption = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  icon?: React.ReactNode;
};

type PropsType = {
  customMultiIcon?: React.ReactNode;
  buttonOptions: Array<ButtonOption>;
  className?: string;
};

export const MultiButton = ({ buttonOptions, className, customMultiIcon }: PropsType) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdown = createRef<HTMLDivElement>();

  const toggle = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const handleClickOutside = (event: MouseEvent | null) => {
    if (
      dropdown.current &&
      event &&
      event.target instanceof HTMLElement &&
      !dropdown.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleClickOutside);

    // returned function will be called on component unmount
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [dropdown]);

  if (buttonOptions.length === 0) return null;

  const hasMultiOptions = buttonOptions.length > 1;
  return (
    <div className={cx(styles.DrawerWrapper, className)} ref={dropdown}>
      <div
        className={cx(styles.Drawer, {
          [styles.DrawerOpen]: isOpen
        })}
      >
        <div className={styles.DrawerHeaderGroup}>
          <ResponsiveHide hideOnMobile={hasMultiOptions}>
            <button
              type="button"
              className={cx(styles.FirstButton, { [styles.FirstButtonMulti]: hasMultiOptions })}
              onClick={buttonOptions[0]?.onClick}
              disabled={buttonOptions[0]?.disabled === true}
            >
              <ResponsiveHide hideOnMobile>
                <Text className={styles.FirstButtonText} bold size="S">
                  {buttonOptions[0].label}
                </Text>
              </ResponsiveHide>
              <ResponsiveHide hideOnDesktop hideOnTablet>
                {!hasMultiOptions && buttonOptions[0].icon ? (
                  buttonOptions[0].icon
                ) : (
                  <Plus size={16} />
                )}
              </ResponsiveHide>
            </button>
          </ResponsiveHide>

          {hasMultiOptions && (
            <button type="button" className={cx(styles.ToggleButton)} onClick={toggle}>
              <ResponsiveHide hideOnMobile>
                {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
              </ResponsiveHide>

              <ResponsiveHide hideOnDesktop hideOnTablet>
                {customMultiIcon || <Plus size={16} />}
              </ResponsiveHide>
            </button>
          )}
        </div>

        <div className={cx(styles.Content, { [styles.ContentOpen]: isOpen })}>
          {buttonOptions?.map(({ label, onClick, disabled = false }) => (
            <button
              type="button"
              className={styles.DropdownListItem}
              key={label}
              disabled={disabled}
              onClick={onClick}
            >
              <Text className={styles.DropdownListItemText} size="S">
                {label}
              </Text>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
