import React, { useEffect, useRef } from "react";
import cx from "classnames";

import Heading from "../Heading";
import Text from "../Text";
import Loader from "../Loader";
import Tooltip from "../Tooltip";
import { User, MikataSimple, Info, CircleCheck, CircleExclamation } from "../Icon";
import { MessageTopics, ChatCardTypes } from "../../../constants";

import formatDate from "../../../utils/formatDate";

import {
  TranscriptMessage,
  SMSDeliveryStatus,
  TranscriptMessageStatus,
  PayloadOptions,
  ChatFlowResponseType
} from "../../../types";

import styles from "./index.module.scss";

type PropsType = {
  transcript?: TranscriptMessage[];
  loading?: boolean;
};

const getDisplayContent = (payloadContent: string, payloadOptions: PayloadOptions | null) => {
  try {
    // If the message is a closing card, it might be JSON with HTML in the content
    const parsedContent = JSON.parse(payloadContent);

    if (parsedContent && Array.isArray(parsedContent)) {
      return parsedContent
        .map((option) => {
          return typeof option === "string" ? option : option.text || JSON.stringify(option);
        })
        .join(", ");
      // eslint-disable-next-line no-else-return
    } else if (parsedContent.headerContent) {
      return `${parsedContent.headerContent.replace(
        /<[^>]+>/g,
        " "
      )} ${parsedContent.bodyContent.replace(/<[^>]+>/g, " ")}`;
    } else if (parsedContent.text) {
      return parsedContent.text;
    } else if (
      parsedContent.responseType &&
      parsedContent.responseType === ChatFlowResponseType.BOOKING
    ) {
      const selectedAppointmentTimes = parsedContent.selectedAppointmentSlots || [];
      return `${selectedAppointmentTimes.length} available appointment time(s) selected.`;
    } else if (
      parsedContent.responseType &&
      parsedContent.responseType === ChatFlowResponseType.PATIENT_VERIFICATION_ATTEMPT
    ) {
      // Deprecated Chat system.
      // New chat system does not add the patientVerified value to the PATIENT_VERIFICATION_ATTEMPT message.
      if (payloadOptions) {
        return (
          <div className={styles.PatientVerification}>
            {payloadOptions?.patientVerified ? (
              <>
                <CircleCheck size={20} />
                <Text className={styles.PatientVerificationText}>Patient verified</Text>
              </>
            ) : (
              <>
                <CircleExclamation size={20} />
                <Text className={styles.PatientVerificationText}>Verification error</Text>
              </>
            )}
          </div>
        );
      }
      return null;
    } else if (
      parsedContent.responseType &&
      parsedContent.responseType === ChatFlowResponseType.PATIENT_VERIFICATION_RESULT
    ) {
      return (
        <div className={styles.PatientVerification}>
          {parsedContent.hasSufficientPatientData === false ? (
            <>
              <CircleExclamation size={20} />
              <Text className={styles.PatientVerificationText}>
                Insufficient patient data. Unable to verify.
              </Text>
            </>
          ) : (
            <>
              {parsedContent.patientVerified ? (
                <>
                  <CircleCheck size={20} />
                  <Text className={styles.PatientVerificationText}>Patient verified</Text>
                </>
              ) : (
                <>
                  <CircleExclamation size={20} />
                  <Text className={styles.PatientVerificationText}>Verification error</Text>
                </>
              )}
            </>
          )}
        </div>
      );
    }

    return JSON.stringify(parsedContent);
  } catch (err) {
    // clean html tags from content
    return payloadContent.replace(/<[^>]+>/gm, " ");
  }
};

const hasPatientVerificationError = (
  payloadContent: string,
  payloadOptions: PayloadOptions | null
) => {
  try {
    const parsedContent = JSON.parse(payloadContent);

    if (
      parsedContent.responseType &&
      parsedContent.responseType === ChatCardTypes.PATIENT_VERIFICATION
    ) {
      return payloadOptions && !payloadOptions.patientVerified;
    }
    return false;
  } catch (err) {
    return false;
  }
};

const parseDeliveryDetails = (
  message: TranscriptMessage
): {
  status: "success" | "pending" | "failed" | "noContact";
  infoMessage?: string;
} => {
  if (message.incoming === true) {
    return {
      status: "success",
      infoMessage: ""
    };
  }

  // Currently emails do not have a delivery status applied - set to success until we start tracking email deliveries
  if (message.medium === "email") {
    return {
      status: "success",
      infoMessage: ""
    };
  }

  if (message.status === TranscriptMessageStatus.noContact) {
    return {
      status: "noContact",
      infoMessage: "We did not have sufficient patient contact information to deliver this message."
    };
  }

  // Status Definitions: https://www.twilio.com/docs/sms/api/message-resource#message-status-values
  switch (message.serviceStatus) {
    case SMSDeliveryStatus.FAILED:
    case SMSDeliveryStatus.UNDELIVERED: {
      return {
        status: "failed",
        infoMessage:
          "We attempted to send this message, but unfortunately it was unable to be delivered."
      };
    }
    case SMSDeliveryStatus.SENT:
    case SMSDeliveryStatus.SENDING:
    case SMSDeliveryStatus.QUEUED:
    case SMSDeliveryStatus.ACCEPTED:
    case SMSDeliveryStatus.SCHEDULED:
    case SMSDeliveryStatus.RECEIVING:
    case SMSDeliveryStatus.RECEIVED: {
      return {
        status: "pending",
        infoMessage:
          "We have sent the message and are awaiting confirmation of a successful delivery."
      };
    }
    case null:
    case SMSDeliveryStatus.READ:
    case SMSDeliveryStatus.DELIVERED:
    case SMSDeliveryStatus.LIKE_DELIVERED:
    default: {
      return {
        status: "success",
        infoMessage: ""
      };
    }
  }
};

const getMediumLabel = (medium: string): string => {
  const mediumLabelMap: { [medium: string]: string } = {
    sms: "SMS",
    email: "EMAIL",
    "web/chat": "WEB CHAT",
    voice: "VOICE"
  };

  return mediumLabelMap[medium] || "";
};

const TranscriptMessages = ({ transcript, loading }: PropsType) => {
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  // On first load snap to bottom
  useEffect(() => {
    if (messagesEndRef.current) {
      const scrollbarHeight = messagesEndRef.current.scrollHeight;
      messagesEndRef.current?.scrollTo({
        top: scrollbarHeight
      });
    }
  }, []);

  // On transcript update/new message smooth scroll to bottom
  useEffect(() => {
    if (messagesEndRef.current) {
      const scrollbarHeight = messagesEndRef.current.scrollHeight;
      messagesEndRef.current?.scrollTo({
        top: scrollbarHeight,
        behavior: "smooth"
      });
    }
  }, [transcript]);

  if (!loading && transcript && transcript.length === 0) {
    return <Text>No messages</Text>;
  }

  return (
    <div
      id="transcriptMessages"
      className={styles.Transcript}
      // eslint-disable-next-line no-return-assign
      ref={(ref) => (messagesEndRef.current = ref)}
    >
      {loading && <Loader small center />}
      {!loading &&
        transcript &&
        transcript.map((message, idx) => {
          const isClinicMessage = !message.incoming;
          const isPatientMessage = message.incoming;
          const isDirectMessage =
            message.senderLastName &&
            (message.title === MessageTopics.DIRECT_MESSAGE ||
              message.title === MessageTopics.CHECK_IN_NOTIFY_USER);

          const isNewSender =
            !transcript[idx - 1] || transcript[idx - 1].incoming !== message.incoming;
          const isNewMedium = transcript[idx - 1] && transcript[idx - 1].medium !== message.medium;
          const lastMessageTime = transcript[idx - 1] ? Date.parse(transcript[idx - 1].sent) : 0;
          const timeSinceLastMessageMS = Date.parse(message.sent) - lastMessageTime;
          const medium = getMediumLabel(message.medium);
          const messageDeliveryDetails = parseDeliveryDetails(message);

          const showMessageTimeMedium =
            isNewSender ||
            isNewMedium ||
            timeSinceLastMessageMS > 300000 || // 5 minutes
            messageDeliveryDetails?.status !== "success" ||
            isDirectMessage;

          const displayContent = getDisplayContent(message.payloadContent, message.payloadOptions);

          if (!displayContent) return null;

          return (
            <div
              key={message.id}
              className={cx({ [styles.MessageTimeMediumWrapper]: showMessageTimeMedium })}
            >
              {showMessageTimeMedium && (
                <div
                  className={cx(styles.TimeMedium, {
                    [styles.TimeMediumPatient]: isPatientMessage
                  })}
                >
                  <Heading size="META">
                    {`${formatDate(message.sent, "fullDateTime")} - ${medium}`}
                  </Heading>
                  {messageDeliveryDetails?.status === "noContact" && (
                    <Heading
                      size="META"
                      className={cx(styles.MessageStatus, styles.MessageStatusWarning)}
                    >
                      Insufficient Contact information
                    </Heading>
                  )}
                  {messageDeliveryDetails?.status === "pending" && (
                    <Heading
                      size="META"
                      className={cx(styles.MessageStatus, styles.MessageStatusWarning)}
                    >
                      Processing message
                    </Heading>
                  )}
                  {messageDeliveryDetails?.status === "failed" && (
                    <Heading
                      size="META"
                      className={cx(styles.MessageStatus, styles.MessageStatusError)}
                    >
                      Message failed
                    </Heading>
                  )}
                  {messageDeliveryDetails?.infoMessage && (
                    <Tooltip
                      icon={
                        <div
                          className={cx(styles.InfoIcon, {
                            [styles.InfoIconWarning]:
                              messageDeliveryDetails?.status === "pending" ||
                              messageDeliveryDetails?.status === "noContact",
                            [styles.InfoIconError]: messageDeliveryDetails?.status === "failed"
                          })}
                        >
                          <Info size={15} />
                        </div>
                      }
                      position="bottomLeft"
                    >
                      {messageDeliveryDetails.infoMessage}
                    </Tooltip>
                  )}
                </div>
              )}
              {isDirectMessage && (
                <div className={cx(styles.SenderInfo)}>
                  <Heading size="META">
                    SENT BY {message.senderFirstName} {message.senderLastName}
                  </Heading>
                </div>
              )}

              <div className={styles.MessageWrapper}>
                {isDirectMessage && (
                  <div className={cx(styles.SenderIcon, styles.SenderIconClinic)}>
                    <User size={20} />
                  </div>
                )}
                {(isNewSender || isNewMedium || showMessageTimeMedium) &&
                  isClinicMessage &&
                  !isDirectMessage && (
                    <div className={cx(styles.SenderIcon, styles.SenderIconClinic)}>
                      <MikataSimple size={22} />
                    </div>
                  )}
                {isNewSender && isPatientMessage && (
                  <div className={cx(styles.SenderIcon, styles.SenderIconPatient)}>
                    <User size={20} />
                  </div>
                )}
                <div
                  className={cx(styles.Message, {
                    [styles.MessageClinic]: isClinicMessage,
                    [styles.MessagePatient]: isPatientMessage,
                    [styles.MessageWarning]:
                      messageDeliveryDetails?.status === "pending" ||
                      messageDeliveryDetails?.status === "noContact",
                    [styles.MessageError]:
                      messageDeliveryDetails?.status === "failed" ||
                      hasPatientVerificationError(message.payloadContent, message.payloadOptions)
                  })}
                >
                  <Text component="div" className={styles.MessageText}>
                    {displayContent}
                  </Text>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default TranscriptMessages;
