import {
  ADD_MESSAGE_CONFIGURATION,
  UPDATE_MESSAGE_CONFIGURATION,
  DELETE_MESSAGE_CONFIGURATION
} from "../constants";

import {
  AddMessageConfigurationAction,
  UpdateMessageConfigurationAction,
  DeleteMessageConfigurationAction
} from "../actions";
import { ActionStatus, MessageConfigurationTemplate } from "../types";

export type MessageConfigurationsAction =
  | AddMessageConfigurationAction
  | UpdateMessageConfigurationAction
  | DeleteMessageConfigurationAction;

export type MessageConfigurationsReduxState = {
  data: Array<MessageConfigurationTemplate>;
  messageConfigurationAddLoading: boolean;
  messageConfigurationUpdateLoading: boolean;
  messageConfigurationDeleteLoading: boolean;
};

const initialMessageConfigurationsState: MessageConfigurationsReduxState = {
  data: [],
  messageConfigurationAddLoading: false,
  messageConfigurationUpdateLoading: false,
  messageConfigurationDeleteLoading: false
};

export const messageConfigurationsReducer = (
  state = initialMessageConfigurationsState,
  action: MessageConfigurationsAction
): MessageConfigurationsReduxState => {
  const { type } = action;
  switch (type) {
    case ADD_MESSAGE_CONFIGURATION: {
      const { status } = action as AddMessageConfigurationAction;
      return {
        ...state,
        messageConfigurationAddLoading: status === ActionStatus.loading
      };
    }
    case UPDATE_MESSAGE_CONFIGURATION: {
      const { status } = action as UpdateMessageConfigurationAction;
      return {
        ...state,
        messageConfigurationUpdateLoading: status === ActionStatus.loading
      };
    }
    case DELETE_MESSAGE_CONFIGURATION: {
      const { status } = action as DeleteMessageConfigurationAction;
      return {
        ...state,
        messageConfigurationDeleteLoading: status === ActionStatus.loading
      };
    }

    default:
      return state;
  }
};
