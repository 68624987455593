import React from "react";
import cx from "classnames";

import Heading from "../../Heading";
import Tooltip from "../../Tooltip";
import { Info } from "../../Icon";

import styles from "./index.module.scss";

type PropsType = {
  children: React.ReactNode;
  fieldName: string;
  label?: string;
  info?: React.ReactNode;
  infoStatus?: "info" | "warning" | "error";
  labelDetails?: React.ReactNode;
  error?: string;
  showField?: boolean;
  warningMessage?: string;
};

const BaseInput = ({
  children,
  info,
  infoStatus = "info",
  fieldName,
  label,
  labelDetails,
  error = "",
  showField = true,
  warningMessage = ""
}: PropsType) => {
  return (
    <div className={cx({ [styles.Wrapper]: true, [styles.Hide]: !showField })}>
      <div className={styles.HeadingRow}>
        {label && (
          <label htmlFor={fieldName} className={styles.Label}>
            <Heading size="META">{label}</Heading>
            {info && (
              <Tooltip
                icon={
                  <div
                    className={cx(styles.Icon, {
                      [styles.IconWarning]: infoStatus === "warning",
                      [styles.IconError]: infoStatus === "error"
                    })}
                  >
                    <Info size={15} />
                  </div>
                }
              >
                {info}
              </Tooltip>
            )}
          </label>
        )}
        {labelDetails}
      </div>

      {children}
      <div
        className={cx(styles.Message, {
          [styles.MessageError]: error,
          [styles.MessageWarning]: !error && warningMessage
        })}
      >
        {error || warningMessage}
      </div>
    </div>
  );
};

export default BaseInput;
