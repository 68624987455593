import { FETCH_DATA_FOR_MIKATA_ADMIN } from "../constants";

export const mikataAdminReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case FETCH_DATA_FOR_MIKATA_ADMIN:
      return { ...state, adminData: { ...payload.data, ...payload.userRoles } };
    default:
      return state;
  }
};
