import React from "react";

type PropsType = {
  size?: number;
};

const Journey = ({ size = 24 }: PropsType) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={`${size}px`}
      height={`${size}px`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6a1 1 0 0 1 1-1h5a4 4 0 0 1 0 8H8a2 2 0 1 0 0 4h5a1 1 0 1 1 0 2H8a4 4 0 0 1 0-8h8a2 2 0 1 0 0-4h-5a1 1 0 0 1-1-1Z"
        fill="currentColor"
      />
      <circle cx="6" cy="6" r="2" fill="currentColor" />
      <circle cx="18" cy="18" r="2" fill="currentColor" />
    </svg>
  );
};

export default Journey;
