import {
  combine,
  isRequired,
  isMultipleOf,
  isGreaterThan,
  isLessThan,
  isInteger
} from "../../../../../utils/validators";

// Validators
export const reasonLabelValidator = isRequired("Reason name is required");
export const defaultEmrReasonValidator = isRequired("Please select a default EMR reason");
export const availabilityRangeValidator = combine([
  isRequired("Please enter a number of weeks"),
  isGreaterThan(0, "Availability range must be greater than 0"),
  isLessThan(26, "Availability range must be 26 weeks or less"),
  isInteger("Availability range must be a whole number")
]);
export const cardTextValidator = isRequired("Please enter card text");
export const durationValidator = combine([
  isRequired("Please enter a duration"),
  isMultipleOf(5, "Duration must be a multiple of 5")
]);
