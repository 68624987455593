import axios from "axios";

import { getSignedUrl, uploadFiles } from "../lib";
import getToken from "../utils/getToken";

import { addNotification } from "./notifications";

import {
  FETCH_ORGANIZATION_DETAILS,
  UPDATE_ORGANIZATION_DETAILS,
  ROOT_URL,
  UPLOAD_ORGANIZATION_LOGO,
  FETCH_ORGANIZATION_LOGO_URL,
  BucketNameSuffixes,
  FETCH_ORGANIZATION_SETTINGS,
  UPDATE_ORGANIZATION_SETTINGS,
  ADD_ORGANIZATION,
  ADD_SELF_SERVE_ORGANIZATION,
  DELETE_ORGANIZATION,
  CLEAR_ORGANIZATION,
  GET_INTEGRATION_SUMMARY,
  FETCH_DATA_FOR_ORGANIZATION, // DEPRECATED
  VERIFY_INVITE_CODE
} from "../constants";
import {
  OrganizationData,
  ActionStatus,
  Dispatch,
  SignedURLData,
  Setting,
  SyncRecord
} from "../types";

export const clearCurrentOrgData = (isAdmin = false) => {
  if (isAdmin) {
    // Clear admin user filter preferences
    localStorage.removeItem("appointmentFilters_activeCheckinStatus");
    localStorage.removeItem("appointmentFilters_activeStatus");
    localStorage.removeItem("appointmentFilters_locationIds");
    localStorage.removeItem("appointmentFilters_practitionerIds");
    localStorage.removeItem("appointmentFilters_searchValue");
    localStorage.removeItem("appointmentFilters_reasonIds");
    localStorage.removeItem("appointmentFilters_rowsPerPage");
    localStorage.removeItem("appointmentRequestFilters_rowsPerPage");
    localStorage.removeItem("appointmentRequestFilters_assignedStaffUserIds");
    localStorage.removeItem("userPreference_unintegratedCreate_locationId");
    localStorage.removeItem("userPreference_unintegratedCreate_reasonId");
  }
  return {
    type: CLEAR_ORGANIZATION,
    payload: {}
  };
};

const acceptedFileTypes = ["image/jpeg", "image/png", "application/svg", "application/jpg"];

export const validateSize = async (file: File) => {
  const img = new Image();
  const imageLoadPromise = new Promise((resolve) => {
    img.src = URL.createObjectURL(file);
    img.onload = resolve;
  });

  await imageLoadPromise;
  if (img.width <= 1000 && img.height <= 1000) {
    return true;
  }

  return false;
};

export const validateFileType = (file: File): boolean => {
  if (acceptedFileTypes.includes(file.type)) {
    return true;
  }
  return false;
};

export type FetchOrganizationDataAction = {
  type: typeof FETCH_ORGANIZATION_DETAILS;
  status?: ActionStatus;
  payload?: OrganizationData;
};

export const fetchOrganization = (organizationId: string) => async (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  dispatch({
    type: FETCH_ORGANIZATION_DETAILS,
    status: ActionStatus.loading
  });

  try {
    const response = await axios.get(`${ROOT_URL}/organizations/${organizationId}`, config);
    dispatch({
      type: FETCH_ORGANIZATION_DETAILS,
      status: ActionStatus.success,
      payload: response.data
    });

    // DEPRECATED Org Data redux store
    dispatch({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      type: FETCH_DATA_FOR_ORGANIZATION as any,
      payload: response
    });
  } catch (e) {
    dispatch(
      addNotification({
        type: "error",
        title: "Error fetching organization data",
        subtitle: "Please try again",
        autoDismiss: true
      })
    );

    dispatch({
      type: FETCH_ORGANIZATION_DETAILS,
      status: ActionStatus.error
    });
  }
};

export type UpdateOrganizationAction = {
  type: typeof UPDATE_ORGANIZATION_DETAILS;
  status?: ActionStatus;
  payload?: OrganizationData;
};

export type updateOrganizationData = {
  primaryColour?: string;
  secondaryColour?: string;
  emrType?: string;
  integrated?: boolean;
  logo?: string;
  email?: string;
  accuroUUID?: string;
  childrenIds?: Array<number>;
};

type updateOrganizationResponse = {
  status: number;
  data: {
    success: boolean;
    conflictingTriggers: [];
    updatedOrganization?: OrganizationData;
  };
};

export const updateOrganization =
  (organizationId: number, organizationData: updateOrganizationData) =>
  async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: UPDATE_ORGANIZATION_DETAILS,
      status: ActionStatus.loading
    });

    return axios
      .patch(`${ROOT_URL}/organizations/${organizationId}`, organizationData, config)
      .then((payload: updateOrganizationResponse) => {
        if (!payload || !payload.data.success) {
          dispatch(
            addNotification({
              type: "error",
              title: "Failed to update the organization",
              subtitle: "Please try again",
              autoDismiss: true
            })
          );

          return dispatch({
            type: UPDATE_ORGANIZATION_DETAILS,
            status: ActionStatus.error
          });
        }

        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: "Organization saved",
            autoDismiss: true
          })
        );

        dispatch({
          type: UPDATE_ORGANIZATION_DETAILS,
          status: ActionStatus.success,
          payload: payload.data.updatedOrganization
        });

        return fetchOrganization(organizationId.toString())(dispatch);
      })
      .catch((error) => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to update the organization",
            subtitle: error.response.data || "Please try again",
            autoDismiss: true
          })
        );

        return dispatch({
          type: UPDATE_ORGANIZATION_DETAILS,
          status: ActionStatus.error
        });
      });
  };

export type DeleteOrganizationAction = {
  type: typeof DELETE_ORGANIZATION;
  status?: ActionStatus;
  payload?: OrganizationData;
};

type deleteOrganizationResponse = {
  status: number;
  data: {
    success: boolean;
    deletedOrganization?: OrganizationData;
  };
};

export const deleteOrganization =
  (organizationId: number, onSuccess?: () => void) => async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: DELETE_ORGANIZATION,
      status: ActionStatus.loading
    });

    return axios
      .delete(`${ROOT_URL}/organizations/${organizationId}`, config)
      .then((payload: deleteOrganizationResponse) => {
        if (!payload || !payload.data.success) {
          dispatch(
            addNotification({
              type: "error",
              title: "Failed to delete the organization",
              subtitle: "Please try again",
              autoDismiss: true
            })
          );

          return dispatch({
            type: DELETE_ORGANIZATION,
            status: ActionStatus.error
          });
        }

        if (onSuccess) {
          onSuccess();
        }

        return dispatch({
          type: DELETE_ORGANIZATION,
          status: ActionStatus.success,
          payload: payload.data.deletedOrganization
        });
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to delete the organization",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );

        return dispatch({
          type: DELETE_ORGANIZATION,
          status: ActionStatus.error
        });
      });
  };

export type UploadFileData = {
  organizationId: number;
  file: File;
  organizationDisplayName: string;
  onSuccess?: (fileName: string) => void;
};

export type UploadLogoAction = {
  type: typeof UPLOAD_ORGANIZATION_LOGO;
  status?: ActionStatus;
  payload?: SignedURLData;
};

export const uploadLogo = (logoFileData: UploadFileData) => async (dispatch: Dispatch) => {
  const token = getToken();
  dispatch({
    type: UPLOAD_ORGANIZATION_LOGO,
    status: ActionStatus.loading
  });

  // Try to get signed URL
  try {
    const validFileSize = await validateSize(logoFileData.file);

    if (!validFileSize) {
      dispatch(
        addNotification({
          type: "error",
          title: "Logo should not be larger than 1000 x 1000 px",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );

      return dispatch({
        type: UPLOAD_ORGANIZATION_LOGO,
        status: ActionStatus.error
      });
    }

    if (!validateFileType(logoFileData.file)) {
      dispatch(
        addNotification({
          type: "error",
          title: "Logo should be in png, svg, or jpeg/jpg format",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );

      return dispatch({
        type: UPLOAD_ORGANIZATION_LOGO,
        status: ActionStatus.error
      });
    }

    const response = await getSignedUrl({
      bucketName: BucketNameSuffixes.ORG_LOGO_UPLOADS,
      fileExt: logoFileData.file.type.split("/")[1],
      fileNamePrefix: `${logoFileData.organizationDisplayName}-logo`,
      token: token || undefined
    });

    const respMod = { ...response, file: logoFileData.file };

    // Try to Upload
    try {
      const uploadResponse = await uploadFiles(respMod.url, respMod.file);

      if (uploadResponse.status === 200) {
        if (logoFileData.onSuccess) {
          logoFileData.onSuccess(respMod.filename);
        }
        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: "Organization logo uploaded",
            autoDismiss: true
          })
        );

        return dispatch({
          type: UPLOAD_ORGANIZATION_LOGO,
          status: ActionStatus.success,
          payload: respMod
        });
      }
      return null;
    } catch (error) {
      dispatch(
        addNotification({
          type: "error",
          title: "Failed to upload organization logo",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );

      return dispatch({
        type: UPLOAD_ORGANIZATION_LOGO,
        status: ActionStatus.error
      });
    }
  } catch (error) {
    dispatch(
      addNotification({
        type: "error",
        title: "Error uploading organization logo",
        subtitle: "Please try again",
        autoDismiss: true
      })
    );

    return dispatch({
      type: UPLOAD_ORGANIZATION_LOGO,
      status: ActionStatus.error
    });
  }
};

/**
 * This action will use the `getSignedURl` call to get back the url of the logo object in S3
 */
export type FetchLogoUrlAction = {
  type: typeof FETCH_ORGANIZATION_LOGO_URL;
  status?: ActionStatus;
  payload?: SignedURLData;
};

export const fetchLogo = (fileNameToDownload: string) => async (dispatch: Dispatch) => {
  const token = getToken();
  dispatch({
    type: FETCH_ORGANIZATION_LOGO_URL,
    status: ActionStatus.loading
  });

  try {
    const response = await getSignedUrl({
      bucketName: BucketNameSuffixes.ORG_LOGO_UPLOADS,
      downloadingFilename: fileNameToDownload,
      token: token || undefined
    });

    return dispatch({
      type: FETCH_ORGANIZATION_LOGO_URL,
      status: ActionStatus.success,
      payload: response
    });
  } catch (error) {
    return dispatch({
      type: FETCH_ORGANIZATION_LOGO_URL,
      status: ActionStatus.error
    });
  }
};

export type FetchOrganizationSettingsAction = {
  type: typeof FETCH_ORGANIZATION_SETTINGS;
  status?: ActionStatus;
  payload?: Array<Setting>;
};

export const fetchOrgSettings = (organizationId: string) => async (dispatch: Dispatch) => {
  const token = getToken();
  const config = {
    headers: { Authorization: token, "Content-Type": "application/json" }
  };

  dispatch({
    type: FETCH_ORGANIZATION_SETTINGS,
    status: ActionStatus.loading
  });

  try {
    const response = await axios.get(
      `${ROOT_URL}/organizations/${organizationId}/settings`,
      config
    );
    dispatch({
      type: FETCH_ORGANIZATION_SETTINGS,
      status: ActionStatus.success,
      payload: response.data
    });
  } catch (e) {
    dispatch(
      addNotification({
        type: "error",
        title: "Error fetching organization settings",
        subtitle: "Please try again",
        autoDismiss: true
      })
    );

    dispatch({
      type: FETCH_ORGANIZATION_SETTINGS,
      status: ActionStatus.error
    });
  }
};

export type UpdateOrganizationSettingsAction = {
  type: typeof UPDATE_ORGANIZATION_SETTINGS;
  status?: ActionStatus;
  payload?: Array<Setting>;
};

type updateOrganizationSettingsResponse = {
  status: number;
  data: Array<Setting>;
};

export type UpdateOrganizationSettingsOptions = {
  onSuccess?: () => void;
};

export const updateOrgSettings =
  (
    organizationId: string,
    settings: Array<Setting>,
    options: UpdateOrganizationSettingsOptions = {}
  ) =>
  async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: UPDATE_ORGANIZATION_SETTINGS,
      status: ActionStatus.loading
    });

    return axios
      .put(`${ROOT_URL}/organizations/${organizationId}/settings/`, settings, config)
      .then((payload: updateOrganizationSettingsResponse) => {
        if (!payload || !payload.data) {
          dispatch(
            addNotification({
              type: "error",
              title: "Failed to update the organization settings",
              subtitle: "Please try again",
              autoDismiss: true
            })
          );

          return dispatch({
            type: UPDATE_ORGANIZATION_SETTINGS,
            status: ActionStatus.error
          });
        }

        if (options?.onSuccess) {
          options?.onSuccess();
        }

        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: "Organization settings saved",
            autoDismiss: true
          })
        );

        return dispatch({
          type: UPDATE_ORGANIZATION_SETTINGS,
          status: ActionStatus.success,
          payload: payload.data
        });
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to update the organization settings",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );

        return dispatch({
          type: UPDATE_ORGANIZATION_SETTINGS,
          status: ActionStatus.error
        });
      });
  };

export type AddOrganizationAction = {
  type: typeof ADD_ORGANIZATION;
  status?: ActionStatus;
  payload?: Array<Setting>;
};

export type AddOrganizationData = {
  organizationAddress: string;
  organizationCity: string;
  organizationCountry: string;
  organizationEmailAddress: string;
  organizationName: string;
  organizationPhoneNumber: string;
  organizationPostalCode: string;
  organizationState: string;
  organizationSuite: string;
  organizationTimezone: string;
  organizationType: string | null;
};

type AddOrganizationResponse = { data?: { id: number } };

export const addOrganization =
  (data: AddOrganizationData, onSuccess?: (orgId: number) => void) =>
  async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: token, "Content-Type": "application/json" }
    };

    dispatch({
      type: UPDATE_ORGANIZATION_SETTINGS,
      status: ActionStatus.loading
    });

    return axios
      .post(`${ROOT_URL}/organizations`, data, config)
      .then((payload: AddOrganizationResponse) => {
        if (!payload || !payload.data) {
          dispatch(
            addNotification({
              type: "error",
              title: "Failed to add organization",
              subtitle: "Please try again",
              autoDismiss: true
            })
          );

          return dispatch({
            type: ADD_ORGANIZATION,
            status: ActionStatus.error
          });
        }

        if (onSuccess) {
          onSuccess(payload.data.id);
        }

        dispatch(
          addNotification({
            type: "success",
            title: "Success",
            subtitle: "Organization created",
            autoDismiss: true
          })
        );

        return dispatch({
          type: ADD_ORGANIZATION,
          status: ActionStatus.success
        });
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to add organization",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );

        return dispatch({
          type: ADD_ORGANIZATION,
          status: ActionStatus.error
        });
      });
  };

export type AddSelfServeOrganizationAction = {
  type: typeof ADD_SELF_SERVE_ORGANIZATION;
  status?: ActionStatus;
  payload?: Array<Setting>;
};

export type AddSelfServeOrganizationData = {
  inviteCode: string;
  firstName: string;
  lastName: string;
  organizationName?: string;
  organizationPhoneNumber?: string;
  organizationEmailAddress: string;
  organizationSpecialty: string;
  organizationAddress?: string;
  organizationSuite?: string;
  organizationCity: string;
  organizationProvince?: string;
  organizationCountry: string;
  organizationTimezone?: string;
  organizationEmrType?: string;
};

type AddSelfServeOrganizationResponse = {
  data?: { success: boolean; error: string; organization: { id: number } };
};

export const addSelfServeOrganization =
  (data: AddSelfServeOrganizationData, onSuccess?: (orgId: number) => void) =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: { "Content-Type": "application/json" }
    };

    dispatch({
      type: ADD_SELF_SERVE_ORGANIZATION,
      status: ActionStatus.loading
    });

    return axios
      .post(`${ROOT_URL}/organizations/self-serve`, data, config)
      .then((payload: AddSelfServeOrganizationResponse) => {
        if (!payload || !payload.data || !payload.data.success) {
          return dispatch({
            type: ADD_SELF_SERVE_ORGANIZATION,
            status: ActionStatus.error
          });
        }

        if (onSuccess) {
          onSuccess(payload.data.organization.id);
        }

        return dispatch({
          type: ADD_SELF_SERVE_ORGANIZATION,
          status: ActionStatus.success
        });
      })
      .catch(() => {
        return dispatch({
          type: ADD_SELF_SERVE_ORGANIZATION,
          status: ActionStatus.error
        });
      });
  };

export type GetIntegrationSummaryAction = {
  type: typeof GET_INTEGRATION_SUMMARY;
  status?: ActionStatus;
  payload?: {
    bulkSyncs: Array<SyncRecord>;
    incrementalSyncs: Array<SyncRecord>;
  };
};

export type GetIntegrationSummaryOptions = {
  silent?: boolean;
};

export const getIntegrationSummary =
  (organizationId: string, options?: GetIntegrationSummaryOptions) =>
  async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: `${token}`, "Content-Type": "application/json" }
    };

    const isSilent = options?.silent;

    dispatch({
      type: GET_INTEGRATION_SUMMARY,
      status: isSilent ? ActionStatus.silentLoading : ActionStatus.loading
    });

    return axios
      .get(`${ROOT_URL}/organizations/${organizationId}/integration`, config)
      .then((response) => {
        return dispatch({
          type: GET_INTEGRATION_SUMMARY,
          status: ActionStatus.success,
          payload: response.data
        });
      })
      .catch((err) => {
        dispatch(
          addNotification({
            type: "error",
            title: "Failed to retrieve sync records.",
            subtitle: "Please try again",
            autoDismiss: true
          })
        );
      });
  };

export type StartSyncOptions = {
  sample?: boolean;
  fetchAllAppts?: boolean;
  manualBulkSync?: boolean;
};

export const startSync =
  (organizationId: string, options: StartSyncOptions) => async (dispatch: Dispatch) => {
    const token = getToken();
    const config = {
      headers: { Authorization: `${token}`, "Content-Type": "application/json" }
    };
    try {
      await axios.post(
        `${ROOT_URL}/organizations/${organizationId}/sample-sync`,
        { ...options, organizationId },
        config
      );

      dispatch(
        addNotification({
          type: "success",
          title: "Success",
          subtitle: "Success. The sync has started.",
          autoDismiss: true
        })
      );
      getIntegrationSummary(organizationId, { silent: true })(dispatch);
    } catch (e) {
      dispatch(
        addNotification({
          type: "error",
          title: "Failed to initialize sync",
          subtitle: "Please try again",
          autoDismiss: true
        })
      );
    }
  };

export type VerifyInviteCodeAction = {
  type: typeof VERIFY_INVITE_CODE;
  status?: ActionStatus;
  payload?: {
    success: boolean;
    verified: boolean;
  };
};

export type VerifyInviteCodeData = {
  inviteCode: string;
};

export const verifyInviteCode = (data: VerifyInviteCodeData) => async (dispatch: Dispatch) => {
  const config = {
    headers: { "Content-Type": "application/json" }
  };
  dispatch({
    type: VERIFY_INVITE_CODE,
    status: ActionStatus.loading
  });

  return axios
    .post(`${ROOT_URL}/organizations/verify-invite-code`, { ...data, appSource: "clinic" }, config)
    .then((payload) => {
      if (!payload || !payload.data.success) {
        dispatch({
          type: VERIFY_INVITE_CODE,
          status: ActionStatus.error
        });

        return dispatch(
          addNotification({
            type: "error",
            title: `Failed to verify invite code.`,
            subtitle: "Please try again",
            autoDismiss: false
          })
        );
      }
      if (payload.data.success) {
        dispatch({
          type: VERIFY_INVITE_CODE,
          status: ActionStatus.success,
          payload: payload.data
        });
      }
    })
    .catch(() => {
      dispatch(
        addNotification({
          type: "error",
          title: `Failed to verify invite code.`,
          subtitle: "Please try again",
          autoDismiss: false
        })
      );

      dispatch({
        type: VERIFY_INVITE_CODE,
        status: ActionStatus.error
      });
    });
};
