import React from "react";
import { connect } from "react-redux";

import {
  getTask as getTaskAction,
  updateAppointmentRequest as updateAppointmentRequestAction,
  UpdateAppointmentRequestData
} from "../../../../../actions";
import {
  AppointmentRequestsUpdatesLoading,
  Task,
  TaskAppointmentRequestContext,
  ReduxStateType
} from "../../../../../types";
import {
  appointmentRequestStatusConfigMap,
  TaskTypes,
  apptRequestStatusOptions
} from "../../../../../constants";

import Dropdown from "../../../../ui/Dropdown";
import Heading from "../../../../ui/Heading";

import AppointmentRequestTask from "./AppointmentRequestTask";
import DirectMessageTask from "./DirectMessageTask";
import InboxDetailsWrapper from "../InboxDetailsWrapper";

import styles from "./InboxDetailsSwitch.module.scss";

type PropsType = {
  selectedTask?: Task;
  setSelectedTask: (task?: Task) => void;
  detailsOpen: boolean;
  closeDrawer: () => void;
  preferQuickView: boolean;
  setPreferQuickView: (preferQuickView: boolean) => void;
  getTask: (
    taskId: number,
    onSuccess?: (task: Task) => void,
    options?: { silent: boolean }
  ) => void;
  updateAppointmentRequest: (
    id: number,
    update: UpdateAppointmentRequestData,
    onSuccess?: () => void
  ) => void;
  updatesLoading?: AppointmentRequestsUpdatesLoading;
  onAssign: (taskIds: number[], userId: number | null) => void;
  loadingForAssignUser: { [id: number]: boolean };
  refetchTasks: (silent: boolean, fetchingForAssignedUser?: boolean) => void;
};

const InboxDetailsSwitch = ({
  selectedTask,
  setSelectedTask,
  detailsOpen,
  preferQuickView,
  setPreferQuickView,
  closeDrawer,
  getTask,
  updateAppointmentRequest,
  updatesLoading,
  onAssign,
  loadingForAssignUser,
  refetchTasks
}: PropsType) => {
  const getCustomHeaderContent = (task: Task | undefined) => {
    if (!task) {
      return null;
    }

    switch (task.type) {
      case TaskTypes.APPOINTMENT_REQUEST:
        return (
          <>
            <Heading className={styles.TaskStageHeading} size="META">
              STAGE
            </Heading>
            <Dropdown
              id="taskStage"
              className={styles.TaskStageDropdown}
              onChange={(selectedTaskStage) => {
                if (task.appointmentRequestId) {
                  updateAppointmentRequest(
                    task.appointmentRequestId,
                    {
                      status: selectedTaskStage
                    },
                    () => {
                      if (selectedTask?.id) {
                        getTask(
                          selectedTask.id,
                          (task?: Task) => {
                            setSelectedTask(task);
                          },
                          { silent: true }
                        );
                      }
                      refetchTasks(true);
                    }
                  );
                }
              }}
              value={
                task.filterContext &&
                (task.filterContext as TaskAppointmentRequestContext).appointmentRequestStatus
                  ? (task.filterContext as TaskAppointmentRequestContext).appointmentRequestStatus
                  : "None"
              }
              options={apptRequestStatusOptions}
              configMap={appointmentRequestStatusConfigMap}
              loading={
                updatesLoading && task.appointmentRequestId
                  ? updatesLoading[task.appointmentRequestId]?.status
                  : undefined
              }
            />
          </>
        );
      default:
        return null;
    }
  };

  const getTaskContent = (task: Task | undefined) => {
    if (!task) {
      return null;
    }

    switch (task.type) {
      case TaskTypes.APPOINTMENT_REQUEST:
        return (
          <AppointmentRequestTask
            task={task}
            preferQuickView={preferQuickView}
            closeDrawer={closeDrawer}
            detailsOpen={detailsOpen}
          />
        );
      case TaskTypes.DIRECT_MESSAGE_CONVERSATION:
        return <DirectMessageTask task={task} />;
      default:
        return null;
    }
  };

  return (
    <InboxDetailsWrapper
      customHeaderContent={getCustomHeaderContent(selectedTask)}
      isOpen={detailsOpen}
      closeDrawer={closeDrawer}
      preferQuickView={preferQuickView}
      setPreferQuickView={setPreferQuickView}
      selectedTask={selectedTask}
      onAssign={onAssign}
      loadingForAssignUser={loadingForAssignUser}
    >
      {getTaskContent(selectedTask)}
    </InboxDetailsWrapper>
  );
};

const mapStateToProps = ({ appointmentRequestsInfo }: ReduxStateType) => {
  return {
    updatesLoading: appointmentRequestsInfo.updatesLoading
  };
};

export default connect(mapStateToProps, {
  getTask: getTaskAction,
  updateAppointmentRequest: updateAppointmentRequestAction
})(InboxDetailsSwitch);
