import {
  SERVICES_ADD,
  SERVICES_FETCH,
  SERVICES_FIND_NUMBERS,
  SERVICES_CLEAR_NUMBER_FIND,
  SERVICES_BUY_NUMBERS,
  SERVICES_DELETE,
  SERVICES_UPDATE
} from "../constants";

import {
  FetchServicesDataAction,
  AddServiceAction,
  FindPhoneNumbersAction,
  BuyPhoneNumbersAction,
  ClearNumbersFindResultAction,
  DeleteServiceAction,
  UpdateServiceAction
} from "../actions/services";

import { Service, ActionStatus } from "../types";

export type ServicesAction =
  | FetchServicesDataAction
  | AddServiceAction
  | FindPhoneNumbersAction
  | BuyPhoneNumbersAction
  | ClearNumbersFindResultAction
  | DeleteServiceAction
  | UpdateServiceAction;

export type ServicesReduxState = {
  services: Array<Service>;
  findResult: Array<string> | undefined;
  servicesFetchLoading: boolean;
  servicesAddLoading: boolean;
  servicesFindNumbersLoading: boolean;
  servicesBuyNumbersLoading: boolean;
  servicesDeleteLoading: boolean;
  servicesUpdateLoading: boolean;
};

const initialServicesState: ServicesReduxState = {
  services: [],
  findResult: undefined,
  servicesFetchLoading: false,
  servicesAddLoading: false,
  servicesFindNumbersLoading: false,
  servicesBuyNumbersLoading: false,
  servicesDeleteLoading: false,
  servicesUpdateLoading: false
};

export const servicesReducer = (
  state = initialServicesState,
  action: ServicesAction
): ServicesReduxState => {
  const { type } = action;

  switch (type) {
    case SERVICES_FETCH: {
      const { payload, status } = action as FetchServicesDataAction;
      return {
        ...state,
        services: payload ? payload.data : [],
        servicesFetchLoading: status === ActionStatus.loading
      };
    }
    case SERVICES_ADD: {
      const { status } = action as AddServiceAction;
      return {
        ...state,
        servicesAddLoading: status === ActionStatus.loading
      };
    }
    case SERVICES_FIND_NUMBERS: {
      const { payload, status } = action as FindPhoneNumbersAction;
      return {
        ...state,
        findResult: payload ? payload.data : [],
        servicesFindNumbersLoading: status === ActionStatus.loading
      };
    }
    case SERVICES_CLEAR_NUMBER_FIND: {
      return {
        ...state,
        findResult: undefined
      };
    }
    case SERVICES_BUY_NUMBERS: {
      const { status } = action as BuyPhoneNumbersAction;
      return {
        ...state,
        servicesBuyNumbersLoading: status === ActionStatus.loading,
        findResult: status === ActionStatus.success ? undefined : state.findResult
      };
    }
    case SERVICES_DELETE: {
      const { status } = action as DeleteServiceAction;
      return {
        ...state,
        servicesDeleteLoading: status === ActionStatus.loading,
        findResult: status === ActionStatus.success ? undefined : state.findResult
      };
    }
    case SERVICES_UPDATE: {
      const { status } = action as UpdateServiceAction;
      return {
        ...state,
        servicesUpdateLoading: status === ActionStatus.loading
      };
    }

    default:
      return state;
  }
};
