import React, { useMemo, useContext, useState, useEffect } from "react";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";

import Heading from "../../../ui/Heading";
import { FloatModal } from "../../../ui/Modal";
import { Form, ToggleInput } from "../../../ui/Input";
import FormContent from "./FormContent";

import { addNotification, AddNotificationAction, closeModal } from "../../../../actions";
import { resetUserPassword, updateUser } from "../../../../lib";
import { UserRoleConstants } from "../../../../constants";

import { InputValue, NewNotification, ReduxStateType, User, UserRole } from "../../../../types";
import { UserContext } from "../../../providers/UserProvider";

import styles from "./index.module.scss";

type PropsType = {
  roles: Array<UserRole>;
  userInfo: User;
  onSuccess: () => void;
  closeModalConnect: () => void;
  addNotif: (notification: NewNotification) => AddNotificationAction;
};

type FormState = {
  firstName: string;
  lastName: string;
  email: string | null;
  roleId: number | undefined;
};

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  roleId: undefined
};

const EditAdminUser = ({ roles, userInfo, onSuccess, closeModalConnect, addNotif }: PropsType) => {
  const { userId, roleId: editorRoleId } = useContext(UserContext);
  const formDisabled = userInfo.id === userId;

  const { firstName, lastName, email, roleId, active } = userInfo;

  const [roleOptions, setRoleOptions] = useState<{ label: string; value: number }[]>([]);

  const canSeeRole = (editorRole: UserRole | undefined, possibleRole: UserRole): boolean => {
    if (!editorRole || !editorRole.rank) return false;
    if (!possibleRole || !possibleRole.rank) return false;
    return editorRole.rank >= possibleRole.rank;
  };

  useEffect(() => {
    const options = [];
    const editorRole = roles.find((role) => role.id === editorRoleId);

    const adminRole = roles.find((role) => role.title === UserRoleConstants.MIKATA_ADMIN);
    if (adminRole && adminRole.id && canSeeRole(editorRole, adminRole)) {
      options.push({ label: UserRoleConstants.MIKATA_ADMIN, value: adminRole.id });
    }
    const superAdminRole = roles.find(
      (role) => role.title === UserRoleConstants.MIKATA_SUPER_ADMIN
    );
    if (superAdminRole && superAdminRole.id && canSeeRole(editorRole, superAdminRole)) {
      options.push({ label: UserRoleConstants.MIKATA_SUPER_ADMIN, value: superAdminRole.id });
    }
    const billingRole = roles.find((role) => role.title === UserRoleConstants.MIKATA_BILLING_ADMIN);
    if (billingRole && billingRole.id && canSeeRole(editorRole, billingRole)) {
      options.push({ label: UserRoleConstants.MIKATA_BILLING_ADMIN, value: billingRole.id });
    }

    setRoleOptions(options);
  }, [roles, editorRoleId]);

  const initialFormState = useMemo(() => {
    const newFormState: FormState = cloneDeep(initialState);

    newFormState.firstName = firstName || "";
    newFormState.lastName = lastName || "";
    newFormState.email = email || "";
    newFormState.roleId = roleId;

    return newFormState;
  }, [firstName, lastName, email, roleId]);

  const handleClose = () => {
    if (onSuccess) onSuccess();
    closeModalConnect();
  };

  const save = async (formValues: FormState) => {
    const data = {
      userUpdateData: {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        email: formValues.email,
        roleId: formValues.roleId
      }
    };

    const response = await updateUser(userInfo.id, data);

    if (response.success) {
      addNotif({
        type: "success",
        title: "Success",
        subtitle: "User updates saved",
        autoDismiss: true
      });
      if (onSuccess) onSuccess();
      closeModalConnect();
    } else {
      addNotif({
        type: "error",
        title: "Failed to update the user",
        subtitle: "Please try again",
        autoDismiss: true
      });
    }
  };

  const resetPassword = async () => {
    const response = await resetUserPassword(userInfo.id);

    if (response.success) {
      addNotif({
        type: "success",
        title: "Success",
        subtitle: `We sent an email to ${firstName} (${email}) inviting them to reset their password.`,
        autoDismiss: true
      });
    } else {
      addNotif({
        type: "error",
        title: "Password reset failed",
        subtitle: "Please try again",
        autoDismiss: true
      });
    }
  };

  const toggleUserActive = async (params: {
    active: boolean;
    status: string;
    deactivatedAt?: string | null;
  }) => {
    // If we're reactivating a user we'll also invite them to reset their password
    if (params.active) {
      await resetPassword();
    }

    const response = await updateUser(userInfo.id, { userUpdateData: params });

    if (response.success) {
      addNotif({
        type: "success",
        title: "Success",
        subtitle: `The user has been ${params.active ? "reactivated" : "deactivated"}`,
        autoDismiss: true
      });
    } else {
      addNotif({
        type: "error",
        title: `Failed to ${params.active ? "reactivate" : "deactivate"} the user`,
        subtitle: "Please try again",
        autoDismiss: true
      });
    }
  };

  const userActiveHandler = (event: { [fieldName: string]: InputValue }) => {
    if (event.active) {
      // Reactivate the user
      toggleUserActive({
        active: event.active,
        status: "awaiting password reset",
        deactivatedAt: null
      });
    } else {
      // Deactivate the user
      toggleUserActive({ active: event.active, status: "deactivated" });
    }
  };

  return (
    <FloatModal isOpen onClose={handleClose}>
      <Heading size="L" className={styles.EditStaffUser}>
        Edit User
      </Heading>

      <Form initialValues={{ active }} className={styles.ActiveToggle}>
        <ToggleInput
          fieldName="active"
          labelChecked="Active"
          labelUnchecked="Inactive"
          disabled={formDisabled}
          customOnChange={userActiveHandler}
        />
      </Form>

      <Form
        onSubmit={(formState) => save(formState.values as FormState)}
        initialValues={initialFormState}
      >
        <FormContent
          formDisabled={formDisabled}
          roleOptions={roleOptions}
          active={active}
          resetPassword={resetPassword}
          initialFormState={initialFormState}
          closeModalConnect={closeModalConnect}
        />
      </Form>
    </FloatModal>
  );
};

const mapStateToProps = ({ users }: ReduxStateType) => {
  return {
    roles: users.roles
  };
};

export default connect(mapStateToProps, {
  closeModalConnect: closeModal,
  addNotif: addNotification
})(EditAdminUser);
