import {
  ADD_FEEDBACK,
  QUEUE_NOTE_FOR_FEEDBACK,
  SET_FEEDBACK_MODE,
  CLEAR_NOTE_FROM_QUEUE
} from "../constants";

import {
  AddFeedbackAction,
  QueueNoteForFeedbackAction,
  SetFeedbackModeAction,
  ClearNoteFromQueueAction
} from "../actions";
import { FeedbackFormModes } from "../typedConstants";

export type FeedbackAction =
  | AddFeedbackAction
  | SetFeedbackModeAction
  | QueueNoteForFeedbackAction
  | ClearNoteFromQueueAction;

export type FeedbackReduxState = {
  feedbackLoading: boolean;
  feedbackMode: FeedbackFormModes | null;
  notesInQueue: number[];
};

const initialFeedbackState: FeedbackReduxState = {
  feedbackLoading: false,
  feedbackMode: null,
  notesInQueue: []
};

export const feedbackReducer = (
  state = initialFeedbackState,
  action: FeedbackAction
): FeedbackReduxState => {
  switch (action.type) {
    case ADD_FEEDBACK: {
      const { status } = action as AddFeedbackAction;
      return {
        ...state,
        feedbackLoading: status === "loading"
      };
    }
    case SET_FEEDBACK_MODE: {
      const { payload } = action as SetFeedbackModeAction;

      return {
        ...state,
        feedbackMode: payload || null
      };
    }
    case QUEUE_NOTE_FOR_FEEDBACK: {
      const { payload } = action as QueueNoteForFeedbackAction;

      return {
        ...state,
        notesInQueue: [...state.notesInQueue, payload.noteId]
      };
    }
    case CLEAR_NOTE_FROM_QUEUE: {
      const { payload } = action as ClearNoteFromQueueAction;

      return {
        ...state,
        notesInQueue: state.notesInQueue.filter((noteId) => noteId !== payload.noteId)
      };
    }
    default:
      return state;
  }
};
