import axios from "axios";

import { addNotification } from "./notifications";
import getToken from "../utils/getToken";

import {
  ANALYTICS_BOOKING_MESSAGES,
  ANALYTICS_MULTI_BOOKING_MESSAGES,
  ANALYTICS_CANCEL_MESSAGES,
  ANALYTICS_MULTI_CANCEL_MESSAGES,
  ANALYTICS_PATIENT_CONFIRMATION_MESSAGES,
  ANALYTICS_PATIENT_RESCHEDULE_MESSAGES,
  ANALYTICS_REQUESTS_REASONS_COUNT,
  ANALYTICS_REQUESTS_REASONS_COUNT_FILTER,
  ANALYTICS_REQUESTS_COUNT,
  ANALYTICS_REQUISITIONS_COUNT,
  ANALYTICS_AVERAGE_PROCESSING_TIME,
  ANALYTICS_SET_PROCESSING_START_TIME,
  ANALYTICS_PROCESSING_TIME_BY_REASON,
  ANALYTICS_QUEUE_TIME,
  ANALYTICS_CHAT_COMPLETION_TIME,
  ANALYTICS_CHAT_DROPOFF,
  ROOT_URL
} from "../constants";
import { ActionStatus, Dispatch } from "../types";

const functionActionMap: { [key: string]: string } = {
  analyticsBookingMessages: ANALYTICS_BOOKING_MESSAGES,
  analyticsMultiBookingMessages: ANALYTICS_MULTI_BOOKING_MESSAGES,
  analyticsCancelMessages: ANALYTICS_CANCEL_MESSAGES,
  analyticsMultiCancelMessages: ANALYTICS_MULTI_CANCEL_MESSAGES,
  analyticsPatientConfirmationMessages: ANALYTICS_PATIENT_CONFIRMATION_MESSAGES,
  analyticsPatientRescheduleMessages: ANALYTICS_PATIENT_RESCHEDULE_MESSAGES,
  analyticsRequestsReasonsCount: ANALYTICS_REQUESTS_REASONS_COUNT,
  analyticsRequestsCount: ANALYTICS_REQUESTS_COUNT,
  analyticsRequisitionCount: ANALYTICS_REQUISITIONS_COUNT,
  analyticsAverageProcessingTime: ANALYTICS_AVERAGE_PROCESSING_TIME,
  analyticsProcessingTimeByReason: ANALYTICS_PROCESSING_TIME_BY_REASON,
  analyticsQueueTime: ANALYTICS_QUEUE_TIME,
  analyticsChatCompletionTime: ANALYTICS_CHAT_COMPLETION_TIME,
  analyticsChatDropOff: ANALYTICS_CHAT_DROPOFF
};

export type FetchAnalyticsAction = {
  type: string;
  status: ActionStatus;
  payload?:
    | {
        data: number;
      }
    | {
        data: { [key: string]: number }[];
      };
};

export const fetchAnalytics =
  async (
    functionName: string,
    startDate: string,
    endDate: string,
    filter: string,
    clockStartTime: string,
    clockEndTime: string
  ) =>
  async (dispatch: Dispatch) => {
    const config = {
      headers: { Authorization: `${getToken()}`, "Content-Type": "application/json" }
    };

    const handleError = () => {
      addNotification({
        type: "error",
        title: "Error fetching analytics",
        subtitle: "Please try again",
        autoDismiss: true
      });
    };

    let queryParams = `functionName=${functionName}&startDate=${startDate}&endDate=${endDate}`;
    if (filter) queryParams = `${queryParams}&filter=${filter}`;
    if (clockStartTime) queryParams = `${queryParams}&clockStartTime=${clockStartTime}`;
    if (clockEndTime) queryParams = `${queryParams}&clockEndTime=${clockEndTime}`;

    await dispatch({
      type: functionActionMap[functionName],
      status: ActionStatus.loading
    });

    try {
      const requestResponse = await axios.get(
        `${ROOT_URL}/analytics/on-demand-analytics?${queryParams}`,
        config
      );

      return dispatch({
        type: functionActionMap[functionName],
        status: ActionStatus.success,
        payload: JSON.parse(requestResponse.data)
      });
    } catch (error) {
      return handleError();
    }
  };

export type SetRequestsReasonsCountFilterAction = {
  type: string;
  status: ActionStatus;
  payload?: {
    data: string;
  };
};
export const setRequestsReasonsCountFilter = (filter: string) => async (dispatch: Dispatch) => {
  return dispatch({
    type: ANALYTICS_REQUESTS_REASONS_COUNT_FILTER,
    status: ActionStatus.success,
    payload: { data: filter }
  });
};

export const setProcessingStartTime = (startTime: string) => async (dispatch: Dispatch) => {
  return dispatch({
    type: ANALYTICS_SET_PROCESSING_START_TIME,
    status: ActionStatus.success,
    payload: { data: startTime }
  });
};
