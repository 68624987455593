import React, { Dispatch, SetStateAction, useMemo } from "react";

import { TextAreaInput, TextInput } from "../../../../../../../../ui/Input";

import BaseChatNodeForm, {
  BaseFormValues,
  PhotoUploadFormValues,
  FormValues
} from "../../BaseChatNodeForm";

import { isRequired } from "../../../../../../../../../utils/validators";

import { ChatFlowsNodes } from "../../../../../../../../../types";

type PhotoUploadPayloadOptions = null | {
  uploadText?: string;
};

export type PhotoUploadChatFlowsNode = ChatFlowsNodes & {
  payloadOptions: PhotoUploadPayloadOptions;
};

type PropsType = {
  chatId: string | undefined;
  node: PhotoUploadChatFlowsNode;
  viewOnly: boolean;
  setSelectedNodeId: Dispatch<SetStateAction<number | null>>;
};
const uploadTextValidator = isRequired("Required field");

const onSaveCustomTransformer = (formValues: FormValues) => {
  const values = formValues as BaseFormValues & PhotoUploadFormValues;
  return {
    payloadContent: values.payloadContent,
    payloadOptions: { uploadText: values.uploadText || "" }
  };
};

const PhotoUploadCard = ({ chatId, node, viewOnly, setSelectedNodeId }: PropsType) => {
  const initialFormValues: PhotoUploadFormValues = useMemo(() => {
    return {
      payloadContent: node?.payloadContent || "",
      payloadOptions: node?.payloadOptions || null,
      uploadText: node?.payloadOptions?.uploadText || ""
    };
  }, [node.id]);

  return (
    <BaseChatNodeForm
      chatId={chatId}
      initialValues={initialFormValues}
      onSaveCustomTransformer={onSaveCustomTransformer}
      node={node}
      key={node.id}
      isInput
      viewOnly={viewOnly}
      setSelectedNodeId={setSelectedNodeId}
    >
      <TextAreaInput
        id="chatNodePayloadContent"
        size="S"
        fieldName="payloadContent"
        label="Mikata Message"
        disabled={viewOnly}
      />
      <TextInput
        fieldName="uploadText"
        label="Upload Text"
        disabled={viewOnly}
        validate={uploadTextValidator}
      />
    </BaseChatNodeForm>
  );
};

export default PhotoUploadCard;
