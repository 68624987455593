import React from "react";
import isEqual from "lodash/isEqual";

import { useFormState } from "informed";
import Button from "../../../ui/Button";
import { TextInput, RadioGroup } from "../../../ui/Input";

import { isRequired } from "../../../../utils/validators";

import styles from "./index.module.scss";
import { Option } from "../../../../types";

type FormState = {
  firstName: string;
  lastName: string;
  email: string | null;
  roleId: number | undefined;
};

type PropsType = {
  formDisabled: boolean;
  roleOptions: Option[];
  active: boolean;
  resetPassword: () => void;
  initialFormState: FormState;
  closeModalConnect: () => void;
};

const firstNameValidator = isRequired("Please enter a first name");
const lastNameValidator = isRequired("Please enter a last name");

const FormContent = ({
  formDisabled,
  roleOptions,
  active,
  resetPassword,
  initialFormState,
  closeModalConnect
}: PropsType) => {
  const formState = useFormState();
  const values = formState.values as FormState;
  const hasFormChanges = !isEqual({ ...values }, initialFormState);

  return (
    <div>
      <TextInput
        fieldName="firstName"
        label="first name"
        placeholder="Enter first name"
        disabled={formDisabled}
        validate={firstNameValidator}
      />
      <TextInput
        fieldName="lastName"
        label="last name"
        placeholder="Enter last name"
        disabled={formDisabled}
        validate={lastNameValidator}
      />
      <TextInput fieldName="email" label="email" placeholder="Enter email" disabled />
      <RadioGroup
        fieldName="roleId"
        label="choose role"
        disabled={formDisabled}
        options={roleOptions}
      />

      <hr className={styles.Divider} />

      <Button secondary disabled={!active} onClick={resetPassword} className={styles.ResetPassword}>
        Reset Password
      </Button>

      <div className={styles.ButtonGroup}>
        <Button inline onClick={closeModalConnect}>
          Cancel
        </Button>
        <Button type="submit" disabled={!hasFormChanges}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default FormContent;
