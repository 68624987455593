import React from "react";

type PropsType = {
  size?: number;
};

const Template = ({ size = 20 }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V9C21 10.1046 20.1046 11 19 11H5C3.89543 11 3 10.1046 3 9V5ZM19 5H5V9H19V5Z"
        fill="#1D2029"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 15C3 13.8954 3.89543 13 5 13H9C10.1046 13 11 13.8954 11 15V19C11 20.1046 10.1046 21 9 21H5C3.89543 21 3 20.1046 3 19V15ZM9 15H5V19H9V15Z"
        fill="#1D2029"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 15C13 14.4477 13.4477 14 14 14L20 14C20.5523 14 21 14.4477 21 15C21 15.5523 20.5523 16 20 16L14 16C13.4477 16 13 15.5523 13 15Z"
        fill="#1D2029"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 19C13 18.4477 13.4477 18 14 18L20 18C20.5523 18 21 18.4477 21 19C21 19.5523 20.5523 20 20 20L14 20C13.4477 20 13 19.5523 13 19Z"
        fill="#1D2029"
      />
    </svg>
  );
};

export default Template;
