import React from "react";

type PropsType = {
  size?: number;
};
const ApptBookWithTime = ({ size = 24 }: PropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill="#494B50"
        fillRule="evenodd"
        d="M8 5a1 1 0 0 1-1-1H5a1 1 0 0 0-1 1v2h16V5a1 1 0 0 0-1-1h-2a1 1 0 1 1-2 0H9a1 1 0 0 1-1 1Zm7-3H9V1a1 1 0 0 0-2 0v1H5a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h6.917c-.276-.463-.468-1-.545-1.6l-.05-.4H5a1 1 0 0 1-1-1V9h16v2.543l2 1.033V5a3 3 0 0 0-3-3h-2V1a1 1 0 1 0-2 0v1Zm.518 17.94a.1.1 0 0 1-.178-.05l-.834-6.506a.1.1 0 0 1 .145-.101l5.828 3.01a.1.1 0 0 1-.014.183l-2.123.701a.1.1 0 0 0-.05.153l1.909 2.726a.1.1 0 0 1-.025.14l-1.065.745a.1.1 0 0 1-.14-.025l-1.908-2.726a.1.1 0 0 0-.16-.005l-1.385 1.755Zm5.879-5.425c1.558.804 1.478 3.002-.015 3.741l.457.653a2.1 2.1 0 0 1-.516 2.924l-1.065.746a2.1 2.1 0 0 1-2.924-.516l-.457-.652c-1.205 1.15-3.298.473-3.52-1.266l-.835-6.506c-.215-1.679 1.542-2.91 3.046-2.133l5.829 3.01Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default ApptBookWithTime;
