import React, { useState } from "react";
import { useField } from "informed";
import cx from "classnames";

import BaseInput from "../BaseInput";
import Text from "../../Text";
import { UploadCloud, CircleCheck, Exclamation } from "../../Icon";

import styles from "./index.module.scss";

import { InputPropsType } from "../../../../types";

type PropsType = InputPropsType & {
  acceptedFileTypes?: string[];
  multiple?: boolean;
};

const UploadInput = ({
  fieldName,
  label,
  placeholder,
  disabled = false,
  validate,
  acceptedFileTypes,
  multiple,
  customOnChange,
  warningMessage = "",
  ...props
}: PropsType) => {
  const [dragHover, setDragHover] = useState(false);
  const { fieldState, fieldApi } = useField({
    ...props,
    name: fieldName,
    validate,
    validateOn: "change"
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { value, error }: { value: any; error: any } = fieldState;
  const { setValue, setTouched, validate: validateInput, setError } = fieldApi;

  const validateFileType = (fileType: string) => {
    return !acceptedFileTypes || (acceptedFileTypes && acceptedFileTypes.includes(fileType));
  };

  const validateFiles = (files: File[]) => {
    const hasValidFileType = files.every((file: File) => validateFileType(file.type));

    if (!hasValidFileType) {
      setError(multiple ? "At least one of the file has invalid file type" : "Invalid file type");
      setValue(multiple ? [] : "");
    }

    if (hasValidFileType) {
      const inputValue = (multiple ? files : files[0]) || "";
      setValue(inputValue);
      setTouched(true);
      validateInput();

      if (customOnChange) {
        customOnChange({ [fieldName]: inputValue });
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeHandler = (event: any): void => {
    const { files } = event.target;

    setError("");

    const selectedFiles: File[] = Array.from(files);
    validateFiles(selectedFiles);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const suppressEvent = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleEnterEvent = (e: any) => {
    suppressEvent(e);
    setDragHover(true);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleLeaveEvent = (e: any) => {
    suppressEvent(e);
    setDragHover(false);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOverEvent = (e: any) => {
    suppressEvent(e);
    setDragHover(true);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDropEvent = (e: any) => {
    suppressEvent(e);
    setDragHover(false);
    setError("");
    const { files } = e.dataTransfer;

    const selectedFiles: File[] = Array.from(files);
    validateFiles(selectedFiles);
  };

  return (
    <BaseInput fieldName={fieldName} warningMessage={warningMessage}>
      <div
        id={`${fieldName}-DropArea`}
        className={cx(styles.DropArea, {
          [styles.DropAreaDragHover]: dragHover,
          [styles.DropAreaError]: Boolean(error),
          [styles.DropAreaValidFile]: multiple ? false : Boolean(value)
        })}
        onDragEnter={handleEnterEvent}
        onDragLeave={handleLeaveEvent}
        onDragOver={handleOverEvent}
        onDrop={handleDropEvent}
      >
        <label className={cx(styles.Label)} htmlFor={fieldName}>
          {!error && (
            <>
              {(multiple || !value) && <UploadCloud />}
              {!multiple && value && <CircleCheck />}
              <Text className={cx(styles.LabelText)}>{(!multiple && value?.name) || label}</Text>
              {!multiple && value && (
                <Text size="S" className={cx(styles.LabelSelectOther)}>
                  Select different file
                </Text>
              )}
            </>
          )}
          {error && (
            <>
              <Exclamation />
              <Text className={cx(styles.LabelText)}>{error}</Text>
            </>
          )}
        </label>
        <input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          id={fieldName}
          type="file"
          name={fieldName}
          className={cx(styles.Input, {
            [styles.InputError]: error
          })}
          placeholder={placeholder}
          disabled={disabled}
          aria-label={label}
          aria-required="true"
          onChange={onChangeHandler}
          accept={acceptedFileTypes ? acceptedFileTypes.join(",") : undefined}
          multiple={multiple}
        />
      </div>
    </BaseInput>
  );
};

export default UploadInput;
