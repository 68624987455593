import React, { useMemo, useState } from "react";
import { connect } from "react-redux";

import { Form } from "../../../../../../ui/Input";
import Text from "../../../../../../ui/Text";
import EditTouchpointForm from "./EditTouchpointForm";

import { usePermissions } from "../../../../../../../hooks/usePermissions";

import { getTouchpointDescription } from "../../../JourneyTimeline/helpers";

import {
  fetchAutomation as fetchAutomationAction,
  FetchAutomationOptions,
  openModal as openModalAction,
  OpenModal,
  updateMessageConfiguration as updateMessageConfigurationAction,
  UpdateMessageConfigurationData,
  UpdateMessageConfigurationOptions
} from "../../../../../../../actions";

import handleMediumSpecificTags from "../../../../../../../utils/handleMediumSpecificTags";
import {
  combine,
  isRequired,
  isGreaterThan,
  patientHasOtherAppointmentRangeTime as patientHasOtherAppointmentRangeTimeValidator
} from "../../../../../../../utils/validators";

import { AdminModalTypes, ModalTypes, MessageTopics } from "../../../../../../../constants";

import {
  AutomatedMessageConfigurationTopic,
  ChatFlow,
  MessageScheduleEvent,
  MessageConfigurationDetails,
  MessageTemplate,
  MessageTemplateMedium,
  Permissions,
  Reason,
  TouchpointSettingRangeTime,
  TouchpointExclusionCases,
  TouchpointSettingRangeTimeType,
  TouchpointSettingRangeTimeUnit,
  ReduxStateType
} from "../../../../../../../types";

import styles from "./index.module.scss";

export type PreviewMediumTypes = MessageTemplateMedium.sms | MessageTemplateMedium.email;

export type EditTouchpointFormState = {
  templateId: string;
  multiTemplateId: string;
  chatFlowIds: number[];
  note: string;
  exclusionHours: number | undefined;
  exclusionHoursOption: string;
  exclusionHoursUnit: string;
  exclusionHoursActive: boolean;
  excludesApptsWhen?: string;
  settings: {
    exclude?: {
      patientHasOtherAppt?: {
        active: boolean;
        rangeStart: TouchpointSettingRangeTime;
        rangeEnd: TouchpointSettingRangeTime;
      };
    };
  };
  reasonIds?: string[];
};

type EditTouchpointProps = {
  automationId?: number;
  touchpoint: MessageConfigurationDetails;
  messageTemplates: MessageTemplate[];
  updateLoading: boolean;
  updateMessageConfiguration: (
    messageConfigurationData: UpdateMessageConfigurationData,
    options?: UpdateMessageConfigurationOptions
  ) => void;
  fetchAutomation: (automationId: string, options: FetchAutomationOptions) => void;
  openModal: OpenModal;
  availableChats: ChatFlow[];
  reasons: Reason[];
};

const supportsExclusionHours = (topic?: string): boolean => {
  return (
    topic === AutomatedMessageConfigurationTopic.BOOKED ||
    topic === AutomatedMessageConfigurationTopic.CANCELLED
  );
};
const supportsExcludeAppointments = (topic?: string): boolean => {
  return topic === AutomatedMessageConfigurationTopic.POST_VISIT_LINK;
};

const templateValidator = isRequired("Please select from message templates");
const exclusionValueValidator = isGreaterThan(0, "Please enter a number greater than zero.");
const touchpointRangeTimeUnitValidator = isRequired("Please select time unit");

const displaySelectedMessageTemplate = (
  messageTemplates: MessageTemplate[],
  templateId: string,
  medium: string
) => {
  const messageTemplate = messageTemplates.find(
    (template) => template.id === parseInt(templateId, 10)
  );

  const previewText = messageTemplate?.payloadContent ?? "";
  const subject = messageTemplate?.subject ?? "A message from [place]";

  const isEmail = medium === MessageTemplateMedium.email;

  return isEmail ? (
    <>
      <Text className={styles.PreviewMessageSubject}>{subject}</Text>
      Hello,
      <br />
      {handleMediumSpecificTags(previewText, medium)}
    </>
  ) : (
    <>{handleMediumSpecificTags(previewText, medium)}</>
  );
};

const EditTouchpoint = ({
  automationId,
  touchpoint,
  messageTemplates,
  updateLoading,
  updateMessageConfiguration,
  fetchAutomation,
  openModal,
  availableChats,
  reasons
}: EditTouchpointProps) => {
  const [showMultiTemplate, setShowMultiTemplate] = useState(Boolean(touchpoint.multiTemplateId));
  const [previewMedium, setPreviewMedium] = useState<PreviewMediumTypes>(MessageTemplateMedium.sms);
  const [multiPreviewMedium, setMultiPreviewMedium] = useState<PreviewMediumTypes>(
    MessageTemplateMedium.sms
  );

  const userCanEdit = usePermissions([Permissions.UPDATE_AUTOMATED_MESSAGE_CONFIGURATION]);

  const formDisabled = updateLoading || !userCanEdit;

  const messageTemplateOptions = messageTemplates
    .filter((messageTemplate) => {
      return (
        messageTemplate.medium === "sms" &&
        messageTemplate.topic === MessageTopics.SCHEDULE_BASED_MESSAGE
      );
    })
    .map((messageTemplate) => ({
      label: messageTemplate.isDefault
        ? `Default ${messageTemplate.displayName} (${messageTemplate.id})`
        : `${messageTemplate.displayName} (${messageTemplate.id})`,
      value: messageTemplate.id.toString()
    }));

  const availableChatOptions = availableChats.map((chat) => {
    return { label: `${chat.title}${chat.version ? ` (${chat.version})` : ""}`, value: chat.id };
  });

  const exclusionHoursOptions = [
    { label: "Appointment is within", value: "Appointment is within" }
  ];

  const exclusionHoursUnitOptions = [
    { label: "Hours", value: TouchpointSettingRangeTimeUnit.HOURS }
  ];

  const excludeAppointmentsOptions = [
    { label: "Patient has another appointment", value: TouchpointExclusionCases.PATIENT_OTHER_APPT }
  ];

  const touchpointSettingRangeTimeTypeOptions = [
    { label: "Before touchpoint", value: TouchpointSettingRangeTimeType.BEFORE_TOUCHPOINT },
    { label: "After touchpoint", value: TouchpointSettingRangeTimeType.AFTER_TOUCHPOINT }
  ];

  const touchpointSettingRangeTimeUnitOptions = [
    { label: "Days", value: TouchpointSettingRangeTimeUnit.DAYS },
    { label: "Hours", value: TouchpointSettingRangeTimeUnit.HOURS },
    { label: "Minutes", value: TouchpointSettingRangeTimeUnit.MINUTES }
  ];

  const deleteTouchpoint = () => {
    if (touchpoint) {
      openModal(AdminModalTypes.DELETE_MESSAGE_CONFIGURATION, {
        messageConfigurationId: touchpoint.id,
        messageConfigurationDescription: getTouchpointDescription(touchpoint.scheduleData),
        automationId: touchpoint.automationId
      });
    }
  };

  const formValidator = (values: EditTouchpointFormState) => {
    const patientHasOtherApptActive = !!values.settings?.exclude?.patientHasOtherAppt?.active;

    return {
      templateId: templateValidator(values.templateId),
      multiTemplateId: undefined,
      chatFlowIds: undefined,
      exclusionHours: undefined,
      exclusionHoursOption: undefined,
      exclusionHoursUnit: undefined,
      exclusionHoursActive: undefined,
      note: undefined,
      excludesApptsWhen: undefined,
      settings: supportsExcludeAppointments(touchpoint.topic)
        ? {
            exclude: {
              patientHasOtherAppt: {
                active: undefined,
                rangeStart: {
                  type: undefined,
                  value: patientHasOtherApptActive
                    ? combine([
                        () =>
                          patientHasOtherAppointmentRangeTimeValidator(
                            values.settings.exclude?.patientHasOtherAppt?.rangeStart,
                            values.settings.exclude?.patientHasOtherAppt?.rangeEnd
                          ),
                        isRequired("Range start value required")
                      ])(values.settings.exclude?.patientHasOtherAppt?.rangeStart?.value)
                    : undefined,
                  unit: patientHasOtherApptActive
                    ? touchpointRangeTimeUnitValidator(
                        values.settings.exclude?.patientHasOtherAppt?.rangeStart?.unit
                      )
                    : undefined
                },
                rangeEnd: {
                  type: undefined,
                  value: patientHasOtherApptActive
                    ? combine([
                        () =>
                          patientHasOtherAppointmentRangeTimeValidator(
                            values.settings.exclude?.patientHasOtherAppt?.rangeStart,
                            values.settings.exclude?.patientHasOtherAppt?.rangeEnd
                          ),
                        isRequired("Range end value required")
                      ])(values.settings.exclude?.patientHasOtherAppt?.rangeEnd?.value)
                    : undefined,
                  unit: patientHasOtherApptActive
                    ? touchpointRangeTimeUnitValidator(
                        values.settings.exclude?.patientHasOtherAppt?.rangeEnd?.unit
                      )
                    : undefined
                },
                reasonIds: undefined
              }
            }
          }
        : undefined
    };
  };

  const initialFormState: EditTouchpointFormState = useMemo(() => {
    const exclusionHours =
      touchpoint?.scheduleData.event === MessageScheduleEvent.BOOKED ||
      touchpoint?.scheduleData.event === MessageScheduleEvent.CANCELLED
        ? touchpoint?.settings?.exclusionHoursBeforeStart
        : undefined;
    const excludeSetting = touchpoint?.settings?.exclude || undefined;
    const reasonIds = touchpoint?.settings?.exclude
      ? touchpoint.settings.exclude.patientHasOtherAppt.reasonIds.map((reasonId) =>
          reasonId.toString()
        )
      : [];
    return {
      templateId: touchpoint?.templateId?.toString() || "",
      multiTemplateId: touchpoint?.multiTemplateId?.toString() || "",
      chatFlowIds: touchpoint?.chatFlows?.map((chatFlow) => chatFlow.id) || [],
      note: touchpoint?.note || "",
      exclusionHours,
      exclusionHoursOption: "Appointment is within",
      exclusionHoursUnit: TouchpointSettingRangeTimeUnit.HOURS,
      exclusionHoursActive: !!exclusionHours,
      excludesApptsWhen: TouchpointExclusionCases.PATIENT_OTHER_APPT,
      settings: {
        exclude: excludeSetting
      },
      reasonIds
    };
  }, [touchpoint]);

  const onSave = (formValues: EditTouchpointFormState) => {
    const reasonIds = formValues.reasonIds?.map((reasonId) => parseInt(reasonId)) || [];
    const settings = formValues.settings?.exclude?.patientHasOtherAppt?.active
      ? {
          exclude: {
            ...formValues.settings.exclude,
            patientHasOtherAppt: {
              active: formValues.settings.exclude?.patientHasOtherAppt?.active,
              rangeStart: formValues.settings.exclude?.patientHasOtherAppt?.rangeStart,
              rangeEnd: formValues.settings.exclude?.patientHasOtherAppt?.rangeEnd,
              reasonIds
            }
          }
        }
      : null;
    updateMessageConfiguration(
      {
        id: touchpoint.id,
        templateId: formValues.templateId ? parseInt(formValues.templateId, 10) : null,
        multiTemplateId: formValues.multiTemplateId
          ? parseInt(formValues.multiTemplateId, 10)
          : null,
        chatFlowIds: formValues.chatFlowIds,
        note: formValues.note || "",
        exclusionHours: formValues.exclusionHours || undefined,
        settings
      },
      { onSuccess: () => fetchAutomation(touchpoint.automationId.toString(), { silent: true }) }
    );
  };

  const openSendTestModal = () => {
    const messageTemplate = messageTemplates.find((mt) => mt.id === touchpoint?.templateId);
    openModal(ModalTypes.SEND_MESSAGE_TEMPLATE, {
      messageConfigurationId: touchpoint.id,
      messageTemplate
    });
  };

  return (
    <Form
      initialValues={initialFormState}
      onSubmit={(formState) => onSave(formState.values as EditTouchpointFormState)}
      validateFields={(values) => formValidator(values as EditTouchpointFormState)}
    >
      <EditTouchpointForm
        automationId={automationId}
        formDisabled={formDisabled}
        touchpoint={touchpoint}
        messageTemplates={messageTemplates}
        showMultiTemplate={showMultiTemplate}
        previewMedium={previewMedium}
        setPreviewMedium={setPreviewMedium}
        multiPreviewMedium={multiPreviewMedium}
        setMultiPreviewMedium={setMultiPreviewMedium}
        exclusionHoursUnitOptions={exclusionHoursUnitOptions}
        exclusionHoursOptions={exclusionHoursOptions}
        touchpointSettingRangeTimeUnitOptions={touchpointSettingRangeTimeUnitOptions}
        touchpointSettingRangeTimeTypeOptions={touchpointSettingRangeTimeTypeOptions}
        excludeAppointmentsOptions={excludeAppointmentsOptions}
        messageTemplateOptions={messageTemplateOptions}
        availableChatOptions={availableChatOptions}
        initialFormState={initialFormState}
        userCanEdit={userCanEdit}
        deleteTouchpoint={deleteTouchpoint}
        setShowMultiTemplate={setShowMultiTemplate}
        openModal={openModal}
        displaySelectedMessageTemplate={displaySelectedMessageTemplate}
        openSendTestModal={openSendTestModal}
        reasons={reasons}
      />
    </Form>
  );
};

const mapStateToProps = ({
  automatedMessages,
  messageTemplates,
  messageConfigurations,
  reasons
}: ReduxStateType) => {
  return {
    messageTemplates: messageTemplates.data,
    updateLoading: messageConfigurations.messageConfigurationUpdateLoading,
    availableChats: automatedMessages.availableChats,
    reasons: reasons.data
  };
};

export default connect(mapStateToProps, {
  openModal: openModalAction,
  fetchAutomation: fetchAutomationAction,
  updateMessageConfiguration: updateMessageConfigurationAction
})(EditTouchpoint);
