import React from "react";

type PropsType = {
  size?: number;
};

const MikaAI = ({ size = 24 }: PropsType) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9651 1.17935C19.7986 0.757275 19.2013 0.757275 19.0348 1.17935L18.3068 3.02518C18.256 3.15405 18.154 3.25605 18.0251 3.30687L16.1793 4.03486C15.7572 4.20132 15.7572 4.79866 16.1793 4.96512L18.0251 5.69311C18.154 5.74393 18.256 5.84593 18.3068 5.9748L19.0348 7.82063C19.2013 8.2427 19.7986 8.24271 19.9651 7.82063L20.6931 5.9748C20.7439 5.84593 20.8459 5.74393 20.9747 5.69311L22.8206 4.96512C23.2427 4.79866 23.2427 4.20132 22.8206 4.03486L20.9747 3.30687C20.8459 3.25605 20.7439 3.15405 20.6931 3.02518L19.9651 1.17935ZM11.9061 3.94164C11.547 3.17219 10.4529 3.17219 10.0938 3.94164L7.65353 9.17032C7.5542 9.38317 7.38312 9.55425 7.17027 9.65358L1.94159 12.0938C1.17214 12.4529 1.17214 13.5471 1.94159 13.9062L7.17027 16.3464C7.38312 16.4457 7.5542 16.6168 7.65353 16.8297L10.0938 22.0583C10.4529 22.8278 11.547 22.8278 11.9061 22.0583L14.3463 16.8297C14.4457 16.6168 14.6168 16.4457 14.8296 16.3464L20.0583 13.9062C20.8277 13.5471 20.8277 12.4529 20.0583 12.0938L14.8296 9.65358C14.6168 9.55425 14.4457 9.38317 14.3463 9.17032L11.9061 3.94164Z"
        fill="url(#paint0_radial_15639_2923)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_15639_2923"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.0005 3.99951) rotate(133.958) scale(19.4486 9.99994)"
        >
          <stop stopColor="#EF89E4" />
          <stop offset="0.34862" stopColor="#BC5BEB" />
          <stop offset="0.584507" stopColor="#EF89E4" />
          <stop offset="0.965" stopColor="#BC5BEB" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default MikaAI;
