import React, { Dispatch, SetStateAction, useMemo } from "react";

import { TextAreaInput } from "../../../../../../../../ui/Input";
import Text from "../../../../../../../../ui/Text";
import { ChatFlowsNodes } from "../../../../../../../../../types";

import BaseChatNodeForm, {
  BaseFormValues,
  CheckInFormValues,
  FormValues
} from "../../BaseChatNodeForm";

type PropsType = {
  chatId: string | undefined;
  node: ChatFlowsNodes;
  viewOnly: boolean;
  setSelectedNodeId: Dispatch<SetStateAction<number | null>>;
};

export type CheckInFormNode = ChatFlowsNodes & {
  payloadContent: string | null;
};

const onSaveCustomTransformer = (formValues: FormValues) => {
  const values = formValues as BaseFormValues & CheckInFormValues;
  return {
    payloadContent: values.payloadContent || ""
  };
};

const CheckInCard = ({ chatId, node, viewOnly, setSelectedNodeId }: PropsType) => {
  const initialFormValues: CheckInFormValues = useMemo(() => {
    return {
      payloadContent: node.payloadContent || ""
    };
  }, [node.id]);

  return (
    <BaseChatNodeForm
      chatId={chatId}
      initialValues={initialFormValues}
      onSaveCustomTransformer={onSaveCustomTransformer}
      node={node}
      key={node.id}
      viewOnly={viewOnly}
      setSelectedNodeId={setSelectedNodeId}
    >
      <TextAreaInput
        id="chatNodePayloadContent"
        size="S"
        fieldName="payloadContent"
        label="Mikata Message"
        disabled={viewOnly}
      />
      <Text component="p">Patients can check-in for their appointment.</Text>
    </BaseChatNodeForm>
  );
};

export default CheckInCard;
