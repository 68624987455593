import React from "react";
import { connect } from "react-redux";

import Button from "../../../ui/Button";
import Heading from "../../../ui/Heading";
import { FloatModal } from "../../../ui/Modal";
import { Form, TextInput, SelectInput } from "../../../ui/Input";

import { isRequired } from "../../../../utils/validators";

import {
  closeModal as closeModalAction,
  addInstruction as addInstructionsAction,
  AddInstructionData
} from "../../../../actions";

import styles from "./index.module.scss";

import { ReduxStateType, Option } from "../../../../types";

type AddInstructionFormData = { displayName: string; description: string; contentTemplate: string };

type PropsType = {
  loading: boolean;
  addInstruction: (instruction: AddInstructionData) => void;
  contentTemplateOptions: Option[];
  closeModal: () => void;
};

const displayNameValidator = isRequired("Please enter a name");

const AddInstruction = ({
  loading,
  contentTemplateOptions,
  addInstruction,
  closeModal
}: PropsType) => {
  const initialFormState: AddInstructionFormData = {
    displayName: "",
    description: "",
    contentTemplate: contentTemplateOptions[0] ? contentTemplateOptions[0].value.toString() : ""
  };

  const onSave = (values: AddInstructionFormData) => {
    addInstruction(values);
  };

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L">Add Instruction</Heading>
      <Form
        onSubmit={(formState) => onSave(formState.values as AddInstructionFormData)}
        initialValues={initialFormState}
      >
        <TextInput fieldName="displayName" label="name" validate={displayNameValidator} />
        <TextInput fieldName="description" label="description" />
        <SelectInput
          fieldName="contentTemplate"
          label="content template"
          options={contentTemplateOptions}
        />
        <div className={styles.ButtonGroup}>
          <Button inline onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit" disabled={loading}>
            Save
          </Button>
        </div>
      </Form>
    </FloatModal>
  );
};
const mapStateToProps = ({ instructions }: ReduxStateType) => {
  return {
    loading: instructions.addLoading,
    contentTemplateOptions: instructions.contentTemplateOptions
  };
};

export default connect(mapStateToProps, {
  closeModal: closeModalAction,
  addInstruction: addInstructionsAction
})(AddInstruction);
