import React, { useRef, useState, useEffect } from "react";
import cx from "classnames";

import Button from "../../../ui/Button";
import Text from "../../../ui/Text";

import CreateUnintegratedAppointment from "./Steps/CreateUnintegratedAppointment";
import StartScribeSessionOne from "./Steps/StartScribeSessionOne";
import StartScribeSessionTwo from "./Steps/StartScribeSessionTwo";
import StartScribeSessionThree from "./Steps/StartScribeSessionThree";
import StartScribeSessionThreePointFive from "./Steps/StartScribeSessionThreePointFive";
import ScribeFeedback from "./Steps/ScribeFeedback";

import { AnnouncementName } from "../../../../types";

import styles from "./stepsCarousel.module.scss";
import ScribeMultiTemplate from "./Steps/ScribeMultiTemplate";
import ScribeMultiLanguage from "./Steps/ScribeMultiLanguage";

type PropsType = {
  announcementName: AnnouncementName;
  markAnnouncementComplete?: (announcementName: AnnouncementName) => void;
};

const stepsByAnnouncementName: { [announcementName: string]: Array<() => React.ReactElement> } = {
  [AnnouncementName.CREATE_UNINTEGRATED_APPOINTMENT_TUTORIAL]: [CreateUnintegratedAppointment],
  [AnnouncementName.START_SCRIBE_SESSION_TUTORIAL]: [
    StartScribeSessionOne,
    StartScribeSessionTwo,
    StartScribeSessionThree,
    StartScribeSessionThreePointFive
  ],
  [AnnouncementName.SCRIBE_MULTI_TEMPLATES]: [ScribeMultiTemplate],
  [AnnouncementName.SCRIBE_MULTI_LANGUAGE]: [ScribeMultiLanguage],
  [AnnouncementName.SCRIBE_FEEDBACK_TUTORIAL]: [ScribeFeedback]
};

type StepPropsType = {
  active: boolean;
  children: React.ReactNode;
};

const Step = ({ active, children }: StepPropsType) => {
  const stepElementRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (active && stepElementRef) {
      stepElementRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
  }, [stepElementRef, active]);

  return (
    <div
      ref={(ref) => {
        if (ref) stepElementRef.current = ref;
      }}
      className={cx(styles.Step)}
    >
      {children}
    </div>
  );
};

const StepsCarousel = ({
  announcementName,
  markAnnouncementComplete
}: PropsType): React.ReactElement => {
  const steps = stepsByAnnouncementName[announcementName as string] || [];

  const [selectedStepIndex, setSelectedStepIndex] = useState<number>(0);

  const hasMultiSteps = steps.length > 1;
  const isOnFirstStep = selectedStepIndex === 0;
  const isOnLastStep = selectedStepIndex === steps.length - 1;

  const handlePreviousAction = () => {
    setSelectedStepIndex((currentSelectedStepIndex) => {
      if (currentSelectedStepIndex > 0) return currentSelectedStepIndex - 1;

      return currentSelectedStepIndex;
    });
  };

  const handleNextAction = () => {
    if (isOnLastStep) {
      if (markAnnouncementComplete) markAnnouncementComplete(announcementName);
    } else {
      setSelectedStepIndex((currentSelectedStepIndex) => currentSelectedStepIndex + 1);
    }
  };

  return (
    <div className={cx(styles.Carousel)}>
      <div className={cx(styles.CarouselScroll)}>
        <div className={cx(styles.CarouselSteps)}>
          {steps.map((StepContent, idx) => {
            return (
              <Step // eslint-disable-next-line react/no-array-index-key
                key={`announcementStep-${announcementName}-${idx}`}
                active={idx === selectedStepIndex}
              >
                <StepContent />
              </Step>
            );
          })}
        </div>
      </div>

      <div className={cx(styles.CarouselFooter)}>
        <div className={cx(styles.CarouselFooterBack)}>
          {hasMultiSteps && !isOnFirstStep && (
            <Button inline onClick={handlePreviousAction}>
              <Text size="S" darkMode>
                Back
              </Text>
            </Button>
          )}
        </div>

        <div className={cx(styles.CarouselFooterNav)}>
          {hasMultiSteps &&
            steps.map((step, idx) => {
              return (
                <Button // eslint-disable-next-line react/no-array-index-key
                  key={`announcementStepNav-${announcementName}-${idx}`}
                  inline
                  className={cx(styles.CarouselFooterNavButton)}
                  onClick={() => {
                    setSelectedStepIndex(idx);
                  }}
                >
                  <div
                    className={cx(styles.CarouselFooterNavDot, {
                      [styles.CarouselFooterNavDotActive]: idx === selectedStepIndex
                    })}
                  />
                </Button>
              );
            })}
        </div>

        <div className={cx(styles.CarouselFooterNext)}>
          <Button inline onClick={handleNextAction}>
            <Text size="S" darkMode>
              {isOnLastStep ? "Done" : "Next"}
            </Text>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StepsCarousel;
