type DataMappingsOption = {
  inputName: string;
  mappedName: string;
};

type DemographicPropertyMapping = {
  emrFieldName?: string;
  mappingChoice?: string;
  inputName?: string;
  tagName?: string;
  required?: boolean;
  defaultValue?: string;
};

export type MessageTemplateFormState = {
  type: string;
  reportTemplateId?: string;
  title?: string;
  letterTypeId?: string;
  fileCSVForm?: Array<DataMappingsOption>;
  demographicPropertiesArray: Array<DemographicPropertyMapping>;
};

export const findDuplicates = (arr: string[]) => {
  return arr.filter((item, index) => arr.indexOf(item) !== index);
};

export const containsAllOrNone = (chosenFields: string[], requiredGroup: string[]) => {
  if (requiredGroup.every((field) => chosenFields.includes(field))) return true;
  if (requiredGroup.every((field) => !chosenFields.includes(field))) return true;
  return false;
};

export const globalFormValidator = (values: MessageTemplateFormState) => {
  if (values.fileCSVForm) {
    const inputNames = values.fileCSVForm.map((inputNameMapping) => inputNameMapping.inputName);
    const mappedNames = values.fileCSVForm.map((inputNameMapping) => inputNameMapping.mappedName);

    if ([...new Set(findDuplicates(inputNames))].length !== 0) {
      return `Input names must be unique.`;
    }

    if ([...new Set(findDuplicates(mappedNames))].length !== 0) {
      return "CSV column names must be unique.";
    }
  }
  if (values.demographicPropertiesArray) {
    const emrFieldNames = values.demographicPropertiesArray.flatMap((property) => {
      if (property.emrFieldName) return property.emrFieldName;
      return [];
    });

    if ([...new Set(findDuplicates(emrFieldNames))].length !== 0) {
      return `EMR Field Names must be unique.`;
    }

    if (
      values.demographicPropertiesArray.some(
        (demographicProperties) =>
          demographicProperties.defaultValue === undefined &&
          demographicProperties.inputName === undefined &&
          demographicProperties.tagName === undefined
      )
    ) {
      return "Either a default value or mapping must be specified for each property.";
    }

    const optionalPropertyDependencies = [
      ["demographics.phones[0].number", "demographics.phones[0].contactType"],

      [
        "demographics.addresses[0].locationId",
        "demographics.addresses[0].street",
        "demographics.addresses[0].city",
        "demographics.addresses[0].postalZip"
      ]
    ];

    let dependencyError;
    optionalPropertyDependencies.forEach((optionalPropertySet) => {
      if (!containsAllOrNone(emrFieldNames, optionalPropertySet)) {
        let propertySetString = "";
        optionalPropertySet.forEach((emrFieldName) => {
          propertySetString = propertySetString.concat(`${emrFieldName}, `);
        });
        dependencyError = `All or none of the following emrFieldNames must be selected: ${propertySetString}`;
      }
    });
    if (dependencyError) {
      return dependencyError;
    }
  }
};
