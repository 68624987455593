import { MessageTopics } from "../constants";

export const defaultEmailSubjects: { [topic: string]: string } = {
  [MessageTopics.SEVEN_DAY_SINGLE_REMINDER]: "Appointment reminder from [place]",
  [MessageTopics.SEVEN_DAY_MULTI_REMINDER]: "Appointment reminder from [place]",
  [MessageTopics.TWO_DAY_SINGLE_REMINDER]: "Appointment reminder from [place]",
  [MessageTopics.TWO_DAY_SINGLE_REMINDER_NO_SEVEN]: "Appointment reminder from [place]",
  [MessageTopics.TWO_DAY_MULTI_REMINDER]: "Appointment reminder from [place]",
  [MessageTopics.TWO_DAY_MULTI_REMINDER_NO_SEVEN]: "Appointment reminder from [place]",
  [MessageTopics.TWO_DAY_PREVISIT_LINK]: "Important message from [orgDisplayName]",
  [MessageTopics.BOOKED]: "Appointment booking notification from [place]",
  [MessageTopics.MULTI_BOOKED]: "Appointment booking notification from [place]",
  [MessageTopics.CANCELLED]: "Appointment cancelled",
  [MessageTopics.MULTI_CANCELLED]: "Appointment cancelled",
  [MessageTopics.POST_VISIT_LINK]: "Appointment follow-up message from [place]",
  // [MessageTopics.REMINDER_RESPONSE_NO_APPT]: "",
  [MessageTopics.REMINDER_CONFIRM]: "Appointment confirmed!",
  [MessageTopics.REMINDER_RESPONSE_RESCHEDULE]: "How to reschedule/cancel your appointment",
  // [MessageTopics.REMINDER_RESPONSE_UNCLEAR]: "",
  // [MessageTopics.USER_RESTART_REMINDERS]: "",
  // [MessageTopics.USER_SURVEY_OPT_OUT]: "",
  // [MessageTopics.CAMPAIGN]: "",
  // [MessageTopics.CAMPAIGN_RESPONSE_UNCLEAR]: "",
  [MessageTopics.CHECK_IN_NOTICE]: "Appointment Check-in from [orgDisplayName]",
  // [MessageTopics.USER_CHECK_IN_RESPONSE]: "",
  [MessageTopics.CHECK_IN_NOTIFY_USER]: "[place] is ready for you!",
  // [MessageTopics.BOOKING_RESPONSE]: "",
  // [MessageTopics.BOOKING_NOT_ENABLED_RESPONSE]: "",
  // [MessageTopics.BOOKING_NOT_ENABLED_FEEDBACK]: "",
  // [MessageTopics.CHECK_IN_NO_APPOINTMENTS]: "",
  // [MessageTopics.CHECK_IN_UNCLEAR]: "",
  // [MessageTopics.SCHEDULE_BASED_MESSAGE]: "",
  [MessageTopics.DIRECT_MESSAGE]: "Important message from [orgDisplayName]",
  [MessageTopics.SECURE_DIRECT_MESSAGE]: "Important message from [orgDisplayName]",
  [MessageTopics.APPOINTMENT_REQUEST_DIRECT_MESSAGE]: "Important message from [orgDisplayName]"
};

export const getDefaultEmailSubject = (topic: string): string => {
  return defaultEmailSubjects[topic];
};
