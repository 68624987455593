import React from "react";

import TranscriptMessages from "../../../../../../ui/TranscriptMessages";
import Card from "../../../../../../ui/Card";
import Text from "../../../../../../ui/Text";

import { AppointmentDetail } from "../../../../../../../types";

export type TranscriptsProps = {
  appointment?: AppointmentDetail;
};

const Transcripts = ({ appointment }: TranscriptsProps) => {
  if (!appointment) return null;

  return (
    <>
      {appointment?.transcript?.length === 0 ? (
        <Text>No messages</Text>
      ) : (
        <Card>
          <TranscriptMessages transcript={appointment?.transcript} />
        </Card>
      )}
    </>
  );
};

export default Transcripts;
