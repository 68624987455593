import React, { Dispatch, SetStateAction, useMemo } from "react";

import { TextAreaInput } from "../../../../../../../../ui/Input";
import Text from "../../../../../../../../ui/Text";
import { ChatFlowsNodes } from "../../../../../../../../../types";

import BaseChatNodeForm, {
  BaseFormValues,
  InstructionReasonsFormValues,
  FormValues
} from "../../BaseChatNodeForm";

import { isRequired } from "../../../../../../../../../utils/validators";

type PropsType = {
  chatId: string | undefined;
  node: ChatFlowsNodes;
  viewOnly: boolean;
  setSelectedNodeId: Dispatch<SetStateAction<number | null>>;
};

export type InstructionReasonsFormNode = ChatFlowsNodes & {
  payloadContent: string | null;
};

const singleValidator = isRequired("Please enter a message for a single reason");
const multiValidator = isRequired("Please enter a message for multiple reasons");

const onSaveCustomTransformer = (formValues: FormValues) => {
  const values = formValues as BaseFormValues & InstructionReasonsFormValues;
  return {
    payloadContent: values.payloadContent || "",
    payloadOptions: { multiAppointmentMessage: values.multiAppointmentMessage || "" }
  };
};

const InstructionReasonsCard = ({ chatId, node, viewOnly, setSelectedNodeId }: PropsType) => {
  const initialFormValues: InstructionReasonsFormValues = useMemo(() => {
    return {
      payloadContent: node.payloadContent || "",
      multiAppointmentMessage: node.payloadOptions?.multiAppointmentMessage || ""
    };
  }, [node.id]);

  return (
    <BaseChatNodeForm
      chatId={chatId}
      initialValues={initialFormValues}
      onSaveCustomTransformer={onSaveCustomTransformer}
      node={node}
      key={node.id}
      viewOnly={viewOnly}
      setSelectedNodeId={setSelectedNodeId}
    >
      <TextAreaInput
        id="chatNodePayloadContent"
        size="S"
        fieldName="payloadContent"
        label="Mikata Message"
        disabled={viewOnly}
        info="This message will appear directly above the patients instruction(s)."
        validate={singleValidator}
      />
      <TextAreaInput
        size="S"
        fieldName="multiAppointmentMessage"
        label="Mikata Multi Message"
        disabled={viewOnly}
        info="This message will appear directly above the patients instruction(s)."
        validate={multiValidator}
      />
      <Text component="p">
        If the patient has multiple appointments, then they will be allowed to select more than one
        option.
      </Text>
    </BaseChatNodeForm>
  );
};

export default InstructionReasonsCard;
