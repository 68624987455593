import React, { useMemo } from "react";
import { connect } from "react-redux";

import { FloatModal } from "../../../ui/Modal";
import Heading from "../../../ui/Heading";
import { Form } from "../../../ui/Input";
import AddChatNodeForm from "./AddChatNodeForm";

import {
  closeModal as closeModalAction,
  addChatNode as AddChatNodeAction
} from "../../../../actions";
import { isRequired, isNodeInputName } from "../../../../utils/validators";
import { ChatNodeOptions, ChatFlowTags, ChatCardTypes } from "../../../../constants";

import { useQueryString } from "../../../../utils/queryStringHelpers";
import { CreateChatFlowNode } from "../../../../types";

import styles from "./index.module.scss";

type PropsType = {
  organizationId: number;
  addChatNode: (data: CreateChatFlowNode, parentChatFlowId: number, onSuccess?: () => void) => void;
  closeModal: () => void;
  chatFlowTags: string[];
};

type FormState = {
  displayName: string;
  cardType: string;
  inputName: string | null;
};

const getCardTypeOptions = (chatFlowTags: string[]) => {
  const includeScribeVisit = chatFlowTags.includes(ChatFlowTags.SCRIBE_VISIT);

  const filteredChatOptions = includeScribeVisit
    ? ChatNodeOptions
    : ChatNodeOptions.filter((node) => node.cardType !== ChatCardTypes.SCRIBE_VISIT);

  return filteredChatOptions.map((node) => ({ label: node.label, value: node.cardType }));
};

const isInputNode = (cardType: string) => {
  return cardType
    ? ChatNodeOptions.find((node) => node.cardType === cardType)?.isInput || false
    : false;
};

const formValidator = (values: FormState) => {
  return {
    displayName: isRequired("Please enter a display name")(values.displayName),
    cardType: isRequired("Please select a card type")(values.cardType),
    inputName: isInputNode(values.cardType)
      ? isNodeInputName("Please enter a valid input name")(values.inputName)
      : undefined
  };
};

const initialFormState = {
  cardType: "",
  displayName: "",
  inputName: null
};

/**
 * * Component definition begins below
 */
const AddChatNode = ({
  organizationId,
  closeModal,
  addChatNode,
  chatFlowTags
}: PropsType): JSX.Element => {
  const { parsed } = useQueryString();
  const { chatId } = parsed;

  const save = async (formValues: FormState) => {
    const data: CreateChatFlowNode = {
      organizationId: organizationId || null,
      displayName: formValues.displayName,
      cardType: formValues.cardType,
      inputName: formValues.inputName || null
    };
    addChatNode(data, Number(chatId), () => closeModal());
  };
  const cardTypeOptions = useMemo(() => getCardTypeOptions(chatFlowTags), [chatFlowTags]);

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L" className={styles.Heading}>
        Add element
      </Heading>
      <Form
        onSubmit={(formState) => save(formState.values as FormState)}
        initialValues={initialFormState}
        validateFields={(values) => formValidator(values as FormState)}
      >
        <AddChatNodeForm isInputNode={isInputNode} cardTypeOptions={cardTypeOptions} />
      </Form>
    </FloatModal>
  );
};

export default connect(null, {
  closeModal: closeModalAction,
  addChatNode: AddChatNodeAction
})(AddChatNode);
