import React from "react";
import cx from "classnames";
import styles from "./index.module.scss";

import { RecordingSessionStatus, RecordingState } from "../../../../../../../types";

import Text from "../../../../../../ui/Text";

const NUMBER_OF_BARS = 20;

type Props = {
  recording?: RecordingState;
  isPaused?: boolean;
  volume: number; // 0-1 value
};

const MIN_SCALE_Y = 0.07;

const StreamingAnimation = ({ recording, isPaused, volume = 0 }: Props) => {
  if (recording?.status !== RecordingSessionStatus.RECORDING && !isPaused) return null;

  const containerScaleValue = isPaused ? MIN_SCALE_Y : Math.max(volume, MIN_SCALE_Y);
  const hasNoMicVolume = containerScaleValue === MIN_SCALE_Y;

  if (isPaused) {
    return (
      <Text size="XS" bold className={styles.PausedText}>
        Session paused
      </Text>
    );
  }

  return (
    <div className={styles.StreamingAnimation}>
      {[...Array(NUMBER_OF_BARS).keys()].map((component, idx) => {
        return (
          <div
            key={`bar-${idx}`}
            className={cx(styles.StreamingAnimationBar, {
              [styles.StreamingAnimationBarPaused]: isPaused,
              [styles.StreamingAnimationBarNoMicVolume]: !isPaused && hasNoMicVolume,
              [styles.StreamingAnimationBarActive]: !hasNoMicVolume && !isPaused
            })}
            style={{ transform: `scaleY(${containerScaleValue})` }}
          />
        );
      })}
    </div>
  );
};

export default StreamingAnimation;
