import React from "react";
import { connect } from "react-redux";

import Button from "../../../ui/Button";
import Heading from "../../../ui/Heading";

import { FloatModal } from "../../../ui/Modal";
import { Form, TextInput, SelectInput } from "../../../ui/Input";
import Tooltip from "../../../ui/Tooltip";

import {
  addAutomation as addAutomationAction,
  AddAutomationData,
  closeModal as closeModalAction
} from "../../../../actions";

import { isRequired } from "../../../../utils/validators";

import styles from "./index.module.scss";

import { ReduxStateType, Automation } from "../../../../types";
import { Info } from "../../../ui/Icon";

type AddAutomationFormData = {
  displayName: string;
  description: string;
  cloneAutomationId: string;
};

type PropsType = {
  automations: Automation[];
  loading?: boolean;
  closeModal: () => void;
  addAutomation: (data: AddAutomationData) => void;
};

const initialFormState: AddAutomationFormData = {
  displayName: "",
  description: "",
  cloneAutomationId: ""
};

const displayNameValidator = isRequired("Please enter a name");

const AddAutomation = ({
  automations,

  loading,
  closeModal,
  addAutomation
}: PropsType) => {
  const onSave = (formData: AddAutomationFormData) => {
    addAutomation({
      displayName: formData.displayName,
      description: formData.description,
      cloneAutomationId: formData.cloneAutomationId
    });
  };

  const formDisabled = loading;

  const automationOptions = [
    {
      label: "None",
      value: ""
    },
    ...automations
      .sort((a, b) => {
        if (a.displayName > b.displayName) return 1;
        if (b.displayName > a.displayName) return -1;
        return 0;
      })
      .map((automation) => ({
        label: automation.displayName,
        value: automation.id.toString()
      }))
  ];

  return (
    <FloatModal isOpen onClose={closeModal}>
      <Heading size="L">Create a journey</Heading>
      <Form
        onSubmit={(formState) => onSave(formState.values as AddAutomationFormData)}
        initialValues={initialFormState}
      >
        <TextInput
          fieldName="displayName"
          label="Journey name"
          placeholder="Enter name"
          validate={displayNameValidator}
          disabled={formDisabled}
        />
        <TextInput
          fieldName="description"
          label="description"
          placeholder="Enter description"
          disabled={formDisabled}
        />
        <div className={styles.CloneJourneyHeader}>
          <Heading size="META">Clone settings and touchpoints from journey</Heading>
          <Tooltip
            icon={
              <div className={styles.Icon}>
                <Info size={15} />
              </div>
            }
            position="topRight"
          >
            Clones touchpoints, appointment hub settings, and action settings from the selected
            journey into the draft journey.
          </Tooltip>
        </div>
        <SelectInput
          fieldName="cloneAutomationId"
          placeholder="Select journey"
          options={automationOptions}
          disabled={formDisabled}
        />
        <div className={styles.ButtonGroup}>
          <Button inline onClick={closeModal}>
            Cancel
          </Button>
          <Button id="createAutomation" type="submit" disabled={formDisabled}>
            Create
          </Button>
        </div>
      </Form>
    </FloatModal>
  );
};
const mapStateToProps = ({ automatedMessages }: ReduxStateType) => {
  return {
    automations: automatedMessages.automations,
    loading: automatedMessages.automationAddLoading
  };
};

export default connect(mapStateToProps, {
  addAutomation: addAutomationAction,
  closeModal: closeModalAction
})(AddAutomation);
