import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";

import { useSearchParams } from "react-router-dom";
import Button from "../../../ui/Button";
import Heading from "../../../ui/Heading";
import { FloatModal } from "../../../ui/Modal";
import Text from "../../../ui/Text";
import { Form, SelectInput } from "../../../ui/Input";
import Loader from "../../../ui/Loader";
import TemplateSearchSelect from "../../../ui/TemplateSearchSelect";
import FeedbackManager from "../../../ui/FeedbackManager";

import { isRequired } from "../../../../utils/validators";

import {
  fetchNoteTemplates as fetchNoteTemplatesAction,
  fetchPractitioners as fetchPractitionersAction,
  cloneNoteTemplate as cloneNoteTemplateAction,
  closeModal as closeModalAction,
  CloneNoteTemplateData,
  setFeedbackMode
} from "../../../../actions";
import { UserContext } from "../../../providers/UserProvider";
import { NoteTemplate, Practitioner, ReduxStateType, SetFeedbackModeData } from "../../../../types";
import { FeedbackFormModes, UserTypeConstants } from "../../../../constants";
import { updateQueryString } from "../../../../utils/queryStringHelpers";

import styles from "./index.module.scss";

type PropsType = {
  providerId?: number | boolean;
  noteTemplatesLoading: boolean;
  noteTemplates: NoteTemplate[];
  noteTemplateId?: number;
  practitioners: Array<Practitioner>;
  noteTemplateTags: Record<string, string[]> | undefined;
  closeModal: () => void;
  setFeedbackMode: (data: SetFeedbackModeData) => void;
  fetchNoteTemplates: () => void;
  fetchPractitioners: () => void;
  cloneNoteTemplate: (data: CloneNoteTemplateData) => void;
};

type FormState = {
  mikataNoteTemplateId: number;
  cloneToUserId: number;
};

const formValidator = (values: FormState) => {
  return {
    cloneToUserId: isRequired("Please select a provider")(values.cloneToUserId)
  };
};

const AddNoteTemplate = ({
  noteTemplatesLoading,
  noteTemplateId,
  providerId,
  noteTemplates,
  noteTemplateTags,
  setFeedbackMode,
  closeModal,
  fetchNoteTemplates,
  fetchPractitioners,
  cloneNoteTemplate,
  practitioners
}: PropsType) => {
  const user = useContext(UserContext);
  const isMikataAdmin = user.userType === UserTypeConstants.MIKATA_ADMIN;
  const [searchParams, setSearchParams] = useSearchParams();
  const templateNavs = ["All templates", "Favourite", "My library"];
  const [templateNavFilter, setTemplateNavFilter] = useState<string>("All templates");

  const [activeStep, setActiveStep] = useState<number>(typeof providerId === "number" ? 2 : 1);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState<boolean>(false);
  const [selectedProviderId, setSelectedProviderId] = useState<number | undefined>(
    typeof providerId === "number" ? providerId : undefined
  );

  const cloneTemplate = (mikataNoteTemplateId: number) => {
    if (selectedProviderId) {
      cloneNoteTemplate({
        cloneFromTemplateId: mikataNoteTemplateId,
        cloneToUserId: selectedProviderId,
        onSuccess: (clonedTemplateId) => {
          updateQueryString({ noteTemplateId: clonedTemplateId.toString() }, setSearchParams);
        }
      });
    }

    closeModal();
  };

  useEffect(() => {
    if (selectedProviderId && activeStep === 1) {
      if (noteTemplateId) {
        cloneTemplate(noteTemplateId);
      } else {
        setActiveStep(2);
      }
    }
  }, [selectedProviderId, noteTemplateId]);

  const moveToNextStep = (formValues: FormState) => {
    setSelectedProviderId(formValues.cloneToUserId);
  };

  useEffect(() => {
    fetchNoteTemplates();
    fetchPractitioners();
  }, []);

  const scribePractitioners = [
    ...practitioners.map((practitioner) => ({
      label: practitioner.fullName,
      value: practitioner.userId
    }))
  ];

  if (noteTemplatesLoading)
    return (
      <FloatModal isOpen onClose={closeModal}>
        <Loader center screen />
      </FloatModal>
    );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {activeStep === 1 ? (
        <FloatModal isOpen onClose={closeModal}>
          <Heading size="L">Select a provider</Heading>
          <Form
            className={styles.FormContainer}
            onSubmit={(formState) => {
              moveToNextStep(formState.values as FormState);
            }}
            validateFields={(values) => formValidator(values as FormState)}
          >
            <div className={styles.Form}>
              <SelectInput
                label="owner"
                fieldName="cloneToUserId"
                placeholder="Select a provider"
                options={scribePractitioners}
              />
            </div>
            <div className={styles.ButtonGroup}>
              <Button inline onClick={closeModal}>
                Cancel
              </Button>
              <Button type="submit">{noteTemplateId ? "Save" : "Next"}</Button>
            </div>
          </Form>
        </FloatModal>
      ) : (
        <FloatModal containerClass={styles.ModalContainer} isWideModal isOpen onClose={closeModal}>
          {noteTemplatesLoading ? (
            <Loader center className={styles.LoaderContainer} />
          ) : (
            <TemplateSearchSelect
              noteTemplates={noteTemplates}
              headerTitle="Start with an existing template"
              noteTemplateTags={noteTemplateTags}
              templateNavFilter={templateNavFilter}
              setTemplateNavFilter={setTemplateNavFilter}
              templateNavs={templateNavs}
              disableActionIcons
              footerComponent={
                !isMikataAdmin && (
                  <FeedbackManager
                    position="bottomRight"
                    context={{
                      appointmentId: undefined,
                      noteIds: [],
                      activeNoteId: null
                    }}
                    feedbackModalOpen={feedbackModalOpen}
                    setFeedbackModalOpen={(value: boolean) => {
                      setFeedbackModalOpen(value);
                    }}
                  >
                    <div className={styles.FooterFeedback}>
                      <Text size="S">Can't find what you are looking for?</Text>
                      <Button
                        inline
                        className={styles.FooterFeedbackButton}
                        onClick={() => {
                          setFeedbackMode({
                            feedbackMode: FeedbackFormModes.SCRIBE_TEMPLATE_REQUEST
                          });
                          setFeedbackModalOpen(true);
                        }}
                      >
                        Request a template
                      </Button>
                    </div>
                  </FeedbackManager>
                )
              }
              onTemplateSelect={(noteTemplateId) => cloneTemplate(noteTemplateId)}
            />
          )}
        </FloatModal>
      )}
    </>
  );
};
const mapStateToProps = ({ noteTemplates, practitioners }: ReduxStateType) => {
  return {
    noteTemplates: noteTemplates.noteTemplates,
    noteTemplatesLoading: noteTemplates.noteTemplatesLoading,
    noteTemplateTags: noteTemplates.noteTemplateTags,
    practitioners: practitioners.data
  };
};

export default connect(mapStateToProps, {
  closeModal: closeModalAction,
  fetchNoteTemplates: fetchNoteTemplatesAction,
  fetchPractitioners: fetchPractitionersAction,
  cloneNoteTemplate: cloneNoteTemplateAction,
  setFeedbackMode
})(AddNoteTemplate);
