import React, { useMemo } from "react";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";

import { Form } from "../../../../../ui/Input";
import Loader from "../../../../../ui/Loader";
import ResponseMessagesForm from "./ResponseMessagesForm";

import { usePermissions } from "../../../../../../hooks/usePermissions";

import {
  updateAutomation,
  UpdateAutomationData,
  openModal as openModalAction,
  OpenModal
} from "../../../../../../actions";

import { ModalTypes, MessageMediums } from "../../../../../../constants";

import {
  automationDeprecatedMessageOptions,
  automationResponseMessageOptions
} from "../automationMessageOptions";

import { JourneyResponseMessagesFormState } from "../JourneyFormValidator";
import {
  AutomationDetails,
  MessageTemplate,
  Permissions,
  ReduxStateType,
  Option
} from "../../../../../../types";

import styles from "./index.module.scss";

type MessageTemplatesByTopic = { [topic: string]: Array<MessageTemplate> };

type PropsType = {
  automation?: AutomationDetails;
  messageTemplates: Array<MessageTemplate>;
  loading?: boolean;
  openModal: OpenModal;
  updateAutomationDetails: (automationId: string, automationData: UpdateAutomationData) => void;
};

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

const templateMediumFilter = (medium: string) => (template: MessageTemplate) => {
  return template.medium === medium;
};

function getTemplateOptionsByMedium(
  medium: string,
  isDefaultAutomation: boolean,
  templates: Array<MessageTemplate>
): Array<Option> {
  return templates
    .filter(templateMediumFilter(medium))
    .map((template: MessageTemplate) => {
      return {
        label: template.isDefault ? "Default" : `${template.displayName} (${template.id})`,
        value: !isDefaultAutomation && template.isDefault ? "" : template.id
      };
    })
    .filter(notEmpty);
}

const initialState = {
  excludedTopics: {},
  messageTemplates: {}
};

const ResponseMessages = ({
  automation,
  messageTemplates,
  updateAutomationDetails,
  loading,
  openModal
}: PropsType): JSX.Element | null => {
  const messages = automation?.messages;
  const automationDeprecatedConfig = automation ? automationDeprecatedMessageOptions : [];
  const automationResponsesConfig = automation ? automationResponseMessageOptions : [];

  const isDefaultAutomation = Boolean(
    automation &&
      automation.status === "published" &&
      automation.locations?.length === 0 &&
      automation.reasons?.length === 0 &&
      automation.practitioners?.length === 0
  );

  const userCanEdit = usePermissions([Permissions.UPDATE_AUTOMATION]);

  const formDisabled = loading || !userCanEdit;

  const messageTemplatesByTopic = useMemo(() => {
    const templatesByTopic: MessageTemplatesByTopic = {};

    if (messageTemplates) {
      messageTemplates.forEach((template: MessageTemplate) => {
        const topicTemplates: Array<MessageTemplate> = templatesByTopic[template.topic];

        templatesByTopic[template.topic] = topicTemplates
          ? [...topicTemplates, template]
          : [template];
      });
    }
    return templatesByTopic;
  }, [messageTemplates, messages]);

  const initialFormState = useMemo(() => {
    if (messages && automation) {
      const newFormState: JourneyResponseMessagesFormState = cloneDeep(initialState);

      const messagesFormState: JourneyResponseMessagesFormState["messageTemplates"] = {};
      const excludedTopicsFormState: JourneyResponseMessagesFormState["excludedTopics"] = {};
      automationResponsesConfig.forEach((templateCategory) => {
        messagesFormState[templateCategory.topic] = {};

        const selectedSmsMessage = messages.find(
          (messageTemplate) =>
            messageTemplate.topic === templateCategory.topic &&
            messageTemplate.medium === MessageMediums.SMS
        );
        if (selectedSmsMessage) {
          messagesFormState[templateCategory.topic].messageTemplateId = selectedSmsMessage.id;
        }

        excludedTopicsFormState[templateCategory.topic] = !automation.excludedTopics.includes(
          templateCategory.topic
        );
      });

      newFormState.messageTemplates = messagesFormState;
      newFormState.excludedTopics = excludedTopicsFormState;

      return newFormState;
    }

    return null;
  }, [messages, automation]);

  const save = (formValues: JourneyResponseMessagesFormState) => {
    let messageTemplateIds: Array<string> = [];
    if (formValues.messageTemplates) {
      messageTemplateIds = Object.values(formValues.messageTemplates)
        .map((messageData) => messageData.messageTemplateId?.toString())
        .filter(notEmpty);
    }

    const deprecatedExcludedTopics: JourneyResponseMessagesFormState["excludedTopics"] = {};

    // IHA-5818 - need to include deprecatedExcludedTopics when saving so as to not override these excludedTopics from automation
    automationDeprecatedConfig.forEach((templateCategory) => {
      deprecatedExcludedTopics[templateCategory.topic] = !automation?.excludedTopics.includes(
        templateCategory.topic
      );
    });

    const allTopics = { ...deprecatedExcludedTopics, ...formValues.excludedTopics };

    const excludedTopics = allTopics
      ? Object.keys(allTopics)
          .map((topic) => {
            if (allTopics[topic] === false) {
              return topic;
            }
            return null;
          })
          .filter(notEmpty)
      : [];

    if (automation) {
      updateAutomationDetails(automation.id, {
        messageTemplateIds,
        excludedTopics
      });
    }
    return null;
  };

  const openSendTestModal = (messageTemplate: MessageTemplate) => {
    const automationJourneyData = {
      automationId: Number(automation?.id)
    };
    if (messageTemplate && automation?.id) {
      openModal(ModalTypes.SEND_MESSAGE_TEMPLATE, {
        messageTemplate,
        automationJourneyData
      });
    }
  };

  if (!automation || !initialFormState) return <Loader screen />;

  return (
    <div className={styles.Wrapper}>
      <Form
        onSubmit={(formState) => save(formState.values as JourneyResponseMessagesFormState)}
        initialValues={initialFormState}
      >
        <ResponseMessagesForm
          automation={automation}
          automationResponsesConfig={automationResponsesConfig}
          messageTemplatesByTopic={messageTemplatesByTopic}
          isDefaultAutomation={isDefaultAutomation}
          formDisabled={formDisabled}
          openSendTestModal={openSendTestModal}
          getTemplateOptionsByMedium={getTemplateOptionsByMedium}
        />
      </Form>
    </div>
  );
};

const mapStateToProps = ({ automatedMessages, messageTemplates }: ReduxStateType) => {
  return {
    messageTemplates: messageTemplates.data,
    automation: automatedMessages.automationDetails,
    loading: automatedMessages.automationDetailsLoading
  };
};

export default connect(mapStateToProps, {
  updateAutomationDetails: updateAutomation,
  openModal: openModalAction
})(ResponseMessages);
