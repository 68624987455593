import React from "react";

type PropsType = {
  size?: number;
  color?: string;
};

const Undo = ({ size = 24, color = "currentColor" }: PropsType) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M3.07 17.36A.89.89 0 0 1 3 17v-6a1 1 0 0 1 2 0v3.69l2.94-2.76a10 10 0 0 1 16.54 3.74 1 1 0 0 1-1.89.66 8 8 0 0 0-13.25-3L6.53 16H10a1 1 0 0 1 0 2H4a1 1 0 0 1-.93-.63Z"
        fill={color}
      />
    </svg>
  );
};

export default Undo;
