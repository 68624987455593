import React, { useState, createRef, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import cx from "classnames";

import Text from "../../../../ui/Text";
import { AdminOrganizationDetailsPageTabs } from "../../../../../constants";
import { ChevronDown, ChevronUp } from "../../../../ui/Icon";

import styles from "./index.module.scss";

const getDataActive = (): boolean => {
  const url = window.location.href;
  const dataPathOptions = [
    "locations",
    "reasons",
    "providers",
    "instructions",
    "chats",
    "campaigns",
    "documents"
  ];
  // Returns true when the url contains a data path option
  return dataPathOptions.reduce<boolean>((acc, curr) => acc || url.includes(curr), false);
};

type PropsType = {
  organizationId: string;
  verified?: boolean;
};

const DataDropdown = ({ organizationId, verified }: PropsType): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdown = createRef<HTMLDivElement>();

  const handleClickOutside = (event: MouseEvent | null) => {
    if (
      dropdown.current &&
      event &&
      event.target instanceof HTMLElement &&
      !dropdown.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const handleNavClick = (e: React.MouseEvent, requiresVerifiedOrg: boolean) => {
    if (requiresVerifiedOrg && !verified) {
      e.preventDefault();
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleClickOutside);

    // returned function will be called on component unmount
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [dropdown]);

  const {
    LOCATIONS,
    REASONS,
    PROVIDERS,
    INSTRUCTIONS,
    CAMPAIGNS,
    CHATS,
    DOCUMENTS,
    SCRIBE_TEMPLATES
  } = AdminOrganizationDetailsPageTabs;

  const [isDataActive, setIsDataActive] = useState(getDataActive());
  const location = useLocation();

  useEffect(() => {
    return () => {
      setIsDataActive(getDataActive());
    };
  }, [location]);

  return (
    <div className={styles.Container}>
      <button
        type="button"
        className={cx(styles.Data, { [styles.DataActive]: isDataActive })}
        onClick={handleButtonClick}
      >
        <span>Data</span>
        <span className={styles.Chevron}>
          {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
        </span>
      </button>

      {isOpen && (
        <nav className={styles.Dropdown} ref={dropdown}>
          <NavLink
            key={LOCATIONS.label}
            to={LOCATIONS.path.replace(":organizationId", organizationId)}
            className={({ isActive }) => (isActive ? styles.NavLinkActive : styles.NavLink)}
            onClick={(e) => handleNavClick(e, false)}
          >
            <Text className={styles.LinkText} size="S" component="span">
              {LOCATIONS.label}
            </Text>
          </NavLink>

          <NavLink
            key={REASONS.label}
            to={REASONS.path.replace(":organizationId", organizationId)}
            className={({ isActive }) => (isActive ? styles.NavLinkActive : styles.NavLink)}
            onClick={(e) => handleNavClick(e, false)}
          >
            <Text className={styles.LinkText} size="S" component="span">
              {REASONS.label}
            </Text>
          </NavLink>

          <NavLink
            key={PROVIDERS.label}
            to={PROVIDERS.path.replace(":organizationId", organizationId)}
            className={({ isActive }) => (isActive ? styles.NavLinkActive : styles.NavLink)}
            onClick={(e) => handleNavClick(e, false)}
          >
            <Text className={styles.LinkText} size="S" component="span">
              {PROVIDERS.label}
            </Text>
          </NavLink>

          <NavLink
            key={INSTRUCTIONS.label}
            to={INSTRUCTIONS.path.replace(":organizationId", organizationId)}
            className={({ isActive }) =>
              isActive
                ? styles.NavLinkActive
                : cx(styles.NavLink, {
                    [styles.NavLinkDisabled]: !verified
                  })
            }
            onClick={(e) => handleNavClick(e, true)}
          >
            <Text className={styles.LinkText} size="S" component="span">
              {INSTRUCTIONS.label}
            </Text>
          </NavLink>

          <NavLink
            key={CAMPAIGNS.label}
            to={CAMPAIGNS.path.replace(":organizationId", organizationId)}
            className={({ isActive }) =>
              isActive
                ? styles.NavLinkActive
                : cx(styles.NavLink, {
                    [styles.NavLinkDisabled]: !verified
                  })
            }
            onClick={(e) => handleNavClick(e, true)}
          >
            <Text className={styles.LinkText} size="S" component="span">
              {CAMPAIGNS.label}
            </Text>
          </NavLink>

          <NavLink
            key={CHATS.label}
            to={CHATS.path.replace(":organizationId", organizationId)}
            className={({ isActive }) =>
              isActive
                ? styles.NavLinkActive
                : cx(styles.NavLink, {
                    [styles.NavLinkDisabled]: !verified
                  })
            }
            onClick={(e) => handleNavClick(e, true)}
          >
            <Text className={styles.LinkText} size="S" component="span">
              {CHATS.label}
            </Text>
          </NavLink>

          <NavLink
            key={DOCUMENTS.label}
            to={DOCUMENTS.path.replace(":organizationId", organizationId)}
            className={({ isActive }) =>
              isActive
                ? styles.NavLinkActive
                : cx(styles.NavLink, {
                    [styles.NavLinkDisabled]: !verified
                  })
            }
            onClick={(e) => handleNavClick(e, true)}
          >
            <Text className={styles.LinkText} size="S" component="span">
              {DOCUMENTS.label}
            </Text>
          </NavLink>

          <NavLink
            key={SCRIBE_TEMPLATES.label}
            to={SCRIBE_TEMPLATES.path.replace(":organizationId", organizationId)}
            className={({ isActive }) => (isActive ? styles.NavLinkActive : styles.NavLink)}
            onClick={(e) => handleNavClick(e, false)}
          >
            <Text className={styles.LinkText} size="S" component="span">
              {SCRIBE_TEMPLATES.label}
            </Text>
          </NavLink>
        </nav>
      )}
    </div>
  );
};

export default DataDropdown;
