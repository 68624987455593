import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import debounce from "lodash/debounce";

import Card from "../../../../../ui/Card";
import { Form } from "../../../../../ui/Input";
import FormContent from "./FormContent";

import { generateReasonLabel } from "../../../../../../utils/generateReasonLabel";

import { AppointmentBookingData, Reason, ReduxStateType, Patient } from "../../../../../../types";
import {
  fetchReasons as fetchReasonsAction,
  fetchPatients as fetchPatientsAction,
  FetchPatients,
  openModal as openModalAction,
  OpenModal,
  updateAppointmentRequest as updateAppointmentRequestAction
} from "../../../../../../actions";

import styles from "./index.module.scss";

type PropsType = {
  appointmentRequestId: number;
  personalHealthNumber: string;
  bookingData: AppointmentBookingData;
  fetchReasons: () => void;
  fetchPatients: (body: FetchPatients) => void;
  reasons: Array<Reason>;
  openModal: OpenModal;
  closeFullScreenModal: () => void;
  organizationId: number;
  timezone: string;
  patients: Patient[];
  patientsLoading: boolean;
  preferQuickView?: boolean;
};

type FormState = {
  personalHealthNumber: string;
  emrReasonId: string;
};

const DEBOUNCE_LIMIT_MILLISECONDS = 1000;

const AppointmentBookingCard = ({
  appointmentRequestId,
  personalHealthNumber,
  bookingData,
  fetchReasons,
  fetchPatients,
  reasons,
  patients,
  patientsLoading,
  openModal,
  closeFullScreenModal,
  organizationId,
  timezone,
  preferQuickView
}: PropsType) => {
  const { bookingReason, selectedAppointmentSlots } = bookingData;
  const { defaultEmrReasonId } = bookingReason;
  const [matchedPatient, setMatchedPatient] = useState<Patient | null>(null);
  const patientResultsMessage =
    patients.length === 1
      ? "1 result was found in your records"
      : `${patients.length} results were found in your records`;
  const patientSectionText = matchedPatient
    ? "The appointment will be booked under the following patient in your records."
    : "Select the patient for whom the appointment will be booked. Please notice that the phone number must be verified in order to receive a confirmation message.";
  const availableAppointmentSlots = selectedAppointmentSlots.filter(
    (apptSlot) => apptSlot.isStillAvailable
  );
  const primaryProviderIds = matchedPatient
    ? matchedPatient.primaryProviders.map((provider) => provider.id)
    : [];

  const noMoreAvailableSlots = availableAppointmentSlots.length === 0;

  const reasonOptions = reasons.map((reason) => {
    return {
      label: generateReasonLabel(reason),
      value: reason.emrReasonId || reason.value
    };
  });

  const debouncedPatientFetch = useCallback(
    debounce(fetchPatients, DEBOUNCE_LIMIT_MILLISECONDS),
    []
  );

  useEffect(() => {
    fetchReasons();
  }, [appointmentRequestId]);

  useEffect(() => {
    if (personalHealthNumber && fetchPatients && !noMoreAvailableSlots) {
      fetchPatients({ personalHealthNumber });
    }
  }, [personalHealthNumber]);

  const handlePHNChange = (personalHealthNumber: string) => {
    setMatchedPatient(null);
    if (personalHealthNumber.length >= 7) {
      debouncedPatientFetch({ personalHealthNumber });
    }
  };

  const initialFormState: FormState = {
    personalHealthNumber,
    emrReasonId: defaultEmrReasonId || ""
  };

  return (
    <Card className={preferQuickView ? styles.QuickView : ""}>
      <Form initialValues={initialFormState}>
        <FormContent
          appointmentRequestId={appointmentRequestId}
          reasons={reasons}
          openModal={openModal}
          closeFullScreenModal={closeFullScreenModal}
          organizationId={organizationId}
          timezone={timezone}
          patients={patients}
          noMoreAvailableSlots={noMoreAvailableSlots}
          matchedPatient={matchedPatient}
          setMatchedPatient={setMatchedPatient}
          patientSectionText={patientSectionText}
          handlePHNChange={handlePHNChange}
          patientResultsMessage={patientResultsMessage}
          reasonOptions={reasonOptions}
          availableAppointmentSlots={availableAppointmentSlots}
          primaryProviderIds={primaryProviderIds}
          patientsLoading={patientsLoading}
          preferQuickView={preferQuickView}
        />
      </Form>
    </Card>
  );
};

const mapStateToProps = ({ reasons, organizationData, patients }: ReduxStateType) => {
  return {
    reasons: reasons.data,
    patients: patients.patients,
    patientsLoading: patients.patientsLoading,
    organizationId: organizationData.organizationData ? organizationData.organizationData.id : 0,
    timezone: organizationData.organizationData ? organizationData.organizationData.timezone : "",
    emrType: organizationData.organizationData ? organizationData.organizationData.emrType : "",
    accuroUUID: organizationData.organizationData
      ? organizationData.organizationData.accuroUUID
      : "",
    integrationCategory: organizationData.organizationData
      ? organizationData.organizationData.integrationCategory
      : ""
  };
};

export default connect(mapStateToProps, {
  fetchReasons: fetchReasonsAction,
  fetchPatients: fetchPatientsAction,
  openModal: openModalAction,
  updateAppointmentRequest: updateAppointmentRequestAction
})(AppointmentBookingCard);
